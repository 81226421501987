import { parseRecordObject } from "../leafmob/md/AttributeMapping";
import { AttributeValueOptionMask, AttributeValueTypeMask, attrTypeFromString } from "../leafmob/md/AttributeType";
import LoaderBase from "../model/LoaderBase";
import { mmrMakeInitialLoadURL, mmrInvoke, mmrMakeRealtimeLoadURL } from './MMRemoteUtils'

const SeqProp = '.seq';

class RemoteLoader extends LoaderBase {
	constructor(listener, isZip) {
		super(listener, isZip);

		this.lastTime = Number.POSITIVE_INFINITY;
		this.pollingInterval = 10000;
		this.baseURL = null;
		this.sessionId = null;

		this.seqNumMap = {};
	}

	processLine(line, lineNo, numEntireLines) {
	}

	setBaseURL(u) {
		this.baseURL = u;
	}

	async initialFetch(layer) {
		const u = mmrMakeInitialLoadURL(this.baseURL);
		const apiResponse = await mmrInvoke(u);
		console.log("base url = ", this.baseURL);

		if (apiResponse.data) {
			this.sessionId = apiResponse.data.sid;
			const idMap = this.makeIdMap(apiResponse.data);
			this.addLayerAttributes(layer, apiResponse.data.attributes);

			layer.data.allocate(idMap, layer.attributeMapping);
			this.sendInitialRecords(layer, apiResponse.data.records);
			/*
			// 新規IDがあれば登録
			for (const id of idList) {

			}*/
		}
//		await fetch

		if (this.listener && this.listener.loaderOnFileEnd) {
			this.listener.loaderOnFileEnd();
		}

		this.lastTime = performance.now();
	}

	async updateFetch(layer) {
		const u = mmrMakeRealtimeLoadURL(this.baseURL);
		var apiResponse = null;

		try {
			apiResponse = await mmrInvoke(u + '?sid=' + this.sessionId);
		} catch(err) {

		}

		if (apiResponse && apiResponse.data) {
			this.writeUpdatedRecords(layer, apiResponse.data);
		}
	}

	writeUpdatedRecords(layer, responseData) {
		const idmapped = this.toIdMapped(responseData, this.seqNumMap);
		console.log(idmapped);
	}

	makeIdMap(responseData) {
		const m = {};
		if (responseData.records) {
			for (const rec of responseData.records) {
				if (!m[ rec.id ]) {
					m[ rec.id ] = 1;
				} else {
					++m[ rec.id ];
				}
			}
		}

		return m;
	}

	toIdMapped(responseData, receivedSeqIdMap) {
		const m = {};
		if (responseData.records) {
			for (const rec of responseData.records) {
				if (receivedSeqIdMap.hasOwnProperty(rec.id) &&
					rec.hasOwnProperty(SeqProp)) {
					console.log("^^", receivedSeqIdMap[rec.id], rec[SeqProp] );
				
				}

				if (!m.hasOwnProperty(rec.id)) {
					m[ rec.id ] = [];
				}

				m[ rec.id ].push(rec);
			}
		}

		for (const id in m) if(m.hasOwnProperty(id)) {
			m[id].sort( (a,b) => { return a[SeqProp] - b[SeqProp]; } )
		}

		return m;
	}

	sendInitialRecords(layer, recordList) {
		var lineno = 0;
		for (const rec of recordList) {
			this.updateSequenceNumber(rec);
			parseRecordObject(layer.data, rec, layer.attributeMapping, lineno++);
		}

		console.log( this.seqNumMap );
	}

	updateSequenceNumber(rec) {
		if (rec.hasOwnProperty(SeqProp)) {
			this.seqNumMap[ rec.id ] = rec[SeqProp];
		}
	}

	addLayerAttributes(layer, srcList) {
		var columnIndex = 0;
		for (const src_attr of srcList) {
			const iType = attrTypeFromString(src_attr.type);

			layer.addAttribute(
				src_attr.name,
				columnIndex,
				iType & AttributeValueTypeMask,
				src_attr.name === 'id', // ID is hidden
				iType & AttributeValueOptionMask);
			++columnIndex;
		}
	}

	nudge(layer) {
		const t = performance.now();
		const dt = t - this.lastTime;

		console.log(dt, this.pollingInterval);
		if (Number.isFinite(dt) && dt >= this.pollingInterval) {
			this.lastTime = t;

			this.updateFetch(layer);
		}
	}
}

export default RemoteLoader;