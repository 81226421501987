
export class MovingObject {
	constructor() {
		this.id = null;
		this.selected = false;
		this.filterResult = null;
		this.attrList = [0,0,0,0];

		this.chipX = 0;
		this.chipY = 0;
		this.direction = 0;
		this.lat = 0;
		this.lng = 0;

		this.tailLog = null;
	}
	
	useTail() {
		if (!this.tailLog) {
			this.tailLog = [
				new TailEntry(), 
				new TailEntry(), 
				new TailEntry(), 
				new TailEntry()
			];
		}

		return this.tailLog;
	}

	moreTail() {
		if (this.tailLog) {
			const t = new TailEntry();
			this.tailLog.push( t );
			return t;
		}
	}

	ensureAttributeLength(n) {
		
	}
};

class TailEntry {
	constructor() {
		this.lat = this.lng = this.time = 0;
		this.sx = this.sy = 0;
	}
}

export class MovingObjectPickPool {
	constructor(array) {
		this.array = array || [];
		this.pickedCount = 0;
		this.tailEnabled = false;
		this.tailDuration = 120;

		this.prevPickTime = -1;
	}

	setTailEnabled(t) {
		this.tailEnabled = t;
	}

	setTailDuration(d) {
		this.tailDuration = d;
	}

	setPrevPickInfo(t) {
		this.prevPickTime = t;
	}
	
	isDirty(newTime) {
		return (newTime !== this.prevPickTime);
	}
	
	forceDirty() {
		this.prevPickTime = -1;
	}

	referRaw() {
		return this.array;
	}

	ensureCapacity(capacity) {
		const a = this.array;
		if (a.length < capacity) {
			const nToAdd = capacity - a.length;
			for (var i = 0;i < nToAdd;++i) {
				a.push(  new MovingObject()  );
			}
		}
	}

	reset() {
		this.pickedCount = 0;
	}
	
	next() {
		const a = this.array;
		if (this.pickedCount >= a.length) { return null; }
		const result = a[this.pickedCount++];
		if (this.tailEnabled) { result.useTail(); }

		return result;
	}

	cancel() {
		if (this.pickedCount > 0) {
			--this.pickedCount;
		}
	}

	dispose() {
		this.array.length = 0;
	}
}
