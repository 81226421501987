import leaflet from 'leaflet'
const L = leaflet;

var _tempTXY = {x:0,y:0};

const OverlayLayer = L.Layer.extend({
	initialize: function (options)  {
		L.GridLayer.prototype.initialize.call(this, options);
		this._renderer = null;
		this._nowZooming = false;
		this._canvasVisible = true;

		if (options) {
			this._renderer = options.renderer || null;
		}
	},
	
	setVisibility: function(v) {
		this._canvasVisible = v;
	},

	referRenderer: function() {
		return this._renderer;
	},

	onAdd: function () {
		this._initContainer();
	},

	onRemove: function (map) {
		if (this._container) {
			L.DomUtil.remove(this._container);
			this._container = null;
		}
	},

	moveToFront: function() {
		L.DomUtil.toFront(this._container);
	},

	getEvents: function () {
		const events = {
			move:      this._onMove,
			resize:    this._onResize,
			zoomstart: this._onZoomStart,
			zoomend:   this._onZoomEnd
		};

		return events;
	},

	_initContainer: function () {
		if (this._container) { return; }
		
		this._size = new L.Point(0,0);
		this._container = L.DomUtil.create('canvas', 'lm-overlay-layer ' + (this.options.className || ''));
		this.getPane().appendChild(this._container);

		this._myPane = this.getPane();

		this._setCounterTransform();

		this._configureTargetCanvas(this._container);
		this._fitSize();
	},

	_onMove: function() {
		this._nowZooming = false;

		this._setCounterTransform();
		this._updateOrigin();
		this._render();
	},

	_onResize: function() {
		this._fitSize();
		this._updateOrigin();
		this._render();
	},

	_onZoomStart: function() {
		this._nowZooming = true;
		this._render();
	},

	_onZoomEnd: function() {
		this._nowZooming = false;
	},

	_setCounterTransform: function() {
		const m = this._map;
		if (m) {
			const pos = m._getMapPanePos();
			_tempTXY.x = -pos.x;
			_tempTXY.y = -pos.y;

			L.DomUtil.setPosition(this._container, _tempTXY)
		}
	},

	_fitSize: function() {
		const m = this._map;
		if (m && this._container) {
			const sz = m.getSize();
			const style = this._container.style;

			this._container.width = sz.x;
			this._container.height = sz.y;
			
			this._size.x = sz.x;
			this._size.y = sz.y;
			style.width  = sz.x + 'px';
			style.height = sz.y + 'px';

			this._renderer.resize(sz.x, sz.y);
		}
	},

	_updateOrigin() {
		const m = this._map;
		const bounds = m.getBounds();
		const nw = bounds.getNorthWest();
		const se = bounds.getSouthEast();

		this._renderer.setOrigin(
			0, 0,
			nw, se, m.getZoom()
		);
	},

	_configureTargetCanvas: function(cv) {
		this._renderer.setTargetCanvas( cv );
		this._renderer.initGL();
	},

	redraw: function(forceUpdate) {
		if (forceUpdate) {
			this._updateOrigin();
		}
		
		this._render();
	},

	_render: function() {
		if (this._renderer) {
			if (this._nowZooming) {
				this._renderer.clear();
			} else {
				if (this._canvasVisible) {
					this._renderer.projectAndRender(this._map);
				} else {
					this._renderer.clear();
				}
			}
		}
	},
	
	dispose: function() {
		this.remove();
		this._renderer = null;
	}
});

const mob_layer = {
	create: function(options) {
		return new OverlayLayer(options);
	}
};

export default mob_layer;
