import React from 'react';
import { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import LinkIcon from '@material-ui/icons/Link';
import IconButton from '@material-ui/core/IconButton';
import { format_date_full } from './TimeSlider'
import get_i18n from '../Internationalization';

class AnnotationList extends Component {
	constructor(props) {
		super(props);
		this.state = {}
		
	}


	render() {
		const a = this.props.annotation;
		return ( <div>{ this.generateItems( a ? a.features : null ) }</div> );
	}

	generateItems(featureList) {
		const res = [];
		var index = 0;

		if ( Array.isArray(featureList) ) {
			for (const f of featureList) {
				if (feature_sanity(f)) {
					res.push( this.generateAnAnnotation(f,index) );
					++index;
				}
			}
		}

		return res;
	}
	
	generateAnAnnotation(feature, index) {
		const pr = feature.properties;
		return ( <div key={index} className="mm-annotation-list-item">
			<h3>{ pr.title || '(no title)' }</h3>
			<Grid container spacing={0}>
				<Grid item xs={8}>
					<table className="mm-ann-table"><tbody>
						<tr><td className="mm-ann-table-rowhead">{ get_i18n('BeginTime') }</td><td>{ ann_time(pr["begin-time"]) }</td></tr>
						<tr><td className="mm-ann-table-rowhead">{ get_i18n('EndTime') }</td><td>{ ann_time(pr["end-time"]) }</td></tr>
					</tbody></table>
				</Grid>
				<Grid item xs={4} className="mm-annotation-command-col">
					{ this.selectButton(  feature,  (e) => this.onSelectButtonClick(e,feature)  ) }
					{ this.linkButton(  feature,  (e) => this.onLinkButtonClick(e,feature)  ) }
					<IconButton size="small" onClick={ (e) => this.onRevealButtonClick(e, feature) }><ArrowForwardIcon /></IconButton>
				</Grid>
			</Grid>
		</div> );
	}

	selectButton(feature, handler) {
		const pr = feature.properties;
		if (pr && pr.hasOwnProperty('traceId')) {
			return (<IconButton size="small" onClick={ handler }><TouchAppIcon /></IconButton>);
		} else {
			return '';
		}
	}

	linkButton(feature, handler) {
		if (feature.properties && feature.properties.url) {
			return (<IconButton size="small" onClick={ handler }><LinkIcon /></IconButton>);
		} else {
			return '';
		}
	}

	onRevealButtonClick(e, feature) {
		if (this.props.onRevealButtonClick) {
			this.props.onRevealButtonClick(e, feature);
		}
	}

	onLinkButtonClick(e, feature) {
		if (this.props.onLinkButtonClick) {
			this.props.onLinkButtonClick(e, feature);
		}
	}
	
	onSelectButtonClick(e, feature) {
		if (this.props.onSelectButtonClick) {
			this.props.onSelectButtonClick(e, feature);
		}
	}
}

function ann_time(raw) {
	if (!raw) {
		return get_i18n('NotAvail');
	}

	const d = new Date(raw*1000);
	return format_date_full(d);
}

function feature_sanity(f) {
	return f.hasOwnProperty('properties');
}

export default AnnotationList;