import MMEventDispatcher from './EventDispatcher'

// base methods
function toolbase_init() {
    this.eventDispatcher = new MMEventDispatcher();
}

function toolbase_set_ev_parent(pr) {
		this.eventDispatcher.setEventParent(pr);
}

function toolbase_make_button(container, iconURL, title, callback, sizeOverride) {
    const a = document.createElement('a');
    a.href = 'javascript:void(0)';
    
    const img = document.createElement('img');
    img.src = iconURL;
    img.width = sizeOverride || 10;
    img.height = sizeOverride || 10;
    a.appendChild(img);
    a.title = title;
    
    a.addEventListener('click', callback, false);

    container.appendChild(a);
    return a;    
}

export { toolbase_init, toolbase_set_ev_parent, toolbase_make_button };
