import GateJob, { GJTYPE } from './gate/GateJob';
import AttributeType from './leafmob/md/AttributeType'

function addGateFunctions(klass) {
	klass.prototype.onGateFunctionWillAppear = function(gateFeature) {
		var enable_test = false;
		const lyr = this.getSelectedLayer();
		if (lyr && lyr.xComputeServer) {
			enable_test = true;
		}

		if (gateFeature.popupElement) {
			gateFeature.popupElement.setAttribute('data-enable-experimental', enable_test ? 1 : 0);
		}
	};

	klass.prototype.onGateInvokeServerRequested = function(senderGateFeature, options) {
		const lyr = this.getSelectedLayer();
		if (lyr && lyr.xComputeServer) {

			const coords = senderGateFeature.referCoordList();
//			console.log(coords);
//			console.log(senderGateFeature, options, lyr.xComputeServer);

			const content = {
				coordinates: coords,
				type: 'line'
			};

			fetch(lyr.xComputeServer['base-url'], {
				method: 'post',
				body: JSON.stringify(content, null, '  '),
				headers: { 'Content-Type': 'application/json' }
			})

		}
	};

	klass.prototype.onGatePutRequest = function(gateType) {
		console.log('Implement here if needed :-)', gateType);
		// - - -
	};

	klass.prototype.onGateExecRequest = function(senderGateFeature, options) {
//		console.log(options);
		if (!options) {
			senderGateFeature.closeControl();
		}
		this.runGateWithMapFeature( senderGateFeature, options );
	};
	
	klass.prototype.onGateRemoveRequest = function(senderGateFeature) {
		senderGateFeature.destroy();

		if (this.cpComponent) {
			this.cpComponent.afterGateRemoved(senderGateFeature);
		}
	};

	klass.prototype.runGateWithMapFeature = function(sourceFeature, options) {
		const targetLayer = this.getSelectedLayer();
		if (targetLayer && GateJob.isValidTarget(targetLayer)) {
			this.showProcessing(true);
			const coords = sourceFeature.referCoordList();
			const job = new GateJob(coords, options, sourceFeature.isPolygon() ? GJTYPE.Polygon : GJTYPE.Line);

			job.setTarget(targetLayer);
			job.setEventParent(this);

			job.start();
		}
	};

	klass.prototype.on_gateJobFinish = function(job) {
		job.setEventParent(null);
		this.showProcessing(false);
		
		const res = job.result;
		const layer = job.targetLayer;
		if (res && layer) {
			if (job.userOptions && job.userOptions.csv) {
				// CSV export
				const jsonObjList = makeExportResult(res.list, layer, true, job.userOptions.gateName);
				const csv = convertGateJsonToCSV(jsonObjList);

				var layerReprTime = null;
				const layerTimeRange = layer.getTimeRange();
				if (layerTimeRange) {
					layerReprTime = layerTimeRange.min * 1000;
				}

				job.userOptions.csv(csv, 'gate-result-' + job.userOptions.gateName + '-' + makeISOTimestamp(layerReprTime, true).replace(/:/g, '_') + '.csv');
			} else {
				const selection = layer.referSelection();
				selection.clear();

				res.selectIDs( selection );
			}
		}
	};
	
	klass.prototype.on_gateJobProgress = function(job, ratio) {
		this.setProgressValue( Math.floor(ratio * 100.0) );
	};
};

function makeExportResult(gateResultList, layer, useLocalTime, gateName) {
	if (layer.data) {
		const amap = layer.attributeMapping;

		const jList = [];
		for (const resEntry of gateResultList) {
			const tset = layer.data.idmap[resEntry.pid];
			const jsonEntry = {id: resEntry.pid, gate_name: gateName, gate_direction: (resEntry.dotProduct < 0) ? -1 : 1 };
			amap.each((aname, attr)=>{
				if (!attr.hidden) {
					const v1 = tset.getValueAtIndex(aname, resEntry.firstIndex);
					var ival = v1;

					if (resEntry.secondIndex !== null) {
						const v2 = tset.getValueAtIndex(aname, resEntry.secondIndex);
						if (!attr.interpolation) {
							if (resEntry.crossOffset >= 0.5) {
								ival = v2;
							}
						} else {
							const _alpha = 1.0 - resEntry.crossOffset;
							ival = v1 * _alpha  +  v2 * resEntry.crossOffset;
						}
					} else {
						jsonEntry.gate_direction = 0;
					}

					if (attr.type === AttributeType.DATETIME) {
						ival = makeISOTimestamp(Math.floor(ival * 1000), useLocalTime);
					}

					jsonEntry[aname] = ival;
				}
			});

			jList.push(jsonEntry);
		}

		return jList;
	}

	return null
}

function makeISOTimestamp(milseconds, useLocalTime) {
	const dt = (null===milseconds) ? new Date() : new Date(milseconds);
	if (useLocalTime) {
		dt.setHours(dt.getHours() + 9);
		return dt.toISOString().replace(/[zZ]/, '') + make_timezone(dt);
	} else {
		return dt.toISOString();
	}
}

function make_timezone(dt) {
	function zp(i) { return (i<10) ? ('0'+i) : i }

	const tz = dt.getTimezoneOffset();
	const sign = (tz<0) ? '+' : '-'; // SIGN IS REVERSED
	const abs = Math.abs(tz);
	const ah = Math.floor(abs / 60);
	const am = Math.floor(abs % 60);

	return sign + zp(ah) + ':' + zp(am);
}

function convertGateJsonToCSV(sourceList) {
	const header = makeHeaderRow(sourceList);
	const rows = [];

	if (header) {
		rows.push(header.join(','));
		for (const sourceObj of sourceList) {

			const values = [];
			for (const colName of header) {
				values.push( sourceObj[colName] );
			}

			rows.push(values.join(','));
		}
	}

	return rows.join("\n");
}

function makeHeaderRow(sourceList) {
	const firstRow = sourceList[0];
	if (!firstRow) {
		return null;
	}

	const ret = [];
	for (const name in firstRow) if(firstRow.hasOwnProperty(name)) {
		ret.push(name);
	}

	return ret;
}



export default addGateFunctions;