import React from 'react';
import { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import '../App.css';
import TimeSlider from './TimeSlider'

import MenuIcon from '@material-ui/icons/Menu';

const kSpeedPreset = [1, 6, 10, 60, 100, 360, 600, 1000, 3600];

class ControlBar extends Component {
	// SETUP ==============================================================
	constructor(props) {
		super(props);
		this.state = {
			presetIndex: props.initial,
			playing: false,
			speedLabel: this.makeLabelText( props.initial )
		};
		
		this.tsComponent = null;
		this.playButtonComponent = null;
	}
	
	forceResize() {
		if (this.tsComponent) {
			this.tsComponent.onGlobalResize(null);
		}
	}
	
	render() {
	    return (<div className="mm-control-bar">
			{ this.renderMenuButton( this.props.showMenuButton ) }
			<div className="mm-control-bar-control-area">{ this.renderPlayButton() }</div>
			<div className="mm-control-bar-control-area-wide">{ this.renderSpeedSlider() }</div>
			<TimeSlider ref={ this.onRenderTimeSlider.bind(this) } afterCurrentTimeChange={ this.afterCurrentTimeChange.bind(this) } /></div>
		) ;
	}
	
	renderMenuButton(show) {
		return show ? 
		 <div className="mm-control-bar-control-area"> <Button onClick={ this.onMenuButtonClick.bind(this) } variant="contained" className="mm-control-menu-button"><img src="images/app-icon.png" width="32" height="32" alt="Show side bar" /></Button> </div> :
		 '' ;
	}

	renderPlayButton() {
		const pl = this.state.playing;
		const color = pl ? 'secondary' : 'primary';
		const lab = pl ? '■' : '►';
		
		return ( <Button variant="contained" ref={ e=> this.playButtonComponent=e } onClick={ this.onPlayClick.bind(this) } color={color} className="mm-control-play-button" >{lab}</Button> );
	}
	
	renderSpeedSlider() {
		const pr = kSpeedPreset;
		return (<div>
			<div id="mm-speed-value-disp">{ this.state.speedLabel }</div>
			<Slider marks value={ this.state.presetIndex } onChange={ this.onSpeedSliderChange.bind(this) } className="mm-control-speed-slider" min={0} max={pr.length-1} />
		</div> );
	}

	onRenderTimeSlider(e) {
		this.tsComponent = e;
	}

	getTimeSlider() {
		return this.tsComponent;
	}

	setTimeRange(timeRange) {
		if (this.tsComponent) {
			this.tsComponent.setTimeRange(timeRange);
		}
	}

	afterCurrentTimeChange(timeSlider, newTime) {
		if (this.props.afterCurrentTimeChange) {
			this.props.afterCurrentTimeChange(timeSlider, newTime);
		}
	}
	
	onMenuButtonClick(e) {
		if (this.props.onMenuButtonClick) {
			this.props.onMenuButtonClick(e);
		}
	}
	
	onPlayClick(e) {
		if (this.props.onPlayClick) {
			this.props.onPlayClick(e);
		}
	}
	
	onSpeedSliderChange(e, newValue) {
		this.state.presetIndex = newValue;
		this.state.speedLabel = this.makeLabelText(newValue);
		this.setState(this.state);
		
		if (this.props.afterSpeedSliderChange) {
			this.props.afterSpeedSliderChange(this);
		}
	}

	setPlayButtonState(playing) {
		this.state.playing = playing;
		this.setState( this.state );
	}

	makeLabelText(val) {
		return '✕' + kSpeedPreset[val];
	}

	getSelectedSpeed() {
		return kSpeedPreset[this.state.presetIndex];
	}
}

export default ControlBar;