import React from 'react';
import { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import './App.css';
import 'leaflet/dist/leaflet.css';

import WelcomeCard from './panes/WelcomeCard'
import ContentPane from './panes/ContentPane'
import LayerList   from './panes/LayerList'
import LoadDialog  from './dialogs/LoadDialog'
import AttrGenDialog from './dialogs/AttrGenDialog'
import {LOADTYPE}  from './dialogs/LoadDialog'

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import ExpressionFilterDialog from './dialogs/ExpressionFilterDialog';
import SettingExportDialog from './dialogs/SettingExportDialog';
import InterpolationLimitDialog from './dialogs/InterpolationLimitDialog';

import MMProject   from './model/MMProject'
import PlayControl from './PlayControl'

import ColoringPresetManager from './model/ColoringPresetManager'
import { check_first_visit, mark_visit_flag } from './misc-utils'
import Popover from '@material-ui/core/Popover';

//
// import MarkerImageGenerator from './marker/MarkerImageGenerator'
// import MarkerUtils from './leafmob/MarkerUtils'

import {dataURL,objectURL} from '@gripeless/pico'

import addGateFunctions from './AppGatePart'
import addPanelHandlers from './AppPanelPart'
import { addCaptureFunctions } from './AppCapturePart'
import get_i18n from './Internationalization';
import { generateLayerAnnotationJSON, generateLayerSettingJSON } from './model/SettingExporter';
import { selftestMMZExporter } from './model/MMZExporter';
import { testMDAppend } from './leafmob/md/MovingData';

import  { InterpolationLimitType } from './model/MMLayer';

class App extends Component {
	constructor(props) {
		super(props);
//		this.setupGoogleLoginHandler();
		ColoringPresetManager.init();
		this.pollingInterval = 5000;
		this.numCapturedFrames = 0;

		this.llComponent = null;
		this.cpComponent = null;
		this.xdlgComponent = null;
		this.welcomeCatdComponent = null;
		this.helpAnchorElement = null;
		this.attrGenDialogComponent = null;
		this.xfilterDialogComponent = null;
		this.intpDialogComponent = null;

		const pj = new MMProject()
		pj.setEventParent(this);

		this.playControl = new PlayControl(this);

		this.state = {
			firstVisit: check_first_visit(),
			firstHelpShown: false,
			loadHelpAnchor: null,
			loadWindowOpen: false,
			currentProject: pj,
			showWelcome: true,
			listSelectedId: -1,
			showProcessing: false,
			progressValue: 0,
			confirmingLayerDelete: false,
			attrGenDialogOpen: false,
			attrGenDialogTarget: null,
			xfilterDialogOpen: false,
			xfilterDialogTargetLayer: null,
			settingExContent: null,
			settingExAnnotation: null,
			settingExLayer: null,
			interpolationLimitDialogOpen: false,
			interpolationLimitDlgLayer: null,
			login: null,
			showSideBar: true,
			showCover: true
		};
		
		this.layerAboutToDelete = null;
		this.firstDialogShown = false;
// MarkerImageGenerator.selfTest();
// MarkerImageGenerator.sizingTest();
// MarkerUtils.selfTest();
// selftestMMZExporter();
//testMDAppend();
// ^test

		this.capSetupExtension();
		setInterval( this.poll.bind(this), this.pollingInterval );
	}
	
	poll() {
		const pj = this.getCurrentProject();
		const ll = pj.layerList;
		const n = ll.count();

		for (var i = 0;i < n;++i) {
			const layer = ll.getAt(i);
			if (layer.loader) {
				layer.loader.nudge(layer);
			}
		}
	}

	setupGoogleLoginHandler() {
		const that = this;
		if (!window.onSignIn) {
			window.onSignIn = function(u) {
				that.onSignIn(u);
			};
		}
	}
	
	onSignIn(googleUser) {
		const profile = googleUser.getBasicProfile();
		const loginData = {
			displayName: profile.getName()
		};
		
		if (this.welcomeCatdComponent) {
			this.welcomeCatdComponent.forceCloseMenu();
		}
		
		const s = this.state;
		s.login = loginData;
		this.setState(s);
	}
	
	onSignOut() {
		const s = this.state;
		s.login = null;
		this.setState(s);
	}
	
	redraw(newTime, forceUpdate) {
		if (this.cpComponent) {
			this.cpComponent.redraw(newTime, forceUpdate);
			this.cpComponent.updateTimeDisplay(newTime);
		}
	}
	
	render() {
		const xs = this.state.showSideBar ? 8 : 12;
		
		return (
			<div>
				<Grid container id="mm-layout-root">
					{ this.generateSideArea() }
					<Grid item xs={xs} className="mm-grid-content-column">
						<ContentPane
							ref={ e=>this.onContentPaneRender(e) }
							showMenuButton={ !this.state.showSideBar }
							selectedLayerProvider={ this }
							afterCurrentTimeChange={ this.afterTimeSliderTimeChange.bind(this) }
							afterSpeedSliderChange={ this.afterSpeedSliderChange.bind(this) }
							onPlayClick={ this.onPlayClick.bind(this) }
							onMenuButtonClick={ this.onMenuButtonClick.bind(this) }
							onGateFunctionWillAppear={ this.onGateFunctionWillAppear.bind(this) }
							onGateInvokeServerRequested={ this.onGateInvokeServerRequested.bind(this) }
							onGatePutRequest={ this.onGatePutRequest.bind(this) }
							onGateExecRequest={ this.onGateExecRequest.bind(this) }
							onGateRemoveRequest={ this.onGateRemoveRequest.bind(this) } />
						<div className="mm-content-cover"
						 style={ {display: this.state.showCover ? 'none' : 'none'} }>
						<img className="mobmap-welcome-hero" src="./images/hero-image.png" />
						<h3>Welcome!</h3></div>
					</Grid>
				</Grid>
				<LoadDialog
					ref={ this.setLoadDialogRef.bind(this) }
					onGenerateGrid={ this.onGenerateGrid.bind(this) }
					onFullLoadButton={ this.onFullLoadButton.bind(this) }
					onRemoteLoad={ this.onRemoteLoad.bind(this) }
					didLocalMovementCSVSelect={ (dialog,file) => this.didLocalMovementCSVSelect(dialog, file) }
				/>
				<Backdrop className="mm-backdrop" open={ this.state.showProcessing }>
					<CircularProgress
					 color="inherit" className="mm-backdrop-progress" size={80} />
				</Backdrop>
				<Dialog open={ this.state.confirmingLayerDelete }>
					<DialogTitle>Delete layer?</DialogTitle>
					<DialogActions>
						<Button onClick={ this.onDeleteDialogOK.bind(this) }     color="secondary">Delete</Button>
						<Button onClick={ this.onDeleteDialogCancel.bind(this) } color="primary">Cancel</Button>
					</DialogActions>
				</Dialog>
				<AttrGenDialog 
				ref={ (el) => {
					if (el) { this.attrGenDialogComponent=el; }
				} }
				onExecuteClick={ this.onAttrGenDialogExecute.bind(this) }
				onCloseClick={ this.onAttrGenDialogClose.bind(this) }
				open={ this.state.attrGenDialogOpen }
				target={ this.state.attrGenDialogTarget } />
				<ExpressionFilterDialog
				ref={ 
					(el) => { if(el){ this.xfilterDialogComponent=el; } }
				}
				 open={ this.state.xfilterDialogOpen }
				 onOK={ this.onExpressionFilterDialogOK.bind(this) }
				 onCancel={ this.onExpressionFilterDialogCancel.bind(this) }
				 layer={ this.state.xfilterDialogTargetLayer } />
				 <SettingExportDialog ref={ this.refSettingExportDialog.bind(this) }
				 	open={ !!this.state.settingExContent }
					content={ this.state.settingExContent }
					annotation={ this.state.settingExAnnotation }
					layer={ this.state.settingExLayer }
					onCloseClick={ this.onLayerExportClose.bind(this) } />
				 <InterpolationLimitDialog
				    ref={ this.setInterpolationLimitDialogRef.bind(this) }
				 	onCloseClick={ this.onInterpolationLimitDialogClose.bind(this) }
				 	value={ false }
				 	open={ this.state.interpolationLimitDialogOpen } />
			</div>
		);
	}

	// >".

	setInterpolationLimitDialogRef(el) {
		if (el) {
			this.intpDialogComponent = el;
		}
	}

	setLoadDialogRef(component) {
		this.loadDialogComponent = component;
		if (!this.firstDialogShown && component) {
			//this.onLoadButtonClick(null);
			this.firstDialogShown = true;
		}
	}

	generateSideArea() {
		if (!this.state.showSideBar)
			 return '' ; 
		else return <Grid item xs={4} id="mm-sidebar-root">
			<div id="mm-sidebar-non-list-area">
				<WelcomeCard onLogout={ e=>this.onSignOut() }
					 onChevronClick={ e=>this.onCollapseChevronClick() }
					 ref={ el=>this.welcomeCatdComponent=el }
					 showWelcome={ this.state.showWelcome }
					 login={ this.state.login } />
					{ this.generateGunctionBar() }
			</div>
			<LayerList
				 initialList={ this.state.currentProject.layerList }
				selectedId={ this.state.listSelectedId }
				onErrorDismiss={ this.onErrorDismiss.bind(this) }
				onLayerDeleteClick={ this.onLayerDeleteClick.bind(this) }
				onExpressionFilterClick={ this.onExpressionFilterClick.bind(this) }
				onExpressionClearClick={ this.onExpressionClearClick.bind(this) }
				onLayerAttrGen={ this.onLayerAttrGen.bind(this) }
				onLayerExportSetting={ this.onLayerExportSetting.bind(this) }
				onLayerVisibilityClick={ this.onLayerVisibilityClick.bind(this) }
				onAnnotationRevealClick={ this.onAnnotationRevealClick.bind(this) }
				onAnnotationLinkClick={ this.onAnnotationLinkClick.bind(this) }
				onAnnotationSelectButtonClick={ this.onAnnotationSelectButtonClick.bind(this) }
				onLayerSelectRequest={ this.onLayerSelectRequest.bind(this) }
				requestPanelToggle={ this.onLayerPanelToggleRequest.bind(this) }
				requestAnnotationToggle={ this.onLayerAnnotationToggleRequest.bind(this) }
				onTargetLayerChange= { this.onTargetLayerChange.bind(this) }
				onLayerMarkerSizingChange={ this.onLayerMarkerSizingChange.bind(this) }
				onLayerColoringChange={ this.onLayerColoringChange.bind(this) }
				onTrajectoryOpacityChange={ this.onTrajectoryOpacityChange.bind(this) }
				onMarkerModeChange={ this.onLayerMarkerModeChange.bind(this) }
				onLayerMarkerDeformChange={ this.onLayerMarkerDeformChange.bind(this) }
				onLayerDirShiftChange={ this.onLayerDirShiftChange.bind(this) }
				onGridModeChange={ this.onLayerGridModeChange.bind(this)  }
				onLayerMarkerVariationChange={ this.onLayerMarkerVariationChange.bind(this) }
				onLayerMarkerSizeVariationChange={ this.onLayerMarkerSizeVariationChange.bind(this) }
				onBindAttrChange={ this.onLayerBindAttrChange.bind(this) }
				onLegendSwitchChange={ this.onLegendSwitchChange.bind(this) }
				onColoringMaxChange={ this.onLayerColoringMaxChange.bind(this) }
				onMarkerSelDispClick={ this.onLayerMarkerSelDispClick.bind(this) }
				onTrajectorySelDispClick={ this.onLayerTrajectorySelDispClick.bind(this) }
				onSelectionCancelClick={ this.onSelectionCancelClick.bind(this) }
				onSwapLayersRequest={ this.onSwapLayersRequest.bind(this) }
				onLayerGenGridClick={ this.onLayerGenGridClick.bind(this) }
				onTrajectoryVisibilityChange={ this.onTrajectoryVisibilityChange.bind(this) }
				onTrajectoryColoringRuleToggle={ this.onTrajectoryColoringRuleToggle.bind(this) }
				onTrajectoryColorChange={ this.onTrajectoryColorChange.bind(this) }
				onGridPresetChange={ this.onGridPresetChange.bind(this) }
				onValueLabelChange={ this.onValueLabelChange.bind(this) }
				onKeepShowChange={ this.onKeepShowChange.bind(this) }
				onInterpolationChange={ this.onInterpolationChange.bind(this) }
				onTailModeChange={ this.onTailModeChange.bind(this) }
				onTailDurationClick={ this.onTailDurationClick.bind(this) }
				ref={ e=>this.llComponent=e } />

			<div id="mm-credits"><a target="_blank" href="/tou.html">{ get_i18n('ToS') }</a> | <a target="_blank" href="/pp.html">{ get_i18n('PrivacyPolicy') }</a> | <a target="_blank" href="/doc/">{ get_i18n('Docs') }</a></div>
		</Grid> ;		
	}
	
	generateGunctionBar() {
		var largeButtonClass = '';
		if (this.state.firstVisit) {
			largeButtonClass = 'mm-with-first-visit';
		}
		
		return this.state.showWelcome ? (
			<div style={ {textAlign:'center'} }>
				<Button id="mm-top-add-button" className={ largeButtonClass } onClick={ e => this.onLoadButtonClick(e) } startIcon={ <AddIcon /> }>{ get_i18n('Add') }</Button>
				<br /><div className="mm-top-add-button-help" ref={ this.refHelpAnchor.bind(this) }> <HelpOutlineIcon fontSize="small" onClick={ this.onLoadHelpClick.bind(this) } /> </div>
				<Popover className="mm-first-visit-popover" open={ !!this.state.loadHelpAnchor }
					anchorEl={ this.state.loadHelpAnchor }
					onClose={ this.onLoadHelpClose.bind(this) }
					// container={ ()=>{ return this.helpAnchorElement } }
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}} >
					<strong data-test-id="GetStarted">⬆︎<br />{ get_i18n('GetStarted') }</strong>{ get_i18n('ClickAdd') }
				</Popover>
			</div>
		) : (<div className="mm-small-top-bar">
				<Button id="mm-small-add-button" onClick={ e => this.onLoadButtonClick(e) } startIcon={ <AddIcon /> }>{ get_i18n('Add') }</Button>
			</div> );
	}

	componentDidMount() {
		this.adjustSubComponentHeight();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		this.adjustSubComponentHeight();
	}
	
	adjustSubComponentHeight() {
		var h = 0;
		const el = document.getElementById('mm-sidebar-non-list-area');
		if (el) { h = el.clientHeight; }
		
		if (this.llComponent) {
			this.llComponent.setExclusionHeight(h);
		}
	}

	onContentPaneRender(c) {
		this.cpComponent = c;
		if (c) {
			c.setTargetLayerList( this.state.currentProject.layerList );
		}
	}

	onLoadHelpClick(e) {
		this.setState({ loadHelpAnchor: this.helpAnchorElement, firstHelpShown: true });
	}
	onLoadHelpClose() {
		this.setState({ loadHelpAnchor: null });
	}
	refHelpAnchor(el) {
		if (el) {
			this.helpAnchorElement = el;
			if (this.state.firstVisit && !this.state.firstHelpShown) {
				setTimeout( () => { this.onLoadHelpClick(null); } , 300);
			}
		}
	}
	
	onLoadButtonClick(e) {
		mark_visit_flag();
		this.loadDialogComponent.init().open();
	}

	onFullLoadButton(e) {
		this.loadDialogComponent.close();
		this.loadDialogComponent.sendCSVLoaderOption();
		this.loadDialogComponent.sendAttributeMapping();
		this.loadDialogComponent.pendingLayer.countAndLoad();
	}

	onRemoteLoad(e) {
		this.loadDialogComponent.close();
		const u = this.loadDialogComponent.getRemoteBaseURL();

		const layer = this.addMovingObjectLayer();
		const loader = layer.creteRemoteLoader(null, false);

		loader.setBaseURL(u);
		loader.initialFetch(layer).then( () => {
			console.log("OK");
		} );
	}
	
	didLocalMovementCSVSelect(dialog, file) {
		const isZip = (file.type && (file.type.indexOf('zip') >= 0)) || isMMZ(file);

		const layer = this.addMovingObjectLayer();
		dialog.pendingLayer = layer;
		
		const loader = layer.createLoader(file, isZip);
		loader.previewHandler = function(previewLines) {
			if (previewLines) {
				dialog.showMovingDataCSVPreview(previewLines);
			} else {
				dialog.fullLoadReady();
			}

			dialog.cancelDragEffect();
		};

		loader.readFile(file);
		this.updateSelectedIdState();
	}
	
	
	on_LayerListChange(layerList) {
		this.updateLayerListState();
	}
	
	on_LayerTitleChange() {
		this.updateLayerListState();
	}
	
	on_LayerIdCountProgressChange() {
		this.updateLayerListState();
	}
	
	on_LayerLoadProgressChange() {
		this.updateLayerListState();
	}

	on_LayerLoadFinish(layer) {
		this.setState({ showCover:false });
		this.forceRedraw();

		if (this.isOnlyOneFirstLayer()) {
			if (layer.hasMovingData()) {
				const md = layer.data;
				const lat = md.calcLatCenter();
				const lng = md.calcLngCenter();
				if (this.cpComponent) {
					this.cpComponent.moveMap(lat, lng);
				}
			}
		}

		if (this.loadDialogComponent) {
			this.loadDialogComponent.pendingLayer = null;
		}
	}
	
	on_LayerAnnotationChange(layer, annotation) {
		this.renewLayerListComponent();
		this.forceRedraw();
	}

	isOnlyOneFirstLayer() {
		const pj = this.getCurrentProject();
		if (!pj) { return false; }
		return pj.layerList.count() < 2;
	}
	
	on_ProjectTimeRangeChange(project, timeRange) {
		if (this.cpComponent) {
			this.cpComponent.setTimeRange(timeRange);
		}
	}

	on_RemainModeChange(selfLayer) {
		this.forcePickAndRedraw( selfLayer.layerId );
		this.renewLayerListComponent();
	}

	forceRedraw() {
		const ts = this.getTimeSliderComponent();
		if (ts) {
			this.redraw( ts.getCurrentTime(), true );
		}
	}

	renewLayerListComponent() {
		if (this.llComponent) {
			this.llComponent.renew( this.state.currentProject.layerList );
		}
	}

	updateLayerListState() {
		this.renewLayerListComponent();
		
		this.state.showWelcome = this.isLayerListEmpty();
		this.setState(this.state);
	}

	isLayerListEmpty() {
		return  this.getCurrentProject().layerList.count() < 1;
	}

	afterTimeSliderTimeChange(timeSlider, newTime) {
		this.redraw(newTime);
	}


	referControlBar() {
		if (this.cpComponent) {
			return this.cpComponent.getControlBar();
		}
		return null;
	}

	onGenerateGrid(initialTargetId) {
		var iid = null;
		if (0 === initialTargetId || initialTargetId) {
			iid = initialTargetId;
		}
		
		this.loadDialogComponent.close();
		this.addGenerativeGridLayer(iid);
		this.forceRedraw();

		this.updateSelectedIdState();
	}

	// AutoPlay
	onPlayClick(e) {
		const cb = this.referControlBar();
		if (cb) {
			const old = this.playControl.isPlaying();
			const speed = cb.getSelectedSpeed();

			this.playControl.setSpeed(old ? 0 : speed);
			cb.setPlayButtonState(!old);
		}
	}

	autoplayAdvance(step) {
		const cb = this.referControlBar();
		if (cb) {
			const ts = cb.getTimeSlider();
			if (ts) {
				const shouldStop = ts.advanceCurrentTime(step);
				if (shouldStop) {
					this.playControl.setSpeed(0);
					cb.setPlayButtonState(false);
				}
			}
		}
	}

	afterSpeedSliderChange(sender) {
		const cb = this.referControlBar();
		if (cb) {
			if (this.playControl.isPlaying()) {
				this.playControl.setSpeed( cb.getSelectedSpeed() );
			}
		}
	}

	// layer-list
	layerById(id) {
		const pj = this.getCurrentProject();
		if (!pj) { return null; }
		return pj.layerList.byId(id);
	}
	
	onTargetLayerChange(e, selfId, targetId) {
		const selfLayer = this.layerById(selfId);
		selfLayer.setTargetId(targetId, true);
	}
	
	on_LayerTargetChange(selfLayer, targetId) {
		this.renewLayerListComponent();
	}
	
	on_LayerFileError(selfLayer, errorMessage) {
		if (this.loadDialogComponent) {
			this.loadDialogComponent.setState({errorMessage: errorMessage});
		}
		
		this.deleteLayer(selfLayer);
	}
	
	// Grid configuration handling -|-|-|-|-|-|-|-|
	onGridPresetChange(selfId, newValue) {
		const layer = this.layerById(selfId);
		if (layer && layer.setGridPreset) {
			layer.setGridPreset( newValue );
		}
	}

	onValueLabelChange(selfId, newValue) {
		const layer = this.layerById(selfId);
		if (layer && layer.setValueLabelVisible) {
			layer.setValueLabelVisible(newValue);
		}
	}
	
	on_GridLabelChange(layer, newValue) {
		this.forceRedraw();
		this.renewLayerListComponent();
	}

	on_LayerGridPresetChange(layer, newPresetName) {
		this.forceRedraw();
		this.renewLayerListComponent();
	}


	on_LayerMarkerSizingChange(layer, newSizingType) {
		this.forceRedraw();
		this.renewLayerListComponent();
	}
	
	on_LayerColoringChange(layer, newColoringKey) {
		this.renewLayerListComponent();
		this.forceRedraw();
	}

	on_MarkerModeChange(sender) {
		this.renewLayerListComponent();
		this.forceRedraw();
	}
	
	onLayerGridModeChange(selfId, newValue) {
		const selfLayer = this.layerById(selfId);
		if (selfLayer.setGridMode) {
			selfLayer.setGridMode(newValue);
		}
	}
	
	on_LayerGridModeChange(sender) {
		this.renewLayerListComponent();
		this.forceRedraw();
	}

	on_MarkerDeformChange(markerSetting) {
		this.renewLayerListComponent();
		this.forceRedraw();
	}

	on_DirShiftChange(senderLayer) {
		this.renewLayerListComponent();
		this.forceRedraw();
	}

	on_MarkerPrimaryVariationChange(markerSetting) {
		this.renewLayerListComponent();
		this.forceRedraw();
	}
	
	on_MarkerSecondaryVariationChange(markerSetting) {
		this.renewLayerListComponent();
		this.forceRedraw();
	}

	onLayerColoringMaxChange( selfId, newValue ) {
		const selfLayer = this.layerById(selfId);
		if (selfLayer.setValueColoringMax) {
			selfLayer.setValueColoringMax(newValue);
		}

		this.renewLayerListComponent();
	}

	on_LayerColoringMaxChange(layer, newValue) {
		this.forceRedraw();
	}


	on_LayerSelectionAppearanceChange(senderLayer, newFlags) {
		this.renewLayerListComponent();
		this.forceRedraw();
	}


	on_LayerSelectionTrajectoryAppearanceChange(senderLayer, newFlags) {
		if (this.cpComponent) {
			this.cpComponent.markSelectionDispDirty( senderLayer.layerId );
		}
		this.renewLayerListComponent();
		this.forceRedraw();
	}

	// ---------------------------------------------------

	onLayerBindAttrChange(selfId, selectorIndex, attributeIndex) {
		const selfLayer = this.layerById(selfId);
		if (0 === selectorIndex && selfLayer.markerSetting) {
			selfLayer.markerSetting.markOnlyColoringDirty();
		}

		if (selfLayer.setBindAttrIndex) {
			selfLayer.setBindAttrIndex(attributeIndex, selectorIndex);
		}
	}
	
	on_LayerBindAttrChange(layer, newAttrIndex) {
		this.renewLayerListComponent();
		this.forceRedraw();
	}

	onLegendSwitchChange(selfId, selectorIndex, e, newValue) {
		const selfLayer = this.layerById(selfId);
		if (selfLayer && selfLayer.setLegendVisible) {
			selfLayer.setLegendVisible(selectorIndex, newValue);
		}
	}

	on_LayerLegendVisibilityChange(layer, changedIndex) {
		if (layer.markerSetting) {
			layer.markerSetting.markDirty();
		}
		this.renewLayerListComponent();
		this.forceRedraw();
	}

	// APP FUNCTIONS
	getCurrentProject() {
		return this.state.currentProject;
	}
	
	getSelectedLayerId() {
		const pj = this.getCurrentProject();
		if (!pj) { return -1; }

		return pj.selectedLayerId;
	}
	
	getSelectedLayer() {
		const pj = this.getCurrentProject();
		if (!pj) { return -1; }

		return pj.layerList.byId( pj.selectedLayerId );
	}

	getTimeSliderComponent() {
		if (this.cpComponent) {
			return this.cpComponent.getTimeSlider();
		}
		return null;
	}

	addMovingObjectLayer() {
		return this.getCurrentProject().addMovingObjectLayer();
	}
	
	addGenerativeGridLayer(initialTargetId) {
		return this.getCurrentProject().addGenerativeGridLayer(initialTargetId);
	}
	
	// invoked from layer menu
	onLayerGenGridClick(selfId) {
		this.addGenerativeGridLayer(selfId);
		this.forceRedraw();
//		this.loadDialogComponent.init().open( LOADTYPE.GGEN , selfId );
	}
	
	onCaptureTestClick() {
		objectURL(window).then(function(e) {
			console.log(e)
		});
	}

	onLayerDeleteClick(selfId) {
		this.layerAboutToDelete = this.layerById(selfId);
		if (this.layerAboutToDelete) {
			this.setDeleteDialogShown(true);
		}
	}

	// Generate attribute dialog =================
	onLayerAttrGen(selfId) {
		const layer = this.layerById(selfId);
		this.setState({
			attrGenDialogOpen: true,
			attrGenDialogTarget: layer
		});

		if (this.attrGenDialogComponent) {
			this.attrGenDialogComponent.initStates();
		}
	}

	onAttrGenDialogExecute() {
		if (this.attrGenDialogComponent) {
			const layer = this.state.attrGenDialogTarget;
			const attrName = this.attrGenDialogComponent.getAttrName();
			const intMode = this.attrGenDialogComponent.getIntChecked();
			const speedFactor = this.attrGenDialogComponent.getSpeedFactor();
			if (layer.data && layer.data.ensureDerivedSpeed) {
				layer.data.ensureDerivedSpeed(attrName, speedFactor , intMode);
				layer.rebuildOrderedAttributeNames();
				this.renewLayerListComponent();

				this.attrGenDialogComponent.setDone();
			}
		}
	}

	onAttrGenDialogClose() {
		this.setState({
			attrGenDialogOpen: false,
			attrGenDialogTarget: null
		});
	}

	// Setting export ------------------------------------
	refSettingExportDialog(c) {
		if (c) {
			this.xdlgComponent = c;
		}
	}

	onLayerExportSetting(selfId) {
		if (this.xdlgComponent) {
			this.xdlgComponent.reset();
		}

		const layer = this.layerById(selfId);
		const j = generateLayerSettingJSON(layer);
		const aj = generateLayerAnnotationJSON(layer);

		this.setState( { settingExContent: j,  settingExAnnotation: aj , settingExLayer: layer } );
	}

	onLayerExportClose() {
		this.setState( { settingExContent: null, settingExAnnotation: null, settingExLayer: null } );
	}

	onInterpolationLimitDialogClose(sender) {
		this.updateInterpolationLimit(this.state.interpolationLimitDlgLayer, sender);

		this.setState({
			interpolationLimitDialogOpen: false,
			interpolationLimitDlgLayer: null
		});
	}

	updateInterpolationLimit(layer, dialog) {
		if (layer && dialog && layer.setInterpolationLimit) {
			const threshold = dialog.getThresholdValue();
			const appearance = dialog.getAppearanceType();

			let limitType = 0;
			if (dialog.getLimitTime()) {
				limitType = InterpolationLimitType.Time;
			} else if (dialog.getLimitDistance()) {
				limitType = InterpolationLimitType.Distance;
			}

			layer.setInterpolationThreshold(threshold);
			layer.setInterpolationAppearance(appearance);
			layer.setInterpolationLimit(limitType, true);
		}
	}

	on_InterpolationLimitChanged(e) {
		this.forceRedraw();
	}

	// Expression filter dialog ==========================
	onExpressionClearClick(layerId) {
		const layer = this.layerById(layerId);
		layer.setFilterExpression(null);

		this.forcePickAndRedraw(layerId);
		this.renewLayerListComponent();
	}

	onExpressionFilterClick(layerId) {
		const layer = this.layerById(layerId);
		if (layer) {
			layer.rebuildOrderedAttributeNames();
			const dlg = this.xfilterDialogComponent;
			if (dlg) {
				dlg.setState(
					dlg.generateExistingExpressionState(layer)
				);
			}

			this.setState({
				xfilterDialogOpen: true,
				xfilterDialogTargetLayer: layer
			});
		}
//		console.log("%c*"+layerId, "color:#CD0");
	}

	onExpressionFilterDialogOK() {
		if (this.xfilterDialogComponent) {
			const x = this.xfilterDialogComponent.generateExpression();
			const layer = this.state.xfilterDialogTargetLayer;
			layer.setFilterExpression(x);

			this.forcePickAndRedraw( layer.layerId );
		}

		this.setState({ xfilterDialogOpen: false });
	}

	onExpressionFilterDialogCancel() {
		this.setState({ xfilterDialogOpen: false });
	}


	onLayerVisibilityClick(selfId) {
		const selfLayer = this.layerById(selfId);
		selfLayer.setVisibility( !selfLayer.visibility );
	}
	
	on_LayerVisibilityChange(senderLayer, newValue) {
		this.renewLayerListComponent();
		this.forceRedraw();
	}
	
	onLayerSelectRequest( listItem , selfId ) {
		this.getCurrentProject().selectById(selfId);
	}
	
	on_ProjectLayerSelectionChange(senderLayer, layerId) {
		this.updateSelectedIdState();
	}
	
	onLayerPanelToggleRequest(selfId) {
		const selfLayer = this.layerById(selfId);
		if (selfLayer) {
			selfLayer._configOpen = !(selfLayer._configOpen);
			this.renewLayerListComponent();
		}
	}

	onLayerAnnotationToggleRequest(selfId) {
		const selfLayer = this.layerById(selfId);
		if (selfLayer) {
			selfLayer._annOpen = !(selfLayer._annOpen);
			this.renewLayerListComponent();
		}
	}

	onAnnotationRevealClick(e, annFeature) {
		// location
		const c = annFeature['.center'];
		if (c) {
			if (this.cpComponent) {
				this.cpComponent.moveMap(c.lat, c.lng);
			}
		}

		// time
		if (annFeature.properties && annFeature.properties.hasOwnProperty('begin-time')) {
			const cb = this.referControlBar();
			if (cb) {
				const ts = cb.getTimeSlider();
				ts.setCurrentTime( annFeature.properties['begin-time'] );
			}
		}
	}

	onAnnotationLinkClick(e, annFeature) {
		if (annFeature.properties && annFeature.properties.url) {
			window.open(annFeature.properties.url, "mobmap-annotation-url");
		}
	}
	
	onAnnotationSelectButtonClick(layerId, annFeature) {
		const layer = this.layerById(layerId);
		const sel = layer.selection;
		const pr = annFeature.properties;

		if (sel && pr && pr.hasOwnProperty('traceId')) {
			sel.clear();
			sel.selectId( ''+pr.traceId );
			sel.fire();
		}
	}

	updateSelectedIdState() {
		const s = this.state;
		s.listSelectedId = this.getSelectedLayerId();
		this.setState(s);
	}
	
	on_SelectionChange(selection) {
		if (selection.owner && this.cpComponent) {
			this.cpComponent.markPickPoolDirty( selection.owner.layerId );
			this.cpComponent.markSelectionDispDirty( selection.owner.layerId );
		}

		this.renewLayerListComponent();
		this.forceRedraw();
	}
	
	onSelectionCancelClick(selfId, e) {
		const selfLayer = this.layerById(selfId);
		const sel = selfLayer.referSelection();
		if (sel) {
			sel.clear();
			sel.fire();
		}
	}
	
	showProcessing(b) {
		const s = this.state;
		s.showProcessing = b;
		if (!b) {
			s.progressValue = 0;
		}
		this.setState(s);
	}

	setProgressValue(v) {
		/*
		const s = this.state;
		s.progressValue = v;
		this.setState(s);
		*/
	}

	// Trajectory
	on_TrajectoryBusyChange(senderLayer) {
		this.renewLayerListComponent();
	}

	on_TrajectoryVisibilityChange(senderLayer, newValue) {
		this.forceRedraw();
	}
	
	onTrajectoryColoringRuleToggle(layerId, newValue) {
		const layer = this.layerById(layerId);
		if (layer && layer.setTrajectoryColoringRule) {
			layer.setTrajectoryColoringRule(newValue);
		}
	}

	on_TrajectoryColoringRuleChange(senderLayer, newValue) {
		this.forceRedraw();
		this.renewLayerListComponent();
	}

	on_TrajectoryColorChange(senderLayer, newValue) {
		this.forceRedraw();
		this.renewLayerListComponent();
	}

	on_TrajectoryOpacityChange(senderLayer, newValue) {
		this.forceRedraw();
		this.renewLayerListComponent();
	}

	// TAIL
	on_TailModeChange(senderLayer, newValue) {
		this.forcePickAndRedraw( senderLayer.layerId );
		this.renewLayerListComponent();
	}

	on_TailDurationChange(senderLayer, newValue) {
		this.forcePickAndRedraw( senderLayer.layerId );
		this.renewLayerListComponent();
	}

	// SWAP
	onSwapLayersRequest(upperIndex) {
		const pj = this.getCurrentProject();
		pj.layerList.swapLayers(upperIndex);
		this.renewLayerListComponent();
		
		if (this.cpComponent) {
			this.cpComponent.invalidateRenderOrder();
			this.forceRedraw();
		}
	}

	// DELETE DIALOG
	setDeleteDialogShown(v) {
		const s = this.state;
		s.confirmingLayerDelete = v;
		this.setState(s);
	}

	onDeleteDialogOK() {
		this.deleteLayer(this.layerAboutToDelete);
		this.setDeleteDialogShown(false);
	}
	
	on_LayerCancelled(layer) {
		this.deleteLayer(layer);
	}

	deleteLayer(layer) {
		const pj = this.getCurrentProject();
		layer.notifyRemoval();
		pj.layerList.remove(layer);
		this.renewLayerListComponent();
	}

	onDeleteDialogCancel() {
		this.layerAboutToDelete = null;
		this.setDeleteDialogShown(false);
	}
	
	// Collapse side bar
	contentWillResized() {
		if (this.cpComponent) {
			this.cpComponent.setSizeDirty();
		}
	}
	
	onCollapseChevronClick() {
		this.contentWillResized();
		this.setState({ showSideBar: false, showCover: false });
	}
	
	onMenuButtonClick() {
		this.contentWillResized();
		this.setState({ showSideBar: true });
	}

	forcePickAndRedraw(layerId) {
		if (this.cpComponent) {
			this.cpComponent.markPickPoolDirty( layerId );
			this.forceRedraw();
		}
	}

	on_LayerHasError(layer) {
	}

	onErrorDismiss(layerId) {
		const layer = this.layerById(layerId);
		if (layer) {
			layer.clearDataError();
		}
	}

	on_LayerErrorCleared() {
		this.renewLayerListComponent();
	}
}

addGateFunctions(App);
addPanelHandlers(App);
addCaptureFunctions(App);

function isMMZ(file) {
	if (!file.name) { return false; }
	return file.name.endsWith('.mmz');
}

export default App;
