import React from 'react';
import { Component } from 'react';

const tempRGB = [0,0,0];

class ColoringItem extends Component {
	constructor(props) {
		super(props);
		this.canvasElement = null;

		this.previewWidth = 128;
		this.previewHeight = 16;
		
		this.tileSize = 10;
		this.padding = window.devicePixelRatio * 4;
	}

	render() {
		return ( <div className="mm-color-selector-item"><canvas ref={ this.onCanvasRendered.bind(this) }></canvas><br />{ this.props.title }</div> );
	}

	onCanvasRendered(e) {
		this.canvasElement = e;
		if (e) {
			const r = window.devicePixelRatio;
		
			e.width = this.previewWidth*r;
			e.height = this.previewHeight*r;
			e.style.width  = this.previewWidth + 'px';
			e.style.height = this.previewHeight + 'px';
			
			this.redraw();
		}
	}

	redraw() {
		const cv = this.canvasElement;
		if (cv) {
			const g = cv.getContext('2d');
			const generator = this.props.generator;

			const r = window.devicePixelRatio;
			const t = this.tileSize * r;
			
			const canvasWidth = cv.width - 0;
			const validWidth = canvasWidth - this.padding*2;
			const numTiles = Math.floor(validWidth / t);
			
			const entireWidth = t * numTiles;
			const actualPadding = (canvasWidth - entireWidth) >> 1;
			
			var x = actualPadding;
			const baseY = (cv.height - t) >> 1;
			g.fillStyle = '#888';
			for (var i = 0;i < numTiles;++i) {
				if (generator) {
					generator.getRGB(tempRGB, i / (numTiles-1));
					g.fillStyle = 'rgb(' +tempRGB.join(',') +')';
				}
				
				g.fillRect(x, baseY, t-r, t);
				g.save();
					g.fillStyle = 'rgba(0,0,0,0.5)';
					g.fillRect(x      , baseY    , r    , t);
					g.fillRect(x+t-r-r, baseY    , r    , t);
					g.fillRect(x+r    , baseY    , t-r*3, r);
					g.fillRect(x+r    , baseY+t-r, t-r*3, r);
				g.restore();
				x += t;
			}
		}
	}
}

export default ColoringItem;