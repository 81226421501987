import React from 'react';
import { Component } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Measure from "react-measure";

import Select from '@material-ui/core/Select';
import ColoringItem from './ColoringItem';
import MenuItem from '@material-ui/core/MenuItem';
import get_i18n from '../Internationalization';

// test
// import HueColorGenerator from '../marker/HueColorGenerator'
// import BlendColorGenerator from '../marker/BlendColorGenerator'

class ColoringSelector extends Component {
	render() {
		return ( <div>
			<InputLabel shrink>{ get_i18n('ColoringRule') }</InputLabel>
			<Measure bounds onResize={ this.onResize.bind(this) }>
				{
					({ measureRef }) => (
						<div ref={ measureRef }></div>
					)
				}
			</Measure>
			<Select value={ this.props.value } onChange={ this.onColoringChange.bind(this) }>
				{ this.generateItems() }
			</Select>
		</div>)
	}
	
	generateItems() {
		var retComponents = [];
		const ls = window.GlobalColorPreset;
		for (var c of ls) {
			retComponents.push(
				 <MenuItem key={ c.name } value={ c.name }><ColoringItem title={ c.title } generator={ c } /></MenuItem>
			);
		}
		
		return retComponents;
	}
	
	onResize(e) {
//		console.log(e, "RESIZE");
	}

	onColoringChange(e,f) {
		const val = f.props.value;
		if (this.props.onColoringChange) {
			this.props.onColoringChange(val);
		}
	}
}

export default ColoringSelector;
