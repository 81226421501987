import React from 'react';
import { Component } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';

import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

import '../App.css';

class WelcomeCard extends Component {
	constructor(props) {
		super(props);
		this.anchorRef = null;

		this.state = {
			menuAnchor: null
		};
	}
	
	render() {
		const uname = this.props.login ? this.props.login.displayName : null;
		const white_style = { color: '#fff' };

/*
			<span className="mm-login-display">{ uname || '' }</span>
		     <IconButton size="small" onClick={this.onUserClick.bind(this)}
			    ref={ el => this.anchorRef=el }>
			{
				uname ? <AccountCircleIcon fontSize="small" style={white_style} /> 
				      : <AccountCircleOutlinedIcon fontSize="small" style={white_style} />
			}
			</IconButton>
*/

	    return (<div className="mm-welcome-pane" id="mm-welcome-bar">
			<div className="mm-app-head">
				<h1><img alt="Mobmap" src="images/lmm-logo-2.png" width="200" height="32" /></h1>
				<div>
		    
					<IconButton size="small" onClick={ this.onChevronClick.bind(this) }>
						<CloseIcon style={white_style}  fontSize="small" />
					</IconButton>
				</div>
			</div>

			<Menu keepMounted onClose={ this.forceCloseMenu.bind(this) }
			 transformOrigin={ {
			          vertical: 'top',
			          horizontal: 'center'
			        } }
			 anchorEl={ this.state.menuAnchor } open={ Boolean(this.state.menuAnchor) }>
				<div className="mm-login-popup">
					{
						uname ? (<div><p>Already logged in.</p>
						<Button onClick={ this.onLogoutButton.bind(this) } color="secondary">log out</Button></div>) : 
						(<div><p>Use Google account to log in</p>
						<div className="g-signin2" data-onsuccess="onSignIn"></div> </div>)
					}
				</div>
			</Menu>

	        {
				(this.props.showWelcome && false) ?
				<Card className="mm-welcome-card">Welcome to Mobmap</Card> :
				''
			}
	    </div>);		
	}
	
	onChevronClick() {
		if (this.props.onChevronClick) {
			this.props.onChevronClick();
		}
	}
	
	onUserClick() {
		const s = this.state;
		s.menuAnchor = s.menuAnchor ? null : this.anchorRef;

		this.setState(s);
	}
	
	forceCloseMenu() {
		const s = this.state;
		s.menuAnchor = null;
		this.setState(s);
	}
	
	onLogoutButton() {
		const that = this;
		const auth2 = window.gapi.auth2.getAuthInstance();
		auth2.signOut().then(function() {
			that.forceCloseMenu();
			if (that.props.onLogout) {
				that.props.onLogout();
			}
		});
	}
}

export default WelcomeCard;