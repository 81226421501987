import React from 'react';
import { Component } from 'react';

import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';

import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import CloudOutlinedIcon from '@material-ui/icons/CloudOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';

import '../App.css';
import './LoadDialog.css';
import PreviewTable from './PreviewTable'
import { AttributeValueTypeMask , AttributeValueOptionMask } from '../leafmob/md/AttributeType'
import get_i18n from '../Internationalization';

import TEST_MOVING_DATA_SOURCE from '../moving-data/test-mo-data.js'
import { InputLabel, TextField } from '@material-ui/core';
import { mmrInvoke, mmrMakeHomeURL } from '../remote/MMRemoteUtils';
import { kTimeAttrName } from '../leafmob/md/AttributeMapping';

// ============================
const SHOW_TEST_FILE = checkDebugURL();
const SHOW_SERVER_OPTION = false;
// ============================


const SlideTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LOADTYPE = {
	MO:    0,
	GRID:  1,
	GGEN:  2,
	RTSV:  3
};

const INITIAL_STATE = {
	opened: false,
	numHeaderLines: 0,
	previewTableOpened: false,
	previewTableSource: null,
	previewPreparing: true,
	selectedType: LOADTYPE.MO,
	initialTargetId: null,
	errorMessage: null,
	remoteResult: null,
	timeFormat: 0
};
const OPEN_STATE = {
	opened: true,
	numHeaderLines: 0,
	previewTableOpened: false,
	previewTableSource: null,
	previewPreparing: true,
	selectedType: LOADTYPE.MO,
	initialTargetId: null,
	errorMessage: null,
	remoteResult: null,
	timeFormat: 0
};

class LoadDialog extends Component {
	constructor(props) {
		super(props);
		this.ptComponent = null;
		this.pendingLayer = null;
		this.state = INITIAL_STATE;
		
		this.topElement = null;
		this.refTxRemoteURL = null;
	}

	init() {
		if (this.localCSVInputElement) {
			this.unloadInput();
		}
		
		if (this.localCSVInputContainer) {
			this.localCSVInputContainer.innerHTML = '';
		
			const input = document.createElement('input');
			input.type = 'file';
			input.accept = 'text/csv,application/zip, .mmz, .csv, .zip';
			this.localCSVInputElement = input;
			this.localCSVInputContainer.appendChild(input);
			
			input.addEventListener('change', this.onLocalMovementCSVInputChange.bind(this), false);
		}
		
		return this;
	}
	
	unloadInput() {
		this.localCSVInputElement = null;
	}

	open(initialPage, param1) {
		var st = {};
		for (var i in OPEN_STATE) if(OPEN_STATE.hasOwnProperty(i)) {
			st[i] = OPEN_STATE[i];
		}
		
		if (initialPage) {
			st.selectedType = LOADTYPE.GGEN;
			st.initialTargetId = param1;
		}

		this.setState( st );
	}
	close() {
		this.setState( { opened: false } );
	}

	render() {
		return ( <Dialog
				fullWidth={true}
				open={ this.state.opened }
				ref={ e=> this.refTopElement(e) }
				className="mm-fullwidth-dialog mm-loader-dialog"
			>
			<AppBar className="mm-std-appbar" style={ {position: 'relative'} }><Toolbar>
				<IconButton edge="start" color="inherit" onClick={ e=> this.handleClose(e) } aria-label="close">
					<CloseIcon />
				</IconButton>
				<Typography variant="h5">
				 { get_i18n('AddData') }
				</Typography>
			</Toolbar></AppBar>

			<div className="mm-loader-dialog-body">
				{ this.renderTypeSelector()  }
				{  this.renderLoaderPage()  }
			</div>
			

		</Dialog> );
	}

	renderTypeSelector() {
		const ENABLE_R = SHOW_SERVER_OPTION;

		/*
				<Button onClick={ e=>this.onTypeClick(LOADTYPE.GRID) } className={ this.makeButtonVariant(LOADTYPE.GRID) }><AppsSharpIcon /> File</Button>
				<Button onClick={ e=>this.onTypeClick(LOADTYPE.GGEN) } className={ this.makeButtonVariant(LOADTYPE.GGEN) }><AppsSharpIcon /> Generate</Button>
		 */
		return ( <div className="mm-loader-select-row">
			<ButtonGroup disableRipple={true} color="primary" size="small" className="mm-load-type-buttons">
				<Button onClick={ e=>this.onTypeClick(LOADTYPE.MO  ) } className={ this.makeButtonVariant(LOADTYPE.MO  ) }><InsertDriveFileOutlinedIcon /> Local file</Button>
				{ ENABLE_R ? <Button onClick={ e=>this.onTypeClick(LOADTYPE.RTSV) } className={ this.makeButtonVariant(LOADTYPE.RTSV) }><CloudOutlinedIcon /> &nbsp;Server</Button> : '' }
			</ButtonGroup>
		</div> );
	}

	renderLoaderPage() {
		if (this.state.selectedType === LOADTYPE.MO) {
			var test_btn = null;
			if (SHOW_TEST_FILE) {
				test_btn = ( <Button onClick={ this.onTestDataClick.bind(this) } color="secondary">test data</Button> );
			}

			return this.state.previewTableOpened ?
				this.renderPreviewTable()  :
				<Paper elevation={3} className="mm-loader-dialog-content">
					<div className="mm-loader-image-container">
						{ get_i18n('VizMov') }<br />
						<img src="/images/csv-vis.png" width="500" height="160" alt="visualize CSV file" />
					</div>
					<h6>{ get_i18n('SelCSV') }</h6>
					<label className="mm-fileselect-outer" ref={ r=> { this.localCSVInputContainer=r; this.init(); } }></label>
					{ this.renderErrorMessage() }
					{ test_btn }
				</Paper> ;
		} else if (this.state.selectedType === LOADTYPE.RTSV)  {
			return this.renderServerLoadPage();
		} else {
			return ( <Paper elevation={3} className="mm-loader-dialog-content">
				<h6>Configure generative grid</h6>
				<div className="mm-load-exec-area">
					<Button onClick={ this.onGenerateGrid.bind(this) } variant="contained" color="primary">Generate</Button>
				</div>
			</Paper> );
		}
	}
	
	renderServerLoadPage() {
		return (<Paper elevation={3} className="mm-loader-dialog-content">
				<h6>Load from movement data server</h6>
				<div className="mm-load-exec-area">
					<div className="mm-load-form-section">
						<TextField ref={ el => this.setRefTxRemoteURL(el) } label="URL" variant="outlined" fullWidth defaultValue="http://localhost:9821/sample-data/" />
					</div>
					{ this.renderServerLoadResult() }
					<div className="mm-load-form-section">
						{ this.renderServerLoadButton() }
					</div>
				</div>
		</Paper>);
	}

	renderServerLoadButton() {
		const e = this.hasRemoteDatasetError();
		const v = this.hasValidRemoteDataset();
		if (!e && v) {
			return <Button onClick={ this.onRemoteStartButton.bind(this) } variant="contained" color="primary">Start</Button>
		}

		return <Button onClick={ this.onRemoteCheckButton.bind(this) } variant="outlined" color="primary">Check</Button>
	}

	hasRemoteDatasetError() {
		const r = this.state.remoteResult;
		if (r && r.error) { return true; }

		return false;
	}

	hasValidRemoteDataset() {
		const r = this.state.remoteResult;
		return !!(r && r.data && r.data.name);
	}

	renderServerLoadResult() {
		const r = this.state.remoteResult;
		if (!r) { return ''; }

		const has_err = this.hasRemoteDatasetError();
		const stat_cls = has_err ? 'mm-load-form-fetch-error' : 'mm-load-form-fetch-success';

		return ( <div className={ "mm-load-form-section "+stat_cls }>
			{
				has_err ? 'このURLは利用できません' : `✔︎ データセット名: ${r.data ? r.data.name : '?'}`
			}
		</div> );
	}

	setRefTxRemoteURL(el) {
		if (el) {
			this.refTxRemoteURL = el.querySelector('input');
		}
	}

	getRemoteBaseURL() {
		if (!this.refTxRemoteURL.value) { return null; }
		return this.refTxRemoteURL.value;
	}

	onRemoteCheckButton() {
		if (this.refTxRemoteURL) {
			mmrInvoke( mmrMakeHomeURL( this.getRemoteBaseURL() ) ).then( res => {
				this.onRemoteHomeFetch( res );
			});
		}
	}

	onRemoteStartButton() {
		if (this.props.onRemoteLoad) {
			console.log("onRemoteLoad");
			this.props.onRemoteLoad();
		}
	}

	onRemoteHomeFetch(res) {
		this.setState( {remoteResult: res} );
	}

	renderErrorMessage() {
		return this.state.errorMessage ? 
		  (<div className="mm-loader-error">Error: { this.state.errorMessage }</div>)  :
		  '';
	}

	makeButtonVariant(selfType) {
		return this.state.selectedType === selfType ? 'mm-loadtype-selected' : '';
	}

	onTestDataClick() {
		const body = TEST_MOVING_DATA_SOURCE.join("\n");
		const blob = new Blob([body], {type : 'text/plain'});
		this.sendLocalMovementCSV(blob);
		setTimeout( this.onFullLoadButton.bind(this) , 900);
	}

	onTypeClick(selectedType) {
		this.state.selectedType = selectedType;
		this.setState( this.state );
	}

	renderPreviewTable() {
		var hasManifest = false;
		if (this.pendingLayer && this.pendingLayer.loader) {
			hasManifest = !!( this.pendingLayer.loader.manifest );
		}

		return (
			<div>
				<PreviewTable ref={ e => this.ptComponent=e } sourceData={ this.state.previewTableSource } numHeaderLines={ this.state.numHeaderLines } hasManifest={hasManifest} />
				<div className="mm-load-exec-area">
					<FormControl>
						<InputLabel id="time-format-option-label">Time format</InputLabel>
						<Select onChange={ this.onTimeFormatChange.bind(this) }
							value={ this.state.timeFormat } label="Time format" labelId="time-format-option-label" id="mm-time-format-option-box">
							<MenuItem value={0}>Default</MenuItem>
							<MenuItem value={1}>Unixtime(sec)</MenuItem>
							<MenuItem value={2}>Unixtime(ms)</MenuItem>
						</Select>
					</FormControl>&nbsp;
					{ this.renderFullLoadButton() }
				</div>
			</div>
		);
	}
	
	renderFullLoadButton() {
		if (this.state.previewPreparing) {
			return ( <Button className="mm-exec-button-preparing"><CircularProgress size="1rem" />&nbsp;wait...</Button> );
		}

		return ( <Button onClick={this.onFullLoadButton.bind(this)} className="mm-exec-button">{ get_i18n('StartLoading') }</Button> );
	}

	
	handleClose(e) {
		if (this.pendingLayer) {
			this.pendingLayer.cancel();
			this.pendingLayer = null;
		}
		this.close();
	}
	
	onLocalMovementCSVInputChange(e) {
		if (this.localCSVInputElement) {
			const firstFile = this.localCSVInputElement.files[0];
			if (firstFile) {
				this.sendLocalMovementCSV( firstFile );
			}
		}
	}
	
	sendLocalMovementCSV(file) {
		const handler = this.props.didLocalMovementCSVSelect;
		if (handler) {
			handler(this, file);
		}
	}

	fullLoadReady() {
		this.state.previewPreparing = false;
		this.setState(this.state);
	}

	onFullLoadButton() {
		if (this.props.onFullLoadButton) {
			this.props.onFullLoadButton(this);
		}
	}

	onGenerateGrid() {
		if (this.props.onGenerateGrid) {
			this.props.onGenerateGrid(this.state.initialTargetId);
		}
	}

	sendCSVLoaderOption() {
		const lyr = this.pendingLayer;
		if (lyr && this.ptComponent && lyr.loaderSetNumHeaderLines) {
			lyr.loaderSetNumHeaderLines(this.ptComponent.props.numHeaderLines);
		}
	}

	sendAttributeMapping() {
		const timeFlags = this.state.timeFormat;
		const lyr = this.pendingLayer;

		if (lyr && lyr.addAttribute && this.ptComponent) {
			this.ptComponent.eachAttribute(function(columnIndex, info) {
				const tp = info.dataType & AttributeValueTypeMask;
				const op = info.dataType & AttributeValueOptionMask;
				const attrEntry = lyr.addAttribute(info.attrName, columnIndex, tp, false, op);

				if (kTimeAttrName === info.attrName) {
					attrEntry.setFlags(timeFlags);
				}
			});
		}
	}

	// preview
	showMovingDataCSVPreview(previewLines) {
		this.state.numHeaderLines = countHeaderLines(previewLines);

		this.state.previewTableOpened = true;
		this.state.previewTableSource = previewLines;
		this.setState( this.state );
	}
	
	// DnD
	refTopElement(el) {
		this.topElement = el;
		if (el) {
			el.addEventListener('dragover', handleDnD.bind(this, el, 1), false);
			el.addEventListener('dragend', handleDnD.bind(this, el, 0), false);
			el.addEventListener('dragleave', handleDnD.bind(this, el, 0), false);
			el.addEventListener('drop', this.onFileDrop.bind(this, el), false);
		}
	}

	cancelDragEffect() {
		if (this.topElement) {
			this.topElement.setAttribute('data-dnd-accept', 0);
		}
	}

	onFileDrop(element, e) {
		if (this.state.previewTableOpened) { return void(0); }

		e.stopPropagation();
	    e.preventDefault();
		element.setAttribute('data-dnd-accept', 2);
		const f = e.dataTransfer.files[0];
		if (f) {
			this.sendLocalMovementCSV(f);
		}
	}

	onTimeFormatChange(e) {
		const v = parseInt(e.target.value, 10);
		this.setState({timeFormat: v});
	}
}

function countHeaderLines(inLines) {
	var i;
	const re_vals = /[0-9]\.[0-9]/ ;
	for (i = 0;i < inLines.length;++i) {
		if (re_vals.test( inLines[i] )) {
			break;
		}
	}

	return i;
}

function handleDnD(recvElement, attrVal, e) {
	if (this.state.previewTableOpened) { return void(0); }

	e.stopPropagation();
	e.preventDefault();

	if (recvElement) {
		recvElement.setAttribute('data-dnd-accept', attrVal);
	}
}

function checkDebugURL() {
	return window.location.href.indexOf('debug=1') >= 0;
}

export {LOADTYPE};
export default LoadDialog;