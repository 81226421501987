async function mmrInvoke(url) {
	var resJson = null;
	const r = await fetch(url);
	try {
		resJson = await r.json();
	} catch(e) { }

	if (resJson) {
		return {
			error: null,
			data: resJson
		} ;
	} else {
		return {
			error: "Failed to fetch"
		} ;
	}
}

function mmrMakeInitialLoadURL(baseURL) {
	return mmrMakeSlashEnd(baseURL) + 'i';
}

function mmrMakeRealtimeLoadURL(baseURL) {
	return mmrMakeSlashEnd(baseURL) + 'rt';
}

function mmrMakeHomeURL(baseURL) {
	return mmrMakeSlashEnd(baseURL);
}

function mmrMakeSlashEnd(baseURL) {
	if (baseURL.endsWith('/')) {
		return baseURL;
	}

	return baseURL + '/';
}

export { mmrMakeHomeURL, mmrInvoke, mmrMakeInitialLoadURL, mmrMakeRealtimeLoadURL } ;