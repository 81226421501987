import leaflet from 'leaflet'
import ColoringPresetManager from '../model/ColoringPresetManager'
import MarkerSetting from '../model/MarkerSetting'
import MarkerImageGenerator from '../marker/MarkerImageGenerator'

const _tempRGBA = [0,0,0,1];
const L = leaflet;
const MapLegend = L.Control.extend({
	initialize: function() {
		this.legendTitle = null;
		this.boxesArea = null;
		this.markerSetting = new MarkerSetting();

		this.markerSetting.sizingType = -1;
		this.coloringKey = '-';
		this.numVariation = -1;

		this.cellViewWidth = 24;
		this.cellViewHeight = 24;

		this.legendVisibility = false;

		this.dirty = true;
	},

	setCellViewHeight: function(h) {
		this.cellViewHeight = h;
	},

	setLegendTitle: function(t) {
		this.legendTitle = t;
	},

	onAdd: function(map) {
		const div = document.createElement('div');
		div.className = 'mm-map-legend';

		if (this.legendTitle) {
			const h = document.createElement('h4');
			h.appendChild( document.createTextNode(this.legendTitle) );
			div.appendChild(h);
		}

		this.boxesArea = document.createElement('canvas');
		this.boxesArea.width = 25;
		this.boxesArea.height = 16;
		div.appendChild(this.boxesArea);

		div.style.display = 'none';
		return div;
	},

	onRemove: function(map) {	
	},

	setLegendVisibility: function(v) {
		if (this.legendVisibility !== v) {
			this.legendVisibility = v;
			this.dirty = true;            
		}
	}, 

	setNumOfVariation: function(n) {
		if (this.numVariation !== n) {
			this.numVariation = n;
			this.dirty = true;            
		}
	},

    setColoringKey: function(k) {
		if (this.coloringKey !== k) {
			this.coloringKey = k;
			this.dirty = true;            
		}
	},

	setSizingType: function(s) {
		if (this.markerSetting.sizingType !== s) {
			this.markerSetting.sizingType = s;
			this.dirty = true;            
		}
	},

	rebuildIfDirty: function() {
		if (this.dirty && this._map) {
			const mapContainer = this._map.getContainer();
			const cv = this.boxesArea;
			const el = this.getContainer();
			var compact = 1;
			if (el) {
				el.style.display = this.legendVisibility ? 'block' : 'none';
			}
			
			const fullWidth = this.cellViewWidth * this.numVariation + 1;
			const w_limit = mapContainer.clientWidth >> 1;
			
			if (fullWidth > w_limit) { compact = 2; }
			if (fullWidth > (w_limit*2)) { compact = 4; }

			const n = this.numVariation;
			var nBoxes = n + (compact-1);
			this.adjustCanvasSize( cv, Math.floor(nBoxes/compact) );

			const pr = window.devicePixelRatio;
			const boxW = (this.cellViewWidth+1)*pr;
			const boxH = this.cellViewHeight*pr;
	
			const g = cv.getContext('2d');
			g.clearRect(0, 0, cv.width, cv.height);

			var i, x;
			var divs = Math.max(1, n-1);
						
			if (this.markerSetting.sizingType >= 0) {
				x = 0;
				for (i = 0;i < nBoxes;i += compact) {
					const label = (i===divs) ? (i + '+') : i;
					this.drawSizeLegend(g, x, boxW, boxH, label, i / divs);
					x += this.cellViewWidth*pr;
				}
			} else {
				const cg = ColoringPresetManager.find( this.coloringKey );
				if (cg) {
					x = 0;
					for (i = 0;i < nBoxes;i += compact) {
						const label = (i===divs) ? (i + '+') : i;
						cg.getRGB(_tempRGBA, i / divs);
						this.drawLegendBox(g, x, boxW, boxH, label, 'rgba('+_tempRGBA.join(',')+')' );

						x += this.cellViewWidth*pr;
					}
				}
			}

			this.dirty = false;
		}
    },

	drawSizeLegend: function(g, x, w, h, label, pos) {
		const pr = window.devicePixelRatio;
		const cx = x + (w >> 1);
		MarkerImageGenerator.drawSizeLegend(g, cx + pr*0.5, (h >> 1) - pr*5 + pr*0.5, this.markerSetting, pos);
		g.save();

		const sz = pr * 11;
		g.font = "bold " +sz+ "px sans-serif";
		g.textAlign = 'center';

		g.shadowOffsetX = pr*0.5;
		g.shadowColor = '#000';
		g.shadowBlur = pr;

		g.fillStyle = '#000';
		g.fillText(label, cx, h - pr);

		g.fillStyle = '#fff';
		g.fillText(label, cx, h - pr*2);

		g.restore();
	},

    drawLegendBox: function(g, x, w, h, label, color) {
		const pr = window.devicePixelRatio;
		g.save();

		g.fillStyle = '#fff';
		g.fillRect(x, 0, w, h);

		g.fillStyle = color;
		g.fillRect(x+pr, pr, w - pr*2, h - pr*2);

		const sz = pr * 11;
		g.font = "bold " +sz+ "px sans-serif";
		g.textAlign = 'center';

		g.shadowOffsetX = pr*0.5;
		g.shadowColor = '#000';
		g.shadowBlur = pr;
		for (var i = 0;i < 2;++i) {
			const tx = x + (w >> 1);
			const ty = ((sz+h) >> 1) - i*pr;
			g.fillStyle = '#000';
			g.fillText(label, tx, ty);

			g.fillStyle = i ? '#fff' : '#000';
			g.fillText(label, tx, ty);
		}

		g.restore();
	},

	adjustCanvasSize: function(cv, nBoxes) {
		const w = this.cellViewWidth * nBoxes + 1;
		const h = this.cellViewHeight;

		cv.width = w * window.devicePixelRatio;
		cv.height = h * window.devicePixelRatio;

		cv.style.width = w + 'px';
		cv.style.height = h + 'px';
	}
});

export default MapLegend;