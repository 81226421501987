import React from 'react';
import { Component } from 'react';
import List from '@material-ui/core/List';

import LayerListItem from './LayerListItem'
import LayerSwap from './LayerSwap'

import './ListArea.css'
import { invoke_cp_handler } from '../misc-utils'

class LayerList extends Component {
	// SETUP ==============================================================
	constructor(props) {
		super(props);
		this.l_swap = new LayerSwap();

		this.state = {
			itemList: []
		};
		
		if (props.initialList) {
			this.buildItemList(props.initialList);
		}
		
		this.childrenRefs = [];
	}

	renew(sourceList) {
		this.buildItemList(sourceList);		
		this.setState(this.state);
	}
	
	buildItemList(sourceList) {
		var selfList = this.state.itemList;

		selfList.length = 0;
		const len = sourceList.count();
		for (var i = 0;i < len;++i) {
			const src = sourceList.getAt(i);
			const sel = src.referSelection();

			const props = ({
				layer: src,

				selectionCount: sel.count(),
				gridPresetName: null
			});

			if ( src.refersMovingData() ) {
				props.referLayerList = this.makeMOLayerReferenceList(src, sourceList);
				props.gridPresetName = src.gridPresetName;
			}

			selfList.push( props );
		}		
	}

	makeMOLayerReferenceList(selfLayer, layerList) {
		const retList = [];
		
		const len = layerList.count();
		for (var i = 0;i < len;++i) {
			const layer = layerList.getAt(i);
			if (layer === selfLayer) { continue; }
			if (layer.hasMovingData()) {
				retList.push(layer);
			}
		}

		return retList;
	}

	render() {
		this.childrenRefs.length = 0;
		
		return (
			<div id="mm-layer-list-outer">
				<List>
					{ this.renderItems() }
				</List>
			</div>
		);
	}
	
	setExclusionHeight(h) {
		const el = document.getElementById('mm-layer-list-outer');
		if (el) {
			el.style.maxHeight = 'calc(100vh - ' +(h-0)+ 'px)';
		}
	}

	renderItems() {
		const cref = this.childrenRefs;
		var ret = [];

		for (const item of this.state.itemList) {
			const layer = item.layer;
			const colorBind = getBindIndex(layer, 'bindAttrIndex');
			const sizeBind = getBindIndex(layer, 'secondAttrIndex');
			
			var remainMode = null;
			if (layer.getRemainMode) { remainMode = layer.getRemainMode(); }

			let interpolationLimit = null;
			if (layer.data) {
				interpolationLimit = layer.data.interpolationLimit || null;
			}

			var numSizeVariation = 8;
			var numColorVariation = 4;
			var markerSizing = 0;
			var coloringRule = null;
			var markerDeform = 0;
			if (layer.markerSetting) {
				numSizeVariation = layer.markerSetting.secondaryVariation;
				numColorVariation = layer.markerSetting.primaryVariation;
				markerSizing = layer.markerSetting.sizingType;
				coloringRule = layer.markerSetting.coloringKey;
				markerDeform = layer.markerSetting.deform;
			} else {
				if (layer.hasOwnProperty('coloringKey')) {
					coloringRule = layer.coloringKey;
				}
			}

			ret.push(
				<LayerListItem
					ref={ el => { if(el)cref.push(el); } }
				
					 onSelectRequest={ this.onLayerSelectRequest.bind(this) }
					 requestPanelToggle={ this.props.requestPanelToggle }
					 requestAnnotationToggle={ this.props.requestAnnotationToggle }
					onLayerVisibilityClick={ this.onLayerVisibilityClick.bind(this) }
					onLayerDeleteClick={ this.onLayerDeleteClick.bind(this) }
					onExpressionFilterClick={ this.props.onExpressionFilterClick }
					onExpressionClearClick={ this.props.onExpressionClearClick }
					onAttrGen={ this.props.onLayerAttrGen }
					onExportSetting={ this.props.onLayerExportSetting }
					onAnnotationRevealClick={ this.props.onAnnotationRevealClick }
					onAnnotationLinkClick={ this.props.onAnnotationLinkClick }
					onAnnotationSelectButtonClick={ this.props.onAnnotationSelectButtonClick }
					onTargetLayerChange={ this.onTargetLayerChange.bind(this) }
					onLayerMarkerSizingChange={ this.onLayerMarkerSizingChange.bind(this) }
					onLayerColoringChange={ this.onLayerColoringChange.bind(this) }
					onMarkerModeChange={ this.onMarkerModeChange.bind(this) }
					onDeformChange={ this.onLayerMarkerDeformChange.bind(this) }
					onDirShiftChange={ this.onLayerDirShiftChange.bind(this) }
					onGridModeChange={ this.onGridModeChange.bind(this) }
					onVariationChange={ this.onVariationChange.bind(this) }
					onSizeVariationChange={ this.onSizeVariationChange.bind(this) }
					onColoringMaxChange={ this.onColoringMaxChange.bind(this) }
					onMarkerSelDispClick={ this.onMarkerSelDispClick.bind(this) }
					onTrajectorySelDispClick={ this.onTrajectorySelDispClick.bind(this) }
					onBindAttrChange={ this.onBindAttrChange.bind(this) }
					onLegendSwitchChange={ this.props.onLegendSwitchChange }
					onSelectionCancelClick={ this.onSelectionCancelClick.bind(this) }
					onSwapUpRequest={ this.onSwapUpRequest.bind(this) }
					onLayerGenGridClick={ this.onLayerGenGridClick.bind(this) }
					onTrajectoryVisibilityChange={ this.onTrajectoryVisibilityChange.bind(this) }
					onTrajectoryColorChange={ this.onTrajectoryColorChange.bind(this) }
					onTrajectoryOpacityChange={ this.onTrajectoryOpacityChange.bind(this) }
					onGridPresetChange={ this.onGridPresetChange.bind(this) }
					onValueLabelChange={ this.onValueLabelChange.bind(this) }
					onTrajectoryColoringRuleToggle={ this.onTrajectoryColoringRuleToggle.bind(this) }
					onKeepShowChange={ this.props.onKeepShowChange }
					onInterpolationChange={ this.props.onInterpolationChange }
					onTailModeChange={ this.onTailModeChange.bind(this) }
					onTailDurationClick={ this.onTailDurationClick.bind(this) }
					onErrorDismiss={ this.props.onErrorDismiss }
					
					key={ layer.layerId }
					selfId={ layer.layerId }
					selected={ layer.layerId === this.props.selectedId }
					mainTitle={ layer.title }
					subTitle={ layer.subTitle }
					targetLayerId={ layer.targetId }
					visibility={ layer.visibility }
					remainMode={ remainMode }
					interpolationLimit={ interpolationLimit }
					legendVisibilities={ layer.legendVisibilities }
					configOpen={ layer._configOpen }
					annOpen={ layer._annOpen }
					layerType={ layer.getLayerType() }
					orderedAttributeNames={ layer.orderedAttributeNames || null }
					filterExpression={ layer.filterExpression || null }
					valueColoringMax={ layer.valueColoringMax || 0 }
					valueLabelVisible={ layer.valueLabelVisible }

					idCountProgress={ layer.idCountProgress }
					loadProgress={ layer.loadProgress }
					loadComplete={ layer.loadComplete }
					hasDataError={ layer.hasDataError() }
					dataErrorList= { layer.referDataErrorList() }

					trajectoryBusy={ layer.trajectoryBusy }
					trajectoryVisible={ layer.trajectoryVisible }
					trajectoryColor={ layer.trajectoryColor || null }
					trajectoryOpacity={ layer.trajectoryOpacity }
					trajectoryColoringRule={ layer.trajectoryColoringRule || 0 }

					tailMode={ layer.tailMode }
					tailDuration={  layer.tailDuration  }

					markerModeIndex={ layer.markerMode || 0 }
					numColorVariation={ numColorVariation }
					colorBind={ colorBind }
					coloringRule={ coloringRule }
					numSizeVariation={ numSizeVariation }
					sizeBind={ sizeBind }
					markerSizing={ markerSizing }

					markerSelDispFlags={ layer.selectionAppearance }
					trajectorySelDispFlags= { layer.selectionTrajectoryAppearance }
					gridModeIndex={ layer.gridMode || 0 }
					dirShift={ !!layer.dirShift }
					markerDeform={ markerDeform }

					annotation={ layer.annotation }
					referLayerList={ item.referLayerList }
					gridPresetName={ item.gridPresetName }
					selectionCount={ item.selectionCount } />
			);
		}

		return ret;
	}
	
	onTargetLayerChange(e, selfId, targetId) {
		if (this.props.onTargetLayerChange) {
			this.props.onTargetLayerChange(e, selfId, targetId);
		}
	}

	onLayerMarkerSizingChange(selfId, newSizingType) {
		if (this.props.onLayerMarkerSizingChange) {
			this.props.onLayerMarkerSizingChange(selfId, newSizingType);
		}
	}

	onLayerColoringChange(selfId, newColoringKey) {
		if (this.props.onLayerColoringChange) {
			this.props.onLayerColoringChange(selfId, newColoringKey);
		}
	}

	onMarkerModeChange(selfId, newValue)        {  invoke_cp_handler(this, 'onMarkerModeChange', selfId, newValue);   }
	onLayerMarkerDeformChange(selfId, newValue) {  invoke_cp_handler(this, 'onLayerMarkerDeformChange', selfId, newValue);  }
	onLayerDirShiftChange(selfId, newValue)     {  invoke_cp_handler(this, 'onLayerDirShiftChange', selfId, newValue);  }
	
	onGridModeChange(selfId, newValue) {
		if (this.props.onGridModeChange) {
			this.props.onGridModeChange( selfId, newValue );
		}
	}

	onVariationChange(selfId, newValue) { invoke_cp_handler(this, 'onLayerMarkerVariationChange', selfId, newValue);  }
	onSizeVariationChange(selfId, newValue) {  invoke_cp_handler(this, 'onLayerMarkerSizeVariationChange', selfId, newValue);  }
	
	onColoringMaxChange(selfId, newValue) {
		if (this.props.onColoringMaxChange) {
			this.props.onColoringMaxChange( selfId, newValue );
		}
	}
	
	onMarkerSelDispClick(selfId, newFlags) {
		if (this.props.onMarkerSelDispClick) {
			this.props.onMarkerSelDispClick(selfId, newFlags);
		}
	}

	onTrajectorySelDispClick(selfId, newFlags) {
		if (this.props.onTrajectorySelDispClick) {
			this.props.onTrajectorySelDispClick(selfId, newFlags);
		}
	}

	onBindAttrChange(selfId, selectorIndex, attributeIndex) {
		if (this.props.onBindAttrChange) {
			this.props.onBindAttrChange(selfId, selectorIndex, attributeIndex);
		}
	}

	onSelectionCancelClick(selfId, e) {
		if (this.props.onSelectionCancelClick) {
			this.props.onSelectionCancelClick(selfId, e);
		}
	}

	onLayerSelectRequest( listItem , selfId ) {
		if (this.props.onLayerSelectRequest) {
			this.props.onLayerSelectRequest(listItem, selfId);
		}
	}
	
	onLayerVisibilityClick(selfId) {
		if (this.props.onLayerVisibilityClick) {
			this.props.onLayerVisibilityClick(selfId);
		}
	}


	onSwapUpRequest(lowerLyaerId) {
		const len = this.childrenRefs.length;
		var lowerIndex = -1;
		for (var i = 0;i < len;++i) {
			const itemComponent = this.childrenRefs[i];
			
			if (lowerLyaerId === itemComponent.props.selfId) {
				lowerIndex = i;
				break;
			}
		}
		
		if (lowerIndex > 0) {
			const upperIndex = lowerIndex - 1;
			const lowerComponent = this.childrenRefs[ lowerIndex ];
			const upperComponent = this.childrenRefs[ upperIndex ];
			
			if (lowerComponent.selfRef && upperComponent.selfRef) {
				if (this.l_swap.beginSwap(upperComponent.selfRef , lowerComponent.selfRef)) {
					
					if (this.props.onSwapLayersRequest) {
						this.props.onSwapLayersRequest(upperIndex);
					}
					
				}
			}
		}
	}

	onLayerDeleteClick(selfId)  {  invoke_cp_handler(this, 'onLayerDeleteClick', selfId);  }
	onLayerGenGridClick(selfId) {  invoke_cp_handler(this, 'onLayerGenGridClick', selfId);  }

	onTrajectoryVisibilityChange(selfId, newValue)   {  invoke_cp_handler(this, 'onTrajectoryVisibilityChange', selfId, newValue);  }
	onTrajectoryColoringRuleToggle(selfId, newValue) {  invoke_cp_handler(this, 'onTrajectoryColoringRuleToggle', selfId, newValue);  }
	onTrajectoryColorChange(selfId, newValue)        {  invoke_cp_handler(this, 'onTrajectoryColorChange', selfId, newValue);  }
	onTrajectoryOpacityChange(selfId, newValue)      {  invoke_cp_handler(this, 'onTrajectoryOpacityChange', selfId, newValue);  }

	onGridPresetChange(selfId, newValue) {  invoke_cp_handler(this, 'onGridPresetChange', selfId, newValue);  }
	onValueLabelChange(selfId, newValue) {  invoke_cp_handler(this, 'onValueLabelChange', selfId, newValue);  }

	onTailModeChange(selfId, newValue) {  invoke_cp_handler(this, 'onTailModeChange', selfId, newValue);  }
	onTailDurationClick(selfId, newValue) {  invoke_cp_handler(this, 'onTailDurationClick', selfId, newValue);  }
}

function getBindIndex(layer, prop) {
	if (!layer.orderedAttributeNames) { return -1; }
	if (!layer.hasOwnProperty(prop)) { return -1; }
	return layer[prop];
}

export default LayerList;