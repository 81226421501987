const LM_SHADER_SOURCES = {
 "line.vs":  "uniform vec4 palette[128];\n"+
  "attribute vec2 position;\n"+
  "attribute vec2 uv;\n"+
  "uniform mat4 viewTrans;\n"+
  "varying vec2 vUV;\n"+
  "varying vec3 vColor;\n"+
  "void main(void) {\n"+
  "	int colorIndex = int( min(127.0, uv.y) );\n"+
  "	vUV = uv;\n"+
  "	vColor = palette[ colorIndex ].xyz;\n"+
  "	gl_Position = viewTrans * vec4(position, 0, 1);\n"+
  "}\n"+
  "\n",
 "line.fs":  "precision mediump float;\n"+
  "varying vec2 vUV;\n"+
  "varying vec3 vColor;\n"+
  "void main(void)\n"+
  "{\n"+
  "	float timeAlpha = max(1.0 - vUV.x, 0.0);\n"+
  "	if (timeAlpha < 0.05) { discard; }\n"+
  "//	gl_FragColor = vec4(0.1, 0.3, 1.0, timeAlpha);\n"+
  "	gl_FragColor = vec4(vColor.rgb, timeAlpha);\n"+
  "}\n",
 "test-tex.vs":  "precision mediump float;\n"+
  "attribute vec2 position;\n"+
  "attribute vec2 centerPos;\n"+
  "attribute vec2 uv;\n"+
  "attribute vec2 rel_uv;\n"+
  "attribute float flags;\n"+
  "varying highp vec2 vUV;\n"+
  "varying vec2 vRelUV;\n"+
  "varying float vFlags;\n"+
  "uniform mat4 viewTrans;\n"+
  "mat3 makeRot(in float angle, in vec2 move) {\n"+
  "	return mat3(cos(angle),-sin(angle), 0,\n"+
  "				sin(angle), cos(angle), 0,\n"+
  "				    move.x,     move.y, 1);\n"+
  "}\n"+
  "void main(void) {\n"+
  "	vUV = uv;\n"+
  "	vRelUV = rel_uv;\n"+
  "	vFlags = flags;\n"+
  "	float angle = (vFlags > 5.0) ? (vFlags - 10.0) : vFlags;\n"+
  "	if (angle > 0.001 || angle < -0.001) {	\n"+
  "		vec3 rPos = makeRot(angle, centerPos) * vec3(position - centerPos, 1);\n"+
  "		gl_Position = viewTrans * vec4(rPos.xy, 0, 1);\n"+
  "	} else {\n"+
  "		gl_Position = viewTrans * vec4(position, 0, 1);\n"+
  "	}\n"+
  "}\n"+
  "\n",
 "test-tex.fs":  "precision mediump float;\n"+
  "uniform sampler2D uSampler;  /* Main texture */ \n"+
  "/* uniform sampler2D uDSampler;  Second texture */ \n"+
  "varying highp vec2 vUV;\n"+
  "varying mediump vec2 vRelUV;\n"+
  "uniform mediump vec4 boundsThresh;\n"+
  "varying mediump float vFlags;\n"+
  "void main(void)\n"+
  "{\n"+
  "	if (vRelUV.s < boundsThresh.x || vRelUV.t < boundsThresh.y || \n"+
  "	    vRelUV.s > boundsThresh.z || vRelUV.t > boundsThresh.w) {\n"+
  "		discard;\n"+
  "	}\n"+
  "	if (vFlags > 5.0) {\n"+
  "		vec4 tcolor = texture2D(uSampler, vUV);\n"+
  "		float mono = dot(tcolor.rgb, vec3(1.0/3.0));\n"+
  "		gl_FragColor = vec4( vec3(mono), tcolor.a);\n"+
  "	} else {\n"+
  "		gl_FragColor = texture2D(uSampler, vUV);\n"+
  "	}\n"+
  "}\n"
};
export default LM_SHADER_SOURCES;
