const AttributeType = {
	STRING:   1,
	INTEGER:  2,
	FLOAT:    3,
	CFLOAT: 0x13,
	DATETIME: 9
};

function attrTypeFromString(s) {
	switch(s) {
		case 'int':
		case 'integer':
			return AttributeType.INTEGER;
		
		case 'float':
			return AttributeType.FLOAT;

		case 'cfloat':
			return AttributeType.CFLOAT;
	
		default:
			return AttributeType.STRING;
	}
}

function attrTypeToString(t) {
	switch(t) {
	case AttributeType.INTEGER:
		return 'int';
	case AttributeType.FLOAT:
		return 'float';
	case AttributeType.CFLOAT:
		return 'cfloat';
	default:
		return 'string';
	}
}

const AttributeValueTypeMask   = 0x0F;
const AttributeValueOptionMask = 0xF0;
export { AttributeValueTypeMask , AttributeValueOptionMask, attrTypeFromString, attrTypeToString };

export default AttributeType;