import React from 'react';
import { Component } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import { kAdditionalAttrsThresh } from '../misc-utils';
import { fillAttributeMenuItems } from '../widgets/MarkerSettingPanel';
import './ExpressionFilterDialog.css'
import ExpressionFilter from '../model/ExpressionFilter';

class ExpressionFilterDialog extends Component {	
	constructor(props) {
		super(props);

		this.state = this.generateExistingExpressionState(props.layer);

		this.formRefs = {};
	}

	generateExistingExpressionState(layer) {
		const state = {
			selOp2: null,
			selAttr2: null,
			selVal2: null
		};
		var firstFx = getFirstExpression(layer);
		var secondEx = null;

		const hasChild = firstFx && (firstFx.leftHand instanceof ExpressionFilter.XFOperatorNode);
		if (hasChild) {
			secondEx = firstFx.rightHand;
			firstFx = firstFx.leftHand;
		}

		this.setExpressionState(state, firstFx, 1);
		if (secondEx) {
			this.setExpressionState(state, secondEx, 2);
		}
		return state;
	}

	setExpressionState(outState, existingEx, rowIndex) {
		const attr = 'selAttr' + rowIndex;
		const op = 'selOp' + rowIndex;
		const val = 'selVal' + rowIndex;

		outState[attr] = existingEx ? existingEx.leftHand.attrName : kAdditionalAttrsThresh;
		outState[op] = existingEx ? existingEx.type : '==';
		outState[val] = existingEx ? existingEx.rightHand.value : 1;
	}

	removeExpressionState(outState, rowIndex) {
		const attr = 'selAttr' + rowIndex;
		const op = 'selOp' + rowIndex;
		const val = 'selVal' + rowIndex;

		delete outState[attr];
		delete outState[op];
		delete outState[val];

		delete this.formRefs[rowIndex];
	}

	render() {
		const attrMenuItems = [];
		const orderedAttrs = isAttrsValid(this.props.layer);
		if (orderedAttrs && orderedAttrs.length > kAdditionalAttrsThresh) {
			fillAttributeMenuItems(attrMenuItems, orderedAttrs);
		}
//		console.log("%c "+orderedAttrs, "color:#F84");

		const okAvailable = attrMenuItems && attrMenuItems.length > 0;

		return ( <Dialog open={ this.props.open }>
			<DialogTitle>Filter expression</DialogTitle>
			{ makeExpressionRow(1, attrMenuItems, this.state, this.onChangeInput.bind(this),
				this.refAttrSelect.bind(this),
				this.refOp.bind(this), this.refValueInput.bind(this) ) }
			{ this.makeAddButton(2) }
			{ makeExpressionRow(2, attrMenuItems, this.state, this.onChangeInput.bind(this),
				this.refAttrSelect.bind(this),
				this.refOp.bind(this), this.refValueInput.bind(this) ) }
			<MuiDialogActions>
				<Button onClick={ this.onCancel.bind(this) } color="secondary">Cancel</Button>
				<Button disabled={ !okAvailable } onClick={ this.onOK.bind(this) } color="primary" variant="contained">OK</Button>
			</MuiDialogActions>
		</Dialog> );
	}

	rowExists(rowIndex) {
		const opProp   = 'selOp'+rowIndex;
		return !!this.state[opProp];
	}

	makeAddButton(nextIndex) {
		const exists = this.rowExists(nextIndex);
		const ex_class = exists ? "xf-logical-operation-button" : "xf-add-row-button"

		return ( <Button onClick={ this.onAddButton.bind(this, nextIndex) } className={ ex_class }>AND</Button> );
	}

	onAddButton(newIndex) {
		const exists = this.rowExists(newIndex);
		const st = this.state;

		if (!exists) {
			this.setExpressionState(st, null, newIndex);
		} else {
			this.removeExpressionState(st, newIndex);
		}

		this.setState(st);
	}

	onChangeInput(name, e, f) {
		if (e.target) {
			const st = {};
			st[name] = e.target.value;
			this.setState(st);
		}
	}

	onOK() {
		if (this.props.onOK) {
			this.props.onOK();
		}
	}

	onCancel() {
		if (this.props.onCancel) {
			this.props.onCancel();
		}
	}

	setFormRef(rowIndex, name, element) {
		if (!this.formRefs[rowIndex]) {
			this.formRefs[rowIndex] = {};
		}

		this.formRefs[rowIndex][name] = element;
	}

	refAttrSelect(rowIndex, el) {
		if (el) {
			this.setFormRef(rowIndex, 'attr', query_ch(el, 'input'));
		}
	}

	refOp(rowIndex, el) {
		if (el) {
			this.setFormRef(rowIndex, 'op', query_ch(el, 'input'));
		}
	}

	refValueInput(rowIndex, el) {
		if (el) {
			this.setFormRef(rowIndex, 'value', query_ch(el, 'input'));
		}
	}

	generateExpression() {
		const row1Expr = generateExpressionFromRefs(this.formRefs[1], this.props.layer);
		const row2Expr = this.rowExists(2) ? generateExpressionFromRefs(this.formRefs[2], this.props.layer) : null;

		if (row2Expr) {
			const logical_op = new ExpressionFilter.XFOperatorNode(null, '&&');
			logical_op.setChildren(row1Expr, row2Expr);
			return logical_op;
		}

		return row1Expr;
	}
}

function generateExpressionFromRefs(rowRefs, layer) {
	if (!rowRefs) { return null; }

	const attrIndexRaw = rowRefs.attr.value;
	const op = rowRefs.op.value;
	const val = rowRefs.value.value;

	// 属性インデックス有効?
	if (isFinite(attrIndexRaw) && attrIndexRaw >= 0) {
		const exp = ExpressionFilter.makeComparison_AttrIndex_op_Const( parseInt(attrIndexRaw,10), op, val, true);
		exp.leftHand.indexNames = isAttrsValid(layer);
		return exp;
	}

	return null;
}

function makeExpressionRow(rowIndex, attrMenuItems, state, changeHandler, ref_a, ref_op, ref_v) {
	const attrProp = 'selAttr'+rowIndex;
	const opProp   = 'selOp'+rowIndex;
	const valProp  = 'selVal'+rowIndex;

	if (!state[attrProp] || !state[opProp] || !state[valProp]) {
		return '';
	}

	if (attrMenuItems && attrMenuItems.length > 0) {
		return ( <Grid container spacing={2} className="mm-xfilter-form-grid">
			<Grid item xs={1} />

			<Grid item xs={4} className="mm-xfilter-first-col">
				<Select ref={ (el) => ref_a(rowIndex, el) }
					value={ state[attrProp] }
					onChange={ (e,f) => changeHandler(attrProp, e, f) } >
					{ attrMenuItems }
				</Select>
			</Grid>
			<Grid item xs={2}>
				<Select ref={ (el) => ref_op(rowIndex, el) }
					value={ state[opProp] }
					onChange={ (e,f) => changeHandler(opProp, e, f) }
					defaultValue='=='>{ makeOpSelection() }</Select>
			</Grid>
			<Grid item xs={4}>
				<TextField ref={ (el) => ref_v(rowIndex, el) }
					value={ state[valProp] }
					onChange={ (e,f) => changeHandler(valProp, e, f) }
					defaultValue={1} />
			</Grid>

			<Grid item xs={1} />
		</Grid> );

	} else {
		return <div className="mm-xfilter-not-avail">Not available</div>;
	}
}

function isAttrsValid(layer) {
	if (!layer) {  return null;  }
	return layer.orderedAttributeNames || null;
}

const OPLIST = ['==', '!=', '<', '<=', '>', '>='];
function makeOpSelection() {
	const ls = [];
	for (const op of OPLIST) {
		ls.push( <MenuItem key={op} value={op}>{op}</MenuItem> );
	}

	return ls;
}

function query_ch(el, sel) {
	if (el) { return el.querySelector(sel); }
	return null;
}

function getFirstExpression(layer) {
	if (!layer) { return null; }
	return layer.filterExpression;
}

export default ExpressionFilterDialog;