import MarkerSetting from '../model/MarkerSetting'
import MarkerUtils from '../leafmob/MarkerUtils'
import ColoringPresetManager from '../model/ColoringPresetManager'
import {MarkerSizingPresets, MarkerSizingPresetNames} from './MarkerSizingPreset';

var _tempBaseColor = [0,0,0];

class MarkerImageGenerator {
	constructor() {
		this.debugMode = false;

		this.currentSize = {
			width: -1,
			height: -1
		};
		
		this.currentChipBounds = {
			minX: 1024,
			maxX: 0,
			minY: 1024,
			maxY: 0
		}
		
		this.lastColorGenerator = null;
		this.canvas = document.createElement('canvas');
		this.resizeCanvas(256, 256);
	}

	autoResize(chipSize, h_variation, v_variation) {
		const desiredWidth  = chipSize * h_variation;
		const desiredHeight = chipSize * v_variation;
		const maxSize = Math.max(desiredWidth, desiredHeight);

		const texSize = this.calcSquaredSize(maxSize);
		this.resizeCanvas(texSize, texSize);
		return this.currentSize;
	}

	resizeCanvas(newWidth, newHeight) {
		const sz = this.currentSize;
		if (sz.width === newWidth && sz.height === newHeight) { return false; }

		sz.width = newWidth;
		sz.height = newHeight;
		this.canvas.width = newWidth;
		this.canvas.height = newHeight;
		return true;
	}
	
	calcSquaredSize(s) {
		var l = Math.log(s) / Math.log(2);
		return Math.pow(2, Math.ceil(l));
	}

	clear() {
		const g = this.canvas.getContext('2d');
		const sz = this.currentSize;

		g.clearRect(0, 0, sz.width, sz.height);
		return g;
	}

	updateLastColorGenerator(colorGenerator) {
		this.lastColorGenerator = colorGenerator;
	}

	generate(colorGenerator, markerSetting) {
		this.lastColorGenerator = colorGenerator;

		const g = this.clear();
		const chipSize = markerSetting.chipSize;
		const numHorizontal = markerSetting.primaryVariation;
		const numVertical = markerSetting.secondaryVariation;

		var divs = numHorizontal-1;
		if (divs < 1) { divs=1; }
		
		var d2 = numVertical-1;
		if (d2 < 1) { d2=1; }

		var y = 0;
		for (var j = 0;j < numVertical;++j) {

			var x = 0;
			for (var i = 0;i < numHorizontal;++i) {
				this.drawMarker(g, x, y, markerSetting, colorGenerator, i/divs, j/d2);

				x += chipSize.width;
			}

			y += chipSize.height;
		}
		
		this.detectChipBounds(g, markerSetting);
	}
	
	generateColorOnly(index, colorGenerator, markerSetting) {
		var divs = markerSetting.primaryVariation - 1;
		if (divs < 1) { divs=1; }
		
		colorGenerator.getRGB(_tempBaseColor, index / divs);
		return _tempBaseColor;
	}

	detectChipBounds(g, markerSetting, useRow) {
		if (!g) {
			g = this.canvas.getContext('2d');
		}

		// const numHorizontal = markerSetting.primaryVariation;
		const w = this.currentSize.width;
		const h = this.currentSize.height;
		const row = useRow || 0;
		
		this.currentChipBounds.minY = h;
		this.currentChipBounds.maxY = 0;
		this.currentChipBounds.minX = w;
		this.currentChipBounds.maxX = 0;
		
		const chipSize = markerSetting.chipSize;
		/*
		for (var i = 0;i < numHorizontal;++i) {
			this.detectInChip(
				g, this.currentChipBounds,
				i*chipSize.width, row * chipSize.height,
				chipSize.width , chipSize.height
			);
		}*/

		this.detectInChip(
			g, this.currentChipBounds,
			0, row * chipSize.height,
			chipSize.width , chipSize.height
		);

		return this.currentChipBounds;
	}
	
	detectInChip(g, currentBounds, ox, oy, w, h) {
		const idat = g.getImageData(ox, oy, w, h);
		var inPicture = false;
		var minY = h;
		var maxY = 0;
		
		var minX = w;
		var maxX = 0;

		var x, y;

		for (y = 0;y < h;++y) {
			for (x = 0;x < w;++x) {
				const alpha = idat.data[ 4*(w*y + x) + 3 ];

				if (0 === alpha) {
					if (!inPicture) {
						minY = y;
					}
				}
				
				if (alpha) {
					inPicture = true;
					maxY = y;
					
					if (x > maxX) {
						maxX = x;
					}
				}
			}
		}
		
		// search left-end
		inPicture = false;
		for (x = 0;x < w;++x) {
			for (y = 0;y < h;++y) {
				const alpha = idat.data[ 4*(w*y + x) + 3 ];
				if (alpha) {
					inPicture = true;
					break;
				}
				
				minX = x;
			}
			
			if (inPicture) {break;}
		}

		++maxX;
		++maxY;

		if (minY < currentBounds.minY) {  currentBounds.minY = minY;  }
		if (maxY > currentBounds.maxY) {  currentBounds.maxY = maxY;  }
		if (minX < currentBounds.minX) {  currentBounds.minX = minX;  }
		if (maxX > currentBounds.maxX) {  currentBounds.maxX = maxX;  }
		return currentBounds;
	}

	drawMarker(g, x, y, markerSetting, colorGenerator, normPosition, secondPosition) {
		colorGenerator.getRGB(_tempBaseColor, normPosition);

		const chipSize = markerSetting.chipSize;
		const cx = markerSetting.center.x;
		const cy = markerSetting.center.y;
		const baseColor = 'rgb(' +_tempBaseColor.join(',')+ ')';

		const drawSize = generateSize(markerSetting, secondPosition);

		if (markerSetting.deform) {
			MarkerUtils.drawDirectionalMarker(g, cx, cy, drawSize, baseColor, x, y);
		} else {
			if (markerSetting.appearance === MarkerSetting.SpotAppearance) {
				MarkerUtils.drawSpotMarker(g, cx, cy, drawSize, baseColor, x, y);
			} else {
				MarkerUtils.drawCircleMarker(g, cx, cy, drawSize, baseColor, x, y);
			}
		}


		if (this.debugMode) {
			g.save();
			g.fillStyle = '#F00';
			g.fillRect(x                 , y, chipSize.width,               1);
			g.fillRect(x                 , y, 1             , chipSize.height);
			g.fillStyle = '#90F';
			g.fillRect(x+chipSize.width-1, y, 1             , chipSize.height);
			
			g.fillStyle = '#FF0';
			g.fillRect(x + cx-1, y + cy  , 3, 1);
			g.fillRect(x + cx  , y + cy-1, 1, 3);
			
			g.restore();
		}

	}	
}

function generateSize(markerSetting, position) {
	const sizing = MarkerSizingPresets[ markerSetting.sizingType ];
	const _pos = 1.0 - position;

	return sizing.fromSize * _pos  +  sizing.toSize * position;
}

MarkerImageGenerator.drawSizeLegend = function(g, x, y, markerSetting, pos) {
	const pr = window.devicePixelRatio;
	const sz = generateSize(markerSetting, pos) * pr;

	g.save();

	g.fillStyle = '#fff';
	g.strokeStyle = '#000';
	g.lineWidth = pr;

	g.beginPath();
	g.arc(x, y, sz, 0, Math.PI*2, false);
	g.fill();
	g.stroke();

	g.restore();
};

MarkerImageGenerator.selfTest = function() {
	var gen = new MarkerImageGenerator();
	var w  = gen.calcSquaredSize(192);
	var w2 = gen.calcSquaredSize(256);
	var w3 = gen.calcSquaredSize(257);
	console.log("%c"+w+'  '+w2+'  '+w3,'font-size:300%');
	
	gen.autoResize(16, 11, 1);
	console.log(gen.canvas);
	
	var ms = new MarkerSetting();
	ms.setPrimaryVariation(11);
	
	document.body.appendChild(gen.canvas);
	gen.canvas.style.position = 'absolute';
	gen.canvas.style.top = '0';
	gen.canvas.style.backgroundColor = 'rgba(100,100,100,0.25)';
	
	const cg = ColoringPresetManager.find( ms.coloringKey );
	console.log(cg);
	gen.generate(cg, ms);
};

MarkerImageGenerator.sizingTest = function() {
	const gen = new MarkerImageGenerator();
	// const canvasSize  = gen.calcSquaredSize(256);
	gen.autoResize(16, 30, 30);

	const ms = new MarkerSetting();
	ms.sizingType = MarkerSizingPresetNames.TO_LARGE;
	ms.setPrimaryVariation(30);
	ms.setSecondaryVariation(30);

	const cg = ColoringPresetManager.find( ms.coloringKey );
	gen.generate(cg, ms);

	console.log(gen.canvas.toDataURL());
};

export default MarkerImageGenerator;
