import MMLayer from './MMLayer'

class GridLayer extends MMLayer {
	constructor() {
		super();

		this.subTitle = 'generative grid';
		this.loadProgress = 1;
		this.idCountProgress = 100;
		this.loadComplete = true;

		this.gridMode = GridLayer.MODE_Sp;
		this.valueColoringMax = 0;
		this.gridPresetName = 'j3';
		this.valueLabelVisible = false;
	}

	refersMovingData() { return true; }

	getLayerType() {
		return 'grid';
	}
	
	setValueColoringMax(v) {
		this.valueColoringMax = v;
		this.eventInvoke('LayerColoringMaxChange', this, v);
	}

	getSpacing() {
		return (this.gridMode === GridLayer.MODE_Sp) ? 1 : 0;
	}

	setGridMode(mode) {
		if (this.gridMode !== mode) {
			this.setVisibility(mode !== 0, true);

			this.gridMode = mode;
			this.eventInvoke('LayerGridModeChange', this, mode);
		}
	}
	
	setGridPreset(name) {
		if (this.gridPresetName !== name) {
			this.gridPresetName = name;
			this.eventInvoke('LayerGridPresetChange', this, name);
		}
	}
	
	setValueLabelVisible(b) {
		if (this.valueLabelVisible !== b) {
			this.valueLabelVisible = b;
			this.eventInvoke('GridLabelChange', this, b);
		}
	}
}

GridLayer.MODE_None = 0;
GridLayer.MODE_NoSp = 1;
GridLayer.MODE_Sp   = 2;

export default GridLayer;

