import leaflet from 'leaflet'

function annEnsureProperties(annotationList, map) {
	if (Array.isArray(annotationList)) {
		for (const f of annotationList) {
			if (f.geometry &&
				!( f.hasOwnProperty('.view') ) ) {

				Object.defineProperty(f, '.view', {
					value: mapOverlayForAnnotation(map, f, onOverlayGenerated),
					writable: false
				});

			}

		}

		if (
			!(annotationList.hasOwnProperty('.idmap')) 
			) {
				Object.defineProperty(annotationList, '.idmap', {
					value: generateTraceIdMap(annotationList),
					writable: false
				});
		}
	}

}

function generateTraceIdMap(annotationList) {
	const m = new Map();
	for (const f of annotationList) {
		const pr = f.properties;
		if (pr && pr.hasOwnProperty('traceId')) {
			m.set(pr.traceId + '', f);
		}
	}
	
	return m;
}

function onOverlayGenerated(annFeature, coordinates) {
	if ( !(annFeature.hasOwnProperty('.center')) ) {
		if (isFinite(coordinates[0])) {
			// single point
			annFeature['.center'] = {lat: coordinates[0], lng: coordinates[1]};
		} else {
			const aLat = calcArrayAverage(coordinates, 0);
			const aLng = calcArrayAverage(coordinates, 1);
			annFeature['.center'] = {lat: aLat, lng: aLng};
		}
	}
}

// IDトレースのアノテーション位置を更新
const _ann_temp_ll = {lat:0, lng:0};
function annUpdateTracer(pickPool, annotationList, map) {
	const idmap = annotationList['.idmap'];
	// このマップにIDが含まれていればトレース対象

	const n = pickPool.pickedCount;
	const srcList = pickPool.referRaw();

	for (var i = 0;i < n;++i) {
		const rec = srcList[i];
		if (idmap.has(rec.id)) {
			const annotationFeature = idmap.get(rec.id);
			const v = annotationFeature['.view'];
			if (v) {
				_ann_temp_ll.lat = rec.lat;
				_ann_temp_ll.lng = rec.lng;
				changeOverlayLocations(v, _ann_temp_ll);
			}
		}
	}
}

function calcArrayAverage(a, prop) {
	const n = a.length;
	if (!n) { return 0; }

	var sum = 0;
	for (const elem of a) {
		sum += elem[prop];
	}

	return sum / n;
}

function annUpdate(annotationList, map, currentTime) {
	if (Array.isArray(annotationList)) {
		for (const f of annotationList) {
			const visible = checkTimeRangeVisibility(f, currentTime);
			const fview = f['.view'];

			if (fview) {
				changeOverlayStates(fview, visible, map);
			}
		}
	}
}

function checkTimeRangeVisibility(feature, currentTime) {
	const pr = feature.properties;

	if (!pr.hasOwnProperty("begin-time")) {
		if (!pr.hasOwnProperty("end-time")) {
			return true;
		}
	}

	return (pr["begin-time"] <= currentTime && currentTime <= pr["end-time"]);
}

// オーバーレイの位置変更(配列・単体どちらも対応して処理)
function changeOverlayLocations(maybeList, loc) {
	if (Array.isArray(maybeList)) {
		for (const o of maybeList) {
			changeAnOverlayLocation(o, loc);
		}
	} else {
		changeAnOverlayLocation(maybeList, loc);
	}
}

function changeAnOverlayLocation(overlay, loc) {
	if (overlay.setLatLng) {
		overlay.setLatLng(loc);
	}
}

// オーバーレイの状態変更(配列・単体どちらも対応して処理)
function changeOverlayStates(maybeList, visibility, map) {
	if (Array.isArray(maybeList)) {
		for (const o of maybeList) {
			changeAnOverlayVisibility(o, visibility, map);
		}
	} else {
		changeAnOverlayVisibility(maybeList, visibility, map);
	}
}
function changeAnOverlayVisibility(overlay, visibility, map) {
	if (overlay.addTo) {
		if (visibility) {
			if (!overlay._map) {
				overlay.addTo(map);
			}
		} else {
			if (overlay._map) {
				overlay.remove();
			}
		}
	}
}

const rePointType = /point/i ;
const rePolygonType = /polygon/i ;

function mapOverlayForAnnotation(map, annFeature, callback) {
	const gt = annFeature.geometry.type;

	if (rePolygonType.test(gt)) {
		// Polygon
		return [
			generatePolygonAnnotationOverlay(map, annFeature, callback),
			generateLabelOverlay(map, annFeature)
		];
	} else if (rePointType.test(gt)) {
		// Point
		return [
			generatePointAnnotationOverlay(map, annFeature, callback),
			generateLabelOverlay(map, annFeature, 16)
		];
	}


	return null;
}

function get_feature_prop(annFeature, propName) {
	const pr = annFeature.properties;
	if (pr) { return pr[propName] || null; }
	return null;
}

function getp_fillColor(annFeature) {  return get_feature_prop(annFeature, 'fillColor');  }
function getp_lineColor(annFeature) {  return get_feature_prop(annFeature, 'lineColor');  }

function generatePolygonAnnotationOverlay(map, annFeature, callback) {
	const loop = firstLoop(annFeature.geometry.coordinates);
	const cleanedLoop = cleanLoopCoordinates(loop);

	const polygon = leaflet.polygon(cleanedLoop, {fillOpacity: 0, color: getp_lineColor(annFeature) || '#19F' }).addTo(map);
	if (callback) {
		callback(annFeature, cleanedLoop);
	}
	return polygon;
}

function generatePointAnnotationOverlay(map, annFeature, callback) {
	const srcCoord = annFeature.geometry.coordinates;
	const leafletCoord = [ srcCoord[1] , srcCoord[0] ];
	const icon = generatePointMarkerIcon(16, 16, getp_fillColor(annFeature));
	const mk = leaflet.marker(leafletCoord, { icon: icon });

	if (callback) {
		callback(annFeature, leafletCoord);
	}
	return mk;
}

function generateLabelOverlay(map, annFeature, baseMargin) {
	var title = 'Untitled';
	var fontSize = 14;
	var marginBottom = 4;
	if (annFeature.properties) {
		if (annFeature.properties.title) {
			title = annFeature.properties.title;
		}

		if (annFeature.properties.fontSize) {
			fontSize = annFeature.properties.fontSize;
		}

		if (annFeature.properties.marginBottom) {
			marginBottom = annFeature.properties.marginBottom;
		}
	}

	const anchorLocation = calcLabelAnchorLocation(annFeature.geometry.coordinates);
	const icon = generateLabelIcon(title, fontSize, marginBottom + (baseMargin || 0));

	return leaflet.marker(anchorLocation, {icon: icon, interactive: false});
}

// ジオメトリ座標列からラベル固定位置の座標を生成
function calcLabelAnchorLocation(coordinates) {
	if (isFinite(coordinates[0])) {
		return {lat: coordinates[1], lng: coordinates[0]};
	}

	const loop = firstLoop(coordinates);
	return getNorthCoord(loop);
}

function firstLoop(coordinates) {
	const a = coordinates[0];
	if (Array.isArray(a)) {
		return a;
	}

	return null;
}

function cleanLoopCoordinates(rawLoop) {
	const copied = copySwapped(rawLoop);
	if (copied.length > 1) {
		const first = copied[0];
		const last = copied[ copied.length - 1 ];

		if (is_near(first[0], last[0]) && is_near(first[1], last[1])) {
			copied.pop();
		}
	}

	return copied;
}

function getNorthCoord(ls) {
	var found = {lat:0, lng:0};
	var m = Number.NEGATIVE_INFINITY;
	for (const c of ls) {
		if (c[1] > m) {
			m = c[1];
			found.lat = c[1];
			found.lng = c[0];
		}
	}

	return found;
}

function copySwapped(src) {
	const dest = new Array(src.length);
	for (var i = 0;i < src.length;++i) {
		dest[i] = [ src[i][1] , src[i][0] ];
	}

	return dest;
}

function is_near(a,b) {
	return Math.abs(a - b) < 0.00001;
}

function generatePointMarkerIcon(viewWidth, viewHeight, fillColor) {
	const pr = window.devicePixelRatio;
	const w = viewWidth * pr;
	const h = viewHeight * pr;
	const barWidth = 3 * pr;
	const arrowSize = Math.floor(w / 2.4);

	const cv = document.createElement('canvas');
	const g = cv.getContext('2d');
	cv.width  = w;
	cv.height = h;

	const bottomY = h - pr;
	const centerX = Math.floor(w / 2);

	g.fillStyle = fillColor || '#19F';

	const mY = bottomY - arrowSize;
	g.beginPath();
	g.moveTo(centerX, bottomY);
	g.lineTo(centerX - arrowSize, mY);
	g.lineTo(centerX - barWidth , mY);
	g.lineTo(centerX - barWidth ,  0);
	g.lineTo(centerX + barWidth ,  0);
	g.lineTo(centerX + barWidth , mY);
	g.lineTo(centerX + arrowSize, mY);
	g.fill();

	const url = cv.toDataURL();
	return leaflet.icon({
		iconUrl: url,
		iconSize: [viewWidth, viewHeight],
		iconAnchor: [viewWidth >> 1, viewHeight]
	});
}

function generateLabelIcon(text, pixelFontSize, marginBottom) {
	const pr = window.devicePixelRatio;
	const cv = document.createElement('canvas');
	const g = cv.getContext('2d');
	const fontSize = (pixelFontSize * pr);
	const fontStyle = 'normal ' + fontSize + 'px sans-serif';

	g.font = fontStyle;
	const met = g.measureText(text);

	const w = (met.width / pr) + 4;
	const h = pixelFontSize + 8;

	cv.width  = w * pr;
	cv.height = h * pr;

	g.textAlign = 'center';
	g.font = fontStyle;
	drawBorderedText(g, text, cv.width >> 1, h*pr - 5);

	const url = cv.toDataURL();
	return leaflet.icon({
		iconUrl: url,
		iconSize: [w, h],
		iconAnchor: [w >> 1, h+marginBottom]
	});
}

function drawBorderedText(g, text, x, y) {
	const pr = window.devicePixelRatio;
	g.fillStyle = '#000';
	
	g.save();
	g.shadowColor = '#000';
	g.shadowOffsetX = 0;
	g.shadowOffsetY = 0;
	g.shadowBlur = 1;
	g.fillText(text, x, y+pr);
	g.fillText(text, x, y+pr);
	g.restore();

	g.fillStyle = '#FFF';
	g.fillText(text, x, y);
}

export { annEnsureProperties , annUpdate, annUpdateTracer } ;