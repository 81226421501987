class XFNode {
	constructor(parent) {
		this.leftHand = null;
		this.rightHand = null;
		this.parent = parent || null;
	}

	exportTree() {
		return [ this.leftHand.exportTree() , this.toString() , this.rightHand.exportTree() ];
	}

	setChildren(left, right) {
		this.leftHand = left;
		this.rightHand = right;
		left.parent = this;
		right.parent = this;
	}

	evaluate(arg1) {}
}

class XFOperatorNode extends XFNode {
	constructor(parent, type) {
		super(parent);
		this.type = type;
		this.func = getCmpFunc(type);
	}

	evaluate(arg1) {
		/*
		console.log(this.leftHand.evaluate(arg1),
		 this.type, this.rightHand.evaluate(arg1)); */
		return this.func(
			this.leftHand.evaluate(arg1),
			this.rightHand.evaluate(arg1)
		);
	}

	sane() {
		return this.func !== null;
	}

	toString() {
		return this.leftHand + " " + this.type + " " + this.rightHand;
	}

	exportTree() {
		return [ this.leftHand.exportTree() , this.type , this.rightHand.exportTree() ];
	}
}

class XFConstantNode extends XFNode {
	constructor(parent, val) {
		super(parent);
		this.value = val;
	}

	toString() {
		return this.value;
	}

	exportTree() { return this.toString(); }

	evaluate(arg1) { return this.value; }
}

class XFAttributeNode  extends XFNode {
	constructor(parent, attrName) {
		super(parent);
		this.indexNames = null;
		this.attrName = attrName;
	}

	evaluate(arg1) {
		if (!arg1) { return null; }
		return arg1[ this.attrName ];
	}

	toString() {
		if (this.indexNames) {
			return this.indexNames[ this.attrName ];
		}

		return this.attrName;
	}

	exportTree() { return this.toString(); }
}

function makeLeafNode(parent, val) {
	if (isFinite(val)) {
		return new XFConstantNode(parent, val);
	} else if (val.toLowerCase) {
		// 文字列の場合はパラメータオブジェクトから属性取得
		return new XFAttributeNode(parent, val);
	} else {
		throw new Error('Bad arg type ' + val);
	}
}

// Comparison provider
function getCmpFunc(op) {
	switch(op) {
		case '==':
      // eslint-disable-next-line eqeqeq
			return (a,b) => { return a == b; } ;
		case '<>':
		case '!=':
      // eslint-disable-next-line eqeqeq
			return (a,b) => { return a != b; } ;

		case '<':
			return (a,b) => { return a < b; } ;
	
		case '>':
			return (a,b) => { return a > b; } ;

		case '<=':
		case '=<':
			return (a,b) => { return a <= b; } ;

		case '>=':
		case '=>':
			return (a,b) => { return a >= b; } ;			
		
		case '&&':
			return (a,b) => { return a && b; } ;

		case '||':
			return (a,b) => { return a || b; } ;
	
		default:
			return null;
	}
}

function makeSimpleComparison(leftV, op, rightV, rhIsAttrIndex) {
	const opNode = new XFOperatorNode(null, op);
	opNode.leftHand = makeLeafNode(opNode, leftV);
	if (rhIsAttrIndex) {
		opNode.rightHand = new XFAttributeNode(opNode, rightV);
	} else {
		opNode.rightHand = makeLeafNode(opNode, rightV);
	}

	return opNode;
}

function makeComparison_AttrIndex_op_Const(leftA, op, rightV) {
	const opNode = new XFOperatorNode(null, op);
	opNode.leftHand = new XFAttributeNode(opNode, leftA);
	opNode.rightHand = makeLeafNode(opNode, rightV);

	return opNode;
}

function xf_deserialize(src, attrNameArray) {
	if (src.length !== 3) {
		return null;
	}

	return createSuitableInstance(null, src, attrNameArray);
}

function createSuitableInstance(parentNode, str, attrNameArray) {
	if (Array.isArray(str)) {
		const arr = str;
		if (arr.length !== 3) {  return null;  }

		const centerNode = createSuitableInstance(parentNode, arr[1], attrNameArray);
		centerNode.leftHand = createSuitableInstance(centerNode, arr[0], attrNameArray);
		centerNode.rightHand = createSuitableInstance(centerNode, arr[2], attrNameArray);
		return centerNode;
	
	} else if (getCmpFunc(str)) {
		return new XFOperatorNode(parentNode, str);
	} else if (isFinite(str)) {
		return new XFConstantNode(parentNode, parseFloat(str) );
	} else {
		return createAttributeNodeMaybeIndexed(parentNode, str, attrNameArray);
	}
}

function createAttributeNodeMaybeIndexed(parentNode, str, attrNameArray) {
	if (attrNameArray) {
		const attrIndex = attrNameArray.indexOf(str);
		const nd = new XFAttributeNode(parentNode, attrIndex);
		nd.indexNames = attrNameArray;
		return nd;
	} else {
		return new XFAttributeNode(parentNode, str);
	}
}

const ExpressionFilter = {
	XFNode: XFNode,
	XFOperatorNode: XFOperatorNode,
	makeSimpleComparison: makeSimpleComparison,
	makeComparison_AttrIndex_op_Const: makeComparison_AttrIndex_op_Const,
	getCmpFunc: getCmpFunc
};

// module.exports = {ExpressionFilter: ExpressionFilter, deserialize: xf_deserialize};
export default ExpressionFilter;
export { xf_deserialize };