import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';

import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import AppsSharpIcon from '@material-ui/icons/AppsSharp';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CheckIcon from '@material-ui/icons/Check';
import SpeedIcon from '@material-ui/icons/Speed';
import SettingsApplicationsOutlined from '@material-ui/icons/SettingsApplicationsOutlined';

// layer head icons
import BuildIcon from '@material-ui/icons/Build';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import Collapse from '@material-ui/core/Collapse';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import AnnotationList from '../widgets/AnnotationList'
import TrajectorySettingPanel from '../widgets/TrajectorySettingPanel'
import MarkerSettingPanel from '../widgets/MarkerSettingPanel'
import LayerErrorBox from './LayerErrorBox'
import ColoringSelector from '../widgets/ColoringSelector'
import Input from '@material-ui/core/Input';

import Badge from '@material-ui/core/Badge';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Switch from '@material-ui/core/Switch';

import { invoke_cp_handler } from '../misc-utils'
import get_i18n from '../Internationalization';
import TailSettingPanel from '../widgets/TailSettingPanel';

const styles = (theme) => ({onright: {
	[theme.breakpoints.up('lg')]: {
		borderLeft: '1px solid #ccc'
	}
} });

class LayerListItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menuOpen:false,
			errorOpen: true
		};
		
		this.selfRef = null;
		this.anchorElement = null;
	}

	setSelfRef(el) {
		this.selfRef = el;
	}

	render() {
		const panelSelectedClass = (this.props.selected ? 'mm-setting-panel-selected' : '');
		const has_ann = !!( this.props.annotation );
		const is_mov = !this.isGrid();

		/*
		<IconButton size="small" edge="end" aria-label="visibility" onClick={ this.onLayerVisibilityClick.bind(this) }>
			{ this.props.visibility ? <VisibilityIcon />  :
				                      <VisibilityOffIcon /> }
		</IconButton>
		*/

		return (
			<div ref={ this.setSelfRef.bind(this) } className="mm-layerlist-item-root">
				<ListItem button disableRipple={true} onClick={ this.onListItemClick.bind(this) } selected={ this.props.selected }>

					<ListItemIcon size="small">
						<Badge className="mm-selection-badge"
						 max={ 99999 }
						 onClick={ this.onSelectionCancelClick.bind(this) }
						 badgeContent={ this.props.selectionCount || null }
						 color="primary" >
							{ this.isGrid() ? <AppsSharpIcon /> :  <DirectionsRunIcon /> }
						</Badge>
					</ListItemIcon>
					<ListItemText primary={ this.generateTitleText() } secondary={
						this.props.loadComplete ?
						this.props.subTitle :
						this.renderProgress(this.props.loadProgress, this.props.idCountProgress)
					} />
					
					{
						(this.props.loadComplete) ?
						<ListItemSecondaryAction>
							<IconButton size="small"
								edge="end" aria-label="more" onClick={ this.onMoreClick.bind(this) }>
								<MoreVertIcon fontSize="small" />
							</IconButton>
							<Menu open={ this.state.menuOpen } anchorEl={ this.anchorElement } onClose={ this.onMenuClose.bind(this) }>
								<MenuItem onClick={ this.onLayerMoveUpClick.bind(this) }><ArrowUpwardIcon />&nbsp; { get_i18n('MoveUp') }</MenuItem>
								{ this.generateGridMenuItemIf( is_mov ) }
								{ this.generateMovObjMenuItemIf( is_mov ) }
								{ this.generateInterpolationMenuItemIf( is_mov ) }
								{ this.generateAttrGenMenuItemIf( is_mov ) }
								{ this.generateExportSettingsMenuItem( is_mov ) }
								<MenuItem onClick={ this.onLayerDeleteClick.bind(this) } className="mm-layer-delete-menu-item"><DeleteIcon />&nbsp; { get_i18n('Delete') }</MenuItem>
							</Menu>
							{ has_ann ? ann_icon( e => this.onAnnClick(e) , this.props.annOpen ) : '' }
							{ config_icon( e=> this.onXClick(e) , this.props.configOpen) }
						</ListItemSecondaryAction>
						:
						( <CircularProgress /> )	
						
					}
				</ListItem>

				{ renderErrorListBox(this.props.dataErrorList, this.state.errorOpen, panelSelectedClass, this.onErrorDismiss.bind(this)) }

				<Collapse className={ panelSelectedClass } in={ this.props.annOpen } timeout="auto" unmountOnExit>
					{ this.renderAnnotationPanel() }
				</Collapse>
	
				<Collapse className={ panelSelectedClass } in={ this.props.configOpen } timeout="auto" unmountOnExit>
					{ this.renderLayerSetting() }
				</Collapse>
			</div>
		);
	}

	generateTitleText() {
		const err = (!this.props.hasDataError) ? '' : (<img src="/images/warnmark.png" alt="!" width="12" height="12" />);
		return (<span>{ this.props.mainTitle } {err}</span>);
	}

	renderProgress(percent, idCountProgress) {
		const i = Math.floor(percent);
		if (i < 0) {
			const x = -Math.floor( (1.0-idCountProgress) * 200);
			const csh = x + 'px 0 0 #E0E0E0 inset';
			return ( <span style={ {boxShadow:csh} } className="mm-layer-load-progress" >counting Ids...</span> )
		} else {
			const sh = (i*2) + 'px 0 0 #666 inset';
			return ( <span style={ {boxShadow:sh} } className="mm-layer-load-progress" >{ i }% loaded</span> )
		}
	}

	renderAnnotationPanel() {
		return (  <div className="mm-layer-setting-outer">
			<Grid container spacing={1} alignItems="center" className="mm-setting-panel-section">
				<Grid item xs={12}>
					<InputLabel className="mm-setting-side-label"><AnnouncementIcon fontSize="small" />{ get_i18n('Annotation') }</InputLabel>
					<AnnotationList
					 annotation={ this.props.annotation }
					 onSelectButtonClick={ this.onAnnotationSelectButtonClick.bind(this) }
					 onRevealButtonClick={ this.props.onAnnotationRevealClick }
					 onLinkButtonClick={ this.props.onAnnotationLinkClick } />
				</Grid>
			</Grid>
		</div> );
	}

	renderLayerSetting() {
		if (this.props.referLayerList) {
			const target_items = [];
			
			// Build target list ----
			for (const lyr of this.props.referLayerList) {
				target_items.push( <MenuItem value={ lyr.layerId } key={ lyr.layerId }>{lyr.title}</MenuItem> );
			}
		
			if (target_items.length < 1 || this.props.targetLayerId < 0) {
				target_items.push( <MenuItem value={ -1 } key={ -1 }><em className="mm-target-select-warn">Select target</em></MenuItem> );
			}
			// ------------------------
			// // GRID LAYER         //
			return (<div className="mm-layer-setting-outer">
		 		<Grid container spacing={1}>
					<Grid item xs={12}>{ this.renderGridToggle() }</Grid>
					<Grid item lg={6} md={12}>{ this.renderGridDataPanel(target_items) }</Grid>
					<Grid item lg={6} md={12} className={ this.props.classes.onright }>{ this.renderGridAppearancePanel() }</Grid>
				</Grid>
			</div>);
		}
		
		return ( <div className="mm-layer-setting-outer">
		 <Grid container spacing={1}>

			<Grid item lg={6} md={12}>
			 <MarkerSettingPanel
				selDispFlags={ this.props.markerSelDispFlags }
				markerModeIndex={ this.props.markerModeIndex }
				legendVisibilities={ this.props.legendVisibilities }
				colorBind={ this.props.colorBind }
				sizeBind={ this.props.sizeBind }
				coloringRule={ this.props.coloringRule }
				markerSizing={ this.props.markerSizing }
				numColorVariation={ this.props.numColorVariation }
				numSizeVariation={ this.props.numSizeVariation }
				markerDeform={ this.props.markerDeform }
				dirShift={ this.props.dirShift }
				orderedAttributeNames={ this.props.orderedAttributeNames }
				filterExpression={ this.props.filterExpression }
				onDeformChange={ this.onDeformChange.bind(this) }
				onDirShiftChange={ this.onDirShiftChange.bind(this) }
				onLegendSwitchChange={ this.onLegendSwitchChange.bind(this) }
				onModeChange={ this.onMarkerModeChange.bind(this) }
				onVariationChange={ this.onVariationChange.bind(this) }
				onSizeVariationChange={ this.onSizeVariationChange.bind(this) }
				onSizingChange={ this.onMarkerSizingChange.bind(this) }
				onColoringChange={ this.onColoringChange.bind(this) } 
				onBindAttrChange={ this.onBindAttrChange.bind(this) }
				onSelDispClick={ this.onMarkerSelDispClick.bind(this) }
				onExpressionFilterClick={ this.onExpressionFilterClick.bind(this) }
				onExpressionClearClick={ this.onExpressionClearClick.bind(this) } />
			</Grid>

			<Grid item lg={6} md={12} className={ this.props.classes.onright }>
			 <TrajectorySettingPanel
			  visible={ this.props.trajectoryVisible }
			  busy={ this.props.trajectoryBusy }
			  selDispFlags={ this.props.trajectorySelDispFlags }
			  coloringRule={ this.props.trajectoryColoringRule }
			  currentColor={ this.props.trajectoryColor || null }
			  currentOpacity={ this.props.trajectoryOpacity }
			  onVisibilityChange={ this.props.onTrajectoryVisibilityChange.bind(this, this.props.selfId) }
			  onColoringRuleToggle={ this.onTrajectoryColoringRuleToggle.bind(this) }
			  onColorChange={ this.onTrajectoryColorChange.bind(this) }
			  onOpacityChange={ this.onTrajectoryOpacityChange.bind(this) }
			  onSelDispClick={ this.onTrajectorySelDispClick.bind(this) }  />

			 <TailSettingPanel
			 	currentMode={ this.props.tailMode }
				currentDuration={  this.props.tailDuration  }
			 	onModeChange={ this.onTailModeChange.bind(this) }
				onDurationClick={ this.onTailDurationClick.bind(this) } />
			</Grid>

		 </Grid>
		</div> );
	}

	isGrid() {
		const t = this.props.layerType;
		if (t && 
			(t.indexOf('g') >= 0 || t.indexOf('G') >= 0) ) {
			return true;
		}
		
		return false;
	}
	
	// [][][] Grid configuration parts [][][]
	renderGridToggle() {
		const md = this.props.gridModeIndex;
		const btn0 = (  !md   ) ? 'secondary' : '';
		const btn1 = (md === 1) ? 'primary'   : '';
		const btn2 = (md === 2) ? 'primary'   : '';
		
		return 	(<div className="mm-setting-panel-toggle"><ButtonGroup size="small" disableRipple={true}>
			<Button variant="contained" disableElevation color={btn0} onClick={ this.onModeToggle.bind(this, 0) }
				startIcon={ <img src="/images/comp-x2.png" alt="X" /> } className="mm-toggle-x">&#65279;</Button>
			<Button variant="contained" disableElevation color={btn1} onClick={ this.onModeToggle.bind(this, 1) }
				className='mm-use-composited-icon'
				startIcon={ <img src="/images/grid-nosp.png" alt="" /> }>
				{ get_i18n('NonSeparated') }
			</Button>
			<Button variant="contained" disableElevation color={btn2} onClick={ this.onModeToggle.bind(this, 2) }
				className='mm-use-composited-icon'
				startIcon={ <img src="/images/grid-sp.png" alt="" /> }>
				{ get_i18n('Separated') }
			</Button>
		</ButtonGroup></div> );
	}
	
	onModeToggle(newValue) {
		if (this.props.onGridModeChange) {
			this.props.onGridModeChange(this.props.selfId, newValue);
		}
	}


	renderGridDataPanel(target_items) {
		return ( <Grid container spacing={1} alignItems="center" className="mm-setting-panel-section">
			<Grid item xs={12}>
				<InputLabel className="mm-setting-side-label"><img src="/images/cat-data.png" width="16" height="16" alt=""/>{ get_i18n('Data') }</InputLabel>
			</Grid>


			<Grid item xs={1} />
			<Grid item xs={6}>
				<InputLabel shrink>{ get_i18n('TargetLayer') }</InputLabel>
				<Select className="mm-layer-option-input" value={this.props.targetLayerId} onChange={ this.onTargetLayerChange.bind(this) }>{ target_items }</Select><br />
			</Grid>
			<Grid item xs={5} />


			<Grid item xs={1} />
			<Grid item xs={6}>
				<InputLabel shrink>{ get_i18n('MaxValue') }</InputLabel>
				<Input value={ this.props.valueColoringMax || 0 } onChange={ this.onColoringMaxChange.bind(this) } inputProps={
					{
						step: 1,
						min: 0,
						max: 1000000,
						type: 'number'
					}
				} />
			</Grid>
			<Grid item xs={5} />
			
		</Grid> );
	}

	// グリッドレイヤーの外観設定（プリセット・色付け・ラベル）
	renderGridAppearancePanel() {
		return ( <Grid container spacing={1} alignItems="center" className="mm-setting-panel-section">
			<Grid item xs={12}>
				<InputLabel className="mm-setting-side-label"><img src="/images/cat-style.png" width="16" height="16" alt=""/>{ get_i18n('Appearance') }</InputLabel>
			</Grid>

			<Grid item xs={1} />
			<Grid item xs={6}>
				{ this.renderGridOption() }
			</Grid>
			<Grid item xs={5} />


			<Grid item xs={1} />
			<Grid item xs={6}>
				<ColoringSelector onColoringChange={ this.onColoringChange.bind(this) } value={ this.props.coloringRule } />
			</Grid>
			<Grid item xs={5} />
		
		
			<Grid item xs={1} />
			<Grid item xs={4}>
				<InputLabel>{ get_i18n('Labels') }</InputLabel>
			</Grid>
			<Grid item xs={7}>
				<Switch
					checked={ this.props.valueLabelVisible }
					onChange={ this.onValueLabelChange.bind(this) }
					color="primary" />
			</Grid>
		
		</Grid> );
	}

	renderGridOption() {
		const selectedVal = this.props.gridPresetName;
		
		return ( <div>
			<InputLabel shrink>{ get_i18n('Preset') }</InputLabel>
			<Select
			 className="mm-layer-grid-preset-input"
			 onChange={ this.onGridPresetChange.bind(this) }
			 value={ selectedVal }>
				<MenuItem value="j1" key="j1">{ get_i18n('JapanLv1') }</MenuItem>
				<MenuItem value="j2" key="j2">{ get_i18n('JapanLv2') }</MenuItem>
				<MenuItem value="j3" key="j3">{ get_i18n('JapanLv3') }</MenuItem>
				<MenuItem value="j4" key="j4">{ get_i18n('JapanLv4') }</MenuItem>
				<MenuItem value="j5" key="j5">{ get_i18n('JapanLv5') }</MenuItem>
				<MenuItem value="jm100" key="jm100">{ get_i18n('Japan100m') }</MenuItem>
				<MenuItem value="jm50" key="jm50">{ get_i18n('Japan50m') }</MenuItem>
			</Select>
		</div> );
	}
	
	onGridPresetChange(e,f) {
		if (e.target && this.props.onGridPresetChange) {
			this.props.onGridPresetChange( this.props.selfId, e.target.value );
		}
	}

	// self click  - - - -
	onListItemClick(e) {
		if (this.props.onSelectRequest) {
			this.props.onSelectRequest(this, this.props.selfId);
		}
	}
	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
	// ... Menu
	generateMovObjMenuItemIf(shouldShow) {
		let checked = false;
		if (this.props.remainMode) {  checked = this.props.remainMode.beforeFirst;  }

		if (shouldShow) {
			return ( <MenuItem className={ checked ? '' : 'mm-menu-chk-disabled' } onClick={ this.onKeepShowMenuItemClick.bind(this) }><CheckIcon />&nbsp; { get_i18n('KeepShow') }</MenuItem> );
		} else {
			return '';
		}
	}

	generateInterpolationMenuItemIf(shouldShow) {
		let checked = false;
		if (this.props.interpolationLimit) {
			checked = this.props.interpolationLimit.active;
		}

		if (shouldShow) {
			return ( <MenuItem className={ checked ? '' : 'mm-menu-chk-disabled' } onClick={ this.onInterpolationMenuItemClick.bind(this) }><CheckIcon />&nbsp; { get_i18n('InterpolationLimit') }</MenuItem> );
		} else {
			return '';
		}
	}

	generateAttrGenMenuItemIf(shouldShow) {
		if (shouldShow) {
			return ( <MenuItem onClick={ this.onAttrGenMenuItemClick.bind(this) }><SpeedIcon />&nbsp; { get_i18n('GenVelo') }</MenuItem> );
		} else {
			return '';
		}
	}

	generateExportSettingsMenuItem(shouldShow) {
		if (shouldShow) {
			return <MenuItem onClick={ this.onExportSettingsMenuItemClick.bind(this) }><SettingsApplicationsOutlined
			 />&nbsp; { get_i18n('ExportLayerSetting') }</MenuItem>
		} else {
			return '';
		}
	}

	onKeepShowMenuItemClick() {
		this.callWithId(this.props.onKeepShowChange, null);
		this.setMenuAnchor(null);
	}

	onInterpolationMenuItemClick() {
		this.callWithId(this.props.onInterpolationChange, null);
		this.setMenuAnchor(null);
	}

	onAttrGenMenuItemClick() {
		this.callWithId(this.props.onAttrGen, null);
		this.setMenuAnchor(null);
	}

	onExportSettingsMenuItemClick() {
		this.callWithId(this.props.onExportSetting, null);
		this.setMenuAnchor(null);
	}
	
	generateGridMenuItemIf(shouldShow) {
		if (shouldShow) {
			return ( <MenuItem onClick={ this.onLayerGenGridClick.bind(this) }><AppsSharpIcon />&nbsp; { get_i18n('GenerateGrid') }</MenuItem> );
		} else {
			return "";
		}
	}
	
	onMoreClick(e) {
		this.setMenuAnchor(e.currentTarget);
	}
	
	onMenuClose() {
		this.setMenuAnchor(null);
	}
	
	setMenuAnchor(el) {
		const st = this.state;
		this.anchorElement = el;
		st.menuOpen = !!el;
		this.setState(st);
	}
	
	onLayerMoveUpClick(e) {
		if (this.props.onSwapUpRequest) {
			this.props.onSwapUpRequest(this.props.selfId);
		}

		this.setMenuAnchor(null);
	}

	onLayerDeleteClick(e) {
		if (this.props.onLayerDeleteClick) {
			this.props.onLayerDeleteClick(this.props.selfId, e);
		}
		this.setMenuAnchor(null);
	}

	onLayerGenGridClick(e) {
		if (this.props.onLayerGenGridClick) {
			this.props.onLayerGenGridClick(this.props.selfId, e);
		}
		
		this.setMenuAnchor(null);
	}

	// - - - - - - - - - -

	// Expand or close panel
	onXClick(e) {
		if (this.props.requestPanelToggle) {
			this.props.requestPanelToggle(this.props.selfId);
		}
	}

	onAnnClick(e) {
		if (this.props.requestAnnotationToggle) {
			this.props.requestAnnotationToggle(this.props.selfId);
		}
	}

	onTargetLayerChange(e) {
		if (this.props.onTargetLayerChange) {
			this.props.onTargetLayerChange(e, this.props.selfId, e.target.value);
		}
	}

	
	callWithId(method, arg2) {
		if (method) {
			method.call(this, this.props.selfId, arg2);
		}
	}

	onErrorDismiss()                    {  this.callWithId(this.props.onErrorDismiss);  }
	onDeformChange(newValue)            {  this.callWithId(this.props.onDeformChange, newValue);  }
	onDirShiftChange(newValue)          {  this.callWithId(this.props.onDirShiftChange, newValue);  }
	onMarkerModeChange(newValue)        {  this.callWithId(this.props.onMarkerModeChange, newValue);    }
	onMarkerSelDispClick(flags)         {  this.callWithId(this.props.onMarkerSelDispClick, flags);     }
	onExpressionFilterClick()           {  this.callWithId(this.props.onExpressionFilterClick);  }
	onExpressionClearClick()            {  this.callWithId(this.props.onExpressionClearClick);  }
	onColoringChange(newColoringKey)    {  this.callWithId(this.props.onLayerColoringChange, newColoringKey); }
	onVariationChange(newValue)         {  this.callWithId(this.props.onVariationChange, newValue);     }	
	onMarkerSizingChange(newSizingType) {  this.callWithId(this.props.onLayerMarkerSizingChange, newSizingType);  }
	onSizeVariationChange(newValue)     {  this.callWithId(this.props.onSizeVariationChange, newValue); }

	onColoringMaxChange(e) {
		if (this.props.onColoringMaxChange) {
			this.props.onColoringMaxChange(this.props.selfId, e.target.value - 0);
		}
	}
	
	onBindAttrChange(selectorIndex, e) {
		if (this.props.onBindAttrChange) {
			this.props.onBindAttrChange(this.props.selfId, selectorIndex, e);
		}
	}

	onLegendSwitchChange(selectorIndex, e, newValue) {
		if (this.props.onLegendSwitchChange) {
			this.props.onLegendSwitchChange(this.props.selfId, selectorIndex, e, newValue);
		}
	}
	
	onTrajectorySelDispClick(flags) {
		if (this.props.onTrajectorySelDispClick) {
			this.props.onTrajectorySelDispClick(this.props.selfId, flags);
		}
	}

	onSelectionCancelClick(e) {
		e.stopPropagation();
		if (this.props.onSelectionCancelClick) {
			this.props.onSelectionCancelClick(this.props.selfId, e);
		}
	}
	
	onLayerVisibilityClick(e) {
		if (this.props.onLayerVisibilityClick) {
			this.props.onLayerVisibilityClick(this.props.selfId);
		}
	}
	
	onTrajectoryColorChange(newColor) {
		if (this.props.onTrajectoryColorChange) {
			this.props.onTrajectoryColorChange(this.props.selfId, newColor);
		}
	}
	
	onTrajectoryOpacityChange(newValue) {
		if (this.props.onTrajectoryOpacityChange) {
			this.props.onTrajectoryOpacityChange(this.props.selfId, newValue);
		}
	}
	
	onValueLabelChange(e, newValue) {
		if (this.props.onValueLabelChange) {
			this.props.onValueLabelChange(this.props.selfId, newValue);
		}
	}
	
	onTrajectoryColoringRuleToggle(newValue) {
		invoke_cp_handler(this, 'onTrajectoryColoringRuleToggle', this.props.selfId, newValue);
	}

	onTailModeChange(newValue)  { this.callWithId(this.props.onTailModeChange, newValue); }
	onTailDurationClick(newValue) { this.callWithId(this.props.onTailDurationClick, newValue); }

	// reorder
	doAnimationTest(e) {
		
		if (this.props.onSwapUpRequest) {
			this.props.onSwapUpRequest(this.props.selfId);
		}

	}
	
	// annotation
	onAnnotationSelectButtonClick(e, feature) {
		this.callWithId(this.props.onAnnotationSelectButtonClick, feature);
	}
}

function config_icon(handler, opened) {
	return make_head_icon(handler, opened, <BuildIcon fontSize="small" /> );
}

function ann_icon(handler, opened) {
	return make_head_icon(handler, opened, <AnnouncementIcon fontSize="small" /> );
}

function make_head_icon(handler, opened, iconComponent) {
	const cls = opened ? 'mm-layer-head mm-layer-head-opened' : 'mm-layer-head';
	return ( <IconButton disableRipple={ true } className={ cls } size="small" edge="end" aria-label="collapse" onClick={ handler }>
		{ iconComponent }
	</IconButton> );
}

function renderErrorListBox(errorList, opened, selectedClass, dismissHandler) {
	if (!errorList) { return ''; }
	if (!errorList.length) { return ''; }

	return (<Collapse in={opened} timeout="auto" unmountOnExit className={ selectedClass }>
		<LayerErrorBox errorList={ errorList } onDismiss={ dismissHandler } />
	</Collapse>) ;
}

export default withStyles(styles)(LayerListItem);