import React from 'react';
import { Component } from 'react';

import { DndProvider } from "react-dnd"
import ReactDnDHTML5Backend from "react-dnd-html5-backend"
import DraggableHead from './DraggableHead'
import DroppableCell from './DroppableCell'

import {attrTypeFromString} from '../leafmob/md/AttributeType'
import AttributeType from '../leafmob/md/AttributeType'
import get_i18n from '../Internationalization';

class PreviewTable extends Component {
	constructor(props) {
		super(props);
		
		this.state = {columnMapping: null};
		this.maxNumColumns = 0;
	}
	
	// output
	eachAttribute(fn) {
		const m = this.state.columnMapping;
		if (m) {
			for (const i in m) if (m.hasOwnProperty(i)) {
				fn(i, m[i]);
			}
		}
	}
	
	render() {
		const detectedInfo = detectColumnTypes( this.props.sourceData );
		const d_rows = this.generateDataRows();
		return ( <table className="mm-csv-preview-table">
					{ this.generateCaptionInfo(detectedInfo, this.props.hasManifest) }
					{ this.generateHeadRow(this.maxNumColumns, detectedInfo) }{ d_rows }
				</table> );
	}

	generateHeadRow(n, detectedInfo) {
		const row = <DraggableHeadRow
			detectedInfo={ detectedInfo }
			onMappingChange={ this.onMappingChange.bind(this) }
			ref={ this.onHeaderRendered.bind(this) }
			numDataCols={n} /> ;

		return (<thead>{ row }</thead>)
	}

	generateDataRows() {
		this.maxNumColumns = 0;
		const colmap = this.state.columnMapping;

		const src = this.props.sourceData;
		let rows = [];
		if (src) {
			for (let i = 0;i < src.length;++i) {
				const isHeader = i < this.props.numHeaderLines;
				rows.push( this.generateADataRow( src[i], i, i - this.props.numHeaderLines, colmap, isHeader ) )
			}
		}
		
		
		return (<tbody>{ rows }</tbody>);
	}
	
	generateCaptionInfo(detectedInfo, hasManifest) {
		const m = hasManifest ? (<div><img alt="[good]" src="/images/verified.png" />{ get_i18n('ManifestValid') }</div>) : '';

		if (!detectedInfo.hasHeader) { return (<caption>{m}</caption>); }
		
		const msg = detectedInfo.headerUsed ? get_i18n('HeaderValid') : get_i18n('HeaderIgnored');
		const ico = detectedInfo.headerUsed ? (<img alt="[good]" src="/images/csvhead.png" />) : '';
		return ( <caption className="mm-preview-table-caption">{m}<div>{ico}{msg}</div></caption> );
	}
	
	generateADataRow(rowSource, index, dispIndex, colmap, isHeader) {
		const cls = isHeader ? 'mm-preview-header-row' : '';
		return (<tr key={ 'preview-row-'+index } className={cls}>{ this.generateDataColumns(rowSource, dispIndex, colmap) }</tr>);
	}
	
	generateDataColumns(rawLine, lineNo, colmap) {
		var tableCells = [( <td className="mm-line-head" key="line-head">{ (lineNo>=0) ? (lineNo+1) : 'h' }</td> )];

		const fields = rawLine.split(',');
		for (var i = 0;i < fields.length;++i) {
			var attrName = null;
			if (colmap && colmap[i]) {
				attrName = colmap[i].attrName;
			}

			tableCells.push( <td data-attr={ attrName } key={ 'col'+i }>{ fields[i] }</td> );
		}
		
		this.maxNumColumns = Math.max( this.maxNumColumns, fields.length );
		return tableCells;
	}
	
	onHeaderRendered(component) {
		if (!this.state.columnMapping) {
			if (component && component.state && component.state.columnMapping) {
				this.onMappingChange( component.state.columnMapping );
			}
		}
	}
	
	onMappingChange(newMapping) {
		this.state.columnMapping = newMapping;
		this.setState( this.state );
	}
}

class DraggableHeadRow extends Component {
	constructor(props) {
		super(props);
		
		const cm = props.detectedInfo ? 
					props.detectedInfo.columnMapping : 
					{
						0: {attrName: 'id', label: 'id'},
						3: {attrName: 'time', label: 'time'},
						4: {attrName: 'lng', label: 'lng(X)'},
						5: {attrName: 'lat', label: 'lat(Y)'}
					} ;
		
		this.state = {
			columnMapping: cm
		}
	}

	render() {
		const n = this.props.numDataCols || 0;
		var cols = [ <th key="head-col"></th> ];
		for (var i = 0;i < n;++i) {
			const colStat = this.state.columnMapping[i] || null;
			if (colStat) {
				// head exists
				var isAdditional = colStat.label ? 0 : 1;
				cols.push( <DroppableCell isAdditional={isAdditional} columnIndex={i} key={ "h-"+i } onDrop={ this.onHeadDrop.bind(this) }>
				 <DraggableHead
					onUserAttributeNameChange={ this.onUserAttributeNameChange.bind(this, i) }
					onUserAttributeTypeChange={ this.onUserAttributeTypeChange.bind(this, i) }
					onRemoveClick={ this.onRemoveButtonClick.bind(this, i) } {...colStat} />
				</DroppableCell> );
			} else {
				// empty
				cols.push( <DroppableCell columnIndex={i} key={ "h-"+i } showButton="1" onAddButton={ this.onAddButton.bind(this, i) } onDrop={ this.onHeadDrop.bind(this) } /> );
			}
		}

		return (  <DndProvider backend={ ReactDnDHTML5Backend }><tr>{cols}</tr></DndProvider>  );
	}

	onUserAttributeNameChange(columnIndex, e) {
		const st = this.state;
		const m = st.columnMapping;
		if (m[columnIndex]) {
			m[columnIndex].attrName = e.target.value;
		}

		this.setState( st );
	}
	
	onUserAttributeTypeChange(columnIndex, e) {
		const st = this.state;
		const m = st.columnMapping;
		if (m[columnIndex]) {
			m[columnIndex].dataType = e.target.value - 0;
		}

		this.setState( st );
	}

	onRemoveButtonClick(columnIndex) {
		const st = this.state;
		const m = st.columnMapping;
		delete m[columnIndex];

		this.setState( st );
	}

	onAddButton(columnIndex) {
		const st = this.state;
		const m = st.columnMapping;
		
		m[columnIndex] = {
			attrName: '', label: null, dataType: AttributeType.INTEGER
		};
		
		this.setState( st );
	}

	onHeadDrop(droppedItem, columnIndex) {
		const oldIndex = this.pickColumn(droppedItem.attrName);
		if (oldIndex === columnIndex) { return; } // no op

		if (oldIndex >= 0) {
			const m = this.state.columnMapping;
			const itemToMove = m[oldIndex];
			delete m[ oldIndex ];
			
			var replacedItem = m[columnIndex] || null;
			m[columnIndex] = itemToMove;
			
			// if replaced
			if (replacedItem) {
				m[oldIndex] = replacedItem;
			}
			
			this.setState( this.state );
			if (this.props.onMappingChange) {
				this.props.onMappingChange(this.state.columnMapping);
			}
		}
	}
	
	pickColumn(attrName) {
		const m = this.state.columnMapping;
		for (var i in m) if (m.hasOwnProperty(i)) {
			const colData = m[i];
			if (colData.attrName === attrName) {
				return parseInt(i, 10);
			}
		}

		return -1;
	}
}

function setAdditionalColumnsFromHeader(dest, sourceInfo) {
	if (!sourceInfo) { return; }
	if (!(sourceInfo.additional)) { return; }

	for (const info of sourceInfo.additional) {
		dest[ info.column ] = {
			attrName: info.name,
			dataType: attrTypeFromString(info.type),
			label: null
		};
	}
}

// AUTO DETECTOR -----------------------------------------------
function detectColumnTypes(rawData) {
	const AttrInfo_Id   = {attrName: 'id', label: 'id'};
	const AttrInfo_Lat  = {attrName: 'lat' , label: 'lat(Y)'};
	const AttrInfo_Lng  = {attrName: 'lng' , label: 'lng(X)'};
	const AttrInfo_Time = {attrName: 'time', label: 'time'  };

	var ret = {
		hasHeader: 0,
		headerUsed: false,
		columnMapping: {
			0: AttrInfo_Id
		}
	};

	var targetLine = rawData[0];
	const first_ln_data = seemsDataRow(targetLine);
	if (!first_ln_data) {
		ret.hasHeader = 1;
		
		// Extract header information
		const uh = seemsUsefulHeader(targetLine);
		if (uh) {
			delete ret.columnMapping[0];

			ret.columnMapping[ uh.id  ]  = AttrInfo_Id;
			ret.columnMapping[ uh.lat  ] = AttrInfo_Lat;
			ret.columnMapping[ uh.lng  ] = AttrInfo_Lng;
			ret.columnMapping[ uh.time ] = AttrInfo_Time;
			setAdditionalColumnsFromHeader(ret.columnMapping, uh);
			ret.headerUsed = true;
			return ret;
		}
		
		targetLine = rawData[1];
	}

	if (!targetLine) { return null; }
	
	const fields = targetLine.split(',');
	let latCol = detectLatCol(fields);
	let lngCol = detectLngCol(fields);

	if (latCol < 0) { latCol=5; }
	if (lngCol < 0) { lngCol=4; }
	if (latCol === lngCol) { ++latCol; }

	let timeCol = detectTimeCol(fields, latCol, lngCol);
	if (timeCol < 0) {
		timeCol = findEmptyColumn(fields, ret.columnMapping);
	}

	ret.columnMapping[  latCol ] = AttrInfo_Lat;
	ret.columnMapping[  lngCol ] = AttrInfo_Lng;
	ret.columnMapping[ timeCol ] = AttrInfo_Time;

	return ret;
}

function findEmptyColumn(fields, m) {
	for (var i = (fields.length-1);i >= 0;--i) {
		if ( !(m[i]) ) return i;
	}

	return -1;
}

function detectFloatRangeCol(fields, cond) {
	const n = fields.length;
	for (var i = 0;i < n;++i) {
		const col = fields[i];
		if (col.indexOf('.') >= 0) {
			const f = parseFloat(col);
			if ( cond(f) ) { return i; }
		}
	}
	
	return -1;
}

function detectLatCol(fields) {  return detectFloatRangeCol(fields, f=>(f >= 20 && f<= 50) );  }
function detectLngCol(fields) {  return detectFloatRangeCol(fields, f=>(f >= 120 && f<= 150) );  }

function detectTimeCol(fields, ex1, ex2) {
	const re = /[0-9]-|:/ ;
	
	const n = fields.length;
	for (var i = 0;i < n;++i) {
		if (i === ex1 || i === ex2) { continue; }
		
		const col = fields[i];
		if (re.test(col)) {
			return i;
		}
	}
	
	return -1;
}

function seemsDataRow(line) {
	const re = /[a-zA-Zぁ-ん亜-黑]/;
	if (!line) { return false; }

	var numNoDigit = 0;
	const fields = line.split(',');
	for (const col of fields) {
		if (re.test(col)) {
			++numNoDigit;
		}
	}
	
	return numNoDigit < (fields.length/2 + 1);
}

function seemsUsefulHeader(line) {
	const re_id  = /id/i ;
	const re_lat = /lat/i ;
	const re_lng = /lng|lon/i ;
	const re_tm  = /time|date/i
	const fields = line.split( / *, */ );

	const re_add = /([a-z0-9_]+) *: *(int|string|float|cfloat)/i ;
	let has_add = false;
	let id_found = false;
	
	let result = {};
	for (let i = 0;i < fields.length;++i) {
		const col = fields[i];
		if (re_id.test(col)) {
			if (!id_found) {
				result.id = i;
				id_found = true;
			}
		} else if (re_lat.test(col)) {
			result.lat = i;
		} else if (re_lng.test(col)) {
			result.lng = i;
		} else if (re_tm.test(col)) {
			result.time = i;
		} else {
			if (re_add.test(col)) {
				const name_part = RegExp['$1'];
				const type_part = RegExp['$2'].toLowerCase();
				if (!result.additional) {
					result.additional = [];
				}

				result.additional.push({
					name: name_part,
					type: type_part,
					column: i
				});
			}
		}
 	}

	if (result.hasOwnProperty('id'  ) && 
	    result.hasOwnProperty('lat' ) && 
	    result.hasOwnProperty('lng' ) && 
	    result.hasOwnProperty('time') ) {

		return result;
	}

	return null;
}

// -------------------------------------------------------------

export default PreviewTable;
