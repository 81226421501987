import ColorGeneratorBase from './ColorGeneratorBase'

const kOffscreenWidth = 256;

class BlendColorGenerator extends ColorGeneratorBase {
	constructor(name, title, colorList) {
		super(name, title);
		this.imageData = null;

		this.canvas = document.createElement('canvas');
		this.g = this.canvas.getContext('2d');
		this.configureCanvas(this.canvas);
		this.fill(this.g, colorList);
		
		this.imageData = this.g.getImageData(0, 1, kOffscreenWidth, 4);
	}

	configureCanvas(cv) {
		cv.width = kOffscreenWidth;
		cv.height = 8;
	}
	
	fill(g, colorList) {
		const grad = g.createLinearGradient(0, 0, kOffscreenWidth, 0);
		if (colorList) {
			const len = colorList.length;
			for (var i = 0;i < len;++i) {
				grad.addColorStop(i / (len-1), colorList[i]);
			}
		} else {
			grad.addColorStop(0.0 , '#fff');
			grad.addColorStop(1.0 , '#F54');
		}

		g.fillStyle = grad;
		g.fillRect(0, 0, kOffscreenWidth, 8);
	}

	getRGB(outRGB, position) {
		const max = kOffscreenWidth-1;
		var x = position * max;
		if (x > max) { x=max; }
		else if (x < 0) { x=0; }
		
		const pos = x << 2;
		
		const data = this.imageData.data;
		outRGB[0] = data[pos  ];
		outRGB[1] = data[pos+1];
		outRGB[2] = data[pos+2];
	}
}

export default BlendColorGenerator;