class PlayControl {
	constructor(receiver) {
		this.receiver = receiver;
		
		this.callback = this.enterFrame.bind(this);
		this.triggerSet = false;
		this.speed = 0;
		this.prevTime = -1;
	}

	setSpeed(s) {
		if (s !== this.speed) {
			this.speed = s;
			this.prevTime = -1;
		}
		
		if (s) {
			this.startIf();
		}
	}
	
	isPlaying() {
		return (this.speed !== 0);
	}
	
	startIf() {
		if (!this.triggerSet) {
			this.triggerSet = true;
			window.requestAnimationFrame( this.callback )
		}
	}
	
	enterFrame() {
		this.triggerSet = false;

		const curT = (new Date() - 0);
		var et = curT - this.prevTime;

		if (et < 1 || this.prevTime < 0) {et=1;}
		else if (et > 1000) {et=1000;}

		const toAdvance = et * this.speed / 1000.0;
		if (this.receiver) {
			this.receiver.autoplayAdvance(toAdvance);
		}

		this.prevTime = curT;
		if (this.speed) {
			this.triggerSet = true;
			window.requestAnimationFrame( this.callback )
		}
	}
}

export default PlayControl;