import React from 'react';
import { Component } from 'react';
import '../App.css';
import './MapArea.css';

import leaflet from 'leaflet'
import MapClockControl from '../widgets/MapClockControl'
import GateToolsControl from '../leafmob/map-gate-control'
import SelectToolsControl from '../leafmob/map-select-control'
import MapLegend from '../widgets/MapLegend'
import mob_layer from '../leafmob/mob-layer'
import leaf_grid_layer from '../leafmob/leaf-grid-layer'
import RendererBase from '../leafmob/renderer-base'
import LMRenderer from '../leafmob/renderer'
import LMGridRenderer from '../leafmob/grid-renderer'
import MarkerTexture from '../leafmob/marker-texture'
import { MovingObjectPickPool } from '../leafmob/md/MovingObject'
import ColoringPresetManager from '../model/ColoringPresetManager'
import { kMM_HideNonSelectedObject } from '../leafmob/SelectionDispMode'
import { annEnsureProperties , annUpdate, annUpdateTracer } from './MapAnnotation'

import LeafletGateFeature from '../leafmob/gate-feature'
import TrajectoryLayer from '../leafmob/TrajectoryLayer'
import ToggleControl from '../leafmob/LeafletToggleControl'

import { kAdditionalAttrsThresh } from '../misc-utils'
import PolygonGateFeature from '../leafmob/polygon-gate-feature';
import { InterpolationLimitType } from '../model/MMLayer';

const TEST_MARKER_IMAGE = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAMAAABrrFhUAAAAUVBMVEVHcExiufX13WJi9a0XZdgXn9hijPUAAAAAAAAAAA'+
'DXnRMT114EK2UESGUEZSplSgSLpfeLxveL98f36os2leI9e/I9svLyyj098o/YqyUl2HEer62nAAAACnRSTlMA////////ZNL634/tJwAAAN9JREFUeNrt1TmOwzAQBECTlKjLt+Hz/w'+
'9dJhtqNrSArUo7kRqD5m4HDOM0Dl/Mv/7/+652+/UvHA596g9BfvyU5Rjkp3eeTxtuYOzO59qN63l/uaQ+yD/Xa1mC/H275XncbgFTbQXcp/U8tQIeQV5aAa8gz62A57TlC6g1voCUwg'+
'tYSgkvYM550xfQNuD+xwY84g1YXvEGzM9Nb8C/fwUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA+PUDCb0GlantR7IAAAAASUVORK5CYII=';

var gNextGateIndex = 1;

class MapArea extends Component {
	constructor(props) {
		super(props);
		this.map = null;
		this.clockControl = null;
		this.basemaps = null;

		this.colorLegend = null;
		this.sizeLegend = null;

		this.mobLayerDic = {};
		this.trajLayerDic = {};
		this.poolDic = {};
		this.targetLayerList = null;
		this.rendererOrderDirty = true;
		
		this.defaultMarkerImage = new Image();
		this.defaultMarkerImage.onload = this.onDefaultMarkerLoad.bind(this);
		this.defaultMarkerImage.src = TEST_MARKER_IMAGE;
		
		this.selectionToolControl = null;
		this.targetMarker = null;
		this.peekToolTip = leaflet.tooltip();

		this.mmFeatureList = [];
	}

	render() {
	    return ( <div id="map-area" ref={ e=>this.onRenderContainer(e) }></div> ) ;
	}
	
	forceResize() {
		if (this.map) {
			this.map.invalidateSize();
		}
	}

	moveMap(lat, lng) {
		if (this.map) {
			this.map.panTo([lat, lng]);
		}
	}

	onRenderContainer(el) {
		if (!this.map) {
			this.map = leaflet.map(el).setView([35.6, 139.7], 10);
			this.map.on('mousemove', this.onMapMouseMove.bind(this));
			this.basemaps = addBasemaps(leaflet, this.map);
			this.generateTargetMarker();
			
			// Add tool buttons
			this.clockControl = this.addMapClockControl(this.map);

			const sel = new SelectToolsControl();
			sel.setPosition('topleft');
			sel.addTo(this.map);
			this.selectionToolControl = sel;

			const g = new GateToolsControl();
			g.setPosition('topleft');
			g.addTo(this.map);

			for (var i = 0;i < 2;++i) {
				const leg = new MapLegend();
				leg.setLegendTitle(i ? 'Color legend' : 'Size legend');
				leg.setPosition('bottomleft');
				leg.addTo(this.map);

				if (!i) {
					this.sizeLegend = leg;
					leg.setCellViewHeight(32);
				} else {
					this.colorLegend = leg;
				}
			}

			
			this.setupMapToggleControl(this.map);
			
			sel.setEventParent(this);
			g.setEventParent(this);
		}
	}

	addMapClockControl(map) {
		const clock = new MapClockControl();
		clock.setPosition('bottomright');
		clock.addTo(map);
		return clock;
	}

	generateTargetMarker(map) {
		const target_icon = leaflet.icon({
		    iconUrl: 'images/target.png',
		    iconSize: [16, 16],
		    iconAnchor: [8, 8],
		    popupAnchor: [8, 8],
		    tooltipAnchor: [8, 0]
		});

		const mk = leaflet.marker([35.6, 139.7], {icon: target_icon});
		mk._targetMovingObjectId = null;
		mk._targetLayer = null;
		mk.on('click', this.onPeekMarkerClick.bind(this));
		// mk.on('mousemove', e => { e.originalEvent.stopPropagation(); } );
		
		mk.bindTooltip(this.peekToolTip);
		this.targetMarker = mk;
		return mk;
	}

	setupMapToggleControl(map) {
		const c = new ToggleControl({
			position: 'topright',
			horizontal: true,
			onChange: this.onMapTypeToggle.bind(this),
			buttonList: [
				{
					id: 'D',
					title: 'Dark map',
					label: 'Dark',
					handler: null
				},
				{
					id: 'W',
					title: 'White map',
					label: 'White',
					handler: null
				},
				{
					id: 'N',
					title: 'Normal map',
					label: 'Normal',
					handler: null
				},
				{
					id: 'S',
					title: 'Satellite',
					label: 'Satellite',
					handler: null
				}
			]
		});
		c.selectFirst().addTo(map);
	}

	onMapTypeToggle(buttonData) {
		this.removeBasemaps();
		if (this.map) {
			this.basemaps[ buttonData.id ].addTo(this.map);
		}
	}
	
	removeBasemaps() {
		const m = this.map;
		if (m) {
			m.eachLayer(lyr => { if(lyr._mm_is_basemap) m.removeLayer(lyr); });
		}
	}

	onDefaultMarkerLoad() {
		this.redraw();
	}
	
	setTargetLayerList(ll) {
		this.targetLayerList = ll;
	}
	
	updateTimeDisplay(currentTime) {
		if (this.clockControl) {
			this.clockControl.setUnixtime(currentTime);
		}
	}

	redraw(pickTime, forceUpdate) {
		// Redraw map
		const ll = this.targetLayerList;
		if (!ll) { return; }

		this.updateAnnotationViews(ll, pickTime);
		this.prepareRendererResources(ll);
		this.reorderRendererIf(ll, this.map);
		this.prepareLayerDrawData(ll, pickTime);

		const len = ll.count();
		for (var i = 0;i < len;++i) {
			const layer = ll.getAt(i);
			const mobL = this.ensureRenderer(layer);
			const tOv = this.trajLayerDic[layer.layerId];
			
			if (mobL) {
				if (layer.hasMovingData()) {
					// 移動体のレイヤ

					const pickPool = this.poolDic[ layer.layerId ];
					const renderer = mobL.referRenderer();
					const r_list = renderer.referObjectList();

					const changeColor = layer.bindAttrIndex >= 0;
					const changeSize  = layer.secondAttrIndex >= 0;
					const maxChipY = this.fillRendererObjectList(r_list, pickPool,
						changeColor ? layer : null,
						changeSize ? layer : null
					);

					updateMarkerValidBounds(renderer, layer.markerSetting, layer.markerGenerator, maxChipY);

					renderer.setCompositionMode(layer.markerMode);
					mobL.setVisibility(layer.visibility);
					mobL.redraw(forceUpdate);

					// Annotation
					if (layer.annotation) {
						annUpdateTracer(pickPool, layer.annotation.features, this.map);
					}
				} else if (layer.refersMovingData()) {
					const renderer = mobL.referRenderer();
					renderer.setValueLabelVisible( layer.valueLabelVisible );
					renderer.generateGridConfig( layer.gridPresetName );
					renderer.setColorGenerator( ColoringPresetManager.find(layer.coloringKey) );
					renderer.coloringMaxValue = layer.valueColoringMax;
					renderer.setSpacing(layer.getSpacing());
					this.updateReferredData(ll, layer, renderer);
					
					mobL.setVisibility(layer.visibility);
					mobL.redraw(forceUpdate);
				}
			}
			
			if (tOv) {
				tOv.setOverlayVisibility(layer.trajectoryVisible);
			}
		}
	}

	updateAnnotationViews(layerList, currentTime) {
		const len = layerList.count();
		for (var i = 0;i < len;++i) {
			const layer = layerList.getAt(i);
			if (layer.annotation) {
				annEnsureProperties(layer.annotation.features, this.map);
				annUpdate(layer.annotation.features, this.map, currentTime);
			}
		}
	}

	updateReferredData(layerList, selfLayer, renderer) {
		const targetLayer = layerList.byId(selfLayer.targetId);
		if (targetLayer) {
			// Is any selected on the target?
			const sel = targetLayer.referSelection();
			const anySelected = (sel.count() > 0);
			renderer.setCountSelectedOnly( anySelected );

			// set pick pool
			const pickPool = this.poolDic[ targetLayer.layerId ] || null;
			renderer.setTargetPickPool(pickPool);
		} else {
			renderer.setCountSelectedOnly(false);
			renderer.setTargetPickPool(null);
		}
	}

	prepareRendererResources(layerList) {
		const len = layerList.count();
		for (var i = 0;i < len;++i) {
			const layer = layerList.getAt(i);
			const mobL = this.ensureRenderer(layer);
			const renderer = mobL.referRenderer();
			
			if (renderer) {
				const sel = layer.referSelection();
				const anySelected = (sel.count() > 0);
				var renderModeInSelection = RendererBase.SelectionMode.Highlight;
				if (layer.selectionAppearance & kMM_HideNonSelectedObject) {
					renderModeInSelection = RendererBase.SelectionMode.Visibility;
				}
				
				renderer.setSelectionMode( anySelected ? renderModeInSelection : RendererBase.SelectionMode.None );

				if (layer.markerSetting) {
					
					if (layer.markerSetting.coloringDirty) {
						const tov = this.trajLayerDic[ layer.layerId ];
						if (tov) {
							tov.invalidateSentColor();
						}
						layer.markerSetting.clearColoringDirty();
					}

					if (layer.markerSetting.dirty) {
						if (mobL) {
							updateMarkerTexture(renderer, layer.markerSetting, layer.markerGenerator);

							this.colorLegend.setNumOfVariation(layer.markerSetting.primaryVariation);
							this.colorLegend.setColoringKey(layer.markerSetting.coloringKey);
							this.colorLegend.setLegendVisibility(layer.legendVisibilities[0]);
							this.colorLegend.rebuildIfDirty();

							this.sizeLegend.setNumOfVariation(layer.markerSetting.secondaryVariation);
							this.sizeLegend.setSizingType(layer.markerSetting.sizingType);
							this.sizeLegend.setLegendVisibility(layer.legendVisibilities[1]);
							this.sizeLegend.rebuildIfDirty();

							layer.markerSetting.clearDirty();
						}
					}

					// 軌 跡 描 画 に 選 択 状 態 を 反 映 -----------------
					this.sendTrajectorySelectionStateIf(layer);
				}
			}
		}
	}

	sendTrajectorySelectionStateIf(layer) {
		const sel = layer.selection;
		const tov = this.trajLayerDic[ layer.layerId ];
		if (!tov || !sel) { return; }

		if (!tov.selectionSent) {
			if (!layer.selectionTrajectoryAppearance) {
				tov.sendSelectedIDs( null );
			} else {
				tov.sendSelectedIDs( sel.idIterator() );
			}
		}
	}

	markPickPoolDirty(layerId) {
		const pickPool = this.poolDic[ layerId ];
		if (pickPool) {
			pickPool.forceDirty();
		}
	}
	
	markSelectionDispDirty(layerId) {
		const o = this.trajLayerDic[ layerId ];
		if ( o ) {
			o.invalidateSentSelection();
		}
	}

	prepareLayerDrawData(layerList, pickTime) {
		const len = layerList.count();
		for (var i = 0;i < len;++i) {
			const layer = layerList.getAt(i);
			
			// MAIN
			const mobL = this.ensureRenderer(layer);
			if (mobL) {
				if (layer.hasMovingData()) {
					const pickPool = this.poolDic[ layer.layerId ];
					const useTail = !!( layer.tailMode );
					pickPool.setTailEnabled(useTail);
					pickPool.setTailDuration( layer.tailDuration );

					var useAngle = false;
					const useShift = !!layer.dirShift;
					if (layer.markerSetting && layer.markerSetting.deform) {
						// 変形が有効な場合は移動方向のデータを準備
						useAngle = true;
					}

					if (useAngle || useShift) {
						if (layer.data.ensureDerivedData()) {
							// データ作成直後は再ピック
							pickPool.forceDirty();
						}
					}

					const renderer = mobL.referRenderer();
					renderer.setCurrentTime(pickTime);
					renderer.setUseTail(useTail);
					renderer.setTailDuration( layer.tailDuration );
					renderer.setUseAngle(useAngle);
					renderer.setDirShiftAmount(useShift ? 3 : 0);
					if (useTail) { renderer.fillTailColorPalette(layer); }

					if (pickPool.isDirty(pickTime)) {
						this.hideTargetMarker();

						// Update dirty check info
						pickPool.setPrevPickInfo(pickTime);
						// ------
						pickPool.reset();
						layer.data.pick(pickPool, pickTime);
						applyExpressionFilter(pickPool, layer);
					}
				}
			}

			// TJ
			const tOv = this.ensureTrajectoryOverlay(layer);
			if (tOv) {
				if (!layer._stJob) {
					layer._stJob = new SendTrajJob(layer, tOv);
				}
				
				const isAdd = !!(layer.trajectoryVisible & 0x2);
				var tclr = layer.trajectoryColor;
				var colorAttrName = null;
				if (layer.trajectoryColoringRule) {
					tclr = TrajectoryLayer.USE_MARKER_COLOR;
					
					colorAttrName = layer.getBoundAttributeName();
					updateMarkerColorGenerator(layer.markerGenerator, layer.markerSetting);
				}

				tOv.setDefaultColor(tclr, layer.trajectoryOpacity, isAdd);

				if (!tOv.dataSent) {
					// Send all
					this.sendTrajectorySelectionStateIf(layer); // Initial selection state
					layer._stJob.start(SendTrajJob.MODE_ALL, colorAttrName);
//					s_endTrajectoryData(tOv, layer, colorAttrName);
				} else {
					let colorAttrNameToSend = null;
					// Send color
					if (!tOv.colorSent && layer.trajectoryColoringRule) {
						colorAttrNameToSend = colorAttrName;
//						s_endTrajectoryColorData(tOv, layer, colorAttrName);
					}

					// 補間制限があり、かつdirtyな場合はマスク情報に使う
					let maskChanged = false;
					const limit = getInterpolationLimitIfDirty(layer);
					if (limit && limit.dirty) {
						maskChanged = true;
						limit.dirty = false;

						tOv.sendMaskAppearance(limit.appearance);
						tOv.sendMaskActive(limit.active);
					}

					// Start job if needed
					if (colorAttrNameToSend || maskChanged) {
						layer._stJob.start(SendTrajJob.MODE_PART, colorAttrNameToSend, limit);
					}
				}
			}
		}
	}

	fillRendererObjectList(dest, pool, colorProvider, sizeProvider) {
		const n = pool.pickedCount;
		const srcList = pool.referRaw();
		var maxCY = 0;

		dest.length = n;
		for (var i = 0;i < n;++i) {
			const rec = srcList[i];
			const attrs = rec.attrList;

			if (colorProvider) {
				rec.chipX = colorProvider.calcChipX(attrs);
			} else {
				rec.chipX = 0;
			}

			if (sizeProvider) {
				rec.chipY = sizeProvider.calcChipY(attrs);
				maxCY = Math.max(maxCY, rec.chipY);
			} else {
				rec.chipY = 0;
			}

			rec.lat = attrs[0];
			rec.lng = attrs[1];
			
			dest[i] = rec;
		}

		return maxCY;
	}

	ensureRenderer(layer) {
		const lid = layer.layerId;
		var ml = this.mobLayerDic[lid];
		if ( !ml ) {
			layer.watchRemoval(this);
			
			if (layer.hasMovingData()) {
				// create
				this.rendererOrderDirty = true;
				ml = this.setupLeafMob(this.map);
				this.mobLayerDic[lid] = ml;

				const pl = new MovingObjectPickPool();
				this.poolDic[lid] = pl;
				pl.ensureCapacity( layer.data.countIDs() );
			} else {
				// create
				this.rendererOrderDirty = true;
				ml = this.setupLeafGrid(this.map);
				this.mobLayerDic[lid] = ml;
			}
		}
		
		return ml;
	}

	ensureTrajectoryOverlay(layer) {
		var o = null;
		if (layer.hasMovingData() && layer.trajectoryVisible) {
			const lid = layer.layerId;
			o = this.trajLayerDic[lid];
			if ( !o ) {
				// create
				this.rendererOrderDirty = true;
				o = this.setupLMTrajectoryOverlay(this.map);
				this.trajLayerDic[lid] = o;
			}
		}
		
		return o;
	}

	invalidateRenderOrder() {
		this.rendererOrderDirty = true;
	}

	reorderRendererIf(ll, map) {
		if (!this.rendererOrderDirty) { return; }
		const dic = this.mobLayerDic;
		const t_dic = this.trajLayerDic;

		for (var i = (ll.count()-1) ; i >= 0 ; --i) {
			const layer = ll.getAt(i);
			const lid = layer.layerId;

			const mobL = dic[lid];
			const tOv  = t_dic[lid];
			
			if (tOv) {
				tOv.bringToFront();
			}

			if (mobL) {
				mobL.moveToFront();
			}
		}
		
		this.rendererOrderDirty = false;
	}

	layerWillBeRemoved(layer) {
		const lid = layer.layerId;
		const mobLayer = this.mobLayerDic[ lid ];
		if (mobLayer) {
			mobLayer.dispose();
			delete this.mobLayerDic[ lid ];
		} 
		
		if (this.poolDic[lid]) {
			this.poolDic[lid].dispose();
			delete this.poolDic[lid];
		}
		
		const tm = this.trajLayerDic;
		if (tm[lid]) {
			if (tm[lid].dispose) {  tm[lid].dispose();  }
			delete tm[lid];
		}
	}

	setupLeafMob(map) {
		const renderer = new LMRenderer();

		const mobLayer = mob_layer.create({
			renderer: renderer
//			canvasPreviewId: 'canvas-preview'
		});
		mobLayer.addTo(map);

		const tex = new MarkerTexture(false);
		tex.fromImage(renderer.gl, this.defaultMarkerImage);
		renderer.setMarkerTexture(tex);

		return mobLayer;
	}
	
	setupLMTrajectoryOverlay(map) {
		const o = new TrajectoryLayer({ workerPath:'/workers/' });
		o.addTo(map);
		return o;
	}

	setupLeafGrid(map) {
		const renderer = new LMGridRenderer();
		
		const gridLayer = leaf_grid_layer.create({
			renderer: renderer
		});

		gridLayer.addTo(map);
		return gridLayer;
	}

	on_SelToolClick(buttonType) {
		console.log("[EV] Selection tool button click");
		const ctrl = this.selectionToolControl;
		const newValue = !ctrl.isPeekButtonSelected();

		if (!newValue) {
			this.hideTargetMarker();
		}

		ctrl.setPeekButtonSelected(newValue);
		this.setPeekModeAttr(newValue);
	}
	
	setPeekModeAttr(enabled) {
		const pn = this.map.getPanes().mapPane;
		if (pn) {
			pn.setAttribute('data-peek-mode', enabled ? '1' : '');
		}
	}

	onPeekMarkerClick(e) {
		const marker = e.target;
		if (marker.hasOwnProperty('_targetMovingObjectId')) {
			const layer = marker._targetLayer;
			const sel = layer.selection;

			if (sel.count() != 1 || !sel.has(marker._targetMovingObjectId)) {
				sel.clear();
				sel.selectId(marker._targetMovingObjectId);
				sel.fire();
			}
		}
	}

	onMapMouseMove(e) {
		const oe = e.originalEvent;
		const sender = oe.target;
		
		if (sender) {
			if (!sender.getContext) { return; }
		}

		if ( this.selectionToolControl && this.selectionToolControl.isPeekButtonSelected() ) {
			const layer = this.requestCurrentLayer();
			if (layer) {
				const lid = layer.layerId;
				const pool = this.poolDic[lid];
				if (pool) {
					const sel = layer.referSelection();
					const anySelected = (sel.count() > 0);

					const foundMarkerObj = this.hittestInPool(pool, oe.offsetX, oe.offsetY, anySelected);

					if (foundMarkerObj) {
						this.targetMarker.setLatLng( foundMarkerObj );
						if ( !this.isTargetMarkerShown() ) {
							this.targetMarker.addTo(this.map);
						}

						this.targetMarker._targetMovingObjectId = foundMarkerObj.id;
						this.targetMarker._targetLayer = layer;

						const peekTbl = this.buildPeekContent(foundMarkerObj, layer);
						this.peekToolTip.setContent( peekTbl );
					} else {
						this.hideTargetMarker();
					}
				}
			}
		}
	}

	buildPeekContent(movingObj, layer) {
		const tbl = document.createElement('table');
		const tbody = document.createElement('tbody');
		tbl.className = 'mm-peek-table';

		const id_row = make_twocol_row("ID", movingObj.id);
		tbody.appendChild(id_row);

		pickListedAttributes(layer, movingObj.attrList, (name, index, attrPos, attrValue) => {
			const attr_row = make_twocol_row(name, attrValue);
			tbody.appendChild(attr_row);
		} );

		tbl.appendChild(tbody);
		return tbl;
	}

	isTargetMarkerShown() {
		return this.map.hasLayer(this.targetMarker);
	}

	hideTargetMarker() {
		if (this.isTargetMarkerShown()) {
			this.map.removeLayer(this.targetMarker);
		}
	}

	hittestInPool(pool, mx, my, considerSelection) {
		var minD = 99;
		var found = null;
		for (const obj of pool.array) {
			if (considerSelection) {
				if (!obj.selected) { continue; }
			}
			
			const dx = obj.sx - mx;
			const dy = obj.sy - my;
			const d = dx*dx + dy*dy;
			if (d < minD) {
				minD = d;
				found = obj;
			}
		}
		
		if (found && minD < 49) {
			return found;
		}
		
		return null;
	}

	requestCurrentLayer() {
		if (this.props.selectedLayerProvider) {
			const lyr = this.props.selectedLayerProvider.getSelectedLayer();
			return lyr;
		}
		return null;
	}

	

	on_GateToolClick(buttonType) {
		if (this.props.onGatePutRequest) {
			this.props.onGatePutRequest(buttonType);
		}

		if (buttonType === GateToolsControl.IS_PUT_LINE_BUTTON) {
			navigator.clipboard.readText().then( cptext => {
				const maybeGeoJSON = looksLikeGateGeoJSON(cptext);
				this.putNewLineGate(maybeGeoJSON);
			}).catch(
				err => {
					this.putNewLineGate(null);
				}
			);
		} else {
			this.putNewPolygonGate(null);
		}
	}

	putNewLineGate(sourceGeoJson) {
		const q = [];

		if (sourceGeoJson) { checkGateAndAdd(q, sourceGeoJson.features); }
		if (q.length < 1) { q.push(null); }

		var nPasted = 0;
		var lastFeature = null;
		for (const entry of q) {
			var feature = null;
			if (!entry) {
				const newId = avoidDupId( this.mmFeatureList, this.makeNewFeatureId('linegate') );
				feature = new LeafletGateFeature( newId );
				feature.setCommandIconPath('/images/gate-cmd');
				feature.setMap(this.map, true);
			} else {
				const newId = avoidDupId( this.mmFeatureList, entry.properties.mmId );
				feature = new LeafletGateFeature( newId );
				feature.setCommandIconPath('/images/gate-cmd');
				feature.setMap(this.map, false);

				feature.locateManual(entry.geometry.coordinates);
				feature.renewLine();
				++nPasted;
				
				lastFeature = feature;
			}

			this.mmFeatureList.push(feature);
			feature.setEventParent(this);
		}

		if (nPasted > 0) {
			this.on_GateSuccessMessageRequested(null, "Pasted from clipboard.");
		}

		if (lastFeature) {
			this.moveToFeature(lastFeature);
		}
	}

	putNewPolygonGate(sourceGeoJson) {
		const newId = avoidDupId( this.mmFeatureList, this.makeNewFeatureId('polygongate') );
		const feature = new PolygonGateFeature( newId, this.map, true );
		feature.setCommandIconPath('/images/gate-cmd');
		feature.generateIconMarkers();

		this.mmFeatureList.push(feature);
		feature.setEventParent(this);
	}

	moveToFeature(f) {
		if (this.map) {
			this.map.panTo( f.representativeCoord() );
		}
	}

	makeNewFeatureId(prefix) {
		return prefix + '-' + (gNextGateIndex++);
	}

	afterFeatureRemoved(fid) {
		const index = this.featureIndexById(fid);
		if (index >= 0) {
			this.mmFeatureList.splice(index, 1);
		}
	}

	featureIndexById(fid) {
		const ls = this.mmFeatureList;
		const n = ls.length;

		for (var i = 0;i < n;++i) {
			if (ls[i].id === fid) { return i; }
		}

		return -1;
	}

	on_GateFunctionWillAppear(gateFeature) {
		if (this.props.onGateFunctionWillAppear) {
			this.props.onGateFunctionWillAppear(gateFeature);
		}
	}
	
	on_GateInvokeServerRequested(senderGateFeature, options) {
		if (this.props.onGateInvokeServerRequested) {
			this.props.onGateInvokeServerRequested(senderGateFeature, options);
		}
	}

	on_GateExecRequested(senderGateFeature, options) {
		if (this.props.onGateExecRequest) {
			this.props.onGateExecRequest(senderGateFeature, options);
		}
	}

	on_GateRemoveRequested(senderGateFeature) {
		if (this.props.onGateRemoveRequest) {
			this.props.onGateRemoveRequest(senderGateFeature);
		}

		this.on_GateSuccessMessageRequested(senderGateFeature, "Removed a gate.");
	}

	on_GateSuccessMessageRequested(gate, message) {
		if (this.props.onGateSuccessMessageRequested) {
			this.props.onGateSuccessMessageRequested(gate, message);
		}
	}
}


class SendTrajJob {
	constructor(layer, overlay) {
		this.tick_closure = this.consume.bind(this);
		this.chunkSize = 500;
		
		this.targetLayer = layer;
		this.targetOverlay = overlay;
		this.mode = SendTrajJob.MODE_STOP;
		this.isNextSet = false;
		
		this.jobData = {
			idList: null,
			numDone: 0,
			colorAttributeName: null,
			maskConfig: null
		};
	}

	resetData(arg1, arg2) {
		const md = this.targetLayer.data;
		this.jobData.idList = md.referFlattenList();
		this.jobData.numDone = 0;
		this.jobData.colorAttributeName = arg1;
		this.jobData.maskConfig = arg2 || null;
	}

	start(mode, arg1, arg2) {
		this.mode = mode;
		this.resetData(arg1, arg2);
		this.reserveNextIf();

		this.targetLayer.setTrajectoryBusy(0.001);
		if (mode === SendTrajJob.MODE_ALL) {
			this.targetOverlay.beginTrajectoryWrite();
		} else {
			this.targetOverlay.clearTileContents();
		}
	}
	
	reserveNextIf() {
		if (!this.isNextSet) {
			setTimeout(this.tick_closure, 1);
			this.isNextSet = true;
		}
	}
	
	consume() {
		this.isNextSet = false;

		const jd = this.jobData;
		const baseIndex = jd.numDone;
		const rest = jd.idList.length - jd.numDone;
		if (rest < 1) {
			this.terminate();
			return;
		}

		var i;
		var n = this.chunkSize;
		if (rest < n) { n=rest; }
		
		if (this.mode === SendTrajJob.MODE_ALL) {
			for (i = 0;i < n;++i) {
				const tset = jd.idList[baseIndex + i];
				sendTrajectoryDataOfTset(this.targetLayer, this.targetOverlay, tset, jd.colorAttributeName);
			}
		} else {
			for (i = 0;i < n;++i) {
				const tset = jd.idList[baseIndex + i];
				sendColorOfTset(this.targetLayer, this.targetOverlay, tset, jd.colorAttributeName, jd.maskConfig);
			}
		}
		
		jd.numDone += n;
		if (jd.numDone < jd.idList.length) {
			this.targetLayer.setTrajectoryBusy(jd.numDone / jd.idList.length);
			this.reserveNextIf();
		} else {
			this.terminate();
		}
	}
	
	terminate() {
		this.targetLayer.setTrajectoryBusy(0);
		const ov = this.targetOverlay;
		ov.forceRedraw();

		if (this.mode === SendTrajJob.MODE_ALL) {
			ov.dataSent = true;
		}
		ov.colorSent = !!this.jobData.colorAttributeName;

		this.mode = SendTrajJob.MODE_STOP;
	}
}
SendTrajJob.MODE_STOP  = 0;
SendTrajJob.MODE_ALL   = 1;
SendTrajJob.MODE_PART  = 2;


function sendTrajectoryData(mapOverlay, layer, colorAttributeName) {
	mapOverlay.beginTrajectoryWrite();
	
	const md = layer.data;
	const idlist = md.referFlattenList();
	const n = idlist.length;
	for (var i = 0;i < n;++i) {
		const tset = idlist[i];
		sendTrajectoryDataOfTset(layer, mapOverlay, tset, colorAttributeName);
	}
	
	mapOverlay.forceRedraw();
	mapOverlay.dataSent = true;
	mapOverlay.colorSent = !!colorAttributeName;
}

function getInterpolationLimitIfDirty(layer) {
	let interpolationLimit = null;
	if (layer.data) { interpolationLimit = layer.data.interpolationLimit || null; }

	if (interpolationLimit && interpolationLimit.type === InterpolationLimitType.Distance) {
		layer.data.ensureDerivedData();
	}

	if (interpolationLimit && interpolationLimit.dirty) {
		return interpolationLimit;
	}

	return null;
}

function getInterpolationLimitIfActive(layer) {
	let interpolationLimit = null;
	if (layer.data) { interpolationLimit = layer.data.interpolationLimit || null; }

	if (interpolationLimit && interpolationLimit.active) {
		if (interpolationLimit.type === InterpolationLimitType.Distance) {
			layer.data.ensureDerivedData();
		}

		return interpolationLimit;
	}

	return null;
}

function sendTrajectoryDataOfTset(layer, mapOverlay, tset, colorAttributeName) {

	const latList = tset.attrs['lat'];
	const lngList = tset.attrs['lng'];

	// additional attribute (if specified)
	let c_array = null;
	let m_array = null;
	if (colorAttributeName) {
		const aList = tset.attrs[colorAttributeName];
		c_array = layer.makeColorMappedArray(aList.arr);
	}

	const interpolationLimit = getInterpolationLimitIfActive(layer);
	if (interpolationLimit) {
		m_array = layer.makeInterpolationMaskArray(tset, interpolationLimit.type, interpolationLimit.threshold);
	}

	// console.log(m_array)
	mapOverlay.addTrajectoryArrays(tset.ownerId, latList.arr, lngList.arr, c_array, m_array);
}

function sendTrajectoryColorData(mapOverlay, layer, colorAttributeName) {
	if (!colorAttributeName) { return false; }
	
	const md = layer.data;
	const idlist = md.referFlattenList();
	const n = idlist.length;
	for (var i = 0;i < n;++i) {
		const tset = idlist[i];
		const aList = tset.attrs[colorAttributeName];
		const c_array = layer.makeColorMappedArray(aList.arr);

		mapOverlay.sendColorArray(tset.ownerId, c_array);
	}

	mapOverlay.forceRedraw();
	mapOverlay.colorSent = true;

	return true;
}

function sendColorOfTset(layer, mapOverlay, tset, colorAttributeName, maskConfig) {
	if (colorAttributeName) { 
		const aList = tset.attrs[colorAttributeName];
		const c_array = layer.makeColorMappedArray(aList.arr);
		mapOverlay.sendColorArray(tset.ownerId, c_array);
	}

	if (maskConfig) {
		if (maskConfig.active) {
			const m_array = layer.makeInterpolationMaskArray(tset, maskConfig.type, maskConfig.threshold);
			// console.log(m_array);
			mapOverlay.sendMaskArray(tset.ownerId, m_array);
		}

//		mapOverlay.sendColorArray(tset.ownerId, c_array);
	}


	return true;
}

function updateMarkerSourceImage(generator, markerSetting) {
	const texSize = generator.autoResize(markerSetting.chipSize.width, markerSetting.primaryVariation, markerSetting.secondaryVariation);
	const cg = ColoringPresetManager.find( markerSetting.coloringKey );

	generator.generate(cg, markerSetting);
	return texSize;
}

function updateMarkerColorGenerator(generator, markerSetting) {
	const cg = ColoringPresetManager.find( markerSetting.coloringKey );
	generator.updateLastColorGenerator(cg);
}

function updateMarkerTexture(renderer, markerSetting, generator) {
	const tex = renderer.referMarkerTexture();
	const tSize = updateMarkerSourceImage(generator, markerSetting);

	tex.autoResize(tSize);
	tex.setChipData(
		markerSetting.chipSize.width,
		markerSetting.chipSize.height,
		markerSetting.center.x,
		markerSetting.center.y
	);
	
	tex.setChipBounds( generator.currentChipBounds );
	tex.fromImage( renderer.gl, generator.canvas );
}

function updateMarkerValidBounds(renderer, markerSetting, generator, maxRow) {
	generator.detectChipBounds(null, markerSetting, maxRow);

	const tex = renderer.referMarkerTexture();
	tex.setChipBounds( generator.currentChipBounds );
}


function addBasemaps(L) {
	return {
		D: createMieruneLayer(L, 'dsbd-dark-map-tile'),
		W: createMieruneLayer(L, 'dsbd-white-map-tile'),
		N: createMieruneLayer(L, 'dsbd-normal-map-tile'),
		S: createSatelliteLayer(L)
	};
}

function createMieruneLayer(L, kls) {
//	const base = "https://tile.mierune.co.jp/";
	const base = "https://api.maptiler.com/maps/";
	const style = "jp-mierune-streets/256";
	const auth = "?key=jCUDT0Csn2ncQP6Ujjhp";
	const url = base + style + "/{z}/{x}/{y}@2x.png" + auth;

	const layer = new L.tileLayer(url, {
		className: kls,
	    attribution: '<a href="https://maptiler.jp/" target="_blank">&copy; MIERUNE</a> <a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
	});
	
	Object.defineProperty(layer, '_mm_is_basemap', {value: true});
	return layer;
}

function createSatelliteLayer(L) {
	const layer = new L.tileLayer('https://api.maptiler.com/tiles/satellite/{z}/{x}/{y}.jpg?key=jCUDT0Csn2ncQP6Ujjhp', {
	    attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
	});
	
	Object.defineProperty(layer, '_mm_is_basemap', {value: true});
	return layer;
}

function make_twocol_row(text1, text2) {
	const tr = document.createElement('tr');

	const c1 = document.createElement('td');
	c1.className = 'mm-peek-row-head';
	c1.appendChild(document.createTextNode(text1));
	tr.appendChild(c1);

	const c2 = document.createElement('td');
	c2.className = 'mm-peek-row-value';
	c2.appendChild(document.createTextNode(text2));
	tr.appendChild(c2);

	return tr;
}

function pickListedAttributes(layer, attrList, callback) {
	if (layer.orderedAttributeNames && callback) {
		const nAll = layer.orderedAttributeNames.length;
		const nValid = nAll - kAdditionalAttrsThresh;
		if (nValid > 0) {
			for (var i = 0;i < nValid;++i) {
				const itemPos = i+kAdditionalAttrsThresh;
				callback(layer.orderedAttributeNames[itemPos], i, itemPos, attrList[itemPos]);
			}
		}
	}
}

function looksLikeGateGeoJSON(text) {
	var j = null;
	try {
		j = JSON.parse(text);
	} catch(e) {
		return null;
	}

	if (!Array.isArray(j.features)) { return false; }

	const first = j.features[0];
	if (first && first.hasOwnProperty('geometry')) {
		return j;
	} else {
		return null;
	}
}

function checkGateAndAdd(dest, featureList) {
	for (const ft of featureList) {
		if (!ft.properties) { continue; }
		if (!ft.properties.mmId) { continue; }
		if (ft.properties.mmType !== 'line-gate') { continue; }

		if (ft.geometry && ft.geometry.type === 'LineString' && ft.geometry.coordinates) {
			if (checkCoordinateArrayValid(ft.geometry.coordinates)) {
				dest.push(ft);
			}
		}
	}
}

function checkCoordinateArrayValid(srcCoords) {
	if (!Array.isArray(srcCoords)) { return false; }
	if (srcCoords.length < 2) { return false; }

	for (const pt of srcCoords) {
		if (!Array.isArray(pt)) { return false; }
		if (pt.length < 2) { return false; }

		const v1 = pt[0];
		const v2 = pt[1];

		if (!Number.isFinite(v1)) { return false; }
		if (!Number.isFinite(v2)) { return false; }
	}

	return true;
}

// 同じIDがある場合は -copied を付加した新しいIDを生成する
function avoidDupId(featureList, newId) {
	var candidate = newId;
	for (var i = 0;i < 99;++i) {
		if (checkDupId(featureList, candidate)) {
			candidate = candidate + '-copied';
		} else {
			return candidate;
		}
	}
	
	return null;
}

function checkDupId(featureList, newId) {
	for (const f of featureList) {
		if (f.id === newId) { return true; }
	}

	return false;
}

// フィルター適用
function applyExpressionFilter(pickPool, layer) {
	const fx = layer.filterExpression;
	if (fx) {
		// enabled

		const n = pickPool.pickedCount;
		const srcList = pickPool.referRaw();
		for (var i = 0;i < n;++i) {
			const rec = srcList[i];
			rec.filterResult = fx.evaluate(rec.attrList);
			// console.log(rec, rec.filterResult)
		}
	}
}

export default MapArea;
