import MMLayer from './MMLayer'
import CSVFileLoader from './CSVFileLoader'
import RemoteLoader from '../remote/MMRemoteLoader'
import MovingData from '../leafmob/md/MovingData'
import AttributeMapping from '../leafmob/md//AttributeMapping'

import MarkerSetting from './MarkerSetting'
import MarkerImageGenerator from '../marker/MarkerImageGenerator'
import {MarkerSizingPresetNames} from '../marker/MarkerSizingPreset';
import { xf_deserialize } from './ExpressionFilter'

import { InterpolationLimitType } from './MMLayer';

const USE_NORMAL_TAIL = 1;

class MovingObjectLayer extends MMLayer {
	constructor() {
		super();
		this._stJob = null;
		this.attributeMapping = new AttributeMapping();
		this.data = new MovingData();
		this.orderedAttributeNames = null;
		this.bindAttrIndex = -1;
		this.trajectoryBusy = false;
		this.tailMode = 0;
		this.tailDuration = 300;

		this.secondAttrIndex = -1;

		// 0:None  1:Normal  2:Add
		this.trajectoryVisible = 0;
		this.trajectoryColor   = null;
		this.trajectoryOpacity = 1;

		// 0:fixed  1:Use marker color
		this.trajectoryColoringRule = 0;
		
		this.markerMode = 1;
		this.dirShift = false;

		this.markerSetting = new MarkerSetting(this);
		this.markerSetting.setEventParent(this);

		this.markerGenerator = new MarkerImageGenerator();

		// サーバ連携テストの設定
		this.xComputeServer = null;
	}

	setInterpolationLimit(type, forceDirty) {
		let opt = null;
		if (this.data) {
			opt = this.data.interpolationLimit;
		}

		if (opt) {
			// Only if the type is changed
			if (forceDirty || opt.type !== type) {

				const a = (type !== InterpolationLimitType.None);
				opt.active = a;
				opt.type = type;
				opt.dirty = true;

				this.eventInvoke('InterpolationLimitChanged', this);
			}
		}
	}

	setInterpolationThreshold(th) {
		if (this.data) {
			const opt = this.data.interpolationLimit;
			if (opt) {
				opt.threshold = th;
				opt.dirty = true;
			}
		}
	}

	setInterpolationAppearance(a) {
		if (this.data) {
			const opt = this.data.interpolationLimit;
			if (opt && opt.appearance !== a) {
				opt.appearance = a;
				opt.dirty = true;
			}
		}
	}

	hasDataError() {
		if (this.data && this.data.countErrors) {
			return this.data.countErrors();
		}

		return 0;
	}

	referDataErrorList() {
		if (!this.data) {  return null;  }
		return this.data.errorList ;
	}

	clearDataError() {
		if (this.data) {
			this.data.errorList.length = 0;
		}

		this.eventInvoke('LayerErrorCleared', this);
	}

	getRemainMode() {
		return this.data.getRemainMode();
	}

	setRemainMode(beforeFirst, afterLast) {
		this.data.setRemainMode(beforeFirst, afterLast);
		this.eventInvoke('RemainModeChange', this);
	}

	setTailMode(m) {
		if (this.tailMode !== m) {
			this.tailMode = m;
			this.eventInvoke('TailModeChange', this);
		}
	}

	setTailDuration(d) {
		if (this.tailDuration !== d) {
			this.tailDuration = d;
			this.eventInvoke('TailDurationChange', this);
		}
	}

	hasMovingData() { return true; }

	setTrajectoryBusy(b) {
		if (this.trajectoryBusy !== b) {
			this.trajectoryBusy = b;
			this.eventInvoke('TrajectoryBusyChange', this, b);
		}
	}
	
	setTrajectoryVisibility(v) {
		if (v !== this.trajectoryVisible) {
			this.trajectoryVisible = v;
			this.eventInvoke('TrajectoryVisibilityChange', this, v);
		}
	}

	setTrajectoryColoringRule(r) {
		if (r !== this.trajectoryColoringRule) {
			this.trajectoryColoringRule = r;
			this.eventInvoke('TrajectoryColoringRuleChange', this, r);
		}
	}

	setTrajectoryColor(c) {
		if (c !== this.trajectoryColor) {
			this.trajectoryColor = c;
			this.eventInvoke('TrajectoryColorChange', this, c);
		}
	}
	
	setTrajectoryOpacity(o) {
		const d = Math.abs(this.trajectoryOpacity - o);
		if (d > 0.001) {
			this.trajectoryOpacity = o;
			this.eventInvoke('TrajectoryOpacityChange', this, o);
		}
	}

	setMarkerMode(m) {
		if (this.markerMode !== m) {
			const v = (m !== 0);
			this.markerMode = m;
			this.setVisibility(v, true);

			this.eventInvoke('MarkerModeChange', this, m);
		}
	}

	setDirShift(b) {
		if (this.dirShift !== b) {
			this.dirShift = b;
			this.eventInvoke('DirShiftChange', this, b);
		}
	}

	getTimeRange() {
		return this.data.timeRange;
	}

	getBoundAttributeName() {
		if (this.bindAttrIndex < 0) { return null; }
		if (!this.orderedAttributeNames) { return null; }
		
		return this.orderedAttributeNames[this.bindAttrIndex] || null;
	}

	getSecondBoundAttributeName() {
		const i = this.secondAttrIndex;
		if (i < 0) { return null; }
		if (!this.orderedAttributeNames) { return null; }
		
		return this.orderedAttributeNames[i] || null;
	}

	createLoader(file, isZip) {
		this.loader = new CSVFileLoader(this, isZip);
		return this.loader;
	}

	creteRemoteLoader(file, isZip) {
		this.loader = new RemoteLoader(this, isZip);
		return this.loader;
	}

	addAttribute(name, columnIndex, type, hidden, optionFlags) {
		return this.attributeMapping.add(name, columnIndex, type, hidden, optionFlags);
	}

	countAndLoad() {
		const idattr = this.attributeMapping.byName('id');
		if (!idattr) {
			console.log("%c[WAIT!] Attribute mapping is not ready", "color:#F08");
			return;
		}

		const sendVal = (this.loader.numHeaderLines << 16) | idattr.columnIndex;
		this.loader.customPreLoad( sendVal );
	}

	setBindAttrIndex(i, primOrSecond) {
		if (!primOrSecond) {
			// Primary bind
			if (this.bindAttrIndex !== i) {
				this.bindAttrIndex = i;
				this.eventInvoke('LayerBindAttrChange', this, i);
			}
		} else {
			// Secondary bind
			if (this.secondAttrIndex !== i) {
				this.secondAttrIndex = i;
				this.eventInvoke('LayerBindAttrChange', this, i);
			}
		}
	}

	calcChipX(attributeList) {
		const v = attributeList[ this.bindAttrIndex ];
		if (v < 0) { return 0; }
		
		const max = this.markerSetting.primaryVariation - 1;
		if (v > max) { return max; }
		
		return v;
	}

	calcChipY(attributeList) {
		const v = attributeList[ this.secondAttrIndex ];
		if (v < 0) { return 0; }

		const max = this.markerSetting.secondaryVariation - 1;
		if (v > max) { return max; }
		
		return v;
	}

	rebuildOrderedAttributeNames() {
		if (this.data) {
			const tset = this.data.referTSetAt(0);
			if (tset) {
				this.orderedAttributeNames = tset.makeOrderedAttributeArray();
			}
		}
	}

	// LOADER HANDLER ------------------------
	loaderOnCountProgress(prog) {
		this.idCountProgress = prog;
		this.eventInvoke('LayerIdCountProgressChange', this, prog);
	}
	
	loaderAfterIdCount(count, idMap) {
		this.loaderOnProgressChange(0);
		this.setSubTitle(count + ' objects');
		const firstTset = this.data.allocate(idMap, this.attributeMapping);
		this.orderedAttributeNames = firstTset.makeOrderedAttributeArray();
	}

	loaderOnFieldset(fields, lineNo) {
		AttributeMapping.parseFieldsWithMapping(
			this.data, fields, this.attributeMapping, lineNo
		);
	}

	loaderOnProgressChange(percent) {
		this.loadProgress = percent;
		this.eventInvoke('LayerLoadProgressChange', this, percent);
	}

	loaderOnFileEnd() {
		this.data.receiveAllEnd();
		this.eventInvoke('LayerTimeRangeSet', this, this.data.timeRange);
		
		this.loadComplete = true;
		this.loadProgress = 100;
		this.eventInvoke('LayerLoadProgressChange', this, 100);
		this.eventInvoke('LayerLoadFinish', this);
	}

	loaderConfigureAfterLoad(cfg) {
		const xComputeServer = 'x-compute-server';
		const coloring = cfg['marker-coloring'];
		const sizing = cfg['marker-sizing'];
		const filter = cfg['filter'];

		if (coloring) {
			applyAttrBindConfig(coloring, this, 0);
			applyVariationConfig(coloring, this, 0);
			applyLegendConfig(coloring, this, 0);
			if (coloring.rule){
				this.setColoringKey(coloring.rule, true);
			}
		}

		if (sizing) {
			applyAttrBindConfig(sizing, this, 1);
			applyVariationConfig(sizing, this, 1);
			applyLegendConfig(sizing, this, 1);
			if (sizing.rule) {
				const szname = to_upper_name(sizing.rule);
				if (MarkerSizingPresetNames.hasOwnProperty(szname)) {
					this.setMarkerSizingType(MarkerSizingPresetNames[szname]);
				}
			}
		}

		if (filter) {
			if (!this.orderedAttributeNames) {
				this.rebuildOrderedAttributeNames();
			}
			applyExpressionFilter(filter , this, this.orderedAttributeNames);
		}

		// GoS test
		if (cfg.hasOwnProperty(xComputeServer)) {
			this.xComputeServer = cfg[xComputeServer];
			console.log("%cxComputeServer"+this.xComputeServer, "color:#05E");
		}
	}

	loaderReportError() {
		const n = this.hasDataError();
		if (n) {
			this.eventInvoke('LayerHasError', this);
		}
	}

	loaderFoundAnnotation(ann) {
		this.setAnnotation(ann);
	}

	getAttrIndexByName(name) {
		return this.orderedAttributeNames.indexOf(name);
	}

	setColoringKey(k, fire) {
		if (this.markerSetting.coloringKey !== k) {
			this.markerSetting.coloringKey = k;
			this.markerSetting.markDirty(true);
		}

		super.setColoringKey(k, fire);
	}
	
	setMarkerSizingType(t) {
		if (this.markerSetting.sizingType !== t) {
			this.markerSetting.sizingType = t;
			this.markerSetting.markDirty();
			this.eventInvoke('LayerMarkerSizingChange', this, t);
		}
	}

	on_SelectionChange(selection) {
		this.data.updateSelection(selection);
	}
	
	makeColorMappedArray(valueArray) {
		const n = valueArray.length;
		const colorArray = new Uint32Array(n);
		for (var i = 0;i < n;++i) {
			const mi = this.mapAttrToMarkerIndex(valueArray[i]);
			const mg = this.markerGenerator;
			const rgb = mg.generateColorOnly(mi, mg.lastColorGenerator, this.markerSetting);
			
			colorArray[i] = (rgb[0] << 16) | (rgb[1] << 8) | rgb[2];
		}
		
		return colorArray;
	}

	makeInterpolationMaskArray(tset, limitType, threshold) {
		let i = 0, n = 0, retArray = null;

		if (limitType === InterpolationLimitType.Distance) {
			const disArray = tset.getDistanceArray();
			if (disArray) {
				n = disArray.length;
				retArray = new Uint8Array(n);
				for (i = 0;i < n;++i) {
					retArray[i] = (disArray[i] > threshold) ? 1 : 0;
				}
				
			}
		} else if (limitType === InterpolationLimitType.Time) {
			const timeArray = tset.getTimeArray();
			if (timeArray) {
				n = timeArray.length;
				retArray = new Uint8Array(n);
				for (i = 0;i < (n-1);++i) {
					const diffTime = timeArray[i+1] - timeArray[i];
					retArray[i] = (diffTime > threshold) ? 1 : 0;
				}
			}
		}

		return retArray;
	}

	writeOutMarkerIndexColor(outArray, writeBaseIndex, queryIndex) {
		const mg = this.markerGenerator;
		const rgb = mg.generateColorOnly(queryIndex, mg.lastColorGenerator, this.markerSetting);

		outArray[writeBaseIndex  ] = rgb[0] / 255.0;
		outArray[writeBaseIndex+1] = rgb[1] / 255.0;
		outArray[writeBaseIndex+2] = rgb[2] / 255.0;
		outArray[writeBaseIndex+3] = 1;
	}

	mapAttrToMarkerIndex(raw) {
		if (raw < 0) {  return 0;  }
		const max = this.markerSetting.primaryVariation - 1;
		if (raw > max) { return max; }
		return raw;
	}

	// Layer Capability
	hasTrajectory() {
		return true;
	}
	
	countTrajectories() {
		if (!this.data) { return 0;}

		return this.data.countIDs();
	}
	
	referTrajectoryAt(index) {
		if (!this.data) { return null; }
		
		return this.data.referTSetAt(index);
	}
}

function applyAttrBindConfig(b_cfg, layer, variationIndex) {
	if (b_cfg && b_cfg.bind) {
		const ai = layer.getAttrIndexByName(b_cfg.bind);
		if (ai >= 0) {
			layer.setBindAttrIndex(ai, variationIndex);
		}
	}
}

function applyVariationConfig(v_cfg, layer, variationIndex) {
	if (v_cfg && v_cfg.variation) {
		const i = parseInt(v_cfg.variation, 10);
		if (variationIndex === 0) {
			layer.markerSetting.setPrimaryVariation(i);
		} else {
			layer.markerSetting.setSecondaryVariation(i);
		}
	}
}

function applyLegendConfig(l_cfg, layer, variationIndex) {
	if (l_cfg && l_cfg.legend) {
		layer.setLegendVisible(variationIndex, true);
	}
}

function applyExpressionFilter(src, layer, orderedAttributeNames) {
	const root = xf_deserialize(src, orderedAttributeNames);
	layer.setFilterExpression(root);
}

function to_upper_name(s) {
	return s.toUpperCase().replaceAll('-', '_');
}

export { USE_NORMAL_TAIL } ;
export default MovingObjectLayer;