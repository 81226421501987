import mm_utils from '../GlobalUtils'
import AttributeType from './AttributeType'
import { kIntp_LastValue } from './MovingData'

const kTimeAttrName = 'time';

const RequirdAttrs = {
	id:   { priority: 0, type: AttributeType.STRING, hidden: true },
	lat:  { priority: 1, type: AttributeType.FLOAT    },
	lng:  { priority: 2, type: AttributeType.FLOAT    },
	time: { priority: 3, type: AttributeType.DATETIME }
};

class AttributeMapping {
	constructor() {
		this.nameMap = {};
		this.colMap  = {};
		this.maxColumnIndex = 0;
	}

	addTestDefault() {
		this.add('id'  , 0, AttributeType.STRING, true);
		this.add(kTimeAttrName, 3, AttributeType.DATETIME);
		this.add('lng' , 4, AttributeType.FLOAT);
		this.add('lat' , 5, AttributeType.FLOAT);
	}

	findRequiredAttribute(name) {
		return RequirdAttrs[name] || null;
	}

	isRequiredAttribute(name) {
		return !!this.findRequiredAttribute(name);
	}

	add(name, columnIndex, type, hidden, optionFlags) {
		var r = null;
		if (!type) {
			// required attribute
			r = this.findRequiredAttribute(name);
			if (!r) {
				throw ( "[bug] Optional attribute must have type." );
			}
			
			type = r.type;
			hidden = !!(r.hidden);
		}
		
		const ent = new AMapEntry(name, columnIndex, type, hidden);
		
		this.nameMap[ name ] = ent;
		this.colMap[ columnIndex ] = ent;
		
		if (r) {
			ent.setPriority(r.priority);
			ent.setInterpolation(1);
		} else {
			if (optionFlags) {
				ent.setInterpolation(1);
			}
		}

		this.updateMaxColumnIndex();
		return ent;
	}
	
	updateMaxColumnIndex() {
		const m = this.colMap;
		for (const i in m) if (m.hasOwnProperty(i)) {
			this.maxColumnIndex = Math.max( this.maxColumnIndex, i-0 );
		}
	}

	byColumn(columnIndex) {
		return this.colMap[columnIndex] || null;
	}
	
	byName(name) {
		return this.nameMap[name] || null;
	}
	
	each(proc) {
		const m = this.nameMap;
		for (const name in m) if(m.hasOwnProperty(name)) {
			proc(name, m[name]);
		}
	}

	makeColumnIndexList() {
		const ret = [];
		const m = this.colMap;
		for (const c in m) if(m.hasOwnProperty(c)) {
			ret.push(c - 0);
		}

		return ret;
	}
}

class AMapEntry {
	constructor(name, columnIndex, type, hidden) {
		this.priority = 10;
		this.interpolation = 0;
		this.name = name;
		this.columnIndex = columnIndex;
		this.type = type;
		this.hidden = !!(hidden);
		this.parserFlags = 0;
		
		this.parser = parserForType(type);
	}
	
	setFlags(f) {
		this.parserFlags = f;
	}

	setPriority(o) {
		this.priority = o;
	}
	
	setInterpolation(i) {
		this.interpolation = i;
	}
};

function parse_dec(i) { return parseInt(i ,10); }
function parse_none(s) { return s; }
function parserForType(type) {
	switch(type) {
		case AttributeType.INTEGER:  return parse_dec;      break;
		case AttributeType.FLOAT:    return parseFloat;     break;
		case AttributeType.DATETIME: return mm_utils.parseFieldTime; break;

		default:
			return parse_none;
			break;
	}
}

// ----------------------------------------------------
function parseFieldsWithMapping(receiver, fields, am, lineNo) {
	const n = fields.length;

	// 最大インデックス3のとき -> フィールド数が4未満ならエラー
	if (n <= am.maxColumnIndex) {
		if (receiver) {
			receiver.receiveTooFewColumns(lineNo);
		}
	}
	
	const idAttr = am.byName('id');
	const idCol  = idAttr.columnIndex;
	const idVal  = idAttr.parser( fields[ idCol ] );
	
	if (receiver) {
		for (var i = 0;i < n;++i) {
			if (i === idCol) { continue; }

			const attrEntry = am.byColumn(i);
			if (attrEntry) {
				const converted = attrEntry.parser( fields[i] );
				receiver.receiveData(idVal, attrEntry.name, converted, lineNo);
			}
		}
		
		receiver.receiveRowEnd(idVal, lineNo);
	}
}

function parseRecordObject(receiver, record, am, lineNo) {
	const idVal = record.id;
	if (!idVal) { return; }

	for (const fieldName in record) if(record.hasOwnProperty(fieldName)) {
		const rawVal = record[fieldName];
		const attr = am.byName(fieldName);
		if (attr) {
			const converted = attr.parser( rawVal );
			receiver.receiveData(idVal, attr.name, converted, lineNo);
		}
	}

	receiver.receiveRowEnd(idVal, lineNo);
}

// ----------------------------------------------------

AttributeMapping.parseFieldsWithMapping = parseFieldsWithMapping;
AttributeMapping.RequirdAttrs = RequirdAttrs;
export { parseRecordObject, kTimeAttrName };
export default AttributeMapping;