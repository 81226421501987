import MMEventDispatcher from './EventDispatcher'
import leaflet from 'leaflet'
import { ViewAgenda } from '@material-ui/icons';
const L = leaflet;

const kHandleImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUBAMAAAB/pwA+AAAAKlBMVEVHcEw'+
 'AAAAAAAAAAAAAAAAAAAAAAAD///8AAAD7+/uoqKhcXFzCwsLq6urLrhQ0AAAAB3RSTlMA+U4yv6PcHlW8cwAAAGZJREFUCNdjYEAB'+
 'qoliQRCWSQcQOINYTBJ7bs493agAZBp2TS8vr1whDGR6LC8HgqoWoHz3dBCzcocCA2tPORicCGBg64UwbyQwMHZCmDMEkJlICpC0I'+
 'RmGZAWyxUjOQXIkstNhAABIsD+fxX4uwQAAAABJRU5ErkJggg==';

const kDummyTransparent = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA1BMVE'+
                          'UAAACnej3aAAAAAXRSTlMAQObYZgAAAAtJREFUCNdjIBEAAAAwAAFletZ8AAAAAElFTkSuQmCC';

const kGearURL = '/images/gate-gear-icon.png';
const kGoogleClientId = '253031000082-rv2ftg74utij3rdf6s6tpftuf8pbocr8.apps.googleusercontent.com';

const dirIconW = 32;
const dirIconH = 32;

class LeafletGateFeature extends MMEventDispatcher {
	constructor(id) {
		super();
		this.id = id;
		this.popupElement = null;

		this.commandIconPath = null;
		this.pubsubForm = null;
		this.csvDownloadLink = null;
		this.cloudSendTrigger = null;
		this.inputForms = {};
		this.prepareIcons();
		
		this.funcMarker = null;
		this.fwdMarker = null;
		this.backMarker = null;
		this.l_polyline = null;
		this.handles = [];
		this.coordList = [];
		this.map = null;
	}

	isPolygon() { return false; }

	prepareIcons() {
		const iconW = 10;

		this.handleIcon = L.icon({
			className: 'leafmob-gate-edit-handle',
		    iconUrl: kHandleImage ,
		    iconSize: [iconW, iconW],
		    iconAnchor: [iconW >> 1, iconW >> 1],
		    popupAnchor: [iconW >> 1, 0]
		});

		this.gearIcon = GateFeatureUtils.createGearIcon();

		this.dirFwdIcon = this.generateDirIcon(1);
		this.dirBackIcon = this.generateDirIcon(-1);
	}

	generateDirIcon(sign) {
		return new RelocatableIcon({
			className: 'leafmob-gate-dir-icon',
		    iconUrl: kDummyTransparent,
		    iconSize: [dirIconW, dirIconH],
		    iconAnchor: [dirIconW >> 1, dirIconH >> 1],
		    popupAnchor: [dirIconW >> 1, 0],
			mmLabelSign: sign
		});
	}

	setCommandIconPath(path) {
		this.commandIconPath = path;
	}

	referCoordList() {
		return this.coordList;
	}
	
	representativeCoord() {
		const r = [0, 0];
		for (const pt of this.coordList) {
			r[0] += pt[0];
			r[1] += pt[1];
		}

		r[0] /= this.coordList.length;
		r[1] /= this.coordList.length;

		return r;
	}

	setMap(m, autoLocate) {
		this.map = m;

		if (autoLocate) {
			this.locateAuto( m.getBounds() );
			this.renewLine();
		}
	}
	
	destroy() {
		this.map = null;
		if (this.l_polyline) { this.l_polyline.remove(); }
		if (this.funcMarker) { this.funcMarker.remove(); }
		if (this.fwdMarker) { this.fwdMarker.remove(); }
		if (this.backMarker) { this.backMarker.remove(); }
		for (const h of this.handles) {
			h.remove();
		}
	}
	
	locateAuto(bounds) {
		const x1 = bounds.getWest();
		const x2 = bounds.getEast();
		const y1 = bounds.getSouth();
		const y2 = bounds.getNorth();
		
		const xlen = Math.abs(x2-x1) / 8.0;
		const ylen = Math.abs(y2-y1) / 8.0;
		
		const centerLat = (y1+y2) / 2;
		const centerLng = (x1+x2) / 2;

		this.addHandle( centerLat + ylen , centerLng - xlen );
		this.addHandle( centerLat - ylen , centerLng + xlen );
		
		this.generateIconMarkers();
	}

	locateManual(srcCoordList) {
		const pt0 = srcCoordList[0];
		const pt1 = srcCoordList[1];

		this.addHandle( pt0[1] , pt0[0] );
		this.addHandle( pt1[1] , pt1[0] );

		this.generateIconMarkers();
	}

	generateIconMarkers() {
		const popupEl = GateFeatureUtils.installFunctionMarker(this, this.map, this.gearIcon,
			this.relocateFunctionMarker());
		this.popupElement = popupEl;

		const centerLL = this.representativeCoord();
		const mf = L.marker( centerLL , {icon: this.dirFwdIcon});
		mf.addTo(this.map);	

		const mb = L.marker(centerLL , {icon: this.dirBackIcon});
		mb.addTo(this.map);	

		this.fwdMarker = mf;
		this.backMarker = mb;
		this.relocateDirectionMarkers();
	}

	renewLine() {
		if (this.l_polyline) {
			this.l_polyline.remove();
			this.l_polyline = null;
		}

		this.l_polyline = L.polyline(this.coordList);
		this.l_polyline.addTo(this.map);
	}

	addHandle(initialLat, initialLng) {
		const loc = [initialLat, initialLng];
		const mk = L.marker( [initialLat, initialLng] , {draggable: true, icon: this.handleIcon});
		mk.addTo(this.map);
		mk.on('move', this.onHandleMove.bind(this));
		
		this.handles.push(mk);
		this.coordList.push(loc);
	}

	onHandleMove() {
		this.updateLineCoords();
		this.relocateFunctionMarker();
		this.relocateDirectionMarkers();
		this.eventInvoke('GateFeatureModified', this);
	}

	updateLineCoords() {
		const n = this.handles.length;
		for (var i = 0;i < n;++i) {
			const handle = this.handles[i];
			const src = handle.getLatLng();
			const dest = this.coordList[i];
			dest[0] = src.lat;
			dest[1] = src.lng;
		}
		
		this.l_polyline.setLatLngs(this.coordList);
	}

	closeControl() {
		if (this.funcMarker) {
			this.funcMarker.closePopup();
		}
	}

	relocateFunctionMarker() {
		return GateFeatureUtils.calcEastEndLocation(this.handles, this.funcMarker);
	}

	relocateDirectionMarkers() {
		const c = this.representativeCoord();

		const p0 = this.coordList[0];
		const p1 = this.coordList[1];

		const dx = p1[0] - p0[0];
		const dy = p1[1] - p0[1];
		const angle = Math.atan2(dy, dx);
		const distance = 9;

		const hw = dirIconW >> 1;
		const hh = dirIconH >> 1;

		if (this.fwdMarker) {
			this.fwdMarker.setLatLng(c);
			this.backMarker.setLatLng(c);

			const dx = Math.cos(angle)*distance;
			const dy = Math.sin(angle)*distance;
	
			this.dirFwdIcon.updateIconAnchor( hw + dx, hh + dy );
			this.dirBackIcon.updateIconAnchor( hw - dx, hh - dy );

			this.dirFwdIcon.redrawIconImage(angle);
			this.dirBackIcon.redrawIconImage(angle + Math.PI);
		}
	}	

	onFunctionMarkerClick() {
		this.eventInvoke("GateFunctionWillAppear", this);

		this.contentToSend = null;
		if (this.pubsubForm) {
			this.pubsubForm.style.display = 'none';
			this.showSendResult(null, false);
		}
		if (this.inputForms.title) {
			this.inputForms.title.value = this.id;
			this.setTitleError(false);
		}
	}

	buildPopupContent(container) {
		this.buildControlForm(container);

		const copyButton = build_command_button(this.commandIconPath, 'gate-copy', 'Copy', '#c93');
		copyButton.addEventListener('click', this.onCopyButtonClick.bind(this));

		const pubButton = build_command_button(this.commandIconPath, 'gate-export', 'Export', '#37d');
		pubButton.addEventListener('click', this.onPubSubButtonClick.bind(this));

		GateFeatureUtils.addExecuteButton(container, this.commandIconPath, this.onExecButtonClick.bind(this));
		container.appendChild(copyButton);
		container.appendChild(pubButton);
		GateFeatureUtils.addRemoveButton(container, this.commandIconPath, this.onRemoveButtonClick.bind(this));

		const experimentButton = build_command_button(this.commandIconPath, 'gate-experiment', 'Invoke server(Experimental)', '#647');
		experimentButton.className += ' mm-experimental-feature-button';
		container.appendChild(experimentButton);
		experimentButton.addEventListener('click', this.onExperimentalFuncButtonClick.bind(this), false );

		this.pubsubForm = this.buildPubSubForm(container);
	}

	buildControlForm(container) {
		const box = document.createElement('div');
		box.className = 'mm-map-gate-control-form';

		const input = document.createElement('input');
		input.className = 'mm-map-gate-title-input';
		input.type = 'text';
		box.appendChild(input);

		this.inputForms.title = input;
		input.addEventListener('change', this.onTitleInputChange.bind(this) );
		container.appendChild(box);
		return box;
	}

	buildPubSubForm(container) {
		const box = document.createElement('div');
		box.className = 'mm-map-gate-form';

		const downloadLink = document.createElement('a');
		downloadLink.className = 'mm-export-file-link';
		downloadLink.innerHTML = 'Save to local file...';
		box.append(downloadLink);
		this.csvDownloadLink = downloadLink;

		const h = document.createElement('h4');
		h.innerHTML = 'or send to Cloud Storage';
		box.append(h);

		const pathLabel = opt_label(box, 'Location');
		const pathInput = opt_input(box, 'bucket/folder');

		const buttonRow = document.createElement('div');
		buttonRow.className = 'mm-map-gate-form-button-container';
		const btn = document.createElement('button');
		btn.innerHTML = 'Send';
		buttonRow.appendChild(btn);
		box.appendChild(buttonRow);

		const sendResultRow = document.createElement('div');
		box.appendChild(sendResultRow);

		this.inputForms.csPath = pathInput;
		this.inputForms.resultOut = sendResultRow;
		
		// Initial value from local storage
		const loaded = local_load_location();
		if (loaded) { pathInput.value = loaded; }


		this.cloudSendTrigger = btn;
		btn.addEventListener('click', this.onSendCloudClick.bind(this));

		box.style.display = 'none';
		container.appendChild(box);
		return box;
	}
	
	getParsedStoragePath() {
		const raw = this.inputForms.csPath.value;
		const re = /([^\/]+)\/(.+)/ ;
		if (re.test(raw)) {
			const bucket = RegExp['$1'];
			var path = RegExp['$2'];
			if (!path.endsWith('/')) {
				path = path + '/';
			}
			
			return { bucket:bucket, path:path };
		}
		return null;
	}
	
	savePathInput() {
		const raw = this.inputForms.csPath.value;
		local_store_location(raw);
	}
	
	onExecButtonClick() {
		this.eventInvoke('GateExecRequested', this, null);
	}

	onCopyButtonClick() {
		navigator.clipboard.writeText( gateToGeoJson(this.coordList, this.id) ).then( ()=>{
			this.eventInvoke('GateSuccessMessageRequested', this, "Copied as a GeoJSON.");
		});
	}
	
	onRemoveButtonClick() {
		this.eventInvoke('GateRemoveRequested', this);
	}

	// ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲
	// ▲ サーバ連携実験用 ▲
	// ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲
	onExperimentalFuncButtonClick() {
		this.eventInvoke('GateInvokeServerRequested', this, {
			gateName: this.id
		});
	}

	onPubSubButtonClick() {
		this.eventInvoke('GateExecRequested', this, {
			gateName: this.id,
			csv: this.afterCSVGenerated.bind(this) 
		});
	}

	onSendCloudClick() {
		const content = this.contentToSend;
		if (!content) { return; }

		const locationInfo = this.getParsedStoragePath();
		if (!locationInfo) {
			this.showSendResult('Bad location', true);
			return;
		}

		const gapi = window.gapi;
		gapi.load('client:auth2', ()=>{
			gapi.client.init({
				'scope': 'https://www.googleapis.com/auth/devstorage.read_write',
				'clientId': kGoogleClientId
			}).then( ()=>{
				if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
					this.afterGoogleAuth(content, locationInfo);
				} else {
					gapi.auth2.getAuthInstance().isSignedIn.listen( this.afterGoogleAuth.bind(this, content, locationInfo) );
					gapi.auth2.getAuthInstance().signIn();
				}
			});
		});
	}
	
	afterGoogleAuth(content, loc, e) {
		const gapi = window.gapi;
		const req = gapi.client.request({
			path: 'https://storage.googleapis.com/upload/storage/v1/b/' +loc.bucket+ '/o',
			headers: {'Content-Type': 'text/csv'},
			method: 'POST',
			params: {
				name: loc.path + content.name,
				uploadType: 'media'
			},
			body: content.body
		});
		
		this.showSendResult(null, false);
		req.execute( (jsonResp,rawResp)=>{
			if (jsonResp.error) {
				this.showSendResult('Failed (Status: ' +jsonResp.error.code+ ')', true);
			} else {
				this.showSendResult('Success', false);
				this.savePathInput();
			}
		} );
	}

	showSendResult(message, error) {
		const el = this.inputForms.resultOut;
		el.innerHTML = '';
		if (!message) {
			el.className = '';
			return;
		}

		el.className = error ? 'mm-map-gate-message-error' : 'mm-map-gate-message-success';
		el.appendChild( document.createTextNode(message) );
	}

	afterCSVGenerated(csvContent, suggestedFilename) {
		const blob = new Blob([csvContent], {type : 'text/csv'})
		const formBox = this.pubsubForm;
		formBox.style.display = '';

		this.csvDownloadLink.setAttribute('download', suggestedFilename);
		this.csvDownloadLink.href = URL.createObjectURL(blob);
		
		this.contentToSend = {
			name: suggestedFilename,
			body: csvContent
		};
	}
	
	onTitleInputChange() {
		if (this.inputForms.title) {
			const newValue = this.inputForms.title.value;
			if ( checkTitleValid(newValue) ) {
				this.id = newValue;
				this.inputForms.title.blur();
				this.setTitleError(false);
			} else {
				this.setTitleError(true);
				this.eventInvoke('GateSuccessMessageRequested', this, "Use alphabet letters, number, -, _");
			}

		}
	}
	
	setTitleError(exist) {
		this.inputForms.title.setAttribute('data-has-error', exist ? 1 : '');
	}
};

function checkTitleValid(v) {
	const re = /[^-_0-9a-zA-Z]/ ;
	if (re.test(v)) { return false; }

	return true;
}

function opt_label(container, text) {
	const lab = document.createElement('label');
	lab.appendChild( document.createTextNode(text) );
	container.appendChild(lab);
	container.appendChild(document.createElement('br'));
	return lab;
}

function opt_input(container, placeholder) {
	const i = document.createElement('input');
	i.className = 'mm-export-option-input';
	i.type = 'text';
	if (placeholder) { i.placeholder=placeholder; }

	container.appendChild(i);
	return i;
}

function build_command_button(basePath, name, title, color) {
	const a = document.createElement('a');
	a.className = 'mm-gate-command';
	a.href = 'javascript:void(0)';

	if (basePath) {
		const img = document.createElement('img');
		img.src = basePath + '/' + name + '.png';
		img.style.backgroundColor = color;
		a.appendChild(img);
		a.appendChild(document.createElement('br'));
	}

	a.appendChild(document.createTextNode(title));
	return a;
}

function gateToGeoJson(coords, id) {
	const lnglats = [];
	for (const pt of coords) { lnglats.push( [ pt[1],pt[0] ] ) }

	const ft = 	{
		type: "Feature",
		geometry: {type: "LineString", coordinates: lnglats},
		properties: {
			mmType: "line-gate",
			mmId: id
		}
	};

	const j = {
		type: "FeatureCollection",
		features: [ft]
	};

	return JSON.stringify(j);
}

var RelocatableIcon = leaflet.Icon.extend({
	createIcon: function (oldIcon) {
		this._currentImage = leaflet.Icon.prototype.createIcon.call(this, oldIcon);
		this._currentCanvas = generateArrowIconCanvas(this.options.iconSize[0], this.options.iconSize[1]);
		return this._currentImage;
	},

	updateIconAnchor: function(x,y) {
		if (this.options && this.options.iconAnchor && this._currentImage) {
			this.options.iconAnchor[0] = x;
			this.options.iconAnchor[1] = y;
			this._setIconStyles(this._currentImage, 'icon');
		}
	},

	redrawIconImage: function(angle) {
		if (this._currentCanvas && this._currentImage) {
			this._currentImage.src = generateArrowImage(this._currentCanvas, angle, this.options.mmLabelSign).toDataURL();
		}
	}
});

function generateArrowIconCanvas(viewWidth, viewHeight) {
	const r = window.devicePixelRatio;
	const cv = document.createElement('canvas');

	cv.width = viewWidth * r;
	cv.height = viewHeight * r;
	return cv;
}

function generateArrowImage(cv, angle, sign) {
	const r = window.devicePixelRatio;
	const g = cv.getContext('2d');
	const w = cv.width - 0;
	const h = cv.height - 0;

	const cx = w / 2;
	const cy = h / 2;

	const len = Math.floor(Math.min(cx,cy)*0.3);
	const len2 = Math.floor(Math.min(cx,cy)*0.6);

	const dx = Math.cos(angle) * len;
	const dy = Math.sin(angle) * len;
	const nx = Math.cos(angle + Math.PI/2) * len;
	const ny = Math.sin(angle + Math.PI/2) * len;
	const dx2 = Math.cos(angle) * len2;
	const dy2 = Math.sin(angle) * len2;

	const ocx = cx + dx*0.4;
	const ocy = cy + dy*0.4;

	g.save();
	g.clearRect(0, 0, w, h);
	g.strokeStyle = '#5af';
	g.lineWidth = r * 2;

	g.beginPath();
	g.moveTo(ocx - dx, ocy - dy);
	g.lineTo(ocx + dx, ocy + dy);
	g.stroke();

	g.moveTo(ocx + nx, ocy + ny);
	g.lineTo(ocx - dx, ocy - dy);
	g.lineTo(ocx - nx, ocy - ny);
	g.stroke();

	// draw sign
	const sx = Math.floor(cx - dx2 + 0.49);
	const sy = Math.floor(cy - dy2 + 0.49);

	g.fillStyle = '#5af';
	g.fillRect(sx-r*4, sy-r  , r*8, r*2);
	if (sign > 0) {
		g.fillRect(sx-r  , sy-r*4, r*2, r*8);
	}


	g.shadowOffsetX = 0;
	g.shadowOffsetY = 0;
	g.shadowColor = '#000';
	g.shadowBlur = 1;

	g.drawImage(cv, 0, 0);
	g.drawImage(cv, 0, 0);

	g.restore();
	return cv;
}

const GateFeatureUtils = {
	createGearIcon: function() {
		const gearW = 20;
		return L.icon({
			className: 'leafmob-gate-function-icon',
		    iconUrl: kGearURL ,
		    iconSize: [gearW, gearW],
		    iconAnchor: [0 - (gearW >> 2), gearW >> 1],
		    popupAnchor: [gearW * 0.75, -(gearW >> 1)]
		});
	},

	installFunctionMarker: function(that, map, gearIcon, location) {
		if (that.funcMarker) { return; }

		const mk = L.marker( location , {icon: gearIcon});
		that.funcMarker = mk;
		mk.addTo(map);

		const popupContent = document.createElement('div');
		if (that.buildPopupContent) {
			that.buildPopupContent(popupContent);
		} else {
			console.log("%cIMPLEMENT [buildPopupContent]", "color:#F30");
		}

		mk.bindPopup( popupContent );
		if (that.onFunctionMarkerClick) {
			mk.on('click', that.onFunctionMarkerClick.bind(that) );
		} else {
			console.log("%cIMPLEMENT [onFunctionMarkerClick]", "color:#F30");
		}

		return popupContent;
	},

	calcEastEndLocation: function(ls, existingMarker) {
		var eLoc = null;

		for (const h of ls) {
			const loc = h.getLatLng();
			if (!eLoc) {
				eLoc = loc;
			} else {
				if (loc.lng > eLoc.lng) {
					eLoc = loc;
				}
			}
		}

		if (eLoc && existingMarker) {
			existingMarker.setLatLng(eLoc);
		}

		return eLoc;
	},

	addExecuteButton: function(container, commandIconPath, handler) {
		const button = build_command_button(commandIconPath, 'gate-exec', 'Execute', '#5b2');
		button.addEventListener('click', handler);
		container.appendChild(button);
	},

	addRemoveButton: function(container, commandIconPath, handler) {
		const button = build_command_button(commandIconPath, 'gate-del', 'Remove', '#c22');
		button.addEventListener('click', handler);
		container.appendChild(button);
	}
};

// Store input data
const kLocalStoreName = 'mm-cloud-storage-path';

function local_store_location(value) {
	const s = window.localStorage;
	if (s) {
		s.setItem(kLocalStoreName, value);
	}
}

function local_load_location() {
	const s = window.localStorage;
	if (s) {
		return s.getItem(kLocalStoreName);
	}

	return null;
}	

export { GateFeatureUtils } ;
export default LeafletGateFeature;