import React from 'react';
import { Component } from 'react';
import Button from      '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from        '@material-ui/core/Grid';
import InputLabel from  '@material-ui/core/InputLabel';
import Input from       '@material-ui/core/Input';
import MenuItem from    '@material-ui/core/MenuItem';
import Select from      '@material-ui/core/Select';
import Slider from      '@material-ui/core/Slider';
import Switch from      '@material-ui/core/Switch';

import SelOnlySwitch from './SelOnlySwitch'
import ExpressionForm from './ExpressionForm';

import ColoringSelector from './ColoringSelector'
import SizingSelector from './SizingSelector'
import { cond_grid } from './cond-grid'

import { kAdditionalAttrsThresh } from '../misc-utils'
import get_i18n from '../Internationalization';

class MarkerSettingPanel extends Component {
	constructor(props) {
		super(props);
		this.state = {}
	}

	getValueText(v) {
		return v.toString();
	}


	onVariationSliderChange(e, newValue) { this.color_var_change(newValue - 0); }
	onVariationInputChange(e, newValue) { this.color_var_change(e.target.value - 0); }

	onSizeVariationSliderChange(e, newValue) { this.size_var_change(newValue); }
	onSizeVariationInputChange(e, newValue) { this.size_var_change(e.target.value - 0); }
	
	size_var_change(newValue) {
		if (this.props.onSizeVariationChange) {
			this.props.onSizeVariationChange( Math.max(1, newValue) );
		}
	}

	color_var_change(newValue) {
		if (this.props.onVariationChange) {
			this.props.onVariationChange( Math.max(1, newValue) );
		}
	}

	onColoringChange(newColoringKey) {
		if (this.props.onColoringChange) {
			this.props.onColoringChange(newColoringKey);
		}
	}


	render() {
		const idx = this.props.markerModeIndex;
		const btn0 = (idx===0) ? 'secondary' : null;
		const btn1 = (idx===1) ? 'primary' : null;
		const btn2 = (idx===2) ? 'primary' : null;
		
		const disable_all = (idx===0);

		var leg0 = false;
		var leg1 = false;
		if (this.props.legendVisibilities) {
			leg0 = !!this.props.legendVisibilities[0];
			leg1 = !!this.props.legendVisibilities[1];
		}

		return ( <div>
			<Grid container spacing={1} alignItems="center" className="mm-setting-panel-section">
				<Grid item xs={12}>
					<PanelTitle icon="/images/cat-marker.png" label="Marker" />
				</Grid>
				
				<Grid item xs={12}>
					<div className="mm-setting-panel-toggle"><ButtonGroup size="small" disableRipple={true}>
						<Button variant="contained" disableElevation color={btn0} onClick={ this.onModeToggle.bind(this, 0) }
							startIcon={ <img src="/images/comp-x2.png" alt="X" /> } className="mm-toggle-x">&#65279;</Button>
						<Button variant="contained" disableElevation color={btn1} onClick={ this.onModeToggle.bind(this, 1) }
							startIcon={ <img src="/images/comp-n2.png" alt="" /> }>
							{ get_i18n('NormalComp') }
						</Button>
						<Button variant="contained" disableElevation color={btn2} onClick={ this.onModeToggle.bind(this, 2) }
							startIcon={ <img src="/images/comp-a2.png" alt="" /> }>
							{ get_i18n('AddComp') }
						</Button>
					</ButtonGroup></div>
				</Grid>

				{ cond_grid(1,disable_all) }
				{ disable_all ? '' : <SelOnlySwitch flags={ this.props.selDispFlags || 0 } onClick={ this.onSelDispClick.bind(this) } xs={11} /> }


				{ cond_grid(1,disable_all) }
				{ disable_all ? '' : <Grid item xs={5}>
						<InputLabel shrink>{ get_i18n('DirMarker') }</InputLabel>
						<Switch color="primary" checked={ !!this.props.markerDeform } onChange={ this.onDeformChange.bind(this) } />
					</Grid>}
				{ disable_all ? '' : <Grid item xs={5}>
						<InputLabel shrink>{ get_i18n('DirShift') }</InputLabel>
						<Switch color="primary" checked={ !!this.props.dirShift } onChange={ this.onDirShiftChange.bind(this) } />
					</Grid>}
				{ cond_grid(1,disable_all) }
				
				
				{ cond_grid(1,disable_all) }
				{ disable_all ? '' : <Grid item xs={10}>
						<ColoringSelector onColoringChange={ this.onColoringChange.bind(this) } value={ this.props.coloringRule } />
					</Grid>}
				{ cond_grid(1,disable_all) }

				
				{ cond_grid(1,disable_all) /* color variation row */ }
				{ disable_all ? '' : makeVariationArea(this.props.numColorVariation , this.onVariationSliderChange.bind(this), get_i18n('ColorVariation') ) }
				{ disable_all ? '' : makeVariationNumeric(this.props.numColorVariation , this.onVariationInputChange.bind(this)) }

				{ this.makeBindAttributeSelector(disable_all, 0, this.onBindAttrChange.bind(this, 0), this.onLegendSwitchChange.bind(this, 0), this.props.colorBind, leg0 ) }


				{ cond_grid(1,disable_all) }
				{ disable_all ? '' : <Grid item xs={10}>
						<SizingSelector value={ this.props.markerSizing || 0 } onChange={ this.props.onSizingChange } />
					</Grid>}
				{ cond_grid(1,disable_all) }

				{ cond_grid(1,disable_all) /* size variation row */ }
				{ disable_all ? '' : makeVariationArea(this.props.numSizeVariation || 0, this.onSizeVariationSliderChange.bind(this), get_i18n('SizeVariation')) }
				{ disable_all ? '' : makeVariationNumeric(this.props.numSizeVariation || 0, this.onSizeVariationInputChange.bind(this)) }

				{ this.makeBindAttributeSelector(disable_all, 1, this.onBindAttrChange.bind(this, 1), this.onLegendSwitchChange.bind(this, 1), this.props.sizeBind, leg1 ) }


				{ cond_grid(1,disable_all) /*フィルター条件エディタ*/ }
				{ disable_all ? '' : this.makeExpressionFilterEditor() }
				{ cond_grid(1,disable_all) }
			</Grid>
		</div> );
	}

	makeExpressionFilterEditor() {
		return ( <Grid item xs={10}><ExpressionForm current={ this.props.filterExpression }
			onClearClick={
				(e) => { if (this.props.onExpressionClearClick) { this.props.onExpressionClearClick(e); } }
			}
			onClick={
				(e) => { if (this.props.onExpressionFilterClick) { this.props.onExpressionFilterClick(e); } }
			} /></Grid> );
	}

	makeBindAttributeSelector(disable_all, index, callback, legend_callback, selectedValue, checked) {
		if (disable_all) { return ''; }

		const ls = this.props.orderedAttributeNames;
		if (ls && ls.length > kAdditionalAttrsThresh) {
			const itemList = fillAttributeMenuItems(newAttributeMenuItemList(), ls);
			
			return (
				<Grid container spacing={1} alignItems="center" className="mm-setting-panel-section">
					<Grid item xs={1} />
					<Grid item xs={6}>
						<InputLabel shrink>{ get_i18n('ChangeBy') }</InputLabel>
						<Select onChange={ callback } value={selectedValue}>{itemList}</Select>
					</Grid>
					<Grid item xs={4}>
						<InputLabel shrink>{ get_i18n('Legend') }</InputLabel>
						<Switch color="primary" onChange={ legend_callback } checked={ checked } />
					</Grid>
					<Grid item xs={1} />
				</Grid>
			) ;
		}
		
		return '';
	}
	
	onBindAttrChange(selectorIndex, e) {
		if (this.props.onBindAttrChange) {
			const val = parseInt( e.target.value , 10 );
			this.props.onBindAttrChange(selectorIndex, val);
		}
	}

	onLegendSwitchChange(selectorIndex, e, newValue) {
		if (this.props.onLegendSwitchChange) {
			this.props.onLegendSwitchChange(selectorIndex, e, newValue);
		}
	}

	onModeToggle(newValue) {
		if (this.props.onModeChange) {
			this.props.onModeChange(newValue);
		}
	}

	onSelDispClick(flags) {
		if (this.props.onSelDispClick) {
			this.props.onSelDispClick(flags);
		}
	}
	
	onDeformChange(e, newValue) {
		if (this.props.onDeformChange) {
			this.props.onDeformChange(newValue ? 1 : 0);
		}
	}
	
	onDirShiftChange(e, newValue) {
		if (this.props.onDirShiftChange) {
			this.props.onDirShiftChange(newValue);
		}
	}
}

function makeVariationArea(currentValue, callback, label) {
	return ( <Grid item xs={7}>
				<InputLabel shrink>{ label }</InputLabel>
				<Slider onChange={ callback }
					value={ currentValue }
					min={1}
					max={99}
				/>
			</Grid> );
}

function makeVariationNumeric(currentValue, callback) {
	return ( <Grid item xs={4}>
		<Input inputProps={ {type:'number'} } style={ {width:'4em'} } onChange={ callback } value={ currentValue } />
	</Grid> );
}

function newAttributeMenuItemList() {
	return [ <MenuItem className="mm-bind-attr-none-item" key="-1" value={-1}>(none)</MenuItem> ];
}

function fillAttributeMenuItems(outArray, orderedAttributeNames) {
	const n = (orderedAttributeNames.length - kAdditionalAttrsThresh);
	for (var i = 0;i < n;++i) {
		const aIndex = kAdditionalAttrsThresh + i;
		outArray.push( <MenuItem value={aIndex} key={aIndex}>{orderedAttributeNames[aIndex]}</MenuItem> );
	}

	return outArray;
}

function PanelTitle(props) {
	return ( <InputLabel className="mm-setting-side-label"><img src={ props.icon } width="16" height="16" alt=""/>{ get_i18n(props.label) }</InputLabel> );
}

export { fillAttributeMenuItems, PanelTitle };
export default MarkerSettingPanel;