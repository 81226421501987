import React from 'react';
import { Component } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import LocalCafeIcon from '@material-ui/icons/LocalCafe';
import Button from '@material-ui/core/Button';
import { generateMMZ, generateMovementCSV } from '../model/MMZExporter';

import "./SettingExportDialog.css"

class SettingExportDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			zipURL: null,
			busy: false
		};

		this.mcsv = null;
	}

	reset() {
		this.mcsv = null;
		this.setState( {busy: false, zipURL:  null } );
	}

	render() {
		const u = this.state.zipURL;

		return ( <Dialog open={ this.props.open }>
			{ u ? this.renderDownloadButton(u) : this.renderExportButton() }
			<MuiDialogActions>
				<Button color="secondary" variant="contained" onClick={ this.props.onCloseClick }>Close</Button>
			</MuiDialogActions>
		</Dialog> );
	}

	generateZipLink() {
		const manifest = {
			layers: [
				this.props.content
			]
		};

		if (!this.mcsv && this.props.layer) {
			generateMovementCSV(this.props.layer).then( (rows) => {
				this.mcsv = rows;

				generateMMZ(rows, manifest, this.props.annotation).then( (zipped) => {
					const blob = new Blob([zipped], {type: 'application/zip'});
					this.setState( {busy: false, zipURL: window.URL.createObjectURL(blob) } );
				} );
			});
		}
	}

	renderExportButton() {
		if (this.state.busy) {
			return ( <div className="mm-export-dialog-ops">
				<em>Wait...</em>
				<LocalCafeIcon className="mm-export-dialog-wait mm-export-dialog-icon" />
			</div> );
		}

		return ( <div className="mm-export-dialog-ops">
			<strong>Warning: It will take a while!</strong>
			<Button className="mm-export-dialog-gen" color="primary" variant="contained" onClick={ this.onGenerateClick.bind(this) }>Generate MMZ</Button>
		</div> );
	}

	renderDownloadButton(url) {
		return ( <div className="mm-export-dialog-ops">
			<em className="mm-export-dialog-indicator">Completed. Click to save.</em>
			<a href={url} download="exported.mmz"><SaveAltIcon className="mm-export-dialog-dl mm-export-dialog-icon" /></a>
		</div> );
	}

	onGenerateClick() {
		const that = this;

		this.setState( {busy: true} );
		setTimeout( function () {
			that.generateZipLink();
		}, 100)
	}
}

export default SettingExportDialog;