import React from 'react';
import { Component } from 'react';

import {MarkerSizingPresets, MarkerSizingPresetNames} from '../marker/MarkerSizingPreset';

class SizingItem extends Component {
	constructor(props) {
		super(props);
		
		const r = window.devicePixelRatio || 1;
		const w = 128;
		const h = 20;
		this.visibleWidth = w;
		this.visibleHeight = h;
		this.canvasWidth = w * r;
		this.canvasHeight = h * r;
		
		this.canvasRef = null;
	}

	render() {
		this.redrawCanvas();
		return ( <div className="mm-color-selector-item">
			<canvas width={ this.canvasWidth } height={ this.canvasHeight } ref={ this.onCanvasRendered.bind(this) }></canvas>
		</div> );
	}

	onCanvasRendered(el) {
		if (el) {
			this.canvasRef = el;
			this.setCanvasSizeStyle(el, this.visibleWidth, this.visibleHeight);
			this.redrawCanvas();
		}
	}
	
	redrawCanvas() {
		if (this.canvasRef) {
			const sizing = MarkerSizingPresets[ this.props.value ];
			const g = this.canvasRef.getContext('2d');
			renderSizingPreview(this.canvasRef, g, sizing.fromSize, sizing.toSize, 4, 16);
		}
	}
	
	setCanvasSizeStyle(target, w, h) {
		const s = target.style;
		s.width  = w + 'px';
		s.height = h + 'px';
	}
}

function MarkerSizing(fs, ts) {
	this.fromSize = fs;
	this.toSize = ts;
}


function renderSizingPreview(canvas, g, fromSize, toSize, num, padding) {
	const w = canvas.width | 0;
	const h = canvas.height | 0;
	g.clearRect(0,0, w,h);

	const cy = h >> 1;

	const visiblePadding = padding * window.devicePixelRatio;
	const validWidth = w - visiblePadding * 2;

	const xStep = validWidth / (num-1);

	var x = visiblePadding;
	for (var i = 0;i < num;++i) {
		const alpha = i / (num-1);
		const _alpha = 1.0 - alpha;
		const sz = fromSize * _alpha + toSize * alpha;
		
		g.fillStyle = '#777';
		g.beginPath();
		g.arc(x, cy, sz * window.devicePixelRatio, 0, Math.PI*2, false);
		g.fill();
		
		x += xStep;
	}
}


export default SizingItem;