function invoke_cp_handler(that, handler_name, arg1, arg2) {
	if (that.props && that.props[handler_name]) {
		that.props[handler_name](arg1, arg2);
	}
}

const kAdditionalAttrsThresh = 3;

const kVisitFlag = 'mobmap-visit-flag';
function check_first_visit() {
	const item = localStorage.getItem( kVisitFlag );
	if (!item) {
		return true;
	}
	
	return false;
}

function mark_visit_flag() {
	localStorage.setItem(kVisitFlag, true);
}

export { invoke_cp_handler, kAdditionalAttrsThresh, check_first_visit, mark_visit_flag };