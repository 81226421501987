import leaflet from 'leaflet'
const L = leaflet;

const WorkerTileLayerBase = {
	setOverlayVisibility: function(v) {
		if (this._container) {
			if (v !== this._isVisible) {
				this._isVisible = v;
				this._container.style.visibility = v ? '' : 'hidden';
				
				if (v && this.worker) {
					this.worker.postMessage({ resumePausedJobs: true });
				}
			}
		}
	},

	beforeAdd: function (map) {
		L.GridLayer.prototype.beforeAdd.call(this, map);
		map.on('zoomstart', this.zoomClosure);
	},
	
	onRemove: function (map) {
		L.GridLayer.prototype.onRemove.call(this, map);
		map.off('zoomstart', this.zoomClosure);
	},
	
	createTileCanvas: function(className, size, debug_mode, constructOffscreen) {
		var tile = null;
		if (constructOffscreen) {
			tile = new OffscreenCanvas(size.x, size.y);
			tile.className = className;
		} else {
			tile = L.DomUtil.create('canvas', className);
			tile.width  = size.x;
			tile.height = size.y;
		}

		if (debug_mode) {
			tile.style.boxShadow = '0 0 2px #F00';
		}

		return tile;
	}
};

export default WorkerTileLayerBase;