import MMEventDispatcher from '../leafmob/EventDispatcher'

class MarkerSetting extends MMEventDispatcher {
	constructor(owner) {
		super();
		this.dirty = true;
		this.coloringDirty = true;

		this.owner = owner;
		this.sizingType = 1;
		this.coloringKey = 'hue';
		this.chipSize = {
			width: 16,
			height: 16
		};
		this.center = {
			x: 6,
			y: 6
		};
		
		this.appearance = 0;
		this.deform = 0;
		this.primaryVariation = 4;
		this.secondaryVariation = 8;
	}

	markDirty(coloringDirty) {
		this.dirty = true;
		this.coloringDirty = !!(coloringDirty);
	}
	
	markOnlyColoringDirty() {
		this.coloringDirty = true;
	}

	clearDirty() {
		this.dirty = false;
		this.coloringDirty = false;
	}
	
	clearColoringDirty() {
		this.coloringDirty = false;
	}

	setAppearance(a) {
		if (this.appearance !== a) {
			this.appearance = a;
			this.markDirty();
		}
	}

	setDeform(d) {
		if (this.deform !== d) {
			this.deform = d;
			this.markDirty();

			this.eventInvoke('MarkerDeformChange', this);
		}
	}

	setPrimaryVariation(v) {
		if (this.primaryVariation !== v) {
			this.markDirty(true);
			this.primaryVariation = v;
			this.eventInvoke('MarkerPrimaryVariationChange', this);
		}
	}

	setSecondaryVariation(v) {
		if (this.secondaryVariation !== v) {
			this.markDirty(true);
			this.secondaryVariation = v;
			this.eventInvoke('MarkerSecondaryVariationChange', this);
		}
	}
}

MarkerSetting.DotAppearance  = 0;
MarkerSetting.SpotAppearance = 2;

MarkerSetting.DeformNone = 0;
MarkerSetting.DeformDirection = 1;

export default MarkerSetting;