var _tempColorComponents = [0,0,0];
const RE_RGB = /\((\d+) *, *(\d+) *, *(\d+)/ ;

const MarkerUtils = {
	drawSpotMarker: function(g, cx, cy, R, baseColor, tx, ty, withRing) {
		const rgb = this.splitColorComponents(baseColor).join(',');
		
		g.save();
		g.translate(tx || 0, ty || 0);

		const grad = g.createRadialGradient(cx+0.5, cy+0.5, 1, cx+0.5, cy+0.5, R);
		grad.addColorStop(0, 'rgba(' + rgb + ',0.5)');
		grad.addColorStop(1, 'rgba(' + rgb + ',0)');
		
		g.fillStyle = grad;
		g.fillRect(0, 0, cx*2, cy*2);

		g.globalCompositeOperation = 'lighter';
		g.fillStyle = baseColor;
		g.fillRect(cx, cy, 1, 1);

		g.restore();
	},

	splitColorComponents: function(cssColor) {
		_tempColorComponents[0] = 0;
		_tempColorComponents[1] = 0;
		_tempColorComponents[2] = 0;
		
		if (cssColor.charAt(0) === '#') {
			const hex = cssColor.replace('#', '');
			var numH = parseInt(hex, 16);
			if (hex.length >= 4) {
				_tempColorComponents[0] = (numH >> 16) & 0xFF;
				_tempColorComponents[1] = (numH >>  8) & 0xFF;
				_tempColorComponents[2] =  numH        & 0xFF;
			} else {
				_tempColorComponents[0] = ((numH >> 8) & 0xF) * 17;
				_tempColorComponents[1] = ((numH >> 4) & 0xF) * 17;
				_tempColorComponents[2] = ( numH       & 0xF) * 17;
			}
		} else if (RE_RGB.test(cssColor)) {
			_tempColorComponents[0] = parseInt( RegExp['$1'] , 10);
			_tempColorComponents[1] = parseInt( RegExp['$2'] , 10);
			_tempColorComponents[2] = parseInt( RegExp['$3'] , 10);
		}
		
		return _tempColorComponents;
	},

	drawMarkerRing: function(g, cx, cy, R, baseColor, tx, ty) {
		g.save();
		g.translate(tx || 0, ty || 0);

		g.strokeStyle = baseColor;

		g.beginPath();
		g.arc(cx+0.5, cy+0.5, R+1, 0, Math.PI*2);

		g.globalAlpha = 0.5;
		g.lineWidth = 3;
		g.stroke();
		g.globalCompositeOperation = 'lighter';
		g.lineWidth = 2;
		g.stroke();
		g.lineWidth = 1;
		g.stroke();

		g.restore();
	},
	
	drawCircleMarker: function(g, cx, cy, R, baseColor, tx, ty, withRing) {
		if (withRing) {
			this.drawMarkerRing(g, cx, cy, R, baseColor, tx, ty);
		}
		
		g.save();
		
		g.translate(tx || 0, ty || 0);

		g.lineWidth = 1;
		g.strokeStyle = '#000';
		g.fillStyle = baseColor;

		g.beginPath();
		g.arc(cx+0.5, cy+0.5, R, 0, Math.PI*2);
		g.fill();

		g.fillStyle = this.createShadeGradient(g, cx, cy - R/2, R);
		g.fill();

		g.fillStyle = this.createHighlightGradient(g, cx, cy - R/2, R);
		g.globalCompositeOperation = 'lighter';
		g.fill();
		g.globalCompositeOperation = 'source-over';

		g.stroke();


		g.restore();
	},

	drawDirectionalMarker: function(g, cx, cy, R, baseColor, tx, ty) {
		g.save();

		g.translate(tx || 0, ty || 0);

		g.lineWidth = 1;
		g.strokeStyle = '#000';
		g.fillStyle = baseColor;


		g.beginPath();
		g.moveTo(cx-R, cy-R/2);
		g.lineTo(cx  , cy-R/2);
		g.lineTo(cx+R, cy  );
		g.lineTo(cx  , cy+R/2);
		g.lineTo(cx-R, cy+R/2);
		g.lineTo(cx-R/2, cy);

		g.closePath();
		g.fill();
		g.stroke();


		g.restore();
	},

	createHighlightGradient: function(g, cx, cy, r) {
		const grad = g.createRadialGradient(cx, cy, r / 3, cx, cy, r);
		
		grad.addColorStop(0.0 , '#666');
		grad.addColorStop(1.0 , '#000');
		
		return grad;
	},

	createShadeGradient: function(g, cx, cy, r) {
		const grad = g.createRadialGradient(cx, cy, r*0.9, cx, cy, r*1.2);
		
		grad.addColorStop(0.0 , 'rgba(0,0,0,0)');
		grad.addColorStop(1.0 , 'rgba(0,0,0,0.25)');
		
		return grad;
	},

	selfTest() {
		const cv = document.createElement('canvas');
		cv.width = 32;
		cv.height = 32;

		const g = cv.getContext('2d');

		this.drawCircleMarker(g, 8, 8, 6, '#1040E0', 0, 0, false);
		this.drawDirectionalMarker(g, 8, 8, 6, '#1040E0', 16, 0, false);

		console.log(cv.toDataURL());
	}
};

export default MarkerUtils;