

function MarkerSizing(fs, ts) {
	this.fromSize = fs;
	this.toSize = ts;
}

const MarkerSizingPresetNames = {
	FIXED_SMALL: 0,
	FIXED_MID:   1,
	FIXED_LARGE: 2,
	TO_LARGE:    3
}

const MarkerSizingPresets = [
	new MarkerSizing(1, 1),
	new MarkerSizing(3, 3),
	new MarkerSizing(5, 5),
	new MarkerSizing(2, 6)
];

function getMarkerSizingPresetName(v, tolower) {
	for (var n in MarkerSizingPresetNames) if (MarkerSizingPresetNames.hasOwnProperty(n)) {
		if (MarkerSizingPresetNames[n] === v) {
			if (tolower) {
				return n.replaceAll('_', '-').toLowerCase();
			}

			return n;
		}
	}

	return null;
}

export {MarkerSizingPresets, MarkerSizingPresetNames, getMarkerSizingPresetName};