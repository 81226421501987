import LoaderBase from './LoaderBase'
const re_ret = /(\r|\n)+/ ;

class CSVFileLoader extends LoaderBase {
	constructor(listener, isZip) {
		super(listener, isZip);
	}

	processCountProgress(prog) {
		if (this.listener && this.listener.loaderOnCountProgress) {
			this.listener.loaderOnCountProgress(prog);
		}
	}

	processAfterIdCount(count, idMap) {
		if (this.listener && this.listener.loaderAfterIdCount) {
			 this.listener.loaderAfterIdCount(count, idMap);
		}
	}

	processLine(line, lineNo, numEntireLines) {
		const fields = line.replace(re_ret,'').split(',');

		if (this.listener && this.listener.loaderOnFieldset && lineNo >= this.numHeaderLines) {
			 this.listener.loaderOnFieldset(fields, lineNo);
		}
		
		if (0 === (lineNo & 0x3FFF)
			&& this.listener && this.listener.loaderOnProgressChange) {
			 this.listener.loaderOnProgressChange( 100 * lineNo / numEntireLines );
		}
	}
	
	processFileEnd() {
		if (this.listener && this.listener.loaderOnFileEnd) {
			this.listener.loaderOnFileEnd();
		}

		// Packaged files - - - - - - - - - - - - - - - - - - - - - - - - - - - -

		if (this.manifest) {
			const title = findLayerTitle(this.manifest);
			if (title && this.listener && this.listener.loaderFoundTitle) {
				this.listener.loaderFoundTitle(title);
			}

			if (this.listener) {
				const cfg = findLayerConfig(this.manifest);
				if (cfg && this.listener.loaderConfigureAfterLoad) {
					this.listener.loaderConfigureAfterLoad(cfg);
				}	
			}
		}

		if (this.listener && this.listener.loaderReportError) {
			this.listener.loaderReportError();
		}

		if (this.annotation) {
			if (this.listener && this.listener.loaderFoundAnnotation) {
				this.listener.loaderFoundAnnotation(this.annotation);
			}
		}
	}
};

function findManifestLayer(manifest) {
	if (Array.isArray(manifest.layers)) {
		return manifest.layers[0];
	} else {
		return null;
	}
}

function findLayerTitle(manifest) {
	const first = findManifestLayer(manifest);
	if (first) {
		return first.title || null;
	} else {
		return null;
	}
}

function findLayerConfig(manifest) {
	const first = findManifestLayer(manifest);
	if (first && first.hasOwnProperty('config')) {
		return first.config || null;
	}

	return null;
}

export default CSVFileLoader;