import React from 'react';
import { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';
import SpeedIcon from '@material-ui/icons/Speed';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import './AttrGenDialog.css'
import get_i18n from '../Internationalization';

const kDefaultSAName = 'speed';

class AttrGenDialog extends Component {
	
	constructor(props) {
		super(props);
		this.attrNameTextField = null;
		this.intCheck = null;
		this.state = {
			type: 'kmh',
			done: false
		};

		this.txSpeedName = null;
	}

	initStates() {
		this.setState( {done: false} );
	}

	setDone() {
		this.setState( {done: true} );
	}

	render() {
		const is_kmh = (this.state.type === 'kmh');
		const done = this.state.done;
	
		return (<Dialog open={ this.props.open }>
			<DialogTitle className="mm-attrgen-title">
				<SpeedIcon fontSize="large" />
				<span className="mm-attrgen-desc">Generate speed</span>
			</DialogTitle>

			<div className="mm-attrgen-section">
				{ this.renderOptionButtons(is_kmh) }

				<TextField ref={ this.refAttrNameTextField.bind(this) } fullWidth defaultValue={ kDefaultSAName } label="Attribute name" /><br />
				<Button className={ done ? 'mm-attrgen-done' : '' } disabled={ done } onClick={ this.props.onExecuteClick } fullWidth variant={done ? 'outlined' : 'contained'} color="primary">{ get_i18n( done ? 'DoneCheck' : 'Generate' ) }</Button>
			</div>

			<MuiDialogActions>
			<Button color="secondary" onClick={ this.props.onCloseClick }>Close</Button>
			</MuiDialogActions>
		</Dialog>);
	}

	renderOptionButtons(is_kmh) {
		return (
			<Grid container className="mm-attrgen-option-gird">
				<Grid item xs={6}>
					<ButtonGroup fullWidth color="primary" disableRipple={true} size="small" className="mm-attrgen-speed-types">
						<Button onClick={ this.onTypeButton.bind(this, 'kmh') } variant={ is_kmh ? 'contained' : '' }>km/h</Button>
						<Button onClick={ this.onTypeButton.bind(this, 'ms') } variant={ !is_kmh ? 'contained' : '' }>m/s</Button>
					</ButtonGroup>
				</Grid>
				<Grid item xs={6}>
					<FormControlLabel control={
						<Checkbox ref={ this.refIntCheck.bind(this) } color="primary" />
					} label={ get_i18n('AsInt') } />
				</Grid>
			</Grid>
		);
	}

	onTypeButton(newValue) {
		this.setState({ type: newValue });
	}

	refAttrNameTextField(el) {
		const input = findInput(el);
		if (input) { this.attrNameTextField = input; }
	}

	refIntCheck(el) {
		const input = findInput(el);
		if (input) { this.intCheck = input; }
	}

	getAttrName() {
		if (this.attrNameTextField) {
			return this.attrNameTextField.value;
		}

		return null;
	}

	getIntChecked() {
		if (this.intCheck) {
			return this.intCheck.checked;
		}

		return false;
	}

	getSpeedFactor() {
		const re = /[kK]/ ;
		if (re.test( this.state.type )) {
			// km/h
			return 3.6;
		} else {
			return 1;
		}
	}
}

function findInput(el) {
	if (el) {
		return el.querySelector('input');
	}

	return null;
}

export default AttrGenDialog;