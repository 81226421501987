
class ClockRenderer {
	constructor(config) {
		this.config = config;
		this.canvas = document.createElement('canvas');

		configureCanvas(this.canvas, config);
		this.g = this.canvas.getContext('2d');
		
		this.dateDirty = true;
		this.currentTime = {hour:0, min:0, sec:0, year: 1900, month: 1, date: 1}
	}

	getElement() {
		return this.canvas;
	}

	render() {
		const g = this.g;
		g.clearRect(0, 0, this.canvas.width, this.canvas.height);
		this.drawScales(g);
		this.drawCircle(g);
		const t = this.currentTime;
		this.drawHands(g, t.hour, t.min, t.sec);
	}

	setUnixtime(unixtime) {
		const d = new Date(unixtime*1000);
		const t = this.currentTime;
		
		t.hour = d.getHours();
		t.min  = d.getMinutes();
		t.sec  = d.getSeconds();
		
		const yr = d.getFullYear();
		const mn = d.getMonth() + 1;
		const dt = d.getDate();
		
		if (t.year !== yr || t.month !== mn || t.date !== dt) {
			t.year  = yr;
			t.month = mn;
			t.date  = dt;
			this.dateDirty = true;
		}
	}

	checkDateDirty() {
		const oldVal = this.dateDirty;
		
		this.dateDirty = false;
		return oldVal;
	}

	drawHands(g, hour, minute, sec) {
		minute += sec/60.0;
		hour   = (hour % 12) + minute/60.0;
		const h_angle = Math.PI * hour / 6.0;
		const m_angle = Math.PI * minute / 30.0;
		
		const cx = this.config.viewWidth >> 1;
		const cy = this.config.viewHeight >> 1;
		drawHand(g, cx, cy, 9, Math.floor(this.config.radius+5), m_angle);
		drawHand(g, cx, cy, 9, Math.floor(this.config.radius*0.8), h_angle);
	}

	drawCircle(g) {
		const dpr = window.devicePixelRatio;
		g.strokeStyle = '#FFF';
		const r = (this.config.radius+8) * dpr;
		g.lineWidth = dpr;
		const cx = (this.config.viewWidth >> 1) * dpr;
		const cy = (this.config.viewHeight >> 1) * dpr;

		g.beginPath();
		g.arc(cx, cy, r, 0, Math.PI*2, false);
		g.stroke();
	}
	
	drawScales(g) {
		g.strokeStyle = '#FFF';
		const r = this.config.radius;
		const cx = this.config.viewWidth >> 1;
		const cy = this.config.viewHeight >> 1;
		
		for (var i = 0;i < 60;++i) {
			const angle = (Math.PI * i) / 30.0;
			if ( 0 === (i%5) ) {
				// major
				drawMajorScale(g, cx, cy, r, angle);
			} else {
				// minor
				drawMinorScale(g, cx, cy, r, angle);
			}
		}
	}
}

function drawHand(g, cx, cy, backLen, frontLen, angle) {
	const dpr = window.devicePixelRatio;
	const w1 = dpr * 2;
	const w2 = dpr * 3;

	g.save();
	g.fillStyle = '#FFF';
	g.translate(cx*dpr, cy*dpr);
	g.rotate(angle);
	
	g.beginPath();
	g.moveTo(-w1, -frontLen*dpr);
	g.lineTo( w1, -frontLen*dpr);
	g.lineTo( w2, backLen*dpr);
	g.lineTo(-w2, backLen*dpr);
	g.fill();

	g.restore();
}

function drawMajorScale(g, cx, cy, r, angle) {
	drawScale(g, cx, cy, r, angle, 3, 6, 0);
}

function drawMinorScale(g, cx, cy, r, angle) {
	drawScale(g, cx, cy, r, angle, 1, 6, 3);
}

function drawScale(g, cx, cy, radius, angle, thick, len, pad) {
	const r = window.devicePixelRatio;
	const dx1 =  Math.sin(angle)*(radius+pad)*r;
	const dy1 = -Math.cos(angle)*(radius+pad)*r;
	const dx2 =  Math.sin(angle)*(radius+len)*r;
	const dy2 = -Math.cos(angle)*(radius+len)*r;
	
	g.lineWidth = r*thick;
	g.beginPath();
	g.moveTo(cx*r + dx1, cy*r + dy1);
	g.lineTo(cx*r + dx2, cy*r + dy2);
	g.stroke();
}

function configureCanvas(cv, cfg) {
	const r = window.devicePixelRatio;
	cv.width  = cfg.viewWidth * r;
	cv.height = cfg.viewHeight * r;
	
	const s = cv.style;
	s.width  = cfg.viewWidth + 'px';
	s.height = cfg.viewHeight + 'px';

	return cv;
}

export default ClockRenderer;
