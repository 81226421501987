function addPanelHandlers(klass) {
	// CONFIGURATION PANEL HANDLERS -------------------------------------

	// data option
	klass.prototype.onKeepShowChange = function(selfId) {
		const selfLayer = this.layerById(selfId);
		if (selfLayer && selfLayer.getRemainMode) {
			var oldValue = true;
			const r = selfLayer.getRemainMode();
			if (r) { oldValue = r.beforeFirst; }
			
			const newValue = !oldValue;
			selfLayer.setRemainMode(newValue, newValue);
		}
	};

	klass.prototype.onInterpolationChange = function(selfId) {
		const selfLayer = this.layerById(selfId);
		if (selfLayer) {
			const newValue = !this.state.interpolationLimitDialogOpen;

			if (this.intpDialogComponent) {
				this.intpDialogComponent.clearReport();
				this.intpDialogComponent.setTargetLayer(selfLayer);
				this.intpDialogComponent.pickLayerState(selfLayer);
			}

			this.setState({
				interpolationLimitDialogOpen: newValue,
				interpolationLimitDlgLayer: selfLayer
			});
		}
	};

	// mode change

	klass.prototype.onLayerMarkerModeChange = function(selfId, newValue) {
		const selfLayer = this.layerById(selfId);
		if (selfLayer.setMarkerMode) {
			selfLayer.markerSetting.setAppearance(newValue);
			selfLayer.setMarkerMode(newValue);
		}
	};

	klass.prototype.onTrajectoryVisibilityChange = function(layerId, newValue) {
		const layer = this.layerById(layerId);
		if (layer.setTrajectoryVisibility) {
			layer.setTrajectoryVisibility(newValue);
		}

		this.renewLayerListComponent();
	};

	klass.prototype.onLayerMarkerDeformChange = function(selfId, newValue) {
		const selfLayer = this.layerById(selfId);
		if (selfLayer.markerSetting) {
			selfLayer.markerSetting.setDeform(newValue);
		}
	};

	klass.prototype.onLayerDirShiftChange = function(selfId, newValue) {
		const selfLayer = this.layerById(selfId);
		if (selfLayer.setDirShift) {
			selfLayer.setDirShift(newValue);
		}
	};

	// ::: Coloring :::
	klass.prototype.onLayerColoringChange = function(selfId, newColoringKey) {
		const selfLayer = this.layerById(selfId);
		selfLayer.setColoringKey(newColoringKey, true);
	};

	klass.prototype.onLayerMarkerVariationChange = function(selfId, newValue) {
		const selfLayer = this.layerById(selfId);
		if (selfLayer.markerSetting) {
			selfLayer.markerSetting.setPrimaryVariation(newValue);
		}
	};

	klass.prototype.onTrajectoryColorChange = function(layerId, newValue) {
		const layer = this.layerById(layerId);
		if (layer && layer.setTrajectoryColor) {
			layer.setTrajectoryColor(newValue);
		}
	};

	klass.prototype.onTrajectoryOpacityChange = function(layerId, newValue) {
		const layer = this.layerById(layerId);
		if (layer && layer.setTrajectoryOpacity) {
			const alpha = (newValue === 0) ? 0.01 : (newValue/10);
			layer.setTrajectoryOpacity(alpha);
		}
	};

	// .oO  Sizing  Oo.
	klass.prototype.onLayerMarkerSizingChange = function(selfId, newSizingType) {
		const selfLayer = this.layerById(selfId);
		selfLayer.setMarkerSizingType(newSizingType);
	};

	klass.prototype.onLayerMarkerSizeVariationChange = function(selfId, newValue) {
		const selfLayer = this.layerById(selfId);
		if (selfLayer.markerSetting) {
			selfLayer.markerSetting.setSecondaryVariation(newValue);
		}
	};

	// Appearance of selected objects
	klass.prototype.onLayerMarkerSelDispClick = function(selfId, newFlags) {
		const selfLayer = this.layerById(selfId);
		if (selfLayer.setSelectionAppearance) {
			selfLayer.setSelectionAppearance(newFlags);
		} 
	};

	klass.prototype.onLayerTrajectorySelDispClick = function(selfId, newFlags) {
		const selfLayer = this.layerById(selfId);
		if (selfLayer.setSelectionTrajectoryAppearance) {
			selfLayer.setSelectionTrajectoryAppearance(newFlags);
		}
	};

	// -= Tail =-
	klass.prototype.onTailModeChange = function(selfId, newValue) {
		const selfLayer = this.layerById(selfId);
		selfLayer.setTailMode(newValue);
	};

	klass.prototype.onTailDurationClick = function(selfId, newValue) {
		const selfLayer = this.layerById(selfId);
		selfLayer.setTailDuration(newValue);
	};
}

export default addPanelHandlers;