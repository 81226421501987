import React from 'react';
import { Component } from 'react';

import Select from '@material-ui/core/Select';
import SizingItem from './SizingItem'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import get_i18n from '../Internationalization';

class SizingSelector extends Component {
	render() {	
		return (<div>
				<InputLabel shrink>{ get_i18n('Sizing') }</InputLabel>
				<Select onChange={ this.onChange.bind(this) } value={ this.props.value }>
					<MenuItem value={ 0 }><SizingItem value={0} /></MenuItem>
					<MenuItem value={ 1 }><SizingItem value={1} /></MenuItem>
					<MenuItem value={ 2 }><SizingItem value={2} /></MenuItem>
					<MenuItem value={ 3 }><SizingItem value={3} /></MenuItem>
				</Select>
			</div>) ;
	}
	
	onChange(e, selectedItem) {
		const value = selectedItem.props.value | 0;
		if (this.props.onChange) {
			this.props.onChange(value);
		}
	}
}

export default SizingSelector;