import AttributeType from './AttributeType'
import leaflet from 'leaflet'
import AttributeMapping from './AttributeMapping';
import { InterpolationLimitType } from '../../model/MMLayer';

const kIntp_LastValue = -1;

const DerivedVelocityAttribute = ",velocity,";
const DerivedDirectionAttribute = ",direction,";
const DerivedDistanceAttribute = ",distance,";

const kDiscardIntp = -1;

class MovingData {
	constructor() {
		this.idmap = {};
		this.flattenList = [];
		this.derivedDataReady = false;
		this.speedAttributeName = null;

		this.remain = {
			beforeFirst: true,
			afterLast: true
		};

		this.interpolationLimit = {
			active: false,
			threshold: 600,
			type: 0,
			appearance: 0,
			dirty: false
		};

		this.latSum = 0;
		this.lngSum = 0;
		this.latCount = 0;
		this.lngCount = 0;

		this.timeRange = {
			min: Number.POSITIVE_INFINITY,
			max: Number.NEGATIVE_INFINITY
		}

		this.errorList = [];
	}
	
	getRemainMode() {
		return this.remain;
	}

	setRemainMode(beforeFirst, afterLast) {
		this.remain.beforeFirst = beforeFirst;
		this.remain.afterLast = afterLast;
	}

	// Reset to no range
	resetTimeRange() {
		this.timeRange.min = Number.POSITIVE_INFINITY;
		this.timeRange.max = Number.NEGATIVE_INFINITY;
	}

	// Expand time range by input data
	updateTimeRange(t) {
		var r = this.timeRange;
		if (t < r.min) {  r.min=t;  }
		if (t > r.max) {  r.max=t;  }
	}
	
	allocate(idmap, attrMap) {
		var firstTset = null;
		for (const id in idmap) if ( idmap.hasOwnProperty(id) ) {
			const newTs = this._allocateForId(id, idmap[id], attrMap, firstTset);
			if (!firstTset) { firstTset = newTs; }
		}
		
		return firstTset;
	}

	appendAdditionalRecords(recordList) {

	}

	hasId(id) {
		return !!this.idmap[id];
	}

	// 読み込み完了後にIDを追加
	appendId(newId, length, attrMap) {
		const refTset = this.findAnyTset();
		const newTs = this._allocateForId(newId, length, attrMap, refTset);
		return newTs;
	}

	// 属性セット参考用のtsetを取得
	findAnyTset() {
		const ids = Object.keys(this.idmap);
		if (ids[0]) {
			return this.idmap[ ids[0] ];
		}

		return null;
	}

	// レコード数を拡張
	expandRecordListLength(id, delta) {
		const tset = this.idmap[ id ];
		if (tset) {
			tset.expand(delta);
		}
	}

	_allocateForId(id, length, attrMap,  referenceTset) {
		const ts = new TSet(length, id, attrMap,  referenceTset);
		this.idmap[id] = ts;
		return ts;
	}
	
	referTSetAt(index) {
		return this.flattenList[index];
	}

	receiveData(id, attrName, value, lineNo) {
		const ent = this.idmap[id];
		var numLocationErrors = 0;
		if (ent) {
			ent.write(attrName, value);
			if (isNaN(value)) {
				this.addError(MDError.TypeMismatch, lineNo);
			}
			
			if (attrName === 'time') {
				this.updateTimeRange(value);
			} else if (attrName === 'lat') {
				if (isNaN(value)) {
					++numLocationErrors;
				} else {
					this.latSum += value;
					++this.latCount;
				}
			} else if (attrName === 'lng') {
				if (isNaN(value)) {
					++numLocationErrors;
				} else {
					this.lngSum += value;
					++this.lngCount;
				}
			}
		}
	}

	receiveRowEnd(id, lineNo) {
		const ent = this.idmap[id];
		if (ent) {
			const err = ent.checkDupTime();
			if (err) {
				this.addError(err, lineNo);
			}
			ent.next();
		}
	}

	receiveTooFewColumns(lineNo) {
		this.addError(MDError.TooFewColumns, lineNo);
	}

	addError(code, lineNo) {
		if (this.errorList.length >= 101) { return; }
		this.errorList.push({
			code: code,
			lineNo: lineNo
		});
	}

	countErrors() {
		return this.errorList.length;
	}

	receiveAllEnd() {
		this._buildFlatten();
	}

	_buildFlatten() {
		// Clear
		this.flattenList.length = 0;

		const m = this.idmap;
		for (const id in m) if (m.hasOwnProperty(id)) {
			this.flattenList.push( m[id] );
		}
	}

	ensureDerivedData() {
		if (!this.derivedDataReady) {
			this._buildDerived();
			this.derivedDataReady = true;
			return true;
		}

		return false;
	}

	ensureDerivedSpeed(attrName, factor, intMode) {
		if (this.speedAttributeName !== attrName) {
			this._buildSpeedData(attrName, factor, intMode);
			this.speedAttributeName = attrName;
		}
	}

	_buildDerived() {
		for (const tset of this.flattenList) {
			tset.buildDerivedData();
		}
	}

	_buildSpeedData(outAttrName, factor, intMode) {
		for (const tset of this.flattenList) {
			tset.buildSpeedData(outAttrName, factor, intMode);
		}
	}

	countIDs() {
		return this.flattenList.length;
	}

	referFlattenList() {
		return this.flattenList;
	}

	pick(outPool, time) {
		// <outPool> receives result

		const limType = this.interpolationLimit.type;
		const limThreshold = this.interpolationLimit.threshold;

		if (limType === InterpolationLimitType.Distance) {
			this.ensureDerivedData();
		}

		const ls = this.flattenList;
		for (const tset of ls) {
			if (!tset.checkTimeRange(this.remain, time)) {
				continue;
			}
			
			const outObj = outPool.next();
			if (outObj) {
				outObj.ensureAttributeLength( tset.countAttributes() );

				if (kDiscardIntp === tset.preparePickState(time, limType, limThreshold)) {
					// 非表示
					outPool.cancel();
				} else {
					tset.interpolateAll(outObj.attrList);

					if (tset.dirAttrIndex >= 0) {
						outObj.direction = outObj.attrList[tset.dirAttrIndex];
					}
					outObj.id = tset.ownerId;
					outObj.selected = tset.selected;
					outObj.filterResult = null;

					if (outPool.tailEnabled) {
						tset.fillTailLog(outObj, outPool.tailDuration);
					}
				}
			}
		}
	}

	updateSelection(selection) {
		const ls = this.flattenList;
		for (const tset of ls) {
			tset.selected = selection.has( tset.ownerId );
		}
	}

	calcLatCenter() {  return this.latSum / this.latCount;  }
	calcLngCenter() {  return this.lngSum / this.lngCount;  }
}

// Timeseries attributes set
class TSet {
	constructor(length, ownerId, attrMap,  referenceTset) {
		this.ownerId = ownerId;
		this.selected = false;

		this.writtenCount = 0;
		this.length = length;
		this.pickFirstIndex = 0;
		this.pickOffset = 0;
		this.pickSpecifiedTime = 0;

		this.flattenList = [];
		this.attrs = { };
		this.dirAttrIndex = -1;
		this.disAttrIndex = -1;
		this.generateAttrsTV(attrMap);
		this._buildFlatten(referenceTset);
	}

	expand(deltaLength) {
		for (const aname in this.attrs) if(this.attrs.hasOwnProperty(aname)) {
			console.log("%c"+this.ownerId+' / '+aname+" "+deltaLength, "color:#090");
			this.attrs[aname].shift(deltaLength);
		}
	}

	hasName(attrName) {
		return this.attrs.hasOwnProperty(attrName);
	}

	tjGetId() {
		return this.ownerId;
	}
	
	tjGetLength() {
		return this.length;
	}
	
	tjGetLatAt(index) {
		return this.attrs.lat.arr[index];
	}

	tjGetLngAt(index) {
		return this.attrs.lng.arr[index];
	}

	tjGetTimeAt(index) {
		return this.attrs.time.arr[index];
	}

	generateAttrsTV(attrMap) {
		this.clearAttributes();
		attrMap.each( this.addAttribute.bind(this) );
	}

	clearAttributes() {
		for (const a in this.attrs) if (this.attrs.hasOwnProperty(a)) {
			delete this.attrs[a];
		}
	}

	addAttribute(name, details) {
		if (details.hidden) { return; }
		const tv = new TimeseriesValue( ArrayClassForType(details.type) , this.length, name, details.priority, details.interpolation);
		this.attrs[name] = tv;

		return tv;
	}

	countAttributes() {
		return this.flattenList.length;
	}

	_buildFlatten(useReference) {
		this.flattenList.length = 0;
		const m = this.attrs;

		if (useReference) {
			const refArray = useReference.flattenList;
			for (const refTv of refArray) {
				this.flattenList.push( m[ refTv.name ] );
			}
		} else {
			for (const name in m) if (m.hasOwnProperty(name)) {
				this.flattenList.push( m[name] );
			}
			
			this.flattenList.sort( function(a,b) { return a.priority - b.priority; } );
			console.log(this.flattenList)
		}
	}

	buildDerivedData() {
		const xs = this.attrs.lng.arr;
		const ys = this.attrs.lat.arr;

		// 移動方向属性追加・計算
		if (!this.hasName(DerivedDirectionAttribute)) {
			const tv = this.addAttribute(DerivedDirectionAttribute, {
				type: AttributeType.FLOAT,
				interpolation: kIntp_LastValue,
				priority: 999
			});

			const dirArray = this.attrs[ DerivedDirectionAttribute ].arr;
			fillDirections(dirArray, xs, ys);

			this.dirAttrIndex = this.flattenList.length;
			this.flattenList.push(tv);
		}

		// 追加属性 レコード間距離計算
		if (!this.hasName(DerivedDistanceAttribute)) {
			const tv = this.addAttribute(DerivedDistanceAttribute, {
				type: AttributeType.FLOAT,
				interpolation: kIntp_LastValue,
				priority: 998
			});

			const disArray = this.attrs[ DerivedDistanceAttribute ].arr;
			fillDistances(disArray, xs, ys);

			this.disAttrIndex = this.flattenList.length;
			this.flattenList.push(tv);
		}
	}

	getDistanceArray() {
		if (this.disAttrIndex < 0) { return null; }
		return this.flattenList[this.disAttrIndex].arr;
	}

	getTimeArray() {
		if (!this.attrs.time) { return null; }
		return this.attrs.time.arr;
	}

	buildSpeedData(outAttrName, factor, intMode) {
		const xs = this.attrs.lng.arr;
		const ys = this.attrs.lat.arr;
		const ts = this.attrs.time.arr;

		if (!this.hasName(outAttrName)) {
			const tv = this.addAttribute(outAttrName, {
				type: AttributeType.FLOAT,
				interpolation: kIntp_LastValue,
				priority: 999
			});

			this.flattenList.push(tv);
		}

		const spdArray = this.attrs[ outAttrName ].arr;
		fillSpeeds(spdArray, xs, ys, ts, factor, intMode);

	}

	makeOrderedAttributeArray() {
		var ret = [];
		for (const tv of this.flattenList) {  ret.push( tv.name );  }

		return ret;
	}
	
	// >>> WRITE API
	write(attrName, value) {
		if (this.attrs.hasOwnProperty(attrName)) {
			const i = this.writtenCount;
			if (i < this.length) {
				this.attrs[attrName].write(i, value);
			}
		}
	}

	next() {
		if (this.writtenCount < this.length) {
			++this.writtenCount;
		}
		if (0 === (this.writtenCount % 10000))
		console.log(this.writtenCount);
	}

	// 前レコードと同じ時刻かチェック
	checkDupTime() {
		const kGeoThresh = 0.0001;
		const i = this.writtenCount;
		if (i === 0) { return false; }

		const xs = this.attrs.lng.arr;
		const ys = this.attrs.lat.arr;
		const ts = this.attrs.time.arr;

		const dx = Math.abs( xs[i] - xs[i-1] );
		const dy = Math.abs( ys[i] - ys[i-1] );
		const dt = Math.abs( ts[i] - ts[i-1] );

		if (dt < 1) {
			if (dx > kGeoThresh || dy > kGeoThresh) {
				return MDError.DupTimeIsolated;
			}
		}

		return false;
	}
	
	// === READ API

	checkTimeRange(remainOption, pickTime) {
		const arr = this.attrs.time.arr;
		if (!arr) { return false; }
		if (!arr.length) { return false; }
		
		const first = arr[ 0 ];
		const last = arr[ arr.length - 1 ];
		
		if (!remainOption.beforeFirst && pickTime < first) { return false }
		if (!remainOption.afterLast && pickTime > last) { return false }
		
		return true;
	}

	preparePickState(time, limitType, limitThreshold) {
		// レコード間距離がある場合は、参照をセット
		let disArr = null;
		const hasDis = (this.disAttrIndex >= 0);
		if (hasDis) {
			disArr = this.flattenList[ this.disAttrIndex ].arr;
		}

		const arr = this.attrs.time.arr;
		const len = arr.length;
		var i = 0;

		// Use cached index
		if (time >= this.pickSpecifiedTime) {  i = this.pickFirstIndex;  }
		this.pickSpecifiedTime = time;

		for ( ;i < len;++i) {
			const t = arr[i];
			if (t === time) {
				// NO-INTP

				// 補間時は早い方のレコード、補間しない場合はそれ自体のインデックス
				this.pickFirstIndex = i;

				// 補間時は前レコード-次レコード間の相対位置
				this.pickOffset = 0;
				return;
			}

			if (t > time) {
				break;
			}
		}
		
		const backIndex = i-1;
		const fwdIndex  = i;
		
		if (backIndex < 0) {
			// before start
			this.pickFirstIndex = 0;
			this.pickOffset = 0;
			return;
		} else if (backIndex >= (len-1)) {
			// after end
			this.pickFirstIndex = len-1;
			this.pickOffset = 0;
			return;
		} else {
			// INTP
			const t1 = arr[backIndex];
			const t2 = arr[fwdIndex];
			const dt = t2 - t1;

			this.pickFirstIndex = backIndex;
			this.pickOffset = (time-t1) / dt;

			if (limitType === InterpolationLimitType.Time) {
				if (dt > limitThreshold) {
					return kDiscardIntp;
				}
			} else if (limitType === InterpolationLimitType.Distance && hasDis) {
				if ( disArr[backIndex] > limitThreshold) {
					return kDiscardIntp;
				}
			}
		}
	}

	interpolateAll(receiver) {
		const fls = this.flattenList;
		const ofs = this.pickOffset;
		const i1  = this.pickFirstIndex;
		if (!ofs) {
			// No interpolation(copy)
			var aIndex = 0;
			for (const a of fls) {
				receiver[aIndex++] = a.arr[i1];
			}
		} else {
			// Select interpolation(copy)
			let aIndex = 0;
			const _o = 1.0 - ofs;
			for (const a of fls) {
				const intp = a.interpolation;
				if (intp === kIntp_LastValue) {
					// -1: Use prev
					receiver[aIndex++] = a.arr[i1];
				} else if (0 === intp) {
					// 0: Use nearest
					receiver[aIndex++] = (ofs>=0.5) ? a.arr[i1+1] : a.arr[i1];
				} else {
					// 補間実行
					// Linear interpolate
					receiver[aIndex++] = a.arr[i1]*_o  +  a.arr[i1+1]*ofs;
				}
			}
		}
		
	}

	interpolate(receiver, attrName) {
		const ofs = this.pickOffset;
		const arr = this.attrs[attrName].arr;
		if (!ofs) {
			receiver[attrName] = arr[this.pickFirstIndex];
		} else {
			const i2 = this.pickFirstIndex+1;
			const _o = 1.0 - ofs;
			receiver[attrName]  =  arr[this.pickFirstIndex]*_o  +  arr[i2]*ofs;
		}
	}

	fillTailLog(outObject, requiredDuration) {
		const outArray = outObject.tailLog;
		const extendMax = 24;
		const tailLength = outArray.length;
		var srcIndex = this.pickFirstIndex;

		const attrs = this.attrs;
		if (!attrs.lat || !attrs.lng || !attrs.time) { return; }

		const arrLng = attrs.lng.arr;
		const arrLat = attrs.lat.arr;
		const arrTime = attrs.time.arr;

		const originTime = arrTime[srcIndex];
		const reqMinTime = originTime - requiredDuration;

		var i = 0;
		var lastIndex = 0;
		var firstIndexBeforeMin = -1;
		for (i = 0;i < tailLength;++i) {
			const dest = outArray[i];
			dest.lat = arrLat[srcIndex];
			dest.lng = arrLng[srcIndex];
			dest.time = arrTime[srcIndex];
			lastIndex = srcIndex;
			if (firstIndexBeforeMin < 0 && dest.time <= reqMinTime) {
				firstIndexBeforeMin = i;
			}

			if (--srcIndex < 0) { srcIndex = 0; }
		}

		if (firstIndexBeforeMin > 0 && firstIndexBeforeMin < (tailLength-1)) {
			outArray.pop();

			// この場合は明らかに拡張不要なので打ち切り
			return;
		}

		// 必要ならリスト拡張
		if (lastIndex) {
			// lastIndex=0 > テイル末尾より以前のレコードは無いので無視
			
			for (i = 0;i < extendMax;++i) {
				const last = outArray[ outArray.length - 1 ];
				if (last.time <= reqMinTime) { break; }

				const dest = outObject.moreTail();
				dest.lat = arrLat[srcIndex];
				dest.lng = arrLng[srcIndex];
				dest.time = arrTime[srcIndex];

				if (srcIndex < 1) { break; }
				--srcIndex;
			}
		}

		// debug out
/*		if (outObject.id == 23000) {
			console.log(outArray[0].time - outArray[ outArray.length-1 ].time, outArray[ outArray.length-1 ].time, reqMinTime);
		}*/
	}

	getValueAtIndex(attrName, index) {
		const attr = this.attrs[attrName];
		if (attr) {
			return attr.arr[index];
		}

		return null;
	}
}

function fillDirections(outArray, xs, ys) {
	var i;
	var oldVal = -Math.PI/2;
	const outLen = outArray.length;
	for (i = 0;i < outLen;++i) { outArray[i] = oldVal; }

	const n = xs.length - 1;
	for (i = 0;i < n;++i) {
		const dx = xs[i+1] - xs[i];
		const dy = ys[i+1] - ys[i];
		const distance = Math.sqrt(dx*dx + dy*dy);

		if (distance > 0.00001) {
			oldVal = Math.atan2(dy, dx);
			outArray[i] = oldVal;
		} else {
			outArray[i] = oldVal;
		}
	}
	
	if (outLen) {
		outArray[ outLen-1 ] = oldVal;
	}

	return outArray;
}

function fillDistances(outArray, xs, ys) {
	let i;
	const n = xs.length - 1;
	outArray[n] = 0;

	const eth = leaflet.CRS.Earth;
	const pt1 = {lat:0, lng:0};
	const pt2 = {lat:0, lng:0};

	for (i = 0;i < n;++i) {
		const pos2 = i+1;

		pt1.lng = xs[i];
		pt1.lat = ys[i];

		pt2.lng = xs[pos2];
		pt2.lat = ys[pos2]

		outArray[i] = eth.distance(pt1, pt2);
	}
}

function fillSpeeds(outArray, xs, ys, ts, factor, intMode) {
	var i;
	const n = xs.length - 1;
	outArray[0] = 0;

	const eth = leaflet.CRS.Earth;
	const pt1 = {lat:0, lng:0};
	const pt2 = {lat:0, lng:0};

	for (i = 0;i < n;++i) {
		const pos2 = i+1;
		const dt = ts[pos2] - ts[i];

		pt1.lng = xs[i];
		pt1.lat = ys[i];

		pt2.lng = xs[pos2];
		pt2.lat = ys[pos2]

		const meter = eth.distance(pt1, pt2);

		var value = (dt ? (meter / dt) : 0) * factor;
		if (intMode) { value = Math.floor(value); }
		outArray[i+1] = value;
	}
}

function ArrayClassForType(dataType) {
	switch(dataType) {
		case AttributeType.INTEGER:
			return Int32Array;  break;
		case AttributeType.FLOAT:
			return Float32Array;  break;
		case AttributeType.DATETIME:
			return Float64Array;  break;
		default:
			return Array;  break;
	}
}

class TimeseriesValue {
	constructor(ArrayClass, length, name, priority, interpolation) {
		this.name = name;
		this.priority = priority;
		this.arr  = new ArrayClass(length);
		this.interpolation = interpolation;
	}

	write(index, value) {
		this.arr[index] = value;
	}

	shift(amount) {
		shiftArray(this.arr, amount);
		return this.arr;
	}

	realloc(newLength) {
		const oldArr = this.arr;
		const klass = oldArr.constructor;
		const newArr = new klass(newLength);

		newArr.set(oldArr);
		this.arr = newArr;
		return newArr;
	}

	getLength() {
		return this.arr.length;
	}
};

function shiftArray(arr, amount) {
	const entire = arr.length;
	const validLen = entire - amount;
	for (var i = 0;i < entire;++i) {
		if (i < validLen) {
			arr[i] = arr[i + amount]
		} else {
			arr[i] = 0;
		}
	}
}

const MDError = {
	DupTimeIsolated: 1,
	TypeMismatch: 2,
	TooFewColumns: 3
};

function testMDAppend() {
	const initialIds = { 1: 3 , 2: 3 , 3: 3 } ;

	console.log("%cTEST START", "background-color: #3F8");
	const md = new MovingData();
	console.log(md);

	const amap = new AttributeMapping();
	amap.addTestDefault();
	console.log(amap);

	md.allocate(initialIds, amap);
	md.appendId('9', 3, amap);

	console.log(md);

	console.log("%cexpand test", "background-color: #9F5");
	md.expandRecordListLength(2, 3);
	console.log(md);
	console.log("%cTEST END", "background-color: #9F0");

	console.log("%cArray shift test", "background-color: #A8F");

	const sarr = ['H','E','L','L','O','!'];
	shiftArray(sarr, 3);
	console.log(sarr);

}

export default MovingData;
export {kIntp_LastValue, MDError, testMDAppend}