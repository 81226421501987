import MMEventDispatcher from "../leafmob/EventDispatcher"

class SelectionManager extends MMEventDispatcher  {
	constructor(owner) {
		super();

		this.owner = owner;
		this.idMap = new Map();
	}

	clear() {
		this.idMap.clear();
	}
	
	idIterator() {
		return this.idMap.keys();
	}

	count() {
		return this.idMap.size;
	}

	selectId(id) {
		this.idMap.set(id, true);
	}

	has(id) {
		return this.idMap.has(id);
	}
	
	fire() {
		this.eventInvoke('SelectionChange', this);
	}
	
	debugOut() {
		console.log("SELECTION: ", this.idMap.size);
	}
}


export default SelectionManager;