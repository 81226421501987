class LayerSwap {
	constructor() {
		this.busy = false;
		this.upperElement = null;
		this.lowerElement = null;
	}
	
	beginSwap(upE, lowE) {
		if (this.busy) { return false; }
		const that = this;

		this.busy = true;
		const sU = upE.style;
		const sL = lowE.style;
		sU.transition = '';
		sU.transform  = 'translate(0, ' + (-lowE.clientHeight) + 'px)';

		sL.transition = '';
		sL.transform = 'translate(0, ' + upE.clientHeight + 'px)';
		
		setTimeout(function() {
			sU.transition = 'transform 0.3s';
			sU.transform  = 'none';
			sL.transition = 'transform 0.3s';
			sL.transform  = 'none';

			that.busy = false;
		}, 30);
		
		return true;
	}
}

export default LayerSwap;