import React from 'react';
import Button from '@material-ui/core/Button';
import { Component } from 'react';
import { MDError } from '../leafmob/md/MovingData';
import get_i18n from '../Internationalization';

class LayerErrorBox extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const ls = [];
		const src = this.props.errorList;
		if (src) {
			var prevId = null;
			for (const item of src) {
				const ekey = makeErrorId(item);
				if (prevId !== ekey) {
					ls.push(
						<li key={ ekey }>{ makeErrorText(item) }</li>
					);
				}
				prevId = ekey;
			}
		}

		return <div className="mm-layer-setting-outer mm-layer-errors-list">
			<div className="mm-layer-errors-head">
				<div>
					{ makeCountLabel(ls.length) }
				</div>
				<div className="mm-layer-errors-control">
					<Button onClick={ this.props.onDismiss } size="small" variant="outlined" color="secondary">{ get_i18n('Dismiss') }</Button>
				</div>
			</div>
			<ul>{ ls }</ul>
		</div> ;
	}
}

function makeCountLabel(n) {
	if (n < 1) { return 'No errors'; }

	if (n === 1) {
		return 'Found 1 error';
	} else if (n >= 101) {
		return 'More than 100 errors';
	}

	return `Found ${n} errors`;
}

function makeErrorId(e) {
	return `${e.code}:${e.lineNo}`;
}

function makeErrorText(e) {
	var s = 'L' + e.lineNo + ': ';
	if (e.code === MDError.DupTimeIsolated) {
		s += get_i18n('Duptime');
	} else if (e.code === MDError.TypeMismatch) { 
		s += get_i18n('TypeMismatch');
	} else if (e.code === MDError.TooFewColumns) {
		s += get_i18n('TooFewColumns');
	} else {
		s += 'Unknown';
	}

	return s;
}

export default LayerErrorBox;