import React from 'react';
import { Component } from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { kMM_HideNonSelectedObject } from '../leafmob/SelectionDispMode'
import get_i18n from '../Internationalization';

class SelOnlySwitch extends Component {
	render() {
		const hide = !!(this.props.flags & kMM_HideNonSelectedObject);

		const c0 = ( hide) ? 'primary' : '';
		const c1 = (!hide) ? 'primary' : '';

		return <Grid item xs={ this.props.xs } className="mm-selonly-disp-type">
			<InputLabel shrink>{ get_i18n('IfSel') }</InputLabel>
			<ButtonGroup size="small" disableRipple={true}>
				<Button variant="contained" onClick={ this.onClick.bind(this, kMM_HideNonSelectedObject) } color={c0} disableElevation>{ get_i18n('SelOnly') }</Button>
				<Button variant="contained" onClick={ this.onClick.bind(this, 0)                         } color={c1} disableElevation>{ get_i18n('ShowAll') }</Button>
			</ButtonGroup>
		</Grid>
	}
	
	onClick(flags) {
		if (this.props.onClick) {
			this.props.onClick(flags);
		}
	}
}

export default SelOnlySwitch;
