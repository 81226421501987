const current_lang = (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language || window.navigator.userLanguage || window.navigator.browserLanguage;

const MSG_EN = {
	// Add
	AddComp: 'Add',
	AddData: 'Add data',
	// Annotation
	// Appearance
	AsInt: 'As int',
	BeginTime: 'Begin',
	ByDistance: 'By distance',
	ByTime: 'By time',
	EndTime: 'End',
	ChangeBy: 'Change by',
	ClickAdd: 'Click and choose data to visualize.',
	ColoringRule: 'Coloring rule',
	ColorVariation: 'Variation',
	// Data
	// Delete
	DirMarker: 'Dir marker',
	DirShift: 'Dir shift',
	// Dismiss
	// Docs
	DoneCheck: '✔︎ Done',
	Duptime: 'Duplicated timestamp',
	ExportLayerSetting: 'Export layer settings',
	FixedColor: 'Fixed color',
	// Generate
	GenerateGrid: 'Generate grid',
	GenVelo: 'Generate velocity attr',
	GetStarted: 'Get started!',
	HeaderIgnored: 'Header row is ignored.',
	HeaderValid: 'Valid header row',
	IfSel: 'If something is selected',
	InterpolationLimit: 'Interpolation limit',
	JapanLv1: 'Japan 80km(LV1)',
	JapanLv2: 'Japan 10km(LV2)',
	JapanLv3: 'Japan 1km(LV3)',
	JapanLv4: 'Japan 500m(LV4)',
	JapanLv5: 'Japan 250m(LV5)',
	Japan100m: 'Japan 100m',
	Japan50m: 'Japan 50m',
	KeepShow: 'Keep markers before first/after last',
	// Labels
	// Legend
	LimitedSectionAppearance: 'Appearance',
	LimitInterpolation: 'Limit interpolation',
	ManifestValid: 'Valid manifest',
	// Marker
	MarkerColor: 'Marker color',
	MaxValue: 'Max value',
	Minutes: 'min.',
	MoveUp: 'Move up',
	None: 'None',
	NonSeparated: 'Non-separated',
	NormalComp: 'Normal',
	NotAvail: 'N/A',
	// Opacity
	// Preset
	PrivacyPolicy: 'Privacy policy',
	SelCSV: 'Select CSV file',
	SelOnly: 'Selected only',
	// Separated
	ShowAll: 'All',
	SizeVariation: 'Variation',
	// Sizing
	StartLoading: 'Start loading',
	// Tail
	TailDuration: 'Duration',
	TailEnabled: 'Show tail',
	TargetLayer: 'Target layer',
	TooFewColumns: 'Too few columns',
	ToS: 'terms of service',
	// Trajectory
	TypeMismatch: 'Data type mismatch',
	Variation: 'Variation',
	VizMov: 'Visualize movement data from a CSV file.'
};

const MSG_JA = {
	Add: '追加',
	AddComp: '加算',
	AddData: 'データを追加',
	Annotation: 'アノテーション',
	Appearance: '外観',
	AsInt: '整数化',
	BeginTime: '開始',
	ByDistance: '距離で制限',
	ByTime: '時間で制限',
	Emphasized: '強調色で表示',
	EndTime: '終了',
	ChangeBy: '属性で変化',
	ClickAdd: '可視化する移動体データを選択します',
	ColoringRule: '色付けルール',
	ColorVariation: '色の数',
	Condition: '条件',
	Dashed: '点線で表示',
	Data: 'データ',
	Delete: '削除',
	DirMarker: '向きを表示',
	DirShift: '横にシフト',
	Dismiss: '表示しない',
	Docs: '使い方',
	DoneCheck: '✔︎ 完了',
	Duptime: '同時刻で位置が異なるレコードがあります',
	ExportLayerSetting: 'レイヤ設定をエクスポート',
	FixedColor: '固定色',
	Generate: '生成',
	GenerateGrid: 'グリッド生成',
	GenVelo: '速度の属性値を生成',
	GetStarted: '最初のステップ',
	HeaderIgnored: 'ヘッダー行を無視します',
	HeaderValid: '有効なヘッダーを見つけました',
	Hidden: '表示しない',
	IfSel: '何か選択されている場合',
	InterpolationLimit: '補間処理を制限',
	JapanLv1: '1次メッシュ(80km)',
	JapanLv2: '2次メッシュ(10km)',
	JapanLv3: '3次メッシュ(1km)',
	JapanLv4: '4次メッシュ(500m)',
	JapanLv5: '5次メッシュ(250m)',
	Japan100m: '日本周辺100m',
	Japan50m: '日本周辺50m',
	KeepShow: '範囲外の時刻でもマーカーを表示',
	Labels: 'ラベル',
	Legend: '凡例表示',
	LimitedSectionAppearance: '制限区間の表示方法',
	LimitInterpolation: '補間処理を制限',
	ManifestValid: '有効なマニフェストを見つけました',
	Marker: 'マーカー',
	MarkerColor: 'マーカーの色',
	MaxValue: '最大値',
	Minutes: '分',
	MoveUp: '上へ',
	None: 'なし',
	NonSeparated: '隙間なし',
	NormalComp: '通常',
	NotAvail: '情報なし',
	Opacity: '透明度',
	Preset: 'プリセット',
	PrivacyPolicy: 'プライバシーポリシー',
	SelCSV: 'CSVファイルを選択',
	SelOnly: '選択分のみ',
	Separated: '隙間あり',
	ShowAll: '全て表示',
	SizeVariation: 'サイズの数',
	Sizing: 'サイズ変化',
	StartLoading: '読み込み開始',
	Tail: 'テイル',
	TailDuration: '長さ',
	TailEnabled: 'テイル表示',
	TargetLayer: '集計対象',
	Threshold: '閾値',
	TooFewColumns: 'CSVのカラム数が足りません',
	ToS: '利用規約',
	Trajectory: '軌跡',
	TypeMismatch: 'データ型が一致しません',
	Variation: '数',
	VizMov: 'CSVファイルを読み込んで移動データを可視化しましょう。'
};

const re_ja = /ja/i ;

function get_i18n(messageId) {
	var ret = null;
	if (re_ja.test(current_lang)) {
		ret = MSG_JA[messageId] || null;
	}

	// english (or fallback)
	if (!ret) {
		ret = MSG_EN[messageId] || null;
	}

	// not found...
	if (!ret) {
		ret = messageId
	}

	return ret;
}

export default get_i18n;