import MMEventDispatcher from "../leafmob/EventDispatcher"
import { isPointInPolygon } from "geolib";
const GJTYPE = { Line: 0, Polygon: 1 };

class GateJob extends MMEventDispatcher {
	constructor(gateCoordList, userOptions, type) {
		super();

		this.type = type || 0;
		this.userOptions = userOptions || null;
		this.targetLayer = null;
		this.gateCoordList = gateCoordList;
		this.geolibPolygon = toGeolibPolygon(gateCoordList);

		this.tickHandler = this.processNext.bind(this);

		this.numAll = 0;
		this.numDone = 0;
		this.chunkSize = 700;
		this._dout_tmp = {midPosition: 0};
		
		this.result = null;
	}

	setTarget(layer) {
		this.targetLayer = layer;
	}

	start() {
		this.result = null;
		
		if (this.targetLayer.hasTrajectory()) {
			this.result = new GateResult();
			this.numAll = this.targetLayer.countTrajectories();
			this.numDone = 0;

			this.processNext();
			return true;
		}
		
		return false;
	}

	processNext() {
		var n = this.chunkSize;
		const rest = this.numAll - this.numDone;
		if (n > rest) {  n = rest;  }
		
		for (var i = 0;i < n;++i) {
			const currentIndex = this.numDone;
			const traj = this.targetLayer.referTrajectoryAt(currentIndex);

			var pt_hit = false;
			// ポリゴンゲートの場合は内部も調査
			if (this.type === GJTYPE.Polygon) {
				pt_hit = this.checkInsideGate(traj);
			}

			this.checkGateOnTrajectory(traj);

			++this.numDone;
		}
		
		this.reportProgress();
		if (this.numDone < this.numAll) {
			setTimeout(this.tickHandler, 1);
		} else {
			// finish
		//	console.log(this.result.list);
			this.eventInvoke('gateJobFinish', this);
		}
	}
	
	checkInsideGate(traj) {
		const tlen = traj.tjGetLength();
		const pid = traj.tjGetId();

		for (var i = 0;i < tlen;++i) {
			const y = traj.tjGetLatAt(i);
			const x = traj.tjGetLngAt(i);
			const hit = isPointInPolygon([x,y], this.geolibPolygon );

			if (hit) {
				// second index = null で線分ではないことを表す
				const ent = new GateResultEntry(pid, i, null, 0, 0);
				this.result.add(ent);
			}
		}

		return false;
	}

	checkGateOnTrajectory(traj) {
		var i;
		const numVertices = this.gateCoordList.length;
		if (numVertices < 2) { return; }

		if (this.type === GJTYPE.Line) {
			this.checkGateWithEdge(this.gateCoordList, 0, 1, traj);
		} else {
			for (i = 0;i < numVertices;++i) {
				this.checkGateWithEdge(this.gateCoordList, i, (i+1) % numVertices, traj);
			}
		}
	}

	// ゲート座標列のfirstIndex-secondIndex間を使用
	checkGateWithEdge(g_coords, firstIndex, secondIndex, traj) {
		const y1 = g_coords[firstIndex][0];
		const x1 = g_coords[firstIndex][1];

		const y2 = g_coords[secondIndex][0];
		const x2 = g_coords[secondIndex][1];

		const tlen = traj.tjGetLength();
		const pid = traj.tjGetId();

		for (var i = 0;i < (tlen-1);++i) {
			const y3 = traj.tjGetLatAt(i);
			const x3 = traj.tjGetLngAt(i);
			
			const y4 = traj.tjGetLatAt(i+1);
			const x4 = traj.tjGetLngAt(i+1);

			const xres = testSegmentCross(x1, y1, x2, y2, x3, y3, x4, y4, true, this._dout_tmp);
			if ( null !== xres ) {
				const ent = new GateResultEntry(pid, i, i+1, this._dout_tmp.midPosition, xres);
				this.result.add(ent);
			}
		}
	}

	reportProgress() {
		const r = this.numDone / this.numAll;
		this.eventInvoke('gateJobProgress', this, r);
	}
};

GateJob.isValidTarget = function(lyr) {
	if (!lyr) { return false; }
	return lyr.hasTrajectory();
};

function toGeolibPolygon(lfPolygon) {
	const n = lfPolygon.length;
	const res = new Array(n);

	for (var i = 0;i < n;++i) {
		res[i] = {lat: lfPolygon[i][0], lng: lfPolygon[i][1]} ;
	}

	return res;
}

// RESULT ============
class GateResult {
	constructor() {
		this.list = [];
	}
	
	add(ent) {
		this.list.push(ent);
	}
	
	selectIDs(selection) {
		const ls = this.list;
		for (const entry of ls) {
			selection.selectId(entry.pid);
		}
		
		selection.fire();
	}
}

class GateResultEntry {
	constructor(pid, i1, i2, ofs, dp) {
		this.pid = pid;
		this.firstIndex = i1;
		this.secondIndex = i2;
		this.crossOffset = ofs;
		this.dotProduct = dp;
	}
}


var testSegmentCross = (function() {
	function matchDir(x1, y1, x2, y2, x3, y3, x4, y4, timeDir) {
		// Rotate x/y
		var dx1 = -(y2 - y1);
		var dy1 = x2 - x1;

		var dx2 = x4 - x3;
		var dy2 = y4 - y3;

		var dp = dx1 * dx2 + dy1 * dy2;
		if (!timeDir) { dp = -dp; }

		return dp;
	}


	//                             Gate           | Traj
	function calcPtLineRelDistance(x3, y3, x4, y4,  x1, y1, x2, y2) {
		//                  |Gate vector
		// Point vec.       |
		// .________________* <- x3,y3 
		// x1,y1                  
		
		var xP  = x1 - x3;
		var yP  = y1 - y3;
		var xP2 = x2 - x3;
		var yP2 = y2 - y3;
		
		var xG = x4 - x3;
		var yG = y4 - y3;
		
		// Cross product indicates area of the parallelogram
		var CP  = xP  * yG - yP * xG;
		var CP2 = xP2 * yG - yP2 * xG;

		var blen = Math.sqrt(xG*xG + yG*yG);
		// ... and the height is (area / bottom length)
		var A1 = Math.abs(CP  / blen);
		var A2 = Math.abs(CP2 / blen);
		
		return A1 / (A1+A2);
	}
	
	// parameters:
	//            x3,y3
	//             |
	//  x1,y1 -----+----- x2,y2
	//             |
	//             |
	//            x4,y4
	return function (x1, y1, x2, y2, x3, y3, x4, y4, timeDir, detailOut) {
		if (!is_valid_segment(x1, y1, x2, y2) || !is_valid_segment(x3, y3, x4, y4)) {
			return null;
		}
	//console.log(x1, y1, x2, y2, x3, y3, x4, y4)
		if (
				((x1 - x2) * (y3 - y1) + (y1 - y2) * (x1 - x3)) *
				((x1 - x2) * (y4 - y1) + (y1 - y2) * (x1 - x4)) <= 0
			) {

			if (
					((x3 - x4) * (y1 - y3) + (y3 - y4) * (x3 - x1)) *
	            	((x3 - x4) * (y2 - y3) + (y3 - y4) * (x3 - x2)) <= 0
			) {
				if (detailOut) {
					// Detail information for cross point is required.
					
					detailOut.midPosition = calcPtLineRelDistance(x1, y1, x2, y2, x3, y3, x4, y4);
				}

				return matchDir(x1, y1, x2, y2, x3, y3, x4, y4, timeDir);
			}
		}

		return null;
	} ;
})();

function is_valid_segment(x1, y1, x2, y2) {
	const dx = x1 - x2;
	const dy = y1 - y2;
	const len = Math.sqrt(dx*dx + dy*dy);

	return len > Number.EPSILON;
}

export {testSegmentCross, GJTYPE};
export default GateJob;
