import React from 'react';
import { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

import ControlBar from '../widgets/ControlBar';
import MapArea from './MapArea'
import '../App.css';

class ContentPane extends Component {
	// SETUP ==============================================================
	constructor(props) {
		super(props);
		this.maComponent = null;
		this.cbComponent = null;
		this.sizeDirty = false;

		this.state = {
			gateSuccessMessage: '',
			gateSuccessOpen: false
		};
	}
	
	setSizeDirty() {
		this.sizeDirty = true;
	}

	onMapAreaRender(e) {
		this.maComponent = e;
	}

	onControlBarRender(e) {
		this.cbComponent = e;
	}

	render() {
	    return (<div className="mm-content-pane">
			<ControlBar
				initial={ 3 }
				showMenuButton={ !!this.props.showMenuButton }
				onMenuButtonClick={ this.props.onMenuButtonClick || null }
				onPlayClick={ this.onPlayClick.bind(this) }
				ref={ this.onControlBarRender.bind(this) }
				afterCurrentTimeChange={ this.afterCurrentTimeChange.bind(this) }
				afterSpeedSliderChange={ this.afterSpeedSliderChange.bind(this) } />
			<MapArea
				ref={ this.onMapAreaRender.bind(this) }
				selectedLayerProvider={ this.props.selectedLayerProvider || null }
				onGateSuccessMessageRequested={ this.onGateSuccessMessageRequested.bind(this) }
				onGateFunctionWillAppear={ this.props.onGateFunctionWillAppear }
				onGateInvokeServerRequested={ this.props.onGateInvokeServerRequested }
				onGatePutRequest={ this.onGatePutRequest.bind(this) }
				onGateExecRequest={ this.onGateExecRequest.bind(this) }
				onGateRemoveRequest={ this.onGateRemoveRequest.bind(this) } />
			<Snackbar onClose={ this.handleSnackbarClose.bind(this) } TransitionComponent={ snaclbar_slide } anchorOrigin={ { vertical: 'top', horizontal: 'right' } }
				open={this.state.gateSuccessOpen} autoHideDuration={4000} message={ this.state.gateSuccessMessage } />
	    </div>) ;
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.sizeDirty) {
			if (this.cbComponent) { this.cbComponent.forceResize(); }
			if (this.maComponent) { this.maComponent.forceResize(); }
			this.sizeDirty = false;
		}
	}

	setTimeRange(timeRange) {
		if (this.cbComponent) {
			this.cbComponent.setTimeRange(timeRange);
		}
	}
	
	getTimeSlider() {
		if (this.cbComponent) {
			return this.cbComponent.getTimeSlider();
		}
		return null;
	}
	
	getControlBar() {
		return this.cbComponent;
	}

	afterCurrentTimeChange(timeSlider, newTime) {
		if (this.props.afterCurrentTimeChange) {
			this.props.afterCurrentTimeChange(timeSlider, newTime);
		}
	}

	afterSpeedSliderChange(sender) {
		if (this.props.afterSpeedSliderChange) {
			this.props.afterSpeedSliderChange(sender);
		}
	}

	setTargetLayerList(ll) {
		if (this.maComponent) {
			this.maComponent.setTargetLayerList(ll);
		}
	}

	invalidateRenderOrder() {
		if (this.maComponent) {
			this.maComponent.invalidateRenderOrder();
		}
	}

	redraw(newTime, forceUpdate) {
		if (this.maComponent) {
			this.maComponent.redraw(newTime, forceUpdate);
		}
	}
	
	updateTimeDisplay(newTime) {
		if (this.maComponent) {
			this.maComponent.updateTimeDisplay(newTime);
		}
	}
	
	moveMap(lat, lng) {
		if (this.maComponent) {
			this.maComponent.moveMap(lat, lng);
		}
	}

	markPickPoolDirty(layerId) {
		if (this.maComponent) {
			this.maComponent.markPickPoolDirty(layerId);
		}
	}
	
	markSelectionDispDirty(layerId) {
		if (this.maComponent) {
			this.maComponent.markSelectionDispDirty(layerId)
		}
	}

	onPlayClick(e) {
		if (this.props.onPlayClick) {
			this.props.onPlayClick(e);
		}
	}
	
	onGatePutRequest(gateType) {
		if (this.props.onGatePutRequest) {
			this.props.onGatePutRequest(gateType);
		}
	}
	
	onGateExecRequest(senderGateFeature, options) {
		if (this.props.onGateExecRequest) {
			this.props.onGateExecRequest(senderGateFeature, options);
		}
	}

	onGateRemoveRequest(senderGateFeature) {
		if (this.props.onGateRemoveRequest) {
			this.props.onGateRemoveRequest(senderGateFeature);
		}
	}

	onGateSuccessMessageRequested(senderGateFeature, message) {
		this.setState({
			gateSuccessMessage: message,
			gateSuccessOpen: true
		});
	}

	handleSnackbarClose() {
		this.setState({
			gateSuccessOpen: false
		});
	}

	afterGateRemoved(gateFeature) {
		if (this.maComponent) {
			this.maComponent.afterFeatureRemoved(gateFeature.id);
		}
	}
}

function snaclbar_slide(props) {
	return <Slide {...props} direction="left" />;
  }

export default ContentPane;