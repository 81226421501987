import leaflet from 'leaflet'
import { toolbase_init, toolbase_set_ev_parent, toolbase_make_button } from './map-tool-base'
const L = leaflet;

const GateToolsControl = L.Control.extend({
	initialize: toolbase_init,
	setEventParent: toolbase_set_ev_parent,
	
	onAdd: function(map) {
		const div = document.createElement('div');
		div.className = 'leaflet-bar';
		this._addGateIcon(div, 'gate-tool-icon', GateToolsControl.IS_PUT_LINE_BUTTON);
		this._addGateIcon(div, 'polygon-gate', GateToolsControl.IS_PUT_POLYGON_BUTTON);
		return div;
	},

	onRemove: function(map) {
		
	},
	
	_addGateIcon: function(container, filename, buttonType) {
		return toolbase_make_button(
			container,
			'/images/'+filename+'.png',
			'Line gate',
			this._onGateToolButtonClick.bind(this, buttonType)
		);
	},
	
	_onGateToolButtonClick: function(buttonType, e) {
		e.stopPropagation();
		
		this.eventDispatcher.eventInvoke('GateToolClick', buttonType);
	}
});

GateToolsControl.IS_PUT_LINE_BUTTON = 0;
GateToolsControl.IS_PUT_POLYGON_BUTTON = 1;

export default GateToolsControl;