class MMEventDispatcher {
	constructor() {
		this.eventParent = null;
	}

	setEventParent(pr) {
		this.eventParent = pr;
	}
	
	eventInvoke(eventName, arg1, arg2, arg3) {
		const name = 'on_' + eventName;
		const pr = this.eventParent;
		if (pr && pr[name]) {
			pr[name].call(pr, arg1, arg2, arg3);
		}
		
		if (pr && pr.eventInvoke) {
			pr.eventInvoke(eventName, arg1, arg2, arg3);
		}
	}
}

export default MMEventDispatcher;