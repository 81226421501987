import WorkerTileLayerBase from './WorkerTileLayerBase'
import leaflet from 'leaflet'
const L = leaflet;
const SHOW_DEBUG_BORDER = false;

const TrajectoryLayer = L.GridLayer.extend({
	initialize: function (options) {
		L.Util.setOptions(this, options);
		this.zoomClosure = this.onMapZoomWillChange.bind(this);
		this.worker = new Worker( (options.workerPath || '')+ 'tj-worker.js');

		this.dataSent = false;
		this.colorSent = false;
		this.selectionSent = false;
		this._isVisible = true;
		this.on('tileunload', this.onTileUnload.bind(this));
	},

	setOverlayVisibility: WorkerTileLayerBase.setOverlayVisibility,
	beforeAdd: WorkerTileLayerBase.beforeAdd,
	onRemove: WorkerTileLayerBase.onRemove,

	onMapZoomWillChange: function() {
		this.invalidateTiles();
	},

	createTile: function(coords) {
		var ofs = null;
		const size = this.getTileSize();
		const tile = WorkerTileLayerBase.createTileCanvas('mm-traj-tile', size, SHOW_DEBUG_BORDER, false);
		try {
			ofs = tile.transferControlToOffscreen();
		} catch(e) {
			window.alert("[UNSUPPORTED] Your browser does not support offscreen canvas.");
		}
		
		const geoCoords = this._tileCoordsToNwSe(coords);
		const tileOrigin = coords.scaleBy(this.getTileSize());
		const hidden = !(this._isVisible);

		this.worker.postMessage({
			tileCanvas: ofs,
			coords:coords,
			tileNW: geoCoords[0],
			tileSE: geoCoords[1],
			tileOrigin: tileOrigin,
			pause: hidden
		}, [ ofs ]);

		return tile;
	},
	
	onTileUnload: function(params) {
		this.worker.postMessage({ removeTileCoords: params.coords });
	},

	sendSelectedIDs: function(idIterator) {
		var a = [];
		if (idIterator) {
			for (const id of idIterator) { a.push(id); }
		}

		this.worker.postMessage({ setSelectedIDs:  a.join("\t")  });
		this.selectionSent = true;
	},

	addTrajectory: function(id, coordList) {
		if (this.worker) {
			const orgLen = coordList.length;
			const bufLen = orgLen * 2;
			const buf = new Float32Array(bufLen);
			
			for (var i = 0;i < orgLen;++i) {
				const srcPt = coordList[i];
				buf[i*2  ] = srcPt.lng;
				buf[i*2+1] = srcPt.lat;
			}
			
			this.worker.postMessage({ trajectoryCoords: buf.buffer, id:id }, [ buf.buffer ]);
		}
	},

	addTrajectoryArrays: function(id, latList, lngList, colorList, maskList) {
		if (this.worker) {
			this.worker.postMessage({
				latList: latList,
				lngList: lngList,
				trajectoryColorArray: colorList,
				trajectoryMaskArray: maskList || null,
				id:id
			});
		}
	},
	
	sendColorArray: function(id, colorList) {
		if (this.worker) {
			this.worker.postMessage({
				sendColorOnly: true,
				trajectoryColorArray: colorList,
				id:id
			});
		}
	},

	sendMaskArray: function(id, maskArray) {
		if (this.worker) {
			this.worker.postMessage({
				sendMaskOnly: true,
				trajectoryMaskArray: maskArray,
				id:id
			});
		}
	},

	sendMaskActive: function(maskActive) {
		if (this.worker) {
			this.worker.postMessage({
				sendMaskActive: maskActive
			});
		}
	},

	sendMaskAppearance: function(maskAppearance) {
		if (this.worker) {
			this.worker.postMessage({
				sendMaskAppearance: maskAppearance
			});
		}
	},

	setDefaultColor: function(color, opacity, additive) {
		if (this.worker) {
			this.worker.postMessage({
				setDefaultColor: true,
				value: color,
				opacity: opacity,
				additive: additive
			});
		}		
	},
	
	beginTrajectoryWrite: function() {
		this.worker.postMessage({ beginTrajectoryWrite: true });
	},

	invalidateTiles: function() {
		this.worker.postMessage({ invalidateTiles: true });
	},

	clearTileContents: function() {
		this.worker.postMessage({ clearTileContents: true });
	},

	invalidateSentColor: function() {
		this.colorSent = false;
	},

	invalidateSentSelection: function() {
		this.selectionSent = false;
	},

	forceRedraw: function() {
		this.worker.postMessage({ finishTrajectoryWrite: true });
	},
	
	dispose: function() {
		this.remove();
		if (this.worker) {
			this.worker.terminate();
		}
	}
});

TrajectoryLayer.USE_MARKER_COLOR = -1;

export default TrajectoryLayer;