var TEST_MOVING_DATA_SOURCE = [
	"66700,1,1,1998/10/01 06:00:00,139.9491826328,35.8476214241,1,11,4311002,3 ,97,37,,97",
	"377200,1,1,1998/10/01 06:00:00,139.6392231337,35.9209632841,1,14,3052009,15,97,29,,97",
	"23000,1,1,1998/10/01 06:00:00,140.0385046369,35.7260155905,2,3,4123006,5 ,97,26,,97",
	"823400,1,1,1998/10/01 06:00:00,139.5799861271,35.4552929672,1,10,1213105,9 ,97,34,,97",
	"823400,2,2,1998/10/01 08:01:00,139.5807717073,35.4576829205,1,10,1213105,9 ,13,34,,1",
	"823400,2,2,1998/10/01 08:02:00,139.582856045,35.4597535444,1,10,1213105,9 ,13,34,,1",
	"823400,2,2,1998/10/01 08:03:00,139.5849404895,35.4618241321,1,10,1213105,9 ,13,34,,1",
	"823400,2,2,1998/10/01 08:04:00,139.5870250407,35.4638946835,1,10,1213105,9 ,13,34,,1",
	"823400,2,2,1998/10/01 08:05:00,139.5865691498,35.4638186141,1,10,1213105,9 ,13,34,,1",
	"823400,2,3,1998/10/01 08:05:00,139.5865691498,35.4638186141,1,10,1213105,9 ,13,34,,12",
	"823400,2,3,1998/10/01 08:06:00,139.5872788804,35.4633185404,1,10,1213105,9 ,13,34,,12",
	"823400,2,3,1998/10/01 08:07:00,139.5882673659,35.4629484176,1,10,1213105,9 ,13,34,,12",
	"823400,2,3,1998/10/01 08:08:00,139.5892576095,35.4625812003,1,10,1213105,9 ,13,34,,12",
	"823400,2,3,1998/10/01 08:09:00,139.5902478442,35.4622139748,1,10,1213105,9 ,13,34,,12",
	"823400,2,3,1998/10/01 08:10:00,139.5912372618,35.4618453077,1,10,1213105,9 ,13,34,,12",
	"823400,2,3,1998/10/01 08:11:00,139.5921780098,35.4614029467,1,10,1213105,9 ,13,34,,12",
	"823400,2,3,1998/10/01 08:12:00,139.5929195638,35.4607570952,1,10,1213105,9 ,13,34,,12",
	"823400,2,3,1998/10/01 08:13:00,139.5935694924,35.4600440426,1,10,1213105,9 ,13,34,,12",
	"823400,2,3,1998/10/01 08:14:00,139.5942194095,35.4593309865,1,10,1213105,9 ,13,34,,12",
	"823400,2,3,1998/10/01 08:15:00,139.594869315,35.4586179267,1,10,1213105,9 ,13,34,,12",
	"823400,2,3,1998/10/01 08:16:00,139.5955192245,35.4579048726,1,10,1213105,9 ,13,34,,12",
	"823400,2,3,1998/10/01 08:17:00,139.5961759399,35.457196193,1,10,1213105,9 ,13,34,,12",
	"823400,2,3,1998/10/01 08:18:00,139.5968686683,35.4565106641,1,10,1213105,9 ,13,34,,12",
	"823400,2,3,1998/10/01 08:19:00,139.5976335353,35.4558855705,1,10,1213105,9 ,13,34,,12",
	"823400,2,3,1998/10/01 08:20:00,139.5985466219,35.4554085515,1,10,1213105,9 ,13,34,,12",
	"823400,2,3,1998/10/01 08:21:00,139.5995306437,35.455033072,1,10,1213105,9 ,13,34,,12",
	"823400,2,3,1998/10/01 08:22:00,139.6005337252,35.454690159,1,10,1213105,9 ,13,34,,12",
	"823400,2,3,1998/10/01 08:23:00,139.6015367983,35.4543472376,1,10,1213105,9 ,13,34,,12",
	"823400,2,3,1998/10/01 08:24:00,139.6025479354,35.4540207856,1,10,1213105,9 ,13,34,,12",
	"823400,2,3,1998/10/01 08:25:00,139.6035568477,35.4536899344,1,10,1213105,9 ,13,34,,12",
	"823400,2,3,1998/10/01 08:26:00,139.6045568478,35.4533410796,1,10,1213105,9 ,13,34,,12",
	"823400,2,3,1998/10/01 08:27:00,139.6055568393,35.4529922164,1,10,1213105,9 ,13,34,,12",
	"823400,2,3,1998/10/01 08:28:00,139.6066249992,35.4528651845,1,10,1213105,9 ,13,34,,12",
	"823400,2,3,1998/10/01 08:29:00,139.6076861305,35.4530263765,1,10,1213105,9 ,13,34,,12",
	"823400,2,3,1998/10/01 08:30:00,139.6085669022,35.4535423182,1,10,1213105,9 ,13,34,,12",
	"823400,2,4,1998/10/01 08:30:00,139.6085669022,35.4535423182,1,10,1213105,9 ,13,34,,10",
	"66700,2,2,1998/10/01 08:31:00,139.9490735485,35.8478016813,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,4,1998/10/01 08:31:00,139.6081007048,35.4536167435,1,10,1213105,9 ,13,34,,10",
	"66700,2,2,1998/10/01 08:32:00,139.9489644636,35.8479819384,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,4,1998/10/01 08:32:00,139.6076345065,35.453691167,1,10,1213105,9 ,13,34,,10",
	"66700,2,2,1998/10/01 08:33:00,139.9488553783,35.8481621954,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,4,1998/10/01 08:33:00,139.6071683073,35.4537655887,1,10,1213105,9 ,13,34,,10",
	"66700,2,2,1998/10/01 08:34:00,139.9487462925,35.8483424523,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,4,1998/10/01 08:34:00,139.6071118096,35.4538779858,1,10,1213105,9 ,13,34,,10",
	"66700,2,2,1998/10/01 08:35:00,139.9486693879,35.8483154085,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,4,1998/10/01 08:35:00,139.6075597997,35.4540063058,1,10,1213105,9 ,13,34,,10",
	"66700,2,2,1998/10/01 08:36:00,139.9486188189,35.8481187228,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,4,1998/10/01 08:36:00,139.6080293976,35.4540471603,1,10,1213105,9 ,13,34,,10",
	"66700,2,2,1998/10/01 08:37:00,139.9485682502,35.8479220371,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,4,1998/10/01 08:37:00,139.6085037529,35.4540672578,1,10,1213105,9 ,13,34,,10",
	"66700,2,2,1998/10/01 08:38:00,139.9485652105,35.8479567812,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,4,1998/10/01 08:38:00,139.6089781085,35.4540873534,1,10,1213105,9 ,13,34,,10",
	"66700,2,2,1998/10/01 08:39:00,139.9485959824,35.8481561619,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,4,1998/10/01 08:39:00,139.6094524643,35.4541074471,1,10,1213105,9 ,13,34,,10",
	"66700,2,2,1998/10/01 08:40:00,139.9486267544,35.8483555426,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,4,1998/10/01 08:40:00,139.6099268204,35.454127539,1,10,1213105,9 ,13,34,,10",
	"66700,2,2,1998/10/01 08:41:00,139.9486575267,35.8485549234,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,4,1998/10/01 08:41:00,139.6104011767,35.454147629,1,10,1213105,9 ,13,34,,10",
	"66700,2,2,1998/10/01 08:42:00,139.9486109447,35.8486408761,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,4,1998/10/01 08:42:00,139.6108755332,35.4541677172,1,10,1213105,9 ,13,34,,10",
	"66700,2,2,1998/10/01 08:43:00,139.9485185443,35.848827212,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,4,1998/10/01 08:43:00,139.61134989,35.4541878035,1,10,1213105,9 ,13,34,,10",
	"66700,2,2,1998/10/01 08:44:00,139.9484261436,35.8490135478,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,4,1998/10/01 08:44:00,139.611824247,35.4542078879,1,10,1213105,9 ,13,34,,10",
	"66700,2,2,1998/10/01 08:45:00,139.9483337424,35.8491998836,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,4,1998/10/01 08:45:00,139.6122952002,35.4541987655,1,10,1213105,9 ,13,34,,10",
	"66700,2,2,1998/10/01 08:46:00,139.9482413408,35.8493862192,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,4,1998/10/01 08:46:00,139.6126824024,35.4540208189,1,10,1213105,9 ,13,34,,10",
	"66700,2,2,1998/10/01 08:47:00,139.9481489387,35.8495725548,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,4,1998/10/01 08:47:00,139.6130167179,35.4539820192,1,10,1213105,9 ,13,34,,10",
	"66700,2,2,1998/10/01 08:48:00,139.9480565363,35.8497588903,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,4,1998/10/01 08:48:00,139.6133074576,35.4539023753,1,10,1213105,9 ,13,34,,10",
	"66700,2,2,1998/10/01 08:49:00,139.9480442732,35.8499561888,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,4,1998/10/01 08:49:00,139.6137264814,35.4540853899,1,10,1213105,9 ,13,34,,10",
	"66700,2,2,1998/10/01 08:50:00,139.9479751727,35.8501491018,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,4,1998/10/01 08:50:00,139.6141476556,35.4542650811,1,10,1213105,9 ,13,34,,10",
	"66700,2,2,1998/10/01 08:51:00,139.9479060718,35.8503420147,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,4,1998/10/01 08:51:00,139.6145688317,35.4544447709,1,10,1213105,9 ,13,34,,10",
	"66700,2,2,1998/10/01 08:52:00,139.9478369706,35.8505349275,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,4,1998/10/01 08:52:00,139.6149928838,35.4546198739,1,10,1213105,9 ,13,34,,10",
	"66700,2,2,1998/10/01 08:53:00,139.9476615178,35.8505956707,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,4,1998/10/01 08:53:00,139.615394775,35.4547308719,1,10,1213105,9 ,13,34,,10",
	"66700,2,2,1998/10/01 08:54:00,139.9475630666,35.85074409,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,4,1998/10/01 08:54:00,139.6156280229,35.4543926452,1,10,1213105,9 ,13,34,,10",
	"66700,2,2,1998/10/01 08:55:00,139.9475039007,35.8509391799,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,4,1998/10/01 08:55:00,139.6159291034,35.4541005363,1,10,1213105,9 ,13,34,,10",
	"823400,2,5,1998/10/01 08:55:00,139.6159291034,35.4541005363,1,10,1213105,9 ,13,34,,1",
	"66700,2,2,1998/10/01 08:56:00,139.9474447345,35.8511342698,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,5,1998/10/01 08:56:00,139.6161695511,35.4512943785,1,10,1213105,9 ,13,34,,1",
	"66700,2,2,1998/10/01 08:57:00,139.947385568,35.8513293596,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,5,1998/10/01 08:57:00,139.6159513302,35.4482518413,1,10,1213105,9 ,13,34,,1",
	"66700,2,2,1998/10/01 08:58:00,139.9473264012,35.8515244494,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,5,1998/10/01 08:58:00,139.6166631994,35.445428353,1,10,1213105,9 ,13,34,,1",
	"66700,2,2,1998/10/01 08:59:00,139.9475492744,35.8515648536,1,11,4311002,3 ,9 ,37,,8",
	"823400,2,5,1998/10/01 08:59:00,139.6163975069,35.4440037391,1,10,1213105,9 ,13,34,,1",
	"66700,2,2,1998/10/01 09:00:00,139.9477935805,35.8515935027,1,11,4311002,3 ,9 ,37,,8",
	"66700,3,3,1998/10/01 09:00:00,139.9477935805,35.8515935027,1,11,4311002,3 ,97,37,,97",
	"823400,2,5,1998/10/01 09:00:00,139.6159396375,35.4414452181,1,10,1213105,9 ,13,34,,1",
	"377200,2,2,1998/10/01 09:01:00,139.6390465488,35.9209653131,1,14,3052009,15,6 ,29,,3",
	"823400,2,5,1998/10/01 09:01:00,139.618163408,35.4390163666,1,10,1213105,9 ,13,34,,1",
	"377200,2,2,1998/10/01 09:02:00,139.6388699639,35.9209673419,1,14,3052009,15,6 ,29,,3",
	"823400,2,5,1998/10/01 09:02:00,139.6178621556,35.4366906967,1,10,1213105,9 ,13,34,,1",
	"377200,2,2,1998/10/01 09:03:00,139.6386933789,35.9209693704,1,14,3052009,15,6 ,29,,3",
	"823400,2,5,1998/10/01 09:03:00,139.6191319122,35.4338255991,1,10,1213105,9 ,13,34,,1",
	"377200,2,2,1998/10/01 09:04:00,139.638516794,35.9209713986,1,14,3052009,15,6 ,29,,3",
	"823400,2,5,1998/10/01 09:04:00,139.6204410688,35.4309729175,1,10,1213105,9 ,13,34,,1",
	"377200,2,2,1998/10/01 09:05:00,139.638340209,35.9209734266,1,14,3052009,15,6 ,29,,3",
	"823400,2,5,1998/10/01 09:05:00,139.6219562288,35.4281946392,1,10,1213105,9 ,13,34,,1",
	"377200,2,2,1998/10/01 09:06:00,139.638163624,35.9209754543,1,14,3052009,15,6 ,29,,3",
	"823400,2,5,1998/10/01 09:06:00,139.6236212153,35.4257960475,1,10,1213105,9 ,13,34,,1",
	"377200,2,2,1998/10/01 09:07:00,139.637987039,35.9209774817,1,14,3052009,15,6 ,29,,3",
	"823400,2,5,1998/10/01 09:07:00,139.6248236743,35.4229115613,1,10,1213105,9 ,13,34,,1",
	"377200,2,2,1998/10/01 09:08:00,139.637810454,35.9209795089,1,14,3052009,15,6 ,29,,3",
	"823400,2,5,1998/10/01 09:08:00,139.6259707022,35.4200120781,1,10,1213105,9 ,13,34,,1",
	"377200,2,2,1998/10/01 09:09:00,139.637633869,35.9209815359,1,14,3052009,15,6 ,29,,3",
	"823400,2,5,1998/10/01 09:09:00,139.6271181464,35.417116493,1,10,1213105,9 ,13,34,,1",
	"377200,2,2,1998/10/01 09:10:00,139.637457284,35.9209835626,1,14,3052009,15,6 ,29,,3",
	"823400,2,5,1998/10/01 09:10:00,139.628125927,35.4155218454,1,10,1213105,9 ,13,34,,1",
	"823400,3,6,1998/10/01 09:10:00,139.628125927,35.4155218454,1,10,1213105,9 ,97,34,,97",
	"377200,2,2,1998/10/01 09:11:00,139.637280699,35.920985589,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:12:00,139.6371054779,35.9209707342,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:13:00,139.6369307376,35.9209499281,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:14:00,139.6367559974,35.9209291218,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:15:00,139.6365812572,35.9209083152,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:16:00,139.6364065172,35.9208875084,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:17:00,139.6362317773,35.9208667013,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:18:00,139.6360570375,35.920845894,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:19:00,139.6358822977,35.9208250864,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:20:00,139.6357075581,35.9208042786,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:21:00,139.6355328185,35.9207834705,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:22:00,139.6353910621,35.9207991041,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:23:00,139.6353635877,35.9209410048,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:24:00,139.6353361132,35.9210829054,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:25:00,139.6351817287,35.9211456844,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:26:00,139.635020409,35.9212041397,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:27:00,139.6348590891,35.9212625948,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:28:00,139.6346964985,35.9213185692,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:29:00,139.6345318706,35.9213705659,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:30:00,139.6343672424,35.9214225623,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:31:00,139.634202614,35.9214745585,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:32:00,139.6340379855,35.9215265545,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:33:00,139.6338733567,35.9215785502,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:34:00,139.6337087276,35.9216305457,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:35:00,139.6335484477,35.9216887666,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:36:00,139.6334092704,35.9217771945,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:37:00,139.6332700928,35.9218656221,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:38:00,139.633130915,35.9219540496,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:39:00,139.6329917368,35.9220424769,1,14,3052009,15,6 ,29,,3",
	"377200,2,2,1998/10/01 09:40:00,139.6328525583,35.9221309041,1,14,3052009,15,6 ,29,,3",
	"377200,3,3,1998/10/01 09:40:00,139.6328525583,35.9221309041,1,14,3052009,15,97,29,,97",
	"23000,2,2,1998/10/01 10:01:00,140.0360445744,35.727435329,2,3,4123006,5 ,7 ,26,,1",
	"23000,2,2,1998/10/01 10:02:00,140.0349367157,35.7267078203,2,3,4123006,5 ,7 ,26,,1",
	"23000,2,3,1998/10/01 10:02:00,140.0349367157,35.7267078203,2,3,4123006,5 ,7 ,26,,12",
	"23000,2,3,1998/10/01 10:03:00,140.0379310582,35.7246651356,2,3,4123006,5 ,7 ,26,,12",
	"23000,2,3,1998/10/01 10:04:00,140.0408071738,35.7224393739,2,3,4123006,5 ,7 ,26,,12",
	"23000,2,3,1998/10/01 10:05:00,140.0434290413,35.7200265131,2,3,4123006,5 ,7 ,26,,12",
	"23000,2,3,1998/10/01 10:06:00,140.0432970309,35.7168920273,2,3,4123006,5 ,7 ,26,,12",
	"23000,2,3,1998/10/01 10:07:00,140.0421259232,35.7138156388,2,3,4123006,5 ,7 ,26,,12",
	"23000,2,3,1998/10/01 10:08:00,140.0394383175,35.7114407827,2,3,4123006,5 ,7 ,26,,12",
	"23000,2,3,1998/10/01 10:09:00,140.0367149759,35.7091542589,2,3,4123006,5 ,7 ,26,,12",
	"23000,2,4,1998/10/01 10:09:00,140.0367149759,35.7091542589,2,3,4123006,5 ,7 ,26,,1",
	"23000,2,4,1998/10/01 10:10:00,140.0392994555,35.7089056317,2,3,4123006,5 ,7 ,26,,1",
	"23000,2,4,1998/10/01 10:11:00,140.0421957529,35.7083080054,2,3,4123006,5 ,7 ,26,,1",
	"23000,2,4,1998/10/01 10:12:00,140.0451286983,35.7080384597,2,3,4123006,5 ,7 ,26,,1",
	"23000,3,5,1998/10/01 10:12:00,140.0451286983,35.7080384597,2,3,4123006,5 ,97,26,,97",
	"23000,4,6,1998/10/01 10:46:00,140.0432402114,35.7083180748,2,3,4123006,5 ,4 ,26,,1",
	"23000,4,6,1998/10/01 10:47:00,140.0413517111,35.7085976602,2,3,4123006,5 ,4 ,26,,1",
	"23000,4,6,1998/10/01 10:48:00,140.0395922247,35.7089805992,2,3,4123006,5 ,4 ,26,,1",
	"23000,4,6,1998/10/01 10:49:00,140.0382064059,35.7093247386,2,3,4123006,5 ,4 ,26,,1",
	"23000,4,6,1998/10/01 10:50:00,140.0367149759,35.7091542589,2,3,4123006,5 ,4 ,26,,1",
	"23000,4,7,1998/10/01 10:50:00,140.0367149759,35.7091542589,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 10:51:00,140.0417876164,35.7134705707,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 10:52:00,140.043766644,35.7191938295,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 10:53:00,140.0384628886,35.7203986372,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 10:54:00,140.0317226349,35.7179430195,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 10:55:00,140.0251465413,35.7151970815,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 10:56:00,140.0185533023,35.7124959221,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 10:57:00,140.0112085302,35.7126354681,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 10:58:00,140.0039854378,35.7114761503,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 10:59:00,139.9969863194,35.7095908117,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:00:00,139.9899415526,35.7080315059,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:01:00,139.9827068995,35.7068707462,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:02:00,139.9761108152,35.7041693282,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:03:00,139.9688958712,35.7035289667,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:04:00,139.9620436313,35.7057189681,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:05:00,139.9557888416,35.7088824506,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:06:00,139.948849799,35.7094689116,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:07:00,139.9438009721,35.7050915668,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:08:00,139.9388867591,35.7005945165,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:09:00,139.9329161756,35.6970534747,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:10:00,139.9270827776,35.6933722418,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:11:00,139.9219119053,35.6890702891,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:12:00,139.9167490023,35.6847619985,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:13:00,139.911581965,35.6804572289,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:14:00,139.9064115405,35.6761553828,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:15:00,139.9012415597,35.6718533999,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:16:00,139.8960666297,35.6675583021,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:17:00,139.8898852372,35.6642910128,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:18:00,139.8825930759,35.663429054,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:19:00,139.8752289383,35.6634741155,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:20:00,139.8678686328,35.6639055559,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:21:00,139.8605063451,35.6643504256,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:22:00,139.8532221111,35.665092312,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:23:00,139.8458708993,35.6656531859,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:24:00,139.8385666245,35.6665166264,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:25:00,139.8315817375,35.6684220943,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:26:00,139.8244277912,35.6699025077,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:27:00,139.8170580946,35.669538482,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:28:00,139.8096817685,35.6692862685,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:29:00,139.8023057075,35.6695298678,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:30:00,139.7956406097,35.6720590573,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,7,1998/10/01 11:31:00,139.7895850504,35.6754126971,2,3,4123006,5 ,4 ,26,,12",
	"377200,4,4,1998/10/01 11:31:00,139.6329738734,35.9220800439,1,14,3052009,15,3 ,29,,3",
	"23000,4,7,1998/10/01 11:32:00,139.7831966004,35.6782281315,2,3,4123006,5 ,4 ,26,,12",
	"377200,4,4,1998/10/01 11:32:00,139.6330951884,35.9220291836,1,14,3052009,15,3 ,29,,3",
	"23000,4,7,1998/10/01 11:33:00,139.7768685762,35.6812976227,2,3,4123006,5 ,4 ,26,,12",
	"377200,4,4,1998/10/01 11:33:00,139.6332165033,35.9219783232,1,14,3052009,15,3 ,29,,3",
	"23000,4,7,1998/10/01 11:34:00,139.77016885,35.6838095805,2,3,4123006,5 ,4 ,26,,12",
	"377200,4,4,1998/10/01 11:34:00,139.633337818,35.9219274627,1,14,3052009,15,3 ,29,,3",
	"23000,4,7,1998/10/01 11:35:00,139.7630338031,35.6853631772,2,3,4123006,5 ,4 ,26,,12",
	"377200,4,4,1998/10/01 11:35:00,139.6334591325,35.921876602,1,14,3052009,15,3 ,29,,3",
	"23000,4,7,1998/10/01 11:36:00,139.7592775582,35.6900211247,2,3,4123006,5 ,4 ,26,,12",
	"377200,4,4,1998/10/01 11:36:00,139.6335804469,35.9218257412,1,14,3052009,15,3 ,29,,3",
	"23000,4,7,1998/10/01 11:37:00,139.7536178322,35.6934622678,2,3,4123006,5 ,4 ,26,,12",
	"377200,4,4,1998/10/01 11:37:00,139.6337017611,35.9217748803,1,14,3052009,15,3 ,29,,3",
	"23000,4,7,1998/10/01 11:38:00,139.7476670248,35.6946501221,2,3,4123006,5 ,4 ,26,,12",
	"377200,4,4,1998/10/01 11:38:00,139.6338230752,35.9217240192,1,14,3052009,15,3 ,29,,3",
	"23000,4,7,1998/10/01 11:39:00,139.7407780339,35.6924951313,2,3,4123006,5 ,4 ,26,,12",
	"377200,4,4,1998/10/01 11:39:00,139.6339443891,35.9216731581,1,14,3052009,15,3 ,29,,3",
	"23000,4,7,1998/10/01 11:40:00,139.7337951368,35.6910456048,2,3,4123006,5 ,4 ,26,,12",
	"377200,4,4,1998/10/01 11:40:00,139.6340657029,35.9216222968,1,14,3052009,15,3 ,29,,3",
	"23000,4,7,1998/10/01 11:41:00,139.7266466823,35.6917881545,2,3,4123006,5 ,4 ,26,,12",
	"377200,4,4,1998/10/01 11:41:00,139.6341870165,35.9215714354,1,14,3052009,15,3 ,29,,3",
	"23000,4,7,1998/10/01 11:42:00,139.7194874031,35.6918214761,2,3,4123006,5 ,4 ,26,,12",
	"377200,4,4,1998/10/01 11:42:00,139.63430833,35.9215205739,1,14,3052009,15,3 ,29,,3",
	"23000,4,7,1998/10/01 11:43:00,139.7122683629,35.6912887927,2,3,4123006,5 ,4 ,26,,12",
	"377200,4,4,1998/10/01 11:43:00,139.6344296433,35.9214697122,1,14,3052009,15,3 ,29,,3",
	"23000,4,7,1998/10/01 11:44:00,139.7051786747,35.6904031095,2,3,4123006,5 ,4 ,26,,12",
	"377200,4,4,1998/10/01 11:44:00,139.6345509564,35.9214188504,1,14,3052009,15,3 ,29,,3",
	"23000,4,7,1998/10/01 11:45:00,139.6998272499,35.690734646,2,3,4123006,5 ,4 ,26,,12",
	"23000,4,8,1998/10/01 11:45:00,139.6998272499,35.690734646,2,3,4123006,5 ,4 ,26,,1",
	"377200,4,4,1998/10/01 11:45:00,139.6346722694,35.9213679885,1,14,3052009,15,3 ,29,,3",
	"23000,4,8,1998/10/01 11:46:00,139.7023228614,35.6900809398,2,3,4123006,5 ,4 ,26,,1",
	"377200,4,4,1998/10/01 11:46:00,139.6347935822,35.9213171265,1,14,3052009,15,3 ,29,,3",
	"23000,4,8,1998/10/01 11:47:00,139.7048801573,35.6898932374,2,3,4123006,5 ,4 ,26,,1",
	"377200,4,4,1998/10/01 11:47:00,139.6349148949,35.9212662643,1,14,3052009,15,3 ,29,,3",
	"23000,4,8,1998/10/01 11:48:00,139.7074144186,35.6897102098,2,3,4123006,5 ,4 ,26,,1",
	"377200,4,4,1998/10/01 11:48:00,139.6350362075,35.9212154021,1,14,3052009,15,3 ,29,,3",
	"23000,4,8,1998/10/01 11:49:00,139.7095470452,35.6893160345,2,3,4123006,5 ,4 ,26,,1",
	"377200,4,4,1998/10/01 11:49:00,139.6351575198,35.9211645397,1,14,3052009,15,3 ,29,,3",
	"23000,4,8,1998/10/01 11:50:00,139.7116392689,35.6896543222,2,3,4123006,5 ,4 ,26,,1",
	"23000,5,9,1998/10/01 11:50:00,139.7116392689,35.6896543222,2,3,4123006,5 ,97,26,,97",
	"377200,4,4,1998/10/01 11:50:00,139.635278832,35.9211136771,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 11:51:00,139.6353461457,35.9210310893,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 11:52:00,139.6353673781,35.9209214277,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 11:53:00,139.6353886105,35.9208117662,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 11:54:00,139.6354775264,35.9207768862,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 11:55:00,139.635612566,35.9207929669,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 11:56:00,139.6357476057,35.9208090474,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 11:57:00,139.6358826455,35.9208251278,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 11:58:00,139.6360176853,35.9208412081,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 11:59:00,139.6361527252,35.9208572882,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:00:00,139.6362877651,35.9208733681,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:01:00,139.6364228051,35.9208894479,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:02:00,139.6365578451,35.9209055275,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:03:00,139.6366928852,35.920921607,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:04:00,139.6368279253,35.9209376863,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:05:00,139.6369629655,35.9209537655,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:06:00,139.6370980058,35.9209698445,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:07:00,139.6372330461,35.9209859234,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:08:00,139.6372516186,35.9208773111,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:09:00,139.6372687706,35.9207671781,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:10:00,139.6372859225,35.920657045,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:11:00,139.6373030743,35.9205469119,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:12:00,139.6373202261,35.9204367789,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:13:00,139.6374414182,35.9204365959,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:14:00,139.6375766947,35.9204512971,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:15:00,139.6377119712,35.9204659982,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:16:00,139.6378472478,35.9204806992,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:17:00,139.6379825245,35.9204954,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:18:00,139.6381178011,35.9205101007,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:19:00,139.6382530779,35.9205248012,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:20:00,139.6383883547,35.9205395015,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:21:00,139.6385236315,35.9205542017,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:22:00,139.6386589084,35.9205689018,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:23:00,139.6387941854,35.9205836017,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:24:00,139.6389294624,35.9205983014,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:25:00,139.6390647394,35.920613001,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:26:00,139.6392000165,35.9206277005,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:27:00,139.6393345302,35.9206428077,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:28:00,139.6392973982,35.9207496332,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:29:00,139.639260266,35.9208564586,1,14,3052009,15,3 ,29,,3",
	"377200,4,4,1998/10/01 12:30:00,139.6392231337,35.9209632841,1,14,3052009,15,3 ,29,,3",
	"377200,5,5,1998/10/01 12:30:00,139.6392231337,35.9209632841,1,14,3052009,15,97,29,,97",
	"66700,6,6,1998/10/01 14:01:00,139.9478438439,35.8512761038,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:02:00,139.947894107,35.8509587048,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:03:00,139.9479443696,35.8506413059,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:04:00,139.9479946319,35.8503239069,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:05:00,139.9480448938,35.8500065078,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:06:00,139.9479819679,35.8501301311,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:07:00,139.9478719194,35.8504373596,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:08:00,139.9478215879,35.8504723699,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:09:00,139.9478489715,35.8501531215,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:10:00,139.9478763549,35.849833873,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:11:00,139.947903738,35.8495146245,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:12:00,139.9478380304,35.8492016933,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:13:00,139.9477461071,35.8488905411,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:14:00,139.9477097415,35.8485740271,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:15:00,139.9477260198,35.8482542992,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:16:00,139.9477436984,35.847934597,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:17:00,139.947756811,35.8476148658,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:18:00,139.9477800672,35.8472954003,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:19:00,139.947797265,35.8469757891,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:20:00,139.9477924691,35.846655889,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:21:00,139.9477270366,35.8463411748,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:22:00,139.9476164674,35.8460341946,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:23:00,139.9474947559,35.8457298974,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:24:00,139.9473730453,35.8454256,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:25:00,139.9472187227,35.8451312803,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:26:00,139.9471658332,35.8448181061,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:27:00,139.9471814051,35.8444983311,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:28:00,139.9471969769,35.844178556,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:29:00,139.9468107052,35.8441578804,1,11,4311002,3 ,9 ,37,,2",
	"66700,6,6,1998/10/01 14:30:00,139.9467526879,35.8439425681,1,11,4311002,3 ,9 ,37,,2",
	"66700,7,7,1998/10/01 14:30:00,139.9467526879,35.8439425681,1,11,4311002,3 ,97,37,,97",
	"377200,6,6,1998/10/01 14:31:00,139.637810454,35.9209795089,1,14,3052009,15,6 ,29,,3",
	"377200,6,6,1998/10/01 14:32:00,139.6364065172,35.9208875084,1,14,3052009,15,6 ,29,,3",
	"377200,6,6,1998/10/01 14:33:00,139.6353361132,35.9210829054,1,14,3052009,15,6 ,29,,3",
	"377200,6,6,1998/10/01 14:34:00,139.6340379855,35.9215265545,1,14,3052009,15,6 ,29,,3",
	"377200,6,6,1998/10/01 14:35:00,139.6328525583,35.9221309041,1,14,3052009,15,6 ,29,,3",
	"377200,7,7,1998/10/01 14:35:00,139.6328525583,35.9221309041,1,14,3052009,15,97,29,,97",
	"66700,8,8,1998/10/01 14:46:00,139.9468774079,35.8442495743,1,11,4311002,3 ,3 ,37,,2",
	"377200,8,8,1998/10/01 14:46:00,139.63430833,35.9215205739,1,14,3052009,15,3 ,29,,3",
	"66700,8,8,1998/10/01 14:47:00,139.9470021288,35.8445565803,1,11,4311002,3 ,3 ,37,,2",
	"377200,8,8,1998/10/01 14:47:00,139.6354775264,35.9207768862,1,14,3052009,15,3 ,29,,3",
	"66700,8,8,1998/10/01 14:48:00,139.9471268506,35.8448635862,1,11,4311002,3 ,3 ,37,,2",
	"377200,8,8,1998/10/01 14:48:00,139.6370980058,35.9209698445,1,14,3052009,15,3 ,29,,3",
	"66700,8,8,1998/10/01 14:49:00,139.9472402647,35.8451722991,1,11,4311002,3 ,3 ,37,,2",
	"377200,8,8,1998/10/01 14:49:00,139.6381178011,35.9205101007,1,14,3052009,15,3 ,29,,3",
	"66700,8,8,1998/10/01 14:50:00,139.9474336963,35.845423633,1,11,4311002,3 ,3 ,37,,2",
	"377200,8,8,1998/10/01 14:50:00,139.6392231337,35.9209632841,1,14,3052009,15,3 ,29,,3",
	"377200,9,9,1998/10/01 14:50:00,139.6392231337,35.9209632841,1,14,3052009,15,97,29,,97",
	"23000,6,10,1998/10/01 14:51:00,139.7103934458,35.6894002668,2,3,4123006,5 ,3 ,26,,1",
	"66700,8,8,1998/10/01 14:51:00,139.947830722,35.8454154679,1,11,4311002,3 ,3 ,37,,2",
	"23000,6,10,1998/10/01 14:52:00,139.7092622095,35.6894504627,2,3,4123006,5 ,3 ,26,,1",
	"66700,8,8,1998/10/01 14:52:00,139.9480254902,35.8456400992,1,11,4311002,3 ,3 ,37,,2",
	"23000,6,10,1998/10/01 14:53:00,139.7079480049,35.6899369749,2,3,4123006,5 ,3 ,26,,1",
	"66700,8,8,1998/10/01 14:53:00,139.9481533493,35.8459461858,1,11,4311002,3 ,3 ,37,,2",
	"23000,6,10,1998/10/01 14:54:00,139.7068749406,35.6899339261,2,3,4123006,5 ,3 ,26,,1",
	"66700,8,8,1998/10/01 14:54:00,139.9482873471,35.8462505733,1,11,4311002,3 ,3 ,37,,2",
	"23000,6,10,1998/10/01 14:55:00,139.7054278713,35.689903402,2,3,4123006,5 ,3 ,26,,1",
	"66700,8,8,1998/10/01 14:55:00,139.948449253,35.8465451264,1,11,4311002,3 ,3 ,37,,2",
	"23000,6,10,1998/10/01 14:56:00,139.7039830437,35.6898604156,2,3,4123006,5 ,3 ,26,,1",
	"66700,8,8,1998/10/01 14:56:00,139.9486583433,35.8468188373,1,11,4311002,3 ,3 ,37,,2",
	"23000,6,10,1998/10/01 14:57:00,139.7025837595,35.6900125959,2,3,4123006,5 ,3 ,26,,1",
	"66700,8,8,1998/10/01 14:57:00,139.9489318782,35.8470532879,1,11,4311002,3 ,3 ,37,,2",
	"23000,6,10,1998/10/01 14:58:00,139.7012055109,35.6903736289,2,3,4123006,5 ,3 ,26,,1",
	"66700,8,8,1998/10/01 14:58:00,139.9492054147,35.8472877378,1,11,4311002,3 ,3 ,37,,2",
	"23000,6,10,1998/10/01 14:59:00,139.6998272499,35.690734646,2,3,4123006,5 ,3 ,26,,1",
	"23000,6,11,1998/10/01 14:59:00,139.6998272499,35.690734646,2,3,4123006,5 ,3 ,26,,12",
	"66700,8,8,1998/10/01 14:59:00,139.9494789529,35.8475221871,1,11,4311002,3 ,3 ,37,,2"
];
	
	

var TEST_MOVING_DATA_SOURCE_F = [
 "20700,1,1,1998/10/01 06:00:00,140.0200404524,35.6761541956,1,8,4121303,9 ,97,44,,97",
 "73600,1,1,1998/10/01 06:00:00,140.0901189153,35.6984961013,2,1,4410011,12,97,35,,97",
 "69000,1,1,1998/10/01 06:00:00,139.911987613,35.910811609,1,4,4312114,13,97,52,,97",
 "89700,4,4,1998/10/01 06:00:00,140.2554569324,35.7471696756,2,8,4422113,3 ,97,42,,97",
 "96600,1,1,1998/10/01 06:00:00,140.0871250154,35.5176974002,1,3,4510101,13,97,33,,97",
 "128800,1,1,1998/10/01 06:00:00,140.4004605494,35.8079538996,1,14,4831221,15,97,26,,97",
 "117300,1,1,1998/10/01 06:00:00,139.8490491398,34.9431297218,1,8,4730209,3 ,97,38,,97",
 "147200,1,1,1998/10/01 06:00:00,140.0829471178,35.6571425181,2,9,4022001,15,97,38,,97",
 "154100,1,1,1998/10/01 06:00:00,140.1457385209,35.6420416991,2,10,4040012,14,97,41,,97",
 "207000,1,1,1998/10/01 06:00:00,140.580742508,36.0535282149,2,16,5631013,15,97,41,,97",
 "220800,1,1,1998/10/01 06:00:00,139.5909653574,35.2589178449,2,10,2310102,14,97,37,,97",
 "273700,1,1,1998/10/01 06:00:00,139.3079584024,35.3955938168,2,5,2820113,14,97,42,,97",
 "280600,1,1,1998/10/01 06:00:00,139.4649344097,35.4658912124,1,11,2910009,8 ,97,42,,97",
 "280600,2,2,1998/10/01 06:00:00,139.4649344097,35.4658912124,1,11,2910009,8 ,8 ,42,,6",
 "317400,1,1,1998/10/01 06:00:00,139.6697066819,35.5414292459,2,5,2022101,14,97,42,,97",
 "363400,1,1,1998/10/01 06:00:00,139.6547658017,35.8703176433,1,6,3032201,3 ,97,34,,97",
 "356500,1,1,1998/10/01 06:00:00,139.7300156336,35.8345651652,2,6,3018202,8 ,97,22,,97",
 "354200,1,1,1998/10/01 06:00:00,139.75284087,35.7934536437,2,12,3017007,14,97,34,,97",
 "434700,1,1,1998/10/01 06:00:00,139.8111623189,35.8131042092,2,8,3210107,8 ,97,37,,97",
 "535900,1,1,1998/10/01 06:00:00,139.7291564519,35.6541808273,2,9,0033106,14,97,70,,97",
 "542800,1,1,1998/10/01 06:00:00,139.7409360122,35.6227794853,2,13,0121101,14,97,45,,97",
 "533600,3,3,1998/10/01 06:00:00,139.7682895209,35.6629843437,1,5,0023204,4 ,97,82,,97",
 "561200,1,1,1998/10/01 06:00:00,139.7278478276,35.7393477246,2,13,0210202,14,97,53,,97",
 "565800,1,1,1998/10/01 06:00:00,139.7582397125,35.7103505992,2,4,0221009,5 ,97,53,,97",
 "593400,1,1,1998/10/01 06:00:00,139.8191684032,35.683508266,1,6,0349009,9 ,97,58,,97",
 "607200,1,1,1998/10/01 06:00:00,139.6088791587,35.7171311386,2,7,0424006,14,97,59,,97",
 "657800,1,1,1998/10/01 06:00:00,139.7981196353,35.7563043481,2,11,0611113,9 ,97,46,,97",
 "749800,1,1,1998/10/01 06:00:00,139.4809918769,35.521195758,1,7,0810411,10,97,36,,97",
 "807300,1,1,1998/10/01 06:00:00,139.6429666539,35.4323298542,2,7,1024031,8 ,97,43,,97",
 "828000,1,1,1998/10/01 06:00:00,139.505064725,35.504407201,1,5,1252001,4 ,97,38,,97",
 "832600,1,1,1998/10/01 06:00:00,139.6156048083,35.4195049372,1,14,1111003,15,97,30,,97",
 "853300,1,1,1998/10/01 06:00:00,139.5062639752,35.5296861308,1,1,1332201,12,97,33,,97",
 "6900,1,1,1998/10/01 06:00:00,139.919992364,35.715967938,2,11,4114005,14,97,32,,97",
 "4600,1,1,1998/10/01 06:00:00,139.9267882873,35.7556968864,1,11,4112301,10,97,37,,97",
 "11500,1,1,1998/10/01 06:00:00,139.8916091155,35.6774817198,2,7,4115209,14,97,32,,97",
 "27600,1,1,1998/10/01 06:00:00,140.0073443415,35.7052864111,2,6,4125008,14,97,25,,97",
 "46000,3,3,1998/10/01 06:00:00,139.9178877479,35.7859750879,1,8,4214428,4 ,97,43,,97",
 "52900,1,1,1998/10/01 06:00:00,139.9699221931,35.848993218,2,7,4220204,14,97,27,,97",
 "57500,1,1,1998/10/01 06:00:00,139.9836600277,35.8169119141,1,10,4222304,9 ,97,27,,97",
 "64400,1,1,1998/10/01 06:00:00,139.9967651393,35.8411370987,2,8,4226026,14,97,38,,97",
 "66700,1,1,1998/10/01 06:00:00,139.9491826328,35.8476214241,1,11,4311002,3 ,97,37,,97",
 "75900,1,1,1998/10/01 06:00:00,140.121102122,35.7283154302,1,1,4411207,12,97,36,,97",
 "82800,1,1,1998/10/01 06:00:00,140.1772532608,35.6789054526,2,14,4416010,15,97,36,,97",
 "85100,1,1,1998/10/01 06:00:00,140.295583572,35.681503397,1,8,4417027,9 ,97,46,,97",
 "92000,1,1,1998/10/01 06:00:00,140.3085347067,35.8368777314,2,6,4431008,14,97,36,,97",
 "103500,1,1,1998/10/01 06:00:00,139.9322308732,35.3852162684,2,3,4610015,13,97,38,,97",
 "98900,1,1,1998/10/01 06:00:00,140.0604989485,35.4713987531,2,11,4511022,8 ,97,32,,97",
 "108100,1,1,1998/10/01 06:00:00,140.0211664233,35.388509241,2,4,4615025,8 ,97,43,,97",
 "105800,1,1,1998/10/01 06:00:00,139.9130343132,35.3450582562,2,2,4612227,12,97,32,,97",
 "110400,1,1,1998/10/01 06:00:00,140.0630262994,35.2592318865,1,12,4621121,1 ,97,41,,97",
 "112700,1,1,1998/10/01 06:00:00,140.2259010353,35.2973336878,1,13,4711006,1 ,97,38,,97",
 "119600,1,1,1998/10/01 06:00:00,140.8599376391,35.7284633857,2,13,4810072,14,97,45,,97",
 "124200,1,1,1998/10/01 06:00:00,140.4854399672,35.6829408964,2,1,4813118,12,97,26,,97",
 "121900,1,1,1998/10/01 06:00:00,140.7001113566,35.7138811076,1,2,4811106,12,97,37,,97",
 "131100,1,1,1998/10/01 06:00:00,140.392342751,35.5389078915,2,15,4910117,15,97,38,,97",
 "126500,1,1,1998/10/01 06:00:00,140.6781592199,35.8334034774,1,9,4820320,3 ,97,16,,97",
 "133400,1,1,1998/10/01 06:00:00,140.4573022298,35.5614890796,2,3,4911101,13,97,34,,97",
 "138000,1,1,1998/10/01 06:00:00,140.3887610796,35.4404586879,1,9,4922013,9 ,97,36,,97",
 "138000,2,2,1998/10/01 06:00:00,140.3887610796,35.4404586879,1,9,4922013,9 ,1 ,36,,6",
 "142600,1,1,1998/10/01 06:00:00,140.1492382343,35.5889689912,2,14,4013104,14,97,27,,97",
 "144900,1,1,1998/10/01 06:00:00,140.0745886945,35.6461524624,2,4,4020004,4 ,97,48,,97",
 "149500,1,1,1998/10/01 06:00:00,140.112938411,35.698136716,2,10,4022410,4 ,97,29,,97",
 "158700,1,1,1998/10/01 06:00:00,140.1714530648,35.5589926423,2,1,4051001,12,97,29,,97",
 "161000,1,1,1998/10/01 06:00:00,140.1003706334,35.6129936651,2,1,4060001,12,97,32,,97",
 "165600,1,1,1998/10/01 06:00:00,140.2647259368,36.2277461073,1,12,5011018,15,97,33,,97",
 "167900,1,1,1998/10/01 06:00:00,140.1744890066,36.1494411712,1,2,5020014,12,97,33,,97",
 "170200,1,1,1998/10/01 06:00:00,140.2047272423,36.0665010098,2,3,5022220,13,97,43,,97",
 "174800,1,1,1998/10/01 06:00:00,139.6980748631,36.1897117573,2,15,5111003,15,97,31,,97",
 "181700,1,1,1998/10/01 06:00:00,139.9540996136,36.1528012386,2,7,5123003,8 ,97,31,,97",
 "177100,1,1,1998/10/01 06:00:00,139.8318405639,36.095792603,2,3,5113112,13,97,35,,97",
 "186300,1,1,1998/10/01 06:00:00,140.1230420936,35.991911881,1,4,5217006,13,97,53,,97",
 "184000,1,1,1998/10/01 06:00:00,140.0990521264,36.0982688414,2,4,5214028,13,97,49,,97",
 "190900,1,1,1998/10/01 06:00:00,139.9653573151,35.9622777835,2,11,5410113,14,97,37,,97",
 "188600,1,1,1998/10/01 06:00:00,139.9842806917,36.0204297076,2,15,5311005,15,97,29,,97",
 "202400,1,1,1998/10/01 06:00:00,140.4916020357,35.9987070227,2,10,5610009,4 ,97,34,,97",
 "200100,1,1,1998/10/01 06:00:00,140.3167808777,36.0031633519,2,8,5510012,8 ,97,39,,97",
 "204700,1,1,1998/10/01 06:00:00,140.6895696894,35.8567117959,2,12,5621106,1 ,97,40,,97",
 "213900,1,1,1998/10/01 06:00:00,139.6901088328,35.2341594218,2,4,2220414,13,97,38,,97",
 "216200,1,1,1998/10/01 06:00:00,139.678788719,35.2488560737,2,1,2221106,12,97,34,,97",
 "232300,1,1,1998/10/01 06:00:00,139.4770412323,35.3491136247,1,4,2411307,3 ,97,40,,97",
 "236900,1,1,1998/10/01 06:00:00,139.4357342685,35.3839618356,2,2,2412501,12,97,19,,97",
 "241500,1,1,1998/10/01 06:00:00,139.4004988194,35.3447799921,1,6,2420707,5 ,97,37,,97",
 "239200,1,1,1998/10/01 06:00:00,139.4299997959,35.3343933589,2,9,2420201,14,97,33,,97",
 "248400,1,1,1998/10/01 06:00:00,139.350029898,35.3581902274,1,5,2511002,3 ,97,37,,97",
 "250700,1,1,1998/10/01 06:00:00,139.3170810079,35.3302677075,2,11,2512003,5 ,97,38,,97",
 "255300,1,1,1998/10/01 06:00:00,139.1756598935,35.3016615805,1,12,2610311,8 ,97,44,,97",
 "257600,1,1,1998/10/01 06:00:00,139.1071420224,35.2580438588,1,13,2620701,1 ,97,33,,97",
 "262200,1,1,1998/10/01 06:00:00,139.1525733577,35.6344929045,1,9,2713004,3 ,97,42,,97",
 "269100,1,1,1998/10/01 06:00:00,139.3606708555,35.4569359812,1,7,2811013,9 ,97,37,,97",
 "271400,1,1,1998/10/01 06:00:00,139.3124746168,35.4726555427,1,7,2811315,9 ,97,37,,97",
 "276000,1,1,1998/10/01 06:00:00,139.2576955397,35.3679563744,1,2,2821003,12,97,33,,97",
 "287500,1,1,1998/10/01 06:00:00,139.4267047972,35.4763265739,1,13,2912305,15,97,31,,97",
 "285200,1,1,1998/10/01 06:00:00,139.4515202495,35.5045589082,1,2,2911313,12,97,34,,97",
 "292100,1,1,1998/10/01 06:00:00,139.3959691858,35.4376241101,2,9,2914110,8 ,97,32,,97",
 "289800,1,1,1998/10/01 06:00:00,139.4296998492,35.4619167803,2,3,2913122,12,97,38,,97",
 "294400,1,1,1998/10/01 06:00:00,139.4461547447,35.5315912869,1,4,2920001,3 ,97,39,,97",
 "299000,1,1,1998/10/01 06:00:00,139.4008808343,35.561529488,2,9,2921203,3 ,97,33,,97",
 "308200,1,1,1998/10/01 06:00:00,139.3439944286,35.5514905642,2,12,2924401,14,97,33,,97",
 "305900,1,1,1998/10/01 06:00:00,139.3730066295,35.554334991,2,9,2924003,3 ,97,34,,97",
 "315100,2,2,1998/10/01 06:00:00,139.6050774618,35.4896469825,1,11,2020106,10,9 ,39,,8",
 "315100,3,3,1998/10/01 06:00:00,139.6050774618,35.4896469825,1,11,2020106,10,97,39,,97",
 "312800,1,1,1998/10/01 06:00:00,139.6999748263,35.5073410372,2,6,2015203,14,97,34,,97",
 "331200,1,1,1998/10/01 06:00:00,139.5830130321,35.5703405851,2,1,2121208,12,97,28,,97",
 "335800,1,1,1998/10/01 06:00:00,139.558954801,35.6317794054,2,6,2130206,14,97,31,,97",
 "338100,1,1,1998/10/01 06:00:00,139.5252244867,35.6242436504,2,6,2132009,14,97,31,,97",
 "347300,1,1,1998/10/01 06:00:00,139.679969943,35.845484689,1,2,3014201,12,97,31,,97",
 "349600,1,1,1998/10/01 06:00:00,139.7055433936,35.8551470936,2,13,3014406,14,97,34,,97",
 "361100,1,1,1998/10/01 06:00:00,139.6364558937,35.8255555815,1,6,3026023,9 ,97,32,,97",
 "368000,1,1,1998/10/01 06:00:00,139.6051045708,35.8817183214,1,12,3035001,15,97,32,,97",
 "374900,1,1,1998/10/01 06:00:00,139.6367965852,35.8726186742,1,4,3040108,4 ,97,71,,97",
 "372600,1,1,1998/10/01 06:00:00,139.6862504048,35.8721390711,2,11,3038102,14,97,37,,97",
 "379500,1,1,1998/10/01 06:00:00,139.6050591202,35.9298516017,2,6,3053202,8 ,97,31,,97",
 "377200,1,1,1998/10/01 06:00:00,139.6392231337,35.9209632841,1,14,3052009,15,97,29,,97",
 "384100,1,1,1998/10/01 06:00:00,139.5957893987,35.9573655267,2,11,3057004,14,97,31,,97",
 "381800,1,1,1998/10/01 06:00:00,139.6013922141,35.8934833766,1,15,3055105,10,97,32,,97",
 "388700,1,1,1998/10/01 06:00:00,139.594768176,35.8017853083,2,11,3110001,14,97,34,,97",
 "395600,1,1,1998/10/01 06:00:00,139.569864113,35.7750357088,1,10,3113009,1 ,97,41,,97",
 "402500,1,1,1998/10/01 06:00:00,139.5083679335,35.85698204,1,9,3116015,9 ,97,44,,97",
 "400200,1,1,1998/10/01 06:00:00,139.5489151284,35.845341548,2,15,3115006,15,97,25,,97",
 "407100,1,1,1998/10/01 06:00:00,139.4676277192,35.8029025419,1,4,3120608,13,97,37,,97",
 "414000,1,1,1998/10/01 06:00:00,139.3981827013,35.8040939664,1,10,3122302,3 ,97,32,,97",
 "411700,1,1,1998/10/01 06:00:00,139.4346191322,35.7976563357,2,1,3122110,12,97,29,,97",
 "423200,1,1,1998/10/01 06:00:00,139.4898133641,35.8792612521,2,9,3133014,15,97,36,,97",
 "420900,1,1,1998/10/01 06:00:00,139.4700257051,35.9135804219,1,7,3130307,3 ,97,39,,97",
 "430100,3,3,1998/10/01 06:00:00,139.4171503036,35.8572340695,1,14,3135204,15,97,33,,97",
 "432400,1,1,1998/10/01 06:00:00,139.4304025609,35.8419099656,2,2,3136204,12,97,33,,97",
 "439300,1,1,1998/10/01 06:00:00,139.8138603714,35.8547797076,2,6,3213107,14,97,29,,97",
 "441600,1,1,1998/10/01 06:00:00,139.88856083,35.8472991304,1,9,3215009,9 ,97,30,,97",
 "443900,1,1,1998/10/01 06:00:00,139.8788869653,35.8074883296,2,10,3215307,5 ,97,46,,97",
 "455400,1,1,1998/10/01 06:00:00,139.8172558244,35.9264218701,1,12,3226003,3 ,97,31,,97",
 "453100,1,1,1998/10/01 06:00:00,139.8360179794,35.8733594006,2,11,3224113,14,97,40,,97",
 "460000,1,1,1998/10/01 06:00:00,139.7230470737,35.9940366258,2,4,3234215,4 ,97,40,,97",
 "457700,1,1,1998/10/01 06:00:00,139.7250423975,35.9354191667,2,12,3231014,14,97,32,,97",
 "462300,1,1,1998/10/01 06:00:00,139.7734182711,35.9421847729,1,8,3235004,9 ,97,38,,97",
 "471500,1,1,1998/10/01 06:00:00,139.2982149388,36.0108710571,1,1,3320701,12,97,29,,97",
 "480700,1,1,1998/10/01 06:00:00,139.3261150198,36.049188189,1,10,3414121,4 ,97,44,,97",
 "485300,1,1,1998/10/01 06:00:00,139.5803086384,35.9608969701,1,13,3511206,15,97,26,,97",
 "487600,1,1,1998/10/01 06:00:00,139.6420365721,35.9891001018,1,10,3512006,6 ,97,36,,97",
 "496800,1,1,1998/10/01 06:00:00,139.6047883353,36.0732337659,1,2,3610207,12,97,33,,97",
 "499100,1,1,1998/10/01 06:00:00,139.7415614015,36.0266054348,2,11,3612106,14,97,39,,97",
 "503700,1,1,1998/10/01 06:00:00,139.6805825806,36.0362732354,2,7,3613326,14,97,29,,97",
 "519800,1,1,1998/10/01 06:00:00,139.3912878538,36.1609066526,1,10,3721001,9 ,97,44,,97",
 "529000,1,1,1998/10/01 06:00:00,139.0820088964,35.9817206287,2,6,3910031,14,97,34,,97",
 "512900,1,1,1998/10/01 06:00:00,139.4425365909,36.1013870571,1,11,3632007,5 ,97,35,,97",
 "524400,3,3,1998/10/01 06:00:00,139.3894587905,36.1407242973,1,6,3723001,6 ,97,23,,97",
 "510600,1,1,1998/10/01 06:00:00,139.4542410068,36.1370604149,2,13,3631014,5 ,97,36,,97",
 "517500,1,1,1998/10/01 06:00:00,139.3658413451,36.1536253163,1,15,3720131,15,97,28,,97",
 "522100,1,1,1998/10/01 06:00:00,139.2854386671,36.1801339475,1,3,3722104,13,97,35,,97",
 "531300,1,1,1998/10/01 06:00:00,139.104597267,36.0698903951,1,5,3920007,3 ,97,33,,97",
 "515200,1,1,1998/10/01 06:00:00,139.2064190418,36.1549598251,1,1,3711123,12,97,37,,97",
 "526700,1,1,1998/10/01 06:00:00,139.236504537,36.2140044772,2,1,3810406,12,97,38,,97",
 "554300,1,1,1998/10/01 06:00:00,139.6998402745,35.5631747499,1,14,0135008,15,97,38,,97",
 "538200,1,1,1998/10/01 06:00:00,139.6864571204,35.6155887799,2,11,0111012,14,97,44,,97",
 "547400,1,1,1998/10/01 06:00:00,139.7241918434,35.5899099814,2,12,0130002,14,97,47,,97",
 "552000,1,1,1998/10/01 06:00:00,139.7496156371,35.5460630549,2,14,0133113,15,97,35,,97",
 "549700,1,1,1998/10/01 06:00:00,139.7304680896,35.5768804158,1,8,0131010,3 ,97,62,,97",
 "558900,1,1,1998/10/01 06:00:00,139.7156564777,35.5574786697,1,14,0138006,9 ,97,32,,97",
 "568100,1,1,1998/10/01 06:00:00,139.723195834,35.680327075,2,6,0231108,14,97,58,,97",
 "572700,1,1,1998/10/01 06:00:00,139.7111766548,35.6707159357,2,2,0241003,12,97,27,,97",
 "577300,1,1,1998/10/01 06:00:00,139.7762754032,35.7306362663,1,4,0311012,9 ,97,27,,97",
 "586500,1,1,1998/10/01 06:00:00,139.8127525895,35.7158013964,2,12,0336004,3 ,97,42,,97",
 "591100,1,1,1998/10/01 06:00:00,139.816583072,35.6673995746,2,9,0344002,14,97,52,,97",
 "595700,1,1,1998/10/01 06:00:00,139.6276111894,35.7298135244,1,1,0410205,12,97,44,,97",
 "604900,1,1,1998/10/01 06:00:00,139.6042595469,35.6879604834,2,1,0423005,12,97,49,,97",
 "614100,1,1,1998/10/01 06:00:00,139.642348736,35.6209289029,2,4,0432205,13,97,73,,97",
 "625600,1,1,1998/10/01 06:00:00,139.6762505888,35.7433260903,2,2,0511005,12,97,37,,97",
 "623300,1,1,1998/10/01 06:00:00,139.627370134,35.6486854439,2,11,0437104,4 ,97,45,,97",
 "627900,1,1,1998/10/01 06:00:00,139.6495617295,35.7424385979,2,7,0511204,9 ,97,34,,97",
 "639400,1,1,1998/10/01 06:00:00,139.6857738389,35.7713645733,1,10,0520112,9 ,97,38,,97",
 "634800,1,1,1998/10/01 06:00:00,139.5958451812,35.7531799798,2,11,0514102,10,97,41,,97",
 "667000,1,1,1998/10/01 06:00:00,139.7761613173,35.7805709408,2,12,0614310,6 ,97,43,,97",
 "662400,1,1,1998/10/01 06:00:00,139.8195599506,35.7905026264,2,3,0612408,13,97,51,,97",
 "671600,1,1,1998/10/01 06:00:00,139.8527597261,35.7205394679,1,1,0622116,12,97,44,,97",
 "650900,1,1,1998/10/01 06:00:00,139.7439854938,35.7648861317,1,9,0531007,3 ,97,43,,97",
 "655500,1,1,1998/10/01 06:00:00,139.8462886322,35.7787607357,2,13,0610006,15,97,40,,97",
 "664700,1,1,1998/10/01 06:00:00,139.7597574914,35.7795510304,1,11,0614018,10,97,57,,97",
 "690000,1,1,1998/10/01 06:00:00,139.5618130131,35.671795292,2,1,0710226,12,97,18,,97",
 "678500,1,1,1998/10/01 06:00:00,139.8816835093,35.6901183687,1,1,0631106,12,97,49,,97",
 "680800,1,1,1998/10/01 06:00:00,139.8888803415,35.732136052,1,5,0632105,15,97,63,,97",
 "713000,1,1,1998/10/01 06:00:00,139.5135776286,35.7604322145,2,2,0741304,12,97,30,,97",
 "699200,1,1,1998/10/01 06:00:00,139.5535622769,35.7041661352,1,6,0720301,5 ,97,42,,97",
 "701500,1,1,1998/10/01 06:00:00,139.5672672302,35.7140014257,2,10,0721104,9 ,97,48,,97",
 "710700,1,1,1998/10/01 06:00:00,139.5458931669,35.7718743528,2,8,0741006,15,97,33,,97",
 "724500,1,1,1998/10/01 06:00:00,139.4868235776,35.6955747238,2,10,0761001,8 ,97,33,,97",
 "729100,1,1,1998/10/01 06:00:00,139.4855254089,35.7271116794,1,3,0770112,13,97,36,,97",
 "736000,1,1,1998/10/01 06:00:00,139.4382713244,35.7235809448,1,13,0780004,5 ,97,25,,97",
 "726800,1,1,1998/10/01 06:00:00,139.4370978694,35.7068959013,1,10,0761308,15,97,34,,97",
 "731400,1,1,1998/10/01 06:00:00,139.4774664017,35.7119598061,2,10,0771111,14,97,32,,97",
 "742900,1,1,1998/10/01 06:00:00,139.3480726468,35.712001832,1,8,0784113,10,97,38,,97",
 "761300,1,1,1998/10/01 06:00:00,139.4455589341,35.6232496272,1,1,0821303,12,97,35,,97",
 "768200,1,1,1998/10/01 06:00:00,139.3486439741,35.6685488639,2,2,0840002,12,97,36,,97",
 "745200,1,1,1998/10/01 06:00:00,139.4131751503,35.7437283695,2,10,0790316,3 ,97,30,,97",
 "752100,1,1,1998/10/01 06:00:00,139.4425237159,35.5655250505,1,6,0811001,6 ,97,36,,97",
 "765900,1,1,1998/10/01 06:00:00,139.4275386754,35.6498521824,1,3,0831004,13,97,53,,97",
 "779700,1,1,1998/10/01 06:00:00,139.2895134585,35.6249787289,1,1,0845007,12,97,36,,97",
 "791200,1,1,1998/10/01 06:00:00,139.1233525094,35.7878343648,2,8,0950008,5 ,97,25,,97",
 "775100,1,1,1998/10/01 06:00:00,139.2902754372,35.6742308204,1,7,0846102,9 ,97,38,,97",
 "784300,1,1,1998/10/01 06:00:00,139.3022269316,35.7737486604,2,6,0912126,14,97,30,,97",
 "788900,1,1,1998/10/01 06:00:00,139.2806431986,35.8210892467,2,2,0931009,12,97,35,,97",
 "782000,1,1,1998/10/01 06:00:00,139.3315222374,35.7221988795,2,2,0911103,12,97,36,,97",
 "800400,1,1,1998/10/01 06:00:00,139.628601512,35.4750808917,2,6,1423001,14,97,32,,97",
 "809600,1,1,1998/10/01 06:00:00,139.6082565958,35.4352238808,2,9,1122002,14,97,33,,97",
 "798100,1,1,1998/10/01 06:00:00,139.6510286818,35.4967678595,2,6,1421008,9 ,97,37,,97",
 "821100,1,1,1998/10/01 06:00:00,139.5683585219,35.4446484168,2,1,1213204,12,97,38,,97",
 "814200,1,1,1998/10/01 06:00:00,139.583890757,35.3946113596,2,17,1142005,15,97,26,,97",
 "825700,1,1,1998/10/01 06:00:00,139.5529673561,35.4588195287,1,2,1250301,12,97,31,,97",
 "844100,1,1,1998/10/01 06:00:00,139.6005849867,35.5092710241,2,8,1312002,4 ,97,34,,97",
 "855600,1,1,1998/10/01 06:00:00,139.5405991039,35.5481955951,2,14,1341201,15,97,47,,97",
 "851000,1,1,1998/10/01 06:00:00,139.5516544179,35.5047655785,2,11,1330011,15,97,45,,97",
 "867100,1,1,1998/10/01 06:00:00,139.4790533189,35.4290894129,2,2,1241102,12,97,29,,97",
 "874000,1,1,1998/10/01 06:00:00,139.5121453912,35.3923016114,1,13,1223004,4 ,97,33,,97",
 "862500,1,1,1998/10/01 06:00:00,139.5679652294,35.5400299058,2,6,1321110,15,97,29,,97",
 "857900,1,1,1998/10/01 06:00:00,139.5067038129,35.5540099967,1,11,1340115,10,97,37,,97",
 "876300,1,1,1998/10/01 06:00:00,139.4920042412,35.3835411536,1,9,1223102,9 ,97,27,,97",
 "878600,1,1,1998/10/01 06:00:00,139.5449257901,35.4214274237,2,8,1221105,14,97,43,,97",
 "871700,1,1,1998/10/01 06:00:00,139.573655375,35.3645056428,1,6,1230404,9 ,97,31,,97",
 "848700,1,1,1998/10/01 06:00:00,139.6152742149,35.5386162802,1,4,1313101,13,97,37,,97",
 "841800,1,1,1998/10/01 06:00:00,139.6201025218,35.3441481905,1,2,1132110,12,97,33,,97",
 "869400,1,1,1998/10/01 06:00:00,139.5812654873,35.3489542668,1,10,1231001,8 ,97,29,,97",
 "839500,1,1,1998/10/01 06:00:00,139.6430835445,35.3679218896,2,11,1133001,14,97,27,,97",
 "860200,1,1,1998/10/01 06:00:00,139.4970245746,35.5502145798,1,9,1340109,10,97,33,,97",
 "830300,1,1,1998/10/01 06:00:00,139.5360140277,35.4518709147,2,4,1251203,8 ,97,37,,97",
 "864800,1,1,1998/10/01 06:00:00,139.5254364569,35.4236493609,1,6,1240102,10,97,27,,97",
 "16100,1,1,1998/10/01 06:00:00,139.9143777652,35.6436602506,2,4,4116209,8 ,97,48,,97",
 "2300,1,1,1998/10/01 06:00:00,139.9170600568,35.7216134486,1,6,4111102,8 ,97,36,,97",
 "13800,1,1,1998/10/01 06:00:00,139.8984130423,35.6627506355,2,14,4116004,5 ,97,25,,97",
 "23000,1,1,1998/10/01 06:00:00,140.0385046369,35.7260155905,2,3,4123006,5 ,97,26,,97",
 "34500,1,1,1998/10/01 06:00:00,139.9894328494,35.7099943629,1,4,4129003,8 ,97,36,,97",
 "9200,1,1,1998/10/01 06:00:00,139.9542751231,35.6991106693,1,11,4114306,5 ,97,28,,97",
 "18400,1,1,1998/10/01 06:00:00,140.0419860907,35.6901721759,1,9,4120311,8 ,97,44,,97",
 "32200,1,1,1998/10/01 06:00:00,140.0391752515,35.7382207104,2,4,4128103,8 ,97,42,,97",
 "71300,1,1,1998/10/01 06:00:00,139.8337501809,35.9699615049,1,12,4313309,9 ,97,34,,97",
 "48300,1,1,1998/10/01 06:00:00,139.9299508295,35.759737216,2,4,4215402,4 ,97,44,,97",
 "55200,1,1,1998/10/01 06:00:00,139.9665348348,35.8247639876,1,10,4222006,9 ,97,38,,97",
 "80500,1,1,1998/10/01 06:00:00,140.1911095172,35.7154899827,1,14,4413233,5 ,97,33,,97",
 "62100,1,1,1998/10/01 06:00:00,140.0348949509,35.8675235612,1,7,4224202,9 ,97,37,,97",
 "78200,1,1,1998/10/01 06:00:00,140.1613857775,35.7304891245,1,12,4413008,8 ,97,44,,97",
 "41400,1,1,1998/10/01 06:00:00,139.913239261,35.826172383,2,5,4212212,8 ,97,44,,97",
 "197800,1,1,1998/10/01 06:00:00,140.186407742,35.9018768104,2,3,5421032,12,97,37,,97",
 "193200,1,1,1998/10/01 06:00:00,140.078774648,35.8889292148,2,5,5412112,9 ,97,35,,97",
 "209300,1,1,1998/10/01 06:00:00,139.6669400944,35.273399682,1,13,2211303,10,97,37,,97",
 "163300,1,1,1998/10/01 06:00:00,140.0717135875,35.6372374873,2,11,4061110,4 ,97,30,,97",
 "172500,1,1,1998/10/01 06:00:00,140.1796024632,36.0148681711,2,4,5024120,13,97,44,,97",
 "151800,1,1,1998/10/01 06:00:00,140.0963968735,35.6456780223,1,9,4031206,8 ,97,38,,97",
 "195500,1,1,1998/10/01 06:00:00,140.1426317263,35.9629493792,2,4,5420020,8 ,97,37,,97",
 "101200,1,1,1998/10/01 06:00:00,140.1612384829,35.5054387867,2,12,4512004,14,97,29,,97",
 "211600,1,1,1998/10/01 06:00:00,139.7332576635,35.2416716537,1,7,2220004,6 ,97,36,,97",
 "135700,1,1,1998/10/01 06:00:00,140.3172851739,35.4371623945,1,11,4920108,3 ,97,39,,97",
 "246100,1,1,1998/10/01 06:00:00,139.3599926932,35.3266591476,1,5,2510201,3 ,97,39,,97",
 "225400,1,1,1998/10/01 06:00:00,139.5210945386,35.343193018,1,3,2313201,13,97,36,,97",
 "253000,1,1,1998/10/01 06:00:00,139.7741435317,35.6791101942,1,8,2520301,9 ,97,30,,97",
 "230000,1,1,1998/10/01 06:00:00,139.7732587793,35.6191006131,1,8,2410601,7 ,97,15,,97",
 "227700,1,1,1998/10/01 06:00:00,139.4790659419,35.3181206636,1,9,2410203,8 ,97,16,,97",
 "234600,1,1,1998/10/01 06:00:00,139.4746581666,35.366292458,1,14,2412116,10,97,63,,97",
 "324300,1,1,1998/10/01 06:00:00,139.6202778635,35.5934633796,1,10,2110103,8 ,97,29,,97",
 "322000,1,1,1998/10/01 06:00:00,139.6395892094,35.5852213864,2,5,2032102,8 ,97,36,,97",
 "303600,1,1,1998/10/01 06:00:00,139.3712852358,35.576310105,1,9,2923016,9 ,97,30,,97",
 "333500,1,1,1998/10/01 06:00:00,139.5786941772,35.616744477,1,8,2130004,9 ,97,46,,97",
 "296700,1,1,1998/10/01 06:00:00,139.4267648324,35.5198973227,2,8,2920301,4 ,97,33,,97",
 "328900,1,1,1998/10/01 06:00:00,139.596110061,35.5785678626,2,8,2120102,9 ,97,28,,97",
 "310500,1,1,1998/10/01 06:00:00,139.7073772751,35.5210230796,1,9,2013102,3 ,97,37,,97",
 "301300,1,1,1998/10/01 06:00:00,139.4123256453,35.5125694918,1,11,2922005,9 ,97,29,,97",
 "345000,1,1,1998/10/01 06:00:00,139.7300545543,35.8057124921,1,8,3012002,9 ,97,37,,97",
 "358800,1,1,1998/10/01 06:00:00,139.6893724248,35.8102900546,2,5,3023001,9 ,97,35,,97",
 "340400,1,1,1998/10/01 06:00:00,139.5304942601,35.5953184501,2,5,2140202,9 ,97,41,,97",
 "365700,1,1,1998/10/01 06:00:00,139.671695328,35.867799522,1,10,3033223,10,97,38,,97",
 "386400,1,1,1998/10/01 06:00:00,139.6534207732,35.921470616,1,10,3059002,8 ,97,37,,97",
 "370300,1,1,1998/10/01 06:00:00,139.6690451358,35.8343815409,1,10,3037010,10,97,39,,97",
 "446200,1,1,1998/10/01 06:00:00,139.7760970545,35.8916441823,2,5,3221102,8 ,97,37,,97",
 "404800,1,1,1998/10/01 06:00:00,139.4705783302,35.7861877771,2,9,3120009,8 ,97,30,,97",
 "418600,1,1,1998/10/01 06:00:00,139.359333103,35.8523007423,1,11,3123503,8 ,97,23,,97",
 "425500,1,1,1998/10/01 06:00:00,139.4424380433,35.9229981851,1,4,3134008,8 ,97,45,,97",
 "391000,1,1,1998/10/01 06:00:00,139.6110550903,35.8126140275,1,7,3110313,8 ,97,33,,97",
 "437000,1,1,1998/10/01 06:00:00,139.8021254793,35.8235157865,1,7,3212001,5 ,97,36,,97",
 "501400,1,1,1998/10/01 06:00:00,139.6631606365,35.9711511286,2,2,3613122,12,97,33,,97",
 "466900,1,1,1998/10/01 06:00:00,139.3420486251,35.8520348277,2,5,3310206,4 ,97,42,,97",
 "476100,1,1,1998/10/01 06:00:00,139.4200871774,35.9720625021,1,3,3411213,13,97,34,,97",
 "508300,1,1,1998/10/01 06:00:00,139.6739567671,36.135370179,1,9,3622214,8 ,97,36,,97",
 "473800,1,1,1998/10/01 06:00:00,139.3883360363,35.9502529942,2,10,3411010,8 ,97,30,,97",
 "478400,1,1,1998/10/01 06:00:00,139.3813104658,36.0117418933,1,4,3413105,13,97,40,,97",
 "483000,1,1,1998/10/01 06:00:00,139.6160188631,35.9682412729,1,10,3510101,8 ,97,27,,97",
 "448500,1,1,1998/10/01 06:00:00,139.7850480953,35.9300494817,2,7,3222102,4 ,97,27,,97",
 "563500,1,1,1998/10/01 06:00:00,139.6983063243,35.725341541,1,7,0212001,10,97,84,,97",
 "540500,1,1,1998/10/01 06:00:00,139.6918401932,35.6348441216,1,6,0112008,5 ,97,54,,97",
 "584200,1,1,1998/10/01 06:00:00,139.8228726471,35.7138182507,2,6,0332001,9 ,97,49,,97",
 "616400,1,1,1998/10/01 06:00:00,139.659473225,35.6055455651,2,13,0433105,15,97,50,,97",
 "611800,1,1,1998/10/01 06:00:00,139.6707419564,35.6291649575,1,4,0431110,13,97,65,,97",
 "581900,1,1,1998/10/01 06:00:00,139.7858126214,35.7140504291,1,9,0321101,5 ,97,97,,97",
 "609500,1,1,1998/10/01 06:00:00,139.6624044338,35.6667821326,1,7,0430102,8 ,97,51,,97",
 "600300,1,1,1998/10/01 06:00:00,139.6417581399,35.7111415451,1,5,0420106,13,97,64,,97",
 "621000,1,1,1998/10/01 06:00:00,139.6490173686,35.6550128086,2,10,0436007,14,97,45,,97",
 "646300,1,1,1998/10/01 06:00:00,139.6658264053,35.7875616333,1,4,0523008,8 ,97,75,,97",
 "644000,1,1,1998/10/01 06:00:00,139.6686269659,35.7681715446,1,4,0522008,5 ,97,52,,97",
 "632500,1,1,1998/10/01 06:00:00,139.5842149722,35.7134791535,2,6,0513201,14,97,39,,97",
 "630200,1,1,1998/10/01 06:00:00,139.6071262625,35.7442697614,1,7,0513010,8 ,97,41,,97",
 "653200,1,1,1998/10/01 06:00:00,139.7209582266,35.7581187282,1,8,0531203,9 ,97,39,,97",
 "660100,1,1,1998/10/01 06:00:00,139.8035808516,35.7662374612,1,7,0612111,9 ,97,60,,97",
 "669300,1,1,1998/10/01 06:00:00,139.8677567037,35.7626181712,1,6,0620210,9 ,97,47,,97",
 "648600,1,1,1998/10/01 06:00:00,139.7387833431,35.7749034579,2,3,0530007,13,97,37,,97",
 "685400,1,1,1998/10/01 06:00:00,139.8844677663,35.6445465773,1,11,0634016,15,97,48,,97",
 "708400,1,1,1998/10/01 06:00:00,139.5400661286,35.7213295166,2,8,0731211,8 ,97,34,,97",
 "715300,1,1,1998/10/01 06:00:00,139.4669227588,35.653088761,1,5,0750217,9 ,97,49,,97",
 "696900,1,1,1998/10/01 06:00:00,139.592267455,35.6811359552,2,6,0720006,4 ,97,39,,97",
 "694600,3,5,1998/10/01 06:00:00,139.635722453,35.6396672087,2,3,0712004,13,97,46,,97",
 "683100,1,1,1998/10/01 06:00:00,139.9058411845,35.6947351827,2,3,0633106,12,97,49,,97",
 "706100,1,1,1998/10/01 06:00:00,139.5569500172,35.7180104109,1,14,0730209,10,97,26,,97",
 "676200,1,1,1998/10/01 06:00:00,139.8626597992,35.7436236238,1,13,0623211,10,97,35,,97",
 "692300,1,1,1998/10/01 06:00:00,139.5437930536,35.6480921047,2,4,0711108,8 ,97,25,,97",
 "719900,1,1,1998/10/01 06:00:00,139.4471001652,35.681734968,1,7,0752005,6 ,97,34,,97",
 "754400,1,1,1998/10/01 06:00:00,139.4695570613,35.5830524579,1,4,0811306,13,97,45,,97",
 "759000,1,1,1998/10/01 06:00:00,139.4301427404,35.6212940343,2,4,0821006,9 ,97,55,,97",
 "772800,1,1,1998/10/01 06:00:00,139.4023508645,35.6139759476,2,9,0842111,4 ,97,38,,97",
 "747500,1,1,1998/10/01 06:00:00,139.4638215271,35.5393844382,1,5,0810007,4 ,97,38,,97",
 "802700,1,1,1998/10/01 06:00:00,139.5899217483,35.4946236359,2,6,1425101,14,97,32,,97",
 "793500,1,1,1998/10/01 06:00:00,139.6472679683,35.5103775248,2,11,1414207,14,97,39,,97",
 "777400,1,1,1998/10/01 06:00:00,139.3472223265,35.6527162198,1,5,0844010,8 ,97,41,,97",
 "786600,1,1,1998/10/01 06:00:00,139.2954727141,35.7762544098,2,3,0930110,13,97,35,,97",
 "823400,1,1,1998/10/01 06:00:00,139.5799861271,35.4552929672,1,10,1213105,9 ,97,34,,97",
 "811900,1,1,1998/10/01 06:00:00,139.5994033728,35.4134175774,1,3,1123201,13,97,31,,97",
 "795800,1,1,1998/10/01 06:00:00,139.692754958,35.5079084537,1,6,1412027,9 ,97,47,,97",
 "280600,2,2,1998/10/01 06:01:00,139.4624232862,35.4685641751,1,11,2910009,8 ,8 ,42,,6",
 "138000,2,2,1998/10/01 06:01:00,140.3867467639,35.4405298133,1,9,4922013,9 ,1 ,36,,6",
 "280600,2,2,1998/10/01 06:02:00,139.4579999896,35.4684367953,1,11,2910009,8 ,8 ,42,,6",
 "138000,2,2,1998/10/01 06:02:00,140.3847324442,35.4406009052,1,9,4922013,9 ,1 ,36,,6",
 "280600,2,2,1998/10/01 06:03:00,139.4535815239,35.4682098239,1,11,2910009,8 ,8 ,42,,6",
 "138000,2,2,1998/10/01 06:03:00,140.3827181205,35.4406719634,1,9,4922013,9 ,1 ,36,,6",
 "280600,2,2,1998/10/01 06:04:00,139.4492909791,35.4688937026,1,11,2910009,8 ,8 ,42,,6",
 "138000,2,2,1998/10/01 06:04:00,140.3814141367,35.4400139644,1,9,4922013,9 ,1 ,36,,6",
 "280600,2,2,1998/10/01 06:05:00,139.4449243204,35.468955417,1,11,2910009,8 ,8 ,42,,6",
 "138000,2,2,1998/10/01 06:05:00,140.3806333718,35.4390017226,1,9,4922013,9 ,1 ,36,,6",
 "280600,2,2,1998/10/01 06:06:00,139.4407792257,35.4688373944,1,11,2910009,8 ,8 ,42,,6",
 "138000,2,2,1998/10/01 06:06:00,140.3786253619,35.4391041508,1,9,4922013,9 ,1 ,36,,6",
 "430100,4,4,1998/10/01 06:06:00,139.4147594236,35.8590037526,1,14,3135204,15,3 ,33,,6",
 "135700,2,2,1998/10/01 06:06:00,140.316801751,35.4380832306,1,11,4920108,3 ,1 ,39,,1",
 "195500,2,2,1998/10/01 06:06:00,140.1409126433,35.9626723337,2,4,5420020,8 ,1 ,37,,2",
 "280600,2,2,1998/10/01 06:07:00,139.4371971899,35.4672192953,1,11,2910009,8 ,8 ,42,,6",
 "138000,2,2,1998/10/01 06:07:00,140.3766212437,35.439284087,1,9,4922013,9 ,1 ,36,,6",
 "430100,4,4,1998/10/01 06:07:00,139.4118811498,35.8580815763,1,14,3135204,15,3 ,33,,6",
 "135700,2,2,1998/10/01 06:07:00,140.3167317742,35.4365017496,1,11,4920108,3 ,1 ,39,,1",
 "195500,2,2,1998/10/01 06:07:00,140.1401938336,35.9635521161,2,4,5420020,8 ,1 ,37,,2",
 "280600,2,2,1998/10/01 06:08:00,139.4332930356,35.4669360218,1,11,2910009,8 ,8 ,42,,6",
 "138000,2,2,1998/10/01 06:08:00,140.3746185662,35.439359796,1,9,4922013,9 ,1 ,36,,6",
 "430100,4,4,1998/10/01 06:08:00,139.408748742,35.859314885,1,14,3135204,15,3 ,33,,6",
 "135700,2,2,1998/10/01 06:08:00,140.3167428977,35.4367659411,1,11,4920108,3 ,1 ,39,,1",
 "195500,2,2,1998/10/01 06:08:00,140.1403352762,35.9650312258,2,4,5420020,8 ,1 ,37,,2",
 "280600,2,2,1998/10/01 06:09:00,139.4288733295,35.4667370097,1,11,2910009,8 ,8 ,42,,6",
 "138000,2,2,1998/10/01 06:09:00,140.3739496048,35.4381204116,1,9,4922013,9 ,1 ,36,,6",
 "430100,4,4,1998/10/01 06:09:00,139.4057474034,35.8594271732,1,14,3135204,15,3 ,33,,6",
 "135700,2,2,1998/10/01 06:09:00,140.3168119062,35.4383474506,1,11,4920108,3 ,1 ,39,,1",
 "195500,2,2,1998/10/01 06:09:00,140.1394318871,35.9657598793,2,4,5420020,8 ,1 ,37,,2",
 "280600,2,2,1998/10/01 06:10:00,139.426035553,35.4674906373,1,11,2910009,8 ,8 ,42,,6",
 "280600,3,3,1998/10/01 06:10:00,139.426035553,35.4674906373,1,11,2910009,8 ,1 ,42,,8",
 "138000,2,2,1998/10/01 06:10:00,140.3737806017,35.4364762417,1,9,4922013,9 ,1 ,36,,6",
 "430100,4,4,1998/10/01 06:10:00,139.4027405518,35.8609362174,1,14,3135204,15,3 ,33,,6",
 "135700,2,2,1998/10/01 06:10:00,140.3168809178,35.4399289596,1,11,4920108,3 ,1 ,39,,1",
 "195500,2,2,1998/10/01 06:10:00,140.1394199642,35.9672434359,2,4,5420020,8 ,1 ,37,,2",
 "280600,3,3,1998/10/01 06:11:00,139.4286683579,35.4690208622,1,11,2910009,8 ,1 ,42,,8",
 "138000,2,2,1998/10/01 06:11:00,140.3731537097,35.4349250114,1,9,4922013,9 ,1 ,36,,6",
 "430100,4,4,1998/10/01 06:11:00,139.3998058663,35.862177653,1,14,3135204,15,3 ,33,,6",
 "135700,2,2,1998/10/01 06:11:00,140.3169499324,35.4415104681,1,11,4920108,3 ,1 ,39,,1",
 "195500,2,2,1998/10/01 06:11:00,140.139408041,35.9687269921,2,4,5420020,8 ,1 ,37,,2",
 "280600,3,3,1998/10/01 06:12:00,139.4313938405,35.4714255294,1,11,2910009,8 ,1 ,42,,8",
 "138000,2,2,1998/10/01 06:12:00,140.3722160038,35.4338864643,1,9,4922013,9 ,1 ,36,,6",
 "430100,4,4,1998/10/01 06:12:00,139.3965031438,35.8615007307,1,14,3135204,15,3 ,33,,6",
 "135700,2,2,1998/10/01 06:12:00,140.3169030864,35.4430048001,1,11,4920108,3 ,1 ,39,,1",
 "195500,2,2,1998/10/01 06:12:00,140.1393961177,35.9702105479,2,4,5420020,8 ,1 ,37,,2",
 "280600,3,3,1998/10/01 06:13:00,139.4318526779,35.475433739,1,11,2910009,8 ,1 ,42,,8",
 "138000,2,2,1998/10/01 06:13:00,140.3702303678,35.4341527579,1,9,4922013,9 ,1 ,36,,6",
 "430100,4,4,1998/10/01 06:13:00,139.3932965029,35.8610442725,1,14,3135204,15,3 ,33,,6",
 "135700,2,2,1998/10/01 06:13:00,140.3149713627,35.4430780509,1,11,4920108,3 ,1 ,39,,1",
 "195500,2,2,1998/10/01 06:13:00,140.139384194,35.9716941034,2,4,5420020,8 ,1 ,37,,2",
 "280600,3,3,1998/10/01 06:14:00,139.4357909149,35.4780563622,1,11,2910009,8 ,1 ,42,,8",
 "138000,2,2,1998/10/01 06:14:00,140.3682249813,35.434104131,1,9,4922013,9 ,1 ,36,,6",
 "430100,4,4,1998/10/01 06:14:00,139.3911472877,35.8625433623,1,14,3135204,15,3 ,33,,6",
 "135700,2,2,1998/10/01 06:14:00,140.3130393159,35.443140362,1,11,4920108,3 ,1 ,39,,1",
 "195500,2,2,1998/10/01 06:14:00,140.1393722702,35.9731776585,2,4,5420020,8 ,1 ,37,,2",
 "280600,3,3,1998/10/01 06:15:00,139.4394799477,35.4795875352,1,11,2910009,8 ,1 ,42,,8",
 "138000,2,2,1998/10/01 06:15:00,140.3662794904,35.4339459032,1,9,4922013,9 ,1 ,36,,6",
 "430100,5,5,1998/10/01 06:15:00,139.3916955301,35.8643946486,1,14,3135204,15,97,33,,97",
 "430100,4,4,1998/10/01 06:15:00,139.3916955301,35.8643946486,1,14,3135204,15,3 ,33,,6",
 "135700,2,2,1998/10/01 06:15:00,140.3129029392,35.4445793056,1,11,4920108,3 ,1 ,39,,1",
 "195500,2,2,1998/10/01 06:15:00,140.1393603461,35.9746612133,2,4,5420020,8 ,1 ,37,,2",
 "280600,3,3,1998/10/01 06:16:00,139.4414471061,35.4833983875,1,11,2910009,8 ,1 ,42,,8",
 "138000,2,2,1998/10/01 06:16:00,140.364300629,35.4342612698,1,9,4922013,9 ,1 ,36,,6",
 "55200,2,2,1998/10/01 06:16:00,139.9637854535,35.8257397324,1,10,4222006,9 ,1 ,38,,2",
 "135700,2,2,1998/10/01 06:16:00,140.3128797742,35.4461616472,1,11,4920108,3 ,1 ,39,,1",
 "71300,2,2,1998/10/01 06:16:00,139.8351980575,35.9705627883,1,12,4313309,9 ,1 ,34,,1",
 "195500,2,2,1998/10/01 06:16:00,140.1409159677,35.9745696343,2,4,5420020,8 ,1 ,37,,2",
 "195500,2,3,1998/10/01 06:16:00,140.1409159677,35.9745696343,2,4,5420020,8 ,1 ,37,,12",
 "280600,3,3,1998/10/01 06:17:00,139.4454320583,35.4859669554,1,11,2910009,8 ,1 ,42,,8",
 "138000,2,2,1998/10/01 06:17:00,140.3623251169,35.4345904611,1,9,4922013,9 ,1 ,36,,6",
 "55200,2,2,1998/10/01 06:17:00,139.9609734102,35.8265715164,1,10,4222006,9 ,1 ,38,,2",
 "135700,2,2,1998/10/01 06:17:00,140.3123275468,35.4473824683,1,11,4920108,3 ,1 ,39,,1",
 "71300,2,2,1998/10/01 06:17:00,139.8351355103,35.971900685,1,12,4313309,9 ,1 ,34,,1",
 "195500,2,3,1998/10/01 06:17:00,140.1404068541,35.9687594008,2,4,5420020,8 ,1 ,37,,12",
 "280600,3,3,1998/10/01 06:18:00,139.4493689245,35.4885658707,1,11,2910009,8 ,1 ,42,,8",
 "138000,2,2,1998/10/01 06:18:00,140.3603424357,35.4347785832,1,9,4922013,9 ,1 ,36,,6",
 "55200,2,2,1998/10/01 06:18:00,139.9589819622,35.8280779706,1,10,4222006,9 ,1 ,38,,2",
 "135700,2,2,1998/10/01 06:18:00,140.3104471425,35.4474562666,1,11,4920108,3 ,1 ,39,,1",
 "71300,2,2,1998/10/01 06:18:00,139.8349663771,35.9732307523,1,12,4313309,9 ,1 ,34,,1",
 "195500,2,3,1998/10/01 06:18:00,140.1398519532,35.9629513033,2,4,5420020,8 ,1 ,37,,12",
 "280600,3,3,1998/10/01 06:19:00,139.4531717465,35.491216579,1,11,2910009,8 ,1 ,42,,8",
 "138000,2,2,1998/10/01 06:19:00,140.3583270563,35.4347359989,1,9,4922013,9 ,1 ,36,,6",
 "55200,2,2,1998/10/01 06:19:00,139.9581388585,35.8304208415,1,10,4222006,9 ,1 ,38,,2",
 "135700,2,2,1998/10/01 06:19:00,140.3102684993,35.4490316529,1,11,4920108,3 ,1 ,39,,1",
 "71300,2,2,1998/10/01 06:19:00,139.8345657347,35.9745283085,1,12,4313309,9 ,1 ,34,,1",
 "195500,2,3,1998/10/01 06:19:00,140.1392971366,35.9571431973,2,4,5420020,8 ,1 ,37,,12",
 "280600,3,3,1998/10/01 06:20:00,139.4559661225,35.4946650688,1,11,2910009,8 ,1 ,42,,8",
 "138000,2,2,1998/10/01 06:20:00,140.3563116311,35.4346948432,1,9,4922013,9 ,1 ,36,,6",
 "71300,2,2,1998/10/01 06:20:00,139.8341023136,35.9758131247,1,12,4313309,9 ,1 ,34,,1",
 "55200,2,2,1998/10/01 06:20:00,139.9572234936,35.8327467735,1,10,4222006,9 ,1 ,38,,2",
 "195500,2,3,1998/10/01 06:20:00,140.1387456975,35.9513348755,2,4,5420020,8 ,1 ,37,,12",
 "135700,2,2,1998/10/01 06:20:00,140.3083767407,35.4491541023,1,11,4920108,3 ,1 ,39,,1",
 "280600,3,3,1998/10/01 06:21:00,139.4592495528,35.497805636,1,11,2910009,8 ,1 ,42,,8",
 "138000,2,2,1998/10/01 06:21:00,140.3542964696,35.4346458433,1,9,4922013,9 ,1 ,36,,6",
 "55200,2,2,1998/10/01 06:21:00,139.9564673685,35.8351025874,1,10,4222006,9 ,1 ,38,,2",
 "135700,2,2,1998/10/01 06:21:00,140.3075589025,35.4501018439,1,11,4920108,3 ,1 ,39,,1",
 "71300,2,2,1998/10/01 06:21:00,139.8336422744,35.9770979995,1,12,4313309,9 ,1 ,34,,1",
 "195500,2,3,1998/10/01 06:21:00,140.1382035534,35.9455259742,2,4,5420020,8 ,1 ,37,,12",
 "280600,3,3,1998/10/01 06:22:00,139.4626527286,35.500750732,1,11,2910009,8 ,1 ,42,,8",
 "138000,2,2,1998/10/01 06:22:00,140.3522817136,35.4345882776,1,9,4922013,9 ,1 ,36,,6",
 "55200,2,2,1998/10/01 06:22:00,139.9561107613,35.8375247993,1,10,4222006,9 ,1 ,38,,2",
 "135700,2,2,1998/10/01 06:22:00,140.3056249526,35.4501122073,1,11,4920108,3 ,1 ,39,,1",
 "71300,2,2,1998/10/01 06:22:00,139.8344126331,35.9779840164,1,12,4313309,9 ,1 ,34,,1",
 "195500,2,3,1998/10/01 06:22:00,140.1382046622,35.9397017112,2,4,5420020,8 ,1 ,37,,12",
 "280600,3,3,1998/10/01 06:23:00,139.4676383574,35.5013912,1,11,2910009,8 ,1 ,42,,8",
 "138000,2,2,1998/10/01 06:23:00,140.3502663293,35.4345486364,1,9,4922013,9 ,1 ,36,,6",
 "55200,2,2,1998/10/01 06:23:00,139.9552669819,35.8398281865,1,10,4222006,9 ,1 ,38,,2",
 "135700,2,2,1998/10/01 06:23:00,140.3036910019,35.4501225398,1,11,4920108,3 ,1 ,39,,1",
 "71300,2,2,1998/10/01 06:23:00,139.8347767171,35.9792547668,1,12,4313309,9 ,1 ,34,,1",
 "71300,2,3,1998/10/01 06:23:00,139.8347767171,35.9792547668,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 06:23:00,140.1382781475,35.9338763997,2,4,5420020,8 ,1 ,37,,12",
 "280600,3,3,1998/10/01 06:24:00,139.4709792933,35.5042919502,1,11,2910009,8 ,1 ,42,,8",
 "138000,2,2,1998/10/01 06:24:00,140.3482507966,35.4345115146,1,9,4922013,9 ,1 ,36,,6",
 "55200,2,2,1998/10/01 06:24:00,139.9546312425,35.8421878543,1,10,4222006,9 ,1 ,38,,2",
 "135700,2,2,1998/10/01 06:24:00,140.3030512075,35.4509931462,1,11,4920108,3 ,1 ,39,,1",
 "71300,2,3,1998/10/01 06:24:00,139.8301811068,35.9792112403,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 06:24:00,140.1372651063,35.9281970512,2,4,5420020,8 ,1 ,37,,12",
 "280600,3,3,1998/10/01 06:25:00,139.473919602,35.5076799963,1,11,2910009,8 ,1 ,42,,8",
 "138000,2,2,1998/10/01 06:25:00,140.3462354634,35.4344788926,1,9,4922013,9 ,1 ,36,,6",
 "55200,2,2,1998/10/01 06:25:00,139.9542439829,35.8446098689,1,10,4222006,9 ,1 ,38,,2",
 "55200,2,3,1998/10/01 06:25:00,139.9542439829,35.8446098689,1,10,4222006,9 ,1 ,38,,12",
 "135700,2,2,1998/10/01 06:25:00,140.3012316944,35.4513363467,1,11,4920108,3 ,1 ,39,,1",
 "71300,2,3,1998/10/01 06:25:00,139.8255583139,35.9796383161,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 06:25:00,140.1321765388,35.9241797981,2,4,5420020,8 ,1 ,37,,12",
 "280600,3,3,1998/10/01 06:26:00,139.4768944256,35.5110483772,1,11,2910009,8 ,1 ,42,,8",
 "138000,2,2,1998/10/01 06:26:00,140.3443418373,35.4350311544,1,9,4922013,9 ,1 ,36,,6",
 "55200,2,3,1998/10/01 06:26:00,139.9598030821,35.8486389531,1,10,4222006,9 ,1 ,38,,12",
 "135700,2,2,1998/10/01 06:26:00,140.2999005365,35.4509177858,1,11,4920108,3 ,1 ,39,,1",
 "71300,2,3,1998/10/01 06:26:00,139.820991903,35.9803763938,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 06:26:00,140.1255096779,35.9221406865,2,4,5420020,8 ,1 ,37,,12",
 "280600,3,3,1998/10/01 06:27:00,139.4801557761,35.5142084958,1,11,2910009,8 ,1 ,42,,8",
 "138000,2,2,1998/10/01 06:27:00,140.3424591261,35.435621288,1,9,4922013,9 ,1 ,36,,6",
 "55200,2,3,1998/10/01 06:27:00,139.9642341539,35.8535917677,1,10,4222006,9 ,1 ,38,,12",
 "135700,2,2,1998/10/01 06:27:00,140.2997850123,35.4496625155,1,11,4920108,3 ,1 ,39,,1",
 "135700,2,3,1998/10/01 06:27:00,140.2997850123,35.4496625155,1,11,4920108,3 ,1 ,39,,12",
 "71300,2,3,1998/10/01 06:27:00,139.8164227797,35.9810951684,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 06:27:00,140.1185811709,35.9206656481,2,4,5420020,8 ,1 ,37,,12",
 "280600,3,3,1998/10/01 06:28:00,139.4829004444,35.5177047342,1,11,2910009,8 ,1 ,42,,8",
 "138000,2,2,1998/10/01 06:28:00,140.3405406626,35.4360773147,1,9,4922013,9 ,1 ,36,,6",
 "55200,2,3,1998/10/01 06:28:00,139.968299198,35.8587622714,1,10,4222006,9 ,1 ,38,,12",
 "135700,2,3,1998/10/01 06:28:00,140.301442947,35.4561693999,1,11,4920108,3 ,1 ,39,,12",
 "71300,2,3,1998/10/01 06:28:00,139.811781476,35.9809104419,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 06:28:00,140.1116438621,35.919218291,2,4,5420020,8 ,1 ,37,,12",
 "280600,3,3,1998/10/01 06:29:00,139.4861718224,35.5208399638,1,11,2910009,8 ,1 ,42,,8",
 "138000,2,2,1998/10/01 06:29:00,140.3385245975,35.4360691008,1,9,4922013,9 ,1 ,36,,6",
 "55200,2,3,1998/10/01 06:29:00,139.9694736985,35.8602560171,1,10,4222006,9 ,1 ,38,,12",
 "135700,2,3,1998/10/01 06:29:00,140.3031309325,35.4626954428,1,11,4920108,3 ,1 ,39,,12",
 "71300,2,3,1998/10/01 06:29:00,139.8071651016,35.9804218918,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 06:29:00,140.1047127997,35.9177514537,2,4,5420020,8 ,1 ,37,,12",
 "280600,3,3,1998/10/01 06:30:00,139.4898701738,35.5236897114,1,11,2910009,8 ,1 ,42,,8",
 "138000,2,2,1998/10/01 06:30:00,140.3365085811,35.4360551584,1,9,4922013,9 ,1 ,36,,6",
 "55200,2,3,1998/10/01 06:30:00,139.9654085017,35.8550855548,1,10,4222006,9 ,1 ,38,,12",
 "71300,2,3,1998/10/01 06:30:00,139.8025728261,35.9797987283,1,12,4313309,9 ,1 ,34,,12",
 "135700,2,3,1998/10/01 06:30:00,140.3048242472,35.4692232764,1,11,4920108,3 ,1 ,39,,12",
 "195500,2,3,1998/10/01 06:30:00,140.0978098702,35.9162081174,2,4,5420020,8 ,1 ,37,,12",
 "280600,3,3,1998/10/01 06:31:00,139.4937190573,35.526388453,1,11,2910009,8 ,1 ,42,,8",
 "138000,2,2,1998/10/01 06:31:00,140.334492689,35.4360612414,1,9,4922013,9 ,1 ,36,,6",
 "664700,2,2,1998/10/01 06:31:00,139.7590537717,35.7798833412,1,11,0614018,10,1 ,57,,2",
 "55200,2,3,1998/10/01 06:31:00,139.961232962,35.8499764305,1,10,4222006,9 ,1 ,38,,12",
 "135700,2,3,1998/10/01 06:31:00,140.3063902747,35.4757701069,1,11,4920108,3 ,1 ,39,,12",
 "71300,2,3,1998/10/01 06:31:00,139.7979481979,35.9797137507,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 06:31:00,140.0918792193,35.9130464406,2,4,5420020,8 ,1 ,37,,12",
 "386400,2,2,1998/10/01 06:31:00,139.6534194764,35.92146969,1,10,3059002,8 ,1 ,37,,1",
 "777400,2,2,1998/10/01 06:31:00,139.3472469092,35.6498161365,1,5,0844010,8 ,1 ,41,,1",
 "280600,3,3,1998/10/01 06:32:00,139.4985183726,35.5277189554,1,11,2910009,8 ,1 ,42,,8",
 "138000,2,2,1998/10/01 06:32:00,140.3324767366,35.4360795574,1,9,4922013,9 ,1 ,36,,6",
 "664700,2,2,1998/10/01 06:32:00,139.758244302,35.7799232759,1,11,0614018,10,1 ,57,,2",
 "55200,2,3,1998/10/01 06:32:00,139.9559346574,35.8456346734,1,10,4222006,9 ,1 ,38,,12",
 "135700,2,3,1998/10/01 06:32:00,140.3075917261,35.4823703951,1,11,4920108,3 ,1 ,39,,12",
 "71300,2,3,1998/10/01 06:32:00,139.7933220824,35.9801405776,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 06:32:00,140.086379296,35.9093155479,2,4,5420020,8 ,1 ,37,,12",
 "386400,2,2,1998/10/01 06:32:00,139.6534181797,35.9214687641,1,10,3059002,8 ,1 ,37,,1",
 "386400,2,3,1998/10/01 06:32:00,139.6534181797,35.9214687641,1,10,3059002,8 ,1 ,37,,10",
 "777400,2,2,1998/10/01 06:32:00,139.3441637249,35.6532695965,1,5,0844010,8 ,1 ,41,,1",
 "280600,3,3,1998/10/01 06:33:00,139.5025645845,35.5301209779,1,11,2910009,8 ,1 ,42,,8",
 "138000,2,2,1998/10/01 06:33:00,140.3304607265,35.4360944359,1,9,4922013,9 ,1 ,36,,6",
 "664700,2,2,1998/10/01 06:33:00,139.7582968777,35.780576667,1,11,0614018,10,1 ,57,,2",
 "55200,2,3,1998/10/01 06:33:00,139.949541546,35.8423839903,1,10,4222006,9 ,1 ,38,,12",
 "71300,2,3,1998/10/01 06:33:00,139.7886964234,35.9805690309,1,12,4313309,9 ,1 ,34,,12",
 "135700,2,3,1998/10/01 06:33:00,140.308755574,35.4889749176,1,11,4920108,3 ,1 ,39,,12",
 "195500,2,3,1998/10/01 06:33:00,140.0808801603,35.9055841341,2,4,5420020,8 ,1 ,37,,12",
 "386400,2,3,1998/10/01 06:33:00,139.6525259048,35.9215922113,1,10,3059002,8 ,1 ,37,,10",
 "777400,2,2,1998/10/01 06:33:00,139.3438308539,35.6574795518,1,5,0844010,8 ,1 ,41,,1",
 "777400,2,3,1998/10/01 06:33:00,139.3438308539,35.6574795518,1,5,0844010,8 ,1 ,41,,12",
 "280600,3,3,1998/10/01 06:34:00,139.5062216317,35.5330041946,1,11,2910009,8 ,1 ,42,,8",
 "138000,2,2,1998/10/01 06:34:00,140.3297972614,35.4349923863,1,9,4922013,9 ,1 ,36,,6",
 "664700,2,2,1998/10/01 06:34:00,139.7574913224,35.7806415162,1,11,0614018,10,1 ,57,,2",
 "55200,2,3,1998/10/01 06:34:00,139.9430626051,35.8392446119,1,10,4222006,9 ,1 ,38,,12",
 "135700,2,3,1998/10/01 06:34:00,140.3099204441,35.4955783349,1,11,4920108,3 ,1 ,39,,12",
 "71300,2,3,1998/10/01 06:34:00,139.7840493224,35.9806106938,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 06:34:00,140.0753792486,35.9018547099,2,4,5420020,8 ,1 ,37,,12",
 "386400,2,3,1998/10/01 06:34:00,139.6499066661,35.9202088146,1,10,3059002,8 ,1 ,37,,10",
 "777400,2,3,1998/10/01 06:34:00,139.3465637076,35.6548555596,1,5,0844010,8 ,1 ,41,,12",
 "280600,3,3,1998/10/01 06:35:00,139.5098237911,35.5359353852,1,11,2910009,8 ,1 ,42,,8",
 "138000,2,2,1998/10/01 06:35:00,140.3297163625,35.4333439266,1,9,4922013,9 ,1 ,36,,6",
 "664700,2,2,1998/10/01 06:35:00,139.7566798923,35.7806801091,1,11,0614018,10,1 ,57,,2",
 "55200,2,3,1998/10/01 06:35:00,139.9366012195,35.8360824572,1,10,4222006,9 ,1 ,38,,12",
 "135700,2,3,1998/10/01 06:35:00,140.3110616748,35.5021857748,1,11,4920108,3 ,1 ,39,,12",
 "71300,2,3,1998/10/01 06:35:00,139.7793996773,35.9804166609,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 06:35:00,140.0687551837,35.9001592569,2,4,5420020,8 ,1 ,37,,12",
 "386400,2,3,1998/10/01 06:35:00,139.647289873,35.9188215617,1,10,3059002,8 ,1 ,37,,10",
 "777400,2,3,1998/10/01 06:35:00,139.3478400002,35.651434916,1,5,0844010,8 ,1 ,41,,12",
 "280600,3,3,1998/10/01 06:36:00,139.5133854441,35.5388978092,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,2,1998/10/01 06:36:00,139.6547622886,35.8703161152,1,6,3032201,3 ,8 ,34,,1",
 "138000,2,2,1998/10/01 06:36:00,140.3295957245,35.4316969075,1,9,4922013,9 ,1 ,36,,6",
 "441600,2,2,1998/10/01 06:36:00,139.8885905658,35.8457969304,1,9,3215009,9 ,1 ,30,,8",
 "664700,2,2,1998/10/01 06:36:00,139.7558706968,35.7807400659,1,11,0614018,10,1 ,57,,2",
 "55200,2,3,1998/10/01 06:36:00,139.9301585107,35.8328959116,1,10,4222006,9 ,1 ,38,,12",
 "71300,2,3,1998/10/01 06:36:00,139.7748288625,35.9798235175,1,12,4313309,9 ,1 ,34,,12",
 "135700,2,3,1998/10/01 06:36:00,140.3128608493,35.508671743,1,11,4920108,3 ,1 ,39,,12",
 "195500,2,3,1998/10/01 06:36:00,140.0635834048,35.8966459178,2,4,5420020,8 ,1 ,37,,12",
 "234600,2,2,1998/10/01 06:36:00,139.4744278368,35.3658770091,1,14,2412116,10,1 ,63,,3",
 "386400,2,3,1998/10/01 06:36:00,139.6446784761,35.9174275848,1,10,3059002,8 ,1 ,37,,10",
 "777400,2,3,1998/10/01 06:36:00,139.3490448984,35.6479968439,1,5,0844010,8 ,1 ,41,,12",
 "280600,3,3,1998/10/01 06:37:00,139.5177376,35.541030219,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,2,1998/10/01 06:37:00,139.6547587756,35.8703145871,1,6,3032201,3 ,8 ,34,,1",
 "363400,2,3,1998/10/01 06:37:00,139.6547587756,35.8703145871,1,6,3032201,3 ,8 ,34,,6",
 "138000,2,2,1998/10/01 06:37:00,140.3293239447,35.4300642564,1,9,4922013,9 ,1 ,36,,6",
 "441600,2,2,1998/10/01 06:37:00,139.8905114841,35.845721089,1,9,3215009,9 ,1 ,30,,8",
 "664700,2,2,1998/10/01 06:37:00,139.7550609276,35.7808022332,1,11,0614018,10,1 ,57,,2",
 "55200,2,3,1998/10/01 06:37:00,139.9239663579,35.8294421346,1,10,4222006,9 ,1 ,38,,12",
 "135700,2,3,1998/10/01 06:37:00,140.31328025,35.5153026963,1,11,4920108,3 ,1 ,39,,12",
 "71300,2,3,1998/10/01 06:37:00,139.7705753392,35.9783099503,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 06:37:00,140.0600344558,35.8915876567,2,4,5420020,8 ,1 ,37,,12",
 "234600,2,2,1998/10/01 06:37:00,139.4741975092,35.3654615598,1,14,2412116,10,1 ,63,,3",
 "386400,2,3,1998/10/01 06:37:00,139.642067171,35.9160335511,1,10,3059002,8 ,1 ,37,,10",
 "777400,2,3,1998/10/01 06:37:00,139.3518786641,35.6453960956,1,5,0844010,8 ,1 ,41,,12",
 "280600,3,3,1998/10/01 06:38:00,139.5221699055,35.5430640163,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 06:38:00,139.6525564334,35.8696612294,1,6,3032201,3 ,8 ,34,,6",
 "138000,2,2,1998/10/01 06:38:00,140.3289478721,35.4284432334,1,9,4922013,9 ,1 ,36,,6",
 "441600,2,2,1998/10/01 06:38:00,139.892431127,35.8456267462,1,9,3215009,9 ,1 ,30,,8",
 "664700,2,2,1998/10/01 06:38:00,139.7542493068,35.780844956,1,11,0614018,10,1 ,57,,2",
 "55200,2,3,1998/10/01 06:38:00,139.9206904994,35.8239996974,1,10,4222006,9 ,1 ,38,,12",
 "135700,2,3,1998/10/01 06:38:00,140.3108114654,35.5211212261,1,11,4920108,3 ,1 ,39,,12",
 "71300,2,3,1998/10/01 06:38:00,139.7668844167,35.9760125993,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 06:38:00,140.0566190751,35.8864677596,2,4,5420020,8 ,1 ,37,,12",
 "234600,2,2,1998/10/01 06:38:00,139.4739671841,35.36504611,1,14,2412116,10,1 ,63,,3",
 "386400,2,3,1998/10/01 06:38:00,139.6394559577,35.9146394606,1,10,3059002,8 ,1 ,37,,10",
 "777400,2,3,1998/10/01 06:38:00,139.3558091811,35.6438163255,1,5,0844010,8 ,1 ,41,,12",
 "280600,3,3,1998/10/01 06:39:00,139.5265625688,35.5451548297,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 06:39:00,139.6526325876,35.8675485105,1,6,3032201,3 ,8 ,34,,6",
 "138000,2,2,1998/10/01 06:39:00,140.328605639,35.4268174735,1,9,4922013,9 ,1 ,36,,6",
 "441600,2,2,1998/10/01 06:39:00,139.8943479038,35.8455230951,1,9,3215009,9 ,1 ,30,,8",
 "664700,2,2,1998/10/01 06:39:00,139.7534374576,35.7808852814,1,11,0614018,10,1 ,57,,2",
 "55200,2,3,1998/10/01 06:39:00,139.9189809197,35.8180212515,1,10,4222006,9 ,1 ,38,,12",
 "135700,2,3,1998/10/01 06:39:00,140.3043340694,35.5246312785,1,11,4920108,3 ,1 ,39,,12",
 "71300,2,3,1998/10/01 06:39:00,139.7625728897,35.9748483064,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 06:39:00,140.0533926775,35.8812702576,2,4,5420020,8 ,1 ,37,,12",
 "234600,2,2,1998/10/01 06:39:00,139.4737368612,35.3646306597,1,14,2412116,10,1 ,63,,3",
 "386400,2,3,1998/10/01 06:39:00,139.6368872281,35.9131944622,1,10,3059002,8 ,1 ,37,,10",
 "777400,2,3,1998/10/01 06:39:00,139.3599884753,35.642942443,1,5,0844010,8 ,1 ,41,,12",
 "280600,3,3,1998/10/01 06:40:00,139.5309252312,35.5472878212,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 06:40:00,139.6515909531,35.8659929054,1,6,3032201,3 ,8 ,34,,6",
 "138000,2,2,1998/10/01 06:40:00,140.3281749435,35.4252198622,1,9,4922013,9 ,1 ,36,,6",
 "441600,2,2,1998/10/01 06:40:00,139.8960363648,35.8447748765,1,9,3215009,9 ,1 ,30,,8",
 "664700,2,2,1998/10/01 06:40:00,139.7534995553,35.7810645902,1,11,0614018,10,1 ,57,,2",
 "664700,3,3,1998/10/01 06:40:00,139.7534995553,35.7810645902,1,11,0614018,10,97,57,,97",
 "71300,2,3,1998/10/01 06:40:00,139.7589696862,35.9769150441,1,12,4313309,9 ,1 ,34,,12",
 "55200,2,3,1998/10/01 06:40:00,139.9174088059,35.8120179737,1,10,4222006,9 ,1 ,38,,12",
 "135700,2,3,1998/10/01 06:40:00,140.2983247353,35.529092904,1,11,4920108,3 ,1 ,39,,12",
 "195500,2,3,1998/10/01 06:40:00,140.0501799186,35.8760702501,2,4,5420020,8 ,1 ,37,,12",
 "234600,2,2,1998/10/01 06:40:00,139.4735065407,35.364215209,1,14,2412116,10,1 ,63,,3",
 "386400,2,3,1998/10/01 06:40:00,139.6343180711,35.9117496988,1,10,3059002,8 ,1 ,37,,10",
 "777400,2,3,1998/10/01 06:40:00,139.3643677941,35.6428411983,1,5,0844010,8 ,1 ,41,,12",
 "280600,3,3,1998/10/01 06:41:00,139.5353234324,35.5493707097,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 06:41:00,139.6502232696,35.8647776254,1,6,3032201,3 ,8 ,34,,6",
 "138000,2,2,1998/10/01 06:41:00,140.3269929675,35.4238843907,1,9,4922013,9 ,1 ,36,,6",
 "441600,2,2,1998/10/01 06:41:00,139.8978944373,35.8444484878,1,9,3215009,9 ,1 ,30,,8",
 "864800,2,2,1998/10/01 06:41:00,139.5246479695,35.4218830754,1,6,1240102,10,1 ,27,,3",
 "55200,2,3,1998/10/01 06:41:00,139.9156082522,35.8060606741,1,10,4222006,9 ,1 ,38,,12",
 "71300,2,3,1998/10/01 06:41:00,139.7552328481,35.9790549922,1,12,4313309,9 ,1 ,34,,12",
 "135700,2,3,1998/10/01 06:41:00,140.2906572054,35.529550104,1,11,4920108,3 ,1 ,39,,12",
 "195500,2,3,1998/10/01 06:41:00,140.0439086424,35.8737258661,2,4,5420020,8 ,1 ,37,,12",
 "234600,2,2,1998/10/01 06:41:00,139.4732762226,35.3637997578,1,14,2412116,10,1 ,63,,3",
 "386400,2,3,1998/10/01 06:41:00,139.6315091099,35.9106651375,1,10,3059002,8 ,1 ,37,,10",
 "777400,2,3,1998/10/01 06:41:00,139.3687465996,35.6427267499,1,5,0844010,8 ,1 ,41,,12",
 "280600,3,3,1998/10/01 06:42:00,139.5396452375,35.5515573269,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 06:42:00,139.6502129337,35.8626574265,1,6,3032201,3 ,8 ,34,,6",
 "138000,2,2,1998/10/01 06:42:00,140.3256374875,35.4226641678,1,9,4922013,9 ,1 ,36,,6",
 "441600,2,2,1998/10/01 06:42:00,139.8997800802,35.8441409561,1,9,3215009,9 ,1 ,30,,8",
 "864800,2,2,1998/10/01 06:42:00,139.526840014,35.4207358207,1,6,1240102,10,1 ,27,,3",
 "55200,2,3,1998/10/01 06:42:00,139.9119439251,35.8007092847,1,10,4222006,9 ,1 ,38,,12",
 "71300,2,3,1998/10/01 06:42:00,139.7537292014,35.9796603827,1,12,4313309,9 ,1 ,34,,12",
 "135700,2,3,1998/10/01 06:42:00,140.2827723417,35.5281937808,1,11,4920108,3 ,1 ,39,,12",
 "195500,2,3,1998/10/01 06:42:00,140.037110729,35.8731586843,2,4,5420020,8 ,1 ,37,,12",
 "234600,2,2,1998/10/01 06:42:00,139.4730459068,35.3633843062,1,14,2412116,10,1 ,63,,3",
 "386400,2,3,1998/10/01 06:42:00,139.628499049,35.9099923152,1,10,3059002,8 ,1 ,37,,10",
 "777400,2,3,1998/10/01 06:42:00,139.3731239514,35.6425901868,1,5,0844010,8 ,1 ,41,,12",
 "280600,3,3,1998/10/01 06:43:00,139.5439986608,35.5537024051,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 06:43:00,139.6495694012,35.8609802246,1,6,3032201,3 ,8 ,34,,6",
 "138000,2,2,1998/10/01 06:43:00,140.3244650979,35.4213654122,1,9,4922013,9 ,1 ,36,,6",
 "441600,2,2,1998/10/01 06:43:00,139.9011880161,35.8435675454,1,9,3215009,9 ,1 ,30,,8",
 "864800,2,2,1998/10/01 06:43:00,139.528301704,35.4191701793,1,6,1240102,10,1 ,27,,3",
 "55200,2,3,1998/10/01 06:43:00,139.9073349792,35.7958536797,1,10,4222006,9 ,1 ,38,,12",
 "71300,2,3,1998/10/01 06:43:00,139.7579014071,35.9779810424,1,12,4313309,9 ,1 ,34,,12",
 "135700,2,3,1998/10/01 06:43:00,140.2749393406,35.5296161645,1,11,4920108,3 ,1 ,39,,12",
 "195500,2,3,1998/10/01 06:43:00,140.0300131638,35.8738207398,2,4,5420020,8 ,1 ,37,,12",
 "234600,2,2,1998/10/01 06:43:00,139.4728155933,35.3629688541,1,14,2412116,10,1 ,63,,3",
 "386400,2,3,1998/10/01 06:43:00,139.6254769141,35.909351365,1,10,3059002,8 ,1 ,37,,10",
 "777400,2,3,1998/10/01 06:43:00,139.3766463434,35.644509364,1,5,0844010,8 ,1 ,41,,12",
 "280600,3,3,1998/10/01 06:44:00,139.5483221817,35.5558879966,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 06:44:00,139.649237989,35.8590383634,1,6,3032201,3 ,8 ,34,,6",
 "138000,2,2,1998/10/01 06:44:00,140.3240626212,35.4197543331,1,9,4922013,9 ,1 ,36,,6",
 "441600,2,2,1998/10/01 06:44:00,139.9021596682,35.8423836369,1,9,3215009,9 ,1 ,30,,8",
 "864800,2,2,1998/10/01 06:44:00,139.5278382147,35.4170555774,1,6,1240102,10,1 ,27,,3",
 "55200,2,3,1998/10/01 06:44:00,139.9034164519,35.7906312954,1,10,4222006,9 ,1 ,38,,12",
 "135700,2,3,1998/10/01 06:44:00,140.2672559049,35.5317600755,1,11,4920108,3 ,1 ,39,,12",
 "71300,2,3,1998/10/01 06:44:00,139.7618320597,35.9760011895,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 06:44:00,140.0228821082,35.8734594731,2,4,5420020,8 ,1 ,37,,12",
 "234600,2,2,1998/10/01 06:44:00,139.4728355421,35.3625267686,1,14,2412116,10,1 ,63,,3",
 "386400,2,3,1998/10/01 06:44:00,139.6224548284,35.9087103389,1,10,3059002,8 ,1 ,37,,10",
 "777400,2,3,1998/10/01 06:44:00,139.3796700122,35.647096196,1,5,0844010,8 ,1 ,41,,12",
 "280600,3,3,1998/10/01 06:45:00,139.5527430947,35.5579375403,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 06:45:00,139.6471077652,35.8583950493,1,6,3032201,3 ,8 ,34,,6",
 "138000,2,2,1998/10/01 06:45:00,140.3223614372,35.4194714359,1,9,4922013,9 ,1 ,36,,6",
 "441600,2,2,1998/10/01 06:45:00,139.9033162355,35.8411332906,1,9,3215009,9 ,1 ,30,,8",
 "864800,2,2,1998/10/01 06:45:00,139.5274543744,35.4149890117,1,6,1240102,10,1 ,27,,3",
 "71300,2,3,1998/10/01 06:45:00,139.7637423092,35.9726169852,1,12,4313309,9 ,1 ,34,,12",
 "55200,2,3,1998/10/01 06:45:00,139.9008719147,35.7848629001,1,10,4222006,9 ,1 ,38,,12",
 "135700,2,3,1998/10/01 06:45:00,140.2595693338,35.5338876363,1,11,4920108,3 ,1 ,39,,12",
 "195500,2,3,1998/10/01 06:45:00,140.0157346569,35.8731664259,2,4,5420020,8 ,1 ,37,,12",
 "234600,2,2,1998/10/01 06:45:00,139.473217655,35.3625249903,1,14,2412116,10,1 ,63,,3",
 "234600,2,3,1998/10/01 06:45:00,139.473217655,35.3625249903,1,14,2412116,10,1 ,63,,12",
 "386400,2,3,1998/10/01 06:45:00,139.62433895,35.9092958937,1,10,3059002,8 ,1 ,37,,10",
 "777400,2,3,1998/10/01 06:45:00,139.3828729124,35.649494123,1,5,0844010,8 ,1 ,41,,12",
 "280600,3,3,1998/10/01 06:46:00,139.557149206,35.5600032303,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 06:46:00,139.6473381412,35.8562719161,1,6,3032201,3 ,8 ,34,,6",
 "138000,2,2,1998/10/01 06:46:00,140.3203460685,35.4194430832,1,9,4922013,9 ,1 ,36,,6",
 "441600,2,2,1998/10/01 06:46:00,139.9044786277,35.8398859258,1,9,3215009,9 ,1 ,30,,8",
 "864800,2,2,1998/10/01 06:46:00,139.5274023886,35.4129093264,1,6,1240102,10,1 ,27,,3",
 "55200,2,3,1998/10/01 06:46:00,139.8987534472,35.7789719465,1,10,4222006,9 ,1 ,38,,12",
 "135700,2,3,1998/10/01 06:46:00,140.2517898383,35.5351397498,1,11,4920108,3 ,1 ,39,,12",
 "71300,2,3,1998/10/01 06:46:00,139.7648327085,35.9689380294,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 06:46:00,140.0086069195,35.8726795197,2,4,5420020,8 ,1 ,37,,12",
 "234600,2,3,1998/10/01 06:46:00,139.4738462563,35.3562648046,1,14,2412116,10,1 ,63,,12",
 "386400,2,3,1998/10/01 06:46:00,139.6245864397,35.9092880034,1,10,3059002,8 ,1 ,37,,10",
 "630200,2,2,1998/10/01 06:46:00,139.6071131402,35.7442444637,1,7,0513010,8 ,1 ,41,,1",
 "777400,2,3,1998/10/01 06:46:00,139.3866303646,35.6513332521,1,5,0844010,8 ,1 ,41,,12",
 "280600,3,3,1998/10/01 06:47:00,139.5608665123,35.5627945067,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 06:47:00,139.6452490174,35.8550974819,1,6,3032201,3 ,8 ,34,,6",
 "138000,2,2,1998/10/01 06:47:00,140.3195398865,35.4181987271,1,9,4922013,9 ,1 ,36,,6",
 "441600,2,2,1998/10/01 06:47:00,139.9056041228,35.8386167456,1,9,3215009,9 ,1 ,30,,8",
 "864800,2,2,1998/10/01 06:47:00,139.5278564992,35.4117352793,1,6,1240102,10,1 ,27,,3",
 "55200,2,3,1998/10/01 06:47:00,139.8962114949,35.7732139221,1,10,4222006,9 ,1 ,38,,12",
 "135700,2,3,1998/10/01 06:47:00,140.2445427797,35.538212192,1,11,4920108,3 ,1 ,39,,12",
 "71300,2,3,1998/10/01 06:47:00,139.765915421,35.9652575759,1,12,4313309,9 ,1 ,34,,12",
 "234600,2,3,1998/10/01 06:47:00,139.4748561516,35.3500415165,1,14,2412116,10,1 ,63,,12",
 "195500,2,3,1998/10/01 06:47:00,140.0015277115,35.8726667585,2,4,5420020,8 ,1 ,37,,12",
 "386400,2,3,1998/10/01 06:47:00,139.6216201409,35.9084930552,1,10,3059002,8 ,1 ,37,,10",
 "630200,2,2,1998/10/01 06:47:00,139.6071000179,35.7442191659,1,7,0513010,8 ,1 ,41,,1",
 "777400,2,3,1998/10/01 06:47:00,139.3903417342,35.6532308316,1,5,0844010,8 ,1 ,41,,12",
 "280600,3,3,1998/10/01 06:48:00,139.5637541732,35.5661904539,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 06:48:00,139.6427004668,35.8546395431,1,6,3032201,3 ,8 ,34,,6",
 "138000,2,2,1998/10/01 06:48:00,140.3189841695,35.4170321111,1,9,4922013,9 ,1 ,36,,6",
 "441600,2,2,1998/10/01 06:48:00,139.9067501231,35.8373605075,1,9,3215009,9 ,1 ,30,,8",
 "864800,2,2,1998/10/01 06:48:00,139.5296543679,35.4101638687,1,6,1240102,10,1 ,27,,3",
 "55200,2,3,1998/10/01 06:48:00,139.8902206074,35.7697475882,1,10,4222006,9 ,1 ,38,,12",
 "135700,2,3,1998/10/01 06:48:00,140.2368325224,35.5402945157,1,11,4920108,3 ,1 ,39,,12",
 "71300,2,3,1998/10/01 06:48:00,139.766996605,35.9615768325,1,12,4313309,9 ,1 ,34,,12",
 "234600,2,3,1998/10/01 06:48:00,139.477894696,35.3442653858,1,14,2412116,10,1 ,63,,12",
 "195500,2,3,1998/10/01 06:48:00,139.9946705837,35.8743305328,2,4,5420020,8 ,1 ,37,,12",
 "386400,2,3,1998/10/01 06:48:00,139.6213695404,35.906327038,1,10,3059002,8 ,1 ,37,,10",
 "630200,2,2,1998/10/01 06:48:00,139.6070868957,35.7441938682,1,7,0513010,8 ,1 ,41,,1",
 "777400,2,3,1998/10/01 06:48:00,139.3933361512,35.6558351154,1,5,0844010,8 ,1 ,41,,12",
 "280600,3,3,1998/10/01 06:49:00,139.5662605683,35.5697787663,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 06:49:00,139.6401070616,35.854486322,1,6,3032201,3 ,8 ,34,,6",
 "138000,2,2,1998/10/01 06:49:00,140.3169742004,35.4169089919,1,9,4922013,9 ,1 ,36,,6",
 "441600,2,2,1998/10/01 06:49:00,139.9080641923,35.8364248161,1,9,3215009,9 ,1 ,30,,8",
 "864800,2,2,1998/10/01 06:49:00,139.5314521669,35.4085924306,1,6,1240102,10,1 ,27,,3",
 "55200,2,3,1998/10/01 06:49:00,139.8828219667,35.7689148056,1,10,4222006,9 ,1 ,38,,12",
 "71300,2,3,1998/10/01 06:49:00,139.7680817131,35.95789686,1,12,4313309,9 ,1 ,34,,12",
 "135700,2,3,1998/10/01 06:49:00,140.229119748,35.5424684523,1,11,4920108,3 ,1 ,39,,12",
 "195500,2,3,1998/10/01 06:49:00,139.987734636,35.8757165197,2,4,5420020,8 ,1 ,37,,12",
 "234600,2,3,1998/10/01 06:49:00,139.4810580826,35.3393802482,1,14,2412116,10,1 ,63,,12",
 "386400,2,3,1998/10/01 06:49:00,139.6236044817,35.906261475,1,10,3059002,8 ,1 ,37,,10",
 "386400,2,4,1998/10/01 06:49:00,139.6236044817,35.906261475,1,10,3059002,8 ,1 ,37,,12",
 "630200,2,2,1998/10/01 06:49:00,139.6070737735,35.7441685705,1,7,0513010,8 ,1 ,41,,1",
 "777400,2,3,1998/10/01 06:49:00,139.3962757705,35.6584871642,1,5,0844010,8 ,1 ,41,,12",
 "280600,3,3,1998/10/01 06:50:00,139.5688664429,35.5733248468,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 06:50:00,139.6382208079,35.8530237422,1,6,3032201,3 ,8 ,34,,6",
 "138000,2,2,1998/10/01 06:50:00,140.314964843,35.416779141,1,9,4922013,9 ,1 ,36,,6",
 "441600,2,2,1998/10/01 06:50:00,139.9091123563,35.8352681639,1,9,3215009,9 ,1 ,30,,8",
 "441600,3,3,1998/10/01 06:50:00,139.9091123563,35.8352681639,1,9,3215009,9 ,97,30,,97",
 "864800,2,2,1998/10/01 06:50:00,139.5324357015,35.4066852992,1,6,1240102,10,1 ,27,,3",
 "55200,2,3,1998/10/01 06:50:00,139.8753423218,35.7696283901,1,10,4222006,9 ,1 ,38,,12",
 "71300,2,3,1998/10/01 06:50:00,139.7691670564,35.9542169407,1,12,4313309,9 ,1 ,34,,12",
 "135700,2,3,1998/10/01 06:50:00,140.2214398048,35.5447339887,1,11,4920108,3 ,1 ,39,,12",
 "234600,2,3,1998/10/01 06:50:00,139.4880597749,35.3388731474,1,14,2412116,10,1 ,63,,12",
 "195500,2,3,1998/10/01 06:50:00,139.981431563,35.8733158904,2,4,5420020,8 ,1 ,37,,12",
 "386400,2,4,1998/10/01 06:50:00,139.6260873809,35.902529785,1,10,3059002,8 ,1 ,37,,12",
 "630200,2,2,1998/10/01 06:50:00,139.6070606512,35.7441432727,1,7,0513010,8 ,1 ,41,,1",
 "777400,2,3,1998/10/01 06:50:00,139.3993843314,35.6609928163,1,5,0844010,8 ,1 ,41,,12",
 "220800,2,2,1998/10/01 06:51:00,139.5871001112,35.2594058994,2,10,2310102,14,8 ,37,,6",
 "280600,3,3,1998/10/01 06:51:00,139.5733266876,35.5750908089,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 06:51:00,139.6363967196,35.8515142475,1,6,3032201,3 ,8 ,34,,6",
 "126500,2,2,1998/10/01 06:51:00,140.6808041305,35.8367200092,1,9,4820320,3 ,1 ,16,,6",
 "138000,2,2,1998/10/01 06:51:00,140.3136131956,35.4161766491,1,9,4922013,9 ,1 ,36,,6",
 "402500,2,2,1998/10/01 06:51:00,139.5058450411,35.8543946344,1,9,3116015,9 ,1 ,44,,6",
 "864800,2,2,1998/10/01 06:51:00,139.5329073621,35.4045671453,1,6,1240102,10,1 ,27,,3",
 "18400,2,2,1998/10/01 06:51:00,140.0381143079,35.6902195973,1,9,4120311,8 ,1 ,44,,6",
 "32200,2,2,1998/10/01 06:51:00,140.0383122344,35.7388781649,2,4,4128103,8 ,1 ,42,,1",
 "55200,2,3,1998/10/01 06:51:00,139.8678598491,35.7692127665,1,10,4222006,9 ,1 ,38,,12",
 "135700,2,3,1998/10/01 06:51:00,140.2137699821,35.5470227731,1,11,4920108,3 ,1 ,39,,12",
 "71300,2,3,1998/10/01 06:51:00,139.7702522987,35.9505370092,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 06:51:00,139.9764680164,35.8691644831,2,4,5420020,8 ,1 ,37,,12",
 "234600,2,3,1998/10/01 06:51:00,139.4954224535,35.3371364378,1,14,2412116,10,1 ,63,,12",
 "301300,2,2,1998/10/01 06:51:00,139.4136275611,35.5133085307,1,11,2922005,9 ,1 ,29,,2",
 "246100,2,2,1998/10/01 06:51:00,139.3584272574,35.3260146668,1,5,2510201,3 ,1 ,39,,1",
 "386400,2,4,1998/10/01 06:51:00,139.6291155672,35.8991012425,1,10,3059002,8 ,1 ,37,,12",
 "483000,2,2,1998/10/01 06:51:00,139.6145791681,35.9694710071,1,10,3510101,8 ,1 ,27,,1",
 "630200,2,2,1998/10/01 06:51:00,139.607047529,35.744117975,1,7,0513010,8 ,1 ,41,,1",
 "676200,2,2,1998/10/01 06:51:00,139.8609672725,35.7430280863,1,13,0623211,10,1 ,35,,1",
 "777400,2,3,1998/10/01 06:51:00,139.4030701363,35.6629259673,1,5,0844010,8 ,1 ,41,,12",
 "220800,2,2,1998/10/01 06:52:00,139.5833263137,35.2598660807,2,10,2310102,14,8 ,37,,6",
 "280600,3,3,1998/10/01 06:52:00,139.5782089982,35.5762228743,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 06:52:00,139.6342202276,35.8503392846,1,6,3032201,3 ,8 ,34,,6",
 "126500,2,2,1998/10/01 06:52:00,140.6862964659,35.8347474288,1,9,4820320,3 ,1 ,16,,6",
 "138000,2,2,1998/10/01 06:52:00,140.3128083436,35.4153529027,1,9,4922013,9 ,1 ,36,,6",
 "402500,2,2,1998/10/01 06:52:00,139.5040875543,35.8514081869,1,9,3116015,9 ,1 ,44,,6",
 "864800,2,2,1998/10/01 06:52:00,139.5334894276,35.4024812565,1,6,1240102,10,1 ,27,,3",
 "18400,2,2,1998/10/01 06:52:00,140.0342666054,35.6904673365,1,9,4120311,8 ,1 ,44,,6",
 "32200,2,2,1998/10/01 06:52:00,140.037413882,35.7395007217,2,4,4128103,8 ,1 ,42,,1",
 "55200,2,3,1998/10/01 06:52:00,139.8604071453,35.7683443834,1,10,4222006,9 ,1 ,38,,12",
 "135700,2,3,1998/10/01 06:52:00,140.206278917,35.5496396655,1,11,4920108,3 ,1 ,39,,12",
 "71300,2,3,1998/10/01 06:52:00,139.7713376748,35.9468571113,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 06:52:00,139.9726588628,35.8642334672,2,4,5420020,8 ,1 ,37,,12",
 "234600,2,3,1998/10/01 06:52:00,139.5028733054,35.336086879,1,14,2412116,10,1 ,63,,12",
 "301300,2,2,1998/10/01 06:52:00,139.4149295007,35.5140475556,1,11,2922005,9 ,1 ,29,,2",
 "246100,2,2,1998/10/01 06:52:00,139.3572922782,35.3259896093,1,5,2510201,3 ,1 ,39,,1",
 "386400,2,4,1998/10/01 06:52:00,139.6323502446,35.8957826539,1,10,3059002,8 ,1 ,37,,12",
 "483000,2,2,1998/10/01 06:52:00,139.6128750418,35.9703775498,1,10,3510101,8 ,1 ,27,,1",
 "630200,2,2,1998/10/01 06:52:00,139.6070344068,35.7440926772,1,7,0513010,8 ,1 ,41,,1",
 "676200,2,2,1998/10/01 06:52:00,139.8595023287,35.7455087974,1,13,0623211,10,1 ,35,,1",
 "777400,2,3,1998/10/01 06:52:00,139.4071603073,35.6637638242,1,5,0844010,8 ,1 ,41,,12",
 "220800,2,2,1998/10/01 06:53:00,139.5849700336,35.2626953511,2,10,2310102,14,8 ,37,,6",
 "280600,3,3,1998/10/01 06:53:00,139.5831303815,35.577270142,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 06:53:00,139.632053027,35.84914443,1,6,3032201,3 ,8 ,34,,6",
 "126500,2,2,1998/10/01 06:53:00,140.6914740952,35.8322553071,1,9,4820320,3 ,1 ,16,,6",
 "138000,2,2,1998/10/01 06:53:00,140.3108067691,35.4151904145,1,9,4922013,9 ,1 ,36,,6",
 "402500,2,2,1998/10/01 06:53:00,139.5037828545,35.84827236,1,9,3116015,9 ,1 ,44,,6",
 "864800,2,2,1998/10/01 06:53:00,139.5342959158,35.4004924285,1,6,1240102,10,1 ,27,,3",
 "864800,2,3,1998/10/01 06:53:00,139.5342959158,35.4004924285,1,6,1240102,10,1 ,27,,12",
 "18400,2,2,1998/10/01 06:53:00,140.0305522695,35.6902901583,1,9,4120311,8 ,1 ,44,,6",
 "32200,2,2,1998/10/01 06:53:00,140.0364931365,35.7398036005,2,4,4128103,8 ,1 ,42,,1",
 "55200,2,3,1998/10/01 06:53:00,139.8529232214,35.7676481425,1,10,4222006,9 ,1 ,38,,12",
 "135700,2,3,1998/10/01 06:53:00,140.1989777928,35.5526280334,1,11,4920108,3 ,1 ,39,,12",
 "71300,2,3,1998/10/01 06:53:00,139.7724231291,35.9431772362,1,12,4313309,9 ,1 ,34,,12",
 "234600,2,3,1998/10/01 06:53:00,139.5094734904,35.3392833551,1,14,2412116,10,1 ,63,,12",
 "195500,2,3,1998/10/01 06:53:00,139.9687599164,35.8593482273,2,4,5420020,8 ,1 ,37,,12",
 "301300,2,2,1998/10/01 06:53:00,139.4161405452,35.5148826221,1,11,2922005,9 ,1 ,29,,2",
 "246100,2,2,1998/10/01 06:53:00,139.3569768635,35.3274018406,1,5,2510201,3 ,1 ,39,,1",
 "386400,2,4,1998/10/01 06:53:00,139.6348298508,35.8920519612,1,10,3059002,8 ,1 ,37,,12",
 "483000,2,2,1998/10/01 06:53:00,139.6113987401,35.9715481442,1,10,3510101,8 ,1 ,27,,1",
 "630200,2,2,1998/10/01 06:53:00,139.6070212846,35.7440673795,1,7,0513010,8 ,1 ,41,,1",
 "676200,2,2,1998/10/01 06:53:00,139.858002282,35.7477887351,1,13,0623211,10,1 ,35,,1",
 "777400,2,3,1998/10/01 06:53:00,139.4114043761,35.6629971038,1,5,0844010,8 ,1 ,41,,12",
 "220800,2,2,1998/10/01 06:54:00,139.5835865215,35.2656743201,2,10,2310102,14,8 ,37,,6",
 "280600,3,3,1998/10/01 06:54:00,139.5880515786,35.5783183634,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 06:54:00,139.6298827746,35.8479537217,1,6,3032201,3 ,8 ,34,,6",
 "126500,2,2,1998/10/01 06:54:00,140.6963774705,35.8314536924,1,9,4820320,3 ,1 ,16,,6",
 "138000,2,2,1998/10/01 06:54:00,140.3090247272,35.4148483366,1,9,4922013,9 ,1 ,36,,6",
 "402500,2,2,1998/10/01 06:54:00,139.5047139781,35.8450506438,1,9,3116015,9 ,1 ,44,,6",
 "864800,2,3,1998/10/01 06:54:00,139.5340166259,35.4057826135,1,6,1240102,10,1 ,27,,12",
 "18400,2,2,1998/10/01 06:54:00,140.0277646661,35.6889968756,1,9,4120311,8 ,1 ,44,,6",
 "32200,2,2,1998/10/01 06:54:00,140.0356946523,35.7390955052,2,4,4128103,8 ,1 ,42,,1",
 "55200,2,3,1998/10/01 06:54:00,139.8458871195,35.7657755554,1,10,4222006,9 ,1 ,38,,12",
 "71300,2,3,1998/10/01 06:54:00,139.7735084826,35.9394973488,1,12,4313309,9 ,1 ,34,,12",
 "135700,2,3,1998/10/01 06:54:00,140.1916761237,35.555615961,1,11,4920108,3 ,1 ,39,,12",
 "195500,2,3,1998/10/01 06:54:00,139.964902131,35.8544414524,2,4,5420020,8 ,1 ,37,,12",
 "234600,2,3,1998/10/01 06:54:00,139.5159059101,35.3427510653,1,14,2412116,10,1 ,63,,12",
 "301300,2,2,1998/10/01 06:54:00,139.4173259452,35.5157420073,1,11,2922005,9 ,1 ,29,,2",
 "246100,2,2,1998/10/01 06:54:00,139.3561056574,35.3284213811,1,5,2510201,3 ,1 ,39,,1",
 "386400,2,4,1998/10/01 06:54:00,139.6370582244,35.8882222915,1,10,3059002,8 ,1 ,37,,12",
 "483000,2,2,1998/10/01 06:54:00,139.6101629664,35.9729081095,1,10,3510101,8 ,1 ,27,,1",
 "630200,2,2,1998/10/01 06:54:00,139.6070081624,35.7440420818,1,7,0513010,8 ,1 ,41,,1",
 "676200,2,2,1998/10/01 06:54:00,139.8561448006,35.7457323342,1,13,0623211,10,1 ,35,,1",
 "676200,2,3,1998/10/01 06:54:00,139.8561448006,35.7457323342,1,13,0623211,10,1 ,35,,12",
 "777400,2,3,1998/10/01 06:54:00,139.4152897433,35.6613441619,1,5,0844010,8 ,1 ,41,,12",
 "220800,2,2,1998/10/01 06:55:00,139.5849650716,35.2684682191,2,10,2310102,14,8 ,37,,6",
 "280600,3,3,1998/10/01 06:55:00,139.5926501475,35.5800548543,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 06:55:00,139.6276008216,35.8469173588,1,6,3032201,3 ,8 ,34,,6",
 "126500,2,2,1998/10/01 06:55:00,140.7004203784,35.8344930928,1,9,4820320,3 ,1 ,16,,6",
 "138000,2,2,1998/10/01 06:55:00,140.308366489,35.413321017,1,9,4922013,9 ,1 ,36,,6",
 "402500,2,2,1998/10/01 06:55:00,139.5061958346,35.8419808623,1,9,3116015,9 ,1 ,44,,6",
 "864800,2,3,1998/10/01 06:55:00,139.5352591698,35.4109930427,1,6,1240102,10,1 ,27,,12",
 "18400,2,2,1998/10/01 06:55:00,140.0250642594,35.6891280226,1,9,4120311,8 ,1 ,44,,6",
 "32200,2,2,1998/10/01 06:55:00,140.0347385061,35.7385364888,2,4,4128103,8 ,1 ,42,,1",
 "71300,2,3,1998/10/01 06:55:00,139.7745937352,35.9358174493,1,12,4313309,9 ,1 ,34,,12",
 "55200,2,3,1998/10/01 06:55:00,139.8389555729,35.7636046476,1,10,4222006,9 ,1 ,38,,12",
 "195500,2,3,1998/10/01 06:55:00,139.9608749076,35.8496319005,2,4,5420020,8 ,1 ,37,,12",
 "135700,2,3,1998/10/01 06:55:00,140.1845925651,35.5589262324,1,11,4920108,3 ,1 ,39,,12",
 "246100,2,2,1998/10/01 06:55:00,139.3544662817,35.3289276471,1,5,2510201,3 ,1 ,39,,1",
 "234600,2,3,1998/10/01 06:55:00,139.5223288304,35.3462299822,1,14,2412116,10,1 ,63,,12",
 "301300,2,2,1998/10/01 06:55:00,139.4183590715,35.516723872,1,11,2922005,9 ,1 ,29,,2",
 "386400,2,4,1998/10/01 06:55:00,139.6390251048,35.8842939627,1,10,3059002,8 ,1 ,37,,12",
 "483000,2,2,1998/10/01 06:55:00,139.6084318841,35.972080876,1,10,3510101,8 ,1 ,27,,1",
 "630200,2,2,1998/10/01 06:55:00,139.6069950402,35.744016784,1,7,0513010,8 ,1 ,41,,1",
 "676200,2,3,1998/10/01 06:55:00,139.8540424263,35.7430194187,1,13,0623211,10,1 ,35,,12",
 "777400,2,3,1998/10/01 06:55:00,139.4194463893,35.6602240066,1,5,0844010,8 ,1 ,41,,12",
 "220800,2,2,1998/10/01 06:56:00,139.5834868878,35.2711735171,2,10,2310102,14,8 ,37,,6",
 "280600,3,3,1998/10/01 06:56:00,139.5972443113,35.5815150469,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 06:56:00,139.6252495661,35.845981931,1,6,3032201,3 ,8 ,34,,6",
 "105800,2,2,1998/10/01 06:56:00,139.9135864803,35.3455527135,2,2,4612227,12,2 ,32,,1",
 "126500,2,2,1998/10/01 06:56:00,140.7045435551,35.8380632278,1,9,4820320,3 ,1 ,16,,6",
 "138000,2,2,1998/10/01 06:56:00,140.3073249174,35.4119192339,1,9,4922013,9 ,1 ,36,,6",
 "294400,2,2,1998/10/01 06:56:00,139.4461590723,35.5331129371,1,4,2920001,3 ,1 ,39,,6",
 "402500,2,2,1998/10/01 06:56:00,139.5074228842,35.8388337322,1,9,3116015,9 ,1 ,44,,6",
 "864800,2,3,1998/10/01 06:56:00,139.5397853289,35.4147297719,1,6,1240102,10,1 ,27,,12",
 "18400,2,2,1998/10/01 06:56:00,140.024513124,35.6884586702,1,9,4120311,8 ,1 ,44,,6",
 "32200,2,2,1998/10/01 06:56:00,140.0335831159,35.7383400434,2,4,4128103,8 ,1 ,42,,1",
 "55200,2,3,1998/10/01 06:56:00,139.831474615,35.7628910285,1,10,4222006,9 ,1 ,38,,12",
 "135700,2,3,1998/10/01 06:56:00,140.1782592897,35.5630582736,1,11,4920108,3 ,1 ,39,,12",
 "71300,2,3,1998/10/01 06:56:00,139.7756792319,35.932137605,1,12,4313309,9 ,1 ,34,,12",
 "234600,2,3,1998/10/01 06:56:00,139.528685941,35.3497844375,1,14,2412116,10,1 ,63,,12",
 "195500,2,3,1998/10/01 06:56:00,139.9557942214,35.8455564651,2,4,5420020,8 ,1 ,37,,12",
 "301300,2,2,1998/10/01 06:56:00,139.4196732715,35.5160357779,1,11,2922005,9 ,1 ,29,,2",
 "246100,2,2,1998/10/01 06:56:00,139.3528268857,35.3294338906,1,5,2510201,3 ,1 ,39,,1",
 "386400,2,4,1998/10/01 06:56:00,139.6407283332,35.8802839219,1,10,3059002,8 ,1 ,37,,12",
 "370300,2,2,1998/10/01 06:56:00,139.6672821447,35.8362975247,1,10,3037010,10,1 ,39,,6",
 "483000,2,2,1998/10/01 06:56:00,139.6066527763,35.9725655023,1,10,3510101,8 ,1 ,27,,1",
 "630200,2,2,1998/10/01 06:56:00,139.6069819181,35.7439914863,1,7,0513010,8 ,1 ,41,,1",
 "676200,2,3,1998/10/01 06:56:00,139.8519256862,35.740313842,1,13,0623211,10,1 ,35,,12",
 "777400,2,3,1998/10/01 06:56:00,139.4236523842,35.6592205973,1,5,0844010,8 ,1 ,41,,12",
 "220800,2,2,1998/10/01 06:57:00,139.5802642032,35.2727608681,2,10,2310102,14,8 ,37,,6",
 "280600,3,3,1998/10/01 06:57:00,139.6021766615,35.5823309435,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 06:57:00,139.6233244447,35.8445673286,1,6,3032201,3 ,8 ,34,,6",
 "105800,2,2,1998/10/01 06:57:00,139.9139667736,35.345875912,2,2,4612227,12,2 ,32,,1",
 "126500,2,2,1998/10/01 06:57:00,140.7086485222,35.8416467655,1,9,4820320,3 ,1 ,16,,6",
 "138000,2,2,1998/10/01 06:57:00,140.306266915,35.410528386,1,9,4922013,9 ,1 ,36,,6",
 "294400,2,2,1998/10/01 06:57:00,139.4482427461,35.5327910433,1,4,2920001,3 ,1 ,39,,6",
 "402500,2,2,1998/10/01 06:57:00,139.5093065595,35.8359393609,1,9,3116015,9 ,1 ,44,,6",
 "864800,2,3,1998/10/01 06:57:00,139.5452582036,35.4176487373,1,6,1240102,10,1 ,27,,12",
 "32200,2,2,1998/10/01 06:57:00,140.0328781328,35.7390221749,2,4,4128103,8 ,1 ,42,,1",
 "55200,2,3,1998/10/01 06:57:00,139.8239926034,35.7621925138,1,10,4222006,9 ,1 ,38,,12",
 "18400,2,2,1998/10/01 06:57:00,140.022287915,35.6893361995,1,9,4120311,8 ,1 ,44,,6",
 "71300,2,3,1998/10/01 06:57:00,139.7767646321,35.9284577493,1,12,4313309,9 ,1 ,34,,12",
 "135700,2,3,1998/10/01 06:57:00,140.1708467582,35.5646835464,1,11,4920108,3 ,1 ,39,,12",
 "195500,2,3,1998/10/01 06:57:00,139.9497250689,35.8424742424,2,4,5420020,8 ,1 ,37,,12",
 "234600,2,3,1998/10/01 06:57:00,139.5316585842,35.3555242878,1,14,2412116,10,1 ,63,,12",
 "301300,2,2,1998/10/01 06:57:00,139.4209927218,35.5153177724,1,11,2922005,9 ,1 ,29,,2",
 "246100,2,2,1998/10/01 06:57:00,139.3511331294,35.3291472884,1,5,2510201,3 ,1 ,39,,1",
 "370300,2,2,1998/10/01 06:57:00,139.6672823866,35.8396318507,1,10,3037010,10,1 ,39,,6",
 "386400,2,4,1998/10/01 06:57:00,139.6423918421,35.8762623144,1,10,3059002,8 ,1 ,37,,12",
 "483000,2,2,1998/10/01 06:57:00,139.6049252495,35.9726989929,1,10,3510101,8 ,1 ,27,,1",
 "630200,2,2,1998/10/01 06:57:00,139.6069687959,35.7439661885,1,7,0513010,8 ,1 ,41,,1",
 "676200,2,3,1998/10/01 06:57:00,139.8490148334,35.738358909,1,13,0623211,10,1 ,35,,12",
 "777400,2,3,1998/10/01 06:57:00,139.4278582751,35.6582170402,1,5,0844010,8 ,1 ,41,,12",
 "220800,2,2,1998/10/01 06:58:00,139.5789110831,35.2754916739,2,10,2310102,14,8 ,37,,6",
 "280600,3,3,1998/10/01 06:58:00,139.6061939286,35.5842723154,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 06:58:00,139.6215124354,35.8430293172,1,6,3032201,3 ,8 ,34,,6",
 "105800,2,2,1998/10/01 06:58:00,139.9142888524,35.3463905225,2,2,4612227,12,2 ,32,,1",
 "126500,2,2,1998/10/01 06:58:00,140.7070139503,35.8457589587,1,9,4820320,3 ,1 ,16,,6",
 "138000,2,2,1998/10/01 06:58:00,140.3045698664,35.4097068647,1,9,4922013,9 ,1 ,36,,6",
 "294400,2,2,1998/10/01 06:58:00,139.4507866291,35.5319301751,1,4,2920001,3 ,1 ,39,,6",
 "402500,2,2,1998/10/01 06:58:00,139.5116490253,35.8332783898,1,9,3116015,9 ,1 ,44,,6",
 "864800,2,3,1998/10/01 06:58:00,139.5507383868,35.4205586825,1,6,1240102,10,1 ,27,,12",
 "18400,2,2,1998/10/01 06:58:00,140.0203561814,35.6912947966,1,9,4120311,8 ,1 ,44,,6",
 "18400,2,3,1998/10/01 06:58:00,140.0203561814,35.6912947966,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,2,1998/10/01 06:58:00,140.0318956171,35.7391053889,2,4,4128103,8 ,1 ,42,,1",
 "55200,2,3,1998/10/01 06:58:00,139.816551835,35.7612591233,1,10,4222006,9 ,1 ,38,,12",
 "135700,2,3,1998/10/01 06:58:00,140.1627005758,35.5645044717,1,11,4920108,3 ,1 ,39,,12",
 "71300,2,3,1998/10/01 06:58:00,139.7778517282,35.9247782328,1,12,4313309,9 ,1 ,34,,12",
 "234600,2,3,1998/10/01 06:58:00,139.5292016363,35.3613123722,1,14,2412116,10,1 ,63,,12",
 "195500,2,3,1998/10/01 06:58:00,139.9435773673,35.8394938826,2,4,5420020,8 ,1 ,37,,12",
 "301300,2,2,1998/10/01 06:58:00,139.4224423528,35.5148250161,1,11,2922005,9 ,1 ,29,,2",
 "246100,2,2,1998/10/01 06:58:00,139.3497129998,35.3289293281,1,5,2510201,3 ,1 ,39,,1",
 "386400,2,4,1998/10/01 06:58:00,139.6451719765,35.8727231577,1,10,3059002,8 ,1 ,37,,12",
 "370300,2,2,1998/10/01 06:58:00,139.6661945071,35.8428289802,1,10,3037010,10,1 ,39,,6",
 "483000,2,2,1998/10/01 06:58:00,139.6031547361,35.9717988822,1,10,3510101,8 ,1 ,27,,1",
 "630200,2,2,1998/10/01 06:58:00,139.6069556737,35.7439408908,1,7,0513010,8 ,1 ,41,,1",
 "676200,2,3,1998/10/01 06:58:00,139.8451680641,35.7376792148,1,13,0623211,10,1 ,35,,12",
 "777400,2,3,1998/10/01 06:58:00,139.4320538019,35.6571859564,1,5,0844010,8 ,1 ,41,,12",
 "220800,2,2,1998/10/01 06:59:00,139.5792297582,35.2786768746,2,10,2310102,14,8 ,37,,6",
 "280600,3,3,1998/10/01 06:59:00,139.6086495884,35.5877414189,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 06:59:00,139.6195497441,35.8416268718,1,6,3032201,3 ,8 ,34,,6",
 "105800,2,2,1998/10/01 06:59:00,139.9150131833,35.3460930929,2,2,4612227,12,2 ,32,,1",
 "126500,2,2,1998/10/01 06:59:00,140.7038247147,35.849855303,1,9,4820320,3 ,1 ,16,,6",
 "138000,2,2,1998/10/01 06:59:00,140.3044475702,35.4082321929,1,9,4922013,9 ,1 ,36,,6",
 "294400,2,2,1998/10/01 06:59:00,139.4529714745,35.5331529733,1,4,2920001,3 ,1 ,39,,6",
 "402500,2,2,1998/10/01 06:59:00,139.5132121654,35.8302520674,1,9,3116015,9 ,1 ,44,,6",
 "864800,2,3,1998/10/01 06:59:00,139.5551029109,35.4243886199,1,6,1240102,10,1 ,27,,12",
 "18400,2,3,1998/10/01 06:59:00,140.0165786289,35.694425396,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,2,1998/10/01 06:59:00,140.0308087727,35.7387283543,2,4,4128103,8 ,1 ,42,,1",
 "55200,2,3,1998/10/01 06:59:00,139.8102833468,35.7584724698,1,10,4222006,9 ,1 ,38,,12",
 "135700,2,3,1998/10/01 06:59:00,140.1547963141,35.5655410645,1,11,4920108,3 ,1 ,39,,12",
 "71300,2,3,1998/10/01 06:59:00,139.77894228,35.9210994,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 06:59:00,139.9374297815,35.836513747,2,4,5420020,8 ,1 ,37,,12",
 "234600,2,3,1998/10/01 06:59:00,139.5301985179,35.3674614413,1,14,2412116,10,1 ,63,,12",
 "301300,2,2,1998/10/01 06:59:00,139.4239356597,35.514717853,1,11,2922005,9 ,1 ,29,,2",
 "246100,2,2,1998/10/01 06:59:00,139.3491153285,35.3276356888,1,5,2510201,3 ,1 ,39,,1",
 "246100,2,3,1998/10/01 06:59:00,139.3491153285,35.3276356888,1,5,2510201,3 ,1 ,39,,12",
 "370300,2,2,1998/10/01 06:59:00,139.6665842325,35.8455134475,1,10,3037010,10,1 ,39,,6",
 "386400,2,4,1998/10/01 06:59:00,139.6486829881,35.8695864081,1,10,3059002,8 ,1 ,37,,12",
 "483000,2,2,1998/10/01 06:59:00,139.6014651272,35.9709639538,1,10,3510101,8 ,1 ,27,,1",
 "630200,2,2,1998/10/01 06:59:00,139.6069425516,35.743915593,1,7,0513010,8 ,1 ,41,,1",
 "676200,2,3,1998/10/01 06:59:00,139.8415969441,35.7364206079,1,13,0623211,10,1 ,35,,12",
 "777400,2,3,1998/10/01 06:59:00,139.4352053879,35.6547933602,1,5,0844010,8 ,1 ,41,,12",
 "220800,2,2,1998/10/01 07:00:00,139.5797155489,35.2817602878,2,10,2310102,14,8 ,37,,6",
 "280600,3,3,1998/10/01 07:00:00,139.611220836,35.5879875714,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 07:00:00,139.6174283993,35.8403780192,1,6,3032201,3 ,8 ,34,,6",
 "105800,2,2,1998/10/01 07:00:00,139.9158295196,35.3461355857,2,2,4612227,12,2 ,32,,1",
 "126500,2,2,1998/10/01 07:00:00,140.7020068849,35.8542024651,1,9,4820320,3 ,1 ,16,,6",
 "138000,2,2,1998/10/01 07:00:00,140.3033434298,35.4072658583,1,9,4922013,9 ,1 ,36,,6",
 "138000,3,3,1998/10/01 07:00:00,140.3033434298,35.4072658583,1,9,4922013,9 ,97,36,,97",
 "294400,2,2,1998/10/01 07:00:00,139.4555315462,35.5345221262,1,4,2920001,3 ,1 ,39,,6",
 "402500,2,2,1998/10/01 07:00:00,139.515926291,35.8278866501,1,9,3116015,9 ,1 ,44,,6",
 "864800,2,3,1998/10/01 07:00:00,139.5568687111,35.4294902492,1,6,1240102,10,1 ,27,,12",
 "32200,2,2,1998/10/01 07:00:00,140.0297312463,35.7383347277,2,4,4128103,8 ,1 ,42,,1",
 "18400,2,3,1998/10/01 07:00:00,140.0127944134,35.6975900801,1,9,4120311,8 ,1 ,44,,12",
 "55200,2,3,1998/10/01 07:00:00,139.8062396828,35.7533181756,1,10,4222006,9 ,1 ,38,,12",
 "71300,2,3,1998/10/01 07:00:00,139.7799280827,35.9174016363,1,12,4313309,9 ,1 ,34,,12",
 "135700,2,3,1998/10/01 07:00:00,140.147564007,35.568641454,1,11,4920108,3 ,1 ,39,,12",
 "195500,2,3,1998/10/01 07:00:00,139.9313414377,35.8334551921,2,4,5420020,8 ,1 ,37,,12",
 "234600,2,3,1998/10/01 07:00:00,139.5308702152,35.3735314335,1,14,2412116,10,1 ,63,,12",
 "246100,2,3,1998/10/01 07:00:00,139.3571706755,35.3292422219,1,5,2510201,3 ,1 ,39,,12",
 "301300,2,2,1998/10/01 07:00:00,139.4231069934,35.5156384397,1,11,2922005,9 ,1 ,29,,2",
 "301300,2,3,1998/10/01 07:00:00,139.4231069934,35.5156384397,1,11,2922005,9 ,1 ,29,,12",
 "370300,2,2,1998/10/01 07:00:00,139.6690467889,35.8474918087,1,10,3037010,10,1 ,39,,6",
 "370300,2,3,1998/10/01 07:00:00,139.6690467889,35.8474918087,1,10,3037010,10,1 ,39,,12",
 "386400,2,4,1998/10/01 07:00:00,139.6520860124,35.866379614,1,10,3059002,8 ,1 ,37,,12",
 "483000,2,2,1998/10/01 07:00:00,139.5996426634,35.9714519399,1,10,3510101,8 ,1 ,27,,1",
 "630200,2,2,1998/10/01 07:00:00,139.6069294294,35.7438902953,1,7,0513010,8 ,1 ,41,,1",
 "676200,2,3,1998/10/01 07:00:00,139.8385500756,35.7343892974,1,13,0623211,10,1 ,35,,12",
 "777400,2,3,1998/10/01 07:00:00,139.4381889355,35.6522559672,1,5,0844010,8 ,1 ,41,,12",
 "220800,2,2,1998/10/01 07:01:00,139.5802889044,35.2849196739,2,10,2310102,14,8 ,37,,6",
 "280600,3,3,1998/10/01 07:01:00,139.6152311406,35.5903595525,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 07:01:00,139.6151965831,35.839272935,1,6,3032201,3 ,8 ,34,,6",
 "105800,2,2,1998/10/01 07:01:00,139.9165175992,35.3458131471,2,2,4612227,12,2 ,32,,1",
 "110400,2,2,1998/10/01 07:01:00,140.059108378,35.2562429759,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:01:00,140.7008158537,35.8584270751,1,9,4820320,3 ,1 ,16,,6",
 "248400,2,2,1998/10/01 07:01:00,139.3497459974,35.3567578804,1,5,2511002,3 ,1 ,37,,2",
 "294400,2,2,1998/10/01 07:01:00,139.4581593462,35.535279984,1,4,2920001,3 ,1 ,39,,6",
 "402500,2,2,1998/10/01 07:01:00,139.5188583424,35.8256067192,1,9,3116015,9 ,1 ,44,,6",
 "414000,2,2,1998/10/01 07:01:00,139.3966258684,35.8027183011,1,10,3122302,3 ,11,32,,6",
 "752100,2,2,1998/10/01 07:01:00,139.4427723111,35.5676753494,1,6,0811001,6 ,1 ,36,,6",
 "839500,2,2,1998/10/01 07:01:00,139.6422564702,35.3666399432,2,11,1133001,14,7 ,27,,1",
 "864800,2,3,1998/10/01 07:01:00,139.5581059363,35.4346427802,1,6,1240102,10,1 ,27,,12",
 "869400,2,2,1998/10/01 07:01:00,139.5798734483,35.3521587771,1,10,1231001,8 ,1 ,29,,6",
 "18400,2,3,1998/10/01 07:01:00,140.0079802379,35.699457408,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,2,1998/10/01 07:01:00,140.0286103308,35.7381454254,2,4,4128103,8 ,1 ,42,,1",
 "55200,2,3,1998/10/01 07:01:00,139.8041187668,35.747434846,1,10,4222006,9 ,1 ,38,,12",
 "78200,2,2,1998/10/01 07:01:00,140.1607584566,35.7325222825,1,12,4413008,8 ,1 ,44,,6",
 "135700,2,3,1998/10/01 07:01:00,140.1407586814,35.5722688459,1,11,4920108,3 ,1 ,39,,12",
 "71300,2,3,1998/10/01 07:01:00,139.7802821487,35.9136510468,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 07:01:00,139.9252430875,35.8304167975,2,4,5420020,8 ,1 ,37,,12",
 "234600,2,3,1998/10/01 07:01:00,139.5338707781,35.3793385589,1,14,2412116,10,1 ,63,,12",
 "303600,2,2,1998/10/01 07:01:00,139.3714197775,35.5770430809,1,9,2923016,9 ,1 ,30,,1",
 "301300,2,3,1998/10/01 07:01:00,139.4263428781,35.5193735126,1,11,2922005,9 ,1 ,29,,12",
 "246100,2,3,1998/10/01 07:01:00,139.365304504,35.3307612848,1,5,2510201,3 ,1 ,39,,12",
 "386400,2,4,1998/10/01 07:01:00,139.6546249678,35.8626745509,1,10,3059002,8 ,1 ,37,,12",
 "370300,2,3,1998/10/01 07:01:00,139.6666949058,35.8500140345,1,10,3037010,10,1 ,39,,12",
 "483000,2,2,1998/10/01 07:01:00,139.5975638107,35.9713191612,1,10,3510101,8 ,1 ,27,,1",
 "630200,2,2,1998/10/01 07:01:00,139.6069163073,35.7438649975,1,7,0513010,8 ,1 ,41,,1",
 "630200,2,3,1998/10/01 07:01:00,139.6069163073,35.7438649975,1,7,0513010,8 ,1 ,41,,12",
 "653200,2,2,1998/10/01 07:01:00,139.7201737405,35.7517165946,1,8,0531203,9 ,1 ,39,,1",
 "676200,2,3,1998/10/01 07:01:00,139.8355395246,35.7323221869,1,13,0623211,10,1 ,35,,12",
 "777400,2,3,1998/10/01 07:01:00,139.4424099969,35.6512968237,1,5,0844010,8 ,1 ,41,,12",
 "220800,2,2,1998/10/01 07:02:00,139.5802202177,35.2881265741,2,10,2310102,14,8 ,37,,6",
 "280600,3,3,1998/10/01 07:02:00,139.6202352583,35.5905110895,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 07:02:00,139.6128653303,35.8383037205,1,6,3032201,3 ,8 ,34,,6",
 "105800,2,2,1998/10/01 07:02:00,139.9171813346,35.3454214555,2,2,4612227,12,2 ,32,,1",
 "110400,2,2,1998/10/01 07:02:00,140.0603573185,35.2553933295,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:02:00,140.6977326145,35.8626363536,1,9,4820320,3 ,1 ,16,,6",
 "248400,2,2,1998/10/01 07:02:00,139.3494621066,35.3553255324,1,5,2511002,3 ,1 ,37,,2",
 "294400,2,2,1998/10/01 07:02:00,139.4609016196,35.5356540333,1,4,2920001,3 ,1 ,39,,6",
 "402500,2,2,1998/10/01 07:02:00,139.5205159719,35.8227994449,1,9,3116015,9 ,1 ,44,,6",
 "414000,2,2,1998/10/01 07:02:00,139.3962189917,35.8011899738,1,10,3122302,3 ,11,32,,6",
 "752100,2,2,1998/10/01 07:02:00,139.4437827379,35.5668365287,1,6,0811001,6 ,1 ,36,,6",
 "839500,2,2,1998/10/01 07:02:00,139.641429422,35.365357991,2,11,1133001,14,7 ,27,,1",
 "869400,2,2,1998/10/01 07:02:00,139.5767358063,35.3533217648,1,10,1231001,8 ,1 ,29,,6",
 "864800,2,3,1998/10/01 07:02:00,139.5620067269,35.4389278036,1,6,1240102,10,1 ,27,,12",
 "18400,2,3,1998/10/01 07:02:00,140.0027099823,35.7002259032,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,2,1998/10/01 07:02:00,140.0275328986,35.7378147336,2,4,4128103,8 ,1 ,42,,1",
 "55200,2,3,1998/10/01 07:02:00,139.8020854166,35.741526733,1,10,4222006,9 ,1 ,38,,12",
 "78200,2,2,1998/10/01 07:02:00,140.1588686059,35.7325545245,1,12,4413008,8 ,1 ,44,,6",
 "135700,2,3,1998/10/01 07:02:00,140.1347296516,35.5767710284,1,11,4920108,3 ,1 ,39,,12",
 "71300,2,3,1998/10/01 07:02:00,139.7800202697,35.9098728058,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 07:02:00,139.9212742418,35.8256728939,2,4,5420020,8 ,1 ,37,,12",
 "234600,2,3,1998/10/01 07:02:00,139.534940746,35.3855701635,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:02:00,139.4296382606,35.523098045,1,11,2922005,9 ,1 ,29,,12",
 "246100,2,3,1998/10/01 07:02:00,139.373502315,35.3319615463,1,5,2510201,3 ,1 ,39,,12",
 "303600,2,2,1998/10/01 07:02:00,139.3715543216,35.5777760567,1,9,2923016,9 ,1 ,30,,1",
 "386400,2,4,1998/10/01 07:02:00,139.6569685328,35.8588849568,1,10,3059002,8 ,1 ,37,,12",
 "370300,2,3,1998/10/01 07:02:00,139.6699318325,35.8470773125,1,10,3037010,10,1 ,39,,12",
 "483000,2,2,1998/10/01 07:02:00,139.5954765136,35.9712789387,1,10,3510101,8 ,1 ,27,,1",
 "630200,2,3,1998/10/01 07:02:00,139.6164122248,35.7408432628,1,7,0513010,8 ,1 ,41,,12",
 "676200,2,3,1998/10/01 07:02:00,139.8324936213,35.7302904822,1,13,0623211,10,1 ,35,,12",
 "653200,2,2,1998/10/01 07:02:00,139.7150852126,35.746407638,1,8,0531203,9 ,1 ,39,,1",
 "777400,2,3,1998/10/01 07:02:00,139.4466783193,35.650748336,1,5,0844010,8 ,1 ,41,,12",
 "220800,2,2,1998/10/01 07:03:00,139.5797647391,35.2912994423,2,10,2310102,14,8 ,37,,6",
 "280600,3,3,1998/10/01 07:03:00,139.6244223848,35.5917363199,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 07:03:00,139.6105217375,35.837354232,1,6,3032201,3 ,8 ,34,,6",
 "105800,2,2,1998/10/01 07:03:00,139.9178450636,35.3450297603,2,2,4612227,12,2 ,32,,1",
 "110400,2,2,1998/10/01 07:03:00,140.0590990283,35.2554696636,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:03:00,140.694674894,35.8668580009,1,9,4820320,3 ,1 ,16,,6",
 "248400,2,2,1998/10/01 07:03:00,139.3495062363,35.3538926849,1,5,2511002,3 ,1 ,37,,2",
 "294400,2,2,1998/10/01 07:03:00,139.4638731923,35.5347759879,1,4,2920001,3 ,1 ,39,,6",
 "402500,2,2,1998/10/01 07:03:00,139.5204754946,35.8202818474,1,9,3116015,9 ,1 ,44,,6",
 "414000,2,2,1998/10/01 07:03:00,139.3962839477,35.7993198445,1,10,3122302,3 ,11,32,,6",
 "752100,2,2,1998/10/01 07:03:00,139.4444719549,35.5649052392,1,6,0811001,6 ,1 ,36,,6",
 "839500,2,2,1998/10/01 07:03:00,139.6406023999,35.364076033,2,11,1133001,14,7 ,27,,1",
 "869400,2,2,1998/10/01 07:03:00,139.5724666812,35.3530181588,1,10,1231001,8 ,1 ,29,,6",
 "864800,2,3,1998/10/01 07:03:00,139.5674915829,35.4418207949,1,6,1240102,10,1 ,27,,12",
 "18400,2,3,1998/10/01 07:03:00,139.9973029545,35.7005314096,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,2,1998/10/01 07:03:00,140.0271372937,35.7387220867,2,4,4128103,8 ,1 ,42,,1",
 "55200,2,3,1998/10/01 07:03:00,139.8002224195,35.7355804393,1,10,4222006,9 ,1 ,38,,12",
 "78200,2,2,1998/10/01 07:03:00,140.1567961764,35.7323239189,1,12,4413008,8 ,1 ,44,,6",
 "135700,2,3,1998/10/01 07:03:00,140.1305459824,35.5823671665,1,11,4920108,3 ,1 ,39,,12",
 "135700,2,4,1998/10/01 07:03:00,140.1305459824,35.5823671665,1,11,4920108,3 ,1 ,39,,1",
 "71300,2,3,1998/10/01 07:03:00,139.7797172074,35.9060965731,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 07:03:00,139.919564985,35.8200181415,2,4,5420020,8 ,1 ,37,,12",
 "234600,2,3,1998/10/01 07:03:00,139.5355219961,35.3918612083,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:03:00,139.4329647862,35.5268040834,1,11,2922005,9 ,1 ,29,,12",
 "246100,2,3,1998/10/01 07:03:00,139.3813569686,35.329730221,1,5,2510201,3 ,1 ,39,,12",
 "303600,2,2,1998/10/01 07:03:00,139.3716888681,35.5785090322,1,9,2923016,9 ,1 ,30,,1",
 "370300,2,3,1998/10/01 07:03:00,139.6732845264,35.8442277804,1,10,3037010,10,1 ,39,,12",
 "386400,2,4,1998/10/01 07:03:00,139.6597183068,35.8553011796,1,10,3059002,8 ,1 ,37,,12",
 "483000,2,2,1998/10/01 07:03:00,139.5938207273,35.9705752138,1,10,3510101,8 ,1 ,27,,1",
 "630200,2,3,1998/10/01 07:03:00,139.6253289697,35.7362787743,1,7,0513010,8 ,1 ,41,,12",
 "653200,2,2,1998/10/01 07:03:00,139.7122724762,35.7400792389,1,8,0531203,9 ,1 ,39,,1",
 "676200,2,3,1998/10/01 07:03:00,139.8294791836,35.728228382,1,13,0623211,10,1 ,35,,12",
 "777400,2,3,1998/10/01 07:03:00,139.449853394,35.6527818633,1,5,0844010,8 ,1 ,41,,12",
 "220800,2,2,1998/10/01 07:04:00,139.5781941332,35.2941769072,2,10,2310102,14,8 ,37,,6",
 "280600,3,3,1998/10/01 07:04:00,139.6276686368,35.5949117505,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 07:04:00,139.6081959994,35.8363783212,1,6,3032201,3 ,8 ,34,,6",
 "105800,2,2,1998/10/01 07:04:00,139.9185087862,35.3446380614,2,2,4612227,12,2 ,32,,1",
 "110400,2,2,1998/10/01 07:04:00,140.0556442477,35.2583619501,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:04:00,140.6916086498,35.8710757223,1,9,4820320,3 ,1 ,16,,6",
 "248400,2,2,1998/10/01 07:04:00,139.3501455284,35.3525398813,1,5,2511002,3 ,1 ,37,,2",
 "294400,2,2,1998/10/01 07:04:00,139.4666267647,35.5335085878,1,4,2920001,3 ,1 ,39,,6",
 "402500,2,2,1998/10/01 07:04:00,139.520276827,35.8176670532,1,9,3116015,9 ,1 ,44,,6",
 "414000,2,2,1998/10/01 07:04:00,139.3968320348,35.7975125629,1,10,3122302,3 ,11,32,,6",
 "752100,2,2,1998/10/01 07:04:00,139.443688402,35.5669680435,1,6,0811001,6 ,1 ,36,,6",
 "839500,2,2,1998/10/01 07:04:00,139.6397754039,35.3627940691,2,11,1133001,14,7 ,27,,1",
 "864800,2,3,1998/10/01 07:04:00,139.5730633535,35.4446140578,1,6,1240102,10,1 ,27,,12",
 "869400,2,2,1998/10/01 07:04:00,139.5681887867,35.3528803308,1,10,1231001,8 ,1 ,29,,6",
 "18400,2,3,1998/10/01 07:04:00,139.9919187169,35.7010340626,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,2,1998/10/01 07:04:00,140.0261959218,35.7388722381,2,4,4128103,8 ,1 ,42,,1",
 "55200,2,3,1998/10/01 07:04:00,139.7957036263,35.7310296182,1,10,4222006,9 ,1 ,38,,12",
 "135700,2,4,1998/10/01 07:04:00,140.1294854963,35.5850684912,1,11,4920108,3 ,1 ,39,,1",
 "78200,2,2,1998/10/01 07:04:00,140.156046728,35.730565594,1,12,4413008,8 ,1 ,44,,6",
 "71300,2,3,1998/10/01 07:04:00,139.7797283861,35.9023235845,1,12,4313309,9 ,1 ,34,,12",
 "234600,2,3,1998/10/01 07:04:00,139.5350410892,35.3980924547,1,14,2412116,10,1 ,63,,12",
 "195500,2,3,1998/10/01 07:04:00,139.9179781771,35.8143383439,2,4,5420020,8 ,1 ,37,,12",
 "303600,2,2,1998/10/01 07:04:00,139.3711794536,35.5790821151,1,9,2923016,9 ,1 ,30,,1",
 "301300,2,3,1998/10/01 07:04:00,139.4362764423,35.5305190359,1,11,2922005,9 ,1 ,29,,12",
 "246100,2,3,1998/10/01 07:04:00,139.3894247303,35.3280886249,1,5,2510201,3 ,1 ,39,,12",
 "386400,2,4,1998/10/01 07:04:00,139.6635843687,35.8524580952,1,10,3059002,8 ,1 ,37,,12",
 "370300,2,3,1998/10/01 07:04:00,139.6765724112,35.8413303478,1,10,3037010,10,1 ,39,,12",
 "483000,2,2,1998/10/01 07:04:00,139.5922447836,35.9694621955,1,10,3510101,8 ,1 ,27,,1",
 "630200,2,3,1998/10/01 07:04:00,139.6357159389,35.7365007133,1,7,0513010,8 ,1 ,41,,12",
 "653200,2,2,1998/10/01 07:04:00,139.7159997281,35.73410111,1,8,0531203,9 ,1 ,39,,1",
 "676200,2,3,1998/10/01 07:04:00,139.8270130733,35.7257418967,1,13,0623211,10,1 ,35,,12",
 "777400,2,3,1998/10/01 07:04:00,139.4510246942,35.6562279357,1,5,0844010,8 ,1 ,41,,12",
 "220800,2,2,1998/10/01 07:05:00,139.5777497024,35.2967186969,2,10,2310102,14,8 ,37,,6",
 "220800,3,3,1998/10/01 07:05:00,139.5777497024,35.2967186969,2,10,2310102,14,3 ,37,,6",
 "280600,3,3,1998/10/01 07:05:00,139.6311382549,35.5979500261,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 07:05:00,139.6059387613,35.8352995611,1,6,3032201,3 ,8 ,34,,6",
 "105800,2,2,1998/10/01 07:05:00,139.9191785216,35.3442534149,2,2,4612227,12,2 ,32,,1",
 "110400,2,2,1998/10/01 07:05:00,140.0510586288,35.2589935422,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:05:00,140.6885313848,35.8752881707,1,9,4820320,3 ,1 ,16,,6",
 "248400,2,2,1998/10/01 07:05:00,139.3507899876,35.3511914089,1,5,2511002,3 ,1 ,37,,2",
 "294400,2,2,1998/10/01 07:05:00,139.4695851662,35.5329872132,1,4,2920001,3 ,1 ,39,,6",
 "402500,2,2,1998/10/01 07:05:00,139.5202376898,35.8150711721,1,9,3116015,9 ,1 ,44,,6",
 "414000,2,2,1998/10/01 07:05:00,139.3979542092,35.7958861465,1,10,3122302,3 ,11,32,,6",
 "752100,2,2,1998/10/01 07:05:00,139.4429048085,35.5690308418,1,6,0811001,6 ,1 ,36,,6",
 "864800,2,3,1998/10/01 07:05:00,139.5788501124,35.4469602587,1,6,1240102,10,1 ,27,,12",
 "839500,2,2,1998/10/01 07:05:00,139.638948434,35.3615120993,2,11,1133001,14,7 ,27,,1",
 "18400,2,3,1998/10/01 07:05:00,139.9865352537,35.7015467566,1,9,4120311,8 ,1 ,44,,12",
 "869400,2,2,1998/10/01 07:05:00,139.5639038223,35.3528929413,1,10,1231001,8 ,1 ,29,,6",
 "55200,2,3,1998/10/01 07:05:00,139.7882591513,35.7318691901,1,10,4222006,9 ,1 ,38,,12",
 "32200,2,2,1998/10/01 07:05:00,140.0262982471,35.7380952188,2,4,4128103,8 ,1 ,42,,1",
 "32200,2,3,1998/10/01 07:05:00,140.0262982471,35.7380952188,2,4,4128103,8 ,1 ,42,,12",
 "78200,2,2,1998/10/01 07:05:00,140.1562309948,35.7287403776,1,12,4413008,8 ,1 ,44,,6",
 "71300,2,3,1998/10/01 07:05:00,139.7809665184,35.8986902674,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 07:05:00,139.9164837366,35.8086425859,2,4,5420020,8 ,1 ,37,,12",
 "135700,2,4,1998/10/01 07:05:00,140.1273518941,35.5866231862,1,11,4920108,3 ,1 ,39,,1",
 "246100,2,3,1998/10/01 07:05:00,139.3976884395,35.327298303,1,5,2510201,3 ,1 ,39,,12",
 "234600,2,3,1998/10/01 07:05:00,139.5338135145,35.4042768252,1,14,2412116,10,1 ,63,,12",
 "303600,2,2,1998/10/01 07:05:00,139.3711490119,35.5796090584,1,9,2923016,9 ,1 ,30,,1",
 "301300,2,3,1998/10/01 07:05:00,139.4396190883,35.5342149608,1,11,2922005,9 ,1 ,29,,12",
 "370300,2,3,1998/10/01 07:05:00,139.6797453539,35.8383476628,1,10,3037010,10,1 ,39,,12",
 "386400,2,4,1998/10/01 07:05:00,139.6672960277,35.8494870781,1,10,3059002,8 ,1 ,37,,12",
 "483000,2,2,1998/10/01 07:05:00,139.5909356579,35.9701870964,1,10,3510101,8 ,1 ,27,,1",
 "630200,2,3,1998/10/01 07:05:00,139.6461657917,35.7376047468,1,7,0513010,8 ,1 ,41,,12",
 "653200,2,2,1998/10/01 07:05:00,139.7111302612,35.7299249782,1,8,0531203,9 ,1 ,39,,1",
 "653200,2,3,1998/10/01 07:05:00,139.7111302612,35.7299249782,1,8,0531203,9 ,1 ,39,,12",
 "676200,2,3,1998/10/01 07:05:00,139.8247532583,35.7231146442,1,13,0623211,10,1 ,35,,12",
 "777400,2,3,1998/10/01 07:05:00,139.4535022489,35.6588568882,1,5,0844010,8 ,1 ,41,,12",
 "220800,3,3,1998/10/01 07:06:00,139.5782741142,35.294079122,2,10,2310102,14,3 ,37,,6",
 "280600,3,3,1998/10/01 07:06:00,139.6344495076,35.601102466,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 07:06:00,139.6036231289,35.8343216329,1,6,3032201,3 ,8 ,34,,6",
 "105800,2,2,1998/10/01 07:06:00,139.9198555355,35.3438773044,2,2,4612227,12,2 ,32,,1",
 "110400,2,2,1998/10/01 07:06:00,140.0467176674,35.2578247144,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:06:00,140.684971564,35.8792265667,1,9,4820320,3 ,1 ,16,,6",
 "248400,2,2,1998/10/01 07:06:00,139.3514112726,35.3498325095,1,5,2511002,3 ,1 ,37,,2",
 "294400,2,2,1998/10/01 07:06:00,139.4725151271,35.5320274501,1,4,2920001,3 ,1 ,39,,6",
 "402500,2,2,1998/10/01 07:06:00,139.5211780929,35.8118790254,1,9,3116015,9 ,1 ,44,,6",
 "414000,2,2,1998/10/01 07:06:00,139.3990224037,35.7942298105,1,10,3122302,3 ,11,32,,6",
 "420900,2,2,1998/10/01 07:06:00,139.467578875,35.9161575144,1,7,3130307,3 ,1 ,39,,6",
 "441600,4,4,1998/10/01 07:06:00,139.908132162,35.8335473384,1,9,3215009,9 ,11,30,,8",
 "752100,2,2,1998/10/01 07:06:00,139.4429258487,35.5711794646,1,6,0811001,6 ,1 ,36,,6",
 "839500,2,2,1998/10/01 07:06:00,139.637505639,35.3610326816,2,11,1133001,14,7 ,27,,1",
 "864800,2,3,1998/10/01 07:06:00,139.5853066764,35.4461813086,1,6,1240102,10,1 ,27,,12",
 "869400,2,2,1998/10/01 07:06:00,139.5598035826,35.3536020893,1,10,1231001,8 ,1 ,29,,6",
 "860200,2,2,1998/10/01 07:06:00,139.4955081181,35.5520086869,1,9,1340109,10,1 ,33,,1",
 "18400,2,3,1998/10/01 07:06:00,139.9811522564,35.7020620908,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:06:00,140.0254132195,35.7337904039,2,4,4128103,8 ,1 ,42,,12",
 "55200,2,3,1998/10/01 07:06:00,139.7808292349,35.7328627025,1,10,4222006,9 ,1 ,38,,12",
 "78200,2,2,1998/10/01 07:06:00,140.1552258011,35.7272968605,1,12,4413008,8 ,1 ,44,,6",
 "135700,2,4,1998/10/01 07:06:00,140.1255633022,35.5884965482,1,11,4920108,3 ,1 ,39,,1",
 "71300,2,3,1998/10/01 07:06:00,139.782621652,35.8951537261,1,12,4313309,9 ,1 ,34,,12",
 "234600,2,3,1998/10/01 07:06:00,139.534971186,35.4104934557,1,14,2412116,10,1 ,63,,12",
 "195500,2,3,1998/10/01 07:06:00,139.9138911172,35.8032458917,2,4,5420020,8 ,1 ,37,,12",
 "303600,2,2,1998/10/01 07:06:00,139.3713272077,35.5801817656,1,9,2923016,9 ,1 ,30,,1",
 "301300,2,3,1998/10/01 07:06:00,139.4423510118,35.5381773199,1,11,2922005,9 ,1 ,29,,12",
 "246100,2,3,1998/10/01 07:06:00,139.4054766126,35.3297297148,1,5,2510201,3 ,1 ,39,,12",
 "386400,2,4,1998/10/01 07:06:00,139.6707955652,35.8463432347,1,10,3059002,8 ,1 ,37,,12",
 "370300,2,3,1998/10/01 07:06:00,139.6828563302,35.8353241579,1,10,3037010,10,1 ,39,,12",
 "483000,2,2,1998/10/01 07:06:00,139.5897018232,35.9715559084,1,10,3510101,8 ,1 ,27,,1",
 "630200,2,3,1998/10/01 07:06:00,139.6566735282,35.7381577812,1,7,0513010,8 ,1 ,41,,12",
 "676200,2,3,1998/10/01 07:06:00,139.8224827359,35.7204937473,1,13,0623211,10,1 ,35,,12",
 "653200,2,3,1998/10/01 07:06:00,139.7126060107,35.7330833362,1,8,0531203,9 ,1 ,39,,12",
 "777400,2,3,1998/10/01 07:06:00,139.4578017387,35.6595461453,1,5,0844010,8 ,1 ,41,,12",
 "220800,3,3,1998/10/01 07:07:00,139.5797812072,35.2911758971,2,10,2310102,14,3 ,37,,6",
 "280600,3,3,1998/10/01 07:07:00,139.6377236942,35.604282225,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 07:07:00,139.6010673938,35.8345898266,1,6,3032201,3 ,8 ,34,,6",
 "105800,2,2,1998/10/01 07:07:00,139.9205325432,35.3435011902,2,2,4612227,12,2 ,32,,1",
 "110400,2,2,1998/10/01 07:07:00,140.0435083607,35.255282087,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:07:00,140.6822778801,35.8833142263,1,9,4820320,3 ,1 ,16,,6",
 "248400,2,2,1998/10/01 07:07:00,139.3517105656,35.3484044375,1,5,2511002,3 ,1 ,37,,2",
 "294400,2,2,1998/10/01 07:07:00,139.4750850865,35.53050644,1,4,2920001,3 ,1 ,39,,6",
 "402500,2,2,1998/10/01 07:07:00,139.5215264928,35.8085797568,1,9,3116015,9 ,1 ,44,,6",
 "414000,2,2,1998/10/01 07:07:00,139.4006358163,35.7929640972,1,10,3122302,3 ,11,32,,6",
 "420900,2,2,1998/10/01 07:07:00,139.4660702551,35.9181977424,1,7,3130307,3 ,1 ,39,,6",
 "441600,4,4,1998/10/01 07:07:00,139.9081803243,35.8319381078,1,9,3215009,9 ,11,30,,8",
 "752100,2,2,1998/10/01 07:07:00,139.4439680305,35.5729371912,1,6,0811001,6 ,1 ,36,,6",
 "839500,2,2,1998/10/01 07:07:00,139.6386375866,35.3609810438,2,11,1133001,14,7 ,27,,1",
 "864800,2,3,1998/10/01 07:07:00,139.5917522514,35.445302667,1,6,1240102,10,1 ,27,,12",
 "869400,2,2,1998/10/01 07:07:00,139.5561883532,35.355451826,1,10,1231001,8 ,1 ,29,,6",
 "860200,2,2,1998/10/01 07:07:00,139.4919122021,35.5525253198,1,9,1340109,10,1 ,33,,1",
 "18400,2,3,1998/10/01 07:07:00,139.975774739,35.7021656961,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:07:00,140.0293583617,35.730460885,2,4,4128103,8 ,1 ,42,,12",
 "55200,2,3,1998/10/01 07:07:00,139.7734060103,35.7338178045,1,10,4222006,9 ,1 ,38,,12",
 "78200,2,2,1998/10/01 07:07:00,140.1547059838,35.7255773818,1,12,4413008,8 ,1 ,44,,6",
 "135700,2,4,1998/10/01 07:07:00,140.1233155962,35.5904403746,1,11,4920108,3 ,1 ,39,,1",
 "71300,2,3,1998/10/01 07:07:00,139.7842719014,35.8916156914,1,12,4313309,9 ,1 ,34,,12",
 "234600,2,3,1998/10/01 07:07:00,139.5402036403,35.4149528951,1,14,2412116,10,1 ,63,,12",
 "195500,2,3,1998/10/01 07:07:00,139.9098282826,35.7984614484,2,4,5420020,8 ,1 ,37,,12",
 "301300,2,3,1998/10/01 07:07:00,139.444008977,35.5425263414,1,11,2922005,9 ,1 ,29,,12",
 "246100,2,3,1998/10/01 07:07:00,139.4131110502,35.3325012769,1,5,2510201,3 ,1 ,39,,12",
 "303600,2,2,1998/10/01 07:07:00,139.3705825234,35.5805944979,1,9,2923016,9 ,1 ,30,,1",
 "386400,2,4,1998/10/01 07:07:00,139.6744013789,35.8432793685,1,10,3059002,8 ,1 ,37,,12",
 "370300,2,3,1998/10/01 07:07:00,139.6859109398,35.8322608214,1,10,3037010,10,1 ,39,,12",
 "483000,2,2,1998/10/01 07:07:00,139.5887169786,35.9729156468,1,10,3510101,8 ,1 ,27,,1",
 "483000,2,3,1998/10/01 07:07:00,139.5887169786,35.9729156468,1,10,3510101,8 ,1 ,27,,12",
 "630200,2,3,1998/10/01 07:07:00,139.6671252031,35.738736999,1,7,0513010,8 ,1 ,41,,12",
 "653200,2,3,1998/10/01 07:07:00,139.7163922613,35.7345499977,1,8,0531203,9 ,1 ,39,,12",
 "676200,2,3,1998/10/01 07:07:00,139.8197530177,35.71818785,1,13,0623211,10,1 ,35,,12",
 "777400,2,3,1998/10/01 07:07:00,139.4621066167,35.6602135831,1,5,0844010,8 ,1 ,41,,12",
 "220800,3,3,1998/10/01 07:08:00,139.5802255012,35.2879958203,2,10,2310102,14,3 ,37,,6",
 "280600,3,3,1998/10/01 07:08:00,139.6409550105,35.6068448525,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 07:08:00,139.5985936141,35.8352860618,1,6,3032201,3 ,8 ,34,,6",
 "105800,2,2,1998/10/01 07:08:00,139.9211300149,35.3430722566,2,2,4612227,12,2 ,32,,1",
 "110400,2,2,1998/10/01 07:08:00,140.0397518301,35.252447308,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:08:00,140.6802116507,35.8871147286,1,9,4820320,3 ,1 ,16,,6",
 "248400,2,2,1998/10/01 07:08:00,139.3520037998,35.3469736342,1,5,2511002,3 ,1 ,37,,2",
 "294400,2,2,1998/10/01 07:08:00,139.4778200774,35.5291888574,1,4,2920001,3 ,1 ,39,,6",
 "402500,2,2,1998/10/01 07:08:00,139.5206727952,35.8062239982,1,9,3116015,9 ,1 ,44,,6",
 "414000,2,2,1998/10/01 07:08:00,139.4010461203,35.7912429084,1,10,3122302,3 ,11,32,,6",
 "420900,2,2,1998/10/01 07:08:00,139.4682346916,35.9208358343,1,7,3130307,3 ,1 ,39,,6",
 "441600,4,4,1998/10/01 07:08:00,139.9072476442,35.8301680107,1,9,3215009,9 ,11,30,,8",
 "752100,2,2,1998/10/01 07:08:00,139.4462794336,35.5739811354,1,6,0811001,6 ,1 ,36,,6",
 "839500,2,2,1998/10/01 07:08:00,139.6388642966,35.3621711644,2,11,1133001,14,7 ,27,,1",
 "860200,2,2,1998/10/01 07:08:00,139.489783239,35.5540255646,1,9,1340109,10,1 ,33,,1",
 "864800,2,3,1998/10/01 07:08:00,139.5980852463,35.4454362778,1,6,1240102,10,1 ,27,,12",
 "869400,2,2,1998/10/01 07:08:00,139.5527744537,35.3575740882,1,10,1231001,8 ,1 ,29,,6",
 "18400,2,3,1998/10/01 07:08:00,139.9704520108,35.7025327346,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:08:00,140.0336705127,35.7274142663,2,4,4128103,8 ,1 ,42,,12",
 "55200,2,3,1998/10/01 07:08:00,139.7699447893,35.7297161906,1,10,4222006,9 ,1 ,38,,12",
 "78200,2,2,1998/10/01 07:08:00,140.1543007781,35.7243351781,1,12,4413008,8 ,1 ,44,,6",
 "135700,2,4,1998/10/01 07:08:00,140.1212448686,35.5923171963,1,11,4920108,3 ,1 ,39,,1",
 "71300,2,3,1998/10/01 07:08:00,139.7859264601,35.8880790113,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 07:08:00,139.9055108595,35.7938188921,2,4,5420020,8 ,1 ,37,,12",
 "234600,2,3,1998/10/01 07:08:00,139.5466610367,35.4183968664,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:08:00,139.4485697192,35.5404847408,1,11,2922005,9 ,1 ,29,,12",
 "246100,2,3,1998/10/01 07:08:00,139.420732469,35.3352943304,1,5,2510201,3 ,1 ,39,,12",
 "303600,2,2,1998/10/01 07:08:00,139.3706925545,35.5812947512,1,9,2923016,9 ,1 ,30,,1",
 "303600,2,3,1998/10/01 07:08:00,139.3706925545,35.5812947512,1,9,2923016,9 ,1 ,30,,12",
 "386400,2,4,1998/10/01 07:08:00,139.6778710681,35.8401145562,1,10,3059002,8 ,1 ,37,,12",
 "370300,2,3,1998/10/01 07:08:00,139.6890244271,35.8292373293,1,10,3037010,10,1 ,39,,12",
 "483000,2,3,1998/10/01 07:08:00,139.5912632919,35.9701891604,1,10,3510101,8 ,1 ,27,,12",
 "630200,2,3,1998/10/01 07:08:00,139.6765001549,35.7352593537,1,7,0513010,8 ,1 ,41,,12",
 "653200,2,3,1998/10/01 07:08:00,139.7205686478,35.7337287137,1,8,0531203,9 ,1 ,39,,12",
 "676200,2,3,1998/10/01 07:08:00,139.8174682013,35.7156142796,1,13,0623211,10,1 ,35,,12",
 "777400,2,3,1998/10/01 07:08:00,139.4659187915,35.6618053289,1,5,0844010,8 ,1 ,41,,12",
 "220800,3,3,1998/10/01 07:09:00,139.5802889025,35.2847823037,2,10,2310102,14,3 ,37,,6",
 "280600,3,3,1998/10/01 07:09:00,139.6455730504,35.6056948177,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 07:09:00,139.5961079375,35.8359536891,1,6,3032201,3 ,8 ,34,,6",
 "105800,2,2,1998/10/01 07:09:00,139.9216043064,35.3425262336,2,2,4612227,12,2 ,32,,1",
 "110400,2,2,1998/10/01 07:09:00,140.0387816552,35.2482966556,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:09:00,140.6750418738,35.8896287198,1,9,4820320,3 ,1 ,16,,6",
 "248400,2,2,1998/10/01 07:09:00,139.3520846906,35.3455252659,1,5,2511002,3 ,1 ,37,,2",
 "294400,2,2,1998/10/01 07:09:00,139.480841922,35.528643594,1,4,2920001,3 ,1 ,39,,6",
 "402500,2,2,1998/10/01 07:09:00,139.5167192467,35.8067807922,1,9,3116015,9 ,1 ,44,,6",
 "414000,2,2,1998/10/01 07:09:00,139.402824308,35.7904715862,1,10,3122302,3 ,11,32,,6",
 "420900,2,2,1998/10/01 07:09:00,139.4705330236,35.9231492786,1,7,3130307,3 ,1 ,39,,6",
 "441600,4,4,1998/10/01 07:09:00,139.9063868012,35.8283735592,1,9,3215009,9 ,11,30,,8",
 "752100,2,2,1998/10/01 07:09:00,139.4483607062,35.5752985454,1,6,0811001,6 ,1 ,36,,6",
 "839500,2,2,1998/10/01 07:09:00,139.6387399258,35.3636129223,2,11,1133001,14,7 ,27,,1",
 "869400,2,2,1998/10/01 07:09:00,139.5496048538,35.3599272871,1,10,1231001,8 ,1 ,29,,6",
 "860200,2,2,1998/10/01 07:09:00,139.4874925724,35.5562399246,1,9,1340109,10,1 ,33,,1",
 "864800,2,3,1998/10/01 07:09:00,139.6025391047,35.4493142516,1,6,1240102,10,1 ,27,,12",
 "18400,2,3,1998/10/01 07:09:00,139.9656977815,35.7046382848,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:09:00,140.0381353428,35.7245165772,2,4,4128103,8 ,1 ,42,,12",
 "55200,2,3,1998/10/01 07:09:00,139.7685160663,35.7304977211,1,10,4222006,9 ,1 ,38,,12",
 "78200,2,2,1998/10/01 07:09:00,140.1557609065,35.7225965301,1,12,4413008,8 ,1 ,44,,6",
 "135700,2,4,1998/10/01 07:09:00,140.1196365555,35.5948991201,1,11,4920108,3 ,1 ,39,,1",
 "71300,2,3,1998/10/01 07:09:00,139.7876436298,35.8845629156,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 07:09:00,139.9023455757,35.7886041125,2,4,5420020,8 ,1 ,37,,12",
 "234600,2,3,1998/10/01 07:09:00,139.553055438,35.4219108261,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:09:00,139.4533447141,35.538056322,1,11,2922005,9 ,1 ,29,,12",
 "246100,2,3,1998/10/01 07:09:00,139.4290317422,35.3359325273,1,5,2510201,3 ,1 ,39,,12",
 "303600,2,3,1998/10/01 07:09:00,139.3749990522,35.579176195,1,9,2923016,9 ,1 ,30,,12",
 "386400,2,4,1998/10/01 07:09:00,139.6812688852,35.8368975933,1,10,3059002,8 ,1 ,37,,12",
 "370300,2,3,1998/10/01 07:09:00,139.692105856,35.8261924588,1,10,3037010,10,1 ,39,,12",
 "483000,2,3,1998/10/01 07:09:00,139.5937907809,35.9672303034,1,10,3510101,8 ,1 ,27,,12",
 "630200,2,3,1998/10/01 07:09:00,139.6841144887,35.729319121,1,7,0513010,8 ,1 ,41,,12",
 "676200,2,3,1998/10/01 07:09:00,139.8156882809,35.7127546877,1,13,0623211,10,1 ,35,,12",
 "653200,2,3,1998/10/01 07:09:00,139.7246742899,35.7326413011,1,8,0531203,9 ,1 ,39,,12",
 "777400,2,3,1998/10/01 07:09:00,139.4674641818,35.6650959741,1,5,0844010,8 ,1 ,41,,12",
 "220800,3,3,1998/10/01 07:10:00,139.5797014286,35.281617502,2,10,2310102,14,3 ,37,,6",
 "280600,3,3,1998/10/01 07:10:00,139.6487351705,35.6078646604,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 07:10:00,139.5936717997,35.8367133547,1,6,3032201,3 ,8 ,34,,6",
 "105800,2,2,1998/10/01 07:10:00,139.9220785915,35.3419802087,2,2,4612227,12,2 ,32,,1",
 "105800,3,3,1998/10/01 07:10:00,139.9220785915,35.3419802087,2,2,4612227,12,97,32,,97",
 "110400,2,2,1998/10/01 07:10:00,140.0372005251,35.244238276,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:10:00,140.6698785669,35.8921517608,1,9,4820320,3 ,1 ,16,,6",
 "248400,2,2,1998/10/01 07:10:00,139.3530728143,35.3445633214,1,5,2511002,3 ,1 ,37,,2",
 "294400,2,2,1998/10/01 07:10:00,139.483936379,35.5283154151,1,4,2920001,3 ,1 ,39,,6",
 "402500,2,2,1998/10/01 07:10:00,139.5174319806,35.808914972,1,9,3116015,9 ,1 ,44,,6",
 "402500,3,3,1998/10/01 07:10:00,139.5174319806,35.808914972,1,9,3116015,9 ,97,44,,97",
 "414000,2,2,1998/10/01 07:10:00,139.4048976067,35.7898158903,1,10,3122302,3 ,11,32,,6",
 "420900,2,2,1998/10/01 07:10:00,139.4709943937,35.9262044525,1,7,3130307,3 ,1 ,39,,6",
 "441600,4,4,1998/10/01 07:10:00,139.9055345664,35.8265781541,1,9,3215009,9 ,11,30,,8",
 "752100,2,2,1998/10/01 07:10:00,139.4491506186,35.577350951,1,6,0811001,6 ,1 ,36,,6",
 "869400,2,2,1998/10/01 07:10:00,139.5456124598,35.3608237288,1,10,1231001,8 ,1 ,29,,6",
 "839500,2,2,1998/10/01 07:10:00,139.6377944923,35.3647292159,2,11,1133001,14,7 ,27,,1",
 "860200,2,2,1998/10/01 07:10:00,139.4866567405,35.5579963187,1,9,1340109,10,1 ,33,,1",
 "860200,2,3,1998/10/01 07:10:00,139.4866567405,35.5579963187,1,9,1340109,10,1 ,33,,12",
 "864800,2,3,1998/10/01 07:10:00,139.6076296775,35.4526289391,1,6,1240102,10,1 ,27,,12",
 "18400,2,3,1998/10/01 07:10:00,139.9607814187,35.7064806334,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:10:00,140.0421704637,35.7214527899,2,4,4128103,8 ,1 ,42,,12",
 "55200,2,3,1998/10/01 07:10:00,139.7644275342,35.7349349757,1,10,4222006,9 ,1 ,38,,12",
 "71300,2,3,1998/10/01 07:10:00,139.7886566874,35.8808718494,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,2,1998/10/01 07:10:00,140.1565154196,35.7207600776,1,12,4413008,8 ,1 ,44,,6",
 "135700,2,4,1998/10/01 07:10:00,140.1177909578,35.5973716841,1,11,4920108,3 ,1 ,39,,1",
 "195500,2,3,1998/10/01 07:10:00,139.9002445335,35.7830392087,2,4,5420020,8 ,1 ,37,,12",
 "246100,2,3,1998/10/01 07:10:00,139.4373654099,35.3363719628,1,5,2510201,3 ,1 ,39,,12",
 "234600,2,3,1998/10/01 07:10:00,139.5565294007,35.4274677277,1,14,2412116,10,1 ,63,,12",
 "303600,2,3,1998/10/01 07:10:00,139.3792269487,35.5769426228,1,9,2923016,9 ,1 ,30,,12",
 "301300,2,3,1998/10/01 07:10:00,139.4587021902,35.5370991338,1,11,2922005,9 ,1 ,29,,12",
 "370300,2,3,1998/10/01 07:10:00,139.6954559761,35.8233545425,1,10,3037010,10,1 ,39,,12",
 "386400,2,4,1998/10/01 07:10:00,139.6845640569,35.8336116333,1,10,3059002,8 ,1 ,37,,12",
 "483000,2,3,1998/10/01 07:10:00,139.5963040373,35.9642635005,1,10,3510101,8 ,1 ,27,,12",
 "653200,2,3,1998/10/01 07:10:00,139.7287900231,35.7315802319,1,8,0531203,9 ,1 ,39,,12",
 "630200,2,3,1998/10/01 07:10:00,139.6939748917,35.7265568997,1,7,0513010,8 ,1 ,41,,12",
 "676200,2,3,1998/10/01 07:10:00,139.8128936482,35.7105268122,1,13,0623211,10,1 ,35,,12",
 "777400,2,3,1998/10/01 07:10:00,139.4684736339,35.668575992,1,5,0844010,8 ,1 ,41,,12",
 "220800,3,3,1998/10/01 07:11:00,139.5791594961,35.2785382655,2,10,2310102,14,3 ,37,,6",
 "280600,3,3,1998/10/01 07:11:00,139.652277098,35.6107962376,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 07:11:00,139.5916801422,35.8380836641,1,6,3032201,3 ,8 ,34,,6",
 "110400,2,2,1998/10/01 07:11:00,140.0352598854,35.2403186108,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:11:00,140.6653105984,35.8952348204,1,9,4820320,3 ,1 ,16,,6",
 "165600,2,2,1998/10/01 07:11:00,140.2668607263,36.2254663083,1,12,5011018,15,6 ,33,,7",
 "248400,2,2,1998/10/01 07:11:00,139.3548402665,35.344518172,1,5,2511002,3 ,1 ,37,,2",
 "294400,2,2,1998/10/01 07:11:00,139.4869013642,35.5287478279,1,4,2920001,3 ,1 ,39,,6",
 "414000,2,2,1998/10/01 07:11:00,139.4071305667,35.7901814679,1,10,3122302,3 ,11,32,,6",
 "420900,2,2,1998/10/01 07:11:00,139.4726909343,35.9289140316,1,7,3130307,3 ,1 ,39,,6",
 "441600,4,4,1998/10/01 07:11:00,139.9054507514,35.8250174104,1,9,3215009,9 ,11,30,,8",
 "752100,2,2,1998/10/01 07:11:00,139.4492541504,35.5794948837,1,6,0811001,6 ,1 ,36,,6",
 "839500,2,2,1998/10/01 07:11:00,139.6375421885,35.3660152338,2,11,1133001,14,7 ,27,,1",
 "864800,2,3,1998/10/01 07:11:00,139.6128360973,35.4558278901,1,6,1240102,10,1 ,27,,12",
 "869400,2,2,1998/10/01 07:11:00,139.5414184562,35.3614786682,1,10,1231001,8 ,1 ,29,,6",
 "860200,2,3,1998/10/01 07:11:00,139.4882636318,35.5545278138,1,9,1340109,10,1 ,33,,12",
 "18400,2,3,1998/10/01 07:11:00,139.9561792526,35.7088134882,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:11:00,140.0369609137,35.7199177711,2,4,4128103,8 ,1 ,42,,12",
 "55200,2,3,1998/10/01 07:11:00,139.7592887136,35.7394208175,1,10,4222006,9 ,1 ,38,,12",
 "78200,2,2,1998/10/01 07:11:00,140.1568125206,35.7218782397,1,12,4413008,8 ,1 ,44,,6",
 "78200,2,3,1998/10/01 07:11:00,140.1568125206,35.7218782397,1,12,4413008,8 ,1 ,44,,12",
 "135700,2,4,1998/10/01 07:11:00,140.1157122038,35.5997188174,1,11,4920108,3 ,1 ,39,,1",
 "71300,2,3,1998/10/01 07:11:00,139.7894931195,35.8771492859,1,12,4313309,9 ,1 ,34,,12",
 "234600,2,3,1998/10/01 07:11:00,139.5574760306,35.4336831042,1,14,2412116,10,1 ,63,,12",
 "195500,2,3,1998/10/01 07:11:00,139.898212382,35.7774537505,2,4,5420020,8 ,1 ,37,,12",
 "227700,2,2,1998/10/01 07:11:00,139.4782455225,35.3186741788,1,9,2410203,8 ,10,16,,1",
 "303600,2,3,1998/10/01 07:11:00,139.3834546113,35.5747088998,1,9,2923016,9 ,1 ,30,,12",
 "301300,2,3,1998/10/01 07:11:00,139.4643253256,35.5371653843,1,11,2922005,9 ,1 ,29,,12",
 "246100,2,3,1998/10/01 07:11:00,139.4456919241,35.3368882673,1,5,2510201,3 ,1 ,39,,12",
 "246100,2,4,1998/10/01 07:11:00,139.4456919241,35.3368882673,1,5,2510201,3 ,1 ,39,,1",
 "386400,2,4,1998/10/01 07:11:00,139.6878803192,35.8303387659,1,10,3059002,8 ,1 ,37,,12",
 "370300,2,3,1998/10/01 07:11:00,139.6990411938,35.8207010474,1,10,3037010,10,1 ,39,,12",
 "476100,2,2,1998/10/01 07:11:00,139.4208663445,35.9692184768,1,3,3411213,13,2 ,34,,2",
 "483000,2,3,1998/10/01 07:11:00,139.598245143,35.961043666,1,10,3510101,8 ,1 ,27,,12",
 "630200,2,3,1998/10/01 07:11:00,139.7041534219,35.7254739287,1,7,0513010,8 ,1 ,41,,12",
 "676200,2,3,1998/10/01 07:11:00,139.8095924848,35.7088657526,1,13,0623211,10,1 ,35,,12",
 "653200,2,3,1998/10/01 07:11:00,139.7329878053,35.7309188317,1,8,0531203,9 ,1 ,39,,12",
 "777400,2,3,1998/10/01 07:11:00,139.4701537343,35.6718044089,1,5,0844010,8 ,1 ,41,,12",
 "220800,3,3,1998/10/01 07:12:00,139.5789058682,35.2753347279,2,10,2310102,14,3 ,37,,6",
 "280600,3,3,1998/10/01 07:12:00,139.6564941747,35.6129864424,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 07:12:00,139.5893962292,35.8389387629,1,6,3032201,3 ,8 ,34,,6",
 "110400,2,2,1998/10/01 07:12:00,140.0324543767,35.2367293645,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:12:00,140.6623284891,35.8994920552,1,9,4820320,3 ,1 ,16,,6",
 "165600,2,2,1998/10/01 07:12:00,140.269123763,36.2234125506,1,12,5011018,15,6 ,33,,7",
 "248400,2,2,1998/10/01 07:12:00,139.3553726135,35.3433620456,1,5,2511002,3 ,1 ,37,,2",
 "294400,2,2,1998/10/01 07:12:00,139.4898921774,35.52899608,1,4,2920001,3 ,1 ,39,,6",
 "414000,2,2,1998/10/01 07:12:00,139.4094207822,35.7900475547,1,10,3122302,3 ,11,32,,6",
 "420900,2,2,1998/10/01 07:12:00,139.4737966789,35.9319676693,1,7,3130307,3 ,1 ,39,,6",
 "441600,4,4,1998/10/01 07:12:00,139.9045997042,35.8234509907,1,9,3215009,9 ,11,30,,8",
 "752100,2,2,1998/10/01 07:12:00,139.4488827635,35.5816318382,1,6,0811001,6 ,1 ,36,,6",
 "839500,2,2,1998/10/01 07:12:00,139.6357723902,35.3659877904,2,11,1133001,14,7 ,27,,1",
 "864800,2,3,1998/10/01 07:12:00,139.6168628096,35.4600348086,1,6,1240102,10,1 ,27,,12",
 "869400,2,2,1998/10/01 07:12:00,139.5373891284,35.3604722675,1,10,1231001,8 ,1 ,29,,6",
 "860200,2,3,1998/10/01 07:12:00,139.4901081364,35.5511693342,1,9,1340109,10,1 ,33,,12",
 "18400,2,3,1998/10/01 07:12:00,139.9513847073,35.7108732958,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:12:00,140.0317821433,35.7179677497,2,4,4128103,8 ,1 ,42,,12",
 "55200,2,3,1998/10/01 07:12:00,139.7526410829,35.7391318371,1,10,4222006,9 ,1 ,38,,12",
 "78200,2,3,1998/10/01 07:12:00,140.1519189812,35.7207397032,1,12,4413008,8 ,1 ,44,,12",
 "135700,2,4,1998/10/01 07:12:00,140.1135348446,35.6020043165,1,11,4920108,3 ,1 ,39,,1",
 "71300,2,3,1998/10/01 07:12:00,139.7903294995,35.8734267181,1,12,4313309,9 ,1 ,34,,12",
 "227700,2,2,1998/10/01 07:12:00,139.4774784285,35.3182009155,1,9,2410203,8 ,10,16,,1",
 "234600,2,3,1998/10/01 07:12:00,139.5618924771,35.4388385086,1,14,2412116,10,1 ,63,,12",
 "195500,2,3,1998/10/01 07:12:00,139.8952899212,35.7721974334,2,4,5420020,8 ,1 ,37,,12",
 "303600,2,3,1998/10/01 07:12:00,139.3876841879,35.5724777514,1,9,2923016,9 ,1 ,30,,12",
 "301300,2,3,1998/10/01 07:12:00,139.4698804885,35.5367123264,1,11,2922005,9 ,1 ,29,,12",
 "246100,2,4,1998/10/01 07:12:00,139.4470233372,35.3365995427,1,5,2510201,3 ,1 ,39,,1",
 "386400,2,4,1998/10/01 07:12:00,139.6912248359,35.8270851234,1,10,3059002,8 ,1 ,37,,12",
 "370300,2,3,1998/10/01 07:12:00,139.7021291504,35.817662457,1,10,3037010,10,1 ,39,,12",
 "476100,2,2,1998/10/01 07:12:00,139.4216454548,35.9663744448,1,3,3411213,13,2 ,34,,2",
 "483000,2,3,1998/10/01 07:12:00,139.5996925431,35.9576391659,1,10,3510101,8 ,1 ,27,,12",
 "630200,2,3,1998/10/01 07:12:00,139.7109861924,35.7284006906,1,7,0513010,8 ,1 ,41,,12",
 "676200,2,3,1998/10/01 07:12:00,139.8056622663,35.7087184902,1,13,0623211,10,1 ,35,,12",
 "653200,2,3,1998/10/01 07:12:00,139.7369569795,35.7322319346,1,8,0531203,9 ,1 ,39,,12",
 "777400,2,3,1998/10/01 07:12:00,139.4743237963,35.6728377928,1,5,0844010,8 ,1 ,41,,12",
 "220800,3,3,1998/10/01 07:13:00,139.5804218336,35.27266068,2,10,2310102,14,3 ,37,,6",
 "280600,3,3,1998/10/01 07:13:00,139.6614141889,35.6138194637,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 07:13:00,139.5869510112,35.8382493778,1,6,3032201,3 ,8 ,34,,6",
 "110400,2,2,1998/10/01 07:13:00,140.0295486217,35.2332440551,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:13:00,140.659415758,35.9037537768,1,9,4820320,3 ,1 ,16,,6",
 "165600,2,2,1998/10/01 07:13:00,140.2696374434,36.2206531001,1,12,5011018,15,6 ,33,,7",
 "248400,2,2,1998/10/01 07:13:00,139.3561647051,35.3426837908,1,5,2511002,3 ,1 ,37,,2",
 "294400,2,2,1998/10/01 07:13:00,139.4928672285,35.5295887352,1,4,2920001,3 ,1 ,39,,6",
 "414000,2,2,1998/10/01 07:13:00,139.4117082174,35.7898875774,1,10,3122302,3 ,11,32,,6",
 "420900,2,2,1998/10/01 07:13:00,139.4762218177,35.934558605,1,7,3130307,3 ,1 ,39,,6",
 "441600,4,4,1998/10/01 07:13:00,139.9041223107,35.8215645942,1,9,3215009,9 ,11,30,,8",
 "752100,2,2,1998/10/01 07:13:00,139.4489984707,35.5837701945,1,6,0811001,6 ,1 ,36,,6",
 "839500,2,2,1998/10/01 07:13:00,139.6358700459,35.3672628739,2,11,1133001,14,7 ,27,,1",
 "864800,2,3,1998/10/01 07:13:00,139.6208464483,35.4642738836,1,6,1240102,10,1 ,27,,12",
 "869400,2,2,1998/10/01 07:13:00,139.5340951891,35.3582453344,1,10,1231001,8 ,1 ,29,,6",
 "860200,2,3,1998/10/01 07:13:00,139.490696936,35.5474327638,1,9,1340109,10,1 ,33,,12",
 "18400,2,3,1998/10/01 07:13:00,139.9465318033,35.7128430388,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:13:00,140.0266966746,35.7158541893,2,4,4128103,8 ,1 ,42,,12",
 "55200,2,3,1998/10/01 07:13:00,139.7461609711,35.7360128273,1,10,4222006,9 ,1 ,38,,12",
 "78200,2,3,1998/10/01 07:13:00,140.1477319636,35.7184926972,1,12,4413008,8 ,1 ,44,,12",
 "135700,2,4,1998/10/01 07:13:00,140.1113243179,35.6042697362,1,11,4920108,3 ,1 ,39,,1",
 "71300,2,3,1998/10/01 07:13:00,139.7911658367,35.8697041474,1,12,4313309,9 ,1 ,34,,12",
 "227700,2,2,1998/10/01 07:13:00,139.4766240407,35.3186769593,1,9,2410203,8 ,10,16,,1",
 "234600,2,3,1998/10/01 07:13:00,139.5683590701,35.4422557167,1,14,2412116,10,1 ,63,,12",
 "195500,2,3,1998/10/01 07:13:00,139.8890675839,35.7695888108,2,4,5420020,8 ,1 ,37,,12",
 "301300,2,3,1998/10/01 07:13:00,139.4745564362,35.5342444267,1,11,2922005,9 ,1 ,29,,12",
 "246100,2,4,1998/10/01 07:13:00,139.4484313062,35.3366638343,1,5,2510201,3 ,1 ,39,,1",
 "303600,2,3,1998/10/01 07:13:00,139.3919231798,35.5702586973,1,9,2923016,9 ,1 ,30,,12",
 "386400,2,4,1998/10/01 07:13:00,139.6946814248,35.8239211549,1,10,3059002,8 ,1 ,37,,12",
 "370300,2,3,1998/10/01 07:13:00,139.7051993251,35.8146104627,1,10,3037010,10,1 ,39,,12",
 "476100,2,2,1998/10/01 07:13:00,139.4215294532,35.966799219,1,3,3411213,13,2 ,34,,2",
 "483000,2,3,1998/10/01 07:13:00,139.6011175925,35.9542283059,1,10,3510101,8 ,1 ,27,,12",
 "630200,2,3,1998/10/01 07:13:00,139.7143428299,35.7362845339,1,7,0513010,8 ,1 ,41,,12",
 "653200,2,3,1998/10/01 07:13:00,139.7407591107,35.7338986585,1,8,0531203,9 ,1 ,39,,12",
 "676200,2,3,1998/10/01 07:13:00,139.8017995844,35.7086722218,1,13,0623211,10,1 ,35,,12",
 "777400,2,3,1998/10/01 07:13:00,139.4786339103,35.672477897,1,5,0844010,8 ,1 ,41,,12",
 "220800,3,3,1998/10/01 07:14:00,139.5836314894,35.2710527899,2,10,2310102,14,3 ,37,,6",
 "280600,3,3,1998/10/01 07:14:00,139.6664954621,35.6138156056,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 07:14:00,139.5855009017,35.839308734,1,6,3032201,3 ,8 ,34,,6",
 "110400,2,2,1998/10/01 07:14:00,140.0294722711,35.2289334787,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:14:00,140.6572366022,35.9082575953,1,9,4820320,3 ,1 ,16,,6",
 "165600,2,2,1998/10/01 07:14:00,140.2714038414,36.2182576501,1,12,5011018,15,6 ,33,,7",
 "248400,2,2,1998/10/01 07:14:00,139.3561575384,35.3412344101,1,5,2511002,3 ,1 ,37,,2",
 "294400,2,2,1998/10/01 07:14:00,139.49596225,35.5301207997,1,4,2920001,3 ,1 ,39,,6",
 "414000,2,2,1998/10/01 07:14:00,139.4140017318,35.7897939869,1,10,3122302,3 ,11,32,,6",
 "420900,2,2,1998/10/01 07:14:00,139.477864178,35.9372721009,1,7,3130307,3 ,1 ,39,,6",
 "441600,4,4,1998/10/01 07:14:00,139.9036122437,35.8196831219,1,9,3215009,9 ,11,30,,8",
 "752100,2,2,1998/10/01 07:14:00,139.4480254305,35.585449458,1,6,0811001,6 ,1 ,36,,6",
 "839500,2,2,1998/10/01 07:14:00,139.6342546763,35.3676275965,2,11,1133001,14,7 ,27,,1",
 "869400,2,2,1998/10/01 07:14:00,139.5326053029,35.3549567514,1,10,1231001,8 ,1 ,29,,6",
 "860200,2,3,1998/10/01 07:14:00,139.4932259996,35.5443771908,1,9,1340109,10,1 ,33,,12",
 "864800,2,3,1998/10/01 07:14:00,139.6246255843,35.4686359078,1,6,1240102,10,1 ,27,,12",
 "55200,2,3,1998/10/01 07:14:00,139.7394053689,35.7333058911,1,10,4222006,9 ,1 ,38,,12",
 "18400,2,3,1998/10/01 07:14:00,139.9416963363,35.714841332,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:14:00,140.0216394543,35.7136962915,2,4,4128103,8 ,1 ,42,,12",
 "78200,2,3,1998/10/01 07:14:00,140.1430060385,35.7171621585,1,12,4413008,8 ,1 ,44,,12",
 "135700,2,4,1998/10/01 07:14:00,140.1090035573,35.6044638362,1,11,4920108,3 ,1 ,39,,1",
 "71300,2,3,1998/10/01 07:14:00,139.7919912374,35.8659799788,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 07:14:00,139.8820403728,35.7689918083,2,4,5420020,8 ,1 ,37,,12",
 "227700,2,2,1998/10/01 07:14:00,139.4757672691,35.3191921563,1,9,2410203,8 ,10,16,,1",
 "234600,2,3,1998/10/01 07:14:00,139.5749331856,35.4455513487,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:14:00,139.4789482945,35.5314360408,1,11,2922005,9 ,1 ,29,,12",
 "246100,2,4,1998/10/01 07:14:00,139.4496793228,35.3364644927,1,5,2510201,3 ,1 ,39,,1",
 "303600,2,3,1998/10/01 07:14:00,139.3961891027,35.5680751022,1,9,2923016,9 ,1 ,30,,12",
 "386400,2,4,1998/10/01 07:14:00,139.6985561419,35.8210844511,1,10,3059002,8 ,1 ,37,,12",
 "370300,2,3,1998/10/01 07:14:00,139.7082259488,35.8115292759,1,10,3037010,10,1 ,39,,12",
 "476100,2,2,1998/10/01 07:14:00,139.4216215154,35.9664820681,1,3,3411213,13,2 ,34,,2",
 "483000,2,3,1998/10/01 07:14:00,139.6030913792,35.9510223285,1,10,3510101,8 ,1 ,27,,12",
 "630200,2,3,1998/10/01 07:14:00,139.7165042297,35.7442987993,1,7,0513010,8 ,1 ,41,,12",
 "653200,2,3,1998/10/01 07:14:00,139.74463802,35.7354426125,1,8,0531203,9 ,1 ,39,,12",
 "676200,2,3,1998/10/01 07:14:00,139.7981777795,35.7097248395,1,13,0623211,10,1 ,35,,12",
 "777400,2,3,1998/10/01 07:14:00,139.4826856971,35.6717103103,1,5,0844010,8 ,1 ,41,,12",
 "220800,3,3,1998/10/01 07:15:00,139.5848318519,35.2683294239,2,10,2310102,14,3 ,37,,6",
 "280600,3,3,1998/10/01 07:15:00,139.6711005163,35.6154925328,1,11,2910009,8 ,1 ,42,,8",
 "363400,2,3,1998/10/01 07:15:00,139.5843714705,35.8396974134,1,6,3032201,3 ,8 ,34,,6",
 "363400,3,4,1998/10/01 07:15:00,139.5843714705,35.8396974134,1,6,3032201,3 ,97,34,,97",
 "110400,2,2,1998/10/01 07:15:00,140.0264044809,35.2259666209,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:15:00,140.6578183882,35.9131218732,1,9,4820320,3 ,1 ,16,,6",
 "165600,2,2,1998/10/01 07:15:00,140.272005393,36.21546642,1,12,5011018,15,6 ,33,,7",
 "248400,2,2,1998/10/01 07:15:00,139.3565650707,35.3398225852,1,5,2511002,3 ,1 ,37,,2",
 "294400,2,2,1998/10/01 07:15:00,139.4991292562,35.5302969248,1,4,2920001,3 ,1 ,39,,6",
 "414000,2,2,1998/10/01 07:15:00,139.4162825372,35.7896291971,1,10,3122302,3 ,11,32,,6",
 "420900,2,2,1998/10/01 07:15:00,139.4781990361,35.9405117816,1,7,3130307,3 ,1 ,39,,6",
 "441600,4,4,1998/10/01 07:15:00,139.9031601596,35.8177918632,1,9,3215009,9 ,11,30,,8",
 "752100,2,2,1998/10/01 07:15:00,139.4462971478,35.5868285671,1,6,0811001,6 ,1 ,36,,6",
 "752100,3,3,1998/10/01 07:15:00,139.4462971478,35.5868285671,1,6,0811001,6 ,97,36,,97",
 "775100,2,2,1998/10/01 07:15:00,139.2923595155,35.6737633595,1,7,0846102,9 ,1 ,38,,2",
 "839500,2,2,1998/10/01 07:15:00,139.6325751738,35.3679944322,2,11,1133001,14,7 ,27,,1",
 "869400,2,2,1998/10/01 07:15:00,139.5316026529,35.3515687941,1,10,1231001,8 ,1 ,29,,6",
 "860200,2,3,1998/10/01 07:15:00,139.4936227577,35.5407459146,1,9,1340109,10,1 ,33,,12",
 "864800,2,3,1998/10/01 07:15:00,139.6283792791,35.4730133843,1,6,1240102,10,1 ,27,,12",
 "18400,2,3,1998/10/01 07:15:00,139.9368823248,35.7168741079,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:15:00,140.0162952008,35.7122868948,2,4,4128103,8 ,1 ,42,,12",
 "55200,2,3,1998/10/01 07:15:00,139.7325582849,35.7308797744,1,10,4222006,9 ,1 ,38,,12",
 "135700,2,4,1998/10/01 07:15:00,140.1058826989,35.6032746861,1,11,4920108,3 ,1 ,39,,1",
 "78200,2,3,1998/10/01 07:15:00,140.1379554564,35.7166289995,1,12,4413008,8 ,1 ,44,,12",
 "71300,2,3,1998/10/01 07:15:00,139.7928011638,35.8622535476,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 07:15:00,139.8749427007,35.7696713848,2,4,5420020,8 ,1 ,37,,12",
 "227700,2,2,1998/10/01 07:15:00,139.4747868347,35.3194969247,1,9,2410203,8 ,10,16,,1",
 "246100,2,4,1998/10/01 07:15:00,139.4508769486,35.3358508798,1,5,2510201,3 ,1 ,39,,1",
 "234600,2,3,1998/10/01 07:15:00,139.5821834344,35.4466069289,1,14,2412116,10,1 ,63,,12",
 "303600,2,3,1998/10/01 07:15:00,139.4004355276,35.5658662904,1,9,2923016,9 ,1 ,30,,12",
 "301300,2,3,1998/10/01 07:15:00,139.48452098,35.53122992,1,11,2922005,9 ,1 ,29,,12",
 "386400,2,4,1998/10/01 07:15:00,139.7019318804,35.8178581295,1,10,3059002,8 ,1 ,37,,12",
 "370300,2,3,1998/10/01 07:15:00,139.7112814003,35.8084671536,1,10,3037010,10,1 ,39,,12",
 "476100,2,2,1998/10/01 07:15:00,139.4225339476,35.963664364,1,3,3411213,13,2 ,34,,2",
 "483000,2,3,1998/10/01 07:15:00,139.6055935989,35.9480497006,1,10,3510101,8 ,1 ,27,,12",
 "653200,2,3,1998/10/01 07:15:00,139.7484754411,35.7370460315,1,8,0531203,9 ,1 ,39,,12",
 "630200,2,3,1998/10/01 07:15:00,139.706856675,35.747126345,1,7,0513010,8 ,1 ,41,,12",
 "676200,2,3,1998/10/01 07:15:00,139.7953800676,35.7076399172,1,13,0623211,10,1 ,35,,12",
 "777400,2,3,1998/10/01 07:15:00,139.4869546145,35.6709008123,1,5,0844010,8 ,1 ,41,,12",
 "220800,3,3,1998/10/01 07:16:00,139.5836533502,35.265499545,2,10,2310102,14,3 ,37,,6",
 "280600,3,3,1998/10/01 07:16:00,139.675138644,35.6180151197,1,11,2910009,8 ,1 ,42,,8",
 "110400,2,2,1998/10/01 07:16:00,140.021657639,35.2243779201,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:16:00,140.6584077725,35.9179974636,1,9,4820320,3 ,1 ,16,,6",
 "165600,2,2,1998/10/01 07:16:00,140.2717539776,36.2126125961,1,12,5011018,15,6 ,33,,7",
 "248400,2,2,1998/10/01 07:16:00,139.3569959266,35.3384150838,1,5,2511002,3 ,1 ,37,,2",
 "294400,2,2,1998/10/01 07:16:00,139.5019449905,35.5298250567,1,4,2920001,3 ,1 ,39,,6",
 "414000,2,2,1998/10/01 07:16:00,139.4185779702,35.7896095287,1,10,3122302,3 ,11,32,,6",
 "420900,2,2,1998/10/01 07:16:00,139.4776367476,35.9437357494,1,7,3130307,3 ,1 ,39,,6",
 "441600,4,4,1998/10/01 07:16:00,139.9026293399,35.8159149297,1,9,3215009,9 ,11,30,,8",
 "736000,2,2,1998/10/01 07:16:00,139.4390703812,35.7232051209,1,13,0780004,5 ,1 ,25,,2",
 "775100,2,2,1998/10/01 07:16:00,139.2944522865,35.673642612,1,7,0846102,9 ,1 ,38,,2",
 "839500,2,2,1998/10/01 07:16:00,139.6312005967,35.3674481043,2,11,1133001,14,7 ,27,,1",
 "860200,2,3,1998/10/01 07:16:00,139.4923732633,35.537119334,1,9,1340109,10,1 ,33,,12",
 "864800,2,3,1998/10/01 07:16:00,139.6322861328,35.4772999855,1,6,1240102,10,1 ,27,,12",
 "869400,2,2,1998/10/01 07:16:00,139.530088091,35.3525405597,1,10,1231001,8 ,1 ,29,,6",
 "18400,2,3,1998/10/01 07:16:00,139.9320680684,35.7189066909,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:16:00,140.01060907,35.7126180178,2,4,4128103,8 ,1 ,42,,12",
 "55200,2,3,1998/10/01 07:16:00,139.7253136121,35.7324719561,1,10,4222006,9 ,1 ,38,,12",
 "78200,2,3,1998/10/01 07:16:00,140.1329047332,35.7160969472,1,12,4413008,8 ,1 ,44,,12",
 "135700,2,4,1998/10/01 07:16:00,140.1031532399,35.6014247538,1,11,4920108,3 ,1 ,39,,1",
 "71300,2,3,1998/10/01 07:16:00,139.7936541711,35.8585335279,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 07:16:00,139.8678412891,35.7692097842,2,4,5420020,8 ,1 ,37,,12",
 "227700,2,2,1998/10/01 07:16:00,139.4737612125,35.3197247979,1,9,2410203,8 ,10,16,,1",
 "234600,2,3,1998/10/01 07:16:00,139.589788484,35.4455704004,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:16:00,139.490138989,35.5314315496,1,11,2922005,9 ,1 ,29,,12",
 "246100,2,4,1998/10/01 07:16:00,139.4520745563,35.3352372548,1,5,2510201,3 ,1 ,39,,1",
 "303600,2,3,1998/10/01 07:16:00,139.4046677109,35.5636390084,1,9,2923016,9 ,1 ,30,,12",
 "386400,2,4,1998/10/01 07:16:00,139.7052327646,35.8145756759,1,10,3059002,8 ,1 ,37,,12",
 "370300,2,3,1998/10/01 07:16:00,139.7143462964,35.8054113089,1,10,3037010,10,1 ,39,,12",
 "476100,2,2,1998/10/01 07:16:00,139.4222394219,35.9641736219,1,3,3411213,13,2 ,34,,2",
 "483000,2,3,1998/10/01 07:16:00,139.6080346263,35.9450455625,1,10,3510101,8 ,1 ,27,,12",
 "630200,2,3,1998/10/01 07:16:00,139.6998990809,35.7526934912,1,7,0513010,8 ,1 ,41,,12",
 "653200,2,3,1998/10/01 07:16:00,139.7521541434,35.7388880316,1,8,0531203,9 ,1 ,39,,12",
 "683100,2,2,1998/10/01 07:16:00,139.9045794868,35.6949205532,2,3,0633106,12,2 ,49,,2",
 "676200,2,3,1998/10/01 07:16:00,139.793137733,35.7050142901,1,13,0623211,10,1 ,35,,12",
 "747500,2,2,1998/10/01 07:16:00,139.4650910893,35.5384999746,1,5,0810007,4 ,1 ,38,,3",
 "777400,2,3,1998/10/01 07:16:00,139.49122077,35.6700822751,1,5,0844010,8 ,1 ,41,,12",
 "220800,3,3,1998/10/01 07:17:00,139.5849192674,35.2625119122,2,10,2310102,14,3 ,37,,6",
 "280600,3,3,1998/10/01 07:17:00,139.6796494633,35.61993355,1,11,2910009,8 ,1 ,42,,8",
 "110400,2,2,1998/10/01 07:17:00,140.0179630111,35.2235431132,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:17:00,140.6565177193,35.9225776452,1,9,4820320,3 ,1 ,16,,6",
 "165600,2,2,1998/10/01 07:17:00,140.2740354539,36.2110528094,1,12,5011018,15,6 ,33,,7",
 "248400,2,2,1998/10/01 07:17:00,139.3574355446,35.3370093952,1,5,2511002,3 ,1 ,37,,2",
 "294400,2,2,1998/10/01 07:17:00,139.5047864782,35.5286645569,1,4,2920001,3 ,1 ,39,,6",
 "414000,2,2,1998/10/01 07:17:00,139.420761521,35.7890625996,1,10,3122302,3 ,11,32,,6",
 "420900,2,2,1998/10/01 07:17:00,139.4764461573,35.9468029412,1,7,3130307,3 ,1 ,39,,6",
 "441600,4,4,1998/10/01 07:17:00,139.9021690943,35.8140252314,1,9,3215009,9 ,11,30,,8",
 "736000,2,2,1998/10/01 07:17:00,139.4397977922,35.7244681935,1,13,0780004,5 ,1 ,25,,2",
 "775100,2,2,1998/10/01 07:17:00,139.2962656261,35.6727117194,1,7,0846102,9 ,1 ,38,,2",
 "839500,2,2,1998/10/01 07:17:00,139.6297604093,35.3667762754,2,11,1133001,14,7 ,27,,1",
 "839500,2,3,1998/10/01 07:17:00,139.6297604093,35.3667762754,2,11,1133001,14,7 ,27,,12",
 "864800,2,3,1998/10/01 07:17:00,139.6362739964,35.4815369017,1,6,1240102,10,1 ,27,,12",
 "869400,2,2,1998/10/01 07:17:00,139.5312133849,35.3538327966,1,10,1231001,8 ,1 ,29,,6",
 "869400,2,3,1998/10/01 07:17:00,139.5312133849,35.3538327966,1,10,1231001,8 ,1 ,29,,12",
 "860200,2,3,1998/10/01 07:17:00,139.4911714309,35.5334859048,1,9,1340109,10,1 ,33,,12",
 "18400,2,3,1998/10/01 07:17:00,139.9272559446,35.7209428208,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:17:00,140.0050261159,35.7117121144,2,4,4128103,8 ,1 ,42,,12",
 "55200,2,3,1998/10/01 07:17:00,139.7181430846,35.7343434019,1,10,4222006,9 ,1 ,38,,12",
 "78200,2,3,1998/10/01 07:17:00,140.1278521513,35.7155769527,1,12,4413008,8 ,1 ,44,,12",
 "135700,2,4,1998/10/01 07:17:00,140.1032011589,35.5991729084,1,11,4920108,3 ,1 ,39,,1",
 "71300,2,3,1998/10/01 07:17:00,139.7950268583,35.8549315581,1,12,4313309,9 ,1 ,34,,12",
 "227700,2,2,1998/10/01 07:17:00,139.4728064119,35.3200360362,1,9,2410203,8 ,10,16,,1",
 "234600,2,3,1998/10/01 07:17:00,139.5973630307,35.4451273712,1,14,2412116,10,1 ,63,,12",
 "195500,2,3,1998/10/01 07:17:00,139.8607699764,35.7683786855,2,4,5420020,8 ,1 ,37,,12",
 "301300,2,3,1998/10/01 07:17:00,139.4957571311,35.5316116718,1,11,2922005,9 ,1 ,29,,12",
 "246100,2,4,1998/10/01 07:17:00,139.4532721461,35.3346236178,1,5,2510201,3 ,1 ,39,,1",
 "303600,2,3,1998/10/01 07:17:00,139.4088996608,35.5614115754,1,9,2923016,9 ,1 ,30,,12",
 "386400,2,4,1998/10/01 07:17:00,139.7084902469,35.8112641175,1,10,3059002,8 ,1 ,37,,12",
 "370300,2,3,1998/10/01 07:17:00,139.7172222787,35.8022434146,1,10,3037010,10,1 ,39,,12",
 "476100,2,2,1998/10/01 07:17:00,139.4235377523,35.9616207176,1,3,3411213,13,2 ,34,,2",
 "483000,2,3,1998/10/01 07:17:00,139.6093213658,35.9416197376,1,10,3510101,8 ,1 ,27,,12",
 "630200,2,3,1998/10/01 07:17:00,139.6914651032,35.7577616564,1,7,0513010,8 ,1 ,41,,12",
 "653200,2,3,1998/10/01 07:17:00,139.7559365784,35.7405332527,1,8,0531203,9 ,1 ,39,,12",
 "683100,2,2,1998/10/01 07:17:00,139.9034124348,35.6953102411,2,3,0633106,12,2 ,49,,2",
 "676200,2,3,1998/10/01 07:17:00,139.7903416021,35.7027576641,1,13,0623211,10,1 ,35,,12",
 "747500,2,2,1998/10/01 07:17:00,139.4663588275,35.5373988433,1,5,0810007,4 ,1 ,38,,3",
 "777400,2,3,1998/10/01 07:17:00,139.4953035629,35.6687989072,1,5,0844010,8 ,1 ,41,,12",
 "220800,3,3,1998/10/01 07:18:00,139.583466954,35.2598230982,2,10,2310102,14,3 ,37,,6",
 "280600,3,3,1998/10/01 07:18:00,139.6837209562,35.6223971296,1,11,2910009,8 ,1 ,42,,8",
 "110400,2,2,1998/10/01 07:18:00,140.0169000136,35.2269749475,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:18:00,140.6512870616,35.9249967386,1,9,4820320,3 ,1 ,16,,6",
 "165600,2,2,1998/10/01 07:18:00,140.2768494889,36.209528367,1,12,5011018,15,6 ,33,,7",
 "248400,2,2,1998/10/01 07:18:00,139.3574776663,35.3357912766,1,5,2511002,3 ,1 ,37,,2",
 "294400,2,2,1998/10/01 07:18:00,139.5077645735,35.5277775754,1,4,2920001,3 ,1 ,39,,6",
 "414000,2,2,1998/10/01 07:18:00,139.4230450227,35.7889822126,1,10,3122302,3 ,11,32,,6",
 "420900,2,2,1998/10/01 07:18:00,139.4749670978,35.9498289311,1,7,3130307,3 ,1 ,39,,6",
 "441600,4,4,1998/10/01 07:18:00,139.9019856215,35.8121132532,1,9,3215009,9 ,11,30,,8",
 "515200,2,2,1998/10/01 07:18:00,139.2066282749,36.1535485323,1,1,3711123,12,2 ,37,,1",
 "736000,2,2,1998/10/01 07:18:00,139.4393056631,35.7256270555,1,13,0780004,5 ,1 ,25,,2",
 "775100,2,2,1998/10/01 07:18:00,139.2978037442,35.6714754324,1,7,0846102,9 ,1 ,38,,2",
 "839500,2,3,1998/10/01 07:18:00,139.628874927,35.3713173413,2,11,1133001,14,7 ,27,,12",
 "869400,2,3,1998/10/01 07:18:00,139.5307915233,35.3582847286,1,10,1231001,8 ,1 ,29,,12",
 "860200,2,3,1998/10/01 07:18:00,139.4946819021,35.5318528706,1,9,1340109,10,1 ,33,,12",
 "864800,2,3,1998/10/01 07:18:00,139.6418992487,35.4840984396,1,6,1240102,10,1 ,27,,12",
 "55200,2,3,1998/10/01 07:18:00,139.7119599629,35.7320416002,1,10,4222006,9 ,1 ,38,,12",
 "18400,2,3,1998/10/01 07:18:00,139.9224504258,35.722989491,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:18:00,139.9995297406,35.7104587238,2,4,4128103,8 ,1 ,42,,12",
 "78200,2,3,1998/10/01 07:18:00,140.1253492896,35.7175391398,1,12,4413008,8 ,1 ,44,,12",
 "135700,2,4,1998/10/01 07:18:00,140.1012559741,35.5967505147,1,11,4920108,3 ,1 ,39,,1",
 "71300,2,3,1998/10/01 07:18:00,139.796733052,35.8514114284,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 07:18:00,139.8536678501,35.7677172343,2,4,5420020,8 ,1 ,37,,12",
 "227700,2,2,1998/10/01 07:18:00,139.4721569507,35.3205738028,1,9,2410203,8 ,10,16,,1",
 "234600,2,3,1998/10/01 07:18:00,139.6027758765,35.4495360945,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:18:00,139.5012826546,35.5311486347,1,11,2922005,9 ,1 ,29,,12",
 "246100,2,4,1998/10/01 07:18:00,139.4544697177,35.3340099688,1,5,2510201,3 ,1 ,39,,1",
 "303600,2,3,1998/10/01 07:18:00,139.4131313775,35.5591839915,1,9,2923016,9 ,1 ,30,,12",
 "370300,2,3,1998/10/01 07:18:00,139.718253742,35.7984350642,1,10,3037010,10,1 ,39,,12",
 "386400,2,4,1998/10/01 07:18:00,139.7117747121,35.8079704283,1,10,3059002,8 ,1 ,37,,12",
 "476100,2,2,1998/10/01 07:18:00,139.4208412291,35.9597200732,1,3,3411213,13,2 ,34,,2",
 "483000,2,3,1998/10/01 07:18:00,139.6100311581,35.9380640052,1,10,3510101,8 ,1 ,27,,12",
 "630200,2,3,1998/10/01 07:18:00,139.681835782,35.7612023663,1,7,0513010,8 ,1 ,41,,12",
 "653200,2,3,1998/10/01 07:18:00,139.7596583574,35.7391076797,1,8,0531203,9 ,1 ,39,,12",
 "683100,2,2,1998/10/01 07:18:00,139.9023749766,35.6950778258,2,3,0633106,12,2 ,49,,2",
 "676200,2,3,1998/10/01 07:18:00,139.7881061598,35.700138416,1,13,0623211,10,1 ,35,,12",
 "747500,2,2,1998/10/01 07:18:00,139.4677763747,35.5369660076,1,5,0810007,4 ,1 ,38,,3",
 "777400,2,3,1998/10/01 07:18:00,139.4993281544,35.6673836814,1,5,0844010,8 ,1 ,41,,12",
 "220800,3,3,1998/10/01 07:19:00,139.5873223681,35.2601023165,2,10,2310102,14,3 ,37,,6",
 "280600,3,3,1998/10/01 07:19:00,139.6879034556,35.6247499653,1,11,2910009,8 ,1 ,42,,8",
 "110400,2,2,1998/10/01 07:19:00,140.0129189279,35.2292219222,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:19:00,140.6470082197,35.9275866792,1,9,4820320,3 ,1 ,16,,6",
 "165600,2,2,1998/10/01 07:19:00,140.2798469211,36.2080224011,1,12,5011018,15,6 ,33,,7",
 "248400,2,2,1998/10/01 07:19:00,139.3557607614,35.3354359077,1,5,2511002,3 ,1 ,37,,2",
 "294400,2,2,1998/10/01 07:19:00,139.5103645959,35.5264084824,1,4,2920001,3 ,1 ,39,,6",
 "414000,2,2,1998/10/01 07:19:00,139.4242366401,35.7879156338,1,10,3122302,3 ,11,32,,6",
 "420900,2,2,1998/10/01 07:19:00,139.4741585618,35.9530127621,1,7,3130307,3 ,1 ,39,,6",
 "441600,4,4,1998/10/01 07:19:00,139.9020347027,35.8101870669,1,9,3215009,9 ,11,30,,8",
 "515200,2,2,1998/10/01 07:19:00,139.2068375002,36.1521372388,1,1,3711123,12,2 ,37,,1",
 "736000,2,2,1998/10/01 07:19:00,139.4397098805,35.7264125112,1,13,0780004,5 ,1 ,25,,2",
 "775100,2,2,1998/10/01 07:19:00,139.2992927654,35.6701975574,1,7,0846102,9 ,1 ,38,,2",
 "839500,2,3,1998/10/01 07:19:00,139.6246857398,35.3741312302,2,11,1133001,14,7 ,27,,12",
 "869400,2,3,1998/10/01 07:19:00,139.5293773439,35.3626357928,1,10,1231001,8 ,1 ,29,,12",
 "860200,2,3,1998/10/01 07:19:00,139.4992790535,35.5316251787,1,9,1340109,10,1 ,33,,12",
 "864800,2,3,1998/10/01 07:19:00,139.6481841205,35.4854560128,1,6,1240102,10,1 ,27,,12",
 "55200,2,3,1998/10/01 07:19:00,139.709218056,35.7263248095,1,10,4222006,9 ,1 ,38,,12",
 "18400,2,3,1998/10/01 07:19:00,139.9176425394,35.7250326451,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:19:00,139.9943311434,35.7085660371,2,4,4128103,8 ,1 ,42,,12",
 "78200,2,3,1998/10/01 07:19:00,140.1230632516,35.7212081875,1,12,4413008,8 ,1 ,44,,12",
 "135700,2,4,1998/10/01 07:19:00,140.0990171023,35.5947877655,1,11,4920108,3 ,1 ,39,,1",
 "71300,2,3,1998/10/01 07:19:00,139.7984457373,35.8478933787,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 07:19:00,139.8468319902,35.7662859981,2,4,5420020,8 ,1 ,37,,12",
 "227700,2,2,1998/10/01 07:19:00,139.4711781552,35.3204128254,1,9,2410203,8 ,10,16,,1",
 "234600,2,3,1998/10/01 07:19:00,139.6089018837,35.4533323928,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:19:00,139.5065226906,35.5294806877,1,11,2922005,9 ,1 ,29,,12",
 "246100,2,4,1998/10/01 07:19:00,139.4556672713,35.3333963077,1,5,2510201,3 ,1 ,39,,1",
 "303600,2,3,1998/10/01 07:19:00,139.417357857,35.5569499349,1,9,2923016,9 ,1 ,30,,12",
 "370300,2,3,1998/10/01 07:19:00,139.7178313777,35.7945097798,1,10,3037010,10,1 ,39,,12",
 "386400,2,4,1998/10/01 07:19:00,139.7150722607,35.8046854286,1,10,3059002,8 ,1 ,37,,12",
 "476100,2,2,1998/10/01 07:19:00,139.4184277429,35.9575665039,1,3,3411213,13,2 ,34,,2",
 "483000,2,3,1998/10/01 07:19:00,139.6116207242,35.9347552037,1,10,3510101,8 ,1 ,27,,12",
 "630200,2,3,1998/10/01 07:19:00,139.6725388371,35.7652527103,1,7,0513010,8 ,1 ,41,,12",
 "653200,2,3,1998/10/01 07:19:00,139.7626301902,35.7365552277,1,8,0531203,9 ,1 ,39,,12",
 "683100,2,2,1998/10/01 07:19:00,139.9013685347,35.6944300499,2,3,0633106,12,2 ,49,,2",
 "676200,2,3,1998/10/01 07:19:00,139.7862137526,35.6973263023,1,13,0623211,10,1 ,35,,12",
 "747500,2,2,1998/10/01 07:19:00,139.4697460807,35.5366164613,1,5,0810007,4 ,1 ,38,,3",
 "759000,2,2,1998/10/01 07:19:00,139.429184574,35.6219342171,2,4,0821006,9 ,1 ,55,,1",
 "777400,2,3,1998/10/01 07:19:00,139.5033437487,35.6659518165,1,5,0844010,8 ,1 ,41,,12",
 "220800,3,3,1998/10/01 07:20:00,139.5909653574,35.2589178449,2,10,2310102,14,3 ,37,,6",
 "220800,4,4,1998/10/01 07:20:00,139.5909653574,35.2589178449,2,10,2310102,14,97,37,,97",
 "280600,3,3,1998/10/01 07:20:00,139.6921265529,35.6270649094,1,11,2910009,8 ,1 ,42,,8",
 "110400,2,2,1998/10/01 07:20:00,140.0097206393,35.2310066929,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:20:00,140.649968089,35.9318553443,1,9,4820320,3 ,1 ,16,,6",
 "165600,2,2,1998/10/01 07:20:00,140.2825448146,36.206596622,1,12,5011018,15,6 ,33,,7",
 "248400,2,2,1998/10/01 07:20:00,139.3555421169,35.3360639124,1,5,2511002,3 ,1 ,37,,2",
 "248400,3,3,1998/10/01 07:20:00,139.3555421169,35.3360639124,1,5,2511002,3 ,97,37,,97",
 "294400,2,2,1998/10/01 07:20:00,139.5129531258,35.5271208907,1,4,2920001,3 ,1 ,39,,6",
 "414000,2,2,1998/10/01 07:20:00,139.4242590806,35.786069952,1,10,3122302,3 ,11,32,,6",
 "420900,2,2,1998/10/01 07:20:00,139.4734920839,35.9562235883,1,7,3130307,3 ,1 ,39,,6",
 "441600,4,4,1998/10/01 07:20:00,139.9020256295,35.8082602524,1,9,3215009,9 ,11,30,,8",
 "515200,2,2,1998/10/01 07:20:00,139.2070467176,36.1507259445,1,1,3711123,12,2 ,37,,1",
 "736000,2,2,1998/10/01 07:20:00,139.4391299493,35.7263630461,1,13,0780004,5 ,1 ,25,,2",
 "775100,2,2,1998/10/01 07:20:00,139.3007423864,35.6688904088,1,7,0846102,9 ,1 ,38,,2",
 "839500,2,3,1998/10/01 07:20:00,139.6199781433,35.3766468619,2,11,1133001,14,7 ,27,,12",
 "860200,2,3,1998/10/01 07:20:00,139.5036252937,35.5304084549,1,9,1340109,10,1 ,33,,12",
 "864800,2,3,1998/10/01 07:20:00,139.6539728578,35.4861582011,1,6,1240102,10,1 ,27,,12",
 "18400,2,3,1998/10/01 07:20:00,139.9128099139,35.7270363897,1,9,4120311,8 ,1 ,44,,12",
 "869400,2,3,1998/10/01 07:20:00,139.5302695367,35.3671795945,1,10,1231001,8 ,1 ,29,,12",
 "32200,2,3,1998/10/01 07:20:00,139.988692225,35.7078608534,2,4,4128103,8 ,1 ,42,,12",
 "55200,2,3,1998/10/01 07:20:00,139.7063621463,35.7206575224,1,10,4222006,9 ,1 ,38,,12",
 "71300,2,3,1998/10/01 07:20:00,139.8001567006,35.8443748541,1,12,4313309,9 ,1 ,34,,12",
 "135700,2,4,1998/10/01 07:20:00,140.0972133273,35.593416862,1,11,4920108,3 ,1 ,39,,1",
 "78200,2,3,1998/10/01 07:20:00,140.1189536239,35.7236021119,1,12,4413008,8 ,1 ,44,,12",
 "234600,2,3,1998/10/01 07:20:00,139.6145629627,35.4575350395,1,14,2412116,10,1 ,63,,12",
 "246100,2,4,1998/10/01 07:20:00,139.4568648069,35.3327826346,1,5,2510201,3 ,1 ,39,,1",
 "195500,2,3,1998/10/01 07:20:00,139.8404539457,35.7638015276,2,4,5420020,8 ,1 ,37,,12",
 "227700,2,2,1998/10/01 07:20:00,139.4710508668,35.3207241895,1,9,2410203,8 ,10,16,,1",
 "227700,2,3,1998/10/01 07:20:00,139.4710508668,35.3207241895,1,9,2410203,8 ,10,16,,12",
 "303600,2,3,1998/10/01 07:20:00,139.4215827455,35.5547140077,1,9,2923016,9 ,1 ,30,,12",
 "301300,2,3,1998/10/01 07:20:00,139.5117509165,35.5277881806,1,11,2922005,9 ,1 ,29,,12",
 "370300,2,3,1998/10/01 07:20:00,139.7173998644,35.7905796532,1,10,3037010,10,1 ,39,,12",
 "386400,2,4,1998/10/01 07:20:00,139.7178056375,35.8011069377,1,10,3059002,8 ,1 ,37,,12",
 "476100,2,2,1998/10/01 07:20:00,139.4160310712,35.9554005182,1,3,3411213,13,2 ,34,,2",
 "483000,2,3,1998/10/01 07:20:00,139.6140957288,35.9317677684,1,10,3510101,8 ,1 ,27,,12",
 "630200,2,3,1998/10/01 07:20:00,139.6628916925,35.7685972448,1,7,0513010,8 ,1 ,41,,12",
 "676200,2,3,1998/10/01 07:20:00,139.7851457829,35.6942707052,1,13,0623211,10,1 ,35,,12",
 "683100,2,2,1998/10/01 07:20:00,139.9003560147,35.6937886095,2,3,0633106,12,2 ,49,,2",
 "653200,2,3,1998/10/01 07:20:00,139.7654596886,35.7339004735,1,8,0531203,9 ,1 ,39,,12",
 "759000,2,2,1998/10/01 07:20:00,139.4282263922,35.6225743922,2,4,0821006,9 ,1 ,55,,1",
 "777400,2,3,1998/10/01 07:20:00,139.5075374221,35.6649317592,1,5,0844010,8 ,1 ,41,,12",
 "747500,2,2,1998/10/01 07:20:00,139.4715714753,35.5359153021,1,5,0810007,4 ,1 ,38,,3",
 "280600,3,3,1998/10/01 07:21:00,139.6966470844,35.6288992581,1,11,2910009,8 ,1 ,42,,8",
 "110400,2,2,1998/10/01 07:21:00,140.0061979102,35.2310401849,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:21:00,140.6517348133,35.936161141,1,9,4820320,3 ,1 ,16,,6",
 "165600,2,2,1998/10/01 07:21:00,140.2853218162,36.204843715,1,12,5011018,15,6 ,33,,7",
 "294400,2,2,1998/10/01 07:21:00,139.5159150111,35.5263952953,1,4,2920001,3 ,1 ,39,,6",
 "414000,2,2,1998/10/01 07:21:00,139.425302282,35.7847119602,1,10,3122302,3 ,11,32,,6",
 "420900,2,2,1998/10/01 07:21:00,139.4727286796,35.9594200435,1,7,3130307,3 ,1 ,39,,6",
 "441600,4,4,1998/10/01 07:21:00,139.9019564657,35.8063344457,1,9,3215009,9 ,11,30,,8",
 "515200,2,2,1998/10/01 07:21:00,139.2072285639,36.1501652817,1,1,3711123,12,2 ,37,,1",
 "736000,2,2,1998/10/01 07:21:00,139.4405168687,35.7268380438,1,13,0780004,5 ,1 ,25,,2",
 "775100,2,2,1998/10/01 07:21:00,139.3020900672,35.6675217509,1,7,0846102,9 ,1 ,38,,2",
 "839500,2,3,1998/10/01 07:21:00,139.6172912874,35.3806706519,2,11,1133001,14,7 ,27,,12",
 "841800,2,2,1998/10/01 07:21:00,139.6201281282,35.3435525521,1,2,1132110,12,2 ,33,,1",
 "864800,2,3,1998/10/01 07:21:00,139.6590503135,35.48669747,1,6,1240102,10,1 ,27,,12",
 "869400,2,3,1998/10/01 07:21:00,139.530012541,35.3717575974,1,10,1231001,8 ,1 ,29,,12",
 "860200,2,3,1998/10/01 07:21:00,139.5079139298,35.5290283344,1,9,1340109,10,1 ,33,,12",
 "18400,2,3,1998/10/01 07:21:00,139.9079504338,35.7289971096,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:21:00,139.9830902755,35.7069960763,2,4,4128103,8 ,1 ,42,,12",
 "55200,2,3,1998/10/01 07:21:00,139.7044584689,35.7147184002,1,10,4222006,9 ,1 ,38,,12",
 "13800,2,2,1998/10/01 07:21:00,139.8967669828,35.6636088016,2,14,4116004,5 ,5 ,25,,1",
 "78200,2,3,1998/10/01 07:21:00,140.114330761,35.7253303085,1,12,4413008,8 ,1 ,44,,12",
 "135700,2,4,1998/10/01 07:21:00,140.0971563819,35.5905203333,1,11,4920108,3 ,1 ,39,,1",
 "71300,2,3,1998/10/01 07:21:00,139.8017745925,35.8408275409,1,12,4313309,9 ,1 ,34,,12",
 "234600,2,3,1998/10/01 07:21:00,139.6192318825,35.4625558593,1,14,2412116,10,1 ,63,,12",
 "195500,2,3,1998/10/01 07:21:00,139.8333636559,35.7630707084,2,4,5420020,8 ,1 ,37,,12",
 "227700,2,3,1998/10/01 07:21:00,139.4710699946,35.3267123437,1,9,2410203,8 ,10,16,,12",
 "303600,2,3,1998/10/01 07:21:00,139.4258099504,35.5524811453,1,9,2923016,9 ,1 ,30,,12",
 "301300,2,3,1998/10/01 07:21:00,139.5169876263,35.526113899,1,11,2922005,9 ,1 ,29,,12",
 "246100,2,4,1998/10/01 07:21:00,139.4580623245,35.3321689494,1,5,2510201,3 ,1 ,39,,1",
 "386400,2,4,1998/10/01 07:21:00,139.7180899009,35.7969125199,1,10,3059002,8 ,1 ,37,,12",
 "370300,2,3,1998/10/01 07:21:00,139.7169887741,35.7866478653,1,10,3037010,10,1 ,39,,12",
 "437000,2,2,1998/10/01 07:21:00,139.8026040474,35.8246249566,1,7,3212001,5 ,1 ,36,,1",
 "476100,2,2,1998/10/01 07:21:00,139.4136716337,35.9532074858,1,3,3411213,13,2 ,34,,2",
 "483000,2,3,1998/10/01 07:21:00,139.6161673577,35.9285974839,1,10,3510101,8 ,1 ,27,,12",
 "630200,2,3,1998/10/01 07:21:00,139.6523937051,35.7693621314,1,7,0513010,8 ,1 ,41,,12",
 "683100,2,2,1998/10/01 07:21:00,139.899343511,35.6931471605,2,3,0633106,12,2 ,49,,2",
 "676200,2,3,1998/10/01 07:21:00,139.7846598191,35.6910865994,1,13,0623211,10,1 ,35,,12",
 "653200,2,3,1998/10/01 07:21:00,139.7677717939,35.7316294089,1,8,0531203,9 ,1 ,39,,12",
 "747500,2,2,1998/10/01 07:21:00,139.4727689074,35.5353582326,1,5,0810007,4 ,1 ,38,,3",
 "747500,2,3,1998/10/01 07:21:00,139.4727689074,35.5353582326,1,5,0810007,4 ,1 ,38,,12",
 "759000,2,2,1998/10/01 07:21:00,139.4271501782,35.6227198753,2,4,0821006,9 ,1 ,55,,1",
 "777400,2,3,1998/10/01 07:21:00,139.5117849059,35.6640516232,1,5,0844010,8 ,1 ,41,,12",
 "280600,3,3,1998/10/01 07:22:00,139.7013234056,35.6304915069,1,11,2910009,8 ,1 ,42,,8",
 "110400,2,2,1998/10/01 07:22:00,140.0043419321,35.2339854533,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:22:00,140.6539547569,35.9404797043,1,9,4820320,3 ,1 ,16,,6",
 "165600,2,2,1998/10/01 07:22:00,140.2884443218,36.2037163642,1,12,5011018,15,6 ,33,,7",
 "294400,2,2,1998/10/01 07:22:00,139.5186187167,35.526635991,1,4,2920001,3 ,1 ,39,,6",
 "414000,2,2,1998/10/01 07:22:00,139.4269250736,35.7834773553,1,10,3122302,3 ,11,32,,6",
 "420900,2,2,1998/10/01 07:22:00,139.4719639418,35.9626163094,1,7,3130307,3 ,1 ,39,,6",
 "441600,4,4,1998/10/01 07:22:00,139.9018785593,35.8044086507,1,9,3215009,9 ,11,30,,8",
 "515200,2,2,1998/10/01 07:22:00,139.2074004588,36.1515796024,1,1,3711123,12,2 ,37,,1",
 "736000,2,2,1998/10/01 07:22:00,139.4423454429,35.7277762652,1,13,0780004,5 ,1 ,25,,2",
 "775100,2,2,1998/10/01 07:22:00,139.3031435016,35.6659964128,1,7,0846102,9 ,1 ,38,,2",
 "841800,2,2,1998/10/01 07:22:00,139.6201537341,35.3429569136,1,2,1132110,12,2 ,33,,1",
 "839500,2,3,1998/10/01 07:22:00,139.6150834398,35.3849393402,2,11,1133001,14,7 ,27,,12",
 "869400,2,3,1998/10/01 07:22:00,139.5322296963,35.3760744852,1,10,1231001,8 ,1 ,29,,12",
 "860200,2,3,1998/10/01 07:22:00,139.5122000519,35.5276430049,1,9,1340109,10,1 ,33,,12",
 "864800,2,3,1998/10/01 07:22:00,139.6629835106,35.4908793321,1,6,1240102,10,1 ,27,,12",
 "13800,2,2,1998/10/01 07:22:00,139.8952710492,35.6644790684,2,14,4116004,5 ,5 ,25,,1",
 "18400,2,3,1998/10/01 07:22:00,139.9031665704,35.7310749292,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:22:00,139.9780202686,35.7048690785,2,4,4128103,8 ,1 ,42,,12",
 "55200,2,3,1998/10/01 07:22:00,139.7024005829,35.7088134276,1,10,4222006,9 ,1 ,38,,12",
 "78200,2,3,1998/10/01 07:22:00,140.1094637272,35.7265503216,1,12,4413008,8 ,1 ,44,,12",
 "135700,2,4,1998/10/01 07:22:00,140.0970994413,35.5876238032,1,11,4920108,3 ,1 ,39,,1",
 "71300,2,3,1998/10/01 07:22:00,139.8033525754,35.8372680272,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 07:22:00,139.8262630065,35.7624095028,2,4,5420020,8 ,1 ,37,,12",
 "227700,2,3,1998/10/01 07:22:00,139.4759995351,35.3317603482,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:22:00,139.6237815521,35.4676479943,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:22:00,139.5218366666,35.5238042736,1,11,2922005,9 ,1 ,29,,12",
 "246100,2,4,1998/10/01 07:22:00,139.459244103,35.3315351955,1,5,2510201,3 ,1 ,39,,1",
 "303600,2,3,1998/10/01 07:22:00,139.4300607184,35.5502787127,1,9,2923016,9 ,1 ,30,,12",
 "370300,2,3,1998/10/01 07:22:00,139.7181397916,35.7828570738,1,10,3037010,10,1 ,39,,12",
 "386400,2,4,1998/10/01 07:22:00,139.7176223913,35.7926894999,1,10,3059002,8 ,1 ,37,,12",
 "437000,2,2,1998/10/01 07:22:00,139.802888583,35.8257671647,1,7,3212001,5 ,1 ,36,,1",
 "476100,2,2,1998/10/01 07:22:00,139.4113110108,35.9510157534,1,3,3411213,13,2 ,34,,2",
 "483000,2,3,1998/10/01 07:22:00,139.6173630736,35.9251297622,1,10,3510101,8 ,1 ,27,,12",
 "630200,2,3,1998/10/01 07:22:00,139.6423554077,35.7715619247,1,7,0513010,8 ,1 ,41,,12",
 "653200,2,3,1998/10/01 07:22:00,139.7701024084,35.7286813831,1,8,0531203,9 ,1 ,39,,12",
 "683100,2,2,1998/10/01 07:22:00,139.8991368714,35.6933848195,2,3,0633106,12,2 ,49,,2",
 "676200,2,3,1998/10/01 07:22:00,139.7844762461,35.6880520422,1,13,0623211,10,1 ,35,,12",
 "747500,2,3,1998/10/01 07:22:00,139.4778481401,35.5318997428,1,5,0810007,4 ,1 ,38,,12",
 "759000,2,2,1998/10/01 07:22:00,139.4266332416,35.6236384277,2,4,0821006,9 ,1 ,55,,1",
 "777400,2,3,1998/10/01 07:22:00,139.5160645295,35.6632830311,1,5,0844010,8 ,1 ,41,,12",
 "280600,3,3,1998/10/01 07:23:00,139.7061759708,35.6316853864,1,11,2910009,8 ,1 ,42,,8",
 "110400,2,2,1998/10/01 07:23:00,140.0007101884,35.2364792841,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:23:00,140.6565567071,35.9440675793,1,9,4820320,3 ,1 ,16,,6",
 "165600,2,2,1998/10/01 07:23:00,140.2898365162,36.2014341693,1,12,5011018,15,6 ,33,,7",
 "294400,2,2,1998/10/01 07:23:00,139.5212176989,35.5266448623,1,4,2920001,3 ,1 ,39,,6",
 "414000,2,2,1998/10/01 07:23:00,139.4287825086,35.7831469407,1,10,3122302,3 ,11,32,,6",
 "420900,2,2,1998/10/01 07:23:00,139.4711814883,35.9658096976,1,7,3130307,3 ,1 ,39,,6",
 "441600,4,4,1998/10/01 07:23:00,139.9018347552,35.8024830239,1,9,3215009,9 ,11,30,,8",
 "515200,2,2,1998/10/01 07:23:00,139.207436123,36.1521074564,1,1,3711123,12,2 ,37,,1",
 "736000,2,2,1998/10/01 07:23:00,139.4439007949,35.7289187484,1,13,0780004,5 ,1 ,25,,2",
 "775100,2,2,1998/10/01 07:23:00,139.304500063,35.6646624264,1,7,0846102,9 ,1 ,38,,2",
 "841800,2,2,1998/10/01 07:23:00,139.620438144,35.342531114,1,2,1132110,12,2 ,33,,1",
 "839500,2,3,1998/10/01 07:23:00,139.613423256,35.3893531004,2,11,1133001,14,7 ,27,,12",
 "869400,2,3,1998/10/01 07:23:00,139.5342322918,35.3804537336,1,10,1231001,8 ,1 ,29,,12",
 "860200,2,3,1998/10/01 07:23:00,139.5164947053,35.5262759192,1,9,1340109,10,1 ,33,,12",
 "864800,2,3,1998/10/01 07:23:00,139.6665291818,35.4952774845,1,6,1240102,10,1 ,27,,12",
 "55200,2,3,1998/10/01 07:23:00,139.7004752511,35.7028862327,1,10,4222006,9 ,1 ,38,,12",
 "13800,2,2,1998/10/01 07:23:00,139.8958102059,35.6644887057,2,14,4116004,5 ,5 ,25,,1",
 "18400,2,3,1998/10/01 07:23:00,139.898264973,35.7329613939,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:23:00,139.9726539321,35.70335765,2,4,4128103,8 ,1 ,42,,12",
 "71300,2,3,1998/10/01 07:23:00,139.8039193296,35.8335418739,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:23:00,140.1045370537,35.7276041883,1,12,4413008,8 ,1 ,44,,12",
 "135700,2,4,1998/10/01 07:23:00,140.0970425054,35.5847272716,1,11,4920108,3 ,1 ,39,,1",
 "195500,2,3,1998/10/01 07:23:00,139.8191989209,35.7615443287,2,4,5420020,8 ,1 ,37,,12",
 "227700,2,3,1998/10/01 07:23:00,139.4810202762,35.3366811751,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:23:00,139.6282067058,35.4728150197,1,14,2412116,10,1 ,63,,12",
 "303600,2,3,1998/10/01 07:23:00,139.4342951777,35.5480553746,1,9,2923016,9 ,1 ,30,,12",
 "301300,2,3,1998/10/01 07:23:00,139.5265868275,35.5213445852,1,11,2922005,9 ,1 ,29,,12",
 "246100,2,4,1998/10/01 07:23:00,139.4604026123,35.3317830845,1,5,2510201,3 ,1 ,39,,1",
 "386400,2,4,1998/10/01 07:23:00,139.7171735055,35.7884619222,1,10,3059002,8 ,1 ,37,,12",
 "370300,2,3,1998/10/01 07:23:00,139.7200709342,35.7792434325,1,10,3037010,10,1 ,39,,12",
 "437000,2,2,1998/10/01 07:23:00,139.8034714701,35.8264911614,1,7,3212001,5 ,1 ,36,,1",
 "476100,2,2,1998/10/01 07:23:00,139.408902316,35.948858887,1,3,3411213,13,2 ,34,,2",
 "476100,2,3,1998/10/01 07:23:00,139.408902316,35.948858887,1,3,3411213,13,2 ,34,,12",
 "483000,2,3,1998/10/01 07:23:00,139.6186748238,35.9216895221,1,10,3510101,8 ,1 ,27,,12",
 "630200,2,3,1998/10/01 07:23:00,139.6340363854,35.7768206771,1,7,0513010,8 ,1 ,41,,12",
 "653200,2,3,1998/10/01 07:23:00,139.7730321628,35.7260972555,1,8,0531203,9 ,1 ,39,,12",
 "683100,2,2,1998/10/01 07:23:00,139.8978655366,35.6935192866,2,3,0633106,12,2 ,49,,2",
 "683100,2,3,1998/10/01 07:23:00,139.8978655366,35.6935192866,2,3,0633106,12,2 ,49,,12",
 "676200,2,3,1998/10/01 07:23:00,139.7815008044,35.685980667,1,13,0623211,10,1 ,35,,12",
 "747500,2,3,1998/10/01 07:23:00,139.4844604618,35.531227711,1,5,0810007,4 ,1 ,38,,12",
 "759000,2,2,1998/10/01 07:23:00,139.4261061833,35.6245531382,2,4,0821006,9 ,1 ,55,,1",
 "777400,2,3,1998/10/01 07:23:00,139.5199679737,35.6616933121,1,5,0844010,8 ,1 ,41,,12",
 "280600,3,3,1998/10/01 07:24:00,139.7105983641,35.6336923479,1,11,2910009,8 ,1 ,42,,8",
 "110400,2,2,1998/10/01 07:24:00,140.0003125862,35.2405148741,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:24:00,140.6612162387,35.9464478219,1,9,4820320,3 ,1 ,16,,6",
 "165600,2,2,1998/10/01 07:24:00,140.2897775076,36.1985631316,1,12,5011018,15,6 ,33,,7",
 "294400,2,2,1998/10/01 07:24:00,139.5230021633,35.528612035,1,4,2920001,3 ,1 ,39,,6",
 "414000,2,2,1998/10/01 07:24:00,139.4306520669,35.7830868626,1,10,3122302,3 ,11,32,,6",
 "420900,2,2,1998/10/01 07:24:00,139.4713634242,35.9685869467,1,7,3130307,3 ,1 ,39,,6",
 "441600,4,4,1998/10/01 07:24:00,139.9018155445,35.800556275,1,9,3215009,9 ,11,30,,8",
 "515200,2,2,1998/10/01 07:24:00,139.207294439,36.1506906506,1,1,3711123,12,2 ,37,,1",
 "736000,2,2,1998/10/01 07:24:00,139.4440708405,35.7306765114,1,13,0780004,5 ,1 ,25,,2",
 "775100,2,2,1998/10/01 07:24:00,139.306229892,35.6637829351,1,7,0846102,9 ,1 ,38,,2",
 "841800,2,2,1998/10/01 07:24:00,139.621030151,35.3428774275,1,2,1132110,12,2 ,33,,1",
 "839500,2,3,1998/10/01 07:24:00,139.6110853683,35.3934960757,2,11,1133001,14,7 ,27,,12",
 "864800,2,3,1998/10/01 07:24:00,139.6711009404,35.4990995701,1,6,1240102,10,1 ,27,,12",
 "869400,2,3,1998/10/01 07:24:00,139.534897664,35.3851042521,1,10,1231001,8 ,1 ,29,,12",
 "860200,2,3,1998/10/01 07:24:00,139.5205300437,35.5244790087,1,9,1340109,10,1 ,33,,12",
 "18400,2,3,1998/10/01 07:24:00,139.8934764953,35.735032257,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:24:00,139.9670292675,35.7039377645,2,4,4128103,8 ,1 ,42,,12",
 "55200,2,3,1998/10/01 07:24:00,139.6995937382,35.6968013946,1,10,4222006,9 ,1 ,38,,12",
 "13800,2,2,1998/10/01 07:24:00,139.8944242618,35.6655295428,2,14,4116004,5 ,5 ,25,,1",
 "135700,2,4,1998/10/01 07:24:00,140.0969855743,35.5818307386,1,11,4920108,3 ,1 ,39,,1",
 "71300,2,3,1998/10/01 07:24:00,139.8034704457,35.8297752477,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:24:00,140.0996104089,35.7286583502,1,12,4413008,8 ,1 ,44,,12",
 "195500,2,3,1998/10/01 07:24:00,139.8122934993,35.7603231711,2,4,5420020,8 ,1 ,37,,12",
 "227700,2,3,1998/10/01 07:24:00,139.4882263136,35.3388741652,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:24:00,139.6328236473,35.4778680706,1,14,2412116,10,1 ,63,,12",
 "303600,2,3,1998/10/01 07:24:00,139.4385272618,35.5458291269,1,9,2923016,9 ,1 ,30,,12",
 "301300,2,3,1998/10/01 07:24:00,139.5313348811,35.5188823591,1,11,2922005,9 ,1 ,29,,12",
 "246100,2,4,1998/10/01 07:24:00,139.4615518779,35.3324558731,1,5,2510201,3 ,1 ,39,,1",
 "386400,2,4,1998/10/01 07:24:00,139.7174797104,35.7842817791,1,10,3059002,8 ,1 ,37,,12",
 "370300,2,3,1998/10/01 07:24:00,139.7220723924,35.7756724172,1,10,3037010,10,1 ,39,,12",
 "437000,2,2,1998/10/01 07:24:00,139.8037105765,35.8276042448,1,7,3212001,5 ,1 ,36,,1",
 "476100,2,3,1998/10/01 07:24:00,139.4142662838,35.9443556472,1,3,3411213,13,2 ,34,,12",
 "483000,2,3,1998/10/01 07:24:00,139.6199996543,35.9182522892,1,10,3510101,8 ,1 ,27,,12",
 "630200,2,3,1998/10/01 07:24:00,139.6259321604,35.7823180869,1,7,0513010,8 ,1 ,41,,12",
 "653200,2,3,1998/10/01 07:24:00,139.7759225689,35.7234848327,1,8,0531203,9 ,1 ,39,,12",
 "683100,2,3,1998/10/01 07:24:00,139.8961367274,35.6910926503,2,3,0633106,12,2 ,49,,12",
 "676200,2,3,1998/10/01 07:24:00,139.7780428327,35.6844670873,1,13,0623211,10,1 ,35,,12",
 "747500,2,3,1998/10/01 07:24:00,139.4912568375,35.5314584714,1,5,0810007,4 ,1 ,38,,12",
 "759000,2,2,1998/10/01 07:24:00,139.4255897593,35.6254718164,2,4,0821006,9 ,1 ,55,,1",
 "777400,2,3,1998/10/01 07:24:00,139.5237800159,35.6599298937,1,5,0844010,8 ,1 ,41,,12",
 "280600,3,3,1998/10/01 07:25:00,139.7154037971,35.6348591322,1,11,2910009,8 ,1 ,42,,8",
 "110400,2,2,1998/10/01 07:25:00,139.9959110613,35.2412918353,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:25:00,140.662505928,35.9509931472,1,9,4820320,3 ,1 ,16,,6",
 "165600,2,2,1998/10/01 07:25:00,140.2889631105,36.1967493556,1,12,5011018,15,6 ,33,,7",
 "165600,3,3,1998/10/01 07:25:00,140.2889631105,36.1967493556,1,12,5011018,15,97,33,,97",
 "294400,2,2,1998/10/01 07:25:00,139.5258747348,35.5276083341,1,4,2920001,3 ,1 ,39,,6",
 "414000,2,2,1998/10/01 07:25:00,139.4324685189,35.7826212536,1,10,3122302,3 ,11,32,,6",
 "420900,2,2,1998/10/01 07:25:00,139.4750974639,35.9697655547,1,7,3130307,3 ,1 ,39,,6",
 "441600,4,4,1998/10/01 07:25:00,139.9017658976,35.798630951,1,9,3215009,9 ,11,30,,8",
 "515200,2,2,1998/10/01 07:25:00,139.2071527596,36.1492738444,1,1,3711123,12,2 ,37,,1",
 "736000,2,2,1998/10/01 07:25:00,139.4459921261,35.7312138417,1,13,0780004,5 ,1 ,25,,2",
 "775100,2,2,1998/10/01 07:25:00,139.3082558099,35.6632035853,1,7,0846102,9 ,1 ,38,,2",
 "841800,2,2,1998/10/01 07:25:00,139.621622163,35.3432237381,1,2,1132110,12,2 ,33,,1",
 "841800,2,3,1998/10/01 07:25:00,139.621622163,35.3432237381,1,2,1132110,12,2 ,33,,12",
 "839500,2,3,1998/10/01 07:25:00,139.6068759904,35.3964548283,2,11,1133001,14,7 ,27,,12",
 "13800,2,2,1998/10/01 07:25:00,139.8930060511,35.6659096448,2,14,4116004,5 ,5 ,25,,1",
 "13800,2,3,1998/10/01 07:25:00,139.8930060511,35.6659096448,2,14,4116004,5 ,5 ,25,,12",
 "864800,2,3,1998/10/01 07:25:00,139.6750826969,35.503301062,1,6,1240102,10,1 ,27,,12",
 "18400,2,3,1998/10/01 07:25:00,139.8882814156,35.7359791739,1,9,4120311,8 ,1 ,44,,12",
 "869400,2,3,1998/10/01 07:25:00,139.5353297868,35.3897777798,1,10,1231001,8 ,1 ,29,,12",
 "860200,2,3,1998/10/01 07:25:00,139.5244264589,35.5224668388,1,9,1340109,10,1 ,33,,12",
 "55200,2,3,1998/10/01 07:25:00,139.6998272499,35.690734646,1,10,4222006,9 ,1 ,38,,12",
 "55200,2,4,1998/10/01 07:25:00,139.6998272499,35.690734646,1,10,4222006,9 ,1 ,38,,1",
 "32200,2,3,1998/10/01 07:25:00,139.9618203803,35.7058169059,2,4,4128103,8 ,1 ,42,,12",
 "71300,2,3,1998/10/01 07:25:00,139.8031188569,35.8260023408,1,12,4313309,9 ,1 ,34,,12",
 "135700,2,4,1998/10/01 07:25:00,140.096928648,35.5789342041,1,11,4920108,3 ,1 ,39,,1",
 "135700,3,5,1998/10/01 07:25:00,140.096928648,35.5789342041,1,11,4920108,3 ,97,39,,97",
 "78200,2,3,1998/10/01 07:25:00,140.0946384082,35.7295581834,1,12,4413008,8 ,1 ,44,,12",
 "195500,2,3,1998/10/01 07:25:00,139.8078986961,35.7557606975,2,4,5420020,8 ,1 ,37,,12",
 "227700,2,3,1998/10/01 07:25:00,139.4957694062,35.3370419807,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:25:00,139.6377842185,35.4826665611,1,14,2412116,10,1 ,63,,12",
 "303600,2,3,1998/10/01 07:25:00,139.4427540925,35.5435964566,1,9,2923016,9 ,1 ,30,,12",
 "246100,2,4,1998/10/01 07:25:00,139.4627011626,35.3331286508,1,5,2510201,3 ,1 ,39,,1",
 "301300,2,3,1998/10/01 07:25:00,139.5360798341,35.5164164053,1,11,2922005,9 ,1 ,29,,12",
 "386400,2,4,1998/10/01 07:25:00,139.7193796927,35.7803317452,1,10,3059002,8 ,1 ,37,,12",
 "370300,2,3,1998/10/01 07:25:00,139.7222874355,35.7717337634,1,10,3037010,10,1 ,39,,12",
 "476100,2,3,1998/10/01 07:25:00,139.4197715352,35.9398716075,1,3,3411213,13,2 ,34,,12",
 "437000,2,2,1998/10/01 07:25:00,139.8035351639,35.8282485855,1,7,3212001,5 ,1 ,36,,1",
 "437000,2,3,1998/10/01 07:25:00,139.8035351639,35.8282485855,1,7,3212001,5 ,1 ,36,,12",
 "483000,2,3,1998/10/01 07:25:00,139.621357727,35.9148235587,1,10,3510101,8 ,1 ,27,,12",
 "630200,2,3,1998/10/01 07:25:00,139.6169159218,35.7866960821,1,7,0513010,8 ,1 ,41,,12",
 "653200,2,3,1998/10/01 07:25:00,139.7787064969,35.7207956903,1,8,0531203,9 ,1 ,39,,12",
 "676200,2,3,1998/10/01 07:25:00,139.7758984567,35.6818145938,1,13,0623211,10,1 ,35,,12",
 "683100,2,3,1998/10/01 07:25:00,139.8939825818,35.6888766055,2,3,0633106,12,2 ,49,,12",
 "777400,2,3,1998/10/01 07:25:00,139.5276000717,35.6581779287,1,5,0844010,8 ,1 ,41,,12",
 "747500,2,3,1998/10/01 07:25:00,139.4980511623,35.5316562673,1,5,0810007,4 ,1 ,38,,12",
 "759000,2,2,1998/10/01 07:25:00,139.4248637482,35.625890665,2,4,0821006,9 ,1 ,55,,1",
 "280600,3,3,1998/10/01 07:26:00,139.7204383246,35.6353389435,1,11,2910009,8 ,1 ,42,,8",
 "110400,2,2,1998/10/01 07:26:00,139.9923805085,35.2384740967,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:26:00,140.6682688701,35.9524284391,1,9,4820320,3 ,1 ,16,,6",
 "294400,2,2,1998/10/01 07:26:00,139.528846016,35.5266880364,1,4,2920001,3 ,1 ,39,,6",
 "414000,2,2,1998/10/01 07:26:00,139.4337003259,35.7810525672,1,10,3122302,3 ,11,32,,6",
 "420900,2,2,1998/10/01 07:26:00,139.4773155043,35.9715627656,1,7,3130307,3 ,1 ,39,,6",
 "441600,4,4,1998/10/01 07:26:00,139.901309974,35.7967404019,1,9,3215009,9 ,11,30,,8",
 "515200,2,2,1998/10/01 07:26:00,139.2071182744,36.1478552225,1,1,3711123,12,2 ,37,,1",
 "519800,2,2,1998/10/01 07:26:00,139.3882670696,36.1575236877,1,10,3721001,9 ,1 ,44,,6",
 "736000,2,2,1998/10/01 07:26:00,139.448103244,35.7313887815,1,13,0780004,5 ,1 ,25,,2",
 "775100,2,2,1998/10/01 07:26:00,139.3100996887,35.6622954802,1,7,0846102,9 ,1 ,38,,2",
 "841800,2,3,1998/10/01 07:26:00,139.6240941158,35.3482316421,1,2,1132110,12,2 ,33,,12",
 "839500,2,3,1998/10/01 07:26:00,139.6021702973,35.3986366547,2,11,1133001,14,7 ,27,,12",
 "869400,2,3,1998/10/01 07:26:00,139.5357443953,35.3944518256,1,10,1231001,8 ,1 ,29,,12",
 "860200,2,3,1998/10/01 07:26:00,139.5283160264,35.5204459576,1,9,1340109,10,1 ,33,,12",
 "864800,2,3,1998/10/01 07:26:00,139.6764690698,35.5085022198,1,6,1240102,10,1 ,27,,12",
 "55200,2,4,1998/10/01 07:26:00,139.6987577209,35.6906657286,1,10,4222006,9 ,1 ,38,,1",
 "13800,2,3,1998/10/01 07:26:00,139.8976501527,35.6688285708,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:26:00,139.8833367782,35.7343134077,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:26:00,139.9569340266,35.7081932312,2,4,4128103,8 ,1 ,42,,12",
 "71300,2,3,1998/10/01 07:26:00,139.8028335816,35.8222251526,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:26:00,140.0895962811,35.7300431903,1,12,4413008,8 ,1 ,44,,12",
 "172500,2,2,1998/10/01 07:26:00,140.1715191823,36.0152245842,2,4,5024120,13,2 ,44,,6",
 "151800,2,2,1998/10/01 07:26:00,140.0963968308,35.6456777705,1,9,4031206,8 ,1 ,38,,1",
 "195500,2,3,1998/10/01 07:26:00,139.8051380449,35.7504360711,2,4,5420020,8 ,1 ,37,,12",
 "227700,2,3,1998/10/01 07:26:00,139.5034062412,35.3362185434,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:26:00,139.6449251851,35.4850190935,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:26:00,139.5408165796,35.5139402153,1,11,2922005,9 ,1 ,29,,12",
 "246100,2,4,1998/10/01 07:26:00,139.4640068076,35.3328288462,1,5,2510201,3 ,1 ,39,,1",
 "303600,2,3,1998/10/01 07:26:00,139.4447709742,35.5434889016,1,9,2923016,9 ,1 ,30,,12",
 "386400,2,4,1998/10/01 07:26:00,139.7217736582,35.7765646714,1,10,3059002,8 ,1 ,37,,12",
 "370300,2,3,1998/10/01 07:26:00,139.7241533617,35.7680985635,1,10,3037010,10,1 ,39,,12",
 "437000,2,3,1998/10/01 07:26:00,139.8029433464,35.823678536,1,7,3212001,5 ,1 ,36,,12",
 "476100,2,3,1998/10/01 07:26:00,139.4256176237,35.9356947765,1,3,3411213,13,2 ,34,,12",
 "446200,2,2,1998/10/01 07:26:00,139.7767274473,35.8913961005,2,5,3221102,8 ,1 ,37,,2",
 "508300,2,2,1998/10/01 07:26:00,139.6775520715,36.1340822242,1,9,3622214,8 ,1 ,36,,6",
 "483000,2,3,1998/10/01 07:26:00,139.622557997,35.9113593769,1,10,3510101,8 ,1 ,27,,12",
 "630200,2,3,1998/10/01 07:26:00,139.6075073929,35.7905782721,1,7,0513010,8 ,1 ,41,,12",
 "653200,2,3,1998/10/01 07:26:00,139.7793527551,35.7175601704,1,8,0531203,9 ,1 ,39,,12",
 "683100,2,3,1998/10/01 07:26:00,139.8910322636,35.6873942747,2,3,0633106,12,2 ,49,,12",
 "676200,2,3,1998/10/01 07:26:00,139.7741320392,35.6789473707,1,13,0623211,10,1 ,35,,12",
 "747500,2,3,1998/10/01 07:26:00,139.5045248254,35.5301238765,1,5,0810007,4 ,1 ,38,,12",
 "759000,2,2,1998/10/01 07:26:00,139.4242976146,35.6251796121,2,4,0821006,9 ,1 ,55,,1",
 "759000,2,3,1998/10/01 07:26:00,139.4242976146,35.6251796121,2,4,0821006,9 ,1 ,55,,12",
 "777400,2,3,1998/10/01 07:26:00,139.5314233248,35.6564316402,1,5,0844010,8 ,1 ,41,,12",
 "280600,3,3,1998/10/01 07:27:00,139.7248471318,35.6373383128,1,11,2910009,8 ,1 ,42,,8",
 "110400,2,2,1998/10/01 07:27:00,139.9883570414,35.2368115835,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:27:00,140.6739272856,35.9541036696,1,9,4820320,3 ,1 ,16,,6",
 "294400,2,2,1998/10/01 07:27:00,139.5319415968,35.5262673178,1,4,2920001,3 ,1 ,39,,6",
 "414000,2,2,1998/10/01 07:27:00,139.4345978465,35.7793659283,1,10,3122302,3 ,11,32,,6",
 "420900,2,2,1998/10/01 07:27:00,139.4776335631,35.9748023495,1,7,3130307,3 ,1 ,39,,6",
 "441600,4,4,1998/10/01 07:27:00,139.9007256235,35.7948794727,1,9,3215009,9 ,11,30,,8",
 "515200,2,2,1998/10/01 07:27:00,139.2066268645,36.1466798131,1,1,3711123,12,2 ,37,,1",
 "519800,2,2,1998/10/01 07:27:00,139.3845584559,36.1545768448,1,10,3721001,9 ,1 ,44,,6",
 "736000,2,2,1998/10/01 07:27:00,139.4502606121,35.7312618321,1,13,0780004,5 ,1 ,25,,2",
 "775100,2,2,1998/10/01 07:27:00,139.3121846952,35.6618329871,1,7,0846102,9 ,1 ,38,,2",
 "841800,2,3,1998/10/01 07:27:00,139.6276622801,35.3527366055,1,2,1132110,12,2 ,33,,12",
 "839500,2,3,1998/10/01 07:27:00,139.5986232841,35.4022125677,2,11,1133001,14,7 ,27,,12",
 "869400,2,3,1998/10/01 07:27:00,139.5347191854,35.3990315849,1,10,1231001,8 ,1 ,29,,12",
 "860200,2,3,1998/10/01 07:27:00,139.5322093509,35.518430043,1,9,1340109,10,1 ,33,,12",
 "864800,2,3,1998/10/01 07:27:00,139.6779074733,35.5136726077,1,6,1240102,10,1 ,27,,12",
 "55200,2,4,1998/10/01 07:27:00,139.698891115,35.689735295,1,10,4222006,9 ,1 ,38,,1",
 "13800,2,3,1998/10/01 07:27:00,139.9018766763,35.6723818428,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:27:00,139.879152876,35.7315019795,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:27:00,139.9518194258,35.7100842217,2,4,4128103,8 ,1 ,42,,12",
 "151800,2,2,1998/10/01 07:27:00,140.0963967882,35.6456775187,1,9,4031206,8 ,1 ,38,,1",
 "78200,2,3,1998/10/01 07:27:00,140.0845199702,35.7297050823,1,12,4413008,8 ,1 ,44,,12",
 "71300,2,3,1998/10/01 07:27:00,139.8025434247,35.8184482127,1,12,4313309,9 ,1 ,34,,12",
 "195500,2,3,1998/10/01 07:27:00,139.803372781,35.7449633566,2,4,5420020,8 ,1 ,37,,12",
 "227700,2,3,1998/10/01 07:27:00,139.5100985664,35.3396203609,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:27:00,139.652479226,35.4861276234,1,14,2412116,10,1 ,63,,12",
 "172500,2,2,1998/10/01 07:27:00,140.1733581599,36.0137748187,2,4,5024120,13,2 ,44,,6",
 "301300,2,3,1998/10/01 07:27:00,139.5458844253,35.512032964,1,11,2922005,9 ,1 ,29,,12",
 "246100,2,4,1998/10/01 07:27:00,139.4653294975,35.3324229223,1,5,2510201,3 ,1 ,39,,1",
 "303600,2,3,1998/10/01 07:27:00,139.4477061122,35.5468225583,1,9,2923016,9 ,1 ,30,,12",
 "370300,2,3,1998/10/01 07:27:00,139.7262042457,35.7645241621,1,10,3037010,10,1 ,39,,12",
 "386400,2,4,1998/10/01 07:27:00,139.7222539458,35.7723623908,1,10,3059002,8 ,1 ,37,,12",
 "437000,2,3,1998/10/01 07:27:00,139.8025797233,35.8189081777,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,2,1998/10/01 07:27:00,139.7772586242,35.8912275775,2,5,3221102,8 ,1 ,37,,2",
 "476100,2,3,1998/10/01 07:27:00,139.4319022607,35.9319497905,1,3,3411213,13,2 ,34,,12",
 "508300,2,2,1998/10/01 07:27:00,139.6812168117,36.1330644198,1,9,3622214,8 ,1 ,36,,6",
 "483000,2,3,1998/10/01 07:27:00,139.6238205121,35.9079102888,1,10,3510101,8 ,1 ,27,,12",
 "630200,2,3,1998/10/01 07:27:00,139.6004255178,35.7963221484,1,7,0513010,8 ,1 ,41,,12",
 "653200,2,3,1998/10/01 07:27:00,139.7769303641,35.7146478485,1,8,0531203,9 ,1 ,39,,12",
 "683100,2,3,1998/10/01 07:27:00,139.8877303642,35.6865500916,2,3,0633106,12,2 ,49,,12",
 "676200,2,3,1998/10/01 07:27:00,139.7723527456,35.6760854813,1,13,0623211,10,1 ,35,,12",
 "747500,2,3,1998/10/01 07:27:00,139.5108504063,35.5280792493,1,5,0810007,4 ,1 ,38,,12",
 "759000,2,3,1998/10/01 07:27:00,139.4291650028,35.6270529215,2,4,0821006,9 ,1 ,55,,12",
 "777400,2,3,1998/10/01 07:27:00,139.5352246682,35.6546540336,1,5,0844010,8 ,1 ,41,,12",
 "280600,3,3,1998/10/01 07:28:00,139.7292066294,35.6394633265,1,11,2910009,8 ,1 ,42,,8",
 "110400,2,2,1998/10/01 07:28:00,139.9846008961,35.2397368656,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:28:00,140.6766424969,35.9500854409,1,9,4820320,3 ,1 ,16,,6",
 "294400,2,2,1998/10/01 07:28:00,139.5336692613,35.5241280624,1,4,2920001,3 ,1 ,39,,6",
 "414000,2,2,1998/10/01 07:28:00,139.4350767338,35.7775363929,1,10,3122302,3 ,11,32,,6",
 "420900,2,2,1998/10/01 07:28:00,139.4771353675,35.9780311491,1,7,3130307,3 ,1 ,39,,6",
 "441600,4,4,1998/10/01 07:28:00,139.8997191734,35.7931366703,1,9,3215009,9 ,11,30,,8",
 "515200,2,2,1998/10/01 07:28:00,139.2053492862,36.1459371933,1,1,3711123,12,2 ,37,,1",
 "519800,2,2,1998/10/01 07:28:00,139.3792349604,36.1535277881,1,10,3721001,9 ,1 ,44,,6",
 "736000,2,2,1998/10/01 07:28:00,139.4517122809,35.7306272648,1,13,0780004,5 ,1 ,25,,2",
 "775100,2,2,1998/10/01 07:28:00,139.3142882563,35.6614302753,1,7,0846102,9 ,1 ,38,,2",
 "841800,2,3,1998/10/01 07:28:00,139.62804617,35.3581434788,1,2,1132110,12,2 ,33,,12",
 "839500,2,3,1998/10/01 07:28:00,139.5967518055,35.4065607255,2,11,1133001,14,7 ,27,,12",
 "869400,2,3,1998/10/01 07:28:00,139.5337584279,35.4036325645,1,10,1231001,8 ,1 ,29,,12",
 "860200,2,3,1998/10/01 07:28:00,139.5360971498,35.5164072161,1,9,1340109,10,1 ,33,,12",
 "864800,2,3,1998/10/01 07:28:00,139.6813673519,35.5181615613,1,6,1240102,10,1 ,27,,12",
 "18400,2,3,1998/10/01 07:28:00,139.8750238249,35.7286366185,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:28:00,139.9469532647,35.7080295712,2,4,4128103,8 ,1 ,42,,12",
 "55200,2,4,1998/10/01 07:28:00,139.6990506304,35.688787286,1,10,4222006,9 ,1 ,38,,1",
 "13800,2,3,1998/10/01 07:28:00,139.9061257142,35.6759175523,2,14,4116004,5 ,5 ,25,,12",
 "71300,2,3,1998/10/01 07:28:00,139.8014190877,35.8148026239,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:28:00,140.0794444935,35.7293556154,1,12,4413008,8 ,1 ,44,,12",
 "172500,2,2,1998/10/01 07:28:00,140.1679843678,36.0147612625,2,4,5024120,13,2 ,44,,6",
 "151800,2,2,1998/10/01 07:28:00,140.0963967456,35.6456772669,1,9,4031206,8 ,1 ,38,,1",
 "195500,2,3,1998/10/01 07:28:00,139.8015973268,35.7393208052,2,4,5420020,8 ,1 ,37,,12",
 "227700,2,3,1998/10/01 07:28:00,139.5166908787,35.3431741972,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:28:00,139.6582442497,35.4862802739,1,14,2412116,10,1 ,63,,12",
 "303600,2,3,1998/10/01 07:28:00,139.4515754087,35.5494424434,1,9,2923016,9 ,1 ,30,,12",
 "301300,2,3,1998/10/01 07:28:00,139.5512617754,35.5106915033,1,11,2922005,9 ,1 ,29,,12",
 "246100,2,4,1998/10/01 07:28:00,139.4666521743,35.3320169837,1,5,2510201,3 ,1 ,39,,1",
 "386400,2,4,1998/10/01 07:28:00,139.7239812018,35.7684009447,1,10,3059002,8 ,1 ,37,,12",
 "370300,2,3,1998/10/01 07:28:00,139.7291407947,35.7614319172,1,10,3037010,10,1 ,39,,12",
 "437000,2,3,1998/10/01 07:28:00,139.8011650326,35.8143100186,1,7,3212001,5 ,1 ,36,,12",
 "476100,2,3,1998/10/01 07:28:00,139.4381475157,35.9281620248,1,3,3411213,13,2 ,34,,12",
 "446200,2,2,1998/10/01 07:28:00,139.7775004956,35.8911741733,2,5,3221102,8 ,1 ,37,,2",
 "483000,2,3,1998/10/01 07:28:00,139.6253616156,35.9045332506,1,10,3510101,8 ,1 ,27,,12",
 "508300,2,2,1998/10/01 07:28:00,139.6879645856,36.1310441976,1,9,3622214,8 ,1 ,36,,6",
 "630200,2,3,1998/10/01 07:28:00,139.5977265378,35.8046242627,1,7,0513010,8 ,1 ,41,,12",
 "683100,2,3,1998/10/01 07:28:00,139.8842961967,35.6861022585,2,3,0633106,12,2 ,49,,12",
 "676200,2,3,1998/10/01 07:28:00,139.770466262,35.6732715031,1,13,0623211,10,1 ,35,,12",
 "653200,2,3,1998/10/01 07:28:00,139.7752007972,35.7114345103,1,8,0531203,9 ,1 ,39,,12",
 "747500,2,3,1998/10/01 07:28:00,139.5171829622,35.5260497488,1,5,0810007,4 ,1 ,38,,12",
 "759000,2,3,1998/10/01 07:28:00,139.4343044688,35.6286569629,2,4,0821006,9 ,1 ,55,,12",
 "777400,2,3,1998/10/01 07:28:00,139.5393041856,35.6533540279,1,5,0844010,8 ,1 ,41,,12",
 "683100,2,3,1998/10/01 07:29:00,139.8808559763,35.6856866832,2,3,0633106,12,2 ,49,,12",
 "280600,3,3,1998/10/01 07:29:00,139.7331924063,35.6414710295,1,11,2910009,8 ,1 ,42,,8",
 "676200,2,3,1998/10/01 07:29:00,139.7681563677,35.6706743784,1,13,0623211,10,1 ,35,,12",
 "653200,2,3,1998/10/01 07:29:00,139.7751667921,35.7079476994,1,8,0531203,9 ,1 ,39,,12",
 "508300,2,2,1998/10/01 07:29:00,139.6938537657,36.1327784874,1,9,3622214,8 ,1 ,36,,6",
 "483000,2,3,1998/10/01 07:29:00,139.6270238611,35.9012055628,1,10,3510101,8 ,1 ,27,,12",
 "476100,2,3,1998/10/01 07:29:00,139.444926067,35.9251664903,1,3,3411213,13,2 ,34,,12",
 "370300,2,3,1998/10/01 07:29:00,139.7327812051,35.7588310764,1,10,3037010,10,1 ,39,,12",
 "303600,2,3,1998/10/01 07:29:00,139.4564319238,35.5503929823,1,9,2923016,9 ,1 ,30,,12",
 "246100,2,4,1998/10/01 07:29:00,139.467233039,35.3320404125,1,5,2510201,3 ,1 ,39,,1",
 "110400,2,2,1998/10/01 07:29:00,139.9796711156,35.2394551653,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:29:00,140.6790053085,35.9455771423,1,9,4820320,3 ,1 ,16,,6",
 "195500,2,3,1998/10/01 07:29:00,139.7994547641,35.7337852902,2,4,5420020,8 ,1 ,37,,12",
 "172500,2,2,1998/10/01 07:29:00,140.1665938542,36.0143758959,2,4,5024120,13,2 ,44,,6",
 "78200,2,3,1998/10/01 07:29:00,140.0743724661,35.7289783956,1,12,4413008,8 ,1 ,44,,12",
 "294400,2,2,1998/10/01 07:29:00,139.5364124522,35.5241915977,1,4,2920001,3 ,1 ,39,,6",
 "71300,2,3,1998/10/01 07:29:00,139.7991215549,35.8115263394,1,12,4313309,9 ,1 ,34,,12",
 "55200,2,4,1998/10/01 07:29:00,139.6994370673,35.6879698868,1,10,4222006,9 ,1 ,38,,1",
 "32200,2,3,1998/10/01 07:29:00,139.9432006686,35.7045226452,2,4,4128103,8 ,1 ,42,,12",
 "18400,2,3,1998/10/01 07:29:00,139.8708547987,35.7258126968,1,9,4120311,8 ,1 ,44,,12",
 "13800,2,3,1998/10/01 07:29:00,139.9103750782,35.6794531483,2,14,4116004,5 ,5 ,25,,12",
 "151800,2,2,1998/10/01 07:29:00,140.0963967029,35.6456770151,1,9,4031206,8 ,1 ,38,,1",
 "414000,2,2,1998/10/01 07:29:00,139.4358419413,35.7757834011,1,10,3122302,3 ,11,32,,6",
 "420900,2,2,1998/10/01 07:29:00,139.4768852365,35.9812808876,1,7,3130307,3 ,1 ,39,,6",
 "441600,4,4,1998/10/01 07:29:00,139.8986149905,35.7915142959,1,9,3215009,9 ,11,30,,8",
 "860200,2,3,1998/10/01 07:29:00,139.539980333,35.5143787007,1,9,1340109,10,1 ,33,,12",
 "839500,2,3,1998/10/01 07:29:00,139.5965977694,35.4100593499,2,11,1133001,14,7 ,27,,12",
 "841800,2,3,1998/10/01 07:29:00,139.6296877842,35.3633671162,1,2,1132110,12,2 ,33,,12",
 "515200,2,2,1998/10/01 07:29:00,139.203788687,36.145290456,1,1,3711123,12,2 ,37,,1",
 "519800,2,2,1998/10/01 07:29:00,139.3748496954,36.1524706789,1,10,3721001,9 ,1 ,44,,6",
 "227700,2,3,1998/10/01 07:29:00,139.5232724921,35.3467407486,1,9,2410203,8 ,10,16,,12",
 "869400,2,3,1998/10/01 07:29:00,139.5343701527,35.408291211,1,10,1231001,8 ,1 ,29,,12",
 "864800,2,3,1998/10/01 07:29:00,139.6858659171,35.5220408104,1,6,1240102,10,1 ,27,,12",
 "234600,2,3,1998/10/01 07:29:00,139.6630659375,35.4910466129,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:29:00,139.5566936672,35.5095575314,1,11,2922005,9 ,1 ,29,,12",
 "386400,2,4,1998/10/01 07:29:00,139.7261840131,35.7645561309,1,10,3059002,8 ,1 ,37,,12",
 "446200,2,2,1998/10/01 07:29:00,139.7781767628,35.8910280714,2,5,3221102,8 ,1 ,37,,2",
 "437000,2,3,1998/10/01 07:29:00,139.7980720423,35.8102580247,1,7,3212001,5 ,1 ,36,,12",
 "736000,2,2,1998/10/01 07:29:00,139.4507203734,35.7296432797,1,13,0780004,5 ,1 ,25,,2",
 "775100,2,2,1998/10/01 07:29:00,139.3164149911,35.6611248983,1,7,0846102,9 ,1 ,38,,2",
 "747500,2,3,1998/10/01 07:29:00,139.5230112451,35.5231977038,1,5,0810007,4 ,1 ,38,,12",
 "630200,2,3,1998/10/01 07:29:00,139.5915555758,35.8114437825,1,7,0513010,8 ,1 ,41,,12",
 "759000,2,3,1998/10/01 07:29:00,139.4394505008,35.6302482823,2,4,0821006,9 ,1 ,55,,12",
 "777400,2,3,1998/10/01 07:29:00,139.5434337894,35.652158647,1,5,0844010,8 ,1 ,41,,12",
 "839500,2,3,1998/10/01 07:30:00,139.5967045671,35.4146835235,2,11,1133001,14,7 ,27,,12",
 "841800,2,3,1998/10/01 07:30:00,139.6294525205,35.3687886497,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:30:00,139.5439697901,35.512526363,1,9,1340109,10,1 ,33,,12",
 "864800,2,3,1998/10/01 07:30:00,139.6904257356,35.5258743407,1,6,1240102,10,1 ,27,,12",
 "280600,3,3,1998/10/01 07:30:00,139.7333894778,35.6452147856,1,11,2910009,8 ,1 ,42,,8",
 "280600,4,4,1998/10/01 07:30:00,139.7333894778,35.6452147856,1,11,2910009,8 ,97,42,,97",
 "869400,2,3,1998/10/01 07:30:00,139.5364719643,35.4125611964,1,10,1231001,8 ,1 ,29,,12",
 "13800,2,3,1998/10/01 07:30:00,139.9146244273,35.6829889031,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:30:00,139.8669069855,35.7227923889,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:30:00,139.9394050495,35.7010469251,2,4,4128103,8 ,1 ,42,,12",
 "55200,2,4,1998/10/01 07:30:00,139.7004511539,35.6874890388,1,10,4222006,9 ,1 ,38,,1",
 "55200,3,5,1998/10/01 07:30:00,139.7004511539,35.6874890388,1,10,4222006,9 ,97,38,,97",
 "71300,2,3,1998/10/01 07:30:00,139.7965249747,35.8083882335,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:30:00,140.0692991764,35.7286235103,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,2,1998/10/01 07:30:00,140.0963966603,35.6456767633,1,9,4031206,8 ,1 ,38,,1",
 "151800,2,3,1998/10/01 07:30:00,140.0963966603,35.6456767633,1,9,4031206,8 ,1 ,38,,10",
 "172500,2,2,1998/10/01 07:30:00,140.162067753,36.0098501499,2,4,5024120,13,2 ,44,,6",
 "195500,2,3,1998/10/01 07:30:00,139.794037775,35.7304812087,2,4,5420020,8 ,1 ,37,,12",
 "227700,2,3,1998/10/01 07:30:00,139.5295071704,35.3506236316,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:30:00,139.6675204015,35.4960821414,1,14,2412116,10,1 ,63,,12",
 "246100,2,4,1998/10/01 07:30:00,139.4682997995,35.3327989839,1,5,2510201,3 ,1 ,39,,1",
 "246100,3,5,1998/10/01 07:30:00,139.4682997995,35.3327989839,1,5,2510201,3 ,97,39,,97",
 "301300,2,3,1998/10/01 07:30:00,139.5622559432,35.5101101097,1,11,2922005,9 ,1 ,29,,12",
 "303600,2,3,1998/10/01 07:30:00,139.4602278834,35.5528947898,1,9,2923016,9 ,1 ,30,,12",
 "110400,2,2,1998/10/01 07:30:00,139.9764921378,35.2363838996,1,12,4621121,1 ,11,41,,8",
 "126500,2,2,1998/10/01 07:30:00,140.6756041468,35.9415365221,1,9,4820320,3 ,1 ,16,,6",
 "126500,3,3,1998/10/01 07:30:00,140.6756041468,35.9415365221,1,9,4820320,3 ,97,16,,97",
 "294400,2,2,1998/10/01 07:30:00,139.5390734332,35.52517939,1,4,2920001,3 ,1 ,39,,6",
 "370300,2,3,1998/10/01 07:30:00,139.7356902442,35.7556811967,1,10,3037010,10,1 ,39,,12",
 "386400,2,4,1998/10/01 07:30:00,139.7293821735,35.7612598135,1,10,3059002,8 ,1 ,37,,12",
 "437000,2,3,1998/10/01 07:30:00,139.7951341701,35.8061475915,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,2,1998/10/01 07:30:00,139.7788611913,35.8909076854,2,5,3221102,8 ,1 ,37,,2",
 "414000,2,2,1998/10/01 07:30:00,139.4367095985,35.7740565219,1,10,3122302,3 ,11,32,,6",
 "420900,2,2,1998/10/01 07:30:00,139.4743753656,35.9820575959,1,7,3130307,3 ,1 ,39,,6",
 "420900,3,3,1998/10/01 07:30:00,139.4743753656,35.9820575959,1,7,3130307,3 ,97,39,,97",
 "476100,2,3,1998/10/01 07:30:00,139.4524621565,35.9235624577,1,3,3411213,13,2 ,34,,12",
 "441600,4,4,1998/10/01 07:30:00,139.8975543129,35.7898751081,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 07:30:00,139.6298499252,35.8984445506,1,10,3510101,8 ,1 ,27,,12",
 "508300,2,2,1998/10/01 07:30:00,139.693893933,36.1362338375,1,9,3622214,8 ,1 ,36,,6",
 "508300,2,3,1998/10/01 07:30:00,139.693893933,36.1362338375,1,9,3622214,8 ,1 ,36,,12",
 "519800,2,2,1998/10/01 07:30:00,139.3695636663,36.1513746788,1,10,3721001,9 ,1 ,44,,6",
 "515200,2,2,1998/10/01 07:30:00,139.2022263593,36.144646381,1,1,3711123,12,2 ,37,,1",
 "630200,2,3,1998/10/01 07:30:00,139.5833712376,35.8168641432,1,7,0513010,8 ,1 ,41,,12",
 "653200,2,3,1998/10/01 07:30:00,139.7743430657,35.7047352216,1,8,0531203,9 ,1 ,39,,12",
 "759000,2,3,1998/10/01 07:30:00,139.4448464213,35.6309469971,2,4,0821006,9 ,1 ,55,,12",
 "747500,2,3,1998/10/01 07:30:00,139.5287540458,35.5202183212,1,5,0810007,4 ,1 ,38,,12",
 "736000,2,2,1998/10/01 07:30:00,139.4499116566,35.7292878662,1,13,0780004,5 ,1 ,25,,2",
 "736000,3,3,1998/10/01 07:30:00,139.4499116566,35.7292878662,1,13,0780004,5 ,97,25,,97",
 "676200,2,3,1998/10/01 07:30:00,139.7654925219,35.6709305845,1,13,0623211,10,1 ,35,,12",
 "775100,2,2,1998/10/01 07:30:00,139.318555317,35.6608965723,1,7,0846102,9 ,1 ,38,,2",
 "775100,3,3,1998/10/01 07:30:00,139.318555317,35.6608965723,1,7,0846102,9 ,97,38,,97",
 "683100,2,3,1998/10/01 07:30:00,139.8774277107,35.6852099462,2,3,0633106,12,2 ,49,,12",
 "777400,2,3,1998/10/01 07:30:00,139.5475545534,35.6509439594,1,5,0844010,8 ,1 ,41,,12",
 "830300,2,2,1998/10/01 07:31:00,139.5357476636,35.4528405783,2,4,1251203,8 ,1 ,37,,1",
 "839500,2,3,1998/10/01 07:31:00,139.5976481237,35.4192527089,2,11,1133001,14,7 ,27,,12",
 "96600,2,2,1998/10/01 07:31:00,140.0871254296,35.5176982191,1,3,4510101,13,2 ,33,,2",
 "841800,2,3,1998/10/01 07:31:00,139.6266737793,35.3735012944,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:31:00,139.5483718496,35.5114125959,1,9,1340109,10,1 ,33,,12",
 "864800,2,3,1998/10/01 07:31:00,139.6949249401,35.5297551932,1,6,1240102,10,1 ,27,,12",
 "869400,2,3,1998/10/01 07:31:00,139.5410182876,35.4153874122,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,2,1998/10/01 07:31:00,139.5740071933,35.3660212863,1,6,1230404,9 ,1 ,31,,1",
 "2300,2,2,1998/10/01 07:31:00,139.9173947464,35.7220512666,1,6,4111102,8 ,1 ,36,,2",
 "13800,2,3,1998/10/01 07:31:00,139.918867447,35.6865298616,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:31:00,139.8628578665,35.7198531916,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:31:00,139.9348931671,35.6982194849,2,4,4128103,8 ,1 ,42,,12",
 "71300,2,3,1998/10/01 07:31:00,139.7946766586,35.8049443943,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:31:00,140.0642327773,35.7282197198,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,3,1998/10/01 07:31:00,140.0962236132,35.6452123105,1,9,4031206,8 ,1 ,38,,10",
 "172500,2,2,1998/10/01 07:31:00,140.1581074087,36.0068151859,2,4,5024120,13,2 ,44,,6",
 "172500,2,3,1998/10/01 07:31:00,140.1581074087,36.0068151859,2,4,5024120,13,2 ,44,,12",
 "195500,2,3,1998/10/01 07:31:00,139.7909319123,35.7258286915,2,4,5420020,8 ,1 ,37,,12",
 "227700,2,3,1998/10/01 07:31:00,139.5317030018,35.3567549472,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:31:00,139.6728749365,35.5006234859,1,14,2412116,10,1 ,63,,12",
 "303600,2,3,1998/10/01 07:31:00,139.4614608427,35.5568139269,1,9,2923016,9 ,1 ,30,,12",
 "301300,2,3,1998/10/01 07:31:00,139.5677673694,35.5110173194,1,11,2922005,9 ,1 ,29,,12",
 "110400,2,2,1998/10/01 07:31:00,139.9712590861,35.2360773488,1,12,4621121,1 ,11,41,,8",
 "108100,2,2,1998/10/01 07:31:00,140.0231384434,35.3940934011,2,4,4615025,8 ,1 ,43,,6",
 "170200,2,2,1998/10/01 07:31:00,140.2045713419,36.0675583467,2,3,5022220,13,2 ,43,,2",
 "340400,2,2,1998/10/01 07:31:00,139.5323001469,35.5947518027,2,5,2140202,9 ,1 ,41,,1",
 "262200,2,2,1998/10/01 07:31:00,139.1509753434,35.6314452209,1,9,2713004,3 ,1 ,42,,6",
 "276000,2,2,1998/10/01 07:31:00,139.2571464504,35.3682392835,1,2,2821003,12,2 ,33,,1",
 "294400,2,2,1998/10/01 07:31:00,139.541315351,35.523758718,1,4,2920001,3 ,1 ,39,,6",
 "370300,2,3,1998/10/01 07:31:00,139.7376932095,35.7523272467,1,10,3037010,10,1 ,39,,12",
 "315100,4,4,1998/10/01 07:31:00,139.6059168741,35.4901509103,1,11,2020106,10,9 ,39,,8",
 "386400,2,4,1998/10/01 07:31:00,139.7332465839,35.7584210368,1,10,3059002,8 ,1 ,37,,12",
 "437000,2,3,1998/10/01 07:31:00,139.7933982077,35.8015820287,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,2,1998/10/01 07:31:00,139.7795415672,35.8907729289,2,5,3221102,8 ,1 ,37,,2",
 "414000,2,2,1998/10/01 07:31:00,139.4374182979,35.7722787813,1,10,3122302,3 ,11,32,,6",
 "476100,2,3,1998/10/01 07:31:00,139.4600063609,35.9219832492,1,3,3411213,13,2 ,34,,12",
 "441600,4,4,1998/10/01 07:31:00,139.8971314167,35.788193235,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 07:31:00,139.6325133339,35.895574639,1,10,3510101,8 ,1 ,27,,12",
 "462300,2,2,1998/10/01 07:31:00,139.7745266622,35.9391653255,1,8,3235004,9 ,1 ,38,,6",
 "508300,2,3,1998/10/01 07:31:00,139.6913292946,36.1285362696,1,9,3622214,8 ,1 ,36,,12",
 "515200,2,2,1998/10/01 07:31:00,139.2010888801,36.1439165514,1,1,3711123,12,2 ,37,,1",
 "519800,2,2,1998/10/01 07:31:00,139.3643493682,36.1501056655,1,10,3721001,9 ,1 ,44,,6",
 "609500,2,2,1998/10/01 07:31:00,139.6613932613,35.6679891302,1,7,0430102,8 ,1 ,51,,1",
 "639400,2,2,1998/10/01 07:31:00,139.6854661977,35.7730837864,1,10,0520112,9 ,1 ,38,,6",
 "630200,2,3,1998/10/01 07:31:00,139.5751669776,35.8221322088,1,7,0513010,8 ,1 ,41,,12",
 "630200,2,4,1998/10/01 07:31:00,139.5751669776,35.8221322088,1,7,0513010,8 ,1 ,41,,10",
 "644000,2,2,1998/10/01 07:31:00,139.6672351223,35.7676266975,1,4,0522008,5 ,1 ,52,,1",
 "747500,2,3,1998/10/01 07:31:00,139.5345010458,35.5172446183,1,5,0810007,4 ,1 ,38,,12",
 "811900,2,2,1998/10/01 07:31:00,139.5991196659,35.413153891,1,3,1123201,13,2 ,31,,1",
 "653200,2,3,1998/10/01 07:31:00,139.7734310254,35.7013157038,1,8,0531203,9 ,1 ,39,,12",
 "648600,2,2,1998/10/01 07:31:00,139.7349252306,35.7711443594,2,3,0530007,13,2 ,37,,1",
 "777400,2,3,1998/10/01 07:31:00,139.5517172143,35.6498518536,1,5,0844010,8 ,1 ,41,,12",
 "676200,2,3,1998/10/01 07:31:00,139.7625232529,35.6730340249,1,13,0623211,10,1 ,35,,12",
 "759000,2,3,1998/10/01 07:31:00,139.4501068651,35.629670076,2,4,0821006,9 ,1 ,55,,12",
 "683100,2,3,1998/10/01 07:31:00,139.8739990275,35.6847354659,2,3,0633106,12,2 ,49,,12",
 "692300,2,2,1998/10/01 07:31:00,139.5453051716,35.6477757045,2,4,0711108,8 ,1 ,25,,1",
 "830300,2,2,1998/10/01 07:32:00,139.5354812931,35.4538102411,2,4,1251203,8 ,1 ,37,,1",
 "839500,2,3,1998/10/01 07:32:00,139.5969419097,35.4238326452,2,11,1133001,14,7 ,27,,12",
 "96600,2,2,1998/10/01 07:32:00,140.0871258437,35.517699038,1,3,4510101,13,2 ,33,,2",
 "841800,2,3,1998/10/01 07:32:00,139.6208659964,35.3760445493,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:32:00,139.5527788806,35.5103120609,1,9,1340109,10,1 ,33,,12",
 "864800,2,3,1998/10/01 07:32:00,139.6943103674,35.5292002312,1,6,1240102,10,1 ,27,,12",
 "869400,2,3,1998/10/01 07:32:00,139.5458154142,35.4179459006,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,2,1998/10/01 07:32:00,139.5738905721,35.366171928,1,6,1230404,9 ,1 ,31,,1",
 "2300,2,2,1998/10/01 07:32:00,139.9177294397,35.7224890835,1,6,4111102,8 ,1 ,36,,2",
 "13800,2,3,1998/10/01 07:32:00,139.9231108418,35.6900706679,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:32:00,139.8584498874,35.717302557,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:32:00,139.9302689563,35.6954920662,2,4,4128103,8 ,1 ,42,,12",
 "71300,2,3,1998/10/01 07:32:00,139.7933021904,35.8013294883,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:32:00,140.0593729399,35.7270097768,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,3,1998/10/01 07:32:00,140.0960505682,35.6447478574,1,9,4031206,8 ,1 ,38,,10",
 "172500,2,3,1998/10/01 07:32:00,140.1553792689,36.0023432724,2,4,5024120,13,2 ,44,,12",
 "195500,2,3,1998/10/01 07:32:00,139.7918740343,35.7201277271,2,4,5420020,8 ,1 ,37,,12",
 "227700,2,3,1998/10/01 07:32:00,139.529395894,35.3626963473,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:32:00,139.6760341426,35.5062563106,1,14,2412116,10,1 ,63,,12",
 "303600,2,3,1998/10/01 07:32:00,139.4620770256,35.5608806512,1,9,2923016,9 ,1 ,30,,12",
 "301300,2,3,1998/10/01 07:32:00,139.5732816441,35.5119132578,1,11,2922005,9 ,1 ,29,,12",
 "110400,2,2,1998/10/01 07:32:00,139.9676926056,35.2328828842,1,12,4621121,1 ,11,41,,8",
 "108100,2,2,1998/10/01 07:32:00,140.0229120095,35.3993664138,2,4,4615025,8 ,1 ,43,,6",
 "170200,2,2,1998/10/01 07:32:00,140.2051089579,36.0677474721,2,3,5022220,13,2 ,43,,2",
 "340400,2,2,1998/10/01 07:32:00,139.5341060084,35.5941851282,2,5,2140202,9 ,1 ,41,,1",
 "262200,2,2,1998/10/01 07:32:00,139.1493774488,35.628397516,1,9,2713004,3 ,1 ,42,,6",
 "276000,2,2,1998/10/01 07:32:00,139.2565973572,35.36852219,1,2,2821003,12,2 ,33,,1",
 "294400,2,2,1998/10/01 07:32:00,139.5436261412,35.5246144497,1,4,2920001,3 ,1 ,39,,6",
 "370300,2,3,1998/10/01 07:32:00,139.7391478887,35.7486904297,1,10,3037010,10,1 ,39,,12",
 "315100,4,4,1998/10/01 07:32:00,139.6067562969,35.4906548322,1,11,2020106,10,9 ,39,,8",
 "386400,2,4,1998/10/01 07:32:00,139.7358841423,35.7547641033,1,10,3059002,8 ,1 ,37,,12",
 "437000,2,3,1998/10/01 07:32:00,139.7917009465,35.7970068505,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,2,1998/10/01 07:32:00,139.7802219408,35.8906381685,2,5,3221102,8 ,1 ,37,,2",
 "414000,2,2,1998/10/01 07:32:00,139.4380568091,35.770483859,1,10,3122302,3 ,11,32,,6",
 "441600,4,4,1998/10/01 07:32:00,139.8976016193,35.7865541486,1,9,3215009,9 ,11,30,,8",
 "476100,2,3,1998/10/01 07:32:00,139.467547255,35.9203942,1,3,3411213,13,2 ,34,,12",
 "483000,2,3,1998/10/01 07:32:00,139.6346071345,35.892402082,1,10,3510101,8 ,1 ,27,,12",
 "462300,2,2,1998/10/01 07:32:00,139.7771964112,35.9379210043,1,8,3235004,9 ,1 ,38,,6",
 "508300,2,3,1998/10/01 07:32:00,139.6872591863,36.1210251362,1,9,3622214,8 ,1 ,36,,12",
 "515200,2,2,1998/10/01 07:32:00,139.1998852313,36.142885225,1,1,3711123,12,2 ,37,,1",
 "519800,2,2,1998/10/01 07:32:00,139.3591938316,36.1501950055,1,10,3721001,9 ,1 ,44,,6",
 "609500,2,2,1998/10/01 07:32:00,139.6610927928,35.6690834349,1,7,0430102,8 ,1 ,51,,1",
 "639400,2,2,1998/10/01 07:32:00,139.6841361939,35.7745760528,1,10,0520112,9 ,1 ,38,,6",
 "630200,2,4,1998/10/01 07:32:00,139.575329938,35.8229339543,1,7,0513010,8 ,1 ,41,,10",
 "644000,2,2,1998/10/01 07:32:00,139.6657935984,35.7677149326,1,4,0522008,5 ,1 ,52,,1",
 "747500,2,3,1998/10/01 07:32:00,139.5402297267,35.5142478882,1,5,0810007,4 ,1 ,38,,12",
 "811900,2,2,1998/10/01 07:32:00,139.5988359609,35.412890204,1,3,1123201,13,2 ,31,,1",
 "648600,2,2,1998/10/01 07:32:00,139.7327653682,35.777836541,2,3,0530007,13,2 ,37,,1",
 "648600,2,3,1998/10/01 07:32:00,139.7327653682,35.777836541,2,3,0530007,13,2 ,37,,12",
 "653200,2,3,1998/10/01 07:32:00,139.7729659187,35.6978170356,1,8,0531203,9 ,1 ,39,,12",
 "777400,2,3,1998/10/01 07:32:00,139.5560839849,35.6495643379,1,5,0844010,8 ,1 ,41,,12",
 "676200,2,3,1998/10/01 07:32:00,139.7595423627,35.6751198589,1,13,0623211,10,1 ,35,,12",
 "759000,2,3,1998/10/01 07:32:00,139.4549753574,35.627623626,2,4,0821006,9 ,1 ,55,,12",
 "683100,2,3,1998/10/01 07:32:00,139.8705563255,35.6843427833,2,3,0633106,12,2 ,49,,12",
 "692300,2,2,1998/10/01 07:32:00,139.5452715901,35.6495420476,2,4,0711108,8 ,1 ,25,,1",
 "830300,2,2,1998/10/01 07:33:00,139.5350342908,35.4547297779,2,4,1251203,8 ,1 ,37,,1",
 "839500,2,3,1998/10/01 07:33:00,139.5959892959,35.4283929184,2,11,1133001,14,7 ,27,,12",
 "96600,2,2,1998/10/01 07:33:00,140.0871262578,35.5176998569,1,3,4510101,13,2 ,33,,2",
 "841800,2,3,1998/10/01 07:33:00,139.6173528719,35.3805515813,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:33:00,139.5572656786,35.5095514657,1,9,1340109,10,1 ,33,,12",
 "864800,2,3,1998/10/01 07:33:00,139.6889058733,35.5268307024,1,6,1240102,10,1 ,27,,12",
 "869400,2,3,1998/10/01 07:33:00,139.5506190666,35.4204963032,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,2,1998/10/01 07:33:00,139.5724033146,35.3671185602,1,6,1230404,9 ,1 ,31,,1",
 "2300,2,2,1998/10/01 07:33:00,139.9180641367,35.7229268995,1,6,4111102,8 ,1 ,36,,2",
 "13800,2,3,1998/10/01 07:33:00,139.9273710687,35.6935977601,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:33:00,139.8542047687,35.7145534437,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:33:00,139.9259658494,35.6924529264,2,4,4128103,8 ,1 ,42,,12",
 "71300,2,3,1998/10/01 07:33:00,139.7919599497,35.797706571,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:33:00,140.0546192553,35.7255185233,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,3,1998/10/01 07:33:00,140.0958775252,35.644283404,1,9,4031206,8 ,1 ,38,,10",
 "172500,2,3,1998/10/01 07:33:00,140.152568398,35.9979050514,2,4,5024120,13,2 ,44,,12",
 "195500,2,3,1998/10/01 07:33:00,139.7924947824,35.7144397046,2,4,5420020,8 ,1 ,37,,12",
 "227700,2,3,1998/10/01 07:33:00,139.529815094,35.3689830692,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:33:00,139.6773227277,35.5124440904,1,14,2412116,10,1 ,63,,12",
 "303600,2,3,1998/10/01 07:33:00,139.4641401731,35.5646151378,1,9,2923016,9 ,1 ,30,,12",
 "301300,2,3,1998/10/01 07:33:00,139.5787943016,35.5128160838,1,11,2922005,9 ,1 ,29,,12",
 "108100,2,2,1998/10/01 07:33:00,140.0222253466,35.4045123591,2,4,4615025,8 ,1 ,43,,6",
 "110400,2,2,1998/10/01 07:33:00,139.9640071014,35.229829376,1,12,4621121,1 ,11,41,,8",
 "170200,2,2,1998/10/01 07:33:00,140.2050513094,36.0679181147,2,3,5022220,13,2 ,43,,2",
 "340400,2,2,1998/10/01 07:33:00,139.5359118447,35.5936184264,2,5,2140202,9 ,1 ,41,,1",
 "262200,2,2,1998/10/01 07:33:00,139.1498930509,35.6256145984,1,9,2713004,3 ,1 ,42,,6",
 "276000,2,2,1998/10/01 07:33:00,139.2560482603,35.3688050939,1,2,2821003,12,2 ,33,,1",
 "294400,2,2,1998/10/01 07:33:00,139.5459581872,35.5259730104,1,4,2920001,3 ,1 ,39,,6",
 "370300,2,3,1998/10/01 07:33:00,139.7424290921,35.7457947578,1,10,3037010,10,1 ,39,,12",
 "315100,4,4,1998/10/01 07:33:00,139.6071253514,35.4909943045,1,11,2020106,10,9 ,39,,8",
 "386400,2,4,1998/10/01 07:33:00,139.7389199011,35.7513258292,1,10,3059002,8 ,1 ,37,,12",
 "437000,2,3,1998/10/01 07:33:00,139.7900071777,35.7924308237,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,2,1998/10/01 07:33:00,139.7809023121,35.8905034042,2,5,3221102,8 ,1 ,37,,2",
 "414000,2,2,1998/10/01 07:33:00,139.4393197592,35.7691213033,1,10,3122302,3 ,11,32,,6",
 "441600,4,4,1998/10/01 07:33:00,139.8961120763,35.7850578539,1,9,3215009,9 ,11,30,,8",
 "476100,2,3,1998/10/01 07:33:00,139.4737156163,35.916777263,1,3,3411213,13,2 ,34,,12",
 "483000,2,3,1998/10/01 07:33:00,139.6366115404,35.8891916442,1,10,3510101,8 ,1 ,27,,12",
 "462300,2,2,1998/10/01 07:33:00,139.7777307405,35.9347099073,1,8,3235004,9 ,1 ,38,,6",
 "487600,2,2,1998/10/01 07:33:00,139.6419206464,35.9871273105,1,10,3512006,6 ,1 ,36,,6",
 "508300,2,3,1998/10/01 07:33:00,139.6817964605,36.1141754152,1,9,3622214,8 ,1 ,36,,12",
 "515200,2,2,1998/10/01 07:33:00,139.1991969145,36.1418466257,1,1,3711123,12,2 ,37,,1",
 "519800,2,2,1998/10/01 07:33:00,139.3538588996,36.1511778177,1,10,3721001,9 ,1 ,44,,6",
 "609500,2,2,1998/10/01 07:33:00,139.6592992374,35.6692153522,1,7,0430102,8 ,1 ,51,,1",
 "639400,2,2,1998/10/01 07:33:00,139.6813296401,35.775573279,1,10,0520112,9 ,1 ,38,,6",
 "630200,2,4,1998/10/01 07:33:00,139.5759031911,35.8229633814,1,7,0513010,8 ,1 ,41,,10",
 "644000,2,2,1998/10/01 07:33:00,139.6642077698,35.768108557,1,4,0522008,5 ,1 ,52,,1",
 "747500,2,3,1998/10/01 07:33:00,139.546344582,35.5119170381,1,5,0810007,4 ,1 ,38,,12",
 "811900,2,2,1998/10/01 07:33:00,139.5985522577,35.4126265164,1,3,1123201,13,2 ,31,,1",
 "648600,2,3,1998/10/01 07:33:00,139.7314326346,35.7793502957,2,3,0530007,13,2 ,37,,12",
 "653200,2,3,1998/10/01 07:33:00,139.7720560812,35.6944095904,1,8,0531203,9 ,1 ,39,,12",
 "777400,2,3,1998/10/01 07:33:00,139.560065315,35.6509359477,1,5,0844010,8 ,1 ,41,,12",
 "676200,2,3,1998/10/01 07:33:00,139.7561314682,35.676670064,1,13,0623211,10,1 ,35,,12",
 "759000,2,3,1998/10/01 07:33:00,139.458910859,35.6244864488,2,4,0821006,9 ,1 ,55,,12",
 "683100,2,3,1998/10/01 07:33:00,139.867095564,35.6840633377,2,3,0633106,12,2 ,49,,12",
 "692300,2,2,1998/10/01 07:33:00,139.5452380069,35.6513083902,2,4,0711108,8 ,1 ,25,,1",
 "830300,2,2,1998/10/01 07:34:00,139.5346543556,35.4556733194,2,4,1251203,8 ,1 ,37,,1",
 "839500,2,3,1998/10/01 07:34:00,139.5970880254,35.4327622637,2,11,1133001,14,7 ,27,,12",
 "96600,2,2,1998/10/01 07:34:00,140.087126672,35.5177006758,1,3,4510101,13,2 ,33,,2",
 "841800,2,3,1998/10/01 07:34:00,139.6147569278,35.3855706446,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:34:00,139.5618227037,35.5100402532,1,9,1340109,10,1 ,33,,12",
 "864800,2,3,1998/10/01 07:34:00,139.6845681196,35.5304844673,1,6,1240102,10,1 ,27,,12",
 "869400,2,3,1998/10/01 07:34:00,139.5546970853,35.4236877713,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,2,1998/10/01 07:34:00,139.5710773215,35.368056698,1,6,1230404,9 ,1 ,31,,1",
 "2300,2,2,1998/10/01 07:34:00,139.9184181974,35.7233334037,1,6,4111102,8 ,1 ,36,,2",
 "13800,2,3,1998/10/01 07:34:00,139.9321736784,35.6966155398,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:34:00,139.8500039424,35.7117593128,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:34:00,139.9219769178,35.6891245358,2,4,4128103,8 ,1 ,42,,12",
 "71300,2,3,1998/10/01 07:34:00,139.7906188615,35.7940833799,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:34:00,140.0498666245,35.7240252175,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,3,1998/10/01 07:34:00,140.0957044842,35.6438189503,1,9,4031206,8 ,1 ,38,,10",
 "172500,2,3,1998/10/01 07:34:00,140.1497280755,35.9934790833,2,4,5024120,13,2 ,44,,12",
 "195500,2,3,1998/10/01 07:34:00,139.7911697552,35.7087238482,2,4,5420020,8 ,1 ,37,,12",
 "227700,2,3,1998/10/01 07:34:00,139.5317139265,35.3750954621,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:34:00,139.6810812682,35.5178909941,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:34:00,139.5843008524,35.512392915,1,11,2922005,9 ,1 ,29,,12",
 "303600,2,3,1998/10/01 07:34:00,139.4674736334,35.5676565767,1,9,2923016,9 ,1 ,30,,12",
 "110400,2,2,1998/10/01 07:34:00,139.9593119204,35.2278122053,1,12,4621121,1 ,11,41,,8",
 "108100,2,2,1998/10/01 07:34:00,140.020817454,35.4091079329,2,4,4615025,8 ,1 ,43,,6",
 "170200,2,2,1998/10/01 07:34:00,140.2040304694,36.0685867556,2,3,5022220,13,2 ,43,,2",
 "340400,2,2,1998/10/01 07:34:00,139.5375115476,35.5942243483,2,5,2140202,9 ,1 ,41,,1",
 "262200,2,2,1998/10/01 07:34:00,139.1520488497,35.6232145787,1,9,2713004,3 ,1 ,42,,6",
 "276000,2,2,1998/10/01 07:34:00,139.2558959754,35.3684081664,1,2,2821003,12,2 ,33,,1",
 "294400,2,2,1998/10/01 07:34:00,139.5489893843,35.5267476595,1,4,2920001,3 ,1 ,39,,6",
 "370300,2,3,1998/10/01 07:34:00,139.7448458998,35.7423763606,1,10,3037010,10,1 ,39,,12",
 "315100,4,4,1998/10/01 07:34:00,139.6072471912,35.4918363582,1,11,2020106,10,9 ,39,,8",
 "386400,2,4,1998/10/01 07:34:00,139.743426479,35.7493692352,1,10,3059002,8 ,1 ,37,,12",
 "437000,2,3,1998/10/01 07:34:00,139.7883157484,35.7878542441,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,2,1998/10/01 07:34:00,139.7815820541,35.89036666,2,5,3221102,8 ,1 ,37,,2",
 "414000,2,2,1998/10/01 07:34:00,139.4404701588,35.7675344541,1,10,3122302,3 ,11,32,,6",
 "441600,4,4,1998/10/01 07:34:00,139.8957250067,35.7832703682,1,9,3215009,9 ,11,30,,8",
 "476100,2,3,1998/10/01 07:34:00,139.4789053826,35.912085861,1,3,3411213,13,2 ,34,,12",
 "483000,2,3,1998/10/01 07:34:00,139.6382596123,35.8858497807,1,10,3510101,8 ,1 ,27,,12",
 "462300,2,2,1998/10/01 07:34:00,139.7778281517,35.9316955618,1,8,3235004,9 ,1 ,38,,6",
 "508300,2,3,1998/10/01 07:34:00,139.6788613837,36.1065793587,1,9,3622214,8 ,1 ,36,,12",
 "487600,2,2,1998/10/01 07:34:00,139.6419077089,35.9851520507,1,10,3512006,6 ,1 ,36,,6",
 "515200,2,2,1998/10/01 07:34:00,139.1997761446,36.1405256297,1,1,3711123,12,2 ,37,,1",
 "519800,2,2,1998/10/01 07:34:00,139.3485755598,36.1500490438,1,10,3721001,9 ,1 ,44,,6",
 "609500,2,2,1998/10/01 07:34:00,139.6586760386,35.6704481921,1,7,0430102,8 ,1 ,51,,1",
 "630200,2,4,1998/10/01 07:34:00,139.5768880435,35.8228328077,1,7,0513010,8 ,1 ,41,,10",
 "639400,2,2,1998/10/01 07:34:00,139.6783282324,35.7763904893,1,10,0520112,9 ,1 ,38,,6",
 "644000,2,2,1998/10/01 07:34:00,139.6626327893,35.7685302285,1,4,0522008,5 ,1 ,52,,1",
 "644000,2,3,1998/10/01 07:34:00,139.6626327893,35.7685302285,1,4,0522008,5 ,1 ,52,,12",
 "747500,2,3,1998/10/01 07:34:00,139.5528493548,35.5102946702,1,5,0810007,4 ,1 ,38,,12",
 "811900,2,2,1998/10/01 07:34:00,139.5982685564,35.412362828,1,3,1123201,13,2 ,31,,1",
 "653200,2,3,1998/10/01 07:34:00,139.7706083873,35.6910940005,1,8,0531203,9 ,1 ,39,,12",
 "648600,2,3,1998/10/01 07:34:00,139.7297675599,35.7807852865,2,3,0530007,13,2 ,37,,12",
 "777400,2,3,1998/10/01 07:34:00,139.563862383,35.6527204908,1,5,0844010,8 ,1 ,41,,12",
 "676200,2,3,1998/10/01 07:34:00,139.7527391799,35.6765043612,1,13,0623211,10,1 ,35,,12",
 "759000,2,3,1998/10/01 07:34:00,139.4629039446,35.6214001225,2,4,0821006,9 ,1 ,55,,12",
 "683100,2,3,1998/10/01 07:34:00,139.8636345105,35.6837880997,2,3,0633106,12,2 ,49,,12",
 "692300,2,2,1998/10/01 07:34:00,139.544441158,35.6529445919,2,4,0711108,8 ,1 ,25,,1",
 "830300,2,2,1998/10/01 07:35:00,139.5343181433,35.4566283144,2,4,1251203,8 ,1 ,37,,1",
 "839500,2,3,1998/10/01 07:35:00,139.6023671378,35.4342967396,2,11,1133001,14,7 ,27,,12",
 "96600,2,2,1998/10/01 07:35:00,140.0871270861,35.5177014947,1,3,4510101,13,2 ,33,,2",
 "841800,2,3,1998/10/01 07:35:00,139.6129809976,35.3908080074,1,2,1132110,12,2 ,33,,12",
 "864800,2,3,1998/10/01 07:35:00,139.6821414963,35.5354499147,1,6,1240102,10,1 ,27,,12",
 "860200,2,3,1998/10/01 07:35:00,139.5663410512,35.5107796558,1,9,1340109,10,1 ,33,,12",
 "869400,2,3,1998/10/01 07:35:00,139.5567242158,35.4280618619,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,2,1998/10/01 07:35:00,139.5713473975,35.3693772897,1,6,1230404,9 ,1 ,31,,1",
 "2300,2,2,1998/10/01 07:35:00,139.9190488883,35.7232925181,1,6,4111102,8 ,1 ,36,,2",
 "18400,2,3,1998/10/01 07:35:00,139.8459101208,35.7088614772,1,9,4120311,8 ,1 ,44,,12",
 "13800,2,3,1998/10/01 07:35:00,139.9371242275,35.699481059,2,14,4116004,5 ,5 ,25,,12",
 "32200,2,3,1998/10/01 07:35:00,139.9179881812,35.6857961196,2,4,4128103,8 ,1 ,42,,12",
 "71300,2,3,1998/10/01 07:35:00,139.7892777964,35.7904601959,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:35:00,140.0451157946,35.7225283177,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,3,1998/10/01 07:35:00,140.0955314452,35.6433544963,1,9,4031206,8 ,1 ,38,,10",
 "172500,2,3,1998/10/01 07:35:00,140.146885769,35.9890539874,2,4,5024120,13,2 ,44,,12",
 "195500,2,3,1998/10/01 07:35:00,139.7858014399,35.7065243493,2,4,5420020,8 ,1 ,37,,12",
 "227700,2,3,1998/10/01 07:35:00,139.5343968789,35.3811532151,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:35:00,139.6863812647,35.5224738511,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:35:00,139.5897918213,35.5114207262,1,11,2922005,9 ,1 ,29,,12",
 "303600,2,3,1998/10/01 07:35:00,139.4696970132,35.5713438689,1,9,2923016,9 ,1 ,30,,12",
 "108100,2,2,1998/10/01 07:35:00,140.0234272686,35.4140784488,2,4,4615025,8 ,1 ,43,,6",
 "110400,2,2,1998/10/01 07:35:00,139.9549124175,35.2256037424,1,12,4621121,1 ,11,41,,8",
 "170200,2,2,1998/10/01 07:35:00,140.203009612,36.0692553877,2,3,5022220,13,2 ,43,,2",
 "340400,2,2,1998/10/01 07:35:00,139.539049256,35.5951831353,2,5,2140202,9 ,1 ,41,,1",
 "340400,3,3,1998/10/01 07:35:00,139.539049256,35.5951831353,2,5,2140202,9 ,97,41,,97",
 "262200,2,2,1998/10/01 07:35:00,139.154863937,35.6210614163,1,9,2713004,3 ,1 ,42,,6",
 "276000,2,2,1998/10/01 07:35:00,139.2560870883,35.3679178541,1,2,2821003,12,2 ,33,,1",
 "294400,3,3,1998/10/01 07:35:00,139.5483347946,35.5289051524,1,4,2920001,3 ,97,39,,97",
 "294400,2,2,1998/10/01 07:35:00,139.5483347946,35.5289051524,1,4,2920001,3 ,1 ,39,,6",
 "370300,2,3,1998/10/01 07:35:00,139.7464145501,35.7389002004,1,10,3037010,10,1 ,39,,12",
 "315100,4,4,1998/10/01 07:35:00,139.6072621926,35.4926878551,1,11,2020106,10,9 ,39,,8",
 "386400,2,4,1998/10/01 07:35:00,139.7485841267,35.7489030682,1,10,3059002,8 ,1 ,37,,12",
 "437000,2,3,1998/10/01 07:35:00,139.786631789,35.7832758551,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,2,1998/10/01 07:35:00,139.7822575572,35.8902165607,2,5,3221102,8 ,1 ,37,,2",
 "414000,2,2,1998/10/01 07:35:00,139.4421833946,35.7666639882,1,10,3122302,3 ,11,32,,6",
 "441600,4,4,1998/10/01 07:35:00,139.8960625977,35.7814312969,1,9,3215009,9 ,11,30,,8",
 "476100,2,3,1998/10/01 07:35:00,139.4834524365,35.9069413969,1,3,3411213,13,2 ,34,,12",
 "483000,2,3,1998/10/01 07:35:00,139.639819796,35.8824801678,1,10,3510101,8 ,1 ,27,,12",
 "462300,2,2,1998/10/01 07:35:00,139.7761666166,35.9292782142,1,8,3235004,9 ,1 ,38,,6",
 "487600,2,2,1998/10/01 07:35:00,139.6417620356,35.9831896222,1,10,3512006,6 ,1 ,36,,6",
 "508300,2,3,1998/10/01 07:35:00,139.6787310309,36.0984006164,1,9,3622214,8 ,1 ,36,,12",
 "519800,2,2,1998/10/01 07:35:00,139.3432789849,36.1489289944,1,10,3721001,9 ,1 ,44,,6",
 "515200,2,2,1998/10/01 07:35:00,139.1999318018,36.1391100044,1,1,3711123,12,2 ,37,,1",
 "609500,2,2,1998/10/01 07:35:00,139.6592482418,35.6710414487,1,7,0430102,8 ,1 ,51,,1",
 "609500,2,3,1998/10/01 07:35:00,139.6592482418,35.6710414487,1,7,0430102,8 ,1 ,51,,12",
 "630200,2,4,1998/10/01 07:35:00,139.5769347713,35.8221616694,1,7,0513010,8 ,1 ,41,,10",
 "639400,2,2,1998/10/01 07:35:00,139.675532445,35.7775748238,1,10,0520112,9 ,1 ,38,,6",
 "644000,2,3,1998/10/01 07:35:00,139.6668852507,35.7676172535,1,4,0522008,5 ,1 ,52,,12",
 "648600,2,3,1998/10/01 07:35:00,139.7280575307,35.7821615303,2,3,0530007,13,2 ,37,,12",
 "747500,2,3,1998/10/01 07:35:00,139.5595190012,35.5096971314,1,5,0810007,4 ,1 ,38,,12",
 "811900,2,2,1998/10/01 07:35:00,139.5979848569,35.412099139,1,3,1123201,13,2 ,31,,1",
 "653200,2,3,1998/10/01 07:35:00,139.7692037076,35.6877660715,1,8,0531203,9 ,1 ,39,,12",
 "786600,2,2,1998/10/01 07:35:00,139.2963671351,35.7762733856,2,3,0930110,13,2 ,35,,2",
 "777400,2,3,1998/10/01 07:35:00,139.5676721118,35.6544871188,1,5,0844010,8 ,1 ,41,,12",
 "676200,2,3,1998/10/01 07:35:00,139.7509744373,35.6736377975,1,13,0623211,10,1 ,35,,12",
 "759000,2,3,1998/10/01 07:35:00,139.4679317387,35.6197009192,2,4,0821006,9 ,1 ,55,,12",
 "683100,2,3,1998/10/01 07:35:00,139.8602931941,35.6843786398,2,3,0633106,12,2 ,49,,12",
 "692300,2,2,1998/10/01 07:35:00,139.5445086302,35.6519575105,2,4,0711108,8 ,1 ,25,,1",
 "692300,2,3,1998/10/01 07:35:00,139.5445086302,35.6519575105,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,2,1998/10/01 07:36:00,139.5340061773,35.4575887523,2,4,1251203,8 ,1 ,37,,1",
 "839500,2,3,1998/10/01 07:36:00,139.6076967213,35.4358392718,2,11,1133001,14,7 ,27,,12",
 "96600,2,2,1998/10/01 07:36:00,140.0871275002,35.5177023136,1,3,4510101,13,2 ,33,,2",
 "841800,2,3,1998/10/01 07:36:00,139.6092448701,35.3952532144,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:36:00,139.5708593182,35.5115196093,1,9,1340109,10,1 ,33,,12",
 "864800,2,3,1998/10/01 07:36:00,139.6799279274,35.5404805398,1,6,1240102,10,1 ,27,,12",
 "869400,2,3,1998/10/01 07:36:00,139.5571127301,35.4327257947,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,2,1998/10/01 07:36:00,139.5721165167,35.3707268681,1,6,1230404,9 ,1 ,31,,1",
 "2300,2,2,1998/10/01 07:36:00,139.9196795786,35.7232516291,1,6,4111102,8 ,1 ,36,,2",
 "13800,2,3,1998/10/01 07:36:00,139.9414697428,35.7029100846,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:36:00,139.8417396916,35.706040511,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:36:00,139.9139991213,35.6824680919,2,4,4128103,8 ,1 ,42,,12",
 "71300,2,3,1998/10/01 07:36:00,139.7879411897,35.7868359326,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:36:00,140.0403749123,35.7210107472,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,3,1998/10/01 07:36:00,140.0953584083,35.642890042,1,9,4031206,8 ,1 ,38,,10",
 "172500,2,3,1998/10/01 07:36:00,140.144070287,35.9846176233,2,4,5024120,13,2 ,44,,12",
 "195500,2,3,1998/10/01 07:36:00,139.7874758697,35.7064345697,2,4,5420020,8 ,1 ,37,,12",
 "227700,2,3,1998/10/01 07:36:00,139.5351273721,35.3875883762,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:36:00,139.6917605074,35.5269976075,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:36:00,139.5952847258,35.5105124221,1,11,2922005,9 ,1 ,29,,12",
 "303600,2,3,1998/10/01 07:36:00,139.472433248,35.5747937592,1,9,2923016,9 ,1 ,30,,12",
 "310500,2,2,1998/10/01 07:36:00,139.7057860393,35.5215745023,1,9,2013102,3 ,1 ,37,,2",
 "110400,2,2,1998/10/01 07:36:00,139.950957775,35.2228040631,1,12,4621121,1 ,11,41,,8",
 "108100,2,2,1998/10/01 07:36:00,140.0273987284,35.4178785432,2,4,4615025,8 ,1 ,43,,6",
 "170200,2,2,1998/10/01 07:36:00,140.2021499427,36.0698458747,2,3,5022220,13,2 ,43,,2",
 "262200,2,2,1998/10/01 07:36:00,139.1579838909,35.6201377279,1,9,2713004,3 ,1 ,42,,6",
 "276000,2,2,1998/10/01 07:36:00,139.256119044,35.367388506,1,2,2821003,12,2 ,33,,1",
 "370300,2,3,1998/10/01 07:36:00,139.7473265544,35.7351403165,1,10,3037010,10,1 ,39,,12",
 "315100,4,4,1998/10/01 07:36:00,139.6073039939,35.4935386042,1,11,2020106,10,9 ,39,,8",
 "386400,2,4,1998/10/01 07:36:00,139.7532851747,35.7470867674,1,10,3059002,8 ,1 ,37,,12",
 "404800,2,2,1998/10/01 07:36:00,139.4708100625,35.7862669528,2,9,3120009,8 ,1 ,30,,1",
 "437000,2,3,1998/10/01 07:36:00,139.7886470197,35.7789977623,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,2,1998/10/01 07:36:00,139.7829321824,35.8900638737,2,5,3221102,8 ,1 ,37,,2",
 "414000,2,2,1998/10/01 07:36:00,139.4422542674,35.7647950374,1,10,3122302,3 ,11,32,,6",
 "476100,2,3,1998/10/01 07:36:00,139.4879200668,35.9017516733,1,3,3411213,13,2 ,34,,12",
 "441600,4,4,1998/10/01 07:36:00,139.8957580912,35.7795720158,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 07:36:00,139.64123209,35.8790661132,1,10,3510101,8 ,1 ,27,,12",
 "462300,2,2,1998/10/01 07:36:00,139.7747561339,35.9266288634,1,8,3235004,9 ,1 ,38,,6",
 "487600,2,2,1998/10/01 07:36:00,139.6405403833,35.9814837267,1,10,3512006,6 ,1 ,36,,6",
 "508300,2,3,1998/10/01 07:36:00,139.679354471,36.0902112813,1,9,3622214,8 ,1 ,36,,12",
 "515200,2,2,1998/10/01 07:36:00,139.2000587406,36.1376940074,1,1,3711123,12,2 ,37,,1",
 "519800,2,2,1998/10/01 07:36:00,139.3380041532,36.1477280813,1,10,3721001,9 ,1 ,44,,6",
 "609500,2,3,1998/10/01 07:36:00,139.6629604817,35.6723252063,1,7,0430102,8 ,1 ,51,,12",
 "639400,2,2,1998/10/01 07:36:00,139.6736101717,35.7794900773,1,10,0520112,9 ,1 ,38,,6",
 "630200,2,4,1998/10/01 07:36:00,139.5767675486,35.8213605064,1,7,0513010,8 ,1 ,41,,10",
 "644000,2,3,1998/10/01 07:36:00,139.670951752,35.7659472102,1,4,0522008,5 ,1 ,52,,12",
 "747500,2,3,1998/10/01 07:36:00,139.5661949985,35.5107552881,1,5,0810007,4 ,1 ,38,,12",
 "811900,2,2,1998/10/01 07:36:00,139.5977011593,35.4118354493,1,3,1123201,13,2 ,31,,1",
 "653200,2,3,1998/10/01 07:36:00,139.7679384406,35.6844012635,1,8,0531203,9 ,1 ,39,,12",
 "648600,2,3,1998/10/01 07:36:00,139.7257123057,35.7826520706,2,3,0530007,13,2 ,37,,12",
 "777400,2,3,1998/10/01 07:36:00,139.5714631426,35.6562805158,1,5,0844010,8 ,1 ,41,,12",
 "786600,2,2,1998/10/01 07:36:00,139.2972379825,35.7761527306,2,3,0930110,13,2 ,35,,2",
 "676200,2,3,1998/10/01 07:36:00,139.7494786917,35.6706698315,1,13,0623211,10,1 ,35,,12",
 "759000,2,3,1998/10/01 07:36:00,139.4733980989,35.6193053326,2,4,0821006,9 ,1 ,55,,12",
 "683100,2,3,1998/10/01 07:36:00,139.8572073486,35.6856870511,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 07:36:00,139.5490125304,35.6505053943,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,2,1998/10/01 07:37:00,139.5336921821,35.4585479594,2,4,1251203,8 ,1 ,37,,1",
 "839500,2,3,1998/10/01 07:37:00,139.6132215199,35.4368449019,2,11,1133001,14,7 ,27,,12",
 "96600,2,2,1998/10/01 07:37:00,140.0871279144,35.5177031325,1,3,4510101,13,2 ,33,,2",
 "841800,2,3,1998/10/01 07:37:00,139.6032677693,35.3974402671,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:37:00,139.5753783257,35.5122567515,1,9,1340109,10,1 ,33,,12",
 "864800,2,3,1998/10/01 07:37:00,139.6776878289,35.545504173,1,6,1240102,10,1 ,27,,12",
 "869400,2,3,1998/10/01 07:37:00,139.5599603433,35.4367414424,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,2,1998/10/01 07:37:00,139.5731205262,35.3720312111,1,6,1230404,9 ,1 ,31,,1",
 "2300,2,2,1998/10/01 07:37:00,139.9203102682,35.7232107368,1,6,4111102,8 ,1 ,36,,2",
 "13800,2,3,1998/10/01 07:37:00,139.9454577305,35.7066437685,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:37:00,139.8385112498,35.7025649281,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:37:00,139.9100043788,35.6791447339,2,4,4128103,8 ,1 ,42,,12",
 "71300,2,3,1998/10/01 07:37:00,139.7866134505,35.7832097814,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:37:00,140.0356394926,35.7194823074,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,3,1998/10/01 07:37:00,140.0950242733,35.6426237461,1,9,4031206,8 ,1 ,38,,10",
 "172500,2,3,1998/10/01 07:37:00,140.1416065405,35.9800570817,2,4,5024120,13,2 ,44,,12",
 "195500,2,3,1998/10/01 07:37:00,139.7938069951,35.7043927233,2,4,5420020,8 ,1 ,37,,12",
 "227700,2,3,1998/10/01 07:37:00,139.5357226227,35.394035765,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:37:00,139.6970454275,35.5315950339,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:37:00,139.6002430167,35.5083474482,1,11,2922005,9 ,1 ,29,,12",
 "303600,2,3,1998/10/01 07:37:00,139.4747347809,35.5784513128,1,9,2923016,9 ,1 ,30,,12",
 "310500,2,2,1998/10/01 07:37:00,139.7041947818,35.5221259039,1,9,2013102,3 ,1 ,37,,2",
 "110400,2,2,1998/10/01 07:37:00,139.9475752887,35.2196065234,1,12,4621121,1 ,11,41,,8",
 "108100,2,2,1998/10/01 07:37:00,140.028215336,35.4232845031,2,4,4615025,8 ,1 ,43,,6",
 "170200,2,2,1998/10/01 07:37:00,140.2031789806,36.0692057069,2,3,5022220,13,2 ,43,,2",
 "262200,2,2,1998/10/01 07:37:00,139.1618884467,35.6195096725,1,9,2713004,3 ,1 ,42,,6",
 "276000,2,2,1998/10/01 07:37:00,139.2564426725,35.3671527458,1,2,2821003,12,2 ,33,,1",
 "370300,2,3,1998/10/01 07:37:00,139.7498848016,35.7317942528,1,10,3037010,10,1 ,39,,12",
 "315100,4,4,1998/10/01 07:37:00,139.6073513966,35.494389126,1,11,2020106,10,9 ,39,,8",
 "386400,2,4,1998/10/01 07:37:00,139.7576457788,35.7447874085,1,10,3059002,8 ,1 ,37,,12",
 "404800,2,2,1998/10/01 07:37:00,139.4710417953,35.7863461282,2,9,3120009,8 ,1 ,30,,1",
 "437000,2,3,1998/10/01 07:37:00,139.7925556656,35.7754493508,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,2,1998/10/01 07:37:00,139.7836070713,35.889912016,2,5,3221102,8 ,1 ,37,,2",
 "414000,2,2,1998/10/01 07:37:00,139.4433562228,35.7633877558,1,10,3122302,3 ,11,32,,6",
 "476100,2,3,1998/10/01 07:37:00,139.4923926396,35.8965647449,1,3,3411213,13,2 ,34,,12",
 "441600,4,4,1998/10/01 07:37:00,139.8946492332,35.7779096747,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 07:37:00,139.6426797832,35.8756626475,1,10,3510101,8 ,1 ,27,,12",
 "462300,2,2,1998/10/01 07:37:00,139.7746700128,35.923407939,1,8,3235004,9 ,1 ,38,,6",
 "487600,2,2,1998/10/01 07:37:00,139.6391792049,35.9799061321,1,10,3512006,6 ,1 ,36,,6",
 "508300,2,3,1998/10/01 07:37:00,139.6798321975,36.0820161585,1,9,3622214,8 ,1 ,36,,12",
 "515200,2,2,1998/10/01 07:37:00,139.200622684,36.1363770967,1,1,3711123,12,2 ,37,,1",
 "519800,2,2,1998/10/01 07:37:00,139.332695094,36.1468765303,1,10,3721001,9 ,1 ,44,,6",
 "609500,2,3,1998/10/01 07:37:00,139.6667131781,35.6735446127,1,7,0430102,8 ,1 ,51,,12",
 "639400,2,2,1998/10/01 07:37:00,139.6717813085,35.7811321517,1,10,0520112,9 ,1 ,38,,6",
 "630200,2,4,1998/10/01 07:37:00,139.5766065879,35.8205584948,1,7,0513010,8 ,1 ,41,,10",
 "644000,2,3,1998/10/01 07:37:00,139.6749547153,35.7641754977,1,4,0522008,5 ,1 ,52,,12",
 "747500,2,3,1998/10/01 07:37:00,139.572863771,35.5118451888,1,5,0810007,4 ,1 ,38,,12",
 "811900,2,2,1998/10/01 07:37:00,139.5974174635,35.4115717589,1,3,1123201,13,2 ,31,,1",
 "648600,2,3,1998/10/01 07:37:00,139.7233485122,35.7830882723,2,3,0530007,13,2 ,37,,12",
 "653200,2,3,1998/10/01 07:37:00,139.7656343588,35.6821268942,1,8,0531203,9 ,1 ,39,,12",
 "786600,2,2,1998/10/01 07:37:00,139.2980928802,35.7759376245,2,3,0930110,13,2 ,35,,2",
 "777400,2,3,1998/10/01 07:37:00,139.5752694454,35.6580520627,1,5,0844010,8 ,1 ,41,,12",
 "676200,2,3,1998/10/01 07:37:00,139.7479855165,35.6677030623,1,13,0623211,10,1 ,35,,12",
 "759000,2,3,1998/10/01 07:37:00,139.4788620691,35.6193576391,2,4,0821006,9 ,1 ,55,,12",
 "683100,2,3,1998/10/01 07:37:00,139.8541267039,35.6870036005,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 07:37:00,139.5538232716,35.6497072665,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,2,1998/10/01 07:38:00,139.5334764542,35.4595247178,2,4,1251203,8 ,1 ,37,,1",
 "839500,2,3,1998/10/01 07:38:00,139.618531481,35.4383673825,2,11,1133001,14,7 ,27,,12",
 "96600,2,2,1998/10/01 07:38:00,140.0871283285,35.5177039514,1,3,4510101,13,2 ,33,,2",
 "841800,2,3,1998/10/01 07:38:00,139.5990812154,35.4016356319,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:38:00,139.5799004932,35.5129800326,1,9,1340109,10,1 ,33,,12",
 "864800,2,3,1998/10/01 07:38:00,139.675391105,35.5505109786,1,6,1240102,10,1 ,27,,12",
 "869400,2,3,1998/10/01 07:38:00,139.5639309415,35.4400355237,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,2,1998/10/01 07:38:00,139.5733524383,35.3734901406,1,6,1230404,9 ,1 ,31,,1",
 "2300,2,2,1998/10/01 07:38:00,139.9209409571,35.7231698412,1,6,4111102,8 ,1 ,36,,2",
 "13800,2,3,1998/10/01 07:38:00,139.9499535784,35.7098635062,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:38:00,139.8356599295,35.6988536526,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:38:00,139.9060099675,35.6758212412,2,4,4128103,8 ,1 ,42,,12",
 "71300,2,3,1998/10/01 07:38:00,139.7879607334,35.7797372283,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:38:00,140.0310602488,35.7176677447,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,3,1998/10/01 07:38:00,140.094473093,35.6425458961,1,9,4031206,8 ,1 ,38,,10",
 "172500,2,3,1998/10/01 07:38:00,140.1409724819,35.9750951213,2,4,5024120,13,2 ,44,,12",
 "195500,2,3,1998/10/01 07:38:00,139.7972257836,35.700354771,2,4,5420020,8 ,1 ,37,,12",
 "227700,2,3,1998/10/01 07:38:00,139.5343158825,35.4003529379,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:38:00,139.7025427644,35.5360143881,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:38:00,139.6052056506,35.5062130989,1,11,2922005,9 ,1 ,29,,12",
 "303600,2,3,1998/10/01 07:38:00,139.4775841676,35.5817779549,1,9,2923016,9 ,1 ,30,,12",
 "310500,2,2,1998/10/01 07:38:00,139.7026210287,35.5227096491,1,9,2013102,3 ,1 ,37,,2",
 "110400,2,2,1998/10/01 07:38:00,139.9446127534,35.222331644,1,12,4621121,1 ,11,41,,8",
 "108100,2,2,1998/10/01 07:38:00,140.0284192639,35.4287084345,2,4,4615025,8 ,1 ,43,,6",
 "170200,2,2,1998/10/01 07:38:00,140.2020598941,36.069663221,2,3,5022220,13,2 ,43,,2",
 "262200,2,2,1998/10/01 07:38:00,139.1642949016,35.6173555071,1,9,2713004,3 ,1 ,42,,6",
 "276000,2,2,1998/10/01 07:38:00,139.2570868246,35.3672153665,1,2,2821003,12,2 ,33,,1",
 "370300,2,3,1998/10/01 07:38:00,139.7521973318,35.7283519513,1,10,3037010,10,1 ,39,,12",
 "315100,4,4,1998/10/01 07:38:00,139.6073757113,35.4952402669,1,11,2020106,10,9 ,39,,8",
 "386400,2,4,1998/10/01 07:38:00,139.761825821,35.7422595524,1,10,3059002,8 ,1 ,37,,12",
 "404800,2,2,1998/10/01 07:38:00,139.4712735286,35.786425303,2,9,3120009,8 ,1 ,30,,1",
 "437000,2,3,1998/10/01 07:38:00,139.797403498,35.7727594075,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,2,1998/10/01 07:38:00,139.7842644505,35.8897198698,2,5,3221102,8 ,1 ,37,,2",
 "414000,2,2,1998/10/01 07:38:00,139.4439082112,35.7615752504,1,10,3122302,3 ,11,32,,6",
 "441600,4,4,1998/10/01 07:38:00,139.8929600179,35.7765786329,1,9,3215009,9 ,11,30,,8",
 "476100,2,3,1998/10/01 07:38:00,139.4969106829,35.891403901,1,3,3411213,13,2 ,34,,12",
 "483000,2,3,1998/10/01 07:38:00,139.6451743552,35.8727210199,1,10,3510101,8 ,1 ,27,,12",
 "462300,2,2,1998/10/01 07:38:00,139.7748826455,35.920247058,1,8,3235004,9 ,1 ,38,,6",
 "487600,2,2,1998/10/01 07:38:00,139.6383718146,35.978389637,1,10,3512006,6 ,1 ,36,,6",
 "508300,2,3,1998/10/01 07:38:00,139.6801611482,36.0738155145,1,9,3622214,8 ,1 ,36,,12",
 "515200,2,2,1998/10/01 07:38:00,139.2012063585,36.1350515203,1,1,3711123,12,2 ,37,,1",
 "519800,2,2,1998/10/01 07:38:00,139.3272970025,36.1467309889,1,10,3721001,9 ,1 ,44,,6",
 "609500,2,3,1998/10/01 07:38:00,139.670368635,35.6747484105,1,7,0430102,8 ,1 ,51,,12",
 "630200,2,4,1998/10/01 07:38:00,139.5764455078,35.8197565025,1,7,0513010,8 ,1 ,41,,10",
 "639400,2,2,1998/10/01 07:38:00,139.6686355035,35.7814182644,1,10,0520112,9 ,1 ,38,,6",
 "644000,2,3,1998/10/01 07:38:00,139.6789683436,35.7624213725,1,4,0522008,5 ,1 ,52,,12",
 "747500,2,3,1998/10/01 07:38:00,139.5795336268,35.5129309444,1,5,0810007,4 ,1 ,38,,12",
 "811900,2,2,1998/10/01 07:38:00,139.5971337696,35.4113080679,1,3,1123201,13,2 ,31,,1",
 "653200,2,3,1998/10/01 07:38:00,139.7636984636,35.6790322921,1,8,0531203,9 ,1 ,39,,12",
 "648600,2,3,1998/10/01 07:38:00,139.7210995506,35.7834464594,2,3,0530007,13,2 ,37,,12",
 "648600,2,4,1998/10/01 07:38:00,139.7210995506,35.7834464594,2,3,0530007,13,2 ,37,,1",
 "786600,2,2,1998/10/01 07:38:00,139.2988936963,35.7756360742,2,3,0930110,13,2 ,35,,2",
 "777400,2,3,1998/10/01 07:38:00,139.5790633896,35.6598412494,1,5,0844010,8 ,1 ,41,,12",
 "676200,2,3,1998/10/01 07:38:00,139.746457995,35.6647565302,1,13,0623211,10,1 ,35,,12",
 "759000,2,3,1998/10/01 07:38:00,139.483517429,35.6216178049,2,4,0821006,9 ,1 ,55,,12",
 "683100,2,3,1998/10/01 07:38:00,139.8510594407,35.6883405244,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 07:38:00,139.5585903208,35.6502337939,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,2,1998/10/01 07:39:00,139.5331242584,35.4604758826,2,4,1251203,8 ,1 ,37,,1",
 "839500,2,3,1998/10/01 07:39:00,139.6237384561,35.4401820533,2,11,1133001,14,7 ,27,,12",
 "96600,2,2,1998/10/01 07:39:00,140.0871287426,35.5177047703,1,3,4510101,13,2 ,33,,2",
 "841800,2,3,1998/10/01 07:39:00,139.5967312784,35.4066838859,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:39:00,139.5844077167,35.5123708094,1,9,1340109,10,1 ,33,,12",
 "864800,2,3,1998/10/01 07:39:00,139.6731901068,35.5555454593,1,6,1240102,10,1 ,27,,12",
 "869400,2,3,1998/10/01 07:39:00,139.5688144581,35.4424840249,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,2,1998/10/01 07:39:00,139.5740795859,35.3744567697,1,6,1230404,9 ,1 ,31,,1",
 "2300,2,2,1998/10/01 07:39:00,139.9215716454,35.7231289422,1,6,4111102,8 ,1 ,36,,2",
 "13800,2,3,1998/10/01 07:39:00,139.9556926848,35.708923238,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:39:00,139.8305360549,35.6976007302,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:39:00,139.9020158525,35.6724976416,2,4,4128103,8 ,1 ,42,,12",
 "71300,2,3,1998/10/01 07:39:00,139.7909035239,35.7768113158,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:39:00,140.0265227782,35.7157819108,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,3,1998/10/01 07:39:00,140.0942813565,35.6420995008,1,9,4031206,8 ,1 ,38,,10",
 "172500,2,3,1998/10/01 07:39:00,140.1405257379,35.9701166931,2,4,5024120,13,2 ,44,,12",
 "195500,2,3,1998/10/01 07:39:00,139.7976841553,35.694548706,2,4,5420020,8 ,1 ,37,,12",
 "227700,2,3,1998/10/01 07:39:00,139.5341537266,35.406755487,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:39:00,139.7068885717,35.5412281132,1,14,2412116,10,1 ,63,,12",
 "303600,2,3,1998/10/01 07:39:00,139.4822851029,35.5831693898,1,9,2923016,9 ,1 ,30,,12",
 "301300,2,3,1998/10/01 07:39:00,139.6107586217,35.5060063306,1,11,2922005,9 ,1 ,29,,12",
 "310500,2,2,1998/10/01 07:39:00,139.7010456821,35.5231786948,1,9,2013102,3 ,1 ,37,,2",
 "110400,2,2,1998/10/01 07:39:00,139.9425119307,35.2263676659,1,12,4621121,1 ,11,41,,8",
 "108100,2,2,1998/10/01 07:39:00,140.0274712614,35.4331121384,2,4,4615025,8 ,1 ,43,,6",
 "170200,2,2,1998/10/01 07:39:00,140.200873396,36.070110144,2,3,5022220,13,2 ,43,,2",
 "262200,2,2,1998/10/01 07:39:00,139.1656622174,35.6148882002,1,9,2713004,3 ,1 ,42,,6",
 "276000,2,2,1998/10/01 07:39:00,139.2577312343,35.3672639873,1,2,2821003,12,2 ,33,,1",
 "370300,2,3,1998/10/01 07:39:00,139.7538023343,35.7246904246,1,10,3037010,10,1 ,39,,12",
 "315100,4,4,1998/10/01 07:39:00,139.6074093554,35.4960914379,1,11,2020106,10,9 ,39,,8",
 "386400,2,4,1998/10/01 07:39:00,139.7646092709,35.7387366833,1,10,3059002,8 ,1 ,37,,12",
 "404800,2,2,1998/10/01 07:39:00,139.4715052623,35.7865044774,2,9,3120009,8 ,1 ,30,,1",
 "437000,2,3,1998/10/01 07:39:00,139.8022647142,35.7700856302,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,2,1998/10/01 07:39:00,139.7849114986,35.8895020536,2,5,3221102,8 ,1 ,37,,2",
 "414000,2,2,1998/10/01 07:39:00,139.4444008422,35.7597680166,1,10,3122302,3 ,11,32,,6",
 "441600,4,4,1998/10/01 07:39:00,139.8910087281,35.775491451,1,9,3215009,9 ,11,30,,8",
 "476100,2,3,1998/10/01 07:39:00,139.5013500673,35.8861981968,1,3,3411213,13,2 ,34,,12",
 "483000,2,3,1998/10/01 07:39:00,139.6481478002,35.8700527668,1,10,3510101,8 ,1 ,27,,12",
 "462300,2,2,1998/10/01 07:39:00,139.7734842744,35.9173330669,1,8,3235004,9 ,1 ,38,,6",
 "487600,2,2,1998/10/01 07:39:00,139.6380562905,35.9764312947,1,10,3512006,6 ,1 ,36,,6",
 "508300,2,3,1998/10/01 07:39:00,139.6774792788,36.0660191422,1,9,3622214,8 ,1 ,36,,12",
 "515200,2,2,1998/10/01 07:39:00,139.2013673052,36.1336482243,1,1,3711123,12,2 ,37,,1",
 "519800,2,2,1998/10/01 07:39:00,139.3230327265,36.1457540976,1,10,3721001,9 ,1 ,44,,6",
 "609500,2,3,1998/10/01 07:39:00,139.6740903823,35.6760299923,1,7,0430102,8 ,1 ,51,,12",
 "639400,2,2,1998/10/01 07:39:00,139.6662334884,35.7823721172,1,10,0520112,9 ,1 ,38,,6",
 "630200,2,4,1998/10/01 07:39:00,139.5762691009,35.818956673,1,7,0513010,8 ,1 ,41,,10",
 "644000,2,3,1998/10/01 07:39:00,139.6830094109,35.7607083532,1,4,0522008,5 ,1 ,52,,12",
 "747500,2,3,1998/10/01 07:39:00,139.5861738759,35.512008312,1,5,0810007,4 ,1 ,38,,12",
 "811900,2,2,1998/10/01 07:39:00,139.5968500775,35.4110443762,1,3,1123201,13,2 ,31,,1",
 "653200,2,3,1998/10/01 07:39:00,139.7625586688,35.6756376146,1,8,0531203,9 ,1 ,39,,12",
 "648600,2,4,1998/10/01 07:39:00,139.7204782869,35.7835384928,2,3,0530007,13,2 ,37,,1",
 "786600,2,2,1998/10/01 07:39:00,139.29959761,35.7753247617,2,3,0930110,13,2 ,35,,2",
 "777400,2,3,1998/10/01 07:39:00,139.5829233004,35.6615284273,1,5,0844010,8 ,1 ,41,,12",
 "676200,2,3,1998/10/01 07:39:00,139.7442586181,35.6620981305,1,13,0623211,10,1 ,35,,12",
 "759000,2,3,1998/10/01 07:39:00,139.4869081213,35.6251513736,2,4,0821006,9 ,1 ,55,,12",
 "683100,2,3,1998/10/01 07:39:00,139.8480001977,35.6896895169,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 07:39:00,139.5628661248,35.6522557494,2,4,0711108,8 ,1 ,25,,12",
 "747500,2,3,1998/10/01 07:40:00,139.592859028,35.511001808,1,5,0810007,4 ,1 ,38,,12",
 "786600,2,2,1998/10/01 07:40:00,139.3002589544,35.7758148417,2,3,0930110,13,2 ,35,,2",
 "830300,2,2,1998/10/01 07:40:00,139.5327720543,35.4614270462,2,4,1251203,8 ,1 ,37,,1",
 "96600,2,2,1998/10/01 07:40:00,140.0871291568,35.5177055892,1,3,4510101,13,2 ,33,,2",
 "96600,2,3,1998/10/01 07:40:00,140.0871291568,35.5177055892,1,3,4510101,13,2 ,33,,10",
 "839500,2,3,1998/10/01 07:40:00,139.626864335,35.4439423904,2,11,1133001,14,7 ,27,,12",
 "841800,2,3,1998/10/01 07:40:00,139.5965412881,35.4121313456,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:40:00,139.588900587,35.5115434815,1,9,1340109,10,1 ,33,,12",
 "864800,2,3,1998/10/01 07:40:00,139.6710420182,35.5605959977,1,6,1240102,10,1 ,27,,12",
 "871700,2,2,1998/10/01 07:40:00,139.5757926473,35.3749402346,1,6,1230404,9 ,1 ,31,,1",
 "871700,2,3,1998/10/01 07:40:00,139.5757926473,35.3749402346,1,6,1230404,9 ,1 ,31,,12",
 "869400,2,3,1998/10/01 07:40:00,139.5736982714,35.4449323288,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,2,1998/10/01 07:40:00,139.9218353467,35.7234243892,1,6,4111102,8 ,1 ,36,,2",
 "13800,2,3,1998/10/01 07:40:00,139.9602179507,35.7084533969,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:40:00,139.8251577172,35.6971016137,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:40:00,139.8980372775,35.6691619488,2,4,4128103,8 ,1 ,42,,12",
 "71300,2,3,1998/10/01 07:40:00,139.7944403547,35.7743972971,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:40:00,140.0220111868,35.7138554324,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,3,1998/10/01 07:40:00,140.094130021,35.6416300246,1,9,4031206,8 ,1 ,38,,10",
 "172500,2,3,1998/10/01 07:40:00,140.1400609826,35.9651393007,2,4,5024120,13,2 ,44,,12",
 "195500,2,3,1998/10/01 07:40:00,139.7976851705,35.6887227964,2,4,5420020,8 ,1 ,37,,12",
 "227700,2,3,1998/10/01 07:40:00,139.5366639398,35.4127331691,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:40:00,139.7095730662,35.5471044251,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:40:00,139.616326823,35.5066391504,1,11,2922005,9 ,1 ,29,,12",
 "303600,2,3,1998/10/01 07:40:00,139.4871569054,35.5842084304,1,9,2923016,9 ,1 ,30,,12",
 "310500,2,2,1998/10/01 07:40:00,139.6993316431,35.5230361794,1,9,2013102,3 ,1 ,37,,2",
 "110400,2,2,1998/10/01 07:40:00,139.9404109004,35.230403649,1,12,4621121,1 ,11,41,,8",
 "108100,2,2,1998/10/01 07:40:00,140.0303671717,35.4379196516,2,4,4615025,8 ,1 ,43,,6",
 "170200,2,2,1998/10/01 07:40:00,140.1997716491,36.070637604,2,3,5022220,13,2 ,43,,2",
 "262200,2,2,1998/10/01 07:40:00,139.1688913118,35.6139236216,1,9,2713004,3 ,1 ,42,,6",
 "276000,2,2,1998/10/01 07:40:00,139.2583100241,35.3674888335,1,2,2821003,12,2 ,33,,1",
 "370300,2,3,1998/10/01 07:40:00,139.7556585892,35.721109206,1,10,3037010,10,1 ,39,,12",
 "315100,4,4,1998/10/01 07:40:00,139.6074301376,35.4969428831,1,11,2020106,10,9 ,39,,8",
 "386400,2,4,1998/10/01 07:40:00,139.7662193032,35.7347030423,1,10,3059002,8 ,1 ,37,,12",
 "404800,2,2,1998/10/01 07:40:00,139.4717369964,35.7865836514,2,9,3120009,8 ,1 ,30,,1",
 "437000,2,3,1998/10/01 07:40:00,139.8071066824,35.7673889443,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,2,1998/10/01 07:40:00,139.7853206664,35.889067076,2,5,3221102,8 ,1 ,37,,2",
 "411700,2,2,1998/10/01 07:40:00,139.4338096084,35.7977495607,2,1,3122110,12,2 ,29,,1",
 "414000,2,2,1998/10/01 07:40:00,139.445132685,35.7580298673,1,10,3122302,3 ,11,32,,6",
 "441600,4,4,1998/10/01 07:40:00,139.8890524368,35.7744241297,1,9,3215009,9 ,11,30,,8",
 "476100,2,3,1998/10/01 07:40:00,139.505797103,35.8809969943,1,3,3411213,13,2 ,34,,12",
 "483000,2,3,1998/10/01 07:40:00,139.6511446872,35.8674022234,1,10,3510101,8 ,1 ,27,,12",
 "462300,2,2,1998/10/01 07:40:00,139.7722853777,35.9143719595,1,8,3235004,9 ,1 ,38,,6",
 "487600,2,2,1998/10/01 07:40:00,139.637697262,35.9745060176,1,10,3512006,6 ,1 ,36,,6",
 "508300,2,3,1998/10/01 07:40:00,139.6749879509,36.0581358355,1,9,3622214,8 ,1 ,36,,12",
 "515200,2,2,1998/10/01 07:40:00,139.2018496542,36.132302115,1,1,3711123,12,2 ,37,,1",
 "519800,2,2,1998/10/01 07:40:00,139.3230416482,36.142748307,1,10,3721001,9 ,1 ,44,,6",
 "609500,2,3,1998/10/01 07:40:00,139.677808909,35.6768248579,1,7,0430102,8 ,1 ,51,,12",
 "630200,2,4,1998/10/01 07:40:00,139.5762166757,35.8181480313,1,7,0513010,8 ,1 ,41,,10",
 "639400,2,2,1998/10/01 07:40:00,139.6651173286,35.7846869022,1,10,0520112,9 ,1 ,38,,6",
 "644000,2,3,1998/10/01 07:40:00,139.6872364033,35.7593354529,1,4,0522008,5 ,1 ,52,,12",
 "653200,2,3,1998/10/01 07:40:00,139.7610252853,35.6723723568,1,8,0531203,9 ,1 ,39,,12",
 "648600,2,4,1998/10/01 07:40:00,139.7198698943,35.7830637997,2,3,0530007,13,2 ,37,,1",
 "759000,2,3,1998/10/01 07:40:00,139.4902755348,35.6287044563,2,4,0821006,9 ,1 ,55,,12",
 "676200,2,3,1998/10/01 07:40:00,139.7417222125,35.6597921709,1,13,0623211,10,1 ,35,,12",
 "811900,2,2,1998/10/01 07:40:00,139.5965663873,35.4107806838,1,3,1123201,13,2 ,31,,1",
 "692300,2,3,1998/10/01 07:40:00,139.567161967,35.6542494478,2,4,0711108,8 ,1 ,25,,12",
 "683100,2,3,1998/10/01 07:40:00,139.844665743,35.690362546,2,3,0633106,12,2 ,49,,12",
 "777400,2,3,1998/10/01 07:40:00,139.5869233424,35.6629884758,1,5,0844010,8 ,1 ,41,,12",
 "830300,2,2,1998/10/01 07:41:00,139.5324198418,35.4623782085,2,4,1251203,8 ,1 ,37,,1",
 "839500,2,3,1998/10/01 07:41:00,139.6246367297,35.4479899779,2,11,1133001,14,7 ,27,,12",
 "96600,2,3,1998/10/01 07:41:00,140.0871294328,35.5177061352,1,3,4510101,13,2 ,33,,10",
 "841800,2,3,1998/10/01 07:41:00,139.5973299009,35.4175251975,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:41:00,139.5934435996,35.5109137147,1,9,1340109,10,1 ,33,,12",
 "864800,2,3,1998/10/01 07:41:00,139.6691449987,35.5657110075,1,6,1240102,10,1 ,27,,12",
 "869400,2,3,1998/10/01 07:41:00,139.5787878775,35.4469578872,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,3,1998/10/01 07:41:00,139.572821451,35.3744334624,1,6,1230404,9 ,1 ,31,,12",
 "2300,2,2,1998/10/01 07:41:00,139.922397321,35.723187389,1,6,4111102,8 ,1 ,36,,2",
 "13800,2,3,1998/10/01 07:41:00,139.9624187164,35.7130656549,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:41:00,139.8197415081,35.6969413309,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:41:00,139.8937316555,35.6661378752,2,4,4128103,8 ,1 ,42,,12",
 "71300,2,3,1998/10/01 07:41:00,139.7982871587,35.7722775027,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:41:00,140.017329404,35.7123161724,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,3,1998/10/01 07:41:00,140.0939786874,35.6411605482,1,9,4031206,8 ,1 ,38,,10",
 "172500,2,3,1998/10/01 07:41:00,140.1395855483,35.9601625513,2,4,5024120,13,2 ,44,,12",
 "195500,2,3,1998/10/01 07:41:00,139.79867616,35.6829636575,2,4,5420020,8 ,1 ,37,,12",
 "209300,2,2,1998/10/01 07:41:00,139.6670728722,35.2740542163,1,13,2211303,10,1 ,37,,1",
 "227700,2,3,1998/10/01 07:41:00,139.5430774176,35.416485667,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:41:00,139.7120698362,35.5530742509,1,14,2412116,10,1 ,63,,12",
 "303600,2,3,1998/10/01 07:41:00,139.4919584227,35.5854153281,1,9,2923016,9 ,1 ,30,,12",
 "301300,2,3,1998/10/01 07:41:00,139.6218532698,35.5074609432,1,11,2922005,9 ,1 ,29,,12",
 "310500,2,2,1998/10/01 07:41:00,139.6977787264,35.5230472579,1,9,2013102,3 ,1 ,37,,2",
 "110400,2,2,1998/10/01 07:41:00,139.9377591098,35.2298326143,1,12,4621121,1 ,11,41,,8",
 "108100,2,2,1998/10/01 07:41:00,140.0327863985,35.4432540443,2,4,4615025,8 ,1 ,43,,6",
 "170200,2,2,1998/10/01 07:41:00,140.1993662875,36.071596083,2,3,5022220,13,2 ,43,,2",
 "216200,2,2,1998/10/01 07:41:00,139.678535242,35.2487447425,2,1,2221106,12,2 ,34,,1",
 "262200,2,2,1998/10/01 07:41:00,139.1702738406,35.6154267425,1,9,2713004,3 ,1 ,42,,6",
 "269100,2,2,1998/10/01 07:41:00,139.3595623744,35.4547018087,1,7,2811013,9 ,1 ,37,,3",
 "276000,2,2,1998/10/01 07:41:00,139.2589424387,35.3675944352,1,2,2821003,12,2 ,33,,1",
 "370300,2,3,1998/10/01 07:41:00,139.7580322437,35.7176984083,1,10,3037010,10,1 ,39,,12",
 "315100,4,4,1998/10/01 07:41:00,139.6074660503,35.4977939657,1,11,2020106,10,9 ,39,,8",
 "386400,2,4,1998/10/01 07:41:00,139.768299869,35.7308265061,1,10,3059002,8 ,1 ,37,,12",
 "404800,2,2,1998/10/01 07:41:00,139.4719687311,35.7866628249,2,9,3120009,8 ,1 ,30,,1",
 "437000,2,3,1998/10/01 07:41:00,139.8118654319,35.7645985078,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,2,1998/10/01 07:41:00,139.7856606622,35.8885689539,2,5,3221102,8 ,1 ,37,,2",
 "414000,2,2,1998/10/01 07:41:00,139.445352172,35.7564022605,1,10,3122302,3 ,11,32,,6",
 "411700,2,2,1998/10/01 07:41:00,139.4330095158,35.7978252709,2,1,3122110,12,2 ,29,,1",
 "441600,4,4,1998/10/01 07:41:00,139.8879970787,35.7727074393,1,9,3215009,9 ,11,30,,8",
 "476100,2,3,1998/10/01 07:41:00,139.5102663108,35.8758085351,1,3,3411213,13,2 ,34,,12",
 "483000,2,3,1998/10/01 07:41:00,139.6534903459,35.8643580942,1,10,3510101,8 ,1 ,27,,12",
 "462300,2,2,1998/10/01 07:41:00,139.7699712619,35.9117523152,1,8,3235004,9 ,1 ,38,,6",
 "501400,2,2,1998/10/01 07:41:00,139.6628031714,35.9714745324,2,2,3613122,12,2 ,33,,1",
 "508300,2,3,1998/10/01 07:41:00,139.6737959118,36.0499886793,1,9,3622214,8 ,1 ,36,,12",
 "487600,2,2,1998/10/01 07:41:00,139.6365294586,35.9727735358,1,10,3512006,6 ,1 ,36,,6",
 "515200,2,2,1998/10/01 07:41:00,139.2025543611,36.1310024416,1,1,3711123,12,2 ,37,,1",
 "519800,2,2,1998/10/01 07:41:00,139.3233521416,36.1383119122,1,10,3721001,9 ,1 ,44,,6",
 "563500,2,2,1998/10/01 07:41:00,139.6977261938,35.7258664701,1,7,0212001,10,1 ,84,,1",
 "609500,2,3,1998/10/01 07:41:00,139.6814197336,35.6782607529,1,7,0430102,8 ,1 ,51,,12",
 "630200,2,4,1998/10/01 07:41:00,139.5763823037,35.8173470092,1,7,0513010,8 ,1 ,41,,10",
 "639400,2,2,1998/10/01 07:41:00,139.6620285535,35.7852505208,1,10,0520112,9 ,1 ,38,,6",
 "644000,2,3,1998/10/01 07:41:00,139.6913689956,35.7578083107,1,4,0522008,5 ,1 ,52,,12",
 "747500,2,3,1998/10/01 07:41:00,139.5990833411,35.5088859387,1,5,0810007,4 ,1 ,38,,12",
 "811900,2,2,1998/10/01 07:41:00,139.5962826989,35.4105169908,1,3,1123201,13,2 ,31,,1",
 "648600,2,4,1998/10/01 07:41:00,139.719261509,35.7825891035,2,3,0530007,13,2 ,37,,1",
 "653200,2,3,1998/10/01 07:41:00,139.7593061801,35.6691473877,1,8,0531203,9 ,1 ,39,,12",
 "786600,2,2,1998/10/01 07:41:00,139.3009396638,35.7762880232,2,3,0930110,13,2 ,35,,2",
 "777400,2,3,1998/10/01 07:41:00,139.590928227,35.6644403598,1,5,0844010,8 ,1 ,41,,12",
 "676200,2,3,1998/10/01 07:41:00,139.7381418312,35.6610803491,1,13,0623211,10,1 ,35,,12",
 "759000,2,3,1998/10/01 07:41:00,139.4936619723,35.6322456297,2,4,0821006,9 ,1 ,55,,12",
 "683100,2,3,1998/10/01 07:41:00,139.8412113354,35.6900476988,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 07:41:00,139.5714395106,35.656269337,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,2,1998/10/01 07:42:00,139.5325446052,35.4624224688,2,4,1251203,8 ,1 ,37,,1",
 "839500,2,3,1998/10/01 07:42:00,139.6213813363,35.451687627,2,11,1133001,14,7 ,27,,12",
 "96600,2,3,1998/10/01 07:42:00,140.0871297089,35.5177066811,1,3,4510101,13,2 ,33,,10",
 "841800,2,3,1998/10/01 07:42:00,139.5971276726,35.4229116203,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:42:00,139.5976746796,35.5095028845,1,9,1340109,10,1 ,33,,12",
 "864800,2,3,1998/10/01 07:42:00,139.6676537759,35.5709172304,1,6,1240102,10,1 ,27,,12",
 "869400,2,3,1998/10/01 07:42:00,139.5844492951,35.4462981569,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,3,1998/10/01 07:42:00,139.5696981142,35.3738030933,1,6,1230404,9 ,1 ,31,,12",
 "2300,2,2,1998/10/01 07:42:00,139.9229592921,35.7229503861,1,6,4111102,8 ,1 ,36,,2",
 "13800,2,3,1998/10/01 07:42:00,139.965408211,35.7173746894,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:42:00,139.8143275049,35.6967430238,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:42:00,139.8886899368,35.6640442392,2,4,4128103,8 ,1 ,42,,12",
 "71300,2,3,1998/10/01 07:42:00,139.8021339696,35.7701578144,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:42:00,140.0122573466,35.7126132418,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,3,1998/10/01 07:42:00,140.0938273555,35.6406910716,1,9,4031206,8 ,1 ,38,,10",
 "172500,2,3,1998/10/01 07:42:00,140.1391106515,35.9551857658,2,4,5024120,13,2 ,44,,12",
 "195500,2,3,1998/10/01 07:42:00,139.7973877579,35.6775630321,2,4,5420020,8 ,1 ,37,,12",
 "209300,2,2,1998/10/01 07:42:00,139.667205652,35.2747087503,1,13,2211303,10,1 ,37,,1",
 "227700,2,3,1998/10/01 07:42:00,139.5496964021,35.4200139366,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:42:00,139.7145669752,35.5590440197,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:42:00,139.6269348507,35.509413848,1,11,2922005,9 ,1 ,29,,12",
 "303600,2,3,1998/10/01 07:42:00,139.4958678369,35.5878763859,1,9,2923016,9 ,1 ,30,,12",
 "310500,2,2,1998/10/01 07:42:00,139.6969389396,35.5242676567,1,9,2013102,3 ,1 ,37,,2",
 "110400,2,2,1998/10/01 07:42:00,139.9354492718,35.2263671809,1,12,4621121,1 ,11,41,,8",
 "108100,2,2,1998/10/01 07:42:00,140.0348564651,35.4485996078,2,4,4615025,8 ,1 ,43,,6",
 "170200,2,2,1998/10/01 07:42:00,140.1994566672,36.0726583865,2,3,5022220,13,2 ,43,,2",
 "216200,2,2,1998/10/01 07:42:00,139.6782817657,35.2486334108,2,1,2221106,12,2 ,34,,1",
 "262200,2,2,1998/10/01 07:42:00,139.1708797361,35.6182265966,1,9,2713004,3 ,1 ,42,,6",
 "269100,2,2,1998/10/01 07:42:00,139.3586340745,35.4575560619,1,7,2811013,9 ,1 ,37,,3",
 "276000,2,2,1998/10/01 07:42:00,139.2595595258,35.3674517362,1,2,2821003,12,2 ,33,,1",
 "370300,2,3,1998/10/01 07:42:00,139.757071383,35.7139834292,1,10,3037010,10,1 ,39,,12",
 "315100,4,4,1998/10/01 07:42:00,139.6075016314,35.4986449092,1,11,2020106,10,9 ,39,,8",
 "386400,2,4,1998/10/01 07:42:00,139.7714674614,35.7274847163,1,10,3059002,8 ,1 ,37,,12",
 "404800,2,2,1998/10/01 07:42:00,139.4722004662,35.786741998,2,9,3120009,8 ,1 ,30,,1",
 "437000,2,3,1998/10/01 07:42:00,139.8134040235,35.7602410247,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,2,1998/10/01 07:42:00,139.7860006539,35.8880708307,2,5,3221102,8 ,1 ,37,,2",
 "411700,2,2,1998/10/01 07:42:00,139.4325376039,35.7972816313,2,1,3122110,12,2 ,29,,1",
 "414000,2,2,1998/10/01 07:42:00,139.4463831097,35.7552545487,1,10,3122302,3 ,11,32,,6",
 "441600,4,4,1998/10/01 07:42:00,139.8873763653,35.7708534378,1,9,3215009,9 ,11,30,,8",
 "476100,2,3,1998/10/01 07:42:00,139.5147261441,35.8706149591,1,3,3411213,13,2 ,34,,12",
 "483000,2,3,1998/10/01 07:42:00,139.6555843125,35.861185067,1,10,3510101,8 ,1 ,27,,12",
 "462300,2,2,1998/10/01 07:42:00,139.7687485165,35.9086722687,1,8,3235004,9 ,1 ,38,,6",
 "501400,2,2,1998/10/01 07:42:00,139.6624457035,35.971797935,2,2,3613122,12,2 ,33,,1",
 "508300,2,3,1998/10/01 07:42:00,139.6724744041,36.0418541935,1,9,3622214,8 ,1 ,36,,12",
 "487600,2,2,1998/10/01 07:42:00,139.6353809969,35.9710327427,1,10,3512006,6 ,1 ,36,,6",
 "515200,2,2,1998/10/01 07:42:00,139.2029721719,36.1296293876,1,1,3711123,12,2 ,37,,1",
 "519800,2,2,1998/10/01 07:42:00,139.3236400075,36.1338744831,1,10,3721001,9 ,1 ,44,,6",
 "563500,2,2,1998/10/01 07:42:00,139.6970424093,35.7259866746,1,7,0212001,10,1 ,84,,1",
 "609500,2,3,1998/10/01 07:42:00,139.6850445615,35.6797013244,1,7,0430102,8 ,1 ,51,,12",
 "630200,2,4,1998/10/01 07:42:00,139.5764924196,35.8165419597,1,7,0513010,8 ,1 ,41,,10",
 "639400,2,2,1998/10/01 07:42:00,139.6589467887,35.7858354223,1,10,0520112,9 ,1 ,38,,6",
 "644000,2,3,1998/10/01 07:42:00,139.6952057044,35.755810554,1,4,0522008,5 ,1 ,52,,12",
 "747500,2,3,1998/10/01 07:42:00,139.6050592898,35.5062549156,1,5,0810007,4 ,1 ,38,,12",
 "811900,2,2,1998/10/01 07:42:00,139.5960456434,35.4102368752,1,3,1123201,13,2 ,31,,1",
 "648600,2,4,1998/10/01 07:42:00,139.718580322,35.7821928556,2,3,0530007,13,2 ,37,,1",
 "653200,2,3,1998/10/01 07:42:00,139.7582273426,35.6657883896,1,8,0531203,9 ,1 ,39,,12",
 "786600,2,2,1998/10/01 07:42:00,139.3016165608,35.7760713458,2,3,0930110,13,2 ,35,,2",
 "777400,2,3,1998/10/01 07:42:00,139.594939937,35.6658797836,1,5,0844010,8 ,1 ,41,,12",
 "676200,2,3,1998/10/01 07:42:00,139.734458312,35.6622012959,1,13,0623211,10,1 ,35,,12",
 "759000,2,3,1998/10/01 07:42:00,139.4977452254,35.6351751367,2,4,0821006,9 ,1 ,55,,12",
 "683100,2,3,1998/10/01 07:42:00,139.837743962,35.6899857386,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 07:42:00,139.5757354041,35.6582629604,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,2,1998/10/01 07:43:00,139.5320179169,35.4633180524,2,4,1251203,8 ,1 ,37,,1",
 "830300,2,3,1998/10/01 07:43:00,139.5320179169,35.4633180524,2,4,1251203,8 ,1 ,37,,12",
 "839500,2,3,1998/10/01 07:43:00,139.6197037603,35.4561155376,2,11,1133001,14,7 ,27,,12",
 "96600,2,3,1998/10/01 07:43:00,140.087129985,35.5177072271,1,3,4510101,13,2 ,33,,10",
 "841800,2,3,1998/10/01 07:43:00,139.5959990658,35.4282745994,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:43:00,139.6016785815,35.5076403312,1,9,1340109,10,1 ,33,,12",
 "864800,2,3,1998/10/01 07:43:00,139.6652438322,35.5757646468,1,6,1240102,10,1 ,27,,12",
 "869400,2,3,1998/10/01 07:43:00,139.5900989772,35.4455280711,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,3,1998/10/01 07:43:00,139.5665776481,35.3731653613,1,6,1230404,9 ,1 ,31,,12",
 "2300,2,2,1998/10/01 07:43:00,139.9235212598,35.7227133806,1,6,4111102,8 ,1 ,36,,2",
 "13800,2,3,1998/10/01 07:43:00,139.9680326968,35.7218037496,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:43:00,139.8089192547,35.6964553606,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:43:00,139.8830290949,35.6634727339,2,4,4128103,8 ,1 ,42,,12",
 "71300,2,3,1998/10/01 07:43:00,139.8059717558,35.7680274306,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:43:00,140.0072160396,35.7121827983,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,3,1998/10/01 07:43:00,140.0937268191,35.6402139208,1,9,4031206,8 ,1 ,38,,10",
 "172500,2,3,1998/10/01 07:43:00,140.1386389824,35.9502087748,2,4,5024120,13,2 ,44,,12",
 "195500,2,3,1998/10/01 07:43:00,139.7946525501,35.6723093054,2,4,5420020,8 ,1 ,37,,12",
 "209300,2,2,1998/10/01 07:43:00,139.6673384341,35.2753632841,1,13,2211303,10,1 ,37,,1",
 "227700,2,3,1998/10/01 07:43:00,139.555155016,35.4244862543,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:43:00,139.7170362461,35.5650211953,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:43:00,139.6324883179,35.5093432589,1,11,2922005,9 ,1 ,29,,12",
 "303600,2,3,1998/10/01 07:43:00,139.4985652267,35.5913206122,1,9,2923016,9 ,1 ,30,,12",
 "310500,2,2,1998/10/01 07:43:00,139.6960099233,35.5254591156,1,9,2013102,3 ,1 ,37,,2",
 "110400,2,2,1998/10/01 07:43:00,139.9336724293,35.2225703753,1,12,4621121,1 ,11,41,,8",
 "108100,2,2,1998/10/01 07:43:00,140.03266808,35.4530901619,2,4,4615025,8 ,1 ,43,,6",
 "170200,2,2,1998/10/01 07:43:00,140.1999268819,36.0736284864,2,3,5022220,13,2 ,43,,2",
 "216200,2,2,1998/10/01 07:43:00,139.6780282901,35.2485220786,2,1,2221106,12,2 ,34,,1",
 "262200,2,2,1998/10/01 07:43:00,139.171690947,35.6211655384,1,9,2713004,3 ,1 ,42,,6",
 "269100,2,2,1998/10/01 07:43:00,139.3577306672,35.4606080735,1,7,2811013,9 ,1 ,37,,3",
 "276000,2,2,1998/10/01 07:43:00,139.2602057068,35.367472678,1,2,2821003,12,2 ,33,,1",
 "370300,2,3,1998/10/01 07:43:00,139.7537741071,35.7113860098,1,10,3037010,10,1 ,39,,12",
 "315100,4,4,1998/10/01 07:43:00,139.6075198106,35.4994961136,1,11,2020106,10,9 ,39,,8",
 "386400,2,4,1998/10/01 07:43:00,139.7749911655,35.7243640553,1,10,3059002,8 ,1 ,37,,12",
 "404800,2,2,1998/10/01 07:43:00,139.4724431747,35.7867816714,2,9,3120009,8 ,1 ,30,,1",
 "437000,2,3,1998/10/01 07:43:00,139.8103366635,35.7562238048,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,2,1998/10/01 07:43:00,139.7863406412,35.8875727066,2,5,3221102,8 ,1 ,37,,2",
 "446200,2,3,1998/10/01 07:43:00,139.7863406412,35.8875727066,2,5,3221102,8 ,1 ,37,,12",
 "411700,2,2,1998/10/01 07:43:00,139.4320478761,35.7967484828,2,1,3122110,12,2 ,29,,1",
 "414000,2,2,1998/10/01 07:43:00,139.4478346755,35.7542095601,1,10,3122302,3 ,11,32,,6",
 "476100,2,3,1998/10/01 07:43:00,139.5191110361,35.8653793704,1,3,3411213,13,2 ,34,,12",
 "441600,4,4,1998/10/01 07:43:00,139.8866107592,35.7690304368,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 07:43:00,139.6575649126,35.8579643091,1,10,3510101,8 ,1 ,27,,12",
 "462300,2,2,1998/10/01 07:43:00,139.7683063496,35.907459861,1,8,3235004,9 ,1 ,38,,6",
 "501400,2,2,1998/10/01 07:43:00,139.6620882326,35.9721213365,2,2,3613122,12,2 ,33,,1",
 "487600,2,2,1998/10/01 07:43:00,139.6342616168,35.9692811627,1,10,3512006,6 ,1 ,36,,6",
 "508300,2,3,1998/10/01 07:43:00,139.6705866901,36.0338341468,1,9,3622214,8 ,1 ,36,,12",
 "515200,2,2,1998/10/01 07:43:00,139.203222966,36.1282543446,1,1,3711123,12,2 ,37,,1",
 "519800,2,2,1998/10/01 07:43:00,139.3220161606,36.1313435156,1,10,3721001,9 ,1 ,44,,6",
 "549700,2,2,1998/10/01 07:43:00,139.7307240434,35.5763612675,1,8,0131010,3 ,1 ,62,,2",
 "563500,2,2,1998/10/01 07:43:00,139.6962171865,35.7261686772,1,7,0212001,10,1 ,84,,1",
 "609500,2,3,1998/10/01 07:43:00,139.6883564081,35.6813187613,1,7,0430102,8 ,1 ,51,,12",
 "609500,2,4,1998/10/01 07:43:00,139.6883564081,35.6813187613,1,7,0430102,8 ,1 ,51,,1",
 "639400,2,2,1998/10/01 07:43:00,139.6558686425,35.7864377256,1,10,0520112,9 ,1 ,38,,6",
 "630200,2,4,1998/10/01 07:43:00,139.5769021843,35.8158458296,1,7,0513010,8 ,1 ,41,,10",
 "630200,2,5,1998/10/01 07:43:00,139.5769021843,35.8158458296,1,7,0513010,8 ,1 ,41,,1",
 "644000,2,3,1998/10/01 07:43:00,139.6988650418,35.7536001168,1,4,0522008,5 ,1 ,52,,12",
 "747500,2,3,1998/10/01 07:43:00,139.6117722671,35.5061222547,1,5,0810007,4 ,1 ,38,,12",
 "811900,2,2,1998/10/01 07:43:00,139.5960068937,35.4098869234,1,3,1123201,13,2 ,31,,1",
 "648600,2,4,1998/10/01 07:43:00,139.7178540893,35.7818451475,2,3,0530007,13,2 ,37,,1",
 "653200,2,3,1998/10/01 07:43:00,139.7585850695,35.6622857335,1,8,0531203,9 ,1 ,39,,12",
 "777400,2,3,1998/10/01 07:43:00,139.5989517915,35.667319074,1,5,0844010,8 ,1 ,41,,12",
 "786600,2,2,1998/10/01 07:43:00,139.3023581285,35.7759923062,2,3,0930110,13,2 ,35,,2",
 "786600,2,3,1998/10/01 07:43:00,139.3023581285,35.7759923062,2,3,0930110,13,2 ,35,,12",
 "676200,2,3,1998/10/01 07:43:00,139.7313533358,35.6626551606,1,13,0623211,10,1 ,35,,12",
 "676200,2,4,1998/10/01 07:43:00,139.7313533358,35.6626551606,1,13,0623211,10,1 ,35,,1",
 "759000,2,3,1998/10/01 07:43:00,139.5029390404,35.6361557436,2,4,0821006,9 ,1 ,55,,12",
 "683100,2,3,1998/10/01 07:43:00,139.8342848715,35.689724138,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 07:43:00,139.5800067669,35.6602916915,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,3,1998/10/01 07:44:00,139.540789633,35.469005823,2,4,1251203,8 ,1 ,37,,12",
 "839500,2,3,1998/10/01 07:44:00,139.6195388802,35.4603783938,2,11,1133001,14,7 ,27,,12",
 "96600,2,3,1998/10/01 07:44:00,140.0871302611,35.517707773,1,3,4510101,13,2 ,33,,10",
 "841800,2,3,1998/10/01 07:44:00,139.5977550299,35.4331901874,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:44:00,139.6058455041,35.506076064,1,9,1340109,10,1 ,33,,12",
 "864800,2,3,1998/10/01 07:44:00,139.6595586472,35.5760265267,1,6,1240102,10,1 ,27,,12",
 "864800,2,4,1998/10/01 07:44:00,139.6595586472,35.5760265267,1,6,1240102,10,1 ,27,,1",
 "869400,2,3,1998/10/01 07:44:00,139.5957547146,35.4448069616,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,3,1998/10/01 07:44:00,139.5635727468,35.3722329767,1,6,1230404,9 ,1 ,31,,12",
 "2300,2,2,1998/10/01 07:44:00,139.924073134,35.7224611041,1,6,4111102,8 ,1 ,36,,2",
 "13800,2,3,1998/10/01 07:44:00,139.9682407117,35.7267452732,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:44:00,139.8035108976,35.6961691813,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:44:00,139.8773445367,35.6633839771,2,4,4128103,8 ,1 ,42,,12",
 "71300,2,3,1998/10/01 07:44:00,139.8097885964,35.7658723831,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:44:00,140.0023018406,35.7110943881,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,3,1998/10/01 07:44:00,140.0936622909,35.6397313299,1,9,4031206,8 ,1 ,38,,10",
 "172500,2,3,1998/10/01 07:44:00,140.1381816439,35.9452309396,2,4,5024120,13,2 ,44,,12",
 "195500,2,3,1998/10/01 07:44:00,139.7897283624,35.6681023013,2,4,5420020,8 ,1 ,37,,12",
 "209300,2,2,1998/10/01 07:44:00,139.6676707953,35.2759354361,1,13,2211303,10,1 ,37,,1",
 "227700,2,3,1998/10/01 07:44:00,139.5569005788,35.4307151091,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:44:00,139.7195834369,35.5709769232,1,14,2412116,10,1 ,63,,12",
 "303600,2,3,1998/10/01 07:44:00,139.500259265,35.5951811028,1,9,2923016,9 ,1 ,30,,12",
 "301300,2,3,1998/10/01 07:44:00,139.6374776009,35.5072335184,1,11,2922005,9 ,1 ,29,,12",
 "310500,2,2,1998/10/01 07:44:00,139.6950367967,35.5266189,1,9,2013102,3 ,1 ,37,,2",
 "110400,2,2,1998/10/01 07:44:00,139.9323349591,35.2191476521,1,12,4621121,1 ,11,41,,8",
 "108100,2,2,1998/10/01 07:44:00,140.0294943014,35.4578838107,2,4,4615025,8 ,1 ,43,,6",
 "170200,2,2,1998/10/01 07:44:00,140.200684257,36.0744979355,2,3,5022220,13,2 ,43,,2",
 "216200,2,2,1998/10/01 07:44:00,139.6777748151,35.2484107458,2,1,2221106,12,2 ,34,,1",
 "262200,2,2,1998/10/01 07:44:00,139.174558207,35.6215473633,1,9,2713004,3 ,1 ,42,,6",
 "269100,2,2,1998/10/01 07:44:00,139.3566354286,35.4636176377,1,7,2811013,9 ,1 ,37,,3",
 "276000,2,2,1998/10/01 07:44:00,139.2608299261,35.3676073403,1,2,2821003,12,2 ,33,,1",
 "370300,2,3,1998/10/01 07:44:00,139.7518348613,35.708318134,1,10,3037010,10,1 ,39,,12",
 "315100,4,4,1998/10/01 07:44:00,139.6075105458,35.5003476949,1,11,2020106,10,9 ,39,,8",
 "386400,2,4,1998/10/01 07:44:00,139.7783669809,35.7211351913,1,10,3059002,8 ,1 ,37,,12",
 "404800,2,2,1998/10/01 07:44:00,139.4726941663,35.7867915282,2,9,3120009,8 ,1 ,30,,1",
 "437000,2,3,1998/10/01 07:44:00,139.8069793678,35.7523121762,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,3,1998/10/01 07:44:00,139.7874687929,35.884944313,2,5,3221102,8 ,1 ,37,,12",
 "414000,2,2,1998/10/01 07:44:00,139.4494736021,35.7530615209,1,10,3122302,3 ,11,32,,6",
 "411700,2,2,1998/10/01 07:44:00,139.4314343449,35.7964329654,2,1,3122110,12,2 ,29,,1",
 "441600,4,4,1998/10/01 07:44:00,139.8852138443,35.7680481873,1,9,3215009,9 ,11,30,,8",
 "476100,2,3,1998/10/01 07:44:00,139.5235974506,35.8602017169,1,3,3411213,13,2 ,34,,12",
 "483000,2,3,1998/10/01 07:44:00,139.6600575778,35.8550118246,1,10,3510101,8 ,1 ,27,,12",
 "462300,2,2,1998/10/01 07:44:00,139.7677887787,35.9059750444,1,8,3235004,9 ,1 ,38,,6",
 "501400,2,2,1998/10/01 07:44:00,139.6617473711,35.9724552069,2,2,3613122,12,2 ,33,,1",
 "508300,2,3,1998/10/01 07:44:00,139.6675276917,36.0260281501,1,9,3622214,8 ,1 ,36,,12",
 "487600,2,2,1998/10/01 07:44:00,139.6331006047,35.9675456022,1,10,3512006,6 ,1 ,36,,6",
 "515200,2,2,1998/10/01 07:44:00,139.2022167559,36.1271468238,1,1,3711123,12,2 ,37,,1",
 "519800,2,2,1998/10/01 07:44:00,139.3166347511,36.132014107,1,10,3721001,9 ,1 ,44,,6",
 "563500,2,2,1998/10/01 07:44:00,139.6954350781,35.7265075714,1,7,0212001,10,1 ,84,,1",
 "549700,2,2,1998/10/01 07:44:00,139.7310554924,35.575925165,1,8,0131010,3 ,1 ,62,,2",
 "609500,2,4,1998/10/01 07:44:00,139.6884790498,35.6807595268,1,7,0430102,8 ,1 ,51,,1",
 "639400,2,2,1998/10/01 07:44:00,139.6527951701,35.7870561231,1,10,0520112,9 ,1 ,38,,6",
 "630200,2,5,1998/10/01 07:44:00,139.5768107416,35.8010357175,1,7,0513010,8 ,1 ,41,,1",
 "644000,2,3,1998/10/01 07:44:00,139.7011938907,35.7504803821,1,4,0522008,5 ,1 ,52,,12",
 "747500,2,3,1998/10/01 07:44:00,139.6185015303,35.5069180988,1,5,0810007,4 ,1 ,38,,12",
 "811900,2,2,1998/10/01 07:44:00,139.5959681444,35.4095369716,1,3,1123201,13,2 ,31,,1",
 "648600,2,4,1998/10/01 07:44:00,139.7171278628,35.7814974349,2,3,0530007,13,2 ,37,,1",
 "653200,2,3,1998/10/01 07:44:00,139.7579612495,35.6588111034,1,8,0531203,9 ,1 ,39,,12",
 "777400,2,3,1998/10/01 07:44:00,139.602952328,35.6687785854,1,5,0844010,8 ,1 ,41,,12",
 "786600,2,3,1998/10/01 07:44:00,139.3052631605,35.772801672,2,3,0930110,13,2 ,35,,12",
 "676200,2,4,1998/10/01 07:44:00,139.7321575738,35.6631127387,1,13,0623211,10,1 ,35,,1",
 "759000,2,3,1998/10/01 07:44:00,139.5078075771,35.6340961551,2,4,0821006,9 ,1 ,55,,12",
 "683100,2,3,1998/10/01 07:44:00,139.8308305224,35.6893946817,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 07:44:00,139.5844239045,35.6620943727,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,3,1998/10/01 07:45:00,139.5494427562,35.4749080061,2,4,1251203,8 ,1 ,37,,12",
 "96600,2,3,1998/10/01 07:45:00,140.0871305372,35.517708319,1,3,4510101,13,2 ,33,,10",
 "839500,2,3,1998/10/01 07:45:00,139.621466689,35.4643883647,2,11,1133001,14,7 ,27,,12",
 "841800,2,3,1998/10/01 07:45:00,139.604147169,35.4346980923,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:45:00,139.6104100385,35.5059664412,1,9,1340109,10,1 ,33,,12",
 "869400,2,3,1998/10/01 07:45:00,139.6003871922,35.4472779252,1,10,1231001,8 ,1 ,29,,12",
 "864800,2,4,1998/10/01 07:45:00,139.6597131239,35.5753652799,1,6,1240102,10,1 ,27,,1",
 "871700,2,3,1998/10/01 07:45:00,139.5608475598,35.3708524505,1,6,1230404,9 ,1 ,31,,12",
 "2300,2,2,1998/10/01 07:45:00,139.9246304025,35.7222168722,1,6,4111102,8 ,1 ,36,,2",
 "18400,2,3,1998/10/01 07:45:00,139.7981033648,35.6958739449,1,9,4120311,8 ,1 ,44,,12",
 "13800,2,3,1998/10/01 07:45:00,139.9661812984,35.731363429,2,14,4116004,5 ,5 ,25,,12",
 "32200,2,3,1998/10/01 07:45:00,139.8716516157,35.6636331242,2,4,4128103,8 ,1 ,42,,12",
 "71300,2,3,1998/10/01 07:45:00,139.8130518348,35.7632755905,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:45:00,139.9974784564,35.7097868712,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,3,1998/10/01 07:45:00,140.0935977634,35.6392487389,1,9,4031206,8 ,1 ,38,,10",
 "172500,2,3,1998/10/01 07:45:00,140.1381978719,35.9402399612,2,4,5024120,13,2 ,44,,12",
 "195500,2,3,1998/10/01 07:45:00,139.784543179,35.6644896826,2,4,5420020,8 ,1 ,37,,12",
 "195500,2,4,1998/10/01 07:45:00,139.784543179,35.6644896826,2,4,5420020,8 ,1 ,37,,1",
 "209300,2,2,1998/10/01 07:45:00,139.6682919831,35.2760206357,1,13,2211303,10,1 ,37,,1",
 "227700,2,3,1998/10/01 07:45:00,139.5597828597,35.4365423955,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:45:00,139.7220834787,35.5769459977,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:45:00,139.6416749792,35.5043661364,1,11,2922005,9 ,1 ,29,,12",
 "303600,2,3,1998/10/01 07:45:00,139.5018472778,35.5990428567,1,9,2923016,9 ,1 ,30,,12",
 "310500,2,2,1998/10/01 07:45:00,139.694220663,35.5273592431,1,9,2013102,3 ,1 ,37,,2",
 "108100,2,2,1998/10/01 07:45:00,140.0261653608,35.4630337739,2,4,4615025,8 ,1 ,43,,6",
 "110400,2,2,1998/10/01 07:45:00,139.9346835429,35.2218021283,1,12,4621121,1 ,11,41,,8",
 "170200,2,2,1998/10/01 07:45:00,140.2014463235,36.075361343,2,3,5022220,13,2 ,43,,2",
 "216200,2,2,1998/10/01 07:45:00,139.6775213409,35.2482994125,2,1,2221106,12,2 ,34,,1",
 "262200,2,2,1998/10/01 07:45:00,139.1775857291,35.6198803105,1,9,2713004,3 ,1 ,42,,6",
 "269100,2,2,1998/10/01 07:45:00,139.3558852578,35.4666442854,1,7,2811013,9 ,1 ,37,,3",
 "276000,2,2,1998/10/01 07:45:00,139.2611035823,35.3671993598,1,2,2821003,12,2 ,33,,1",
 "370300,2,3,1998/10/01 07:45:00,139.7516226444,35.7043787935,1,10,3037010,10,1 ,39,,12",
 "315100,4,4,1998/10/01 07:45:00,139.6078359566,35.501050909,1,11,2020106,10,9 ,39,,8",
 "386400,2,4,1998/10/01 07:45:00,139.7791699803,35.7173164566,1,10,3059002,8 ,1 ,37,,12",
 "404800,2,2,1998/10/01 07:45:00,139.4729303739,35.7867275535,2,9,3120009,8 ,1 ,30,,1",
 "437000,2,3,1998/10/01 07:45:00,139.8045369263,35.748016176,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,3,1998/10/01 07:45:00,139.7883482227,35.8821411215,2,5,3221102,8 ,1 ,37,,12",
 "411700,2,2,1998/10/01 07:45:00,139.43069446,35.7964409349,2,1,3122110,12,2 ,29,,1",
 "414000,2,2,1998/10/01 07:45:00,139.4514605275,35.752133531,1,10,3122302,3 ,11,32,,6",
 "441600,4,4,1998/10/01 07:45:00,139.882853502,35.7680652882,1,9,3215009,9 ,11,30,,8",
 "476100,2,3,1998/10/01 07:45:00,139.5281328898,35.8550521295,1,3,3411213,13,2 ,34,,12",
 "483000,2,3,1998/10/01 07:45:00,139.6633607927,35.8526161002,1,10,3510101,8 ,1 ,27,,12",
 "462300,2,2,1998/10/01 07:45:00,139.7667387123,35.9028492523,1,8,3235004,9 ,1 ,38,,6",
 "501400,2,2,1998/10/01 07:45:00,139.6614211148,35.9727984099,2,2,3613122,12,2 ,33,,1",
 "487600,2,2,1998/10/01 07:45:00,139.6319572236,35.9658032811,1,10,3512006,6 ,1 ,36,,6",
 "508300,2,3,1998/10/01 07:45:00,139.6668173251,36.017914431,1,9,3622214,8 ,1 ,36,,12",
 "508300,2,4,1998/10/01 07:45:00,139.6668173251,36.017914431,1,9,3622214,8 ,1 ,36,,1",
 "515200,2,2,1998/10/01 07:45:00,139.2009506719,36.1263015599,1,1,3711123,12,2 ,37,,1",
 "519800,2,2,1998/10/01 07:45:00,139.3112095546,36.13146022,1,10,3721001,9 ,1 ,44,,6",
 "549700,2,2,1998/10/01 07:45:00,139.7317377994,35.575874997,1,8,0131010,3 ,1 ,62,,2",
 "563500,2,2,1998/10/01 07:45:00,139.6949381247,35.7270026226,1,7,0212001,10,1 ,84,,1",
 "609500,2,4,1998/10/01 07:45:00,139.6886016897,35.680200292,1,7,0430102,8 ,1 ,51,,1",
 "639400,2,2,1998/10/01 07:45:00,139.6502990236,35.788008357,1,10,0520112,9 ,1 ,38,,6",
 "639400,3,3,1998/10/01 07:45:00,139.6502990236,35.788008357,1,10,0520112,9 ,97,38,,97",
 "630200,2,5,1998/10/01 07:45:00,139.5758745605,35.7868453949,1,7,0513010,8 ,1 ,41,,1",
 "630200,3,6,1998/10/01 07:45:00,139.5758745605,35.7868453949,1,7,0513010,8 ,97,41,,97",
 "644000,2,3,1998/10/01 07:45:00,139.7039799378,35.7477413695,1,4,0522008,5 ,1 ,52,,12",
 "648600,2,4,1998/10/01 07:45:00,139.7164016427,35.781149718,2,3,0530007,13,2 ,37,,1",
 "747500,2,3,1998/10/01 07:45:00,139.6248676065,35.5087125069,1,5,0810007,4 ,1 ,38,,12",
 "811900,2,2,1998/10/01 07:45:00,139.5959293954,35.4091870198,1,3,1123201,13,2 ,31,,1",
 "811900,2,3,1998/10/01 07:45:00,139.5959293954,35.4091870198,1,3,1123201,13,2 ,31,,12",
 "653200,2,3,1998/10/01 07:45:00,139.7570457999,35.6554621427,1,8,0531203,9 ,1 ,39,,12",
 "653200,2,4,1998/10/01 07:45:00,139.7570457999,35.6554621427,1,8,0531203,9 ,1 ,39,,1",
 "777400,2,3,1998/10/01 07:45:00,139.6069871281,35.6701723252,1,5,0844010,8 ,1 ,41,,12",
 "786600,2,3,1998/10/01 07:45:00,139.3078082736,35.769377537,2,3,0930110,13,2 ,35,,12",
 "676200,2,4,1998/10/01 07:45:00,139.7329941998,35.6628810425,1,13,0623211,10,1 ,35,,1",
 "759000,2,3,1998/10/01 07:45:00,139.5130681038,35.6329589464,2,4,0821006,9 ,1 ,55,,12",
 "683100,2,3,1998/10/01 07:45:00,139.8273614618,35.6893349918,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 07:45:00,139.5889456735,35.6637225548,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,3,1998/10/01 07:46:00,139.5597557153,35.4780432701,2,4,1251203,8 ,1 ,37,,12",
 "839500,2,3,1998/10/01 07:46:00,139.6238571788,35.4682684118,2,11,1133001,14,7 ,27,,12",
 "96600,2,3,1998/10/01 07:46:00,140.0871308133,35.5177088649,1,3,4510101,13,2 ,33,,10",
 "841800,2,3,1998/10/01 07:46:00,139.6104536629,35.436393899,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:46:00,139.6149730903,35.5064899287,1,9,1340109,10,1 ,33,,12",
 "864800,2,4,1998/10/01 07:46:00,139.6598675979,35.5747040329,1,6,1240102,10,1 ,27,,1",
 "869400,2,3,1998/10/01 07:46:00,139.6042692254,35.4507136565,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,3,1998/10/01 07:46:00,139.5579513157,35.3697369819,1,6,1230404,9 ,1 ,31,,12",
 "2300,2,2,1998/10/01 07:46:00,139.9251887526,35.7219742556,1,6,4111102,8 ,1 ,36,,2",
 "13800,2,3,1998/10/01 07:46:00,139.9635769067,35.7358368836,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:46:00,139.7927522201,35.6964052681,1,9,4120311,8 ,1 ,44,,12",
 "34500,2,2,1998/10/01 07:46:00,139.986213402,35.7084624899,1,4,4129003,8 ,1 ,36,,1",
 "32200,2,3,1998/10/01 07:46:00,139.8659696676,35.6640422644,2,4,4128103,8 ,1 ,42,,12",
 "71300,2,3,1998/10/01 07:46:00,139.8132105994,35.7595904664,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:46:00,139.992759401,35.7083366036,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,3,1998/10/01 07:46:00,140.0935332367,35.6387661479,1,9,4031206,8 ,1 ,38,,10",
 "195500,2,4,1998/10/01 07:46:00,139.7854748175,35.6635846524,2,4,5420020,8 ,1 ,37,,1",
 "172500,2,3,1998/10/01 07:46:00,140.1382608398,35.9352484667,2,4,5024120,13,2 ,44,,12",
 "209300,2,2,1998/10/01 07:46:00,139.6688670309,35.276225408,1,13,2211303,10,1 ,37,,1",
 "227700,2,3,1998/10/01 07:46:00,139.5655276683,35.4408361298,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:46:00,139.7245502011,35.5829242108,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:46:00,139.6437744774,35.5001032821,1,11,2922005,9 ,1 ,29,,12",
 "303600,2,3,1998/10/01 07:46:00,139.5053036278,35.6020224359,1,9,2923016,9 ,1 ,30,,12",
 "85100,2,2,1998/10/01 07:46:00,140.2918431517,35.6806329483,1,8,4417027,9 ,10,46,,6",
 "310500,2,2,1998/10/01 07:46:00,139.6929525407,35.5283181681,1,9,2013102,3 ,1 ,37,,2",
 "110400,2,2,1998/10/01 07:46:00,139.936746325,35.2258513373,1,12,4621121,1 ,11,41,,8",
 "108100,2,2,1998/10/01 07:46:00,140.0283925622,35.4679256079,2,4,4615025,8 ,1 ,43,,6",
 "170200,2,2,1998/10/01 07:46:00,140.2020695152,36.0762980588,2,3,5022220,13,2 ,43,,2",
 "216200,2,2,1998/10/01 07:46:00,139.6772678674,35.2481880786,2,1,2221106,12,2 ,34,,1",
 "262200,2,2,1998/10/01 07:46:00,139.1804638058,35.6207012926,1,9,2713004,3 ,1 ,42,,6",
 "269100,2,2,1998/10/01 07:46:00,139.3552268508,35.4697282559,1,7,2811013,9 ,1 ,37,,3",
 "276000,2,2,1998/10/01 07:46:00,139.2617092465,35.3672245849,1,2,2821003,12,2 ,33,,1",
 "370300,2,3,1998/10/01 07:46:00,139.747386406,35.7033239409,1,10,3037010,10,1 ,39,,12",
 "315100,4,4,1998/10/01 07:46:00,139.608409318,35.5017618372,1,11,2020106,10,9 ,39,,8",
 "386400,2,4,1998/10/01 07:46:00,139.7767570473,35.7136939849,1,10,3059002,8 ,1 ,37,,12",
 "404800,2,2,1998/10/01 07:46:00,139.4731645042,35.7866532057,2,9,3120009,8 ,1 ,30,,1",
 "437000,2,3,1998/10/01 07:46:00,139.8028610929,35.7434372642,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,3,1998/10/01 07:46:00,139.7890111929,35.8792941566,2,5,3221102,8 ,1 ,37,,12",
 "414000,2,2,1998/10/01 07:46:00,139.4534658402,35.7512308638,1,10,3122302,3 ,11,32,,6",
 "411700,2,2,1998/10/01 07:46:00,139.4302850784,35.7958645205,2,1,3122110,12,2 ,29,,1",
 "476100,2,3,1998/10/01 07:46:00,139.5330206104,35.8501281873,1,3,3411213,13,2 ,34,,12",
 "441600,4,4,1998/10/01 07:46:00,139.8804954174,35.7682052054,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 07:46:00,139.6665580938,35.8501328717,1,10,3510101,8 ,1 ,27,,12",
 "462300,2,2,1998/10/01 07:46:00,139.7638129268,35.9013704449,1,8,3235004,9 ,1 ,38,,6",
 "501400,2,2,1998/10/01 07:46:00,139.6610169382,35.9730808407,2,2,3613122,12,2 ,33,,1",
 "487600,2,2,1998/10/01 07:46:00,139.6308262689,35.9640548677,1,10,3512006,6 ,1 ,36,,6",
 "508300,2,4,1998/10/01 07:46:00,139.6668235663,36.0171325616,1,9,3622214,8 ,1 ,36,,1",
 "515200,2,2,1998/10/01 07:46:00,139.1995411424,36.1254588037,1,1,3711123,12,2 ,37,,1",
 "512900,2,2,1998/10/01 07:46:00,139.4435916941,36.1017199372,1,11,3632007,5 ,1 ,35,,2",
 "519800,2,2,1998/10/01 07:46:00,139.3058173611,36.1317312102,1,10,3721001,9 ,1 ,44,,6",
 "549700,2,2,1998/10/01 07:46:00,139.7322131607,35.5756677183,1,8,0131010,3 ,1 ,62,,2",
 "563500,2,2,1998/10/01 07:46:00,139.6943262888,35.7264804456,1,7,0212001,10,1 ,84,,1",
 "563500,2,3,1998/10/01 07:46:00,139.6943262888,35.7264804456,1,7,0212001,10,1 ,84,,12",
 "609500,2,4,1998/10/01 07:46:00,139.688681279,35.6796399367,1,7,0430102,8 ,1 ,51,,1",
 "646300,2,2,1998/10/01 07:46:00,139.6653566242,35.787963099,1,4,0523008,8 ,1 ,75,,1",
 "644000,2,3,1998/10/01 07:46:00,139.7084019998,35.7468688515,1,4,0522008,5 ,1 ,52,,12",
 "747500,2,3,1998/10/01 07:46:00,139.6310932706,35.5099706966,1,5,0810007,4 ,1 ,38,,12",
 "713000,2,2,1998/10/01 07:46:00,139.5125848211,35.7608531712,2,2,0741304,12,2 ,30,,1",
 "811900,2,3,1998/10/01 07:46:00,139.5968027131,35.4062552839,1,3,1123201,13,2 ,31,,12",
 "653200,2,4,1998/10/01 07:46:00,139.7560482275,35.6557076598,1,8,0531203,9 ,1 ,39,,1",
 "648600,2,4,1998/10/01 07:46:00,139.7156754289,35.7808019967,2,3,0530007,13,2 ,37,,1",
 "768200,2,2,1998/10/01 07:46:00,139.3478292442,35.6686074055,2,2,0840002,12,2 ,36,,1",
 "777400,2,3,1998/10/01 07:46:00,139.6112467524,35.6705862754,1,5,0844010,8 ,1 ,41,,12",
 "786600,2,3,1998/10/01 07:46:00,139.3103531673,35.7659533443,2,3,0930110,13,2 ,35,,12",
 "788900,2,2,1998/10/01 07:46:00,139.2798145184,35.8207422765,2,2,0931009,12,2 ,35,,1",
 "782000,2,2,1998/10/01 07:46:00,139.3314215262,35.7225220946,2,2,0911103,12,2 ,36,,1",
 "676200,2,4,1998/10/01 07:46:00,139.7338390062,35.6624750597,1,13,0623211,10,1 ,35,,1",
 "759000,2,3,1998/10/01 07:46:00,139.5185667123,35.6330121501,2,4,0821006,9 ,1 ,55,,12",
 "683100,2,3,1998/10/01 07:46:00,139.8238851154,35.6892891202,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 07:46:00,139.5934672194,35.6653513859,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,3,1998/10/01 07:47:00,139.5707600902,35.4767570863,2,4,1251203,8 ,1 ,37,,12",
 "839500,2,3,1998/10/01 07:47:00,139.6254601721,35.4725348208,2,11,1133001,14,7 ,27,,12",
 "96600,2,3,1998/10/01 07:47:00,140.0871310894,35.5177094109,1,3,4510101,13,2 ,33,,10",
 "841800,2,3,1998/10/01 07:47:00,139.616848032,35.4377877124,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:47:00,139.6195226421,35.5070782324,1,9,1340109,10,1 ,33,,12",
 "864800,2,4,1998/10/01 07:47:00,139.6600220695,35.5740427856,1,6,1240102,10,1 ,27,,1",
 "869400,2,3,1998/10/01 07:47:00,139.608980994,35.4533761352,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,3,1998/10/01 07:47:00,139.5548614995,35.369004143,1,6,1230404,9 ,1 ,31,,12",
 "2300,2,2,1998/10/01 07:47:00,139.9257470993,35.7217316364,1,6,4111102,8 ,1 ,36,,2",
 "13800,2,3,1998/10/01 07:47:00,139.9609597057,35.7403053692,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:47:00,139.7873854587,35.6961113183,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:47:00,139.86027959,35.6643612354,2,4,4128103,8 ,1 ,42,,12",
 "34500,2,2,1998/10/01 07:47:00,139.9829238652,35.7078234806,1,4,4129003,8 ,1 ,36,,1",
 "71300,2,3,1998/10/01 07:47:00,139.8105742105,35.7564851299,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:47:00,139.9877237383,35.7077255802,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,3,1998/10/01 07:47:00,140.0934007969,35.638334776,1,9,4031206,8 ,1 ,38,,10",
 "172500,2,3,1998/10/01 07:47:00,140.1381716992,35.9302614147,2,4,5024120,13,2 ,44,,12",
 "195500,2,4,1998/10/01 07:47:00,139.786462177,35.6627201569,2,4,5420020,8 ,1 ,37,,1",
 "209300,2,2,1998/10/01 07:47:00,139.6696759781,35.2762109814,1,13,2211303,10,1 ,37,,1",
 "227700,2,3,1998/10/01 07:47:00,139.572264905,35.4442138039,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:47:00,139.7278999031,35.588598437,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:47:00,139.6455440154,35.4957477806,1,11,2922005,9 ,1 ,29,,12",
 "303600,2,3,1998/10/01 07:47:00,139.5089033269,35.6048987111,1,9,2923016,9 ,1 ,30,,12",
 "85100,2,2,1998/10/01 07:47:00,140.2886967524,35.6839758537,1,8,4417027,9 ,10,46,,6",
 "310500,2,2,1998/10/01 07:47:00,139.6918792102,35.5294191879,1,9,2013102,3 ,1 ,37,,2",
 "110400,2,2,1998/10/01 07:47:00,139.9388093124,35.2299005081,1,12,4621121,1 ,11,41,,8",
 "108100,2,2,1998/10/01 07:47:00,140.0289624646,35.4719873946,2,4,4615025,8 ,1 ,43,,6",
 "170200,2,2,1998/10/01 07:47:00,140.2026545604,36.0772511662,2,3,5022220,13,2 ,43,,2",
 "216200,2,2,1998/10/01 07:47:00,139.6770143945,35.2480767442,2,1,2221106,12,2 ,34,,1",
 "262200,2,2,1998/10/01 07:47:00,139.1822535113,35.6183648397,1,9,2713004,3 ,1 ,42,,6",
 "269100,2,2,1998/10/01 07:47:00,139.3543281962,35.4727805282,1,7,2811013,9 ,1 ,37,,3",
 "276000,2,2,1998/10/01 07:47:00,139.2623250996,35.3673891856,1,2,2821003,12,2 ,33,,1",
 "370300,2,3,1998/10/01 07:47:00,139.7433351385,35.7014639316,1,10,3037010,10,1 ,39,,12",
 "315100,4,4,1998/10/01 07:47:00,139.6090650627,35.5024232696,1,11,2020106,10,9 ,39,,8",
 "386400,2,4,1998/10/01 07:47:00,139.7750808588,35.7098349065,1,10,3059002,8 ,1 ,37,,12",
 "404800,2,2,1998/10/01 07:47:00,139.4733986339,35.7865788574,2,9,3120009,8 ,1 ,30,,1",
 "404800,2,3,1998/10/01 07:47:00,139.4733986339,35.7865788574,2,9,3120009,8 ,1 ,30,,12",
 "437000,2,3,1998/10/01 07:47:00,139.801449055,35.7387986635,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,3,1998/10/01 07:47:00,139.7896516569,35.8764436709,2,5,3221102,8 ,1 ,37,,12",
 "411700,2,2,1998/10/01 07:47:00,139.429854996,35.7952981854,2,1,3122110,12,2 ,29,,1",
 "414000,2,2,1998/10/01 07:47:00,139.4555134644,35.7503854977,1,10,3122302,3 ,11,32,,6",
 "441600,4,4,1998/10/01 07:47:00,139.878137346,35.7683453185,1,9,3215009,9 ,11,30,,8",
 "476100,2,3,1998/10/01 07:47:00,139.5388979044,35.8459819263,1,3,3411213,13,2 ,34,,12",
 "483000,2,3,1998/10/01 07:47:00,139.6695159705,35.847454031,1,10,3510101,8 ,1 ,27,,12",
 "462300,2,2,1998/10/01 07:47:00,139.7618419794,35.8988806198,1,8,3235004,9 ,1 ,38,,6",
 "501400,2,2,1998/10/01 07:47:00,139.6605905964,35.9733424622,2,2,3613122,12,2 ,33,,1",
 "508300,2,4,1998/10/01 07:47:00,139.6668298073,36.0163506921,1,9,3622214,8 ,1 ,36,,1",
 "487600,2,2,1998/10/01 07:47:00,139.6295924952,35.9623593235,1,10,3512006,6 ,1 ,36,,6",
 "515200,2,2,1998/10/01 07:47:00,139.1980866029,36.1246665084,1,1,3711123,12,2 ,37,,1",
 "512900,2,2,1998/10/01 07:47:00,139.4446468064,36.102052808,1,11,3632007,5 ,1 ,35,,2",
 "519800,2,2,1998/10/01 07:47:00,139.3006266858,36.1310691846,1,10,3721001,9 ,1 ,44,,6",
 "549700,2,2,1998/10/01 07:47:00,139.7322171006,35.5751081843,1,8,0131010,3 ,1 ,62,,2",
 "563500,2,3,1998/10/01 07:47:00,139.6979331482,35.7263536748,1,7,0212001,10,1 ,84,,12",
 "609500,2,4,1998/10/01 07:47:00,139.6885739021,35.679079152,1,7,0430102,8 ,1 ,51,,1",
 "644000,2,3,1998/10/01 07:47:00,139.7128553612,35.7460926305,1,4,0522008,5 ,1 ,52,,12",
 "646300,2,2,1998/10/01 07:47:00,139.6648868384,35.7883645628,1,4,0523008,8 ,1 ,75,,1",
 "747500,2,3,1998/10/01 07:47:00,139.6346294485,35.5056672597,1,5,0810007,4 ,1 ,38,,12",
 "811900,2,3,1998/10/01 07:47:00,139.5982049203,35.402958999,1,3,1123201,13,2 ,31,,12",
 "713000,2,2,1998/10/01 07:47:00,139.5115920031,35.7612741197,2,2,0741304,12,2 ,30,,1",
 "653200,2,4,1998/10/01 07:47:00,139.7580683099,35.6563786452,1,8,0531203,9 ,1 ,39,,1",
 "648600,2,4,1998/10/01 07:47:00,139.7149492215,35.780454271,2,3,0530007,13,2 ,37,,1",
 "768200,2,2,1998/10/01 07:47:00,139.3470138204,35.6686509787,2,2,0840002,12,2 ,36,,1",
 "786600,2,3,1998/10/01 07:47:00,139.3128978417,35.762529094,2,3,0930110,13,2 ,35,,12",
 "788900,2,2,1998/10/01 07:47:00,139.2789858456,35.8203953006,2,2,0931009,12,2 ,35,,1",
 "782000,2,2,1998/10/01 07:47:00,139.3313208141,35.7228453096,2,2,0911103,12,2 ,36,,1",
 "777400,2,3,1998/10/01 07:47:00,139.6155521969,35.669918608,1,5,0844010,8 ,1 ,41,,12",
 "676200,2,4,1998/10/01 07:47:00,139.7347201016,35.6621249025,1,13,0623211,10,1 ,35,,1",
 "759000,2,3,1998/10/01 07:47:00,139.5240476569,35.633408122,2,4,0821006,9 ,1 ,55,,12",
 "719900,2,2,1998/10/01 07:47:00,139.4471002802,35.6817357044,1,7,0752005,6 ,10,34,,1",
 "683100,2,3,1998/10/01 07:47:00,139.8204114628,35.6891491339,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 07:47:00,139.5979918462,35.6669747015,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,3,1998/10/01 07:48:00,139.5776208858,35.4695089223,2,4,1251203,8 ,1 ,37,,12",
 "839500,2,3,1998/10/01 07:48:00,139.6267343662,35.4768286061,2,11,1133001,14,7 ,27,,12",
 "96600,2,3,1998/10/01 07:48:00,140.0871313655,35.5177099568,1,3,4510101,13,2 ,33,,10",
 "841800,2,3,1998/10/01 07:48:00,139.6230019363,35.4398705786,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:48:00,139.6238396713,35.5082857173,1,9,1340109,10,1 ,33,,12",
 "864800,2,4,1998/10/01 07:48:00,139.6601765384,35.5733815381,1,6,1240102,10,1 ,27,,1",
 "869400,2,3,1998/10/01 07:48:00,139.6133905759,35.4563266234,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,3,1998/10/01 07:48:00,139.5517760346,35.3682591303,1,6,1230404,9 ,1 ,31,,12",
 "2300,2,2,1998/10/01 07:48:00,139.9263078043,35.721492672,1,6,4111102,8 ,1 ,36,,2",
 "13800,2,3,1998/10/01 07:48:00,139.958326963,35.744767758,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:48:00,139.7829004575,35.6937686266,1,9,4120311,8 ,1 ,44,,12",
 "34500,2,2,1998/10/01 07:48:00,139.9805481112,35.7061047283,1,4,4129003,8 ,1 ,36,,1",
 "32200,2,3,1998/10/01 07:48:00,139.8546668382,35.6649732272,2,4,4128103,8 ,1 ,42,,12",
 "71300,2,3,1998/10/01 07:48:00,139.8078142668,35.7534420544,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:48:00,139.9827490124,35.7068851727,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,3,1998/10/01 07:48:00,140.0929559237,35.6381617913,1,9,4031206,8 ,1 ,38,,10",
 "195500,2,4,1998/10/01 07:48:00,139.7873770094,35.661804666,2,4,5420020,8 ,1 ,37,,1",
 "172500,2,3,1998/10/01 07:48:00,140.1351276608,35.9260387871,2,4,5024120,13,2 ,44,,12",
 "209300,2,2,1998/10/01 07:48:00,139.669972647,35.2767678005,1,13,2211303,10,1 ,37,,1",
 "227700,2,3,1998/10/01 07:48:00,139.5792804539,35.4469574,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:48:00,139.7315079453,35.5941766013,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:48:00,139.6465119908,35.4912205339,1,11,2922005,9 ,1 ,29,,12",
 "303600,2,3,1998/10/01 07:48:00,139.5125501992,35.6077183324,1,9,2923016,9 ,1 ,30,,12",
 "85100,2,2,1998/10/01 07:48:00,140.285797462,35.686389412,1,8,4417027,9 ,10,46,,6",
 "310500,2,2,1998/10/01 07:48:00,139.6921102802,35.5306557843,1,9,2013102,3 ,1 ,37,,2",
 "108100,2,2,1998/10/01 07:48:00,140.0322399031,35.4767597736,2,4,4615025,8 ,1 ,43,,6",
 "110400,2,2,1998/10/01 07:48:00,139.9421358749,35.2318400456,1,12,4621121,1 ,11,41,,8",
 "170200,2,2,1998/10/01 07:48:00,140.2031049766,36.0782487122,2,3,5022220,13,2 ,43,,2",
 "216200,2,2,1998/10/01 07:48:00,139.6767824676,35.2479587172,2,1,2221106,12,2 ,34,,1",
 "262200,2,2,1998/10/01 07:48:00,139.1847649653,35.618407033,1,9,2713004,3 ,1 ,42,,6",
 "269100,2,2,1998/10/01 07:48:00,139.3529373553,35.4757060126,1,7,2811013,9 ,1 ,37,,3",
 "276000,2,2,1998/10/01 07:48:00,139.2629152105,35.3676098224,1,2,2821003,12,2 ,33,,1",
 "370300,2,3,1998/10/01 07:48:00,139.7409074796,35.6980516537,1,10,3037010,10,1 ,39,,12",
 "315100,4,4,1998/10/01 07:48:00,139.609712575,35.5030901994,1,11,2020106,10,9 ,39,,8",
 "386400,2,4,1998/10/01 07:48:00,139.7745369449,35.7058987683,1,10,3059002,8 ,1 ,37,,12",
 "404800,2,3,1998/10/01 07:48:00,139.4727794228,35.7824101589,2,9,3120009,8 ,1 ,30,,12",
 "437000,2,3,1998/10/01 07:48:00,139.7997378637,35.7342403187,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,3,1998/10/01 07:48:00,139.7902920984,35.8735931838,2,5,3221102,8 ,1 ,37,,12",
 "411700,2,2,1998/10/01 07:48:00,139.4293839296,35.7947538255,2,1,3122110,12,2 ,29,,1",
 "414000,2,2,1998/10/01 07:48:00,139.4575610204,35.7495409567,1,10,3122302,3 ,11,32,,6",
 "441600,4,4,1998/10/01 07:48:00,139.8757909982,35.7684223165,1,9,3215009,9 ,11,30,,8",
 "476100,2,3,1998/10/01 07:48:00,139.5449709316,35.8420195623,1,3,3411213,13,2 ,34,,12",
 "483000,2,3,1998/10/01 07:48:00,139.6725621552,35.8448417657,1,10,3510101,8 ,1 ,27,,12",
 "462300,2,2,1998/10/01 07:48:00,139.7588472276,35.897399788,1,8,3235004,9 ,1 ,38,,6",
 "501400,2,2,1998/10/01 07:48:00,139.6601443767,35.9735821768,2,2,3613122,12,2 ,33,,1",
 "487600,2,2,1998/10/01 07:48:00,139.6278262134,35.9610434713,1,10,3512006,6 ,1 ,36,,6",
 "508300,2,4,1998/10/01 07:48:00,139.6668360481,36.0155688226,1,9,3622214,8 ,1 ,36,,1",
 "512900,2,2,1998/10/01 07:48:00,139.4457019276,36.1023856696,1,11,3632007,5 ,1 ,35,,2",
 "515200,2,2,1998/10/01 07:48:00,139.1966353566,36.1238700656,1,1,3711123,12,2 ,37,,1",
 "519800,2,2,1998/10/01 07:48:00,139.296934038,36.1277884571,1,10,3721001,9 ,1 ,44,,6",
 "549700,2,2,1998/10/01 07:48:00,139.7327068179,35.5749638537,1,8,0131010,3 ,1 ,62,,2",
 "563500,2,3,1998/10/01 07:48:00,139.7015385707,35.7263930224,1,7,0212001,10,1 ,84,,12",
 "609500,2,4,1998/10/01 07:48:00,139.6887066287,35.6786594681,1,7,0430102,8 ,1 ,51,,1",
 "644000,2,3,1998/10/01 07:48:00,139.7165873487,35.7442021914,1,4,0522008,5 ,1 ,52,,12",
 "646300,2,2,1998/10/01 07:48:00,139.6644170478,35.7887660247,1,4,0523008,8 ,1 ,75,,1",
 "747500,2,3,1998/10/01 07:48:00,139.6337294429,35.5002065196,1,5,0810007,4 ,1 ,38,,12",
 "811900,2,3,1998/10/01 07:48:00,139.600804094,35.4002317283,1,3,1123201,13,2 ,31,,12",
 "713000,2,2,1998/10/01 07:48:00,139.5105991747,35.7616950599,2,2,0741304,12,2 ,30,,1",
 "648600,2,4,1998/10/01 07:48:00,139.7143541603,35.7800085795,2,3,0530007,13,2 ,37,,1",
 "653200,2,4,1998/10/01 07:48:00,139.7603677347,35.6554631148,1,8,0531203,9 ,1 ,39,,1",
 "768200,2,2,1998/10/01 07:48:00,139.3461959994,35.6686427703,2,2,0840002,12,2 ,36,,1",
 "777400,2,3,1998/10/01 07:48:00,139.6198811231,35.6693611402,1,5,0844010,8 ,1 ,41,,12",
 "788900,2,2,1998/10/01 07:48:00,139.27815718,35.8200483191,2,2,0931009,12,2 ,35,,1",
 "786600,2,3,1998/10/01 07:48:00,139.315443174,35.7591052194,2,3,0930110,13,2 ,35,,12",
 "782000,2,2,1998/10/01 07:48:00,139.3312903389,35.7231041365,2,2,0911103,12,2 ,36,,1",
 "676200,2,4,1998/10/01 07:48:00,139.735635519,35.6618420554,1,13,0623211,10,1 ,35,,1",
 "759000,2,3,1998/10/01 07:48:00,139.529547119,35.6335629268,2,4,0821006,9 ,1 ,55,,12",
 "719900,2,2,1998/10/01 07:48:00,139.4471003953,35.6817364407,1,7,0752005,6 ,10,34,,1",
 "719900,2,3,1998/10/01 07:48:00,139.4471003953,35.6817364407,1,7,0752005,6 ,10,34,,10",
 "683100,2,3,1998/10/01 07:48:00,139.8169378224,35.6890090474,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 07:48:00,139.6025030031,35.6686223327,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,3,1998/10/01 07:49:00,139.5864393035,35.4636899008,2,4,1251203,8 ,1 ,37,,12",
 "839500,2,3,1998/10/01 07:49:00,139.6299574766,35.4805491135,2,11,1133001,14,7 ,27,,12",
 "96600,2,3,1998/10/01 07:49:00,140.0871316416,35.5177105027,1,3,4510101,13,2 ,33,,10",
 "841800,2,3,1998/10/01 07:49:00,139.6269014166,35.4440737458,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:49:00,139.6281254346,35.5096012895,1,9,1340109,10,1 ,33,,12",
 "864800,2,4,1998/10/01 07:49:00,139.6603310048,35.5727202902,1,6,1240102,10,1 ,27,,1",
 "869400,2,3,1998/10/01 07:49:00,139.6168731854,35.4600458505,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,3,1998/10/01 07:49:00,139.5486806368,35.3675425822,1,6,1230404,9 ,1 ,31,,12",
 "2300,2,2,1998/10/01 07:49:00,139.9268690487,35.7212545456,1,6,4111102,8 ,1 ,36,,2",
 "13800,2,3,1998/10/01 07:49:00,139.9557100695,35.7492363244,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:49:00,139.7806616003,35.6939338979,1,9,4120311,8 ,1 ,44,,12",
 "34500,2,2,1998/10/01 07:49:00,139.9774928314,35.7043636795,1,4,4129003,8 ,1 ,36,,1",
 "32200,2,3,1998/10/01 07:49:00,139.848989665,35.6654249172,2,4,4128103,8 ,1 ,42,,12",
 "71300,2,3,1998/10/01 07:49:00,139.8054808469,35.750172397,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:49:00,139.9782458787,35.7049517542,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,3,1998/10/01 07:49:00,140.0930329621,35.6376804247,1,9,4031206,8 ,1 ,38,,10",
 "172500,2,3,1998/10/01 07:49:00,140.1300641706,35.9232599287,2,4,5024120,13,2 ,44,,12",
 "195500,2,4,1998/10/01 07:49:00,139.7875315844,35.6606368878,2,4,5420020,8 ,1 ,37,,1",
 "209300,2,2,1998/10/01 07:49:00,139.6700022097,35.2774308867,1,13,2211303,10,1 ,37,,1",
 "227700,2,3,1998/10/01 07:49:00,139.5870798038,35.4459396374,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:49:00,139.7341400189,35.6000316306,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:49:00,139.6469506064,35.486691271,1,11,2922005,9 ,1 ,29,,12",
 "303600,2,3,1998/10/01 07:49:00,139.5171064882,35.6094649659,1,9,2923016,9 ,1 ,30,,12",
 "85100,2,2,1998/10/01 07:49:00,140.2827713461,35.6898240842,1,8,4417027,9 ,10,46,,6",
 "310500,2,2,1998/10/01 07:49:00,139.6937146489,35.5311815463,1,9,2013102,3 ,1 ,37,,2",
 "110400,2,2,1998/10/01 07:49:00,139.9469258973,35.2328828475,1,12,4621121,1 ,11,41,,8",
 "108100,2,2,1998/10/01 07:49:00,140.0335353758,35.4814499764,2,4,4615025,8 ,1 ,43,,6",
 "170200,2,2,1998/10/01 07:49:00,140.2035686666,36.0792139797,2,3,5022220,13,2 ,43,,2",
 "216200,2,2,1998/10/01 07:49:00,139.6768824835,35.2477375853,2,1,2221106,12,2 ,34,,1",
 "262200,2,2,1998/10/01 07:49:00,139.1845274279,35.6213123446,1,9,2713004,3 ,1 ,42,,6",
 "269100,2,2,1998/10/01 07:49:00,139.351454138,35.4785530995,1,7,2811013,9 ,1 ,37,,3",
 "276000,2,2,1998/10/01 07:49:00,139.2634933553,35.3678498808,1,2,2821003,12,2 ,33,,1",
 "370300,2,3,1998/10/01 07:49:00,139.7383836785,35.6946851499,1,10,3037010,10,1 ,39,,12",
 "315100,4,4,1998/10/01 07:49:00,139.6104143587,35.503717515,1,11,2020106,10,9 ,39,,8",
 "386400,2,4,1998/10/01 07:49:00,139.7734903232,35.7017626295,1,10,3059002,8 ,1 ,37,,12",
 "404800,2,3,1998/10/01 07:49:00,139.4730459726,35.7780469304,2,9,3120009,8 ,1 ,30,,12",
 "437000,2,3,1998/10/01 07:49:00,139.796559117,35.7306974753,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,3,1998/10/01 07:49:00,139.7909325177,35.8707426956,2,5,3221102,8 ,1 ,37,,12",
 "414000,2,2,1998/10/01 07:49:00,139.4595904456,35.7486668468,1,10,3122302,3 ,11,32,,6",
 "411700,2,2,1998/10/01 07:49:00,139.4289122325,35.7942098102,2,1,3122110,12,2 ,29,,1",
 "441600,4,4,1998/10/01 07:49:00,139.8735621714,35.7679800051,1,9,3215009,9 ,11,30,,8",
 "476100,2,3,1998/10/01 07:49:00,139.5510245151,35.8380378044,1,3,3411213,13,2 ,34,,12",
 "483000,2,3,1998/10/01 07:49:00,139.6756088946,35.842230316,1,10,3510101,8 ,1 ,27,,12",
 "462300,2,2,1998/10/01 07:49:00,139.7578232067,35.8946289596,1,8,3235004,9 ,1 ,38,,6",
 "501400,2,2,1998/10/01 07:49:00,139.6596646889,35.9736326935,2,2,3613122,12,2 ,33,,1",
 "508300,2,4,1998/10/01 07:49:00,139.6661129207,36.0157686349,1,9,3622214,8 ,1 ,36,,1",
 "487600,2,2,1998/10/01 07:49:00,139.6259673275,35.959774069,1,10,3512006,6 ,1 ,36,,6",
 "512900,2,2,1998/10/01 07:49:00,139.4467569473,36.1027187071,1,11,3632007,5 ,1 ,35,,2",
 "515200,2,2,1998/10/01 07:49:00,139.1951877983,36.1230692447,1,1,3711123,12,2 ,37,,1",
 "519800,2,2,1998/10/01 07:49:00,139.2933215412,36.1250247947,1,10,3721001,9 ,1 ,44,,6",
 "563500,2,3,1998/10/01 07:49:00,139.7048973344,35.7251955028,1,7,0212001,10,1 ,84,,12",
 "549700,2,2,1998/10/01 07:49:00,139.733369687,35.5749508422,1,8,0131010,3 ,1 ,62,,2",
 "609500,2,4,1998/10/01 07:49:00,139.6890475227,35.6781899387,1,7,0430102,8 ,1 ,51,,1",
 "646300,2,2,1998/10/01 07:49:00,139.6639851465,35.7886477632,1,4,0523008,8 ,1 ,75,,1",
 "644000,2,3,1998/10/01 07:49:00,139.7165573289,35.7406914278,1,4,0522008,5 ,1 ,52,,12",
 "747500,2,3,1998/10/01 07:49:00,139.6328280962,35.4947684142,1,5,0810007,4 ,1 ,38,,12",
 "713000,2,2,1998/10/01 07:49:00,139.5101494957,35.7614770069,2,2,0741304,12,2 ,30,,1",
 "811900,2,3,1998/10/01 07:49:00,139.6033106749,35.3974107126,1,3,1123201,13,2 ,31,,12",
 "648600,2,4,1998/10/01 07:49:00,139.7138749405,35.7794441341,2,3,0530007,13,2 ,37,,1",
 "653200,2,4,1998/10/01 07:49:00,139.7606555047,35.6532004001,1,8,0531203,9 ,1 ,39,,1",
 "768200,2,2,1998/10/01 07:49:00,139.3453782272,35.668635487,2,2,0840002,12,2 ,36,,1",
 "777400,2,3,1998/10/01 07:49:00,139.6241861015,35.6686989064,1,5,0844010,8 ,1 ,41,,12",
 "788900,2,2,1998/10/01 07:49:00,139.2773285217,35.8197013319,2,2,0931009,12,2 ,35,,1",
 "786600,2,3,1998/10/01 07:49:00,139.3179807946,35.7556776469,2,3,0930110,13,2 ,35,,12",
 "782000,2,2,1998/10/01 07:49:00,139.3310763343,35.7232962609,2,2,0911103,12,2 ,36,,1",
 "676200,2,4,1998/10/01 07:49:00,139.7365514302,35.6615615887,1,13,0623211,10,1 ,35,,1",
 "759000,2,3,1998/10/01 07:49:00,139.5339725185,35.6358575134,2,4,0821006,9 ,1 ,55,,12",
 "719900,2,3,1998/10/01 07:49:00,139.4471972567,35.6835535724,1,7,0752005,6 ,10,34,,10",
 "692300,2,3,1998/10/01 07:49:00,139.6070541796,35.6701932429,2,4,0711108,8 ,1 ,25,,12",
 "683100,2,3,1998/10/01 07:49:00,139.8134798566,35.6887850952,2,3,0633106,12,2 ,49,,12",
 "830300,2,3,1998/10/01 07:50:00,139.5952505758,35.4581996096,2,4,1251203,8 ,1 ,37,,12",
 "96600,2,3,1998/10/01 07:50:00,140.0871319177,35.5177110487,1,3,4510101,13,2 ,33,,10",
 "839500,2,3,1998/10/01 07:50:00,139.6288792157,35.4850623481,2,11,1133001,14,7 ,27,,12",
 "841800,2,3,1998/10/01 07:50:00,139.6238215599,35.4486663018,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:50:00,139.632675875,35.5092882139,1,9,1340109,10,1 ,33,,12",
 "864800,2,4,1998/10/01 07:50:00,139.6604854687,35.5720590421,1,6,1240102,10,1 ,27,,1",
 "864800,3,5,1998/10/01 07:50:00,139.6604854687,35.5720590421,1,6,1240102,10,97,27,,97",
 "871700,2,3,1998/10/01 07:50:00,139.5455472917,35.3670240946,1,6,1230404,9 ,1 ,31,,12",
 "869400,2,3,1998/10/01 07:50:00,139.6203648924,35.4637614804,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,2,1998/10/01 07:50:00,139.9271680778,35.7209308067,1,6,4111102,8 ,1 ,36,,2",
 "2300,2,3,1998/10/01 07:50:00,139.9271680778,35.7209308067,1,6,4111102,8 ,1 ,36,,12",
 "13800,2,3,1998/10/01 07:50:00,139.9527409917,35.7535525878,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:50:00,139.7759275537,35.6955621774,1,9,4120311,8 ,1 ,44,,12",
 "32200,2,3,1998/10/01 07:50:00,139.8433129917,35.6658786971,2,4,4128103,8 ,1 ,42,,12",
 "34500,2,2,1998/10/01 07:50:00,139.974424265,35.7029027147,1,4,4129003,8 ,1 ,36,,1",
 "71300,2,3,1998/10/01 07:50:00,139.8056646202,35.7465062066,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:50:00,139.9735037575,35.7034647079,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,3,1998/10/01 07:50:00,140.0925731214,35.6374133555,1,9,4031206,8 ,1 ,38,,10",
 "151800,2,4,1998/10/01 07:50:00,140.0925731214,35.6374133555,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 07:50:00,140.1241821634,35.9218507493,2,4,5024120,13,2 ,44,,12",
 "195500,2,4,1998/10/01 07:50:00,139.7876530456,35.6594584466,2,4,5420020,8 ,1 ,37,,1",
 "195500,3,5,1998/10/01 07:50:00,139.7876530456,35.6594584466,2,4,5420020,8 ,97,37,,97",
 "209300,2,2,1998/10/01 07:50:00,139.6700317729,35.2780939729,1,13,2211303,10,1 ,37,,1",
 "227700,2,3,1998/10/01 07:50:00,139.5948736709,35.4448769279,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:50:00,139.7349243473,35.6063078795,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:50:00,139.6426649616,35.4840666345,1,11,2922005,9 ,1 ,29,,12",
 "85100,2,2,1998/10/01 07:50:00,140.2797405657,35.6932562722,1,8,4417027,9 ,10,46,,6",
 "303600,2,3,1998/10/01 07:50:00,139.5210825864,35.6119109459,1,9,2923016,9 ,1 ,30,,12",
 "310500,2,2,1998/10/01 07:50:00,139.6952983198,35.5314417006,1,9,2013102,3 ,1 ,37,,2",
 "108100,2,2,1998/10/01 07:50:00,140.02972302,35.4863961113,2,4,4615025,8 ,1 ,43,,6",
 "108100,3,3,1998/10/01 07:50:00,140.02972302,35.4863961113,2,4,4615025,8 ,97,43,,97",
 "110400,2,2,1998/10/01 07:50:00,139.9517922424,35.2347047437,1,12,4621121,1 ,11,41,,8",
 "110400,3,3,1998/10/01 07:50:00,139.9517922424,35.2347047437,1,12,4621121,1 ,97,41,,97",
 "170200,2,2,1998/10/01 07:50:00,140.2027823473,36.0800479519,2,3,5022220,13,2 ,43,,2",
 "216200,2,2,1998/10/01 07:50:00,139.6769824988,35.2475164533,2,1,2221106,12,2 ,34,,1",
 "262200,2,2,1998/10/01 07:50:00,139.1847759954,35.6246213147,1,9,2713004,3 ,1 ,42,,6",
 "262200,3,3,1998/10/01 07:50:00,139.1847759954,35.6246213147,1,9,2713004,3 ,97,42,,97",
 "269100,2,2,1998/10/01 07:50:00,139.3514025859,35.4816366475,1,7,2811013,9 ,1 ,37,,3",
 "276000,2,2,1998/10/01 07:50:00,139.2635593064,35.3682771593,1,2,2821003,12,2 ,33,,1",
 "370300,2,3,1998/10/01 07:50:00,139.7359351211,35.6915687753,1,10,3037010,10,1 ,39,,12",
 "370300,2,4,1998/10/01 07:50:00,139.7359351211,35.6915687753,1,10,3037010,10,1 ,39,,1",
 "315100,4,4,1998/10/01 07:50:00,139.6108060748,35.5042575791,1,11,2020106,10,9 ,39,,8",
 "315100,5,5,1998/10/01 07:50:00,139.6108060748,35.5042575791,1,11,2020106,10,97,39,,97",
 "386400,2,4,1998/10/01 07:50:00,139.7729432408,35.6975445844,1,10,3059002,8 ,1 ,37,,12",
 "404800,2,3,1998/10/01 07:50:00,139.4726383964,35.7737413644,2,9,3120009,8 ,1 ,30,,12",
 "437000,2,3,1998/10/01 07:50:00,139.7913068556,35.7294239135,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,3,1998/10/01 07:50:00,139.7915728911,35.8678922025,2,5,3221102,8 ,1 ,37,,12",
 "411700,2,2,1998/10/01 07:50:00,139.4284405419,35.793665793,2,1,3122110,12,2 ,29,,1",
 "414000,2,2,1998/10/01 07:50:00,139.4616198267,35.7477927021,1,10,3122302,3 ,11,32,,6",
 "441600,4,4,1998/10/01 07:50:00,139.8715018373,35.7670352232,1,9,3215009,9 ,11,30,,8",
 "476100,2,3,1998/10/01 07:50:00,139.557070313,35.8340485701,1,3,3411213,13,2 ,34,,12",
 "483000,2,3,1998/10/01 07:50:00,139.6785112755,35.8395114811,1,10,3510101,8 ,1 ,27,,12",
 "462300,2,2,1998/10/01 07:50:00,139.7562869725,35.8923329046,1,8,3235004,9 ,1 ,38,,6",
 "487600,2,2,1998/10/01 07:50:00,139.6240471274,35.9585731638,1,10,3512006,6 ,1 ,36,,6",
 "508300,2,4,1998/10/01 07:50:00,139.6665211321,36.0153717323,1,9,3622214,8 ,1 ,36,,1",
 "501400,2,2,1998/10/01 07:50:00,139.6591952763,35.9734256742,2,2,3613122,12,2 ,33,,1",
 "515200,2,2,1998/10/01 07:50:00,139.1937387489,36.1222702268,1,1,3711123,12,2 ,37,,1",
 "512900,2,2,1998/10/01 07:50:00,139.4473124552,36.1034901939,1,11,3632007,5 ,1 ,35,,2",
 "519800,2,2,1998/10/01 07:50:00,139.2927258139,36.1214050397,1,10,3721001,9 ,1 ,44,,6",
 "519800,3,3,1998/10/01 07:50:00,139.2927258139,36.1214050397,1,10,3721001,9 ,97,44,,97",
 "549700,2,2,1998/10/01 07:50:00,139.7335824673,35.5744188455,1,8,0131010,3 ,1 ,62,,2",
 "563500,2,3,1998/10/01 07:50:00,139.7083332314,35.724300078,1,7,0212001,10,1 ,84,,12",
 "609500,2,4,1998/10/01 07:50:00,139.6894583027,35.6777312217,1,7,0430102,8 ,1 ,51,,1",
 "653200,2,4,1998/10/01 07:50:00,139.7632742504,35.6519374986,1,8,0531203,9 ,1 ,39,,1",
 "653200,3,5,1998/10/01 07:50:00,139.7632742504,35.6519374986,1,8,0531203,9 ,97,39,,97",
 "646300,2,2,1998/10/01 07:50:00,139.6633195932,35.7887644841,1,4,0523008,8 ,1 ,75,,1",
 "644000,2,3,1998/10/01 07:50:00,139.7148313871,35.7372558327,1,4,0522008,5 ,1 ,52,,12",
 "648600,2,4,1998/10/01 07:50:00,139.7133957275,35.7788796867,2,3,0530007,13,2 ,37,,1",
 "713000,2,2,1998/10/01 07:50:00,139.5103352742,35.7605775141,2,2,0741304,12,2 ,30,,1",
 "747500,2,3,1998/10/01 07:50:00,139.6286247258,35.490462833,1,5,0810007,4 ,1 ,38,,12",
 "811900,2,3,1998/10/01 07:50:00,139.607370522,35.3963413562,1,3,1123201,13,2 ,31,,12",
 "768200,2,2,1998/10/01 07:50:00,139.3445603994,35.6686319734,2,2,0840002,12,2 ,36,,1",
 "782000,2,2,1998/10/01 07:50:00,139.3307838823,35.7235293698,2,2,0911103,12,2 ,36,,1",
 "777400,2,3,1998/10/01 07:50:00,139.6285031179,35.6680847933,1,5,0844010,8 ,1 ,41,,12",
 "786600,2,3,1998/10/01 07:50:00,139.320526694,35.7522541595,2,3,0930110,13,2 ,35,,12",
 "788900,2,2,1998/10/01 07:50:00,139.2764998706,35.8193543391,2,2,0931009,12,2 ,35,,1",
 "683100,2,3,1998/10/01 07:50:00,139.810005984,35.6886590155,2,3,0633106,12,2 ,49,,12",
 "676200,2,4,1998/10/01 07:50:00,139.736930871,35.661895162,1,13,0623211,10,1 ,35,,1",
 "676200,3,5,1998/10/01 07:50:00,139.736930871,35.661895162,1,13,0623211,10,97,35,,97",
 "759000,2,3,1998/10/01 07:50:00,139.5349750215,35.6402534132,2,4,0821006,9 ,1 ,55,,12",
 "719900,2,3,1998/10/01 07:50:00,139.4472270223,35.6853869298,1,7,0752005,6 ,10,34,,10",
 "692300,2,3,1998/10/01 07:50:00,139.611868173,35.6704922844,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,3,1998/10/01 07:51:00,139.6046079957,35.4533232361,2,4,1251203,8 ,1 ,37,,12",
 "839500,2,3,1998/10/01 07:51:00,139.6278132876,35.4895093904,2,11,1133001,14,7 ,27,,12",
 "839500,2,4,1998/10/01 07:51:00,139.6278132876,35.4895093904,2,11,1133001,14,7 ,27,,1",
 "96600,2,3,1998/10/01 07:51:00,140.0871321938,35.5177115946,1,3,4510101,13,2 ,33,,10",
 "841800,2,3,1998/10/01 07:51:00,139.6207427128,35.4533733151,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:51:00,139.6367629003,35.5075524353,1,9,1340109,10,1 ,33,,12",
 "869400,2,3,1998/10/01 07:51:00,139.6237105929,35.4675649374,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,3,1998/10/01 07:51:00,139.5424389972,35.367666947,1,6,1230404,9 ,1 ,31,,12",
 "2300,2,3,1998/10/01 07:51:00,139.9231690236,35.7226834454,1,6,4111102,8 ,1 ,36,,12",
 "13800,2,3,1998/10/01 07:51:00,139.949436424,35.7577061971,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:51:00,139.7705160465,35.6957984061,1,9,4120311,8 ,1 ,44,,12",
 "434700,4,4,1998/10/01 07:51:00,139.8098412122,35.8119261351,2,8,3210107,8 ,1 ,37,,7",
 "34500,2,2,1998/10/01 07:51:00,139.9709025639,35.7028590027,1,4,4129003,8 ,1 ,36,,1",
 "32200,2,3,1998/10/01 07:51:00,139.8376872614,35.6666462987,2,4,4128103,8 ,1 ,42,,12",
 "41400,2,2,1998/10/01 07:51:00,139.9132395446,35.8261722854,2,5,4212212,8 ,1 ,44,,1",
 "593400,2,2,1998/10/01 07:51:00,139.8191681394,35.683508518,1,6,0349009,9 ,1 ,58,,1",
 "71300,2,3,1998/10/01 07:51:00,139.8058769235,35.7430276463,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:51:00,139.9684535479,35.7036181554,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 07:51:00,140.0891559538,35.6422281147,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 07:51:00,140.1182425199,35.9205957976,2,4,5024120,13,2 ,44,,12",
 "209300,2,2,1998/10/01 07:51:00,139.6700613366,35.278757059,1,13,2211303,10,1 ,37,,1",
 "209300,2,3,1998/10/01 07:51:00,139.6700613366,35.278757059,1,13,2211303,10,1 ,37,,12",
 "227700,2,3,1998/10/01 07:51:00,139.601225408,35.448077004,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:51:00,139.7357086921,35.6125841256,1,14,2412116,10,1 ,63,,12",
 "303600,2,3,1998/10/01 07:51:00,139.5246601564,35.614702023,1,9,2923016,9 ,1 ,30,,12",
 "301300,2,3,1998/10/01 07:51:00,139.6375269018,35.4822651504,1,11,2922005,9 ,1 ,29,,12",
 "85100,2,2,1998/10/01 07:51:00,140.2782148783,35.6969118468,1,8,4417027,9 ,10,46,,6",
 "310500,2,2,1998/10/01 07:51:00,139.6968624888,35.5314219481,1,9,2013102,3 ,1 ,37,,2",
 "310500,2,3,1998/10/01 07:51:00,139.6968624888,35.5314219481,1,9,2013102,3 ,1 ,37,,12",
 "170200,2,2,1998/10/01 07:51:00,140.2020710726,36.0809265644,2,3,5022220,13,2 ,43,,2",
 "216200,2,2,1998/10/01 07:51:00,139.6770825135,35.2472953212,2,1,2221106,12,2 ,34,,1",
 "269100,2,2,1998/10/01 07:51:00,139.3502597388,35.4842146019,1,7,2811013,9 ,1 ,37,,3",
 "276000,2,2,1998/10/01 07:51:00,139.2640976567,35.368573596,1,2,2821003,12,2 ,33,,1",
 "285200,2,2,1998/10/01 07:51:00,139.4517300363,35.503279041,1,2,2911313,12,2 ,34,,1",
 "370300,2,4,1998/10/01 07:51:00,139.7365724959,35.6912371476,1,10,3037010,10,1 ,39,,1",
 "386400,2,4,1998/10/01 07:51:00,139.7716332506,35.6934740978,1,10,3059002,8 ,1 ,37,,12",
 "404800,2,3,1998/10/01 07:51:00,139.4702935328,35.7698138167,2,9,3120009,8 ,1 ,30,,12",
 "437000,2,3,1998/10/01 07:51:00,139.7881212023,35.7254307975,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,3,1998/10/01 07:51:00,139.7921957317,35.8650391442,2,5,3221102,8 ,1 ,37,,12",
 "414000,2,2,1998/10/01 07:51:00,139.4636639419,35.7469418883,1,10,3122302,3 ,11,32,,6",
 "411700,2,2,1998/10/01 07:51:00,139.4282013855,35.7930506551,2,1,3122110,12,2 ,29,,1",
 "476100,2,3,1998/10/01 07:51:00,139.5631227593,35.8300664228,1,3,3411213,13,2 ,34,,12",
 "441600,4,4,1998/10/01 07:51:00,139.8694776183,35.7660410076,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 07:51:00,139.681381735,35.8367714064,1,10,3510101,8 ,1 ,27,,12",
 "462300,2,2,1998/10/01 07:51:00,139.7542511769,35.8908205849,1,8,3235004,9 ,1 ,38,,6",
 "501400,2,2,1998/10/01 07:51:00,139.6587332302,35.973206667,2,2,3613122,12,2 ,33,,1",
 "487600,2,2,1998/10/01 07:51:00,139.6217908116,35.957849116,1,10,3512006,6 ,1 ,36,,6",
 "508300,2,4,1998/10/01 07:51:00,139.666905395,36.0147079704,1,9,3622214,8 ,1 ,36,,1",
 "515200,2,2,1998/10/01 07:51:00,139.1922937338,36.1214664649,1,1,3711123,12,2 ,37,,1",
 "512900,2,2,1998/10/01 07:51:00,139.4480291715,36.1036628803,1,11,3632007,5 ,1 ,35,,2",
 "549700,2,2,1998/10/01 07:51:00,139.7340128248,35.5741316699,1,8,0131010,3 ,1 ,62,,2",
 "563500,2,3,1998/10/01 07:51:00,139.7103578974,35.7265296115,1,7,0212001,10,1 ,84,,12",
 "609500,2,4,1998/10/01 07:51:00,139.6896118724,35.6772150942,1,7,0430102,8 ,1 ,51,,1",
 "646300,2,2,1998/10/01 07:51:00,139.6626578756,35.7888946028,1,4,0523008,8 ,1 ,75,,1",
 "644000,2,3,1998/10/01 07:51:00,139.7129774644,35.7338721559,1,4,0522008,5 ,1 ,52,,12",
 "747500,2,3,1998/10/01 07:51:00,139.6288149138,35.4852312715,1,5,0810007,4 ,1 ,38,,12",
 "713000,2,2,1998/10/01 07:51:00,139.5106584602,35.7597084202,2,2,0741304,12,2 ,30,,1",
 "811900,2,3,1998/10/01 07:51:00,139.6105305814,35.3940421913,1,3,1123201,13,2 ,31,,12",
 "648600,2,4,1998/10/01 07:51:00,139.7129165213,35.7783152373,2,3,0530007,13,2 ,37,,1",
 "768200,2,2,1998/10/01 07:51:00,139.3441616516,35.6691222176,2,2,0840002,12,2 ,36,,1",
 "786600,2,3,1998/10/01 07:51:00,139.3230617895,35.7488253936,2,3,0930110,13,2 ,35,,12",
 "788900,2,2,1998/10/01 07:51:00,139.2756712268,35.8190073405,2,2,0931009,12,2 ,35,,1",
 "782000,2,2,1998/10/01 07:51:00,139.3304914286,35.723762478,2,2,0911103,12,2 ,36,,1",
 "777400,2,3,1998/10/01 07:51:00,139.6327570728,35.6674531337,1,5,0844010,8 ,1 ,41,,12",
 "821100,2,2,1998/10/01 07:51:00,139.5694049576,35.444167259,2,1,1213204,12,2 ,38,,1",
 "759000,2,3,1998/10/01 07:51:00,139.5366155882,35.6444491256,2,4,0821006,9 ,1 ,55,,12",
 "696900,2,2,1998/10/01 07:51:00,139.5942452419,35.68255308,2,6,0720006,4 ,1 ,39,,2",
 "719900,2,3,1998/10/01 07:51:00,139.4471595612,35.6872222037,1,7,0752005,6 ,10,34,,10",
 "683100,2,3,1998/10/01 07:51:00,139.8065396958,35.6884277819,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 07:51:00,139.6167291762,35.6697641067,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,3,1998/10/01 07:52:00,139.6139989113,35.4571747888,2,4,1251203,8 ,1 ,37,,12",
 "839500,2,4,1998/10/01 07:52:00,139.6281856263,35.4887192207,2,11,1133001,14,7 ,27,,1",
 "96600,2,3,1998/10/01 07:52:00,140.0871324699,35.5177121406,1,3,4510101,13,2 ,33,,10",
 "841800,2,3,1998/10/01 07:52:00,139.6187055973,35.4585596958,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:52:00,139.6407018344,35.5056178248,1,9,1340109,10,1 ,33,,12",
 "869400,2,3,1998/10/01 07:52:00,139.6269927659,35.4714064597,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,3,1998/10/01 07:52:00,139.5393711472,35.3684591286,1,6,1230404,9 ,1 ,31,,12",
 "2300,2,3,1998/10/01 07:52:00,139.9191036456,35.724414635,1,6,4111102,8 ,1 ,36,,12",
 "13800,2,3,1998/10/01 07:52:00,139.9467439592,35.762102661,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:52:00,139.7654100585,35.6947047836,1,9,4120311,8 ,1 ,44,,12",
 "434700,4,4,1998/10/01 07:52:00,139.8074715243,35.8113102866,2,8,3210107,8 ,1 ,37,,7",
 "34500,2,2,1998/10/01 07:52:00,139.9674591554,35.703395722,1,4,4129003,8 ,1 ,36,,1",
 "32200,2,3,1998/10/01 07:52:00,139.8323172357,35.6681987106,2,4,4128103,8 ,1 ,42,,12",
 "41400,2,2,1998/10/01 07:52:00,139.9132398283,35.8261721877,2,5,4212212,8 ,1 ,44,,1",
 "593400,2,2,1998/10/01 07:52:00,139.8191678755,35.68350877,1,6,0349009,9 ,1 ,58,,1",
 "71300,2,3,1998/10/01 07:52:00,139.8013953125,35.7421548963,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:52:00,139.9637046528,35.7050769456,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 07:52:00,140.0857340082,35.6470818854,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 07:52:00,140.112297573,35.9193575749,2,4,5024120,13,2 ,44,,12",
 "209300,2,3,1998/10/01 07:52:00,139.6629896434,35.2803415942,1,13,2211303,10,1 ,37,,12",
 "227700,2,3,1998/10/01 07:52:00,139.6071026967,35.4523375428,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:52:00,139.7371525293,35.618778557,1,14,2412116,10,1 ,63,,12",
 "303600,2,3,1998/10/01 07:52:00,139.5296420147,35.6145843821,1,9,2923016,9 ,1 ,30,,12",
 "301300,2,3,1998/10/01 07:52:00,139.6339760645,35.4787186467,1,11,2922005,9 ,1 ,29,,12",
 "85100,2,2,1998/10/01 07:52:00,140.2771124694,35.7004458599,1,8,4417027,9 ,10,46,,6",
 "310500,2,3,1998/10/01 07:52:00,139.689937317,35.5254633042,1,9,2013102,3 ,1 ,37,,12",
 "170200,2,2,1998/10/01 07:52:00,140.2012282606,36.0817381044,2,3,5022220,13,2 ,43,,2",
 "216200,2,2,1998/10/01 07:52:00,139.6771825277,35.2470741891,2,1,2221106,12,2 ,34,,1",
 "269100,2,2,1998/10/01 07:52:00,139.3479521968,35.4864696855,1,7,2811013,9 ,1 ,37,,3",
 "276000,2,2,1998/10/01 07:52:00,139.2646360109,35.3688700303,1,2,2821003,12,2 ,33,,1",
 "285200,2,2,1998/10/01 07:52:00,139.4519398161,35.5019991731,1,2,2911313,12,2 ,34,,1",
 "370300,2,4,1998/10/01 07:52:00,139.7373239461,35.6914556205,1,10,3037010,10,1 ,39,,1",
 "386400,2,4,1998/10/01 07:52:00,139.7699122469,35.6894695873,1,10,3059002,8 ,1 ,37,,12",
 "404800,2,3,1998/10/01 07:52:00,139.4678942429,35.7659072311,2,9,3120009,8 ,1 ,30,,12",
 "437000,2,3,1998/10/01 07:52:00,139.7850880689,35.7213411794,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,3,1998/10/01 07:52:00,139.79281591,35.862185698,2,5,3221102,8 ,1 ,37,,12",
 "411700,2,2,1998/10/01 07:52:00,139.4282466181,35.7923856956,2,1,3122110,12,2 ,29,,1",
 "414000,2,2,1998/10/01 07:52:00,139.4647225745,35.7457841176,1,10,3122302,3 ,11,32,,6",
 "441600,4,4,1998/10/01 07:52:00,139.867504748,35.7649872654,1,9,3215009,9 ,11,30,,8",
 "476100,2,3,1998/10/01 07:52:00,139.569197684,35.8261072195,1,3,3411213,13,2 ,34,,12",
 "483000,2,3,1998/10/01 07:52:00,139.6841879965,35.8339876612,1,10,3510101,8 ,1 ,27,,12",
 "462300,2,2,1998/10/01 07:52:00,139.7549197768,35.88988647,1,8,3235004,9 ,1 ,38,,6",
 "462300,3,3,1998/10/01 07:52:00,139.7549197768,35.88988647,1,8,3235004,9 ,97,38,,97",
 "501400,2,2,1998/10/01 07:52:00,139.6584013261,35.9734375011,2,2,3613122,12,2 ,33,,1",
 "487600,2,2,1998/10/01 07:52:00,139.6196172293,35.9573109471,1,10,3512006,6 ,1 ,36,,6",
 "508300,2,4,1998/10/01 07:52:00,139.6673157081,36.0140013888,1,9,3622214,8 ,1 ,36,,1",
 "512900,2,2,1998/10/01 07:52:00,139.4489933854,36.103181517,1,11,3632007,5 ,1 ,35,,2",
 "515200,2,2,1998/10/01 07:52:00,139.1928045816,36.1206357042,1,1,3711123,12,2 ,37,,1",
 "549700,2,2,1998/10/01 07:52:00,139.7340528778,35.573730522,1,8,0131010,3 ,1 ,62,,2",
 "563500,2,3,1998/10/01 07:52:00,139.7110844595,35.7294403898,1,7,0212001,10,1 ,84,,12",
 "609500,2,4,1998/10/01 07:52:00,139.6895444741,35.6766496458,1,7,0430102,8 ,1 ,51,,1",
 "644000,2,3,1998/10/01 07:52:00,139.7109237815,35.7305928896,1,4,0522008,5 ,1 ,52,,12",
 "646300,2,2,1998/10/01 07:52:00,139.6619785755,35.788865379,1,4,0523008,8 ,1 ,75,,1",
 "747500,2,3,1998/10/01 07:52:00,139.629712391,35.4798305324,1,5,0810007,4 ,1 ,38,,12",
 "713000,2,2,1998/10/01 07:52:00,139.5111913563,35.7589699551,2,2,0741304,12,2 ,30,,1",
 "811900,2,3,1998/10/01 07:52:00,139.6128545087,35.3911326402,1,3,1123201,13,2 ,31,,12",
 "648600,2,4,1998/10/01 07:52:00,139.7124373219,35.777750786,2,3,0530007,13,2 ,37,,1",
 "768200,2,2,1998/10/01 07:52:00,139.3434339391,35.6694258228,2,2,0840002,12,2 ,36,,1",
 "788900,2,2,1998/10/01 07:52:00,139.2756419427,35.8191569985,2,2,0931009,12,2 ,35,,1",
 "786600,2,3,1998/10/01 07:52:00,139.3255966665,35.7453965705,2,3,0930110,13,2 ,35,,12",
 "782000,2,2,1998/10/01 07:52:00,139.3301989731,35.7239955855,2,2,0911103,12,2 ,36,,1",
 "777400,2,3,1998/10/01 07:52:00,139.6370658602,35.6668000643,1,5,0844010,8 ,1 ,41,,12",
 "821100,2,2,1998/10/01 07:52:00,139.5701340693,35.443701179,2,1,1213204,12,2 ,38,,1",
 "759000,2,3,1998/10/01 07:52:00,139.5390378661,35.64845326,2,4,0821006,9 ,1 ,55,,12",
 "696900,2,2,1998/10/01 07:52:00,139.5963657137,35.683435068,2,6,0720006,4 ,1 ,39,,2",
 "719900,2,3,1998/10/01 07:52:00,139.4470637774,35.6890566485,1,7,0752005,6 ,10,34,,10",
 "683100,2,3,1998/10/01 07:52:00,139.8030729519,35.6882032315,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 07:52:00,139.6216124161,35.669139763,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,3,1998/10/01 07:53:00,139.6209168901,35.4645633919,2,4,1251203,8 ,1 ,37,,12",
 "839500,2,4,1998/10/01 07:53:00,139.6285579577,35.4879290496,2,11,1133001,14,7 ,27,,1",
 "96600,2,3,1998/10/01 07:53:00,140.087132746,35.5177126865,1,3,4510101,13,2 ,33,,10",
 "841800,2,3,1998/10/01 07:53:00,139.6217282185,35.4631657834,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:53:00,139.6426930208,35.5022523868,1,9,1340109,10,1 ,33,,12",
 "869400,2,3,1998/10/01 07:53:00,139.6303582523,35.4751987917,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,3,1998/10/01 07:53:00,139.5362174682,35.3689250761,1,6,1230404,9 ,1 ,31,,12",
 "2300,2,3,1998/10/01 07:53:00,139.9150323141,35.7261366348,1,6,4111102,8 ,1 ,36,,12",
 "13800,2,3,1998/10/01 07:53:00,139.9451030046,35.7668703306,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:53:00,139.7607331597,35.695955525,1,9,4120311,8 ,1 ,44,,12",
 "434700,4,4,1998/10/01 07:53:00,139.8054676499,35.8119950225,2,8,3210107,8 ,1 ,37,,7",
 "34500,2,2,1998/10/01 07:53:00,139.9647744245,35.7044865054,1,4,4129003,8 ,1 ,36,,1",
 "32200,2,3,1998/10/01 07:53:00,139.8268171857,35.6694191009,2,4,4128103,8 ,1 ,42,,12",
 "41400,2,2,1998/10/01 07:53:00,139.9132401119,35.82617209,2,5,4212212,8 ,1 ,44,,1",
 "593400,2,2,1998/10/01 07:53:00,139.8191676117,35.683509022,1,6,0349009,9 ,1 ,58,,1",
 "71300,2,3,1998/10/01 07:53:00,139.7969761667,35.7424567071,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:53:00,139.9591831627,35.7069830475,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 07:53:00,140.0807438915,35.6507328581,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 07:53:00,140.1063594037,35.9180981306,2,4,5024120,13,2 ,44,,12",
 "209300,2,3,1998/10/01 07:53:00,139.655552164,35.2802478012,1,13,2211303,10,1 ,37,,12",
 "227700,2,3,1998/10/01 07:53:00,139.6133280389,35.456270374,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:53:00,139.7386022477,35.6249745073,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:53:00,139.635540199,35.4807531482,1,11,2922005,9 ,1 ,29,,12",
 "303600,2,3,1998/10/01 07:53:00,139.5346748373,35.6145570198,1,9,2923016,9 ,1 ,30,,12",
 "85100,2,2,1998/10/01 07:53:00,140.2737980221,35.7034777885,1,8,4417027,9 ,10,46,,6",
 "310500,2,3,1998/10/01 07:53:00,139.6829270706,35.5195712002,1,9,2013102,3 ,1 ,37,,12",
 "170200,2,2,1998/10/01 07:53:00,140.2008062323,36.0827431651,2,3,5022220,13,2 ,43,,2",
 "216200,2,2,1998/10/01 07:53:00,139.6772825414,35.2468530568,2,1,2221106,12,2 ,34,,1",
 "269100,2,2,1998/10/01 07:53:00,139.3463642883,35.4893293631,1,7,2811013,9 ,1 ,37,,3",
 "276000,2,2,1998/10/01 07:53:00,139.2651777008,35.3691623257,1,2,2821003,12,2 ,33,,1",
 "285200,2,2,1998/10/01 07:53:00,139.4513859704,35.501221569,1,2,2911313,12,2 ,34,,1",
 "370300,2,4,1998/10/01 07:53:00,139.7380754004,35.6916740888,1,10,3037010,10,1 ,39,,1",
 "386400,2,4,1998/10/01 07:53:00,139.7683216445,35.685429968,1,10,3059002,8 ,1 ,37,,12",
 "404800,2,3,1998/10/01 07:53:00,139.4674157256,35.7617060292,2,9,3120009,8 ,1 ,30,,12",
 "437000,2,3,1998/10/01 07:53:00,139.7819132641,35.7173235212,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,3,1998/10/01 07:53:00,139.7934680701,35.859337011,2,5,3221102,8 ,1 ,37,,12",
 "411700,2,2,1998/10/01 07:53:00,139.4277256001,35.7921162167,2,1,3122110,12,2 ,29,,1",
 "414000,2,2,1998/10/01 07:53:00,139.4664272359,35.7445340677,1,10,3122302,3 ,11,32,,6",
 "476100,2,3,1998/10/01 07:53:00,139.5751669776,35.8221322088,1,3,3411213,13,2 ,34,,12",
 "476100,2,4,1998/10/01 07:53:00,139.5751669776,35.8221322088,1,3,3411213,13,2 ,34,,1",
 "441600,4,4,1998/10/01 07:53:00,139.8655430382,35.7639414702,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 07:53:00,139.6869862519,35.8311977007,1,10,3510101,8 ,1 ,27,,12",
 "501400,2,2,1998/10/01 07:53:00,139.658107763,35.9738009332,2,2,3613122,12,2 ,33,,1",
 "508300,2,4,1998/10/01 07:53:00,139.6677324676,36.0132966189,1,9,3622214,8 ,1 ,36,,1",
 "487600,2,2,1998/10/01 07:53:00,139.6173026125,35.9567140169,1,10,3512006,6 ,1 ,36,,6",
 "512900,2,2,1998/10/01 07:53:00,139.4499575875,36.1027001459,1,11,3632007,5 ,1 ,35,,2",
 "515200,2,2,1998/10/01 07:53:00,139.1921145916,36.1198275271,1,1,3711123,12,2 ,37,,1",
 "549700,2,2,1998/10/01 07:53:00,139.7344388677,35.5738805468,1,8,0131010,3 ,1 ,62,,2",
 "563500,2,3,1998/10/01 07:53:00,139.7128106028,35.7284435927,1,7,0212001,10,1 ,84,,12",
 "609500,2,4,1998/10/01 07:53:00,139.6893043857,35.6761799892,1,7,0430102,8 ,1 ,51,,1",
 "609500,3,5,1998/10/01 07:53:00,139.6893043857,35.6761799892,1,7,0430102,8 ,97,51,,97",
 "644000,2,3,1998/10/01 07:53:00,139.7123028485,35.7326556926,1,4,0522008,5 ,1 ,52,,12",
 "747500,2,3,1998/10/01 07:53:00,139.6255862163,35.4754604327,1,5,0810007,4 ,1 ,38,,12",
 "713000,2,2,1998/10/01 07:53:00,139.5111660962,35.7594330017,2,2,0741304,12,2 ,30,,1",
 "811900,2,3,1998/10/01 07:53:00,139.6138178655,35.3877211861,1,3,1123201,13,2 ,31,,12",
 "646300,2,2,1998/10/01 07:53:00,139.6612989282,35.7888331516,1,4,0523008,8 ,1 ,75,,1",
 "646300,2,3,1998/10/01 07:53:00,139.6612989282,35.7888331516,1,4,0523008,8 ,1 ,75,,12",
 "648600,2,4,1998/10/01 07:53:00,139.7119581292,35.7771863327,2,3,0530007,13,2 ,37,,1",
 "768200,2,2,1998/10/01 07:53:00,139.3427136354,35.6697419769,2,2,0840002,12,2 ,36,,1",
 "777400,2,3,1998/10/01 07:53:00,139.6413803625,35.6661742982,1,5,0844010,8 ,1 ,41,,12",
 "786600,2,3,1998/10/01 07:53:00,139.3281314377,35.7419677458,2,3,0930110,13,2 ,35,,12",
 "788900,2,2,1998/10/01 07:53:00,139.2758176231,35.8198858124,2,2,0931009,12,2 ,35,,1",
 "782000,2,2,1998/10/01 07:53:00,139.3301001363,35.7243165967,2,2,0911103,12,2 ,36,,1",
 "821100,2,2,1998/10/01 07:53:00,139.5690038465,35.4433768355,2,1,1213204,12,2 ,38,,1",
 "759000,2,3,1998/10/01 07:53:00,139.5417125488,35.652233159,2,4,0821006,9 ,1 ,55,,12",
 "696900,2,2,1998/10/01 07:53:00,139.5980446276,35.6839187627,2,6,0720006,4 ,1 ,39,,2",
 "719900,2,3,1998/10/01 07:53:00,139.4469846664,35.6908916071,1,7,0752005,6 ,10,34,,10",
 "683100,2,3,1998/10/01 07:53:00,139.7995982618,35.6880824799,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 07:53:00,139.6264622567,35.6683669027,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,3,1998/10/01 07:54:00,139.6156722277,35.4587551364,2,4,1251203,8 ,1 ,37,,12",
 "839500,2,4,1998/10/01 07:54:00,139.6289302818,35.4871388773,2,11,1133001,14,7 ,27,,1",
 "96600,2,3,1998/10/01 07:54:00,140.087133022,35.5177132325,1,3,4510101,13,2 ,33,,10",
 "841800,2,3,1998/10/01 07:54:00,139.6238350105,35.4677105666,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:54:00,139.6444275834,35.4987620057,1,9,1340109,10,1 ,33,,12",
 "869400,2,3,1998/10/01 07:54:00,139.6338273081,35.4789287962,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,3,1998/10/01 07:54:00,139.5332003455,35.3681248374,1,6,1230404,9 ,1 ,31,,12",
 "2300,2,3,1998/10/01 07:54:00,139.9109230323,35.7277977012,1,6,4111102,8 ,1 ,36,,12",
 "13800,2,3,1998/10/01 07:54:00,139.9435271482,35.7716524822,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:54:00,139.7553928619,35.6959072983,1,9,4120311,8 ,1 ,44,,12",
 "434700,4,4,1998/10/01 07:54:00,139.8031801173,35.8128829182,2,8,3210107,8 ,1 ,37,,7",
 "32200,2,3,1998/10/01 07:54:00,139.8211962889,35.6697220974,2,4,4128103,8 ,1 ,42,,12",
 "34500,2,2,1998/10/01 07:54:00,139.9614270085,35.7058230559,1,4,4129003,8 ,1 ,36,,1",
 "41400,2,2,1998/10/01 07:54:00,139.9132403955,35.8261719923,2,5,4212212,8 ,1 ,44,,1",
 "593400,2,2,1998/10/01 07:54:00,139.8191673478,35.683509274,1,6,0349009,9 ,1 ,58,,1",
 "71300,2,3,1998/10/01 07:54:00,139.7929665354,35.7443313075,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:54:00,139.9549278083,35.709225416,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 07:54:00,140.0740046889,35.6517180708,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 07:54:00,140.1004185914,35.9168472281,2,4,5024120,13,2 ,44,,12",
 "209300,2,3,1998/10/01 07:54:00,139.6491355922,35.2828434656,1,13,2211303,10,1 ,37,,12",
 "227700,2,3,1998/10/01 07:54:00,139.6181417542,35.4613958238,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:54:00,139.7401433447,35.6311472106,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:54:00,139.6399066606,35.4834973601,1,11,2922005,9 ,1 ,29,,12",
 "303600,2,3,1998/10/01 07:54:00,139.5397069027,35.6147252608,1,9,2923016,9 ,1 ,30,,12",
 "85100,2,2,1998/10/01 07:54:00,140.270261956,35.7065674587,1,8,4417027,9 ,10,46,,6",
 "310500,2,3,1998/10/01 07:54:00,139.6774525093,35.51277614,1,9,2013102,3 ,1 ,37,,12",
 "170200,2,2,1998/10/01 07:54:00,140.2003650839,36.0837450223,2,3,5022220,13,2 ,43,,2",
 "216200,2,2,1998/10/01 07:54:00,139.6773825545,35.2466319245,2,1,2221106,12,2 ,34,,1",
 "269100,2,2,1998/10/01 07:54:00,139.3450358728,35.4922574305,1,7,2811013,9 ,1 ,37,,3",
 "276000,2,2,1998/10/01 07:54:00,139.2657222554,35.3694510666,1,2,2821003,12,2 ,33,,1",
 "285200,2,2,1998/10/01 07:54:00,139.4498088901,35.5011558852,1,2,2911313,12,2 ,34,,1",
 "370300,2,4,1998/10/01 07:54:00,139.7388281674,35.691889531,1,10,3037010,10,1 ,39,,1",
 "386400,2,4,1998/10/01 07:54:00,139.7659273359,35.6823221629,1,10,3059002,8 ,1 ,37,,12",
 "331200,2,2,1998/10/01 07:54:00,139.5825571492,35.5703924943,2,1,2121208,12,2 ,28,,1",
 "404800,2,3,1998/10/01 07:54:00,139.46790829,35.7573558715,2,9,3120009,8 ,1 ,30,,12",
 "437000,2,3,1998/10/01 07:54:00,139.7788593502,35.7132450912,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,3,1998/10/01 07:54:00,139.7942403769,35.8565105846,2,5,3221102,8 ,1 ,37,,12",
 "414000,2,2,1998/10/01 07:54:00,139.4666555582,35.7428124066,1,10,3122302,3 ,11,32,,6",
 "411700,2,2,1998/10/01 07:54:00,139.4269347741,35.7920978074,2,1,3122110,12,2 ,29,,1",
 "476100,2,4,1998/10/01 07:54:00,139.5739582122,35.8231126367,1,3,3411213,13,2 ,34,,1",
 "441600,4,4,1998/10/01 07:54:00,139.8636148059,35.7628265398,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 07:54:00,139.6898438029,35.8284478119,1,10,3510101,8 ,1 ,27,,12",
 "501400,2,2,1998/10/01 07:54:00,139.6578141973,35.9741643645,2,2,3613122,12,2 ,33,,1",
 "487600,2,2,1998/10/01 07:54:00,139.6150554575,35.9559626809,1,10,3512006,6 ,1 ,36,,6",
 "508300,2,4,1998/10/01 07:54:00,139.6681950886,36.0127282861,1,9,3622214,8 ,1 ,36,,1",
 "515200,2,2,1998/10/01 07:54:00,139.191934804,36.1186900635,1,1,3711123,12,2 ,37,,1",
 "512900,2,2,1998/10/01 07:54:00,139.4509395239,36.1022439299,1,11,3632007,5 ,1 ,35,,2",
 "549700,2,2,1998/10/01 07:54:00,139.7351201318,35.5738218085,1,8,0131010,3 ,1 ,62,,2",
 "563500,2,3,1998/10/01 07:54:00,139.7159985529,35.7271499414,1,7,0212001,10,1 ,84,,12",
 "646300,2,3,1998/10/01 07:54:00,139.6678291631,35.7875779296,1,4,0523008,8 ,1 ,75,,12",
 "644000,2,3,1998/10/01 07:54:00,139.716022733,35.734524023,1,4,0522008,5 ,1 ,52,,12",
 "747500,2,3,1998/10/01 07:54:00,139.6251493738,35.470007721,1,5,0810007,4 ,1 ,38,,12",
 "713000,2,2,1998/10/01 07:54:00,139.510774595,35.760287513,2,2,0741304,12,2 ,30,,1",
 "811900,2,3,1998/10/01 07:54:00,139.6153371725,35.3844487457,1,3,1123201,13,2 ,31,,12",
 "648600,2,4,1998/10/01 07:54:00,139.7114789433,35.7766218775,2,3,0530007,13,2 ,37,,1",
 "768200,2,2,1998/10/01 07:54:00,139.3419920874,35.6700562326,2,2,0840002,12,2 ,36,,1",
 "777400,2,3,1998/10/01 07:54:00,139.6421031062,35.6631889549,1,5,0844010,8 ,1 ,41,,12",
 "786600,2,3,1998/10/01 07:54:00,139.3306664409,35.7385390853,2,3,0930110,13,2 ,35,,12",
 "788900,2,2,1998/10/01 07:54:00,139.2755586748,35.8206128788,2,2,0931009,12,2 ,35,,1",
 "782000,2,2,1998/10/01 07:54:00,139.3300111933,35.7246420999,2,2,0911103,12,2 ,36,,1",
 "821100,2,2,1998/10/01 07:54:00,139.5678133221,35.4432856319,2,1,1213204,12,2 ,38,,1",
 "759000,2,3,1998/10/01 07:54:00,139.546859014,35.6511537299,2,4,0821006,9 ,1 ,55,,12",
 "696900,2,2,1998/10/01 07:54:00,139.5992438621,35.6858952989,2,6,0720006,4 ,1 ,39,,2",
 "719900,2,3,1998/10/01 07:54:00,139.4468958261,35.6927262661,1,7,0752005,6 ,10,34,,10",
 "683100,2,3,1998/10/01 07:54:00,139.7961209574,35.6880418053,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 07:54:00,139.631332172,35.6676782986,2,4,0711108,8 ,1 ,25,,12",
 "786600,2,3,1998/10/01 07:55:00,139.3332012256,35.7351103676,2,3,0930110,13,2 ,35,,12",
 "830300,2,3,1998/10/01 07:55:00,139.6074047843,35.452504584,2,4,1251203,8 ,1 ,37,,12",
 "839500,2,4,1998/10/01 07:55:00,139.6297575014,35.4868830031,2,11,1133001,14,7 ,27,,1",
 "96600,2,3,1998/10/01 07:55:00,140.0871332981,35.5177137784,1,3,4510101,13,2 ,33,,10",
 "96600,2,4,1998/10/01 07:55:00,140.0871332981,35.5177137784,1,3,4510101,13,2 ,33,,1",
 "841800,2,3,1998/10/01 07:55:00,139.6276524552,35.4721779273,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:55:00,139.6457076,35.4951479486,1,9,1340109,10,1 ,33,,12",
 "869400,2,3,1998/10/01 07:55:00,139.6375033768,35.4825059599,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,3,1998/10/01 07:55:00,139.531139349,35.3661413003,1,6,1230404,9 ,1 ,31,,12",
 "2300,2,3,1998/10/01 07:55:00,139.9068485078,35.7294459607,1,6,4111102,8 ,1 ,36,,12",
 "18400,2,3,1998/10/01 07:55:00,139.7500570657,35.6952311892,1,9,4120311,8 ,1 ,44,,12",
 "13800,2,3,1998/10/01 07:55:00,139.942228154,35.7764888611,2,14,4116004,5 ,5 ,25,,12",
 "434700,4,4,1998/10/01 07:55:00,139.8007299628,35.8134772302,2,8,3210107,8 ,1 ,37,,7",
 "32200,2,3,1998/10/01 07:55:00,139.8154999165,35.6694749942,2,4,4128103,8 ,1 ,42,,12",
 "34500,2,2,1998/10/01 07:55:00,139.9593883388,35.7072098058,1,4,4129003,8 ,1 ,36,,1",
 "34500,2,3,1998/10/01 07:55:00,139.9593883388,35.7072098058,1,4,4129003,8 ,1 ,36,,12",
 "41400,2,2,1998/10/01 07:55:00,139.9132406791,35.8261718947,2,5,4212212,8 ,1 ,44,,1",
 "41400,2,3,1998/10/01 07:55:00,139.9132406791,35.8261718947,2,5,4212212,8 ,1 ,44,,10",
 "593400,2,2,1998/10/01 07:55:00,139.819167084,35.683509526,1,6,0349009,9 ,1 ,58,,1",
 "78200,2,3,1998/10/01 07:55:00,139.9500753489,35.7098990264,1,12,4413008,8 ,1 ,44,,12",
 "71300,2,3,1998/10/01 07:55:00,139.7885377002,35.7439592019,1,12,4313309,9 ,1 ,34,,12",
 "151800,2,4,1998/10/01 07:55:00,140.0675650129,35.6534236292,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 07:55:00,140.0947641707,35.9149964455,2,4,5024120,13,2 ,44,,12",
 "209300,2,3,1998/10/01 07:55:00,139.6433444303,35.2866164852,1,13,2211303,10,1 ,37,,12",
 "227700,2,3,1998/10/01 07:55:00,139.6228657858,35.4665776378,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:55:00,139.7424241875,35.6371603596,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:55:00,139.6451810454,35.4850779289,1,11,2922005,9 ,1 ,29,,12",
 "85100,2,2,1998/10/01 07:55:00,140.265927816,35.7086674713,1,8,4417027,9 ,10,46,,6",
 "303600,2,3,1998/10/01 07:55:00,139.5447006081,35.6152576609,1,9,2923016,9 ,1 ,30,,12",
 "310500,2,3,1998/10/01 07:55:00,139.6756486724,35.5047263946,1,9,2013102,3 ,1 ,37,,12",
 "170200,2,2,1998/10/01 07:55:00,140.1995474982,36.0845686231,2,3,5022220,13,2 ,43,,2",
 "216200,2,2,1998/10/01 07:55:00,139.6775941657,35.2464748928,2,1,2221106,12,2 ,34,,1",
 "269100,2,2,1998/10/01 07:55:00,139.3436328058,35.4951231218,1,7,2811013,9 ,1 ,37,,3",
 "276000,2,2,1998/10/01 07:55:00,139.2662662457,35.3697405194,1,2,2821003,12,2 ,33,,1",
 "285200,2,2,1998/10/01 07:55:00,139.4488312192,35.5007029139,1,2,2911313,12,2 ,34,,1",
 "370300,2,4,1998/10/01 07:55:00,139.7392159016,35.6914847901,1,10,3037010,10,1 ,39,,1",
 "386400,2,4,1998/10/01 07:55:00,139.763577874,35.6786731515,1,10,3059002,8 ,1 ,37,,12",
 "331200,2,2,1998/10/01 07:55:00,139.5821012657,35.5704444019,2,1,2121208,12,2 ,28,,1",
 "404800,2,3,1998/10/01 07:55:00,139.4685593935,35.7530642988,2,9,3120009,8 ,1 ,30,,12",
 "437000,2,3,1998/10/01 07:55:00,139.7764458763,35.7091581568,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,3,1998/10/01 07:55:00,139.7955672643,35.8538220553,2,5,3221102,8 ,1 ,37,,12",
 "414000,2,2,1998/10/01 07:55:00,139.4663324567,35.7409610575,1,10,3122302,3 ,11,32,,6",
 "411700,2,2,1998/10/01 07:55:00,139.4262377865,35.7921802609,2,1,3122110,12,2 ,29,,1",
 "441600,4,4,1998/10/01 07:55:00,139.8616833926,35.7617153748,1,9,3215009,9 ,11,30,,8",
 "476100,2,4,1998/10/01 07:55:00,139.573013904,35.823010469,1,3,3411213,13,2 ,34,,1",
 "483000,2,3,1998/10/01 07:55:00,139.6926361328,35.8256542937,1,10,3510101,8 ,1 ,27,,12",
 "501400,2,2,1998/10/01 07:55:00,139.6574521883,35.9742217672,2,2,3613122,12,2 ,33,,1",
 "487600,2,2,1998/10/01 07:55:00,139.6128037864,35.9552248837,1,10,3512006,6 ,1 ,36,,6",
 "508300,2,4,1998/10/01 07:55:00,139.6689702011,36.0130517595,1,9,3622214,8 ,1 ,36,,1",
 "512900,2,2,1998/10/01 07:55:00,139.4520331044,36.1020111429,1,11,3632007,5 ,1 ,35,,2",
 "515200,2,2,1998/10/01 07:55:00,139.1922030048,36.1173003209,1,1,3711123,12,2 ,37,,1",
 "515200,3,3,1998/10/01 07:55:00,139.1922030048,36.1173003209,1,1,3711123,12,97,37,,97",
 "549700,2,2,1998/10/01 07:55:00,139.7357977902,35.5738879683,1,8,0131010,3 ,1 ,62,,2",
 "563500,2,3,1998/10/01 07:55:00,139.7191805769,35.7258117802,1,7,0212001,10,1 ,84,,12",
 "644000,2,3,1998/10/01 07:55:00,139.7204401318,35.7337627494,1,4,0522008,5 ,1 ,52,,12",
 "646300,2,3,1998/10/01 07:55:00,139.6745866629,35.7868912059,1,4,0523008,8 ,1 ,75,,12",
 "648600,2,4,1998/10/01 07:55:00,139.7113633353,35.7768509014,2,3,0530007,13,2 ,37,,1",
 "713000,2,2,1998/10/01 07:55:00,139.5103830853,35.7611420228,2,2,0741304,12,2 ,30,,1",
 "759000,2,3,1998/10/01 07:55:00,139.5520960094,35.6498189755,2,4,0821006,9 ,1 ,55,,12",
 "747500,2,3,1998/10/01 07:55:00,139.6212320355,35.4654852753,1,5,0810007,4 ,1 ,38,,12",
 "747500,2,4,1998/10/01 07:55:00,139.6212320355,35.4654852753,1,5,0810007,4 ,1 ,38,,1",
 "768200,2,2,1998/10/01 07:55:00,139.3412673668,35.6703654048,2,2,0840002,12,2 ,36,,1",
 "811900,2,3,1998/10/01 07:55:00,139.6186544081,35.3848609974,1,3,1123201,13,2 ,31,,12",
 "782000,2,2,1998/10/01 07:55:00,139.3299222496,35.724967603,2,2,0911103,12,2 ,36,,1",
 "788900,2,2,1998/10/01 07:55:00,139.2752868951,35.8213378984,2,2,0931009,12,2 ,35,,1",
 "821100,2,2,1998/10/01 07:55:00,139.5681939355,35.443504832,2,1,1213204,12,2 ,38,,1",
 "683100,2,3,1998/10/01 07:55:00,139.7926448559,35.6880242958,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 07:55:00,139.6361896494,35.6669328822,2,4,0711108,8 ,1 ,25,,12",
 "777400,2,3,1998/10/01 07:55:00,139.6419178755,35.65961806,1,5,0844010,8 ,1 ,41,,12",
 "696900,2,2,1998/10/01 07:55:00,139.5992524931,35.6879821883,2,6,0720006,4 ,1 ,39,,2",
 "696900,2,3,1998/10/01 07:55:00,139.5992524931,35.6879821883,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,3,1998/10/01 07:55:00,139.4468003898,35.6945607194,1,7,0752005,6 ,10,34,,10",
 "830300,2,3,1998/10/01 07:56:00,139.5992004838,35.4461465882,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,2,1998/10/01 07:56:00,140.0901195858,35.6984967206,2,1,4410011,12,2 ,35,,1",
 "73600,2,3,1998/10/01 07:56:00,140.0901195858,35.6984967206,2,1,4410011,12,2 ,35,,9",
 "839500,2,4,1998/10/01 07:56:00,139.6307290962,35.486590386,2,11,1133001,14,7 ,27,,1",
 "96600,2,4,1998/10/01 07:56:00,140.1027639747,35.5306629516,1,3,4510101,13,2 ,33,,1",
 "841800,2,3,1998/10/01 07:56:00,139.6316079795,35.476563692,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:56:00,139.6464724954,35.4914326465,1,9,1340109,10,1 ,33,,12",
 "869400,2,3,1998/10/01 07:56:00,139.6427360633,35.4843774209,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,3,1998/10/01 07:56:00,139.5300187622,35.3636732426,1,6,1230404,9 ,1 ,31,,12",
 "2300,2,3,1998/10/01 07:56:00,139.9028095169,35.7312156617,1,6,4111102,8 ,1 ,36,,12",
 "13800,2,3,1998/10/01 07:56:00,139.9410151649,35.7813408884,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:56:00,139.7449310379,35.6938179893,1,9,4120311,8 ,1 ,44,,12",
 "434700,4,4,1998/10/01 07:56:00,139.7983834692,35.8143042985,2,8,3210107,8 ,1 ,37,,7",
 "32200,2,3,1998/10/01 07:56:00,139.8098009526,35.6692849005,2,4,4128103,8 ,1 ,42,,12",
 "34500,2,3,1998/10/01 07:56:00,139.9654224631,35.7044260472,1,4,4129003,8 ,1 ,36,,12",
 "41400,2,3,1998/10/01 07:56:00,139.9152885144,35.8258049831,2,5,4212212,8 ,1 ,44,,10",
 "593400,2,2,1998/10/01 07:56:00,139.8191668201,35.683509778,1,6,0349009,9 ,1 ,58,,1",
 "71300,2,3,1998/10/01 07:56:00,139.7839838751,35.7433757077,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:56:00,139.9461860457,35.7073253836,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 07:56:00,140.0617841182,35.6564405292,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 07:56:00,140.0900471528,35.9118037191,2,4,5024120,13,2 ,44,,12",
 "209300,2,3,1998/10/01 07:56:00,139.6360119874,35.2865350511,1,13,2211303,10,1 ,37,,12",
 "227700,2,3,1998/10/01 07:56:00,139.6273945493,35.4718766839,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:56:00,139.7455453643,35.6426659655,1,14,2412116,10,1 ,63,,12",
 "303600,2,3,1998/10/01 07:56:00,139.5497231624,35.6155442962,1,9,2923016,9 ,1 ,30,,12",
 "301300,2,3,1998/10/01 07:56:00,139.6507493145,35.4856547916,1,11,2922005,9 ,1 ,29,,12",
 "85100,2,2,1998/10/01 07:56:00,140.2643955354,35.7116283066,1,8,4417027,9 ,10,46,,6",
 "310500,2,3,1998/10/01 07:56:00,139.6699469349,35.4981081997,1,9,2013102,3 ,1 ,37,,12",
 "170200,2,2,1998/10/01 07:56:00,140.1986335441,36.0853323998,2,3,5022220,13,2 ,43,,2",
 "216200,2,2,1998/10/01 07:56:00,139.6778132537,35.2463221558,2,1,2221106,12,2 ,34,,1",
 "255300,2,2,1998/10/01 07:56:00,139.1789900309,35.302957281,1,12,2610311,8 ,1 ,44,,6",
 "269100,2,2,1998/10/01 07:56:00,139.3416550189,35.4970064665,1,7,2811013,9 ,1 ,37,,3",
 "276000,2,2,1998/10/01 07:56:00,139.2668086674,35.3700319464,1,2,2821003,12,2 ,33,,1",
 "285200,2,2,1998/10/01 07:56:00,139.4491978917,35.4994496036,1,2,2911313,12,2 ,34,,1",
 "370300,2,4,1998/10/01 07:56:00,139.7394736739,35.6908689142,1,10,3037010,10,1 ,39,,1",
 "386400,2,4,1998/10/01 07:56:00,139.7622037118,35.674580426,1,10,3059002,8 ,1 ,37,,12",
 "331200,2,2,1998/10/01 07:56:00,139.5816532666,35.5705177999,2,1,2121208,12,2 ,28,,1",
 "404800,2,3,1998/10/01 07:56:00,139.4722476249,35.7498947479,2,9,3120009,8 ,1 ,30,,12",
 "437000,2,3,1998/10/01 07:56:00,139.7749997334,35.7056326717,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,3,1998/10/01 07:56:00,139.7968712075,35.8511257387,2,5,3221102,8 ,1 ,37,,12",
 "414000,2,2,1998/10/01 07:56:00,139.4660724291,35.7391020425,1,10,3122302,3 ,11,32,,6",
 "411700,2,2,1998/10/01 07:56:00,139.4262601338,35.7928460741,2,1,3122110,12,2 ,29,,1",
 "411700,3,3,1998/10/01 07:56:00,139.4262601338,35.7928460741,2,1,3122110,12,97,29,,97",
 "476100,2,4,1998/10/01 07:56:00,139.573771107,35.8219713868,1,3,3411213,13,2 ,34,,1",
 "441600,4,4,1998/10/01 07:56:00,139.8597548559,35.7606017848,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 07:56:00,139.6957723651,35.8231230888,1,10,3510101,8 ,1 ,27,,12",
 "501400,2,2,1998/10/01 07:56:00,139.6570200475,35.973965572,2,2,3613122,12,2 ,33,,1",
 "487600,2,2,1998/10/01 07:56:00,139.6105568504,35.9544729546,1,10,3512006,6 ,1 ,36,,6",
 "508300,2,4,1998/10/01 07:56:00,139.6695291377,36.0124157155,1,9,3622214,8 ,1 ,36,,1",
 "512900,2,2,1998/10/01 07:56:00,139.4531294295,36.1017823106,1,11,3632007,5 ,1 ,35,,2",
 "549700,2,2,1998/10/01 07:56:00,139.7361234079,35.5734125968,1,8,0131010,3 ,1 ,62,,2",
 "563500,2,3,1998/10/01 07:56:00,139.7220793025,35.7239815848,1,7,0212001,10,1 ,84,,12",
 "646300,2,3,1998/10/01 07:56:00,139.6790279111,35.7841313313,1,4,0523008,8 ,1 ,75,,12",
 "644000,2,3,1998/10/01 07:56:00,139.7247707585,35.7326157485,1,4,0522008,5 ,1 ,52,,12",
 "747500,2,4,1998/10/01 07:56:00,139.6228515888,35.4674873713,1,5,0810007,4 ,1 ,38,,1",
 "811900,2,3,1998/10/01 07:56:00,139.6206103358,35.3843685994,1,3,1123201,13,2 ,31,,12",
 "713000,2,2,1998/10/01 07:56:00,139.5099915672,35.7619965312,2,2,0741304,12,2 ,30,,1",
 "648600,2,4,1998/10/01 07:56:00,139.7113393589,35.7775235445,2,3,0530007,13,2 ,37,,1",
 "768200,2,2,1998/10/01 07:56:00,139.3405188285,35.6706212032,2,2,0840002,12,2 ,36,,1",
 "777400,2,3,1998/10/01 07:56:00,139.6443070942,35.656699324,1,5,0844010,8 ,1 ,41,,12",
 "782000,2,2,1998/10/01 07:56:00,139.3298423602,35.7252946299,2,2,0911103,12,2 ,36,,1",
 "786600,2,3,1998/10/01 07:56:00,139.3357361205,35.7316817545,2,3,0930110,13,2 ,35,,12",
 "788900,2,2,1998/10/01 07:56:00,139.2756073224,35.8220024061,2,2,0931009,12,2 ,35,,1",
 "821100,2,2,1998/10/01 07:56:00,139.5693403835,35.443795747,2,1,1213204,12,2 ,38,,1",
 "759000,2,3,1998/10/01 07:56:00,139.5575332312,35.6498248913,2,4,0821006,9 ,1 ,55,,12",
 "719900,2,3,1998/10/01 07:56:00,139.4467152941,35.6963955379,1,7,0752005,6 ,10,34,,10",
 "683100,2,3,1998/10/01 07:56:00,139.7891965316,35.6883702201,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 07:56:00,139.6410534215,35.6662164741,2,4,0711108,8 ,1 ,25,,12",
 "696900,2,3,1998/10/01 07:56:00,139.6029303429,35.6863695467,2,6,0720006,4 ,1 ,39,,12",
 "830300,2,3,1998/10/01 07:57:00,139.5883808827,35.4457622873,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 07:57:00,140.0899401995,35.6987431258,2,1,4410011,12,2 ,35,,9",
 "839500,2,4,1998/10/01 07:57:00,139.6317006839,35.486297761,2,11,1133001,14,7 ,27,,1",
 "96600,2,4,1998/10/01 07:57:00,140.1199233781,35.5451784396,1,3,4510101,13,2 ,33,,1",
 "841800,2,3,1998/10/01 07:57:00,139.6356656622,35.4808871928,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:57:00,139.6471758694,35.4877136874,1,9,1340109,10,1 ,33,,12",
 "869400,2,3,1998/10/01 07:57:00,139.6482801632,35.4854627001,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,3,1998/10/01 07:57:00,139.5294720195,35.3610932746,1,6,1230404,9 ,1 ,31,,12",
 "2300,2,3,1998/10/01 07:57:00,139.8986568122,35.7328004574,1,6,4111102,8 ,1 ,36,,12",
 "13800,2,3,1998/10/01 07:57:00,139.9398167006,35.786195481,2,14,4116004,5 ,5 ,25,,12",
 "18400,2,3,1998/10/01 07:57:00,139.7398752981,35.6922300404,1,9,4120311,8 ,1 ,44,,12",
 "434700,4,4,1998/10/01 07:57:00,139.7960537803,35.8151617632,2,8,3210107,8 ,1 ,37,,7",
 "32200,2,3,1998/10/01 07:57:00,139.804103308,35.6694831593,2,4,4128103,8 ,1 ,42,,12",
 "34500,2,3,1998/10/01 07:57:00,139.9722358714,35.7033392817,1,4,4129003,8 ,1 ,36,,12",
 "41400,2,3,1998/10/01 07:57:00,139.9170998192,35.8258238197,2,5,4212212,8 ,1 ,44,,10",
 "593400,2,2,1998/10/01 07:57:00,139.8191665563,35.68351003,1,6,0349009,9 ,1 ,58,,1",
 "71300,2,3,1998/10/01 07:57:00,139.7802529946,35.7413519933,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:57:00,139.9428477534,35.7041892139,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 07:57:00,140.0568459025,35.6603184471,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 07:57:00,140.0853345863,35.9086067968,2,4,5024120,13,2 ,44,,12",
 "209300,2,3,1998/10/01 07:57:00,139.6300701261,35.2900442727,1,13,2211303,10,1 ,37,,12",
 "227700,2,3,1998/10/01 07:57:00,139.6320815934,35.4770822349,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:57:00,139.750318355,35.6470760089,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:57:00,139.6545757099,35.4851531106,1,11,2922005,9 ,1 ,29,,12",
 "303600,2,3,1998/10/01 07:57:00,139.5547603284,35.6155675219,1,9,2923016,9 ,1 ,30,,12",
 "85100,2,2,1998/10/01 07:57:00,140.2639332202,35.7158283876,1,8,4417027,9 ,10,46,,6",
 "310500,2,3,1998/10/01 07:57:00,139.6634889565,35.4919050935,1,9,2013102,3 ,1 ,37,,12",
 "170200,2,2,1998/10/01 07:57:00,140.1977627015,36.086124609,2,3,5022220,13,2 ,43,,2",
 "216200,2,2,1998/10/01 07:57:00,139.6780056741,35.2461578574,2,1,2221106,12,2 ,34,,1",
 "255300,2,2,1998/10/01 07:57:00,139.1800892309,35.3038061827,1,12,2610311,8 ,1 ,44,,6",
 "269100,2,2,1998/10/01 07:57:00,139.338828625,35.4985437984,1,7,2811013,9 ,1 ,37,,3",
 "276000,2,2,1998/10/01 07:57:00,139.2674025108,35.3701483228,1,2,2821003,12,2 ,33,,1",
 "285200,2,2,1998/10/01 07:57:00,139.4495318005,35.4982087444,1,2,2911313,12,2 ,34,,1",
 "370300,2,4,1998/10/01 07:57:00,139.7397413298,35.6902558526,1,10,3037010,10,1 ,39,,1",
 "386400,2,4,1998/10/01 07:57:00,139.7600906586,35.670719945,1,10,3059002,8 ,1 ,37,,12",
 "331200,2,2,1998/10/01 07:57:00,139.5813705982,35.5707983428,2,1,2121208,12,2 ,28,,1",
 "404800,2,3,1998/10/01 07:57:00,139.4759360067,35.7467254231,2,9,3120009,8 ,1 ,30,,12",
 "437000,2,3,1998/10/01 07:57:00,139.7745221364,35.7008890884,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,3,1998/10/01 07:57:00,139.7981824777,35.8484317968,2,5,3221102,8 ,1 ,37,,12",
 "414000,2,2,1998/10/01 07:57:00,139.4657957319,35.7372446709,1,10,3122302,3 ,11,32,,6",
 "476100,2,4,1998/10/01 07:57:00,139.5733600232,35.8207298675,1,3,3411213,13,2 ,34,,1",
 "441600,4,4,1998/10/01 07:57:00,139.8578884573,35.7594389116,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 07:57:00,139.6990435259,35.8206991567,1,10,3510101,8 ,1 ,27,,12",
 "501400,2,2,1998/10/01 07:57:00,139.6566924493,35.9741315695,2,2,3613122,12,2 ,33,,1",
 "508300,2,4,1998/10/01 07:57:00,139.6698727778,36.0116853749,1,9,3622214,8 ,1 ,36,,1",
 "487600,2,2,1998/10/01 07:57:00,139.6084274245,35.9535509337,1,10,3512006,6 ,1 ,36,,6",
 "512900,2,2,1998/10/01 07:57:00,139.4542208253,36.1015386762,1,11,3632007,5 ,1 ,35,,2",
 "549700,2,2,1998/10/01 07:57:00,139.7362048078,35.5732852198,1,8,0131010,3 ,1 ,62,,2",
 "549700,3,3,1998/10/01 07:57:00,139.7362048078,35.5732852198,1,8,0131010,3 ,97,62,,97",
 "563500,2,3,1998/10/01 07:57:00,139.7248923161,35.7220755801,1,7,0212001,10,1 ,84,,12",
 "644000,2,3,1998/10/01 07:57:00,139.7291144845,35.7315030665,1,4,0522008,5 ,1 ,52,,12",
 "646300,2,3,1998/10/01 07:57:00,139.6810392882,35.7789931251,1,4,0523008,8 ,1 ,75,,12",
 "747500,2,4,1998/10/01 07:57:00,139.6254736012,35.4701758301,1,5,0810007,4 ,1 ,38,,1",
 "713000,2,2,1998/10/01 07:57:00,139.5095850436,35.762819561,2,2,0741304,12,2 ,30,,1",
 "811900,2,3,1998/10/01 07:57:00,139.6199914254,35.3809656916,1,3,1123201,13,2 ,31,,12",
 "648600,2,4,1998/10/01 07:57:00,139.7106553633,35.7778091385,2,3,0530007,13,2 ,37,,1",
 "768200,2,2,1998/10/01 07:57:00,139.3400117168,35.6711387719,2,2,0840002,12,2 ,36,,1",
 "788900,2,2,1998/10/01 07:57:00,139.2763575828,35.8224294317,2,2,0931009,12,2 ,35,,1",
 "786600,2,3,1998/10/01 07:57:00,139.338217812,35.7282277936,2,3,0930110,13,2 ,35,,12",
 "777400,2,3,1998/10/01 07:57:00,139.6465026329,35.653733375,1,5,0844010,8 ,1 ,41,,12",
 "782000,2,2,1998/10/01 07:57:00,139.3297660075,35.725622252,2,2,0911103,12,2 ,36,,1",
 "821100,2,2,1998/10/01 07:57:00,139.5704868397,35.4440866512,2,1,1213204,12,2 ,38,,1",
 "759000,2,3,1998/10/01 07:57:00,139.5623387848,35.6520097467,2,4,0821006,9 ,1 ,55,,12",
 "719900,2,3,1998/10/01 07:57:00,139.4463829805,35.6981970064,1,7,0752005,6 ,10,34,,10",
 "683100,2,3,1998/10/01 07:57:00,139.7860620139,35.6895005454,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 07:57:00,139.6457983647,35.6669674015,2,4,0711108,8 ,1 ,25,,12",
 "696900,2,3,1998/10/01 07:57:00,139.6069417174,35.6849060302,2,6,0720006,4 ,1 ,39,,12",
 "830300,2,3,1998/10/01 07:58:00,139.5771946173,35.4468190994,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 07:58:00,140.0897608121,35.6989895307,2,1,4410011,12,2 ,35,,9",
 "839500,2,4,1998/10/01 07:58:00,139.6326722646,35.4860051282,2,11,1133001,14,7 ,27,,1",
 "96600,2,4,1998/10/01 07:58:00,140.1291552082,35.5636208208,1,3,4510101,13,2 ,33,,1",
 "841800,2,3,1998/10/01 07:58:00,139.6410749232,35.4838612756,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:58:00,139.6448033411,35.4847282329,1,9,1340109,10,1 ,33,,12",
 "869400,2,3,1998/10/01 07:58:00,139.6537557423,35.4866346798,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,3,1998/10/01 07:58:00,139.530773625,35.3587236634,1,6,1230404,9 ,1 ,31,,12",
 "2300,2,3,1998/10/01 07:58:00,139.8945791777,35.7345118114,1,6,4111102,8 ,1 ,36,,12",
 "13800,2,3,1998/10/01 07:58:00,139.9386929764,35.7910048631,2,14,4116004,5 ,5 ,25,,12",
 "13800,2,4,1998/10/01 07:58:00,139.9386929764,35.7910048631,2,14,4116004,5 ,5 ,25,,10",
 "18400,2,3,1998/10/01 07:58:00,139.734757554,35.6908231816,1,9,4120311,8 ,1 ,44,,12",
 "434700,4,4,1998/10/01 07:58:00,139.7949567817,35.8165382267,2,8,3210107,8 ,1 ,37,,7",
 "34500,2,3,1998/10/01 07:58:00,139.9788062832,35.7051967251,1,4,4129003,8 ,1 ,36,,12",
 "32200,2,3,1998/10/01 07:58:00,139.7987278708,35.6707523785,2,4,4128103,8 ,1 ,42,,12",
 "41400,2,3,1998/10/01 07:58:00,139.9192294263,35.8255117674,2,5,4212212,8 ,1 ,44,,10",
 "593400,2,2,1998/10/01 07:58:00,139.8191662924,35.683510282,1,6,0349009,9 ,1 ,58,,1",
 "71300,2,3,1998/10/01 07:58:00,139.777941193,35.7389670192,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:58:00,139.9394553726,35.7010921489,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 07:58:00,140.0520957433,35.6643592782,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 07:58:00,140.0806228175,35.9054092779,2,4,5024120,13,2 ,44,,12",
 "209300,2,3,1998/10/01 07:58:00,139.6270333288,35.2954016455,1,13,2211303,10,1 ,37,,12",
 "227700,2,3,1998/10/01 07:58:00,139.6369900446,35.4821428306,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:58:00,139.755783496,35.6513031187,1,14,2412116,10,1 ,63,,12",
 "303600,2,3,1998/10/01 07:58:00,139.5597969648,35.6156118773,1,9,2923016,9 ,1 ,30,,12",
 "301300,2,3,1998/10/01 07:58:00,139.6596328888,35.4870752554,1,11,2922005,9 ,1 ,29,,12",
 "85100,2,2,1998/10/01 07:58:00,140.2623803571,35.7196643022,1,8,4417027,9 ,10,46,,6",
 "310500,2,3,1998/10/01 07:58:00,139.657125369,35.4859194642,1,9,2013102,3 ,1 ,37,,12",
 "170200,2,2,1998/10/01 07:58:00,140.1971758578,36.0870743167,2,3,5022220,13,2 ,43,,2",
 "216200,2,2,1998/10/01 07:58:00,139.6780360734,35.2459233173,2,1,2221106,12,2 ,34,,1",
 "345000,2,2,1998/10/01 07:58:00,139.7300537359,35.8057119518,1,8,3012002,9 ,1 ,37,,1",
 "345000,2,3,1998/10/01 07:58:00,139.7300537359,35.8057119518,1,8,3012002,9 ,1 ,37,,10",
 "255300,2,2,1998/10/01 07:58:00,139.1826855924,35.3045986501,1,12,2610311,8 ,1 ,44,,6",
 "269100,2,2,1998/10/01 07:58:00,139.3359800073,35.5003623826,1,7,2811013,9 ,1 ,37,,3",
 "276000,2,2,1998/10/01 07:58:00,139.2676988057,35.3698447134,1,2,2821003,12,2 ,33,,1",
 "285200,2,2,1998/10/01 07:58:00,139.4501599884,35.4970241376,1,2,2911313,12,2 ,34,,1",
 "370300,2,4,1998/10/01 07:58:00,139.740005939,35.6896419307,1,10,3037010,10,1 ,39,,1",
 "386400,2,4,1998/10/01 07:58:00,139.7592674328,35.6665720982,1,10,3059002,8 ,1 ,37,,12",
 "386400,2,5,1998/10/01 07:58:00,139.7592674328,35.6665720982,1,10,3059002,8 ,1 ,37,,1",
 "331200,2,2,1998/10/01 07:58:00,139.5812227382,35.5711539494,2,1,2121208,12,2 ,28,,1",
 "404800,2,3,1998/10/01 07:58:00,139.4796241664,35.7435560363,2,9,3120009,8 ,1 ,30,,12",
 "437000,2,3,1998/10/01 07:58:00,139.7755052214,35.6972216849,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,3,1998/10/01 07:58:00,139.7994858644,35.8457353692,2,5,3221102,8 ,1 ,37,,12",
 "414000,2,2,1998/10/01 07:58:00,139.4678152633,35.7368812728,1,10,3122302,3 ,11,32,,6",
 "476100,2,4,1998/10/01 07:58:00,139.5732042453,35.8193488429,1,3,3411213,13,2 ,34,,1",
 "441600,4,4,1998/10/01 07:58:00,139.8559849626,35.7582966533,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 07:58:00,139.7018634582,35.8179259972,1,10,3510101,8 ,1 ,27,,12",
 "501400,2,2,1998/10/01 07:58:00,139.6564158934,35.9745037124,2,2,3613122,12,2 ,33,,1",
 "508300,2,4,1998/10/01 07:58:00,139.6702164115,36.0109550332,1,9,3622214,8 ,1 ,36,,1",
 "487600,2,2,1998/10/01 07:58:00,139.608509752,35.9516112634,1,10,3512006,6 ,1 ,36,,6",
 "512900,2,2,1998/10/01 07:58:00,139.4553060656,36.1012772816,1,11,3632007,5 ,1 ,35,,2",
 "563500,2,3,1998/10/01 07:58:00,139.7272084916,35.7197735423,1,7,0212001,10,1 ,84,,12",
 "646300,2,3,1998/10/01 07:58:00,139.6866816538,35.7776671773,1,4,0523008,8 ,1 ,75,,12",
 "644000,2,3,1998/10/01 07:58:00,139.7335545008,35.7309920588,1,4,0522008,5 ,1 ,52,,12",
 "747500,2,4,1998/10/01 07:58:00,139.6270641033,35.4695194478,1,5,0810007,4 ,1 ,38,,1",
 "713000,2,2,1998/10/01 07:58:00,139.5088845948,35.7621392031,2,2,0741304,12,2 ,30,,1",
 "811900,2,3,1998/10/01 07:58:00,139.6169013575,35.3786444443,1,3,1123201,13,2 ,31,,12",
 "648600,2,4,1998/10/01 07:58:00,139.7098134334,35.7778287682,2,3,0530007,13,2 ,37,,1",
 "768200,2,2,1998/10/01 07:58:00,139.3394233724,35.6716023996,2,2,0840002,12,2 ,36,,1",
 "788900,2,2,1998/10/01 07:58:00,139.2760295714,35.8217191292,2,2,0931009,12,2 ,35,,1",
 "782000,2,2,1998/10/01 07:58:00,139.3296896542,35.725949874,2,2,0911103,12,2 ,36,,1",
 "786600,2,3,1998/10/01 07:58:00,139.3406879287,35.7247679861,2,3,0930110,13,2 ,35,,12",
 "777400,2,3,1998/10/01 07:58:00,139.6456475089,35.6502332374,1,5,0844010,8 ,1 ,41,,12",
 "821100,2,2,1998/10/01 07:58:00,139.5710505929,35.4441514689,2,1,1213204,12,2 ,38,,1",
 "759000,2,3,1998/10/01 07:58:00,139.5671229748,35.6542315524,2,4,0821006,9 ,1 ,55,,12",
 "696900,2,3,1998/10/01 07:58:00,139.6110202597,35.6835743282,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,3,1998/10/01 07:58:00,139.4465152061,35.6993383629,1,7,0752005,6 ,10,34,,10",
 "719900,2,4,1998/10/01 07:58:00,139.4465152061,35.6993383629,1,7,0752005,6 ,10,34,,12",
 "683100,2,3,1998/10/01 07:58:00,139.7835235374,35.691436053,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 07:58:00,139.6504528911,35.6683217369,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,3,1998/10/01 07:59:00,139.567433344,35.4420462186,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 07:59:00,140.0895814236,35.6992359353,2,1,4410011,12,2 ,35,,9",
 "839500,2,4,1998/10/01 07:59:00,139.6335898393,35.4860753514,2,11,1133001,14,7 ,27,,1",
 "96600,2,4,1998/10/01 07:59:00,140.1280824711,35.5829253409,1,3,4510101,13,2 ,33,,1",
 "841800,2,3,1998/10/01 07:59:00,139.6474394126,35.4854041568,1,2,1132110,12,2 ,33,,12",
 "860200,2,3,1998/10/01 07:59:00,139.6404740588,35.4834510705,1,9,1340109,10,1 ,33,,12",
 "869400,2,3,1998/10/01 07:59:00,139.657728157,35.4860994552,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,3,1998/10/01 07:59:00,139.5320478247,35.3563400153,1,6,1230404,9 ,1 ,31,,12",
 "2300,2,3,1998/10/01 07:59:00,139.8903794608,35.735931048,1,6,4111102,8 ,1 ,36,,12",
 "13800,2,4,1998/10/01 07:59:00,139.9387011875,35.7906286004,2,14,4116004,5 ,5 ,25,,10",
 "18400,2,3,1998/10/01 07:59:00,139.729461302,35.6911309546,1,9,4120311,8 ,1 ,44,,12",
 "434700,4,4,1998/10/01 07:59:00,139.7925620282,35.8161804888,2,8,3210107,8 ,1 ,37,,7",
 "34500,2,3,1998/10/01 07:59:00,139.9852209828,35.70737597,1,4,4129003,8 ,1 ,36,,12",
 "32200,2,3,1998/10/01 07:59:00,139.7936764813,35.6729149848,2,4,4128103,8 ,1 ,42,,12",
 "41400,2,3,1998/10/01 07:59:00,139.9211925175,35.8254442985,2,5,4212212,8 ,1 ,44,,10",
 "41400,2,4,1998/10/01 07:59:00,139.9211925175,35.8254442985,2,5,4212212,8 ,1 ,44,,12",
 "593400,2,2,1998/10/01 07:59:00,139.8191660286,35.683510534,1,6,0349009,9 ,1 ,58,,1",
 "71300,2,3,1998/10/01 07:59:00,139.7751784327,35.7359870007,1,12,4313309,9 ,1 ,34,,12",
 "78200,2,3,1998/10/01 07:59:00,139.935457113,35.6985433982,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 07:59:00,140.0473357373,35.6683933924,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 07:59:00,140.0759098587,35.9022131089,2,4,5024120,13,2 ,44,,12",
 "209300,2,3,1998/10/01 07:59:00,139.6250256458,35.3011627865,1,13,2211303,10,1 ,37,,12",
 "227700,2,3,1998/10/01 07:59:00,139.6441373796,35.4848051823,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 07:59:00,139.7576233997,35.657418116,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 07:59:00,139.6629187351,35.4907478735,1,11,2922005,9 ,1 ,29,,12",
 "303600,2,3,1998/10/01 07:59:00,139.5644057825,35.6171913421,1,9,2923016,9 ,1 ,30,,12",
 "85100,2,2,1998/10/01 07:59:00,140.2587426586,35.7207942509,1,8,4417027,9 ,10,46,,6",
 "310500,2,3,1998/10/01 07:59:00,139.6490469047,35.4855142909,1,9,2013102,3 ,1 ,37,,12",
 "170200,2,2,1998/10/01 07:59:00,140.1972155527,36.0877706715,2,3,5022220,13,2 ,43,,2",
 "216200,2,2,1998/10/01 07:59:00,139.6779344823,35.2457377469,2,1,2221106,12,2 ,34,,1",
 "345000,2,3,1998/10/01 07:59:00,139.7269668383,35.8063682493,1,8,3012002,9 ,1 ,37,,10",
 "255300,2,2,1998/10/01 07:59:00,139.1812569698,35.3044432242,1,12,2610311,8 ,1 ,44,,6",
 "269100,2,2,1998/10/01 07:59:00,139.3328309297,35.5010816002,1,7,2811013,9 ,1 ,37,,3",
 "276000,2,2,1998/10/01 07:59:00,139.2678990181,35.3694603051,1,2,2821003,12,2 ,33,,1",
 "285200,2,2,1998/10/01 07:59:00,139.4506261907,35.4958002115,1,2,2911313,12,2 ,34,,1",
 "370300,2,4,1998/10/01 07:59:00,139.7402604119,35.6890251437,1,10,3037010,10,1 ,39,,1",
 "386400,2,5,1998/10/01 07:59:00,139.7590608509,35.665883591,1,10,3059002,8 ,1 ,37,,1",
 "331200,2,2,1998/10/01 07:59:00,139.5811164515,35.5715198553,2,1,2121208,12,2 ,28,,1",
 "404800,2,3,1998/10/01 07:59:00,139.4833162663,35.740389811,2,9,3120009,8 ,1 ,30,,12",
 "437000,2,3,1998/10/01 07:59:00,139.7771030133,35.6926594887,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,3,1998/10/01 07:59:00,139.8007935328,35.843040381,2,5,3221102,8 ,1 ,37,,12",
 "414000,2,2,1998/10/01 07:59:00,139.4701020912,35.7367262557,1,10,3122302,3 ,11,32,,6",
 "476100,2,4,1998/10/01 07:59:00,139.5728396783,35.8179968145,1,3,3411213,13,2 ,34,,1",
 "441600,4,4,1998/10/01 07:59:00,139.8541330665,35.7571333524,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 07:59:00,139.7046811044,35.8151495633,1,10,3510101,8 ,1 ,27,,12",
 "501400,2,2,1998/10/01 07:59:00,139.6561014795,35.9746968627,2,2,3613122,12,2 ,33,,1",
 "487600,2,2,1998/10/01 07:59:00,139.6085097161,35.9496356751,1,10,3512006,6 ,1 ,36,,6",
 "508300,2,4,1998/10/01 07:59:00,139.6708715482,36.0106122029,1,9,3622214,8 ,1 ,36,,1",
 "512900,2,2,1998/10/01 07:59:00,139.4554717797,36.1006963953,1,11,3632007,5 ,1 ,35,,2",
 "563500,2,3,1998/10/01 07:59:00,139.728532226,35.7169858029,1,7,0212001,10,1 ,84,,12",
 "646300,2,3,1998/10/01 07:59:00,139.6931135172,35.7778762604,1,4,0523008,8 ,1 ,75,,12",
 "644000,2,3,1998/10/01 07:59:00,139.7376712643,35.7325452606,1,4,0522008,5 ,1 ,52,,12",
 "747500,2,4,1998/10/01 07:59:00,139.6280503863,35.4676606073,1,5,0810007,4 ,1 ,38,,1",
 "713000,2,2,1998/10/01 07:59:00,139.5083806228,35.7613300055,2,2,0741304,12,2 ,30,,1",
 "811900,2,3,1998/10/01 07:59:00,139.6127737084,35.3777592936,1,3,1123201,13,2 ,31,,12",
 "648600,2,4,1998/10/01 07:59:00,139.7089715032,35.777848392,2,3,0530007,13,2 ,37,,1",
 "768200,2,2,1998/10/01 07:59:00,139.3388507542,35.6720786327,2,2,0840002,12,2 ,36,,1",
 "777400,2,3,1998/10/01 07:59:00,139.6448029874,35.6467316907,1,5,0844010,8 ,1 ,41,,12",
 "788900,2,2,1998/10/01 07:59:00,139.2757015658,35.8210088257,2,2,0931009,12,2 ,35,,1",
 "786600,2,3,1998/10/01 07:59:00,139.343474364,35.7214738652,2,3,0930110,13,2 ,35,,12",
 "782000,2,2,1998/10/01 07:59:00,139.3294761809,35.7260820021,2,2,0911103,12,2 ,36,,1",
 "821100,2,2,1998/10/01 07:59:00,139.5711023326,35.4441658891,2,1,1213204,12,2 ,38,,1",
 "759000,2,3,1998/10/01 07:59:00,139.5718876402,35.6564813175,2,4,0821006,9 ,1 ,55,,12",
 "867100,2,2,1998/10/01 07:59:00,139.4804040534,35.429436375,2,2,1241102,12,2 ,29,,1",
 "719900,2,4,1998/10/01 07:59:00,139.450214606,35.6993524134,1,7,0752005,6 ,10,34,,12",
 "683100,2,3,1998/10/01 07:59:00,139.7811448167,35.6935059334,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 07:59:00,139.655103226,35.6696880495,2,4,0711108,8 ,1 ,25,,12",
 "696900,2,3,1998/10/01 07:59:00,139.6153834791,35.6832293304,2,6,0720006,4 ,1 ,39,,12",
 "830300,2,3,1998/10/01 08:00:00,139.5576244827,35.4374462259,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:00:00,140.089402034,35.6994823397,2,1,4410011,12,2 ,35,,9",
 "839500,2,4,1998/10/01 08:00:00,139.6344472251,35.4865500642,2,11,1133001,14,7 ,27,,1",
 "839500,3,5,1998/10/01 08:00:00,139.6344472251,35.4865500642,2,11,1133001,14,97,27,,97",
 "841800,2,3,1998/10/01 08:00:00,139.653811458,35.4866773588,1,2,1132110,12,2 ,33,,12",
 "841800,2,4,1998/10/01 08:00:00,139.653811458,35.4866773588,1,2,1132110,12,2 ,33,,1",
 "96600,2,4,1998/10/01 08:00:00,140.1285444031,35.5996000195,1,3,4510101,13,2 ,33,,1",
 "96600,3,5,1998/10/01 08:00:00,140.1285444031,35.5996000195,1,3,4510101,13,97,33,,97",
 "860200,2,3,1998/10/01 08:00:00,139.6365942558,35.4815258177,1,9,1340109,10,1 ,33,,12",
 "871700,2,3,1998/10/01 08:00:00,139.5312133849,35.3538327966,1,6,1230404,9 ,1 ,31,,12",
 "871700,2,4,1998/10/01 08:00:00,139.5312133849,35.3538327966,1,6,1230404,9 ,1 ,31,,1",
 "869400,2,3,1998/10/01 08:00:00,139.6619186153,35.4891951224,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,3,1998/10/01 08:00:00,139.8858804417,35.7355121521,1,6,4111102,8 ,1 ,36,,12",
 "13800,2,4,1998/10/01 08:00:00,139.9391316531,35.7905685549,2,14,4116004,5 ,5 ,25,,10",
 "18400,2,3,1998/10/01 08:00:00,139.724302808,35.6922716146,1,9,4120311,8 ,1 ,44,,12",
 "434700,4,4,1998/10/01 08:00:00,139.7900850765,35.8164608795,2,8,3210107,8 ,1 ,37,,7",
 "34500,2,3,1998/10/01 08:00:00,139.992144334,35.7082602568,1,4,4129003,8 ,1 ,36,,12",
 "32200,2,3,1998/10/01 08:00:00,139.7891941988,35.6757253238,2,4,4128103,8 ,1 ,42,,12",
 "41400,2,4,1998/10/01 08:00:00,139.9248055416,35.8301131434,2,5,4212212,8 ,1 ,44,,12",
 "593400,2,2,1998/10/01 08:00:00,139.8191657648,35.683510786,1,6,0349009,9 ,1 ,58,,1",
 "593400,2,3,1998/10/01 08:00:00,139.8191657648,35.683510786,1,6,0349009,9 ,1 ,58,,10",
 "78200,2,3,1998/10/01 08:00:00,139.9313261512,35.6961156454,1,12,4413008,8 ,1 ,44,,12",
 "71300,2,3,1998/10/01 08:00:00,139.7710934988,35.73420142,1,12,4313309,9 ,1 ,34,,12",
 "151800,2,4,1998/10/01 08:00:00,140.0425876751,35.672437023,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:00:00,140.0704672993,35.9002271544,2,4,5024120,13,2 ,44,,12",
 "209300,2,3,1998/10/01 08:00:00,139.6239596827,35.3069262722,1,13,2211303,10,1 ,37,,12",
 "227700,2,3,1998/10/01 08:00:00,139.6518881521,35.4859293668,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 08:00:00,139.758544086,35.6636533353,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 08:00:00,139.6657717939,35.4946356446,1,11,2922005,9 ,1 ,29,,12",
 "85100,2,2,1998/10/01 08:00:00,140.2537604164,35.7204474396,1,8,4417027,9 ,10,46,,6",
 "303600,2,3,1998/10/01 08:00:00,139.5684029767,35.619582015,1,9,2923016,9 ,1 ,30,,12",
 "310500,2,3,1998/10/01 08:00:00,139.6394203794,35.4833421695,1,9,2013102,3 ,1 ,37,,12",
 "170200,2,2,1998/10/01 08:00:00,140.1960294773,36.0881923125,2,3,5022220,13,2 ,43,,2",
 "170200,3,3,1998/10/01 08:00:00,140.1960294773,36.0881923125,2,3,5022220,13,97,43,,97",
 "216200,2,2,1998/10/01 08:00:00,139.677699719,35.2456015843,2,1,2221106,12,2 ,34,,1",
 "216200,3,3,1998/10/01 08:00:00,139.677699719,35.2456015843,2,1,2221106,12,97,34,,97",
 "345000,2,3,1998/10/01 08:00:00,139.7239531901,35.8057902686,1,8,3012002,9 ,1 ,37,,10",
 "255300,2,2,1998/10/01 08:00:00,139.1849377649,35.3045913498,1,12,2610311,8 ,1 ,44,,6",
 "269100,2,2,1998/10/01 08:00:00,139.3333560101,35.4995901738,1,7,2811013,9 ,1 ,37,,3",
 "269100,3,3,1998/10/01 08:00:00,139.3333560101,35.4995901738,1,7,2811013,9 ,97,37,,97",
 "276000,2,2,1998/10/01 08:00:00,139.2683348843,35.3690857372,1,2,2821003,12,2 ,33,,1",
 "276000,3,3,1998/10/01 08:00:00,139.2683348843,35.3690857372,1,2,2821003,12,97,33,,97",
 "285200,2,2,1998/10/01 08:00:00,139.4507504371,35.4955740055,1,2,2911313,12,2 ,34,,1",
 "285200,3,3,1998/10/01 08:00:00,139.4507504371,35.4955740055,1,2,2911313,12,97,34,,97",
 "370300,2,4,1998/10/01 08:00:00,139.7405118524,35.6884075332,1,10,3037010,10,1 ,39,,1",
 "386400,2,5,1998/10/01 08:00:00,139.7587963303,35.6652114051,1,10,3059002,8 ,1 ,37,,1",
 "331200,2,2,1998/10/01 08:00:00,139.5808529514,35.5716687271,2,1,2121208,12,2 ,28,,1",
 "404800,2,3,1998/10/01 08:00:00,139.4876726723,35.7378922203,2,9,3120009,8 ,1 ,30,,12",
 "437000,2,3,1998/10/01 08:00:00,139.7802863915,35.6886688267,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,3,1998/10/01 08:00:00,139.8020019237,35.8403147626,2,5,3221102,8 ,1 ,37,,12",
 "414000,2,2,1998/10/01 08:00:00,139.46978008,35.7382201587,1,10,3122302,3 ,11,32,,6",
 "414000,3,3,1998/10/01 08:00:00,139.46978008,35.7382201587,1,10,3122302,3 ,97,32,,97",
 "441600,4,4,1998/10/01 08:00:00,139.852763533,35.7555627792,1,9,3215009,9 ,11,30,,8",
 "476100,2,4,1998/10/01 08:00:00,139.5722341668,35.816697941,1,3,3411213,13,2 ,34,,1",
 "483000,2,3,1998/10/01 08:00:00,139.7074332168,35.8123296393,1,10,3510101,8 ,1 ,27,,12",
 "487600,2,2,1998/10/01 08:00:00,139.6083613003,35.9476682247,1,10,3512006,6 ,1 ,36,,6",
 "508300,2,4,1998/10/01 08:00:00,139.6718327671,36.0106501288,1,9,3622214,8 ,1 ,36,,1",
 "508300,3,5,1998/10/01 08:00:00,139.6718327671,36.0106501288,1,9,3622214,8 ,97,36,,97",
 "501400,2,2,1998/10/01 08:00:00,139.6556936042,35.9745636336,2,2,3613122,12,2 ,33,,1",
 "501400,3,3,1998/10/01 08:00:00,139.6556936042,35.9745636336,2,2,3613122,12,97,33,,97",
 "512900,2,2,1998/10/01 08:00:00,139.4549506489,36.0998808808,1,11,3632007,5 ,1 ,35,,2",
 "512900,3,3,1998/10/01 08:00:00,139.4549506489,36.0998808808,1,11,3632007,5 ,97,35,,97",
 "563500,2,3,1998/10/01 08:00:00,139.7298559715,35.7141980802,1,7,0212001,10,1 ,84,,12",
 "648600,2,4,1998/10/01 08:00:00,139.7089585476,35.7779681046,2,3,0530007,13,2 ,37,,1",
 "648600,3,5,1998/10/01 08:00:00,139.7089585476,35.7779681046,2,3,0530007,13,97,37,,97",
 "644000,2,3,1998/10/01 08:00:00,139.7396586748,35.7325420238,1,4,0522008,5 ,1 ,52,,12",
 "646300,2,3,1998/10/01 08:00:00,139.6977322292,35.7738088685,1,4,0523008,8 ,1 ,75,,12",
 "713000,2,2,1998/10/01 08:00:00,139.507934713,35.7609582291,2,2,0741304,12,2 ,30,,1",
 "713000,3,3,1998/10/01 08:00:00,139.507934713,35.7609582291,2,2,0741304,12,97,30,,97",
 "747500,2,4,1998/10/01 08:00:00,139.6295373192,35.4647339057,1,5,0810007,4 ,1 ,38,,1",
 "747500,3,5,1998/10/01 08:00:00,139.6295373192,35.4647339057,1,5,0810007,4 ,97,38,,97",
 "759000,2,3,1998/10/01 08:00:00,139.5766738326,35.6587002324,2,4,0821006,9 ,1 ,55,,12",
 "768200,2,2,1998/10/01 08:00:00,139.3381291925,35.6722847625,2,2,0840002,12,2 ,36,,1",
 "782000,2,2,1998/10/01 08:00:00,139.3290911612,35.7259695513,2,2,0911103,12,2 ,36,,1",
 "782000,3,3,1998/10/01 08:00:00,139.3290911612,35.7259695513,2,2,0911103,12,97,36,,97",
 "811900,2,3,1998/10/01 08:00:00,139.6085724867,35.3781130087,1,3,1123201,13,2 ,31,,12",
 "788900,2,2,1998/10/01 08:00:00,139.275373566,35.8202985213,2,2,0931009,12,2 ,35,,1",
 "786600,2,3,1998/10/01 08:00:00,139.3472141251,35.7189151109,2,3,0930110,13,2 ,35,,12",
 "821100,2,2,1998/10/01 08:00:00,139.5721176144,35.4446898411,2,1,1213204,12,2 ,38,,1",
 "683100,2,3,1998/10/01 08:00:00,139.7785590929,35.6953744053,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 08:00:00,139.6596844077,35.6712012062,2,4,0711108,8 ,1 ,25,,12",
 "777400,2,3,1998/10/01 08:00:00,139.646500165,35.6435164835,1,5,0844010,8 ,1 ,41,,12",
 "777400,2,4,1998/10/01 08:00:00,139.646500165,35.6435164835,1,5,0844010,8 ,1 ,41,,1",
 "867100,2,2,1998/10/01 08:00:00,139.4817547996,35.429783322,2,2,1241102,12,2 ,29,,1",
 "696900,2,3,1998/10/01 08:00:00,139.6197479812,35.6828455939,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:00:00,139.4539119924,35.6994243241,1,7,0752005,6 ,10,34,,12",
 "830300,2,3,1998/10/01 08:01:00,139.5605824926,35.4413652546,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:01:00,140.0894894993,35.6997192873,2,1,4410011,12,2 ,35,,9",
 "841800,2,4,1998/10/01 08:01:00,139.6544694874,35.4863245563,1,2,1132110,12,2 ,33,,1",
 "860200,2,3,1998/10/01 08:01:00,139.633812761,35.4785234556,1,9,1340109,10,1 ,33,,12",
 "280600,5,5,1998/10/01 08:01:00,139.7357849856,35.6461536008,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:01:00,139.6643827424,35.4934116277,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,4,1998/10/01 08:01:00,139.5306231418,35.3530948608,1,6,1230404,9 ,1 ,31,,1",
 "2300,2,3,1998/10/01 08:01:00,139.8820570783,35.7334744132,1,6,4111102,8 ,1 ,36,,12",
 "13800,2,4,1998/10/01 08:01:00,139.9392414746,35.7901669763,2,14,4116004,5 ,5 ,25,,10",
 "18400,2,3,1998/10/01 08:01:00,139.7190328939,35.6916962866,1,9,4120311,8 ,1 ,44,,12",
 "434700,4,4,1998/10/01 08:01:00,139.7875357889,35.8162985524,2,8,3210107,8 ,1 ,37,,7",
 "34500,2,3,1998/10/01 08:01:00,139.9986668454,35.7102051301,1,4,4129003,8 ,1 ,36,,12",
 "32200,2,3,1998/10/01 08:01:00,139.7841218007,35.6776957372,2,4,4128103,8 ,1 ,42,,12",
 "41400,2,4,1998/10/01 08:01:00,139.93057086,35.8331444802,2,5,4212212,8 ,1 ,44,,12",
 "593400,2,3,1998/10/01 08:01:00,139.8191656738,35.683510873,1,6,0349009,9 ,1 ,58,,10",
 "71300,2,3,1998/10/01 08:01:00,139.7670425592,35.732398908,1,12,4313309,9 ,1 ,34,,12",
 "71300,2,4,1998/10/01 08:01:00,139.7670425592,35.732398908,1,12,4313309,9 ,1 ,34,,1",
 "78200,2,3,1998/10/01 08:01:00,139.9273228524,35.6935600427,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:01:00,140.037835692,35.676477798,1,9,4031206,8 ,1 ,38,,12",
 "828000,2,2,1998/10/01 08:01:00,139.5050650606,35.5044082313,1,5,1252001,4 ,1 ,38,,1",
 "172500,2,3,1998/10/01 08:01:00,140.0650938833,35.8985023508,2,4,5024120,13,2 ,44,,12",
 "209300,2,3,1998/10/01 08:01:00,139.6245790494,35.312961512,1,13,2211303,10,1 ,37,,12",
 "211600,2,2,1998/10/01 08:01:00,139.7332572667,35.2416719268,1,7,2220004,6 ,1 ,36,,1",
 "225400,2,2,1998/10/01 08:01:00,139.522280293,35.343967959,1,3,2313201,13,2 ,36,,3",
 "227700,2,3,1998/10/01 08:01:00,139.6578364951,35.4861318042,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 08:01:00,139.7621200332,35.6664580071,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 08:01:00,139.6697162843,35.4979100507,1,11,2922005,9 ,1 ,29,,12",
 "303600,2,3,1998/10/01 08:01:00,139.5709150625,35.62314523,1,9,2923016,9 ,1 ,30,,12",
 "85100,2,2,1998/10/01 08:01:00,140.2487274849,35.7199348864,1,8,4417027,9 ,10,46,,6",
 "310500,2,3,1998/10/01 08:01:00,139.6325182715,35.4775453281,1,9,2013102,3 ,1 ,37,,12",
 "322000,2,2,1998/10/01 08:01:00,139.6383704184,35.5853799407,2,5,2032102,8 ,1 ,36,,1",
 "345000,2,3,1998/10/01 08:01:00,139.7218467432,35.8047422177,1,8,3012002,9 ,1 ,37,,10",
 "255300,2,2,1998/10/01 08:01:00,139.1877547547,35.3034549819,1,12,2610311,8 ,1 ,44,,6",
 "271400,2,2,1998/10/01 08:01:00,139.3139433753,35.4720336199,1,7,2811315,9 ,11,37,,8",
 "289800,2,2,1998/10/01 08:01:00,139.4311571358,35.4620141623,2,3,2913122,12,2 ,38,,1",
 "370300,2,4,1998/10/01 08:01:00,139.7407614765,35.6877894298,1,10,3037010,10,1 ,39,,1",
 "305900,2,2,1998/10/01 08:01:00,139.3727603149,35.5530324982,2,9,2924003,3 ,1 ,34,,2",
 "386400,2,5,1998/10/01 08:01:00,139.7585296977,35.6645510882,1,10,3059002,8 ,1 ,37,,1",
 "331200,2,2,1998/10/01 08:01:00,139.580409978,35.5716556682,2,1,2121208,12,2 ,28,,1",
 "404800,2,3,1998/10/01 08:01:00,139.4922376608,35.7356047001,2,9,3120009,8 ,1 ,30,,12",
 "374900,2,2,1998/10/01 08:01:00,139.6370250443,35.8693134829,1,4,3040108,4 ,1 ,71,,6",
 "425500,2,2,1998/10/01 08:01:00,139.4434325688,35.9227417719,1,4,3134008,8 ,1 ,45,,1",
 "437000,2,3,1998/10/01 08:01:00,139.7831600629,35.6846192851,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,3,1998/10/01 08:01:00,139.8032102319,35.8375891307,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,2,1998/10/01 08:01:00,139.3886621673,35.9512077838,2,10,3411010,8 ,1 ,30,,2",
 "441600,4,4,1998/10/01 08:01:00,139.8514025092,35.7539874416,1,9,3215009,9 ,11,30,,8",
 "476100,2,4,1998/10/01 08:01:00,139.5716033569,35.8154070516,1,3,3411213,13,2 ,34,,1",
 "483000,2,3,1998/10/01 08:01:00,139.7102238199,35.8095350446,1,10,3510101,8 ,1 ,27,,12",
 "478400,2,2,1998/10/01 08:01:00,139.3818874543,36.0114833566,1,4,3413105,13,2 ,40,,1",
 "487600,2,2,1998/10/01 08:01:00,139.6074010066,35.9458636936,1,10,3512006,6 ,1 ,36,,6",
 "563500,2,3,1998/10/01 08:01:00,139.7312573898,35.7114361722,1,7,0212001,10,1 ,84,,12",
 "646300,2,3,1998/10/01 08:01:00,139.7018269219,35.7693766857,1,4,0523008,8 ,1 ,75,,12",
 "644000,2,3,1998/10/01 08:01:00,139.7426764178,35.7297654972,1,4,0522008,5 ,1 ,52,,12",
 "823400,2,2,1998/10/01 08:01:00,139.5807717073,35.4576829205,1,10,1213105,9 ,13,34,,1",
 "811900,2,3,1998/10/01 08:01:00,139.6044886296,35.3791607911,1,3,1123201,13,2 ,31,,12",
 "745200,2,2,1998/10/01 08:01:00,139.414450991,35.743821709,2,10,0790316,3 ,1 ,30,,2",
 "742900,2,2,1998/10/01 08:01:00,139.3494943316,35.7138690711,1,8,0784113,10,11,38,,7",
 "768200,2,2,1998/10/01 08:01:00,139.3379585512,35.6728659117,2,2,0840002,12,2 ,36,,1",
 "761300,2,2,1998/10/01 08:01:00,139.4457889503,35.6222717257,1,1,0821303,12,2 ,35,,1",
 "798100,2,2,1998/10/01 08:01:00,139.6465657099,35.4970329201,2,6,1421008,9 ,1 ,37,,6",
 "788900,2,2,1998/10/01 08:01:00,139.2750455719,35.8195882159,2,2,0931009,12,2 ,35,,1",
 "777400,2,4,1998/10/01 08:01:00,139.6469640787,35.6430355841,1,5,0844010,8 ,1 ,41,,1",
 "786600,2,3,1998/10/01 08:01:00,139.3511077808,35.7164769479,2,3,0930110,13,2 ,35,,12",
 "660100,2,2,1998/10/01 08:01:00,139.8014041451,35.7664180436,1,7,0612111,9 ,1 ,60,,1",
 "821100,2,2,1998/10/01 08:01:00,139.5731329093,35.4452137846,2,1,1213204,12,2 ,38,,1",
 "759000,2,3,1998/10/01 08:01:00,139.581439315,35.6609484639,2,4,0821006,9 ,1 ,55,,12",
 "876300,2,2,1998/10/01 08:01:00,139.4902284606,35.3849156248,1,9,1223102,9 ,1 ,27,,7",
 "867100,2,2,1998/10/01 08:01:00,139.4831055575,35.4301302539,2,2,1241102,12,2 ,29,,1",
 "696900,2,3,1998/10/01 08:01:00,139.6240669244,35.6821911027,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:01:00,139.4576067687,35.6995734161,1,7,0752005,6 ,10,34,,12",
 "683100,2,3,1998/10/01 08:01:00,139.7751049385,35.695591243,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 08:01:00,139.6642473442,35.6727517881,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,3,1998/10/01 08:02:00,139.5670552027,35.4490190306,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:02:00,140.0896997187,35.6999487846,2,1,4410011,12,2 ,35,,9",
 "841800,2,4,1998/10/01 08:02:00,139.6551495951,35.4862003111,1,2,1132110,12,2 ,33,,1",
 "860200,2,3,1998/10/01 08:02:00,139.631060709,35.4759644946,1,9,1340109,10,1 ,33,,12",
 "871700,2,4,1998/10/01 08:02:00,139.5300329093,35.3523569222,1,6,1230404,9 ,1 ,31,,1",
 "280600,5,5,1998/10/01 08:02:00,139.7365695363,35.6489011851,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:02:00,139.6683729769,35.4967742296,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,3,1998/10/01 08:02:00,139.8785384965,35.731075652,1,6,4111102,8 ,1 ,36,,12",
 "13800,2,4,1998/10/01 08:02:00,139.9393512951,35.7897653976,2,14,4116004,5 ,5 ,25,,10",
 "18400,2,3,1998/10/01 08:02:00,139.7137480392,35.6911827699,1,9,4120311,8 ,1 ,44,,12",
 "434700,4,4,1998/10/01 08:02:00,139.7849885291,35.8161166719,2,8,3210107,8 ,1 ,37,,7",
 "32200,2,3,1998/10/01 08:02:00,139.7793905453,35.6802834058,2,4,4128103,8 ,1 ,42,,12",
 "34500,2,3,1998/10/01 08:02:00,140.0053963636,35.7117960622,1,4,4129003,8 ,1 ,36,,12",
 "41400,2,4,1998/10/01 08:02:00,139.9364542286,35.8360367526,2,5,4212212,8 ,1 ,44,,12",
 "593400,2,3,1998/10/01 08:02:00,139.8191655828,35.6835109599,1,6,0349009,9 ,1 ,58,,10",
 "71300,2,4,1998/10/01 08:02:00,139.7680646937,35.7328221656,1,12,4313309,9 ,1 ,34,,1",
 "78200,2,3,1998/10/01 08:02:00,139.9237485604,35.6906027578,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:02:00,140.0330842157,35.6805191608,1,9,4031206,8 ,1 ,38,,12",
 "828000,2,2,1998/10/01 08:02:00,139.5050653962,35.5044092616,1,5,1252001,4 ,1 ,38,,1",
 "172500,2,3,1998/10/01 08:02:00,140.0618434303,35.8942734401,2,4,5024120,13,2 ,44,,12",
 "209300,2,3,1998/10/01 08:02:00,139.6221889523,35.3181597116,1,13,2211303,10,1 ,37,,12",
 "211600,2,2,1998/10/01 08:02:00,139.7332568698,35.2416721999,1,7,2220004,6 ,1 ,36,,1",
 "225400,2,2,1998/10/01 08:02:00,139.5234660702,35.3447428885,1,3,2313201,13,2 ,36,,3",
 "227700,2,3,1998/10/01 08:02:00,139.6629703481,35.4908526197,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 08:02:00,139.7678883121,35.6703958683,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 08:02:00,139.6735623778,35.5012612062,1,11,2922005,9 ,1 ,29,,12",
 "303600,2,3,1998/10/01 08:02:00,139.5733014942,35.6267676629,1,9,2923016,9 ,1 ,30,,12",
 "85100,2,2,1998/10/01 08:02:00,140.2436896607,35.7189327732,1,8,4417027,9 ,10,46,,6",
 "310500,2,3,1998/10/01 08:02:00,139.6265758359,35.4709185015,1,9,2013102,3 ,1 ,37,,12",
 "322000,2,2,1998/10/01 08:02:00,139.6371516227,35.5855384826,2,5,2032102,8 ,1 ,36,,1",
 "345000,2,3,1998/10/01 08:02:00,139.7185818149,35.8048677445,1,8,3012002,9 ,1 ,37,,10",
 "255300,2,2,1998/10/01 08:02:00,139.1906235502,35.3017500592,1,12,2610311,8 ,1 ,44,,6",
 "271400,2,2,1998/10/01 08:02:00,139.3154121114,35.4714116789,1,7,2811315,9 ,11,37,,8",
 "289800,2,2,1998/10/01 08:02:00,139.4321131065,35.4613735798,2,3,2913122,12,2 ,38,,1",
 "370300,2,4,1998/10/01 08:02:00,139.7414797921,35.6878062968,1,10,3037010,10,1 ,39,,1",
 "305900,2,2,1998/10/01 08:02:00,139.3707572313,35.552673348,2,9,2924003,3 ,1 ,34,,2",
 "386400,2,5,1998/10/01 08:02:00,139.7581147034,35.6639331618,1,10,3059002,8 ,1 ,37,,1",
 "331200,2,2,1998/10/01 08:02:00,139.5799990915,35.5718169407,2,1,2121208,12,2 ,28,,1",
 "404800,2,3,1998/10/01 08:02:00,139.4968029266,35.7333177683,2,9,3120009,8 ,1 ,30,,12",
 "425500,2,2,1998/10/01 08:02:00,139.4435008152,35.9242117205,1,4,3134008,8 ,1 ,45,,1",
 "374900,2,2,1998/10/01 08:02:00,139.6375460668,35.8660202566,1,4,3040108,4 ,1 ,71,,6",
 "437000,2,3,1998/10/01 08:02:00,139.7804479167,35.680408397,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,3,1998/10/01 08:02:00,139.803917694,35.8347687387,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,2,1998/10/01 08:02:00,139.3884662189,35.9520220461,2,10,3411010,8 ,1 ,30,,2",
 "441600,4,4,1998/10/01 08:02:00,139.8500768627,35.7523922602,1,9,3215009,9 ,11,30,,8",
 "476100,2,4,1998/10/01 08:02:00,139.5710877598,35.8140826575,1,3,3411213,13,2 ,34,,1",
 "478400,2,2,1998/10/01 08:02:00,139.3824644391,36.0112248171,1,4,3413105,13,2 ,40,,1",
 "483000,2,3,1998/10/01 08:02:00,139.7130109914,35.8067381729,1,10,3510101,8 ,1 ,27,,12",
 "487600,2,2,1998/10/01 08:02:00,139.6060314359,35.9442322623,1,10,3512006,6 ,1 ,36,,6",
 "563500,2,3,1998/10/01 08:02:00,139.7337513703,35.7094126097,1,7,0212001,10,1 ,84,,12",
 "644000,2,3,1998/10/01 08:02:00,139.745971486,35.7272204874,1,4,0522008,5 ,1 ,52,,12",
 "823400,2,2,1998/10/01 08:02:00,139.582856045,35.4597535444,1,10,1213105,9 ,13,34,,1",
 "811900,2,3,1998/10/01 08:02:00,139.6002705911,35.3796000724,1,3,1123201,13,2 ,31,,12",
 "646300,2,3,1998/10/01 08:02:00,139.7043631402,35.7642418047,1,4,0523008,8 ,1 ,75,,12",
 "742900,2,2,1998/10/01 08:02:00,139.3499758807,35.7161922185,1,8,0784113,10,11,38,,7",
 "745200,2,2,1998/10/01 08:02:00,139.4151528616,35.7426447042,2,10,0790316,3 ,1 ,30,,2",
 "761300,2,2,1998/10/01 08:02:00,139.4460189607,35.6212938235,1,1,0821303,12,2 ,35,,1",
 "768200,2,2,1998/10/01 08:02:00,139.3376371568,35.6733164383,2,2,0840002,12,2 ,36,,1",
 "788900,2,2,1998/10/01 08:02:00,139.2747175836,35.8188779097,2,2,0931009,12,2 ,35,,1",
 "786600,2,3,1998/10/01 08:02:00,139.3536612676,35.7130740259,2,3,0930110,13,2 ,35,,12",
 "798100,2,2,1998/10/01 08:02:00,139.6397336902,35.4958431712,2,6,1421008,9 ,1 ,37,,6",
 "777400,2,4,1998/10/01 08:02:00,139.6469775108,35.6421092988,1,5,0844010,8 ,1 ,41,,1",
 "660100,2,2,1998/10/01 08:02:00,139.7998936225,35.7676104776,1,7,0612111,9 ,1 ,60,,1",
 "821100,2,2,1998/10/01 08:02:00,139.5724069783,35.4449150591,2,1,1213204,12,2 ,38,,1",
 "759000,2,3,1998/10/01 08:02:00,139.5864456399,35.6628150693,2,4,0821006,9 ,1 ,55,,12",
 "867100,2,2,1998/10/01 08:02:00,139.484456327,35.4304771708,2,2,1241102,12,2 ,29,,1",
 "876300,2,2,1998/10/01 08:02:00,139.4894320321,35.3856546289,1,9,1223102,9 ,1 ,27,,7",
 "696900,2,3,1998/10/01 08:02:00,139.6283168761,35.6813214761,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:02:00,139.4613056232,35.6996277833,1,7,0752005,6 ,10,34,,12",
 "683100,2,3,1998/10/01 08:02:00,139.7716312191,35.6957321611,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 08:02:00,139.6688484091,35.6742242458,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,3,1998/10/01 08:03:00,139.5734709238,35.4567054938,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:03:00,140.0899197022,35.7001722319,2,1,4410011,12,2 ,35,,9",
 "841800,2,4,1998/10/01 08:03:00,139.6558652219,35.4864677119,1,2,1132110,12,2 ,33,,1",
 "860200,2,3,1998/10/01 08:03:00,139.6283151759,35.4729397009,1,9,1340109,10,1 ,33,,12",
 "869400,2,3,1998/10/01 08:03:00,139.6723355632,35.5001601569,1,10,1231001,8 ,1 ,29,,12",
 "280600,5,5,1998/10/01 08:03:00,139.7364088002,35.6517089915,1,11,2910009,8 ,9 ,42,,8",
 "871700,2,4,1998/10/01 08:03:00,139.5305103428,35.3515865759,1,6,1230404,9 ,1 ,31,,1",
 "2300,2,3,1998/10/01 08:03:00,139.8750455796,35.7286517162,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:03:00,139.5850618759,35.8376690692,1,6,3032201,3 ,11,34,,6",
 "13800,2,4,1998/10/01 08:03:00,139.9394611145,35.7893638188,2,14,4116004,5 ,5 ,25,,10",
 "18400,2,3,1998/10/01 08:03:00,139.7083900657,35.6914330851,1,9,4120311,8 ,1 ,44,,12",
 "434700,4,4,1998/10/01 08:03:00,139.7824392482,35.8159551914,2,8,3210107,8 ,1 ,37,,7",
 "34500,2,3,1998/10/01 08:03:00,140.0122937277,35.7126103013,1,4,4129003,8 ,1 ,36,,12",
 "32200,2,3,1998/10/01 08:03:00,139.7745435522,35.6818486258,2,4,4128103,8 ,1 ,42,,12",
 "32200,2,4,1998/10/01 08:03:00,139.7745435522,35.6818486258,2,4,4128103,8 ,1 ,42,,1",
 "41400,2,4,1998/10/01 08:03:00,139.9423570132,35.838902927,2,5,4212212,8 ,1 ,44,,12",
 "593400,2,3,1998/10/01 08:03:00,139.8191654918,35.6835110468,1,6,0349009,9 ,1 ,58,,10",
 "71300,2,4,1998/10/01 08:03:00,139.7691859284,35.7319135674,1,12,4313309,9 ,1 ,34,,1",
 "78200,2,3,1998/10/01 08:03:00,139.9201894768,35.6876330498,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:03:00,140.0283199298,35.6845506386,1,9,4031206,8 ,1 ,38,,12",
 "828000,2,2,1998/10/01 08:03:00,139.5050657317,35.5044102919,1,5,1252001,4 ,1 ,38,,1",
 "172500,2,3,1998/10/01 08:03:00,140.0589016448,35.8898930312,2,4,5024120,13,2 ,44,,12",
 "209300,2,3,1998/10/01 08:03:00,139.6188710656,35.3235144107,1,13,2211303,10,1 ,37,,12",
 "211600,2,2,1998/10/01 08:03:00,139.733256473,35.2416724729,1,7,2220004,6 ,1 ,36,,1",
 "225400,2,2,1998/10/01 08:03:00,139.52465187,35.3455178064,1,3,2313201,13,2 ,36,,3",
 "227700,2,3,1998/10/01 08:03:00,139.6674749351,35.496045233,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 08:03:00,139.772091375,35.6756736353,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 08:03:00,139.6758251524,35.5054118824,1,11,2922005,9 ,1 ,29,,12",
 "303600,2,3,1998/10/01 08:03:00,139.5757330059,35.6303693952,1,9,2923016,9 ,1 ,30,,12",
 "85100,2,2,1998/10/01 08:03:00,140.2398095947,35.7212010722,1,8,4417027,9 ,10,46,,6",
 "310500,2,3,1998/10/01 08:03:00,139.6207811825,35.4642044375,1,9,2013102,3 ,1 ,37,,12",
 "322000,2,2,1998/10/01 08:03:00,139.6359328223,35.5856970121,2,5,2032102,8 ,1 ,36,,1",
 "345000,2,3,1998/10/01 08:03:00,139.7154529034,35.8041449705,1,8,3012002,9 ,1 ,37,,10",
 "255300,2,2,1998/10/01 08:03:00,139.1940818426,35.3019266399,1,12,2610311,8 ,1 ,44,,6",
 "271400,2,2,1998/10/01 08:03:00,139.3168361492,35.4708009179,1,7,2811315,9 ,11,37,,8",
 "289800,2,2,1998/10/01 08:03:00,139.4328395253,35.4603361555,2,3,2913122,12,2 ,38,,1",
 "370300,2,4,1998/10/01 08:03:00,139.7422662094,35.6879154183,1,10,3037010,10,1 ,39,,1",
 "305900,2,2,1998/10/01 08:03:00,139.3689073268,35.5519635625,2,9,2924003,3 ,1 ,34,,2",
 "386400,2,5,1998/10/01 08:03:00,139.7577683282,35.6632831144,1,10,3059002,8 ,1 ,37,,1",
 "331200,2,2,1998/10/01 08:03:00,139.5796926255,35.5716718238,2,1,2121208,12,2 ,28,,1",
 "404800,2,3,1998/10/01 08:03:00,139.5016133603,35.7314052479,2,9,3120009,8 ,1 ,30,,12",
 "374900,2,2,1998/10/01 08:03:00,139.6380757829,35.8627289429,1,4,3040108,4 ,1 ,71,,6",
 "425500,2,2,1998/10/01 08:03:00,139.4440912775,35.925254,1,4,3134008,8 ,1 ,45,,1",
 "425500,2,3,1998/10/01 08:03:00,139.4440912775,35.925254,1,4,3134008,8 ,1 ,45,,12",
 "437000,2,3,1998/10/01 08:03:00,139.7778475679,35.6761259838,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,3,1998/10/01 08:03:00,139.803721253,35.8318798387,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,2,1998/10/01 08:03:00,139.3886462361,35.9529470085,2,10,3411010,8 ,1 ,30,,2",
 "441600,4,4,1998/10/01 08:03:00,139.8487572978,35.7507941997,1,9,3215009,9 ,11,30,,8",
 "476100,2,4,1998/10/01 08:03:00,139.570191284,35.8133970781,1,3,3411213,13,2 ,34,,1",
 "483000,2,3,1998/10/01 08:03:00,139.7158095782,35.803948977,1,10,3510101,8 ,1 ,27,,12",
 "478400,2,2,1998/10/01 08:03:00,139.3830414201,36.0109662748,1,4,3413105,13,2 ,40,,1",
 "487600,2,2,1998/10/01 08:03:00,139.6046476831,35.9426087061,1,10,3512006,6 ,1 ,36,,6",
 "563500,2,3,1998/10/01 08:03:00,139.7372745099,35.7086397092,1,7,0212001,10,1 ,84,,12",
 "644000,2,3,1998/10/01 08:03:00,139.7496807463,35.7250796757,1,4,0522008,5 ,1 ,52,,12",
 "811900,2,3,1998/10/01 08:03:00,139.5961805143,35.3786162402,1,3,1123201,13,2 ,31,,12",
 "745200,2,2,1998/10/01 08:03:00,139.4160551815,35.7410359056,2,10,0790316,3 ,1 ,30,,2",
 "646300,2,3,1998/10/01 08:03:00,139.7064214443,35.7589456883,1,4,0523008,8 ,1 ,75,,12",
 "742900,2,2,1998/10/01 08:03:00,139.3510129119,35.7188014049,1,8,0784113,10,11,38,,7",
 "761300,2,2,1998/10/01 08:03:00,139.4450333604,35.6209227416,1,1,0821303,12,2 ,35,,1",
 "768200,2,2,1998/10/01 08:03:00,139.3371299101,35.673596825,2,2,0840002,12,2 ,36,,1",
 "777400,2,4,1998/10/01 08:03:00,139.6469630111,35.6409718964,1,5,0844010,8 ,1 ,41,,1",
 "788900,2,2,1998/10/01 08:03:00,139.2741595362,35.8183716725,2,2,0931009,12,2 ,35,,1",
 "786600,2,3,1998/10/01 08:03:00,139.3561447065,35.7096207745,2,3,0930110,13,2 ,35,,12",
 "798100,2,2,1998/10/01 08:03:00,139.633374817,35.4982465358,2,6,1421008,9 ,1 ,37,,6",
 "821100,2,2,1998/10/01 08:03:00,139.5718904976,35.4445924744,2,1,1213204,12,2 ,38,,1",
 "660100,2,2,1998/10/01 08:03:00,139.7985099572,35.7689918283,1,7,0612111,9 ,1 ,60,,1",
 "759000,2,3,1998/10/01 08:03:00,139.5914775587,35.664637474,2,4,0821006,9 ,1 ,55,,12",
 "823400,2,2,1998/10/01 08:03:00,139.5849404895,35.4618241321,1,10,1213105,9 ,13,34,,1",
 "867100,2,2,1998/10/01 08:03:00,139.4845310434,35.4294481939,2,2,1241102,12,2 ,29,,1",
 "876300,2,2,1998/10/01 08:03:00,139.4879516237,35.3872263586,1,9,1223102,9 ,1 ,27,,7",
 "719900,2,4,1998/10/01 08:03:00,139.4649989479,35.6997659277,1,7,0752005,6 ,10,34,,12",
 "696900,2,3,1998/10/01 08:03:00,139.6323397346,35.6798785353,2,6,0720006,4 ,1 ,39,,12",
 "683100,2,3,1998/10/01 08:03:00,139.768191776,35.6957440606,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 08:03:00,139.6734006124,35.6757962857,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,3,1998/10/01 08:04:00,139.577842856,35.4652975706,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:04:00,140.0901438159,35.700392894,2,1,4410011,12,2 ,35,,9",
 "841800,2,4,1998/10/01 08:04:00,139.6565763681,35.4867408131,1,2,1132110,12,2 ,33,,1",
 "860200,2,3,1998/10/01 08:04:00,139.6256682973,35.4698563294,1,9,1340109,10,1 ,33,,12",
 "869400,2,3,1998/10/01 08:04:00,139.6754465866,35.5040467333,1,10,1231001,8 ,1 ,29,,12",
 "280600,5,5,1998/10/01 08:04:00,139.7367460342,35.6545112951,1,11,2910009,8 ,9 ,42,,8",
 "871700,2,4,1998/10/01 08:04:00,139.5310322898,35.3508148758,1,6,1230404,9 ,1 ,31,,1",
 "2300,2,3,1998/10/01 08:04:00,139.8715407504,35.7262401977,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:04:00,139.585139709,35.8358453782,1,6,3032201,3 ,11,34,,6",
 "13800,2,4,1998/10/01 08:04:00,139.9396510996,35.7890501714,2,14,4116004,5 ,5 ,25,,10",
 "18400,2,3,1998/10/01 08:04:00,139.7033987707,35.6897249589,1,9,4120311,8 ,1 ,44,,12",
 "434700,4,4,1998/10/01 08:04:00,139.7798907003,35.8157881841,2,8,3210107,8 ,1 ,37,,7",
 "34500,2,3,1998/10/01 08:04:00,140.0192191281,35.7126884839,1,4,4129003,8 ,1 ,36,,12",
 "32200,2,4,1998/10/01 08:04:00,139.7770883195,35.681080934,2,4,4128103,8 ,1 ,42,,1",
 "41400,2,4,1998/10/01 08:04:00,139.9482642666,35.8417633127,2,5,4212212,8 ,1 ,44,,12",
 "593400,2,3,1998/10/01 08:04:00,139.8191654008,35.6835111337,1,6,0349009,9 ,1 ,58,,10",
 "71300,2,4,1998/10/01 08:04:00,139.77050094,35.7308554635,1,12,4313309,9 ,1 ,34,,1",
 "78200,2,3,1998/10/01 08:04:00,139.916630657,35.6846632347,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:04:00,140.0235455696,35.6885743853,1,9,4031206,8 ,1 ,38,,12",
 "828000,2,2,1998/10/01 08:04:00,139.5050660673,35.5044113222,1,5,1252001,4 ,1 ,38,,1",
 "828000,2,3,1998/10/01 08:04:00,139.5050660673,35.5044113222,1,5,1252001,4 ,1 ,38,,10",
 "172500,2,3,1998/10/01 08:04:00,140.055974315,35.8855063672,2,4,5024120,13,2 ,44,,12",
 "209300,2,3,1998/10/01 08:04:00,139.6198337674,35.3295349589,1,13,2211303,10,1 ,37,,12",
 "211600,2,2,1998/10/01 08:04:00,139.7332560761,35.241672746,1,7,2220004,6 ,1 ,36,,1",
 "227700,2,3,1998/10/01 08:04:00,139.6729629402,35.5006990815,1,9,2410203,8 ,10,16,,12",
 "225400,2,2,1998/10/01 08:04:00,139.5258376924,35.3462927126,1,3,2313201,13,2 ,36,,3",
 "234600,2,3,1998/10/01 08:04:00,139.7754771729,35.6812802787,1,14,2412116,10,1 ,63,,12",
 "301300,2,3,1998/10/01 08:04:00,139.67657389,35.5099472425,1,11,2922005,9 ,1 ,29,,12",
 "303600,2,3,1998/10/01 08:04:00,139.5789606342,35.6334893002,1,9,2923016,9 ,1 ,30,,12",
 "85100,2,2,1998/10/01 08:04:00,140.235398498,35.7234243079,1,8,4417027,9 ,10,46,,6",
 "310500,2,3,1998/10/01 08:04:00,139.6146877367,35.4576695457,1,9,2013102,3 ,1 ,37,,12",
 "322000,2,2,1998/10/01 08:04:00,139.6347825188,35.5857899631,2,5,2032102,8 ,1 ,36,,1",
 "345000,2,3,1998/10/01 08:04:00,139.7145387594,35.8018910953,1,8,3012002,9 ,1 ,37,,10",
 "255300,2,2,1998/10/01 08:04:00,139.1957416744,35.2995902711,1,12,2610311,8 ,1 ,44,,6",
 "271400,2,2,1998/10/01 08:04:00,139.3152366747,35.4711326859,1,7,2811315,9 ,11,37,,8",
 "289800,2,2,1998/10/01 08:04:00,139.4340871265,35.4601879977,2,3,2913122,12,2 ,38,,1",
 "370300,2,4,1998/10/01 08:04:00,139.7430526289,35.6880245347,1,10,3037010,10,1 ,39,,1",
 "305900,2,2,1998/10/01 08:04:00,139.366960665,35.551441643,2,9,2924003,3 ,1 ,34,,2",
 "386400,2,5,1998/10/01 08:04:00,139.7573357111,35.6626714244,1,10,3059002,8 ,1 ,37,,1",
 "331200,2,2,1998/10/01 08:04:00,139.5794218316,35.5713678304,2,1,2121208,12,2 ,28,,1",
 "404800,2,3,1998/10/01 08:04:00,139.5062205077,35.7292562636,2,9,3120009,8 ,1 ,30,,12",
 "374900,2,2,1998/10/01 08:04:00,139.6387250128,35.8594531707,1,4,3040108,4 ,1 ,71,,6",
 "425500,2,3,1998/10/01 08:04:00,139.4511706651,35.9238327482,1,4,3134008,8 ,1 ,45,,12",
 "437000,2,3,1998/10/01 08:04:00,139.7756977202,35.6716924632,1,7,3212001,5 ,1 ,36,,12",
 "446200,2,3,1998/10/01 08:04:00,139.803377539,35.8289956138,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,2,1998/10/01 08:04:00,139.3891899862,35.9537469085,2,10,3411010,8 ,1 ,30,,2",
 "478400,2,2,1998/10/01 08:04:00,139.3836183973,36.0107077297,1,4,3413105,13,2 ,40,,1",
 "441600,4,4,1998/10/01 08:04:00,139.8475241963,35.749150388,1,9,3215009,9 ,11,30,,8",
 "476100,2,4,1998/10/01 08:04:00,139.5694081903,35.8123930122,1,3,3411213,13,2 ,34,,1",
 "483000,2,3,1998/10/01 08:04:00,139.7179249596,35.8008134782,1,10,3510101,8 ,1 ,27,,12",
 "487600,2,2,1998/10/01 08:04:00,139.6032664583,35.9409835962,1,10,3512006,6 ,1 ,36,,6",
 "563500,2,3,1998/10/01 08:04:00,139.7408816632,35.7081092389,1,7,0212001,10,1 ,84,,12",
 "644000,2,3,1998/10/01 08:04:00,139.7525947336,35.7226070608,1,4,0522008,5 ,1 ,52,,12",
 "646300,2,3,1998/10/01 08:04:00,139.708403151,35.7536328233,1,4,0523008,8 ,1 ,75,,12",
 "823400,2,2,1998/10/01 08:04:00,139.5870250407,35.4638946835,1,10,1213105,9 ,13,34,,1",
 "811900,2,3,1998/10/01 08:04:00,139.5922157945,35.3773173035,1,3,1123201,13,2 ,31,,12",
 "742900,2,2,1998/10/01 08:04:00,139.3503989126,35.7210486131,1,8,0784113,10,11,38,,7",
 "745200,2,2,1998/10/01 08:04:00,139.4164256293,35.7389190964,2,10,0790316,3 ,1 ,30,,2",
 "761300,2,2,1998/10/01 08:04:00,139.4445113075,35.620028898,1,1,0821303,12,2 ,35,,1",
 "768200,2,2,1998/10/01 08:04:00,139.3370397055,35.6742436079,2,2,0840002,12,2 ,36,,1",
 "788900,2,2,1998/10/01 08:04:00,139.2732626772,35.8181659642,2,2,0931009,12,2 ,35,,1",
 "777400,2,4,1998/10/01 08:04:00,139.6472704996,35.6400080343,1,5,0844010,8 ,1 ,41,,1",
 "786600,2,3,1998/10/01 08:04:00,139.35862793,35.7061674682,2,3,0930110,13,2 ,35,,12",
 "798100,2,2,1998/10/01 08:04:00,139.6255694478,35.4971504672,2,6,1421008,9 ,1 ,37,,6",
 "660100,2,2,1998/10/01 08:04:00,139.7975869745,35.7705147842,1,7,0612111,9 ,1 ,60,,1",
 "821100,2,2,1998/10/01 08:04:00,139.5729491519,35.4450553964,2,1,1213204,12,2 ,38,,1",
 "759000,2,3,1998/10/01 08:04:00,139.5965172801,35.6664456924,2,4,0821006,9 ,1 ,55,,12",
 "867100,2,2,1998/10/01 08:04:00,139.4844859821,35.4282900677,2,2,1241102,12,2 ,29,,1",
 "876300,2,2,1998/10/01 08:04:00,139.4868654829,35.3889240895,1,9,1223102,9 ,1 ,27,,7",
 "696900,2,3,1998/10/01 08:04:00,139.63637802,35.6784660062,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:04:00,139.4686934446,35.6998924793,1,7,0752005,6 ,10,34,,12",
 "683100,2,3,1998/10/01 08:04:00,139.7650245386,35.6945720225,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 08:04:00,139.6780908095,35.6770494013,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,3,1998/10/01 08:05:00,139.5822849889,35.4738594427,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:05:00,140.0903715365,35.7006111239,2,1,4410011,12,2 ,35,,9",
 "841800,2,4,1998/10/01 08:05:00,139.657157895,35.4871742955,1,2,1132110,12,2 ,33,,1",
 "860200,2,3,1998/10/01 08:05:00,139.6231167862,35.4667363253,1,9,1340109,10,1 ,33,,12",
 "280600,5,5,1998/10/01 08:05:00,139.7365158605,35.6572544275,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:05:00,139.6764690676,35.5086459665,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,4,1998/10/01 08:05:00,139.5314797287,35.3505547594,1,6,1230404,9 ,1 ,31,,1",
 "2300,2,3,1998/10/01 08:05:00,139.8680668947,35.7238043007,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:05:00,139.5851589668,35.8365561006,1,6,3032201,3 ,11,34,,6",
 "18400,2,3,1998/10/01 08:05:00,139.6998272499,35.690734646,1,9,4120311,8 ,1 ,44,,12",
 "18400,2,4,1998/10/01 08:05:00,139.6998272499,35.690734646,1,9,4120311,8 ,1 ,44,,1",
 "13800,2,4,1998/10/01 08:05:00,139.9401546846,35.789080497,2,14,4116004,5 ,5 ,25,,10",
 "434700,4,4,1998/10/01 08:05:00,139.7773378039,35.8156729173,2,8,3210107,8 ,1 ,37,,7",
 "32200,2,4,1998/10/01 08:05:00,139.7788464296,35.6799161764,2,4,4128103,8 ,1 ,42,,1",
 "32200,3,5,1998/10/01 08:05:00,139.7788464296,35.6799161764,2,4,4128103,8 ,97,42,,97",
 "34500,2,3,1998/10/01 08:05:00,140.0254388659,35.7153221613,1,4,4129003,8 ,1 ,36,,12",
 "41400,2,4,1998/10/01 08:05:00,139.9541516517,35.8446509774,2,5,4212212,8 ,1 ,44,,12",
 "593400,2,3,1998/10/01 08:05:00,139.8191653098,35.6835112206,1,6,0349009,9 ,1 ,58,,10",
 "71300,2,4,1998/10/01 08:05:00,139.7713290174,35.7300032748,1,12,4313309,9 ,1 ,34,,1",
 "71300,3,5,1998/10/01 08:05:00,139.7713290174,35.7300032748,1,12,4313309,9 ,97,34,,97",
 "78200,2,3,1998/10/01 08:05:00,139.9130698953,35.6816950748,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:05:00,140.0187660057,35.6925941975,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:05:00,140.0532716282,35.8810281541,2,4,5024120,13,2 ,44,,12",
 "828000,2,3,1998/10/01 08:05:00,139.5050661257,35.5044115015,1,5,1252001,4 ,1 ,38,,10",
 "209300,2,3,1998/10/01 08:05:00,139.62007505,35.3356343096,1,13,2211303,10,1 ,37,,12",
 "211600,2,2,1998/10/01 08:05:00,139.7332556792,35.2416730191,1,7,2220004,6 ,1 ,36,,1",
 "211600,2,3,1998/10/01 08:05:00,139.7332556792,35.2416730191,1,7,2220004,6 ,1 ,36,,10",
 "225400,2,2,1998/10/01 08:05:00,139.5270235376,35.3470676072,1,3,2313201,13,2 ,36,,3",
 "227700,2,3,1998/10/01 08:05:00,139.6760970727,35.5065123255,1,9,2410203,8 ,10,16,,12",
 "234600,2,3,1998/10/01 08:05:00,139.7800431033,35.6799323139,1,14,2412116,10,1 ,63,,12",
 "234600,2,4,1998/10/01 08:05:00,139.7800431033,35.6799323139,1,14,2412116,10,1 ,63,,1",
 "303600,2,3,1998/10/01 08:05:00,139.5833288676,35.6354748843,1,9,2923016,9 ,1 ,30,,12",
 "301300,2,3,1998/10/01 08:05:00,139.6782619784,35.5143136189,1,11,2922005,9 ,1 ,29,,12",
 "85100,2,2,1998/10/01 08:05:00,140.2303300783,35.7240049904,1,8,4417027,9 ,10,46,,6",
 "310500,2,3,1998/10/01 08:05:00,139.6071381195,35.4523571301,1,9,2013102,3 ,1 ,37,,12",
 "322000,2,2,1998/10/01 08:05:00,139.634132454,35.5855047222,2,5,2032102,8 ,1 ,36,,1",
 "345000,2,3,1998/10/01 08:05:00,139.7175440392,35.8018876214,1,8,3012002,9 ,1 ,37,,10",
 "345000,2,4,1998/10/01 08:05:00,139.7175440392,35.8018876214,1,8,3012002,9 ,1 ,37,,12",
 "255300,2,2,1998/10/01 08:05:00,139.1969337756,35.2967308703,1,12,2610311,8 ,1 ,44,,6",
 "271400,2,2,1998/10/01 08:05:00,139.3149767714,35.4710991875,1,7,2811315,9 ,11,37,,8",
 "289800,2,2,1998/10/01 08:05:00,139.4353151001,35.4605681283,2,3,2913122,12,2 ,38,,1",
 "370300,2,4,1998/10/01 08:05:00,139.743843062,35.6880894724,1,10,3037010,10,1 ,39,,1",
 "305900,2,2,1998/10/01 08:05:00,139.3649316426,35.5512628738,2,9,2924003,3 ,1 ,34,,2",
 "331200,2,2,1998/10/01 08:05:00,139.5791654265,35.5710555122,2,1,2121208,12,2 ,28,,1",
 "386400,2,5,1998/10/01 08:05:00,139.7568492044,35.66208422,1,10,3059002,8 ,1 ,37,,1",
 "404800,2,3,1998/10/01 08:05:00,139.5109379611,35.72718531,2,9,3120009,8 ,1 ,30,,12",
 "374900,2,2,1998/10/01 08:05:00,139.6390974519,35.8561619864,1,4,3040108,4 ,1 ,71,,6",
 "425500,2,3,1998/10/01 08:05:00,139.4583696854,35.9223258959,1,4,3134008,8 ,1 ,45,,12",
 "437000,2,3,1998/10/01 08:05:00,139.7723496176,35.6679056269,1,7,3212001,5 ,1 ,36,,12",
 "437000,2,4,1998/10/01 08:05:00,139.7723496176,35.6679056269,1,7,3212001,5 ,1 ,36,,1",
 "446200,2,3,1998/10/01 08:05:00,139.8031266407,35.8261053977,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,2,1998/10/01 08:05:00,139.3899843805,35.9544762904,2,10,3411010,8 ,1 ,30,,2",
 "441600,4,4,1998/10/01 08:05:00,139.8463101177,35.7474971012,1,9,3215009,9 ,11,30,,8",
 "476100,2,4,1998/10/01 08:05:00,139.5690152943,35.8110535168,1,3,3411213,13,2 ,34,,1",
 "476100,3,5,1998/10/01 08:05:00,139.5690152943,35.8110535168,1,3,3411213,13,97,34,,97",
 "478400,2,2,1998/10/01 08:05:00,139.3841953709,36.0104491817,1,4,3413105,13,2 ,40,,1",
 "483000,2,3,1998/10/01 08:05:00,139.7181485243,35.7972407975,1,10,3510101,8 ,1 ,27,,12",
 "487600,2,2,1998/10/01 08:05:00,139.6029930472,35.9390850629,1,10,3512006,6 ,1 ,36,,6",
 "563500,2,3,1998/10/01 08:05:00,139.743535423,35.7062821306,1,7,0212001,10,1 ,84,,12",
 "646300,2,3,1998/10/01 08:05:00,139.7127351874,35.7495638715,1,4,0523008,8 ,1 ,75,,12",
 "644000,2,3,1998/10/01 08:05:00,139.7514244332,35.7190469727,1,4,0522008,5 ,1 ,52,,12",
 "823400,2,2,1998/10/01 08:05:00,139.5865691498,35.4638186141,1,10,1213105,9 ,13,34,,1",
 "823400,2,3,1998/10/01 08:05:00,139.5865691498,35.4638186141,1,10,1213105,9 ,13,34,,12",
 "811900,2,3,1998/10/01 08:05:00,139.5882313126,35.3760899373,1,3,1123201,13,2 ,31,,12",
 "761300,2,2,1998/10/01 08:05:00,139.4439169099,35.6194950535,1,1,0821303,12,2 ,35,,1",
 "745200,2,2,1998/10/01 08:05:00,139.4167977174,35.7368027625,2,10,0790316,3 ,1 ,30,,2",
 "742900,2,2,1998/10/01 08:05:00,139.3479584556,35.7195427661,1,8,0784113,10,11,38,,7",
 "742900,3,3,1998/10/01 08:05:00,139.3479584556,35.7195427661,1,8,0784113,10,97,38,,97",
 "660100,2,2,1998/10/01 08:05:00,139.7981209569,35.7722281153,1,7,0612111,9 ,1 ,60,,1",
 "660100,2,3,1998/10/01 08:05:00,139.7981209569,35.7722281153,1,7,0612111,9 ,1 ,60,,12",
 "768200,2,2,1998/10/01 08:05:00,139.3364691264,35.6740015486,2,2,0840002,12,2 ,36,,1",
 "786600,2,3,1998/10/01 08:05:00,139.3611083319,35.7027128701,2,3,0930110,13,2 ,35,,12",
 "777400,2,4,1998/10/01 08:05:00,139.6477947598,35.6392560568,1,5,0844010,8 ,1 ,41,,1",
 "798100,2,2,1998/10/01 08:05:00,139.6181522244,35.4970386008,2,6,1421008,9 ,1 ,37,,6",
 "788900,2,2,1998/10/01 08:05:00,139.2723658229,35.8179602493,2,2,0931009,12,2 ,35,,1",
 "788900,3,3,1998/10/01 08:05:00,139.2723658229,35.8179602493,2,2,0931009,12,97,35,,97",
 "683100,2,3,1998/10/01 08:05:00,139.761869847,35.6951396377,2,3,0633106,12,2 ,49,,12",
 "821100,2,2,1998/10/01 08:05:00,139.5740078183,35.445518309,2,1,1213204,12,2 ,38,,1",
 "759000,2,3,1998/10/01 08:05:00,139.6015494929,35.6682674659,2,4,0821006,9 ,1 ,55,,12",
 "867100,2,2,1998/10/01 08:05:00,139.4841980117,35.4271561149,2,2,1241102,12,2 ,29,,1",
 "876300,2,2,1998/10/01 08:05:00,139.4861303302,35.3907343315,1,9,1223102,9 ,1 ,27,,7",
 "696900,2,3,1998/10/01 08:05:00,139.6404220933,35.6770634906,2,6,0720006,4 ,1 ,39,,12",
 "692300,2,3,1998/10/01 08:05:00,139.6824540054,35.6789163079,2,4,0711108,8 ,1 ,25,,12",
 "719900,2,4,1998/10/01 08:05:00,139.472391985,35.6999602372,1,7,0752005,6 ,10,34,,12",
 "830300,2,3,1998/10/01 08:06:00,139.5876437604,35.4820482364,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:06:00,140.0906092719,35.7007379473,2,1,4410011,12,2 ,35,,9",
 "89700,5,5,1998/10/01 08:06:00,140.2504041935,35.7496434428,2,8,4422113,3 ,8 ,42,,7",
 "841800,2,4,1998/10/01 08:06:00,139.657721683,35.4876230419,1,2,1132110,12,2 ,33,,1",
 "860200,2,3,1998/10/01 08:06:00,139.6220604071,35.463069054,1,9,1340109,10,1 ,33,,12",
 "869400,2,3,1998/10/01 08:06:00,139.6776610995,35.5131969769,1,10,1231001,8 ,1 ,29,,12",
 "280600,5,5,1998/10/01 08:06:00,139.736790412,35.6599395785,1,11,2910009,8 ,9 ,42,,8",
 "871700,2,4,1998/10/01 08:06:00,139.5323906628,35.3500845095,1,6,1230404,9 ,1 ,31,,1",
 "2300,2,3,1998/10/01 08:06:00,139.8647681387,35.7212111173,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:06:00,139.5866608914,35.8348430669,1,6,3032201,3 ,11,34,,6",
 "13800,2,4,1998/10/01 08:06:00,139.94065827,35.7891108205,2,14,4116004,5 ,5 ,25,,10",
 "18400,2,4,1998/10/01 08:06:00,139.6987577209,35.6906657286,1,9,4120311,8 ,1 ,44,,1",
 "434700,4,4,1998/10/01 08:06:00,139.7755370784,35.8157555569,2,8,3210107,8 ,1 ,37,,7",
 "34500,2,3,1998/10/01 08:06:00,140.0316798549,35.7179252411,1,4,4129003,8 ,1 ,36,,12",
 "41400,2,4,1998/10/01 08:06:00,139.9593790761,35.8482461411,2,5,4212212,8 ,1 ,44,,12",
 "593400,2,3,1998/10/01 08:06:00,139.8191652188,35.6835113075,1,6,0349009,9 ,1 ,58,,10",
 "62100,2,2,1998/10/01 08:06:00,140.0356964044,35.8662091119,1,7,4224202,9 ,1 ,37,,1",
 "78200,2,3,1998/10/01 08:06:00,139.9095056531,35.6787297988,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:06:00,140.0139820112,35.6966106875,1,9,4031206,8 ,1 ,38,,12",
 "828000,2,3,1998/10/01 08:06:00,139.5050661841,35.5044116808,1,5,1252001,4 ,1 ,38,,10",
 "172500,2,3,1998/10/01 08:06:00,140.050511071,35.8765753347,2,4,5024120,13,2 ,44,,12",
 "209300,2,3,1998/10/01 08:06:00,139.6212903052,35.3416350383,1,13,2211303,10,1 ,37,,12",
 "211600,2,3,1998/10/01 08:06:00,139.7309613025,35.2425761113,1,7,2220004,6 ,1 ,36,,10",
 "225400,2,2,1998/10/01 08:06:00,139.5282094054,35.3478424902,1,3,2313201,13,2 ,36,,3",
 "227700,2,3,1998/10/01 08:06:00,139.6774783116,35.5128421555,1,9,2410203,8 ,10,16,,12",
 "234600,2,4,1998/10/01 08:06:00,139.7806191519,35.6791899781,1,14,2412116,10,1 ,63,,1",
 "303600,2,3,1998/10/01 08:06:00,139.5880751805,35.6368553242,1,9,2923016,9 ,1 ,30,,12",
 "301300,2,3,1998/10/01 08:06:00,139.6813301053,35.518126335,1,11,2922005,9 ,1 ,29,,12",
 "85100,2,2,1998/10/01 08:06:00,140.225340674,35.7250807697,1,8,4417027,9 ,10,46,,6",
 "310500,2,3,1998/10/01 08:06:00,139.5998572196,35.4467726862,1,9,2013102,3 ,1 ,37,,12",
 "322000,2,2,1998/10/01 08:06:00,139.6329865131,35.5858660956,2,5,2032102,8 ,1 ,36,,1",
 "232300,2,2,1998/10/01 08:06:00,139.4792161303,35.3486689462,1,4,2411307,3 ,1 ,40,,2",
 "345000,2,4,1998/10/01 08:06:00,139.718245599,35.7990391992,1,8,3012002,9 ,1 ,37,,12",
 "241500,2,2,1998/10/01 08:06:00,139.3980906397,35.3441988563,1,6,2420707,5 ,1 ,37,,6",
 "255300,2,2,1998/10/01 08:06:00,139.1986308666,35.2941165989,1,12,2610311,8 ,1 ,44,,6",
 "271400,2,2,1998/10/01 08:06:00,139.316616503,35.4709228915,1,7,2811315,9 ,11,37,,8",
 "289800,2,2,1998/10/01 08:06:00,139.4363493414,35.4600310267,2,3,2913122,12,2 ,38,,1",
 "370300,2,4,1998/10/01 08:06:00,139.7446406069,35.6880760943,1,10,3037010,10,1 ,39,,1",
 "305900,2,2,1998/10/01 08:06:00,139.3632469587,35.5521779121,2,9,2924003,3 ,1 ,34,,2",
 "386400,2,5,1998/10/01 08:06:00,139.7563582597,35.6614994649,1,10,3059002,8 ,1 ,37,,1",
 "331200,2,2,1998/10/01 08:06:00,139.578916819,35.5707390162,2,1,2121208,12,2 ,28,,1",
 "404800,2,3,1998/10/01 08:06:00,139.5157712681,35.7253258349,2,9,3120009,8 ,1 ,30,,12",
 "374900,2,2,1998/10/01 08:06:00,139.6406088773,35.8539485305,1,4,3040108,4 ,1 ,71,,6",
 "425500,2,3,1998/10/01 08:06:00,139.4655684361,35.9208186099,1,4,3134008,8 ,1 ,45,,12",
 "437000,2,4,1998/10/01 08:06:00,139.7720034909,35.6676543176,1,7,3212001,5 ,1 ,36,,1",
 "446200,2,3,1998/10/01 08:06:00,139.8029081935,35.8232130863,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,2,1998/10/01 08:06:00,139.3906026716,35.9553298721,2,10,3411010,8 ,1 ,30,,2",
 "441600,4,4,1998/10/01 08:06:00,139.8450521714,35.7458661277,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 08:06:00,139.7177235745,35.7936586948,1,10,3510101,8 ,1 ,27,,12",
 "478400,2,2,1998/10/01 08:06:00,139.3847723406,36.010190631,1,4,3413105,13,2 ,40,,1",
 "487600,2,2,1998/10/01 08:06:00,139.6029409153,35.9371161292,1,10,3512006,6 ,1 ,36,,6",
 "563500,2,3,1998/10/01 08:06:00,139.7442482464,35.7033835681,1,7,0212001,10,1 ,84,,12",
 "644000,2,3,1998/10/01 08:06:00,139.7521019524,35.7153765584,1,4,0522008,5 ,1 ,52,,12",
 "646300,2,3,1998/10/01 08:06:00,139.7194920485,35.7488400063,1,4,0523008,8 ,1 ,75,,12",
 "811900,2,3,1998/10/01 08:06:00,139.5839837684,35.3760824841,1,3,1123201,13,2 ,31,,12",
 "823400,2,3,1998/10/01 08:06:00,139.5872788804,35.4633185404,1,10,1213105,9 ,13,34,,12",
 "745200,2,2,1998/10/01 08:06:00,139.4171645902,35.7346858357,2,10,0790316,3 ,1 ,30,,2",
 "768200,2,2,1998/10/01 08:06:00,139.3363223646,35.674447184,2,2,0840002,12,2 ,36,,1",
 "761300,2,2,1998/10/01 08:06:00,139.443556007,35.6185476447,1,1,0821303,12,2 ,35,,1",
 "798100,2,2,1998/10/01 08:06:00,139.6116156252,35.4995092232,2,6,1421008,9 ,1 ,37,,6",
 "777400,2,4,1998/10/01 08:06:00,139.6480075895,35.6381323861,1,5,0844010,8 ,1 ,41,,1",
 "786600,2,3,1998/10/01 08:06:00,139.3630689474,35.6991048557,2,3,0930110,13,2 ,35,,12",
 "821100,2,2,1998/10/01 08:06:00,139.5750603811,35.4459897315,2,1,1213204,12,2 ,38,,1",
 "669300,2,2,1998/10/01 08:06:00,139.8679043641,35.7638040339,1,6,0620210,9 ,13,47,,1",
 "660100,2,3,1998/10/01 08:06:00,139.8007142685,35.7709416142,1,7,0612111,9 ,1 ,60,,12",
 "759000,2,3,1998/10/01 08:06:00,139.6066009164,35.6700518397,2,4,0821006,9 ,1 ,55,,12",
 "876300,2,2,1998/10/01 08:06:00,139.4854913514,35.3925666464,1,9,1223102,9 ,1 ,27,,7",
 "867100,2,2,1998/10/01 08:06:00,139.4839347092,35.4260217833,2,2,1241102,12,2 ,29,,1",
 "696900,2,3,1998/10/01 08:06:00,139.6443284773,35.6754442407,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:06:00,139.4760905326,35.7000278821,1,7,0752005,6 ,10,34,,12",
 "683100,2,3,1998/10/01 08:06:00,139.7588008837,35.6960764572,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 08:06:00,139.6868689128,35.680703344,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,3,1998/10/01 08:07:00,139.596296263,35.4879704617,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:07:00,140.0908843412,35.7009162311,2,1,4410011,12,2 ,35,,9",
 "89700,5,5,1998/10/01 08:07:00,140.2485513477,35.7514715186,2,8,4422113,3 ,8 ,42,,7",
 "841800,2,4,1998/10/01 08:07:00,139.6583178247,35.4880421186,1,2,1132110,12,2 ,33,,1",
 "860200,2,3,1998/10/01 08:07:00,139.6236527654,35.4597808167,1,9,1340109,10,1 ,33,,12",
 "280600,5,5,1998/10/01 08:07:00,139.7380824345,35.6625407712,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:07:00,139.6804260312,35.5172712861,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,4,1998/10/01 08:07:00,139.5333990007,35.3497919991,1,6,1230404,9 ,1 ,31,,1",
 "2300,2,3,1998/10/01 08:07:00,139.8612753129,35.7187929392,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:07:00,139.588001495,35.833253581,1,6,3032201,3 ,11,34,,6",
 "13800,2,4,1998/10/01 08:07:00,139.9411618558,35.789141142,2,14,4116004,5 ,5 ,25,,10",
 "18400,2,4,1998/10/01 08:07:00,139.698891115,35.689735295,1,9,4120311,8 ,1 ,44,,1",
 "434700,4,4,1998/10/01 08:07:00,139.7737993272,35.8161182768,2,8,3210107,8 ,1 ,37,,7",
 "34500,2,3,1998/10/01 08:07:00,140.0380661957,35.720271636,1,4,4129003,8 ,1 ,36,,12",
 "41400,2,4,1998/10/01 08:07:00,139.9635285745,35.8526942281,2,5,4212212,8 ,1 ,44,,12",
 "593400,2,3,1998/10/01 08:07:00,139.8191651278,35.6835113944,1,6,0349009,9 ,1 ,58,,10",
 "62100,2,2,1998/10/01 08:07:00,140.0373310337,35.8663609751,1,7,4224202,9 ,1 ,37,,1",
 "78200,2,3,1998/10/01 08:07:00,139.9059416745,35.6757644154,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:07:00,140.008220794,35.6994276287,1,9,4031206,8 ,1 ,38,,12",
 "828000,2,3,1998/10/01 08:07:00,139.5050662425,35.50441186,1,5,1252001,4 ,1 ,38,,10",
 "172500,2,3,1998/10/01 08:07:00,140.0455464128,35.874174257,2,4,5024120,13,2 ,44,,12",
 "209300,2,3,1998/10/01 08:07:00,139.6235780361,35.3473857466,1,13,2211303,10,1 ,37,,12",
 "211600,2,3,1998/10/01 08:07:00,139.7280900832,35.2420172458,1,7,2220004,6 ,1 ,36,,10",
 "225400,2,2,1998/10/01 08:07:00,139.5293357562,35.3486454975,1,3,2313201,13,2 ,36,,3",
 "227700,2,3,1998/10/01 08:07:00,139.6815409402,35.5183257333,1,9,2410203,8 ,10,16,,12",
 "234600,2,4,1998/10/01 08:07:00,139.7811951899,35.6784476395,1,14,2412116,10,1 ,63,,1",
 "301300,2,3,1998/10/01 08:07:00,139.6851862362,35.5214696718,1,11,2922005,9 ,1 ,29,,12",
 "303600,2,3,1998/10/01 08:07:00,139.5928109414,35.6382600308,1,9,2923016,9 ,1 ,30,,12",
 "85100,2,2,1998/10/01 08:07:00,140.2205315006,35.7265475825,1,8,4417027,9 ,10,46,,6",
 "310500,2,3,1998/10/01 08:07:00,139.5906875295,35.4454478318,1,9,2013102,3 ,1 ,37,,12",
 "322000,2,2,1998/10/01 08:07:00,139.6319743906,35.5864429869,2,5,2032102,8 ,1 ,36,,1",
 "232300,2,2,1998/10/01 08:07:00,139.4805843856,35.3477976026,1,4,2411307,3 ,1 ,40,,2",
 "241500,2,2,1998/10/01 08:07:00,139.3975325076,35.3461083421,1,6,2420707,5 ,1 ,37,,6",
 "345000,2,4,1998/10/01 08:07:00,139.7179601624,35.7960828124,1,8,3012002,9 ,1 ,37,,12",
 "255300,2,2,1998/10/01 08:07:00,139.2014311818,35.2921519389,1,12,2610311,8 ,1 ,44,,6",
 "271400,2,2,1998/10/01 08:07:00,139.3182557593,35.4709215627,1,7,2811315,9 ,11,37,,8",
 "289800,2,2,1998/10/01 08:07:00,139.4373752495,35.4599500955,2,3,2913122,12,2 ,38,,1",
 "370300,2,4,1998/10/01 08:07:00,139.7454381516,35.6880627109,1,10,3037010,10,1 ,39,,1",
 "305900,2,2,1998/10/01 08:07:00,139.3613095014,35.5525209261,2,9,2924003,3 ,1 ,34,,2",
 "386400,2,5,1998/10/01 08:07:00,139.7558673222,35.6609147077,1,10,3059002,8 ,1 ,37,,1",
 "331200,2,2,1998/10/01 08:07:00,139.5786487205,35.5704352246,2,1,2121208,12,2 ,28,,1",
 "404800,2,3,1998/10/01 08:07:00,139.5210728675,35.7252822555,2,9,3120009,8 ,1 ,30,,12",
 "374900,2,2,1998/10/01 08:07:00,139.6415928919,35.8510396085,1,4,3040108,4 ,1 ,71,,6",
 "425500,2,3,1998/10/01 08:07:00,139.4719821294,35.9181307867,1,4,3134008,8 ,1 ,45,,12",
 "437000,2,4,1998/10/01 08:07:00,139.7718225609,35.6675275339,1,7,3212001,5 ,1 ,36,,1",
 "446200,2,3,1998/10/01 08:07:00,139.8026897621,35.8203207732,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,2,1998/10/01 08:07:00,139.3913303779,35.956113058,2,10,3411010,8 ,1 ,30,,2",
 "441600,4,4,1998/10/01 08:07:00,139.8436712302,35.7443034785,1,9,3215009,9 ,11,30,,8",
 "478400,2,2,1998/10/01 08:07:00,139.3853493066,36.0099320774,1,4,3413105,13,2 ,40,,1",
 "483000,2,3,1998/10/01 08:07:00,139.7173436309,35.7900697857,1,10,3510101,8 ,1 ,27,,12",
 "487600,2,2,1998/10/01 08:07:00,139.60277544,35.9351817307,1,10,3512006,6 ,1 ,36,,6",
 "563500,2,3,1998/10/01 08:07:00,139.7427993329,35.7006583512,1,7,0212001,10,1 ,84,,12",
 "644000,2,3,1998/10/01 08:07:00,139.7528384927,35.7117133346,1,4,0522008,5 ,1 ,52,,12",
 "811900,2,3,1998/10/01 08:07:00,139.5797277988,35.3759548512,1,3,1123201,13,2 ,31,,12",
 "745200,2,2,1998/10/01 08:07:00,139.4175162108,35.7325669008,2,10,0790316,3 ,1 ,30,,2",
 "646300,2,3,1998/10/01 08:07:00,139.7253262831,35.7463240039,1,4,0523008,8 ,1 ,75,,12",
 "761300,2,2,1998/10/01 08:07:00,139.4435458358,35.617804747,1,1,0821303,12,2 ,35,,1",
 "768200,2,2,1998/10/01 08:07:00,139.3355661527,35.6746724876,2,2,0840002,12,2 ,36,,1",
 "777400,2,4,1998/10/01 08:07:00,139.6484875176,35.6370557322,1,5,0844010,8 ,1 ,41,,1",
 "786600,2,3,1998/10/01 08:07:00,139.3630744324,35.6951025477,2,3,0930110,13,2 ,35,,12",
 "798100,2,2,1998/10/01 08:07:00,139.6057114108,35.5040427441,2,6,1421008,9 ,1 ,37,,6",
 "821100,2,2,1998/10/01 08:07:00,139.5760575912,35.4465362974,2,1,1213204,12,2 ,38,,1",
 "660100,2,3,1998/10/01 08:07:00,139.8033656753,35.7694777784,1,7,0612111,9 ,1 ,60,,12",
 "669300,2,2,1998/10/01 08:07:00,139.867457789,35.7649292068,1,6,0620210,9 ,13,47,,1",
 "759000,2,3,1998/10/01 08:07:00,139.6119444083,35.6704807534,2,4,0821006,9 ,1 ,55,,12",
 "867100,2,2,1998/10/01 08:07:00,139.4841612906,35.4248794813,2,2,1241102,12,2 ,29,,1",
 "823400,2,3,1998/10/01 08:07:00,139.5882673659,35.4629484176,1,10,1213105,9 ,13,34,,12",
 "876300,2,2,1998/10/01 08:07:00,139.485933086,35.3945270405,1,9,1223102,9 ,1 ,27,,7",
 "696900,2,3,1998/10/01 08:07:00,139.6477009517,35.6731489197,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:07:00,139.479778776,35.7001778698,1,7,0752005,6 ,10,34,,12",
 "683100,2,3,1998/10/01 08:07:00,139.7553792145,35.6959065927,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 08:07:00,139.6903090732,35.6835744962,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,3,1998/10/01 08:08:00,139.606049056,35.4927679519,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:08:00,140.0912263623,35.7009637156,2,1,4410011,12,2 ,35,,9",
 "89700,5,5,1998/10/01 08:08:00,140.2492888282,35.7546620284,2,8,4422113,3 ,8 ,42,,7",
 "841800,2,4,1998/10/01 08:08:00,139.6589361887,35.4884398099,1,2,1132110,12,2 ,33,,1",
 "860200,2,3,1998/10/01 08:08:00,139.6262007276,35.4566423752,1,9,1340109,10,1 ,33,,12",
 "869400,2,3,1998/10/01 08:08:00,139.6842989605,35.5207240726,1,10,1231001,8 ,1 ,29,,12",
 "280600,5,5,1998/10/01 08:08:00,139.7385654731,35.6653367576,1,11,2910009,8 ,9 ,42,,8",
 "871700,2,4,1998/10/01 08:08:00,139.5344482108,35.3495933793,1,6,1230404,9 ,1 ,31,,1",
 "2300,2,3,1998/10/01 08:08:00,139.8574850681,35.7166981053,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:08:00,139.5859373154,35.8345195976,1,6,3032201,3 ,11,34,,6",
 "13800,2,4,1998/10/01 08:08:00,139.9416658212,35.7891668973,2,14,4116004,5 ,5 ,25,,10",
 "18400,2,4,1998/10/01 08:08:00,139.6990506304,35.688787286,1,9,4120311,8 ,1 ,44,,1",
 "434700,4,4,1998/10/01 08:08:00,139.7716652653,35.816329551,2,8,3210107,8 ,1 ,37,,7",
 "34500,2,3,1998/10/01 08:08:00,140.0404695703,35.7227100617,1,4,4129003,8 ,1 ,36,,12",
 "41400,2,4,1998/10/01 08:08:00,139.9672341651,35.8574076892,2,5,4212212,8 ,1 ,44,,12",
 "593400,2,3,1998/10/01 08:08:00,139.8191650368,35.6835114813,1,6,0349009,9 ,1 ,58,,10",
 "62100,2,2,1998/10/01 08:08:00,140.0386447196,35.8672239752,1,7,4224202,9 ,1 ,37,,1",
 "78200,2,3,1998/10/01 08:08:00,139.9023779597,35.6727989248,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:08:00,140.0015105925,35.7002936919,1,9,4031206,8 ,1 ,38,,12",
 "828000,2,3,1998/10/01 08:08:00,139.5050663009,35.5044120393,1,5,1252001,4 ,1 ,38,,10",
 "172500,2,3,1998/10/01 08:08:00,140.0397333039,35.8725826125,2,4,5024120,13,2 ,44,,12",
 "197800,2,2,1998/10/01 08:08:00,140.1864077965,35.9018769325,2,3,5421032,12,2 ,37,,7",
 "209300,2,3,1998/10/01 08:08:00,139.6275446377,35.3524588556,1,13,2211303,10,1 ,37,,12",
 "211600,2,3,1998/10/01 08:08:00,139.7253407956,35.2414967455,1,7,2220004,6 ,1 ,36,,10",
 "225400,2,2,1998/10/01 08:08:00,139.5295605759,35.3498745309,1,3,2313201,13,2 ,36,,3",
 "227700,2,3,1998/10/01 08:08:00,139.6870051595,35.5229980935,1,9,2410203,8 ,10,16,,12",
 "234600,2,4,1998/10/01 08:08:00,139.7817712173,35.677705298,1,14,2412116,10,1 ,63,,1",
 "301300,2,3,1998/10/01 08:08:00,139.6891070896,35.524764599,1,11,2922005,9 ,1 ,29,,12",
 "303600,2,3,1998/10/01 08:08:00,139.5975471178,35.6396637678,1,9,2923016,9 ,1 ,30,,12",
 "85100,2,2,1998/10/01 08:08:00,140.2155381348,35.7267108931,1,8,4417027,9 ,10,46,,6",
 "310500,2,3,1998/10/01 08:08:00,139.580779291,35.4467982509,1,9,2013102,3 ,1 ,37,,12",
 "124200,2,2,1998/10/01 08:08:00,140.480781833,35.6834777106,2,1,4813118,12,2 ,26,,9",
 "322000,2,2,1998/10/01 08:08:00,139.6309531006,35.5870089799,2,5,2032102,8 ,1 ,36,,1",
 "232300,2,2,1998/10/01 08:08:00,139.4827760236,35.3475740916,1,4,2411307,3 ,1 ,40,,2",
 "241500,2,2,1998/10/01 08:08:00,139.3970819112,35.3482725467,1,6,2420707,5 ,1 ,37,,6",
 "345000,2,4,1998/10/01 08:08:00,139.7176618651,35.7931204467,1,8,3012002,9 ,1 ,37,,12",
 "255300,2,2,1998/10/01 08:08:00,139.2040341221,35.2900244668,1,12,2610311,8 ,1 ,44,,6",
 "271400,2,2,1998/10/01 08:08:00,139.3199093774,35.4708998314,1,7,2811315,9 ,11,37,,8",
 "289800,2,2,1998/10/01 08:08:00,139.4368623359,35.460787334,2,3,2913122,12,2 ,38,,1",
 "370300,2,4,1998/10/01 08:08:00,139.746235696,35.6880493223,1,10,3037010,10,1 ,39,,1",
 "305900,2,2,1998/10/01 08:08:00,139.360515177,35.5514285035,2,9,2924003,3 ,1 ,34,,2",
 "386400,2,5,1998/10/01 08:08:00,139.755381187,35.6603272884,1,10,3059002,8 ,1 ,37,,1",
 "331200,2,2,1998/10/01 08:08:00,139.5782155568,35.5703291746,2,1,2121208,12,2 ,28,,1",
 "404800,2,3,1998/10/01 08:08:00,139.5263838215,35.7258570669,2,9,3120009,8 ,1 ,30,,12",
 "425500,2,3,1998/10/01 08:08:00,139.4772927008,35.9139070981,1,4,3134008,8 ,1 ,45,,12",
 "374900,2,2,1998/10/01 08:08:00,139.6445023317,35.8490696727,1,4,3040108,4 ,1 ,71,,6",
 "437000,2,4,1998/10/01 08:08:00,139.7719007304,35.6675887293,1,7,3212001,5 ,1 ,36,,1",
 "446200,2,3,1998/10/01 08:08:00,139.8024265875,35.817431571,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,2,1998/10/01 08:08:00,139.3924752275,35.9563213709,2,10,3411010,8 ,1 ,30,,2",
 "441600,4,4,1998/10/01 08:08:00,139.8421967347,35.7427975026,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 08:08:00,139.716974587,35.7864800285,1,10,3510101,8 ,1 ,27,,12",
 "478400,2,2,1998/10/01 08:08:00,139.3859262689,36.009673521,1,4,3413105,13,2 ,40,,1",
 "487600,2,2,1998/10/01 08:08:00,139.602899707,35.9332089036,1,10,3512006,6 ,1 ,36,,6",
 "563500,2,3,1998/10/01 08:08:00,139.7409328702,35.6980860808,1,7,0212001,10,1 ,84,,12",
 "646300,2,3,1998/10/01 08:08:00,139.7298810676,35.7422366162,1,4,0523008,8 ,1 ,75,,12",
 "644000,2,3,1998/10/01 08:08:00,139.7538177476,35.7080881972,1,4,0522008,5 ,1 ,52,,12",
 "811900,2,3,1998/10/01 08:08:00,139.5757926473,35.3749402346,1,3,1123201,13,2 ,31,,12",
 "811900,2,4,1998/10/01 08:08:00,139.5757926473,35.3749402346,1,3,1123201,13,2 ,31,,1",
 "823400,2,3,1998/10/01 08:08:00,139.5892576095,35.4625812003,1,10,1213105,9 ,13,34,,12",
 "745200,2,2,1998/10/01 08:08:00,139.4178468924,35.7304466615,2,10,0790316,3 ,1 ,30,,2",
 "761300,2,2,1998/10/01 08:08:00,139.4443400552,35.6171449829,1,1,0821303,12,2 ,35,,1",
 "768200,2,2,1998/10/01 08:08:00,139.3348943281,35.6750531854,2,2,0840002,12,2 ,36,,1",
 "786600,2,3,1998/10/01 08:08:00,139.3631415006,35.6911051757,2,3,0930110,13,2 ,35,,12",
 "798100,2,2,1998/10/01 08:08:00,139.5994415868,35.5079891164,2,6,1421008,9 ,1 ,37,,6",
 "777400,2,4,1998/10/01 08:08:00,139.6489522817,35.6359786049,1,5,0844010,8 ,1 ,41,,1",
 "821100,2,2,1998/10/01 08:08:00,139.5770968516,35.4470241897,2,1,1213204,12,2 ,38,,1",
 "669300,2,2,1998/10/01 08:08:00,139.8679284983,35.7659294698,1,6,0620210,9 ,13,47,,1",
 "660100,2,3,1998/10/01 08:08:00,139.8060101994,35.7680058034,1,7,0612111,9 ,1 ,60,,12",
 "759000,2,3,1998/10/01 08:08:00,139.6173617835,35.6696832368,2,4,0821006,9 ,1 ,55,,12",
 "867100,2,2,1998/10/01 08:08:00,139.4844689887,35.4237484706,2,2,1241102,12,2 ,29,,1",
 "876300,2,2,1998/10/01 08:08:00,139.485481179,35.3959595068,1,9,1223102,9 ,1 ,27,,7",
 "696900,2,3,1998/10/01 08:08:00,139.6502527395,35.67038825,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:08:00,139.4833764103,35.7002446068,1,7,0752005,6 ,10,34,,12",
 "683100,2,3,1998/10/01 08:08:00,139.7519188023,35.6956329577,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 08:08:00,139.6939661139,35.6862679733,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,3,1998/10/01 08:09:00,139.6163357469,35.4962926933,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:09:00,140.0915751881,35.7009979056,2,1,4410011,12,2 ,35,,9",
 "89700,5,5,1998/10/01 08:09:00,140.2448947118,35.7578364258,2,8,4422113,3 ,8 ,42,,7",
 "841800,2,4,1998/10/01 08:09:00,139.6595672414,35.4888241879,1,2,1132110,12,2 ,33,,1",
 "860200,2,3,1998/10/01 08:09:00,139.6287457924,35.4535024746,1,9,1340109,10,1 ,33,,12",
 "280600,5,5,1998/10/01 08:09:00,139.7396248262,35.6679309563,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:09:00,139.6882967493,35.5240833505,1,10,1231001,8 ,1 ,29,,12",
 "871700,2,4,1998/10/01 08:09:00,139.5352411584,35.3491112153,1,6,1230404,9 ,1 ,31,,1",
 "2300,2,3,1998/10/01 08:09:00,139.8539088157,35.7143570868,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:09:00,139.5861764487,35.8340544548,1,6,3032201,3 ,11,34,,6",
 "13800,2,4,1998/10/01 08:09:00,139.9421694306,35.7891969231,2,14,4116004,5 ,5 ,25,,10",
 "18400,2,4,1998/10/01 08:09:00,139.6994370673,35.6879698868,1,9,4120311,8 ,1 ,44,,1",
 "434700,4,4,1998/10/01 08:09:00,139.7691144418,35.8164526538,2,8,3210107,8 ,1 ,37,,7",
 "34500,2,3,1998/10/01 08:09:00,140.0351964882,35.7264690531,1,4,4129003,8 ,1 ,36,,12",
 "41400,2,4,1998/10/01 08:09:00,139.9709087198,35.8621074522,2,5,4212212,8 ,1 ,44,,12",
 "41400,2,5,1998/10/01 08:09:00,139.9709087198,35.8621074522,2,5,4212212,8 ,1 ,44,,10",
 "593400,2,3,1998/10/01 08:09:00,139.8191649458,35.6835115683,1,6,0349009,9 ,1 ,58,,10",
 "62100,2,2,1998/10/01 08:09:00,140.0379895223,35.8670470947,1,7,4224202,9 ,1 ,37,,1",
 "78200,2,3,1998/10/01 08:09:00,139.8988157658,35.6698323427,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:09:00,139.9946712172,35.7007654263,1,9,4031206,8 ,1 ,38,,12",
 "828000,2,3,1998/10/01 08:09:00,139.5050663593,35.5044122186,1,5,1252001,4 ,1 ,38,,10",
 "172500,2,3,1998/10/01 08:09:00,140.0337829691,35.8736387503,2,4,5024120,13,2 ,44,,12",
 "197800,2,2,1998/10/01 08:09:00,140.186407851,35.9018770546,2,3,5421032,12,2 ,37,,7",
 "209300,2,3,1998/10/01 08:09:00,139.6281996829,35.3584784068,1,13,2211303,10,1 ,37,,12",
 "211600,2,3,1998/10/01 08:09:00,139.7230361063,35.2408597142,1,7,2220004,6 ,1 ,36,,10",
 "225400,2,2,1998/10/01 08:09:00,139.5297854022,35.3511035636,1,3,2313201,13,2 ,36,,3",
 "227700,2,3,1998/10/01 08:09:00,139.6925139382,35.5276375016,1,9,2410203,8 ,10,16,,12",
 "234600,2,4,1998/10/01 08:09:00,139.7823472339,35.6769629537,1,14,2412116,10,1 ,63,,1",
 "301300,2,3,1998/10/01 08:09:00,139.6930102533,35.5280734505,1,11,2922005,9 ,1 ,29,,12",
 "303600,2,3,1998/10/01 08:09:00,139.6022841927,35.641065817,1,9,2923016,9 ,1 ,30,,12",
 "85100,2,2,1998/10/01 08:09:00,140.2105333878,35.7273299592,1,8,4417027,9 ,10,46,,6",
 "310500,2,3,1998/10/01 08:09:00,139.5717283743,35.4439448415,1,9,2013102,3 ,1 ,37,,12",
 "124200,2,2,1998/10/01 08:09:00,140.4782479712,35.687681018,2,1,4813118,12,2 ,26,,9",
 "322000,2,2,1998/10/01 08:09:00,139.629912119,35.5875502905,2,5,2032102,8 ,1 ,36,,1",
 "232300,2,2,1998/10/01 08:09:00,139.4848658079,35.346924649,1,4,2411307,3 ,1 ,40,,2",
 "345000,2,4,1998/10/01 08:09:00,139.7173533521,35.7901579281,1,8,3012002,9 ,1 ,37,,12",
 "241500,2,2,1998/10/01 08:09:00,139.3966191786,35.3504046425,1,6,2420707,5 ,1 ,37,,6",
 "255300,2,2,1998/10/01 08:09:00,139.2057849753,35.2873779209,1,12,2610311,8 ,1 ,44,,6",
 "271400,2,2,1998/10/01 08:09:00,139.3215629948,35.4708780775,1,7,2811315,9 ,11,37,,8",
 "289800,2,2,1998/10/01 08:09:00,139.4368597606,35.4615033443,2,3,2913122,12,2 ,38,,1",
 "370300,2,4,1998/10/01 08:09:00,139.7468355364,35.6878349081,1,10,3037010,10,1 ,39,,1",
 "305900,2,2,1998/10/01 08:09:00,139.3616740577,35.5501065339,2,9,2924003,3 ,1 ,34,,2",
 "386400,2,5,1998/10/01 08:09:00,139.755223609,35.6596363584,1,10,3059002,8 ,1 ,37,,1",
 "331200,2,2,1998/10/01 08:09:00,139.577761479,35.5703491174,2,1,2121208,12,2 ,28,,1",
 "404800,2,3,1998/10/01 08:09:00,139.5316948536,35.7264316442,2,9,3120009,8 ,1 ,30,,12",
 "374900,2,2,1998/10/01 08:09:00,139.648314553,35.8479632071,1,4,3040108,4 ,1 ,71,,6",
 "425500,2,3,1998/10/01 08:09:00,139.4816336917,35.9089991813,1,4,3134008,8 ,1 ,45,,12",
 "437000,2,4,1998/10/01 08:09:00,139.7715662987,35.6673270889,1,7,3212001,5 ,1 ,36,,1",
 "446200,2,3,1998/10/01 08:09:00,139.8013549537,35.8146782705,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,2,1998/10/01 08:09:00,139.3932726002,35.9567365203,2,10,3411010,8 ,1 ,30,,2",
 "478400,2,2,1998/10/01 08:09:00,139.3865032274,36.0094149618,1,4,3413105,13,2 ,40,,1",
 "441600,4,4,1998/10/01 08:09:00,139.8406641433,35.7413467715,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 08:09:00,139.7180639904,35.7830212875,1,10,3510101,8 ,1 ,27,,12",
 "487600,2,2,1998/10/01 08:09:00,139.6038216661,35.9314029841,1,10,3512006,6 ,1 ,36,,6",
 "563500,2,3,1998/10/01 08:09:00,139.7390236926,35.6955343507,1,7,0212001,10,1 ,84,,12",
 "644000,2,3,1998/10/01 08:09:00,139.7549113054,35.7044843113,1,4,0522008,5 ,1 ,52,,12",
 "646300,2,3,1998/10/01 08:09:00,139.7340431545,35.7378377402,1,4,0523008,8 ,1 ,75,,12",
 "823400,2,3,1998/10/01 08:09:00,139.5902478442,35.4622139748,1,10,1213105,9 ,13,34,,12",
 "811900,2,4,1998/10/01 08:09:00,139.5764930317,35.3727033353,1,3,1123201,13,2 ,31,,1",
 "745200,2,2,1998/10/01 08:09:00,139.4179096812,35.7283095101,2,10,0790316,3 ,1 ,30,,2",
 "761300,2,2,1998/10/01 08:09:00,139.445346727,35.616985394,1,1,0821303,12,2 ,35,,1",
 "768200,2,2,1998/10/01 08:09:00,139.3354930919,35.6754076604,2,2,0840002,12,2 ,36,,1",
 "777400,2,4,1998/10/01 08:09:00,139.6491644365,35.6348456776,1,5,0844010,8 ,1 ,41,,1",
 "798100,2,2,1998/10/01 08:09:00,139.5913493698,35.5084206251,2,6,1421008,9 ,1 ,37,,6",
 "786600,2,3,1998/10/01 08:09:00,139.3660993248,35.6879771573,2,3,0930110,13,2 ,35,,12",
 "821100,2,2,1998/10/01 08:09:00,139.5779728123,35.4474420835,2,1,1213204,12,2 ,38,,1",
 "660100,2,3,1998/10/01 08:09:00,139.80864568,35.7665231026,1,7,0612111,9 ,1 ,60,,12",
 "669300,2,2,1998/10/01 08:09:00,139.8685992973,35.7665370241,1,6,0620210,9 ,13,47,,1",
 "759000,2,3,1998/10/01 08:09:00,139.6227923514,35.6689486982,2,4,0821006,9 ,1 ,55,,12",
 "867100,2,2,1998/10/01 08:09:00,139.4847708962,35.4226167381,2,2,1241102,12,2 ,29,,1",
 "876300,2,2,1998/10/01 08:09:00,139.485982949,35.397917748,1,9,1223102,9 ,1 ,27,,7",
 "696900,2,3,1998/10/01 08:09:00,139.6511064443,35.6668960379,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:09:00,139.4870666106,35.7004584004,1,7,0752005,6 ,10,34,,12",
 "683100,2,3,1998/10/01 08:09:00,139.7485682255,35.6948785842,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 08:09:00,139.6982219327,35.688290725,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,3,1998/10/01 08:10:00,139.627716453,35.4961932574,2,4,1251203,8 ,1 ,37,,12",
 "89700,5,5,1998/10/01 08:10:00,140.2412630517,35.7615306828,2,8,4422113,3 ,8 ,42,,7",
 "73600,2,3,1998/10/01 08:10:00,140.0919240141,35.7010320946,2,1,4410011,12,2 ,35,,9",
 "841800,2,4,1998/10/01 08:10:00,139.6601907632,35.4892166693,1,2,1132110,12,2 ,33,,1",
 "860200,2,3,1998/10/01 08:10:00,139.630953589,35.4502094603,1,9,1340109,10,1 ,33,,12",
 "860200,2,4,1998/10/01 08:10:00,139.630953589,35.4502094603,1,9,1340109,10,1 ,33,,1",
 "871700,2,4,1998/10/01 08:10:00,139.5348755544,35.3488794102,1,6,1230404,9 ,1 ,31,,1",
 "871700,3,5,1998/10/01 08:10:00,139.5348755544,35.3488794102,1,6,1230404,9 ,97,31,,97",
 "869400,2,3,1998/10/01 08:10:00,139.6922919441,35.5274448211,1,10,1231001,8 ,1 ,29,,12",
 "280600,5,5,1998/10/01 08:10:00,139.7418187216,35.6701155671,1,11,2910009,8 ,9 ,42,,8",
 "2300,2,3,1998/10/01 08:10:00,139.8503516233,35.7119968509,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:10:00,139.5878850042,35.8324750563,1,6,3032201,3 ,11,34,,6",
 "13800,2,4,1998/10/01 08:10:00,139.9422828692,35.7888662535,2,14,4116004,5 ,5 ,25,,10",
 "18400,2,4,1998/10/01 08:10:00,139.7004511539,35.6874890388,1,9,4120311,8 ,1 ,44,,1",
 "18400,3,5,1998/10/01 08:10:00,139.7004511539,35.6874890388,1,9,4120311,8 ,97,44,,97",
 "434700,4,4,1998/10/01 08:10:00,139.7666525108,35.8161687294,2,8,3210107,8 ,1 ,37,,7",
 "34500,2,3,1998/10/01 08:10:00,140.0298132987,35.7301246414,1,4,4129003,8 ,1 ,36,,12",
 "41400,2,5,1998/10/01 08:10:00,139.9714725089,35.8618907721,2,5,4212212,8 ,1 ,44,,10",
 "593400,2,3,1998/10/01 08:10:00,139.8191648548,35.6835116552,1,6,0349009,9 ,1 ,58,,10",
 "62100,2,2,1998/10/01 08:10:00,140.0399036641,35.8673228625,1,7,4224202,9 ,1 ,37,,1",
 "78200,2,3,1998/10/01 08:10:00,139.8951757638,35.666936472,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:10:00,139.9878531483,35.7014234876,1,9,4031206,8 ,1 ,38,,12",
 "828000,2,3,1998/10/01 08:10:00,139.5050664176,35.5044123978,1,5,1252001,4 ,1 ,38,,10",
 "172500,2,3,1998/10/01 08:10:00,140.0276590404,35.8738518331,2,4,5024120,13,2 ,44,,12",
 "197800,2,2,1998/10/01 08:10:00,140.1864079055,35.9018771767,2,3,5421032,12,2 ,37,,7",
 "209300,2,3,1998/10/01 08:10:00,139.6297901959,35.3643731395,1,13,2211303,10,1 ,37,,12",
 "211600,2,3,1998/10/01 08:10:00,139.7211840485,35.2427096146,1,7,2220004,6 ,1 ,36,,10",
 "225400,2,2,1998/10/01 08:10:00,139.5300111469,35.3523324275,1,3,2313201,13,2 ,36,,3",
 "227700,2,3,1998/10/01 08:10:00,139.6979559855,35.5323274982,1,9,2410203,8 ,10,16,,12",
 "234600,2,4,1998/10/01 08:10:00,139.7829232399,35.6762206066,1,14,2412116,10,1 ,63,,1",
 "301300,2,3,1998/10/01 08:10:00,139.6968624888,35.5314219481,1,11,2922005,9 ,1 ,29,,12",
 "301300,2,4,1998/10/01 08:10:00,139.6968624888,35.5314219481,1,11,2922005,9 ,1 ,29,,1",
 "85100,2,2,1998/10/01 08:10:00,140.205373078,35.7269517069,1,8,4417027,9 ,10,46,,6",
 "303600,2,3,1998/10/01 08:10:00,139.607022867,35.6424644933,1,9,2923016,9 ,1 ,30,,12",
 "310500,2,3,1998/10/01 08:10:00,139.5631637426,35.4396508335,1,9,2013102,3 ,1 ,37,,12",
 "124200,2,2,1998/10/01 08:10:00,140.477453774,35.6921848944,2,1,4813118,12,2 ,26,,9",
 "322000,2,2,1998/10/01 08:10:00,139.6292830452,35.5874131454,2,5,2032102,8 ,1 ,36,,1",
 "322000,2,3,1998/10/01 08:10:00,139.6292830452,35.5874131454,2,5,2032102,8 ,1 ,36,,12",
 "232300,2,2,1998/10/01 08:10:00,139.4865610276,35.3457539106,1,4,2411307,3 ,1 ,40,,2",
 "345000,2,4,1998/10/01 08:10:00,139.7170403966,35.7871956419,1,8,3012002,9 ,1 ,37,,12",
 "241500,2,2,1998/10/01 08:10:00,139.3956494477,35.3522491298,1,6,2420707,5 ,1 ,37,,6",
 "255300,2,2,1998/10/01 08:10:00,139.2066067275,35.2856381331,1,12,2610311,8 ,1 ,44,,6",
 "255300,3,3,1998/10/01 08:10:00,139.2066067275,35.2856381331,1,12,2610311,8 ,97,44,,97",
 "271400,2,2,1998/10/01 08:10:00,139.323164512,35.4705921507,1,7,2811315,9 ,11,37,,8",
 "289800,2,2,1998/10/01 08:10:00,139.4370193979,35.4619538991,2,3,2913122,12,2 ,38,,1",
 "289800,3,3,1998/10/01 08:10:00,139.4370193979,35.4619538991,2,3,2913122,12,97,38,,97",
 "370300,2,4,1998/10/01 08:10:00,139.7470393024,35.6872057105,1,10,3037010,10,1 ,39,,1",
 "370300,3,5,1998/10/01 08:10:00,139.7470393024,35.6872057105,1,10,3037010,10,97,39,,97",
 "305900,2,2,1998/10/01 08:10:00,139.3623958982,35.5488760187,2,9,2924003,3 ,1 ,34,,2",
 "305900,3,3,1998/10/01 08:10:00,139.3623958982,35.5488760187,2,9,2924003,3 ,97,34,,97",
 "386400,2,5,1998/10/01 08:10:00,139.755116212,35.6589329523,1,10,3059002,8 ,1 ,37,,1",
 "331200,2,2,1998/10/01 08:10:00,139.5774376129,35.5706163455,2,1,2121208,12,2 ,28,,1",
 "331200,3,3,1998/10/01 08:10:00,139.5774376129,35.5706163455,2,1,2121208,12,97,28,,97",
 "404800,2,3,1998/10/01 08:10:00,139.5370059636,35.7270059874,2,9,3120009,8 ,1 ,30,,12",
 "374900,2,2,1998/10/01 08:10:00,139.6510957275,35.8460358195,1,4,3040108,4 ,1 ,71,,6",
 "425500,2,3,1998/10/01 08:10:00,139.4859502978,35.9040774849,1,4,3134008,8 ,1 ,45,,12",
 "437000,2,4,1998/10/01 08:10:00,139.7712318692,35.6670654475,1,7,3212001,5 ,1 ,36,,1",
 "446200,2,3,1998/10/01 08:10:00,139.7996373414,35.8121496389,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,2,1998/10/01 08:10:00,139.3940430944,35.9571080285,2,10,3411010,8 ,1 ,30,,2",
 "473800,2,3,1998/10/01 08:10:00,139.3940430944,35.9571080285,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 08:10:00,139.8391163717,35.7398953486,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 08:10:00,139.7197720164,35.7797018617,1,10,3510101,8 ,1 ,27,,12",
 "478400,2,2,1998/10/01 08:10:00,139.3870801822,36.0091563998,1,4,3413105,13,2 ,40,,1",
 "487600,2,2,1998/10/01 08:10:00,139.6050591202,35.9298516017,1,10,3512006,6 ,1 ,36,,6",
 "487600,3,3,1998/10/01 08:10:00,139.6050591202,35.9298516017,1,10,3512006,6 ,97,36,,97",
 "563500,2,3,1998/10/01 08:10:00,139.737130105,35.692975183,1,7,0212001,10,1 ,84,,12",
 "646300,2,3,1998/10/01 08:10:00,139.7383787828,35.733564163,1,4,0523008,8 ,1 ,75,,12",
 "644000,2,3,1998/10/01 08:10:00,139.7555652958,35.700856273,1,4,0522008,5 ,1 ,52,,12",
 "811900,2,4,1998/10/01 08:10:00,139.5766239661,35.3704505832,1,3,1123201,13,2 ,31,,1",
 "660100,2,3,1998/10/01 08:10:00,139.8112413253,35.7649950497,1,7,0612111,9 ,1 ,60,,12",
 "745200,2,2,1998/10/01 08:10:00,139.4178877837,35.7261714212,2,10,0790316,3 ,1 ,30,,2",
 "761300,2,2,1998/10/01 08:10:00,139.4465125283,35.6172776098,1,1,0821303,12,2 ,35,,1",
 "761300,3,3,1998/10/01 08:10:00,139.4465125283,35.6172776098,1,1,0821303,12,97,35,,97",
 "786600,2,3,1998/10/01 08:10:00,139.369202302,35.6849004869,2,3,0930110,13,2 ,35,,12",
 "768200,2,2,1998/10/01 08:10:00,139.3361876538,35.6757601548,2,2,0840002,12,2 ,36,,1",
 "768200,3,3,1998/10/01 08:10:00,139.3361876538,35.6757601548,2,2,0840002,12,97,36,,97",
 "777400,2,4,1998/10/01 08:10:00,139.6490309895,35.6338842614,1,5,0844010,8 ,1 ,41,,1",
 "777400,3,5,1998/10/01 08:10:00,139.6490309895,35.6338842614,1,5,0844010,8 ,97,41,,97",
 "798100,2,2,1998/10/01 08:10:00,139.5864801077,35.5134162928,2,6,1421008,9 ,1 ,37,,6",
 "821100,2,2,1998/10/01 08:10:00,139.5773508792,35.4481694856,2,1,1213204,12,2 ,38,,1",
 "669300,2,2,1998/10/01 08:10:00,139.8695895918,35.7674141537,1,6,0620210,9 ,13,47,,1",
 "759000,2,3,1998/10/01 08:10:00,139.6282033252,35.6681262361,2,4,0821006,9 ,1 ,55,,12",
 "876300,2,2,1998/10/01 08:10:00,139.4864564106,35.3997661813,1,9,1223102,9 ,1 ,27,,7",
 "867100,2,2,1998/10/01 08:10:00,139.4850437622,35.4214797543,2,2,1241102,12,2 ,29,,1",
 "823400,2,3,1998/10/01 08:10:00,139.5912372618,35.4618453077,1,10,1213105,9 ,13,34,,12",
 "683100,2,3,1998/10/01 08:10:00,139.7452912684,35.6939423754,2,3,0633106,12,2 ,49,,12",
 "696900,2,3,1998/10/01 08:10:00,139.6531502806,35.6637326623,2,6,0720006,4 ,1 ,39,,12",
 "692300,2,3,1998/10/01 08:10:00,139.7028802385,35.6895896783,2,4,0711108,8 ,1 ,25,,12",
 "719900,2,4,1998/10/01 08:10:00,139.4907617141,35.7005926282,1,7,0752005,6 ,10,34,,12",
 "830300,2,3,1998/10/01 08:11:00,139.6390935155,35.4959216042,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:11:00,140.0922728404,35.7010662826,2,1,4410011,12,2 ,35,,9",
 "89700,5,5,1998/10/01 08:11:00,140.2397389741,35.7661550715,2,8,4422113,3 ,8 ,42,,7",
 "841800,2,4,1998/10/01 08:11:00,139.6608130427,35.4896105049,1,2,1132110,12,2 ,33,,1",
 "860200,2,4,1998/10/01 08:11:00,139.631504486,35.4495198832,1,9,1340109,10,1 ,33,,1",
 "869400,2,3,1998/10/01 08:11:00,139.6962014798,35.5308730218,1,10,1231001,8 ,1 ,29,,12",
 "280600,5,5,1998/10/01 08:11:00,139.7442925886,35.671968967,1,11,2910009,8 ,9 ,42,,8",
 "2300,2,3,1998/10/01 08:11:00,139.8468833537,35.7095504175,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:11:00,139.5899754074,35.8312617268,1,6,3032201,3 ,11,34,,6",
 "13800,2,4,1998/10/01 08:11:00,139.9425987677,35.7886440362,2,14,4116004,5 ,5 ,25,,10",
 "434700,4,4,1998/10/01 08:11:00,139.7648015328,35.8168832641,2,8,3210107,8 ,1 ,37,,7",
 "34500,2,3,1998/10/01 08:11:00,140.0251857163,35.734320125,1,4,4129003,8 ,1 ,36,,12",
 "41400,2,5,1998/10/01 08:11:00,139.9720161143,35.8616506166,2,5,4212212,8 ,1 ,44,,10",
 "593400,2,3,1998/10/01 08:11:00,139.8191647638,35.6835117421,1,6,0349009,9 ,1 ,58,,10",
 "657800,2,2,1998/10/01 08:11:00,139.7981196028,35.7563045315,2,11,0611113,9 ,1 ,46,,1",
 "62100,2,2,1998/10/01 08:11:00,140.0415727353,35.8676549159,1,7,4224202,9 ,1 ,37,,1",
 "78200,2,3,1998/10/01 08:11:00,139.8908639725,35.6647357248,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:11:00,139.981033932,35.7020731059,1,9,4031206,8 ,1 ,38,,12",
 "828000,2,3,1998/10/01 08:11:00,139.505066476,35.5044125771,1,5,1252001,4 ,1 ,38,,10",
 "172500,2,3,1998/10/01 08:11:00,140.0215533138,35.8733923938,2,4,5024120,13,2 ,44,,12",
 "197800,2,2,1998/10/01 08:11:00,140.18640796,35.9018772988,2,3,5421032,12,2 ,37,,7",
 "209300,2,3,1998/10/01 08:11:00,139.6291167589,35.3704268744,1,13,2211303,10,1 ,37,,12",
 "211600,2,3,1998/10/01 08:11:00,139.720690604,35.2450385623,1,7,2220004,6 ,1 ,36,,10",
 "227700,2,3,1998/10/01 08:11:00,139.7033741679,35.5370054869,1,9,2410203,8 ,10,16,,12",
 "225400,2,2,1998/10/01 08:11:00,139.5304508608,35.353521829,1,3,2313201,13,2 ,36,,3",
 "234600,2,4,1998/10/01 08:11:00,139.7834992352,35.6754782565,1,14,2412116,10,1 ,63,,1",
 "303600,2,3,1998/10/01 08:11:00,139.6117643421,35.6438567659,1,9,2923016,9 ,1 ,30,,12",
 "301300,2,4,1998/10/01 08:11:00,139.6950873845,35.5315225996,1,11,2922005,9 ,1 ,29,,1",
 "85100,2,2,1998/10/01 08:11:00,140.2010357649,35.7246907652,1,8,4417027,9 ,10,46,,6",
 "310500,2,3,1998/10/01 08:11:00,139.5572449761,35.433129293,1,9,2013102,3 ,1 ,37,,12",
 "124200,2,2,1998/10/01 08:11:00,140.4794704098,35.6963325962,2,1,4813118,12,2 ,26,,9",
 "322000,2,3,1998/10/01 08:11:00,139.6333797385,35.5853065286,2,5,2032102,8 ,1 ,36,,12",
 "181700,2,2,1998/10/01 08:11:00,139.9565496077,36.1572068627,2,7,5123003,8 ,1 ,31,,6",
 "232300,2,2,1998/10/01 08:11:00,139.4878188548,35.3450323168,1,4,2411307,3 ,1 ,40,,2",
 "241500,2,2,1998/10/01 08:11:00,139.3947600315,35.3541529237,1,6,2420707,5 ,1 ,37,,6",
 "345000,2,4,1998/10/01 08:11:00,139.7174799907,35.7842810849,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:11:00,139.3247329928,35.4701631638,1,7,2811315,9 ,11,37,,8",
 "386400,2,5,1998/10/01 08:11:00,139.7550109968,35.6582293273,1,10,3059002,8 ,1 ,37,,1",
 "404800,2,3,1998/10/01 08:11:00,139.5423120191,35.7276098687,2,9,3120009,8 ,1 ,30,,12",
 "374900,2,2,1998/10/01 08:11:00,139.6539444231,35.8436910843,1,4,3040108,4 ,1 ,71,,6",
 "425500,2,3,1998/10/01 08:11:00,139.4901705555,35.8991006809,1,4,3134008,8 ,1 ,45,,12",
 "437000,2,4,1998/10/01 08:11:00,139.7708974419,35.6668038052,1,7,3212001,5 ,1 ,36,,1",
 "446200,2,3,1998/10/01 08:11:00,139.7976489652,35.8097467305,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,3,1998/10/01 08:11:00,139.3990928633,35.9562122745,2,10,3411010,8 ,1 ,30,,12",
 "478400,2,2,1998/10/01 08:11:00,139.3876571332,36.008897835,1,4,3413105,13,2 ,40,,1",
 "441600,4,4,1998/10/01 08:11:00,139.8374963789,35.7384944578,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 08:11:00,139.7218052089,35.7765045725,1,10,3510101,8 ,1 ,27,,12",
 "563500,2,3,1998/10/01 08:11:00,139.7356622238,35.6902485349,1,7,0212001,10,1 ,84,,12",
 "644000,2,3,1998/10/01 08:11:00,139.7574553997,35.6974789142,1,4,0522008,5 ,1 ,52,,12",
 "646300,2,3,1998/10/01 08:11:00,139.7429776931,35.729472617,1,4,0523008,8 ,1 ,75,,12",
 "811900,2,4,1998/10/01 08:11:00,139.5789023302,35.3691752071,1,3,1123201,13,2 ,31,,1",
 "823400,2,3,1998/10/01 08:11:00,139.5921780098,35.4614029467,1,10,1213105,9 ,13,34,,12",
 "660100,2,3,1998/10/01 08:11:00,139.8131797298,35.7629952291,1,7,0612111,9 ,1 ,60,,12",
 "745200,2,2,1998/10/01 08:11:00,139.4179306757,35.7240334827,2,10,0790316,3 ,1 ,30,,2",
 "798100,2,2,1998/10/01 08:11:00,139.5791824341,35.5160361645,2,6,1421008,9 ,1 ,37,,6",
 "786600,2,3,1998/10/01 08:11:00,139.3700229376,35.6810107588,2,3,0930110,13,2 ,35,,12",
 "669300,2,2,1998/10/01 08:11:00,139.8705799081,35.7682912749,1,6,0620210,9 ,13,47,,1",
 "821100,2,2,1998/10/01 08:11:00,139.5763727743,35.4486037452,2,1,1213204,12,2 ,38,,1",
 "759000,2,3,1998/10/01 08:11:00,139.6336195475,35.6673224243,2,4,0821006,9 ,1 ,55,,12",
 "867100,2,2,1998/10/01 08:11:00,139.4851318535,35.4203239101,2,2,1241102,12,2 ,29,,1",
 "876300,2,2,1998/10/01 08:11:00,139.4872213618,35.4016666375,1,9,1223102,9 ,1 ,27,,7",
 "719900,2,4,1998/10/01 08:11:00,139.4944596379,35.700681033,1,7,0752005,6 ,10,34,,12",
 "683100,2,3,1998/10/01 08:11:00,139.74207004,35.6928745175,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 08:11:00,139.7074331642,35.6911468055,2,4,0711108,8 ,1 ,25,,12",
 "696900,2,3,1998/10/01 08:11:00,139.6571401125,35.6625883685,2,6,0720006,4 ,1 ,39,,12",
 "830300,2,3,1998/10/01 08:12:00,139.6504720076,35.4957264678,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:12:00,140.0926216651,35.7011004828,2,1,4410011,12,2 ,35,,9",
 "89700,5,5,1998/10/01 08:12:00,140.2359734539,35.7696892309,2,8,4422113,3 ,8 ,42,,7",
 "841800,2,4,1998/10/01 08:12:00,139.6614353283,35.4900043374,1,2,1132110,12,2 ,33,,1",
 "860200,2,4,1998/10/01 08:12:00,139.6321238811,35.4488800033,1,9,1340109,10,1 ,33,,1",
 "280600,5,5,1998/10/01 08:12:00,139.7465476747,35.6734503237,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:12:00,139.7003308676,35.5341208625,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,3,1998/10/01 08:12:00,139.8434203928,35.7070989372,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:12:00,139.5905514919,35.8295641927,1,6,3032201,3 ,11,34,,6",
 "13800,2,4,1998/10/01 08:12:00,139.9428080131,35.7884653729,2,14,4116004,5 ,5 ,25,,10",
 "434700,4,4,1998/10/01 08:12:00,139.763148249,35.8169736615,2,8,3210107,8 ,1 ,37,,7",
 "34500,2,3,1998/10/01 08:12:00,140.0275187522,35.7394321999,1,4,4129003,8 ,1 ,36,,12",
 "41400,2,5,1998/10/01 08:12:00,139.9724592825,35.8612936417,2,5,4212212,8 ,1 ,44,,10",
 "593400,2,3,1998/10/01 08:12:00,139.8191646728,35.683511829,1,6,0349009,9 ,1 ,58,,10",
 "657800,2,2,1998/10/01 08:12:00,139.7981195703,35.756304715,2,11,0611113,9 ,1 ,46,,1",
 "62100,2,2,1998/10/01 08:12:00,140.0413779327,35.8692350288,1,7,4224202,9 ,1 ,37,,1",
 "78200,2,3,1998/10/01 08:12:00,139.8859825388,35.6637685636,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:12:00,139.9742285678,35.7019814557,1,9,4031206,8 ,1 ,38,,12",
 "828000,2,3,1998/10/01 08:12:00,139.5050665344,35.5044127564,1,5,1252001,4 ,1 ,38,,10",
 "172500,2,3,1998/10/01 08:12:00,140.0154297335,35.8731364876,2,4,5024120,13,2 ,44,,12",
 "197800,2,2,1998/10/01 08:12:00,140.1864080144,35.9018774209,2,3,5421032,12,2 ,37,,7",
 "197800,2,3,1998/10/01 08:12:00,140.1864080144,35.9018774209,2,3,5421032,12,2 ,37,,1",
 "209300,2,3,1998/10/01 08:12:00,139.6240446994,35.3743064254,1,13,2211303,10,1 ,37,,12",
 "211600,2,3,1998/10/01 08:12:00,139.7188106832,35.246801427,1,7,2220004,6 ,1 ,36,,10",
 "225400,2,2,1998/10/01 08:12:00,139.530449507,35.3534549621,1,3,2313201,13,2 ,36,,3",
 "227700,2,3,1998/10/01 08:12:00,139.7077258182,35.542401553,1,9,2410203,8 ,10,16,,12",
 "234600,2,4,1998/10/01 08:12:00,139.7841078283,35.6747615446,1,14,2412116,10,1 ,63,,1",
 "303600,2,3,1998/10/01 08:12:00,139.6165033824,35.645254979,1,9,2923016,9 ,1 ,30,,12",
 "301300,2,4,1998/10/01 08:12:00,139.6942646844,35.5311584079,1,11,2922005,9 ,1 ,29,,1",
 "85100,2,2,1998/10/01 08:12:00,140.1964090825,35.7239038931,1,8,4417027,9 ,10,46,,6",
 "310500,2,3,1998/10/01 08:12:00,139.5554741128,35.4251237667,1,9,2013102,3 ,1 ,37,,12",
 "124200,2,2,1998/10/01 08:12:00,140.4845587823,35.6986377565,2,1,4813118,12,2 ,26,,9",
 "322000,2,3,1998/10/01 08:12:00,139.6374041453,35.5831008116,2,5,2032102,8 ,1 ,36,,12",
 "181700,2,2,1998/10/01 08:12:00,139.9621910052,36.1577043249,2,7,5123003,8 ,1 ,31,,6",
 "232300,2,2,1998/10/01 08:12:00,139.4889915048,35.3435405729,1,4,2411307,3 ,1 ,40,,2",
 "241500,2,2,1998/10/01 08:12:00,139.3940447518,35.356040646,1,6,2420707,5 ,1 ,37,,6",
 "345000,2,4,1998/10/01 08:12:00,139.7187827816,35.7815039685,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:12:00,139.3262461808,35.4696393631,1,7,2811315,9 ,11,37,,8",
 "386400,2,5,1998/10/01 08:12:00,139.7549138598,35.6575249823,1,10,3059002,8 ,1 ,37,,1",
 "404800,2,3,1998/10/01 08:12:00,139.5476197296,35.7282009878,2,9,3120009,8 ,1 ,30,,12",
 "425500,2,3,1998/10/01 08:12:00,139.494484376,35.8941773797,1,4,3134008,8 ,1 ,45,,12",
 "374900,2,2,1998/10/01 08:12:00,139.6571065236,35.841594958,1,4,3040108,4 ,1 ,71,,6",
 "437000,2,4,1998/10/01 08:12:00,139.7705630167,35.666542162,1,7,3212001,5 ,1 ,36,,1",
 "446200,2,3,1998/10/01 08:12:00,139.7957168897,35.8073176615,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,3,1998/10/01 08:12:00,139.4030722615,35.9534756373,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 08:12:00,139.835857199,35.7371071133,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 08:12:00,139.7222520857,35.7729380322,1,10,3510101,8 ,1 ,27,,12",
 "478400,2,2,1998/10/01 08:12:00,139.3882340805,36.0086392673,1,4,3413105,13,2 ,40,,1",
 "563500,2,3,1998/10/01 08:12:00,139.7362472434,35.6873914029,1,7,0212001,10,1 ,84,,12",
 "644000,2,3,1998/10/01 08:12:00,139.7586008027,35.6939207403,1,4,0522008,5 ,1 ,52,,12",
 "646300,2,3,1998/10/01 08:12:00,139.7481371864,35.7258671379,1,4,0523008,8 ,1 ,75,,12",
 "823400,2,3,1998/10/01 08:12:00,139.5929195638,35.4607570952,1,10,1213105,9 ,13,34,,12",
 "811900,2,4,1998/10/01 08:12:00,139.5802511217,35.3673383006,1,3,1123201,13,2 ,31,,1",
 "745200,2,2,1998/10/01 08:12:00,139.4179973898,35.7218959579,2,10,0790316,3 ,1 ,30,,2",
 "786600,2,3,1998/10/01 08:12:00,139.3683292464,35.6772729314,2,3,0930110,13,2 ,35,,12",
 "798100,2,2,1998/10/01 08:12:00,139.5717280521,35.5168195342,2,6,1421008,9 ,1 ,37,,6",
 "669300,2,2,1998/10/01 08:12:00,139.8716061696,35.7691404518,1,6,0620210,9 ,13,47,,1",
 "660100,2,3,1998/10/01 08:12:00,139.8134591644,35.7604412077,1,7,0612111,9 ,1 ,60,,12",
 "821100,2,2,1998/10/01 08:12:00,139.5753420457,35.4490806994,2,1,1213204,12,2 ,38,,1",
 "759000,2,3,1998/10/01 08:12:00,139.6390323929,35.6665019483,2,4,0821006,9 ,1 ,55,,12",
 "867100,2,2,1998/10/01 08:12:00,139.4851995999,35.4191665367,2,2,1241102,12,2 ,29,,1",
 "876300,2,2,1998/10/01 08:12:00,139.4875416448,35.4036121349,1,9,1223102,9 ,1 ,27,,7",
 "696900,2,3,1998/10/01 08:12:00,139.6615216362,35.6624936063,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:12:00,139.4981566896,35.7007883487,1,7,0752005,6 ,10,34,,12",
 "683100,2,3,1998/10/01 08:12:00,139.7387975785,35.691913554,2,3,0633106,12,2 ,49,,12",
 "692300,2,3,1998/10/01 08:12:00,139.7122602772,35.6912896751,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,3,1998/10/01 08:13:00,139.6616789126,35.4943384608,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:13:00,140.0929704347,35.7011350557,2,1,4410011,12,2 ,35,,9",
 "89700,5,5,1998/10/01 08:13:00,140.2310699093,35.7724104494,2,8,4422113,3 ,8 ,42,,7",
 "841800,2,4,1998/10/01 08:13:00,139.6617047921,35.4897952052,1,2,1132110,12,2 ,33,,1",
 "860200,2,4,1998/10/01 08:13:00,139.632906703,35.4483733097,1,9,1340109,10,1 ,33,,1",
 "280600,5,5,1998/10/01 08:13:00,139.7469239072,35.6757890487,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:13:00,139.7039740026,35.537717411,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,3,1998/10/01 08:13:00,139.8399546047,35.7046682323,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:13:00,139.5920497146,35.8282115802,1,6,3032201,3 ,11,34,,6",
 "13800,2,4,1998/10/01 08:13:00,139.9425856954,35.7883210527,2,14,4116004,5 ,5 ,25,,10",
 "13800,2,5,1998/10/01 08:13:00,139.9425856954,35.7883210527,2,14,4116004,5 ,5 ,25,,1",
 "434700,4,4,1998/10/01 08:13:00,139.7615554506,35.8162412138,2,8,3210107,8 ,1 ,37,,7",
 "434700,5,5,1998/10/01 08:13:00,139.7615554506,35.8162412138,2,8,3210107,8 ,97,37,,97",
 "34500,2,3,1998/10/01 08:13:00,140.0295416237,35.7445972983,1,4,4129003,8 ,1 ,36,,12",
 "41400,2,5,1998/10/01 08:13:00,139.9725181241,35.8608146554,2,5,4212212,8 ,1 ,44,,10",
 "593400,2,3,1998/10/01 08:13:00,139.8191645818,35.6835119159,1,6,0349009,9 ,1 ,58,,10",
 "657800,2,2,1998/10/01 08:13:00,139.7981195378,35.7563048984,2,11,0611113,9 ,1 ,46,,1",
 "62100,2,2,1998/10/01 08:13:00,140.0411334396,35.8708104798,1,7,4224202,9 ,1 ,37,,1",
 "78200,2,3,1998/10/01 08:13:00,139.8809307012,35.6632670512,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:13:00,139.9678054735,35.7036877682,1,9,4031206,8 ,1 ,38,,12",
 "828000,2,3,1998/10/01 08:13:00,139.5050665928,35.5044129357,1,5,1252001,4 ,1 ,38,,10",
 "172500,2,3,1998/10/01 08:13:00,140.0093195363,35.8727468366,2,4,5024120,13,2 ,44,,12",
 "197800,2,3,1998/10/01 08:13:00,140.1864826448,35.9029266306,2,3,5421032,12,2 ,37,,1",
 "209300,2,3,1998/10/01 08:13:00,139.6185547684,35.378268919,1,13,2211303,10,1 ,37,,12",
 "211600,2,3,1998/10/01 08:13:00,139.7165825595,35.2483048936,1,7,2220004,6 ,1 ,36,,10",
 "225400,2,2,1998/10/01 08:13:00,139.5312133849,35.3538327966,1,3,2313201,13,2 ,36,,3",
 "225400,2,3,1998/10/01 08:13:00,139.5312133849,35.3538327966,1,3,2313201,13,2 ,36,,12",
 "227700,2,3,1998/10/01 08:13:00,139.7101726883,35.548538219,1,9,2410203,8 ,10,16,,12",
 "234600,2,4,1998/10/01 08:13:00,139.784995709,35.674264448,1,14,2412116,10,1 ,63,,1",
 "301300,2,4,1998/10/01 08:13:00,139.6925597638,35.5307421092,1,11,2922005,9 ,1 ,29,,1",
 "303600,2,3,1998/10/01 08:13:00,139.6212262942,35.6466889029,1,9,2923016,9 ,1 ,30,,12",
 "85100,2,2,1998/10/01 08:13:00,140.1922219237,35.7232966321,1,8,4417027,9 ,10,46,,6",
 "310500,2,3,1998/10/01 08:13:00,139.5486056308,35.4194338662,1,9,2013102,3 ,1 ,37,,12",
 "124200,2,2,1998/10/01 08:13:00,140.4883165277,35.6988343826,2,1,4813118,12,2 ,26,,9",
 "322000,2,3,1998/10/01 08:13:00,139.6414394371,35.5809082307,2,5,2032102,8 ,1 ,36,,12",
 "181700,2,2,1998/10/01 08:13:00,139.9679328571,36.1564735595,2,7,5123003,8 ,1 ,31,,6",
 "232300,2,2,1998/10/01 08:13:00,139.491076311,35.3429914332,1,4,2411307,3 ,1 ,40,,2",
 "241500,2,2,1998/10/01 08:13:00,139.3920849701,35.3575363292,1,6,2420707,5 ,1 ,37,,6",
 "345000,2,4,1998/10/01 08:13:00,139.7203520737,35.7788236622,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:13:00,139.3277776171,35.4691506973,1,7,2811315,9 ,11,37,,8",
 "386400,2,5,1998/10/01 08:13:00,139.754816805,35.6568205759,1,10,3059002,8 ,1 ,37,,1",
 "404800,2,3,1998/10/01 08:13:00,139.5529136683,35.7288192158,2,9,3120009,8 ,1 ,30,,12",
 "374900,2,2,1998/10/01 08:13:00,139.6602856857,35.839514789,1,4,3040108,4 ,1 ,71,,6",
 "425500,2,3,1998/10/01 08:13:00,139.498761725,35.8892333494,1,4,3134008,8 ,1 ,45,,12",
 "437000,2,4,1998/10/01 08:13:00,139.7702364923,35.666273982,1,7,3212001,5 ,1 ,36,,1",
 "446200,2,3,1998/10/01 08:13:00,139.7945411755,35.8045880838,2,5,3221102,8 ,1 ,37,,12",
 "441600,4,4,1998/10/01 08:13:00,139.834127571,35.7357943711,1,9,3215009,9 ,11,30,,8",
 "473800,2,3,1998/10/01 08:13:00,139.4067935561,35.9504670007,2,10,3411010,8 ,1 ,30,,12",
 "478400,2,2,1998/10/01 08:13:00,139.388811024,36.0083806969,1,4,3413105,13,2 ,40,,1",
 "483000,2,3,1998/10/01 08:13:00,139.7233585866,35.7695067689,1,10,3510101,8 ,1 ,27,,12",
 "563500,2,3,1998/10/01 08:13:00,139.7374400335,35.6845648042,1,7,0212001,10,1 ,84,,12",
 "644000,2,3,1998/10/01 08:13:00,139.7614188444,35.6913445249,1,4,0522008,5 ,1 ,52,,12",
 "811900,2,4,1998/10/01 08:13:00,139.5804031162,35.365030101,1,3,1123201,13,2 ,31,,1",
 "823400,2,3,1998/10/01 08:13:00,139.5935694924,35.4600440426,1,10,1213105,9 ,13,34,,12",
 "646300,2,3,1998/10/01 08:13:00,139.7525713464,35.7222621044,1,4,0523008,8 ,1 ,75,,12",
 "745200,2,2,1998/10/01 08:13:00,139.4187979431,35.7198915036,2,10,0790316,3 ,1 ,30,,2",
 "786600,2,3,1998/10/01 08:13:00,139.3661079088,35.6737032778,2,3,0930110,13,2 ,35,,12",
 "798100,2,2,1998/10/01 08:13:00,139.5638997782,35.5150790567,2,6,1421008,9 ,1 ,37,,6",
 "821100,2,2,1998/10/01 08:13:00,139.574797054,35.4497931963,2,1,1213204,12,2 ,38,,1",
 "660100,2,3,1998/10/01 08:13:00,139.8120711769,35.7581318896,1,7,0612111,9 ,1 ,60,,12",
 "669300,2,2,1998/10/01 08:13:00,139.8726348422,35.7699877617,1,6,0620210,9 ,13,47,,1",
 "759000,2,3,1998/10/01 08:13:00,139.6444063218,35.6665372416,2,4,0821006,9 ,1 ,55,,12",
 "867100,2,2,1998/10/01 08:13:00,139.4852720178,35.4180093388,2,2,1241102,12,2 ,29,,1",
 "876300,2,2,1998/10/01 08:13:00,139.4871988845,35.405593252,1,9,1223102,9 ,1 ,27,,7",
 "696900,2,3,1998/10/01 08:13:00,139.665816748,35.6617385612,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:13:00,139.5018522544,35.700927916,1,7,0752005,6 ,10,34,,12",
 "683100,2,3,1998/10/01 08:13:00,139.7359351211,35.6915687753,2,3,0633106,12,2 ,49,,12",
 "683100,2,4,1998/10/01 08:13:00,139.7359351211,35.6915687753,2,3,0633106,12,2 ,49,,1",
 "692300,2,3,1998/10/01 08:13:00,139.717152554,35.691226119,2,4,0711108,8 ,1 ,25,,12",
 "830300,2,3,1998/10/01 08:14:00,139.6709507663,35.4989705622,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:14:00,140.0933057066,35.7012107232,2,1,4410011,12,2 ,35,,9",
 "89700,5,5,1998/10/01 08:14:00,140.2268767264,35.7752759318,2,8,4422113,3 ,8 ,42,,7",
 "841800,2,4,1998/10/01 08:14:00,139.6617201404,35.4891518026,1,2,1132110,12,2 ,33,,1",
 "860200,2,4,1998/10/01 08:14:00,139.6336800881,35.4483370361,1,9,1340109,10,1 ,33,,1",
 "280600,5,5,1998/10/01 08:14:00,139.7464128303,35.6785464022,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:14:00,139.7071671443,35.5416093232,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,3,1998/10/01 08:14:00,139.8377686131,35.7013846038,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:14:00,139.5930180608,35.8268605831,1,6,3032201,3 ,11,34,,6",
 "13800,2,5,1998/10/01 08:14:00,139.9408490992,35.7977166489,2,14,4116004,5 ,5 ,25,,1",
 "34500,2,3,1998/10/01 08:14:00,140.0282851138,35.7502211041,1,4,4129003,8 ,1 ,36,,12",
 "41400,2,5,1998/10/01 08:14:00,139.9724888655,35.8603076999,2,5,4212212,8 ,1 ,44,,10",
 "593400,2,3,1998/10/01 08:14:00,139.8191644908,35.6835120028,1,6,0349009,9 ,1 ,58,,10",
 "657800,2,2,1998/10/01 08:14:00,139.7981195053,35.7563050818,2,11,0611113,9 ,1 ,46,,1",
 "62100,2,2,1998/10/01 08:14:00,140.0409015057,35.8723852477,1,7,4224202,9 ,1 ,37,,1",
 "62100,2,3,1998/10/01 08:14:00,140.0409015057,35.8723852477,1,7,4224202,9 ,1 ,37,,12",
 "78200,2,3,1998/10/01 08:14:00,139.875847433,35.6634477674,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:14:00,139.9616307351,35.7061084119,1,9,4031206,8 ,1 ,38,,12",
 "828000,2,3,1998/10/01 08:14:00,139.5050666512,35.5044131149,1,5,1252001,4 ,1 ,38,,10",
 "172500,2,3,1998/10/01 08:14:00,140.0032243715,35.8723519076,2,4,5024120,13,2 ,44,,12",
 "197800,2,3,1998/10/01 08:14:00,140.1865572773,35.9039758401,2,3,5421032,12,2 ,37,,1",
 "209300,2,3,1998/10/01 08:14:00,139.6156304569,35.3838816695,1,13,2211303,10,1 ,37,,12",
 "211600,2,3,1998/10/01 08:14:00,139.7150923615,35.2503613542,1,7,2220004,6 ,1 ,36,,10",
 "225400,2,3,1998/10/01 08:14:00,139.5295951044,35.350762473,1,3,2313201,13,2 ,36,,12",
 "227700,2,3,1998/10/01 08:14:00,139.7127315928,35.5546563663,1,9,2410203,8 ,10,16,,12",
 "234600,2,4,1998/10/01 08:14:00,139.785994258,35.6739700728,1,14,2412116,10,1 ,63,,1",
 "301300,2,4,1998/10/01 08:14:00,139.6919925497,35.529742856,1,11,2922005,9 ,1 ,29,,1",
 "303600,2,3,1998/10/01 08:14:00,139.6259575095,35.6481049016,1,9,2923016,9 ,1 ,30,,12",
 "85100,2,2,1998/10/01 08:14:00,140.1881758397,35.7237571737,1,8,4417027,9 ,10,46,,6",
 "310500,2,3,1998/10/01 08:14:00,139.5401925118,35.4149469593,1,9,2013102,3 ,1 ,37,,12",
 "124200,2,2,1998/10/01 08:14:00,140.4875347093,35.7032659718,2,1,4813118,12,2 ,26,,9",
 "322000,2,3,1998/10/01 08:14:00,139.6454647841,35.5787036186,2,5,2032102,8 ,1 ,36,,12",
 "181700,2,2,1998/10/01 08:14:00,139.9735569231,36.154949573,2,7,5123003,8 ,1 ,31,,6",
 "232300,2,2,1998/10/01 08:14:00,139.4927756941,35.3418203624,1,4,2411307,3 ,1 ,40,,2",
 "241500,2,2,1998/10/01 08:14:00,139.3901619652,35.3590659007,1,6,2420707,5 ,1 ,37,,6",
 "345000,2,4,1998/10/01 08:14:00,139.721948309,35.7761558249,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:14:00,139.3293012114,35.4686697881,1,7,2811315,9 ,11,37,,8",
 "386400,2,5,1998/10/01 08:14:00,139.7547156587,35.656116554,1,10,3059002,8 ,1 ,37,,1",
 "404800,2,3,1998/10/01 08:14:00,139.5582585274,35.7291220881,2,9,3120009,8 ,1 ,30,,12",
 "374900,2,2,1998/10/01 08:14:00,139.6634561609,35.837426013,1,4,3040108,4 ,1 ,71,,6",
 "425500,2,3,1998/10/01 08:14:00,139.5029978187,35.8842658524,1,4,3134008,8 ,1 ,45,,12",
 "437000,2,4,1998/10/01 08:14:00,139.7699084055,35.6660070571,1,7,3212001,5 ,1 ,36,,1",
 "446200,2,3,1998/10/01 08:14:00,139.7934887003,35.8018200369,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,3,1998/10/01 08:14:00,139.4104927441,35.9474404414,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 08:14:00,139.8324529641,35.7344480832,1,9,3215009,9 ,11,30,,8",
 "478400,2,2,1998/10/01 08:14:00,139.3893879638,36.0081221236,1,4,3413105,13,2 ,40,,1",
 "483000,2,3,1998/10/01 08:14:00,139.7252056423,35.7662340428,1,10,3510101,8 ,1 ,27,,12",
 "563500,2,3,1998/10/01 08:14:00,139.7386665764,35.6817478075,1,7,0212001,10,1 ,84,,12",
 "646300,2,3,1998/10/01 08:14:00,139.7518122065,35.7168719106,1,4,0523008,8 ,1 ,75,,12",
 "644000,2,3,1998/10/01 08:14:00,139.7638852913,35.6890118703,1,4,0522008,5 ,1 ,52,,12",
 "823400,2,3,1998/10/01 08:14:00,139.5942194095,35.4593309865,1,10,1213105,9 ,13,34,,12",
 "811900,2,4,1998/10/01 08:14:00,139.5805551015,35.3627219002,1,3,1123201,13,2 ,31,,1",
 "745200,2,2,1998/10/01 08:14:00,139.4199195059,35.7179605635,2,10,0790316,3 ,1 ,30,,2",
 "786600,2,3,1998/10/01 08:14:00,139.3638867677,35.6701335826,2,3,0930110,13,2 ,35,,12",
 "798100,2,2,1998/10/01 08:14:00,139.556023703,35.5136317862,2,6,1421008,9 ,1 ,37,,6",
 "821100,2,2,1998/10/01 08:14:00,139.5737480455,35.4502629712,2,1,1213204,12,2 ,38,,1",
 "660100,2,3,1998/10/01 08:14:00,139.8101651065,35.7560347158,1,7,0612111,9 ,1 ,60,,12",
 "669300,2,2,1998/10/01 08:14:00,139.8716593473,35.7698652034,1,6,0620210,9 ,13,47,,1",
 "759000,2,3,1998/10/01 08:14:00,139.6495737714,35.6680849931,2,4,0821006,9 ,1 ,55,,12",
 "867100,2,2,1998/10/01 08:14:00,139.4864705226,35.4179428836,2,2,1241102,12,2 ,29,,1",
 "876300,2,2,1998/10/01 08:14:00,139.4873510945,35.4075325729,1,9,1223102,9 ,1 ,27,,7",
 "719900,2,4,1998/10/01 08:14:00,139.505547833,35.70106737,1,7,0752005,6 ,10,34,,12",
 "683100,2,4,1998/10/01 08:14:00,139.7366053586,35.6912467021,2,3,0633106,12,2 ,49,,1",
 "692300,2,3,1998/10/01 08:14:00,139.721873634,35.6923916124,2,4,0711108,8 ,1 ,25,,12",
 "696900,2,3,1998/10/01 08:14:00,139.670112511,35.6609870743,2,6,0720006,4 ,1 ,39,,12",
 "830300,2,3,1998/10/01 08:15:00,139.6762069295,35.5069592455,2,4,1251203,8 ,1 ,37,,12",
 "89700,5,5,1998/10/01 08:15:00,140.2213828222,35.7768188297,2,8,4422113,3 ,8 ,42,,7",
 "73600,2,3,1998/10/01 08:15:00,140.0936014397,35.7013611774,2,1,4410011,12,2 ,35,,9",
 "841800,2,4,1998/10/01 08:15:00,139.6617354885,35.4885084,1,2,1132110,12,2 ,33,,1",
 "841800,3,5,1998/10/01 08:15:00,139.6617354885,35.4885084,1,2,1132110,12,97,33,,97",
 "860200,2,4,1998/10/01 08:15:00,139.6345754766,35.447960344,1,9,1340109,10,1 ,33,,1",
 "860200,3,5,1998/10/01 08:15:00,139.6345754766,35.447960344,1,9,1340109,10,97,33,,97",
 "280600,5,5,1998/10/01 08:15:00,139.7452652265,35.6811444463,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:15:00,139.7091068852,35.5459896732,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,3,1998/10/01 08:15:00,139.8350089909,35.6984888121,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:15:00,139.5948395224,35.825463136,1,6,3032201,3 ,11,34,,6",
 "13800,2,5,1998/10/01 08:15:00,139.9425187666,35.8071338819,2,14,4116004,5 ,5 ,25,,1",
 "13800,3,6,1998/10/01 08:15:00,139.9425187666,35.8071338819,2,14,4116004,5 ,97,25,,97",
 "34500,2,3,1998/10/01 08:15:00,140.0234920346,35.7539824813,1,4,4129003,8 ,1 ,36,,12",
 "34500,2,4,1998/10/01 08:15:00,140.0234920346,35.7539824813,1,4,4129003,8 ,1 ,36,,1",
 "41400,2,5,1998/10/01 08:15:00,139.9725994948,35.8598610037,2,5,4212212,8 ,1 ,44,,10",
 "593400,2,3,1998/10/01 08:15:00,139.8191643998,35.6835120897,1,6,0349009,9 ,1 ,58,,10",
 "62100,2,3,1998/10/01 08:15:00,140.0335904439,35.873657676,1,7,4224202,9 ,1 ,37,,12",
 "657800,2,2,1998/10/01 08:15:00,139.7981194728,35.7563052653,2,11,0611113,9 ,1 ,46,,1",
 "78200,2,3,1998/10/01 08:15:00,139.8707705204,35.6636965871,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:15:00,139.9557658674,35.7090143836,1,9,4031206,8 ,1 ,38,,12",
 "828000,2,3,1998/10/01 08:15:00,139.5050667096,35.5044132942,1,5,1252001,4 ,1 ,38,,10",
 "172500,2,3,1998/10/01 08:15:00,139.9973353508,35.8737274544,2,4,5024120,13,2 ,44,,12",
 "197800,2,3,1998/10/01 08:15:00,140.1867778519,35.9049974587,2,3,5421032,12,2 ,37,,1",
 "209300,2,3,1998/10/01 08:15:00,139.6133453554,35.3896646704,1,13,2211303,10,1 ,37,,12",
 "211600,2,3,1998/10/01 08:15:00,139.7127494625,35.2517621035,1,7,2220004,6 ,1 ,36,,10",
 "227700,2,3,1998/10/01 08:15:00,139.7152908849,35.5607744538,1,9,2410203,8 ,10,16,,12",
 "225400,2,3,1998/10/01 08:15:00,139.5265980084,35.3485406081,1,3,2313201,13,2 ,36,,12",
 "234600,2,4,1998/10/01 08:15:00,139.7870409952,35.6737639652,1,14,2412116,10,1 ,63,,1",
 "234600,3,5,1998/10/01 08:15:00,139.7870409952,35.6737639652,1,14,2412116,10,97,63,,97",
 "85100,2,2,1998/10/01 08:15:00,140.184236843,35.7232387708,1,8,4417027,9 ,10,46,,6",
 "301300,2,4,1998/10/01 08:15:00,139.692747153,35.528480466,1,11,2922005,9 ,1 ,29,,1",
 "303600,2,3,1998/10/01 08:15:00,139.6307052534,35.6494838288,1,9,2923016,9 ,1 ,30,,12",
 "310500,2,3,1998/10/01 08:15:00,139.5344187711,35.4086361913,1,9,2013102,3 ,1 ,37,,12",
 "124200,2,2,1998/10/01 08:15:00,140.4890232806,35.707234253,2,1,4813118,12,2 ,26,,9",
 "124200,3,3,1998/10/01 08:15:00,140.4890232806,35.707234253,2,1,4813118,12,97,26,,97",
 "322000,2,3,1998/10/01 08:15:00,139.6497912785,35.5769942721,2,5,2032102,8 ,1 ,36,,12",
 "181700,2,2,1998/10/01 08:15:00,139.979161043,36.1533068706,2,7,5123003,8 ,1 ,31,,6",
 "232300,2,2,1998/10/01 08:15:00,139.4943232636,35.3405677306,1,4,2411307,3 ,1 ,40,,2",
 "241500,2,2,1998/10/01 08:15:00,139.3882213285,35.3605804596,1,6,2420707,5 ,1 ,37,,6",
 "345000,2,4,1998/10/01 08:15:00,139.7222470555,35.773199062,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:15:00,139.3309233447,35.4684063633,1,7,2811315,9 ,11,37,,8",
 "386400,2,5,1998/10/01 08:15:00,139.7548653505,35.6555220609,1,10,3059002,8 ,1 ,37,,1",
 "386400,3,6,1998/10/01 08:15:00,139.7548653505,35.6555220609,1,10,3059002,8 ,97,37,,97",
 "404800,2,3,1998/10/01 08:15:00,139.5636004195,35.7288527524,2,9,3120009,8 ,1 ,30,,12",
 "425500,2,3,1998/10/01 08:15:00,139.5072515838,35.8793085304,1,4,3134008,8 ,1 ,45,,12",
 "374900,2,2,1998/10/01 08:15:00,139.6665784895,35.8352916984,1,4,3040108,4 ,1 ,71,,6",
 "437000,2,4,1998/10/01 08:15:00,139.7695793569,35.6657409158,1,7,3212001,5 ,1 ,36,,1",
 "446200,2,3,1998/10/01 08:15:00,139.7924561404,35.799047039,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,3,1998/10/01 08:15:00,139.414193292,35.944415094,2,10,3411010,8 ,1 ,30,,12",
 "478400,2,2,1998/10/01 08:15:00,139.3899648998,36.0078635475,1,4,3413105,13,2 ,40,,1",
 "441600,4,4,1998/10/01 08:15:00,139.830733669,35.7331320115,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 08:15:00,139.727269557,35.7630538767,1,10,3510101,8 ,1 ,27,,12",
 "563500,2,3,1998/10/01 08:15:00,139.7401439059,35.6790311646,1,7,0212001,10,1 ,84,,12",
 "646300,2,3,1998/10/01 08:15:00,139.752921039,35.7113911812,1,4,0523008,8 ,1 ,75,,12",
 "644000,2,3,1998/10/01 08:15:00,139.7633252842,35.6856170673,1,4,0522008,5 ,1 ,52,,12",
 "660100,2,3,1998/10/01 08:15:00,139.8082619547,35.7539358108,1,7,0612111,9 ,1 ,60,,12",
 "759000,2,3,1998/10/01 08:15:00,139.6547620928,35.6695868412,2,4,0821006,9 ,1 ,55,,12",
 "823400,2,3,1998/10/01 08:15:00,139.594869315,35.4586179267,1,10,1213105,9 ,13,34,,12",
 "669300,2,2,1998/10/01 08:15:00,139.8702541365,35.7695346768,1,6,0620210,9 ,13,47,,1",
 "669300,2,3,1998/10/01 08:15:00,139.8702541365,35.7695346768,1,6,0620210,9 ,13,47,,12",
 "745200,2,2,1998/10/01 08:15:00,139.4209552155,35.7160011028,2,10,0790316,3 ,1 ,30,,2",
 "811900,2,4,1998/10/01 08:15:00,139.5807070778,35.3604136984,1,3,1123201,13,2 ,31,,1",
 "786600,2,3,1998/10/01 08:15:00,139.3616561055,35.6665680337,2,3,0930110,13,2 ,35,,12",
 "798100,2,2,1998/10/01 08:15:00,139.5480022329,35.5128454013,2,6,1421008,9 ,1 ,37,,6",
 "683100,2,4,1998/10/01 08:15:00,139.7373896719,35.691474729,2,3,0633106,12,2 ,49,,1",
 "692300,2,3,1998/10/01 08:15:00,139.7266917869,35.6917656041,2,4,0711108,8 ,1 ,25,,12",
 "821100,2,2,1998/10/01 08:15:00,139.573273082,35.4498798565,2,1,1213204,12,2 ,38,,1",
 "821100,3,3,1998/10/01 08:15:00,139.573273082,35.4498798565,2,1,1213204,12,97,38,,97",
 "867100,2,2,1998/10/01 08:15:00,139.4871403964,35.4172129122,2,2,1241102,12,2 ,29,,1",
 "876300,2,2,1998/10/01 08:15:00,139.4891245476,35.4089042161,1,9,1223102,9 ,1 ,27,,7",
 "719900,2,4,1998/10/01 08:15:00,139.5092416345,35.7012273301,1,7,0752005,6 ,10,34,,12",
 "696900,2,3,1998/10/01 08:15:00,139.6743976266,35.660195394,2,6,0720006,4 ,1 ,39,,12",
 "20700,2,2,1998/10/01 08:16:00,140.0200403042,35.6761540308,1,8,4121303,9 ,1 ,44,,6",
 "830300,2,3,1998/10/01 08:16:00,139.6787190013,35.5159810988,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:16:00,140.0938449522,35.7015637423,2,1,4410011,12,2 ,35,,9",
 "89700,5,5,1998/10/01 08:16:00,140.2161142786,35.778801901,2,8,4422113,3 ,8 ,42,,7",
 "280600,5,5,1998/10/01 08:16:00,139.7443213228,35.6838288781,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:16:00,139.7109616213,35.5504246032,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,3,1998/10/01 08:16:00,139.8305791274,35.6976058837,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:16:00,139.5965857194,35.8239114135,1,6,3032201,3 ,11,34,,6",
 "356500,2,2,1998/10/01 08:16:00,139.7301222309,35.8374142824,2,6,3018202,8 ,4 ,22,,4",
 "16100,2,2,1998/10/01 08:16:00,139.9135589829,35.6444869093,2,4,4116209,8 ,1 ,48,,1",
 "34500,2,4,1998/10/01 08:16:00,140.0233408856,35.7528078554,1,4,4129003,8 ,1 ,36,,1",
 "41400,2,5,1998/10/01 08:16:00,139.9730909895,35.8595514766,2,5,4212212,8 ,1 ,44,,10",
 "593400,2,3,1998/10/01 08:16:00,139.8191643088,35.6835121767,1,6,0349009,9 ,1 ,58,,10",
 "657800,2,2,1998/10/01 08:16:00,139.7981194403,35.7563054487,2,11,0611113,9 ,1 ,46,,1",
 "62100,2,3,1998/10/01 08:16:00,140.0261065366,35.8737364253,1,7,4224202,9 ,1 ,37,,12",
 "78200,2,3,1998/10/01 08:16:00,139.8657008169,35.6640616168,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:16:00,139.9496536195,35.7115643854,1,9,4031206,8 ,1 ,38,,12",
 "828000,2,3,1998/10/01 08:16:00,139.505066768,35.5044134734,1,5,1252001,4 ,1 ,38,,10",
 "172500,2,3,1998/10/01 08:16:00,139.9914165238,35.8750337236,2,4,5024120,13,2 ,44,,12",
 "197800,2,3,1998/10/01 08:16:00,140.1872803677,35.9059656432,2,3,5421032,12,2 ,37,,1",
 "209300,2,3,1998/10/01 08:16:00,139.6096819035,35.3948613193,1,13,2211303,10,1 ,37,,12",
 "211600,2,3,1998/10/01 08:16:00,139.7104336413,35.2531825284,1,7,2220004,6 ,1 ,36,,10",
 "227700,2,3,1998/10/01 08:16:00,139.7178378463,35.5668955624,1,9,2410203,8 ,10,16,,12",
 "225400,2,3,1998/10/01 08:16:00,139.523179466,35.3466903979,1,3,2313201,13,2 ,36,,12",
 "301300,2,4,1998/10/01 08:16:00,139.6940580104,35.5274973105,1,11,2922005,9 ,1 ,29,,1",
 "303600,2,3,1998/10/01 08:16:00,139.6354572122,35.6508533488,1,9,2923016,9 ,1 ,30,,12",
 "85100,2,2,1998/10/01 08:16:00,140.1808404833,35.7237870051,1,8,4417027,9 ,10,46,,6",
 "310500,2,3,1998/10/01 08:16:00,139.5342959158,35.4004924285,1,9,2013102,3 ,1 ,37,,12",
 "310500,2,4,1998/10/01 08:16:00,139.5342959158,35.4004924285,1,9,2013102,3 ,1 ,37,,1",
 "103500,2,2,1998/10/01 08:16:00,139.9359080357,35.3823598481,2,3,4610015,13,4 ,38,,2",
 "103500,3,3,1998/10/01 08:16:00,139.9359080357,35.3823598481,2,3,4610015,13,97,38,,97",
 "322000,2,3,1998/10/01 08:16:00,139.6546005295,35.5766011082,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,2,1998/10/01 08:16:00,139.6188034954,35.5943254429,1,10,2110103,8 ,1 ,29,,1",
 "177100,2,2,1998/10/01 08:16:00,139.8352896642,36.0953297647,2,3,5113112,13,2 ,35,,6",
 "181700,2,2,1998/10/01 08:16:00,139.9848954556,36.1525083131,2,7,5123003,8 ,1 ,31,,6",
 "200100,2,2,1998/10/01 08:16:00,140.3151052663,36.0034933003,2,8,5510012,8 ,1 ,39,,6",
 "232300,2,2,1998/10/01 08:16:00,139.49540066,35.3390743135,1,4,2411307,3 ,1 ,40,,2",
 "345000,2,4,1998/10/01 08:16:00,139.7229152381,35.7703202996,1,8,3012002,9 ,1 ,37,,12",
 "241500,2,2,1998/10/01 08:16:00,139.386208757,35.3619463005,1,6,2420707,5 ,1 ,37,,6",
 "271400,2,2,1998/10/01 08:16:00,139.3324988717,35.4679966878,1,7,2811315,9 ,11,37,,8",
 "404800,2,3,1998/10/01 08:16:00,139.5689238219,35.7284049631,2,9,3120009,8 ,1 ,30,,12",
 "374900,2,2,1998/10/01 08:16:00,139.6697391893,35.833193396,1,4,3040108,4 ,1 ,71,,6",
 "425500,2,3,1998/10/01 08:16:00,139.5115161025,35.874357456,1,4,3134008,8 ,1 ,45,,12",
 "437000,2,4,1998/10/01 08:16:00,139.7692209509,35.6655056169,1,7,3212001,5 ,1 ,36,,1",
 "446200,2,3,1998/10/01 08:16:00,139.7914291867,35.7962726529,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,3,1998/10/01 08:16:00,139.4179007483,35.9413954537,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 08:16:00,139.8289898934,35.7318314453,1,9,3215009,9 ,11,30,,8",
 "478400,2,2,1998/10/01 08:16:00,139.3905418321,36.0076049686,1,4,3413105,13,2 ,40,,1",
 "483000,2,3,1998/10/01 08:16:00,139.7304012611,35.7605331881,1,10,3510101,8 ,1 ,27,,12",
 "563500,2,3,1998/10/01 08:16:00,139.7431823451,35.6775931799,1,7,0212001,10,1 ,84,,12",
 "572700,2,2,1998/10/01 08:16:00,139.7115507681,35.6705142351,2,2,0241003,12,2 ,27,,1",
 "611800,2,2,1998/10/01 08:16:00,139.6687120777,35.6301064423,1,4,0431110,13,2 ,65,,1",
 "644000,2,3,1998/10/01 08:16:00,139.7622081967,35.6820181971,1,4,0522008,5 ,1 ,52,,12",
 "646300,2,3,1998/10/01 08:16:00,139.7544584015,35.70597985,1,4,0523008,8 ,1 ,75,,12",
 "811900,2,4,1998/10/01 08:16:00,139.5808590451,35.3581054955,1,3,1123201,13,2 ,31,,1",
 "823400,2,3,1998/10/01 08:16:00,139.5955192245,35.4579048726,1,10,1213105,9 ,13,34,,12",
 "745200,2,2,1998/10/01 08:16:00,139.4217966972,35.7139843002,2,10,0790316,3 ,1 ,30,,2",
 "786600,2,3,1998/10/01 08:16:00,139.3594035145,35.6630115696,2,3,0930110,13,2 ,35,,12",
 "798100,2,2,1998/10/01 08:16:00,139.5406616248,35.5135792074,2,6,1421008,9 ,1 ,37,,6",
 "660100,2,3,1998/10/01 08:16:00,139.8065635822,35.7517278175,1,7,0612111,9 ,1 ,60,,12",
 "669300,2,3,1998/10/01 08:16:00,139.8812565674,35.7690687094,1,6,0620210,9 ,13,47,,12",
 "759000,2,3,1998/10/01 08:16:00,139.6598681575,35.6712652573,2,4,0821006,9 ,1 ,55,,12",
 "867100,2,2,1998/10/01 08:16:00,139.4878987423,35.4168829885,2,2,1241102,12,2 ,29,,1",
 "876300,2,2,1998/10/01 08:16:00,139.4902817918,35.4105437946,1,9,1223102,9 ,1 ,27,,7",
 "719900,2,4,1998/10/01 08:16:00,139.5129391581,35.7013270918,1,7,0752005,6 ,10,34,,12",
 "683100,2,4,1998/10/01 08:16:00,139.7381740484,35.6917026151,2,3,0633106,12,2 ,49,,1",
 "692300,2,3,1998/10/01 08:16:00,139.7314784727,35.6911697499,2,4,0711108,8 ,1 ,25,,12",
 "696900,2,3,1998/10/01 08:16:00,139.6787224686,35.6595906935,2,6,0720006,4 ,1 ,39,,12",
 "20700,2,2,1998/10/01 08:17:00,140.0200401559,35.676153866,1,8,4121303,9 ,1 ,44,,6",
 "830300,2,3,1998/10/01 08:17:00,139.6801229913,35.524549311,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:17:00,140.0940509011,35.7017959213,2,1,4410011,12,2 ,35,,9",
 "89700,5,5,1998/10/01 08:17:00,140.2104463227,35.7794046278,2,8,4422113,3 ,8 ,42,,7",
 "280600,5,5,1998/10/01 08:17:00,139.7449668552,35.6866034427,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:17:00,139.712816561,35.5548595019,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,3,1998/10/01 08:17:00,139.8260356379,35.6971275712,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:17:00,139.5984175175,35.8224303564,1,6,3032201,3 ,11,34,,6",
 "356500,2,2,1998/10/01 08:17:00,139.7287455728,35.8399842134,2,6,3018202,8 ,4 ,22,,4",
 "16100,2,2,1998/10/01 08:17:00,139.9130627879,35.6454115254,2,4,4116209,8 ,1 ,48,,1",
 "34500,2,4,1998/10/01 08:17:00,140.0231274125,35.7516385475,1,4,4129003,8 ,1 ,36,,1",
 "41400,2,5,1998/10/01 08:17:00,139.9735388642,35.8591984253,2,5,4212212,8 ,1 ,44,,10",
 "593400,2,3,1998/10/01 08:17:00,139.8191642178,35.6835122636,1,6,0349009,9 ,1 ,58,,10",
 "657800,2,2,1998/10/01 08:17:00,139.7981194078,35.7563056321,2,11,0611113,9 ,1 ,46,,1",
 "62100,2,3,1998/10/01 08:17:00,140.0186316049,35.8732926332,1,7,4224202,9 ,1 ,37,,12",
 "78200,2,3,1998/10/01 08:17:00,139.8606237402,35.664344829,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:17:00,139.9435172772,35.7140776318,1,9,4031206,8 ,1 ,38,,12",
 "828000,2,3,1998/10/01 08:17:00,139.5050668263,35.5044136527,1,5,1252001,4 ,1 ,38,,10",
 "172500,2,3,1998/10/01 08:17:00,139.9854302394,35.875437077,2,4,5024120,13,2 ,44,,12",
 "197800,2,3,1998/10/01 08:17:00,140.1875464963,35.9069885279,2,3,5421032,12,2 ,37,,1",
 "209300,2,3,1998/10/01 08:17:00,139.6031546548,35.3975181834,1,13,2211303,10,1 ,37,,12",
 "211600,2,3,1998/10/01 08:17:00,139.709230317,35.2553943828,1,7,2220004,6 ,1 ,36,,10",
 "225400,2,3,1998/10/01 08:17:00,139.5197669912,35.3448330607,1,3,2313201,13,2 ,36,,12",
 "227700,2,3,1998/10/01 08:17:00,139.7204371659,35.5730024408,1,9,2410203,8 ,10,16,,12",
 "301300,2,4,1998/10/01 08:17:00,139.6952542356,35.5264376371,1,11,2922005,9 ,1 ,29,,1",
 "85100,2,2,1998/10/01 08:17:00,140.1775310261,35.7205337382,1,8,4417027,9 ,10,46,,6",
 "303600,2,3,1998/10/01 08:17:00,139.6402356125,35.6521558394,1,9,2923016,9 ,1 ,30,,12",
 "310500,2,4,1998/10/01 08:17:00,139.5315004345,35.3980205658,1,9,2013102,3 ,1 ,37,,1",
 "322000,2,3,1998/10/01 08:17:00,139.6594463069,35.5765587004,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,2,1998/10/01 08:17:00,139.6173290957,35.5951874881,1,10,2110103,8 ,1 ,29,,1",
 "177100,2,2,1998/10/01 08:17:00,139.8363170855,36.0929246261,2,3,5113112,13,2 ,35,,6",
 "181700,2,2,1998/10/01 08:17:00,139.9906481296,36.1513038248,2,7,5123003,8 ,1 ,31,,6",
 "200100,2,2,1998/10/01 08:17:00,140.3136745838,36.0042722246,2,8,5510012,8 ,1 ,39,,6",
 "232300,2,2,1998/10/01 08:17:00,139.4967335899,35.3380050859,1,4,2411307,3 ,1 ,40,,2",
 "345000,2,4,1998/10/01 08:17:00,139.7244267785,35.7676143752,1,8,3012002,9 ,1 ,37,,12",
 "241500,2,2,1998/10/01 08:17:00,139.3835300708,35.362021781,1,6,2420707,5 ,1 ,37,,6",
 "271400,2,2,1998/10/01 08:17:00,139.3340920323,35.4676641925,1,7,2811315,9 ,11,37,,8",
 "404800,2,3,1998/10/01 08:17:00,139.5742694038,35.728110982,2,9,3120009,8 ,1 ,30,,12",
 "374900,2,2,1998/10/01 08:17:00,139.6728362893,35.8310357461,1,4,3040108,4 ,1 ,71,,6",
 "425500,2,3,1998/10/01 08:17:00,139.5157518496,35.8693903372,1,4,3134008,8 ,1 ,45,,12",
 "437000,2,4,1998/10/01 08:17:00,139.7688235612,35.6653112731,1,7,3212001,5 ,1 ,36,,1",
 "446200,2,3,1998/10/01 08:17:00,139.7904022808,35.7934982626,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,3,1998/10/01 08:17:00,139.4216150253,35.9383817357,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 08:17:00,139.8272461746,35.7305308535,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 08:17:00,139.7335569329,35.758033802,1,10,3510101,8 ,1 ,27,,12",
 "478400,2,2,1998/10/01 08:17:00,139.3909817107,36.0073284119,1,4,3413105,13,2 ,40,,1",
 "572700,2,2,1998/10/01 08:17:00,139.7119248794,35.6703125334,2,2,0241003,12,2 ,27,,1",
 "563500,2,3,1998/10/01 08:17:00,139.7467729835,35.677861891,1,7,0212001,10,1 ,84,,12",
 "611800,2,2,1998/10/01 08:17:00,139.666507297,35.6307350516,1,4,0431110,13,2 ,65,,1",
 "646300,2,3,1998/10/01 08:17:00,139.7557191738,35.7005810017,1,4,0523008,8 ,1 ,75,,12",
 "644000,2,3,1998/10/01 08:17:00,139.7609348552,35.6784539,1,4,0522008,5 ,1 ,52,,12",
 "823400,2,3,1998/10/01 08:17:00,139.5961759399,35.457196193,1,10,1213105,9 ,13,34,,12",
 "811900,2,4,1998/10/01 08:17:00,139.5810110033,35.3557972914,1,3,1123201,13,2 ,31,,1",
 "745200,2,2,1998/10/01 08:17:00,139.4218991884,35.7118698662,2,10,0790316,3 ,1 ,30,,2",
 "798100,2,2,1998/10/01 08:17:00,139.5341886367,35.5167579113,2,6,1421008,9 ,1 ,37,,6",
 "786600,2,3,1998/10/01 08:17:00,139.3566942757,35.6597120815,2,3,0930110,13,2 ,35,,12",
 "660100,2,3,1998/10/01 08:17:00,139.8050249717,35.7494459985,1,7,0612111,9 ,1 ,60,,12",
 "669300,2,3,1998/10/01 08:17:00,139.8920705653,35.770306636,1,6,0620210,9 ,13,47,,12",
 "759000,2,3,1998/10/01 08:17:00,139.6649552997,35.6729831628,2,4,0821006,9 ,1 ,55,,12",
 "876300,2,2,1998/10/01 08:17:00,139.4917535111,35.411826029,1,9,1223102,9 ,1 ,27,,7",
 "867100,2,2,1998/10/01 08:17:00,139.4892927661,35.4166991651,2,2,1241102,12,2 ,29,,1",
 "696900,2,3,1998/10/01 08:17:00,139.6830336491,35.6589050866,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:17:00,139.5166366918,35.70142674,1,7,0752005,6 ,10,34,,12",
 "683100,2,4,1998/10/01 08:17:00,139.738959872,35.6919271654,2,3,0633106,12,2 ,49,,1",
 "692300,2,3,1998/10/01 08:17:00,139.7362343949,35.6911579844,2,4,0711108,8 ,1 ,25,,12",
 "20700,2,2,1998/10/01 08:18:00,140.0200400077,35.6761537012,1,8,4121303,9 ,1 ,44,,6",
 "830300,2,3,1998/10/01 08:18:00,139.6774641583,35.5335903469,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:18:00,140.0942568512,35.7020281,2,1,4410011,12,2 ,35,,9",
 "89700,5,5,1998/10/01 08:18:00,140.2047032086,35.7796437714,2,8,4422113,3 ,8 ,42,,7",
 "280600,5,5,1998/10/01 08:18:00,139.7454995849,35.6893617678,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:18:00,139.7146717044,35.5592943692,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,3,1998/10/01 08:18:00,139.8214538387,35.6969920306,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:18:00,139.6004306386,35.8211112077,1,6,3032201,3 ,11,34,,6",
 "356500,2,2,1998/10/01 08:18:00,139.7306996596,35.8418964011,2,6,3018202,8 ,4 ,22,,4",
 "16100,2,2,1998/10/01 08:18:00,139.9130205992,35.6464740065,2,4,4116209,8 ,1 ,48,,1",
 "34500,2,4,1998/10/01 08:18:00,140.0243771695,35.7512504912,1,4,4129003,8 ,1 ,36,,1",
 "41400,2,5,1998/10/01 08:18:00,139.973986735,35.8588453723,2,5,4212212,8 ,1 ,44,,10",
 "593400,2,3,1998/10/01 08:18:00,139.8191641268,35.6835123505,1,6,0349009,9 ,1 ,58,,10",
 "657800,2,2,1998/10/01 08:18:00,139.7981193753,35.7563058155,2,11,0611113,9 ,1 ,46,,1",
 "62100,2,3,1998/10/01 08:18:00,140.011154321,35.8728841861,1,7,4224202,9 ,1 ,37,,12",
 "78200,2,3,1998/10/01 08:18:00,139.8556213566,35.6648945391,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:18:00,139.9374167319,35.7166484625,1,9,4031206,8 ,1 ,38,,12",
 "828000,2,3,1998/10/01 08:18:00,139.5050668847,35.504413832,1,5,1252001,4 ,1 ,38,,10",
 "172500,2,3,1998/10/01 08:18:00,139.9804197069,35.8726289347,2,4,5024120,13,2 ,44,,12",
 "197800,2,3,1998/10/01 08:18:00,140.1885285037,35.9070462032,2,3,5421032,12,2 ,37,,1",
 "209300,2,3,1998/10/01 08:18:00,139.5985722685,35.4022833412,1,13,2211303,10,1 ,37,,12",
 "211600,2,3,1998/10/01 08:18:00,139.7080453276,35.2576141204,1,7,2220004,6 ,1 ,36,,10",
 "225400,2,3,1998/10/01 08:18:00,139.5163451151,35.3429878169,1,3,2313201,13,2 ,36,,12",
 "227700,2,3,1998/10/01 08:18:00,139.7229928171,35.5791216894,1,9,2410203,8 ,10,16,,12",
 "303600,2,3,1998/10/01 08:18:00,139.6450992328,35.6532334662,1,9,2923016,9 ,1 ,30,,12",
 "301300,2,4,1998/10/01 08:18:00,139.6962056887,35.5252089498,1,11,2922005,9 ,1 ,29,,1",
 "85100,2,2,1998/10/01 08:18:00,140.1726903536,35.7198637938,1,8,4417027,9 ,10,46,,6",
 "310500,2,4,1998/10/01 08:18:00,139.5292792638,35.3950143536,1,9,2013102,3 ,1 ,37,,1",
 "322000,2,3,1998/10/01 08:18:00,139.6611648426,35.5789078656,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,2,1998/10/01 08:18:00,139.6158546644,35.5960495149,1,10,2110103,8 ,1 ,29,,1",
 "177100,2,2,1998/10/01 08:18:00,139.8388327641,36.0876034817,2,3,5113112,13,2 ,35,,6",
 "181700,2,2,1998/10/01 08:18:00,139.996039824,36.1492466535,2,7,5123003,8 ,1 ,31,,6",
 "200100,2,2,1998/10/01 08:18:00,140.3119837121,36.0043347933,2,8,5510012,8 ,1 ,39,,6",
 "232300,2,2,1998/10/01 08:18:00,139.4987284906,35.3372193666,1,4,2411307,3 ,1 ,40,,2",
 "345000,2,4,1998/10/01 08:18:00,139.7259679822,35.7649195653,1,8,3012002,9 ,1 ,37,,12",
 "241500,2,2,1998/10/01 08:18:00,139.3808820996,35.3622923925,1,6,2420707,5 ,1 ,37,,6",
 "271400,2,2,1998/10/01 08:18:00,139.3357457607,35.4676735192,1,7,2811315,9 ,11,37,,8",
 "404800,2,3,1998/10/01 08:18:00,139.5795120452,35.7273408914,2,9,3120009,8 ,1 ,30,,12",
 "374900,2,2,1998/10/01 08:18:00,139.6752475445,35.8283711707,1,4,3040108,4 ,1 ,71,,6",
 "425500,2,3,1998/10/01 08:18:00,139.5199359631,35.8643943064,1,4,3134008,8 ,1 ,45,,12",
 "437000,2,4,1998/10/01 08:18:00,139.7684261735,35.6651169279,1,7,3212001,5 ,1 ,36,,1",
 "446200,2,3,1998/10/01 08:18:00,139.7893753892,35.7907238763,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,3,1998/10/01 08:18:00,139.425661755,35.9356684813,2,10,3411010,8 ,1 ,30,,12",
 "478400,2,2,1998/10/01 08:18:00,139.3916149739,36.0074696977,1,4,3413105,13,2 ,40,,1",
 "441600,4,4,1998/10/01 08:18:00,139.8256495918,35.7291115728,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 08:18:00,139.7357748275,35.7549187932,1,10,3510101,8 ,1 ,27,,12",
 "563500,2,3,1998/10/01 08:18:00,139.7504259608,35.6776310157,1,7,0212001,10,1 ,84,,12",
 "572700,2,2,1998/10/01 08:18:00,139.7118585878,35.6700544034,2,2,0241003,12,2 ,27,,1",
 "611800,2,2,1998/10/01 08:18:00,139.6650407194,35.6318085425,1,4,0431110,13,2 ,65,,1",
 "644000,2,3,1998/10/01 08:18:00,139.7595778519,35.6753060379,1,4,0522008,5 ,1 ,52,,12",
 "646300,2,3,1998/10/01 08:18:00,139.7583359306,35.695472665,1,4,0523008,8 ,1 ,75,,12",
 "823400,2,3,1998/10/01 08:18:00,139.5968686683,35.4565106641,1,10,1213105,9 ,13,34,,12",
 "811900,2,4,1998/10/01 08:18:00,139.5811629524,35.3534890863,1,3,1123201,13,2 ,31,,1",
 "745200,2,2,1998/10/01 08:18:00,139.4202527684,35.7102094614,2,10,0790316,3 ,1 ,30,,2",
 "798100,2,2,1998/10/01 08:18:00,139.5275023572,35.5204791365,2,6,1421008,9 ,1 ,37,,6",
 "786600,2,3,1998/10/01 08:18:00,139.3528071577,35.6572703251,2,3,0930110,13,2 ,35,,12",
 "660100,2,3,1998/10/01 08:18:00,139.8041213232,35.7469429393,1,7,0612111,9 ,1 ,60,,12",
 "669300,2,3,1998/10/01 08:18:00,139.8981944196,35.7774038564,1,6,0620210,9 ,13,47,,12",
 "759000,2,3,1998/10/01 08:18:00,139.670069347,35.6746452199,2,4,0821006,9 ,1 ,55,,12",
 "867100,2,2,1998/10/01 08:18:00,139.4906515471,35.4163773199,2,2,1241102,12,2 ,29,,1",
 "876300,2,2,1998/10/01 08:18:00,139.4918916541,35.4137086166,1,9,1223102,9 ,1 ,27,,7",
 "719900,2,4,1998/10/01 08:18:00,139.5203344978,35.7015161256,1,7,0752005,6 ,10,34,,12",
 "683100,2,4,1998/10/01 08:18:00,139.7394510944,35.6916380556,2,3,0633106,12,2 ,49,,1",
 "692300,2,3,1998/10/01 08:18:00,139.7408852603,35.692526618,2,4,0711108,8 ,1 ,25,,12",
 "696900,2,3,1998/10/01 08:18:00,139.6871282019,35.6576636925,2,6,0720006,4 ,1 ,39,,12",
 "20700,2,2,1998/10/01 08:19:00,140.0200398595,35.6761535365,1,8,4121303,9 ,1 ,44,,6",
 "830300,2,3,1998/10/01 08:19:00,139.6747622967,35.5426000808,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:19:00,140.0944628024,35.7022602783,2,1,4410011,12,2 ,35,,9",
 "89700,5,5,1998/10/01 08:19:00,140.202071022,35.7835304833,2,8,4422113,3 ,8 ,42,,7",
 "280600,5,5,1998/10/01 08:19:00,139.7461308414,35.6921380053,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:19:00,139.7164882041,35.5637396653,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,3,1998/10/01 08:19:00,139.8168720551,35.6968563156,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:19:00,139.6012561214,35.8198923619,1,6,3032201,3 ,11,34,,6",
 "356500,2,2,1998/10/01 08:19:00,139.7316283486,35.8444620911,2,6,3018202,8 ,4 ,22,,4",
 "16100,2,2,1998/10/01 08:19:00,139.9129784094,35.6475364874,2,4,4116209,8 ,1 ,48,,1",
 "34500,2,4,1998/10/01 08:19:00,140.0254983577,35.7507964628,1,4,4129003,8 ,1 ,36,,1",
 "41400,2,5,1998/10/01 08:19:00,139.9744346018,35.8584923176,2,5,4212212,8 ,1 ,44,,10",
 "593400,2,3,1998/10/01 08:19:00,139.8191640358,35.6835124374,1,6,0349009,9 ,1 ,58,,10",
 "657800,2,2,1998/10/01 08:19:00,139.7981193428,35.756305999,2,11,0611113,9 ,1 ,46,,1",
 "657800,2,3,1998/10/01 08:19:00,139.7981193428,35.756305999,2,11,0611113,9 ,1 ,46,,10",
 "62100,2,3,1998/10/01 08:19:00,140.003701232,35.8723215805,1,7,4224202,9 ,1 ,37,,12",
 "78200,2,3,1998/10/01 08:19:00,139.8505567153,35.6653041571,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:19:00,139.9313184243,35.7192231646,1,9,4031206,8 ,1 ,38,,12",
 "828000,2,3,1998/10/01 08:19:00,139.5050669431,35.5044140113,1,5,1252001,4 ,1 ,38,,10",
 "172500,2,3,1998/10/01 08:19:00,139.9763034796,35.8689670431,2,4,5024120,13,2 ,44,,12",
 "197800,2,3,1998/10/01 08:19:00,140.189231687,35.9077363953,2,3,5421032,12,2 ,37,,1",
 "209300,2,3,1998/10/01 08:19:00,139.596659239,35.4081063318,1,13,2211303,10,1 ,37,,12",
 "211600,2,3,1998/10/01 08:19:00,139.7084198836,35.2571290576,1,7,2220004,6 ,1 ,36,,10",
 "225400,2,3,1998/10/01 08:19:00,139.5129225952,35.341142843,1,3,2313201,13,2 ,36,,12",
 "227700,2,3,1998/10/01 08:19:00,139.7257306131,35.5851769952,1,9,2410203,8 ,10,16,,12",
 "301300,2,4,1998/10/01 08:19:00,139.697160289,35.5239815184,1,11,2922005,9 ,1 ,29,,1",
 "303600,2,3,1998/10/01 08:19:00,139.649702821,35.6548504423,1,9,2923016,9 ,1 ,30,,12",
 "85100,2,2,1998/10/01 08:19:00,140.1678347015,35.718376204,1,8,4417027,9 ,10,46,,6",
 "310500,2,4,1998/10/01 08:19:00,139.5274356794,35.3913937631,1,9,2013102,3 ,1 ,37,,1",
 "322000,2,3,1998/10/01 08:19:00,139.6629660553,35.5825833171,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,2,1998/10/01 08:19:00,139.6143802016,35.5969115235,1,10,2110103,8 ,1 ,29,,1",
 "177100,2,2,1998/10/01 08:19:00,139.8395466892,36.0859377897,2,3,5113112,13,2 ,35,,6",
 "181700,2,2,1998/10/01 08:19:00,140.0017927088,36.1484213106,2,7,5123003,8 ,1 ,31,,6",
 "200100,2,2,1998/10/01 08:19:00,140.310385907,36.0037957349,2,8,5510012,8 ,1 ,39,,6",
 "232300,2,2,1998/10/01 08:19:00,139.5007556134,35.3365203445,1,4,2411307,3 ,1 ,40,,2",
 "241500,2,2,1998/10/01 08:19:00,139.3787225409,35.3625645737,1,6,2420707,5 ,1 ,37,,6",
 "345000,2,4,1998/10/01 08:19:00,139.7278693495,35.7623904429,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:19:00,139.3370057039,35.4668575918,1,7,2811315,9 ,11,37,,8",
 "404800,2,3,1998/10/01 08:19:00,139.5848414506,35.7268928726,2,9,3120009,8 ,1 ,30,,12",
 "374900,2,2,1998/10/01 08:19:00,139.6774433928,35.8255733485,1,4,3040108,4 ,1 ,71,,6",
 "425500,2,3,1998/10/01 08:19:00,139.5242432513,35.8594685194,1,4,3134008,8 ,1 ,45,,12",
 "437000,2,4,1998/10/01 08:19:00,139.7680287876,35.6649225814,1,7,3212001,5 ,1 ,36,,1",
 "446200,2,3,1998/10/01 08:19:00,139.7883505963,35.7879489834,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,3,1998/10/01 08:19:00,139.4298940585,35.9331465436,2,10,3411010,8 ,1 ,30,,12",
 "478400,2,2,1998/10/01 08:19:00,139.3922720579,36.007444158,1,4,3413105,13,2 ,40,,1",
 "441600,4,4,1998/10/01 08:19:00,139.8240777538,35.7276732175,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 08:19:00,139.7382833959,35.7519595025,1,10,3510101,8 ,1 ,27,,12",
 "563500,2,3,1998/10/01 08:19:00,139.7540280315,35.6778605436,1,7,0212001,10,1 ,84,,12",
 "572700,2,2,1998/10/01 08:19:00,139.7115800123,35.6697677009,2,2,0241003,12,2 ,27,,1",
 "611800,2,2,1998/10/01 08:19:00,139.662735561,35.6319724284,1,4,0431110,13,2 ,65,,1",
 "646300,2,3,1998/10/01 08:19:00,139.7616554967,35.6911473359,1,4,0523008,8 ,1 ,75,,12",
 "644000,2,3,1998/10/01 08:19:00,139.7569484899,35.6722939134,1,4,0522008,5 ,1 ,52,,12",
 "823400,2,3,1998/10/01 08:19:00,139.5976335353,35.4558855705,1,10,1213105,9 ,13,34,,12",
 "811900,2,4,1998/10/01 08:19:00,139.5813148925,35.35118088,1,3,1123201,13,2 ,31,,1",
 "660100,2,3,1998/10/01 08:19:00,139.8031900965,35.7444462592,1,7,0612111,9 ,1 ,60,,12",
 "745200,2,2,1998/10/01 08:19:00,139.4182160673,35.7088879943,2,10,0790316,3 ,1 ,30,,2",
 "798100,2,2,1998/10/01 08:19:00,139.52053484,35.5236944706,2,6,1421008,9 ,1 ,37,,6",
 "786600,2,3,1998/10/01 08:19:00,139.3488924787,35.6548582574,2,3,0930110,13,2 ,35,,12",
 "669300,2,3,1998/10/01 08:19:00,139.9012328512,35.7858758285,1,6,0620210,9 ,13,47,,12",
 "759000,2,3,1998/10/01 08:19:00,139.675154688,35.6763659359,2,4,0821006,9 ,1 ,55,,12",
 "867100,2,2,1998/10/01 08:19:00,139.4920092753,35.4160500823,2,2,1241102,12,2 ,29,,1",
 "876300,2,2,1998/10/01 08:19:00,139.4925929804,35.4156108666,1,9,1223102,9 ,1 ,27,,7",
 "683100,2,4,1998/10/01 08:19:00,139.7402455919,35.691841279,2,3,0633106,12,2 ,49,,1",
 "692300,2,3,1998/10/01 08:19:00,139.7454819307,35.6940082097,2,4,0711108,8 ,1 ,25,,12",
 "696900,2,3,1998/10/01 08:19:00,139.6913880164,35.6571011034,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:19:00,139.5240270797,35.7015780419,1,7,0752005,6 ,10,34,,12",
 "20700,2,2,1998/10/01 08:20:00,140.0200397112,35.6761533717,1,8,4121303,9 ,1 ,44,,6",
 "830300,2,3,1998/10/01 08:20:00,139.6715948669,35.5515209371,2,4,1251203,8 ,1 ,37,,12",
 "89700,5,5,1998/10/01 08:20:00,140.2007569371,35.7882436603,2,8,4422113,3 ,8 ,42,,7",
 "73600,2,3,1998/10/01 08:20:00,140.0946648357,35.7024946753,2,1,4410011,12,2 ,35,,9",
 "869400,2,3,1998/10/01 08:20:00,139.7183803876,35.568164124,1,10,1231001,8 ,1 ,29,,12",
 "280600,5,5,1998/10/01 08:20:00,139.7471885084,35.6945925908,1,11,2910009,8 ,9 ,42,,8",
 "2300,2,3,1998/10/01 08:20:00,139.8122955026,35.6966342816,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:20:00,139.6026100999,35.8182461043,1,6,3032201,3 ,11,34,,6",
 "356500,2,2,1998/10/01 08:20:00,139.7327331042,35.8471336401,2,6,3018202,8 ,4 ,22,,4",
 "356500,3,3,1998/10/01 08:20:00,139.7327331042,35.8471336401,2,6,3018202,8 ,97,22,,97",
 "16100,2,2,1998/10/01 08:20:00,139.9129362186,35.648598968,2,4,4116209,8 ,1 ,48,,1",
 "34500,2,4,1998/10/01 08:20:00,140.0263959892,35.7502311347,1,4,4129003,8 ,1 ,36,,1",
 "41400,2,5,1998/10/01 08:20:00,139.9749030724,35.8582185815,2,5,4212212,8 ,1 ,44,,10",
 "41400,2,6,1998/10/01 08:20:00,139.9749030724,35.8582185815,2,5,4212212,8 ,1 ,44,,1",
 "593400,2,3,1998/10/01 08:20:00,139.8191639448,35.6835125243,1,6,0349009,9 ,1 ,58,,10",
 "62100,2,3,1998/10/01 08:20:00,139.9964875458,35.87393785,1,7,4224202,9 ,1 ,37,,12",
 "657800,2,3,1998/10/01 08:20:00,139.798119294,35.7563062741,2,11,0611113,9 ,1 ,46,,10",
 "78200,2,3,1998/10/01 08:20:00,139.8454889352,35.6656866541,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:20:00,139.9252259726,35.7218073689,1,9,4031206,8 ,1 ,38,,12",
 "828000,2,3,1998/10/01 08:20:00,139.5050670015,35.5044141905,1,5,1252001,4 ,1 ,38,,10",
 "172500,2,3,1998/10/01 08:20:00,139.9730585734,35.8647319881,2,4,5024120,13,2 ,44,,12",
 "197800,3,4,1998/10/01 08:20:00,140.1891325119,35.9079829201,2,3,5421032,12,97,37,,97",
 "197800,2,3,1998/10/01 08:20:00,140.1891325119,35.9079829201,2,3,5421032,12,2 ,37,,1",
 "209300,2,3,1998/10/01 08:20:00,139.5965990963,35.4142042175,1,13,2211303,10,1 ,37,,12",
 "211600,2,3,1998/10/01 08:20:00,139.7078636265,35.2595089337,1,7,2220004,6 ,1 ,36,,10",
 "211600,2,4,1998/10/01 08:20:00,139.7078636265,35.2595089337,1,7,2220004,6 ,1 ,36,,12",
 "225400,2,3,1998/10/01 08:20:00,139.5095002315,35.3392977725,1,3,2313201,13,2 ,36,,12",
 "227700,2,3,1998/10/01 08:20:00,139.7293983861,35.5909064855,1,9,2410203,8 ,10,16,,12",
 "301300,2,4,1998/10/01 08:20:00,139.6974255062,35.5229571339,1,11,2922005,9 ,1 ,29,,1",
 "301300,3,5,1998/10/01 08:20:00,139.6974255062,35.5229571339,1,11,2922005,9 ,97,29,,97",
 "85100,2,2,1998/10/01 08:20:00,140.1629600953,35.7169310129,1,8,4417027,9 ,10,46,,6",
 "303600,2,3,1998/10/01 08:20:00,139.6544250519,35.6562805826,1,9,2923016,9 ,1 ,30,,12",
 "310500,2,4,1998/10/01 08:20:00,139.5287650695,35.3878598898,1,9,2013102,3 ,1 ,37,,1",
 "310500,3,5,1998/10/01 08:20:00,139.5287650695,35.3878598898,1,9,2013102,3 ,97,37,,97",
 "322000,2,3,1998/10/01 08:20:00,139.6657126364,35.5857671111,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,2,1998/10/01 08:20:00,139.6131979955,35.5979944607,1,10,2110103,8 ,1 ,29,,1",
 "181700,2,2,1998/10/01 08:20:00,140.007093137,36.1472055626,2,7,5123003,8 ,1 ,31,,6",
 "177100,2,2,1998/10/01 08:20:00,139.8451103717,36.0803880887,2,3,5113112,13,2 ,35,,6",
 "200100,2,2,1998/10/01 08:20:00,140.3087461094,36.0033729688,2,8,5510012,8 ,1 ,39,,6",
 "232300,2,2,1998/10/01 08:20:00,139.5028970868,35.336310246,1,4,2411307,3 ,1 ,40,,2",
 "345000,2,4,1998/10/01 08:20:00,139.730581922,35.7604043718,1,8,3012002,9 ,1 ,37,,12",
 "241500,2,2,1998/10/01 08:20:00,139.3788122946,35.3608887033,1,6,2420707,5 ,1 ,37,,6",
 "241500,3,3,1998/10/01 08:20:00,139.3788122946,35.3608887033,1,6,2420707,5 ,97,37,,97",
 "271400,2,2,1998/10/01 08:20:00,139.3381325793,35.4658697359,1,7,2811315,9 ,11,37,,8",
 "404800,2,3,1998/10/01 08:20:00,139.5901707976,35.7264446175,2,9,3120009,8 ,1 ,30,,12",
 "374900,2,2,1998/10/01 08:20:00,139.6796490131,35.8227803154,1,4,3040108,4 ,1 ,71,,6",
 "425500,2,3,1998/10/01 08:20:00,139.5285840774,35.854562391,1,4,3134008,8 ,1 ,45,,12",
 "437000,2,4,1998/10/01 08:20:00,139.7676314037,35.6647282336,1,7,3212001,5 ,1 ,36,,1",
 "446200,2,3,1998/10/01 08:20:00,139.787329607,35.7851731662,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,3,1998/10/01 08:20:00,139.4341260938,35.9306244539,2,10,3411010,8 ,1 ,30,,12",
 "478400,2,2,1998/10/01 08:20:00,139.3929280343,36.0073939671,1,4,3413105,13,2 ,40,,1",
 "441600,4,4,1998/10/01 08:20:00,139.8224869476,35.726248973,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 08:20:00,139.7417141921,35.7497750322,1,10,3510101,8 ,1 ,27,,12",
 "563500,2,3,1998/10/01 08:20:00,139.7574587343,35.676943372,1,7,0212001,10,1 ,84,,12",
 "572700,2,2,1998/10/01 08:20:00,139.7113052124,35.6694785782,2,2,0241003,12,2 ,27,,1",
 "611800,2,2,1998/10/01 08:20:00,139.6617770182,35.6335180388,1,4,0431110,13,2 ,65,,1",
 "611800,2,3,1998/10/01 08:20:00,139.6617770182,35.6335180388,1,4,0431110,13,2 ,65,,12",
 "644000,2,3,1998/10/01 08:20:00,139.755247351,35.6688554288,1,4,0522008,5 ,1 ,52,,12",
 "646300,2,3,1998/10/01 08:20:00,139.7633329144,35.6869415058,1,4,0523008,8 ,1 ,75,,12",
 "660100,2,3,1998/10/01 08:20:00,139.8023762861,35.7419220889,1,7,0612111,9 ,1 ,60,,12",
 "759000,2,3,1998/10/01 08:20:00,139.6802945232,35.6779031739,2,4,0821006,9 ,1 ,55,,12",
 "823400,2,3,1998/10/01 08:20:00,139.5985466219,35.4554085515,1,10,1213105,9 ,13,34,,12",
 "669300,2,3,1998/10/01 08:20:00,139.905737733,35.7940942329,1,6,0620210,9 ,13,47,,12",
 "745200,2,2,1998/10/01 08:20:00,139.4163924112,35.7088222711,2,10,0790316,3 ,1 ,30,,2",
 "745200,3,3,1998/10/01 08:20:00,139.4163924112,35.7088222711,2,10,0790316,3 ,97,30,,97",
 "811900,2,4,1998/10/01 08:20:00,139.5812654873,35.3489542668,1,3,1123201,13,2 ,31,,1",
 "811900,3,5,1998/10/01 08:20:00,139.5812654873,35.3489542668,1,3,1123201,13,97,31,,97",
 "786600,2,3,1998/10/01 08:20:00,139.344171696,35.6545600754,2,3,0930110,13,2 ,35,,12",
 "798100,2,2,1998/10/01 08:20:00,139.5131808823,35.5237990151,2,6,1421008,9 ,1 ,37,,6",
 "798100,3,3,1998/10/01 08:20:00,139.5131808823,35.5237990151,2,6,1421008,9 ,97,37,,97",
 "683100,2,4,1998/10/01 08:20:00,139.7410392656,35.6920466386,2,3,0633106,12,2 ,49,,1",
 "692300,2,3,1998/10/01 08:20:00,139.7501933482,35.6952062716,2,4,0711108,8 ,1 ,25,,12",
 "867100,2,2,1998/10/01 08:20:00,139.4931809217,35.4159877616,2,2,1241102,12,2 ,29,,1",
 "876300,2,2,1998/10/01 08:20:00,139.4940632021,35.4160417167,1,9,1223102,9 ,1 ,27,,7",
 "876300,3,3,1998/10/01 08:20:00,139.4940632021,35.4160417167,1,9,1223102,9 ,97,27,,97",
 "719900,2,4,1998/10/01 08:20:00,139.5277229695,35.7017125394,1,7,0752005,6 ,10,34,,12",
 "696900,2,3,1998/10/01 08:20:00,139.6956917988,35.6576598901,2,6,0720006,4 ,1 ,39,,12",
 "20700,2,2,1998/10/01 08:21:00,140.020039563,35.6761532069,1,8,4121303,9 ,1 ,44,,6",
 "772800,2,2,1998/10/01 08:21:00,139.4012194868,35.6141842074,2,9,0842111,4 ,1 ,38,,1",
 "830300,2,3,1998/10/01 08:21:00,139.6680959798,35.5603737662,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:21:00,140.0948595069,35.7027332411,2,1,4410011,12,2 ,35,,9",
 "89700,5,5,1998/10/01 08:21:00,140.1983995451,35.7926452307,2,8,4422113,3 ,8 ,42,,7",
 "128800,2,2,1998/10/01 08:21:00,140.400462513,35.8079555029,1,14,4831221,15,7 ,26,,1",
 "869400,2,3,1998/10/01 08:21:00,139.7202652732,35.5725906673,1,10,1231001,8 ,1 ,29,,12",
 "280600,5,5,1998/10/01 08:21:00,139.7504959844,35.6954077522,1,11,2910009,8 ,9 ,42,,8",
 "2300,2,3,1998/10/01 08:21:00,139.8077203586,35.696391943,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:21:00,139.6041240284,35.8165402932,1,6,3032201,3 ,11,34,,6",
 "16100,2,2,1998/10/01 08:21:00,139.9128940266,35.6496614485,2,4,4116209,8 ,1 ,48,,1",
 "16100,2,3,1998/10/01 08:21:00,139.9128940266,35.6496614485,2,4,4116209,8 ,1 ,48,,12",
 "34500,2,4,1998/10/01 08:21:00,140.0277900779,35.7500656203,1,4,4129003,8 ,1 ,36,,1",
 "41400,2,6,1998/10/01 08:21:00,139.9728803943,35.859704411,2,5,4212212,8 ,1 ,44,,1",
 "593400,2,3,1998/10/01 08:21:00,139.8191638538,35.6835126112,1,6,0349009,9 ,1 ,58,,10",
 "657800,2,3,1998/10/01 08:21:00,139.7981192452,35.7563065492,2,11,0611113,9 ,1 ,46,,10",
 "62100,2,3,1998/10/01 08:21:00,139.9892472225,35.8755248546,1,7,4224202,9 ,1 ,37,,12",
 "78200,2,3,1998/10/01 08:21:00,139.8404472729,35.6662460275,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:21:00,139.9191386061,35.7243998473,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:21:00,139.9697069279,35.8605516602,2,4,5024120,13,2 ,44,,12",
 "828000,2,3,1998/10/01 08:21:00,139.5050670599,35.5044143698,1,5,1252001,4 ,1 ,38,,10",
 "209300,2,3,1998/10/01 08:21:00,139.5976694561,35.420225298,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:21:00,139.7001820999,35.2592027851,1,7,2220004,6 ,1 ,36,,12",
 "227700,2,3,1998/10/01 08:21:00,139.7330826772,35.5966290937,1,9,2410203,8 ,10,16,,12",
 "225400,2,3,1998/10/01 08:21:00,139.5060780237,35.3374526054,1,3,2313201,13,2 ,36,,12",
 "303600,2,3,1998/10/01 08:21:00,139.6591511912,35.6577091816,1,9,2923016,9 ,1 ,30,,12",
 "85100,2,2,1998/10/01 08:21:00,140.1587853311,35.7163116706,1,8,4417027,9 ,10,46,,6",
 "103500,4,4,1998/10/01 08:21:00,139.9374727353,35.38216657,2,3,4610015,13,2 ,38,,2",
 "322000,2,3,1998/10/01 08:21:00,139.6683204764,35.589041377,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,2,1998/10/01 08:21:00,139.6123077552,35.5991940438,1,10,2110103,8 ,1 ,29,,1",
 "177100,2,2,1998/10/01 08:21:00,139.8510776198,36.0760681135,2,3,5113112,13,2 ,35,,6",
 "181700,2,2,1998/10/01 08:21:00,140.0115858854,36.1443823738,2,7,5123003,8 ,1 ,31,,6",
 "200100,2,2,1998/10/01 08:21:00,140.3073722021,36.0039001212,2,8,5510012,8 ,1 ,39,,6",
 "232300,2,2,1998/10/01 08:21:00,139.5042574308,35.3363176569,1,4,2411307,3 ,1 ,40,,2",
 "345000,2,4,1998/10/01 08:21:00,139.733266051,35.7583967154,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:21:00,139.3396720815,35.465482335,1,7,2811315,9 ,11,37,,8",
 "358800,2,2,1998/10/01 08:21:00,139.6878235894,35.8098479611,2,5,3023001,9 ,1 ,35,,2",
 "404800,2,3,1998/10/01 08:21:00,139.5954778107,35.7258489772,2,9,3120009,8 ,1 ,30,,12",
 "361100,2,2,1998/10/01 08:21:00,139.6371635604,35.8270458547,1,6,3026023,9 ,1 ,32,,6",
 "418600,2,2,1998/10/01 08:21:00,139.359333129,35.8523003674,1,11,3123503,8 ,1 ,23,,1",
 "374900,2,2,1998/10/01 08:21:00,139.6815573011,35.8198696879,1,4,3040108,4 ,1 ,71,,6",
 "379500,2,2,1998/10/01 08:21:00,139.6052817853,35.928864298,2,6,3053202,8 ,1 ,31,,1",
 "425500,2,3,1998/10/01 08:21:00,139.5333013108,35.8499010027,1,4,3134008,8 ,1 ,45,,12",
 "437000,2,4,1998/10/01 08:21:00,139.7672340217,35.6645338844,1,7,3212001,5 ,1 ,36,,1",
 "446200,2,3,1998/10/01 08:21:00,139.7865358612,35.7823637563,2,5,3221102,8 ,1 ,37,,12",
 "441600,4,4,1998/10/01 08:21:00,139.8209154597,35.7248099054,1,9,3215009,9 ,11,30,,8",
 "478400,2,2,1998/10/01 08:21:00,139.3935838652,36.0073424818,1,4,3413105,13,2 ,40,,1",
 "473800,2,3,1998/10/01 08:21:00,139.4383173525,35.9280578884,2,10,3411010,8 ,1 ,30,,12",
 "483000,2,3,1998/10/01 08:21:00,139.7460417518,35.749193898,1,10,3510101,8 ,1 ,27,,12",
 "462300,4,4,1998/10/01 08:21:00,139.7549714528,35.8890835888,1,8,3235004,9 ,11,38,,8",
 "522100,2,2,1998/10/01 08:21:00,139.2842726872,36.1809265284,1,3,3722104,13,2 ,35,,2",
 "563500,2,3,1998/10/01 08:21:00,139.7610251423,35.6762644597,1,7,0212001,10,1 ,84,,12",
 "572700,2,2,1998/10/01 08:21:00,139.7112749078,35.6692206944,2,2,0241003,12,2 ,27,,1",
 "627900,2,2,1998/10/01 08:21:00,139.6487914828,35.7437583375,2,7,0511204,9 ,1 ,34,,2",
 "611800,2,3,1998/10/01 08:21:00,139.6564541762,35.6310202581,1,4,0431110,13,2 ,65,,12",
 "644000,2,3,1998/10/01 08:21:00,139.7535768068,35.6654021158,1,4,0522008,5 ,1 ,52,,12",
 "646300,2,3,1998/10/01 08:21:00,139.762123347,35.681778613,1,4,0523008,8 ,1 ,75,,12",
 "823400,2,3,1998/10/01 08:21:00,139.5995306437,35.455033072,1,10,1213105,9 ,13,34,,12",
 "724500,2,2,1998/10/01 08:21:00,139.4882800567,35.6945451461,2,10,0761001,8 ,1 ,33,,7",
 "786600,2,3,1998/10/01 08:21:00,139.3393772653,35.6554062071,2,3,0930110,13,2 ,35,,12",
 "660100,2,3,1998/10/01 08:21:00,139.8016167435,35.7393867196,1,7,0612111,9 ,1 ,60,,12",
 "669300,2,3,1998/10/01 08:21:00,139.9124279618,35.8012958145,1,6,0620210,9 ,13,47,,12",
 "759000,2,3,1998/10/01 08:21:00,139.6851948188,35.6799383667,2,4,0821006,9 ,1 ,55,,12",
 "867100,2,2,1998/10/01 08:21:00,139.4940632021,35.4160417167,2,2,1241102,12,2 ,29,,1",
 "867100,3,3,1998/10/01 08:21:00,139.4940632021,35.4160417167,2,2,1241102,12,97,29,,97",
 "696900,2,3,1998/10/01 08:21:00,139.6999382988,35.6584732652,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:21:00,139.531420021,35.7018217291,1,7,0752005,6 ,10,34,,12",
 "692300,2,3,1998/10/01 08:21:00,139.754960625,35.6958849516,2,4,0711108,8 ,1 ,25,,12",
 "683100,2,4,1998/10/01 08:21:00,139.741828847,35.6922622268,2,3,0633106,12,2 ,49,,1",
 "20700,2,2,1998/10/01 08:22:00,140.0200394147,35.6761530421,1,8,4121303,9 ,1 ,44,,6",
 "772800,2,2,1998/10/01 08:22:00,139.4002787149,35.6152523795,2,9,0842111,4 ,1 ,38,,1",
 "830300,2,3,1998/10/01 08:22:00,139.6649996435,35.5693309535,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:22:00,140.0950541793,35.7029718066,2,1,4410011,12,2 ,35,,9",
 "89700,5,5,1998/10/01 08:22:00,140.1952335705,35.7965781374,2,8,4422113,3 ,8 ,42,,7",
 "128800,2,2,1998/10/01 08:22:00,140.4004644766,35.8079571061,1,14,4831221,15,7 ,26,,1",
 "280600,5,5,1998/10/01 08:22:00,139.7523971649,35.6969098023,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:22:00,139.722117112,35.5770265583,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,3,1998/10/01 08:22:00,139.8031452107,35.6961498224,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:22:00,139.6056211199,35.8148248428,1,6,3032201,3 ,11,34,,6",
 "16100,2,3,1998/10/01 08:22:00,139.9062243784,35.6442830182,2,4,4116209,8 ,1 ,48,,12",
 "34500,2,4,1998/10/01 08:22:00,140.0280154864,35.749087795,1,4,4129003,8 ,1 ,36,,1",
 "41400,2,6,1998/10/01 08:22:00,139.9694699129,35.8603686293,2,5,4212212,8 ,1 ,44,,1",
 "593400,2,3,1998/10/01 08:22:00,139.8191637628,35.6835126981,1,6,0349009,9 ,1 ,58,,10",
 "657800,2,3,1998/10/01 08:22:00,139.7981191965,35.7563068243,2,11,0611113,9 ,1 ,46,,10",
 "62100,2,3,1998/10/01 08:22:00,139.9823520285,35.8739407826,1,7,4224202,9 ,1 ,37,,12",
 "78200,2,3,1998/10/01 08:22:00,139.8355093817,35.66720245,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:22:00,139.9130244088,35.7269498434,1,9,4031206,8 ,1 ,38,,12",
 "828000,2,3,1998/10/01 08:22:00,139.5050671183,35.5044145491,1,5,1252001,4 ,1 ,38,,10",
 "172500,2,3,1998/10/01 08:22:00,139.9664006629,35.8563475448,2,4,5024120,13,2 ,44,,12",
 "209300,2,3,1998/10/01 08:22:00,139.5963150829,35.426222905,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:22:00,139.6931348565,35.2619048763,1,7,2220004,6 ,1 ,36,,12",
 "225400,2,3,1998/10/01 08:22:00,139.5024277352,35.3359878584,1,3,2313201,13,2 ,36,,12",
 "227700,2,3,1998/10/01 08:22:00,139.7345037766,35.6029425668,1,9,2410203,8 ,10,16,,12",
 "303600,2,3,1998/10/01 08:22:00,139.6638898541,35.6590882256,1,9,2923016,9 ,1 ,30,,12",
 "85100,2,2,1998/10/01 08:22:00,140.1546579134,35.7157263062,1,8,4417027,9 ,10,46,,6",
 "103500,4,4,1998/10/01 08:22:00,139.9385676677,35.3822102551,2,3,4610015,13,2 ,38,,2",
 "322000,2,3,1998/10/01 08:22:00,139.6678401058,35.5928574334,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,2,1998/10/01 08:22:00,139.6122497865,35.5989810182,1,10,2110103,8 ,1 ,29,,1",
 "177100,2,2,1998/10/01 08:22:00,139.8586653206,36.0725175148,2,3,5113112,13,2 ,35,,6",
 "181700,2,2,1998/10/01 08:22:00,140.0155108298,36.1412014318,2,7,5123003,8 ,1 ,31,,6",
 "200100,2,2,1998/10/01 08:22:00,140.3061449659,36.00487761,2,8,5510012,8 ,1 ,39,,6",
 "232300,2,2,1998/10/01 08:22:00,139.5063826968,35.3365080827,1,4,2411307,3 ,1 ,40,,2",
 "345000,2,4,1998/10/01 08:22:00,139.7352595533,35.7559067299,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:22:00,139.3413226542,35.4654784203,1,7,2811315,9 ,11,37,,8",
 "358800,2,2,1998/10/01 08:22:00,139.6862483808,35.8098435902,2,5,3023001,9 ,1 ,35,,2",
 "404800,2,3,1998/10/01 08:22:00,139.6008027877,35.725402484,2,9,3120009,8 ,1 ,30,,12",
 "361100,2,2,1998/10/01 08:22:00,139.6378283034,35.8281058701,1,6,3026023,9 ,1 ,32,,6",
 "418600,2,2,1998/10/01 08:22:00,139.3593331549,35.8522999924,1,11,3123503,8 ,1 ,23,,1",
 "374900,2,2,1998/10/01 08:22:00,139.6819732197,35.8165691472,1,4,3040108,4 ,1 ,71,,6",
 "425500,2,3,1998/10/01 08:22:00,139.5389288705,35.845961756,1,4,3134008,8 ,1 ,45,,12",
 "379500,2,2,1998/10/01 08:22:00,139.6055877331,35.9278636362,2,6,3053202,8 ,1 ,31,,1",
 "437000,2,4,1998/10/01 08:22:00,139.7668366417,35.664339534,1,7,3212001,5 ,1 ,36,,1",
 "446200,2,3,1998/10/01 08:22:00,139.7879335834,35.7797672291,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,3,1998/10/01 08:22:00,139.4427151329,35.9257649972,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 08:22:00,139.8193361084,35.7233767431,1,9,3215009,9 ,11,30,,8",
 "478400,2,2,1998/10/01 08:22:00,139.3939775321,36.0070506905,1,4,3413105,13,2 ,40,,1",
 "483000,2,3,1998/10/01 08:22:00,139.7502690436,35.7483015307,1,10,3510101,8 ,1 ,27,,12",
 "462300,4,4,1998/10/01 08:22:00,139.7550231278,35.8882807074,1,8,3235004,9 ,11,38,,8",
 "522100,2,2,1998/10/01 08:22:00,139.2829158656,36.1807001931,1,3,3722104,13,2 ,35,,2",
 "563500,2,3,1998/10/01 08:22:00,139.7645686001,35.6755048093,1,7,0212001,10,1 ,84,,12",
 "572700,2,2,1998/10/01 08:22:00,139.7115420442,35.6689996062,2,2,0241003,12,2 ,27,,1",
 "627900,2,2,1998/10/01 08:22:00,139.6483533655,35.7451343117,2,7,0511204,9 ,1 ,34,,2",
 "611800,2,3,1998/10/01 08:22:00,139.6505232374,35.6311950412,1,4,0431110,13,2 ,65,,12",
 "644000,2,3,1998/10/01 08:22:00,139.7519477709,35.6619356664,1,4,0522008,5 ,1 ,52,,12",
 "646300,2,3,1998/10/01 08:22:00,139.7602174248,35.6764452764,1,4,0523008,8 ,1 ,75,,12",
 "823400,2,3,1998/10/01 08:22:00,139.6005337252,35.454690159,1,10,1213105,9 ,13,34,,12",
 "724500,2,2,1998/10/01 08:22:00,139.4889399087,35.694312682,2,10,0761001,8 ,1 ,33,,7",
 "786600,2,3,1998/10/01 08:22:00,139.3345788818,35.6562371348,2,3,0930110,13,2 ,35,,12",
 "660100,2,3,1998/10/01 08:22:00,139.800873763,35.7368484145,1,7,0612111,9 ,1 ,60,,12",
 "669300,2,3,1998/10/01 08:22:00,139.916755132,35.8095387325,1,6,0620210,9 ,13,47,,12",
 "759000,2,3,1998/10/01 08:22:00,139.6895044964,35.682673333,2,4,0821006,9 ,1 ,55,,12",
 "719900,2,4,1998/10/01 08:22:00,139.5351179595,35.7019115642,1,7,0752005,6 ,10,34,,12",
 "683100,2,4,1998/10/01 08:22:00,139.7426176498,35.6924797244,2,3,0633106,12,2 ,49,,1",
 "692300,2,3,1998/10/01 08:22:00,139.7585004487,35.6945452132,2,4,0711108,8 ,1 ,25,,12",
 "696900,2,3,1998/10/01 08:22:00,139.7027469809,35.6566174263,2,6,0720006,4 ,1 ,39,,12",
 "20700,2,2,1998/10/01 08:23:00,140.0200392665,35.6761528773,1,8,4121303,9 ,1 ,44,,6",
 "772800,2,2,1998/10/01 08:23:00,139.3995654602,35.6163080248,2,9,0842111,4 ,1 ,38,,1",
 "830300,2,3,1998/10/01 08:23:00,139.6645820166,35.5782248336,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:23:00,140.0952496691,35.7032099096,2,1,4410011,12,2 ,35,,9",
 "89700,5,5,1998/10/01 08:23:00,140.193879598,35.8011004887,2,8,4422113,3 ,8 ,42,,7",
 "128800,2,2,1998/10/01 08:23:00,140.4004664402,35.8079587094,1,14,4831221,15,7 ,26,,1",
 "280600,5,5,1998/10/01 08:23:00,139.7527699616,35.6993283716,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:23:00,139.7239625086,35.5814641519,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,3,1998/10/01 08:23:00,139.7985703517,35.6959046,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:23:00,139.6071245994,35.8131195544,1,6,3032201,3 ,11,34,,6",
 "16100,2,3,1998/10/01 08:23:00,139.8995352226,35.6387308942,2,4,4116209,8 ,1 ,48,,12",
 "34500,2,4,1998/10/01 08:23:00,140.0277343515,35.747927751,1,4,4129003,8 ,1 ,36,,1",
 "41400,2,6,1998/10/01 08:23:00,139.9668990546,35.8615792901,2,5,4212212,8 ,1 ,44,,1",
 "593400,2,3,1998/10/01 08:23:00,139.8191636718,35.683512785,1,6,0349009,9 ,1 ,58,,10",
 "657800,2,3,1998/10/01 08:23:00,139.7981191477,35.7563070994,2,11,0611113,9 ,1 ,46,,10",
 "62100,2,3,1998/10/01 08:23:00,139.9769496317,35.869742402,1,7,4224202,9 ,1 ,37,,12",
 "78200,2,3,1998/10/01 08:23:00,139.8307235614,35.6686016878,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:23:00,139.9068704061,35.7294360078,1,9,4031206,8 ,1 ,38,,12",
 "828000,2,3,1998/10/01 08:23:00,139.5050671767,35.5044147283,1,5,1252001,4 ,1 ,38,,10",
 "172500,2,3,1998/10/01 08:23:00,139.9630952886,35.85214305,2,4,5024120,13,2 ,44,,12",
 "209300,2,3,1998/10/01 08:23:00,139.5965312383,35.4322048951,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:23:00,139.6859295485,35.263751824,1,7,2220004,6 ,1 ,36,,12",
 "225400,2,3,1998/10/01 08:23:00,139.498409698,35.3363412021,1,3,2313201,13,2 ,36,,12",
 "227700,2,3,1998/10/01 08:23:00,139.7353076092,35.6093747639,1,9,2410203,8 ,10,16,,12",
 "303600,2,3,1998/10/01 08:23:00,139.6677430098,35.661738486,1,9,2923016,9 ,1 ,30,,12",
 "85100,2,2,1998/10/01 08:23:00,140.150060251,35.7175816858,1,8,4417027,9 ,10,46,,6",
 "103500,4,4,1998/10/01 08:23:00,139.9399121002,35.3818811648,2,3,4610015,13,2 ,38,,2",
 "133400,2,2,1998/10/01 08:23:00,140.4538791262,35.5653951825,2,3,4911101,13,2 ,34,,7",
 "322000,2,3,1998/10/01 08:23:00,139.6673057039,35.5967860951,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,2,1998/10/01 08:23:00,139.6111733831,35.6001230471,1,10,2110103,8 ,1 ,29,,1",
 "177100,2,2,1998/10/01 08:23:00,139.865771525,36.0683270637,2,3,5113112,13,2 ,35,,6",
 "181700,2,2,1998/10/01 08:23:00,140.0164679232,36.1364677237,2,7,5123003,8 ,1 ,31,,6",
 "200100,2,2,1998/10/01 08:23:00,140.3046204698,36.0055436653,2,8,5510012,8 ,1 ,39,,6",
 "232300,2,2,1998/10/01 08:23:00,139.5084076907,35.3357355453,1,4,2411307,3 ,1 ,40,,2",
 "345000,2,4,1998/10/01 08:23:00,139.7372529313,35.7534167102,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:23:00,139.3427395244,35.4648581495,1,7,2811315,9 ,11,37,,8",
 "358800,2,2,1998/10/01 08:23:00,139.6846538277,35.8101599649,2,5,3023001,9 ,1 ,35,,2",
 "404800,2,3,1998/10/01 08:23:00,139.6061455043,35.7250997748,2,9,3120009,8 ,1 ,30,,12",
 "361100,2,2,1998/10/01 08:23:00,139.640074991,35.8286107727,1,6,3026023,9 ,1 ,32,,6",
 "374900,2,2,1998/10/01 08:23:00,139.6823578913,35.8132632991,1,4,3040108,4 ,1 ,71,,6",
 "418600,2,2,1998/10/01 08:23:00,139.3593331809,35.8522996175,1,11,3123503,8 ,1 ,23,,1",
 "379500,2,2,1998/10/01 08:23:00,139.605684704,35.9268395854,2,6,3053202,8 ,1 ,31,,1",
 "425500,2,3,1998/10/01 08:23:00,139.5447247424,35.8421814823,1,4,3134008,8 ,1 ,45,,12",
 "437000,2,4,1998/10/01 08:23:00,139.7664392636,35.6641451822,1,7,3212001,5 ,1 ,36,,1",
 "446200,2,3,1998/10/01 08:23:00,139.7901652121,35.7775123402,2,5,3221102,8 ,1 ,37,,12",
 "441600,4,4,1998/10/01 08:23:00,139.8177619876,35.7219409819,1,9,3215009,9 ,11,30,,8",
 "473800,2,3,1998/10/01 08:23:00,139.4477404987,35.924550556,2,10,3411010,8 ,1 ,30,,12",
 "483000,2,3,1998/10/01 08:23:00,139.7542124999,35.7466781787,1,10,3510101,8 ,1 ,27,,12",
 "478400,2,2,1998/10/01 08:23:00,139.3940482689,36.0065184346,1,4,3413105,13,2 ,40,,1",
 "462300,4,4,1998/10/01 08:23:00,139.7550748017,35.8874778259,1,8,3235004,9 ,11,38,,8",
 "522100,2,2,1998/10/01 08:23:00,139.2815794782,36.1808579664,1,3,3722104,13,2 ,35,,2",
 "563500,2,3,1998/10/01 08:23:00,139.7676345594,35.6739936998,1,7,0212001,10,1 ,84,,12",
 "572700,2,2,1998/10/01 08:23:00,139.7112825216,35.6687012312,2,2,0241003,12,2 ,27,,1",
 "611800,2,3,1998/10/01 08:23:00,139.6444352319,35.6316613453,1,4,0431110,13,2 ,65,,12",
 "627900,2,2,1998/10/01 08:23:00,139.6490070504,35.7464952346,2,7,0511204,9 ,1 ,34,,2",
 "644000,2,3,1998/10/01 08:23:00,139.75041318,35.6584568076,1,4,0522008,5 ,1 ,52,,12",
 "724500,2,2,1998/10/01 08:23:00,139.4916546309,35.6937493255,2,10,0761001,8 ,1 ,33,,7",
 "823400,2,3,1998/10/01 08:23:00,139.6015367983,35.4543472376,1,10,1213105,9 ,13,34,,12",
 "646300,2,3,1998/10/01 08:23:00,139.7634250912,35.6723983972,1,4,0523008,8 ,1 ,75,,12",
 "786600,2,3,1998/10/01 08:23:00,139.3297407424,35.6568835447,2,3,0930110,13,2 ,35,,12",
 "669300,2,3,1998/10/01 08:23:00,139.9190784675,35.8183713778,1,6,0620210,9 ,13,47,,12",
 "660100,2,3,1998/10/01 08:23:00,139.7998251521,35.7343874115,1,7,0612111,9 ,1 ,60,,12",
 "759000,2,3,1998/10/01 08:23:00,139.693352229,35.6858448258,2,4,0821006,9 ,1 ,55,,12",
 "696900,2,3,1998/10/01 08:23:00,139.7053219808,35.653712342,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:23:00,139.5388159071,35.7020012857,1,7,0752005,6 ,10,34,,12",
 "683100,2,4,1998/10/01 08:23:00,139.7434059843,35.6926983575,2,3,0633106,12,2 ,49,,1",
 "692300,2,3,1998/10/01 08:23:00,139.7611536831,35.6915654673,2,4,0711108,8 ,1 ,25,,12",
 "20700,2,2,1998/10/01 08:24:00,140.0200391182,35.6761527125,1,8,4121303,9 ,1 ,44,,6",
 "20700,2,3,1998/10/01 08:24:00,140.0200391182,35.6761527125,1,8,4121303,9 ,1 ,44,,1",
 "772800,2,2,1998/10/01 08:24:00,139.3994891268,35.6176019053,2,9,0842111,4 ,1 ,38,,1",
 "830300,2,3,1998/10/01 08:24:00,139.6746800946,35.5823927496,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:24:00,140.0954556028,35.7034421,2,1,4410011,12,2 ,35,,9",
 "89700,5,5,1998/10/01 08:24:00,140.1901558192,35.8039195494,2,8,4422113,3 ,8 ,42,,7",
 "128800,2,2,1998/10/01 08:24:00,140.4004684038,35.8079603126,1,14,4831221,15,7 ,26,,1",
 "280600,5,5,1998/10/01 08:24:00,139.7542189096,35.7017066436,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:24:00,139.7261222391,35.5857929125,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,3,1998/10/01 08:24:00,139.7940504028,35.6963097288,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:24:00,139.6082677433,35.81123453,1,6,3032201,3 ,11,34,,6",
 "16100,2,3,1998/10/01 08:24:00,139.8917811881,35.6345493655,2,4,4116209,8 ,1 ,48,,12",
 "34500,2,4,1998/10/01 08:24:00,140.0267940546,35.7481779928,1,4,4129003,8 ,1 ,36,,1",
 "41400,2,6,1998/10/01 08:24:00,139.9643313245,35.863036977,2,5,4212212,8 ,1 ,44,,1",
 "593400,2,3,1998/10/01 08:24:00,139.8191635808,35.683512872,1,6,0349009,9 ,1 ,58,,10",
 "48300,2,2,1998/10/01 08:24:00,139.931136254,35.761357811,2,4,4215402,4 ,1 ,44,,1",
 "657800,2,3,1998/10/01 08:24:00,139.798119099,35.7563073745,2,11,0611113,9 ,1 ,46,,10",
 "62100,2,3,1998/10/01 08:24:00,139.9729415944,35.8645860921,1,7,4224202,9 ,1 ,37,,12",
 "78200,2,3,1998/10/01 08:24:00,139.8257933406,35.6696333179,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:24:00,139.9007461266,35.7319596886,1,9,4031206,8 ,1 ,38,,12",
 "828000,2,3,1998/10/01 08:24:00,139.5050672351,35.5044149076,1,5,1252001,4 ,1 ,38,,10",
 "172500,2,3,1998/10/01 08:24:00,139.9593384661,35.8482085185,2,4,5024120,13,2 ,44,,12",
 "209300,2,3,1998/10/01 08:24:00,139.6032458867,35.4344862915,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:24:00,139.6801643168,35.2679843304,1,7,2220004,6 ,1 ,36,,12",
 "225400,2,3,1998/10/01 08:24:00,139.4945158268,35.3373832591,1,3,2313201,13,2 ,36,,12",
 "227700,2,3,1998/10/01 08:24:00,139.7363746221,35.6157757718,1,9,2410203,8 ,10,16,,12",
 "303600,2,3,1998/10/01 08:24:00,139.671560051,35.6644243409,1,9,2923016,9 ,1 ,30,,12",
 "85100,2,2,1998/10/01 08:24:00,140.1453373118,35.718616647,1,8,4417027,9 ,10,46,,6",
 "103500,4,4,1998/10/01 08:24:00,139.9406014334,35.3811249186,2,3,4610015,13,2 ,38,,2",
 "133400,2,2,1998/10/01 08:24:00,140.4493954315,35.5687496235,2,3,4911101,13,2 ,34,,7",
 "322000,2,3,1998/10/01 08:24:00,139.6674480996,35.6007422192,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,2,1998/10/01 08:24:00,139.6105068811,35.5998559658,1,10,2110103,8 ,1 ,29,,1",
 "324300,2,3,1998/10/01 08:24:00,139.6105068811,35.5998559658,1,10,2110103,8 ,1 ,29,,12",
 "177100,2,2,1998/10/01 08:24:00,139.8709654924,36.0629515086,2,3,5113112,13,2 ,35,,6",
 "181700,2,2,1998/10/01 08:24:00,140.0200265287,36.1327559633,2,7,5123003,8 ,1 ,31,,6",
 "200100,2,2,1998/10/01 08:24:00,140.3050501853,36.0064648939,2,8,5510012,8 ,1 ,39,,6",
 "232300,2,2,1998/10/01 08:24:00,139.5102057921,35.3360284136,1,4,2411307,3 ,1 ,40,,2",
 "345000,2,4,1998/10/01 08:24:00,139.7395834163,35.7511435738,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:24:00,139.3442189636,35.4642582853,1,7,2811315,9 ,11,37,,8",
 "358800,2,2,1998/10/01 08:24:00,139.6830188013,35.8101577521,2,5,3023001,9 ,1 ,35,,2",
 "404800,2,3,1998/10/01 08:24:00,139.6114958712,35.724874227,2,9,3120009,8 ,1 ,30,,12",
 "361100,2,2,1998/10/01 08:24:00,139.6402829034,35.8304555978,1,6,3026023,9 ,1 ,32,,6",
 "418600,2,2,1998/10/01 08:24:00,139.3593332069,35.8522992425,1,11,3123503,8 ,1 ,23,,1",
 "374900,2,2,1998/10/01 08:24:00,139.6827995109,35.8099623468,1,4,3040108,4 ,1 ,71,,6",
 "379500,2,2,1998/10/01 08:24:00,139.6056736475,35.9258067177,2,6,3053202,8 ,1 ,31,,1",
 "425500,2,3,1998/10/01 08:24:00,139.5504739083,35.8384907437,1,4,3134008,8 ,1 ,45,,12",
 "425500,2,4,1998/10/01 08:24:00,139.5504739083,35.8384907437,1,4,3134008,8 ,1 ,45,,9",
 "437000,2,4,1998/10/01 08:24:00,139.7660418874,35.6639508291,1,7,3212001,5 ,1 ,36,,1",
 "446200,2,3,1998/10/01 08:24:00,139.7926067688,35.7754192933,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,3,1998/10/01 08:24:00,139.4528211233,35.9234873283,2,10,3411010,8 ,1 ,30,,12",
 "478400,2,2,1998/10/01 08:24:00,139.3941684802,36.0059946084,1,4,3413105,13,2 ,40,,1",
 "441600,4,4,1998/10/01 08:24:00,139.816167933,35.7205186036,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 08:24:00,139.7578590306,35.7446548539,1,10,3510101,8 ,1 ,27,,12",
 "462300,4,4,1998/10/01 08:24:00,139.7551264745,35.8866749443,1,8,3235004,9 ,11,38,,8",
 "522100,2,2,1998/10/01 08:24:00,139.2804134734,36.1816491076,1,3,3722104,13,2 ,35,,2",
 "563500,2,3,1998/10/01 08:24:00,139.7704211901,35.6720534184,1,7,0212001,10,1 ,84,,12",
 "572700,2,2,1998/10/01 08:24:00,139.7110221907,35.6684033262,2,2,0241003,12,2 ,27,,1",
 "627900,2,2,1998/10/01 08:24:00,139.6496778067,35.7478507948,2,7,0511204,9 ,1 ,34,,2",
 "611800,2,3,1998/10/01 08:24:00,139.6388381096,35.6295629971,1,4,0431110,13,2 ,65,,12",
 "646300,2,3,1998/10/01 08:24:00,139.7621729168,35.6685063555,1,4,0523008,8 ,1 ,75,,12",
 "644000,2,3,1998/10/01 08:24:00,139.7499053058,35.654767596,1,4,0522008,5 ,1 ,52,,12",
 "724500,2,2,1998/10/01 08:24:00,139.4943771762,35.6931991707,2,10,0761001,8 ,1 ,33,,7",
 "823400,2,3,1998/10/01 08:24:00,139.6025479354,35.4540207856,1,10,1213105,9 ,13,34,,12",
 "786600,2,3,1998/10/01 08:24:00,139.3250157633,35.6579347221,2,3,0930110,13,2 ,35,,12",
 "660100,2,3,1998/10/01 08:24:00,139.7986955652,35.7321814821,1,7,0612111,9 ,1 ,60,,12",
 "669300,2,3,1998/10/01 08:24:00,139.9219685293,35.8270774654,1,6,0620210,9 ,13,47,,12",
 "759000,2,3,1998/10/01 08:24:00,139.6980061096,35.6882036728,2,4,0821006,9 ,1 ,55,,12",
 "696900,2,3,1998/10/01 08:24:00,139.707861079,35.6507880265,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:24:00,139.5425056549,35.7021788149,1,7,0752005,6 ,10,34,,12",
 "683100,2,4,1998/10/01 08:24:00,139.7442175336,35.6928083062,2,3,0633106,12,2 ,49,,1",
 "692300,2,3,1998/10/01 08:24:00,139.7638821398,35.6890006142,2,4,0711108,8 ,1 ,25,,12",
 "20700,2,3,1998/10/01 08:25:00,140.0157064376,35.6779490414,1,8,4121303,9 ,1 ,44,,1",
 "830300,2,3,1998/10/01 08:25:00,139.6853600183,35.5856266599,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:25:00,140.0956615377,35.70367429,2,1,4410011,12,2 ,35,,9",
 "89700,5,5,1998/10/01 08:25:00,140.1843599731,35.8047971691,2,8,4422113,3 ,8 ,42,,7",
 "128800,2,2,1998/10/01 08:25:00,140.4004703674,35.8079619159,1,14,4831221,15,7 ,26,,1",
 "128800,2,3,1998/10/01 08:25:00,140.4004703674,35.8079619159,1,14,4831221,15,7 ,26,,10",
 "280600,5,5,1998/10/01 08:25:00,139.7549928142,35.7037196278,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:25:00,139.7287746143,35.5899487878,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,3,1998/10/01 08:25:00,139.7894756022,35.6964691772,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:25:00,139.6100330091,35.8097313166,1,6,3032201,3 ,11,34,,6",
 "16100,2,3,1998/10/01 08:25:00,139.8827161169,35.6365488535,2,4,4116209,8 ,1 ,48,,12",
 "34500,2,4,1998/10/01 08:25:00,140.0264439517,35.7491012272,1,4,4129003,8 ,1 ,36,,1",
 "34500,3,5,1998/10/01 08:25:00,140.0264439517,35.7491012272,1,4,4129003,8 ,97,36,,97",
 "41400,2,6,1998/10/01 08:25:00,139.9608019075,35.8636795066,2,5,4212212,8 ,1 ,44,,1",
 "41400,3,7,1998/10/01 08:25:00,139.9608019075,35.8636795066,2,5,4212212,8 ,97,44,,97",
 "48300,2,2,1998/10/01 08:25:00,139.9328363358,35.7626667896,2,4,4215402,4 ,1 ,44,,1",
 "593400,2,3,1998/10/01 08:25:00,139.8191634898,35.6835129589,1,6,0349009,9 ,1 ,58,,10",
 "657800,2,3,1998/10/01 08:25:00,139.7981190502,35.7563076497,2,11,0611113,9 ,1 ,46,,10",
 "657800,2,4,1998/10/01 08:25:00,139.7981190502,35.7563076497,2,11,0611113,9 ,1 ,46,,1",
 "62100,2,3,1998/10/01 08:25:00,139.9688551701,35.8594693728,1,7,4224202,9 ,1 ,37,,12",
 "78200,2,3,1998/10/01 08:25:00,139.8207546673,35.6697022094,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:25:00,139.8946214761,35.734491847,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:25:00,139.9547185763,35.8449574309,2,4,5024120,13,2 ,44,,12",
 "828000,2,3,1998/10/01 08:25:00,139.5050672934,35.5044150868,1,5,1252001,4 ,1 ,38,,10",
 "209300,2,3,1998/10/01 08:25:00,139.6103150255,35.4363713072,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:25:00,139.6746410511,35.2723180719,1,7,2220004,6 ,1 ,36,,12",
 "225400,2,3,1998/10/01 08:25:00,139.4906271329,35.3384389975,1,3,2313201,13,2 ,36,,12",
 "227700,2,3,1998/10/01 08:25:00,139.7379860299,35.622105613,1,9,2410203,8 ,10,16,,12",
 "303600,2,3,1998/10/01 08:25:00,139.6755776983,35.666888601,1,9,2923016,9 ,1 ,30,,12",
 "85100,2,2,1998/10/01 08:25:00,140.1403685159,35.7174702486,1,8,4417027,9 ,10,46,,6",
 "103500,4,4,1998/10/01 08:25:00,139.9413314256,35.3800076516,2,3,4610015,13,2 ,38,,2",
 "133400,2,2,1998/10/01 08:25:00,140.4526860608,35.5724825266,2,3,4911101,13,2 ,34,,7",
 "324300,2,3,1998/10/01 08:25:00,139.6140297721,35.6016028398,1,10,2110103,8 ,1 ,29,,12",
 "322000,2,3,1998/10/01 08:25:00,139.6678697224,35.6046857467,2,5,2032102,8 ,1 ,36,,12",
 "177100,2,2,1998/10/01 08:25:00,139.875016614,36.0565975343,2,3,5113112,13,2 ,35,,6",
 "181700,2,2,1998/10/01 08:25:00,140.0250199776,36.1303149999,2,7,5123003,8 ,1 ,31,,6",
 "200100,2,2,1998/10/01 08:25:00,140.3062722404,36.0074617228,2,8,5510012,8 ,1 ,39,,6",
 "200100,3,3,1998/10/01 08:25:00,140.3062722404,36.0074617228,2,8,5510012,8 ,97,39,,97",
 "232300,2,2,1998/10/01 08:25:00,139.511776594,35.3364249519,1,4,2411307,3 ,1 ,40,,2",
 "345000,2,4,1998/10/01 08:25:00,139.7421221235,35.749020673,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:25:00,139.3449147275,35.4650142816,1,7,2811315,9 ,11,37,,8",
 "358800,2,2,1998/10/01 08:25:00,139.6813794808,35.8100972462,2,5,3023001,9 ,1 ,35,,2",
 "404800,2,3,1998/10/01 08:25:00,139.6168225418,35.7245367212,2,9,3120009,8 ,1 ,30,,12",
 "361100,2,2,1998/10/01 08:25:00,139.639809782,35.8322961969,1,6,3026023,9 ,1 ,32,,6",
 "418600,2,2,1998/10/01 08:25:00,139.3593332328,35.8522988676,1,11,3123503,8 ,1 ,23,,1",
 "418600,2,3,1998/10/01 08:25:00,139.3593332328,35.8522988676,1,11,3123503,8 ,1 ,23,,10",
 "374900,2,2,1998/10/01 08:25:00,139.6833034619,35.8066671619,1,4,3040108,4 ,1 ,71,,6",
 "425500,2,4,1998/10/01 08:25:00,139.5513278475,35.8388662196,1,4,3134008,8 ,1 ,45,,9",
 "379500,2,2,1998/10/01 08:25:00,139.6055919879,35.9247763913,2,6,3053202,8 ,1 ,31,,1",
 "437000,2,4,1998/10/01 08:25:00,139.7656445131,35.6637564747,1,7,3212001,5 ,1 ,36,,1",
 "437000,3,5,1998/10/01 08:25:00,139.7656445131,35.6637564747,1,7,3212001,5 ,97,36,,97",
 "446200,2,3,1998/10/01 08:25:00,139.7955446675,35.7737869001,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,3,1998/10/01 08:25:00,139.4579016138,35.9224238845,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 08:25:00,139.8145794591,35.7190921446,1,9,3215009,9 ,11,30,,8",
 "478400,2,2,1998/10/01 08:25:00,139.39435815,36.005481923,1,4,3413105,13,2 ,40,,1",
 "483000,2,3,1998/10/01 08:25:00,139.7613990265,35.742499109,1,10,3510101,8 ,1 ,27,,12",
 "462300,4,4,1998/10/01 08:25:00,139.7551781463,35.8858720626,1,8,3235004,9 ,11,38,,8",
 "522100,2,2,1998/10/01 08:25:00,139.2793859326,36.1825487667,1,3,3722104,13,2 ,35,,2",
 "563500,2,3,1998/10/01 08:25:00,139.7735572826,35.6705479488,1,7,0212001,10,1 ,84,,12",
 "572700,2,2,1998/10/01 08:25:00,139.7107509751,35.6681490288,2,2,0241003,12,2 ,27,,1",
 "627900,2,2,1998/10/01 08:25:00,139.6505079727,35.7491146612,2,7,0511204,9 ,1 ,34,,2",
 "611800,2,3,1998/10/01 08:25:00,139.6339115431,35.6264650986,1,4,0431110,13,2 ,65,,12",
 "644000,2,3,1998/10/01 08:25:00,139.7493435072,35.6510833664,1,4,0522008,5 ,1 ,52,,12",
 "646300,2,3,1998/10/01 08:25:00,139.756357113,35.6680752621,1,4,0523008,8 ,1 ,75,,12",
 "660100,2,3,1998/10/01 08:25:00,139.7962326779,35.7306236896,1,7,0612111,9 ,1 ,60,,12",
 "759000,2,3,1998/10/01 08:25:00,139.7000515293,35.6899728245,2,4,0821006,9 ,1 ,55,,12",
 "724500,2,2,1998/10/01 08:25:00,139.4969740772,35.6923660463,2,10,0761001,8 ,1 ,33,,7",
 "823400,2,3,1998/10/01 08:25:00,139.6035568477,35.4536899344,1,10,1213105,9 ,13,34,,12",
 "669300,2,3,1998/10/01 08:25:00,139.9301480362,35.8329366002,1,6,0620210,9 ,13,47,,12",
 "692300,2,3,1998/10/01 08:25:00,139.7642664085,35.6857368103,2,4,0711108,8 ,1 ,25,,12",
 "692300,2,4,1998/10/01 08:25:00,139.7642664085,35.6857368103,2,4,0711108,8 ,1 ,25,,1",
 "683100,2,4,1998/10/01 08:25:00,139.7449557781,35.6927523523,2,3,0633106,12,2 ,49,,1",
 "772800,2,2,1998/10/01 08:25:00,139.3993855303,35.6189534642,2,9,0842111,4 ,1 ,38,,1",
 "786600,2,3,1998/10/01 08:25:00,139.3203281792,35.6591142862,2,3,0930110,13,2 ,35,,12",
 "696900,2,3,1998/10/01 08:25:00,139.7096568287,35.647516782,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:25:00,139.5461937669,35.7023705804,1,7,0752005,6 ,10,34,,12",
 "20700,2,3,1998/10/01 08:26:00,140.0126426451,35.6756067914,1,8,4121303,9 ,1 ,44,,1",
 "772800,2,2,1998/10/01 08:26:00,139.400314722,35.6200685267,2,9,0842111,4 ,1 ,38,,1",
 "830300,2,3,1998/10/01 08:26:00,139.6960811252,35.5887758508,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:26:00,140.0958674738,35.7039064797,2,1,4410011,12,2 ,35,,9",
 "89700,5,5,1998/10/01 08:26:00,140.1812365038,35.808151082,2,8,4422113,3 ,8 ,42,,7",
 "128800,2,3,1998/10/01 08:26:00,140.4004706948,35.8079621832,1,14,4831221,15,7 ,26,,10",
 "280600,5,5,1998/10/01 08:26:00,139.754137203,35.7064561007,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:26:00,139.7314543035,35.5940930565,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,3,1998/10/01 08:26:00,139.785121979,35.6953679164,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:26:00,139.6120110864,35.8083773453,1,6,3032201,3 ,11,34,,6",
 "356500,4,4,1998/10/01 08:26:00,139.7343000581,35.8474865257,2,6,3018202,8 ,13,22,,4",
 "16100,2,3,1998/10/01 08:26:00,139.8739802087,35.6396768268,2,4,4116209,8 ,1 ,48,,12",
 "593400,2,3,1998/10/01 08:26:00,139.8191633988,35.6835130458,1,6,0349009,9 ,1 ,58,,10",
 "48300,2,2,1998/10/01 08:26:00,139.9333601428,35.7641859212,2,4,4215402,4 ,1 ,44,,1",
 "62100,2,3,1998/10/01 08:26:00,139.9648137931,35.8543290855,1,7,4224202,9 ,1 ,37,,12",
 "657800,2,4,1998/10/01 08:26:00,139.8023466035,35.7580319732,2,11,0611113,9 ,1 ,46,,1",
 "78200,2,3,1998/10/01 08:26:00,139.8156720814,35.66948201,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:26:00,139.8881587027,35.7359698786,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:26:00,139.9494862423,35.8423567932,2,4,5024120,13,2 ,44,,12",
 "828000,2,3,1998/10/01 08:26:00,139.5050673518,35.5044152661,1,5,1252001,4 ,1 ,38,,10",
 "209300,2,3,1998/10/01 08:26:00,139.6174482775,35.4380022986,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:26:00,139.6715381206,35.2779089783,1,7,2220004,6 ,1 ,36,,12",
 "227700,2,3,1998/10/01 08:26:00,139.7381972449,35.6229995284,1,9,2410203,8 ,10,16,,12",
 "225400,2,3,1998/10/01 08:26:00,139.4870774193,35.3380002784,1,3,2313201,13,2 ,36,,12",
 "225400,2,4,1998/10/01 08:26:00,139.4870774193,35.3380002784,1,3,2313201,13,2 ,36,,1",
 "303600,2,3,1998/10/01 08:26:00,139.6798853983,35.6689866355,1,9,2923016,9 ,1 ,30,,12",
 "303600,2,4,1998/10/01 08:26:00,139.6798853983,35.6689866355,1,9,2923016,9 ,1 ,30,,1",
 "85100,2,2,1998/10/01 08:26:00,140.1351870769,35.7173369385,1,8,4417027,9 ,10,46,,6",
 "92000,2,2,1998/10/01 08:26:00,140.3142326788,35.8361315456,2,6,4431008,14,10,36,,7",
 "103500,4,4,1998/10/01 08:26:00,139.941576005,35.3789131565,2,3,4610015,13,2 ,38,,2",
 "133400,2,2,1998/10/01 08:26:00,140.4521785497,35.5766071286,2,3,4911101,13,2 ,34,,7",
 "322000,2,3,1998/10/01 08:26:00,139.669306149,35.6084348345,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,3,1998/10/01 08:26:00,139.6175954922,35.6034246497,1,10,2110103,8 ,1 ,29,,12",
 "177100,2,2,1998/10/01 08:26:00,139.8814589374,36.0522189562,2,3,5113112,13,2 ,35,,6",
 "181700,2,2,1998/10/01 08:26:00,140.027839648,36.1263075676,2,7,5123003,8 ,1 ,31,,6",
 "232300,2,2,1998/10/01 08:26:00,139.5132938317,35.3370827242,1,4,2411307,3 ,1 ,40,,2",
 "345000,2,4,1998/10/01 08:26:00,139.7452479876,35.7474929025,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:26:00,139.3463423006,35.4649789645,1,7,2811315,9 ,11,37,,8",
 "358800,2,2,1998/10/01 08:26:00,139.6797396805,35.8100458534,2,5,3023001,9 ,1 ,35,,2",
 "404800,2,3,1998/10/01 08:26:00,139.6221523656,35.724168734,2,9,3120009,8 ,1 ,30,,12",
 "361100,2,2,1998/10/01 08:26:00,139.6395625526,35.8341374602,1,6,3026023,9 ,1 ,32,,6",
 "418600,2,3,1998/10/01 08:26:00,139.3588593238,35.849640855,1,11,3123503,8 ,1 ,23,,10",
 "374900,2,2,1998/10/01 08:26:00,139.6839103059,35.803383391,1,4,3040108,4 ,1 ,71,,6",
 "425500,2,4,1998/10/01 08:26:00,139.5520770292,35.8389599871,1,4,3134008,8 ,1 ,45,,9",
 "379500,2,2,1998/10/01 08:26:00,139.6054886219,35.9237472621,2,6,3053202,8 ,1 ,31,,1",
 "446200,2,3,1998/10/01 08:26:00,139.7984930982,35.7721671545,2,5,3221102,8 ,1 ,37,,12",
 "478400,2,2,1998/10/01 08:26:00,139.3945330499,36.004965786,1,4,3413105,13,2 ,40,,1",
 "441600,4,4,1998/10/01 08:26:00,139.8129454458,35.717700719,1,9,3215009,9 ,11,30,,8",
 "473800,2,3,1998/10/01 08:26:00,139.46298197,35.9213602248,2,10,3411010,8 ,1 ,30,,12",
 "483000,2,3,1998/10/01 08:26:00,139.7641652646,35.7397357599,1,10,3510101,8 ,1 ,27,,12",
 "462300,4,4,1998/10/01 08:26:00,139.7551286534,35.8850757724,1,8,3235004,9 ,11,38,,8",
 "522100,2,2,1998/10/01 08:26:00,139.2778948738,36.1823612121,1,3,3722104,13,2 ,35,,2",
 "563500,2,3,1998/10/01 08:26:00,139.7769488127,35.6694434789,1,7,0212001,10,1 ,84,,12",
 "572700,2,2,1998/10/01 08:26:00,139.7103701995,35.6683422349,2,2,0241003,12,2 ,27,,1",
 "577300,2,2,1998/10/01 08:26:00,139.7766222438,35.7282302994,1,4,0311012,9 ,1 ,27,,8",
 "611800,2,3,1998/10/01 08:26:00,139.6312320498,35.6218932587,1,4,0431110,13,2 ,65,,12",
 "627900,2,2,1998/10/01 08:26:00,139.6513225725,35.7501776625,2,7,0511204,9 ,1 ,34,,2",
 "644000,2,4,1998/10/01 08:26:00,139.7484356892,35.6476850035,1,4,0522008,5 ,1 ,52,,1",
 "646300,2,3,1998/10/01 08:26:00,139.7500736282,35.6701825781,1,4,0523008,8 ,1 ,75,,12",
 "646300,2,4,1998/10/01 08:26:00,139.7500736282,35.6701825781,1,4,0523008,8 ,1 ,75,,1",
 "644000,2,3,1998/10/01 08:26:00,139.7484356892,35.6476850035,1,4,0522008,5 ,1 ,52,,12",
 "724500,2,2,1998/10/01 08:26:00,139.4981469132,35.6907626821,2,10,0761001,8 ,1 ,33,,7",
 "823400,2,3,1998/10/01 08:26:00,139.6045568478,35.4533410796,1,10,1213105,9 ,13,34,,12",
 "786600,2,3,1998/10/01 08:26:00,139.3157447093,35.6583217222,2,3,0930110,13,2 ,35,,12",
 "660100,2,3,1998/10/01 08:26:00,139.7930526005,35.7306426418,1,7,0612111,9 ,1 ,60,,12",
 "669300,2,3,1998/10/01 08:26:00,139.9396546075,35.8375942134,1,6,0620210,9 ,13,47,,12",
 "759000,2,3,1998/10/01 08:26:00,139.701531949,35.6856531439,2,4,0821006,9 ,1 ,55,,12",
 "719900,2,4,1998/10/01 08:26:00,139.549892051,35.7024512598,1,7,0752005,6 ,10,34,,12",
 "683100,2,4,1998/10/01 08:26:00,139.7453590121,35.6926002386,2,3,0633106,12,2 ,49,,1",
 "692300,2,4,1998/10/01 08:26:00,139.7646718549,35.6855099758,2,4,0711108,8 ,1 ,25,,1",
 "696900,2,3,1998/10/01 08:26:00,139.7114465444,35.6442428121,2,6,0720006,4 ,1 ,39,,12",
 "20700,2,3,1998/10/01 08:27:00,140.0101100158,35.6718195111,1,8,4121303,9 ,1 ,44,,1",
 "772800,2,2,1998/10/01 08:27:00,139.4008226688,35.6213415476,2,9,0842111,4 ,1 ,38,,1",
 "830300,2,3,1998/10/01 08:27:00,139.7068726263,35.5917654018,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:27:00,140.0960695534,35.7041409124,2,1,4410011,12,2 ,35,,9",
 "89700,5,5,1998/10/01 08:27:00,140.1806256466,35.8129544639,2,8,4422113,3 ,8 ,42,,7",
 "128800,2,3,1998/10/01 08:27:00,140.4004710222,35.8079624506,1,14,4831221,15,7 ,26,,10",
 "280600,5,5,1998/10/01 08:27:00,139.7532948578,35.7091951903,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:27:00,139.7338446001,35.5983338615,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,3,1998/10/01 08:27:00,139.7826054309,35.692628161,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:27:00,139.614012932,35.8070464841,1,6,3032201,3 ,11,34,,6",
 "356500,4,4,1998/10/01 08:27:00,139.7342948564,35.8505275796,2,6,3018202,8 ,13,22,,4",
 "16100,2,3,1998/10/01 08:27:00,139.8652366827,35.6427911472,2,4,4116209,8 ,1 ,48,,12",
 "593400,2,3,1998/10/01 08:27:00,139.8191633078,35.6835131327,1,6,0349009,9 ,1 ,58,,10",
 "48300,2,2,1998/10/01 08:27:00,139.9316112009,35.7654514712,2,4,4215402,4 ,1 ,44,,1",
 "48300,2,3,1998/10/01 08:27:00,139.9316112009,35.7654514712,2,4,4215402,4 ,1 ,44,,12",
 "657800,2,4,1998/10/01 08:27:00,139.8036787253,35.7624600641,2,11,0611113,9 ,1 ,46,,1",
 "62100,2,3,1998/10/01 08:27:00,139.9605419362,35.8493234369,1,7,4224202,9 ,1 ,37,,12",
 "78200,2,3,1998/10/01 08:27:00,139.8105877456,35.6692935933,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:27:00,139.8821136955,35.7335115658,1,9,4031206,8 ,1 ,38,,12",
 "828000,2,3,1998/10/01 08:27:00,139.5050674102,35.5044154454,1,5,1252001,4 ,1 ,38,,10",
 "828000,2,4,1998/10/01 08:27:00,139.5050674102,35.5044154454,1,5,1252001,4 ,1 ,38,,1",
 "172500,2,3,1998/10/01 08:27:00,139.9442177867,35.8398039967,2,4,5024120,13,2 ,44,,12",
 "209300,2,3,1998/10/01 08:27:00,139.6242310141,35.4404910953,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:27:00,139.6644030482,35.2802664562,1,7,2220004,6 ,1 ,36,,12",
 "225400,2,4,1998/10/01 08:27:00,139.4864830671,35.3380435853,1,3,2313201,13,2 ,36,,1",
 "227700,2,3,1998/10/01 08:27:00,139.7329031155,35.6201860186,1,9,2410203,8 ,10,16,,12",
 "303600,2,4,1998/10/01 08:27:00,139.681957481,35.6681686775,1,9,2923016,9 ,1 ,30,,1",
 "85100,2,2,1998/10/01 08:27:00,140.1300255673,35.7170183159,1,8,4417027,9 ,10,46,,6",
 "92000,2,2,1998/10/01 08:27:00,140.3201696252,35.8381150399,2,6,4431008,14,10,36,,7",
 "103500,4,4,1998/10/01 08:27:00,139.9421431885,35.3781379735,2,3,4610015,13,2 ,38,,2",
 "133400,2,2,1998/10/01 08:27:00,140.4504630494,35.5801602017,2,3,4911101,13,2 ,34,,7",
 "322000,2,3,1998/10/01 08:27:00,139.671344754,35.6120268582,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,3,1998/10/01 08:27:00,139.6210013089,35.6054146818,1,10,2110103,8 ,1 ,29,,12",
 "177100,2,2,1998/10/01 08:27:00,139.8873027958,36.0554958443,2,3,5113112,13,2 ,35,,6",
 "177100,3,3,1998/10/01 08:27:00,139.8873027958,36.0554958443,2,3,5113112,13,97,35,,97",
 "181700,2,2,1998/10/01 08:27:00,140.0314384796,36.1229524637,2,7,5123003,8 ,1 ,31,,6",
 "232300,2,2,1998/10/01 08:27:00,139.5146362054,35.3385540161,1,4,2411307,3 ,1 ,40,,2",
 "345000,2,4,1998/10/01 08:27:00,139.7483356238,35.7459148875,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:27:00,139.3479652188,35.4647190928,1,7,2811315,9 ,11,37,,8",
 "358800,2,2,1998/10/01 08:27:00,139.6790373968,35.8089045461,2,5,3023001,9 ,1 ,35,,2",
 "404800,2,3,1998/10/01 08:27:00,139.6274569262,35.7235564107,2,9,3120009,8 ,1 ,30,,12",
 "361100,2,2,1998/10/01 08:27:00,139.6395404527,35.835893417,1,6,3026023,9 ,1 ,32,,6",
 "374900,2,2,1998/10/01 08:27:00,139.6844609116,35.8001166499,1,4,3040108,4 ,1 ,71,,6",
 "418600,2,3,1998/10/01 08:27:00,139.356694533,35.8470506229,1,11,3123503,8 ,1 ,23,,10",
 "425500,2,4,1998/10/01 08:27:00,139.5528094237,35.839255165,1,4,3134008,8 ,1 ,45,,9",
 "379500,2,2,1998/10/01 08:27:00,139.6053714842,35.9227187761,2,6,3053202,8 ,1 ,31,,1",
 "446200,2,3,1998/10/01 08:27:00,139.8014373798,35.7705423972,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,3,1998/10/01 08:27:00,139.4680580885,35.9202836011,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 08:27:00,139.8111510803,35.7164483875,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 08:27:00,139.7656006353,35.736330938,1,10,3510101,8 ,1 ,27,,12",
 "478400,2,2,1998/10/01 08:27:00,139.3946820392,36.0044446335,1,4,3413105,13,2 ,40,,1",
 "462300,4,4,1998/10/01 08:27:00,139.7549206544,35.8842898102,1,8,3235004,9 ,11,38,,8",
 "522100,2,2,1998/10/01 08:27:00,139.2763754179,36.1823027291,1,3,3722104,13,2 ,35,,2",
 "563500,2,3,1998/10/01 08:27:00,139.7801774235,35.6681633897,1,7,0212001,10,1 ,84,,12",
 "572700,2,2,1998/10/01 08:27:00,139.7100130267,35.6684766252,2,2,0241003,12,2 ,27,,1",
 "577300,2,2,1998/10/01 08:27:00,139.7771300052,35.7264121672,1,4,0311012,9 ,1 ,27,,8",
 "611800,2,3,1998/10/01 08:27:00,139.6294875214,35.617007099,1,4,0431110,13,2 ,65,,12",
 "627900,2,2,1998/10/01 08:27:00,139.6505638689,35.7508506713,2,7,0511204,9 ,1 ,34,,2",
 "627900,3,3,1998/10/01 08:27:00,139.6505638689,35.7508506713,2,7,0511204,9 ,97,34,,97",
 "644000,2,4,1998/10/01 08:27:00,139.7485571518,35.6488832305,1,4,0522008,5 ,1 ,52,,1",
 "646300,2,4,1998/10/01 08:27:00,139.7502200178,35.6697821514,1,4,0523008,8 ,1 ,75,,1",
 "823400,2,3,1998/10/01 08:27:00,139.6055568393,35.4529922164,1,10,1213105,9 ,13,34,,12",
 "724500,2,2,1998/10/01 08:27:00,139.4997254687,35.6895780584,2,10,0761001,8 ,1 ,33,,7",
 "786600,2,3,1998/10/01 08:27:00,139.3116438148,35.65612496,2,3,0930110,13,2 ,35,,12",
 "669300,2,3,1998/10/01 08:27:00,139.9491910216,35.842212017,1,6,0620210,9 ,13,47,,12",
 "660100,2,3,1998/10/01 08:27:00,139.7907273557,35.7288842559,1,7,0612111,9 ,1 ,60,,12",
 "759000,2,3,1998/10/01 08:27:00,139.7039802936,35.6817985808,2,4,0821006,9 ,1 ,55,,12",
 "696900,2,3,1998/10/01 08:27:00,139.7132905523,35.6409886385,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:27:00,139.5535903434,35.7025318255,1,7,0752005,6 ,10,34,,12",
 "683100,2,4,1998/10/01 08:27:00,139.7461437614,35.6928272957,2,3,0633106,12,2 ,49,,1",
 "692300,2,4,1998/10/01 08:27:00,139.765077299,35.6852831399,2,4,0711108,8 ,1 ,25,,1",
 "20700,2,3,1998/10/01 08:28:00,140.0073406488,35.6681838793,1,8,4121303,9 ,1 ,44,,1",
 "772800,2,2,1998/10/01 08:28:00,139.4000750042,35.6225220653,2,9,0842111,4 ,1 ,38,,1",
 "830300,2,3,1998/10/01 08:28:00,139.7174092998,35.5952249101,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:28:00,140.0962902554,35.7043607774,2,1,4410011,12,2 ,35,,9",
 "89700,5,5,1998/10/01 08:28:00,140.177951389,35.8162055092,2,8,4422113,3 ,8 ,42,,7",
 "128800,2,3,1998/10/01 08:28:00,140.4004713497,35.8079627179,1,14,4831221,15,7 ,26,,10",
 "869400,2,3,1998/10/01 08:28:00,139.7345092849,35.6029866446,1,10,1231001,8 ,1 ,29,,12",
 "280600,5,5,1998/10/01 08:28:00,139.7525499949,35.7119529674,1,11,2910009,8 ,9 ,42,,8",
 "2300,2,3,1998/10/01 08:28:00,139.780135581,35.6943997636,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:28:00,139.6153920774,35.8053466273,1,6,3032201,3 ,11,34,,6",
 "356500,4,4,1998/10/01 08:28:00,139.7341967848,35.853568626,2,6,3018202,8 ,13,22,,4",
 "16100,2,3,1998/10/01 08:28:00,139.8564396909,35.6457521039,2,4,4116209,8 ,1 ,48,,12",
 "593400,2,3,1998/10/01 08:28:00,139.8191632168,35.6835132196,1,6,0349009,9 ,1 ,58,,10",
 "48300,2,3,1998/10/01 08:28:00,139.9272798441,35.7648491928,2,4,4215402,4 ,1 ,44,,12",
 "657800,2,4,1998/10/01 08:28:00,139.8012665953,35.7663247094,2,11,0611113,9 ,1 ,46,,1",
 "62100,2,3,1998/10/01 08:28:00,139.9550968565,35.8451680999,1,7,4224202,9 ,1 ,37,,12",
 "78200,2,3,1998/10/01 08:28:00,139.80550432,35.669446737,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:28:00,139.8768564171,35.7299083915,1,9,4031206,8 ,1 ,38,,12",
 "828000,2,4,1998/10/01 08:28:00,139.5082372931,35.5183956427,1,5,1252001,4 ,1 ,38,,1",
 "172500,2,3,1998/10/01 08:28:00,139.9389486682,35.8372523259,2,4,5024120,13,2 ,44,,12",
 "209300,2,3,1998/10/01 08:28:00,139.6265096204,35.4458516337,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:28:00,139.6566083223,35.2802262731,1,7,2220004,6 ,1 ,36,,12",
 "227700,2,3,1998/10/01 08:28:00,139.726570209,35.6222327566,1,9,2410203,8 ,10,16,,12",
 "225400,2,4,1998/10/01 08:28:00,139.4858887142,35.3380868893,1,3,2313201,13,2 ,36,,1",
 "303600,2,4,1998/10/01 08:28:00,139.6841070868,35.6676433979,1,9,2923016,9 ,1 ,30,,1",
 "85100,2,2,1998/10/01 08:28:00,140.1249606753,35.7161437269,1,8,4417027,9 ,10,46,,6",
 "92000,2,2,1998/10/01 08:28:00,140.3253488661,35.835273807,2,6,4431008,14,10,36,,7",
 "103500,4,4,1998/10/01 08:28:00,139.9436880232,35.3778838781,2,3,4610015,13,2 ,38,,2",
 "133400,2,2,1998/10/01 08:28:00,140.4490597213,35.5837660092,2,3,4911101,13,2 ,34,,7",
 "322000,2,3,1998/10/01 08:28:00,139.674088745,35.6152801078,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,3,1998/10/01 08:28:00,139.6235758034,35.6081239663,1,10,2110103,8 ,1 ,29,,12",
 "181700,2,2,1998/10/01 08:28:00,140.0372681045,36.1225112056,2,7,5123003,8 ,1 ,31,,6",
 "232300,2,2,1998/10/01 08:28:00,139.5159826058,35.3376492985,1,4,2411307,3 ,1 ,40,,2",
 "345000,2,4,1998/10/01 08:28:00,139.7513162107,35.7442091191,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:28:00,139.3495891572,35.4644707821,1,7,2811315,9 ,11,37,,8",
 "358800,2,2,1998/10/01 08:28:00,139.6784361109,35.807663809,2,5,3023001,9 ,1 ,35,,2",
 "358800,2,3,1998/10/01 08:28:00,139.6784361109,35.807663809,2,5,3023001,9 ,1 ,35,,12",
 "404800,2,3,1998/10/01 08:28:00,139.6327614329,35.7229440038,2,9,3120009,8 ,1 ,30,,12",
 "361100,2,2,1998/10/01 08:28:00,139.6408484104,35.8373346539,1,6,3026023,9 ,1 ,32,,6",
 "418600,2,3,1998/10/01 08:28:00,139.3558368,35.8439796344,1,11,3123503,8 ,1 ,23,,10",
 "374900,2,2,1998/10/01 08:28:00,139.6852815054,35.7968636608,1,4,3040108,4 ,1 ,71,,6",
 "425500,2,4,1998/10/01 08:28:00,139.5536407944,35.8388488139,1,4,3134008,8 ,1 ,45,,9",
 "379500,2,2,1998/10/01 08:28:00,139.6054489348,35.9218480253,2,6,3053202,8 ,1 ,31,,1",
 "446200,2,3,1998/10/01 08:28:00,139.8043815353,35.7689175534,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,3,1998/10/01 08:28:00,139.4722617604,35.9179124529,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 08:28:00,139.8093280633,35.7152230462,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 08:28:00,139.7669985483,35.7329146749,1,10,3510101,8 ,1 ,27,,12",
 "478400,2,2,1998/10/01 08:28:00,139.394875536,36.0039338862,1,4,3413105,13,2 ,40,,1",
 "462300,4,4,1998/10/01 08:28:00,139.7547126594,35.8835038476,1,8,3235004,9 ,11,38,,8",
 "522100,2,2,1998/10/01 08:28:00,139.2750636525,36.1829052217,1,3,3722104,13,2 ,35,,2",
 "563500,2,3,1998/10/01 08:28:00,139.782765089,35.6660695356,1,7,0212001,10,1 ,84,,12",
 "577300,2,2,1998/10/01 08:28:00,139.7779109763,35.7244212732,1,4,0311012,9 ,1 ,27,,8",
 "572700,2,2,1998/10/01 08:28:00,139.7097594796,35.6681748495,2,2,0241003,12,2 ,27,,1",
 "611800,2,3,1998/10/01 08:28:00,139.6273709073,35.6122946527,1,4,0431110,13,2 ,65,,12",
 "646300,2,4,1998/10/01 08:28:00,139.7503664059,35.6693817245,1,4,0523008,8 ,1 ,75,,1",
 "644000,2,4,1998/10/01 08:28:00,139.7472955375,35.6497701546,1,4,0522008,5 ,1 ,52,,1",
 "724500,2,2,1998/10/01 08:28:00,139.5025308969,35.6895192244,2,10,0761001,8 ,1 ,33,,7",
 "823400,2,3,1998/10/01 08:28:00,139.6066249992,35.4528651845,1,10,1213105,9 ,13,34,,12",
 "786600,2,3,1998/10/01 08:28:00,139.3075436161,35.6539274505,2,3,0930110,13,2 ,35,,12",
 "660100,2,3,1998/10/01 08:28:00,139.7890136746,35.7266876523,1,7,0612111,9 ,1 ,60,,12",
 "669300,2,3,1998/10/01 08:28:00,139.9583198885,35.8473017422,1,6,0620210,9 ,13,47,,12",
 "759000,2,3,1998/10/01 08:28:00,139.7093940605,35.6815692616,2,4,0821006,9 ,1 ,55,,12",
 "696900,2,3,1998/10/01 08:28:00,139.7146430833,35.6376032149,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:28:00,139.557288132,35.7026253372,1,7,0752005,6 ,10,34,,12",
 "683100,2,4,1998/10/01 08:28:00,139.7461217188,35.6922541443,2,3,0633106,12,2 ,49,,1",
 "692300,2,4,1998/10/01 08:28:00,139.7654827409,35.6850563026,2,4,0711108,8 ,1 ,25,,1",
 "20700,2,3,1998/10/01 08:29:00,140.0075702627,35.6649814832,1,8,4121303,9 ,1 ,44,,1",
 "772800,2,2,1998/10/01 08:29:00,139.4009672718,35.6233185095,2,9,0842111,4 ,1 ,38,,1",
 "830300,2,3,1998/10/01 08:29:00,139.7223612776,35.60343481,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:29:00,140.0965687957,35.7045354521,2,1,4410011,12,2 ,35,,9",
 "89700,5,5,1998/10/01 08:29:00,140.173837282,35.8195155423,2,8,4422113,3 ,8 ,42,,7",
 "128800,2,3,1998/10/01 08:29:00,140.4004716771,35.8079629852,1,14,4831221,15,7 ,26,,10",
 "869400,2,3,1998/10/01 08:29:00,139.7350919736,35.6076491977,1,10,1231001,8 ,1 ,29,,12",
 "280600,5,5,1998/10/01 08:29:00,139.7519778349,35.7147380319,1,11,2910009,8 ,9 ,42,,8",
 "2300,2,3,1998/10/01 08:29:00,139.7759860078,35.6955601119,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:29:00,139.6173345184,35.8039867877,1,6,3032201,3 ,11,34,,6",
 "356500,4,4,1998/10/01 08:29:00,139.7344067089,35.856604816,2,6,3018202,8 ,13,22,,4",
 "16100,2,3,1998/10/01 08:29:00,139.8469333913,35.6464075531,2,4,4116209,8 ,1 ,48,,12",
 "48300,2,3,1998/10/01 08:29:00,139.9229134175,35.7642428699,2,4,4215402,4 ,1 ,44,,12",
 "593400,2,3,1998/10/01 08:29:00,139.8191631258,35.6835133065,1,6,0349009,9 ,1 ,58,,10",
 "593400,2,4,1998/10/01 08:29:00,139.8191631258,35.6835133065,1,6,0349009,9 ,1 ,58,,1",
 "657800,2,4,1998/10/01 08:29:00,139.7977085548,35.7698387688,2,11,0611113,9 ,1 ,46,,1",
 "62100,2,3,1998/10/01 08:29:00,139.9487082092,35.8419782566,1,7,4224202,9 ,1 ,37,,12",
 "78200,2,3,1998/10/01 08:29:00,139.8005201104,35.6699983781,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:29:00,139.8716186089,35.726286644,1,9,4031206,8 ,1 ,38,,12",
 "828000,2,4,1998/10/01 08:29:00,139.51753316,35.5302499566,1,5,1252001,4 ,1 ,38,,1",
 "172500,2,3,1998/10/01 08:29:00,139.9337332565,35.8346295099,2,4,5024120,13,2 ,44,,12",
 "211600,2,4,1998/10/01 08:29:00,139.6496567257,35.2824668879,1,7,2220004,6 ,1 ,36,,12",
 "209300,2,3,1998/10/01 08:29:00,139.6218434653,35.4505598416,1,13,2211303,10,1 ,37,,12",
 "227700,2,3,1998/10/01 08:29:00,139.7221869124,35.6275882477,1,9,2410203,8 ,10,16,,12",
 "225400,2,4,1998/10/01 08:29:00,139.4852943608,35.3381301904,1,3,2313201,13,2 ,36,,1",
 "303600,2,4,1998/10/01 08:29:00,139.6855048387,35.6664756308,1,9,2923016,9 ,1 ,30,,1",
 "85100,2,2,1998/10/01 08:29:00,140.1198093408,35.7161548463,1,8,4417027,9 ,10,46,,6",
 "92000,2,2,1998/10/01 08:29:00,140.3312062562,35.833201984,2,6,4431008,14,10,36,,7",
 "103500,4,4,1998/10/01 08:29:00,139.9451553427,35.3774124064,2,3,4610015,13,2 ,38,,2",
 "133400,2,2,1998/10/01 08:29:00,140.4472111519,35.588179048,2,3,4911101,13,2 ,34,,7",
 "324300,2,3,1998/10/01 08:29:00,139.6259616241,35.6109510156,1,10,2110103,8 ,1 ,29,,12",
 "322000,2,3,1998/10/01 08:29:00,139.6770025835,35.6184444393,2,5,2032102,8 ,1 ,36,,12",
 "181700,2,2,1998/10/01 08:29:00,140.0426546367,36.1212713134,2,7,5123003,8 ,1 ,31,,6",
 "232300,2,2,1998/10/01 08:29:00,139.5176659551,35.3379495998,1,4,2411307,3 ,1 ,40,,2",
 "345000,2,4,1998/10/01 08:29:00,139.7544477706,35.7426855641,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:29:00,139.3512380667,35.4644602302,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:29:00,139.6818669668,35.8048388173,2,5,3023001,9 ,1 ,35,,12",
 "404800,2,3,1998/10/01 08:29:00,139.6381024578,35.7227010423,2,9,3120009,8 ,1 ,30,,12",
 "361100,2,2,1998/10/01 08:29:00,139.6416914642,35.8391022382,1,6,3026023,9 ,1 ,32,,6",
 "418600,2,3,1998/10/01 08:29:00,139.3563278546,35.8404067318,1,11,3123503,8 ,1 ,23,,10",
 "374900,2,2,1998/10/01 08:29:00,139.6855170281,35.7935665132,1,4,3040108,4 ,1 ,71,,6",
 "379500,2,2,1998/10/01 08:29:00,139.6060770908,35.9209525351,2,6,3053202,8 ,1 ,31,,1",
 "425500,2,4,1998/10/01 08:29:00,139.5543203493,35.8382888945,1,4,3134008,8 ,1 ,45,,9",
 "446200,2,3,1998/10/01 08:29:00,139.8073102746,35.7672744054,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,3,1998/10/01 08:29:00,139.4760460417,35.9149575308,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 08:29:00,139.8075025157,35.714000066,1,9,3215009,9 ,11,30,,8",
 "478400,2,2,1998/10/01 08:29:00,139.3951383991,36.0034430076,1,4,3413105,13,2 ,40,,1",
 "483000,2,3,1998/10/01 08:29:00,139.7690852933,35.7297471856,1,10,3510101,8 ,1 ,27,,12",
 "462300,4,4,1998/10/01 08:29:00,139.7545046685,35.8827178845,1,8,3235004,9 ,11,38,,8",
 "522100,2,2,1998/10/01 08:29:00,139.2737636882,36.1835463766,1,3,3722104,13,2 ,35,,2",
 "563500,2,3,1998/10/01 08:29:00,139.7852942677,35.6639117603,1,7,0212001,10,1 ,84,,12",
 "577300,2,2,1998/10/01 08:29:00,139.7791596515,35.7227735341,1,4,0311012,9 ,1 ,27,,8",
 "572700,2,2,1998/10/01 08:29:00,139.7095130405,35.667914616,2,2,0241003,12,2 ,27,,1",
 "611800,2,3,1998/10/01 08:29:00,139.6236761571,35.6082146578,1,4,0431110,13,2 ,65,,12",
 "644000,2,4,1998/10/01 08:29:00,139.7458034579,35.6502166983,1,4,0522008,5 ,1 ,52,,1",
 "646300,2,4,1998/10/01 08:29:00,139.7505127926,35.6689812975,1,4,0523008,8 ,1 ,75,,1",
 "724500,2,2,1998/10/01 08:29:00,139.5053370406,35.6895013195,2,10,0761001,8 ,1 ,33,,7",
 "823400,2,3,1998/10/01 08:29:00,139.6076861305,35.4530263765,1,10,1213105,9 ,13,34,,12",
 "786600,2,3,1998/10/01 08:29:00,139.3034436429,35.6517298025,2,3,0930110,13,2 ,35,,12",
 "660100,2,3,1998/10/01 08:29:00,139.7874053694,35.7244311566,1,7,0612111,9 ,1 ,60,,12",
 "669300,2,3,1998/10/01 08:29:00,139.9649574942,35.8545118749,1,6,0620210,9 ,13,47,,12",
 "759000,2,3,1998/10/01 08:29:00,139.7148140404,35.680782455,2,4,0821006,9 ,1 ,55,,12",
 "719900,2,4,1998/10/01 08:29:00,139.5609742346,35.702829356,1,7,0752005,6 ,10,34,,12",
 "683100,2,4,1998/10/01 08:29:00,139.746001698,35.6915819905,2,3,0633106,12,2 ,49,,1",
 "692300,2,4,1998/10/01 08:29:00,139.7659109672,35.6848623104,2,4,0711108,8 ,1 ,25,,1",
 "696900,2,3,1998/10/01 08:29:00,139.7155342529,35.6340922045,2,6,0720006,4 ,1 ,39,,12",
 "20700,2,3,1998/10/01 08:30:00,140.0066973445,35.6613227552,1,8,4121303,9 ,1 ,44,,1",
 "20700,3,4,1998/10/01 08:30:00,140.0066973445,35.6613227552,1,8,4121303,9 ,97,44,,97",
 "772800,2,2,1998/10/01 08:30:00,139.3995597157,35.6237471275,2,9,0842111,4 ,1 ,38,,1",
 "830300,2,3,1998/10/01 08:30:00,139.7273978084,35.611738557,2,4,1251203,8 ,1 ,37,,12",
 "73600,2,3,1998/10/01 08:30:00,140.0968473372,35.7047101262,2,1,4410011,12,2 ,35,,9",
 "73600,3,4,1998/10/01 08:30:00,140.0968473372,35.7047101262,2,1,4410011,12,97,35,,97",
 "89700,5,5,1998/10/01 08:30:00,140.1696892785,35.8229684043,2,8,4422113,3 ,8 ,42,,7",
 "128800,2,3,1998/10/01 08:30:00,140.4004720045,35.8079632526,1,14,4831221,15,7 ,26,,10",
 "869400,2,3,1998/10/01 08:30:00,139.7356746514,35.6123117509,1,10,1231001,8 ,1 ,29,,12",
 "280600,5,5,1998/10/01 08:30:00,139.7514656979,35.7175314487,1,11,2910009,8 ,9 ,42,,8",
 "356500,4,4,1998/10/01 08:30:00,139.7366533978,35.8576425466,2,6,3018202,8 ,13,22,,4",
 "356500,5,5,1998/10/01 08:30:00,139.7366533978,35.8576425466,2,6,3018202,8 ,97,22,,97",
 "2300,2,3,1998/10/01 08:30:00,139.7714071459,35.6957454726,1,6,4111102,8 ,1 ,36,,12",
 "363400,4,5,1998/10/01 08:30:00,139.6194832882,35.8028200593,1,6,3032201,3 ,11,34,,6",
 "16100,2,3,1998/10/01 08:30:00,139.837418516,35.646953344,2,4,4116209,8 ,1 ,48,,12",
 "48300,2,3,1998/10/01 08:30:00,139.9185543923,35.7636026513,2,4,4215402,4 ,1 ,44,,12",
 "593400,2,4,1998/10/01 08:30:00,139.7927762121,35.7087156591,1,6,0349009,9 ,1 ,58,,1",
 "593400,3,5,1998/10/01 08:30:00,139.7927762121,35.7087156591,1,6,0349009,9 ,97,58,,97",
 "62100,2,3,1998/10/01 08:30:00,139.9422659486,35.8388588282,1,7,4224202,9 ,1 ,37,,12",
 "657800,2,4,1998/10/01 08:30:00,139.7951938929,35.7728141167,2,11,0611113,9 ,1 ,46,,1",
 "657800,3,5,1998/10/01 08:30:00,139.7951938929,35.7728141167,2,11,0611113,9 ,97,46,,97",
 "78200,2,3,1998/10/01 08:30:00,139.7959973934,35.6719035727,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:30:00,139.8665506907,35.7225231582,1,9,4031206,8 ,1 ,38,,12",
 "828000,2,4,1998/10/01 08:30:00,139.518495292,35.5456432824,1,5,1252001,4 ,1 ,38,,1",
 "828000,3,5,1998/10/01 08:30:00,139.518495292,35.5456432824,1,5,1252001,4 ,97,38,,97",
 "172500,2,3,1998/10/01 08:30:00,139.9284505064,35.832097899,2,4,5024120,13,2 ,44,,12",
 "209300,2,3,1998/10/01 08:30:00,139.6196052973,35.4563754118,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:30:00,139.6436559568,35.2865253873,1,7,2220004,6 ,1 ,36,,12",
 "227700,2,3,1998/10/01 08:30:00,139.716251685,35.6316063405,1,9,2410203,8 ,10,16,,12",
 "225400,2,4,1998/10/01 08:30:00,139.4847000067,35.3381734885,1,3,2313201,13,2 ,36,,1",
 "85100,2,2,1998/10/01 08:30:00,140.1147042947,35.7159983952,1,8,4417027,9 ,10,46,,6",
 "303600,2,4,1998/10/01 08:30:00,139.6871654656,35.6656110661,1,9,2923016,9 ,1 ,30,,1",
 "303600,3,5,1998/10/01 08:30:00,139.6871654656,35.6656110661,1,9,2923016,9 ,97,30,,97",
 "92000,2,2,1998/10/01 08:30:00,140.336843275,35.8323002845,2,6,4431008,14,10,36,,7",
 "103500,4,4,1998/10/01 08:30:00,139.9447462699,35.3775454054,2,3,4610015,13,2 ,38,,2",
 "133400,2,2,1998/10/01 08:30:00,140.446464932,35.5930689151,2,3,4911101,13,2 ,34,,7",
 "322000,2,3,1998/10/01 08:30:00,139.6799125619,35.6216111794,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,3,1998/10/01 08:30:00,139.6283673874,35.6136914195,1,10,2110103,8 ,1 ,29,,12",
 "181700,2,2,1998/10/01 08:30:00,140.0475232118,36.1200070793,2,7,5123003,8 ,1 ,31,,6",
 "232300,2,2,1998/10/01 08:30:00,139.5188478574,35.3388658094,1,4,2411307,3 ,1 ,40,,2",
 "232300,3,3,1998/10/01 08:30:00,139.5188478574,35.3388658094,1,4,2411307,3 ,97,40,,97",
 "345000,2,4,1998/10/01 08:30:00,139.7573333147,35.7408684385,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:30:00,139.3525929818,35.4645031631,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:30:00,139.6847441667,35.8016654845,2,5,3023001,9 ,1 ,35,,12",
 "404800,2,3,1998/10/01 08:30:00,139.6434549583,35.7225895724,2,9,3120009,8 ,1 ,30,,12",
 "361100,2,2,1998/10/01 08:30:00,139.642189599,35.8409504151,1,6,3026023,9 ,1 ,32,,6",
 "374900,2,2,1998/10/01 08:30:00,139.6850940513,35.7902639787,1,4,3040108,4 ,1 ,71,,6",
 "418600,2,3,1998/10/01 08:30:00,139.3575235687,35.8370932495,1,11,3123503,8 ,1 ,23,,10",
 "425500,2,4,1998/10/01 08:30:00,139.5550615381,35.8377850706,1,4,3134008,8 ,1 ,45,,9",
 "379500,2,2,1998/10/01 08:30:00,139.6071522539,35.9204147015,2,6,3053202,8 ,1 ,31,,1",
 "446200,2,3,1998/10/01 08:30:00,139.8102231357,35.7656130006,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,3,1998/10/01 08:30:00,139.4793061826,35.911632434,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 08:30:00,139.8056989686,35.7127568218,1,9,3215009,9 ,11,30,,8",
 "478400,2,2,1998/10/01 08:30:00,139.3953671618,36.00294243,1,4,3413105,13,2 ,40,,1",
 "483000,2,3,1998/10/01 08:30:00,139.7719884609,35.7270389816,1,10,3510101,8 ,1 ,27,,12",
 "462300,4,4,1998/10/01 08:30:00,139.7542979054,35.8819319437,1,8,3235004,9 ,11,38,,8",
 "522100,2,2,1998/10/01 08:30:00,139.272458408,36.1841804024,1,3,3722104,13,2 ,35,,2",
 "563500,2,3,1998/10/01 08:30:00,139.78783729,35.6617625479,1,7,0212001,10,1 ,84,,12",
 "577300,2,2,1998/10/01 08:30:00,139.781022334,35.7209210327,1,4,0311012,9 ,1 ,27,,8",
 "572700,2,2,1998/10/01 08:30:00,139.7093704551,35.6682615137,2,2,0241003,12,2 ,27,,1",
 "572700,3,3,1998/10/01 08:30:00,139.7093704551,35.6682615137,2,2,0241003,12,97,27,,97",
 "611800,2,3,1998/10/01 08:30:00,139.6195731496,35.6044227822,1,4,0431110,13,2 ,65,,12",
 "644000,2,4,1998/10/01 08:30:00,139.7443787088,35.6503726671,1,4,0522008,5 ,1 ,52,,1",
 "644000,3,5,1998/10/01 08:30:00,139.7443787088,35.6503726671,1,4,0522008,5 ,97,52,,97",
 "646300,2,4,1998/10/01 08:30:00,139.7506591778,35.6685808702,1,4,0523008,8 ,1 ,75,,1",
 "646300,3,5,1998/10/01 08:30:00,139.7506591778,35.6685808702,1,4,0523008,8 ,97,75,,97",
 "724500,2,2,1998/10/01 08:30:00,139.5081408458,35.689403792,2,10,0761001,8 ,1 ,33,,7",
 "660100,2,3,1998/10/01 08:30:00,139.7857355149,35.7222045373,1,7,0612111,9 ,1 ,60,,12",
 "669300,2,3,1998/10/01 08:30:00,139.9709087198,35.8621074522,1,6,0620210,9 ,13,47,,12",
 "669300,2,4,1998/10/01 08:30:00,139.9709087198,35.8621074522,1,6,0620210,9 ,13,47,,1",
 "786600,2,3,1998/10/01 08:30:00,139.2993438955,35.6495320161,2,3,0930110,13,2 ,35,,12",
 "683100,2,4,1998/10/01 08:30:00,139.7457241633,35.6909446407,2,3,0633106,12,2 ,49,,1",
 "683100,3,5,1998/10/01 08:30:00,139.7457241633,35.6909446407,2,3,0633106,12,97,49,,97",
 "759000,2,3,1998/10/01 08:30:00,139.7202538847,35.6800864733,2,4,0821006,9 ,1 ,55,,12",
 "823400,2,3,1998/10/01 08:30:00,139.6085669022,35.4535423182,1,10,1213105,9 ,13,34,,12",
 "823400,2,4,1998/10/01 08:30:00,139.6085669022,35.4535423182,1,10,1213105,9 ,13,34,,10",
 "696900,2,3,1998/10/01 08:30:00,139.7168180586,35.6306917715,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:30:00,139.5646734024,35.7028770611,1,7,0752005,6 ,10,34,,12",
 "692300,2,4,1998/10/01 08:30:00,139.7663580452,35.6846954938,2,4,0711108,8 ,1 ,25,,1",
 "692300,3,5,1998/10/01 08:30:00,139.7663580452,35.6846954938,2,4,0711108,8 ,97,25,,97",
 "830300,2,3,1998/10/01 08:31:00,139.7354215952,35.6175438105,2,4,1251203,8 ,1 ,37,,12",
 "89700,5,5,1998/10/01 08:31:00,140.165846311,35.826053286,2,8,4422113,3 ,8 ,42,,7",
 "128800,2,3,1998/10/01 08:31:00,140.4004723319,35.8079635199,1,14,4831221,15,7 ,26,,10",
 "280600,5,5,1998/10/01 08:31:00,139.7517952081,35.7202675611,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:31:00,139.7366749407,35.6169232675,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,3,1998/10/01 08:31:00,139.7671270964,35.6952358717,1,6,4111102,8 ,1 ,36,,12",
 "2300,2,4,1998/10/01 08:31:00,139.7671270964,35.6952358717,1,6,4111102,8 ,1 ,36,,1",
 "363400,4,5,1998/10/01 08:31:00,139.6217463771,35.8018195615,1,6,3032201,3 ,11,34,,6",
 "16100,2,3,1998/10/01 08:31:00,139.8279238613,35.6462122169,2,4,4116209,8 ,1 ,48,,12",
 "48300,2,3,1998/10/01 08:31:00,139.9142107578,35.7628966538,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:31:00,139.9358538096,35.8356998595,1,7,4224202,9 ,1 ,37,,12",
 "78200,2,3,1998/10/01 08:31:00,139.7915298522,35.6738873731,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:31:00,139.8613784512,35.7188433722,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:31:00,139.9235976003,35.8290983636,2,4,5024120,13,2 ,44,,12",
 "193200,2,2,1998/10/01 08:31:00,140.0787743952,35.8889292846,2,5,5412112,9 ,1 ,35,,1",
 "211600,2,4,1998/10/01 08:31:00,139.6359835106,35.286540272,1,7,2220004,6 ,1 ,36,,12",
 "209300,2,3,1998/10/01 08:31:00,139.6208214491,35.4617646329,1,13,2211303,10,1 ,37,,12",
 "4600,2,2,1998/10/01 08:31:00,139.929207306,35.7554538429,1,11,4112301,10,1 ,37,,6",
 "227700,2,3,1998/10/01 08:31:00,139.7145626656,35.6379200273,1,9,2410203,8 ,10,16,,12",
 "225400,2,4,1998/10/01 08:31:00,139.4841056521,35.3382167837,1,3,2313201,13,2 ,36,,1",
 "66700,2,2,1998/10/01 08:31:00,139.9490735485,35.8478016813,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:31:00,140.1098451697,35.7172544702,1,8,4417027,9 ,10,46,,6",
 "92000,2,2,1998/10/01 08:31:00,140.3382682363,35.8295487853,2,6,4431008,14,10,36,,7",
 "98900,2,2,1998/10/01 08:31:00,140.0622957594,35.4699537823,2,11,4511022,8 ,1 ,32,,6",
 "103500,4,4,1998/10/01 08:31:00,139.9458388682,35.3772914068,2,3,4610015,13,2 ,38,,2",
 "133400,2,2,1998/10/01 08:31:00,140.4463687602,35.5980136444,2,3,4911101,13,2 ,34,,7",
 "324300,2,3,1998/10/01 08:31:00,139.6294861361,35.6170029987,1,10,2110103,8 ,1 ,29,,12",
 "322000,2,3,1998/10/01 08:31:00,139.6827653526,35.6248123358,2,5,2032102,8 ,1 ,36,,12",
 "181700,2,2,1998/10/01 08:31:00,140.0533597187,36.1195248769,2,7,5123003,8 ,1 ,31,,6",
 "345000,2,4,1998/10/01 08:31:00,139.7599135098,35.7387806906,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:31:00,139.3542414368,35.464397212,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:31:00,139.6860178047,35.7978153862,2,5,3023001,9 ,1 ,35,,12",
 "312800,2,2,1998/10/01 08:31:00,139.7003951473,35.5068454332,2,6,2015203,14,5 ,34,,1",
 "404800,2,3,1998/10/01 08:31:00,139.6485279347,35.7212373198,2,9,3120009,8 ,1 ,30,,12",
 "361100,2,2,1998/10/01 08:31:00,139.6429788054,35.8427169424,1,6,3026023,9 ,1 ,32,,6",
 "374900,2,2,1998/10/01 08:31:00,139.6852215434,35.7869902844,1,4,3040108,4 ,1 ,71,,6",
 "418600,2,3,1998/10/01 08:31:00,139.3602401891,35.8376253381,1,11,3123503,8 ,1 ,23,,10",
 "418600,2,4,1998/10/01 08:31:00,139.3602401891,35.8376253381,1,11,3123503,8 ,1 ,23,,12",
 "379500,2,2,1998/10/01 08:31:00,139.6081737403,35.9198147113,2,6,3053202,8 ,1 ,31,,1",
 "425500,2,4,1998/10/01 08:31:00,139.5558631659,35.8373396704,1,4,3134008,8 ,1 ,45,,9",
 "381800,2,2,1998/10/01 08:31:00,139.6004707226,35.8957401485,1,15,3055105,10,1 ,32,,6",
 "446200,2,3,1998/10/01 08:31:00,139.8128420794,35.7636892201,2,5,3221102,8 ,1 ,37,,12",
 "430100,6,6,1998/10/01 08:31:00,139.3906965709,35.8631176584,1,14,3135204,15,7 ,33,,6",
 "441600,4,4,1998/10/01 08:31:00,139.8046862105,35.7110360233,1,9,3215009,9 ,11,30,,8",
 "473800,2,3,1998/10/01 08:31:00,139.4823683086,35.9081680184,2,10,3411010,8 ,1 ,30,,12",
 "478400,2,2,1998/10/01 08:31:00,139.3955606023,36.0024713097,1,4,3413105,13,2 ,40,,1",
 "483000,2,3,1998/10/01 08:31:00,139.774970883,35.7243832012,1,10,3510101,8 ,1 ,27,,12",
 "455400,2,2,1998/10/01 08:31:00,139.818386931,35.9253913167,1,12,3226003,3 ,11,31,,8",
 "462300,4,4,1998/10/01 08:31:00,139.7545497557,35.881154525,1,8,3235004,9 ,11,38,,8",
 "522100,2,2,1998/10/01 08:31:00,139.2713736291,36.1841072064,1,3,3722104,13,2 ,35,,2",
 "563500,2,3,1998/10/01 08:31:00,139.7908237794,35.6600327624,1,7,0212001,10,1 ,84,,12",
 "577300,2,2,1998/10/01 08:31:00,139.7837141819,35.7199955349,1,4,0311012,9 ,1 ,27,,8",
 "611800,2,3,1998/10/01 08:31:00,139.6142815915,35.6017269615,1,4,0431110,13,2 ,65,,12",
 "667000,2,2,1998/10/01 08:31:00,139.7765607424,35.7805112228,2,12,0614310,6 ,1 ,43,,2",
 "724500,2,2,1998/10/01 08:31:00,139.5108981297,35.6889887165,2,10,0761001,8 ,1 ,33,,7",
 "823400,2,4,1998/10/01 08:31:00,139.6081007048,35.4536167435,1,10,1213105,9 ,13,34,,10",
 "786600,2,3,1998/10/01 08:31:00,139.2952443736,35.6473340913,2,3,0930110,13,2 ,35,,12",
 "669300,2,4,1998/10/01 08:31:00,139.9720648062,35.8626714595,1,6,0620210,9 ,13,47,,1",
 "660100,2,3,1998/10/01 08:31:00,139.7840274496,35.7199975129,1,7,0612111,9 ,1 ,60,,12",
 "772800,2,2,1998/10/01 08:31:00,139.3998220838,35.6231466385,2,9,0842111,4 ,1 ,38,,1",
 "759000,2,3,1998/10/01 08:31:00,139.7254370085,35.6810286882,2,4,0821006,9 ,1 ,55,,12",
 "696900,2,3,1998/10/01 08:31:00,139.7202869628,35.6285918693,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:31:00,139.5683725753,35.7029246526,1,7,0752005,6 ,10,34,,12",
 "772800,2,2,1998/10/01 08:32:00,139.4002995798,35.6244569053,2,9,0842111,4 ,1 ,38,,1",
 "772800,2,3,1998/10/01 08:32:00,139.4002995798,35.6244569053,2,9,0842111,4 ,1 ,38,,12",
 "830300,2,3,1998/10/01 08:32:00,139.7387763307,35.6261960594,2,4,1251203,8 ,1 ,37,,12",
 "89700,5,5,1998/10/01 08:32:00,140.1659454354,35.8308115884,2,8,4422113,3 ,8 ,42,,7",
 "128800,2,3,1998/10/01 08:32:00,140.4004726594,35.8079637872,1,14,4831221,15,7 ,26,,10",
 "869400,2,3,1998/10/01 08:32:00,139.737829769,35.6215135776,1,10,1231001,8 ,1 ,29,,12",
 "280600,5,5,1998/10/01 08:32:00,139.7528903849,35.7229403016,1,11,2910009,8 ,9 ,42,,8",
 "2300,2,4,1998/10/01 08:32:00,139.7673675207,35.6949769089,1,6,4111102,8 ,1 ,36,,1",
 "363400,4,5,1998/10/01 08:32:00,139.6237976058,35.8005414192,1,6,3032201,3 ,11,34,,6",
 "16100,2,3,1998/10/01 08:32:00,139.8200659438,35.6487061834,2,4,4116209,8 ,1 ,48,,12",
 "48300,2,3,1998/10/01 08:32:00,139.9100013538,35.7618160263,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:32:00,139.9294327631,35.8325534882,1,7,4224202,9 ,1 ,37,,12",
 "78200,2,3,1998/10/01 08:32:00,139.7875197547,35.6762845476,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:32:00,139.8557939579,35.7156011697,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:32:00,139.9209038771,35.8246675092,2,4,5024120,13,2 ,44,,12",
 "193200,2,2,1998/10/01 08:32:00,140.0787741424,35.8889293543,2,5,5412112,9 ,1 ,35,,1",
 "209300,2,3,1998/10/01 08:32:00,139.6239187247,35.4672818663,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:32:00,139.6297795662,35.2902589304,1,7,2220004,6 ,1 ,36,,12",
 "4600,2,2,1998/10/01 08:32:00,139.9304635779,35.7537409375,1,11,4112301,10,1 ,37,,6",
 "227700,2,3,1998/10/01 08:32:00,139.7116374433,35.6438939675,1,9,2410203,8 ,10,16,,12",
 "225400,2,4,1998/10/01 08:32:00,139.4835203831,35.3381693345,1,3,2313201,13,2 ,36,,1",
 "66700,2,2,1998/10/01 08:32:00,139.9489644636,35.8479819384,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:32:00,140.1051741238,35.7190885813,1,8,4417027,9 ,10,46,,6",
 "92000,2,2,1998/10/01 08:32:00,140.344867653,35.8295883059,2,6,4431008,14,10,36,,7",
 "98900,2,2,1998/10/01 08:32:00,140.0636376656,35.4714102357,2,11,4511022,8 ,1 ,32,,6",
 "103500,4,4,1998/10/01 08:32:00,139.9468627663,35.3766574498,2,3,4610015,13,2 ,38,,2",
 "133400,2,2,1998/10/01 08:32:00,140.4479504686,35.6023397042,2,3,4911101,13,2 ,34,,7",
 "322000,2,3,1998/10/01 08:32:00,139.6849776248,35.6283273445,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,3,1998/10/01 08:32:00,139.6306831538,35.6202961587,1,10,2110103,8 ,1 ,29,,12",
 "181700,2,2,1998/10/01 08:32:00,140.0590390698,36.118475255,2,7,5123003,8 ,1 ,31,,6",
 "345000,2,4,1998/10/01 08:32:00,139.7603715864,35.7383569637,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:32:00,139.3555493131,35.4647899584,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:32:00,139.6874401032,35.7940110836,2,5,3023001,9 ,1 ,35,,12",
 "312800,2,2,1998/10/01 08:32:00,139.7011471266,35.5069153441,2,6,2015203,14,5 ,34,,1",
 "404800,2,3,1998/10/01 08:32:00,139.6534868583,35.7195844052,2,9,3120009,8 ,1 ,30,,12",
 "361100,2,2,1998/10/01 08:32:00,139.6430590928,35.8442925874,1,6,3026023,9 ,1 ,32,,6",
 "418600,2,4,1998/10/01 08:32:00,139.3658126189,35.837964691,1,11,3123503,8 ,1 ,23,,12",
 "374900,2,2,1998/10/01 08:32:00,139.687009013,35.7840359443,1,4,3040108,4 ,1 ,71,,6",
 "425500,2,4,1998/10/01 08:32:00,139.5566573094,35.8368857136,1,4,3134008,8 ,1 ,45,,9",
 "379500,2,2,1998/10/01 08:32:00,139.6089968202,35.9192136696,2,6,3053202,8 ,1 ,31,,1",
 "381800,2,2,1998/10/01 08:32:00,139.5994291084,35.8979518456,1,15,3055105,10,1 ,32,,6",
 "446200,2,3,1998/10/01 08:32:00,139.8135523376,35.760892796,2,5,3221102,8 ,1 ,37,,12",
 "430100,6,6,1998/10/01 08:32:00,139.391987845,35.8614726473,1,14,3135204,15,7 ,33,,6",
 "473800,2,3,1998/10/01 08:32:00,139.4850864244,35.9047971679,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 08:32:00,139.8031822149,35.7100404771,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 08:32:00,139.7778482156,35.7216500447,1,10,3510101,8 ,1 ,27,,12",
 "478400,2,2,1998/10/01 08:32:00,139.3962174505,36.0025129536,1,4,3413105,13,2 ,40,,1",
 "455400,2,2,1998/10/01 08:32:00,139.8190290291,35.9241138516,1,12,3226003,3 ,11,31,,8",
 "462300,4,4,1998/10/01 08:32:00,139.7548016009,35.8803771057,1,8,3235004,9 ,11,38,,8",
 "522100,2,2,1998/10/01 08:32:00,139.2701997633,36.1842849879,1,3,3722104,13,2 ,35,,2",
 "563500,2,3,1998/10/01 08:32:00,139.793297583,35.6578399749,1,7,0212001,10,1 ,84,,12",
 "577300,2,2,1998/10/01 08:32:00,139.7863945306,35.7189965,1,4,0311012,9 ,1 ,27,,8",
 "611800,2,3,1998/10/01 08:32:00,139.6092222145,35.5987909629,1,4,0431110,13,2 ,65,,12",
 "667000,2,2,1998/10/01 08:32:00,139.7769601668,35.7804515034,2,12,0614310,6 ,1 ,43,,2",
 "724500,2,2,1998/10/01 08:32:00,139.5136383587,35.6884947005,2,10,0761001,8 ,1 ,33,,7",
 "823400,2,4,1998/10/01 08:32:00,139.6076345065,35.453691167,1,10,1213105,9 ,13,34,,10",
 "786600,2,3,1998/10/01 08:32:00,139.2909896699,35.6453595005,2,3,0930110,13,2 ,35,,12",
 "660100,2,3,1998/10/01 08:32:00,139.782293113,35.7178039646,1,7,0612111,9 ,1 ,60,,12",
 "669300,2,4,1998/10/01 08:32:00,139.97337284,35.8626397071,1,6,0620210,9 ,13,47,,1",
 "759000,2,3,1998/10/01 08:32:00,139.7295771106,35.6838823136,2,4,0821006,9 ,1 ,55,,12",
 "696900,2,3,1998/10/01 08:32:00,139.7235386696,35.626263749,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:32:00,139.572071715,35.7029740169,1,7,0752005,6 ,10,34,,12",
 "772800,2,3,1998/10/01 08:33:00,139.4061714827,35.6263206604,2,9,0842111,4 ,1 ,38,,12",
 "830300,2,3,1998/10/01 08:33:00,139.7390999349,35.6354086236,2,4,1251203,8 ,1 ,37,,12",
 "89700,5,5,1998/10/01 08:33:00,140.1630007616,35.8350071237,2,8,4422113,3 ,8 ,42,,7",
 "128800,2,3,1998/10/01 08:33:00,140.4004729868,35.8079640546,1,14,4831221,15,7 ,26,,10",
 "280600,5,5,1998/10/01 08:33:00,139.7547505321,35.7252930345,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:33:00,139.7388589176,35.6261243963,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,4,1998/10/01 08:33:00,139.7676079434,35.6947179456,1,6,4111102,8 ,1 ,36,,1",
 "363400,4,5,1998/10/01 08:33:00,139.6259144157,35.7993345991,1,6,3032201,3 ,11,34,,6",
 "16100,2,3,1998/10/01 08:33:00,139.8189805077,35.6564383451,2,4,4116209,8 ,1 ,48,,12",
 "48300,2,3,1998/10/01 08:33:00,139.9062002701,35.7599636226,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:33:00,139.9234461982,35.8289530068,1,7,4224202,9 ,1 ,37,,12",
 "78200,2,3,1998/10/01 08:33:00,139.7831170658,35.678273898,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:33:00,139.8504633579,35.7120709917,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:33:00,139.919505548,35.8198078693,2,4,5024120,13,2 ,44,,12",
 "193200,2,2,1998/10/01 08:33:00,140.0787738896,35.8889294241,2,5,5412112,9 ,1 ,35,,1",
 "209300,2,3,1998/10/01 08:33:00,139.6282600472,35.4722441005,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:33:00,139.6269329387,35.2960135566,1,7,2220004,6 ,1 ,36,,12",
 "4600,2,2,1998/10/01 08:33:00,139.9326070504,35.7526582612,1,11,4112301,10,1 ,37,,6",
 "225400,2,4,1998/10/01 08:33:00,139.4829638252,35.3379971584,1,3,2313201,13,2 ,36,,1",
 "227700,2,3,1998/10/01 08:33:00,139.7084173935,35.6498006633,1,9,2410203,8 ,10,16,,12",
 "66700,2,2,1998/10/01 08:33:00,139.9488553783,35.8481621954,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:33:00,140.1003495198,35.7206389534,1,8,4417027,9 ,10,46,,6",
 "92000,2,2,1998/10/01 08:33:00,140.349382502,35.8262041272,2,6,4431008,14,10,36,,7",
 "98900,2,2,1998/10/01 08:33:00,140.0631927687,35.4741013118,2,11,4511022,8 ,1 ,32,,6",
 "103500,4,4,1998/10/01 08:33:00,139.9484320057,35.3764913726,2,3,4610015,13,2 ,38,,2",
 "133400,2,2,1998/10/01 08:33:00,140.4470929018,35.6068242701,2,3,4911101,13,2 ,34,,7",
 "322000,2,3,1998/10/01 08:33:00,139.6870975365,35.6318879629,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,3,1998/10/01 08:33:00,139.6319277858,35.6235687136,1,10,2110103,8 ,1 ,29,,12",
 "181700,2,2,1998/10/01 08:33:00,140.0625827685,36.1163832152,2,7,5123003,8 ,1 ,31,,6",
 "345000,2,4,1998/10/01 08:33:00,139.7577214976,35.7402823281,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:33:00,139.3571364835,35.4651079991,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:33:00,139.690983673,35.7913736406,2,5,3023001,9 ,1 ,35,,12",
 "312800,2,2,1998/10/01 08:33:00,139.7019064199,35.5069210134,2,6,2015203,14,5 ,34,,1",
 "404800,2,3,1998/10/01 08:33:00,139.6586920142,35.7193324426,2,9,3120009,8 ,1 ,30,,12",
 "361100,2,2,1998/10/01 08:33:00,139.6421865055,35.8460437538,1,6,3026023,9 ,1 ,32,,6",
 "374900,2,2,1998/10/01 08:33:00,139.6895962389,35.7814827356,1,4,3040108,4 ,1 ,71,,6",
 "418600,2,4,1998/10/01 08:33:00,139.3711697337,35.8394418632,1,11,3123503,8 ,1 ,23,,12",
 "379500,2,2,1998/10/01 08:33:00,139.6097138425,35.9187735624,2,6,3053202,8 ,1 ,31,,1",
 "425500,2,4,1998/10/01 08:33:00,139.5568992836,35.8367076069,1,4,3134008,8 ,1 ,45,,9",
 "381800,2,2,1998/10/01 08:33:00,139.5974687858,35.8991548325,1,15,3055105,10,1 ,32,,6",
 "446200,2,3,1998/10/01 08:33:00,139.8121961748,35.7582693914,2,5,3221102,8 ,1 ,37,,12",
 "430100,6,6,1998/10/01 08:33:00,139.3936566652,35.8612298205,1,14,3135204,15,7 ,33,,6",
 "473800,2,3,1998/10/01 08:33:00,139.4878846363,35.9012207687,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 08:33:00,139.8013456285,35.7091445692,1,9,3215009,9 ,11,30,,8",
 "478400,2,2,1998/10/01 08:33:00,139.3967632925,36.0027181603,1,4,3413105,13,2 ,40,,1",
 "483000,2,3,1998/10/01 08:33:00,139.7797478739,35.7185191426,1,10,3510101,8 ,1 ,27,,12",
 "455400,2,2,1998/10/01 08:33:00,139.8196714825,35.9228352917,1,12,3226003,3 ,11,31,,8",
 "462300,4,4,1998/10/01 08:33:00,139.7552989783,35.8796914866,1,8,3235004,9 ,11,38,,8",
 "522100,2,2,1998/10/01 08:33:00,139.2690209206,36.1850115197,1,3,3722104,13,2 ,35,,2",
 "563500,2,3,1998/10/01 08:33:00,139.795624541,35.6555313866,1,7,0212001,10,1 ,84,,12",
 "577300,2,2,1998/10/01 08:33:00,139.7891936489,35.7181827281,1,4,0311012,9 ,1 ,27,,8",
 "611800,2,3,1998/10/01 08:33:00,139.6062714174,35.5943406497,1,4,0431110,13,2 ,65,,12",
 "667000,2,2,1998/10/01 08:33:00,139.7773595907,35.7803917827,2,12,0614310,6 ,1 ,43,,2",
 "823400,2,4,1998/10/01 08:33:00,139.6071683073,35.4537655887,1,10,1213105,9 ,13,34,,10",
 "724500,2,2,1998/10/01 08:33:00,139.5163745023,35.6879872857,2,10,0761001,8 ,1 ,33,,7",
 "786600,2,3,1998/10/01 08:33:00,139.2865011345,35.6437460952,2,3,0930110,13,2 ,35,,12",
 "660100,2,3,1998/10/01 08:33:00,139.780572529,35.7156033952,1,7,0612111,9 ,1 ,60,,12",
 "669300,2,4,1998/10/01 08:33:00,139.9747070199,35.8624862998,1,6,0620210,9 ,13,47,,1",
 "759000,2,3,1998/10/01 08:33:00,139.7313772881,35.6880899508,2,4,0821006,9 ,1 ,55,,12",
 "696900,2,3,1998/10/01 08:33:00,139.7258478342,35.6232144198,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:33:00,139.5757707936,35.7030265421,1,7,0752005,6 ,10,34,,12",
 "830300,2,3,1998/10/01 08:34:00,139.7441079431,35.6436998364,2,4,1251203,8 ,1 ,37,,12",
 "89700,5,5,1998/10/01 08:34:00,140.1632677538,35.8388484535,2,8,4422113,3 ,8 ,42,,7",
 "128800,2,3,1998/10/01 08:34:00,140.4004733142,35.8079643219,1,14,4831221,15,7 ,26,,10",
 "280600,5,5,1998/10/01 08:34:00,139.7564568028,35.7277387533,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:34:00,139.7399576479,35.6307200758,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,4,1998/10/01 08:34:00,139.7678483646,35.6944589819,1,6,4111102,8 ,1 ,36,,1",
 "363400,4,5,1998/10/01 08:34:00,139.6280789274,35.7981857065,1,6,3032201,3 ,11,34,,6",
 "16100,2,3,1998/10/01 08:34:00,139.8123695312,35.6614056295,2,4,4116209,8 ,1 ,48,,12",
 "48300,2,3,1998/10/01 08:34:00,139.9023856681,35.7581307013,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:34:00,139.9205220871,35.8234039579,1,7,4224202,9 ,1 ,37,,12",
 "78200,2,3,1998/10/01 08:34:00,139.7788382373,35.6805036674,1,12,4413008,8 ,1 ,44,,12",
 "151800,2,4,1998/10/01 08:34:00,139.8452696096,35.7084080558,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:34:00,139.9181278404,35.8149443155,2,4,5024120,13,2 ,44,,12",
 "193200,2,2,1998/10/01 08:34:00,140.0787736368,35.8889294939,2,5,5412112,9 ,1 ,35,,1",
 "209300,2,3,1998/10/01 08:34:00,139.6335410524,35.4765290071,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:34:00,139.6244131241,35.3019259895,1,7,2220004,6 ,1 ,36,,12",
 "4600,2,2,1998/10/01 08:34:00,139.934723613,35.751538097,1,11,4112301,10,1 ,37,,6",
 "225400,2,4,1998/10/01 08:34:00,139.4824284003,35.337783126,1,3,2313201,13,2 ,36,,1",
 "227700,2,3,1998/10/01 08:34:00,139.7040314835,35.6551625139,1,9,2410203,8 ,10,16,,12",
 "66700,2,2,1998/10/01 08:34:00,139.9487462925,35.8483424523,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:34:00,140.0953802191,35.7218056891,1,8,4417027,9 ,10,46,,6",
 "92000,2,2,1998/10/01 08:34:00,140.3543423733,35.8228929653,2,6,4431008,14,10,36,,7",
 "98900,2,2,1998/10/01 08:34:00,140.0621890039,35.4766507964,2,11,4511022,8 ,1 ,32,,6",
 "103500,4,4,1998/10/01 08:34:00,139.9500002316,35.3763190923,2,3,4610015,13,2 ,38,,2",
 "133400,2,2,1998/10/01 08:34:00,140.448349881,35.6105533433,2,3,4911101,13,2 ,34,,7",
 "322000,2,3,1998/10/01 08:34:00,139.6893119188,35.6354070903,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,3,1998/10/01 08:34:00,139.6339807621,35.6265637901,1,10,2110103,8 ,1 ,29,,12",
 "181700,2,2,1998/10/01 08:34:00,140.0652814716,36.1132933677,2,7,5123003,8 ,1 ,31,,6",
 "345000,2,4,1998/10/01 08:34:00,139.7542855665,35.7399551874,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:34:00,139.3574853111,35.4639136939,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:34:00,139.6957863557,35.790661309,2,5,3023001,9 ,1 ,35,,12",
 "312800,2,2,1998/10/01 08:34:00,139.7026639339,35.5068924576,2,6,2015203,14,5 ,34,,1",
 "404800,2,3,1998/10/01 08:34:00,139.6639882557,35.7192981255,2,9,3120009,8 ,1 ,30,,12",
 "361100,2,2,1998/10/01 08:34:00,139.6422029148,35.8479322873,1,6,3026023,9 ,1 ,32,,6",
 "374900,2,2,1998/10/01 08:34:00,139.6921732516,35.7789108042,1,4,3040108,4 ,1 ,71,,6",
 "418600,2,4,1998/10/01 08:34:00,139.3765173284,35.8409285271,1,11,3123503,8 ,1 ,23,,12",
 "379500,2,2,1998/10/01 08:34:00,139.6106649995,35.9185246218,2,6,3053202,8 ,1 ,31,,1",
 "425500,2,4,1998/10/01 08:34:00,139.5561325853,35.8371920728,1,4,3134008,8 ,1 ,45,,9",
 "381800,2,2,1998/10/01 08:34:00,139.5945916263,35.8987282368,1,15,3055105,10,1 ,32,,6",
 "446200,2,3,1998/10/01 08:34:00,139.8100800057,35.7559408647,2,5,3221102,8 ,1 ,37,,12",
 "430100,6,6,1998/10/01 08:34:00,139.3958021395,35.8614303659,1,14,3135204,15,7 ,33,,6",
 "473800,2,3,1998/10/01 08:34:00,139.4929901332,35.9008499672,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 08:34:00,139.7992982534,35.7083862184,1,9,3215009,9 ,11,30,,8",
 "478400,2,2,1998/10/01 08:34:00,139.397382401,36.002751758,1,4,3413105,13,2 ,40,,1",
 "483000,2,3,1998/10/01 08:34:00,139.7775836378,35.715421118,1,10,3510101,8 ,1 ,27,,12",
 "455400,2,2,1998/10/01 08:34:00,139.820456416,35.921611228,1,12,3226003,3 ,11,31,,8",
 "462300,4,4,1998/10/01 08:34:00,139.7558854342,35.8790444993,1,8,3235004,9 ,11,38,,8",
 "522100,2,2,1998/10/01 08:34:00,139.2677628749,36.1857045059,1,3,3722104,13,2 ,35,,2",
 "563500,2,3,1998/10/01 08:34:00,139.7979333798,35.6532105863,1,7,0212001,10,1 ,84,,12",
 "577300,2,2,1998/10/01 08:34:00,139.7920267324,35.7174454073,1,4,0311012,9 ,1 ,27,,8",
 "611800,2,3,1998/10/01 08:34:00,139.6014376222,35.5912079998,1,4,0431110,13,2 ,65,,12",
 "667000,2,2,1998/10/01 08:34:00,139.777759014,35.7803320607,2,12,0614310,6 ,1 ,43,,2",
 "724500,2,2,1998/10/01 08:34:00,139.5191080663,35.6874693677,2,10,0761001,8 ,1 ,33,,7",
 "823400,2,4,1998/10/01 08:34:00,139.6071118096,35.4538779858,1,10,1213105,9 ,13,34,,10",
 "786600,2,3,1998/10/01 08:34:00,139.2820867797,35.6421187172,2,3,0930110,13,2 ,35,,12",
 "786600,2,4,1998/10/01 08:34:00,139.2820867797,35.6421187172,2,3,0930110,13,2 ,35,,9",
 "669300,2,4,1998/10/01 08:34:00,139.9760336765,35.8623180609,1,6,0620210,9 ,13,47,,1",
 "660100,2,3,1998/10/01 08:34:00,139.778940338,35.7133585704,1,7,0612111,9 ,1 ,60,,12",
 "772800,2,3,1998/10/01 08:34:00,139.412160424,35.6245126108,2,9,0842111,4 ,1 ,38,,12",
 "759000,2,3,1998/10/01 08:34:00,139.735260213,35.6911176474,2,4,0821006,9 ,1 ,55,,12",
 "719900,2,4,1998/10/01 08:34:00,139.5794698778,35.7030789537,1,7,0752005,6 ,10,34,,12",
 "696900,2,3,1998/10/01 08:34:00,139.7281036074,35.6201380356,2,6,0720006,4 ,1 ,39,,12",
 "786600,2,4,1998/10/01 08:35:00,139.283634898,35.6427483366,2,3,0930110,13,2 ,35,,9",
 "830300,2,3,1998/10/01 08:35:00,139.7536257595,35.6487980064,2,4,1251203,8 ,1 ,37,,12",
 "89700,5,5,1998/10/01 08:35:00,140.1576122237,35.8394959533,2,8,4422113,3 ,8 ,42,,7",
 "89700,6,6,1998/10/01 08:35:00,140.1576122237,35.8394959533,2,8,4422113,3 ,97,42,,97",
 "128800,2,3,1998/10/01 08:35:00,140.4004736416,35.8079645892,1,14,4831221,15,7 ,26,,10",
 "280600,5,5,1998/10/01 08:35:00,139.7576319591,35.7303882939,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:35:00,139.7418793467,35.6351352446,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,4,1998/10/01 08:35:00,139.7680887842,35.6942000177,1,6,4111102,8 ,1 ,36,,1",
 "363400,4,5,1998/10/01 08:35:00,139.6302335578,35.7975476906,1,6,3032201,3 ,11,34,,6",
 "16100,2,3,1998/10/01 08:35:00,139.802869973,35.661402234,2,4,4116209,8 ,1 ,48,,12",
 "48300,2,3,1998/10/01 08:35:00,139.8982032355,35.7569768813,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:35:00,139.9188245654,35.8174600455,1,7,4224202,9 ,1 ,37,,12",
 "78200,2,3,1998/10/01 08:35:00,139.7745435522,35.6818486258,1,12,4413008,8 ,1 ,44,,12",
 "78200,2,4,1998/10/01 08:35:00,139.7745435522,35.6818486258,1,12,4413008,8 ,1 ,44,,1",
 "151800,2,4,1998/10/01 08:35:00,139.8400520633,35.7047791889,1,9,4031206,8 ,1 ,38,,12",
 "193200,2,2,1998/10/01 08:35:00,140.078773384,35.8889295637,2,5,5412112,9 ,1 ,35,,1",
 "193200,2,3,1998/10/01 08:35:00,140.078773384,35.8889295637,2,5,5412112,9 ,1 ,35,,10",
 "172500,2,3,1998/10/01 08:35:00,139.9169087743,35.8100526522,2,4,5024120,13,2 ,44,,12",
 "209300,2,3,1998/10/01 08:35:00,139.6390208257,35.4806696429,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:35:00,139.6239301303,35.3081369443,1,7,2220004,6 ,1 ,36,,12",
 "4600,2,2,1998/10/01 08:35:00,139.9368323422,35.7504081051,1,11,4112301,10,1 ,37,,6",
 "225400,2,4,1998/10/01 08:35:00,139.4818899134,35.3379492306,1,3,2313201,13,2 ,36,,1",
 "227700,2,3,1998/10/01 08:35:00,139.701335835,35.6610004827,1,9,2410203,8 ,10,16,,12",
 "66700,2,2,1998/10/01 08:35:00,139.9486693879,35.8483154085,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:35:00,140.0905446248,35.7232762024,1,8,4417027,9 ,10,46,,6",
 "92000,2,2,1998/10/01 08:35:00,140.3599676116,35.8197659139,2,6,4431008,14,10,36,,7",
 "98900,2,2,1998/10/01 08:35:00,140.0635194715,35.478133808,2,11,4511022,8 ,1 ,32,,6",
 "103500,4,4,1998/10/01 08:35:00,139.951567975,35.3761439264,2,3,4610015,13,2 ,38,,2",
 "133400,2,2,1998/10/01 08:35:00,140.4466519872,35.6145654057,2,3,4911101,13,2 ,34,,7",
 "324300,2,3,1998/10/01 08:35:00,139.6373723964,35.628585854,1,10,2110103,8 ,1 ,29,,12",
 "322000,2,3,1998/10/01 08:35:00,139.6920054918,35.6386993369,2,5,2032102,8 ,1 ,36,,12",
 "181700,2,2,1998/10/01 08:35:00,140.068378299,36.1107111498,2,7,5123003,8 ,1 ,31,,6",
 "345000,2,4,1998/10/01 08:35:00,139.7511778918,35.7383992221,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:35:00,139.3588655449,35.4638364159,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:35:00,139.7006044771,35.7899890192,2,5,3023001,9 ,1 ,35,,12",
 "312800,2,2,1998/10/01 08:35:00,139.7026444455,35.5071916534,2,6,2015203,14,5 ,34,,1",
 "312800,3,3,1998/10/01 08:35:00,139.7026444455,35.5071916534,2,6,2015203,14,97,34,,97",
 "404800,2,3,1998/10/01 08:35:00,139.6688543372,35.7176778756,2,9,3120009,8 ,1 ,30,,12",
 "361100,2,2,1998/10/01 08:35:00,139.6426164995,35.8498008606,1,6,3026023,9 ,1 ,32,,6",
 "418600,2,4,1998/10/01 08:35:00,139.3818466704,35.8423717916,1,11,3123503,8 ,1 ,23,,12",
 "374900,2,2,1998/10/01 08:35:00,139.6947637826,35.7763471256,1,4,3040108,4 ,1 ,71,,6",
 "381800,2,2,1998/10/01 08:35:00,139.5918241477,35.8990934276,1,15,3055105,10,1 ,32,,6",
 "379500,2,2,1998/10/01 08:35:00,139.6108215176,35.9174995793,2,6,3053202,8 ,1 ,31,,1",
 "425500,2,4,1998/10/01 08:35:00,139.5553658777,35.8376765337,1,4,3134008,8 ,1 ,45,,9",
 "425500,2,5,1998/10/01 08:35:00,139.5553658777,35.8376765337,1,4,3134008,8 ,1 ,45,,1",
 "446200,2,3,1998/10/01 08:35:00,139.807966977,35.7536104799,2,5,3221102,8 ,1 ,37,,12",
 "430100,6,6,1998/10/01 08:35:00,139.3981211355,35.8616636597,1,14,3135204,15,7 ,33,,6",
 "441600,4,4,1998/10/01 08:35:00,139.7975712173,35.7081837404,1,9,3215009,9 ,11,30,,8",
 "473800,2,3,1998/10/01 08:35:00,139.4982108795,35.9012533128,2,10,3411010,8 ,1 ,30,,12",
 "478400,2,2,1998/10/01 08:35:00,139.3978191031,36.0023509034,1,4,3413105,13,2 ,40,,1",
 "478400,2,3,1998/10/01 08:35:00,139.3978191031,36.0023509034,1,4,3413105,13,2 ,40,,12",
 "483000,2,3,1998/10/01 08:35:00,139.7782285732,35.7126148531,1,10,3510101,8 ,1 ,27,,12",
 "455400,2,2,1998/10/01 08:35:00,139.8211094508,35.9203395032,1,12,3226003,3 ,11,31,,8",
 "462300,4,4,1998/10/01 08:35:00,139.7560242226,35.8785835983,1,8,3235004,9 ,11,38,,8",
 "522100,2,2,1998/10/01 08:35:00,139.2667872534,36.1854191712,1,3,3722104,13,2 ,35,,2",
 "563500,2,3,1998/10/01 08:35:00,139.8004898065,35.6510869008,1,7,0212001,10,1 ,84,,12",
 "577300,2,2,1998/10/01 08:35:00,139.7948741179,35.71674687,1,4,0311012,9 ,1 ,27,,8",
 "611800,2,3,1998/10/01 08:35:00,139.5958054163,35.5890900207,1,4,0431110,13,2 ,65,,12",
 "667000,2,2,1998/10/01 08:35:00,139.7780856078,35.7802747022,2,12,0614310,6 ,1 ,43,,2",
 "724500,2,2,1998/10/01 08:35:00,139.5216623809,35.6865452808,2,10,0761001,8 ,1 ,33,,7",
 "660100,2,3,1998/10/01 08:35:00,139.7767647111,35.7135676179,1,7,0612111,9 ,1 ,60,,12",
 "660100,2,4,1998/10/01 08:35:00,139.7767647111,35.7135676179,1,7,0612111,9 ,1 ,60,,1",
 "772800,2,3,1998/10/01 08:35:00,139.4183659765,35.6234198411,2,9,0842111,4 ,1 ,38,,12",
 "669300,2,4,1998/10/01 08:35:00,139.9770337087,35.8616182343,1,6,0620210,9 ,13,47,,1",
 "759000,2,3,1998/10/01 08:35:00,139.7390966906,35.6942757897,2,4,0821006,9 ,1 ,55,,12",
 "823400,2,4,1998/10/01 08:35:00,139.6075597997,35.4540063058,1,10,1213105,9 ,13,34,,10",
 "719900,2,4,1998/10/01 08:35:00,139.5831564636,35.7033121425,1,7,0752005,6 ,10,34,,12",
 "696900,2,3,1998/10/01 08:35:00,139.7323656358,35.6201602683,2,6,0720006,4 ,1 ,39,,12",
 "830300,2,3,1998/10/01 08:36:00,139.757599532,35.6573196876,2,4,1251203,8 ,1 ,37,,12",
 "128800,2,3,1998/10/01 08:36:00,140.4004739691,35.8079648566,1,14,4831221,15,7 ,26,,10",
 "280600,5,5,1998/10/01 08:36:00,139.7588744642,35.7330025595,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:36:00,139.7428698929,35.6397164655,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,4,1998/10/01 08:36:00,139.7683292023,35.6939410529,1,6,4111102,8 ,1 ,36,,1",
 "363400,4,5,1998/10/01 08:36:00,139.6300479923,35.7954553833,1,6,3032201,3 ,11,34,,6",
 "16100,2,3,1998/10/01 08:36:00,139.7951831491,35.6657859065,2,4,4116209,8 ,1 ,48,,12",
 "48300,2,3,1998/10/01 08:36:00,139.8938036496,35.7565668563,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:36:00,139.9172825286,35.8114882455,1,7,4224202,9 ,1 ,37,,12",
 "78200,2,4,1998/10/01 08:36:00,139.7741701603,35.6805200509,1,12,4413008,8 ,1 ,44,,1",
 "151800,2,4,1998/10/01 08:36:00,139.8367772563,35.6998634697,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:36:00,139.9151887781,35.8052720018,2,4,5024120,13,2 ,44,,12",
 "193200,2,3,1998/10/01 08:36:00,140.0741546236,35.8889927156,2,5,5412112,9 ,1 ,35,,10",
 "209300,2,3,1998/10/01 08:36:00,139.6448051326,35.4844836154,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:36:00,139.6250280729,35.3144235587,1,7,2220004,6 ,1 ,36,,12",
 "4600,2,2,1998/10/01 08:36:00,139.9385817879,35.749060784,1,11,4112301,10,1 ,37,,6",
 "227700,2,3,1998/10/01 08:36:00,139.7021209033,35.6674342044,1,9,2410203,8 ,10,16,,12",
 "225400,2,4,1998/10/01 08:36:00,139.4813479895,35.3381538646,1,3,2313201,13,2 ,36,,1",
 "66700,2,2,1998/10/01 08:36:00,139.9486188189,35.8481187228,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:36:00,140.0854682689,35.7238672857,1,8,4417027,9 ,10,46,,6",
 "92000,2,2,1998/10/01 08:36:00,140.3649262326,35.8162597497,2,6,4431008,14,10,36,,7",
 "98900,2,2,1998/10/01 08:36:00,140.0666996985,35.4789341347,2,11,4511022,8 ,1 ,32,,6",
 "103500,4,4,1998/10/01 08:36:00,139.9531274458,35.375928556,2,3,4610015,13,2 ,38,,2",
 "133400,2,2,1998/10/01 08:36:00,140.4438979248,35.618991389,2,3,4911101,13,2 ,34,,7",
 "322000,2,3,1998/10/01 08:36:00,139.6954241507,35.6414243421,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,3,1998/10/01 08:36:00,139.6407217787,35.6306512915,1,10,2110103,8 ,1 ,29,,12",
 "181700,2,2,1998/10/01 08:36:00,140.0741449202,36.1114086802,2,7,5123003,8 ,1 ,31,,6",
 "345000,2,4,1998/10/01 08:36:00,139.7480623577,35.7368543091,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:36:00,139.3605062423,35.4640033914,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:36:00,139.7046194568,35.7877552093,2,5,3023001,9 ,1 ,35,,12",
 "404800,2,3,1998/10/01 08:36:00,139.6734890065,35.7155807347,2,9,3120009,8 ,1 ,30,,12",
 "361100,2,2,1998/10/01 08:36:00,139.6424740633,35.8516555,1,6,3026023,9 ,1 ,32,,6",
 "374900,2,2,1998/10/01 08:36:00,139.6975704118,35.773942601,1,4,3040108,4 ,1 ,71,,6",
 "418600,2,4,1998/10/01 08:36:00,139.3869381197,35.8411619532,1,11,3123503,8 ,1 ,23,,12",
 "379500,2,2,1998/10/01 08:36:00,139.6109590857,35.9164727505,2,6,3053202,8 ,1 ,31,,1",
 "381800,2,2,1998/10/01 08:36:00,139.5889189082,35.8993624463,1,15,3055105,10,1 ,32,,6",
 "446200,2,3,1998/10/01 08:36:00,139.8061474093,35.7511242163,2,5,3221102,8 ,1 ,37,,12",
 "430100,6,6,1998/10/01 08:36:00,139.4001679751,35.8621533203,1,14,3135204,15,7 ,33,,6",
 "441600,4,4,1998/10/01 08:36:00,139.7959911907,35.7077908184,1,9,3215009,9 ,11,30,,8",
 "473800,2,3,1998/10/01 08:36:00,139.5034216339,35.9017374669,2,10,3411010,8 ,1 ,30,,12",
 "478400,2,3,1998/10/01 08:36:00,139.3971147221,36.007062585,1,4,3413105,13,2 ,40,,12",
 "483000,2,3,1998/10/01 08:36:00,139.7764848468,35.7096409626,1,10,3510101,8 ,1 ,27,,12",
 "455400,2,2,1998/10/01 08:36:00,139.8216407981,35.9190275948,1,12,3226003,3 ,11,31,,8",
 "462300,4,4,1998/10/01 08:36:00,139.7550860594,35.8785411306,1,8,3235004,9 ,11,38,,8",
 "522100,2,2,1998/10/01 08:36:00,139.2656720527,36.1860148718,1,3,3722104,13,2 ,35,,2",
 "563500,2,3,1998/10/01 08:36:00,139.8037606448,35.6497656146,1,7,0212001,10,1 ,84,,12",
 "577300,2,2,1998/10/01 08:36:00,139.7977190969,35.7160404026,1,4,0311012,9 ,1 ,27,,8",
 "604900,2,2,1998/10/01 08:36:00,139.6050667352,35.6881516896,2,1,0423005,12,2 ,49,,1",
 "611800,2,3,1998/10/01 08:36:00,139.5902346772,35.58682023,1,4,0431110,13,2 ,65,,12",
 "667000,2,2,1998/10/01 08:36:00,139.77768162,35.7802410653,2,12,0614310,6 ,1 ,43,,2",
 "701500,2,2,1998/10/01 08:36:00,139.5666410408,35.7132059856,2,10,0721104,9 ,13,48,,2",
 "823400,2,4,1998/10/01 08:36:00,139.6080293976,35.4540471603,1,10,1213105,9 ,13,34,,10",
 "724500,2,2,1998/10/01 08:36:00,139.5239466575,35.6852207375,2,10,0761001,8 ,1 ,33,,7",
 "786600,2,4,1998/10/01 08:36:00,139.2851830408,35.6433779363,2,3,0930110,13,2 ,35,,9",
 "669300,2,4,1998/10/01 08:36:00,139.9783025971,35.8612948255,1,6,0620210,9 ,13,47,,1",
 "660100,2,4,1998/10/01 08:36:00,139.7773679173,35.7127369981,1,7,0612111,9 ,1 ,60,,1",
 "759000,2,3,1998/10/01 08:36:00,139.7419676522,35.6981088266,2,4,0821006,9 ,1 ,55,,12",
 "772800,2,3,1998/10/01 08:36:00,139.4242976146,35.6251796121,2,9,0842111,4 ,1 ,38,,12",
 "772800,2,4,1998/10/01 08:36:00,139.4242976146,35.6251796121,2,9,0842111,4 ,1 ,38,,1",
 "696900,2,3,1998/10/01 08:36:00,139.7367494499,35.6203702234,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:36:00,139.586853072,35.7034317511,1,7,0752005,6 ,10,34,,12",
 "830300,2,3,1998/10/01 08:37:00,139.7581883753,35.6665299455,2,4,1251203,8 ,1 ,37,,12",
 "128800,2,3,1998/10/01 08:37:00,140.4004742965,35.8079651239,1,14,4831221,15,7 ,26,,10",
 "280600,5,5,1998/10/01 08:37:00,139.7594499739,35.7357834163,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:37:00,139.7465709202,35.6431927106,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,4,1998/10/01 08:37:00,139.7685676028,35.6936812687,1,6,4111102,8 ,1 ,36,,1",
 "363400,4,5,1998/10/01 08:37:00,139.6312054733,35.7939308403,1,6,3032201,3 ,11,34,,6",
 "16100,2,3,1998/10/01 08:37:00,139.7883625085,35.6712150526,2,4,4116209,8 ,1 ,48,,12",
 "48300,2,3,1998/10/01 08:37:00,139.8895911709,35.7555185793,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:37:00,139.9153726857,35.8055957865,1,7,4224202,9 ,1 ,37,,12",
 "78200,2,4,1998/10/01 08:37:00,139.7734766716,35.6792884635,1,12,4413008,8 ,1 ,44,,1",
 "151800,2,4,1998/10/01 08:37:00,139.8307686854,35.697628647,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:37:00,139.912145221,35.800944148,2,4,5024120,13,2 ,44,,12",
 "193200,2,3,1998/10/01 08:37:00,140.0685142464,35.8891357677,2,5,5412112,9 ,1 ,35,,10",
 "209300,2,3,1998/10/01 08:37:00,139.6520620579,35.4857417036,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:37:00,139.6208143697,35.3195590806,1,7,2220004,6 ,1 ,36,,12",
 "4600,2,2,1998/10/01 08:37:00,139.9402492398,35.748171698,1,11,4112301,10,1 ,37,,6",
 "225400,2,4,1998/10/01 08:37:00,139.480806063,35.3383584961,1,3,2313201,13,2 ,36,,1",
 "227700,2,3,1998/10/01 08:37:00,139.7035899388,35.673763315,1,9,2410203,8 ,10,16,,12",
 "66700,2,2,1998/10/01 08:37:00,139.9485682502,35.8479220371,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:37:00,140.0803022654,35.7242343409,1,8,4417027,9 ,10,46,,6",
 "92000,2,2,1998/10/01 08:37:00,140.3688421927,35.8147910105,2,6,4431008,14,10,36,,7",
 "98900,2,2,1998/10/01 08:37:00,140.0698392761,35.479668524,2,11,4511022,8 ,1 ,32,,6",
 "103500,4,4,1998/10/01 08:37:00,139.954592856,35.3757203141,2,3,4610015,13,2 ,38,,2",
 "133400,2,2,1998/10/01 08:37:00,140.440434057,35.6230417713,2,3,4911101,13,2 ,34,,7",
 "322000,2,3,1998/10/01 08:37:00,139.6989055564,35.6441701835,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,3,1998/10/01 08:37:00,139.644717656,35.6316842715,1,10,2110103,8 ,1 ,29,,12",
 "181700,2,2,1998/10/01 08:37:00,140.0779126233,36.1078977739,2,7,5123003,8 ,1 ,31,,6",
 "345000,2,4,1998/10/01 08:37:00,139.7448150552,35.7355088984,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:37:00,139.3614021468,35.4641944205,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:37:00,139.7087926826,35.7857630147,2,5,3023001,9 ,1 ,35,,12",
 "404800,2,3,1998/10/01 08:37:00,139.6788006197,35.7155166412,2,9,3120009,8 ,1 ,30,,12",
 "361100,2,2,1998/10/01 08:37:00,139.6424453392,35.8534884358,1,6,3026023,9 ,1 ,32,,6",
 "374900,2,2,1998/10/01 08:37:00,139.700137477,35.7713635157,1,4,3040108,4 ,1 ,71,,6",
 "418600,2,4,1998/10/01 08:37:00,139.39101784,35.8437658438,1,11,3123503,8 ,1 ,23,,12",
 "379500,2,2,1998/10/01 08:37:00,139.6110956201,35.9154459719,2,6,3053202,8 ,1 ,31,,1",
 "381800,2,2,1998/10/01 08:37:00,139.586005906,35.8995773104,1,15,3055105,10,1 ,32,,6",
 "446200,2,3,1998/10/01 08:37:00,139.8047188012,35.7485082023,2,5,3221102,8 ,1 ,37,,12",
 "430100,6,6,1998/10/01 08:37:00,139.4022489078,35.86118555,1,14,3135204,15,7 ,33,,6",
 "473800,2,3,1998/10/01 08:37:00,139.5086324535,35.9022213951,2,10,3411010,8 ,1 ,30,,12",
 "478400,2,3,1998/10/01 08:37:00,139.3997123892,36.0113447894,1,4,3413105,13,2 ,40,,12",
 "441600,4,4,1998/10/01 08:37:00,139.7945731194,35.7066191762,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 08:37:00,139.7756282183,35.7070685898,1,10,3510101,8 ,1 ,27,,12",
 "455400,2,2,1998/10/01 08:37:00,139.8222236649,35.9177302908,1,12,3226003,3 ,11,31,,8",
 "462300,4,4,1998/10/01 08:37:00,139.7546159034,35.8780382189,1,8,3235004,9 ,11,38,,8",
 "522100,2,2,1998/10/01 08:37:00,139.2645889431,36.1868813538,1,3,3722104,13,2 ,35,,2",
 "563500,2,3,1998/10/01 08:37:00,139.8067763047,35.6480782006,1,7,0212001,10,1 ,84,,12",
 "577300,2,2,1998/10/01 08:37:00,139.8005730801,35.7153591049,1,4,0311012,9 ,1 ,27,,8",
 "604900,2,2,1998/10/01 08:37:00,139.6059199768,35.6881713149,2,1,0423005,12,2 ,49,,1",
 "611800,2,3,1998/10/01 08:37:00,139.5843915523,35.5852016571,1,4,0431110,13,2 ,65,,12",
 "667000,2,2,1998/10/01 08:37:00,139.7774645722,35.7802335654,2,12,0614310,6 ,1 ,43,,2",
 "701500,2,2,1998/10/01 08:37:00,139.5660148637,35.7124105422,2,10,0721104,9 ,13,48,,2",
 "823400,2,4,1998/10/01 08:37:00,139.6085037529,35.4540672578,1,10,1213105,9 ,13,34,,10",
 "724500,2,2,1998/10/01 08:37:00,139.5262903912,35.6839622287,2,10,0761001,8 ,1 ,33,,7",
 "660100,2,4,1998/10/01 08:37:00,139.7781838128,35.7121126524,1,7,0612111,9 ,1 ,60,,1",
 "786600,2,4,1998/10/01 08:37:00,139.285556101,35.6438163959,2,3,0930110,13,2 ,35,,9",
 "669300,2,4,1998/10/01 08:37:00,139.9795227088,35.8608287115,1,6,0620210,9 ,13,47,,1",
 "772800,2,4,1998/10/01 08:37:00,139.4256764899,35.6253117254,2,9,0842111,4 ,1 ,38,,1",
 "759000,2,3,1998/10/01 08:37:00,139.7451005811,35.7015679121,2,4,0821006,9 ,1 ,55,,12",
 "759000,2,4,1998/10/01 08:37:00,139.7451005811,35.7015679121,2,4,0821006,9 ,1 ,55,,1",
 "696900,2,3,1998/10/01 08:37:00,139.7382593398,35.623260647,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:37:00,139.590551024,35.7035234372,1,7,0752005,6 ,10,34,,12",
 "772800,2,4,1998/10/01 08:38:00,139.4267097047,35.6235052706,2,9,0842111,4 ,1 ,38,,1",
 "830300,2,3,1998/10/01 08:38:00,139.7623956002,35.6751519707,2,4,1251203,8 ,1 ,37,,12",
 "128800,2,3,1998/10/01 08:38:00,140.4004746239,35.8079653912,1,14,4831221,15,7 ,26,,10",
 "280600,5,5,1998/10/01 08:38:00,139.7605707351,35.7383402262,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:38:00,139.749648087,35.6467582328,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,4,1998/10/01 08:38:00,139.7686760888,35.6933687073,1,6,4111102,8 ,1 ,36,,1",
 "363400,4,5,1998/10/01 08:38:00,139.6334356331,35.7933724859,1,6,3032201,3 ,11,34,,6",
 "16100,2,3,1998/10/01 08:38:00,139.7799310512,35.6746301044,2,4,4116209,8 ,1 ,48,,12",
 "48300,2,3,1998/10/01 08:38:00,139.8856027561,35.7539491579,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:38:00,139.9115857137,35.8003437021,1,7,4224202,9 ,1 ,37,,12",
 "78200,2,4,1998/10/01 08:38:00,139.7740201761,35.6783132936,1,12,4413008,8 ,1 ,44,,1",
 "151800,2,4,1998/10/01 08:38:00,139.8239480982,35.6970658384,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:38:00,139.9084215157,35.7969810942,2,4,5024120,13,2 ,44,,12",
 "193200,2,3,1998/10/01 08:38:00,140.0637473663,35.8922302336,2,5,5412112,9 ,1 ,35,,10",
 "209300,2,3,1998/10/01 08:38:00,139.658642204,35.4885907275,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:38:00,139.6192228323,35.3255842875,1,7,2220004,6 ,1 ,36,,12",
 "4600,2,2,1998/10/01 08:38:00,139.9417845498,35.747777692,1,11,4112301,10,1 ,37,,6",
 "225400,2,4,1998/10/01 08:38:00,139.480650658,35.3387360305,1,3,2313201,13,2 ,36,,1",
 "227700,2,3,1998/10/01 08:38:00,139.7033241337,35.6801069748,1,9,2410203,8 ,10,16,,12",
 "66700,2,2,1998/10/01 08:38:00,139.9485652105,35.8479567812,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:38:00,140.0752940059,35.7233627022,1,8,4417027,9 ,10,46,,6",
 "92000,2,2,1998/10/01 08:38:00,140.3714677287,35.8105230146,2,6,4431008,14,10,36,,7",
 "98900,2,2,1998/10/01 08:38:00,140.0722694476,35.4806779069,2,11,4511022,8 ,1 ,32,,6",
 "103500,4,4,1998/10/01 08:38:00,139.9558817455,35.3753949015,2,3,4610015,13,2 ,38,,2",
 "133400,2,2,1998/10/01 08:38:00,140.4438107905,35.626550133,2,3,4911101,13,2 ,34,,7",
 "322000,2,3,1998/10/01 08:38:00,139.7020342482,35.6471372244,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,3,1998/10/01 08:38:00,139.6489178399,35.6317561343,1,10,2110103,8 ,1 ,29,,12",
 "181700,2,2,1998/10/01 08:38:00,140.0825013677,36.1048189191,2,7,5123003,8 ,1 ,31,,6",
 "345000,2,4,1998/10/01 08:38:00,139.7415287771,35.734220536,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:38:00,139.3628065792,35.4648999898,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:38:00,139.7135146683,35.78471139,2,5,3023001,9 ,1 ,35,,12",
 "404800,2,3,1998/10/01 08:38:00,139.684033021,35.7148746797,2,9,3120009,8 ,1 ,30,,12",
 "361100,2,2,1998/10/01 08:38:00,139.6425584565,35.8549451347,1,6,3026023,9 ,1 ,32,,6",
 "418600,2,4,1998/10/01 08:38:00,139.3962225535,35.8449301983,1,11,3123503,8 ,1 ,23,,12",
 "374900,2,2,1998/10/01 08:38:00,139.7024426353,35.7686344508,1,4,3040108,4 ,1 ,71,,6",
 "379500,2,2,1998/10/01 08:38:00,139.611869105,35.9149950905,2,6,3053202,8 ,1 ,31,,1",
 "381800,2,2,1998/10/01 08:38:00,139.5830924189,35.8997533204,1,15,3055105,10,1 ,32,,6",
 "446200,2,3,1998/10/01 08:38:00,139.8036489435,35.7457450349,2,5,3221102,8 ,1 ,37,,12",
 "430100,6,6,1998/10/01 08:38:00,139.4042895077,35.8601610261,1,14,3135204,15,7 ,33,,6",
 "473800,2,3,1998/10/01 08:38:00,139.5138537087,35.902612807,2,10,3411010,8 ,1 ,30,,12",
 "478400,2,3,1998/10/01 08:38:00,139.4019592871,36.0157303228,1,4,3413105,13,2 ,40,,12",
 "441600,4,4,1998/10/01 08:38:00,139.7931546695,35.7050808632,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 08:38:00,139.7746425223,35.7037132873,1,10,3510101,8 ,1 ,27,,12",
 "455400,2,2,1998/10/01 08:38:00,139.8228456313,35.916444811,1,12,3226003,3 ,11,31,,8",
 "462300,4,4,1998/10/01 08:38:00,139.7539520147,35.877772824,1,8,3235004,9 ,11,38,,8",
 "522100,2,2,1998/10/01 08:38:00,139.2635058195,36.1877478338,1,3,3722104,13,2 ,35,,2",
 "563500,2,3,1998/10/01 08:38:00,139.8096682442,35.6462439109,1,7,0212001,10,1 ,84,,12",
 "577300,2,2,1998/10/01 08:38:00,139.8031742919,35.7142801718,1,4,0311012,9 ,1 ,27,,8",
 "604900,2,2,1998/10/01 08:38:00,139.6067733441,35.6881518753,2,1,0423005,12,2 ,49,,1",
 "611800,2,3,1998/10/01 08:38:00,139.5784190037,35.5838831216,1,4,0431110,13,2 ,65,,12",
 "667000,2,2,1998/10/01 08:38:00,139.777859213,35.7803115915,2,12,0614310,6 ,1 ,43,,2",
 "701500,2,2,1998/10/01 08:38:00,139.5654568877,35.71158453,2,10,0721104,9 ,13,48,,2",
 "823400,2,4,1998/10/01 08:38:00,139.6089781085,35.4540873534,1,10,1213105,9 ,13,34,,10",
 "724500,2,2,1998/10/01 08:38:00,139.5286446814,35.6827175683,2,10,0761001,8 ,1 ,33,,7",
 "786600,2,4,1998/10/01 08:38:00,139.2852885389,35.645031111,2,3,0930110,13,2 ,35,,9",
 "660100,2,4,1998/10/01 08:38:00,139.7793302792,35.7118738509,1,7,0612111,9 ,1 ,60,,1",
 "669300,2,4,1998/10/01 08:38:00,139.9804923182,35.8605100743,1,6,0620210,9 ,13,47,,1",
 "759000,2,4,1998/10/01 08:38:00,139.7450461494,35.7013591696,2,4,0821006,9 ,1 ,55,,1",
 "696900,2,3,1998/10/01 08:38:00,139.7387954918,35.6267994184,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:38:00,139.5942491349,35.7036107584,1,7,0752005,6 ,10,34,,12",
 "830300,2,3,1998/10/01 08:39:00,139.767210733,35.6813207001,2,4,1251203,8 ,1 ,37,,12",
 "830300,2,4,1998/10/01 08:39:00,139.767210733,35.6813207001,2,4,1251203,8 ,1 ,37,,1",
 "128800,2,3,1998/10/01 08:39:00,140.4004749513,35.8079656586,1,14,4831221,15,7 ,26,,10",
 "869400,2,3,1998/10/01 08:39:00,139.7543636428,35.6493893646,1,10,1231001,8 ,1 ,29,,12",
 "280600,5,5,1998/10/01 08:39:00,139.7606735514,35.7402875697,1,11,2910009,8 ,9 ,42,,8",
 "2300,2,4,1998/10/01 08:39:00,139.768784574,35.6930561458,1,6,4111102,8 ,1 ,36,,1",
 "363400,4,5,1998/10/01 08:39:00,139.6350221978,35.7917466682,1,6,3032201,3 ,11,34,,6",
 "16100,2,3,1998/10/01 08:39:00,139.7706906289,35.6758855253,2,4,4116209,8 ,1 ,48,,12",
 "48300,2,3,1998/10/01 08:39:00,139.8816999789,35.7522482602,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:39:00,139.907009507,35.7955125844,1,7,4224202,9 ,1 ,37,,12",
 "78200,2,4,1998/10/01 08:39:00,139.7748424708,35.6771520146,1,12,4413008,8 ,1 ,44,,1",
 "151800,2,4,1998/10/01 08:39:00,139.8170873036,35.6968626953,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:39:00,139.9048619415,35.7929268118,2,4,5024120,13,2 ,44,,12",
 "193200,2,3,1998/10/01 08:39:00,140.062399413,35.8954603582,2,5,5412112,9 ,1 ,35,,10",
 "209300,2,3,1998/10/01 08:39:00,139.6640327141,35.4927927272,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:39:00,139.6199285712,35.3319315935,1,7,2220004,6 ,1 ,36,,12",
 "4600,2,2,1998/10/01 08:39:00,139.9435140668,35.7492511282,1,11,4112301,10,1 ,37,,6",
 "227700,2,3,1998/10/01 08:39:00,139.7012384015,35.6863379991,1,9,2410203,8 ,10,16,,12",
 "225400,2,4,1998/10/01 08:39:00,139.4805366732,35.3392116578,1,3,2313201,13,2 ,36,,1",
 "66700,2,2,1998/10/01 08:39:00,139.9485959824,35.8481561619,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:39:00,140.0707540374,35.7213567189,1,8,4417027,9 ,10,46,,6",
 "92000,2,2,1998/10/01 08:39:00,140.3736408855,35.805305151,2,6,4431008,14,10,36,,7",
 "98900,2,2,1998/10/01 08:39:00,140.0736629346,35.4822881037,2,11,4511022,8 ,1 ,32,,6",
 "103500,4,4,1998/10/01 08:39:00,139.9572614388,35.3755735662,2,3,4610015,13,2 ,38,,2",
 "133400,2,2,1998/10/01 08:39:00,140.4483180076,35.6298866641,2,3,4911101,13,2 ,34,,7",
 "322000,2,3,1998/10/01 08:39:00,139.7050682915,35.6501413793,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,3,1998/10/01 08:39:00,139.652824674,35.630492077,1,10,2110103,8 ,1 ,29,,12",
 "181700,2,2,1998/10/01 08:39:00,140.0871213415,36.1017691737,2,7,5123003,8 ,1 ,31,,6",
 "345000,2,4,1998/10/01 08:39:00,139.7383089234,35.7328249685,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:39:00,139.3643991978,35.4652236944,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:39:00,139.7175298492,35.7825399631,2,5,3023001,9 ,1 ,35,,12",
 "404800,2,3,1998/10/01 08:39:00,139.6893277871,35.7154950959,2,9,3120009,8 ,1 ,30,,12",
 "361100,2,2,1998/10/01 08:39:00,139.6426501776,35.856582815,1,6,3026023,9 ,1 ,32,,6",
 "418600,2,4,1998/10/01 08:39:00,139.4015599811,35.8438092682,1,11,3123503,8 ,1 ,23,,12",
 "374900,2,2,1998/10/01 08:39:00,139.704224667,35.7656734277,1,4,3040108,4 ,1 ,71,,6",
 "379500,2,2,1998/10/01 08:39:00,139.6131374284,35.9150362978,2,6,3053202,8 ,1 ,31,,1",
 "381800,2,2,1998/10/01 08:39:00,139.5804386504,35.9007241779,1,15,3055105,10,1 ,32,,6",
 "446200,2,3,1998/10/01 08:39:00,139.8027063549,35.7429512214,2,5,3221102,8 ,1 ,37,,12",
 "430100,6,6,1998/10/01 08:39:00,139.4063100323,35.859231384,1,14,3135204,15,7 ,33,,6",
 "473800,2,3,1998/10/01 08:39:00,139.5190860364,35.9029058684,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 08:39:00,139.791514826,35.7036941347,1,9,3215009,9 ,11,30,,8",
 "478400,2,3,1998/10/01 08:39:00,139.4021769257,36.0205204476,1,4,3413105,13,2 ,40,,12",
 "483000,2,3,1998/10/01 08:39:00,139.7744627495,35.7001267095,1,10,3510101,8 ,1 ,27,,12",
 "455400,2,2,1998/10/01 08:39:00,139.8232470778,35.9151076607,1,12,3226003,3 ,11,31,,8",
 "462300,4,4,1998/10/01 08:39:00,139.7530116532,35.8780191083,1,8,3235004,9 ,11,38,,8",
 "522100,2,2,1998/10/01 08:39:00,139.2624229493,36.1886145313,1,3,3722104,13,2 ,35,,2",
 "563500,2,3,1998/10/01 08:39:00,139.8126011792,35.64445864,1,7,0212001,10,1 ,84,,12",
 "577300,2,2,1998/10/01 08:39:00,139.80564339,35.7129322945,1,4,0311012,9 ,1 ,27,,8",
 "604900,2,2,1998/10/01 08:39:00,139.6076259965,35.6881164991,2,1,0423005,12,2 ,49,,1",
 "611800,2,3,1998/10/01 08:39:00,139.5739093238,35.5803993494,1,4,0431110,13,2 ,65,,12",
 "667000,2,2,1998/10/01 08:39:00,139.7782538546,35.7803896164,2,12,0614310,6 ,1 ,43,,2",
 "701500,2,2,1998/10/01 08:39:00,139.5649691418,35.7107270397,2,10,0721104,9 ,13,48,,2",
 "724500,2,2,1998/10/01 08:39:00,139.5311501575,35.6817806953,2,10,0761001,8 ,1 ,33,,7",
 "823400,2,4,1998/10/01 08:39:00,139.6094524643,35.4541074471,1,10,1213105,9 ,13,34,,10",
 "786600,2,4,1998/10/01 08:39:00,139.2868898211,35.6455649445,2,3,0930110,13,2 ,35,,9",
 "660100,2,4,1998/10/01 08:39:00,139.7804880021,35.7116728051,1,7,0612111,9 ,1 ,60,,1",
 "669300,2,4,1998/10/01 08:39:00,139.9817220848,35.8600611097,1,6,0620210,9 ,13,47,,1",
 "772800,2,4,1998/10/01 08:39:00,139.4284135785,35.6226689344,2,9,0842111,4 ,1 ,38,,1",
 "759000,2,4,1998/10/01 08:39:00,139.7449917179,35.7011504272,2,4,0821006,9 ,1 ,55,,1",
 "696900,2,3,1998/10/01 08:39:00,139.7384566806,35.6266688124,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:39:00,139.5979473594,35.7036949629,1,7,0752005,6 ,10,34,,12",
 "830300,2,4,1998/10/01 08:40:00,139.7662674039,35.6827872073,2,4,1251203,8 ,1 ,37,,1",
 "128800,2,3,1998/10/01 08:40:00,140.4004752788,35.8079659259,1,14,4831221,15,7 ,26,,10",
 "869400,2,3,1998/10/01 08:40:00,139.7566690818,35.6536207011,1,10,1231001,8 ,1 ,29,,12",
 "280600,5,5,1998/10/01 08:40:00,139.762240638,35.7425674081,1,11,2910009,8 ,9 ,42,,8",
 "2300,2,4,1998/10/01 08:40:00,139.7688930583,35.6927435842,1,6,4111102,8 ,1 ,36,,1",
 "363400,4,5,1998/10/01 08:40:00,139.6363199627,35.7899691958,1,6,3032201,3 ,11,34,,6",
 "16100,2,3,1998/10/01 08:40:00,139.767210733,35.6813207001,2,4,4116209,8 ,1 ,48,,12",
 "16100,2,4,1998/10/01 08:40:00,139.767210733,35.6813207001,2,4,4116209,8 ,1 ,48,,1",
 "48300,2,3,1998/10/01 08:40:00,139.8779528941,35.750327215,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:40:00,139.9032235127,35.7902676489,1,7,4224202,9 ,1 ,37,,12",
 "78200,2,4,1998/10/01 08:40:00,139.7762260291,35.6764161028,1,12,4413008,8 ,1 ,44,,1",
 "78200,3,5,1998/10/01 08:40:00,139.7762260291,35.6764161028,1,12,4413008,8 ,97,44,,97",
 "151800,2,4,1998/10/01 08:40:00,139.8102347521,35.6965249322,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:40:00,139.9022474524,35.7884160279,2,4,5024120,13,2 ,44,,12",
 "193200,2,3,1998/10/01 08:40:00,140.0631204501,35.8959935445,2,5,5412112,9 ,1 ,35,,10",
 "193200,2,4,1998/10/01 08:40:00,140.0631204501,35.8959935445,2,5,5412112,9 ,1 ,35,,12",
 "209300,2,3,1998/10/01 08:40:00,139.6691857601,35.4972052681,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:40:00,139.6204023766,35.3383057332,1,7,2220004,6 ,1 ,36,,12",
 "4600,2,2,1998/10/01 08:40:00,139.9448258385,35.7508722514,1,11,4112301,10,1 ,37,,6",
 "225400,2,4,1998/10/01 08:40:00,139.4803959384,35.3396868052,1,3,2313201,13,2 ,36,,1",
 "227700,2,3,1998/10/01 08:40:00,139.6999634071,35.6926807483,1,9,2410203,8 ,10,16,,12",
 "66700,2,2,1998/10/01 08:40:00,139.9486267544,35.8483555426,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:40:00,140.0667690149,35.719236233,1,8,4417027,9 ,10,46,,6",
 "92000,2,2,1998/10/01 08:40:00,140.3769238342,35.8038177423,2,6,4431008,14,10,36,,7",
 "92000,3,3,1998/10/01 08:40:00,140.3769238342,35.8038177423,2,6,4431008,14,97,36,,97",
 "98900,2,2,1998/10/01 08:40:00,140.0766750815,35.4831327635,2,11,4511022,8 ,1 ,32,,6",
 "103500,4,4,1998/10/01 08:40:00,139.9586473364,35.3759090568,2,3,4610015,13,2 ,38,,2",
 "103500,5,5,1998/10/01 08:40:00,139.9586473364,35.3759090568,2,3,4610015,13,97,38,,97",
 "133400,2,2,1998/10/01 08:40:00,140.4524144596,35.6330215836,2,3,4911101,13,2 ,34,,7",
 "133400,3,3,1998/10/01 08:40:00,140.4524144596,35.6330215836,2,3,4911101,13,97,34,,97",
 "322000,2,3,1998/10/01 08:40:00,139.7070522499,35.6537081183,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,3,1998/10/01 08:40:00,139.6568636211,35.6311229403,1,10,2110103,8 ,1 ,29,,12",
 "181700,2,2,1998/10/01 08:40:00,140.0917192892,36.0986977688,2,7,5123003,8 ,1 ,31,,6",
 "345000,2,4,1998/10/01 08:40:00,139.7350860062,35.7314351095,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:40:00,139.3660508242,35.4652066018,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:40:00,139.7194651341,35.7789031155,2,5,3023001,9 ,1 ,35,,12",
 "404800,2,3,1998/10/01 08:40:00,139.6946222463,35.7157156221,2,9,3120009,8 ,1 ,30,,12",
 "361100,2,2,1998/10/01 08:40:00,139.6424673592,35.8584752224,1,6,3026023,9 ,1 ,32,,6",
 "418600,2,4,1998/10/01 08:40:00,139.404963458,35.8401634875,1,11,3123503,8 ,1 ,23,,12",
 "374900,2,2,1998/10/01 08:40:00,139.7063464575,35.7628635562,1,4,3040108,4 ,1 ,71,,6",
 "379500,2,2,1998/10/01 08:40:00,139.6135876546,35.9143505791,2,6,3053202,8 ,1 ,31,,1",
 "381800,2,2,1998/10/01 08:40:00,139.5780432213,35.9020293352,1,15,3055105,10,1 ,32,,6",
 "446200,2,3,1998/10/01 08:40:00,139.8018389842,35.7401411588,2,5,3221102,8 ,1 ,37,,12",
 "430100,6,6,1998/10/01 08:40:00,139.408359932,35.8595005026,1,14,3135204,15,7 ,33,,6",
 "473800,2,3,1998/10/01 08:40:00,139.5242480588,35.9035058002,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 08:40:00,139.7897981877,35.7023924914,1,9,3215009,9 ,11,30,,8",
 "478400,2,3,1998/10/01 08:40:00,139.4023532304,36.0253123479,1,4,3413105,13,2 ,40,,12",
 "483000,2,3,1998/10/01 08:40:00,139.7755145154,35.69763458,1,10,3510101,8 ,1 ,27,,12",
 "455400,2,2,1998/10/01 08:40:00,139.8235690598,35.913751397,1,12,3226003,3 ,11,31,,8",
 "462300,4,4,1998/10/01 08:40:00,139.7522024537,35.8776253169,1,8,3235004,9 ,11,38,,8",
 "522100,2,2,1998/10/01 08:40:00,139.2613401255,36.1894812371,1,3,3722104,13,2 ,35,,2",
 "563500,2,3,1998/10/01 08:40:00,139.8160669028,35.644516962,1,7,0212001,10,1 ,84,,12",
 "577300,2,2,1998/10/01 08:40:00,139.8081630072,35.711644666,1,4,0311012,9 ,1 ,27,,8",
 "604900,2,2,1998/10/01 08:40:00,139.6084718859,35.6880250001,2,1,0423005,12,2 ,49,,1",
 "611800,2,3,1998/10/01 08:40:00,139.5687439731,35.5780308395,1,4,0431110,13,2 ,65,,12",
 "667000,2,2,1998/10/01 08:40:00,139.7786484969,35.78046764,2,12,0614310,6 ,1 ,43,,2",
 "701500,2,2,1998/10/01 08:40:00,139.5644645639,35.7098761374,2,10,0721104,9 ,13,48,,2",
 "724500,2,2,1998/10/01 08:40:00,139.533949158,35.6816181302,2,10,0761001,8 ,1 ,33,,7",
 "660100,2,4,1998/10/01 08:40:00,139.7814777957,35.711680619,1,7,0612111,9 ,1 ,60,,1",
 "660100,3,5,1998/10/01 08:40:00,139.7814777957,35.711680619,1,7,0612111,9 ,97,60,,97",
 "786600,2,4,1998/10/01 08:40:00,139.2884884255,35.6461037717,2,3,0930110,13,2 ,35,,9",
 "772800,2,4,1998/10/01 08:40:00,139.4301427404,35.6212940343,2,9,0842111,4 ,1 ,38,,1",
 "772800,3,5,1998/10/01 08:40:00,139.4301427404,35.6212940343,2,9,0842111,4 ,97,38,,97",
 "669300,2,4,1998/10/01 08:40:00,139.9829687054,35.859646514,1,6,0620210,9 ,13,47,,1",
 "669300,3,5,1998/10/01 08:40:00,139.9829687054,35.859646514,1,6,0620210,9 ,97,47,,97",
 "759000,2,4,1998/10/01 08:40:00,139.7449372867,35.7009416847,2,4,0821006,9 ,1 ,55,,1",
 "823400,2,4,1998/10/01 08:40:00,139.6099268204,35.454127539,1,10,1213105,9 ,13,34,,10",
 "696900,2,3,1998/10/01 08:40:00,139.7379596306,35.6231062528,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:40:00,139.6016373532,35.7038574408,1,7,0752005,6 ,10,34,,12",
 "830300,2,4,1998/10/01 08:41:00,139.766189792,35.6827506513,2,4,1251203,8 ,1 ,37,,1",
 "128800,2,3,1998/10/01 08:41:00,140.4004756062,35.8079661933,1,14,4831221,15,7 ,26,,10",
 "280600,5,5,1998/10/01 08:41:00,139.7633257426,35.7450685138,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:41:00,139.7578160942,35.65821262,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,4,1998/10/01 08:41:00,139.7691200287,35.6926171259,1,6,4111102,8 ,1 ,36,,1",
 "363400,4,5,1998/10/01 08:41:00,139.6377568988,35.7882510199,1,6,3032201,3 ,11,34,,6",
 "16100,2,4,1998/10/01 08:41:00,139.7668212377,35.6805880293,2,4,4116209,8 ,1 ,48,,1",
 "48300,2,3,1998/10/01 08:41:00,139.8739026938,35.7492478738,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:41:00,139.900753383,35.7845157203,1,7,4224202,9 ,1 ,37,,12",
 "151800,2,4,1998/10/01 08:41:00,139.8033838885,35.6961624577,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:41:00,139.9004560526,35.7836446375,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 08:41:00,140.0599531159,35.8914665896,2,5,5412112,9 ,1 ,35,,12",
 "209300,2,3,1998/10/01 08:41:00,139.6740794494,35.5017847232,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:41:00,139.6220858971,35.3445542848,1,7,2220004,6 ,1 ,36,,12",
 "4600,2,2,1998/10/01 08:41:00,139.9470409759,35.7518066724,1,11,4112301,10,1 ,37,,6",
 "227700,2,3,1998/10/01 08:41:00,139.7000740103,35.6990532147,1,9,2410203,8 ,10,16,,12",
 "225400,2,4,1998/10/01 08:41:00,139.480255202,35.3401619524,1,3,2313201,13,2 ,36,,1",
 "66700,2,2,1998/10/01 08:41:00,139.9486575267,35.8485549234,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:41:00,140.0626367834,35.719622044,1,8,4417027,9 ,10,46,,6",
 "98900,2,2,1998/10/01 08:41:00,140.0790709064,35.4849880779,2,11,4511022,8 ,1 ,32,,6",
 "324300,2,3,1998/10/01 08:41:00,139.6604884102,35.6328051866,1,10,2110103,8 ,1 ,29,,12",
 "322000,2,3,1998/10/01 08:41:00,139.7039438068,35.6566279833,2,5,2032102,8 ,1 ,36,,12",
 "181700,2,2,1998/10/01 08:41:00,140.0962807729,36.0955916122,2,7,5123003,8 ,1 ,31,,6",
 "345000,2,4,1998/10/01 08:41:00,139.7315552652,35.7309408877,1,8,3012002,9 ,1 ,37,,12",
 "271400,2,2,1998/10/01 08:41:00,139.3677015496,35.4651494569,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:41:00,139.7210723489,35.7751352478,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,2,1998/10/01 08:41:00,139.6714481652,35.8670973644,1,10,3033223,10,1 ,38,,1",
 "404800,2,3,1998/10/01 08:41:00,139.6998883209,35.7162195202,2,9,3120009,8 ,1 ,30,,12",
 "361100,2,2,1998/10/01 08:41:00,139.6422462939,35.8603654729,1,6,3026023,9 ,1 ,32,,6",
 "374900,2,2,1998/10/01 08:41:00,139.7074543701,35.7596699081,1,4,3040108,4 ,1 ,71,,6",
 "418600,2,4,1998/10/01 08:41:00,139.4079899823,35.8362665539,1,11,3123503,8 ,1 ,23,,12",
 "379500,2,2,1998/10/01 08:41:00,139.6137042888,35.9133221171,2,6,3053202,8 ,1 ,31,,1",
 "381800,2,2,1998/10/01 08:41:00,139.5759834248,35.9036906895,1,15,3055105,10,1 ,32,,6",
 "446200,2,3,1998/10/01 08:41:00,139.8010287785,35.7373197697,2,5,3221102,8 ,1 ,37,,12",
 "448500,2,2,1998/10/01 08:41:00,139.7841963143,35.9306425147,2,7,3222102,4 ,1 ,27,,1",
 "430100,6,6,1998/10/01 08:41:00,139.4104453099,35.8585396771,1,14,3135204,15,7 ,33,,6",
 "441600,4,4,1998/10/01 08:41:00,139.788239868,35.7011968708,1,9,3215009,9 ,11,30,,8",
 "473800,2,3,1998/10/01 08:41:00,139.5294925777,35.9035471946,2,10,3411010,8 ,1 ,30,,12",
 "478400,2,3,1998/10/01 08:41:00,139.402378193,36.0301040374,1,4,3413105,13,2 ,40,,12",
 "483000,2,3,1998/10/01 08:41:00,139.7764808435,35.6941659687,1,10,3510101,8 ,1 ,27,,12",
 "455400,2,2,1998/10/01 08:41:00,139.8239197953,35.9123997224,1,12,3226003,3 ,11,31,,8",
 "462300,4,4,1998/10/01 08:41:00,139.7514116095,35.8771435256,1,8,3235004,9 ,11,38,,8",
 "522100,2,2,1998/10/01 08:41:00,139.2602413506,36.1903346991,1,3,3722104,13,2 ,35,,2",
 "563500,2,3,1998/10/01 08:41:00,139.8196388085,35.6451108576,1,7,0212001,10,1 ,84,,12",
 "577300,2,2,1998/10/01 08:41:00,139.808781639,35.7095327258,1,4,0311012,9 ,1 ,27,,8",
 "604900,2,2,1998/10/01 08:41:00,139.6089084079,35.6879882092,2,1,0423005,12,2 ,49,,1",
 "611800,2,3,1998/10/01 08:41:00,139.5625143131,35.5778289401,1,4,0431110,13,2 ,65,,12",
 "621000,2,2,1998/10/01 08:41:00,139.6484819743,35.6566705513,2,10,0436007,14,7 ,45,,1",
 "667000,2,2,1998/10/01 08:41:00,139.7786458431,35.7805131389,2,12,0614310,6 ,1 ,43,,2",
 "701500,2,2,1998/10/01 08:41:00,139.5639497545,35.7090292403,2,10,0721104,9 ,13,48,,2",
 "823400,2,4,1998/10/01 08:41:00,139.6104011767,35.454147629,1,10,1213105,9 ,13,34,,10",
 "724500,2,2,1998/10/01 08:41:00,139.5367471048,35.6814463452,2,10,0761001,8 ,1 ,33,,7",
 "742900,4,4,1998/10/01 08:41:00,139.3504015769,35.7210076195,1,8,0784113,10,3 ,38,,7",
 "786600,2,4,1998/10/01 08:41:00,139.2900868795,35.6466431383,2,3,0930110,13,2 ,35,,9",
 "759000,2,4,1998/10/01 08:41:00,139.7448828558,35.7007329421,2,4,0821006,9 ,1 ,55,,1",
 "708400,2,2,1998/10/01 08:41:00,139.5397405541,35.7220096475,2,8,0731211,8 ,1 ,34,,1",
 "696900,2,3,1998/10/01 08:41:00,139.7373442499,35.6195602741,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:41:00,139.6053357642,35.7039345148,1,7,0752005,6 ,10,34,,12",
 "830300,2,4,1998/10/01 08:42:00,139.7680099163,35.6841591468,2,4,1251203,8 ,1 ,37,,1",
 "128800,2,3,1998/10/01 08:42:00,140.4004759336,35.8079664606,1,14,4831221,15,7 ,26,,10",
 "280600,5,5,1998/10/01 08:42:00,139.762546841,35.7478199613,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:42:00,139.7585908484,35.6628425807,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,4,1998/10/01 08:42:00,139.769376261,35.6925141565,1,6,4111102,8 ,1 ,36,,1",
 "363400,4,5,1998/10/01 08:42:00,139.6399394505,35.7875042994,1,6,3032201,3 ,11,34,,6",
 "16100,2,4,1998/10/01 08:42:00,139.7664317495,35.6798553572,2,4,4116209,8 ,1 ,48,,1",
 "48300,2,3,1998/10/01 08:42:00,139.8695538066,35.7498843119,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:42:00,139.898643104,35.7786601408,1,7,4224202,9 ,1 ,37,,12",
 "151800,2,4,1998/10/01 08:42:00,139.7965453138,35.6959091747,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:42:00,139.8987134645,35.7788590334,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 08:42:00,140.0568898981,35.8868715718,2,5,5412112,9 ,1 ,35,,12",
 "209300,2,3,1998/10/01 08:42:00,139.6778455467,35.5070424342,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:42:00,139.6256129131,35.350190207,1,7,2220004,6 ,1 ,36,,12",
 "4600,2,2,1998/10/01 08:42:00,139.9491756966,35.7529038022,1,11,4112301,10,1 ,37,,6",
 "227700,2,3,1998/10/01 08:42:00,139.7011936381,35.7054320404,1,9,2410203,8 ,10,16,,12",
 "225400,2,4,1998/10/01 08:42:00,139.4801144638,35.3406370994,1,3,2313201,13,2 ,36,,1",
 "66700,2,2,1998/10/01 08:42:00,139.9486109447,35.8486408761,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:42:00,140.0594603883,35.7206545983,1,8,4417027,9 ,10,46,,6",
 "98900,2,2,1998/10/01 08:42:00,140.0799546976,35.4875244981,2,11,4511022,8 ,1 ,32,,6",
 "322000,2,3,1998/10/01 08:42:00,139.7011989198,35.6597515901,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,3,1998/10/01 08:42:00,139.6637862346,35.6349344854,1,10,2110103,8 ,1 ,29,,12",
 "181700,2,2,1998/10/01 08:42:00,140.1001087799,36.0919203052,2,7,5123003,8 ,1 ,31,,6",
 "345000,2,4,1998/10/01 08:42:00,139.7281009668,35.7317863102,1,8,3012002,9 ,1 ,37,,12",
 "345000,2,5,1998/10/01 08:42:00,139.7281009668,35.7317863102,1,8,3012002,9 ,1 ,37,,1",
 "271400,2,2,1998/10/01 08:42:00,139.3693533117,35.4650836267,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:42:00,139.7220344385,35.7712779364,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,2,1998/10/01 08:42:00,139.6713764992,35.8663696004,1,10,3033223,10,1 ,38,,1",
 "404800,2,3,1998/10/01 08:42:00,139.7047913281,35.715162433,2,9,3120009,8 ,1 ,30,,12",
 "361100,2,2,1998/10/01 08:42:00,139.6431890998,35.861557216,1,6,3026023,9 ,1 ,32,,6",
 "418600,2,4,1998/10/01 08:42:00,139.4101504257,35.8320594221,1,11,3123503,8 ,1 ,23,,12",
 "374900,2,2,1998/10/01 08:42:00,139.7087584442,35.7566295399,1,4,3040108,4 ,1 ,71,,6",
 "379500,2,2,1998/10/01 08:42:00,139.6139510066,35.9123116596,2,6,3053202,8 ,1 ,31,,1",
 "381800,2,2,1998/10/01 08:42:00,139.5746333211,35.905140856,1,15,3055105,10,1 ,32,,6",
 "446200,2,3,1998/10/01 08:42:00,139.7999321023,35.7345676362,2,5,3221102,8 ,1 ,37,,12",
 "448500,2,2,1998/10/01 08:42:00,139.7833445206,35.9312355416,2,7,3222102,4 ,1 ,27,,1",
 "430100,6,6,1998/10/01 08:42:00,139.4126187761,35.858345209,1,14,3135204,15,7 ,33,,6",
 "473800,2,3,1998/10/01 08:42:00,139.5347369715,35.9035978021,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 08:42:00,139.7870195548,35.6995494801,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 08:42:00,139.7783320823,35.6909307436,1,10,3510101,8 ,1 ,27,,12",
 "478400,2,3,1998/10/01 08:42:00,139.4014835576,36.0348214344,1,4,3413105,13,2 ,40,,12",
 "478400,2,4,1998/10/01 08:42:00,139.4014835576,36.0348214344,1,4,3413105,13,2 ,40,,9",
 "455400,2,2,1998/10/01 08:42:00,139.8239315594,35.9110452816,1,12,3226003,3 ,11,31,,8",
 "462300,4,4,1998/10/01 08:42:00,139.7507920268,35.8771053946,1,8,3235004,9 ,11,38,,8",
 "522100,2,2,1998/10/01 08:42:00,139.2591069058,36.1911563022,1,3,3722104,13,2 ,35,,2",
 "563500,2,3,1998/10/01 08:42:00,139.823280535,35.6454170941,1,7,0212001,10,1 ,84,,12",
 "577300,2,2,1998/10/01 08:42:00,139.8117302613,35.7096838382,1,4,0311012,9 ,1 ,27,,8",
 "604900,2,2,1998/10/01 08:42:00,139.6088968993,35.6879698835,2,1,0423005,12,2 ,49,,1",
 "611800,2,3,1998/10/01 08:42:00,139.5566191636,35.5765335952,1,4,0431110,13,2 ,65,,12",
 "621000,2,2,1998/10/01 08:42:00,139.6485648342,35.6584629299,2,10,0436007,14,7 ,45,,1",
 "667000,2,2,1998/10/01 08:42:00,139.7782403643,35.7805037277,2,12,0614310,6 ,1 ,43,,2",
 "701500,2,2,1998/10/01 08:42:00,139.5634463062,35.7081778347,2,10,0721104,9 ,13,48,,2",
 "724500,2,2,1998/10/01 08:42:00,139.5395456217,35.6813978389,2,10,0761001,8 ,1 ,33,,7",
 "823400,2,4,1998/10/01 08:42:00,139.6108755332,35.4541677172,1,10,1213105,9 ,13,34,,10",
 "742900,4,4,1998/10/01 08:42:00,139.3507187921,35.7180420522,1,8,0784113,10,3 ,38,,7",
 "786600,2,4,1998/10/01 08:42:00,139.2916892342,35.647174876,2,3,0930110,13,2 ,35,,9",
 "759000,2,4,1998/10/01 08:42:00,139.7448284252,35.7005241996,2,4,0821006,9 ,1 ,55,,1",
 "696900,2,3,1998/10/01 08:42:00,139.7364465146,35.616050471,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:42:00,139.6090333899,35.7040353218,1,7,0752005,6 ,10,34,,12",
 "708400,2,2,1998/10/01 08:42:00,139.5394149741,35.7226897774,2,8,0731211,8 ,1 ,34,,1",
 "830300,2,4,1998/10/01 08:43:00,139.770577337,35.6836028859,2,4,1251203,8 ,1 ,37,,1",
 "128800,2,3,1998/10/01 08:43:00,140.400476261,35.8079667279,1,14,4831221,15,7 ,26,,10",
 "280600,5,5,1998/10/01 08:43:00,139.7621329244,35.7506230788,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:43:00,139.7592823266,35.6667091041,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,4,1998/10/01 08:43:00,139.7694891578,35.6922066004,1,6,4111102,8 ,1 ,36,,1",
 "363400,4,5,1998/10/01 08:43:00,139.6423098864,35.7866929698,1,6,3032201,3 ,11,34,,6",
 "16100,2,4,1998/10/01 08:43:00,139.7660422684,35.6791226838,2,4,4116209,8 ,1 ,48,,1",
 "48300,2,3,1998/10/01 08:43:00,139.8651723254,35.7504093737,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:43:00,139.8960340451,35.772964187,1,7,4224202,9 ,1 ,37,,12",
 "151800,2,4,1998/10/01 08:43:00,139.7897382632,35.6964796046,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:43:00,139.8968096962,35.7741200991,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 08:43:00,140.0539366079,35.882230718,2,5,5412112,9 ,1 ,35,,12",
 "209300,2,3,1998/10/01 08:43:00,139.6826909077,35.5116725973,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:43:00,139.6279746689,35.3559991935,1,7,2220004,6 ,1 ,36,,12",
 "4600,2,2,1998/10/01 08:43:00,139.9512493733,35.7540633119,1,11,4112301,10,1 ,37,,6",
 "225400,2,4,1998/10/01 08:43:00,139.4800002492,35.3411166458,1,3,2313201,13,2 ,36,,1",
 "227700,2,3,1998/10/01 08:43:00,139.7034084762,35.7116400472,1,9,2410203,8 ,10,16,,12",
 "66700,2,2,1998/10/01 08:43:00,139.9485185443,35.848827212,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:43:00,140.0555891718,35.7234311763,1,8,4417027,9 ,10,46,,6",
 "98900,2,2,1998/10/01 08:43:00,140.0783398831,35.4897992978,2,11,4511022,8 ,1 ,32,,6",
 "322000,2,3,1998/10/01 08:43:00,139.7016642509,35.6636920134,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,3,1998/10/01 08:43:00,139.6664926714,35.6375280204,1,10,2110103,8 ,1 ,29,,12",
 "181700,2,2,1998/10/01 08:43:00,140.1044066142,36.0916960876,2,7,5123003,8 ,1 ,31,,6",
 "345000,2,5,1998/10/01 08:43:00,139.7268726944,35.7323495419,1,8,3012002,9 ,1 ,37,,1",
 "271400,2,2,1998/10/01 08:43:00,139.3710046342,35.4650108268,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:43:00,139.7221005287,35.7672894289,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,2,1998/10/01 08:43:00,139.6711962963,35.8656674244,1,10,3033223,10,1 ,38,,1",
 "312800,4,4,1998/10/01 08:43:00,139.7020869583,35.5069223608,2,6,2015203,14,3 ,34,,1",
 "404800,2,3,1998/10/01 08:43:00,139.7060163851,35.7128025921,2,9,3120009,8 ,1 ,30,,12",
 "361100,2,2,1998/10/01 08:43:00,139.6436097131,35.8630819026,1,6,3026023,9 ,1 ,32,,6",
 "418600,2,4,1998/10/01 08:43:00,139.4112678968,35.8275393792,1,11,3123503,8 ,1 ,23,,12",
 "374900,2,2,1998/10/01 08:43:00,139.7096716615,35.7534087054,1,4,3040108,4 ,1 ,71,,6",
 "379500,2,2,1998/10/01 08:43:00,139.6142383041,35.9113058879,2,6,3053202,8 ,1 ,31,,1",
 "381800,2,2,1998/10/01 08:43:00,139.5743646953,35.9068504148,1,15,3055105,10,1 ,32,,6",
 "446200,2,3,1998/10/01 08:43:00,139.7986361189,35.7321084797,2,5,3221102,8 ,1 ,37,,12",
 "448500,2,2,1998/10/01 08:43:00,139.7824927142,35.9318285624,2,7,3222102,4 ,1 ,27,,1",
 "430100,6,6,1998/10/01 08:43:00,139.4144499613,35.8590583765,1,14,3135204,15,7 ,33,,6",
 "473800,2,3,1998/10/01 08:43:00,139.5399812933,35.9036539345,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 08:43:00,139.7859250002,35.6978421247,1,9,3215009,9 ,11,30,,8",
 "478400,2,4,1998/10/01 08:43:00,139.4003869167,36.0355545756,1,4,3413105,13,2 ,40,,9",
 "483000,2,3,1998/10/01 08:43:00,139.7808793658,35.687988097,1,10,3510101,8 ,1 ,27,,12",
 "455400,2,2,1998/10/01 08:43:00,139.8231817186,35.9100009393,1,12,3226003,3 ,11,31,,8",
 "462300,4,4,1998/10/01 08:43:00,139.7500085967,35.877595098,1,8,3235004,9 ,11,38,,8",
 "522100,2,2,1998/10/01 08:43:00,139.2578825282,36.191886749,1,3,3722104,13,2 ,35,,2",
 "563500,2,3,1998/10/01 08:43:00,139.8266788862,35.645826182,1,7,0212001,10,1 ,84,,12",
 "563500,2,4,1998/10/01 08:43:00,139.8266788862,35.645826182,1,7,0212001,10,1 ,84,,11",
 "577300,2,2,1998/10/01 08:43:00,139.8146015585,35.7094825782,1,4,0311012,9 ,1 ,27,,8",
 "604900,2,2,1998/10/01 08:43:00,139.609727731,35.687809602,2,1,0423005,12,2 ,49,,1",
 "611800,2,3,1998/10/01 08:43:00,139.5541490756,35.5719947682,1,4,0431110,13,2 ,65,,12",
 "621000,2,2,1998/10/01 08:43:00,139.6481524395,35.6602082116,2,10,0436007,14,7 ,45,,1",
 "667000,2,2,1998/10/01 08:43:00,139.7783594448,35.7805081265,2,12,0614310,6 ,1 ,43,,2",
 "701500,2,2,1998/10/01 08:43:00,139.5629466059,35.707324943,2,10,0721104,9 ,13,48,,2",
 "724500,2,2,1998/10/01 08:43:00,139.542341429,35.6815936897,2,10,0761001,8 ,1 ,33,,7",
 "823400,2,4,1998/10/01 08:43:00,139.61134989,35.4541878035,1,10,1213105,9 ,13,34,,10",
 "742900,4,4,1998/10/01 08:43:00,139.3504950157,35.715587781,1,8,0784113,10,3 ,38,,7",
 "786600,2,4,1998/10/01 08:43:00,139.2922824265,35.6479444823,2,3,0930110,13,2 ,35,,9",
 "759000,2,4,1998/10/01 08:43:00,139.7447739948,35.700315457,2,4,0821006,9 ,1 ,55,,1",
 "696900,2,3,1998/10/01 08:43:00,139.7357005939,35.612519329,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:43:00,139.6127300105,35.7041572729,1,7,0752005,6 ,10,34,,12",
 "708400,2,2,1998/10/01 08:43:00,139.5390893884,35.7233699062,2,8,0731211,8 ,1 ,34,,1",
 "830300,2,4,1998/10/01 08:44:00,139.7707646157,35.6817695626,2,4,1251203,8 ,1 ,37,,1",
 "128800,2,3,1998/10/01 08:44:00,140.4004765885,35.8079669953,1,14,4831221,15,7 ,26,,10",
 "280600,5,5,1998/10/01 08:44:00,139.7624700932,35.7533929448,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,3,1998/10/01 08:44:00,139.7603888936,35.67124723,1,10,1231001,8 ,1 ,29,,12",
 "2300,2,4,1998/10/01 08:44:00,139.7694800692,35.6918818364,1,6,4111102,8 ,1 ,36,,1",
 "363400,4,5,1998/10/01 08:44:00,139.6446250838,35.7858512755,1,6,3032201,3 ,11,34,,6",
 "16100,2,4,1998/10/01 08:44:00,139.7656527944,35.6783900091,2,4,4116209,8 ,1 ,48,,1",
 "48300,2,3,1998/10/01 08:44:00,139.8610769496,35.7492222873,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:44:00,139.8899180651,35.7696917967,1,7,4224202,9 ,1 ,37,,12",
 "151800,2,4,1998/10/01 08:44:00,139.7836002078,35.6942997727,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:44:00,139.8926943578,35.7705532596,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 08:44:00,140.051137866,35.8775313228,2,5,5412112,9 ,1 ,35,,12",
 "209300,2,3,1998/10/01 08:44:00,139.6861414452,35.5170826519,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:44:00,139.6295630245,35.3621600292,1,7,2220004,6 ,1 ,36,,12",
 "4600,2,2,1998/10/01 08:44:00,139.9531397586,35.7554130374,1,11,4112301,10,1 ,37,,6",
 "227700,2,3,1998/10/01 08:44:00,139.7054916574,35.7178780049,1,9,2410203,8 ,10,16,,12",
 "225400,2,4,1998/10/01 08:44:00,139.4795709781,35.3414284998,1,3,2313201,13,2 ,36,,1",
 "66700,2,2,1998/10/01 08:44:00,139.9484261436,35.8490135478,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:44:00,140.0516118678,35.7261080971,1,8,4417027,9 ,10,46,,6",
 "98900,2,2,1998/10/01 08:44:00,140.0773262548,35.4922737366,2,11,4511022,8 ,1 ,32,,6",
 "322000,2,3,1998/10/01 08:44:00,139.7021449855,35.6676315447,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,3,1998/10/01 08:44:00,139.6687083711,35.6404485227,1,10,2110103,8 ,1 ,29,,12",
 "181700,2,2,1998/10/01 08:44:00,140.1085302582,36.0908778221,2,7,5123003,8 ,1 ,31,,6",
 "345000,2,5,1998/10/01 08:44:00,139.7259362549,35.7321789826,1,8,3012002,9 ,1 ,37,,1",
 "271400,2,2,1998/10/01 08:44:00,139.3726569756,35.4649599979,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:44:00,139.7221694851,35.7633008169,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,2,1998/10/01 08:44:00,139.6704003309,35.8652496177,1,10,3033223,10,1 ,38,,1",
 "312800,4,4,1998/10/01 08:44:00,139.7014087993,35.5069172984,2,6,2015203,14,3 ,34,,1",
 "404800,2,3,1998/10/01 08:44:00,139.7109697869,35.7113103736,2,9,3120009,8 ,1 ,30,,12",
 "361100,2,2,1998/10/01 08:44:00,139.6442233648,35.8644510792,1,6,3026023,9 ,1 ,32,,6",
 "374900,2,2,1998/10/01 08:44:00,139.712154059,35.7508058594,1,4,3040108,4 ,1 ,71,,6",
 "418600,2,4,1998/10/01 08:44:00,139.412273475,35.8230018834,1,11,3123503,8 ,1 ,23,,12",
 "379500,2,2,1998/10/01 08:44:00,139.6145476868,35.9103041549,2,6,3053202,8 ,1 ,31,,1",
 "381800,2,2,1998/10/01 08:44:00,139.5721143808,35.9083233291,1,15,3055105,10,1 ,32,,6",
 "446200,2,3,1998/10/01 08:44:00,139.795775692,35.7305827759,2,5,3221102,8 ,1 ,37,,12",
 "448500,2,2,1998/10/01 08:44:00,139.7815696005,35.9320948052,2,7,3222102,4 ,1 ,27,,1",
 "430100,6,6,1998/10/01 08:44:00,139.4164182123,35.858262646,1,14,3135204,15,7 ,33,,6",
 "473800,2,3,1998/10/01 08:44:00,139.5451988242,35.9039030675,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 08:44:00,139.7840862798,35.6972497772,1,9,3215009,9 ,11,30,,8",
 "478400,2,4,1998/10/01 08:44:00,139.4006772649,36.0364417889,1,4,3413105,13,2 ,40,,9",
 "483000,2,3,1998/10/01 08:44:00,139.7831878992,35.6849592861,1,10,3510101,8 ,1 ,27,,12",
 "455400,2,2,1998/10/01 08:44:00,139.8237177155,35.9086901009,1,12,3226003,3 ,11,31,,8",
 "462300,4,4,1998/10/01 08:44:00,139.7491853786,35.8773085558,1,8,3235004,9 ,11,38,,8",
 "522100,2,2,1998/10/01 08:44:00,139.2570970467,36.192072973,1,3,3722104,13,2 ,35,,2",
 "563500,2,4,1998/10/01 08:44:00,139.823923604,35.644133969,1,7,0212001,10,1 ,84,,11",
 "577300,2,2,1998/10/01 08:44:00,139.8173951011,35.7086767549,1,4,0311012,9 ,1 ,27,,8",
 "604900,2,2,1998/10/01 08:44:00,139.6105498107,35.6876215546,2,1,0423005,12,2 ,49,,1",
 "611800,2,3,1998/10/01 08:44:00,139.5531924542,35.5669611733,1,4,0431110,13,2 ,65,,12",
 "621000,2,2,1998/10/01 08:44:00,139.6476391413,35.6619514656,2,10,0436007,14,7 ,45,,1",
 "667000,2,2,1998/10/01 08:44:00,139.7787649377,35.7805261033,2,12,0614310,6 ,1 ,43,,2",
 "701500,2,2,1998/10/01 08:44:00,139.5635449191,35.7068938946,2,10,0721104,9 ,13,48,,2",
 "823400,2,4,1998/10/01 08:44:00,139.611824247,35.4542078879,1,10,1213105,9 ,13,34,,10",
 "724500,2,2,1998/10/01 08:44:00,139.5451339687,35.6818174764,2,10,0761001,8 ,1 ,33,,7",
 "742900,4,4,1998/10/01 08:44:00,139.3491769466,35.7134037606,1,8,0784113,10,3 ,38,,7",
 "786600,2,4,1998/10/01 08:44:00,139.2912693333,35.649088702,2,3,0930110,13,2 ,35,,9",
 "759000,2,4,1998/10/01 08:44:00,139.7447195648,35.7001067143,2,4,0821006,9 ,1 ,55,,1",
 "719900,2,4,1998/10/01 08:44:00,139.616424893,35.7043099228,1,7,0752005,6 ,10,34,,12",
 "708400,2,2,1998/10/01 08:44:00,139.5387637972,35.7240500342,2,8,0731211,8 ,1 ,34,,1",
 "696900,2,3,1998/10/01 08:44:00,139.7352548086,35.6089522485,2,6,0720006,4 ,1 ,39,,12",
 "830300,2,4,1998/10/01 08:45:00,139.7725045644,35.6803555317,2,4,1251203,8 ,1 ,37,,1",
 "128800,2,3,1998/10/01 08:45:00,140.4004769159,35.8079672626,1,14,4831221,15,7 ,26,,10",
 "869400,2,3,1998/10/01 08:45:00,139.7626001533,35.6754601003,1,10,1231001,8 ,1 ,29,,12",
 "869400,2,4,1998/10/01 08:45:00,139.7626001533,35.6754601003,1,10,1231001,8 ,1 ,29,,1",
 "280600,5,5,1998/10/01 08:45:00,139.7626625105,35.7561530807,1,11,2910009,8 ,9 ,42,,8",
 "2300,2,4,1998/10/01 08:45:00,139.7694709806,35.6915570723,1,6,4111102,8 ,1 ,36,,1",
 "2300,3,5,1998/10/01 08:45:00,139.7694709806,35.6915570723,1,6,4111102,8 ,97,36,,97",
 "363400,4,5,1998/10/01 08:45:00,139.6462099691,35.7841894289,1,6,3032201,3 ,11,34,,6",
 "16100,2,4,1998/10/01 08:45:00,139.7655320257,35.6777654454,2,4,4116209,8 ,1 ,48,,1",
 "48300,2,3,1998/10/01 08:45:00,139.8576734868,35.7470708429,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:45:00,139.882557713,35.7689410479,1,7,4224202,9 ,1 ,37,,12",
 "151800,2,4,1998/10/01 08:45:00,139.7803298548,35.6942277071,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:45:00,139.8868317521,35.7692171939,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 08:45:00,140.0465336286,35.8744445249,2,5,5412112,9 ,1 ,35,,12",
 "209300,2,3,1998/10/01 08:45:00,139.6904785375,35.5220339872,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:45:00,139.6294904006,35.3685276392,1,7,2220004,6 ,1 ,36,,12",
 "4600,2,2,1998/10/01 08:45:00,139.9554446259,35.7555380946,1,11,4112301,10,1 ,37,,6",
 "227700,2,3,1998/10/01 08:45:00,139.7079400851,35.7240062941,1,9,2410203,8 ,10,16,,12",
 "225400,2,4,1998/10/01 08:45:00,139.4790844738,35.3417116201,1,3,2313201,13,2 ,36,,1",
 "225400,3,5,1998/10/01 08:45:00,139.4790844738,35.3417116201,1,3,2313201,13,97,36,,97",
 "66700,2,2,1998/10/01 08:45:00,139.9483337424,35.8491998836,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:45:00,140.0467210481,35.7273543374,1,8,4417027,9 ,10,46,,6",
 "98900,2,2,1998/10/01 08:45:00,140.0754774008,35.4942075257,2,11,4511022,8 ,1 ,32,,6",
 "322000,2,3,1998/10/01 08:45:00,139.7027731387,35.6715540248,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,3,1998/10/01 08:45:00,139.6709629774,35.6433425579,1,10,2110103,8 ,1 ,29,,12",
 "181700,2,2,1998/10/01 08:45:00,140.1111140122,36.0887733889,2,7,5123003,8 ,1 ,31,,6",
 "181700,3,3,1998/10/01 08:45:00,140.1111140122,36.0887733889,2,7,5123003,8 ,97,31,,97",
 "345000,2,5,1998/10/01 08:45:00,139.7255491893,35.7310796853,1,8,3012002,9 ,1 ,37,,1",
 "271400,2,2,1998/10/01 08:45:00,139.3743087419,35.4649012818,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:45:00,139.7222300873,35.759312205,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,2,1998/10/01 08:45:00,139.6695781018,35.8648637962,1,10,3033223,10,1 ,38,,1",
 "312800,4,4,1998/10/01 08:45:00,139.7007326412,35.5068946587,2,6,2015203,14,3 ,34,,1",
 "404800,2,3,1998/10/01 08:45:00,139.715774429,35.7095648816,2,9,3120009,8 ,1 ,30,,12",
 "361100,2,2,1998/10/01 08:45:00,139.644941031,35.8654539586,1,6,3026023,9 ,1 ,32,,6",
 "361100,3,3,1998/10/01 08:45:00,139.644941031,35.8654539586,1,6,3026023,9 ,97,32,,97",
 "418600,2,4,1998/10/01 08:45:00,139.4133136983,35.8184698423,1,11,3123503,8 ,1 ,23,,12",
 "374900,2,2,1998/10/01 08:45:00,139.7154294197,35.7491508785,1,4,3040108,4 ,1 ,71,,6",
 "379500,2,2,1998/10/01 08:45:00,139.6146584883,35.9092776193,2,6,3053202,8 ,1 ,31,,1",
 "379500,3,3,1998/10/01 08:45:00,139.6146584883,35.9092776193,2,6,3053202,8 ,97,31,,97",
 "381800,2,2,1998/10/01 08:45:00,139.5704443745,35.9102772353,1,15,3055105,10,1 ,32,,6",
 "381800,3,3,1998/10/01 08:45:00,139.5704443745,35.9102772353,1,15,3055105,10,97,32,,97",
 "448500,2,2,1998/10/01 08:45:00,139.7806278433,35.9321546874,2,7,3222102,4 ,1 ,27,,1",
 "446200,2,3,1998/10/01 08:45:00,139.7923569423,35.7303032724,2,5,3221102,8 ,1 ,37,,12",
 "430100,6,6,1998/10/01 08:45:00,139.4171503036,35.8572340695,1,14,3135204,15,7 ,33,,6",
 "430100,7,7,1998/10/01 08:45:00,139.4171503036,35.8572340695,1,14,3135204,15,97,33,,97",
 "473800,2,3,1998/10/01 08:45:00,139.5493302239,35.9064663344,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 08:45:00,139.7817336314,35.6974227492,1,9,3215009,9 ,11,30,,8",
 "478400,2,4,1998/10/01 08:45:00,139.4009409695,36.0367442152,1,4,3413105,13,2 ,40,,9",
 "483000,2,3,1998/10/01 08:45:00,139.781284315,35.6817655042,1,10,3510101,8 ,1 ,27,,12",
 "455400,2,2,1998/10/01 08:45:00,139.8242536947,35.9073792598,1,12,3226003,3 ,11,31,,8",
 "462300,4,4,1998/10/01 08:45:00,139.7484507468,35.8767858362,1,8,3235004,9 ,11,38,,8",
 "462300,5,5,1998/10/01 08:45:00,139.7484507468,35.8767858362,1,8,3235004,9 ,97,38,,97",
 "522100,2,2,1998/10/01 08:45:00,139.2576223631,36.1909152264,1,3,3722104,13,2 ,35,,2",
 "522100,3,3,1998/10/01 08:45:00,139.2576223631,36.1909152264,1,3,3722104,13,97,35,,97",
 "563500,2,4,1998/10/01 08:45:00,139.8205653615,35.6431926435,1,7,0212001,10,1 ,84,,11",
 "577300,2,2,1998/10/01 08:45:00,139.8200644733,35.7076287142,1,4,0311012,9 ,1 ,27,,8",
 "611800,2,3,1998/10/01 08:45:00,139.5527347052,35.561887242,1,4,0431110,13,2 ,65,,12",
 "604900,2,2,1998/10/01 08:45:00,139.6113665912,35.6874192681,2,1,0423005,12,2 ,49,,1",
 "621000,2,2,1998/10/01 08:45:00,139.6460823335,35.6627731508,2,10,0436007,14,7 ,45,,1",
 "667000,2,2,1998/10/01 08:45:00,139.7791704307,35.7805440788,2,12,0614310,6 ,1 ,43,,2",
 "701500,2,2,1998/10/01 08:45:00,139.5643586298,35.7065140971,2,10,0721104,9 ,13,48,,2",
 "701500,3,3,1998/10/01 08:45:00,139.5643586298,35.7065140971,2,10,0721104,9 ,97,48,,97",
 "823400,2,4,1998/10/01 08:45:00,139.6122952002,35.4541987655,1,10,1213105,9 ,13,34,,10",
 "724500,2,2,1998/10/01 08:45:00,139.5479285974,35.6820223194,2,10,0761001,8 ,1 ,33,,7",
 "742900,4,4,1998/10/01 08:45:00,139.3480726468,35.712001832,1,8,0784113,10,3 ,38,,7",
 "742900,5,5,1998/10/01 08:45:00,139.3480726468,35.712001832,1,8,0784113,10,97,38,,97",
 "786600,2,4,1998/10/01 08:45:00,139.2921345722,35.6500376839,2,3,0930110,13,2 ,35,,9",
 "759000,2,4,1998/10/01 08:45:00,139.744665135,35.6998979717,2,4,0821006,9 ,1 ,55,,1",
 "759000,3,5,1998/10/01 08:45:00,139.744665135,35.6998979717,2,4,0821006,9 ,97,55,,97",
 "708400,2,2,1998/10/01 08:45:00,139.5387834054,35.723695033,2,8,0731211,8 ,1 ,34,,1",
 "719900,2,4,1998/10/01 08:45:00,139.6201193838,35.7044682361,1,7,0752005,6 ,10,34,,12",
 "696900,2,3,1998/10/01 08:45:00,139.7348090303,35.605385167,2,6,0720006,4 ,1 ,39,,12",
 "830300,2,4,1998/10/01 08:46:00,139.7741435317,35.6791101942,2,4,1251203,8 ,1 ,37,,1",
 "830300,3,5,1998/10/01 08:46:00,139.7741435317,35.6791101942,2,4,1251203,8 ,97,37,,97",
 "128800,2,3,1998/10/01 08:46:00,140.4004772433,35.8079675299,1,14,4831221,15,7 ,26,,10",
 "280600,5,5,1998/10/01 08:46:00,139.7617721883,35.7588638968,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,4,1998/10/01 08:46:00,139.7621733466,35.6742712595,1,10,1231001,8 ,1 ,29,,1",
 "363400,4,5,1998/10/01 08:46:00,139.6479744445,35.7830459311,1,6,3032201,3 ,11,34,,6",
 "16100,2,4,1998/10/01 08:46:00,139.7664832481,35.6775775237,2,4,4116209,8 ,1 ,48,,1",
 "48300,2,3,1998/10/01 08:46:00,139.8549777703,35.74421236,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:46:00,139.8751220582,35.7696523669,1,7,4224202,9 ,1 ,37,,12",
 "151800,2,4,1998/10/01 08:46:00,139.7739942883,35.6956326917,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:46:00,139.8807894219,35.7691145397,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 08:46:00,140.0404458245,35.8727777211,2,5,5412112,9 ,1 ,35,,12",
 "211600,2,4,1998/10/01 08:46:00,139.6259418781,35.373825453,1,7,2220004,6 ,1 ,36,,12",
 "209300,2,3,1998/10/01 08:46:00,139.6950653052,35.5268484287,1,13,2211303,10,1 ,37,,12",
 "4600,2,2,1998/10/01 08:46:00,139.9575299676,35.7544244824,1,11,4112301,10,1 ,37,,6",
 "227700,2,3,1998/10/01 08:46:00,139.7111302612,35.7299249782,1,9,2410203,8 ,10,16,,12",
 "227700,2,4,1998/10/01 08:46:00,139.7111302612,35.7299249782,1,9,2410203,8 ,10,16,,1",
 "66700,2,2,1998/10/01 08:46:00,139.9482413408,35.8493862192,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:46:00,140.0417457222,35.7285487065,1,8,4417027,9 ,10,46,,6",
 "98900,2,2,1998/10/01 08:46:00,140.0742000423,35.4966325605,2,11,4511022,8 ,1 ,32,,6",
 "322000,2,3,1998/10/01 08:46:00,139.7040799796,35.6753609424,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,3,1998/10/01 08:46:00,139.6745217196,35.6451633905,1,10,2110103,8 ,1 ,29,,12",
 "345000,2,5,1998/10/01 08:46:00,139.7252336041,35.7299598901,1,8,3012002,9 ,1 ,37,,1",
 "271400,2,2,1998/10/01 08:46:00,139.3759588643,35.4648121315,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:46:00,139.7222626447,35.7553232726,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,2,1998/10/01 08:46:00,139.6687753536,35.8644510495,1,10,3033223,10,1 ,38,,1",
 "312800,4,4,1998/10/01 08:46:00,139.7000714913,35.5067921583,2,6,2015203,14,3 ,34,,1",
 "391000,2,2,1998/10/01 08:46:00,139.6086614391,35.8134038601,1,7,3110313,8 ,1 ,33,,6",
 "404800,2,3,1998/10/01 08:46:00,139.7191821721,35.7063836346,2,9,3120009,8 ,1 ,30,,12",
 "374900,2,2,1998/10/01 08:46:00,139.718884152,35.7474011571,1,4,3040108,4 ,1 ,71,,6",
 "418600,2,4,1998/10/01 08:46:00,139.4143945601,35.8139439536,1,11,3123503,8 ,1 ,23,,12",
 "448500,2,2,1998/10/01 08:46:00,139.7797750666,35.9321937783,2,7,3222102,4 ,1 ,27,,1",
 "446200,2,3,1998/10/01 08:46:00,139.7900481961,35.7281159862,2,5,3221102,8 ,1 ,37,,12",
 "441600,4,4,1998/10/01 08:46:00,139.7793867439,35.6976411715,1,9,3215009,9 ,11,30,,8",
 "473800,2,3,1998/10/01 08:46:00,139.5530689667,35.9094590739,2,10,3411010,8 ,1 ,30,,12",
 "478400,2,4,1998/10/01 08:46:00,139.4012674276,36.0379111743,1,4,3413105,13,2 ,40,,9",
 "483000,2,3,1998/10/01 08:46:00,139.7792935235,35.6785503858,1,10,3510101,8 ,1 ,27,,12",
 "455400,2,2,1998/10/01 08:46:00,139.8248123098,35.9060801318,1,12,3226003,3 ,11,31,,8",
 "563500,2,4,1998/10/01 08:46:00,139.8171284846,35.6425279102,1,7,0212001,10,1 ,84,,11",
 "577300,2,2,1998/10/01 08:46:00,139.8227432126,35.7065755849,1,4,0311012,9 ,1 ,27,,8",
 "604900,2,2,1998/10/01 08:46:00,139.6121768078,35.6871996291,2,1,0423005,12,2 ,49,,1",
 "611800,2,3,1998/10/01 08:46:00,139.5501831741,35.557368937,1,4,0431110,13,2 ,65,,12",
 "621000,2,2,1998/10/01 08:46:00,139.6448424674,35.6639788847,2,10,0436007,14,7 ,45,,1",
 "667000,2,2,1998/10/01 08:46:00,139.7795695061,35.7805977835,2,12,0614310,6 ,1 ,43,,2",
 "724500,2,2,1998/10/01 08:46:00,139.5507238176,35.6818769362,2,10,0761001,8 ,1 ,33,,7",
 "823400,2,4,1998/10/01 08:46:00,139.6126824024,35.4540208189,1,10,1213105,9 ,13,34,,10",
 "786600,2,4,1998/10/01 08:46:00,139.2920739411,35.6509713946,2,3,0930110,13,2 ,35,,9",
 "708400,2,2,1998/10/01 08:46:00,139.5388837382,35.7244205478,2,8,0731211,8 ,1 ,34,,1",
 "696900,2,3,1998/10/01 08:46:00,139.7343632555,35.6018180848,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:46:00,139.6238171862,35.70456512,1,7,0752005,6 ,10,34,,12",
 "128800,2,3,1998/10/01 08:47:00,140.4004775707,35.8079677973,1,14,4831221,15,7 ,26,,10",
 "280600,5,5,1998/10/01 08:47:00,139.7594919331,35.7609634994,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,4,1998/10/01 08:47:00,139.7621469686,35.6731197047,1,10,1231001,8 ,1 ,29,,1",
 "363400,4,5,1998/10/01 08:47:00,139.6495548662,35.781384658,1,6,3032201,3 ,11,34,,6",
 "16100,2,4,1998/10/01 08:47:00,139.7672427991,35.6771854425,2,4,4116209,8 ,1 ,48,,1",
 "48300,2,3,1998/10/01 08:47:00,139.8525928908,35.7411705903,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:47:00,139.8676849886,35.7691846693,1,7,4224202,9 ,1 ,37,,12",
 "151800,2,4,1998/10/01 08:47:00,139.7672653736,35.6953945663,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:47:00,139.8747066362,35.7696863771,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 08:47:00,140.0342416237,35.8735936621,2,5,5412112,9 ,1 ,35,,12",
 "209300,2,3,1998/10/01 08:47:00,139.6996524944,35.5316627736,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:47:00,139.6193906162,35.3771397302,1,7,2220004,6 ,1 ,36,,12",
 "4600,2,2,1998/10/01 08:47:00,139.95978739,35.754728034,1,11,4112301,10,1 ,37,,6",
 "227700,2,4,1998/10/01 08:47:00,139.7124797805,35.7294744398,1,9,2410203,8 ,10,16,,1",
 "66700,2,2,1998/10/01 08:47:00,139.9481489387,35.8495725548,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:47:00,140.0370566093,35.7283222741,1,8,4417027,9 ,10,46,,6",
 "98900,2,2,1998/10/01 08:47:00,140.072042763,35.4958113267,2,11,4511022,8 ,1 ,32,,6",
 "98900,3,3,1998/10/01 08:47:00,140.072042763,35.4958113267,2,11,4511022,8 ,97,32,,97",
 "322000,2,3,1998/10/01 08:47:00,139.7036358478,35.6792865859,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,3,1998/10/01 08:47:00,139.6780016255,35.647095222,1,10,2110103,8 ,1 ,29,,12",
 "345000,2,5,1998/10/01 08:47:00,139.7251750193,35.7298845953,1,8,3012002,9 ,1 ,37,,1",
 "271400,2,2,1998/10/01 08:47:00,139.3776082897,35.4647148104,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:47:00,139.7221336987,35.7513408379,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,2,1998/10/01 08:47:00,139.66797875,35.8640304051,1,10,3033223,10,1 ,38,,1",
 "312800,4,4,1998/10/01 08:47:00,139.6999748263,35.5073410372,2,6,2015203,14,3 ,34,,1",
 "312800,5,5,1998/10/01 08:47:00,139.6999748263,35.5073410372,2,6,2015203,14,97,34,,97",
 "391000,2,2,1998/10/01 08:47:00,139.6073588276,35.8126159124,1,7,3110313,8 ,1 ,33,,6",
 "404800,2,3,1998/10/01 08:47:00,139.7243415941,35.7054700095,2,9,3120009,8 ,1 ,30,,12",
 "374900,2,2,1998/10/01 08:47:00,139.7222923039,35.7456092483,1,4,3040108,4 ,1 ,71,,6",
 "418600,2,4,1998/10/01 08:47:00,139.4173147371,35.8102544086,1,11,3123503,8 ,1 ,23,,12",
 "448500,2,2,1998/10/01 08:47:00,139.7790165705,35.9328472793,2,7,3222102,4 ,1 ,27,,1",
 "446200,2,3,1998/10/01 08:47:00,139.7882521311,35.7256155191,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,3,1998/10/01 08:47:00,139.5568091018,35.9124507779,2,10,3411010,8 ,1 ,30,,12",
 "478400,2,4,1998/10/01 08:47:00,139.4010714725,36.0392547951,1,4,3413105,13,2 ,40,,9",
 "441600,4,4,1998/10/01 08:47:00,139.7770325538,35.6977997618,1,9,3215009,9 ,11,30,,8",
 "483000,2,3,1998/10/01 08:47:00,139.777343513,35.675318712,1,10,3510101,8 ,1 ,27,,12",
 "455400,2,2,1998/10/01 08:47:00,139.8260292633,35.905117276,1,12,3226003,3 ,11,31,,8",
 "563500,2,4,1998/10/01 08:47:00,139.813600372,35.6422040696,1,7,0212001,10,1 ,84,,11",
 "577300,2,2,1998/10/01 08:47:00,139.8254306797,35.7055373555,1,4,0311012,9 ,1 ,27,,8",
 "604900,2,2,1998/10/01 08:47:00,139.6129929398,35.6869951955,2,1,0423005,12,2 ,49,,1",
 "611800,2,3,1998/10/01 08:47:00,139.5448988311,35.5546668972,1,4,0431110,13,2 ,65,,12",
 "621000,2,2,1998/10/01 08:47:00,139.6436284103,35.665234121,2,10,0436007,14,7 ,45,,1",
 "667000,2,2,1998/10/01 08:47:00,139.7799737712,35.7806291448,2,12,0614310,6 ,1 ,43,,2",
 "724500,2,2,1998/10/01 08:47:00,139.5535130312,35.6816317068,2,10,0761001,8 ,1 ,33,,7",
 "823400,2,4,1998/10/01 08:47:00,139.6130167179,35.4539820192,1,10,1213105,9 ,13,34,,10",
 "786600,2,4,1998/10/01 08:47:00,139.2920488954,35.6520054626,2,3,0930110,13,2 ,35,,9",
 "719900,2,4,1998/10/01 08:47:00,139.6275151814,35.704657322,1,7,0752005,6 ,10,34,,12",
 "708400,2,2,1998/10/01 08:47:00,139.5389651124,35.7251475396,2,8,0731211,8 ,1 ,34,,1",
 "696900,2,3,1998/10/01 08:47:00,139.7338246462,35.5982622252,2,6,0720006,4 ,1 ,39,,12",
 "128800,2,3,1998/10/01 08:48:00,140.4004778982,35.8079680646,1,14,4831221,15,7 ,26,,10",
 "280600,5,5,1998/10/01 08:48:00,139.7595754803,35.7630085716,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,4,1998/10/01 08:48:00,139.7633090063,35.6723227467,1,10,1231001,8 ,1 ,29,,1",
 "363400,4,5,1998/10/01 08:48:00,139.6502631794,35.7795041086,1,6,3032201,3 ,11,34,,6",
 "16100,2,4,1998/10/01 08:48:00,139.7681252503,35.6768399457,2,4,4116209,8 ,1 ,48,,1",
 "48300,2,3,1998/10/01 08:48:00,139.8497343002,35.7385377613,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:48:00,139.8602738058,35.7683317772,1,7,4224202,9 ,1 ,37,,12",
 "151800,2,4,1998/10/01 08:48:00,139.7611995914,35.6956650168,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:48:00,139.8686128645,35.7693337607,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 08:48:00,140.027830781,35.8738620945,2,5,5412112,9 ,1 ,35,,12",
 "209300,2,3,1998/10/01 08:48:00,139.7041172686,35.5365491702,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:48:00,139.6161228893,35.3829296191,1,7,2220004,6 ,1 ,36,,12",
 "4600,2,2,1998/10/01 08:48:00,139.9615904107,35.7561348155,1,11,4112301,10,1 ,37,,6",
 "227700,2,4,1998/10/01 08:48:00,139.7138292846,35.7290238861,1,9,2410203,8 ,10,16,,1",
 "66700,2,2,1998/10/01 08:48:00,139.9480565363,35.8497588903,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:48:00,140.032546082,35.7283797846,1,8,4417027,9 ,10,46,,6",
 "322000,2,3,1998/10/01 08:48:00,139.7023930482,35.6831106353,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,3,1998/10/01 08:48:00,139.6815201317,35.6489796466,1,10,2110103,8 ,1 ,29,,12",
 "345000,2,5,1998/10/01 08:48:00,139.7250607848,35.7288737497,1,8,3012002,9 ,1 ,37,,1",
 "345000,3,6,1998/10/01 08:48:00,139.7250607848,35.7288737497,1,8,3012002,9 ,97,37,,97",
 "271400,2,2,1998/10/01 08:48:00,139.379258358,35.4646252489,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:48:00,139.7208525714,35.747519189,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,2,1998/10/01 08:48:00,139.6671821547,35.8636097555,1,10,3033223,10,1 ,38,,1",
 "391000,2,2,1998/10/01 08:48:00,139.6066430687,35.8109098602,1,7,3110313,8 ,1 ,33,,6",
 "404800,2,3,1998/10/01 08:48:00,139.729510464,35.7047618869,2,9,3120009,8 ,1 ,30,,12",
 "418600,2,4,1998/10/01 08:48:00,139.4221839517,35.8079043654,1,11,3123503,8 ,1 ,23,,12",
 "374900,2,2,1998/10/01 08:48:00,139.7251687635,35.7432598081,1,4,3040108,4 ,1 ,71,,6",
 "446200,2,3,1998/10/01 08:48:00,139.7864284994,35.7231285955,2,5,3221102,8 ,1 ,37,,12",
 "448500,2,2,1998/10/01 08:48:00,139.7784018316,35.9330461428,2,7,3222102,4 ,1 ,27,,1",
 "473800,2,3,1998/10/01 08:48:00,139.5607749241,35.915216504,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 08:48:00,139.7751832772,35.6975014476,1,9,3215009,9 ,11,30,,8",
 "478400,2,4,1998/10/01 08:48:00,139.4008350316,36.0405939947,1,4,3413105,13,2 ,40,,9",
 "483000,2,3,1998/10/01 08:48:00,139.7758037824,35.6719494745,1,10,3510101,8 ,1 ,27,,12",
 "455400,2,2,1998/10/01 08:48:00,139.8264519078,35.9037959421,1,12,3226003,3 ,11,31,,8",
 "563500,2,4,1998/10/01 08:48:00,139.8100774383,35.6418439463,1,7,0212001,10,1 ,84,,11",
 "577300,2,2,1998/10/01 08:48:00,139.8281201402,35.7045037092,1,4,0311012,9 ,1 ,27,,8",
 "604900,2,2,1998/10/01 08:48:00,139.6135905204,35.6870831915,2,1,0423005,12,2 ,49,,1",
 "611800,2,3,1998/10/01 08:48:00,139.5408630012,35.5508214816,1,4,0431110,13,2 ,65,,12",
 "621000,2,2,1998/10/01 08:48:00,139.6420276334,35.6659859696,2,10,0436007,14,7 ,45,,1",
 "621000,2,3,1998/10/01 08:48:00,139.6420276334,35.6659859696,2,10,0436007,14,7 ,45,,12",
 "667000,2,2,1998/10/01 08:48:00,139.7803785628,35.7806082985,2,12,0614310,6 ,1 ,43,,2",
 "724500,2,2,1998/10/01 08:48:00,139.5563036543,35.6813919623,2,10,0761001,8 ,1 ,33,,7",
 "823400,2,4,1998/10/01 08:48:00,139.6133074576,35.4539023753,1,10,1213105,9 ,13,34,,10",
 "786600,2,4,1998/10/01 08:48:00,139.2915742714,35.6533596246,2,3,0930110,13,2 ,35,,9",
 "696900,2,3,1998/10/01 08:48:00,139.7320386802,35.5950031886,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:48:00,139.6312131857,35.7047494105,1,7,0752005,6 ,10,34,,12",
 "708400,2,2,1998/10/01 08:48:00,139.5390350636,35.7258754213,2,8,0731211,8 ,1 ,34,,1",
 "128800,2,3,1998/10/01 08:49:00,140.4004782256,35.807968332,1,14,4831221,15,7 ,26,,10",
 "280600,5,5,1998/10/01 08:49:00,139.7626336078,35.7643372421,1,11,2910009,8 ,9 ,42,,8",
 "869400,2,4,1998/10/01 08:49:00,139.7639422491,35.671478265,1,10,1231001,8 ,1 ,29,,1",
 "363400,4,5,1998/10/01 08:49:00,139.651113542,35.777808995,1,6,3032201,3 ,11,34,,6",
 "16100,2,4,1998/10/01 08:49:00,139.7690076939,35.6764944424,2,4,4116209,8 ,1 ,48,,1",
 "48300,2,3,1998/10/01 08:49:00,139.845423233,35.737724328,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:49:00,139.852833559,35.7676398227,1,7,4224202,9 ,1 ,37,,12",
 "151800,2,4,1998/10/01 08:49:00,139.7545424823,35.6958633321,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:49:00,139.8625675426,35.7685486111,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 08:49:00,140.0214358336,35.8733883838,2,5,5412112,9 ,1 ,35,,12",
 "209300,2,3,1998/10/01 08:49:00,139.708439406,35.5415236856,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:49:00,139.6135259268,35.3889424566,1,7,2220004,6 ,1 ,36,,12",
 "4600,2,2,1998/10/01 08:49:00,139.9638540832,35.7566994768,1,11,4112301,10,1 ,37,,6",
 "227700,2,4,1998/10/01 08:49:00,139.7151696345,35.7285973504,1,9,2410203,8 ,10,16,,1",
 "66700,2,2,1998/10/01 08:49:00,139.9480442732,35.8499561888,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:49:00,140.0290436117,35.7314312123,1,8,4417027,9 ,10,46,,6",
 "322000,2,3,1998/10/01 08:49:00,139.700999043,35.6868964206,2,5,2032102,8 ,1 ,36,,12",
 "324300,2,3,1998/10/01 08:49:00,139.6850395536,35.6508634519,1,10,2110103,8 ,1 ,29,,12",
 "271400,2,2,1998/10/01 08:49:00,139.3809076051,35.4645265272,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:49:00,139.7185506128,35.7439995209,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,2,1998/10/01 08:49:00,139.6663855679,35.8631891006,1,10,3033223,10,1 ,38,,1",
 "391000,2,2,1998/10/01 08:49:00,139.6072472329,35.8089719579,1,7,3110313,8 ,1 ,33,,6",
 "404800,2,3,1998/10/01 08:49:00,139.7346569858,35.7038174046,2,9,3120009,8 ,1 ,30,,12",
 "404800,2,4,1998/10/01 08:49:00,139.7346569858,35.7038174046,2,9,3120009,8 ,1 ,30,,1",
 "374900,2,2,1998/10/01 08:49:00,139.7280545256,35.7409166637,1,4,3040108,4 ,1 ,71,,6",
 "418600,2,4,1998/10/01 08:49:00,139.4271049523,35.8056260536,1,11,3123503,8 ,1 ,23,,12",
 "448500,2,2,1998/10/01 08:49:00,139.7779522863,35.9337773638,2,7,3222102,4 ,1 ,27,,1",
 "446200,2,3,1998/10/01 08:49:00,139.7845560058,35.7206658972,2,5,3221102,8 ,1 ,37,,12",
 "441600,4,4,1998/10/01 08:49:00,139.7728221447,35.697548645,1,9,3215009,9 ,11,30,,8",
 "473800,2,3,1998/10/01 08:49:00,139.5654728003,35.9171076444,2,10,3411010,8 ,1 ,30,,12",
 "478400,2,4,1998/10/01 08:49:00,139.4005966702,36.0419324598,1,4,3413105,13,2 ,40,,9",
 "483000,2,3,1998/10/01 08:49:00,139.7736125524,35.6689250646,1,10,3510101,8 ,1 ,27,,12",
 "455400,2,2,1998/10/01 08:49:00,139.8266806016,35.9024294618,1,12,3226003,3 ,11,31,,8",
 "563500,2,4,1998/10/01 08:49:00,139.8066707727,35.6410685719,1,7,0212001,10,1 ,84,,11",
 "577300,2,2,1998/10/01 08:49:00,139.8305654712,35.7038318401,1,4,0311012,9 ,1 ,27,,8",
 "604900,2,2,1998/10/01 08:49:00,139.6136370025,35.6877444185,2,1,0423005,12,2 ,49,,1",
 "611800,2,3,1998/10/01 08:49:00,139.5369231133,35.5468747354,1,4,0431110,13,2 ,65,,12",
 "621000,2,3,1998/10/01 08:49:00,139.6472872721,35.6674274709,2,10,0436007,14,7 ,45,,12",
 "667000,2,2,1998/10/01 08:49:00,139.7807804588,35.7805626087,2,12,0614310,6 ,1 ,43,,2",
 "823400,2,4,1998/10/01 08:49:00,139.6137264814,35.4540853899,1,10,1213105,9 ,13,34,,10",
 "724500,2,2,1998/10/01 08:49:00,139.5591054512,35.6813030081,2,10,0761001,8 ,1 ,33,,7",
 "786600,2,4,1998/10/01 08:49:00,139.2911912497,35.6547364508,2,3,0930110,13,2 ,35,,9",
 "708400,2,2,1998/10/01 08:49:00,139.5391050161,35.7266033029,2,8,0731211,8 ,1 ,34,,1",
 "696900,2,3,1998/10/01 08:49:00,139.7299987507,35.5918282205,2,6,0720006,4 ,1 ,39,,12",
 "719900,2,4,1998/10/01 08:49:00,139.6349112243,35.7048406847,1,7,0752005,6 ,10,34,,12",
 "786600,2,4,1998/10/01 08:50:00,139.2908082146,35.6561132754,2,3,0930110,13,2 ,35,,9",
 "128800,2,3,1998/10/01 08:50:00,140.400478553,35.8079685993,1,14,4831221,15,7 ,26,,10",
 "869400,2,4,1998/10/01 08:50:00,139.7637256449,35.6711866313,1,10,1231001,8 ,1 ,29,,1",
 "869400,3,5,1998/10/01 08:50:00,139.7637256449,35.6711866313,1,10,1231001,8 ,97,29,,97",
 "280600,5,5,1998/10/01 08:50:00,139.7621938061,35.7667369974,1,11,2910009,8 ,9 ,42,,8",
 "363400,4,5,1998/10/01 08:50:00,139.6519640009,35.7760701419,1,6,3032201,3 ,11,34,,6",
 "16100,2,4,1998/10/01 08:50:00,139.7698901298,35.6761489325,2,4,4116209,8 ,1 ,48,,1",
 "48300,2,3,1998/10/01 08:50:00,139.8414171427,35.7363027316,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:50:00,139.8458485696,35.7657547295,1,7,4224202,9 ,1 ,37,,12",
 "151800,2,4,1998/10/01 08:50:00,139.7478505944,35.6947086185,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:50:00,139.8564824669,35.7679783508,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 08:50:00,140.0150252984,35.8730967778,2,5,5412112,9 ,1 ,35,,12",
 "209300,2,3,1998/10/01 08:50:00,139.7126775686,35.5465469802,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:50:00,139.6100338961,35.3945283897,1,7,2220004,6 ,1 ,36,,12",
 "4600,2,2,1998/10/01 08:50:00,139.964680916,35.7582557714,1,11,4112301,10,1 ,37,,6",
 "4600,3,3,1998/10/01 08:50:00,139.964680916,35.7582557714,1,11,4112301,10,97,37,,97",
 "227700,2,4,1998/10/01 08:50:00,139.7163761072,35.7287062479,1,9,2410203,8 ,10,16,,1",
 "66700,2,2,1998/10/01 08:50:00,139.9479751727,35.8501491018,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:50:00,140.0253050102,35.7329102776,1,8,4417027,9 ,10,46,,6",
 "322000,2,3,1998/10/01 08:50:00,139.6998272499,35.690734646,2,5,2032102,8 ,1 ,36,,12",
 "322000,2,4,1998/10/01 08:50:00,139.6998272499,35.690734646,2,5,2032102,8 ,1 ,36,,1",
 "324300,2,3,1998/10/01 08:50:00,139.6887431136,35.6524497454,1,10,2110103,8 ,1 ,29,,12",
 "271400,2,2,1998/10/01 08:50:00,139.3825560379,35.4644185323,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:50:00,139.7165981519,35.7403431941,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,2,1998/10/01 08:50:00,139.6656696183,35.8627193272,1,10,3033223,10,1 ,38,,1",
 "391000,2,2,1998/10/01 08:50:00,139.6091508079,35.807667608,1,7,3110313,8 ,1 ,33,,6",
 "404800,2,4,1998/10/01 08:50:00,139.7399339753,35.6998857313,2,9,3120009,8 ,1 ,30,,1",
 "404800,3,5,1998/10/01 08:50:00,139.7399339753,35.6998857313,2,9,3120009,8 ,97,30,,97",
 "418600,2,4,1998/10/01 08:50:00,139.4320273189,35.8033498619,1,11,3123503,8 ,1 ,23,,12",
 "374900,2,2,1998/10/01 08:50:00,139.7309595621,35.738591446,1,4,3040108,4 ,1 ,71,,6",
 "448500,2,2,1998/10/01 08:50:00,139.777273317,35.9334388535,2,7,3222102,4 ,1 ,27,,1",
 "446200,2,3,1998/10/01 08:50:00,139.7826303211,35.7182304689,2,5,3221102,8 ,1 ,37,,12",
 "473800,2,3,1998/10/01 08:50:00,139.5702585803,35.9188549288,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 08:50:00,139.7708956124,35.6970549293,1,9,3215009,9 ,11,30,,8",
 "478400,2,4,1998/10/01 08:50:00,139.4002671557,36.043258154,1,4,3413105,13,2 ,40,,9",
 "483000,2,3,1998/10/01 08:50:00,139.7701661463,35.6670952947,1,10,3510101,8 ,1 ,27,,12",
 "455400,2,2,1998/10/01 08:50:00,139.8269482618,35.9010652712,1,12,3226003,3 ,11,31,,8",
 "563500,2,4,1998/10/01 08:50:00,139.8032454772,35.6403106086,1,7,0212001,10,1 ,84,,11",
 "577300,2,2,1998/10/01 08:50:00,139.82978686,35.7052771942,1,4,0311012,9 ,1 ,27,,8",
 "577300,3,3,1998/10/01 08:50:00,139.82978686,35.7052771942,1,4,0311012,9 ,97,27,,97",
 "604900,2,2,1998/10/01 08:50:00,139.6129839184,35.6881930672,2,1,0423005,12,2 ,49,,1",
 "604900,3,3,1998/10/01 08:50:00,139.6129839184,35.6881930672,2,1,0423005,12,97,49,,97",
 "611800,2,3,1998/10/01 08:50:00,139.5315003614,35.544487309,1,4,0431110,13,2 ,65,,12",
 "621000,2,3,1998/10/01 08:50:00,139.6526082755,35.6689478128,2,10,0436007,14,7 ,45,,12",
 "667000,2,2,1998/10/01 08:50:00,139.7811100776,35.7803867134,2,12,0614310,6 ,1 ,43,,2",
 "667000,3,3,1998/10/01 08:50:00,139.7811100776,35.7803867134,2,12,0614310,6 ,97,43,,97",
 "724500,2,2,1998/10/01 08:50:00,139.5619111509,35.6813397493,2,10,0761001,8 ,1 ,33,,7",
 "823400,2,4,1998/10/01 08:50:00,139.6141476556,35.4542650811,1,10,1213105,9 ,13,34,,10",
 "708400,2,2,1998/10/01 08:50:00,139.5389504468,35.7272263521,2,8,0731211,8 ,1 ,34,,1",
 "708400,2,3,1998/10/01 08:50:00,139.5389504468,35.7272263521,2,8,0731211,8 ,1 ,34,,12",
 "696900,2,3,1998/10/01 08:50:00,139.7282728912,35.5887192674,2,6,0720006,4 ,1 ,39,,12",
 "696900,2,4,1998/10/01 08:50:00,139.7282728912,35.5887192674,2,6,0720006,4 ,1 ,39,,1",
 "719900,2,4,1998/10/01 08:50:00,139.6386078585,35.7049601366,1,7,0752005,6 ,10,34,,12",
 "89700,7,7,1998/10/01 08:51:00,140.1632203104,35.8388460795,2,8,4422113,3 ,3 ,42,,7",
 "128800,2,3,1998/10/01 08:51:00,140.4004788804,35.8079688666,1,14,4831221,15,7 ,26,,10",
 "273700,2,2,1998/10/01 08:51:00,139.3079587941,35.3955940009,2,5,2820113,14,7 ,42,,6",
 "280600,5,5,1998/10/01 08:51:00,139.7619471797,35.7692820779,1,11,2910009,8 ,9 ,42,,8",
 "363400,4,5,1998/10/01 08:51:00,139.6531600061,35.7743159174,1,6,3032201,3 ,11,34,,6",
 "16100,2,4,1998/10/01 08:51:00,139.7707725582,35.6758034162,2,4,4116209,8 ,1 ,48,,1",
 "48300,2,3,1998/10/01 08:51:00,139.8379974159,35.7340098428,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:51:00,139.8389533418,35.7636043544,1,7,4224202,9 ,1 ,37,,12",
 "151800,2,4,1998/10/01 08:51:00,139.7414526995,35.6926932431,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:51:00,139.8504019405,35.7673788745,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 08:51:00,140.0086326945,35.8725922118,2,5,5412112,9 ,1 ,35,,12",
 "211600,2,4,1998/10/01 08:51:00,139.6032889615,35.3974256694,1,7,2220004,6 ,1 ,36,,12",
 "209300,2,3,1998/10/01 08:51:00,139.7163235553,35.5518671739,1,13,2211303,10,1 ,37,,12",
 "227700,2,4,1998/10/01 08:51:00,139.7171229501,35.7284636706,1,9,2410203,8 ,10,16,,1",
 "66700,2,2,1998/10/01 08:51:00,139.9479060718,35.8503420147,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:51:00,140.0213399382,35.7335360841,1,8,4417027,9 ,10,46,,6",
 "324300,2,3,1998/10/01 08:51:00,139.6926812797,35.6536620149,1,10,2110103,8 ,1 ,29,,12",
 "322000,2,4,1998/10/01 08:51:00,139.7146687424,35.6873054027,2,5,2032102,8 ,1 ,36,,1",
 "271400,2,2,1998/10/01 08:51:00,139.3842045445,35.4643113276,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:51:00,139.714727877,35.7366571209,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,2,1998/10/01 08:51:00,139.6653378921,35.8619951114,1,10,3033223,10,1 ,38,,1",
 "391000,2,2,1998/10/01 08:51:00,139.6109422105,35.806158376,1,7,3110313,8 ,1 ,33,,6",
 "374900,2,2,1998/10/01 08:51:00,139.7339565841,35.7363490231,1,4,3040108,4 ,1 ,71,,6",
 "418600,2,4,1998/10/01 08:51:00,139.436949406,35.8010734661,1,11,3123503,8 ,1 ,23,,12",
 "402500,4,4,1998/10/01 08:51:00,139.5148872774,35.8068617036,1,9,3116015,9 ,11,44,,8",
 "446200,2,3,1998/10/01 08:51:00,139.7807130461,35.7157907608,2,5,3221102,8 ,1 ,37,,12",
 "448500,2,2,1998/10/01 08:51:00,139.7761590096,35.9335314476,2,7,3222102,4 ,1 ,27,,1",
 "473800,2,3,1998/10/01 08:51:00,139.5750536572,35.9205853271,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 08:51:00,139.7690338597,35.6961150965,1,9,3215009,9 ,11,30,,8",
 "478400,2,4,1998/10/01 08:51:00,139.3996009787,36.0444904247,1,4,3413105,13,2 ,40,,9",
 "483000,2,3,1998/10/01 08:51:00,139.7670444278,35.6696275204,1,10,3510101,8 ,1 ,27,,12",
 "483000,2,4,1998/10/01 08:51:00,139.7670444278,35.6696275204,1,10,3510101,8 ,1 ,27,,1",
 "455400,2,2,1998/10/01 08:51:00,139.8275949561,35.8998275308,1,12,3226003,3 ,11,31,,8",
 "480700,2,2,1998/10/01 08:51:00,139.3285942653,36.0532865762,1,10,3414121,4 ,1 ,44,,6",
 "563500,2,4,1998/10/01 08:51:00,139.800822927,35.6382557209,1,7,0212001,10,1 ,84,,11",
 "611800,2,3,1998/10/01 08:51:00,139.5255157455,35.5432135107,1,4,0431110,13,2 ,65,,12",
 "621000,2,3,1998/10/01 08:51:00,139.6578749751,35.6705890201,2,10,0436007,14,7 ,45,,12",
 "678500,2,2,1998/10/01 08:51:00,139.8809918573,35.6906329552,1,1,0631106,12,2 ,49,,2",
 "724500,2,2,1998/10/01 08:51:00,139.5647167014,35.6813687232,2,10,0761001,8 ,1 ,33,,7",
 "823400,2,4,1998/10/01 08:51:00,139.6145688317,35.4544447709,1,10,1213105,9 ,13,34,,10",
 "786600,2,4,1998/10/01 08:51:00,139.2913004447,35.6570813579,2,3,0930110,13,2 ,35,,9",
 "708400,2,3,1998/10/01 08:51:00,139.5447271808,35.727902327,2,8,0731211,8 ,1 ,34,,12",
 "696900,2,4,1998/10/01 08:51:00,139.7281567638,35.5884882203,2,6,0720006,4 ,1 ,39,,1",
 "719900,2,4,1998/10/01 08:51:00,139.6423045885,35.7050819109,1,7,0752005,6 ,10,34,,12",
 "89700,7,7,1998/10/01 08:52:00,140.1629588915,35.8351626469,2,8,4422113,3 ,3 ,42,,7",
 "128800,2,3,1998/10/01 08:52:00,140.4004792079,35.807969134,1,14,4831221,15,7 ,26,,10",
 "273700,2,2,1998/10/01 08:52:00,139.3079591857,35.3955941849,2,5,2820113,14,7 ,42,,6",
 "280600,5,5,1998/10/01 08:52:00,139.7617567575,35.7720988423,1,11,2910009,8 ,9 ,42,,8",
 "363400,4,5,1998/10/01 08:52:00,139.6552915908,35.7731306222,1,6,3032201,3 ,11,34,,6",
 "16100,2,4,1998/10/01 08:52:00,139.771654275,35.6754567016,2,4,4116209,8 ,1 ,48,,1",
 "48300,2,3,1998/10/01 08:52:00,139.8345929222,35.7317024664,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:52:00,139.831516062,35.7628949711,1,7,4224202,9 ,1 ,37,,12",
 "151800,2,4,1998/10/01 08:52:00,139.7349883444,35.6908228258,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:52:00,139.8449393755,35.7652658191,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 08:52:00,140.0022518046,35.8725143377,2,5,5412112,9 ,1 ,35,,12",
 "211600,2,4,1998/10/01 08:52:00,139.5984832861,35.4024176098,1,7,2220004,6 ,1 ,36,,12",
 "209300,2,3,1998/10/01 08:52:00,139.7204545534,35.5569479493,1,13,2211303,10,1 ,37,,12",
 "227700,2,4,1998/10/01 08:52:00,139.7184786236,35.7280256383,1,9,2410203,8 ,10,16,,1",
 "66700,2,2,1998/10/01 08:52:00,139.9478369706,35.8505349275,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:52:00,140.0164208228,35.7337141714,1,8,4417027,9 ,10,46,,6",
 "324300,2,3,1998/10/01 08:52:00,139.6953211041,35.6562736821,1,10,2110103,8 ,1 ,29,,12",
 "322000,2,4,1998/10/01 08:52:00,139.7291029218,35.6852245517,2,5,2032102,8 ,1 ,36,,1",
 "271400,2,2,1998/10/01 08:52:00,139.3858535991,35.4642103237,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:52:00,139.7128086262,35.7329896673,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,2,1998/10/01 08:52:00,139.6650061718,35.8612708946,1,10,3033223,10,1 ,38,,1",
 "391000,2,2,1998/10/01 08:52:00,139.6127330233,35.8046523637,1,7,3110313,8 ,1 ,33,,6",
 "374900,2,2,1998/10/01 08:52:00,139.7372364766,35.7343999001,1,4,3040108,4 ,1 ,71,,6",
 "418600,2,4,1998/10/01 08:52:00,139.4418792905,35.7988085003,1,11,3123503,8 ,1 ,23,,12",
 "402500,4,4,1998/10/01 08:52:00,139.5113014934,35.8064616371,1,9,3116015,9 ,11,44,,8",
 "446200,2,3,1998/10/01 08:52:00,139.778898266,35.7132996196,2,5,3221102,8 ,1 ,37,,12",
 "448500,2,2,1998/10/01 08:52:00,139.7750390538,35.9335669227,2,7,3222102,4 ,1 ,27,,1",
 "441600,4,4,1998/10/01 08:52:00,139.7668870352,35.6953124173,1,9,3215009,9 ,11,30,,8",
 "473800,2,3,1998/10/01 08:52:00,139.5798600782,35.9222951012,2,10,3411010,8 ,1 ,30,,12",
 "478400,2,4,1998/10/01 08:52:00,139.3987543095,36.0456556628,1,4,3413105,13,2 ,40,,9",
 "483000,2,4,1998/10/01 08:52:00,139.765834007,35.6685834427,1,10,3510101,8 ,1 ,27,,1",
 "455400,2,2,1998/10/01 08:52:00,139.8280451782,35.8985156855,1,12,3226003,3 ,11,31,,8",
 "480700,2,2,1998/10/01 08:52:00,139.3260494996,36.0578529344,1,10,3414121,4 ,1 ,44,,6",
 "563500,2,4,1998/10/01 08:52:00,139.7986096741,35.6359894196,1,7,0212001,10,1 ,84,,11",
 "611800,2,3,1998/10/01 08:52:00,139.5192905146,35.5429733774,1,4,0431110,13,2 ,65,,12",
 "621000,2,3,1998/10/01 08:52:00,139.6630771293,35.6723643264,2,10,0436007,14,7 ,45,,12",
 "678500,2,2,1998/10/01 08:52:00,139.8804435143,35.6912004425,1,1,0631106,12,2 ,49,,2",
 "823400,2,4,1998/10/01 08:52:00,139.6149928838,35.4546198739,1,10,1213105,9 ,13,34,,10",
 "724500,2,2,1998/10/01 08:52:00,139.567521802,35.6813105267,2,10,0761001,8 ,1 ,33,,7",
 "786600,2,4,1998/10/01 08:52:00,139.2911603149,35.6584827758,2,3,0930110,13,2 ,35,,9",
 "719900,2,4,1998/10/01 08:52:00,139.6460013303,35.7052035717,1,7,0752005,6 ,10,34,,12",
 "708400,2,3,1998/10/01 08:52:00,139.5505355436,35.7284575609,2,8,0731211,8 ,1 ,34,,12",
 "696900,2,4,1998/10/01 08:52:00,139.7280406369,35.5882571732,2,6,0720006,4 ,1 ,39,,1",
 "89700,7,7,1998/10/01 08:53:00,140.1657769003,35.8310561393,2,8,4422113,3 ,3 ,42,,7",
 "128800,2,3,1998/10/01 08:53:00,140.4004795353,35.8079694013,1,14,4831221,15,7 ,26,,10",
 "273700,2,2,1998/10/01 08:53:00,139.3079595774,35.3955943689,2,5,2820113,14,7 ,42,,6",
 "280600,5,5,1998/10/01 08:53:00,139.7613529021,35.7749039908,1,11,2910009,8 ,9 ,42,,8",
 "363400,4,5,1998/10/01 08:53:00,139.6564614087,35.7718594266,1,6,3032201,3 ,11,34,,6",
 "16100,2,4,1998/10/01 08:53:00,139.7725327743,35.6751047697,2,4,4116209,8 ,1 ,48,,1",
 "48300,2,3,1998/10/01 08:53:00,139.8311798209,35.7294030846,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:53:00,139.8240778269,35.7621999908,1,7,4224202,9 ,1 ,37,,12",
 "151800,2,4,1998/10/01 08:53:00,139.7282554552,35.6912327809,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:53:00,139.8392239184,35.7636399085,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 08:53:00,139.99610139,35.8740138038,2,5,5412112,9 ,1 ,35,,12",
 "209300,2,3,1998/10/01 08:53:00,139.7245258504,35.5620593601,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:53:00,139.5966435587,35.408555923,1,7,2220004,6 ,1 ,36,,12",
 "227700,2,4,1998/10/01 08:53:00,139.7199003895,35.7277732063,1,9,2410203,8 ,10,16,,1",
 "66700,2,2,1998/10/01 08:53:00,139.9476615178,35.8505956707,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:53:00,140.0117081289,35.7321297246,1,8,4417027,9 ,10,46,,6",
 "322000,2,4,1998/10/01 08:53:00,139.737850947,35.6760278305,2,5,2032102,8 ,1 ,36,,1",
 "324300,2,3,1998/10/01 08:53:00,139.6977572302,35.6590100055,1,10,2110103,8 ,1 ,29,,12",
 "271400,2,2,1998/10/01 08:53:00,139.3875027937,35.4641118077,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:53:00,139.7110772052,35.7293636378,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,2,1998/10/01 08:53:00,139.6645976866,35.8605951957,1,10,3033223,10,1 ,38,,1",
 "391000,2,2,1998/10/01 08:53:00,139.6139778236,35.8028461353,1,7,3110313,8 ,1 ,33,,6",
 "374900,2,2,1998/10/01 08:53:00,139.7400975716,35.7320378195,1,4,3040108,4 ,1 ,71,,6",
 "418600,2,4,1998/10/01 08:53:00,139.446797808,35.7965274229,1,11,3123503,8 ,1 ,23,,12",
 "402500,4,4,1998/10/01 08:53:00,139.5084930947,35.8044910659,1,9,3116015,9 ,11,44,,8",
 "446200,2,3,1998/10/01 08:53:00,139.7766977,35.7110869084,2,5,3221102,8 ,1 ,37,,12",
 "448500,2,2,1998/10/01 08:53:00,139.7741836198,35.9336301986,2,7,3222102,4 ,1 ,27,,1",
 "473800,2,3,1998/10/01 08:53:00,139.584664032,35.9240096547,2,10,3411010,8 ,1 ,30,,12",
 "478400,2,4,1998/10/01 08:53:00,139.3979379535,36.0468351526,1,4,3413105,13,2 ,40,,9",
 "441600,4,4,1998/10/01 08:53:00,139.7647046445,35.6945918359,1,9,3215009,9 ,11,30,,8",
 "483000,2,4,1998/10/01 08:53:00,139.7669043251,35.6695185717,1,10,3510101,8 ,1 ,27,,1",
 "455400,2,2,1998/10/01 08:53:00,139.8283372239,35.8971551443,1,12,3226003,3 ,11,31,,8",
 "480700,2,2,1998/10/01 08:53:00,139.3220479182,36.0618566208,1,10,3414121,4 ,1 ,44,,6",
 "563500,2,4,1998/10/01 08:53:00,139.7963965461,35.6337230769,1,7,0212001,10,1 ,84,,11",
 "611800,2,3,1998/10/01 08:53:00,139.5131169489,35.542378837,1,4,0431110,13,2 ,65,,12",
 "621000,2,3,1998/10/01 08:53:00,139.6683261906,35.674044182,2,10,0436007,14,7 ,45,,12",
 "678500,2,2,1998/10/01 08:53:00,139.8802374803,35.6919276345,1,1,0631106,12,2 ,49,,2",
 "724500,2,2,1998/10/01 08:53:00,139.5703268326,35.6812551117,2,10,0761001,8 ,1 ,33,,7",
 "823400,2,4,1998/10/01 08:53:00,139.615394775,35.4547308719,1,10,1213105,9 ,13,34,,10",
 "786600,2,4,1998/10/01 08:53:00,139.290433267,35.6597408389,2,3,0930110,13,2 ,35,,9",
 "696900,2,4,1998/10/01 08:53:00,139.7279245108,35.588026126,2,6,0720006,4 ,1 ,39,,1",
 "719900,2,4,1998/10/01 08:53:00,139.6496984589,35.7053164628,1,7,0752005,6 ,10,34,,12",
 "708400,2,3,1998/10/01 08:53:00,139.5563294305,35.7290109392,2,8,0731211,8 ,1 ,34,,12",
 "89700,7,7,1998/10/01 08:54:00,140.1659392378,35.8264472755,2,8,4422113,3 ,3 ,42,,7",
 "128800,2,3,1998/10/01 08:54:00,140.4004798627,35.8079696686,1,14,4831221,15,7 ,26,,10",
 "273700,2,2,1998/10/01 08:54:00,139.307959969,35.395594553,2,5,2820113,14,7 ,42,,6",
 "280600,5,5,1998/10/01 08:54:00,139.7607026277,35.7776729046,1,11,2910009,8 ,9 ,42,,8",
 "363400,4,5,1998/10/01 08:54:00,139.6568837084,35.770109285,1,6,3032201,3 ,11,34,,6",
 "16100,2,4,1998/10/01 08:54:00,139.7734138035,35.674782416,2,4,4116209,8 ,1 ,48,,1",
 "48300,2,3,1998/10/01 08:54:00,139.8280024931,35.72690684,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:54:00,139.8166802905,35.7612737451,1,7,4224202,9 ,1 ,37,,12",
 "151800,2,4,1998/10/01 08:54:00,139.721660218,35.6923733312,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:54:00,139.8331423869,35.7630496635,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 08:54:00,139.989871595,35.8752867643,2,5,5412112,9 ,1 ,35,,12",
 "211600,2,4,1998/10/01 08:54:00,139.5967607647,35.4149389078,1,7,2220004,6 ,1 ,36,,12",
 "209300,2,3,1998/10/01 08:54:00,139.7284738588,35.5672329107,1,13,2211303,10,1 ,37,,12",
 "227700,2,4,1998/10/01 08:54:00,139.7211464732,35.7271810091,1,9,2410203,8 ,10,16,,1",
 "66700,2,2,1998/10/01 08:54:00,139.9475630666,35.85074409,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:54:00,140.0071854543,35.7314847258,1,8,4417027,9 ,10,46,,6",
 "322000,2,4,1998/10/01 08:54:00,139.7476245696,35.6667945267,2,5,2032102,8 ,1 ,36,,1",
 "324300,2,3,1998/10/01 08:54:00,139.701786511,35.6593931398,1,10,2110103,8 ,1 ,29,,12",
 "271400,2,2,1998/10/01 08:54:00,139.3891514229,35.4640059318,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:54:00,139.7099633877,35.7255022265,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,2,1998/10/01 08:54:00,139.6637954898,35.8601824723,1,10,3033223,10,1 ,38,,1",
 "391000,2,2,1998/10/01 08:54:00,139.6156259383,35.8015409868,1,7,3110313,8 ,1 ,33,,6",
 "418600,2,4,1998/10/01 08:54:00,139.4516054436,35.794110038,1,11,3123503,8 ,1 ,23,,12",
 "374900,2,2,1998/10/01 08:54:00,139.7427522257,35.7295212318,1,4,3040108,4 ,1 ,71,,6",
 "402500,4,4,1998/10/01 08:54:00,139.5053534094,35.80286317,1,9,3116015,9 ,11,44,,8",
 "448500,2,2,1998/10/01 08:54:00,139.77441141,35.9342544455,2,7,3222102,4 ,1 ,27,,1",
 "446200,2,3,1998/10/01 08:54:00,139.7763529899,35.708208241,2,5,3221102,8 ,1 ,37,,12",
 "441600,4,4,1998/10/01 08:54:00,139.7624792218,35.6948536103,1,9,3215009,9 ,11,30,,8",
 "473800,2,3,1998/10/01 08:54:00,139.5894647884,35.9257301644,2,10,3411010,8 ,1 ,30,,12",
 "478400,2,4,1998/10/01 08:54:00,139.3970615886,36.047984849,1,4,3413105,13,2 ,40,,9",
 "483000,2,4,1998/10/01 08:54:00,139.765652839,35.6685072476,1,10,3510101,8 ,1 ,27,,1",
 "455400,2,2,1998/10/01 08:54:00,139.8285683003,35.8957866631,1,12,3226003,3 ,11,31,,8",
 "480700,2,2,1998/10/01 08:54:00,139.3192216032,36.06637654,1,10,3414121,4 ,1 ,44,,6",
 "563500,2,4,1998/10/01 08:54:00,139.7937813642,35.6324165579,1,7,0212001,10,1 ,84,,11",
 "563500,2,5,1998/10/01 08:54:00,139.7937813642,35.6324165579,1,7,0212001,10,1 ,84,,1",
 "611800,2,3,1998/10/01 08:54:00,139.5083039199,35.5392420188,1,4,0431110,13,2 ,65,,12",
 "621000,2,3,1998/10/01 08:54:00,139.6735198086,35.675836672,2,10,0436007,14,7 ,45,,12",
 "678500,2,2,1998/10/01 08:54:00,139.8798112927,35.6925927479,1,1,0631106,12,2 ,49,,2",
 "823400,2,4,1998/10/01 08:54:00,139.6156280229,35.4543926452,1,10,1213105,9 ,13,34,,10",
 "724500,2,2,1998/10/01 08:54:00,139.5731281324,35.6813264962,2,10,0761001,8 ,1 ,33,,7",
 "786600,2,4,1998/10/01 08:54:00,139.2888766679,35.6593050252,2,3,0930110,13,2 ,35,,9",
 "708400,2,3,1998/10/01 08:54:00,139.5621629938,35.7289545811,2,8,0731211,8 ,1 ,34,,12",
 "696900,2,4,1998/10/01 08:54:00,139.7278083853,35.5877950786,2,6,0720006,4 ,1 ,39,,1",
 "719900,2,4,1998/10/01 08:54:00,139.6533960241,35.7054203213,1,7,0752005,6 ,10,34,,12",
 "89700,7,7,1998/10/01 08:55:00,140.1692257093,35.823329211,2,8,4422113,3 ,3 ,42,,7",
 "128800,2,3,1998/10/01 08:55:00,140.4004801901,35.807969936,1,14,4831221,15,7 ,26,,10",
 "128800,2,4,1998/10/01 08:55:00,140.4004801901,35.807969936,1,14,4831221,15,7 ,26,,2",
 "273700,2,2,1998/10/01 08:55:00,139.3079603607,35.395594737,2,5,2820113,14,7 ,42,,6",
 "273700,2,3,1998/10/01 08:55:00,139.3079603607,35.395594737,2,5,2820113,14,7 ,42,,1",
 "280600,5,5,1998/10/01 08:55:00,139.7607931493,35.7804719803,1,11,2910009,8 ,9 ,42,,8",
 "363400,4,5,1998/10/01 08:55:00,139.6593975992,35.7696366705,1,6,3032201,3 ,11,34,,6",
 "16100,2,4,1998/10/01 08:55:00,139.7743899303,35.6747238965,2,4,4116209,8 ,1 ,48,,1",
 "48300,2,3,1998/10/01 08:55:00,139.8254705604,35.7239454497,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:55:00,139.8103845328,35.7585875312,1,7,4224202,9 ,1 ,37,,12",
 "151800,2,4,1998/10/01 08:55:00,139.7150379849,35.6913580698,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:55:00,139.8270575793,35.7624877503,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 08:55:00,139.983712833,35.8748198845,2,5,5412112,9 ,1 ,35,,12",
 "209300,2,3,1998/10/01 08:55:00,139.73204243,35.5725915768,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:55:00,139.5974618642,35.421254622,1,7,2220004,6 ,1 ,36,,12",
 "227700,2,4,1998/10/01 08:55:00,139.7226027812,35.7271203062,1,9,2410203,8 ,10,16,,1",
 "227700,3,5,1998/10/01 08:55:00,139.7226027812,35.7271203062,1,9,2410203,8 ,97,16,,97",
 "66700,2,2,1998/10/01 08:55:00,139.9475039007,35.8509391799,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:55:00,140.0040610979,35.7342422448,1,8,4417027,9 ,10,46,,6",
 "322000,2,4,1998/10/01 08:55:00,139.7580476865,35.6598864837,2,5,2032102,8 ,1 ,36,,1",
 "322000,3,5,1998/10/01 08:55:00,139.7580476865,35.6598864837,2,5,2032102,8 ,97,36,,97",
 "324300,2,3,1998/10/01 08:55:00,139.7052048769,35.6597614094,1,10,2110103,8 ,1 ,29,,12",
 "271400,2,2,1998/10/01 08:55:00,139.3908002584,35.4639022317,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:55:00,139.7059151806,35.7248144371,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,2,1998/10/01 08:55:00,139.6629762895,35.8597920184,1,10,3033223,10,1 ,38,,1",
 "391000,2,2,1998/10/01 08:55:00,139.6151644615,35.7995340451,1,7,3110313,8 ,1 ,33,,6",
 "418600,2,4,1998/10/01 08:55:00,139.4547560782,35.7902937966,1,11,3123503,8 ,1 ,23,,12",
 "374900,2,2,1998/10/01 08:55:00,139.7456842301,35.7272443186,1,4,3040108,4 ,1 ,71,,6",
 "402500,4,4,1998/10/01 08:55:00,139.5021625964,35.8013065613,1,9,3116015,9 ,11,44,,8",
 "448500,2,2,1998/10/01 08:55:00,139.7746564199,35.9348998724,2,7,3222102,4 ,1 ,27,,1",
 "446200,2,3,1998/10/01 08:55:00,139.7748206695,35.7074014933,2,5,3221102,8 ,1 ,37,,12",
 "446200,2,4,1998/10/01 08:55:00,139.7748206695,35.7074014933,2,5,3221102,8 ,1 ,37,,1",
 "473800,2,3,1998/10/01 08:55:00,139.5942838716,35.927416676,2,10,3411010,8 ,1 ,30,,12",
 "478400,2,4,1998/10/01 08:55:00,139.3961415261,36.0491128996,1,4,3413105,13,2 ,40,,9",
 "441600,4,4,1998/10/01 08:55:00,139.7606833544,35.6960907302,1,9,3215009,9 ,11,30,,8",
 "483000,2,4,1998/10/01 08:55:00,139.7644013845,35.6674959102,1,10,3510101,8 ,1 ,27,,1",
 "455400,2,2,1998/10/01 08:55:00,139.8287929901,35.8944173809,1,12,3226003,3 ,11,31,,8",
 "480700,2,2,1998/10/01 08:55:00,139.3161973491,36.070866948,1,10,3414121,4 ,1 ,44,,6",
 "563500,2,5,1998/10/01 08:55:00,139.7946154858,35.6313547189,1,7,0212001,10,1 ,84,,1",
 "621000,2,3,1998/10/01 08:55:00,139.6788725524,35.6772646033,2,10,0436007,14,7 ,45,,12",
 "611800,2,3,1998/10/01 08:55:00,139.5041044014,35.5354785054,1,4,0431110,13,2 ,65,,12",
 "678500,2,2,1998/10/01 08:55:00,139.8788994016,35.6927493424,1,1,0631106,12,2 ,49,,2",
 "823400,2,4,1998/10/01 08:55:00,139.6159291034,35.4541005363,1,10,1213105,9 ,13,34,,10",
 "823400,2,5,1998/10/01 08:55:00,139.6159291034,35.4541005363,1,10,1213105,9 ,13,34,,1",
 "724500,2,2,1998/10/01 08:55:00,139.5758960717,35.6816988445,2,10,0761001,8 ,1 ,33,,7",
 "786600,2,4,1998/10/01 08:55:00,139.2874842557,35.6599749343,2,3,0930110,13,2 ,35,,9",
 "719900,2,4,1998/10/01 08:55:00,139.6570936932,35.7055219188,1,7,0752005,6 ,10,34,,12",
 "696900,2,4,1998/10/01 08:55:00,139.7276922606,35.5875640311,2,6,0720006,4 ,1 ,39,,1",
 "708400,2,3,1998/10/01 08:55:00,139.5679742224,35.7284571614,2,8,0731211,8 ,1 ,34,,12",
 "89700,7,7,1998/10/01 08:56:00,140.1732607245,35.8199549478,2,8,4422113,3 ,3 ,42,,7",
 "128800,2,4,1998/10/01 08:56:00,140.4142256988,35.830948082,1,14,4831221,15,7 ,26,,2",
 "273700,2,3,1998/10/01 08:56:00,139.3108749337,35.3973586847,2,5,2820113,14,7 ,42,,1",
 "280600,5,5,1998/10/01 08:56:00,139.7610604447,35.7832876383,1,11,2910009,8 ,9 ,42,,8",
 "363400,4,5,1998/10/01 08:56:00,139.6614846231,35.7685783957,1,6,3032201,3 ,11,34,,6",
 "16100,2,4,1998/10/01 08:56:00,139.7748361498,35.6754128521,2,4,4116209,8 ,1 ,48,,1",
 "48300,2,3,1998/10/01 08:56:00,139.8229220365,35.7209935582,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:56:00,139.8063187985,35.7534834596,1,7,4224202,9 ,1 ,37,,12",
 "151800,2,4,1998/10/01 08:56:00,139.7082644142,35.6914018253,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:56:00,139.820997713,35.7617843804,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 08:56:00,139.9787827811,35.8714719373,2,5,5412112,9 ,1 ,35,,12",
 "211600,2,4,1998/10/01 08:56:00,139.5960592828,35.4275453265,1,7,2220004,6 ,1 ,36,,12",
 "209300,2,3,1998/10/01 08:56:00,139.7347583437,35.5782638439,1,13,2211303,10,1 ,37,,12",
 "66700,2,2,1998/10/01 08:56:00,139.9474447345,35.8511342698,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:56:00,140.0004975758,35.7367870445,1,8,4417027,9 ,10,46,,6",
 "324300,2,3,1998/10/01 08:56:00,139.708653954,35.661701666,1,10,2110103,8 ,1 ,29,,12",
 "271400,2,2,1998/10/01 08:56:00,139.392446614,35.4637882249,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:56:00,139.7014403324,35.7264251466,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,2,1998/10/01 08:56:00,139.662145295,35.8594183249,1,10,3033223,10,1 ,38,,1",
 "299000,2,2,1998/10/01 08:56:00,139.4021075974,35.5608195505,2,9,2921203,3 ,1 ,33,,1",
 "391000,2,2,1998/10/01 08:56:00,139.6156004402,35.7974607517,1,7,3110313,8 ,1 ,33,,6",
 "374900,2,2,1998/10/01 08:56:00,139.7490055701,35.7253495554,1,4,3040108,4 ,1 ,71,,6",
 "418600,2,4,1998/10/01 08:56:00,139.4586919277,35.7870422726,1,11,3123503,8 ,1 ,23,,12",
 "402500,4,4,1998/10/01 08:56:00,139.4987700472,35.8000708905,1,9,3116015,9 ,11,44,,8",
 "446200,2,4,1998/10/01 08:56:00,139.7760205245,35.7058642721,2,5,3221102,8 ,1 ,37,,1",
 "448500,2,2,1998/10/01 08:56:00,139.7745609494,35.9357598881,2,7,3222102,4 ,1 ,27,,1",
 "448500,2,3,1998/10/01 08:56:00,139.7745609494,35.9357598881,2,7,3222102,4 ,1 ,27,,12",
 "473800,2,3,1998/10/01 08:56:00,139.5990973393,35.9291140197,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 08:56:00,139.7583328251,35.6961546049,1,9,3215009,9 ,11,30,,8",
 "478400,2,4,1998/10/01 08:56:00,139.3952764429,36.0502665607,1,4,3413105,13,2 ,40,,9",
 "483000,2,4,1998/10/01 08:56:00,139.7643967098,35.6675914934,1,10,3510101,8 ,1 ,27,,1",
 "455400,2,2,1998/10/01 08:56:00,139.8294331319,35.8934593699,1,12,3226003,3 ,11,31,,8",
 "480700,2,2,1998/10/01 08:56:00,139.3132324006,36.0753458306,1,10,3414121,4 ,1 ,44,,6",
 "563500,2,5,1998/10/01 08:56:00,139.7954495853,35.6302928738,1,7,0212001,10,1 ,84,,1",
 "611800,2,3,1998/10/01 08:56:00,139.4995650215,35.5320733443,1,4,0431110,13,2 ,65,,12",
 "621000,2,3,1998/10/01 08:56:00,139.6838280975,35.6794484999,2,10,0436007,14,7 ,45,,12",
 "678500,2,2,1998/10/01 08:56:00,139.8789303395,35.6933648945,1,1,0631106,12,2 ,49,,2",
 "724500,2,2,1998/10/01 08:56:00,139.5786402001,35.6820461193,2,10,0761001,8 ,1 ,33,,7",
 "823400,2,5,1998/10/01 08:56:00,139.6161695511,35.4512943785,1,10,1213105,9 ,13,34,,1",
 "786600,2,4,1998/10/01 08:56:00,139.2878357574,35.6612964204,2,3,0930110,13,2 ,35,,9",
 "708400,2,3,1998/10/01 08:56:00,139.5738097729,35.7281362689,2,8,0731211,8 ,1 ,34,,12",
 "696900,2,4,1998/10/01 08:56:00,139.7275761364,35.5873329835,2,6,0720006,4 ,1 ,39,,1",
 "719900,2,4,1998/10/01 08:56:00,139.6607921207,35.7056002402,1,7,0752005,6 ,10,34,,12",
 "89700,7,7,1998/10/01 08:57:00,140.1770805407,35.8164360687,2,8,4422113,3 ,3 ,42,,7",
 "128800,2,4,1998/10/01 08:57:00,140.4414653993,35.842778228,1,14,4831221,15,7 ,26,,2",
 "273700,2,3,1998/10/01 08:57:00,139.3162795255,35.3993166607,2,5,2820113,14,7 ,42,,1",
 "280600,5,5,1998/10/01 08:57:00,139.761565833,35.7860794957,1,11,2910009,8 ,9 ,42,,8",
 "363400,4,5,1998/10/01 08:57:00,139.6633558074,35.767407092,1,6,3032201,3 ,11,34,,6",
 "16100,2,4,1998/10/01 08:57:00,139.7753303441,35.67609409,2,4,4116209,8 ,1 ,48,,1",
 "48300,2,3,1998/10/01 08:57:00,139.8199402563,35.7183379107,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:57:00,139.8042004389,35.7476374599,1,7,4224202,9 ,1 ,37,,12",
 "151800,2,4,1998/10/01 08:57:00,139.7018815759,35.6893770891,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:57:00,139.8149373214,35.7610702058,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 08:57:00,139.9750236868,35.8672589867,2,5,5412112,9 ,1 ,35,,12",
 "211600,2,4,1998/10/01 08:57:00,139.5979960144,35.4332819963,1,7,2220004,6 ,1 ,36,,12",
 "209300,2,3,1998/10/01 08:57:00,139.7351552741,35.5842769533,1,13,2211303,10,1 ,37,,12",
 "66700,2,2,1998/10/01 08:57:00,139.947385568,35.8513293596,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:57:00,139.9968284952,35.7396357552,1,8,4417027,9 ,10,46,,6",
 "324300,2,3,1998/10/01 08:57:00,139.7116420854,35.6640894777,1,10,2110103,8 ,1 ,29,,12",
 "271400,2,2,1998/10/01 08:57:00,139.3940987864,35.4637673769,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:57:00,139.6966223731,35.7261866894,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,2,1998/10/01 08:57:00,139.661320194,35.859035923,1,10,3033223,10,1 ,38,,1",
 "299000,2,2,1998/10/01 08:57:00,139.4031916171,35.5602706881,2,9,2921203,3 ,1 ,33,,1",
 "391000,2,2,1998/10/01 08:57:00,139.6155804138,35.7954761144,1,7,3110313,8 ,1 ,33,,6",
 "374900,2,2,1998/10/01 08:57:00,139.7522382128,35.723398628,1,4,3040108,4 ,1 ,71,,6",
 "418600,2,4,1998/10/01 08:57:00,139.4630807037,35.7841315833,1,11,3123503,8 ,1 ,23,,12",
 "402500,4,4,1998/10/01 08:57:00,139.4952241117,35.7991646685,1,9,3116015,9 ,11,44,,8",
 "446200,2,4,1998/10/01 08:57:00,139.776035448,35.7031601902,2,5,3221102,8 ,1 ,37,,1",
 "448500,2,3,1998/10/01 08:57:00,139.7764019416,35.9296874261,2,7,3222102,4 ,1 ,27,,12",
 "441600,4,4,1998/10/01 08:57:00,139.7559736344,35.6960670161,1,9,3215009,9 ,11,30,,8",
 "473800,2,3,1998/10/01 08:57:00,139.603912155,35.9308088782,2,10,3411010,8 ,1 ,30,,12",
 "478400,2,4,1998/10/01 08:57:00,139.3943734753,36.051156888,1,4,3413105,13,2 ,40,,9",
 "483000,2,4,1998/10/01 08:57:00,139.7656383756,35.6686108346,1,10,3510101,8 ,1 ,27,,1",
 "455400,2,2,1998/10/01 08:57:00,139.8309455706,35.8930601629,1,12,3226003,3 ,11,31,,8",
 "480700,2,2,1998/10/01 08:57:00,139.3098815946,36.0796103073,1,10,3414121,4 ,1 ,44,,6",
 "563500,2,5,1998/10/01 08:57:00,139.7955658629,35.6304551889,1,7,0212001,10,1 ,84,,1",
 "611800,2,3,1998/10/01 08:57:00,139.4933609392,35.5317858889,1,4,0431110,13,2 ,65,,12",
 "621000,2,3,1998/10/01 08:57:00,139.6885274528,35.6819024716,2,10,0436007,14,7 ,45,,12",
 "678500,2,2,1998/10/01 08:57:00,139.8792783267,35.6940626738,1,1,0631106,12,2 ,49,,2",
 "823400,2,5,1998/10/01 08:57:00,139.6159513302,35.4482518413,1,10,1213105,9 ,13,34,,1",
 "724500,2,2,1998/10/01 08:57:00,139.5813900516,35.6824967072,2,10,0761001,8 ,1 ,33,,7",
 "786600,2,4,1998/10/01 08:57:00,139.2887052734,35.6624781923,2,3,0930110,13,2 ,35,,9",
 "719900,2,4,1998/10/01 08:57:00,139.6644894829,35.7057092277,1,7,0752005,6 ,10,34,,12",
 "708400,2,3,1998/10/01 08:57:00,139.5795422846,35.72733835,2,8,0731211,8 ,1 ,34,,12",
 "696900,2,4,1998/10/01 08:57:00,139.7274600129,35.5871019358,2,6,0720006,4 ,1 ,39,,1",
 "89700,7,7,1998/10/01 08:58:00,140.1805779767,35.8138384763,2,8,4422113,3 ,3 ,42,,7",
 "128800,2,4,1998/10/01 08:58:00,140.4674243902,35.8546886314,1,14,4831221,15,7 ,26,,2",
 "273700,2,3,1998/10/01 08:58:00,139.3212623594,35.4017585762,2,5,2820113,14,7 ,42,,1",
 "280600,5,5,1998/10/01 08:58:00,139.7621512295,35.788862626,1,11,2910009,8 ,9 ,42,,8",
 "363400,4,5,1998/10/01 08:58:00,139.6656370062,35.7664224796,1,6,3032201,3 ,11,34,,6",
 "16100,2,4,1998/10/01 08:58:00,139.7762260291,35.6764161028,2,4,4116209,8 ,1 ,48,,1",
 "16100,3,5,1998/10/01 08:58:00,139.7762260291,35.6764161028,2,4,4116209,8 ,97,48,,97",
 "48300,2,3,1998/10/01 08:58:00,139.8173648783,35.715447455,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:58:00,139.8021648416,35.7417672959,1,7,4224202,9 ,1 ,37,,12",
 "151800,2,4,1998/10/01 08:58:00,139.6987946242,35.6882004457,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:58:00,139.8101529513,35.758324193,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 08:58:00,139.9715568408,35.8628589805,2,5,5412112,9 ,1 ,35,,12",
 "209300,2,3,1998/10/01 08:58:00,139.7357306233,35.5903432834,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:58:00,139.6054955898,35.4350716278,1,7,2220004,6 ,1 ,36,,12",
 "66700,2,2,1998/10/01 08:58:00,139.9473264012,35.8515244494,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:58:00,139.9932633759,35.7409226174,1,8,4417027,9 ,10,46,,6",
 "324300,2,3,1998/10/01 08:58:00,139.7133291774,35.6659327701,1,10,2110103,8 ,1 ,29,,12",
 "271400,2,2,1998/10/01 08:58:00,139.3957524837,35.4637673635,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:58:00,139.6918419805,35.7269942648,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,2,1998/10/01 08:58:00,139.6604962781,35.8586517947,1,10,3033223,10,1 ,38,,1",
 "299000,2,2,1998/10/01 08:58:00,139.4044885001,35.5603642686,2,9,2921203,3 ,1 ,33,,1",
 "391000,2,2,1998/10/01 08:58:00,139.6153198236,35.7933832511,1,7,3110313,8 ,1 ,33,,6",
 "374900,2,2,1998/10/01 08:58:00,139.7537691318,35.7203213879,1,4,3040108,4 ,1 ,71,,6",
 "418600,2,4,1998/10/01 08:58:00,139.4675163668,35.7812694397,1,11,3123503,8 ,1 ,23,,12",
 "402500,4,4,1998/10/01 08:58:00,139.4915515023,35.7986921031,1,9,3116015,9 ,11,44,,8",
 "446200,2,4,1998/10/01 08:58:00,139.7768623055,35.7005381837,2,5,3221102,8 ,1 ,37,,1",
 "448500,2,3,1998/10/01 08:58:00,139.7782117194,35.9235638021,2,7,3222102,4 ,1 ,27,,12",
 "473800,2,3,1998/10/01 08:58:00,139.6087951721,35.9323563343,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 08:58:00,139.7536172169,35.6959426285,1,9,3215009,9 ,11,30,,8",
 "478400,2,4,1998/10/01 08:58:00,139.3933719304,36.0519779721,1,4,3413105,13,2 ,40,,9",
 "483000,2,4,1998/10/01 08:58:00,139.7668061165,35.6680751446,1,10,3510101,8 ,1 ,27,,1",
 "455400,2,2,1998/10/01 08:58:00,139.8323355831,35.8922669661,1,12,3226003,3 ,11,31,,8",
 "480700,2,2,1998/10/01 08:58:00,139.3082789722,36.0844383206,1,10,3414121,4 ,1 ,44,,6",
 "563500,2,5,1998/10/01 08:58:00,139.7953752275,35.6316696841,1,7,0212001,10,1 ,84,,1",
 "611800,2,3,1998/10/01 08:58:00,139.4872169715,35.5312502234,1,4,0431110,13,2 ,65,,12",
 "621000,2,3,1998/10/01 08:58:00,139.6923991859,35.6852148509,2,10,0436007,14,7 ,45,,12",
 "678500,2,2,1998/10/01 08:58:00,139.8794315172,35.6947954384,1,1,0631106,12,2 ,49,,2",
 "724500,2,2,1998/10/01 08:58:00,139.5841689327,35.6827942296,2,10,0761001,8 ,1 ,33,,7",
 "823400,2,5,1998/10/01 08:58:00,139.6166631994,35.445428353,1,10,1213105,9 ,13,34,,1",
 "786600,2,4,1998/10/01 08:58:00,139.2900714577,35.6632613156,2,3,0930110,13,2 ,35,,9",
 "719900,2,4,1998/10/01 08:58:00,139.6681868556,35.7058181016,1,7,0752005,6 ,10,34,,12",
 "708400,2,3,1998/10/01 08:58:00,139.5853601673,35.7268492536,2,8,0731211,8 ,1 ,34,,12",
 "696900,2,4,1998/10/01 08:58:00,139.7273438901,35.5868708879,2,6,0720006,4 ,1 ,39,,1",
 "89700,7,7,1998/10/01 08:59:00,140.1810362904,35.8091434743,2,8,4422113,3 ,3 ,42,,7",
 "128800,2,4,1998/10/01 08:59:00,140.4762607349,35.8798615291,1,14,4831221,15,7 ,26,,2",
 "273700,2,3,1998/10/01 08:59:00,139.3231420746,35.4059269712,2,5,2820113,14,7 ,42,,1",
 "280600,5,5,1998/10/01 08:59:00,139.761999402,35.7916677878,1,11,2910009,8 ,9 ,42,,8",
 "363400,4,5,1998/10/01 08:59:00,139.6679424363,35.7654878225,1,6,3032201,3 ,11,34,,6",
 "48300,2,3,1998/10/01 08:59:00,139.8152699564,35.7122774446,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 08:59:00,139.8003340102,35.7358500064,1,7,4224202,9 ,1 ,37,,12",
 "151800,2,4,1998/10/01 08:59:00,139.693260042,35.6850066672,1,9,4031206,8 ,1 ,38,,12",
 "172500,2,3,1998/10/01 08:59:00,139.8067081559,35.754201585,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 08:59:00,139.968069015,35.8584695136,2,5,5412112,9 ,1 ,35,,12",
 "209300,2,3,1998/10/01 08:59:00,139.7378474547,35.5961645952,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 08:59:00,139.6129876516,35.4368067973,1,7,2220004,6 ,1 ,36,,12",
 "66700,2,2,1998/10/01 08:59:00,139.9475492744,35.8515648536,1,11,4311002,3 ,9 ,37,,8",
 "85100,2,2,1998/10/01 08:59:00,139.9885519319,35.7394978344,1,8,4417027,9 ,10,46,,6",
 "324300,2,3,1998/10/01 08:59:00,139.7157585681,35.668738048,1,10,2110103,8 ,1 ,29,,12",
 "271400,2,2,1998/10/01 08:59:00,139.397406164,35.4637701069,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 08:59:00,139.6870994655,35.7279723138,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,2,1998/10/01 08:59:00,139.6596723702,35.8582676608,1,10,3033223,10,1 ,38,,1",
 "299000,2,2,1998/10/01 08:59:00,139.4058601826,35.5598746009,2,9,2921203,3 ,1 ,33,,1",
 "391000,2,2,1998/10/01 08:59:00,139.6151566481,35.7912882834,1,7,3110313,8 ,1 ,33,,6",
 "374900,2,2,1998/10/01 08:59:00,139.755929344,35.7175263333,1,4,3040108,4 ,1 ,71,,6",
 "418600,2,4,1998/10/01 08:59:00,139.4724416793,35.7819100745,1,11,3123503,8 ,1 ,23,,12",
 "402500,4,4,1998/10/01 08:59:00,139.4878659918,35.7982820968,1,9,3116015,9 ,11,44,,8",
 "448500,2,3,1998/10/01 08:59:00,139.779923092,35.9174224809,2,7,3222102,4 ,1 ,27,,12",
 "446200,2,4,1998/10/01 08:59:00,139.7783320057,35.6987425447,2,5,3221102,8 ,1 ,37,,1",
 "473800,2,3,1998/10/01 08:59:00,139.6137726505,35.9314450296,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 08:59:00,139.7513256227,35.6955559068,1,9,3215009,9 ,11,30,,8",
 "478400,2,4,1998/10/01 08:59:00,139.3931235183,36.0533109167,1,4,3413105,13,2 ,40,,9",
 "483000,2,4,1998/10/01 08:59:00,139.7677716116,35.6668828115,1,10,3510101,8 ,1 ,27,,1",
 "455400,2,2,1998/10/01 08:59:00,139.8328984206,35.8913286573,1,12,3226003,3 ,11,31,,8",
 "480700,2,2,1998/10/01 08:59:00,139.3061815181,36.0892661731,1,10,3414121,4 ,1 ,44,,6",
 "563500,2,5,1998/10/01 08:59:00,139.7955050811,35.6312957385,1,7,0212001,10,1 ,84,,1",
 "611800,2,3,1998/10/01 08:59:00,139.484871747,35.5267163412,1,4,0431110,13,2 ,65,,12",
 "621000,2,3,1998/10/01 08:59:00,139.6970258197,35.6878082657,2,10,0436007,14,7 ,45,,12",
 "678500,2,2,1998/10/01 08:59:00,139.8801690204,35.6944533362,1,1,0631106,12,2 ,49,,2",
 "724500,2,2,1998/10/01 08:59:00,139.5864314235,35.6820709302,2,10,0761001,8 ,1 ,33,,7",
 "823400,2,5,1998/10/01 08:59:00,139.6163975069,35.4440037391,1,10,1213105,9 ,13,34,,1",
 "786600,2,4,1998/10/01 08:59:00,139.2906774521,35.6623691842,2,3,0930110,13,2 ,35,,9",
 "708400,2,3,1998/10/01 08:59:00,139.5911769425,35.7263530176,2,8,0731211,8 ,1 ,34,,12",
 "696900,2,4,1998/10/01 08:59:00,139.727227768,35.5866398399,2,6,0720006,4 ,1 ,39,,1",
 "719900,2,4,1998/10/01 08:59:00,139.6718842388,35.7059268619,1,7,0752005,6 ,10,34,,12",
 "89700,7,7,1998/10/01 09:00:00,140.1830307831,35.8051083206,2,8,4422113,3 ,3 ,42,,7",
 "128800,2,4,1998/10/01 09:00:00,140.4986994965,35.8881642523,1,14,4831221,15,7 ,26,,2",
 "128800,3,5,1998/10/01 09:00:00,140.4986994965,35.8881642523,1,14,4831221,15,97,26,,97",
 "273700,2,3,1998/10/01 09:00:00,139.3275427039,35.4047966431,2,5,2820113,14,7 ,42,,1",
 "273700,3,4,1998/10/01 09:00:00,139.3275427039,35.4047966431,2,5,2820113,14,97,42,,97",
 "280600,5,5,1998/10/01 09:00:00,139.7618413375,35.7944892023,1,11,2910009,8 ,9 ,42,,8",
 "363400,4,5,1998/10/01 09:00:00,139.670172626,35.764447328,1,6,3032201,3 ,11,34,,6",
 "48300,2,3,1998/10/01 09:00:00,139.8119255031,35.7099315101,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 09:00:00,139.7960887344,35.7310954373,1,7,4224202,9 ,1 ,37,,12",
 "151800,2,4,1998/10/01 09:00:00,139.6883564081,35.6813187613,1,9,4031206,8 ,1 ,38,,12",
 "151800,2,5,1998/10/01 09:00:00,139.6883564081,35.6813187613,1,9,4031206,8 ,1 ,38,,1",
 "172500,2,3,1998/10/01 09:00:00,139.804829604,35.7494640038,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 09:00:00,139.9646072912,35.8540664111,2,5,5412112,9 ,1 ,35,,12",
 "209300,2,3,1998/10/01 09:00:00,139.7404984174,35.601869888,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 09:00:00,139.6203039747,35.4389633622,1,7,2220004,6 ,1 ,36,,12",
 "66700,2,2,1998/10/01 09:00:00,139.9477935805,35.8515935027,1,11,4311002,3 ,9 ,37,,8",
 "66700,3,3,1998/10/01 09:00:00,139.9477935805,35.8515935027,1,11,4311002,3 ,97,37,,97",
 "85100,2,2,1998/10/01 09:00:00,139.9865123775,35.7366100814,1,8,4417027,9 ,10,46,,6",
 "85100,3,3,1998/10/01 09:00:00,139.9865123775,35.7366100814,1,8,4417027,9 ,97,46,,97",
 "324300,2,3,1998/10/01 09:00:00,139.7189886808,35.6708121962,1,10,2110103,8 ,1 ,29,,12",
 "271400,2,2,1998/10/01 09:00:00,139.3990597734,35.4637841115,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 09:00:00,139.6830550357,35.730120632,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,2,1998/10/01 09:00:00,139.6588484702,35.8578835212,1,10,3033223,10,1 ,38,,1",
 "299000,2,2,1998/10/01 09:00:00,139.4070334767,35.5599323797,2,9,2921203,3 ,1 ,33,,1",
 "299000,3,3,1998/10/01 09:00:00,139.4070334767,35.5599323797,2,9,2921203,3 ,97,33,,97",
 "391000,2,2,1998/10/01 09:00:00,139.6150984663,35.7891870359,1,7,3110313,8 ,1 ,33,,6",
 "418600,2,4,1998/10/01 09:00:00,139.4733735854,35.7863344925,1,11,3123503,8 ,1 ,23,,12",
 "374900,2,2,1998/10/01 09:00:00,139.7587715344,35.715525954,1,4,3040108,4 ,1 ,71,,6",
 "446200,2,4,1998/10/01 09:00:00,139.7814006213,35.6981758963,2,5,3221102,8 ,1 ,37,,1",
 "446200,3,5,1998/10/01 09:00:00,139.7814006213,35.6981758963,2,5,3221102,8 ,97,37,,97",
 "402500,4,4,1998/10/01 09:00:00,139.4842763177,35.7974907314,1,9,3116015,9 ,11,44,,8",
 "448500,2,3,1998/10/01 09:00:00,139.7801148032,35.911162203,2,7,3222102,4 ,1 ,27,,12",
 "473800,2,3,1998/10/01 09:00:00,139.6164046181,35.9279143509,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 09:00:00,139.7490232506,35.6951287381,1,9,3215009,9 ,11,30,,8",
 "478400,2,4,1998/10/01 09:00:00,139.3932539747,36.0546512357,1,4,3413105,13,2 ,40,,9",
 "478400,3,5,1998/10/01 09:00:00,139.3932539747,36.0546512357,1,4,3413105,13,97,40,,97",
 "483000,2,4,1998/10/01 09:00:00,139.7672389768,35.6662429851,1,10,3510101,8 ,1 ,27,,1",
 "483000,3,5,1998/10/01 09:00:00,139.7672389768,35.6662429851,1,10,3510101,8 ,97,27,,97",
 "455400,2,2,1998/10/01 09:00:00,139.8314784554,35.8907354254,1,12,3226003,3 ,11,31,,8",
 "455400,3,3,1998/10/01 09:00:00,139.8314784554,35.8907354254,1,12,3226003,3 ,97,31,,97",
 "480700,2,2,1998/10/01 09:00:00,139.3056162647,36.0909405609,1,10,3414121,4 ,1 ,44,,6",
 "563500,2,5,1998/10/01 09:00:00,139.7961417717,35.6301463751,1,7,0212001,10,1 ,84,,1",
 "611800,2,3,1998/10/01 09:00:00,139.4835629783,35.5217360165,1,4,0431110,13,2 ,65,,12",
 "621000,2,3,1998/10/01 09:00:00,139.6998272499,35.690734646,2,10,0436007,14,7 ,45,,12",
 "621000,2,4,1998/10/01 09:00:00,139.6998272499,35.690734646,2,10,0436007,14,7 ,45,,1",
 "678500,2,2,1998/10/01 09:00:00,139.8808450218,35.6939324767,1,1,0631106,12,2 ,49,,2",
 "678500,3,3,1998/10/01 09:00:00,139.8808450218,35.6939324767,1,1,0631106,12,97,49,,97",
 "724500,2,2,1998/10/01 09:00:00,139.5881442462,35.6806267864,2,10,0761001,8 ,1 ,33,,7",
 "786600,2,4,1998/10/01 09:00:00,139.2907915315,35.6625098281,2,3,0930110,13,2 ,35,,9",
 "786600,3,5,1998/10/01 09:00:00,139.2907915315,35.6625098281,2,3,0930110,13,2 ,35,,9",
 "823400,2,5,1998/10/01 09:00:00,139.6159396375,35.4414452181,1,10,1213105,9 ,13,34,,1",
 "708400,2,3,1998/10/01 09:00:00,139.5969661003,35.7256745455,2,8,0731211,8 ,1 ,34,,12",
 "696900,2,4,1998/10/01 09:00:00,139.7273946211,35.5866738703,2,6,0720006,4 ,1 ,39,,1",
 "696900,3,5,1998/10/01 09:00:00,139.7273946211,35.5866738703,2,6,0720006,4 ,97,39,,97",
 "719900,2,4,1998/10/01 09:00:00,139.6755818757,35.7060297622,1,7,0752005,6 ,10,34,,12",
 "89700,7,7,1998/10/01 09:01:00,140.1886257481,35.8039714821,2,8,4422113,3 ,3 ,42,,7",
 "117300,2,2,1998/10/01 09:01:00,139.8463391987,34.9426258263,1,8,4730209,3 ,8 ,38,,6",
 "280600,5,5,1998/10/01 09:01:00,139.7617347736,35.7973122532,1,11,2910009,8 ,9 ,42,,8",
 "317400,2,2,1998/10/01 09:01:00,139.6713158966,35.5411318415,2,5,2022101,14,7 ,42,,6",
 "363400,4,5,1998/10/01 09:01:00,139.6723250503,35.7632858326,1,6,3032201,3 ,11,34,,6",
 "9200,2,2,1998/10/01 09:01:00,139.952937922,35.6994288269,1,11,4114306,5 ,1 ,28,,1",
 "48300,2,3,1998/10/01 09:01:00,139.8078905949,35.708799716,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 09:01:00,139.7886908341,35.7318114515,1,7,4224202,9 ,1 ,37,,12",
 "151800,2,5,1998/10/01 09:01:00,139.6884851273,35.6807318139,1,9,4031206,8 ,1 ,38,,1",
 "172500,2,3,1998/10/01 09:01:00,139.803075914,35.7446834726,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 09:01:00,139.9609987589,35.849746635,2,5,5412112,9 ,1 ,35,,12",
 "211600,2,4,1998/10/01 09:01:00,139.6261828508,35.4427676889,1,7,2220004,6 ,1 ,36,,12",
 "209300,2,3,1998/10/01 09:01:00,139.7435056875,35.607359421,1,13,2211303,10,1 ,37,,12",
 "296700,2,2,1998/10/01 09:01:00,139.4278614937,35.5209867098,2,8,2920301,4 ,1 ,33,,1",
 "119600,2,2,1998/10/01 09:01:00,140.8591137158,35.7293268748,2,13,4810072,14,8 ,45,,1",
 "324300,2,3,1998/10/01 09:01:00,139.7228007965,35.6722635721,1,10,2110103,8 ,1 ,29,,12",
 "250700,2,2,1998/10/01 09:01:00,139.3177150697,35.3289263639,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:01:00,139.4007133835,35.4637980935,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 09:01:00,139.6795423421,35.7328968461,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,2,1998/10/01 09:01:00,139.6580181189,35.8575088427,1,10,3033223,10,1 ,38,,1",
 "391000,2,2,1998/10/01 09:01:00,139.6144625286,35.7874285477,1,7,3110313,8 ,1 ,33,,6",
 "418600,2,4,1998/10/01 09:01:00,139.4727785521,35.7824244109,1,11,3123503,8 ,1 ,23,,12",
 "374900,2,2,1998/10/01 09:01:00,139.7596341498,35.7122806421,1,4,3040108,4 ,1 ,71,,6",
 "377200,2,2,1998/10/01 09:01:00,139.6390465488,35.9209653131,1,14,3052009,15,6 ,29,,3",
 "402500,4,4,1998/10/01 09:01:00,139.4808158632,35.7964740727,1,9,3116015,9 ,11,44,,8",
 "448500,2,3,1998/10/01 09:01:00,139.7796237878,35.904876846,2,7,3222102,4 ,1 ,27,,12",
 "473800,2,3,1998/10/01 09:01:00,139.6178540156,35.9238140057,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 09:01:00,139.7468011562,35.6944768512,1,9,3215009,9 ,11,30,,8",
 "480700,2,2,1998/10/01 09:01:00,139.3042362632,36.0928006585,1,10,3414121,4 ,1 ,44,,6",
 "485300,2,2,1998/10/01 09:01:00,139.5792452791,35.9601838202,1,13,3511206,15,5 ,26,,2",
 "563500,2,5,1998/10/01 09:01:00,139.7967784441,35.6289970082,1,7,0212001,10,1 ,84,,1",
 "600300,2,2,1998/10/01 09:01:00,139.6421275995,35.7102285659,1,5,0420106,13,2 ,64,,2",
 "611800,2,3,1998/10/01 09:01:00,139.481626504,35.5169265284,1,4,0431110,13,2 ,65,,12",
 "616400,2,2,1998/10/01 09:01:00,139.6588727949,35.605648278,2,13,0433105,15,7 ,50,,1",
 "621000,2,4,1998/10/01 09:01:00,139.6987551021,35.6905257451,2,10,0436007,14,7 ,45,,1",
 "701500,4,4,1998/10/01 09:01:00,139.5633835439,35.7068452727,2,10,0721104,9 ,4 ,48,,2",
 "823400,2,5,1998/10/01 09:01:00,139.618163408,35.4390163666,1,10,1213105,9 ,13,34,,1",
 "724500,2,2,1998/10/01 09:01:00,139.5900024395,35.6789395855,2,10,0761001,8 ,1 ,33,,7",
 "786600,3,5,1998/10/01 09:01:00,139.2904923488,35.6623974475,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:01:00,139.470380159,35.5830335391,1,4,0811306,13,2 ,45,,1",
 "708400,2,3,1998/10/01 09:01:00,139.6027949787,35.725321968,2,8,0731211,8 ,1 ,34,,12",
 "719900,2,4,1998/10/01 09:01:00,139.6792809952,35.7060870909,1,7,0752005,6 ,10,34,,12",
 "89700,7,7,1998/10/01 09:02:00,140.1935293478,35.8024232312,2,8,4422113,3 ,3 ,42,,7",
 "117300,2,2,1998/10/01 09:02:00,139.8438241761,34.94170901,1,8,4730209,3 ,8 ,38,,6",
 "280600,5,5,1998/10/01 09:02:00,139.7611252486,35.8000835829,1,11,2910009,8 ,9 ,42,,8",
 "317400,2,2,1998/10/01 09:02:00,139.6724463023,35.5414089847,2,5,2022101,14,7 ,42,,6",
 "9200,2,2,1998/10/01 09:02:00,139.9516007103,35.6997469697,1,11,4114306,5 ,1 ,28,,1",
 "363400,4,5,1998/10/01 09:02:00,139.674511355,35.7621660041,1,6,3032201,3 ,11,34,,6",
 "48300,2,3,1998/10/01 09:02:00,139.8034734458,35.7085907646,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 09:02:00,139.7813043206,35.7327991895,1,7,4224202,9 ,1 ,37,,12",
 "151800,2,5,1998/10/01 09:02:00,139.6886138445,35.6801448662,1,9,4031206,8 ,1 ,38,,1",
 "172500,2,3,1998/10/01 09:02:00,139.8015379363,35.7398517928,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 09:02:00,139.9565347961,35.8460016373,2,5,5412112,9 ,1 ,35,,12",
 "211600,2,4,1998/10/01 09:02:00,139.6242426587,35.4483169299,1,7,2220004,6 ,1 ,36,,12",
 "209300,2,3,1998/10/01 09:02:00,139.742229664,35.6133719083,1,13,2211303,10,1 ,37,,12",
 "296700,2,2,1998/10/01 09:02:00,139.4289581847,35.522076087,2,8,2920301,4 ,1 ,33,,1",
 "119600,2,2,1998/10/01 09:02:00,140.8586496608,35.7292536101,2,13,4810072,14,8 ,45,,1",
 "324300,2,3,1998/10/01 09:02:00,139.7266983977,35.6735621091,1,10,2110103,8 ,1 ,29,,12",
 "250700,2,2,1998/10/01 09:02:00,139.3177803427,35.3275478904,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:02:00,139.4023253642,35.4640686109,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 09:02:00,139.6759973029,35.7356459082,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,2,1998/10/01 09:02:00,139.6574493417,35.8577900864,1,10,3033223,10,1 ,38,,1",
 "391000,2,2,1998/10/01 09:02:00,139.6121644946,35.7883873321,1,7,3110313,8 ,1 ,33,,6",
 "391000,2,3,1998/10/01 09:02:00,139.6121644946,35.7883873321,1,7,3110313,8 ,1 ,33,,12",
 "374900,2,2,1998/10/01 09:02:00,139.7604018608,35.7090202543,1,4,3040108,4 ,1 ,71,,6",
 "418600,2,4,1998/10/01 09:02:00,139.4730598807,35.7778192505,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:02:00,139.6388699639,35.9209673419,1,14,3052009,15,6 ,29,,3",
 "402500,4,4,1998/10/01 09:02:00,139.4772232177,35.7957757777,1,9,3116015,9 ,11,44,,8",
 "448500,2,3,1998/10/01 09:02:00,139.780945309,35.8987354602,2,7,3222102,4 ,1 ,27,,12",
 "441600,4,4,1998/10/01 09:02:00,139.7446370913,35.6937049367,1,9,3215009,9 ,11,30,,8",
 "473800,2,3,1998/10/01 09:02:00,139.6194105884,35.9197394077,2,10,3411010,8 ,1 ,30,,12",
 "480700,2,2,1998/10/01 09:02:00,139.3004818254,36.0964924879,1,10,3414121,4 ,1 ,44,,6",
 "485300,2,2,1998/10/01 09:02:00,139.5777220508,35.9600204159,1,13,3511206,15,5 ,26,,2",
 "563500,2,5,1998/10/01 09:02:00,139.7968889465,35.62862282,1,7,0212001,10,1 ,84,,1",
 "600300,2,2,1998/10/01 09:02:00,139.6419221983,35.7093383494,1,5,0420106,13,2 ,64,,2",
 "611800,2,3,1998/10/01 09:02:00,139.4770531773,35.5135325696,1,4,0431110,13,2 ,65,,12",
 "616400,2,2,1998/10/01 09:02:00,139.6582723633,35.6057509879,2,13,0433105,15,7 ,50,,1",
 "621000,2,4,1998/10/01 09:02:00,139.6989374629,35.6894578603,2,10,0436007,14,7 ,45,,1",
 "701500,4,4,1998/10/01 09:02:00,139.5629604686,35.7073486041,2,10,0721104,9 ,4 ,48,,2",
 "724500,2,2,1998/10/01 09:02:00,139.5909848512,35.6791898071,2,10,0761001,8 ,1 ,33,,7",
 "724500,3,3,1998/10/01 09:02:00,139.5909848512,35.6791898071,2,10,0761001,8 ,97,33,,97",
 "823400,2,5,1998/10/01 09:02:00,139.6178621556,35.4366906967,1,10,1213105,9 ,13,34,,1",
 "786600,3,5,1998/10/01 09:02:00,139.290193167,35.6622850661,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:02:00,139.4711602571,35.5830926752,1,4,0811306,13,2 ,45,,1",
 "708400,2,3,1998/10/01 09:02:00,139.6086279441,35.7249962084,2,8,0731211,8 ,1 ,34,,12",
 "719900,2,4,1998/10/01 09:02:00,139.6829801543,35.706143235,1,7,0752005,6 ,10,34,,12",
 "89700,7,7,1998/10/01 09:03:00,140.1946232273,35.7979379582,2,8,4422113,3 ,3 ,42,,7",
 "117300,2,2,1998/10/01 09:03:00,139.8414123508,34.9405736509,1,8,4730209,3 ,8 ,38,,6",
 "280600,5,5,1998/10/01 09:03:00,139.7603561118,35.8028375624,1,11,2910009,8 ,9 ,42,,8",
 "317400,2,2,1998/10/01 09:03:00,139.6730086226,35.5421942245,2,5,2022101,14,7 ,42,,6",
 "9200,2,2,1998/10/01 09:03:00,139.950263488,35.7000650976,1,11,4114306,5 ,1 ,28,,1",
 "363400,4,5,1998/10/01 09:03:00,139.6766929029,35.7610396161,1,6,3032201,3 ,11,34,,6",
 "48300,2,3,1998/10/01 09:03:00,139.7994126219,35.7096560805,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 09:03:00,139.7739216335,35.7338005085,1,7,4224202,9 ,1 ,37,,12",
 "151800,2,5,1998/10/01 09:03:00,139.6889503102,35.6797686395,1,9,4031206,8 ,1 ,38,,1",
 "172500,2,3,1998/10/01 09:03:00,139.7999686428,35.7350317496,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 09:03:00,139.9511430576,35.8431715583,2,5,5412112,9 ,1 ,35,,12",
 "211600,2,4,1998/10/01 09:03:00,139.6205770554,35.4538105662,1,7,2220004,6 ,1 ,36,,12",
 "209300,2,3,1998/10/01 09:03:00,139.7408890557,35.6193749545,1,13,2211303,10,1 ,37,,12",
 "296700,2,2,1998/10/01 09:03:00,139.4292333692,35.5222893342,2,8,2920301,4 ,1 ,33,,1",
 "119600,2,2,1998/10/01 09:03:00,140.8587891057,35.7281919655,2,13,4810072,14,8 ,45,,1",
 "324300,2,3,1998/10/01 09:03:00,139.7306434435,35.674754315,1,10,2110103,8 ,1 ,29,,12",
 "250700,2,2,1998/10/01 09:03:00,139.3189140486,35.3269651575,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:03:00,139.4038390047,35.4646059246,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 09:03:00,139.6719248044,35.7377322643,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,2,1998/10/01 09:03:00,139.6570474036,35.8584903326,1,10,3033223,10,1 ,38,,1",
 "365700,2,3,1998/10/01 09:03:00,139.6570474036,35.8584903326,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:03:00,139.6165864139,35.7866854431,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:03:00,139.4724098329,35.7733096379,1,11,3123503,8 ,1 ,23,,12",
 "374900,2,2,1998/10/01 09:03:00,139.7610865153,35.705797115,1,4,3040108,4 ,1 ,71,,6",
 "377200,2,2,1998/10/01 09:03:00,139.6386933789,35.9209693704,1,14,3052009,15,6 ,29,,3",
 "402500,4,4,1998/10/01 09:03:00,139.4735707834,35.7952446832,1,9,3116015,9 ,11,44,,8",
 "448500,2,3,1998/10/01 09:03:00,139.7836963927,35.8928486005,2,7,3222102,4 ,1 ,27,,12",
 "441600,4,4,1998/10/01 09:03:00,139.7424579339,35.6929629502,1,9,3215009,9 ,11,30,,8",
 "473800,2,3,1998/10/01 09:03:00,139.6210193311,35.9156779373,2,10,3411010,8 ,1 ,30,,12",
 "460000,2,2,1998/10/01 09:03:00,139.7250021395,35.993037697,2,4,3234215,4 ,1 ,40,,6",
 "480700,2,2,1998/10/01 09:03:00,139.2958457454,36.0992597273,1,10,3414121,4 ,1 ,44,,6",
 "485300,2,2,1998/10/01 09:03:00,139.576724151,35.9591935273,1,13,3511206,15,5 ,26,,2",
 "563500,2,5,1998/10/01 09:03:00,139.7967559549,35.6286720365,1,7,0212001,10,1 ,84,,1",
 "600300,2,2,1998/10/01 09:03:00,139.6410834277,35.7086660564,1,5,0420106,13,2 ,64,,2",
 "611800,2,3,1998/10/01 09:03:00,139.4713897786,35.5115977473,1,4,0431110,13,2 ,65,,12",
 "616400,2,2,1998/10/01 09:03:00,139.6576719301,35.6058536947,2,13,0433105,15,7 ,50,,1",
 "621000,2,4,1998/10/01 09:03:00,139.6990920186,35.6883708598,2,10,0436007,14,7 ,45,,1",
 "701500,4,4,1998/10/01 09:03:00,139.5634158869,35.7081259155,2,10,0721104,9 ,4 ,48,,2",
 "823400,2,5,1998/10/01 09:03:00,139.6191319122,35.4338255991,1,10,1213105,9 ,13,34,,1",
 "786600,3,5,1998/10/01 09:03:00,139.289893986,35.662172684,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:03:00,139.4716879205,35.5836090644,1,4,0811306,13,2 ,45,,1",
 "719900,2,4,1998/10/01 09:03:00,139.6866685038,35.7062921755,1,7,0752005,6 ,10,34,,12",
 "708400,2,3,1998/10/01 09:03:00,139.6144680257,35.7247396054,2,8,0731211,8 ,1 ,34,,12",
 "89700,7,7,1998/10/01 09:04:00,140.1977601614,35.7941663597,2,8,4422113,3 ,3 ,42,,7",
 "117300,2,2,1998/10/01 09:04:00,139.8395668082,34.9415634454,1,8,4730209,3 ,8 ,38,,6",
 "280600,5,5,1998/10/01 09:04:00,139.7597137464,35.8056078077,1,11,2910009,8 ,9 ,42,,8",
 "317400,2,2,1998/10/01 09:04:00,139.6746073639,35.5425273007,2,5,2022101,14,7 ,42,,6",
 "363400,4,5,1998/10/01 09:04:00,139.6789309521,35.7600601701,1,6,3032201,3 ,11,34,,6",
 "9200,2,2,1998/10/01 09:04:00,139.948926255,35.7003832106,1,11,4114306,5 ,1 ,28,,1",
 "434700,6,6,1998/10/01 09:04:00,139.7599253421,35.8152143202,2,8,3210107,8 ,10,37,,7",
 "48300,2,3,1998/10/01 09:04:00,139.7957933592,35.708155822,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 09:04:00,139.7696764709,35.7301256472,1,7,4224202,9 ,1 ,37,,12",
 "151800,2,5,1998/10/01 09:04:00,139.6895002284,35.6797890414,1,9,4031206,8 ,1 ,38,,1",
 "172500,2,3,1998/10/01 09:04:00,139.7963727736,35.7311573565,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 09:04:00,139.945632378,35.840488972,2,5,5412112,9 ,1 ,35,,12",
 "209300,2,3,1998/10/01 09:04:00,139.7386719976,35.6250762905,1,13,2211303,10,1 ,37,,12",
 "211600,2,4,1998/10/01 09:04:00,139.6190524654,35.4598647177,1,7,2220004,6 ,1 ,36,,12",
 "296700,2,2,1998/10/01 09:04:00,139.4296995592,35.5229429174,2,8,2920301,4 ,1 ,33,,1",
 "119600,2,2,1998/10/01 09:04:00,140.8586375452,35.729218628,2,13,4810072,14,8 ,45,,1",
 "324300,2,3,1998/10/01 09:04:00,139.7341796048,35.6765813179,1,10,2110103,8 ,1 ,29,,12",
 "250700,2,2,1998/10/01 09:04:00,139.3206446583,35.3267326638,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:04:00,139.4051106002,35.4654660502,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 09:04:00,139.6671865455,35.7387249143,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,3,1998/10/01 09:04:00,139.6601250634,35.8549542677,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:04:00,139.6210081452,35.7849833903,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:04:00,139.4699022472,35.7691767447,1,11,3123503,8 ,1 ,23,,12",
 "374900,2,2,1998/10/01 09:04:00,139.7635024242,35.7032636175,1,4,3040108,4 ,1 ,71,,6",
 "377200,2,2,1998/10/01 09:04:00,139.638516794,35.9209713986,1,14,3052009,15,6 ,29,,3",
 "402500,4,4,1998/10/01 09:04:00,139.4699561476,35.7945242445,1,9,3116015,9 ,11,44,,8",
 "448500,2,3,1998/10/01 09:04:00,139.7864504953,35.8869627304,2,7,3222102,4 ,1 ,27,,12",
 "441600,4,4,1998/10/01 09:04:00,139.7402425772,35.6922949272,1,9,3215009,9 ,11,30,,8",
 "473800,2,3,1998/10/01 09:04:00,139.6224996566,35.9115873389,2,10,3411010,8 ,1 ,30,,12",
 "460000,2,2,1998/10/01 09:04:00,139.7269571561,35.9920387362,2,4,3234215,4 ,1 ,40,,6",
 "485300,2,2,1998/10/01 09:04:00,139.575254883,35.9587547075,1,13,3511206,15,5 ,26,,2",
 "480700,2,2,1998/10/01 09:04:00,139.2897575963,36.0991503495,1,10,3414121,4 ,1 ,44,,6",
 "563500,2,5,1998/10/01 09:04:00,139.7975505264,35.6275901876,1,7,0212001,10,1 ,84,,1",
 "600300,2,2,1998/10/01 09:04:00,139.6404620873,35.707849042,1,5,0420106,13,2 ,64,,2",
 "611800,2,3,1998/10/01 09:04:00,139.4651752216,35.511238084,1,4,0431110,13,2 ,65,,12",
 "616400,2,2,1998/10/01 09:04:00,139.6570714954,35.6059563986,2,13,0433105,15,7 ,50,,1",
 "621000,2,4,1998/10/01 09:04:00,139.6995712152,35.687351315,2,10,0436007,14,7 ,45,,1",
 "701500,4,4,1998/10/01 09:04:00,139.5638032372,35.7089109973,2,10,0721104,9 ,4 ,48,,2",
 "823400,2,5,1998/10/01 09:04:00,139.6204410688,35.4309729175,1,10,1213105,9 ,13,34,,1",
 "786600,3,5,1998/10/01 09:04:00,139.2895948059,35.6620603012,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:04:00,139.4722118472,35.5841279919,1,4,0811306,13,2 ,45,,1",
 "708400,2,3,1998/10/01 09:04:00,139.620286068,35.7244048612,2,8,0731211,8 ,1 ,34,,12",
 "719900,2,4,1998/10/01 09:04:00,139.6902964843,35.7057477269,1,7,0752005,6 ,10,34,,12",
 "89700,7,7,1998/10/01 09:05:00,140.200142601,35.7898945793,2,8,4422113,3 ,3 ,42,,7",
 "117300,2,2,1998/10/01 09:05:00,139.8373951211,34.9426795878,1,8,4730209,3 ,8 ,38,,6",
 "280600,5,5,1998/10/01 09:05:00,139.759103926,35.8083870111,1,11,2910009,8 ,9 ,42,,8",
 "317400,2,2,1998/10/01 09:05:00,139.6752951238,35.5416661726,2,5,2022101,14,7 ,42,,6",
 "9200,2,2,1998/10/01 09:05:00,139.9475890113,35.7007013088,1,11,4114306,5 ,1 ,28,,1",
 "363400,4,5,1998/10/01 09:05:00,139.6812506232,35.7591596212,1,6,3032201,3 ,11,34,,6",
 "434700,6,6,1998/10/01 09:05:00,139.7592127909,35.8144680248,2,8,3210107,8 ,10,37,,7",
 "48300,2,3,1998/10/01 09:05:00,139.7933145562,35.705173868,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 09:05:00,139.7740461715,35.7255744003,1,7,4224202,9 ,1 ,37,,12",
 "151800,2,5,1998/10/01 09:05:00,139.6901901054,35.6799845752,1,9,4031206,8 ,1 ,38,,1",
 "172500,2,3,1998/10/01 09:05:00,139.7903257965,35.7315927567,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 09:05:00,139.940114216,35.8378167987,2,5,5412112,9 ,1 ,35,,12",
 "211600,2,4,1998/10/01 09:05:00,139.6212320355,35.4654852753,1,7,2220004,6 ,1 ,36,,12",
 "211600,2,5,1998/10/01 09:05:00,139.6212320355,35.4654852753,1,7,2220004,6 ,1 ,36,,1",
 "209300,2,3,1998/10/01 09:05:00,139.7381774919,35.6310931937,1,13,2211303,10,1 ,37,,12",
 "296700,2,2,1998/10/01 09:05:00,139.4306098054,35.5241416073,2,8,2920301,4 ,1 ,33,,1",
 "119600,2,2,1998/10/01 09:05:00,140.8584861205,35.7303056949,2,13,4810072,14,8 ,45,,1",
 "324300,2,3,1998/10/01 09:05:00,139.7373552453,35.6769960493,1,10,2110103,8 ,1 ,29,,12",
 "250700,2,2,1998/10/01 09:05:00,139.3223934929,35.3266220058,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:05:00,139.4062132486,35.4664732357,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 09:05:00,139.6623440692,35.7386981648,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,3,1998/10/01 09:05:00,139.6642477445,35.8519892698,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:05:00,139.6251041993,35.7828514982,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:05:00,139.4673699688,35.7650535373,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:05:00,139.638340209,35.9209734266,1,14,3052009,15,6 ,29,,3",
 "374900,2,2,1998/10/01 09:05:00,139.7666773229,35.7011977783,1,4,3040108,4 ,1 ,71,,6",
 "402500,4,4,1998/10/01 09:05:00,139.4663641071,35.7946765235,1,9,3116015,9 ,11,44,,8",
 "448500,2,3,1998/10/01 09:05:00,139.7886399957,35.8809461324,2,7,3222102,4 ,1 ,27,,12",
 "473800,2,3,1998/10/01 09:05:00,139.6237908214,35.9074844906,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 09:05:00,139.7380170529,35.6916497454,1,9,3215009,9 ,11,30,,8",
 "460000,2,2,1998/10/01 09:05:00,139.7289121235,35.9910397432,2,4,3234215,4 ,1 ,40,,6",
 "480700,2,2,1998/10/01 09:05:00,139.2849238104,36.1024310116,1,10,3414121,4 ,1 ,44,,6",
 "485300,2,2,1998/10/01 09:05:00,139.5737293501,35.9583753573,1,13,3511206,15,5 ,26,,2",
 "563500,2,5,1998/10/01 09:05:00,139.7983450766,35.6265083332,1,7,0212001,10,1 ,84,,1",
 "563500,3,6,1998/10/01 09:05:00,139.7983450766,35.6265083332,1,7,0212001,10,97,84,,97",
 "600300,2,2,1998/10/01 09:05:00,139.6398407596,35.7070320244,1,5,0420106,13,2 ,64,,2",
 "611800,2,3,1998/10/01 09:05:00,139.4590052817,35.5106247094,1,4,0431110,13,2 ,65,,12",
 "616400,2,2,1998/10/01 09:05:00,139.6564710593,35.6060590995,2,13,0433105,15,7 ,50,,1",
 "621000,2,4,1998/10/01 09:05:00,139.700120797,35.6863502351,2,10,0436007,14,7 ,45,,1",
 "701500,4,4,1998/10/01 09:05:00,139.5636269973,35.7097604222,2,10,0721104,9 ,4 ,48,,2",
 "754400,2,2,1998/10/01 09:05:00,139.472750705,35.5844994712,1,4,0811306,13,2 ,45,,1",
 "823400,2,5,1998/10/01 09:05:00,139.6219562288,35.4281946392,1,10,1213105,9 ,13,34,,1",
 "786600,3,5,1998/10/01 09:05:00,139.2892956266,35.6619479176,2,3,0930110,13,2 ,35,,9",
 "719900,2,4,1998/10/01 09:05:00,139.693638572,35.7044943619,1,7,0752005,6 ,10,34,,12",
 "708400,2,3,1998/10/01 09:05:00,139.6260720919,35.7237162501,2,8,0731211,8 ,1 ,34,,12",
 "89700,7,7,1998/10/01 09:06:00,140.2015452083,35.7853321345,2,8,4422113,3 ,3 ,42,,7",
 "117300,2,2,1998/10/01 09:06:00,139.8366061179,34.9448448281,1,8,4730209,3 ,8 ,38,,6",
 "280600,5,5,1998/10/01 09:06:00,139.7592277815,35.8111941847,1,11,2910009,8 ,9 ,42,,8",
 "317400,2,2,1998/10/01 09:06:00,139.6758103864,35.5403901036,2,5,2022101,14,7 ,42,,6",
 "9200,2,2,1998/10/01 09:06:00,139.946251757,35.7010193922,1,11,4114306,5 ,1 ,28,,1",
 "363400,4,5,1998/10/01 09:06:00,139.6833790752,35.7579683264,1,6,3032201,3 ,11,34,,6",
 "434700,6,6,1998/10/01 09:06:00,139.757447646,35.814839676,2,8,3210107,8 ,10,37,,7",
 "48300,2,3,1998/10/01 09:06:00,139.7901786172,35.7026280729,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 09:06:00,139.7785585487,35.7209436325,1,7,4224202,9 ,1 ,37,,12",
 "80500,2,2,1998/10/01 09:06:00,140.19154494,35.7185428721,1,14,4413233,5 ,1 ,33,,6",
 "151800,2,5,1998/10/01 09:06:00,139.6908588947,35.6802245022,1,9,4031206,8 ,1 ,38,,1",
 "172500,2,3,1998/10/01 09:06:00,139.7842841347,35.7324007809,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 09:06:00,139.934643293,35.8350822111,2,5,5412112,9 ,1 ,35,,12",
 "209300,2,3,1998/10/01 09:06:00,139.7392869583,35.6370525932,1,13,2211303,10,1 ,37,,12",
 "211600,2,5,1998/10/01 09:06:00,139.6201027022,35.4659423811,1,7,2220004,6 ,1 ,36,,1",
 "296700,2,2,1998/10/01 09:06:00,139.4314317801,35.5247758851,2,8,2920301,4 ,1 ,33,,1",
 "119600,2,2,1998/10/01 09:06:00,140.8579986016,35.7312039043,2,13,4810072,14,8 ,45,,1",
 "324300,2,3,1998/10/01 09:06:00,139.7388632214,35.6737937052,1,10,2110103,8 ,1 ,29,,12",
 "250700,2,2,1998/10/01 09:06:00,139.324133555,35.3265183779,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:06:00,139.4072182396,35.4675471941,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 09:06:00,139.6574843827,35.7382416477,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,3,1998/10/01 09:06:00,139.6680719292,35.8487765159,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:06:00,139.6288831241,35.7803232857,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:06:00,139.4675506805,35.7605142038,1,11,3123503,8 ,1 ,23,,12",
 "374900,2,2,1998/10/01 09:06:00,139.7688549995,35.698900607,1,4,3040108,4 ,1 ,71,,6",
 "377200,2,2,1998/10/01 09:06:00,139.638163624,35.9209754543,1,14,3052009,15,6 ,29,,3",
 "402500,4,4,1998/10/01 09:06:00,139.4627450479,35.7951219366,1,9,3116015,9 ,11,44,,8",
 "448500,2,3,1998/10/01 09:06:00,139.7900320134,35.8747507551,2,7,3222102,4 ,1 ,27,,12",
 "473800,2,3,1998/10/01 09:06:00,139.6253802013,35.9035926867,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 09:06:00,139.7358526525,35.691298328,1,9,3215009,9 ,11,30,,8",
 "460000,2,2,1998/10/01 09:06:00,139.7308670416,35.9900407182,2,4,3234215,4 ,1 ,40,,6",
 "480700,2,2,1998/10/01 09:06:00,139.2837185517,36.1071242374,1,10,3414121,4 ,1 ,44,,6",
 "485300,2,2,1998/10/01 09:06:00,139.5723594064,35.9577018944,1,13,3511206,15,5 ,26,,2",
 "600300,2,2,1998/10/01 09:06:00,139.6392194446,35.7062150034,1,5,0420106,13,2 ,64,,2",
 "611800,2,3,1998/10/01 09:06:00,139.4530388912,35.50916232,1,4,0431110,13,2 ,65,,12",
 "616400,2,2,1998/10/01 09:06:00,139.6558706215,35.6061617974,2,13,0433105,15,7 ,50,,1",
 "621000,2,4,1998/10/01 09:06:00,139.700670365,35.6853491526,2,10,0436007,14,7 ,45,,1",
 "701500,4,4,1998/10/01 09:06:00,139.5634507535,35.7106098468,2,10,0721104,9 ,4 ,48,,2",
 "823400,2,5,1998/10/01 09:06:00,139.6236212153,35.4257960475,1,10,1213105,9 ,13,34,,1",
 "786600,3,5,1998/10/01 09:06:00,139.2889964482,35.6618355333,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:06:00,139.4733810642,35.5840685815,1,4,0811306,13,2 ,45,,1",
 "719900,2,4,1998/10/01 09:06:00,139.6962263637,35.702351245,1,7,0752005,6 ,10,34,,12",
 "708400,2,3,1998/10/01 09:06:00,139.6318628159,35.7230477666,2,8,0731211,8 ,1 ,34,,12",
 "89700,7,7,1998/10/01 09:07:00,140.2029126532,35.7807517328,2,8,4422113,3 ,3 ,42,,7",
 "117300,2,2,1998/10/01 09:07:00,139.8356997299,34.9470023722,1,8,4730209,3 ,8 ,38,,6",
 "280600,5,5,1998/10/01 09:07:00,139.7597520735,35.8139855934,1,11,2910009,8 ,9 ,42,,8",
 "317400,2,2,1998/10/01 09:07:00,139.6770385608,35.5398056467,2,5,2022101,14,7 ,42,,6",
 "9200,2,2,1998/10/01 09:07:00,139.944914492,35.7013374607,1,11,4114306,5 ,1 ,28,,1",
 "363400,4,5,1998/10/01 09:07:00,139.685516577,35.7567883608,1,6,3032201,3 ,11,34,,6",
 "434700,6,6,1998/10/01 09:07:00,139.7570726457,35.8163082544,2,8,3210107,8 ,10,37,,7",
 "434700,7,7,1998/10/01 09:07:00,139.7570726457,35.8163082544,2,8,3210107,8 ,97,37,,97",
 "48300,2,3,1998/10/01 09:07:00,139.7877633017,35.699622699,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 09:07:00,139.7777145702,35.7155760983,1,7,4224202,9 ,1 ,37,,12",
 "80500,2,2,1998/10/01 09:07:00,140.1906603054,35.7208256792,1,14,4413233,5 ,1 ,33,,6",
 "151800,2,5,1998/10/01 09:07:00,139.6914726691,35.6805480535,1,9,4031206,8 ,1 ,38,,1",
 "172500,2,3,1998/10/01 09:07:00,139.7782497195,35.7332396859,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 09:07:00,139.9291254589,35.8324109562,2,5,5412112,9 ,1 ,35,,12",
 "211600,2,5,1998/10/01 09:07:00,139.6201544636,35.465325221,1,7,2220004,6 ,1 ,36,,1",
 "209300,2,3,1998/10/01 09:07:00,139.742093381,35.6427050941,1,13,2211303,10,1 ,37,,12",
 "296700,2,2,1998/10/01 09:07:00,139.4322816632,35.5260038454,2,8,2920301,4 ,1 ,33,,1",
 "119600,2,2,1998/10/01 09:07:00,140.856898899,35.7315314748,2,13,4810072,14,8 ,45,,1",
 "324300,2,3,1998/10/01 09:07:00,139.7419652298,35.6715151004,1,10,2110103,8 ,1 ,29,,12",
 "250700,2,2,1998/10/01 09:07:00,139.3257361286,35.3270896451,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:07:00,139.408229248,35.4686178156,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 09:07:00,139.6529336294,35.7376660132,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,3,1998/10/01 09:07:00,139.671803537,35.8454865444,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:07:00,139.6323148571,35.7777208033,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:07:00,139.4680705388,35.7559228373,1,11,3123503,8 ,1 ,23,,12",
 "374900,2,2,1998/10/01 09:07:00,139.7713043425,35.6967727884,1,4,3040108,4 ,1 ,71,,6",
 "377200,2,2,1998/10/01 09:07:00,139.637987039,35.9209774817,1,14,3052009,15,6 ,29,,3",
 "402500,4,4,1998/10/01 09:07:00,139.4592286643,35.7944811939,1,9,3116015,9 ,11,44,,8",
 "448500,2,3,1998/10/01 09:07:00,139.7914239124,35.8685553698,2,7,3222102,4 ,1 ,27,,12",
 "473800,2,3,1998/10/01 09:07:00,139.6281354851,35.8999776465,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 09:07:00,139.7342270508,35.6917375515,1,9,3215009,9 ,11,30,,8",
 "460000,2,2,1998/10/01 09:07:00,139.7328237914,35.9890442073,2,4,3234215,4 ,1 ,40,,6",
 "480700,2,2,1998/10/01 09:07:00,139.2848590009,36.1120951646,1,10,3414121,4 ,1 ,44,,6",
 "485300,2,2,1998/10/01 09:07:00,139.5712131285,35.9567962483,1,13,3511206,15,5 ,26,,2",
 "600300,2,2,1998/10/01 09:07:00,139.6385805421,35.7054638045,1,5,0420106,13,2 ,64,,2",
 "611800,2,3,1998/10/01 09:07:00,139.4470361267,35.5078221346,1,4,0431110,13,2 ,65,,12",
 "616400,2,2,1998/10/01 09:07:00,139.6552701823,35.6062644923,2,13,0433105,15,7 ,50,,1",
 "621000,2,4,1998/10/01 09:07:00,139.7012199192,35.6843480674,2,10,0436007,14,7 ,45,,1",
 "701500,4,4,1998/10/01 09:07:00,139.5628945034,35.7112204524,2,10,0721104,9 ,4 ,48,,2",
 "823400,2,5,1998/10/01 09:07:00,139.6248236743,35.4229115613,1,10,1213105,9 ,13,34,,1",
 "786600,3,5,1998/10/01 09:07:00,139.2886972706,35.6617231483,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:07:00,139.4741673805,35.583883084,1,4,0811306,13,2 ,45,,1",
 "719900,2,4,1998/10/01 09:07:00,139.6980378405,35.6997259512,1,7,0752005,6 ,10,34,,12",
 "708400,2,3,1998/10/01 09:07:00,139.6376866733,35.7227156862,2,8,0731211,8 ,1 ,34,,12",
 "89700,7,7,1998/10/01 09:08:00,140.2079837306,35.7790365493,2,8,4422113,3 ,3 ,42,,7",
 "117300,2,2,1998/10/01 09:08:00,139.8350534141,34.9489137708,1,8,4730209,3 ,8 ,38,,6",
 "280600,5,5,1998/10/01 09:08:00,139.7601142864,35.8167943638,1,11,2910009,8 ,9 ,42,,8",
 "317400,2,2,1998/10/01 09:08:00,139.6785343319,35.539237597,2,5,2022101,14,7 ,42,,6",
 "9200,2,2,1998/10/01 09:08:00,139.9435772163,35.7016555143,1,11,4114306,5 ,1 ,28,,1",
 "363400,4,5,1998/10/01 09:08:00,139.6875746444,35.7555175719,1,6,3032201,3 ,11,34,,6",
 "48300,2,3,1998/10/01 09:08:00,139.7856801626,35.6964402688,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 09:08:00,139.7750548298,35.7099991137,1,7,4224202,9 ,1 ,37,,12",
 "80500,2,2,1998/10/01 09:08:00,140.1879407104,35.7230075502,1,14,4413233,5 ,1 ,33,,6",
 "151800,2,5,1998/10/01 09:08:00,139.6920568049,35.680905819,1,9,4031206,8 ,1 ,38,,1",
 "172500,2,3,1998/10/01 09:08:00,139.7722315264,35.7335651536,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 09:08:00,139.9239162086,35.8293983225,2,5,5412112,9 ,1 ,35,,12",
 "211600,2,5,1998/10/01 09:08:00,139.6193545442,35.4647805152,1,7,2220004,6 ,1 ,36,,1",
 "209300,2,3,1998/10/01 09:08:00,139.7473286657,35.6468726617,1,13,2211303,10,1 ,37,,12",
 "296700,2,2,1998/10/01 09:08:00,139.433131572,35.5272317997,2,8,2920301,4 ,1 ,33,,1",
 "119600,2,2,1998/10/01 09:08:00,140.8570000926,35.7326221593,2,13,4810072,14,8 ,45,,1",
 "324300,2,3,1998/10/01 09:08:00,139.7458780582,35.6703915727,1,10,2110103,8 ,1 ,29,,12",
 "250700,2,2,1998/10/01 09:08:00,139.3272918558,35.3277556864,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:08:00,139.4092217028,35.4697000246,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 09:08:00,139.6480411122,35.7376342791,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,3,1998/10/01 09:08:00,139.6755955693,35.8422424152,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:08:00,139.6342591369,35.7751296093,1,7,3110313,8 ,1 ,33,,12",
 "374900,2,2,1998/10/01 09:08:00,139.7731936456,35.6945942706,1,4,3040108,4 ,1 ,71,,6",
 "418600,2,4,1998/10/01 09:08:00,139.4699789134,35.7518444512,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:08:00,139.637810454,35.9209795089,1,14,3052009,15,6 ,29,,3",
 "402500,4,4,1998/10/01 09:08:00,139.4560493059,35.7960536845,1,9,3116015,9 ,11,44,,8",
 "448500,2,3,1998/10/01 09:08:00,139.7927791945,35.8623546322,2,7,3222102,4 ,1 ,27,,12",
 "473800,2,3,1998/10/01 09:08:00,139.6315600078,35.8967495232,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 09:08:00,139.731948475,35.6912380307,1,9,3215009,9 ,11,30,,8",
 "460000,2,2,1998/10/01 09:08:00,139.734825786,35.9881088647,2,4,3234215,4 ,1 ,40,,6",
 "480700,2,2,1998/10/01 09:08:00,139.283240365,36.1169823829,1,10,3414121,4 ,1 ,44,,6",
 "485300,2,2,1998/10/01 09:08:00,139.5701193724,35.9558423483,1,13,3511206,15,5 ,26,,2",
 "600300,2,2,1998/10/01 09:08:00,139.6374466822,35.7052010525,1,5,0420106,13,2 ,64,,2",
 "611800,2,3,1998/10/01 09:08:00,139.4440003864,35.5101623849,1,4,0431110,13,2 ,65,,12",
 "616400,2,2,1998/10/01 09:08:00,139.6546697415,35.6063671842,2,13,0433105,15,7 ,50,,1",
 "621000,2,4,1998/10/01 09:08:00,139.7017694597,35.6833469794,2,10,0436007,14,7 ,45,,1",
 "701500,4,4,1998/10/01 09:08:00,139.5619265571,35.7115646292,2,10,0721104,9 ,4 ,48,,2",
 "823400,2,5,1998/10/01 09:08:00,139.6259707022,35.4200120781,1,10,1213105,9 ,13,34,,1",
 "786600,3,5,1998/10/01 09:08:00,139.2883980938,35.6616107625,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:08:00,139.4749695116,35.5837310892,1,4,0811306,13,2 ,45,,1",
 "708400,2,3,1998/10/01 09:08:00,139.6435293817,35.7225866558,2,8,0731211,8 ,1 ,34,,12",
 "719900,2,4,1998/10/01 09:08:00,139.6992552748,35.6968935931,1,7,0752005,6 ,10,34,,12",
 "89700,7,7,1998/10/01 09:09:00,140.2134965674,35.779267096,2,8,4422113,3 ,3 ,42,,7",
 "117300,2,2,1998/10/01 09:09:00,139.8367713667,34.95071149,1,8,4730209,3 ,8 ,38,,6",
 "280600,5,5,1998/10/01 09:09:00,139.7602222598,35.8196164384,1,11,2910009,8 ,9 ,42,,8",
 "317400,2,2,1998/10/01 09:09:00,139.6800468698,35.5386991928,2,5,2022101,14,7 ,42,,6",
 "9200,2,2,1998/10/01 09:09:00,139.9422985731,35.7020312504,1,11,4114306,5 ,1 ,28,,1",
 "363400,4,5,1998/10/01 09:09:00,139.6896179952,35.7542310364,1,6,3032201,3 ,11,34,,6",
 "48300,2,3,1998/10/01 09:09:00,139.7849301506,35.6928932438,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 09:09:00,139.77425724,35.7042201363,1,7,4224202,9 ,1 ,37,,12",
 "80500,2,2,1998/10/01 09:09:00,140.1851076205,35.7230329903,1,14,4413233,5 ,1 ,33,,6",
 "151800,2,5,1998/10/01 09:09:00,139.6926317584,35.6812739007,1,9,4031206,8 ,1 ,38,,1",
 "172500,2,3,1998/10/01 09:09:00,139.7698553193,35.7298547918,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 09:09:00,139.9209606201,35.8248215441,2,5,5412112,9 ,1 ,35,,12",
 "211600,2,5,1998/10/01 09:09:00,139.6188313632,35.4640086065,1,7,2220004,6 ,1 ,36,,1",
 "209300,2,3,1998/10/01 09:09:00,139.7534147413,35.6499493132,1,13,2211303,10,1 ,37,,12",
 "296700,2,2,1998/10/01 09:09:00,139.4339815066,35.5284597479,2,8,2920301,4 ,1 ,33,,1",
 "119600,2,2,1998/10/01 09:09:00,140.8571949517,35.7337046177,2,13,4810072,14,8 ,45,,1",
 "324300,2,3,1998/10/01 09:09:00,139.7500736282,35.6701825781,1,10,2110103,8 ,1 ,29,,12",
 "324300,2,4,1998/10/01 09:09:00,139.7500736282,35.6701825781,1,10,2110103,8 ,1 ,29,,1",
 "250700,2,2,1998/10/01 09:09:00,139.3288466624,35.3284231246,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:09:00,139.4102155189,35.4707814322,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 09:09:00,139.6431666628,35.7373463635,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,3,1998/10/01 09:09:00,139.679201825,35.8388602526,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:09:00,139.637603881,35.7722668034,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:09:00,139.4738716776,35.7484992251,1,11,3123503,8 ,1 ,23,,12",
 "374900,2,2,1998/10/01 09:09:00,139.7746304405,35.6917334874,1,4,3040108,4 ,1 ,71,,6",
 "377200,2,2,1998/10/01 09:09:00,139.637633869,35.9209815359,1,14,3052009,15,6 ,29,,3",
 "402500,4,4,1998/10/01 09:09:00,139.4529652153,35.7977501428,1,9,3116015,9 ,11,44,,8",
 "448500,2,3,1998/10/01 09:09:00,139.7943848017,35.8562011176,2,7,3222102,4 ,1 ,27,,12",
 "473800,2,3,1998/10/01 09:09:00,139.6325640188,35.8933417936,2,10,3411010,8 ,1 ,30,,12",
 "441600,4,4,1998/10/01 09:09:00,139.7295969079,35.6911503537,1,9,3215009,9 ,11,30,,8",
 "460000,2,2,1998/10/01 09:09:00,139.7368407351,35.9871924475,2,4,3234215,4 ,1 ,40,,6",
 "485300,2,2,1998/10/01 09:09:00,139.569045015,35.9548743901,1,13,3511206,15,5 ,26,,2",
 "480700,2,2,1998/10/01 09:09:00,139.2809054643,36.1215582498,1,10,3414121,4 ,1 ,44,,6",
 "600300,2,2,1998/10/01 09:09:00,139.6366278251,35.7048045561,1,5,0420106,13,2 ,64,,2",
 "611800,2,3,1998/10/01 09:09:00,139.4419102268,35.5149333654,1,4,0431110,13,2 ,65,,12",
 "616400,2,2,1998/10/01 09:09:00,139.6541045614,35.6065051062,2,13,0433105,15,7 ,50,,1",
 "621000,2,4,1998/10/01 09:09:00,139.7025061848,35.6824505119,2,10,0436007,14,7 ,45,,1",
 "701500,4,4,1998/10/01 09:09:00,139.5609319648,35.7118532783,2,10,0721104,9 ,4 ,48,,2",
 "823400,2,5,1998/10/01 09:09:00,139.6271181464,35.417116493,1,10,1213105,9 ,13,34,,1",
 "786600,3,5,1998/10/01 09:09:00,139.2880989179,35.661498376,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:09:00,139.4752159705,35.5831006969,1,4,0811306,13,2 ,45,,1",
 "708400,2,3,1998/10/01 09:09:00,139.6490529653,35.7210659756,2,8,0731211,8 ,1 ,34,,12",
 "719900,2,4,1998/10/01 09:09:00,139.6998958325,35.6939431532,1,7,0752005,6 ,10,34,,12",
 "89700,7,7,1998/10/01 09:10:00,140.2187811224,35.7776894737,2,8,4422113,3 ,3 ,42,,7",
 "117300,2,2,1998/10/01 09:10:00,139.8369955137,34.9525623024,1,8,4730209,3 ,8 ,38,,6",
 "280600,5,5,1998/10/01 09:10:00,139.761003775,35.8210795185,1,11,2910009,8 ,9 ,42,,8",
 "280600,6,6,1998/10/01 09:10:00,139.761003775,35.8210795185,1,11,2910009,8 ,97,42,,97",
 "317400,2,2,1998/10/01 09:10:00,139.6815514664,35.5381468904,2,5,2022101,14,7 ,42,,6",
 "9200,2,2,1998/10/01 09:10:00,139.9416675959,35.7030442108,1,11,4114306,5 ,1 ,28,,1",
 "9200,2,3,1998/10/01 09:10:00,139.9416675959,35.7030442108,1,11,4114306,5 ,1 ,28,,12",
 "363400,4,5,1998/10/01 09:10:00,139.6915658715,35.7528508226,1,6,3032201,3 ,11,34,,6",
 "48300,2,3,1998/10/01 09:10:00,139.785053221,35.689360006,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 09:10:00,139.7730309731,35.6982301627,1,7,4224202,9 ,1 ,37,,12",
 "80500,2,2,1998/10/01 09:10:00,140.1827879065,35.7241174095,1,14,4413233,5 ,1 ,33,,6",
 "151800,2,5,1998/10/01 09:10:00,139.6931814426,35.6816656626,1,9,4031206,8 ,1 ,38,,1",
 "151800,3,6,1998/10/01 09:10:00,139.6931814426,35.6816656626,1,9,4031206,8 ,97,38,,97",
 "193200,2,4,1998/10/01 09:10:00,139.9194848772,35.819734741,2,5,5412112,9 ,1 ,35,,12",
 "172500,2,3,1998/10/01 09:10:00,139.7733672575,35.7261567952,2,4,5024120,13,2 ,44,,12",
 "209300,2,3,1998/10/01 09:10:00,139.7545620171,35.655968811,1,13,2211303,10,1 ,37,,12",
 "211600,2,5,1998/10/01 09:10:00,139.6187762208,35.4629787887,1,7,2220004,6 ,1 ,36,,1",
 "296700,2,2,1998/10/01 09:10:00,139.434831467,35.52968769,2,8,2920301,4 ,1 ,33,,1",
 "119600,2,2,1998/10/01 09:10:00,140.8570023259,35.7344147387,2,13,4810072,14,8 ,45,,1",
 "119600,3,3,1998/10/01 09:10:00,140.8570023259,35.7344147387,2,13,4810072,14,97,45,,97",
 "324300,2,4,1998/10/01 09:10:00,139.7501712214,35.669915627,1,10,2110103,8 ,1 ,29,,1",
 "250700,2,2,1998/10/01 09:10:00,139.3303939006,35.3291010601,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:10:00,139.4112095939,35.4718626845,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 09:10:00,139.6383209368,35.7367952426,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,3,1998/10/01 09:10:00,139.6827455212,35.8354367159,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:10:00,139.6422022962,35.7709875885,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:10:00,139.4777645023,35.7451541679,1,11,3123503,8 ,1 ,23,,12",
 "374900,2,2,1998/10/01 09:10:00,139.7756632223,35.6888777136,1,4,3040108,4 ,1 ,71,,6",
 "374900,3,3,1998/10/01 09:10:00,139.7756632223,35.6888777136,1,4,3040108,4 ,97,71,,97",
 "377200,2,2,1998/10/01 09:10:00,139.637457284,35.9209835626,1,14,3052009,15,6 ,29,,3",
 "402500,4,4,1998/10/01 09:10:00,139.4499191922,35.7994913645,1,9,3116015,9 ,11,44,,8",
 "448500,2,3,1998/10/01 09:10:00,139.7972463216,35.8503500346,2,7,3222102,4 ,1 ,27,,12",
 "473800,2,3,1998/10/01 09:10:00,139.6285488611,35.8905970095,2,10,3411010,8 ,1 ,30,,12",
 "473800,2,4,1998/10/01 09:10:00,139.6285488611,35.8905970095,2,10,3411010,8 ,1 ,30,,1",
 "441600,4,4,1998/10/01 09:10:00,139.729387888,35.6929766416,1,9,3215009,9 ,11,30,,8",
 "441600,5,5,1998/10/01 09:10:00,139.729387888,35.6929766416,1,9,3215009,9 ,97,30,,97",
 "460000,2,2,1998/10/01 09:10:00,139.7388603506,35.986284714,2,4,3234215,4 ,1 ,40,,6",
 "480700,2,2,1998/10/01 09:10:00,139.2795781559,36.1265598903,1,10,3414121,4 ,1 ,44,,6",
 "485300,2,2,1998/10/01 09:10:00,139.5682202169,35.9537648301,1,13,3511206,15,5 ,26,,2",
 "600300,2,2,1998/10/01 09:10:00,139.6363044357,35.7048979168,1,5,0420106,13,2 ,64,,2",
 "600300,2,3,1998/10/01 09:10:00,139.6363044357,35.7048979168,1,5,0420106,13,2 ,64,,12",
 "616400,2,2,1998/10/01 09:10:00,139.6538618336,35.6069652156,2,13,0433105,15,7 ,50,,1",
 "616400,2,3,1998/10/01 09:10:00,139.6538618336,35.6069652156,2,13,0433105,15,7 ,50,,12",
 "611800,2,3,1998/10/01 09:10:00,139.4394127471,35.5196001631,1,4,0431110,13,2 ,65,,12",
 "621000,2,4,1998/10/01 09:10:00,139.7034518294,35.6816708106,2,10,0436007,14,7 ,45,,1",
 "701500,4,4,1998/10/01 09:10:00,139.5602600864,35.7118457106,2,10,0721104,9 ,4 ,48,,2",
 "701500,5,5,1998/10/01 09:10:00,139.5602600864,35.7118457106,2,10,0721104,9 ,97,48,,97",
 "786600,3,5,1998/10/01 09:10:00,139.2878605028,35.6613320191,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:10:00,139.4754438791,35.5824543223,1,4,0811306,13,2 ,45,,1",
 "823400,2,5,1998/10/01 09:10:00,139.628125927,35.4155218454,1,10,1213105,9 ,13,34,,1",
 "823400,3,6,1998/10/01 09:10:00,139.628125927,35.4155218454,1,10,1213105,9 ,97,34,,97",
 "708400,2,3,1998/10/01 09:10:00,139.6544749041,35.7192798395,2,8,0731211,8 ,1 ,34,,12",
 "719900,2,4,1998/10/01 09:10:00,139.7006024545,35.6910569534,1,7,0752005,6 ,10,34,,12",
 "795800,2,2,1998/10/01 09:11:00,139.6921451084,35.5092052647,1,6,1412027,9 ,1 ,47,,1",
 "89700,7,7,1998/10/01 09:11:00,140.2241738835,35.7761841256,2,8,4422113,3 ,3 ,42,,7",
 "117300,2,2,1998/10/01 09:11:00,139.8380104759,34.9546364319,1,8,4730209,3 ,8 ,38,,6",
 "317400,2,2,1998/10/01 09:11:00,139.6830510967,35.537585728,2,5,2022101,14,7 ,42,,6",
 "9200,2,3,1998/10/01 09:11:00,139.9391936123,35.7008569124,1,11,4114306,5 ,1 ,28,,12",
 "363400,4,5,1998/10/01 09:11:00,139.6935218691,35.7514770978,1,6,3032201,3 ,11,34,,6",
 "48300,2,3,1998/10/01 09:11:00,139.7825427962,35.6865382569,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 09:11:00,139.7711556939,35.6923710669,1,7,4224202,9 ,1 ,37,,12",
 "80500,2,2,1998/10/01 09:11:00,140.1800405062,35.723004162,1,14,4413233,5 ,1 ,33,,6",
 "172500,2,3,1998/10/01 09:11:00,139.77742095,35.7224178185,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 09:11:00,139.9180525618,35.8146395213,2,5,5412112,9 ,1 ,35,,12",
 "209300,2,3,1998/10/01 09:11:00,139.7564177519,35.6617445568,1,13,2211303,10,1 ,37,,12",
 "211600,2,5,1998/10/01 09:11:00,139.6180346475,35.462148677,1,7,2220004,6 ,1 ,36,,1",
 "296700,2,2,1998/10/01 09:11:00,139.4347456386,35.529898929,2,8,2920301,4 ,1 ,33,,1",
 "324300,2,4,1998/10/01 09:11:00,139.750268814,35.6696486758,1,10,2110103,8 ,1 ,29,,1",
 "250700,2,2,1998/10/01 09:11:00,139.3319422648,35.3297767287,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:11:00,139.4121957206,35.4729488097,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 09:11:00,139.633474331,35.7362495037,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,3,1998/10/01 09:11:00,139.6862111751,35.8319586478,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:11:00,139.6462933812,35.7688420696,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:11:00,139.4816587961,35.7418103711,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:11:00,139.637280699,35.920985589,1,14,3052009,15,6 ,29,,3",
 "402500,4,4,1998/10/01 09:11:00,139.4468793129,35.8012397679,1,9,3116015,9 ,11,44,,8",
 "448500,2,3,1998/10/01 09:11:00,139.8000973106,35.8444953042,2,7,3222102,4 ,1 ,27,,12",
 "473800,2,4,1998/10/01 09:11:00,139.628920797,35.8898939508,2,10,3411010,8 ,1 ,30,,1",
 "460000,2,2,1998/10/01 09:11:00,139.7407785575,35.9852595116,2,4,3234215,4 ,1 ,40,,6",
 "485300,2,2,1998/10/01 09:11:00,139.5678975488,35.9525014147,1,13,3511206,15,5 ,26,,2",
 "480700,2,2,1998/10/01 09:11:00,139.2790561609,36.131706694,1,10,3414121,4 ,1 ,44,,6",
 "586500,2,2,1998/10/01 09:11:00,139.8121824007,35.7161261582,2,12,0336004,3 ,1 ,42,,2",
 "600300,2,3,1998/10/01 09:11:00,139.6419313653,35.7050696217,1,5,0420106,13,2 ,64,,12",
 "611800,2,3,1998/10/01 09:11:00,139.436812146,35.5242292002,1,4,0431110,13,2 ,65,,12",
 "616400,2,3,1998/10/01 09:11:00,139.6587430225,35.6058692886,2,13,0433105,15,7 ,50,,12",
 "621000,2,4,1998/10/01 09:11:00,139.7043974556,35.6808911017,2,10,0436007,14,7 ,45,,1",
 "786600,3,5,1998/10/01 09:11:00,139.2876979966,35.6610982373,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:11:00,139.475671784,35.5818079471,1,4,0811306,13,2 ,45,,1",
 "708400,2,3,1998/10/01 09:11:00,139.6602208971,35.7195280205,2,8,0731211,8 ,1 ,34,,12",
 "719900,2,4,1998/10/01 09:11:00,139.6994707662,35.6887813668,1,7,0752005,6 ,10,34,,12",
 "795800,2,2,1998/10/01 09:12:00,139.6917253188,35.5105546676,1,6,1412027,9 ,1 ,47,,1",
 "89700,7,7,1998/10/01 09:12:00,140.2286785143,35.7735608975,2,8,4422113,3 ,3 ,42,,7",
 "117300,2,2,1998/10/01 09:12:00,139.8385861541,34.9568644439,1,8,4730209,3 ,8 ,38,,6",
 "317400,2,2,1998/10/01 09:12:00,139.6845526256,35.5370283283,2,5,2022101,14,7 ,42,,6",
 "363400,4,5,1998/10/01 09:12:00,139.6954713408,35.7500971774,1,6,3032201,3 ,11,34,,6",
 "9200,2,3,1998/10/01 09:12:00,139.9362842548,35.6990086259,1,11,4114306,5 ,1 ,28,,12",
 "48300,2,3,1998/10/01 09:12:00,139.778671345,35.6848027106,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 09:12:00,139.7687528365,35.6865919313,1,7,4224202,9 ,1 ,37,,12",
 "80500,2,2,1998/10/01 09:12:00,140.1775692373,35.7206081886,1,14,4413233,5 ,1 ,33,,6",
 "172500,2,3,1998/10/01 09:12:00,139.7796614661,35.7181381938,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 09:12:00,139.9167500318,35.8095214063,2,5,5412112,9 ,1 ,35,,12",
 "211600,2,5,1998/10/01 09:12:00,139.6184545666,35.4618122474,1,7,2220004,6 ,1 ,36,,1",
 "209300,2,3,1998/10/01 09:12:00,139.7608722664,35.6665365273,1,13,2211303,10,1 ,37,,12",
 "296700,2,2,1998/10/01 09:12:00,139.4348114412,35.5296510855,2,8,2920301,4 ,1 ,33,,1",
 "324300,2,4,1998/10/01 09:12:00,139.7503664059,35.6693817245,1,10,2110103,8 ,1 ,29,,1",
 "250700,2,2,1998/10/01 09:12:00,139.3332893313,35.3303557189,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:12:00,139.4131819346,35.4740348772,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 09:12:00,139.6286203066,35.7357574712,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,3,1998/10/01 09:12:00,139.6897549753,35.8285334114,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:12:00,139.6500140268,35.76684017,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:12:00,139.4857992929,35.7386966382,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:12:00,139.6371054779,35.9209707342,1,14,3052009,15,6 ,29,,3",
 "402500,4,4,1998/10/01 09:12:00,139.4439685276,35.8031272569,1,9,3116015,9 ,11,44,,8",
 "448500,2,3,1998/10/01 09:12:00,139.8027687039,35.8385851273,2,7,3222102,4 ,1 ,27,,12",
 "473800,2,4,1998/10/01 09:12:00,139.6292927262,35.8891908909,2,10,3411010,8 ,1 ,30,,1",
 "460000,2,2,1998/10/01 09:12:00,139.7423681677,35.9838983929,2,4,3234215,4 ,1 ,40,,6",
 "480700,2,2,1998/10/01 09:12:00,139.2793559047,36.1368509359,1,10,3414121,4 ,1 ,44,,6",
 "485300,2,2,1998/10/01 09:12:00,139.5664688999,35.9519091613,1,13,3511206,15,5 ,26,,2",
 "586500,2,2,1998/10/01 09:12:00,139.8114902132,35.7161172182,2,12,0336004,3 ,1 ,42,,2",
 "600300,2,3,1998/10/01 09:12:00,139.6475896052,35.7052558072,1,5,0420106,13,2 ,64,,12",
 "616400,2,3,1998/10/01 09:12:00,139.6636880691,35.6055898779,2,13,0433105,15,7 ,50,,12",
 "611800,2,3,1998/10/01 09:12:00,139.4367159615,35.5290667781,1,4,0431110,13,2 ,65,,12",
 "621000,2,4,1998/10/01 09:12:00,139.7035289401,35.6816133885,2,10,0436007,14,7 ,45,,1",
 "786600,3,5,1998/10/01 09:12:00,139.2875554085,35.6608559659,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:12:00,139.4758996853,35.5811615715,1,4,0811306,13,2 ,45,,1",
 "708400,2,3,1998/10/01 09:12:00,139.6659942583,35.7190045876,2,8,0731211,8 ,1 ,34,,12",
 "719900,2,4,1998/10/01 09:12:00,139.6964866986,35.6871705426,1,7,0752005,6 ,10,34,,12",
 "795800,2,2,1998/10/01 09:13:00,139.6912967266,35.5119021602,1,6,1412027,9 ,1 ,47,,1",
 "89700,7,7,1998/10/01 09:13:00,140.233252334,35.7712256839,2,8,4422113,3 ,3 ,42,,7",
 "117300,2,2,1998/10/01 09:13:00,139.8386408301,34.958973135,1,8,4730209,3 ,8 ,38,,6",
 "317400,2,2,1998/10/01 09:13:00,139.686075671,35.5366103911,2,5,2022101,14,7 ,42,,6",
 "363400,4,5,1998/10/01 09:13:00,139.6973901877,35.7486890639,1,6,3032201,3 ,11,34,,6",
 "9200,2,3,1998/10/01 09:13:00,139.9332700582,35.697262196,1,11,4114306,5 ,1 ,28,,12",
 "48300,2,3,1998/10/01 09:13:00,139.776016657,35.6820084434,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 09:13:00,139.7654572772,35.6818706114,1,7,4224202,9 ,1 ,37,,12",
 "80500,2,2,1998/10/01 09:13:00,140.1740257239,35.7202469229,1,14,4413233,5 ,1 ,33,,6",
 "172500,2,3,1998/10/01 09:13:00,139.7764804352,35.7138993726,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 09:13:00,139.9147639737,35.804570183,2,5,5412112,9 ,1 ,35,,12",
 "209300,2,3,1998/10/01 09:13:00,139.7669402767,35.6694415211,1,13,2211303,10,1 ,37,,12",
 "211600,2,5,1998/10/01 09:13:00,139.6192819073,35.4610348259,1,7,2220004,6 ,1 ,36,,1",
 "296700,2,2,1998/10/01 09:13:00,139.4358666735,35.5307631158,2,8,2920301,4 ,1 ,33,,1",
 "324300,2,4,1998/10/01 09:13:00,139.7504639972,35.6691147732,1,10,2110103,8 ,1 ,29,,1",
 "250700,2,2,1998/10/01 09:13:00,139.3349850848,35.3307270545,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:13:00,139.4141684871,35.4751207611,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 09:13:00,139.6239868295,35.736834067,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,3,1998/10/01 09:13:00,139.6932397362,35.8250688224,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:13:00,139.6507809053,35.7629129485,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:13:00,139.4905244637,35.7361757674,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:13:00,139.6369307376,35.9209499281,1,14,3052009,15,6 ,29,,3",
 "402500,4,4,1998/10/01 09:13:00,139.4411254494,35.8050828538,1,9,3116015,9 ,11,44,,8",
 "448500,2,3,1998/10/01 09:13:00,139.8037872391,35.8324335279,2,7,3222102,4 ,1 ,27,,12",
 "473800,2,4,1998/10/01 09:13:00,139.6296646488,35.8884878297,2,10,3411010,8 ,1 ,30,,1",
 "460000,2,2,1998/10/01 09:13:00,139.7437606796,35.982408668,2,4,3234215,4 ,1 ,40,,6",
 "480700,2,2,1998/10/01 09:13:00,139.2774305195,36.1414509061,1,10,3414121,4 ,1 ,44,,6",
 "485300,2,2,1998/10/01 09:13:00,139.5652740726,35.9511486757,1,13,3511206,15,5 ,26,,2",
 "586500,2,2,1998/10/01 09:13:00,139.8107948938,35.7161295714,2,12,0336004,3 ,1 ,42,,2",
 "600300,2,3,1998/10/01 09:13:00,139.653249012,35.7054162797,1,5,0420106,13,2 ,64,,12",
 "611800,2,3,1998/10/01 09:13:00,139.4359572644,35.5301557422,1,4,0431110,13,2 ,65,,12",
 "616400,2,3,1998/10/01 09:13:00,139.6683896745,35.607219063,2,13,0433105,15,7 ,50,,12",
 "621000,2,4,1998/10/01 09:13:00,139.7045809212,35.6814776403,2,10,0436007,14,7 ,45,,1",
 "786600,3,5,1998/10/01 09:13:00,139.2874316091,35.6606076791,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:13:00,139.4761275828,35.5805151953,1,4,0811306,13,2 ,45,,1",
 "708400,2,3,1998/10/01 09:13:00,139.6709485571,35.7165208511,2,8,0731211,8 ,1 ,34,,12",
 "719900,2,4,1998/10/01 09:13:00,139.6935936849,35.6853461756,1,7,0752005,6 ,10,34,,12",
 "795800,2,2,1998/10/01 09:14:00,139.6908610896,35.5132482559,1,6,1412027,9 ,1 ,47,,1",
 "89700,7,7,1998/10/01 09:14:00,140.2380250134,35.7685657201,2,8,4422113,3 ,3 ,42,,7",
 "117300,2,2,1998/10/01 09:14:00,139.8377334677,34.960945875,1,8,4730209,3 ,8 ,38,,6",
 "317400,2,2,1998/10/01 09:14:00,139.6873916776,35.5357992746,2,5,2022101,14,7 ,42,,6",
 "363400,4,5,1998/10/01 09:14:00,139.699264244,35.7472412191,1,6,3032201,3 ,11,34,,6",
 "9200,2,3,1998/10/01 09:14:00,139.9302739136,35.6954949903,1,11,4114306,5 ,1 ,28,,12",
 "48300,2,3,1998/10/01 09:14:00,139.7740306446,35.6787824861,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 09:14:00,139.7627666054,35.6762569392,1,7,4224202,9 ,1 ,37,,12",
 "80500,2,2,1998/10/01 09:14:00,140.1704239247,35.7191627286,1,14,4413233,5 ,1 ,33,,6",
 "172500,2,3,1998/10/01 09:14:00,139.7751892392,35.7091511676,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 09:14:00,139.9113809573,35.8001389295,2,5,5412112,9 ,1 ,35,,12",
 "209300,2,3,1998/10/01 09:14:00,139.7723496176,35.6679056269,1,13,2211303,10,1 ,37,,12",
 "209300,2,4,1998/10/01 09:14:00,139.7723496176,35.6679056269,1,13,2211303,10,1 ,37,,1",
 "211600,2,5,1998/10/01 09:14:00,139.6201784841,35.4605105283,1,7,2220004,6 ,1 ,36,,1",
 "296700,2,2,1998/10/01 09:14:00,139.4365834371,35.5317390833,2,8,2920301,4 ,1 ,33,,1",
 "324300,2,4,1998/10/01 09:14:00,139.7505615878,35.6688478217,1,10,2110103,8 ,1 ,29,,1",
 "250700,2,2,1998/10/01 09:14:00,139.3362308295,35.3310147312,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:14:00,139.4151545178,35.4762069447,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 09:14:00,139.6198924079,35.7390174913,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,3,1998/10/01 09:14:00,139.6972760089,35.8220304406,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:14:00,139.6519684214,35.7591382436,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:14:00,139.4954621581,35.7339278962,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:14:00,139.6367559974,35.9209291218,1,14,3052009,15,6 ,29,,3",
 "402500,4,4,1998/10/01 09:14:00,139.4382734347,35.8070305305,1,9,3116015,9 ,11,44,,8",
 "448500,2,3,1998/10/01 09:14:00,139.8031306877,35.8261589786,2,7,3222102,4 ,1 ,27,,12",
 "473800,2,4,1998/10/01 09:14:00,139.6300365648,35.8877847672,2,10,3411010,8 ,1 ,30,,1",
 "460000,2,2,1998/10/01 09:14:00,139.7438656581,35.9809583732,2,4,3234215,4 ,1 ,40,,6",
 "485300,2,2,1998/10/01 09:14:00,139.5647999416,35.9499038714,1,13,3511206,15,5 ,26,,2",
 "480700,2,2,1998/10/01 09:14:00,139.2755311925,36.1463006336,1,10,3414121,4 ,1 ,44,,6",
 "586500,2,2,1998/10/01 09:14:00,139.8106426152,35.7156752388,2,12,0336004,3 ,1 ,42,,2",
 "600300,2,3,1998/10/01 09:14:00,139.6589088757,35.7055654948,1,5,0420106,13,2 ,64,,12",
 "616400,2,3,1998/10/01 09:14:00,139.6732746388,35.6080279043,2,13,0433105,15,7 ,50,,12",
 "611800,2,3,1998/10/01 09:14:00,139.4322795889,35.5260436713,1,4,0431110,13,2 ,65,,12",
 "621000,2,4,1998/10/01 09:14:00,139.7058888941,35.6812239232,2,10,0436007,14,7 ,45,,1",
 "786600,3,5,1998/10/01 09:14:00,139.2873953927,35.6603417338,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:14:00,139.4762542773,35.5798800181,1,4,0811306,13,2 ,45,,1",
 "719900,2,4,1998/10/01 09:14:00,139.6907967556,35.6834108397,1,7,0752005,6 ,10,34,,12",
 "708400,2,3,1998/10/01 09:14:00,139.6764956751,35.7157803748,2,8,0731211,8 ,1 ,34,,12",
 "795800,2,2,1998/10/01 09:15:00,139.6904326427,35.5145959322,1,6,1412027,9 ,1 ,47,,1",
 "89700,7,7,1998/10/01 09:15:00,140.240081438,35.7642815667,2,8,4422113,3 ,3 ,42,,7",
 "117300,2,2,1998/10/01 09:15:00,139.8393804048,34.9627884055,1,8,4730209,3 ,8 ,38,,6",
 "317400,2,2,1998/10/01 09:15:00,139.6884831365,35.5348140661,2,5,2022101,14,7 ,42,,6",
 "363400,4,5,1998/10/01 09:15:00,139.7011067076,35.7457674108,1,6,3032201,3 ,11,34,,6",
 "9200,2,3,1998/10/01 09:15:00,139.927391869,35.6936140313,1,11,4114306,5 ,1 ,28,,12",
 "48300,2,3,1998/10/01 09:15:00,139.7720228121,35.6755655823,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 09:15:00,139.7600283355,35.6706097556,1,7,4224202,9 ,1 ,37,,12",
 "80500,2,2,1998/10/01 09:15:00,140.1668354075,35.7180495623,1,14,4413233,5 ,1 ,33,,6",
 "172500,2,3,1998/10/01 09:15:00,139.7742995318,35.7044739524,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 09:15:00,139.9074706646,35.7959945881,2,5,5412112,9 ,1 ,35,,12",
 "211600,2,5,1998/10/01 09:15:00,139.6211727876,35.4603306747,1,7,2220004,6 ,1 ,36,,1",
 "209300,2,4,1998/10/01 09:15:00,139.7727322143,35.6673843004,1,13,2211303,10,1 ,37,,1",
 "296700,2,2,1998/10/01 09:15:00,139.4372937304,35.5328611672,2,8,2920301,4 ,1 ,33,,1",
 "324300,2,4,1998/10/01 09:15:00,139.7506591778,35.6685808702,1,10,2110103,8 ,1 ,29,,1",
 "324300,3,5,1998/10/01 09:15:00,139.7506591778,35.6685808702,1,10,2110103,8 ,97,29,,97",
 "250700,2,2,1998/10/01 09:15:00,139.3378800594,35.3315064524,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:15:00,139.4162727119,35.4772009323,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 09:15:00,139.6157762148,35.7411733571,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,3,1998/10/01 09:15:00,139.7010184321,35.8187641634,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:15:00,139.6558390548,35.756716727,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:15:00,139.5004632432,35.7317795191,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:15:00,139.6365812572,35.9209083152,1,14,3052009,15,6 ,29,,3",
 "402500,4,4,1998/10/01 09:15:00,139.4352898778,35.8088215407,1,9,3116015,9 ,11,44,,8",
 "448500,2,3,1998/10/01 09:15:00,139.8026558406,35.8198726971,2,7,3222102,4 ,1 ,27,,12",
 "473800,2,4,1998/10/01 09:15:00,139.6306390969,35.887198079,2,10,3411010,8 ,1 ,30,,1",
 "473800,3,5,1998/10/01 09:15:00,139.6306390969,35.887198079,2,10,3411010,8 ,97,30,,97",
 "460000,2,2,1998/10/01 09:15:00,139.7452017041,35.979500682,2,4,3234215,4 ,1 ,40,,6",
 "480700,2,2,1998/10/01 09:15:00,139.2718170117,36.1487867952,1,10,3414121,4 ,1 ,44,,6",
 "480700,3,3,1998/10/01 09:15:00,139.2718170117,36.1487867952,1,10,3414121,4 ,97,44,,97",
 "485300,2,2,1998/10/01 09:15:00,139.5644998103,35.9486311256,1,13,3511206,15,5 ,26,,2",
 "586500,2,2,1998/10/01 09:15:00,139.810614419,35.7151086745,2,12,0336004,3 ,1 ,42,,2",
 "600300,2,3,1998/10/01 09:15:00,139.6645662602,35.7057589521,1,5,0420106,13,2 ,64,,12",
 "616400,2,3,1998/10/01 09:15:00,139.67814654,35.6067759724,2,13,0433105,15,7 ,50,,12",
 "621000,2,4,1998/10/01 09:15:00,139.706940909,35.6815835381,2,10,0436007,14,7 ,45,,1",
 "621000,3,5,1998/10/01 09:15:00,139.706940909,35.6815835381,2,10,0436007,14,97,45,,97",
 "611800,2,3,1998/10/01 09:15:00,139.4286047449,35.5219299836,1,4,0431110,13,2 ,65,,12",
 "786600,3,5,1998/10/01 09:15:00,139.2874338313,35.6600757606,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:15:00,139.4759234901,35.5792664079,1,4,0811306,13,2 ,45,,1",
 "719900,2,4,1998/10/01 09:15:00,139.6883564081,35.6813187613,1,7,0752005,6 ,10,34,,12",
 "719900,2,5,1998/10/01 09:15:00,139.6883564081,35.6813187613,1,7,0752005,6 ,10,34,,1",
 "708400,2,3,1998/10/01 09:15:00,139.6821917878,35.7148040333,2,8,0731211,8 ,1 ,34,,12",
 "795800,2,2,1998/10/01 09:16:00,139.6900212879,35.5159471218,1,6,1412027,9 ,1 ,47,,1",
 "89700,7,7,1998/10/01 09:16:00,140.2421815428,35.7599220251,2,8,4422113,3 ,3 ,42,,7",
 "117300,2,2,1998/10/01 09:16:00,139.8419478503,34.9628176115,1,8,4730209,3 ,8 ,38,,6",
 "317400,2,2,1998/10/01 09:16:00,139.6892873521,35.533637157,2,5,2022101,14,7 ,42,,6",
 "9200,2,3,1998/10/01 09:16:00,139.9247899528,35.6914717063,1,11,4114306,5 ,1 ,28,,12",
 "363400,4,5,1998/10/01 09:16:00,139.7029668437,35.744307973,1,6,3032201,3 ,11,34,,6",
 "48300,2,3,1998/10/01 09:16:00,139.7697513554,35.6724760936,2,4,4215402,4 ,1 ,44,,12",
 "62100,2,3,1998/10/01 09:16:00,139.7583713969,35.6647904663,1,7,4224202,9 ,1 ,37,,12",
 "80500,2,2,1998/10/01 09:16:00,140.1632234313,35.71698845,1,14,4413233,5 ,1 ,33,,6",
 "172500,2,3,1998/10/01 09:16:00,139.7732363559,35.6995870584,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 09:16:00,139.9040128573,35.7916069404,2,5,5412112,9 ,1 ,35,,12",
 "209300,2,4,1998/10/01 09:16:00,139.773114806,35.6668629726,1,13,2211303,10,1 ,37,,1",
 "211600,2,5,1998/10/01 09:16:00,139.622239906,35.4597874844,1,7,2220004,6 ,1 ,36,,1",
 "296700,2,2,1998/10/01 09:16:00,139.4375216759,35.5319125327,2,8,2920301,4 ,1 ,33,,1",
 "296700,2,3,1998/10/01 09:16:00,139.4375216759,35.5319125327,2,8,2920301,4 ,1 ,33,,12",
 "119600,4,4,1998/10/01 09:16:00,140.8569943881,35.7335679651,2,13,4810072,14,3 ,45,,1",
 "250700,2,2,1998/10/01 09:16:00,139.3395641032,35.3319105862,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:16:00,139.4176103943,35.4779925902,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 09:16:00,139.6112323618,35.7425931876,2,5,3023001,9 ,1 ,35,,12",
 "365700,2,3,1998/10/01 09:16:00,139.7045237542,35.8153132514,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:16:00,139.6595489215,35.7541274021,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:16:00,139.5053821749,35.7296049156,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:16:00,139.6364065172,35.9208875084,1,14,3052009,15,6 ,29,,3",
 "402500,4,4,1998/10/01 09:16:00,139.4320295603,35.8102833048,1,9,3116015,9 ,11,44,,8",
 "448500,2,3,1998/10/01 09:16:00,139.8008791726,35.8138102142,2,7,3222102,4 ,1 ,27,,12",
 "460000,2,2,1998/10/01 09:16:00,139.746741679,35.9781026208,2,4,3234215,4 ,1 ,40,,6",
 "485300,2,2,1998/10/01 09:16:00,139.5638861163,35.9474262573,1,13,3511206,15,5 ,26,,2",
 "586500,2,2,1998/10/01 09:16:00,139.8106097513,35.7145416081,2,12,0336004,3 ,1 ,42,,2",
 "600300,2,3,1998/10/01 09:16:00,139.6702260402,35.7059040272,1,5,0420106,13,2 ,64,,12",
 "611800,2,3,1998/10/01 09:16:00,139.4249612705,35.5177977572,1,4,0431110,13,2 ,65,,12",
 "616400,2,3,1998/10/01 09:16:00,139.6830186175,35.6065956369,2,13,0433105,15,7 ,50,,12",
 "786600,3,5,1998/10/01 09:16:00,139.2875735761,35.6598329577,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:16:00,139.4755159432,35.5786839296,1,4,0811306,13,2 ,45,,1",
 "708400,2,3,1998/10/01 09:16:00,139.6880065128,35.7152536169,2,8,0731211,8 ,1 ,34,,12",
 "719900,2,5,1998/10/01 09:16:00,139.6886138445,35.6801448662,1,7,0752005,6 ,10,34,,1",
 "795800,2,2,1998/10/01 09:17:00,139.6894428151,35.5172241529,1,6,1412027,9 ,1 ,47,,1",
 "89700,7,7,1998/10/01 09:17:00,140.246266466,35.7566376993,2,8,4422113,3 ,3 ,42,,7",
 "117300,2,2,1998/10/01 09:17:00,139.844227218,34.9639703997,1,8,4730209,3 ,8 ,38,,6",
 "317400,2,2,1998/10/01 09:17:00,139.6899996199,35.532420893,2,5,2022101,14,7 ,42,,6",
 "363400,4,5,1998/10/01 09:17:00,139.7050418259,35.743312259,1,6,3032201,3 ,11,34,,6",
 "9200,2,3,1998/10/01 09:17:00,139.9222053711,35.6893151571,1,11,4114306,5 ,1 ,28,,12",
 "48300,2,3,1998/10/01 09:17:00,139.7670444278,35.6696275204,2,4,4215402,4 ,1 ,44,,12",
 "48300,2,4,1998/10/01 09:17:00,139.7670444278,35.6696275204,2,4,4215402,4 ,1 ,44,,1",
 "62100,2,3,1998/10/01 09:17:00,139.7579449325,35.6587438278,1,7,4224202,9 ,1 ,37,,12",
 "80500,2,2,1998/10/01 09:17:00,140.1597784115,35.7160630138,1,14,4413233,5 ,1 ,33,,6",
 "172500,2,3,1998/10/01 09:17:00,139.7721882148,35.6947019249,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 09:17:00,139.9015722675,35.7867810381,2,5,5412112,9 ,1 ,35,,12",
 "209300,2,4,1998/10/01 09:17:00,139.7735598947,35.6663793851,1,13,2211303,10,1 ,37,,1",
 "211600,2,5,1998/10/01 09:17:00,139.6234083741,35.4596615892,1,7,2220004,6 ,1 ,36,,1",
 "296700,2,3,1998/10/01 09:17:00,139.440896217,35.5356635958,2,8,2920301,4 ,1 ,33,,12",
 "119600,4,4,1998/10/01 09:17:00,140.8569864508,35.7327211914,2,13,4810072,14,3 ,45,,1",
 "250700,2,2,1998/10/01 09:17:00,139.3412904031,35.3321684931,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:17:00,139.4191014275,35.4785717099,1,7,2811315,9 ,11,37,,8",
 "358800,2,3,1998/10/01 09:17:00,139.6069163073,35.7438649975,2,5,3023001,9 ,1 ,35,,12",
 "358800,2,4,1998/10/01 09:17:00,139.6069163073,35.7438649975,2,5,3023001,9 ,1 ,35,,1",
 "365700,2,3,1998/10/01 09:17:00,139.7079476647,35.8118084631,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:17:00,139.6630865026,35.7513799428,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:17:00,139.5103702339,35.7274334168,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:17:00,139.6362317773,35.9208667013,1,14,3052009,15,6 ,29,,3",
 "402500,4,4,1998/10/01 09:17:00,139.4288019156,35.8117930552,1,9,3116015,9 ,11,44,,8",
 "448500,2,3,1998/10/01 09:17:00,139.7966512156,35.8085409051,2,7,3222102,4 ,1 ,27,,12",
 "460000,2,2,1998/10/01 09:17:00,139.7478925676,35.9768524096,2,4,3234215,4 ,1 ,40,,6",
 "460000,3,3,1998/10/01 09:17:00,139.7478925676,35.9768524096,2,4,3234215,4 ,97,40,,97",
 "485300,2,2,1998/10/01 09:17:00,139.5636393014,35.9461485096,1,13,3511206,15,5 ,26,,2",
 "586500,2,2,1998/10/01 09:17:00,139.8105797833,35.7140283067,2,12,0336004,3 ,1 ,42,,2",
 "600300,2,3,1998/10/01 09:17:00,139.6758866009,35.7060354722,1,5,0420106,13,2 ,64,,12",
 "611800,2,3,1998/10/01 09:17:00,139.4213123264,35.513669088,1,4,0431110,13,2 ,65,,12",
 "616400,2,3,1998/10/01 09:17:00,139.6879140718,35.6077579641,2,13,0433105,15,7 ,50,,12",
 "786600,3,5,1998/10/01 09:17:00,139.2878002999,35.6596390461,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:17:00,139.4752368955,35.5780517892,1,4,0811306,13,2 ,45,,1",
 "708400,2,3,1998/10/01 09:17:00,139.6937649322,35.7157990326,2,8,0731211,8 ,1 ,34,,12",
 "719900,2,5,1998/10/01 09:17:00,139.6895002284,35.6797890414,1,7,0752005,6 ,10,34,,1",
 "795800,2,2,1998/10/01 09:18:00,139.687943208,35.5178746486,1,6,1412027,9 ,1 ,47,,1",
 "89700,7,7,1998/10/01 09:18:00,140.249412367,35.7534633287,2,8,4422113,3 ,3 ,42,,7",
 "117300,2,2,1998/10/01 09:18:00,139.8448473897,34.9661901924,1,8,4730209,3 ,8 ,38,,6",
 "317400,2,2,1998/10/01 09:18:00,139.6907115086,35.531204631,2,5,2022101,14,7 ,42,,6",
 "363400,4,5,1998/10/01 09:18:00,139.7076201736,35.743256934,1,6,3032201,3 ,11,34,,6",
 "9200,2,3,1998/10/01 09:18:00,139.9196208856,35.6871585856,1,11,4114306,5 ,1 ,28,,12",
 "48300,2,4,1998/10/01 09:18:00,139.7680168403,35.6706817712,2,4,4215402,4 ,1 ,44,,1",
 "62100,2,3,1998/10/01 09:18:00,139.7564262316,35.6527685008,1,7,4224202,9 ,1 ,37,,12",
 "80500,2,2,1998/10/01 09:18:00,140.1571297646,35.7157699317,1,14,4413233,5 ,1 ,33,,6",
 "172500,2,3,1998/10/01 09:18:00,139.7716171746,35.6955478485,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 09:18:00,139.8998432188,35.7819631344,2,5,5412112,9 ,1 ,35,,12",
 "209300,2,4,1998/10/01 09:18:00,139.7741783849,35.6660526496,1,13,2211303,10,1 ,37,,1",
 "211600,2,5,1998/10/01 09:18:00,139.6240630561,35.460486346,1,7,2220004,6 ,1 ,36,,1",
 "296700,2,3,1998/10/01 09:18:00,139.4428086021,35.5399865291,2,8,2920301,4 ,1 ,33,,12",
 "119600,4,4,1998/10/01 09:18:00,140.8569272573,35.7318760803,2,13,4810072,14,3 ,45,,1",
 "250700,2,2,1998/10/01 09:18:00,139.3430270902,35.3323770019,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:18:00,139.4207086059,35.4788818032,1,7,2811315,9 ,11,37,,8",
 "358800,2,4,1998/10/01 09:18:00,139.607709848,35.7432200822,2,5,3023001,9 ,1 ,35,,1",
 "365700,2,3,1998/10/01 09:18:00,139.7114150079,35.8083322407,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:18:00,139.6669343316,35.7489700281,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:18:00,139.5154381557,35.725405108,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:18:00,139.6360570375,35.920845894,1,14,3052009,15,6 ,29,,3",
 "402500,4,4,1998/10/01 09:18:00,139.4255577511,35.8132771275,1,9,3116015,9 ,11,44,,8",
 "448500,2,3,1998/10/01 09:18:00,139.7938304729,35.8027189331,2,7,3222102,4 ,1 ,27,,12",
 "485300,2,2,1998/10/01 09:18:00,139.5635679632,35.944846808,1,13,3511206,15,5 ,26,,2",
 "586500,2,2,1998/10/01 09:18:00,139.8100456317,35.7138725297,2,12,0336004,3 ,1 ,42,,2",
 "600300,2,3,1998/10/01 09:18:00,139.6815485321,35.70612152,1,5,0420106,13,2 ,64,,12",
 "611800,2,3,1998/10/01 09:18:00,139.4176233282,35.5095641543,1,4,0431110,13,2 ,65,,12",
 "616400,2,3,1998/10/01 09:18:00,139.692727177,35.6064767281,2,13,0433105,15,7 ,50,,12",
 "786600,3,5,1998/10/01 09:18:00,139.2880570391,35.659471219,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:18:00,139.4748664234,35.5774534781,1,4,0811306,13,2 ,45,,1",
 "708400,2,3,1998/10/01 09:18:00,139.6995164703,35.7162359132,2,8,0731211,8 ,1 ,34,,12",
 "719900,2,5,1998/10/01 09:18:00,139.6908588947,35.6802245022,1,7,0752005,6 ,10,34,,1",
 "795800,2,2,1998/10/01 09:19:00,139.6866137925,35.5175353504,1,6,1412027,9 ,1 ,47,,1",
 "795800,2,3,1998/10/01 09:19:00,139.6866137925,35.5175353504,1,6,1412027,9 ,1 ,47,,12",
 "89700,7,7,1998/10/01 09:19:00,140.2505159784,35.7495639317,2,8,4422113,3 ,3 ,42,,7",
 "117300,2,2,1998/10/01 09:19:00,139.8460753287,34.9680738101,1,8,4730209,3 ,8 ,38,,6",
 "317400,2,2,1998/10/01 09:19:00,139.6914725121,35.5300082665,2,5,2022101,14,7 ,42,,6",
 "363400,4,5,1998/10/01 09:19:00,139.7101982779,35.7432774018,1,6,3032201,3 ,11,34,,6",
 "9200,2,3,1998/10/01 09:19:00,139.9170365392,35.6850019577,1,11,4114306,5 ,1 ,28,,12",
 "48300,2,4,1998/10/01 09:19:00,139.7689892783,35.6717360139,2,4,4215402,4 ,1 ,44,,1",
 "62100,2,3,1998/10/01 09:19:00,139.7521857053,35.6479612923,1,7,4224202,9 ,1 ,37,,12",
 "80500,2,2,1998/10/01 09:19:00,140.153841479,35.7159574823,1,14,4413233,5 ,1 ,33,,6",
 "172500,2,3,1998/10/01 09:19:00,139.7673104705,35.6989207434,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 09:19:00,139.898030456,35.7769484115,2,5,5412112,9 ,1 ,35,,12",
 "209300,2,4,1998/10/01 09:19:00,139.7748357627,35.6657672063,1,13,2211303,10,1 ,37,,1",
 "211600,2,5,1998/10/01 09:19:00,139.6240623674,35.4615168606,1,7,2220004,6 ,1 ,36,,1",
 "296700,2,3,1998/10/01 09:19:00,139.4452869626,35.5441243049,2,8,2920301,4 ,1 ,33,,12",
 "119600,4,4,1998/10/01 09:19:00,140.8572809024,35.7312969882,2,13,4810072,14,3 ,45,,1",
 "250700,2,2,1998/10/01 09:19:00,139.344761109,35.3326000949,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:19:00,139.4223594841,35.478935352,1,7,2811315,9 ,11,37,,8",
 "358800,2,4,1998/10/01 09:19:00,139.6072751731,35.7422245722,2,5,3023001,9 ,1 ,35,,1",
 "365700,2,3,1998/10/01 09:19:00,139.7148939372,35.804863733,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:19:00,139.6712374865,35.7470800568,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:19:00,139.5210233154,35.7252768913,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:19:00,139.6358822977,35.9208250864,1,14,3052009,15,6 ,29,,3",
 "402500,4,4,1998/10/01 09:19:00,139.4225480565,35.8150490803,1,9,3116015,9 ,11,44,,8",
 "448500,2,3,1998/10/01 09:19:00,139.7915845535,35.7966924,2,7,3222102,4 ,1 ,27,,12",
 "485300,2,2,1998/10/01 09:19:00,139.5633864879,35.9435511729,1,13,3511206,15,5 ,26,,2",
 "586500,2,2,1998/10/01 09:19:00,139.8095027397,35.7135180719,2,12,0336004,3 ,1 ,42,,2",
 "600300,2,3,1998/10/01 09:19:00,139.6871989473,35.7062717361,1,5,0420106,13,2 ,64,,12",
 "611800,2,3,1998/10/01 09:19:00,139.4139347046,35.5054591076,1,4,0431110,13,2 ,65,,12",
 "616400,2,3,1998/10/01 09:19:00,139.6975704464,35.6052680361,2,13,0433105,15,7 ,50,,12",
 "786600,3,5,1998/10/01 09:19:00,139.288343258,35.6593392467,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:19:00,139.4744613826,35.5768678437,1,4,0811306,13,2 ,45,,1",
 "719900,2,5,1998/10/01 09:19:00,139.6920568049,35.680905819,1,7,0752005,6 ,10,34,,1",
 "708400,2,3,1998/10/01 09:19:00,139.7048292579,35.7150707814,2,8,0731211,8 ,1 ,34,,12",
 "89700,7,7,1998/10/01 09:20:00,140.2554569324,35.7471696756,2,8,4422113,3 ,3 ,42,,7",
 "89700,8,8,1998/10/01 09:20:00,140.2554569324,35.7471696756,2,8,4422113,3 ,97,42,,97",
 "117300,2,2,1998/10/01 09:20:00,139.8464217841,34.9703250583,1,8,4730209,3 ,8 ,38,,6",
 "317400,2,2,1998/10/01 09:20:00,139.6923391422,35.5288630959,2,5,2022101,14,7 ,42,,6",
 "363400,4,5,1998/10/01 09:20:00,139.7121776286,35.7425920389,1,6,3032201,3 ,11,34,,6",
 "9200,2,3,1998/10/01 09:20:00,139.9147259182,35.6828254554,1,11,4114306,5 ,1 ,28,,12",
 "9200,2,4,1998/10/01 09:20:00,139.9147259182,35.6828254554,1,11,4114306,5 ,1 ,28,,1",
 "48300,2,4,1998/10/01 09:20:00,139.7684230535,35.6711187769,2,4,4215402,4 ,1 ,44,,1",
 "62100,2,3,1998/10/01 09:20:00,139.747019056,35.6440536574,1,7,4224202,9 ,1 ,37,,12",
 "80500,2,2,1998/10/01 09:20:00,140.150418371,35.717309188,1,14,4413233,5 ,1 ,33,,6",
 "172500,2,3,1998/10/01 09:20:00,139.7618390523,35.7010439709,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 09:20:00,139.8953476659,35.7722511212,2,5,5412112,9 ,1 ,35,,12",
 "209300,2,4,1998/10/01 09:20:00,139.7754931358,35.6654817593,1,13,2211303,10,1 ,37,,1",
 "209300,3,5,1998/10/01 09:20:00,139.7754931358,35.6654817593,1,13,2211303,10,97,37,,97",
 "211600,2,5,1998/10/01 09:20:00,139.6240824415,35.4625471906,1,7,2220004,6 ,1 ,36,,1",
 "211600,3,6,1998/10/01 09:20:00,139.6240824415,35.4625471906,1,7,2220004,6 ,97,36,,97",
 "296700,2,3,1998/10/01 09:20:00,139.448884704,35.5477047119,2,8,2920301,4 ,1 ,33,,12",
 "119600,4,4,1998/10/01 09:20:00,140.8582700665,35.7311255966,2,13,4810072,14,3 ,45,,1",
 "250700,2,2,1998/10/01 09:20:00,139.3465022891,35.3327747734,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:20:00,139.4240128808,35.4789009902,1,7,2811315,9 ,11,37,,8",
 "358800,2,4,1998/10/01 09:20:00,139.6069234765,35.7411516635,2,5,3023001,9 ,1 ,35,,1",
 "365700,2,3,1998/10/01 09:20:00,139.7178079177,35.801101651,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:20:00,139.6755363846,35.7451837276,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:20:00,139.5266287521,35.7258835702,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:20:00,139.6357075581,35.9208042786,1,14,3052009,15,6 ,29,,3",
 "402500,4,4,1998/10/01 09:20:00,139.4197887272,35.8170829026,1,9,3116015,9 ,11,44,,8",
 "448500,2,3,1998/10/01 09:20:00,139.7893526414,35.7906624154,2,7,3222102,4 ,1 ,27,,12",
 "485300,2,2,1998/10/01 09:20:00,139.5630817506,35.9422788628,1,13,3511206,15,5 ,26,,2",
 "586500,2,2,1998/10/01 09:20:00,139.8095317329,35.7133154841,2,12,0336004,3 ,1 ,42,,2",
 "586500,3,3,1998/10/01 09:20:00,139.8095317329,35.7133154841,2,12,0336004,3 ,97,42,,97",
 "600300,2,3,1998/10/01 09:20:00,139.6926199347,35.7050200571,1,5,0420106,13,2 ,64,,12",
 "616400,2,3,1998/10/01 09:20:00,139.7026077647,35.6049111322,2,13,0433105,15,7 ,50,,12",
 "616400,2,4,1998/10/01 09:20:00,139.7026077647,35.6049111322,2,13,0433105,15,7 ,50,,1",
 "611800,2,3,1998/10/01 09:20:00,139.4103168959,35.5013127905,1,4,0431110,13,2 ,65,,12",
 "795800,2,3,1998/10/01 09:20:00,139.6900575758,35.5215921718,1,6,1412027,9 ,1 ,47,,12",
 "786600,3,5,1998/10/01 09:20:00,139.2886634191,35.6592813976,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:20:00,139.4741212275,35.5762663076,1,4,0811306,13,2 ,45,,1",
 "708400,2,3,1998/10/01 09:20:00,139.7065220867,35.7125249826,2,8,0731211,8 ,1 ,34,,12",
 "719900,3,6,1998/10/01 09:20:00,139.6931814426,35.6816656626,1,7,0752005,6 ,97,34,,97",
 "719900,2,5,1998/10/01 09:20:00,139.6931814426,35.6816656626,1,7,0752005,6 ,10,34,,1",
 "795800,2,3,1998/10/01 09:21:00,139.6938683686,35.5255920966,1,6,1412027,9 ,1 ,47,,12",
 "117300,2,2,1998/10/01 09:21:00,139.8455062798,34.972433619,1,8,4730209,3 ,8 ,38,,6",
 "317400,2,2,1998/10/01 09:21:00,139.6934919361,35.5279016587,2,5,2022101,14,7 ,42,,6",
 "363400,4,5,1998/10/01 09:21:00,139.7145427399,35.7424891606,1,6,3032201,3 ,11,34,,6",
 "9200,2,4,1998/10/01 09:21:00,139.9153198705,35.6823445795,1,11,4114306,5 ,1 ,28,,1",
 "48300,2,4,1998/10/01 09:21:00,139.7674509506,35.670064334,2,4,4215402,4 ,1 ,44,,1",
 "62100,2,3,1998/10/01 09:21:00,139.7426467323,35.6393194536,1,7,4224202,9 ,1 ,37,,12",
 "80500,2,2,1998/10/01 09:21:00,140.1471050377,35.7187988655,1,14,4413233,5 ,1 ,33,,6",
 "172500,2,3,1998/10/01 09:21:00,139.7558768697,35.7017840958,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 09:21:00,139.8898789117,35.7696856567,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,2,1998/10/01 09:21:00,139.7732582723,35.6191007527,1,8,2410601,7 ,3 ,15,,1",
 "296700,2,3,1998/10/01 09:21:00,139.4536958986,35.5500669721,2,8,2920301,4 ,1 ,33,,12",
 "119600,4,4,1998/10/01 09:21:00,140.8585283589,35.7303056636,2,13,4810072,14,3 ,45,,1",
 "149500,2,2,1998/10/01 09:21:00,140.1141963865,35.6993209845,2,10,4022410,4 ,1 ,29,,2",
 "250700,2,2,1998/10/01 09:21:00,139.3482538622,35.3328684536,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:21:00,139.4256655504,35.4788496145,1,7,2811315,9 ,11,37,,8",
 "358800,2,4,1998/10/01 09:21:00,139.6065339542,35.7400803601,2,5,3023001,9 ,1 ,35,,1",
 "365700,2,3,1998/10/01 09:21:00,139.7180421493,35.7966745302,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:21:00,139.6797299645,35.7432898856,1,7,3110313,8 ,1 ,33,,12",
 "377200,2,2,1998/10/01 09:21:00,139.6355328185,35.9207834705,1,14,3052009,15,6 ,29,,3",
 "418600,2,4,1998/10/01 09:21:00,139.5322342757,35.7264899884,1,11,3123503,8 ,1 ,23,,12",
 "402500,4,4,1998/10/01 09:21:00,139.4172006846,35.8192595617,1,9,3116015,9 ,11,44,,8",
 "448500,2,3,1998/10/01 09:21:00,139.7871299106,35.7846302172,2,7,3222102,4 ,1 ,27,,12",
 "466900,2,2,1998/10/01 09:21:00,139.3411301616,35.8520903209,2,5,3310206,4 ,1 ,42,,1",
 "485300,2,2,1998/10/01 09:21:00,139.5623736143,35.9411103733,1,13,3511206,15,5 ,26,,2",
 "581900,2,2,1998/10/01 09:21:00,139.7834746076,35.7140181438,1,9,0321101,5 ,7 ,97,,2",
 "600300,2,3,1998/10/01 09:21:00,139.6966607477,35.7018459675,1,5,0420106,13,2 ,64,,12",
 "611800,2,3,1998/10/01 09:21:00,139.40666861,35.497184493,1,4,0431110,13,2 ,65,,12",
 "616400,2,4,1998/10/01 09:21:00,139.7034768829,35.6049301984,2,13,0433105,15,7 ,50,,1",
 "701500,6,6,1998/10/01 09:21:00,139.5616578477,35.7125265305,2,10,0721104,9 ,3 ,48,,2",
 "742900,6,6,1998/10/01 09:21:00,139.3454447419,35.7127466666,1,8,0784113,10,11,38,,7",
 "786600,3,5,1998/10/01 09:21:00,139.2889809287,35.6593397593,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:21:00,139.4743661632,35.5756403874,1,4,0811306,13,2 ,45,,1",
 "708400,2,3,1998/10/01 09:21:00,139.7119106891,35.7108549436,2,8,0731211,8 ,1 ,34,,12",
 "795800,2,3,1998/10/01 09:22:00,139.6976793545,35.5295920168,1,6,1412027,9 ,1 ,47,,12",
 "117300,2,2,1998/10/01 09:22:00,139.8443247229,34.9744688534,1,8,4730209,3 ,8 ,38,,6",
 "317400,2,2,1998/10/01 09:22:00,139.6946669489,35.5269586457,2,5,2022101,14,7 ,42,,6",
 "363400,4,5,1998/10/01 09:22:00,139.7161102335,35.741495727,1,6,3032201,3 ,11,34,,6",
 "9200,2,4,1998/10/01 09:22:00,139.9154912178,35.6818656736,1,11,4114306,5 ,1 ,28,,1",
 "48300,2,4,1998/10/01 09:22:00,139.7677326329,35.6705599686,2,4,4215402,4 ,1 ,44,,1",
 "62100,2,3,1998/10/01 09:22:00,139.741138215,35.6333836067,1,7,4224202,9 ,1 ,37,,12",
 "80500,2,2,1998/10/01 09:22:00,140.1434522704,35.7180255689,1,14,4413233,5 ,1 ,33,,6",
 "172500,2,3,1998/10/01 09:22:00,139.7498417418,35.7023615183,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 09:22:00,139.8835846057,35.7688421826,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,2,1998/10/01 09:22:00,139.7732577652,35.6191008924,1,8,2410601,7 ,3 ,15,,1",
 "296700,2,3,1998/10/01 09:22:00,139.458950131,35.5515505533,2,8,2920301,4 ,1 ,33,,12",
 "119600,4,4,1998/10/01 09:22:00,140.8586471878,35.7294771975,2,13,4810072,14,3 ,45,,1",
 "149500,2,2,1998/10/01 09:22:00,140.1154476353,35.7004733232,2,10,4022410,4 ,1 ,29,,2",
 "250700,2,2,1998/10/01 09:22:00,139.3499754244,35.3329656409,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:22:00,139.4273188017,35.4788086357,1,7,2811315,9 ,11,37,,8",
 "358800,2,4,1998/10/01 09:22:00,139.6061444422,35.7390090553,2,5,3023001,9 ,1 ,35,,1",
 "365700,2,3,1998/10/01 09:22:00,139.7175788374,35.7922140361,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:22:00,139.6839751354,35.7413152261,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:22:00,139.5378398862,35.7270961458,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:22:00,139.6353910621,35.9207991041,1,14,3052009,15,6 ,29,,3",
 "402500,4,4,1998/10/01 09:22:00,139.414575661,35.8214067063,1,9,3116015,9 ,11,44,,8",
 "448500,2,3,1998/10/01 09:22:00,139.7887292936,35.7789172662,2,7,3222102,4 ,1 ,27,,12",
 "466900,2,2,1998/10/01 09:22:00,139.340211697,35.852145807,2,5,3310206,4 ,1 ,42,,1",
 "485300,2,2,1998/10/01 09:22:00,139.5608579208,35.9409713641,1,13,3511206,15,5 ,26,,2",
 "581900,2,2,1998/10/01 09:22:00,139.7812907447,35.7136290032,1,9,0321101,5 ,7 ,97,,2",
 "600300,2,3,1998/10/01 09:22:00,139.699021753,35.6976690408,1,5,0420106,13,2 ,64,,12",
 "611800,2,3,1998/10/01 09:22:00,139.4031569992,35.4929775474,1,4,0431110,13,2 ,65,,12",
 "616400,2,4,1998/10/01 09:22:00,139.7043299855,35.6050490823,2,13,0433105,15,7 ,50,,1",
 "701500,6,6,1998/10/01 09:22:00,139.5631674411,35.7129178776,2,10,0721104,9 ,3 ,48,,2",
 "742900,6,6,1998/10/01 09:22:00,139.3451253979,35.7142252462,1,8,0784113,10,11,38,,7",
 "786600,3,5,1998/10/01 09:22:00,139.2892633287,35.659476524,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:22:00,139.4746389237,35.5750436163,1,4,0811306,13,2 ,45,,1",
 "708400,2,3,1998/10/01 09:22:00,139.7169939911,35.7088576035,2,8,0731211,8 ,1 ,34,,12",
 "795800,2,3,1998/10/01 09:23:00,139.7000754734,35.5325441854,1,6,1412027,9 ,1 ,47,,12",
 "117300,2,2,1998/10/01 09:23:00,139.843894488,34.9764346328,1,8,4730209,3 ,8 ,38,,6",
 "317400,2,2,1998/10/01 09:23:00,139.6957799198,35.5263409501,2,5,2022101,14,7 ,42,,6",
 "363400,4,5,1998/10/01 09:23:00,139.7185222343,35.7408633854,1,6,3032201,3 ,11,34,,6",
 "9200,2,4,1998/10/01 09:23:00,139.9148905735,35.6813903688,1,11,4114306,5 ,1 ,28,,1",
 "48300,2,4,1998/10/01 09:23:00,139.767438067,35.6694748224,2,4,4215402,4 ,1 ,44,,1",
 "62100,2,3,1998/10/01 09:23:00,139.7391699252,35.6275176745,1,7,4224202,9 ,1 ,37,,12",
 "80500,2,2,1998/10/01 09:23:00,140.1410287703,35.718727861,1,14,4413233,5 ,1 ,33,,6",
 "172500,2,3,1998/10/01 09:23:00,139.7443559366,35.7010476798,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 09:23:00,139.8772147568,35.769457953,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,2,1998/10/01 09:23:00,139.7732572581,35.619101032,1,8,2410601,7 ,3 ,15,,1",
 "296700,2,3,1998/10/01 09:23:00,139.4614055215,35.5556462359,2,8,2920301,4 ,1 ,33,,12",
 "119600,4,4,1998/10/01 09:23:00,140.8588215102,35.7286575678,2,13,4810072,14,3 ,45,,1",
 "149500,2,2,1998/10/01 09:23:00,140.1165362395,35.7017624538,2,10,4022410,4 ,1 ,29,,2",
 "250700,2,2,1998/10/01 09:23:00,139.3502025297,35.3343835096,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:23:00,139.4289722078,35.4787745286,1,7,2811315,9 ,11,37,,8",
 "358800,2,4,1998/10/01 09:23:00,139.6056625019,35.7379690317,2,5,3023001,9 ,1 ,35,,1",
 "365700,2,3,1998/10/01 09:23:00,139.7171006211,35.7877520257,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:23:00,139.6880270112,35.7391081141,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:23:00,139.5434379693,35.7277462193,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:23:00,139.6353635877,35.9209410048,1,14,3052009,15,6 ,29,,3",
 "402500,4,4,1998/10/01 09:23:00,139.4119527252,35.8235387071,1,9,3116015,9 ,11,44,,8",
 "448500,2,3,1998/10/01 09:23:00,139.7941982939,35.774531091,2,7,3222102,4 ,1 ,27,,12",
 "466900,2,2,1998/10/01 09:23:00,139.3392932312,35.8522012861,2,5,3310206,4 ,1 ,42,,1",
 "485300,2,2,1998/10/01 09:23:00,139.5593588682,35.9405234853,1,13,3511206,15,5 ,26,,2",
 "600300,2,3,1998/10/01 09:23:00,139.7000875329,35.6931568472,1,5,0420106,13,2 ,64,,12",
 "581900,2,2,1998/10/01 09:23:00,139.7795520572,35.7129296286,1,9,0321101,5 ,7 ,97,,2",
 "616400,2,4,1998/10/01 09:23:00,139.7051757798,35.6052135306,2,13,0433105,15,7 ,50,,1",
 "611800,2,3,1998/10/01 09:23:00,139.4000332211,35.4886033629,1,4,0431110,13,2 ,65,,12",
 "701500,6,6,1998/10/01 09:23:00,139.5644294539,35.7137151583,2,10,0721104,9 ,3 ,48,,2",
 "742900,6,6,1998/10/01 09:23:00,139.3466064346,35.7165185734,1,8,0784113,10,11,38,,7",
 "742900,7,7,1998/10/01 09:23:00,139.3466064346,35.7165185734,1,8,0784113,10,97,38,,97",
 "786600,3,5,1998/10/01 09:23:00,139.2895397114,35.659622693,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:23:00,139.4748527116,35.5744024246,1,4,0811306,13,2 ,45,,1",
 "708400,2,3,1998/10/01 09:23:00,139.7210904554,35.705775052,2,8,0731211,8 ,1 ,34,,12",
 "795800,2,3,1998/10/01 09:24:00,139.6988090227,35.53295853,1,6,1412027,9 ,1 ,47,,12",
 "117300,2,2,1998/10/01 09:24:00,139.8456060685,34.97797056,1,8,4730209,3 ,8 ,38,,6",
 "317400,2,2,1998/10/01 09:24:00,139.6971731626,35.5270625392,2,5,2022101,14,7 ,42,,6",
 "363400,4,5,1998/10/01 09:24:00,139.7202207733,35.7415907017,1,6,3032201,3 ,11,34,,6",
 "363400,5,6,1998/10/01 09:24:00,139.7202207733,35.7415907017,1,6,3032201,3 ,97,34,,97",
 "9200,2,4,1998/10/01 09:24:00,139.9142899363,35.6809150611,1,11,4114306,5 ,1 ,28,,1",
 "48300,2,4,1998/10/01 09:24:00,139.7685291131,35.6685015745,2,4,4215402,4 ,1 ,44,,1",
 "62100,2,3,1998/10/01 09:24:00,139.7378297163,35.6215133415,1,7,4224202,9 ,1 ,37,,12",
 "80500,2,2,1998/10/01 09:24:00,140.139965119,35.7209012556,1,14,4413233,5 ,1 ,33,,6",
 "172500,2,3,1998/10/01 09:24:00,139.741010313,35.6968682523,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 09:24:00,139.8708279893,35.7695776591,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,2,1998/10/01 09:24:00,139.7732567511,35.6191011717,1,8,2410601,7 ,3 ,15,,1",
 "296700,2,3,1998/10/01 09:24:00,139.461890201,35.5602756409,2,8,2920301,4 ,1 ,33,,12",
 "119600,4,4,1998/10/01 09:24:00,140.8591937908,35.7280715204,2,13,4810072,14,3 ,45,,1",
 "149500,2,2,1998/10/01 09:24:00,140.1175297128,35.7031009,2,10,4022410,4 ,1 ,29,,2",
 "250700,2,2,1998/10/01 09:24:00,139.3513267341,35.3350247687,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:24:00,139.4306255498,35.4787361057,1,7,2811315,9 ,11,37,,8",
 "358800,2,4,1998/10/01 09:24:00,139.6048509581,35.7373607739,2,5,3023001,9 ,1 ,35,,1",
 "365700,2,3,1998/10/01 09:24:00,139.7178909404,35.7833951126,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:24:00,139.6916950249,35.7364757441,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:24:00,139.5490468251,35.7283265722,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:24:00,139.6353361132,35.9210829054,1,14,3052009,15,6 ,29,,3",
 "402500,4,4,1998/10/01 09:24:00,139.4090942966,35.8238795417,1,9,3116015,9 ,11,44,,8",
 "448500,2,3,1998/10/01 09:24:00,139.8006011429,35.7710040681,2,7,3222102,4 ,1 ,27,,12",
 "466900,2,2,1998/10/01 09:24:00,139.3383747641,35.8522567581,2,5,3310206,4 ,1 ,42,,1",
 "485300,2,2,1998/10/01 09:24:00,139.5578862588,35.9400231929,1,13,3511206,15,5 ,26,,2",
 "581900,2,2,1998/10/01 09:24:00,139.7778109464,35.7123617383,1,9,0321101,5 ,7 ,97,,2",
 "600300,2,3,1998/10/01 09:24:00,139.7003930057,35.6886497683,1,5,0420106,13,2 ,64,,12",
 "611800,2,3,1998/10/01 09:24:00,139.3998055509,35.4835244477,1,4,0431110,13,2 ,65,,12",
 "616400,2,4,1998/10/01 09:24:00,139.7059376436,35.6055240976,2,13,0433105,15,7 ,50,,1",
 "701500,6,6,1998/10/01 09:24:00,139.5657118045,35.7141491401,2,10,0721104,9 ,3 ,48,,2",
 "786600,3,5,1998/10/01 09:24:00,139.2898229748,35.6597563033,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:24:00,139.4748543794,35.5737346315,1,4,0811306,13,2 ,45,,1",
 "708400,2,3,1998/10/01 09:24:00,139.7267435994,35.7047829023,2,8,0731211,8 ,1 ,34,,12",
 "117300,2,2,1998/10/01 09:25:00,139.8476718758,34.9793493498,1,8,4730209,3 ,8 ,38,,6",
 "317400,2,2,1998/10/01 09:25:00,139.6985475149,35.5278081626,2,5,2022101,14,7 ,42,,6",
 "9200,2,4,1998/10/01 09:25:00,139.9136852912,35.6804431573,1,11,4114306,5 ,1 ,28,,1",
 "48300,2,4,1998/10/01 09:25:00,139.7679361485,35.6672928999,2,4,4215402,4 ,1 ,44,,1",
 "48300,3,5,1998/10/01 09:25:00,139.7679361485,35.6672928999,2,4,4215402,4 ,97,44,,97",
 "62100,2,3,1998/10/01 09:25:00,139.7363123528,35.6155378409,1,7,4224202,9 ,1 ,37,,12",
 "80500,2,2,1998/10/01 09:25:00,140.1372014687,35.7218776277,1,14,4413233,5 ,1 ,33,,6",
 "172500,2,3,1998/10/01 09:25:00,139.7376729113,35.6926963158,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 09:25:00,139.8645006781,35.7687318861,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,2,1998/10/01 09:25:00,139.773256244,35.6191013114,1,8,2410601,7 ,3 ,15,,1",
 "296700,2,3,1998/10/01 09:25:00,139.464090258,35.5645480235,2,8,2920301,4 ,1 ,33,,12",
 "119600,4,4,1998/10/01 09:25:00,140.8599376391,35.7284633857,2,13,4810072,14,3 ,45,,1",
 "119600,5,5,1998/10/01 09:25:00,140.8599376391,35.7284633857,2,13,4810072,14,97,45,,97",
 "149500,2,2,1998/10/01 09:25:00,140.1161366508,35.7040390126,2,10,4022410,4 ,1 ,29,,2",
 "250700,2,2,1998/10/01 09:25:00,139.3530813074,35.3349930427,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:25:00,139.4322791103,35.4787059289,1,7,2811315,9 ,11,37,,8",
 "358800,2,4,1998/10/01 09:25:00,139.6035966229,35.7378109917,2,5,3023001,9 ,1 ,35,,1",
 "358800,3,5,1998/10/01 09:25:00,139.6035966229,35.7378109917,2,5,3023001,9 ,97,35,,97",
 "365700,2,3,1998/10/01 09:25:00,139.7200462308,35.7792803181,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:25:00,139.6954392243,35.7339345418,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:25:00,139.5546420474,35.7289162402,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:25:00,139.6351817287,35.9211456844,1,14,3052009,15,6 ,29,,3",
 "402500,4,4,1998/10/01 09:25:00,139.4064273754,35.8227513789,1,9,3116015,9 ,11,44,,8",
 "402500,5,5,1998/10/01 09:25:00,139.4064273754,35.8227513789,1,9,3116015,9 ,97,44,,97",
 "448500,2,3,1998/10/01 09:25:00,139.8069865989,35.7674565018,2,7,3222102,4 ,1 ,27,,12",
 "466900,2,2,1998/10/01 09:25:00,139.3374562959,35.8523122232,2,5,3310206,4 ,1 ,42,,1",
 "485300,2,2,1998/10/01 09:25:00,139.5564470353,35.9394479933,1,13,3511206,15,5 ,26,,2",
 "581900,2,2,1998/10/01 09:25:00,139.7767647111,35.7135676179,1,9,0321101,5 ,7 ,97,,2",
 "581900,2,3,1998/10/01 09:25:00,139.7767647111,35.7135676179,1,9,0321101,5 ,7 ,97,,12",
 "600300,2,3,1998/10/01 09:25:00,139.702077913,35.6842449067,1,5,0420106,13,2 ,64,,12",
 "611800,2,3,1998/10/01 09:25:00,139.3999547379,35.4784328344,1,4,0431110,13,2 ,65,,12",
 "616400,2,4,1998/10/01 09:25:00,139.7066331859,35.6059501335,2,13,0433105,15,7 ,50,,1",
 "616400,3,5,1998/10/01 09:25:00,139.7066331859,35.6059501335,2,13,0433105,15,97,50,,97",
 "754400,2,2,1998/10/01 09:25:00,139.4745994768,35.5730953726,1,4,0811306,13,2 ,45,,1",
 "701500,6,6,1998/10/01 09:25:00,139.5672672302,35.7140014257,2,10,0721104,9 ,3 ,48,,2",
 "701500,7,7,1998/10/01 09:25:00,139.5672672302,35.7140014257,2,10,0721104,9 ,97,48,,97",
 "786600,3,5,1998/10/01 09:25:00,139.2901445542,35.6597985103,2,3,0930110,13,2 ,35,,9",
 "795800,2,3,1998/10/01 09:25:00,139.7030583498,35.536630486,1,6,1412027,9 ,1 ,47,,12",
 "708400,2,3,1998/10/01 09:25:00,139.7324584101,35.704240201,2,8,0731211,8 ,1 ,34,,12",
 "795800,2,3,1998/10/01 09:26:00,139.7065552541,35.5408179669,1,6,1412027,9 ,1 ,47,,12",
 "117300,2,2,1998/10/01 09:26:00,139.847653495,34.9815895238,1,8,4730209,3 ,8 ,38,,6",
 "317400,2,2,1998/10/01 09:26:00,139.6998991908,35.5281424774,2,5,2022101,14,7 ,42,,6",
 "9200,2,4,1998/10/01 09:26:00,139.9141110428,35.6799048665,1,11,4114306,5 ,1 ,28,,1",
 "62100,2,3,1998/10/01 09:26:00,139.735322411,35.6094932085,1,7,4224202,9 ,1 ,37,,12",
 "80500,2,2,1998/10/01 09:26:00,140.133366416,35.7217289395,1,14,4413233,5 ,1 ,33,,6",
 "172500,2,3,1998/10/01 09:26:00,139.7327927079,35.6896955486,2,4,5024120,13,2 ,44,,12",
 "193200,2,4,1998/10/01 09:26:00,139.8581286055,35.7681310344,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,2,1998/10/01 09:26:00,139.7732557369,35.619101451,1,8,2410601,7 ,3 ,15,,1",
 "296700,2,3,1998/10/01 09:26:00,139.4677248203,35.5680755545,2,8,2920301,4 ,1 ,33,,12",
 "149500,2,2,1998/10/01 09:26:00,140.1161238608,35.7055588697,2,10,4022410,4 ,1 ,29,,2",
 "250700,2,2,1998/10/01 09:26:00,139.3548052125,35.3352510612,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:26:00,139.4339327848,35.4786795963,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:26:00,139.7221350586,35.7751842867,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:26:00,139.700139873,35.7328752517,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:26:00,139.560284686,35.7290876159,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:26:00,139.635020409,35.9212041397,1,14,3052009,15,6 ,29,,3",
 "448500,2,3,1998/10/01 09:26:00,139.8129274092,35.7635209535,2,7,3222102,4 ,1 ,27,,12",
 "466900,2,2,1998/10/01 09:26:00,139.3365378265,35.8523676812,2,5,3310206,4 ,1 ,42,,1",
 "485300,2,2,1998/10/01 09:26:00,139.5549609833,35.9389656242,1,13,3511206,15,5 ,26,,2",
 "581900,2,3,1998/10/01 09:26:00,139.7776706023,35.7155240551,1,9,0321101,5 ,7 ,97,,12",
 "600300,2,3,1998/10/01 09:26:00,139.7058620224,35.6813957201,1,5,0420106,13,2 ,64,,12",
 "611800,2,3,1998/10/01 09:26:00,139.3996604666,35.4733473754,1,4,0431110,13,2 ,65,,12",
 "786600,3,5,1998/10/01 09:26:00,139.2904609448,35.6597329325,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:26:00,139.4744261537,35.5724383133,1,4,0811306,13,2 ,45,,1",
 "708400,2,3,1998/10/01 09:26:00,139.7362794628,35.7012312109,2,8,0731211,8 ,1 ,34,,12",
 "795800,2,3,1998/10/01 09:27:00,139.7088879187,35.5454660598,1,6,1412027,9 ,1 ,47,,12",
 "117300,2,2,1998/10/01 09:27:00,139.8495319663,34.982952664,1,8,4730209,3 ,8 ,38,,6",
 "317400,2,2,1998/10/01 09:27:00,139.7012031604,35.5275679326,2,5,2022101,14,7 ,42,,6",
 "9200,2,4,1998/10/01 09:27:00,139.9145879946,35.6793630246,1,11,4114306,5 ,1 ,28,,1",
 "434700,8,8,1998/10/01 09:27:00,139.7574493197,35.8149813609,2,8,3210107,8 ,1 ,37,,7",
 "62100,2,3,1998/10/01 09:27:00,139.7345636282,35.6034215026,1,7,4224202,9 ,1 ,37,,12",
 "80500,2,2,1998/10/01 09:27:00,140.1319273629,35.7233200223,1,14,4413233,5 ,1 ,33,,6",
 "172500,2,3,1998/10/01 09:27:00,139.7301025325,35.6857359401,2,4,5024120,13,2 ,44,,12",
 "172500,2,4,1998/10/01 09:27:00,139.7301025325,35.6857359401,2,4,5024120,13,2 ,44,,1",
 "193200,2,4,1998/10/01 09:27:00,139.8517571608,35.767526986,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,2,1998/10/01 09:27:00,139.7732552299,35.6191015907,1,8,2410601,7 ,3 ,15,,1",
 "230000,2,3,1998/10/01 09:27:00,139.7732552299,35.6191015907,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:27:00,139.4703702538,35.5721926359,2,8,2920301,4 ,1 ,33,,12",
 "149500,2,2,1998/10/01 09:27:00,140.1155568511,35.7070226512,2,10,4022410,4 ,1 ,29,,2",
 "250700,2,2,1998/10/01 09:27:00,139.3565117674,35.3355876337,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:27:00,139.4355851153,35.478620738,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:27:00,139.7226921211,35.7707664725,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:27:00,139.7048954739,35.7323639863,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:27:00,139.5658995575,35.7285711768,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:27:00,139.6348590891,35.9212625948,1,14,3052009,15,6 ,29,,3",
 "448500,2,3,1998/10/01 09:27:00,139.8116960789,35.7577192657,2,7,3222102,4 ,1 ,27,,12",
 "466900,2,2,1998/10/01 09:27:00,139.3356193559,35.8524231321,2,5,3310206,4 ,1 ,42,,1",
 "485300,2,2,1998/10/01 09:27:00,139.5554654667,35.9380531781,1,13,3511206,15,5 ,26,,2",
 "600300,2,3,1998/10/01 09:27:00,139.7114798158,35.6812544155,1,5,0420106,13,2 ,64,,12",
 "581900,2,3,1998/10/01 09:27:00,139.7791891678,35.7173420416,1,9,0321101,5 ,7 ,97,,12",
 "611800,2,3,1998/10/01 09:27:00,139.399245129,35.4682647543,1,4,0431110,13,2 ,65,,12",
 "786600,3,5,1998/10/01 09:27:00,139.2906813329,35.6595429384,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:27:00,139.4742891901,35.5717759921,1,4,0811306,13,2 ,45,,1",
 "708400,2,3,1998/10/01 09:27:00,139.7409483843,35.7033872731,2,8,0731211,8 ,1 ,34,,12",
 "795800,2,3,1998/10/01 09:28:00,139.7108942686,35.5502635628,1,6,1412027,9 ,1 ,47,,12",
 "117300,2,2,1998/10/01 09:28:00,139.8519803497,34.9839930965,1,8,4730209,3 ,8 ,38,,6",
 "317400,2,2,1998/10/01 09:28:00,139.7025083534,35.5267438003,2,5,2022101,14,7 ,42,,6",
 "9200,2,4,1998/10/01 09:28:00,139.9139924767,35.6788834603,1,11,4114306,5 ,1 ,28,,1",
 "434700,8,8,1998/10/01 09:28:00,139.7589742034,35.8147070914,2,8,3210107,8 ,1 ,37,,7",
 "62100,2,3,1998/10/01 09:28:00,139.7335233832,35.59739175,1,7,4224202,9 ,1 ,37,,12",
 "80500,2,2,1998/10/01 09:28:00,140.1282817001,35.7239059196,1,14,4413233,5 ,1 ,33,,6",
 "172500,2,4,1998/10/01 09:28:00,139.733966799,35.6836899466,2,4,5024120,13,2 ,44,,1",
 "193200,2,4,1998/10/01 09:28:00,139.84583912,35.7657496245,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:28:00,139.7737338913,35.620793173,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:28:00,139.4733038307,35.5761788146,2,8,2920301,4 ,1 ,33,,12",
 "149500,2,2,1998/10/01 09:28:00,140.1153798051,35.7085782667,2,10,4022410,4 ,1 ,29,,2",
 "250700,2,2,1998/10/01 09:28:00,139.358209043,35.3359545385,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:28:00,139.4372351882,35.4785274995,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:28:00,139.7249462501,35.7666820242,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:28:00,139.7089524867,35.7301450426,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:28:00,139.571541562,35.72826103,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:28:00,139.6346964985,35.9213185692,1,14,3052009,15,6 ,29,,3",
 "448500,2,3,1998/10/01 09:28:00,139.8071877407,35.7526097069,2,7,3222102,4 ,1 ,27,,12",
 "466900,2,2,1998/10/01 09:28:00,139.3347026132,35.8524373737,2,5,3310206,4 ,1 ,42,,1",
 "485300,2,2,1998/10/01 09:28:00,139.5550441686,35.9368318943,1,13,3511206,15,5 ,26,,2",
 "581900,2,3,1998/10/01 09:28:00,139.77960706,35.7194271318,1,9,0321101,5 ,7 ,97,,12",
 "600300,2,3,1998/10/01 09:28:00,139.7170629284,35.6804927068,1,5,0420106,13,2 ,64,,12",
 "611800,2,3,1998/10/01 09:28:00,139.3987852311,35.4631849307,1,4,0431110,13,2 ,65,,12",
 "786600,3,5,1998/10/01 09:28:00,139.2907879676,35.659288638,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:28:00,139.4741369979,35.5711539208,1,4,0811306,13,2 ,45,,1",
 "708400,2,3,1998/10/01 09:28:00,139.7461562522,35.7017451791,2,8,0731211,8 ,1 ,34,,12",
 "795800,2,3,1998/10/01 09:29:00,139.7129008568,35.5550610291,1,6,1412027,9 ,1 ,47,,12",
 "117300,2,2,1998/10/01 09:29:00,139.8547318859,34.9836763303,1,8,4730209,3 ,8 ,38,,6",
 "317400,2,2,1998/10/01 09:29:00,139.703687363,35.5268663905,2,5,2022101,14,7 ,42,,6",
 "9200,2,4,1998/10/01 09:29:00,139.9133969659,35.678403893,1,11,4114306,5 ,1 ,28,,1",
 "434700,8,8,1998/10/01 09:29:00,139.7598213623,35.8145092179,2,8,3210107,8 ,1 ,37,,7",
 "62100,2,3,1998/10/01 09:29:00,139.7300919565,35.5919713165,1,7,4224202,9 ,1 ,37,,12",
 "80500,2,2,1998/10/01 09:29:00,140.1257524794,35.725600342,1,14,4413233,5 ,1 ,33,,6",
 "172500,2,4,1998/10/01 09:29:00,139.73589355,35.6800777577,2,4,5024120,13,2 ,44,,1",
 "193200,2,4,1998/10/01 09:29:00,139.8400023779,35.7637421955,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:29:00,139.7730120419,35.6218557243,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:29:00,139.4759305605,35.5803105443,2,8,2920301,4 ,1 ,33,,12",
 "149500,2,2,1998/10/01 09:29:00,140.1142646789,35.7097536055,2,10,4022410,4 ,1 ,29,,2",
 "250700,2,2,1998/10/01 09:29:00,139.3594265766,35.3355034087,2,11,2512003,5 ,1 ,38,,6",
 "271400,2,2,1998/10/01 09:29:00,139.4388854445,35.4784365758,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:29:00,139.727535203,35.7627400482,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:29:00,139.7131914035,35.7282199827,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:29:00,139.5771559945,35.7278254396,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:29:00,139.6345318706,35.9213705659,1,14,3052009,15,6 ,29,,3",
 "448500,2,3,1998/10/01 09:29:00,139.8056115973,35.7467956316,2,7,3222102,4 ,1 ,27,,12",
 "466900,2,2,1998/10/01 09:29:00,139.3337855894,35.8524387471,2,5,3310206,4 ,1 ,42,,1",
 "485300,2,2,1998/10/01 09:29:00,139.5535741887,35.9367222714,1,13,3511206,15,5 ,26,,2",
 "581900,2,3,1998/10/01 09:29:00,139.7782219295,35.7212793845,1,9,0321101,5 ,7 ,97,,12",
 "600300,2,3,1998/10/01 09:29:00,139.722663954,35.6799563045,1,5,0420106,13,2 ,64,,12",
 "611800,2,3,1998/10/01 09:29:00,139.3964407796,35.4585200884,1,4,0431110,13,2 ,65,,12",
 "786600,3,5,1998/10/01 09:29:00,139.2908946015,35.6590343376,2,3,0930110,13,2 ,35,,9",
 "754400,2,2,1998/10/01 09:29:00,139.474069337,35.5713874747,1,4,0811306,13,2 ,45,,1",
 "708400,2,3,1998/10/01 09:29:00,139.750080113,35.6982593988,2,8,0731211,8 ,1 ,34,,12",
 "117300,2,2,1998/10/01 09:30:00,139.8563733518,34.9847586023,1,8,4730209,3 ,8 ,38,,6",
 "117300,3,3,1998/10/01 09:30:00,139.8563733518,34.9847586023,1,8,4730209,3 ,3 ,38,,6",
 "317400,2,2,1998/10/01 09:30:00,139.7051818231,35.5263999507,2,5,2022101,14,7 ,42,,6",
 "317400,3,3,1998/10/01 09:30:00,139.7051818231,35.5263999507,2,5,2022101,14,97,42,,97",
 "9200,2,4,1998/10/01 09:30:00,139.9134075181,35.6781376927,1,11,4114306,5 ,1 ,28,,1",
 "9200,3,5,1998/10/01 09:30:00,139.9134075181,35.6781376927,1,11,4114306,5 ,97,28,,97",
 "434700,8,8,1998/10/01 09:30:00,139.7606988456,35.8154919855,2,8,3210107,8 ,1 ,37,,7",
 "62100,2,3,1998/10/01 09:30:00,139.7266156863,35.5865689505,1,7,4224202,9 ,1 ,37,,12",
 "80500,2,2,1998/10/01 09:30:00,140.1243798998,35.7250598747,1,14,4413233,5 ,1 ,33,,6",
 "80500,3,3,1998/10/01 09:30:00,140.1243798998,35.7250598747,1,14,4413233,5 ,97,33,,97",
 "172500,2,4,1998/10/01 09:30:00,139.7381051748,35.677981948,2,4,5024120,13,2 ,44,,1",
 "172500,3,5,1998/10/01 09:30:00,139.7381051748,35.677981948,2,4,5024120,13,97,44,,97",
 "193200,2,4,1998/10/01 09:30:00,139.8336381422,35.7630968141,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:30:00,139.7718293292,35.6232985249,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:30:00,139.4805499715,35.5827982226,2,8,2920301,4 ,1 ,33,,12",
 "149500,2,2,1998/10/01 09:30:00,140.1124648933,35.7101540491,2,10,4022410,4 ,1 ,29,,2",
 "250700,2,2,1998/10/01 09:30:00,139.3609228459,35.3348804605,2,11,2512003,5 ,1 ,38,,6",
 "250700,3,3,1998/10/01 09:30:00,139.3609228459,35.3348804605,2,11,2512003,5 ,97,38,,97",
 "271400,2,2,1998/10/01 09:30:00,139.4405360171,35.4783494859,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:30:00,139.7315654597,35.759707367,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:30:00,139.717603373,35.7267813842,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:30:00,139.5827128745,35.7270718412,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:30:00,139.6343672424,35.9214225623,1,14,3052009,15,6 ,29,,3",
 "448500,2,3,1998/10/01 09:30:00,139.803183537,35.742711779,2,7,3222102,4 ,1 ,27,,12",
 "466900,2,2,1998/10/01 09:30:00,139.3328663434,35.8524849097,2,5,3310206,4 ,1 ,42,,1",
 "485300,2,2,1998/10/01 09:30:00,139.5540509032,35.937078845,1,13,3511206,15,5 ,26,,2",
 "485300,3,3,1998/10/01 09:30:00,139.5540509032,35.937078845,1,13,3511206,15,97,26,,97",
 "581900,2,3,1998/10/01 09:30:00,139.7764798269,35.7229587817,1,9,0321101,5 ,7 ,97,,12",
 "600300,2,3,1998/10/01 09:30:00,139.7276820797,35.6820713771,1,5,0420106,13,2 ,64,,12",
 "611800,2,3,1998/10/01 09:30:00,139.3927973469,35.454423549,1,4,0431110,13,2 ,65,,12",
 "786600,3,5,1998/10/01 09:30:00,139.2910012348,35.658780037,2,3,0930110,13,2 ,35,,9",
 "795800,2,3,1998/10/01 09:30:00,139.7149076834,35.5598584586,1,6,1412027,9 ,1 ,47,,12",
 "754400,2,2,1998/10/01 09:30:00,139.4743715678,35.5720131674,1,4,0811306,13,2 ,45,,1",
 "754400,3,3,1998/10/01 09:30:00,139.4743715678,35.5720131674,1,4,0811306,13,97,45,,97",
 "708400,2,3,1998/10/01 09:30:00,139.7525198962,35.6940783138,2,8,0731211,8 ,1 ,34,,12",
 "795800,2,3,1998/10/01 09:31:00,139.7168835549,35.5646641499,1,6,1412027,9 ,1 ,47,,12",
 "117300,3,3,1998/10/01 09:31:00,139.8546014483,34.983694455,1,8,4730209,3 ,3 ,38,,6",
 "434700,8,8,1998/10/01 09:31:00,139.7615554506,35.8162412138,2,8,3210107,8 ,1 ,37,,7",
 "434700,9,9,1998/10/01 09:31:00,139.7615554506,35.8162412138,2,8,3210107,8 ,97,37,,97",
 "62100,2,3,1998/10/01 09:31:00,139.7237552676,35.5809492705,1,7,4224202,9 ,1 ,37,,12",
 "193200,2,4,1998/10/01 09:31:00,139.827263495,35.7625228302,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:31:00,139.7726524216,35.624689564,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:31:00,139.486066404,35.5839790324,2,8,2920301,4 ,1 ,33,,12",
 "149500,2,2,1998/10/01 09:31:00,140.1106711496,35.7095956702,2,10,4022410,4 ,1 ,29,,2",
 "271400,2,2,1998/10/01 09:31:00,139.4421893057,35.4783194648,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:31:00,139.7348799609,35.7561925439,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:31:00,139.721482115,35.7243586492,1,7,3110313,8 ,1 ,33,,12",
 "377200,2,2,1998/10/01 09:31:00,139.634202614,35.9214745585,1,14,3052009,15,6 ,29,,3",
 "418600,2,4,1998/10/01 09:31:00,139.5883377454,35.726598824,1,11,3123503,8 ,1 ,23,,12",
 "448500,2,3,1998/10/01 09:31:00,139.7959050524,35.7428300517,2,7,3222102,4 ,1 ,27,,12",
 "466900,2,2,1998/10/01 09:31:00,139.3319458685,35.8524925367,2,5,3310206,4 ,1 ,42,,1",
 "453100,2,2,1998/10/01 09:31:00,139.8339643528,35.8732564886,2,11,3224113,14,4 ,40,,6",
 "581900,2,3,1998/10/01 09:31:00,139.7747161811,35.7246236289,1,9,0321101,5 ,7 ,97,,12",
 "614100,2,2,1998/10/01 09:31:00,139.6431179035,35.6204970546,2,4,0432205,13,2 ,73,,1",
 "600300,2,3,1998/10/01 09:31:00,139.7306317984,35.6859171861,1,5,0420106,13,2 ,64,,12",
 "611800,2,3,1998/10/01 09:31:00,139.3884746863,35.4507611425,1,4,0431110,13,2 ,65,,12",
 "690000,2,2,1998/10/01 09:31:00,139.5624324087,35.671397544,2,1,0710226,12,7 ,18,,2",
 "786600,3,5,1998/10/01 09:31:00,139.2911078674,35.6585257364,2,3,0930110,13,2 ,35,,9",
 "874000,2,2,1998/10/01 09:31:00,139.5132675949,35.3930892437,1,13,1223004,4 ,1 ,33,,7",
 "708400,2,3,1998/10/01 09:31:00,139.7565445824,35.6907165258,2,8,0731211,8 ,1 ,34,,12",
 "795800,2,3,1998/10/01 09:32:00,139.7189304869,35.569450314,1,6,1412027,9 ,1 ,47,,12",
 "117300,3,3,1998/10/01 09:32:00,139.8518344661,34.9839188337,1,8,4730209,3 ,3 ,38,,6",
 "62100,2,3,1998/10/01 09:32:00,139.7213432558,35.5751729279,1,7,4224202,9 ,1 ,37,,12",
 "193200,2,4,1998/10/01 09:32:00,139.8208952528,35.7618988412,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:32:00,139.772886953,35.6251706453,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:32:00,139.491538686,35.585264599,2,8,2920301,4 ,1 ,33,,12",
 "149500,2,2,1998/10/01 09:32:00,140.1090191077,35.7088118702,2,10,4022410,4 ,1 ,29,,2",
 "271400,2,2,1998/10/01 09:32:00,139.4438226635,35.4784949682,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:32:00,139.7378327745,35.7524227165,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:32:00,139.7252146113,35.7218020685,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:32:00,139.593947828,35.7260282755,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:32:00,139.6340379855,35.9215265545,1,14,3052009,15,6 ,29,,3",
 "448500,2,3,1998/10/01 09:32:00,139.7889047187,35.7440720327,2,7,3222102,4 ,1 ,27,,12",
 "466900,2,2,1998/10/01 09:32:00,139.3310252051,35.8524748673,2,5,3310206,4 ,1 ,42,,1",
 "453100,2,2,1998/10/01 09:32:00,139.8319107315,35.8731535415,2,11,3224113,14,4 ,40,,6",
 "581900,2,3,1998/10/01 09:32:00,139.7728782657,35.7262333644,1,9,0321101,5 ,7 ,97,,12",
 "600300,2,3,1998/10/01 09:32:00,139.7331258364,35.6898908009,1,5,0420106,13,2 ,64,,12",
 "614100,2,2,1998/10/01 09:32:00,139.6449975921,35.6218645681,2,4,0432205,13,2 ,73,,1",
 "611800,2,3,1998/10/01 09:32:00,139.3841090512,35.4471298839,1,4,0431110,13,2 ,65,,12",
 "690000,2,2,1998/10/01 09:32:00,139.5630517982,35.6709997928,2,1,0710226,12,7 ,18,,2",
 "786600,3,5,1998/10/01 09:32:00,139.2912144993,35.6582714356,2,3,0930110,13,2 ,35,,9",
 "874000,2,2,1998/10/01 09:32:00,139.5142233225,35.3930470036,1,13,1223004,4 ,1 ,33,,7",
 "708400,2,3,1998/10/01 09:32:00,139.7607644801,35.688043682,2,8,0731211,8 ,1 ,34,,12",
 "795800,2,3,1998/10/01 09:33:00,139.7209549754,35.5742428393,1,6,1412027,9 ,1 ,47,,12",
 "117300,3,3,1998/10/01 09:33:00,139.849327631,34.9829057143,1,8,4730209,3 ,3 ,38,,6",
 "62100,2,3,1998/10/01 09:33:00,139.7189100479,35.5694025264,1,7,4224202,9 ,1 ,37,,12",
 "193200,2,4,1998/10/01 09:33:00,139.8145325489,35.7612404389,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:33:00,139.7713389854,35.6239828859,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:33:00,139.4959985774,35.5880096786,2,8,2920301,4 ,1 ,33,,12",
 "149500,2,2,1998/10/01 09:33:00,140.1078733023,35.7094315008,2,10,4022410,4 ,1 ,29,,2",
 "271400,2,2,1998/10/01 09:33:00,139.4453606628,35.4789871633,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:33:00,139.7420374371,35.7496652218,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:33:00,139.7278171426,35.7184916348,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:33:00,139.5995556892,35.7254528697,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:33:00,139.6338733567,35.9215785502,1,14,3052009,15,6 ,29,,3",
 "448500,2,3,1998/10/01 09:33:00,139.7816270459,35.7423888632,2,7,3222102,4 ,1 ,27,,12",
 "466900,2,2,1998/10/01 09:33:00,139.3301056044,35.8524366741,2,5,3310206,4 ,1 ,42,,1",
 "453100,2,2,1998/10/01 09:33:00,139.8298619595,35.8730027751,2,11,3224113,14,4 ,40,,6",
 "581900,2,3,1998/10/01 09:33:00,139.7710441561,35.7278467904,1,9,0321101,5 ,7 ,97,,12",
 "600300,2,3,1998/10/01 09:33:00,139.7376396194,35.6926690471,1,5,0420106,13,2 ,64,,12",
 "614100,2,2,1998/10/01 09:33:00,139.6474255813,35.6224456052,2,4,0432205,13,2 ,73,,1",
 "614100,3,3,1998/10/01 09:33:00,139.6474255813,35.6224456052,2,4,0432205,13,97,73,,97",
 "611800,2,3,1998/10/01 09:33:00,139.379577382,35.4436529343,1,4,0431110,13,2 ,65,,12",
 "690000,2,2,1998/10/01 09:33:00,139.5636604341,35.6707551398,2,1,0710226,12,7 ,18,,2",
 "786600,3,5,1998/10/01 09:33:00,139.2912451661,35.6580053826,2,3,0930110,13,2 ,35,,9",
 "874000,2,2,1998/10/01 09:33:00,139.5141881697,35.3929521122,1,13,1223004,4 ,1 ,33,,7",
 "708400,2,3,1998/10/01 09:33:00,139.764413886,35.6850445484,2,8,0731211,8 ,1 ,34,,12",
 "795800,2,3,1998/10/01 09:34:00,139.7229591323,35.5790411327,1,6,1412027,9 ,1 ,47,,12",
 "117300,3,3,1998/10/01 09:34:00,139.8476119319,34.9813849944,1,8,4730209,3 ,3 ,38,,6",
 "62100,2,3,1998/10/01 09:34:00,139.7164459595,35.5636408797,1,7,4224202,9 ,1 ,37,,12",
 "193200,2,4,1998/10/01 09:34:00,139.8096484731,35.7582378557,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:34:00,139.76978652,35.6227979605,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:34:00,139.4989182003,35.591976149,2,8,2920301,4 ,1 ,33,,12",
 "149500,2,2,1998/10/01 09:34:00,140.1076849328,35.7109684962,2,10,4022410,4 ,1 ,29,,2",
 "271400,2,2,1998/10/01 09:34:00,139.4468321986,35.4796047269,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:34:00,139.7474631651,35.7491085466,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:34:00,139.7295816441,35.7147758133,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:34:00,139.605195186,35.725139408,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:34:00,139.6337087276,35.9216305457,1,14,3052009,15,6 ,29,,3",
 "448500,2,3,1998/10/01 09:34:00,139.7774162663,35.7381485676,2,7,3222102,4 ,1 ,27,,12",
 "466900,2,2,1998/10/01 09:34:00,139.329184824,35.8524211053,2,5,3310206,4 ,1 ,42,,1",
 "453100,2,2,1998/10/01 09:34:00,139.8278153819,35.872830411,2,11,3224113,14,4 ,40,,6",
 "581900,2,3,1998/10/01 09:34:00,139.7692841141,35.7295110418,1,9,0321101,5 ,7 ,97,,12",
 "600300,2,3,1998/10/01 09:34:00,139.7407425297,35.6965193646,1,5,0420106,13,2 ,64,,12",
 "611800,2,3,1998/10/01 09:34:00,139.3736419355,35.4422072684,1,4,0431110,13,2 ,65,,12",
 "690000,2,2,1998/10/01 09:34:00,139.5642201066,35.6712080049,2,1,0710226,12,7 ,18,,2",
 "786600,3,5,1998/10/01 09:34:00,139.2912451554,35.6577366035,2,3,0930110,13,2 ,35,,9",
 "874000,2,2,1998/10/01 09:34:00,139.5161190823,35.3927131042,1,13,1223004,4 ,1 ,33,,7",
 "708400,2,3,1998/10/01 09:34:00,139.7700669415,35.6838309104,2,8,0731211,8 ,1 ,34,,12",
 "117300,3,3,1998/10/01 09:35:00,139.847653862,34.9791094767,1,8,4730209,3 ,3 ,38,,6",
 "62100,2,3,1998/10/01 09:35:00,139.7140695866,35.5578550158,1,7,4224202,9 ,1 ,37,,12",
 "193200,2,4,1998/10/01 09:35:00,139.806169088,35.7538629657,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:35:00,139.7682850348,35.621568651,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:35:00,139.5005391695,35.5964216886,2,8,2920301,4 ,1 ,33,,12",
 "149500,2,2,1998/10/01 09:35:00,140.1069621938,35.712398798,2,10,4022410,4 ,1 ,29,,2",
 "271400,2,2,1998/10/01 09:35:00,139.4483061959,35.4802184287,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:35:00,139.7525159977,35.7474067822,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:35:00,139.7314446552,35.711092884,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:35:00,139.6108423802,35.7249037795,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:35:00,139.6335484477,35.9216887666,1,14,3052009,15,6 ,29,,3",
 "448500,2,3,1998/10/01 09:35:00,139.7714731122,35.734358109,2,7,3222102,4 ,1 ,27,,12",
 "466900,2,2,1998/10/01 09:35:00,139.3282641095,35.8524028736,2,5,3310206,4 ,1 ,42,,1",
 "453100,2,2,1998/10/01 09:35:00,139.8257688131,35.8726580121,2,11,3224113,14,4 ,40,,6",
 "581900,2,3,1998/10/01 09:35:00,139.7679191983,35.7314052791,1,9,0321101,5 ,7 ,97,,12",
 "600300,2,3,1998/10/01 09:35:00,139.7438288859,35.7003912991,1,5,0420106,13,2 ,64,,12",
 "611800,2,3,1998/10/01 09:35:00,139.3676298019,35.4408925296,1,4,0431110,13,2 ,65,,12",
 "690000,2,2,1998/10/01 09:35:00,139.5646115062,35.6713254564,2,1,0710226,12,7 ,18,,2",
 "795800,2,3,1998/10/01 09:35:00,139.7249503499,35.5838423571,1,6,1412027,9 ,1 ,47,,12",
 "786600,3,5,1998/10/01 09:35:00,139.2912451448,35.6574678244,2,3,0930110,13,2 ,35,,9",
 "874000,2,2,1998/10/01 09:35:00,139.5182234444,35.3925386737,1,13,1223004,4 ,1 ,33,,7",
 "708400,2,3,1998/10/01 09:35:00,139.7748318537,35.6816731222,2,8,0731211,8 ,1 ,34,,12",
 "795800,2,3,1998/10/01 09:36:00,139.7277515317,35.5883639606,1,6,1412027,9 ,1 ,47,,12",
 "117300,3,3,1998/10/01 09:36:00,139.8454352118,34.9780961663,1,8,4730209,3 ,3 ,38,,6",
 "62100,2,3,1998/10/01 09:36:00,139.7116538939,35.5520797898,1,7,4224202,9 ,1 ,37,,12",
 "163300,2,2,1998/10/01 09:36:00,140.0708662742,35.6386566891,2,11,4061110,4 ,5 ,30,,2",
 "193200,2,4,1998/10/01 09:36:00,139.8060522923,35.7486860812,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:36:00,139.7667887568,35.6203351213,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:36:00,139.5032766992,35.6004163961,2,8,2920301,4 ,1 ,33,,12",
 "142600,2,2,1998/10/01 09:36:00,140.1507533996,35.5909873847,2,14,4013104,14,8 ,27,,6",
 "149500,2,2,1998/10/01 09:36:00,140.1058676554,35.7135205102,2,10,4022410,4 ,1 ,29,,2",
 "271400,2,2,1998/10/01 09:36:00,139.4497919036,35.4808130516,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:36:00,139.757188891,35.7450714012,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:36:00,139.7353241768,35.7089381036,1,7,3110313,8 ,1 ,33,,12",
 "377200,2,2,1998/10/01 09:36:00,139.6334092704,35.9217771945,1,14,3052009,15,6 ,29,,3",
 "418600,2,4,1998/10/01 09:36:00,139.6164654013,35.7245484726,1,11,3123503,8 ,1 ,23,,12",
 "448500,2,3,1998/10/01 09:36:00,139.7655969437,35.7337336398,2,7,3222102,4 ,1 ,27,,12",
 "466900,2,2,1998/10/01 09:36:00,139.3273432671,35.8523893677,2,5,3310206,4 ,1 ,42,,1",
 "453100,2,2,1998/10/01 09:36:00,139.8237222531,35.8724855783,2,11,3224113,14,4 ,40,,6",
 "487600,4,4,1998/10/01 09:36:00,139.6062223229,35.9287970454,1,10,3512006,6 ,9 ,36,,6",
 "581900,2,3,1998/10/01 09:36:00,139.7664586177,35.7325568651,1,9,0321101,5 ,7 ,97,,12",
 "600300,2,3,1998/10/01 09:36:00,139.7484239332,35.7024194618,1,5,0420106,13,2 ,64,,12",
 "611800,2,3,1998/10/01 09:36:00,139.3627020875,35.4379094056,1,4,0431110,13,2 ,65,,12",
 "690000,2,2,1998/10/01 09:36:00,139.5650423715,35.6709360694,2,1,0710226,12,7 ,18,,2",
 "786600,3,5,1998/10/01 09:36:00,139.2912773729,35.6572009871,2,3,0930110,13,2 ,35,,9",
 "874000,2,2,1998/10/01 09:36:00,139.5202763197,35.3922219851,1,13,1223004,4 ,1 ,33,,7",
 "708400,2,3,1998/10/01 09:36:00,139.7733205616,35.6776277512,2,8,0731211,8 ,1 ,34,,12",
 "795800,2,3,1998/10/01 09:37:00,139.7306533979,35.592845655,1,6,1412027,9 ,1 ,47,,12",
 "117300,3,3,1998/10/01 09:37:00,139.8438884887,34.9762517487,1,8,4730209,3 ,3 ,38,,6",
 "62100,2,3,1998/10/01 09:37:00,139.7092385465,35.5463045105,1,7,4224202,9 ,1 ,37,,12",
 "163300,2,2,1998/10/01 09:37:00,140.0691126529,35.6380041383,2,11,4061110,4 ,5 ,30,,2",
 "193200,2,4,1998/10/01 09:37:00,139.806272912,35.743461351,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:37:00,139.7652634876,35.6191258287,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:37:00,139.5073707918,35.6036602553,2,8,2920301,4 ,1 ,33,,12",
 "142600,2,2,1998/10/01 09:37:00,140.1473514717,35.592231891,2,14,4013104,14,8 ,27,,6",
 "149500,2,2,1998/10/01 09:37:00,140.1042867395,35.7141691858,2,10,4022410,4 ,1 ,29,,2",
 "271400,2,2,1998/10/01 09:37:00,139.4512726227,35.4814159981,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:37:00,139.7615906291,35.7423915642,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:37:00,139.7401297128,35.7082198307,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:37:00,139.6220925922,35.7241756344,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:37:00,139.6332700928,35.9218656221,1,14,3052009,15,6 ,29,,3",
 "448500,2,3,1998/10/01 09:37:00,139.7604596306,35.7384288817,2,7,3222102,4 ,1 ,27,,12",
 "466900,2,2,1998/10/01 09:37:00,139.3266099658,35.8520337011,2,5,3310206,4 ,1 ,42,,1",
 "453100,2,2,1998/10/01 09:37:00,139.8216757021,35.8723131096,2,11,3224113,14,4 ,40,,6",
 "487600,4,4,1998/10/01 09:37:00,139.6085090603,35.9288673774,1,10,3512006,6 ,9 ,36,,6",
 "581900,2,3,1998/10/01 09:37:00,139.7649948989,35.7343977312,1,9,0321101,5 ,7 ,97,,12",
 "600300,2,3,1998/10/01 09:37:00,139.7540794895,35.7021882283,1,5,0420106,13,2 ,64,,12",
 "611800,2,3,1998/10/01 09:37:00,139.358838622,35.4339163577,1,4,0431110,13,2 ,65,,12",
 "690000,2,2,1998/10/01 09:37:00,139.565782219,35.6707142944,2,1,0710226,12,7 ,18,,2",
 "786600,3,5,1998/10/01 09:37:00,139.2913354872,35.6569364976,2,3,0930110,13,2 ,35,,9",
 "874000,2,2,1998/10/01 09:37:00,139.5218113994,35.3910466926,1,13,1223004,4 ,1 ,33,,7",
 "708400,2,3,1998/10/01 09:37:00,139.7705776157,35.6734182941,2,8,0731211,8 ,1 ,34,,12",
 "795800,2,3,1998/10/01 09:38:00,139.7335050642,35.5973482397,1,6,1412027,9 ,1 ,47,,12",
 "117300,3,3,1998/10/01 09:38:00,139.8444833637,34.9742969953,1,8,4730209,3 ,3 ,38,,6",
 "62100,2,3,1998/10/01 09:38:00,139.7064542393,35.5406960355,1,7,4224202,9 ,1 ,37,,12",
 "163300,2,2,1998/10/01 09:38:00,140.0671548801,35.6373045253,2,11,4061110,4 ,5 ,30,,2",
 "193200,2,4,1998/10/01 09:38:00,139.800555896,35.7418723227,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:38:00,139.7637190905,35.6179325331,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:38:00,139.5113797261,35.6069732097,2,8,2920301,4 ,1 ,33,,12",
 "142600,2,2,1998/10/01 09:38:00,140.1436135381,35.5923875942,2,14,4013104,14,8 ,27,,6",
 "149500,2,2,1998/10/01 09:38:00,140.1045217055,35.7150748921,2,10,4022410,4 ,1 ,29,,2",
 "149500,3,3,1998/10/01 09:38:00,140.1045217055,35.7150748921,2,10,4022410,4 ,97,29,,97",
 "271400,2,2,1998/10/01 09:38:00,139.4527563076,35.4820139964,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:38:00,139.7646105174,35.7387340723,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:38:00,139.743661075,35.7059244953,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:38:00,139.6276912789,35.7235293597,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:38:00,139.633130915,35.9219540496,1,14,3052009,15,6 ,29,,3",
 "448500,2,3,1998/10/01 09:38:00,139.7546600343,35.7425689346,2,7,3222102,4 ,1 ,27,,12",
 "466900,2,2,1998/10/01 09:38:00,139.3264492618,35.8514080997,2,5,3310206,4 ,1 ,42,,1",
 "453100,2,2,1998/10/01 09:38:00,139.8196292044,35.8721402596,2,11,3224113,14,4 ,40,,6",
 "487600,4,4,1998/10/01 09:38:00,139.6107913098,35.9287267654,1,10,3512006,6 ,9 ,36,,6",
 "581900,2,3,1998/10/01 09:38:00,139.7632009782,35.7360406894,1,9,0321101,5 ,7 ,97,,12",
 "600300,2,3,1998/10/01 09:38:00,139.7595939481,35.701432139,1,5,0420106,13,2 ,64,,12",
 "611800,2,3,1998/10/01 09:38:00,139.3549847644,35.429916398,1,4,0431110,13,2 ,65,,12",
 "690000,2,2,1998/10/01 09:38:00,139.566508311,35.6704649295,2,1,0710226,12,7 ,18,,2",
 "786600,3,5,1998/10/01 09:38:00,139.2915638692,35.6568943842,2,3,0930110,13,2 ,35,,9",
 "874000,2,2,1998/10/01 09:38:00,139.5229834678,35.3907359083,1,13,1223004,4 ,1 ,33,,7",
 "708400,2,3,1998/10/01 09:38:00,139.7670444278,35.6696275204,2,8,0731211,8 ,1 ,34,,12",
 "708400,2,4,1998/10/01 09:38:00,139.7670444278,35.6696275204,2,8,0731211,8 ,1 ,34,,1",
 "795800,2,3,1998/10/01 09:39:00,139.7344294455,35.602347756,1,6,1412027,9 ,1 ,47,,12",
 "117300,3,3,1998/10/01 09:39:00,139.8456664335,34.972222733,1,8,4730209,3 ,3 ,38,,6",
 "62100,2,3,1998/10/01 09:39:00,139.7021999504,35.535685096,1,7,4224202,9 ,1 ,37,,12",
 "163300,2,2,1998/10/01 09:39:00,140.0654025366,35.6380438632,2,11,4061110,4 ,5 ,30,,2",
 "193200,2,4,1998/10/01 09:39:00,139.794626733,35.743485144,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:39:00,139.7621747392,35.6167392175,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:39:00,139.5163691861,35.6091702837,2,8,2920301,4 ,1 ,33,,12",
 "142600,2,2,1998/10/01 09:39:00,140.1413721255,35.5936745327,2,14,4013104,14,8 ,27,,6",
 "271400,2,2,1998/10/01 09:39:00,139.4542418656,35.4826089135,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:39:00,139.7663171875,35.7344784025,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:39:00,139.7437467928,35.7020844905,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:39:00,139.6332899032,35.7228829784,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:39:00,139.6329917368,35.9220424769,1,14,3052009,15,6 ,29,,3",
 "448500,2,3,1998/10/01 09:39:00,139.7481835087,35.7459857252,2,7,3222102,4 ,1 ,27,,12",
 "466900,2,2,1998/10/01 09:39:00,139.3263006613,35.8507985459,2,5,3310206,4 ,1 ,42,,1",
 "453100,2,2,1998/10/01 09:39:00,139.8175829741,35.8719653574,2,11,3224113,14,4 ,40,,6",
 "487600,4,4,1998/10/01 09:39:00,139.612605297,35.9285664712,1,10,3512006,6 ,9 ,36,,6",
 "581900,2,3,1998/10/01 09:39:00,139.7613708791,35.7376568975,1,9,0321101,5 ,7 ,97,,12",
 "600300,2,3,1998/10/01 09:39:00,139.7645437034,35.6999019558,1,5,0420106,13,2 ,64,,12",
 "600300,2,4,1998/10/01 09:39:00,139.7645437034,35.6999019558,1,5,0420106,13,2 ,64,,1",
 "611800,2,3,1998/10/01 09:39:00,139.3520796372,35.425414872,1,4,0431110,13,2 ,65,,12",
 "690000,2,2,1998/10/01 09:39:00,139.5672277949,35.6702023136,2,1,0710226,12,7 ,18,,2",
 "786600,3,5,1998/10/01 09:39:00,139.2918867054,35.6569439503,2,3,0930110,13,2 ,35,,9",
 "874000,2,2,1998/10/01 09:39:00,139.5244608491,35.3913364668,1,13,1223004,4 ,1 ,33,,7",
 "708400,2,4,1998/10/01 09:39:00,139.7681372147,35.6708122739,2,8,0731211,8 ,1 ,34,,1",
 "795800,2,3,1998/10/01 09:40:00,139.7350597698,35.6073914885,1,6,1412027,9 ,1 ,47,,12",
 "117300,3,3,1998/10/01 09:40:00,139.8464188052,34.9700435041,1,8,4730209,3 ,3 ,38,,6",
 "62100,2,3,1998/10/01 09:40:00,139.6968624888,35.5314219481,1,7,4224202,9 ,1 ,37,,12",
 "62100,2,4,1998/10/01 09:40:00,139.6968624888,35.5314219481,1,7,4224202,9 ,1 ,37,,1",
 "163300,2,2,1998/10/01 09:40:00,140.0631316958,35.6379675016,2,11,4061110,4 ,5 ,30,,2",
 "193200,2,4,1998/10/01 09:40:00,139.788716772,35.7440142533,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:40:00,139.7606304338,35.6155458819,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:40:00,139.5209881067,35.6118124588,2,8,2920301,4 ,1 ,33,,12",
 "142600,2,2,1998/10/01 09:40:00,140.1377475244,35.594507984,2,14,4013104,14,8 ,27,,6",
 "271400,2,2,1998/10/01 09:40:00,139.4557212743,35.4832139758,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:40:00,139.7685745715,35.7304087784,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:40:00,139.7413290148,35.6986232097,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:40:00,139.6389289522,35.7226321905,1,11,3123503,8 ,1 ,23,,12",
 "377200,2,2,1998/10/01 09:40:00,139.6328525583,35.9221309041,1,14,3052009,15,6 ,29,,3",
 "377200,3,3,1998/10/01 09:40:00,139.6328525583,35.9221309041,1,14,3052009,15,97,29,,97",
 "448500,2,3,1998/10/01 09:40:00,139.7416758259,35.7493513094,2,7,3222102,4 ,1 ,27,,12",
 "466900,2,2,1998/10/01 09:40:00,139.3258722584,35.8505163876,2,5,3310206,4 ,1 ,42,,1",
 "453100,2,2,1998/10/01 09:40:00,139.815536674,35.871791037,2,11,3224113,14,4 ,40,,6",
 "487600,4,4,1998/10/01 09:40:00,139.612981147,35.9267302006,1,10,3512006,6 ,9 ,36,,6",
 "581900,2,3,1998/10/01 09:40:00,139.7594997326,35.7392420566,1,9,0321101,5 ,7 ,97,,12",
 "600300,2,4,1998/10/01 09:40:00,139.7684594672,35.6981046075,1,5,0420106,13,2 ,64,,1",
 "600300,3,5,1998/10/01 09:40:00,139.7684594672,35.6981046075,1,5,0420106,13,97,64,,97",
 "611800,2,3,1998/10/01 09:40:00,139.3486660229,35.4212200859,1,4,0431110,13,2 ,65,,12",
 "690000,2,2,1998/10/01 09:40:00,139.5678588377,35.6698338204,2,1,0710226,12,7 ,18,,2",
 "786600,3,5,1998/10/01 09:40:00,139.292214504,35.6569689873,2,3,0930110,13,2 ,35,,9",
 "874000,2,2,1998/10/01 09:40:00,139.5255622917,35.3910960009,1,13,1223004,4 ,1 ,33,,7",
 "874000,3,3,1998/10/01 09:40:00,139.5255622917,35.3910960009,1,13,1223004,4 ,97,33,,97",
 "708400,2,4,1998/10/01 09:40:00,139.7691528373,35.6719131828,2,8,0731211,8 ,1 ,34,,1",
 "795800,2,3,1998/10/01 09:41:00,139.7356900823,35.6124352211,1,6,1412027,9 ,1 ,47,,12",
 "117300,3,3,1998/10/01 09:41:00,139.8460424696,34.9677605477,1,8,4730209,3 ,3 ,38,,6",
 "147200,2,2,1998/10/01 09:41:00,140.0829497991,35.6571416792,2,9,4022001,15,7 ,38,,1",
 "13800,4,7,1998/10/01 09:41:00,139.9425187688,35.807133255,2,14,4116004,5 ,3 ,25,,1",
 "62100,2,4,1998/10/01 09:41:00,139.6996996871,35.5328274673,1,7,4224202,9 ,1 ,37,,1",
 "163300,2,2,1998/10/01 09:41:00,140.0610475242,35.6380165804,2,11,4061110,4 ,5 ,30,,2",
 "193200,2,4,1998/10/01 09:41:00,139.7825128276,35.7429260757,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:41:00,139.7590861743,35.6143525263,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:41:00,139.5251540712,35.6148193408,2,8,2920301,4 ,1 ,33,,12",
 "142600,2,2,1998/10/01 09:41:00,140.1360705207,35.5963516738,2,14,4013104,14,8 ,27,,6",
 "271400,2,2,1998/10/01 09:41:00,139.4572032705,35.4838147254,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:41:00,139.7720678467,35.7269710634,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:41:00,139.738787144,35.6952204894,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:41:00,139.644563266,35.7224351656,1,11,3123503,8 ,1 ,23,,12",
 "448500,2,3,1998/10/01 09:41:00,139.7366856399,35.7541253592,2,7,3222102,4 ,1 ,27,,12",
 "466900,2,2,1998/10/01 09:41:00,139.3251406565,35.8500915284,2,5,3310206,4 ,1 ,42,,1",
 "453100,2,2,1998/10/01 09:41:00,139.8134912561,35.8716100176,2,11,3224113,14,4 ,40,,6",
 "487600,4,4,1998/10/01 09:41:00,139.613671916,35.9249613563,1,10,3512006,6 ,9 ,36,,6",
 "581900,2,3,1998/10/01 09:41:00,139.7572984817,35.7403840771,1,9,0321101,5 ,7 ,97,,12",
 "611800,2,3,1998/10/01 09:41:00,139.3441081099,35.4177523953,1,4,0431110,13,2 ,65,,12",
 "690000,2,2,1998/10/01 09:41:00,139.5685103691,35.6694718868,2,1,0710226,12,7 ,18,,2",
 "786600,3,5,1998/10/01 09:41:00,139.29253579,35.6570211414,2,3,0930110,13,2 ,35,,9",
 "708400,2,4,1998/10/01 09:41:00,139.7680625566,35.6707268846,2,8,0731211,8 ,1 ,34,,1",
 "795800,2,3,1998/10/01 09:42:00,139.7368047727,35.6174193359,1,6,1412027,9 ,1 ,47,,12",
 "117300,3,3,1998/10/01 09:42:00,139.8447424615,34.9658538143,1,8,4730209,3 ,3 ,38,,6",
 "147200,2,2,1998/10/01 09:42:00,140.0829524803,35.6571408403,2,9,4022001,15,7 ,38,,1",
 "13800,4,7,1998/10/01 09:42:00,139.942518771,35.8071326282,2,14,4116004,5 ,3 ,25,,1",
 "62100,2,4,1998/10/01 09:42:00,139.7025369844,35.5342329196,1,7,4224202,9 ,1 ,37,,1",
 "163300,2,2,1998/10/01 09:42:00,140.0594130013,35.6385223356,2,11,4061110,4 ,5 ,30,,2",
 "193200,2,4,1998/10/01 09:42:00,139.7785432097,35.7398991299,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:42:00,139.7575765788,35.6131304997,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:42:00,139.5308218389,35.6145265128,2,8,2920301,4 ,1 ,33,,12",
 "142600,2,2,1998/10/01 09:42:00,140.1361638218,35.5994219963,2,14,4013104,14,8 ,27,,6",
 "271400,2,2,1998/10/01 09:42:00,139.458669974,35.4844402001,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:42:00,139.7757488772,35.7236487956,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:42:00,139.7363508772,35.6917687979,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:42:00,139.6498481408,35.7208002864,1,11,3123503,8 ,1 ,23,,12",
 "448500,2,3,1998/10/01 09:42:00,139.7322257829,35.759239536,2,7,3222102,4 ,1 ,27,,12",
 "466900,2,2,1998/10/01 09:42:00,139.3244557922,35.8499153065,2,5,3310206,4 ,1 ,42,,1",
 "453100,2,2,1998/10/01 09:42:00,139.8124746505,35.8725009326,2,11,3224113,14,4 ,40,,6",
 "487600,4,4,1998/10/01 09:42:00,139.6155648884,35.924674105,1,10,3512006,6 ,9 ,36,,6",
 "581900,2,3,1998/10/01 09:42:00,139.7547211455,35.7401672678,1,9,0321101,5 ,7 ,97,,12",
 "611800,2,3,1998/10/01 09:42:00,139.3395000813,35.4143375058,1,4,0431110,13,2 ,65,,12",
 "690000,2,2,1998/10/01 09:42:00,139.5691728242,35.6691236904,2,1,0710226,12,7 ,18,,2",
 "786600,3,5,1998/10/01 09:42:00,139.2928517897,35.6570968349,2,3,0930110,13,2 ,35,,9",
 "708400,2,4,1998/10/01 09:42:00,139.7675050303,35.6703209372,2,8,0731211,8 ,1 ,34,,1",
 "795800,2,3,1998/10/01 09:43:00,139.7378623896,35.6224092501,1,6,1412027,9 ,1 ,47,,12",
 "117300,3,3,1998/10/01 09:43:00,139.8439124692,34.9636922266,1,8,4730209,3 ,3 ,38,,6",
 "154100,2,2,1998/10/01 09:43:00,140.1457383184,35.6420415022,2,10,4040012,14,7 ,41,,6",
 "147200,2,2,1998/10/01 09:43:00,140.0829551616,35.6571400015,2,9,4022001,15,7 ,38,,1",
 "13800,4,7,1998/10/01 09:43:00,139.9425187733,35.8071320013,2,14,4116004,5 ,3 ,25,,1",
 "13800,4,8,1998/10/01 09:43:00,139.9425187733,35.8071320013,2,14,4116004,5 ,3 ,25,,10",
 "62100,2,4,1998/10/01 09:43:00,139.7038215325,35.5325503632,1,7,4224202,9 ,1 ,37,,1",
 "163300,2,2,1998/10/01 09:43:00,140.0589375662,35.6371072965,2,11,4061110,4 ,5 ,30,,2",
 "163300,2,3,1998/10/01 09:43:00,140.0589375662,35.6371072965,2,11,4061110,4 ,5 ,30,,12",
 "193200,2,4,1998/10/01 09:43:00,139.7747904588,35.7357601013,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:43:00,139.7564884125,35.6116549107,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:43:00,139.5365241475,35.6146058066,2,8,2920301,4 ,1 ,33,,12",
 "142600,2,2,1998/10/01 09:43:00,140.1358573442,35.6024655034,2,14,4013104,14,8 ,27,,6",
 "271400,2,2,1998/10/01 09:43:00,139.4600173628,35.485190792,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:43:00,139.7792461946,35.7202002852,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:43:00,139.7359892308,35.6879857163,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:43:00,139.6551017601,35.7190979963,1,11,3123503,8 ,1 ,23,,12",
 "448500,2,3,1998/10/01 09:43:00,139.7269608702,35.7636437495,2,7,3222102,4 ,1 ,27,,12",
 "448500,2,4,1998/10/01 09:43:00,139.7269608702,35.7636437495,2,7,3222102,4 ,1 ,27,,1",
 "466900,2,2,1998/10/01 09:43:00,139.3237243057,35.8502751633,2,5,3310206,4 ,1 ,42,,1",
 "453100,2,2,1998/10/01 09:43:00,139.810427797,35.8723308611,2,11,3224113,14,4 ,40,,6",
 "487600,4,4,1998/10/01 09:43:00,139.6167369233,35.9235821797,1,10,3512006,6 ,9 ,36,,6",
 "581900,2,3,1998/10/01 09:43:00,139.7524185281,35.7390204067,1,9,0321101,5 ,7 ,97,,12",
 "611800,2,3,1998/10/01 09:43:00,139.3354024307,35.4105748231,1,4,0431110,13,2 ,65,,12",
 "690000,2,2,1998/10/01 09:43:00,139.5699150516,35.6689368338,2,1,0710226,12,7 ,18,,2",
 "786600,3,5,1998/10/01 09:43:00,139.2931686278,35.6571701645,2,3,0930110,13,2 ,35,,9",
 "708400,2,4,1998/10/01 09:43:00,139.7671310215,35.6699072971,2,8,0731211,8 ,1 ,34,,1",
 "795800,2,3,1998/10/01 09:44:00,139.7385652023,35.627446589,1,6,1412027,9 ,1 ,47,,12",
 "117300,3,3,1998/10/01 09:44:00,139.8414462399,34.9628254953,1,8,4730209,3 ,3 ,38,,6",
 "154100,2,2,1998/10/01 09:44:00,140.1457381159,35.6420413053,2,10,4040012,14,7 ,41,,6",
 "147200,2,2,1998/10/01 09:44:00,140.0829578428,35.6571391626,2,9,4022001,15,7 ,38,,1",
 "147200,2,3,1998/10/01 09:44:00,140.0829578428,35.6571391626,2,9,4022001,15,7 ,38,,6",
 "13800,4,8,1998/10/01 09:44:00,139.9435978922,35.8060702379,2,14,4116004,5 ,3 ,25,,10",
 "62100,2,4,1998/10/01 09:44:00,139.7033376379,35.5303461482,1,7,4224202,9 ,1 ,37,,1",
 "163300,2,3,1998/10/01 09:44:00,140.0548232407,35.6401878254,2,11,4061110,4 ,5 ,30,,12",
 "193200,2,4,1998/10/01 09:44:00,139.7691030655,35.7333543445,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:44:00,139.7555419175,35.6101003023,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:44:00,139.5422062505,35.6149712085,2,8,2920301,4 ,1 ,33,,12",
 "142600,2,2,1998/10/01 09:44:00,140.1373145861,35.6052111876,2,14,4013104,14,8 ,27,,6",
 "271400,2,2,1998/10/01 09:44:00,139.4614094635,35.4859212753,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:44:00,139.7781949148,35.7161446598,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:44:00,139.737587737,35.6842205064,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:44:00,139.6606875089,35.7195819881,1,11,3123503,8 ,1 ,23,,12",
 "448500,2,4,1998/10/01 09:44:00,139.7270511828,35.7617906471,2,7,3222102,4 ,1 ,27,,1",
 "466900,2,2,1998/10/01 09:44:00,139.3229186502,35.8506384393,2,5,3310206,4 ,1 ,42,,1",
 "453100,2,2,1998/10/01 09:44:00,139.808490283,35.8723669686,2,11,3224113,14,4 ,40,,6",
 "487600,4,4,1998/10/01 09:44:00,139.6171478366,35.9217512737,1,10,3512006,6 ,9 ,36,,6",
 "581900,2,3,1998/10/01 09:44:00,139.7501191329,35.7378690854,1,9,0321101,5 ,7 ,97,,12",
 "611800,2,3,1998/10/01 09:44:00,139.3310019754,35.4070227758,1,4,0431110,13,2 ,65,,12",
 "690000,2,2,1998/10/01 09:44:00,139.5702569997,35.6685323926,2,1,0710226,12,7 ,18,,2",
 "786600,3,5,1998/10/01 09:44:00,139.2934854665,35.6572434932,2,3,0930110,13,2 ,35,,9",
 "708400,2,4,1998/10/01 09:44:00,139.7682405178,35.6687460773,2,8,0731211,8 ,1 ,34,,1",
 "117300,3,3,1998/10/01 09:45:00,139.8390590271,34.9624295299,1,8,4730209,3 ,3 ,38,,6",
 "147200,2,3,1998/10/01 09:45:00,140.0791432727,35.6558511876,2,9,4022001,15,7 ,38,,6",
 "154100,2,2,1998/10/01 09:45:00,140.1457379134,35.6420411084,2,10,4040012,14,7 ,41,,6",
 "13800,4,8,1998/10/01 09:45:00,139.9446769825,35.8050084646,2,14,4116004,5 ,3 ,25,,10",
 "62100,2,4,1998/10/01 09:45:00,139.7047175692,35.5284527877,1,7,4224202,9 ,1 ,37,,1",
 "62100,3,5,1998/10/01 09:45:00,139.7047175692,35.5284527877,1,7,4224202,9 ,97,37,,97",
 "163300,2,3,1998/10/01 09:45:00,140.0504718474,35.6431661631,2,11,4061110,4 ,5 ,30,,12",
 "193200,2,4,1998/10/01 09:45:00,139.7642504552,35.7301776789,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:45:00,139.7549023437,35.6084836069,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:45:00,139.5478731736,35.6154915413,2,8,2920301,4 ,1 ,33,,12",
 "142600,2,2,1998/10/01 09:45:00,140.1367078341,35.6082473998,2,14,4013104,14,8 ,27,,6",
 "271400,2,2,1998/10/01 09:45:00,139.4628440993,35.4865714329,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:45:00,139.7757102342,35.712308483,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:45:00,139.7392290092,35.6804675128,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:45:00,139.6662642454,35.7189527349,1,11,3123503,8 ,1 ,23,,12",
 "448500,2,4,1998/10/01 09:45:00,139.7282514567,35.7586875751,2,7,3222102,4 ,1 ,27,,1",
 "448500,3,5,1998/10/01 09:45:00,139.7282514567,35.7586875751,2,7,3222102,4 ,97,27,,97",
 "466900,2,2,1998/10/01 09:45:00,139.3221129874,35.8510017099,2,5,3310206,4 ,1 ,42,,1",
 "453100,2,2,1998/10/01 09:45:00,139.8073207913,35.8737443847,2,11,3224113,14,4 ,40,,6",
 "487600,4,4,1998/10/01 09:45:00,139.6176517555,35.919936014,1,10,3512006,6 ,9 ,36,,6",
 "581900,2,3,1998/10/01 09:45:00,139.7478053304,35.736737911,1,9,0321101,5 ,7 ,97,,12",
 "611800,2,3,1998/10/01 09:45:00,139.3262305554,35.403753497,1,4,0431110,13,2 ,65,,12",
 "690000,2,2,1998/10/01 09:45:00,139.5704286179,35.6679054225,2,1,0710226,12,7 ,18,,2",
 "795800,2,3,1998/10/01 09:45:00,139.7405988624,35.6321949597,1,6,1412027,9 ,1 ,47,,12",
 "786600,3,5,1998/10/01 09:45:00,139.2938023059,35.6573168211,2,3,0930110,13,2 ,35,,9",
 "708400,2,4,1998/10/01 09:45:00,139.7695417608,35.6677562999,2,8,0731211,8 ,1 ,34,,1",
 "708400,3,5,1998/10/01 09:45:00,139.7695417608,35.6677562999,2,8,0731211,8 ,97,34,,97",
 "117300,3,3,1998/10/01 09:46:00,139.837648223,34.9604893368,1,8,4730209,3 ,3 ,38,,6",
 "154100,2,2,1998/10/01 09:46:00,140.1457377109,35.6420409115,2,10,4040012,14,7 ,41,,6",
 "147200,2,3,1998/10/01 09:46:00,140.0812311401,35.6543956008,2,9,4022001,15,7 ,38,,6",
 "13800,4,8,1998/10/01 09:46:00,139.9457560441,35.8039466816,2,14,4116004,5 ,3 ,25,,10",
 "163300,2,3,1998/10/01 09:46:00,140.0459253761,35.6459454899,2,11,4061110,4 ,5 ,30,,12",
 "193200,2,4,1998/10/01 09:46:00,139.7630730029,35.7251628569,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:46:00,139.7545747411,35.606988515,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:46:00,139.5535768873,35.6155675574,2,8,2920301,4 ,1 ,33,,12",
 "142600,2,2,1998/10/01 09:46:00,140.1360393536,35.6112641202,2,14,4013104,14,8 ,27,,6",
 "271400,2,2,1998/10/01 09:46:00,139.4640928104,35.4874552892,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:46:00,139.7751686604,35.7079580357,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:46:00,139.742410837,35.6776512114,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:46:00,139.6710175958,35.7164827071,1,11,3123503,8 ,1 ,23,,12",
 "466900,2,2,1998/10/01 09:46:00,139.3218061398,35.8503701548,2,5,3310206,4 ,1 ,42,,1",
 "453100,2,2,1998/10/01 09:46:00,139.8061736412,35.8751340198,2,11,3224113,14,4 ,40,,6",
 "487600,4,4,1998/10/01 09:46:00,139.6181717313,35.9181234937,1,10,3512006,6 ,9 ,36,,6",
 "581900,2,3,1998/10/01 09:46:00,139.7454097712,35.7357315707,1,9,0321101,5 ,7 ,97,,12",
 "611800,2,3,1998/10/01 09:46:00,139.3214595213,35.4004840312,1,4,0431110,13,2 ,65,,12",
 "690000,2,2,1998/10/01 09:46:00,139.5706080371,35.6672799121,2,1,0710226,12,7 ,18,,2",
 "786600,3,5,1998/10/01 09:46:00,139.2941191458,35.6573901481,2,3,0930110,13,2 ,35,,9",
 "795800,2,3,1998/10/01 09:46:00,139.7424075613,35.6370339647,1,6,1412027,9 ,1 ,47,,12",
 "795800,2,3,1998/10/01 09:47:00,139.7444479481,35.641656969,1,6,1412027,9 ,1 ,47,,12",
 "117300,3,3,1998/10/01 09:47:00,139.8389344365,34.9585274654,1,8,4730209,3 ,3 ,38,,6",
 "154100,2,2,1998/10/01 09:47:00,140.1457375083,35.6420407145,2,10,4040012,14,7 ,41,,6",
 "147200,2,3,1998/10/01 09:47:00,140.084267424,35.6537418275,2,9,4022001,15,7 ,38,,6",
 "13800,4,8,1998/10/01 09:47:00,139.945701686,35.8029080981,2,14,4116004,5 ,3 ,25,,10",
 "163300,2,3,1998/10/01 09:47:00,140.0413809275,35.6487271835,2,11,4061110,4 ,5 ,30,,12",
 "193200,2,4,1998/10/01 09:47:00,139.7630831484,35.7200789347,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:47:00,139.7531046154,35.6078867696,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:47:00,139.5592815875,35.6155996265,2,8,2920301,4 ,1 ,33,,12",
 "142600,2,2,1998/10/01 09:47:00,140.1341666862,35.613932556,2,14,4013104,14,8 ,27,,6",
 "271400,2,2,1998/10/01 09:47:00,139.4653959647,35.4882728729,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:47:00,139.774186395,35.7037949525,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:47:00,139.7472134165,35.6778260824,1,7,3110313,8 ,1 ,33,,12",
 "418600,2,4,1998/10/01 09:47:00,139.6763853194,35.7157746017,1,11,3123503,8 ,1 ,23,,12",
 "466900,2,2,1998/10/01 09:47:00,139.3211939535,35.8501390097,2,5,3310206,4 ,1 ,42,,1",
 "453100,2,2,1998/10/01 09:47:00,139.8056150667,35.8764061649,2,11,3224113,14,4 ,40,,6",
 "487600,4,4,1998/10/01 09:47:00,139.6187207063,35.9163181385,1,10,3512006,6 ,9 ,36,,6",
 "581900,2,3,1998/10/01 09:47:00,139.7429679484,35.7347955266,1,9,0321101,5 ,7 ,97,,12",
 "611800,2,3,1998/10/01 09:47:00,139.3164973876,35.3974260162,1,4,0431110,13,2 ,65,,12",
 "690000,2,2,1998/10/01 09:47:00,139.5707649976,35.6667609913,2,1,0710226,12,7 ,18,,2",
 "786600,3,5,1998/10/01 09:47:00,139.294431149,35.6574747425,2,3,0930110,13,2 ,35,,9",
 "117300,3,3,1998/10/01 09:48:00,139.838385091,34.9563497008,1,8,4730209,3 ,3 ,38,,6",
 "154100,2,2,1998/10/01 09:48:00,140.1457373058,35.6420405176,2,10,4040012,14,7 ,41,,6",
 "147200,2,3,1998/10/01 09:48:00,140.086760461,35.6526828849,2,9,4022001,15,7 ,38,,6",
 "13800,4,8,1998/10/01 09:48:00,139.9447394959,35.80177757,2,14,4116004,5 ,3 ,25,,10",
 "163300,2,3,1998/10/01 09:48:00,140.0368557708,35.6515295519,2,11,4061110,4 ,5 ,30,,12",
 "193200,2,4,1998/10/01 09:48:00,139.7668985151,35.7158854648,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:48:00,139.7531461969,35.6096047502,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:48:00,139.5645412539,35.6172480832,2,8,2920301,4 ,1 ,33,,12",
 "142600,2,2,1998/10/01 09:48:00,140.1324264875,35.6166531461,2,14,4013104,14,8 ,27,,6",
 "271400,2,2,1998/10/01 09:48:00,139.4660833689,35.4894708814,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:48:00,139.7732170285,35.699415429,1,10,3033223,10,1 ,38,,12",
 "391000,2,3,1998/10/01 09:48:00,139.7520731833,35.6774318319,1,7,3110313,8 ,1 ,33,,12",
 "391000,2,4,1998/10/01 09:48:00,139.7520731833,35.6774318319,1,7,3110313,8 ,1 ,33,,1",
 "418600,2,4,1998/10/01 09:48:00,139.6818878274,35.7148105327,1,11,3123503,8 ,1 ,23,,12",
 "466900,2,2,1998/10/01 09:48:00,139.3205457918,35.8498090285,2,5,3310206,4 ,1 ,42,,1",
 "453100,2,2,1998/10/01 09:48:00,139.8039820731,35.8772417933,2,11,3224113,14,4 ,40,,6",
 "487600,4,4,1998/10/01 09:48:00,139.6207302421,35.9170102569,1,10,3512006,6 ,9 ,36,,6",
 "581900,2,3,1998/10/01 09:48:00,139.740555747,35.7338103474,1,9,0321101,5 ,7 ,97,,12",
 "611800,2,3,1998/10/01 09:48:00,139.3111631074,35.3948051445,1,4,0431110,13,2 ,65,,12",
 "690000,2,2,1998/10/01 09:48:00,139.5715399915,35.666643975,2,1,0710226,12,7 ,18,,2",
 "795800,2,3,1998/10/01 09:48:00,139.7475191697,35.6457407536,1,6,1412027,9 ,1 ,47,,12",
 "795800,2,4,1998/10/01 09:48:00,139.7475191697,35.6457407536,1,6,1412027,9 ,1 ,47,,1",
 "786600,3,5,1998/10/01 09:48:00,139.2947347846,35.6575788301,2,3,0930110,13,2 ,35,,9",
 "795800,2,4,1998/10/01 09:49:00,139.7478665982,35.6465012409,1,6,1412027,9 ,1 ,47,,1",
 "117300,3,3,1998/10/01 09:49:00,139.837927924,34.954063177,1,8,4730209,3 ,3 ,38,,6",
 "154100,2,2,1998/10/01 09:49:00,140.1457371033,35.6420403207,2,10,4040012,14,7 ,41,,6",
 "147200,2,3,1998/10/01 09:49:00,140.0888124757,35.6499173914,2,9,4022001,15,7 ,38,,6",
 "13800,4,8,1998/10/01 09:49:00,139.9438167067,35.8006221768,2,14,4116004,5 ,3 ,25,,10",
 "163300,2,3,1998/10/01 09:49:00,140.0324567771,35.6544624984,2,11,4061110,4 ,5 ,30,,12",
 "193200,2,4,1998/10/01 09:49:00,139.7687617023,35.7109823951,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:49:00,139.7532899643,35.6113369029,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:49:00,139.5688419367,35.6201476818,2,8,2920301,4 ,1 ,33,,12",
 "142600,2,2,1998/10/01 09:49:00,140.1314990174,35.6196359876,2,14,4013104,14,8 ,27,,6",
 "271400,2,2,1998/10/01 09:49:00,139.4672363515,35.4902171936,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:49:00,139.7723326335,35.6950214364,1,10,3033223,10,1 ,38,,12",
 "391000,2,4,1998/10/01 09:49:00,139.7519538973,35.6769628744,1,7,3110313,8 ,1 ,33,,1",
 "418600,2,4,1998/10/01 09:49:00,139.687514947,35.7151859256,1,11,3123503,8 ,1 ,23,,12",
 "466900,2,2,1998/10/01 09:49:00,139.3197857048,35.8502324151,2,5,3310206,4 ,1 ,42,,1",
 "453100,2,2,1998/10/01 09:49:00,139.8019723401,35.8775978244,2,11,3224113,14,4 ,40,,6",
 "487600,4,4,1998/10/01 09:49:00,139.6217313211,35.9159341675,1,10,3512006,6 ,9 ,36,,6",
 "581900,2,3,1998/10/01 09:49:00,139.738179468,35.7327681836,1,9,0321101,5 ,7 ,97,,12",
 "611800,2,3,1998/10/01 09:49:00,139.3058204922,35.3921955435,1,4,0431110,13,2 ,65,,12",
 "690000,2,2,1998/10/01 09:49:00,139.5723149832,35.6665269537,2,1,0710226,12,7 ,18,,2",
 "786600,3,5,1998/10/01 09:49:00,139.2950300984,35.6576976444,2,3,0930110,13,2 ,35,,9",
 "795800,2,4,1998/10/01 09:50:00,139.7482615354,35.6472293057,1,6,1412027,9 ,1 ,47,,1",
 "117300,3,3,1998/10/01 09:50:00,139.8366150817,34.9520390819,1,8,4730209,3 ,3 ,38,,6",
 "147200,2,3,1998/10/01 09:50:00,140.091922647,35.6492729169,2,9,4022001,15,7 ,38,,6",
 "154100,2,2,1998/10/01 09:50:00,140.1457369008,35.6420401238,2,10,4040012,14,7 ,41,,6",
 "13800,4,8,1998/10/01 09:50:00,139.9428962103,35.7994655795,2,14,4116004,5 ,3 ,25,,10",
 "163300,2,3,1998/10/01 09:50:00,140.0280954465,35.6574336696,2,11,4061110,4 ,5 ,30,,12",
 "193200,2,4,1998/10/01 09:50:00,139.7699708246,35.7060700749,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:50:00,139.7514491949,35.6116220845,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:50:00,139.5716173245,35.6242164228,2,8,2920301,4 ,1 ,33,,12",
 "142600,2,2,1998/10/01 09:50:00,140.1299840405,35.6224127427,2,14,4013104,14,8 ,27,,6",
 "271400,2,2,1998/10/01 09:50:00,139.4688739246,35.4900973629,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:50:00,139.7704840902,35.6908039637,1,10,3033223,10,1 ,38,,12",
 "391000,2,4,1998/10/01 09:50:00,139.7518346128,35.6764939168,1,7,3110313,8 ,1 ,33,,1",
 "418600,2,4,1998/10/01 09:50:00,139.6930765412,35.7158595193,1,11,3123503,8 ,1 ,23,,12",
 "466900,2,2,1998/10/01 09:50:00,139.3189817778,35.8505980417,2,5,3310206,4 ,1 ,42,,1",
 "453100,2,2,1998/10/01 09:50:00,139.7999768439,35.8780061038,2,11,3224113,14,4 ,40,,6",
 "487600,4,4,1998/10/01 09:50:00,139.6223987708,35.9141953842,1,10,3512006,6 ,9 ,36,,6",
 "581900,2,3,1998/10/01 09:50:00,139.7358033244,35.7317258616,1,9,0321101,5 ,7 ,97,,12",
 "611800,2,3,1998/10/01 09:50:00,139.3004711221,35.3895954709,1,4,0431110,13,2 ,65,,12",
 "690000,2,2,1998/10/01 09:50:00,139.572507042,35.6670381122,2,1,0710226,12,7 ,18,,2",
 "690000,3,3,1998/10/01 09:50:00,139.572507042,35.6670381122,2,1,0710226,12,97,18,,97",
 "786600,3,5,1998/10/01 09:50:00,139.2953104193,35.6578340921,2,3,0930110,13,2 ,35,,9",
 "795800,2,4,1998/10/01 09:51:00,139.7491241238,35.6476327672,1,6,1412027,9 ,1 ,47,,1",
 "117300,3,3,1998/10/01 09:51:00,139.8362830646,34.9502096087,1,8,4730209,3 ,3 ,38,,6",
 "154100,2,2,1998/10/01 09:51:00,140.1457366983,35.6420399269,2,10,4040012,14,7 ,41,,6",
 "147200,2,3,1998/10/01 09:51:00,140.0959288536,35.6492173777,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 09:51:00,139.7602136885,35.8191642677,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,8,1998/10/01 09:51:00,139.9419846606,35.7983043485,2,14,4116004,5 ,3 ,25,,10",
 "163300,2,3,1998/10/01 09:51:00,140.0237317472,35.6604026763,2,11,4061110,4 ,5 ,30,,12",
 "193200,2,4,1998/10/01 09:51:00,139.7682218921,35.7012026819,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:51:00,139.7493261542,35.6116199075,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:51:00,139.5743117384,35.628322579,2,8,2920301,4 ,1 ,33,,12",
 "131100,2,2,1998/10/01 09:51:00,140.3940029543,35.539836552,2,15,4910117,15,7 ,38,,9",
 "142600,2,2,1998/10/01 09:51:00,140.1278630054,35.624955506,2,14,4013104,14,8 ,27,,6",
 "271400,2,2,1998/10/01 09:51:00,139.4702605476,35.4906831644,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:51:00,139.7687401102,35.6865573882,1,10,3033223,10,1 ,38,,12",
 "391000,2,4,1998/10/01 09:51:00,139.7517153296,35.676024959,1,7,3110313,8 ,1 ,33,,1",
 "418600,2,4,1998/10/01 09:51:00,139.6986304413,35.7162475295,1,11,3123503,8 ,1 ,23,,12",
 "466900,2,2,1998/10/01 09:51:00,139.3187984147,35.8511780081,2,5,3310206,4 ,1 ,42,,1",
 "466900,2,3,1998/10/01 09:51:00,139.3187984147,35.8511780081,2,5,3310206,4 ,1 ,42,,12",
 "453100,2,2,1998/10/01 09:51:00,139.7979771698,35.878398397,2,11,3224113,14,4 ,40,,6",
 "487600,4,4,1998/10/01 09:51:00,139.6236949854,35.9129094003,1,10,3512006,6 ,9 ,36,,6",
 "581900,2,3,1998/10/01 09:51:00,139.7332944557,35.7309467151,1,9,0321101,5 ,7 ,97,,12",
 "611800,2,3,1998/10/01 09:51:00,139.2948929539,35.3873548645,1,4,0431110,13,2 ,65,,12",
 "786600,3,5,1998/10/01 09:51:00,139.2955435741,35.6580232346,2,3,0930110,13,2 ,35,,9",
 "855600,2,2,1998/10/01 09:51:00,139.5406574928,35.5499302713,2,14,1341201,15,6 ,47,,6",
 "795800,2,4,1998/10/01 09:52:00,139.7500288217,35.6479685763,1,6,1412027,9 ,1 ,47,,1",
 "117300,3,3,1998/10/01 09:52:00,139.8346763909,34.9483441394,1,8,4730209,3 ,3 ,38,,6",
 "154100,2,2,1998/10/01 09:52:00,140.1457364957,35.64203973,2,10,4040012,14,7 ,41,,6",
 "147200,2,3,1998/10/01 09:52:00,140.0996279518,35.648119452,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 09:52:00,139.7601002398,35.8166992337,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,8,1998/10/01 09:52:00,139.9413890958,35.7971261658,2,14,4116004,5 ,3 ,25,,10",
 "163300,2,3,1998/10/01 09:52:00,140.0193553036,35.6633593223,2,11,4061110,4 ,5 ,30,,12",
 "193200,2,4,1998/10/01 09:52:00,139.7655949193,35.6966949093,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:52:00,139.7475500439,35.6117242325,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:52:00,139.5773490034,35.6322528466,2,8,2920301,4 ,1 ,33,,12",
 "131100,2,2,1998/10/01 09:52:00,140.3956631957,35.5407651891,2,15,4910117,15,7 ,38,,9",
 "142600,2,2,1998/10/01 09:52:00,140.1256297557,35.626373674,2,14,4013104,14,8 ,27,,6",
 "271400,2,2,1998/10/01 09:52:00,139.4711524035,35.491731623,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:52:00,139.76708209,35.6823437797,1,10,3033223,10,1 ,38,,12",
 "391000,2,4,1998/10/01 09:52:00,139.7515960478,35.675556001,1,7,3110313,8 ,1 ,33,,1",
 "418600,2,4,1998/10/01 09:52:00,139.7042079693,35.7157536275,1,11,3123503,8 ,1 ,23,,12",
 "466900,2,3,1998/10/01 09:52:00,139.3247590561,35.847989766,2,5,3310206,4 ,1 ,42,,12",
 "453100,2,2,1998/10/01 09:52:00,139.796061219,35.8788008053,2,11,3224113,14,4 ,40,,6",
 "487600,4,4,1998/10/01 09:52:00,139.6237057145,35.91105744,1,10,3512006,6 ,9 ,36,,6",
 "581900,2,3,1998/10/01 09:52:00,139.730640044,35.7311402348,1,9,0321101,5 ,7 ,97,,12",
 "611800,2,3,1998/10/01 09:52:00,139.289200795,35.3853006253,1,4,0431110,13,2 ,65,,12",
 "786600,3,5,1998/10/01 09:52:00,139.2957381297,35.6582389656,2,3,0930110,13,2 ,35,,9",
 "855600,2,2,1998/10/01 09:52:00,139.5419813576,35.5513108604,2,14,1341201,15,6 ,47,,6",
 "795800,2,4,1998/10/01 09:53:00,139.7509404566,35.6482927294,1,6,1412027,9 ,1 ,47,,1",
 "117300,3,3,1998/10/01 09:53:00,139.836037279,34.9463506047,1,8,4730209,3 ,3 ,38,,6",
 "154100,2,2,1998/10/01 09:53:00,140.1457362932,35.6420395331,2,10,4040012,14,7 ,41,,6",
 "147200,2,3,1998/10/01 09:53:00,140.1020547528,35.6459061387,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 09:53:00,139.7597897484,35.814244361,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,8,1998/10/01 09:53:00,139.9413039291,35.7959482772,2,14,4116004,5 ,3 ,25,,10",
 "163300,2,3,1998/10/01 09:53:00,140.0148947012,35.6662283037,2,11,4061110,4 ,5 ,30,,12",
 "193200,2,4,1998/10/01 09:53:00,139.7633855127,35.694403027,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:53:00,139.7472939979,35.6131395418,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:53:00,139.5818821558,35.6350311115,2,8,2920301,4 ,1 ,33,,12",
 "131100,2,2,1998/10/01 09:53:00,140.3973234752,35.5416938029,2,15,4910117,15,7 ,38,,9",
 "142600,2,2,1998/10/01 09:53:00,140.1239434159,35.6282809134,2,14,4013104,14,8 ,27,,6",
 "271400,2,2,1998/10/01 09:53:00,139.4717354823,35.4929977306,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:53:00,139.7638067609,35.6793548208,1,10,3033223,10,1 ,38,,12",
 "391000,2,4,1998/10/01 09:53:00,139.7514767674,35.6750870429,1,7,3110313,8 ,1 ,33,,1",
 "418600,2,4,1998/10/01 09:53:00,139.7054634606,35.713103586,1,11,3123503,8 ,1 ,23,,12",
 "466900,2,3,1998/10/01 09:53:00,139.3298136734,35.8438259855,2,5,3310206,4 ,1 ,42,,12",
 "453100,2,2,1998/10/01 09:53:00,139.7948845079,35.8794892885,2,11,3224113,14,4 ,40,,6",
 "487600,4,4,1998/10/01 09:53:00,139.6243926648,35.9092817831,1,10,3512006,6 ,9 ,36,,6",
 "581900,2,3,1998/10/01 09:53:00,139.7281009668,35.7317863102,1,9,0321101,5 ,7 ,97,,12",
 "581900,2,4,1998/10/01 09:53:00,139.7281009668,35.7317863102,1,9,0321101,5 ,7 ,97,,1",
 "611800,2,3,1998/10/01 09:53:00,139.2835089277,35.3832461199,1,4,0431110,13,2 ,65,,12",
 "786600,3,5,1998/10/01 09:53:00,139.2960004044,35.6582571141,2,3,0930110,13,2 ,35,,9",
 "855600,2,2,1998/10/01 09:53:00,139.5428142069,35.5523852379,2,14,1341201,15,6 ,47,,6",
 "795800,2,4,1998/10/01 09:54:00,139.7518520989,35.6486168756,1,6,1412027,9 ,1 ,47,,1",
 "117300,3,3,1998/10/01 09:54:00,139.8367107326,34.9441081965,1,8,4730209,3 ,3 ,38,,6",
 "154100,2,2,1998/10/01 09:54:00,140.1457360907,35.6420393362,2,10,4040012,14,7 ,41,,6",
 "147200,2,3,1998/10/01 09:54:00,140.1047675981,35.6442709426,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 09:54:00,139.7593338808,35.8118051525,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,8,1998/10/01 09:54:00,139.940483221,35.794743381,2,14,4116004,5 ,3 ,25,,10",
 "163300,2,3,1998/10/01 09:54:00,140.0102791483,35.6689321984,2,11,4061110,4 ,5 ,30,,12",
 "193200,2,4,1998/10/01 09:54:00,139.7577332737,35.6960011381,2,5,5412112,9 ,1 ,35,,12",
 "230000,2,3,1998/10/01 09:54:00,139.7466005381,35.6142803478,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:54:00,139.5872491107,35.6366119759,2,8,2920301,4 ,1 ,33,,12",
 "131100,2,2,1998/10/01 09:54:00,140.3985581987,35.5428965786,2,15,4910117,15,7 ,38,,9",
 "142600,2,2,1998/10/01 09:54:00,140.1223199646,35.6308322307,2,14,4013104,14,8 ,27,,6",
 "271400,2,2,1998/10/01 09:54:00,139.4723154841,35.4942647526,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:54:00,139.7623562947,35.6750348726,1,10,3033223,10,1 ,38,,12",
 "391000,2,4,1998/10/01 09:54:00,139.7516738871,35.6746899751,1,7,3110313,8 ,1 ,33,,1",
 "418600,2,4,1998/10/01 09:54:00,139.7106173359,35.7113930885,1,11,3123503,8 ,1 ,23,,12",
 "466900,2,3,1998/10/01 09:54:00,139.3369403435,35.8427866089,2,5,3310206,4 ,1 ,42,,12",
 "453100,2,2,1998/10/01 09:54:00,139.7940213577,35.8805167957,2,11,3224113,14,4 ,40,,6",
 "487600,4,4,1998/10/01 09:54:00,139.6250144124,35.9074908308,1,10,3512006,6 ,9 ,36,,6",
 "581900,2,4,1998/10/01 09:54:00,139.7304243643,35.7292876907,1,9,0321101,5 ,7 ,97,,1",
 "611800,2,3,1998/10/01 09:54:00,139.2779399417,35.3809785141,1,4,0431110,13,2 ,65,,12",
 "786600,3,5,1998/10/01 09:54:00,139.2963128292,35.6582035618,2,3,0930110,13,2 ,35,,9",
 "855600,2,2,1998/10/01 09:54:00,139.5433283144,35.5535902015,2,14,1341201,15,6 ,47,,6",
 "117300,3,3,1998/10/01 09:55:00,139.837852324,34.9420131123,1,8,4730209,3 ,3 ,38,,6",
 "154100,2,2,1998/10/01 09:55:00,140.1457358882,35.6420391392,2,10,4040012,14,7 ,41,,6",
 "147200,2,3,1998/10/01 09:55:00,140.1081067434,35.6439748052,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 09:55:00,139.7589849347,35.8093552044,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,8,1998/10/01 09:55:00,139.9396766158,35.7935317681,2,14,4116004,5 ,3 ,25,,10",
 "163300,2,3,1998/10/01 09:55:00,140.0057038527,35.6716770125,2,11,4061110,4 ,5 ,30,,12",
 "193200,2,4,1998/10/01 09:55:00,139.7514614375,35.6954854083,2,5,5412112,9 ,1 ,35,,12",
 "193200,2,5,1998/10/01 09:55:00,139.7514614375,35.6954854083,2,5,5412112,9 ,1 ,35,,1",
 "230000,2,3,1998/10/01 09:55:00,139.7464559325,35.6158459549,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:55:00,139.5926134111,35.6382013812,2,8,2920301,4 ,1 ,33,,12",
 "131100,2,2,1998/10/01 09:55:00,140.3992751439,35.544432938,2,15,4910117,15,7 ,38,,9",
 "142600,2,2,1998/10/01 09:55:00,140.1199906579,35.632358868,2,14,4013104,14,8 ,27,,6",
 "271400,2,2,1998/10/01 09:55:00,139.4729531293,35.4955125834,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:55:00,139.7602116481,35.6709338577,1,10,3033223,10,1 ,38,,12",
 "391000,2,4,1998/10/01 09:55:00,139.7520220612,35.6743051538,1,7,3110313,8 ,1 ,33,,1",
 "418600,2,4,1998/10/01 09:55:00,139.7157050543,35.7095761603,1,11,3123503,8 ,1 ,23,,12",
 "466900,2,3,1998/10/01 09:55:00,139.343853191,35.8411488477,2,5,3310206,4 ,1 ,42,,12",
 "453100,2,2,1998/10/01 09:55:00,139.7936879485,35.8820940415,2,11,3224113,14,4 ,40,,6",
 "487600,4,4,1998/10/01 09:55:00,139.6242616462,35.906171475,1,10,3512006,6 ,9 ,36,,6",
 "487600,5,5,1998/10/01 09:55:00,139.6242616462,35.906171475,1,10,3512006,6 ,97,36,,97",
 "581900,2,4,1998/10/01 09:55:00,139.7308240602,35.7263943983,1,9,0321101,5 ,7 ,97,,1",
 "581900,3,5,1998/10/01 09:55:00,139.7308240602,35.7263943983,1,9,0321101,5 ,97,97,,97",
 "611800,2,3,1998/10/01 09:55:00,139.2741946685,35.3770109105,1,4,0431110,13,2 ,65,,12",
 "795800,2,4,1998/10/01 09:55:00,139.7527637485,35.6489410149,1,6,1412027,9 ,1 ,47,,1",
 "786600,3,5,1998/10/01 09:55:00,139.2966157993,35.6582913916,2,3,0930110,13,2 ,35,,9",
 "855600,2,2,1998/10/01 09:55:00,139.544449502,35.5547328057,2,14,1341201,15,6 ,47,,6",
 "795800,2,4,1998/10/01 09:56:00,139.7536760408,35.6489503835,1,6,1412027,9 ,1 ,47,,1",
 "117300,3,3,1998/10/01 09:56:00,139.8402234999,34.9410238954,1,8,4730209,3 ,3 ,38,,6",
 "154100,2,2,1998/10/01 09:56:00,140.1457356856,35.6420389423,2,10,4040012,14,7 ,41,,6",
 "147200,2,3,1998/10/01 09:56:00,140.1112691204,35.644319104,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 09:56:00,139.7594492746,35.8069192214,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,8,1998/10/01 09:56:00,139.9389209105,35.7922988404,2,14,4116004,5 ,3 ,25,,10",
 "163300,2,3,1998/10/01 09:56:00,140.0025310757,35.6754413601,2,11,4061110,4 ,5 ,30,,12",
 "193200,2,5,1998/10/01 09:56:00,139.7526462015,35.6946620436,2,5,5412112,9 ,1 ,35,,1",
 "230000,2,3,1998/10/01 09:56:00,139.7456089984,35.6169202551,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:56:00,139.5979781546,35.639789802,2,8,2920301,4 ,1 ,33,,12",
 "131100,2,2,1998/10/01 09:56:00,140.3999921168,35.5459692924,2,15,4910117,15,7 ,38,,9",
 "142600,2,2,1998/10/01 09:56:00,140.1201678562,35.6349922521,2,14,4013104,14,8 ,27,,6",
 "142600,3,3,1998/10/01 09:56:00,140.1201678562,35.6349922521,2,14,4013104,14,97,27,,97",
 "271400,2,2,1998/10/01 09:56:00,139.4735980459,35.4967583482,1,7,2811315,9 ,11,37,,8",
 "365700,2,3,1998/10/01 09:56:00,139.7592674328,35.6665720982,1,10,3033223,10,1 ,38,,12",
 "365700,2,4,1998/10/01 09:56:00,139.7592674328,35.6665720982,1,10,3033223,10,1 ,38,,1",
 "391000,2,4,1998/10/01 09:56:00,139.7523596021,35.6739133144,1,7,3110313,8 ,1 ,33,,1",
 "418600,2,4,1998/10/01 09:56:00,139.7193677708,35.7062782183,1,11,3123503,8 ,1 ,23,,12",
 "466900,2,3,1998/10/01 09:56:00,139.3504248426,35.8386572723,2,5,3310206,4 ,1 ,42,,12",
 "453100,2,2,1998/10/01 09:56:00,139.7924601226,35.8827752873,2,11,3224113,14,4 ,40,,6",
 "611800,2,3,1998/10/01 09:56:00,139.2710389755,35.3727399944,1,4,0431110,13,2 ,65,,12",
 "611800,2,4,1998/10/01 09:56:00,139.2710389755,35.3727399944,1,4,0431110,13,2 ,65,,1",
 "786600,3,5,1998/10/01 09:56:00,139.2968957775,35.6584322391,2,3,0930110,13,2 ,35,,9",
 "855600,2,2,1998/10/01 09:56:00,139.545583817,35.5557995717,2,14,1341201,15,6 ,47,,6",
 "795800,2,4,1998/10/01 09:57:00,139.7545889976,35.6486287044,1,6,1412027,9 ,1 ,47,,1",
 "117300,3,3,1998/10/01 09:57:00,139.8422965242,34.9409930439,1,8,4730209,3 ,3 ,38,,6",
 "154100,2,2,1998/10/01 09:57:00,140.1457354831,35.6420387454,2,10,4040012,14,7 ,41,,6",
 "147200,2,3,1998/10/01 09:57:00,140.1126339778,35.6413981176,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 09:57:00,139.7599075882,35.8044838448,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,8,1998/10/01 09:57:00,139.9386929764,35.7910048631,2,14,4116004,5 ,3 ,25,,10",
 "13800,4,9,1998/10/01 09:57:00,139.9386929764,35.7910048631,2,14,4116004,5 ,3 ,25,,12",
 "163300,2,3,1998/10/01 09:57:00,139.9999637634,35.6795041898,2,11,4061110,4 ,5 ,30,,12",
 "193200,2,5,1998/10/01 09:57:00,139.7538309413,35.6938386671,2,5,5412112,9 ,1 ,35,,1",
 "230000,2,3,1998/10/01 09:57:00,139.7451382988,35.6186133238,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:57:00,139.6033438583,35.6413764535,2,8,2920301,4 ,1 ,33,,12",
 "131100,2,2,1998/10/01 09:57:00,140.3994877735,35.5471051982,2,15,4910117,15,7 ,38,,9",
 "271400,2,2,1998/10/01 09:57:00,139.4742448064,35.4979752495,1,7,2811315,9 ,11,37,,8",
 "365700,2,4,1998/10/01 09:57:00,139.7588983244,35.6667696478,1,10,3033223,10,1 ,38,,1",
 "391000,2,4,1998/10/01 09:57:00,139.7526971397,35.673521474,1,7,3110313,8 ,1 ,33,,1",
 "418600,2,4,1998/10/01 09:57:00,139.7248625332,35.7054299669,1,11,3123503,8 ,1 ,23,,12",
 "466900,2,3,1998/10/01 09:57:00,139.3575180165,35.8377411675,2,5,3310206,4 ,1 ,42,,12",
 "453100,2,2,1998/10/01 09:57:00,139.7912352055,35.8835844948,2,11,3224113,14,4 ,40,,6",
 "611800,2,4,1998/10/01 09:57:00,139.2720820475,35.3724761917,1,4,0431110,13,2 ,65,,1",
 "786600,3,5,1998/10/01 09:57:00,139.2971611004,35.6585914704,2,3,0930110,13,2 ,35,,9",
 "855600,2,2,1998/10/01 09:57:00,139.544353276,35.5572372584,2,14,1341201,15,6 ,47,,6",
 "795800,2,4,1998/10/01 09:58:00,139.7549390563,35.6482838678,1,6,1412027,9 ,1 ,47,,1",
 "795800,3,5,1998/10/01 09:58:00,139.7549390563,35.6482838678,1,6,1412027,9 ,97,47,,97",
 "117300,3,3,1998/10/01 09:58:00,139.8447443251,34.9421428155,1,8,4730209,3 ,3 ,38,,6",
 "154100,2,2,1998/10/01 09:58:00,140.1457352806,35.6420385485,2,10,4040012,14,7 ,41,,6",
 "154100,2,3,1998/10/01 09:58:00,140.1457352806,35.6420385485,2,10,4040012,14,7 ,41,,1",
 "147200,2,3,1998/10/01 09:58:00,140.1153636449,35.6403255549,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 09:58:00,139.7605663083,35.8020749244,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,9,1998/10/01 09:58:00,139.9402661417,35.7843750106,2,14,4116004,5 ,3 ,25,,12",
 "163300,2,3,1998/10/01 09:58:00,139.9951297036,35.6819402656,2,11,4061110,4 ,5 ,30,,12",
 "193200,2,5,1998/10/01 09:58:00,139.7550156566,35.6930152788,2,5,5412112,9 ,1 ,35,,1",
 "230000,2,3,1998/10/01 09:58:00,139.743659675,35.6197578539,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:58:00,139.6087070027,35.6429691242,2,8,2920301,4 ,1 ,33,,12",
 "131100,2,2,1998/10/01 09:58:00,140.3976432263,35.5477618859,2,15,4910117,15,7 ,38,,9",
 "271400,2,2,1998/10/01 09:58:00,139.4755182608,35.4982387239,1,7,2811315,9 ,11,37,,8",
 "365700,2,4,1998/10/01 09:58:00,139.7587600851,35.6674095182,1,10,3033223,10,1 ,38,,1",
 "391000,2,4,1998/10/01 09:58:00,139.7530346741,35.6731296326,1,7,3110313,8 ,1 ,33,,1",
 "418600,2,4,1998/10/01 09:58:00,139.7303271199,35.7047566492,1,11,3123503,8 ,1 ,23,,12",
 "466900,2,3,1998/10/01 09:58:00,139.3647598767,35.8378344078,2,5,3310206,4 ,1 ,42,,12",
 "453100,2,2,1998/10/01 09:58:00,139.7900053743,35.8848576853,2,11,3224113,14,4 ,40,,6",
 "611800,2,4,1998/10/01 09:58:00,139.2731251128,35.37221238,1,4,0431110,13,2 ,65,,1",
 "786600,3,5,1998/10/01 09:58:00,139.2974264244,35.6587507011,2,3,0930110,13,2 ,35,,9",
 "855600,2,2,1998/10/01 09:58:00,139.5431279399,35.5586779174,2,14,1341201,15,6 ,47,,6",
 "117300,3,3,1998/10/01 09:59:00,139.8473568849,34.9422585933,1,8,4730209,3 ,3 ,38,,6",
 "154100,2,3,1998/10/01 09:59:00,140.1401828221,35.6291246414,2,10,4040012,14,7 ,41,,1",
 "147200,2,3,1998/10/01 09:59:00,140.1184140269,35.6380618598,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 09:59:00,139.7612373868,35.7996682407,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,9,1998/10/01 09:59:00,139.9419274488,35.7777023274,2,14,4116004,5 ,3 ,25,,12",
 "163300,2,3,1998/10/01 09:59:00,139.9902336633,35.6842943053,2,11,4061110,4 ,5 ,30,,12",
 "193200,2,5,1998/10/01 09:59:00,139.7558380282,35.691973175,2,5,5412112,9 ,1 ,35,,1",
 "230000,2,3,1998/10/01 09:59:00,139.742161283,35.6209839215,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 09:59:00,139.614082171,35.6445346977,2,8,2920301,4 ,1 ,33,,12",
 "131100,2,2,1998/10/01 09:59:00,140.3969663026,35.5487243671,2,15,4910117,15,7 ,38,,9",
 "271400,2,2,1998/10/01 09:59:00,139.4771710462,35.4981804215,1,7,2811315,9 ,11,37,,8",
 "365700,2,4,1998/10/01 09:59:00,139.7586403028,35.6676259268,1,10,3033223,10,1 ,38,,1",
 "391000,2,4,1998/10/01 09:59:00,139.7533722051,35.6727377903,1,7,3110313,8 ,1 ,33,,1",
 "418600,2,4,1998/10/01 09:59:00,139.7351815451,35.7030066099,1,11,3123503,8 ,1 ,23,,12",
 "466900,2,3,1998/10/01 09:59:00,139.3716579054,35.8395918004,2,5,3310206,4 ,1 ,42,,12",
 "453100,2,2,1998/10/01 09:59:00,139.7888955102,35.8862604781,2,11,3224113,14,4 ,40,,6",
 "611800,2,4,1998/10/01 09:59:00,139.2741714214,35.3719620538,1,4,0431110,13,2 ,65,,1",
 "786600,3,5,1998/10/01 09:59:00,139.2977180271,35.6588735347,2,3,0930110,13,2 ,35,,9",
 "855600,2,2,1998/10/01 09:59:00,139.5419535376,35.5601466235,2,14,1341201,15,6 ,47,,6",
 "117300,3,3,1998/10/01 10:00:00,139.8490491398,34.9431297218,1,8,4730209,3 ,3 ,38,,6",
 "117300,4,4,1998/10/01 10:00:00,139.8490491398,34.9431297218,1,8,4730209,3 ,97,38,,97",
 "147200,2,3,1998/10/01 10:00:00,140.1213532247,35.6356969527,2,9,4022001,15,7 ,38,,6",
 "154100,2,3,1998/10/01 10:00:00,140.1278868732,35.6194632363,2,10,4040012,14,7 ,41,,1",
 "280600,7,7,1998/10/01 10:00:00,139.7617347744,35.7972434163,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,9,1998/10/01 10:00:00,139.943718173,35.7710533976,2,14,4116004,5 ,3 ,25,,12",
 "163300,2,3,1998/10/01 10:00:00,139.9848785552,35.6858143072,2,11,4061110,4 ,5 ,30,,12",
 "193200,2,5,1998/10/01 10:00:00,139.7561912618,35.6908879755,2,5,5412112,9 ,1 ,35,,1",
 "193200,3,6,1998/10/01 10:00:00,139.7561912618,35.6908879755,2,5,5412112,9 ,97,35,,97",
 "230000,2,3,1998/10/01 10:00:00,139.7408354132,35.622337447,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 10:00:00,139.6194444479,35.6461298569,2,8,2920301,4 ,1 ,33,,12",
 "131100,2,2,1998/10/01 10:00:00,140.3964714504,35.5498352906,2,15,4910117,15,7 ,38,,9",
 "131100,3,3,1998/10/01 10:00:00,140.3964714504,35.5498352906,2,15,4910117,15,97,38,,97",
 "271400,2,2,1998/10/01 10:00:00,139.4788249467,35.4981483864,1,7,2811315,9 ,11,37,,8",
 "365700,2,4,1998/10/01 10:00:00,139.7586971763,35.6677470906,1,10,3033223,10,1 ,38,,1",
 "391000,2,4,1998/10/01 10:00:00,139.7537097328,35.6723459469,1,7,3110313,8 ,1 ,33,,1",
 "391000,3,5,1998/10/01 10:00:00,139.7537097328,35.6723459469,1,7,3110313,8 ,97,33,,97",
 "418600,2,4,1998/10/01 10:00:00,139.7386770783,35.7025755657,1,11,3123503,8 ,1 ,23,,12",
 "466900,2,3,1998/10/01 10:00:00,139.3785950323,35.8412184828,2,5,3310206,4 ,1 ,42,,12",
 "453100,2,2,1998/10/01 10:00:00,139.7889356827,35.8876219291,2,11,3224113,14,4 ,40,,6",
 "453100,3,3,1998/10/01 10:00:00,139.7889356827,35.8876219291,2,11,3224113,14,97,40,,97",
 "611800,2,4,1998/10/01 10:00:00,139.2751873706,35.3716487345,1,4,0431110,13,2 ,65,,1",
 "786600,3,5,1998/10/01 10:00:00,139.2980344003,35.6589439492,2,3,0930110,13,2 ,35,,9",
 "855600,2,2,1998/10/01 10:00:00,139.5412820614,35.5615419327,2,14,1341201,15,6 ,47,,6",
 "89700,9,9,1998/10/01 10:01:00,140.2548535829,35.7487502925,2,8,4422113,3 ,4 ,42,,7",
 "154100,2,3,1998/10/01 10:01:00,140.1133344165,35.6105349396,2,10,4040012,14,7 ,41,,1",
 "154100,3,4,1998/10/01 10:01:00,140.1133344165,35.6105349396,2,10,4040012,14,97,41,,97",
 "147200,2,3,1998/10/01 10:01:00,140.1242805458,35.6333222286,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 10:01:00,139.7618276601,35.7947765831,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,9,1998/10/01 10:01:00,139.9459221803,35.7644861955,2,14,4116004,5 ,3 ,25,,12",
 "23000,2,2,1998/10/01 10:01:00,140.0360445744,35.727435329,2,3,4123006,5 ,7 ,26,,1",
 "101200,2,2,1998/10/01 10:01:00,140.1612381694,35.5054390364,2,12,4512004,14,5 ,29,,2",
 "163300,2,3,1998/10/01 10:01:00,139.9794648616,35.6872252443,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,3,1998/10/01 10:01:00,139.7396324793,35.6237686086,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 10:01:00,139.6247915977,35.6477582311,2,8,2920301,4 ,1 ,33,,12",
 "271400,2,2,1998/10/01 10:01:00,139.4804784267,35.4981030321,1,7,2811315,9 ,11,37,,8",
 "365700,2,4,1998/10/01 10:01:00,139.7589692656,35.6683470054,1,10,3033223,10,1 ,38,,1",
 "418600,2,4,1998/10/01 10:01:00,139.7438002789,35.7026896087,1,11,3123503,8 ,1 ,23,,12",
 "372600,2,2,1998/10/01 10:01:00,139.687764078,35.8720887402,2,11,3038102,14,7 ,37,,1",
 "400200,2,2,1998/10/01 10:01:00,139.5494632831,35.8454148814,2,15,3115006,15,4 ,25,,1",
 "430100,8,8,1998/10/01 10:01:00,139.4162361074,35.8585057612,1,14,3135204,15,4 ,33,,6",
 "466900,2,3,1998/10/01 10:01:00,139.3851932699,35.8418901917,2,5,3310206,4 ,1 ,42,,12",
 "529000,2,2,1998/10/01 10:01:00,139.0805075084,35.9830616289,2,6,3910031,14,7 ,34,,6",
 "540500,2,2,1998/10/01 10:01:00,139.6909411078,35.6374071591,1,6,0112008,5 ,1 ,54,,1",
 "540500,2,3,1998/10/01 10:01:00,139.6909411078,35.6374071591,1,6,0112008,5 ,1 ,54,,12",
 "552000,2,2,1998/10/01 10:01:00,139.74902471,35.5458282412,2,14,0133113,15,4 ,35,,1",
 "611800,2,4,1998/10/01 10:01:00,139.2761923425,35.3712995626,1,4,0431110,13,2 ,65,,1",
 "786600,3,5,1998/10/01 10:01:00,139.2983621666,35.6589218445,2,3,0930110,13,2 ,35,,9",
 "809600,2,2,1998/10/01 10:01:00,139.6091934937,35.4350395138,2,9,1122002,14,4 ,33,,1",
 "855600,2,2,1998/10/01 10:01:00,139.5414600011,35.5628194752,2,14,1341201,15,6 ,47,,6",
 "706100,2,2,1998/10/01 10:01:00,139.5569501907,35.7180099819,1,14,0730209,10,7 ,26,,1",
 "89700,9,9,1998/10/01 10:02:00,140.2542502099,35.7503309062,2,8,4422113,3 ,4 ,42,,7",
 "147200,2,3,1998/10/01 10:02:00,140.1272190087,35.6309582518,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 10:02:00,139.7619521319,35.7923106206,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,9,1998/10/01 10:02:00,139.9490234841,35.7582256677,2,14,4116004,5 ,3 ,25,,12",
 "23000,2,2,1998/10/01 10:02:00,140.0349367157,35.7267078203,2,3,4123006,5 ,7 ,26,,1",
 "23000,2,3,1998/10/01 10:02:00,140.0349367157,35.7267078203,2,3,4123006,5 ,7 ,26,,12",
 "101200,2,2,1998/10/01 10:02:00,140.1612378559,35.505439286,2,12,4512004,14,5 ,29,,2",
 "163300,2,3,1998/10/01 10:02:00,139.9740509766,35.688635938,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,3,1998/10/01 10:02:00,139.7382983599,35.6250647175,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 10:02:00,139.6301651823,35.6493281649,2,8,2920301,4 ,1 ,33,,12",
 "271400,2,2,1998/10/01 10:02:00,139.482132017,35.4980604035,1,7,2811315,9 ,11,37,,8",
 "365700,2,4,1998/10/01 10:02:00,139.7592860723,35.6689419026,1,10,3033223,10,1 ,38,,1",
 "372600,2,2,1998/10/01 10:02:00,139.6892777493,35.8720383903,2,11,3038102,14,7 ,37,,1",
 "418600,2,4,1998/10/01 10:02:00,139.7482257916,35.7002031619,1,11,3123503,8 ,1 ,23,,12",
 "400200,2,2,1998/10/01 10:02:00,139.5500114389,35.8454882124,2,15,3115006,15,4 ,25,,1",
 "430100,8,8,1998/10/01 10:02:00,139.4146610447,35.8590100654,1,14,3135204,15,4 ,33,,6",
 "466900,2,3,1998/10/01 10:02:00,139.3908126705,35.8435051791,2,5,3310206,4 ,1 ,42,,12",
 "529000,2,2,1998/10/01 10:02:00,139.0807290096,35.982774208,2,6,3910031,14,7 ,34,,6",
 "540500,2,3,1998/10/01 10:02:00,139.6925262216,35.6393357797,1,6,0112008,5 ,1 ,54,,12",
 "552000,2,2,1998/10/01 10:02:00,139.7484337863,35.5455934247,2,14,0133113,15,4 ,35,,1",
 "611800,2,4,1998/10/01 10:02:00,139.2771973059,35.3709503823,1,4,0431110,13,2 ,65,,1",
 "786600,3,5,1998/10/01 10:02:00,139.2986863937,35.6589436016,2,3,0930110,13,2 ,35,,9",
 "809600,2,2,1998/10/01 10:02:00,139.6104448203,35.4355467822,2,9,1122002,14,4 ,33,,1",
 "855600,2,2,1998/10/01 10:02:00,139.5403067198,35.5642992518,2,14,1341201,15,6 ,47,,6",
 "706100,2,2,1998/10/01 10:02:00,139.5569503643,35.7180095529,1,14,0730209,10,7 ,26,,1",
 "89700,9,9,1998/10/01 10:03:00,140.2540174217,35.7519529987,2,8,4422113,3 ,4 ,42,,7",
 "147200,2,3,1998/10/01 10:03:00,140.1306284457,35.6290792955,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 10:03:00,139.7622346974,35.7898572611,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,9,1998/10/01 10:03:00,139.9535306993,35.7524954824,2,14,4116004,5 ,3 ,25,,12",
 "23000,2,3,1998/10/01 10:03:00,140.0379310582,35.7246651356,2,3,4123006,5 ,7 ,26,,12",
 "101200,2,2,1998/10/01 10:03:00,140.1612375424,35.5054395357,2,12,4512004,14,5 ,29,,2",
 "163300,2,3,1998/10/01 10:03:00,139.9686369003,35.6900463883,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,3,1998/10/01 10:03:00,139.7385066848,35.6267925093,1,8,2410601,7 ,3 ,15,,10",
 "296700,2,3,1998/10/01 10:03:00,139.6355471474,35.650879266,2,8,2920301,4 ,1 ,33,,12",
 "271400,2,2,1998/10/01 10:03:00,139.4831589774,35.4990884376,1,7,2811315,9 ,11,37,,8",
 "365700,2,4,1998/10/01 10:03:00,139.759598024,35.6695395991,1,10,3033223,10,1 ,38,,1",
 "372600,2,2,1998/10/01 10:03:00,139.6907438848,35.8721932791,2,11,3038102,14,7 ,37,,1",
 "418600,2,4,1998/10/01 10:03:00,139.7512329756,35.6963718938,1,11,3123503,8 ,1 ,23,,12",
 "400200,2,2,1998/10/01 10:03:00,139.5502078374,35.8455459279,2,15,3115006,15,4 ,25,,1",
 "430100,8,8,1998/10/01 10:03:00,139.4133484045,35.8585919736,1,14,3135204,15,4 ,33,,6",
 "466900,2,3,1998/10/01 10:03:00,139.3974280782,35.8449742081,2,5,3310206,4 ,1 ,42,,12",
 "540500,2,3,1998/10/01 10:03:00,139.6945332652,35.6409550221,1,6,0112008,5 ,1 ,54,,12",
 "529000,2,2,1998/10/01 10:03:00,139.0810676135,35.9842307357,2,6,3910031,14,7 ,34,,6",
 "552000,2,2,1998/10/01 10:03:00,139.7477896732,35.5455096287,2,14,0133113,15,4 ,35,,1",
 "611800,2,4,1998/10/01 10:03:00,139.2782022608,35.3706011935,1,4,0431110,13,2 ,65,,1",
 "786600,3,5,1998/10/01 10:03:00,139.2989800452,35.6590652916,2,3,0930110,13,2 ,35,,9",
 "809600,2,2,1998/10/01 10:03:00,139.6118136341,35.435751537,2,9,1122002,14,4 ,33,,1",
 "855600,2,2,1998/10/01 10:03:00,139.5409177823,35.5654905323,2,14,1341201,15,6 ,47,,6",
 "706100,2,2,1998/10/01 10:03:00,139.5569505379,35.718009124,1,14,0730209,10,7 ,26,,1",
 "89700,9,9,1998/10/01 10:04:00,140.2529781674,35.7517556797,2,8,4422113,3 ,4 ,42,,7",
 "147200,2,3,1998/10/01 10:04:00,140.1342553557,35.6274736654,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 10:04:00,139.7618310029,35.7874188766,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,9,1998/10/01 10:04:00,139.9573647299,35.7464508195,2,14,4116004,5 ,3 ,25,,12",
 "23000,2,3,1998/10/01 10:04:00,140.0408071738,35.7224393739,2,3,4123006,5 ,7 ,26,,12",
 "101200,2,2,1998/10/01 10:04:00,140.1612372289,35.5054397854,2,12,4512004,14,5 ,29,,2",
 "163300,2,3,1998/10/01 10:04:00,139.9632168284,35.6914397288,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,3,1998/10/01 10:04:00,139.7387150186,35.6285203001,1,8,2410601,7 ,3 ,15,,10",
 "230000,2,4,1998/10/01 10:04:00,139.7387150186,35.6285203001,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,3,1998/10/01 10:04:00,139.6409723189,35.6523195739,2,8,2920301,4 ,1 ,33,,12",
 "271400,2,2,1998/10/01 10:04:00,139.4841136342,35.5001933863,1,7,2811315,9 ,11,37,,8",
 "365700,2,4,1998/10/01 10:04:00,139.7599255293,35.6701317887,1,10,3033223,10,1 ,38,,1",
 "418600,2,4,1998/10/01 10:04:00,139.7544340138,35.6928608593,1,11,3123503,8 ,1 ,23,,12",
 "372600,2,2,1998/10/01 10:04:00,139.6921311687,35.8726886909,2,11,3038102,14,7 ,37,,1",
 "400200,2,2,1998/10/01 10:04:00,139.5496644187,35.845506891,2,15,3115006,15,4 ,25,,1",
 "430100,8,8,1998/10/01 10:04:00,139.411708495,35.8580719475,1,14,3135204,15,4 ,33,,6",
 "466900,2,3,1998/10/01 10:04:00,139.4034215119,35.8421334804,2,5,3310206,4 ,1 ,42,,12",
 "529000,2,2,1998/10/01 10:04:00,139.0827901319,35.9861404269,2,6,3910031,14,7 ,34,,6",
 "540500,2,3,1998/10/01 10:04:00,139.6967793799,35.6423757986,1,6,0112008,5 ,1 ,54,,12",
 "552000,2,2,1998/10/01 10:04:00,139.7471464059,35.5454007357,2,14,0133113,15,4 ,35,,1",
 "611800,2,4,1998/10/01 10:04:00,139.2792072071,35.3702519963,1,4,0431110,13,2 ,65,,1",
 "809600,2,2,1998/10/01 10:04:00,139.6131076789,35.4361129518,2,9,1122002,14,4 ,33,,1",
 "786600,3,5,1998/10/01 10:04:00,139.2992736975,35.6591869809,2,3,0930110,13,2 ,35,,9",
 "855600,2,2,1998/10/01 10:04:00,139.5407585788,35.5667649854,2,14,1341201,15,6 ,47,,6",
 "706100,2,2,1998/10/01 10:04:00,139.5569507114,35.718008695,1,14,0730209,10,7 ,26,,1",
 "89700,9,9,1998/10/01 10:05:00,140.2536600447,35.7526485416,2,8,4422113,3 ,4 ,42,,7",
 "147200,2,3,1998/10/01 10:05:00,140.1379443089,35.6259647121,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 10:05:00,139.7613182428,35.7849868119,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,9,1998/10/01 10:05:00,139.9609597057,35.7403053692,2,14,4116004,5 ,3 ,25,,12",
 "23000,2,3,1998/10/01 10:05:00,140.0434290413,35.7200265131,2,3,4123006,5 ,7 ,26,,12",
 "101200,2,2,1998/10/01 10:05:00,140.1612369154,35.505440035,2,12,4512004,14,5 ,29,,2",
 "163300,2,3,1998/10/01 10:05:00,139.9576513193,35.6922932393,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:05:00,139.7381657733,35.6245838,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,3,1998/10/01 10:05:00,139.6464753938,35.6535546296,2,8,2920301,4 ,1 ,33,,12",
 "271400,2,2,1998/10/01 10:05:00,139.4850873186,35.5012872087,1,7,2811315,9 ,11,37,,8",
 "365700,2,4,1998/10/01 10:05:00,139.759343522,35.6705374091,1,10,3033223,10,1 ,38,,1",
 "365700,3,5,1998/10/01 10:05:00,139.759343522,35.6705374091,1,10,3033223,10,97,38,,97",
 "418600,2,4,1998/10/01 10:05:00,139.7587032283,35.6902255216,1,11,3123503,8 ,1 ,23,,12",
 "372600,2,2,1998/10/01 10:05:00,139.69351847,35.8731840866,2,11,3038102,14,7 ,37,,1",
 "372600,3,3,1998/10/01 10:05:00,139.69351847,35.8731840866,2,11,3038102,14,97,37,,97",
 "400200,2,2,1998/10/01 10:05:00,139.5502198056,35.8454975207,2,15,3115006,15,4 ,25,,1",
 "466900,2,3,1998/10/01 10:05:00,139.4073070517,35.8371501531,2,5,3310206,4 ,1 ,42,,12",
 "430100,8,8,1998/10/01 10:05:00,139.4100661758,35.8587001673,1,14,3135204,15,4 ,33,,6",
 "529000,2,2,1998/10/01 10:05:00,139.0839807753,35.9882271768,2,6,3910031,14,7 ,34,,6",
 "552000,2,2,1998/10/01 10:05:00,139.7465678988,35.5453046967,2,14,0133113,15,4 ,35,,1",
 "540500,2,3,1998/10/01 10:05:00,139.698762276,35.6440492658,1,6,0112008,5 ,1 ,54,,12",
 "611800,2,4,1998/10/01 10:05:00,139.2802121448,35.3699027906,1,4,0431110,13,2 ,65,,1",
 "809600,2,2,1998/10/01 10:05:00,139.6131249091,35.4368956701,2,9,1122002,14,4 ,33,,1",
 "809600,3,3,1998/10/01 10:05:00,139.6131249091,35.4368956701,2,9,1122002,14,97,33,,97",
 "786600,3,5,1998/10/01 10:05:00,139.2995673508,35.6593086694,2,3,0930110,13,2 ,35,,9",
 "855600,2,2,1998/10/01 10:05:00,139.5412352832,35.5673687677,2,14,1341201,15,6 ,47,,6",
 "855600,3,3,1998/10/01 10:05:00,139.5412352832,35.5673687677,2,14,1341201,15,97,47,,97",
 "706100,2,2,1998/10/01 10:05:00,139.556950885,35.718008266,1,14,0730209,10,7 ,26,,1",
 "706100,2,3,1998/10/01 10:05:00,139.556950885,35.718008266,1,14,0730209,10,7 ,26,,10",
 "89700,9,9,1998/10/01 10:06:00,140.2544536691,35.7541100016,2,8,4422113,3 ,4 ,42,,7",
 "147200,2,3,1998/10/01 10:06:00,140.1415621133,35.6243548024,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 10:06:00,139.7609886468,35.7825352142,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,9,1998/10/01 10:06:00,139.9645504424,35.7341581252,2,14,4116004,5 ,3 ,25,,12",
 "23000,2,3,1998/10/01 10:06:00,140.0432970309,35.7168920273,2,3,4123006,5 ,7 ,26,,12",
 "101200,2,2,1998/10/01 10:06:00,140.1612366019,35.5054402847,2,12,4512004,14,5 ,29,,2",
 "163300,2,3,1998/10/01 10:06:00,139.9539824405,35.6899252907,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:06:00,139.7376165814,35.6206472949,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,3,1998/10/01 10:06:00,139.6516614505,35.6554621378,2,8,2920301,4 ,1 ,33,,12",
 "271400,2,2,1998/10/01 10:06:00,139.4860610294,35.5023810233,1,7,2811315,9 ,11,37,,8",
 "338100,2,2,1998/10/01 10:06:00,139.5256312433,35.624010485,2,6,2132009,14,6 ,31,,1",
 "418600,2,4,1998/10/01 10:06:00,139.7612579661,35.686425915,1,11,3123503,8 ,1 ,23,,12",
 "400200,2,2,1998/10/01 10:06:00,139.5507751924,35.8454881479,2,15,3115006,15,4 ,25,,1",
 "430100,8,8,1998/10/01 10:06:00,139.408513894,35.8593034596,1,14,3135204,15,4 ,33,,6",
 "466900,2,3,1998/10/01 10:06:00,139.4102109629,35.8318162841,2,5,3310206,4 ,1 ,42,,12",
 "529000,2,2,1998/10/01 10:06:00,139.0841695621,35.9905947017,2,6,3910031,14,7 ,34,,6",
 "540500,2,3,1998/10/01 10:06:00,139.7007380392,35.6457175566,1,6,0112008,5 ,1 ,54,,12",
 "552000,2,2,1998/10/01 10:06:00,139.7460316149,35.5454587935,2,14,0133113,15,4 ,35,,1",
 "611800,2,4,1998/10/01 10:06:00,139.2812170739,35.3695535765,1,4,0431110,13,2 ,65,,1",
 "731400,2,2,1998/10/01 10:06:00,139.4773401454,35.7116495815,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:06:00,139.299886251,35.6593352001,2,3,0930110,13,2 ,35,,9",
 "706100,2,3,1998/10/01 10:06:00,139.5572253953,35.716704324,1,14,0730209,10,7 ,26,,10",
 "89700,9,9,1998/10/01 10:07:00,140.2549005145,35.7555236084,2,8,4422113,3 ,4 ,42,,7",
 "147200,2,3,1998/10/01 10:07:00,140.1446920898,35.6221880191,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 10:07:00,139.7607466048,35.7800754977,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,9,1998/10/01 10:07:00,139.9679775764,35.7279613417,2,14,4116004,5 ,3 ,25,,12",
 "23000,2,3,1998/10/01 10:07:00,140.0421259232,35.7138156388,2,3,4123006,5 ,7 ,26,,12",
 "101200,2,2,1998/10/01 10:07:00,140.1612362884,35.5054405344,2,12,4512004,14,5 ,29,,2",
 "163300,2,3,1998/10/01 10:07:00,139.9496453251,35.6869356222,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:07:00,139.7366344706,35.6167686361,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,3,1998/10/01 10:07:00,139.6570227909,35.6570609386,2,8,2920301,4 ,1 ,33,,12",
 "271400,2,2,1998/10/01 10:07:00,139.487050234,35.5034650726,1,7,2811315,9 ,11,37,,8",
 "338100,2,2,1998/10/01 10:07:00,139.5260379976,35.6237773183,2,6,2132009,14,6 ,31,,1",
 "418600,2,4,1998/10/01 10:07:00,139.7662064203,35.6846483282,1,11,3123503,8 ,1 ,23,,12",
 "400200,2,2,1998/10/01 10:07:00,139.5512059466,35.8452383563,2,15,3115006,15,4 ,25,,1",
 "430100,8,8,1998/10/01 10:07:00,139.4072664099,35.8582722071,1,14,3135204,15,4 ,33,,6",
 "466900,2,3,1998/10/01 10:07:00,139.4116038164,35.8260236605,2,5,3310206,4 ,1 ,42,,12",
 "529000,2,2,1998/10/01 10:07:00,139.0839114719,35.9927439581,2,6,3910031,14,7 ,34,,6",
 "540500,2,3,1998/10/01 10:07:00,139.7024841135,35.6475181218,1,6,0112008,5 ,1 ,54,,12",
 "552000,2,2,1998/10/01 10:07:00,139.7453781288,35.5455139131,2,14,0133113,15,4 ,35,,1",
 "611800,2,4,1998/10/01 10:07:00,139.2822219945,35.3692043539,1,4,0431110,13,2 ,65,,1",
 "731400,2,2,1998/10/01 10:07:00,139.4772138902,35.7113393568,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:07:00,139.300210618,35.659325054,2,3,0930110,13,2 ,35,,9",
 "706100,2,3,1998/10/01 10:07:00,139.5574998966,35.7154003812,1,14,0730209,10,7 ,26,,10",
 "89700,9,9,1998/10/01 10:08:00,140.2544976887,35.7568962423,2,8,4422113,3 ,4 ,42,,7",
 "147200,2,3,1998/10/01 10:08:00,140.1472636541,35.6195538957,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 10:08:00,139.7607233279,35.7776345961,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,9,1998/10/01 10:08:00,139.9678872909,35.7211962271,2,14,4116004,5 ,3 ,25,,12",
 "23000,2,3,1998/10/01 10:08:00,140.0394383175,35.7114407827,2,3,4123006,5 ,7 ,26,,12",
 "101200,2,2,1998/10/01 10:08:00,140.1612359749,35.505440784,2,12,4512004,14,5 ,29,,2",
 "163300,2,3,1998/10/01 10:08:00,139.9448614338,35.6844335301,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:08:00,139.7357501363,35.6128749843,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,3,1998/10/01 10:08:00,139.6624127289,35.6585893342,2,8,2920301,4 ,1 ,33,,12",
 "271400,2,2,1998/10/01 10:08:00,139.4881575267,35.5044700522,1,7,2811315,9 ,11,37,,8",
 "338100,2,2,1998/10/01 10:08:00,139.526394234,35.6237372131,2,6,2132009,14,6 ,31,,1",
 "418600,2,4,1998/10/01 10:08:00,139.7716227814,35.6833656541,1,11,3123503,8 ,1 ,23,,12",
 "400200,2,2,1998/10/01 10:08:00,139.5515836132,35.8449067261,2,15,3115006,15,4 ,25,,1",
 "430100,8,8,1998/10/01 10:08:00,139.4059021332,35.8581320631,1,14,3135204,15,4 ,33,,6",
 "430100,9,9,1998/10/01 10:08:00,139.4059021332,35.8581320631,1,14,3135204,15,97,33,,97",
 "466900,2,3,1998/10/01 10:08:00,139.4129220068,35.8202194722,2,5,3310206,4 ,1 ,42,,12",
 "540500,2,3,1998/10/01 10:08:00,139.7045036269,35.6491408438,1,6,0112008,5 ,1 ,54,,12",
 "529000,2,2,1998/10/01 10:08:00,139.0837454592,35.9948113177,2,6,3910031,14,7 ,34,,6",
 "552000,2,2,1998/10/01 10:08:00,139.7447241953,35.5455663033,2,14,0133113,15,4 ,35,,1",
 "611800,2,4,1998/10/01 10:08:00,139.2832269066,35.3688551229,1,4,0431110,13,2 ,65,,1",
 "731400,2,2,1998/10/01 10:08:00,139.4770876359,35.7110291319,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:08:00,139.3005162356,35.6594252181,2,3,0930110,13,2 ,35,,9",
 "706100,2,3,1998/10/01 10:08:00,139.5577743888,35.7140964374,1,14,0730209,10,7 ,26,,10",
 "89700,9,9,1998/10/01 10:09:00,140.2525969163,35.7574792117,2,8,4422113,3 ,4 ,42,,7",
 "147200,2,3,1998/10/01 10:09:00,140.1502735209,35.617557925,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 10:09:00,139.7613118312,35.7752167822,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,9,1998/10/01 10:09:00,139.9639072763,35.7152224789,2,14,4116004,5 ,3 ,25,,12",
 "23000,2,3,1998/10/01 10:09:00,140.0367149759,35.7091542589,2,3,4123006,5 ,7 ,26,,12",
 "23000,2,4,1998/10/01 10:09:00,140.0367149759,35.7091542589,2,3,4123006,5 ,7 ,26,,1",
 "101200,2,2,1998/10/01 10:09:00,140.1612356614,35.5054410337,2,12,4512004,14,5 ,29,,2",
 "163300,2,3,1998/10/01 10:09:00,139.9401423325,35.6818505589,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:09:00,139.7352525284,35.6089340016,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,3,1998/10/01 10:09:00,139.6670436774,35.6612314786,2,8,2920301,4 ,1 ,33,,12",
 "271400,2,2,1998/10/01 10:09:00,139.4892648668,35.5054752699,1,7,2811315,9 ,11,37,,8",
 "338100,2,2,1998/10/01 10:09:00,139.5266487862,35.6240857316,2,6,2132009,14,6 ,31,,1",
 "418600,2,4,1998/10/01 10:09:00,139.7767122628,35.6813623799,1,11,3123503,8 ,1 ,23,,12",
 "400200,2,2,1998/10/01 10:09:00,139.5519615703,35.8445752801,2,15,3115006,15,4 ,25,,1",
 "466900,2,3,1998/10/01 10:09:00,139.4142784138,35.8144210158,2,5,3310206,4 ,1 ,42,,12",
 "540500,2,3,1998/10/01 10:09:00,139.7057159608,35.6512473022,1,6,0112008,5 ,1 ,54,,12",
 "529000,2,2,1998/10/01 10:09:00,139.0842852315,35.9971302344,2,6,3910031,14,7 ,34,,6",
 "552000,2,2,1998/10/01 10:09:00,139.7448197646,35.5460618079,2,14,0133113,15,4 ,35,,1",
 "611800,2,4,1998/10/01 10:09:00,139.28423181,35.3685058834,1,4,0431110,13,2 ,65,,1",
 "731400,2,2,1998/10/01 10:09:00,139.4769613825,35.7107189069,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:09:00,139.300821854,35.6595253815,2,3,0930110,13,2 ,35,,9",
 "706100,2,3,1998/10/01 10:09:00,139.5577459705,35.7128923085,1,14,0730209,10,7 ,26,,10",
 "89700,9,9,1998/10/01 10:10:00,140.250696116,35.7580621512,2,8,4422113,3 ,4 ,42,,7",
 "89700,10,10,1998/10/01 10:10:00,140.250696116,35.7580621512,2,8,4422113,3 ,97,42,,97",
 "147200,2,3,1998/10/01 10:10:00,140.1543814772,35.6173170812,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 10:10:00,139.7616525577,35.7727644595,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,9,1998/10/01 10:10:00,139.9605016679,35.7090272833,2,14,4116004,5 ,3 ,25,,12",
 "23000,2,4,1998/10/01 10:10:00,140.0392994555,35.7089056317,2,3,4123006,5 ,7 ,26,,1",
 "101200,2,2,1998/10/01 10:10:00,140.1612353479,35.5054412834,2,12,4512004,14,5 ,29,,2",
 "163300,2,3,1998/10/01 10:10:00,139.9359098185,35.6787736696,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:10:00,139.7347599575,35.6049925029,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,3,1998/10/01 10:10:00,139.6713562929,35.6642832677,2,8,2920301,4 ,1 ,33,,12",
 "271400,2,2,1998/10/01 10:10:00,139.4903596083,35.5064897184,1,7,2811315,9 ,11,37,,8",
 "338100,2,2,1998/10/01 10:10:00,139.5269593153,35.62430004,2,6,2132009,14,6 ,31,,1",
 "418600,2,4,1998/10/01 10:10:00,139.7816369043,35.6791256059,1,11,3123503,8 ,1 ,23,,12",
 "400200,2,2,1998/10/01 10:10:00,139.5523435108,35.8442468636,2,15,3115006,15,4 ,25,,1",
 "466900,2,3,1998/10/01 10:10:00,139.4181533614,35.8098276562,2,5,3310206,4 ,1 ,42,,12",
 "540500,2,3,1998/10/01 10:10:00,139.7070123436,35.6533061859,1,6,0112008,5 ,1 ,54,,12",
 "529000,2,2,1998/10/01 10:10:00,139.0847713984,35.9993454984,2,6,3910031,14,7 ,34,,6",
 "529000,3,3,1998/10/01 10:10:00,139.0847713984,35.9993454984,2,6,3910031,14,97,34,,97",
 "552000,2,2,1998/10/01 10:10:00,139.7452342026,35.546369803,2,14,0133113,15,4 ,35,,1",
 "552000,3,3,1998/10/01 10:10:00,139.7452342026,35.546369803,2,14,0133113,15,97,35,,97",
 "611800,2,4,1998/10/01 10:10:00,139.2852367049,35.3681566354,1,4,0431110,13,2 ,65,,1",
 "731400,2,2,1998/10/01 10:10:00,139.4768351301,35.7104086817,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:10:00,139.3010505235,35.6597075578,2,3,0930110,13,2 ,35,,9",
 "706100,2,3,1998/10/01 10:10:00,139.5584106102,35.7116854291,1,14,0730209,10,7 ,26,,10",
 "69000,2,2,1998/10/01 10:11:00,139.9119873774,35.9108112391,1,4,4312114,13,2 ,52,,6",
 "147200,2,3,1998/10/01 10:11:00,140.1584547022,35.6172236225,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 10:11:00,139.7619471779,35.7703095255,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,9,1998/10/01 10:11:00,139.9543266328,35.709462916,2,14,4116004,5 ,3 ,25,,12",
 "23000,2,4,1998/10/01 10:11:00,140.0421957529,35.7083080054,2,3,4123006,5 ,7 ,26,,1",
 "101200,2,2,1998/10/01 10:11:00,140.1612350344,35.505441533,2,12,4512004,14,5 ,29,,2",
 "101200,2,3,1998/10/01 10:11:00,140.1612350344,35.505441533,2,12,4512004,14,5 ,29,,10",
 "163300,2,3,1998/10/01 10:11:00,139.9319680113,35.6754339954,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:11:00,139.7342673991,35.6010510026,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,3,1998/10/01 10:11:00,139.6759212515,35.6670574594,2,8,2920301,4 ,1 ,33,,12",
 "142600,4,4,1998/10/01 10:11:00,140.1179190578,35.6361531571,2,14,4013104,14,4 ,27,,6",
 "271400,2,2,1998/10/01 10:11:00,139.4914572144,35.5075021067,1,7,2811315,9 ,11,37,,8",
 "338100,2,2,1998/10/01 10:11:00,139.5274214727,35.6241507893,2,6,2132009,14,6 ,31,,1",
 "418600,2,4,1998/10/01 10:11:00,139.7863143015,35.6765549901,1,11,3123503,8 ,1 ,23,,12",
 "400200,2,2,1998/10/01 10:11:00,139.5527254481,35.8439184458,2,15,3115006,15,4 ,25,,1",
 "423200,2,2,1998/10/01 10:11:00,139.4909822781,35.8812282118,2,9,3133014,15,4 ,36,,7",
 "466900,2,3,1998/10/01 10:11:00,139.4244221223,35.8068665024,2,5,3310206,4 ,1 ,42,,12",
 "503700,2,2,1998/10/01 10:11:00,139.6790265388,36.0344247997,2,7,3613326,14,7 ,29,,6",
 "540500,2,3,1998/10/01 10:11:00,139.7058547018,35.6553271673,1,6,0112008,5 ,1 ,54,,12",
 "611800,2,4,1998/10/01 10:11:00,139.2862415912,35.367807379,1,4,0431110,13,2 ,65,,1",
 "731400,2,2,1998/10/01 10:11:00,139.4767088787,35.7100984564,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:11:00,139.3012313533,35.6599321986,2,3,0930110,13,2 ,35,,9",
 "706100,2,3,1998/10/01 10:11:00,139.5590752297,35.7104785458,1,14,0730209,10,7 ,26,,10",
 "69000,2,2,1998/10/01 10:12:00,139.9119871419,35.9108108693,1,4,4312114,13,2 ,52,,6",
 "147200,2,3,1998/10/01 10:12:00,140.1625352729,35.6176494786,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 10:12:00,139.7617092131,35.7680632987,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,9,1998/10/01 10:12:00,139.9469620787,35.7080375648,2,14,4116004,5 ,3 ,25,,12",
 "23000,2,4,1998/10/01 10:12:00,140.0451286983,35.7080384597,2,3,4123006,5 ,7 ,26,,1",
 "23000,3,5,1998/10/01 10:12:00,140.0451286983,35.7080384597,2,3,4123006,5 ,97,26,,97",
 "101200,2,3,1998/10/01 10:12:00,140.1589495999,35.5070232787,2,12,4512004,14,5 ,29,,10",
 "163300,2,3,1998/10/01 10:12:00,139.9280840171,35.6720496204,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:12:00,139.7334039683,35.5971711348,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,3,1998/10/01 10:12:00,139.6808271991,35.6693999525,2,8,2920301,4 ,1 ,33,,12",
 "142600,4,4,1998/10/01 10:12:00,140.1172223826,35.6377878904,2,14,4013104,14,4 ,27,,6",
 "271400,2,2,1998/10/01 10:12:00,139.4925418792,35.5085237568,1,7,2811315,9 ,11,37,,8",
 "338100,2,2,1998/10/01 10:12:00,139.5278846093,35.6240037346,2,6,2132009,14,6 ,31,,1",
 "418600,2,4,1998/10/01 10:12:00,139.7910200004,35.6742649053,1,11,3123503,8 ,1 ,23,,12",
 "400200,2,2,1998/10/01 10:12:00,139.5531055557,35.8435886466,2,15,3115006,15,4 ,25,,1",
 "423200,2,2,1998/10/01 10:12:00,139.4910853339,35.8811846292,2,9,3133014,15,4 ,36,,7",
 "466900,2,3,1998/10/01 10:12:00,139.4307240682,35.8039525412,2,5,3310206,4 ,1 ,42,,12",
 "503700,2,2,1998/10/01 10:12:00,139.6776207039,36.0316497787,2,7,3613326,14,7 ,29,,6",
 "540500,2,3,1998/10/01 10:12:00,139.7036533386,35.6567998284,1,6,0112008,5 ,1 ,54,,12",
 "611800,2,4,1998/10/01 10:12:00,139.287246469,35.3674581141,1,4,0431110,13,2 ,65,,1",
 "731400,2,2,1998/10/01 10:12:00,139.4765826283,35.7097882309,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:12:00,139.3014467989,35.6601342424,2,3,0930110,13,2 ,35,,9",
 "706100,2,3,1998/10/01 10:12:00,139.5595659171,35.7092755693,1,14,0730209,10,7 ,26,,10",
 "69000,2,2,1998/10/01 10:13:00,139.9119869063,35.9108104994,1,4,4312114,13,2 ,52,,6",
 "147200,2,3,1998/10/01 10:13:00,140.166644633,35.6179482521,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 10:13:00,139.762610759,35.7657076804,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,9,1998/10/01 10:13:00,139.9414697428,35.7029100846,2,14,4116004,5 ,3 ,25,,12",
 "101200,2,3,1998/10/01 10:13:00,140.1560575741,35.507091433,2,12,4512004,14,5 ,29,,10",
 "163300,2,3,1998/10/01 10:13:00,139.9247410938,35.6683661096,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:13:00,139.7311722149,35.5936537116,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,3,1998/10/01 10:13:00,139.6862369942,35.6697475782,2,8,2920301,4 ,1 ,33,,12",
 "142600,4,4,1998/10/01 10:13:00,140.1181276444,35.6397494333,2,14,4013104,14,4 ,27,,6",
 "271400,2,2,1998/10/01 10:13:00,139.4936177419,35.5095516335,1,7,2811315,9 ,11,37,,8",
 "338100,2,2,1998/10/01 10:13:00,139.5283560307,35.6238752459,2,6,2132009,14,6 ,31,,1",
 "418600,2,4,1998/10/01 10:13:00,139.7958842996,35.6719528586,1,11,3123503,8 ,1 ,23,,12",
 "400200,2,2,1998/10/01 10:13:00,139.553244901,35.8432506624,2,15,3115006,15,4 ,25,,1",
 "423200,2,2,1998/10/01 10:13:00,139.4913267028,35.8831233028,2,9,3133014,15,4 ,36,,7",
 "466900,2,3,1998/10/01 10:13:00,139.4370255559,35.8010382452,2,5,3310206,4 ,1 ,42,,12",
 "503700,2,2,1998/10/01 10:13:00,139.6767256716,36.0287410638,2,7,3613326,14,7 ,29,,6",
 "540500,2,3,1998/10/01 10:13:00,139.7017727956,35.658516117,1,6,0112008,5 ,1 ,54,,12",
 "540500,2,4,1998/10/01 10:13:00,139.7017727956,35.658516117,1,6,0112008,5 ,1 ,54,,1",
 "611800,2,4,1998/10/01 10:13:00,139.2874858975,35.3667030363,1,4,0431110,13,2 ,65,,1",
 "731400,2,2,1998/10/01 10:13:00,139.4764563789,35.7094780053,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:13:00,139.3017185296,35.660281212,2,3,0930110,13,2 ,35,,9",
 "706100,2,3,1998/10/01 10:13:00,139.5588208112,35.7081003783,1,14,0730209,10,7 ,26,,10",
 "69000,2,2,1998/10/01 10:14:00,139.9119866707,35.9108101296,1,4,4312114,13,2 ,52,,6",
 "147200,2,3,1998/10/01 10:14:00,140.1705818714,35.6187247621,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 10:14:00,139.761845654,35.76399491,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,9,1998/10/01 10:14:00,139.935250052,35.6984269345,2,14,4116004,5 ,3 ,25,,12",
 "101200,2,3,1998/10/01 10:14:00,140.1532795348,35.5073754447,2,12,4512004,14,5 ,29,,10",
 "163300,2,3,1998/10/01 10:14:00,139.9224561254,35.664122169,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:14:00,139.7289061218,35.5901506979,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,3,1998/10/01 10:14:00,139.6918390472,35.6688708882,2,8,2920301,4 ,1 ,33,,12",
 "142600,4,4,1998/10/01 10:14:00,140.1173846796,35.6414951941,2,14,4013104,14,4 ,27,,6",
 "271400,2,2,1998/10/01 10:14:00,139.4946879843,35.5105834432,1,7,2811315,9 ,11,37,,8",
 "338100,2,2,1998/10/01 10:14:00,139.5288274505,35.6237467553,2,6,2132009,14,6 ,31,,1",
 "418600,2,4,1998/10/01 10:14:00,139.800903846,35.6698351814,1,11,3123503,8 ,1 ,23,,12",
 "400200,2,2,1998/10/01 10:14:00,139.5528859204,35.8429055075,2,15,3115006,15,4 ,25,,1",
 "423200,2,2,1998/10/01 10:14:00,139.4923872493,35.8850335134,2,9,3133014,15,4 ,36,,7",
 "466900,2,3,1998/10/01 10:14:00,139.4433374615,35.7981392803,2,5,3310206,4 ,1 ,42,,12",
 "503700,2,2,1998/10/01 10:14:00,139.6741291501,36.0263844496,2,7,3613326,14,7 ,29,,6",
 "540500,2,4,1998/10/01 10:14:00,139.702492467,35.6582576689,1,6,0112008,5 ,1 ,54,,1",
 "611800,2,4,1998/10/01 10:14:00,139.2870632268,35.3658918126,1,4,0431110,13,2 ,65,,1",
 "731400,2,2,1998/10/01 10:14:00,139.4763301304,35.7091677796,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:14:00,139.302035452,35.6603543357,2,3,0930110,13,2 ,35,,9",
 "706100,2,3,1998/10/01 10:14:00,139.5588868911,35.7068365554,1,14,0730209,10,7 ,26,,10",
 "69000,2,2,1998/10/01 10:15:00,139.9119864351,35.9108097598,1,4,4312114,13,2 ,52,,6",
 "147200,2,3,1998/10/01 10:15:00,140.1737860535,35.620846384,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 10:15:00,139.7591734611,35.7628338964,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,9,1998/10/01 10:15:00,139.9284918554,35.6944314267,2,14,4116004,5 ,3 ,25,,12",
 "101200,2,3,1998/10/01 10:15:00,140.1506961494,35.5084915951,2,12,4512004,14,5 ,29,,10",
 "163300,2,3,1998/10/01 10:15:00,139.9202466828,35.6598505463,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:15:00,139.7266601392,35.5866391473,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,3,1998/10/01 10:15:00,139.6975118634,35.6692462159,2,8,2920301,4 ,1 ,33,,12",
 "142600,4,4,1998/10/01 10:15:00,140.1173795728,35.6431672519,2,14,4013104,14,4 ,27,,6",
 "271400,2,2,1998/10/01 10:15:00,139.4957677805,35.5116085886,1,7,2811315,9 ,11,37,,8",
 "338100,2,2,1998/10/01 10:15:00,139.529256137,35.6237375594,2,6,2132009,14,6 ,31,,1",
 "418600,2,4,1998/10/01 10:15:00,139.8064974806,35.6694209078,1,11,3123503,8 ,1 ,23,,12",
 "400200,2,2,1998/10/01 10:15:00,139.5525269428,35.8425603516,2,15,3115006,15,4 ,25,,1",
 "423200,2,2,1998/10/01 10:15:00,139.4936533438,35.886759589,2,9,3133014,15,4 ,36,,7",
 "466900,2,3,1998/10/01 10:15:00,139.449624136,35.7952046234,2,5,3310206,4 ,1 ,42,,12",
 "503700,2,2,1998/10/01 10:15:00,139.6738005288,36.0236853634,2,7,3613326,14,7 ,29,,6",
 "540500,2,4,1998/10/01 10:15:00,139.7031810972,35.6579578676,1,6,0112008,5 ,1 ,54,,1",
 "611800,2,4,1998/10/01 10:15:00,139.286644017,35.3650662384,1,4,0431110,13,2 ,65,,1",
 "731400,2,2,1998/10/01 10:15:00,139.4761917937,35.708862134,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:15:00,139.3023409795,35.6603979493,2,3,0930110,13,2 ,35,,9",
 "706100,2,3,1998/10/01 10:15:00,139.5592264641,35.7055428483,1,14,0730209,10,7 ,26,,10",
 "69000,2,2,1998/10/01 10:16:00,139.9119861995,35.9108093899,1,4,4312114,13,2 ,52,,6",
 "147200,2,3,1998/10/01 10:16:00,140.1769698496,35.622989571,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 10:16:00,139.7595019708,35.7609510717,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,9,1998/10/01 10:16:00,139.922580397,35.6896280754,2,14,4116004,5 ,3 ,25,,12",
 "101200,2,3,1998/10/01 10:16:00,140.1482368109,35.5097704325,2,12,4512004,14,5 ,29,,10",
 "163300,2,3,1998/10/01 10:16:00,139.9180374758,35.6555788796,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:16:00,139.7246042498,35.5830584863,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,3,1998/10/01 10:16:00,139.7031454532,35.669447808,2,8,2920301,4 ,1 ,33,,12",
 "142600,4,4,1998/10/01 10:16:00,140.1164172602,35.6445650299,2,14,4013104,14,4 ,27,,6",
 "271400,2,2,1998/10/01 10:16:00,139.496850103,35.5126319485,1,7,2811315,9 ,11,37,,8",
 "338100,2,2,1998/10/01 10:16:00,139.5295684675,35.6240532025,2,6,2132009,14,6 ,31,,1",
 "418600,2,4,1998/10/01 10:16:00,139.8121413357,35.6693380801,1,11,3123503,8 ,1 ,23,,12",
 "400200,2,2,1998/10/01 10:16:00,139.5526276183,35.8422351864,2,15,3115006,15,4 ,25,,1",
 "423200,2,2,1998/10/01 10:16:00,139.4936386382,35.8886766563,2,9,3133014,15,4 ,36,,7",
 "466900,2,3,1998/10/01 10:16:00,139.4543316726,35.7908596067,2,5,3310206,4 ,1 ,42,,12",
 "503700,2,2,1998/10/01 10:16:00,139.674848161,36.020596954,2,7,3613326,14,7 ,29,,6",
 "540500,2,4,1998/10/01 10:16:00,139.7037748726,35.6575437478,1,6,0112008,5 ,1 ,54,,1",
 "611800,2,4,1998/10/01 10:16:00,139.2862248156,35.3642406626,1,4,0431110,13,2 ,65,,1",
 "731400,2,2,1998/10/01 10:16:00,139.4760929395,35.7085480454,2,10,0771111,14,4 ,32,,6",
 "742900,8,8,1998/10/01 10:16:00,139.3456258805,35.7152171898,1,8,0784113,10,3 ,38,,7",
 "786600,3,5,1998/10/01 10:16:00,139.3025667656,35.6602198506,2,3,0930110,13,2 ,35,,9",
 "706100,2,3,1998/10/01 10:16:00,139.5592659083,35.7042804988,1,14,0730209,10,7 ,26,,10",
 "69000,2,2,1998/10/01 10:17:00,139.9119859639,35.9108090201,1,4,4312114,13,2 ,52,,6",
 "147200,2,3,1998/10/01 10:17:00,140.1801215884,35.6231484518,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 10:17:00,139.7615429861,35.7591384616,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,9,1998/10/01 10:17:00,139.9167458903,35.6847594014,2,14,4116004,5 ,3 ,25,,12",
 "101200,2,3,1998/10/01 10:17:00,140.1454754129,35.5107156442,2,12,4512004,14,5 ,29,,10",
 "163300,2,3,1998/10/01 10:17:00,139.9150928871,35.6516718478,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:17:00,139.7230676881,35.5793007411,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,3,1998/10/01 10:17:00,139.7081189773,35.6671652099,2,8,2920301,4 ,1 ,33,,12",
 "142600,4,4,1998/10/01 10:17:00,140.1174557403,35.6465581031,2,14,4013104,14,4 ,27,,6",
 "271400,2,2,1998/10/01 10:17:00,139.4979341159,35.5136541045,1,7,2811315,9 ,11,37,,8",
 "338100,2,2,1998/10/01 10:17:00,139.5298808004,35.6243688447,2,6,2132009,14,6 ,31,,1",
 "418600,2,4,1998/10/01 10:17:00,139.8177068013,35.669628789,1,11,3123503,8 ,1 ,23,,12",
 "418600,2,5,1998/10/01 10:17:00,139.8177068013,35.669628789,1,11,3123503,8 ,1 ,23,,1",
 "400200,2,2,1998/10/01 10:17:00,139.5524905652,35.841902968,2,15,3115006,15,4 ,25,,1",
 "423200,2,2,1998/10/01 10:17:00,139.4952584909,35.8902291486,2,9,3133014,15,4 ,36,,7",
 "466900,2,3,1998/10/01 10:17:00,139.4592906794,35.7866451954,2,5,3310206,4 ,1 ,42,,12",
 "503700,2,2,1998/10/01 10:17:00,139.6747103008,36.0179549092,2,7,3613326,14,7 ,29,,6",
 "540500,2,4,1998/10/01 10:17:00,139.7043090053,35.657072861,1,6,0112008,5 ,1 ,54,,1",
 "611800,2,4,1998/10/01 10:17:00,139.2859973955,35.3633828948,1,4,0431110,13,2 ,65,,1",
 "731400,2,2,1998/10/01 10:17:00,139.476014038,35.7082275752,2,10,0771111,14,4 ,32,,6",
 "742900,8,8,1998/10/01 10:17:00,139.3454491881,35.7127504266,1,8,0784113,10,3 ,38,,7",
 "786600,3,5,1998/10/01 10:17:00,139.3028961104,35.6602198766,2,3,0930110,13,2 ,35,,9",
 "706100,2,3,1998/10/01 10:17:00,139.5589206377,35.7030191118,1,14,0730209,10,7 ,26,,10",
 "69000,2,2,1998/10/01 10:18:00,139.9119857283,35.9108086502,1,4,4312114,13,2 ,52,,6",
 "147200,2,3,1998/10/01 10:18:00,140.1839826642,35.6224754882,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 10:18:00,139.7623706244,35.7568086198,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,9,1998/10/01 10:18:00,139.9109062751,35.6798950871,2,14,4116004,5 ,3 ,25,,12",
 "101200,2,3,1998/10/01 10:18:00,140.1430260106,35.5118629057,2,12,4512004,14,5 ,29,,10",
 "163300,2,3,1998/10/01 10:18:00,139.9111274362,35.6483519199,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:18:00,139.7215011498,35.5755511412,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,3,1998/10/01 10:18:00,139.7127555664,35.6649392522,2,8,2920301,4 ,1 ,33,,12",
 "142600,4,4,1998/10/01 10:18:00,140.1168353575,35.648101926,2,14,4013104,14,4 ,27,,6",
 "271400,2,2,1998/10/01 10:18:00,139.4990232252,35.5146726764,1,7,2811315,9 ,11,37,,8",
 "338100,2,2,1998/10/01 10:18:00,139.5301931357,35.6246844861,2,6,2132009,14,6 ,31,,1",
 "418600,2,5,1998/10/01 10:18:00,139.8211590109,35.6697345557,1,11,3123503,8 ,1 ,23,,1",
 "400200,2,2,1998/10/01 10:18:00,139.5521253032,35.8415622143,2,15,3115006,15,4 ,25,,1",
 "423200,2,2,1998/10/01 10:18:00,139.4960247525,35.8920373837,2,9,3133014,15,4 ,36,,7",
 "466900,2,3,1998/10/01 10:18:00,139.4649092443,35.7829187623,2,5,3310206,4 ,1 ,42,,12",
 "503700,2,2,1998/10/01 10:18:00,139.6735557304,36.015812619,2,7,3613326,14,7 ,29,,6",
 "540500,2,4,1998/10/01 10:18:00,139.7048762088,35.656628486,1,6,0112008,5 ,1 ,54,,1",
 "611800,2,4,1998/10/01 10:18:00,139.2859300135,35.3624928766,1,4,0431110,13,2 ,65,,1",
 "731400,2,2,1998/10/01 10:18:00,139.4759797992,35.7079064397,2,10,0771111,14,4 ,32,,6",
 "742900,8,8,1998/10/01 10:18:00,139.3480726468,35.712001832,1,8,0784113,10,3 ,38,,7",
 "742900,9,9,1998/10/01 10:18:00,139.3480726468,35.712001832,1,8,0784113,10,97,38,,97",
 "786600,3,5,1998/10/01 10:18:00,139.3032254551,35.6602199017,2,3,0930110,13,2 ,35,,9",
 "706100,2,3,1998/10/01 10:18:00,139.5589790335,35.7016970607,1,14,0730209,10,7 ,26,,10",
 "69000,2,2,1998/10/01 10:19:00,139.9119854928,35.9108082804,1,4,4312114,13,2 ,52,,6",
 "147200,2,3,1998/10/01 10:19:00,140.1872735922,35.6229860105,2,9,4022001,15,7 ,38,,6",
 "280600,7,7,1998/10/01 10:19:00,139.762768858,35.7544181691,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,9,1998/10/01 10:19:00,139.9050634317,35.6750336295,2,14,4116004,5 ,3 ,25,,12",
 "101200,2,3,1998/10/01 10:19:00,140.142331907,35.5142304155,2,12,4512004,14,5 ,29,,10",
 "163300,2,3,1998/10/01 10:19:00,139.9071441372,35.6450463456,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:19:00,139.7199357381,35.5718012454,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,3,1998/10/01 10:19:00,139.7176769473,35.6631025656,2,8,2920301,4 ,1 ,33,,12",
 "142600,4,4,1998/10/01 10:19:00,140.1146158088,35.6487763843,2,14,4013104,14,4 ,27,,6",
 "271400,2,2,1998/10/01 10:19:00,139.5001411179,35.5156691611,1,7,2811315,9 ,11,37,,8",
 "338100,2,2,1998/10/01 10:19:00,139.5305086255,35.6249980129,2,6,2132009,14,6 ,31,,1",
 "418600,2,5,1998/10/01 10:19:00,139.8240813551,35.6699022173,1,11,3123503,8 ,1 ,23,,1",
 "400200,2,2,1998/10/01 10:19:00,139.5520848061,35.8412426047,2,15,3115006,15,4 ,25,,1",
 "423200,2,2,1998/10/01 10:19:00,139.4960601726,35.8936989371,2,9,3133014,15,4 ,36,,7",
 "466900,2,3,1998/10/01 10:19:00,139.4712027249,35.780961195,2,5,3310206,4 ,1 ,42,,12",
 "503700,2,2,1998/10/01 10:19:00,139.6715508547,36.0142456606,2,7,3613326,14,7 ,29,,6",
 "540500,2,4,1998/10/01 10:19:00,139.7054434749,35.6561841634,1,6,0112008,5 ,1 ,54,,1",
 "611800,2,4,1998/10/01 10:19:00,139.2865601262,35.3619837532,1,4,0431110,13,2 ,65,,1",
 "731400,2,2,1998/10/01 10:19:00,139.4759879235,35.7075804543,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:19:00,139.303554587,35.660213165,2,3,0930110,13,2 ,35,,9",
 "706100,2,3,1998/10/01 10:19:00,139.5592941265,35.7021076901,1,14,0730209,10,7 ,26,,10",
 "69000,2,2,1998/10/01 10:20:00,139.9119852572,35.9108079106,1,4,4312114,13,2 ,52,,6",
 "147200,2,3,1998/10/01 10:20:00,140.1902933792,35.6235568624,2,9,4022001,15,7 ,38,,6",
 "147200,3,4,1998/10/01 10:20:00,140.1902933792,35.6235568624,2,9,4022001,15,97,38,,97",
 "280600,7,7,1998/10/01 10:20:00,139.7620393966,35.7520279228,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,9,1998/10/01 10:20:00,139.8992209868,35.6701721318,2,14,4116004,5 ,3 ,25,,12",
 "101200,2,3,1998/10/01 10:20:00,140.1413717,35.516546453,2,12,4512004,14,5 ,29,,10",
 "163300,2,3,1998/10/01 10:20:00,139.9031611663,35.6417406373,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:20:00,139.7183362716,35.5680609741,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,3,1998/10/01 10:20:00,139.7227669966,35.6649863555,2,8,2920301,4 ,1 ,33,,12",
 "142600,4,4,1998/10/01 10:20:00,140.1132983184,35.6485849544,2,14,4013104,14,4 ,27,,6",
 "142600,5,5,1998/10/01 10:20:00,140.1132983184,35.6485849544,2,14,4013104,14,97,27,,97",
 "271400,2,2,1998/10/01 10:20:00,139.5013726737,35.5165715087,1,7,2811315,9 ,11,37,,8",
 "338100,2,2,1998/10/01 10:20:00,139.5308271881,35.6253094796,2,6,2132009,14,6 ,31,,1",
 "418600,2,5,1998/10/01 10:20:00,139.8259381032,35.6708734565,1,11,3123503,8 ,1 ,23,,1",
 "400200,2,2,1998/10/01 10:20:00,139.5521630999,35.8409126448,2,15,3115006,15,4 ,25,,1",
 "400200,3,3,1998/10/01 10:20:00,139.5521630999,35.8409126448,2,15,3115006,15,97,25,,97",
 "423200,2,2,1998/10/01 10:20:00,139.4972774791,35.8947175217,2,9,3133014,15,4 ,36,,7",
 "423200,3,3,1998/10/01 10:20:00,139.4972774791,35.8947175217,2,9,3133014,15,97,36,,97",
 "466900,2,3,1998/10/01 10:20:00,139.4733564251,35.7862300094,2,5,3310206,4 ,1 ,42,,12",
 "503700,2,2,1998/10/01 10:20:00,139.6686133227,36.0138056843,2,7,3613326,14,7 ,29,,6",
 "503700,3,3,1998/10/01 10:20:00,139.6686133227,36.0138056843,2,7,3613326,14,97,29,,97",
 "540500,2,4,1998/10/01 10:20:00,139.7060107347,35.6557398381,1,6,0112008,5 ,1 ,54,,1",
 "611800,2,4,1998/10/01 10:20:00,139.2875917988,35.3616917403,1,4,0431110,13,2 ,65,,1",
 "611800,3,5,1998/10/01 10:20:00,139.2875917988,35.3616917403,1,4,0431110,13,97,65,,97",
 "731400,2,2,1998/10/01 10:20:00,139.4759387728,35.7072563213,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:20:00,139.3038763047,35.6601577791,2,3,0930110,13,2 ,35,,9",
 "706100,2,3,1998/10/01 10:20:00,139.5606719137,35.7026740805,1,14,0730209,10,7 ,26,,10",
 "706100,2,4,1998/10/01 10:20:00,139.5606719137,35.7026740805,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:21:00,139.9119850216,35.9108075407,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:21:00,139.7622749888,35.7495722475,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,9,1998/10/01 10:21:00,139.8930060511,35.6659096448,2,14,4116004,5 ,3 ,25,,12",
 "13800,4,10,1998/10/01 10:21:00,139.8930060511,35.6659096448,2,14,4116004,5 ,3 ,25,,1",
 "101200,2,3,1998/10/01 10:21:00,140.140345367,35.5188394099,2,12,4512004,14,5 ,29,,10",
 "163300,2,3,1998/10/01 10:21:00,139.8991785235,35.6384347951,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:21:00,139.716736701,35.5643207508,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,3,1998/10/01 10:21:00,139.7273091378,35.6676354736,2,8,2920301,4 ,1 ,33,,12",
 "271400,2,2,1998/10/01 10:21:00,139.5026963737,35.5173833803,1,7,2811315,9 ,11,37,,8",
 "338100,2,2,1998/10/01 10:21:00,139.5311415922,35.6256237164,2,6,2132009,14,6 ,31,,1",
 "418600,2,5,1998/10/01 10:21:00,139.8284082681,35.672594413,1,11,3123503,8 ,1 ,23,,1",
 "466900,2,3,1998/10/01 10:21:00,139.4768629427,35.7905431472,2,5,3310206,4 ,1 ,42,,12",
 "540500,2,4,1998/10/01 10:21:00,139.7065779882,35.6552955101,1,6,0112008,5 ,1 ,54,,1",
 "731400,2,2,1998/10/01 10:21:00,139.4758240284,35.7069444599,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:21:00,139.3041927758,35.6600844077,2,3,0930110,13,2 ,35,,9",
 "706100,2,4,1998/10/01 10:21:00,139.5666746473,35.7029028221,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:22:00,139.911984786,35.9108071709,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:22:00,139.7627285258,35.7471350191,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,10,1998/10/01 10:22:00,139.8953278034,35.6644548592,2,14,4116004,5 ,3 ,25,,1",
 "101200,2,3,1998/10/01 10:22:00,140.1382802871,35.5205598299,2,12,4512004,14,5 ,29,,10",
 "163300,2,3,1998/10/01 10:22:00,139.8949510502,35.6353755164,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:22:00,139.7152022803,35.5605626576,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,3,1998/10/01 10:22:00,139.725312498,35.6716771512,2,8,2920301,4 ,1 ,33,,12",
 "271400,2,2,1998/10/01 10:22:00,139.5040093612,35.518206742,1,7,2811315,9 ,11,37,,8",
 "338100,2,2,1998/10/01 10:22:00,139.531034737,35.6260069453,2,6,2132009,14,6 ,31,,1",
 "418600,2,5,1998/10/01 10:22:00,139.8304836787,35.6747928249,1,11,3123503,8 ,1 ,23,,1",
 "466900,2,3,1998/10/01 10:22:00,139.4831034368,35.787787914,2,5,3310206,4 ,1 ,42,,12",
 "540500,2,4,1998/10/01 10:22:00,139.7071452354,35.6548511794,1,6,0112008,5 ,1 ,54,,1",
 "731400,2,2,1998/10/01 10:22:00,139.4759206003,35.7066559479,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:22:00,139.3045114972,35.6600219746,2,3,0930110,13,2 ,35,,9",
 "706100,2,4,1998/10/01 10:22:00,139.5728628982,35.7029852609,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:23:00,139.9119845504,35.910806801,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:23:00,139.7634126156,35.7447315392,1,11,2910009,8 ,1 ,42,,8",
 "13800,4,10,1998/10/01 10:23:00,139.8984130423,35.6627506355,2,14,4116004,5 ,3 ,25,,1",
 "13800,5,11,1998/10/01 10:23:00,139.8984130423,35.6627506355,2,14,4116004,5 ,97,25,,97",
 "101200,2,3,1998/10/01 10:23:00,140.1353061832,35.5207990303,2,12,4512004,14,5 ,29,,10",
 "163300,2,3,1998/10/01 10:23:00,139.8894612655,35.6345217243,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:23:00,139.7136339686,35.5568136435,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,3,1998/10/01 10:23:00,139.7281459393,35.674186319,2,8,2920301,4 ,1 ,33,,12",
 "271400,2,2,1998/10/01 10:23:00,139.5053167838,35.5190361065,1,7,2811315,9 ,11,37,,8",
 "338100,2,2,1998/10/01 10:23:00,139.5310928157,35.6264075083,2,6,2132009,14,6 ,31,,1",
 "418600,2,5,1998/10/01 10:23:00,139.8327671244,35.6764102228,1,11,3123503,8 ,1 ,23,,1",
 "466900,2,3,1998/10/01 10:23:00,139.4879441456,35.7834316254,2,5,3310206,4 ,1 ,42,,12",
 "540500,2,4,1998/10/01 10:23:00,139.7077124764,35.654406846,1,6,0112008,5 ,1 ,54,,1",
 "731400,2,2,1998/10/01 10:23:00,139.4761760531,35.7064045775,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:23:00,139.3048366381,35.6599792391,2,3,0930110,13,2 ,35,,9",
 "706100,2,4,1998/10/01 10:23:00,139.5790510498,35.7030730251,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:24:00,139.9119843148,35.9108064312,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:24:00,139.7622495078,35.7425771102,1,11,2910009,8 ,1 ,42,,8",
 "101200,2,3,1998/10/01 10:24:00,140.1323989697,35.521346915,2,12,4512004,14,5 ,29,,10",
 "163300,2,3,1998/10/01 10:24:00,139.8841088996,35.6360308904,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:24:00,139.7120658025,35.5530646069,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,3,1998/10/01 10:24:00,139.7332402025,35.676194506,2,8,2920301,4 ,1 ,33,,12",
 "271400,2,2,1998/10/01 10:24:00,139.5066294168,35.5198599838,1,7,2811315,9 ,11,37,,8",
 "338100,2,2,1998/10/01 10:24:00,139.531376311,35.6267392376,2,6,2132009,14,6 ,31,,1",
 "418600,2,5,1998/10/01 10:24:00,139.8335902405,35.6791236291,1,11,3123503,8 ,1 ,23,,1",
 "466900,2,3,1998/10/01 10:24:00,139.493083101,35.7795468338,2,5,3310206,4 ,1 ,42,,12",
 "540500,2,4,1998/10/01 10:24:00,139.7083234127,35.65432005,1,6,0112008,5 ,1 ,54,,1",
 "731400,2,2,1998/10/01 10:24:00,139.4764793246,35.7061931376,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:24:00,139.3051616716,35.6599358966,2,3,0930110,13,2 ,35,,9",
 "706100,2,4,1998/10/01 10:24:00,139.5852250376,35.7033838374,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:25:00,139.9119840792,35.9108060613,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:25:00,139.7604683253,35.740590894,1,11,2910009,8 ,1 ,42,,8",
 "101200,2,3,1998/10/01 10:25:00,140.1297009213,35.5224097766,2,12,4512004,14,5 ,29,,10",
 "163300,2,3,1998/10/01 10:25:00,139.8789154808,35.637907789,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:25:00,139.710497782,35.549315548,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,3,1998/10/01 10:25:00,139.7381361531,35.678390567,2,8,2920301,4 ,1 ,33,,12",
 "271400,2,2,1998/10/01 10:25:00,139.5079399453,35.520685961,1,7,2811315,9 ,11,37,,8",
 "338100,2,2,1998/10/01 10:25:00,139.5316620244,35.627071057,2,6,2132009,14,6 ,31,,1",
 "418600,2,5,1998/10/01 10:25:00,139.8350801514,35.6807639396,1,11,3123503,8 ,1 ,23,,1",
 "466900,2,3,1998/10/01 10:25:00,139.4999387571,35.7776415401,2,5,3310206,4 ,1 ,42,,12",
 "540500,2,4,1998/10/01 10:25:00,139.7089567932,35.6543306521,1,6,0112008,5 ,1 ,54,,1",
 "731400,2,2,1998/10/01 10:25:00,139.4768067626,35.7060285615,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:25:00,139.3054741475,35.6599400704,2,3,0930110,13,2 ,35,,9",
 "706100,2,4,1998/10/01 10:25:00,139.5914110039,35.7035444827,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:26:00,139.9119838437,35.9108056915,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:26:00,139.7612601309,35.7387981088,1,11,2910009,8 ,1 ,42,,8",
 "101200,2,3,1998/10/01 10:26:00,140.1269466889,35.5233702912,2,12,4512004,14,5 ,29,,10",
 "163300,2,3,1998/10/01 10:26:00,139.8737151795,35.6397724316,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:26:00,139.7089299069,35.5455664666,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,3,1998/10/01 10:26:00,139.7418874835,35.6807923672,2,8,2920301,4 ,1 ,33,,12",
 "271400,2,2,1998/10/01 10:26:00,139.5089684776,35.5217294676,1,7,2811315,9 ,11,37,,8",
 "338100,2,2,1998/10/01 10:26:00,139.5319079693,35.6274227159,2,6,2132009,14,6 ,31,,1",
 "418600,2,5,1998/10/01 10:26:00,139.8358569023,35.6835086115,1,11,3123503,8 ,1 ,23,,1",
 "466900,2,3,1998/10/01 10:26:00,139.5067791445,35.7756989921,2,5,3310206,4 ,1 ,42,,12",
 "540500,2,4,1998/10/01 10:26:00,139.7095669776,35.6539268465,1,6,0112008,5 ,1 ,54,,1",
 "731400,2,2,1998/10/01 10:26:00,139.4767580615,35.7057041163,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:26:00,139.305748398,35.660088893,2,3,0930110,13,2 ,35,,9",
 "706100,2,4,1998/10/01 10:26:00,139.5975976709,35.703686645,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:27:00,139.9119836081,35.9108053217,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:27:00,139.7596362232,35.7368540987,1,11,2910009,8 ,1 ,42,,8",
 "101200,2,3,1998/10/01 10:27:00,140.12450029,35.5247476264,2,12,4512004,14,5 ,29,,10",
 "163300,2,3,1998/10/01 10:27:00,139.868514866,35.6416372686,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:27:00,139.7073403583,35.5418480614,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,3,1998/10/01 10:27:00,139.7417126433,35.6853751463,2,8,2920301,4 ,1 ,33,,12",
 "296700,2,4,1998/10/01 10:27:00,139.7417126433,35.6853751463,2,8,2920301,4 ,1 ,33,,1",
 "271400,2,2,1998/10/01 10:27:00,139.5097427152,35.5229251575,1,7,2811315,9 ,11,37,,8",
 "338100,2,2,1998/10/01 10:27:00,139.5321001314,35.6277455857,2,6,2132009,14,6 ,31,,1",
 "418600,2,5,1998/10/01 10:27:00,139.8365197442,35.686275374,1,11,3123503,8 ,1 ,23,,1",
 "466900,2,3,1998/10/01 10:27:00,139.5136222619,35.7737632223,2,5,3310206,4 ,1 ,42,,12",
 "540500,2,4,1998/10/01 10:27:00,139.7100771613,35.653449595,1,6,0112008,5 ,1 ,54,,1",
 "731400,2,2,1998/10/01 10:27:00,139.4767093608,35.7053796711,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:27:00,139.3060226495,35.6602377151,2,3,0930110,13,2 ,35,,9",
 "706100,2,4,1998/10/01 10:27:00,139.6037765234,35.7038939212,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:28:00,139.9119833725,35.9108049518,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:28:00,139.7592421523,35.7344072357,1,11,2910009,8 ,1 ,42,,8",
 "101200,2,3,1998/10/01 10:28:00,140.1223180144,35.5264245576,2,12,4512004,14,5 ,29,,10",
 "163300,2,3,1998/10/01 10:28:00,139.8633141931,35.6435012168,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:28:00,139.7046695819,35.5385429516,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,4,1998/10/01 10:28:00,139.7439173043,35.6836055961,2,8,2920301,4 ,1 ,33,,1",
 "271400,2,2,1998/10/01 10:28:00,139.5105663032,35.5240943875,1,7,2811315,9 ,11,37,,8",
 "338100,2,2,1998/10/01 10:28:00,139.5325151126,35.6275222679,2,6,2132009,14,6 ,31,,1",
 "418600,2,5,1998/10/01 10:28:00,139.8371826319,35.6890421315,1,11,3123503,8 ,1 ,23,,1",
 "466900,2,3,1998/10/01 10:28:00,139.5204651395,35.7718273743,2,5,3310206,4 ,1 ,42,,12",
 "540500,2,4,1998/10/01 10:28:00,139.7105219947,35.6529204919,1,6,0112008,5 ,1 ,54,,1",
 "731400,2,2,1998/10/01 10:28:00,139.4766857939,35.7050554544,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:28:00,139.306296902,35.6603865365,2,3,0930110,13,2 ,35,,9",
 "706100,2,4,1998/10/01 10:28:00,139.6099623028,35.7040606285,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:29:00,139.9119831369,35.910804582,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:29:00,139.7584719656,35.732030385,1,11,2910009,8 ,1 ,42,,8",
 "101200,2,3,1998/10/01 10:29:00,140.1201354873,35.52810139,2,12,4512004,14,5 ,29,,10",
 "163300,2,3,1998/10/01 10:29:00,139.8581262932,35.645387529,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:29:00,139.7018038785,35.5353591754,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,4,1998/10/01 10:29:00,139.7452820636,35.680483753,2,8,2920301,4 ,1 ,33,,1",
 "271400,2,2,1998/10/01 10:29:00,139.5120281221,35.5247238121,1,7,2811315,9 ,11,37,,8",
 "338100,2,2,1998/10/01 10:29:00,139.5329300915,35.6272989486,2,6,2132009,14,6 ,31,,1",
 "418600,2,5,1998/10/01 10:29:00,139.8375536651,35.6918230745,1,11,3123503,8 ,1 ,23,,1",
 "466900,2,3,1998/10/01 10:29:00,139.5259418518,35.7682128502,2,5,3310206,4 ,1 ,42,,12",
 "540500,2,4,1998/10/01 10:29:00,139.7109668223,35.6523913871,1,6,0112008,5 ,1 ,54,,1",
 "731400,2,2,1998/10/01 10:29:00,139.4767433488,35.7047319752,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:29:00,139.306573085,35.6605329019,2,3,0930110,13,2 ,35,,9",
 "706100,2,4,1998/10/01 10:29:00,139.6161445832,35.7042966455,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:30:00,139.9119829013,35.9108042121,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:30:00,139.7574104361,35.7297408422,1,11,2910009,8 ,1 ,42,,8",
 "101200,2,3,1998/10/01 10:30:00,140.1184036077,35.530055434,2,12,4512004,14,5 ,29,,10",
 "163300,2,3,1998/10/01 10:30:00,139.852524664,35.6460378845,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:30:00,139.6983264665,35.5326015624,1,8,2410601,7 ,3 ,15,,12",
 "296700,2,4,1998/10/01 10:30:00,139.7467508387,35.6780500751,2,8,2920301,4 ,1 ,33,,1",
 "296700,3,5,1998/10/01 10:30:00,139.7467508387,35.6780500751,2,8,2920301,4 ,97,33,,97",
 "271400,2,2,1998/10/01 10:30:00,139.5135263065,35.5252985964,1,7,2811315,9 ,11,37,,8",
 "338100,2,2,1998/10/01 10:30:00,139.533345068,35.6270756279,2,6,2132009,14,6 ,31,,1",
 "338100,3,3,1998/10/01 10:30:00,139.533345068,35.6270756279,2,6,2132009,14,97,31,,97",
 "418600,2,5,1998/10/01 10:30:00,139.8398085892,35.693169772,1,11,3123503,8 ,1 ,23,,1",
 "418600,3,6,1998/10/01 10:30:00,139.8398085892,35.693169772,1,11,3123503,8 ,97,23,,97",
 "466900,2,3,1998/10/01 10:30:00,139.5305534994,35.7636610196,2,5,3310206,4 ,1 ,42,,12",
 "540500,2,4,1998/10/01 10:30:00,139.7116379294,35.6524680766,1,6,0112008,5 ,1 ,54,,1",
 "540500,3,5,1998/10/01 10:30:00,139.7116379294,35.6524680766,1,6,0112008,5 ,97,54,,97",
 "731400,2,2,1998/10/01 10:30:00,139.4767926022,35.7044076579,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:30:00,139.3068532245,35.6606742332,2,3,0930110,13,2 ,35,,9",
 "706100,2,4,1998/10/01 10:30:00,139.6223268802,35.70452793,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:31:00,139.9119826657,35.9108038423,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:31:00,139.7562945502,35.7274503547,1,11,2910009,8 ,1 ,42,,8",
 "101200,2,3,1998/10/01 10:31:00,140.1181083387,35.5324692398,2,12,4512004,14,5 ,29,,10",
 "163300,2,3,1998/10/01 10:31:00,139.8468628305,35.6464120974,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:31:00,139.6949409231,35.5297692312,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:31:00,140.1781924259,35.679130676,2,14,4416010,15,4 ,36,,1",
 "186300,2,2,1998/10/01 10:31:00,140.1227121428,35.9943183902,1,4,5217006,13,4 ,53,,3",
 "241500,4,4,1998/10/01 10:31:00,139.3806241866,35.3624594825,1,6,2420707,5 ,3 ,37,,6",
 "271400,2,2,1998/10/01 10:31:00,139.51502552,35.5258715978,1,7,2811315,9 ,11,37,,8",
 "466900,2,3,1998/10/01 10:31:00,139.5351170951,35.7590775177,2,5,3310206,4 ,1 ,42,,12",
 "616400,4,6,1998/10/01 10:31:00,139.7058127957,35.6056616991,2,13,0433105,15,3 ,50,,1",
 "731400,2,2,1998/10/01 10:31:00,139.4768334737,35.7040824945,2,10,0771111,14,4 ,32,,6",
 "809600,4,4,1998/10/01 10:31:00,139.6125426057,35.4358476366,2,9,1122002,14,3 ,33,,1",
 "786600,3,5,1998/10/01 10:31:00,139.307143123,35.6608012848,2,3,0930110,13,2 ,35,,9",
 "851000,2,2,1998/10/01 10:31:00,139.5519222025,35.5026129938,2,11,1330011,15,7 ,45,,6",
 "862500,2,2,1998/10/01 10:31:00,139.5686603613,35.5405920412,2,6,1321110,15,5 ,29,,2",
 "685400,2,2,1998/10/01 10:31:00,139.8851127224,35.6433932133,1,11,0634016,15,10,48,,6",
 "706100,2,4,1998/10/01 10:31:00,139.6285132132,35.7046821865,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:32:00,139.9119824302,35.9108034725,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:32:00,139.7547821527,35.725322401,1,11,2910009,8 ,1 ,42,,8",
 "101200,2,3,1998/10/01 10:32:00,140.1177146187,35.5348554919,2,12,4512004,14,5 ,29,,10",
 "163300,2,3,1998/10/01 10:32:00,139.8411988831,35.6467643811,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:32:00,139.6916226529,35.5268815993,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:32:00,140.1791315963,35.679355892,2,14,4416010,15,4 ,36,,1",
 "186300,2,2,1998/10/01 10:32:00,140.1240319385,35.9965878974,1,4,5217006,13,4 ,53,,3",
 "241500,4,4,1998/10/01 10:32:00,139.3825693106,35.3621759983,1,6,2420707,5 ,3 ,37,,6",
 "271400,2,2,1998/10/01 10:32:00,139.5165413479,35.5264135483,1,7,2811315,9 ,11,37,,8",
 "466900,2,3,1998/10/01 10:32:00,139.5397589445,35.7545494047,2,5,3310206,4 ,1 ,42,,12",
 "616400,4,6,1998/10/01 10:32:00,139.7049924115,35.6053732592,2,13,0433105,15,3 ,50,,1",
 "731400,2,2,1998/10/01 10:32:00,139.4768743449,35.7037573311,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:32:00,139.307437677,35.6609215249,2,3,0930110,13,2 ,35,,9",
 "809600,4,4,1998/10/01 10:32:00,139.6110976396,35.4356662102,2,9,1122002,14,3 ,33,,1",
 "851000,2,2,1998/10/01 10:32:00,139.5523494302,35.5018601603,2,11,1330011,15,7 ,45,,6",
 "862500,2,2,1998/10/01 10:32:00,139.5687111805,35.5405586352,2,6,1321110,15,5 ,29,,2",
 "685400,2,2,1998/10/01 10:32:00,139.8858413838,35.6422734432,1,11,0634016,15,10,48,,6",
 "706100,2,4,1998/10/01 10:32:00,139.6346995838,35.7048357865,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:33:00,139.9119821946,35.9108031026,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:33:00,139.7531126296,35.7232918382,1,11,2910009,8 ,1 ,42,,8",
 "101200,2,3,1998/10/01 10:33:00,140.1199636027,35.5361215906,2,12,4512004,14,5 ,29,,10",
 "101200,2,4,1998/10/01 10:33:00,140.1199636027,35.5361215906,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:33:00,139.8355344514,35.6468220189,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:33:00,139.6882452193,35.5240400533,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:33:00,140.1800562058,35.6795873606,2,14,4416010,15,4 ,36,,1",
 "186300,2,2,1998/10/01 10:33:00,140.127304434,35.9974117539,1,4,5217006,13,4 ,53,,3",
 "241500,4,4,1998/10/01 10:33:00,139.3851975614,35.3619887948,1,6,2420707,5 ,3 ,37,,6",
 "271400,2,2,1998/10/01 10:33:00,139.5178445232,35.5270907703,1,7,2811315,9 ,11,37,,8",
 "466900,2,3,1998/10/01 10:33:00,139.5458377617,35.751445889,2,5,3310206,4 ,1 ,42,,12",
 "466900,2,4,1998/10/01 10:33:00,139.5458377617,35.751445889,2,5,3310206,4 ,1 ,42,,1",
 "616400,4,6,1998/10/01 10:33:00,139.7041720331,35.6050848137,2,13,0433105,15,3 ,50,,1",
 "731400,2,2,1998/10/01 10:33:00,139.4769204156,35.7034328139,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:33:00,139.3077212309,35.6610574423,2,3,0930110,13,2 ,35,,9",
 "809600,4,4,1998/10/01 10:33:00,139.6097364652,35.435260463,2,9,1122002,14,3 ,33,,1",
 "851000,2,2,1998/10/01 10:33:00,139.5543446663,35.5019711454,2,11,1330011,15,7 ,45,,6",
 "862500,2,2,1998/10/01 10:33:00,139.5696154501,35.5408628477,2,6,1321110,15,5 ,29,,2",
 "685400,2,2,1998/10/01 10:33:00,139.8858017248,35.6413118959,1,11,0634016,15,10,48,,6",
 "706100,2,4,1998/10/01 10:33:00,139.6408837909,35.7050351217,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:34:00,139.911981959,35.9108027328,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:34:00,139.7520756025,35.7209839089,1,11,2910009,8 ,1 ,42,,8",
 "101200,2,4,1998/10/01 10:34:00,140.1243032896,35.5399170953,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:34:00,139.8298868093,35.6463347562,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:34:00,139.6848656626,35.5212002884,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:34:00,140.1801590643,35.6803728106,2,14,4416010,15,4 ,36,,1",
 "186300,2,2,1998/10/01 10:34:00,140.1301825283,35.9954702804,1,4,5217006,13,4 ,53,,3",
 "241500,4,4,1998/10/01 10:34:00,139.3874579739,35.3611767629,1,6,2420707,5 ,3 ,37,,6",
 "271400,2,2,1998/10/01 10:34:00,139.5190471177,35.5269000811,1,7,2811315,9 ,11,37,,8",
 "466900,2,4,1998/10/01 10:34:00,139.544820069,35.7496006388,2,5,3310206,4 ,1 ,42,,1",
 "616400,4,6,1998/10/01 10:34:00,139.7033219122,35.6049297264,2,13,0433105,15,3 ,50,,1",
 "731400,2,2,1998/10/01 10:34:00,139.4770001284,35.7031124778,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:34:00,139.3079973847,35.661203907,2,3,0930110,13,2 ,35,,9",
 "809600,4,4,1998/10/01 10:34:00,139.6084716454,35.4348183645,2,9,1122002,14,3 ,33,,1",
 "851000,2,2,1998/10/01 10:34:00,139.5566189231,35.5018003619,2,11,1330011,15,7 ,45,,6",
 "862500,2,2,1998/10/01 10:34:00,139.5702721458,35.5414481834,2,6,1321110,15,5 ,29,,2",
 "685400,2,2,1998/10/01 10:34:00,139.8844978162,35.6406231848,1,11,0634016,15,10,48,,6",
 "706100,2,4,1998/10/01 10:34:00,139.6470680272,35.7052386558,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:35:00,139.9119817234,35.9108023629,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:35:00,139.751256277,35.7186266046,1,11,2910009,8 ,1 ,42,,8",
 "101200,2,4,1998/10/01 10:35:00,140.1285877922,35.5437523568,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:35:00,139.8242583123,35.6458306303,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:35:00,139.6815372577,35.5183222505,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:35:00,140.1794171361,35.6807139147,2,14,4416010,15,4 ,36,,1",
 "186300,2,2,1998/10/01 10:35:00,140.1327685918,35.9936653066,1,4,5217006,13,4 ,53,,3",
 "241500,4,4,1998/10/01 10:35:00,139.3893707209,35.3596884555,1,6,2420707,5 ,3 ,37,,6",
 "271400,2,2,1998/10/01 10:35:00,139.5205068403,35.5269671663,1,7,2811315,9 ,11,37,,8",
 "466900,2,4,1998/10/01 10:35:00,139.5450807548,35.7474838378,2,5,3310206,4 ,1 ,42,,1",
 "466900,3,5,1998/10/01 10:35:00,139.5450807548,35.7474838378,2,5,3310206,4 ,97,42,,97",
 "616400,4,6,1998/10/01 10:35:00,139.7026077647,35.6049111322,2,13,0433105,15,3 ,50,,1",
 "616400,4,7,1998/10/01 10:35:00,139.7026077647,35.6049111322,2,13,0433105,15,3 ,50,,12",
 "731400,2,2,1998/10/01 10:35:00,139.4770798406,35.7027921416,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:35:00,139.3082810142,35.6613401923,2,3,0930110,13,2 ,35,,9",
 "809600,4,4,1998/10/01 10:35:00,139.6082565958,35.4352238808,2,9,1122002,14,3 ,33,,1",
 "809600,5,5,1998/10/01 10:35:00,139.6082565958,35.4352238808,2,9,1122002,14,97,33,,97",
 "685400,2,2,1998/10/01 10:35:00,139.8832079062,35.6399167583,1,11,0634016,15,10,48,,6",
 "851000,2,2,1998/10/01 10:35:00,139.5586175741,35.502780383,2,11,1330011,15,7 ,45,,6",
 "862500,2,2,1998/10/01 10:35:00,139.5709268244,35.542041862,2,6,1321110,15,5 ,29,,2",
 "706100,2,4,1998/10/01 10:35:00,139.6532534368,35.7054164013,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:36:00,139.9119814878,35.9108019931,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:36:00,139.7517124911,35.716186733,1,11,2910009,8 ,1 ,42,,8",
 "101200,2,4,1998/10/01 10:36:00,140.1308021426,35.5485965962,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:36:00,139.8201027824,35.6485893536,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:36:00,139.6787379533,35.5151182286,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:36:00,140.1787403448,35.6812852506,2,14,4416010,15,4 ,36,,1",
 "186300,2,2,1998/10/01 10:36:00,140.1363239376,35.9947591748,1,4,5217006,13,4 ,53,,3",
 "241500,4,4,1998/10/01 10:36:00,139.391266682,35.3581858086,1,6,2420707,5 ,3 ,37,,6",
 "271400,2,2,1998/10/01 10:36:00,139.5217193701,35.5270085767,1,7,2811315,9 ,11,37,,8",
 "616400,4,7,1998/10/01 10:36:00,139.6975704464,35.6052680361,2,13,0433105,15,3 ,50,,12",
 "731400,2,2,1998/10/01 10:36:00,139.4771595521,35.7024718054,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:36:00,139.3085694928,35.6614698747,2,3,0930110,13,2 ,35,,9",
 "851000,2,2,1998/10/01 10:36:00,139.5609862878,35.5034084399,2,11,1330011,15,7 ,45,,6",
 "862500,2,2,1998/10/01 10:36:00,139.5717183524,35.541781507,2,6,1321110,15,5 ,29,,2",
 "685400,2,2,1998/10/01 10:36:00,139.8819368851,35.6391878475,1,11,0634016,15,10,48,,6",
 "706100,2,4,1998/10/01 10:36:00,139.6594396324,35.7055739859,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:37:00,139.9119812522,35.9108016232,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:37:00,139.7521656015,35.713746532,1,11,2910009,8 ,1 ,42,,8",
 "101200,2,4,1998/10/01 10:37:00,140.1327635403,35.5535381179,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:37:00,139.8195043398,35.653197857,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:37:00,139.6769609417,35.5114427111,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:37:00,140.1778922858,35.6816763327,2,14,4416010,15,4 ,36,,1",
 "186300,2,2,1998/10/01 10:37:00,140.1398651846,35.9958389073,1,4,5217006,13,4 ,53,,3",
 "241500,4,4,1998/10/01 10:37:00,139.3931533449,35.356675376,1,6,2420707,5 ,3 ,37,,6",
 "271400,2,2,1998/10/01 10:37:00,139.5226252131,35.5281408651,1,7,2811315,9 ,11,37,,8",
 "616400,4,7,1998/10/01 10:37:00,139.692727177,35.6064767281,2,13,0433105,15,3 ,50,,12",
 "731400,2,2,1998/10/01 10:37:00,139.477239263,35.7021514691,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:37:00,139.3088579723,35.6615995565,2,3,0930110,13,2 ,35,,9",
 "851000,2,2,1998/10/01 10:37:00,139.5635908138,35.5036408533,2,11,1330011,15,7 ,45,,6",
 "862500,2,2,1998/10/01 10:37:00,139.5726297032,35.5414917359,2,6,1321110,15,5 ,29,,2",
 "685400,2,2,1998/10/01 10:37:00,139.8824254664,35.6382169182,1,11,0634016,15,10,48,,6",
 "706100,2,4,1998/10/01 10:37:00,139.6656253729,35.7057426875,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:38:00,139.9119810166,35.9108012534,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:38:00,139.7527124168,35.7113198592,1,11,2910009,8 ,1 ,42,,8",
 "101200,2,4,1998/10/01 10:38:00,140.1347150072,35.5584820304,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:38:00,139.8183048641,35.6576769164,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:38:00,139.6763478745,35.5075326311,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:38:00,140.1775379445,35.6823709825,2,14,4416010,15,4 ,36,,1",
 "186300,2,2,1998/10/01 10:38:00,140.1409926359,35.9955975326,1,4,5217006,13,4 ,53,,3",
 "241500,4,4,1998/10/01 10:38:00,139.3948361463,35.3551532086,1,6,2420707,5 ,3 ,37,,6",
 "271400,2,2,1998/10/01 10:38:00,139.5238463321,35.5283221338,1,7,2811315,9 ,11,37,,8",
 "616400,4,7,1998/10/01 10:38:00,139.6879140718,35.6077579641,2,13,0433105,15,3 ,50,,12",
 "731400,2,2,1998/10/01 10:38:00,139.4772931842,35.7018278148,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:38:00,139.3091464528,35.6617292375,2,3,0930110,13,2 ,35,,9",
 "851000,2,2,1998/10/01 10:38:00,139.5644414295,35.5049259974,2,11,1330011,15,7 ,45,,6",
 "862500,2,2,1998/10/01 10:38:00,139.5735961604,35.5413786605,2,6,1321110,15,5 ,29,,2",
 "685400,2,2,1998/10/01 10:38:00,139.8810189076,35.6382887034,1,11,0634016,15,10,48,,6",
 "706100,2,4,1998/10/01 10:38:00,139.6718106769,35.7059246991,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:39:00,139.9119807811,35.9108008836,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:39:00,139.7533831414,35.7089138796,1,11,2910009,8 ,1 ,42,,8",
 "101200,2,4,1998/10/01 10:39:00,140.1346933245,35.5636499865,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:39:00,139.8144814162,35.6610552183,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:39:00,139.675278567,35.5036735516,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:39:00,140.1771443419,35.6830863965,2,14,4416010,15,4 ,36,,1",
 "186300,2,2,1998/10/01 10:39:00,140.1433493965,35.9966803834,1,4,5217006,13,4 ,53,,3",
 "241500,4,4,1998/10/01 10:39:00,139.3948035698,35.3530014893,1,6,2420707,5 ,3 ,37,,6",
 "271400,2,2,1998/10/01 10:39:00,139.5253547326,35.5277693851,1,7,2811315,9 ,11,37,,8",
 "616400,4,7,1998/10/01 10:39:00,139.6830186175,35.6065956369,2,13,0433105,15,3 ,50,,12",
 "731400,2,2,1998/10/01 10:39:00,139.4772891996,35.7015012053,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:39:00,139.3094349343,35.6618589179,2,3,0930110,13,2 ,35,,9",
 "862500,2,2,1998/10/01 10:39:00,139.5745663431,35.5412786115,2,6,1321110,15,5 ,29,,2",
 "851000,2,2,1998/10/01 10:39:00,139.5660627869,35.5050610027,2,11,1330011,15,7 ,45,,6",
 "685400,2,2,1998/10/01 10:39:00,139.8795792067,35.638763968,1,11,0634016,15,10,48,,6",
 "706100,2,4,1998/10/01 10:39:00,139.6779973624,35.7060675819,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:40:00,139.9119805455,35.9108005137,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:40:00,139.7541171052,35.7065200938,1,11,2910009,8 ,1 ,42,,8",
 "80500,4,4,1998/10/01 10:40:00,140.1243766605,35.7250571727,1,14,4413233,5 ,10,33,,1",
 "80500,4,5,1998/10/01 10:40:00,140.1243766605,35.7250571727,1,14,4413233,5 ,10,33,,10",
 "101200,2,4,1998/10/01 10:40:00,140.1343480913,35.568837313,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:40:00,139.8088865201,35.6611882639,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:40:00,139.672610793,35.5003965841,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:40:00,140.1769010178,35.6838600179,2,14,4416010,15,4 ,36,,1",
 "186300,2,2,1998/10/01 10:40:00,140.1419537052,35.9983296385,1,4,5217006,13,4 ,53,,3",
 "186300,3,3,1998/10/01 10:40:00,140.1419537052,35.9983296385,1,4,5217006,13,97,53,,97",
 "241500,4,4,1998/10/01 10:40:00,139.3964720585,35.3514571312,1,6,2420707,5 ,3 ,37,,6",
 "271400,2,2,1998/10/01 10:40:00,139.5269023969,35.527290046,1,7,2811315,9 ,11,37,,8",
 "616400,4,7,1998/10/01 10:40:00,139.67814654,35.6067759724,2,13,0433105,15,3 ,50,,12",
 "731400,2,2,1998/10/01 10:40:00,139.4772803687,35.7011744167,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:40:00,139.3097234167,35.6619885976,2,3,0930110,13,2 ,35,,9",
 "851000,2,2,1998/10/01 10:40:00,139.5683273949,35.5042990056,2,11,1330011,15,7 ,45,,6",
 "862500,2,2,1998/10/01 10:40:00,139.575535947,35.5411748459,2,6,1321110,15,5 ,29,,2",
 "685400,2,2,1998/10/01 10:40:00,139.8781491593,35.6392590269,1,11,0634016,15,10,48,,6",
 "706100,2,4,1998/10/01 10:40:00,139.6841856433,35.7061615068,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:41:00,139.9119803099,35.9108001439,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:41:00,139.7548697006,35.7041300193,1,11,2910009,8 ,1 ,42,,8",
 "80500,4,5,1998/10/01 10:41:00,140.1243764697,35.7250570136,1,14,4413233,5 ,10,33,,10",
 "101200,2,4,1998/10/01 10:41:00,140.1340119806,35.5740250349,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:41:00,139.8032210654,35.6613155509,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:41:00,139.6692702642,35.4975268764,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:41:00,140.1760616369,35.68407448,2,14,4416010,15,4 ,36,,1",
 "241500,4,4,1998/10/01 10:41:00,139.3969709342,35.3493743764,1,6,2420707,5 ,3 ,37,,6",
 "271400,2,2,1998/10/01 10:41:00,139.528450043,35.526810687,1,7,2811315,9 ,11,37,,8",
 "400200,4,4,1998/10/01 10:41:00,139.5519686649,35.8412568273,2,15,3115006,15,3 ,25,,1",
 "616400,4,7,1998/10/01 10:41:00,139.6732746388,35.6080279043,2,13,0433105,15,3 ,50,,12",
 "731400,2,2,1998/10/01 10:41:00,139.4772721893,35.7008476223,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:41:00,139.3100119,35.6621182766,2,3,0930110,13,2 ,35,,9",
 "862500,2,2,1998/10/01 10:41:00,139.576505983,35.5410744028,2,6,1321110,15,5 ,29,,2",
 "851000,2,2,1998/10/01 10:41:00,139.5708747071,35.5036169394,2,11,1330011,15,7 ,45,,6",
 "685400,2,2,1998/10/01 10:41:00,139.8767296489,35.6397738695,1,11,0634016,15,10,48,,6",
 "706100,2,4,1998/10/01 10:41:00,139.6902920854,35.7057486756,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:42:00,139.9119800743,35.910799774,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:42:00,139.7550967351,35.7017227635,1,11,2910009,8 ,1 ,42,,8",
 "80500,4,5,1998/10/01 10:42:00,140.1243762788,35.7250568544,1,14,4413233,5 ,10,33,,10",
 "101200,2,4,1998/10/01 10:42:00,140.1322892326,35.5789679318,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:42:00,139.7981111112,35.6632998851,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:42:00,139.6658612035,35.494712098,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:42:00,140.1750836495,35.684039008,2,14,4416010,15,4 ,36,,1",
 "241500,4,4,1998/10/01 10:42:00,139.3986606113,35.3481396049,1,6,2420707,5 ,3 ,37,,6",
 "271400,2,2,1998/10/01 10:42:00,139.530029518,35.5264829197,1,7,2811315,9 ,11,37,,8",
 "400200,4,4,1998/10/01 10:42:00,139.5520206743,35.8416092431,2,15,3115006,15,3 ,25,,1",
 "616400,4,7,1998/10/01 10:42:00,139.6683896745,35.607219063,2,13,0433105,15,3 ,50,,12",
 "731400,2,2,1998/10/01 10:42:00,139.4772770953,35.7005209589,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:42:00,139.3103010917,35.6622430517,2,3,0930110,13,2 ,35,,9",
 "851000,2,2,1998/10/01 10:42:00,139.5735378076,35.5030747663,2,11,1330011,15,7 ,45,,6",
 "862500,2,2,1998/10/01 10:42:00,139.5774757611,35.5409718856,2,6,1321110,15,5 ,29,,2",
 "685400,2,2,1998/10/01 10:42:00,139.875311159,35.6402906441,1,11,0634016,15,10,48,,6",
 "706100,2,4,1998/10/01 10:42:00,139.695440095,35.7031042471,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:43:00,139.9119798387,35.9107994042,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:43:00,139.7532560534,35.7003921135,1,11,2910009,8 ,1 ,42,,8",
 "80500,4,5,1998/10/01 10:43:00,140.124376088,35.7250566952,1,14,4413233,5 ,10,33,,10",
 "101200,2,4,1998/10/01 10:43:00,140.1298348441,35.5837603891,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:43:00,139.7941670346,35.6666341558,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:43:00,139.6632572133,35.4914347931,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:43:00,140.1741059757,35.6840147049,2,14,4416010,15,4 ,36,,1",
 "241500,4,4,1998/10/01 10:43:00,139.3993817295,35.3466642173,1,6,2420707,5 ,3 ,37,,6",
 "271400,2,2,1998/10/01 10:43:00,139.5316727246,35.5263522286,1,7,2811315,9 ,11,37,,8",
 "400200,4,4,1998/10/01 10:43:00,139.5521798279,35.8419652382,2,15,3115006,15,3 ,25,,1",
 "616400,4,7,1998/10/01 10:43:00,139.6636880691,35.6055898779,2,13,0433105,15,3 ,50,,12",
 "731400,2,2,1998/10/01 10:43:00,139.4773184612,35.700195837,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:43:00,139.3106185966,35.6621716077,2,3,0930110,13,2 ,35,,9",
 "851000,2,2,1998/10/01 10:43:00,139.5761255438,35.5025642197,2,11,1330011,15,7 ,45,,6",
 "862500,2,2,1998/10/01 10:43:00,139.5784482959,35.5408909143,2,6,1321110,15,5 ,29,,2",
 "685400,2,2,1998/10/01 10:43:00,139.8738924489,35.6408070322,1,11,0634016,15,10,48,,6",
 "706100,2,4,1998/10/01 10:43:00,139.6986061248,35.6987952731,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:44:00,139.9119796031,35.9107990344,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:44:00,139.7527279132,35.6980516108,1,11,2910009,8 ,1 ,42,,8",
 "80500,4,5,1998/10/01 10:44:00,140.1243758972,35.725056536,1,14,4413233,5 ,10,33,,10",
 "101200,2,4,1998/10/01 10:44:00,140.1275029507,35.5885908922,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:44:00,139.7902270625,35.6699737818,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:44:00,139.660828874,35.4880395552,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:44:00,140.1731393211,35.6838886765,2,14,4416010,15,4 ,36,,1",
 "241500,4,4,1998/10/01 10:44:00,139.3996333457,35.3448816605,1,6,2420707,5 ,3 ,37,,6",
 "271400,2,2,1998/10/01 10:44:00,139.5328332113,35.5254405137,1,7,2811315,9 ,11,37,,8",
 "400200,4,4,1998/10/01 10:44:00,139.552338983,35.8423212332,2,15,3115006,15,3 ,25,,1",
 "616400,4,7,1998/10/01 10:44:00,139.6587430225,35.6058692886,2,13,0433105,15,3 ,50,,12",
 "731400,2,2,1998/10/01 10:44:00,139.4773599875,35.6998707403,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:44:00,139.310936101,35.6621001628,2,3,0930110,13,2 ,35,,9",
 "851000,2,2,1998/10/01 10:44:00,139.5787794247,35.5020093842,2,11,1330011,15,7 ,45,,6",
 "862500,2,2,1998/10/01 10:44:00,139.5794232502,35.5408484678,2,6,1321110,15,5 ,29,,2",
 "685400,2,2,1998/10/01 10:44:00,139.8724736472,35.6413232695,1,11,0634016,15,10,48,,6",
 "706100,2,4,1998/10/01 10:44:00,139.6999027183,35.6938908377,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:45:00,139.9119793675,35.9107986645,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:45:00,139.752247565,35.6960959585,1,11,2910009,8 ,1 ,42,,8",
 "80500,4,5,1998/10/01 10:45:00,140.1243757063,35.7250563768,1,14,4413233,5 ,10,33,,10",
 "101200,2,4,1998/10/01 10:45:00,140.1250422329,35.5933809437,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:45:00,139.7855894932,35.6726364355,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:45:00,139.6568824401,35.4858469252,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:45:00,140.1721771089,35.6837416132,2,14,4416010,15,4 ,36,,1",
 "241500,4,4,1998/10/01 10:45:00,139.4004988194,35.3447799921,1,6,2420707,5 ,3 ,37,,6",
 "241500,5,5,1998/10/01 10:45:00,139.4004988194,35.3447799921,1,6,2420707,5 ,97,37,,97",
 "271400,2,2,1998/10/01 10:45:00,139.5336013423,35.5242448635,1,7,2811315,9 ,11,37,,8",
 "400200,4,4,1998/10/01 10:45:00,139.5525989511,35.8426295878,2,15,3115006,15,3 ,25,,1",
 "616400,4,7,1998/10/01 10:45:00,139.6538618336,35.6069652156,2,13,0433105,15,3 ,50,,12",
 "616400,4,8,1998/10/01 10:45:00,139.6538618336,35.6069652156,2,13,0433105,15,3 ,50,,1",
 "731400,2,2,1998/10/01 10:45:00,139.4774690615,35.6995562139,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:45:00,139.3112536047,35.662028717,2,3,0930110,13,2 ,35,,9",
 "685400,2,2,1998/10/01 10:45:00,139.871066483,35.6418567442,1,11,0634016,15,10,48,,6",
 "851000,2,2,1998/10/01 10:45:00,139.5804733054,35.5007841574,2,11,1330011,15,7 ,45,,6",
 "862500,2,2,1998/10/01 10:45:00,139.5803836828,35.5409883638,2,6,1321110,15,5 ,29,,2",
 "706100,2,4,1998/10/01 10:45:00,139.7001308174,35.6893837811,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:46:00,139.911979132,35.9107982947,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:46:00,139.7497537144,35.6952751942,1,11,2910009,8 ,1 ,42,,8",
 "23000,4,6,1998/10/01 10:46:00,140.0432402114,35.7083180748,2,3,4123006,5 ,4 ,26,,1",
 "80500,4,5,1998/10/01 10:46:00,140.1243755155,35.7250562176,1,14,4413233,5 ,10,33,,10",
 "101200,2,4,1998/10/01 10:46:00,140.1225981325,35.5981770358,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:46:00,139.7805174111,35.6746276727,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:46:00,139.6537432873,35.4866292344,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:46:00,140.1716111544,35.6841884226,2,14,4416010,15,4 ,36,,1",
 "271400,2,2,1998/10/01 10:46:00,139.5348572649,35.5237944072,1,7,2811315,9 ,11,37,,8",
 "400200,4,4,1998/10/01 10:46:00,139.5528996404,35.8429186992,2,15,3115006,15,3 ,25,,1",
 "529000,4,4,1998/10/01 10:46:00,139.0833536971,35.9998274322,2,6,3910031,14,4 ,34,,6",
 "616400,4,8,1998/10/01 10:46:00,139.6544229816,35.6068232624,2,13,0433105,15,3 ,50,,1",
 "731400,2,2,1998/10/01 10:46:00,139.4775832421,35.6992429244,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:46:00,139.3115715695,35.6619586506,2,3,0930110,13,2 ,35,,9",
 "862500,2,2,1998/10/01 10:46:00,139.5813351587,35.5411728352,2,6,1321110,15,5 ,29,,2",
 "851000,2,2,1998/10/01 10:46:00,139.5826472119,35.499572965,2,11,1330011,15,7 ,45,,6",
 "685400,2,2,1998/10/01 10:46:00,139.8696492975,35.642375965,1,11,0634016,15,10,48,,6",
 "706100,2,4,1998/10/01 10:46:00,139.7056158864,35.6905762014,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:47:00,139.9119788964,35.9107979248,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:47:00,139.7468853133,35.6945019972,1,11,2910009,8 ,1 ,42,,8",
 "23000,4,6,1998/10/01 10:47:00,140.0413517111,35.7085976602,2,3,4123006,5 ,4 ,26,,1",
 "80500,4,5,1998/10/01 10:47:00,140.1243753246,35.7250560584,1,14,4413233,5 ,10,33,,10",
 "101200,2,4,1998/10/01 10:47:00,140.1202120672,35.6029925094,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:47:00,139.7781133095,35.676564711,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:47:00,139.6491493467,35.4855205066,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:47:00,140.1707214987,35.6840948766,2,14,4416010,15,4 ,36,,1",
 "271400,2,2,1998/10/01 10:47:00,139.536392408,35.5241786053,1,7,2811315,9 ,11,37,,8",
 "400200,4,4,1998/10/01 10:47:00,139.5532003319,35.84320781,2,15,3115006,15,3 ,25,,1",
 "529000,4,4,1998/10/01 10:47:00,139.0833662765,36.0011456518,2,6,3910031,14,4 ,34,,6",
 "616400,4,8,1998/10/01 10:47:00,139.6549841276,35.6066813065,2,13,0433105,15,3 ,50,,1",
 "731400,2,2,1998/10/01 10:47:00,139.4777013669,35.6989305811,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:47:00,139.3118895368,35.661888593,2,3,0930110,13,2 ,35,,9",
 "851000,2,2,1998/10/01 10:47:00,139.5832090227,35.4973756453,2,11,1330011,15,7 ,45,,6",
 "862500,2,2,1998/10/01 10:47:00,139.5822866391,35.5413572992,2,6,1321110,15,5 ,29,,2",
 "685400,2,2,1998/10/01 10:47:00,139.8682778095,35.6429616196,1,11,0634016,15,10,48,,6",
 "706100,2,4,1998/10/01 10:47:00,139.7115630979,35.691365754,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:48:00,139.9119786608,35.910797555,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:48:00,139.7461474088,35.6922300574,1,11,2910009,8 ,1 ,42,,8",
 "23000,4,6,1998/10/01 10:48:00,140.0395922247,35.7089805992,2,3,4123006,5 ,4 ,26,,1",
 "80500,4,5,1998/10/01 10:48:00,140.1243751338,35.7250558992,1,14,4413233,5 ,10,33,,10",
 "101200,2,4,1998/10/01 10:48:00,140.1178557844,35.6078179306,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:48:00,139.7806357394,35.6807189879,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:48:00,139.6443836114,35.4848793688,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:48:00,140.1697678183,35.6839152341,2,14,4416010,15,4 ,36,,1",
 "271400,2,2,1998/10/01 10:48:00,139.5379032124,35.5245857703,1,7,2811315,9 ,11,37,,8",
 "400200,4,4,1998/10/01 10:48:00,139.553212642,35.8434927709,2,15,3115006,15,3 ,25,,1",
 "529000,4,4,1998/10/01 10:48:00,139.083579969,36.001824445,2,6,3910031,14,4 ,34,,6",
 "616400,4,8,1998/10/01 10:48:00,139.6555452717,35.606539348,2,13,0433105,15,3 ,50,,1",
 "731400,2,2,1998/10/01 10:48:00,139.4778144563,35.6986170166,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:48:00,139.3122075036,35.6618185345,2,3,0930110,13,2 ,35,,9",
 "851000,2,2,1998/10/01 10:48:00,139.5847726878,35.4957928333,2,11,1330011,15,7 ,45,,6",
 "862500,2,2,1998/10/01 10:48:00,139.5830147897,35.5412064673,2,6,1321110,15,5 ,29,,2",
 "685400,2,2,1998/10/01 10:48:00,139.8668592018,35.6434782653,1,11,0634016,15,10,48,,6",
 "706100,2,4,1998/10/01 10:48:00,139.7176786439,35.6913232611,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:49:00,139.9119784252,35.9107971851,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:49:00,139.7456233245,35.6897997643,1,11,2910009,8 ,1 ,42,,8",
 "23000,4,6,1998/10/01 10:49:00,140.0382064059,35.7093247386,2,3,4123006,5 ,4 ,26,,1",
 "80500,4,5,1998/10/01 10:49:00,140.1243749429,35.72505574,1,14,4413233,5 ,10,33,,10",
 "101200,2,4,1998/10/01 10:49:00,140.1145319716,35.6121509593,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:49:00,139.7831848895,35.6848206594,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:49:00,139.6398526833,35.483480134,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:49:00,140.1688028002,35.6837880764,2,14,4416010,15,4 ,36,,1",
 "271400,2,2,1998/10/01 10:49:00,139.5391609138,35.5252273494,1,7,2811315,9 ,11,37,,8",
 "400200,4,4,1998/10/01 10:49:00,139.5528948376,35.843769403,2,15,3115006,15,3 ,25,,1",
 "529000,4,4,1998/10/01 10:49:00,139.0825955037,36.0027671576,2,6,3910031,14,4 ,34,,6",
 "616400,4,8,1998/10/01 10:49:00,139.6561064138,35.6063973869,2,13,0433105,15,3 ,50,,1",
 "731400,2,2,1998/10/01 10:49:00,139.4779503229,35.6983096914,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:49:00,139.3125254699,35.6617484751,2,3,0930110,13,2 ,35,,9",
 "851000,2,2,1998/10/01 10:49:00,139.5872610083,35.4949382366,2,11,1330011,15,7 ,45,,6",
 "862500,2,2,1998/10/01 10:49:00,139.5829587558,35.5404226287,2,6,1321110,15,5 ,29,,2",
 "685400,2,2,1998/10/01 10:49:00,139.8654439065,35.6440009617,1,11,0634016,15,10,48,,6",
 "706100,2,4,1998/10/01 10:49:00,139.7236438028,35.6923039707,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:50:00,139.9119781896,35.9107968153,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:50:00,139.7451529056,35.6873949393,1,11,2910009,8 ,1 ,42,,8",
 "23000,4,6,1998/10/01 10:50:00,140.0367149759,35.7091542589,2,3,4123006,5 ,4 ,26,,1",
 "23000,4,7,1998/10/01 10:50:00,140.0367149759,35.7091542589,2,3,4123006,5 ,4 ,26,,12",
 "80500,4,5,1998/10/01 10:50:00,140.1243747521,35.7250555809,1,14,4413233,5 ,10,33,,10",
 "101200,2,4,1998/10/01 10:50:00,140.1097874917,35.6156070199,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:50:00,139.7802436683,35.688717872,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:50:00,139.6359767643,35.481219571,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:50:00,140.167844801,35.6836237259,2,14,4416010,15,4 ,36,,1",
 "271400,2,2,1998/10/01 10:50:00,139.5402409066,35.5244861079,1,7,2811315,9 ,11,37,,8",
 "400200,4,4,1998/10/01 10:50:00,139.5525696559,35.8440403735,2,15,3115006,15,3 ,25,,1",
 "529000,4,4,1998/10/01 10:50:00,139.0825874917,36.0028979859,2,6,3910031,14,4 ,34,,6",
 "616400,4,8,1998/10/01 10:50:00,139.6566675539,35.6062554231,2,13,0433105,15,3 ,50,,1",
 "731400,2,2,1998/10/01 10:50:00,139.4781211321,35.6980166681,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:50:00,139.312844661,35.6616824694,2,3,0930110,13,2 ,35,,9",
 "685400,2,2,1998/10/01 10:50:00,139.8640233772,35.6445141117,1,11,0634016,15,10,48,,6",
 "851000,2,2,1998/10/01 10:50:00,139.5877081241,35.4927295644,2,11,1330011,15,7 ,45,,6",
 "862500,2,2,1998/10/01 10:50:00,139.5823384418,35.5398047745,2,6,1321110,15,5 ,29,,2",
 "862500,3,3,1998/10/01 10:50:00,139.5823384418,35.5398047745,2,6,1321110,15,97,29,,97",
 "706100,2,4,1998/10/01 10:50:00,139.7295694966,35.6911268525,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:51:00,139.911977954,35.9107964455,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:51:00,139.744583144,35.6849710058,1,11,2910009,8 ,1 ,42,,8",
 "23000,4,7,1998/10/01 10:51:00,140.0417876164,35.7134705707,2,3,4123006,5 ,4 ,26,,12",
 "80500,4,5,1998/10/01 10:51:00,140.1243745612,35.7250554217,1,14,4413233,5 ,10,33,,10",
 "101200,2,4,1998/10/01 10:51:00,140.1059025417,35.6197178889,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:51:00,139.7771334452,35.6925803761,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:51:00,139.6330234138,35.478079196,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:51:00,140.1668868055,35.6834593678,2,14,4416010,15,4 ,36,,1",
 "142600,6,6,1998/10/01 10:51:00,140.1114383958,35.6487616371,2,14,4013104,14,4 ,27,,6",
 "271400,2,2,1998/10/01 10:51:00,139.5415388171,35.5236879379,1,7,2811315,9 ,11,37,,8",
 "374900,4,4,1998/10/01 10:51:00,139.7763539755,35.6874954455,1,4,3040108,4 ,9 ,71,,6",
 "400200,4,4,1998/10/01 10:51:00,139.5522486995,35.8443145897,2,15,3115006,15,3 ,25,,1",
 "529000,4,4,1998/10/01 10:51:00,139.0816519048,36.0038621221,2,6,3910031,14,4 ,34,,6",
 "616400,4,8,1998/10/01 10:51:00,139.6572286921,35.6061134568,2,13,0433105,15,3 ,50,,1",
 "621000,4,6,1998/10/01 10:51:00,139.7058194216,35.6812602391,2,10,0436007,14,3 ,45,,1",
 "662400,2,2,1998/10/01 10:51:00,139.8174015408,35.7899720969,2,3,0612408,13,2 ,51,,2",
 "731400,2,2,1998/10/01 10:51:00,139.4783573115,35.6977526142,2,10,0771111,14,4 ,32,,6",
 "793500,2,2,1998/10/01 10:51:00,139.6472686368,35.5103774245,2,11,1414207,14,4 ,39,,1",
 "786600,3,5,1998/10/01 10:51:00,139.31316608,35.6616238368,2,3,0930110,13,2 ,35,,9",
 "851000,2,2,1998/10/01 10:51:00,139.5891367374,35.4908215367,2,11,1330011,15,7 ,45,,6",
 "685400,2,2,1998/10/01 10:51:00,139.8625996965,35.6450213625,1,11,0634016,15,10,48,,6",
 "706100,2,4,1998/10/01 10:51:00,139.7356033289,35.6909709171,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:52:00,139.9119777184,35.9107960756,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:52:00,139.7445523609,35.6825462717,1,11,2910009,8 ,1 ,42,,8",
 "23000,4,7,1998/10/01 10:52:00,140.043766644,35.7191938295,2,3,4123006,5 ,4 ,26,,12",
 "80500,4,5,1998/10/01 10:52:00,140.1243743704,35.7250552625,1,14,4413233,5 ,10,33,,10",
 "101200,2,4,1998/10/01 10:52:00,140.1021678076,35.6239236756,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:52:00,139.7755031095,35.6969957002,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:52:00,139.6301002478,35.4749201732,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:52:00,140.165928814,35.6832950021,2,14,4416010,15,4 ,36,,1",
 "142600,6,6,1998/10/01 10:52:00,140.1096498372,35.6488086242,2,14,4013104,14,4 ,27,,6",
 "271400,2,2,1998/10/01 10:52:00,139.5426381256,35.5241776161,1,7,2811315,9 ,11,37,,8",
 "374900,4,4,1998/10/01 10:52:00,139.7766123147,35.6860208859,1,4,3040108,4 ,9 ,71,,6",
 "400200,4,4,1998/10/01 10:52:00,139.5519354392,35.8445947174,2,15,3115006,15,3 ,25,,1",
 "529000,4,4,1998/10/01 10:52:00,139.0825675791,36.0052373952,2,6,3910031,14,4 ,34,,6",
 "616400,4,8,1998/10/01 10:52:00,139.6577898283,35.6059714878,2,13,0433105,15,3 ,50,,1",
 "621000,4,6,1998/10/01 10:52:00,139.7046979433,35.6809369296,2,10,0436007,14,3 ,45,,1",
 "662400,2,2,1998/10/01 10:52:00,139.8159240996,35.78903552,2,3,0612408,13,2 ,51,,2",
 "731400,2,2,1998/10/01 10:52:00,139.478596328,35.6974902647,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:52:00,139.3134874987,35.6615652033,2,3,0930110,13,2 ,35,,9",
 "793500,2,2,1998/10/01 10:52:00,139.6472693053,35.5103773241,2,11,1414207,14,4 ,39,,1",
 "851000,2,2,1998/10/01 10:52:00,139.5907236611,35.4890543217,2,11,1330011,15,7 ,45,,6",
 "685400,2,2,1998/10/01 10:52:00,139.861167905,35.6455132668,1,11,0634016,15,10,48,,6",
 "706100,2,4,1998/10/01 10:52:00,139.7414240233,35.6926848225,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:53:00,139.9119774829,35.9107957058,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:53:00,139.7458035069,35.6803032899,1,11,2910009,8 ,1 ,42,,8",
 "23000,4,7,1998/10/01 10:53:00,140.0384628886,35.7203986372,2,3,4123006,5 ,4 ,26,,12",
 "80500,4,5,1998/10/01 10:53:00,140.1243741796,35.7250551033,1,14,4413233,5 ,10,33,,10",
 "101200,2,4,1998/10/01 10:53:00,140.0988593597,35.6283557578,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:53:00,139.7745029187,35.7005204874,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:53:00,139.6272555888,35.4717140532,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:53:00,140.1649708263,35.6831306286,2,14,4416010,15,4 ,36,,1",
 "142600,6,6,1998/10/01 10:53:00,140.1099905924,35.6473464757,2,14,4013104,14,4 ,27,,6",
 "271400,2,2,1998/10/01 10:53:00,139.5437614148,35.5248958551,1,7,2811315,9 ,11,37,,8",
 "374900,4,4,1998/10/01 10:53:00,139.7767590391,35.6845304936,1,4,3040108,4 ,9 ,71,,6",
 "400200,4,4,1998/10/01 10:53:00,139.5516208136,35.8448738281,2,15,3115006,15,3 ,25,,1",
 "529000,4,4,1998/10/01 10:53:00,139.0835060535,36.0066025347,2,6,3910031,14,4 ,34,,6",
 "616400,4,8,1998/10/01 10:53:00,139.6583509625,35.6058295162,2,13,0433105,15,3 ,50,,1",
 "621000,4,6,1998/10/01 10:53:00,139.7037969877,35.6813905501,2,10,0436007,14,3 ,45,,1",
 "662400,2,2,1998/10/01 10:53:00,139.8132597094,35.7893005526,2,3,0612408,13,2 ,51,,2",
 "731400,2,2,1998/10/01 10:53:00,139.4789316681,35.6973137068,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:53:00,139.3138089168,35.661506569,2,3,0930110,13,2 ,35,,9",
 "793500,2,2,1998/10/01 10:53:00,139.6472699738,35.5103772237,2,11,1414207,14,4 ,39,,1",
 "851000,2,2,1998/10/01 10:53:00,139.5929023317,35.4878317336,2,11,1330011,15,7 ,45,,6",
 "685400,2,2,1998/10/01 10:53:00,139.8597360959,35.646005154,1,11,0634016,15,10,48,,6",
 "706100,2,4,1998/10/01 10:53:00,139.7471715271,35.6945477817,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:54:00,139.9119772473,35.9107953359,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:54:00,139.7463887609,35.6779754162,1,11,2910009,8 ,1 ,42,,8",
 "23000,4,7,1998/10/01 10:54:00,140.0317226349,35.7179430195,2,3,4123006,5 ,4 ,26,,12",
 "80500,4,5,1998/10/01 10:54:00,140.1243739887,35.7250549441,1,14,4413233,5 ,10,33,,10",
 "101200,2,4,1998/10/01 10:54:00,140.0956938132,35.6328622243,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:54:00,139.7749096712,35.7051257982,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:54:00,139.6244809559,35.4684666269,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:54:00,140.1640022487,35.6830154495,2,14,4416010,15,4 ,36,,1",
 "142600,6,6,1998/10/01 10:54:00,140.1107031763,35.6459356132,2,14,4013104,14,4 ,27,,6",
 "271400,2,2,1998/10/01 10:54:00,139.5448844929,35.5256421504,1,7,2811315,9 ,11,37,,8",
 "374900,4,4,1998/10/01 10:54:00,139.776447732,35.6831010493,1,4,3040108,4 ,9 ,71,,6",
 "400200,4,4,1998/10/01 10:54:00,139.5513056515,35.8451525397,2,15,3115006,15,3 ,25,,1",
 "529000,4,4,1998/10/01 10:54:00,139.0844445598,36.007967667,2,6,3910031,14,4 ,34,,6",
 "529000,5,5,1998/10/01 10:54:00,139.0844445598,36.007967667,2,6,3910031,14,97,34,,97",
 "616400,4,8,1998/10/01 10:54:00,139.6589120947,35.605687542,2,13,0433105,15,3 ,50,,1",
 "621000,4,6,1998/10/01 10:54:00,139.7029605137,35.6820804677,2,10,0436007,14,3 ,45,,1",
 "662400,2,2,1998/10/01 10:54:00,139.8106078777,35.7896462172,2,3,0612408,13,2 ,51,,2",
 "731400,2,2,1998/10/01 10:54:00,139.4792237779,35.697127345,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:54:00,139.314130813,35.6614497236,2,3,0930110,13,2 ,35,,9",
 "793500,2,2,1998/10/01 10:54:00,139.6472706423,35.5103771234,2,11,1414207,14,4 ,39,,1",
 "851000,2,2,1998/10/01 10:54:00,139.5944636425,35.4860879776,2,11,1330011,15,7 ,45,,6",
 "685400,2,2,1998/10/01 10:54:00,139.8583042692,35.6464970242,1,11,0634016,15,10,48,,6",
 "706100,2,4,1998/10/01 10:54:00,139.7531651412,35.6957597564,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:55:00,139.9119770117,35.9107949661,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:55:00,139.7469817454,35.6755573848,1,11,2910009,8 ,1 ,42,,8",
 "23000,4,7,1998/10/01 10:55:00,140.0251465413,35.7151970815,2,3,4123006,5 ,4 ,26,,12",
 "80500,4,5,1998/10/01 10:55:00,140.1243737979,35.7250547849,1,14,4413233,5 ,10,33,,10",
 "101200,2,4,1998/10/01 10:55:00,140.092554516,35.6373809562,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:55:00,139.7763824283,35.7085049581,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:55:00,139.6217023004,35.4652215685,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:55:00,140.1630329528,35.6829036114,2,14,4416010,15,4 ,36,,1",
 "142600,6,6,1998/10/01 10:55:00,140.1109902342,35.6447059762,2,14,4013104,14,4 ,27,,6",
 "271400,2,2,1998/10/01 10:55:00,139.5464395229,35.5260976317,1,7,2811315,9 ,11,37,,8",
 "374900,4,4,1998/10/01 10:55:00,139.775636638,35.6817601819,1,4,3040108,4 ,9 ,71,,6",
 "400200,4,4,1998/10/01 10:55:00,139.5509778861,35.845421333,2,15,3115006,15,3 ,25,,1",
 "621000,4,6,1998/10/01 10:55:00,139.7021212889,35.6827681724,2,10,0436007,14,3 ,45,,1",
 "616400,4,8,1998/10/01 10:55:00,139.659473225,35.6055455651,2,13,0433105,15,3 ,50,,1",
 "616400,5,9,1998/10/01 10:55:00,139.659473225,35.6055455651,2,13,0433105,15,97,50,,97",
 "662400,2,2,1998/10/01 10:55:00,139.807928234,35.7895457333,2,3,0612408,13,2 ,51,,2",
 "731400,2,2,1998/10/01 10:55:00,139.4792328807,35.6968005611,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:55:00,139.3144529545,35.6613937974,2,3,0930110,13,2 ,35,,9",
 "793500,2,2,1998/10/01 10:55:00,139.6472713108,35.510377023,2,11,1414207,14,4 ,39,,1",
 "793500,2,3,1998/10/01 10:55:00,139.6472713108,35.510377023,2,11,1414207,14,4 ,39,,10",
 "851000,2,2,1998/10/01 10:55:00,139.5954907063,35.4840517158,2,11,1330011,15,7 ,45,,6",
 "706100,2,4,1998/10/01 10:55:00,139.7592888907,35.6960828785,1,14,0730209,10,7 ,26,,12",
 "685400,2,2,1998/10/01 10:55:00,139.8567994051,35.6467945194,1,11,0634016,15,10,48,,6",
 "69000,2,2,1998/10/01 10:56:00,139.9119767761,35.9107945963,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:56:00,139.7465242479,35.6735688105,1,11,2910009,8 ,1 ,42,,8",
 "23000,4,7,1998/10/01 10:56:00,140.0185533023,35.7124959221,2,3,4123006,5 ,4 ,26,,12",
 "80500,4,5,1998/10/01 10:56:00,140.124373607,35.7250546257,1,14,4413233,5 ,10,33,,10",
 "101200,2,4,1998/10/01 10:56:00,140.0893932956,35.6418896308,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:56:00,139.7783292025,35.7125493563,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:56:00,139.6187726897,35.4620672249,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:56:00,140.1620636595,35.6827917655,2,14,4416010,15,4 ,36,,1",
 "142600,6,6,1998/10/01 10:56:00,140.1118277688,35.643389505,2,14,4013104,14,4 ,27,,6",
 "271400,2,2,1998/10/01 10:56:00,139.5480276999,35.5264775961,1,7,2811315,9 ,11,37,,8",
 "374900,4,4,1998/10/01 10:56:00,139.774959639,35.6804026379,1,4,3040108,4 ,9 ,71,,6",
 "400200,4,4,1998/10/01 10:56:00,139.5505478914,35.8455215781,2,15,3115006,15,3 ,25,,1",
 "621000,4,6,1998/10/01 10:56:00,139.7016090353,35.6836392244,2,10,0436007,14,3 ,45,,1",
 "662400,2,2,1998/10/01 10:56:00,139.8052455643,35.7894476626,2,3,0612408,13,2 ,51,,2",
 "731400,2,2,1998/10/01 10:56:00,139.4792838423,35.6964767924,2,10,0771111,14,4 ,32,,6",
 "793500,2,3,1998/10/01 10:56:00,139.6499785568,35.5100666527,2,11,1414207,14,4 ,39,,10",
 "786600,3,5,1998/10/01 10:56:00,139.3147750956,35.6613378702,2,3,0930110,13,2 ,35,,9",
 "851000,2,2,1998/10/01 10:56:00,139.5978546606,35.4829001291,2,11,1330011,15,7 ,45,,6",
 "685400,2,2,1998/10/01 10:56:00,139.8552541433,35.6469246748,1,11,0634016,15,10,48,,6",
 "706100,2,4,1998/10/01 10:56:00,139.7647548068,35.6945131381,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:57:00,139.9119765405,35.9107942264,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:57:00,139.7448779462,35.6720869953,1,11,2910009,8 ,1 ,42,,8",
 "23000,4,7,1998/10/01 10:57:00,140.0112085302,35.7126354681,2,3,4123006,5 ,4 ,26,,12",
 "80500,4,5,1998/10/01 10:57:00,140.1243734162,35.7250544665,1,14,4413233,5 ,10,33,,10",
 "80500,4,6,1998/10/01 10:57:00,140.1243734162,35.7250544665,1,14,4413233,5 ,10,33,,1",
 "101200,2,4,1998/10/01 10:57:00,140.0862317228,35.6463982213,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:57:00,139.7782300118,35.7161862021,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:57:00,139.6158245753,35.4589240152,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:57:00,140.1610943688,35.6826799118,2,14,4416010,15,4 ,36,,1",
 "142600,6,6,1998/10/01 10:57:00,140.1127342855,35.6421240275,2,14,4013104,14,4 ,27,,6",
 "271400,2,2,1998/10/01 10:57:00,139.5493168396,35.5270139112,1,7,2811315,9 ,11,37,,8",
 "374900,4,4,1998/10/01 10:57:00,139.7741422421,35.679064269,1,4,3040108,4 ,9 ,71,,6",
 "400200,4,4,1998/10/01 10:57:00,139.5500845397,35.8455432352,2,15,3115006,15,3 ,25,,1",
 "621000,4,6,1998/10/01 10:57:00,139.7011228483,35.6845248967,2,10,0436007,14,3 ,45,,1",
 "662400,2,2,1998/10/01 10:57:00,139.802562841,35.7893468984,2,3,0612408,13,2 ,51,,2",
 "731400,2,2,1998/10/01 10:57:00,139.4793450846,35.6961537643,2,10,0771111,14,4 ,32,,6",
 "793500,2,3,1998/10/01 10:57:00,139.6526490626,35.5096755773,2,11,1414207,14,4 ,39,,10",
 "786600,3,5,1998/10/01 10:57:00,139.3150972363,35.6612819423,2,3,0930110,13,2 ,35,,9",
 "851000,2,2,1998/10/01 10:57:00,139.6001409052,35.4816566039,2,11,1330011,15,7 ,45,,6",
 "685400,2,2,1998/10/01 10:57:00,139.8537061759,35.6470307629,1,11,0634016,15,10,48,,6",
 "706100,2,4,1998/10/01 10:57:00,139.770586649,35.6957942123,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:58:00,139.9119763049,35.9107938566,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:58:00,139.7427826834,35.6706558752,1,11,2910009,8 ,1 ,42,,8",
 "23000,4,7,1998/10/01 10:58:00,140.0039854378,35.7114761503,2,3,4123006,5 ,4 ,26,,12",
 "80500,4,6,1998/10/01 10:58:00,140.1142406588,35.7160357743,1,14,4413233,5 ,10,33,,1",
 "101200,2,4,1998/10/01 10:58:00,140.0820670647,35.6502102696,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:58:00,139.7791209627,35.7203812374,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:58:00,139.6128251596,35.4558180519,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:58:00,140.1601253173,35.6825684477,2,14,4416010,15,4 ,36,,1",
 "142600,6,6,1998/10/01 10:58:00,140.1130216279,35.6407113031,2,14,4013104,14,4 ,27,,6",
 "271400,2,2,1998/10/01 10:58:00,139.5489200454,35.5281104088,1,7,2811315,9 ,11,37,,8",
 "374900,4,4,1998/10/01 10:58:00,139.7733242453,35.6777261557,1,4,3040108,4 ,9 ,71,,6",
 "400200,4,4,1998/10/01 10:58:00,139.549621562,35.8455069661,2,15,3115006,15,3 ,25,,1",
 "621000,4,6,1998/10/01 10:58:00,139.7006366505,35.685410567,2,10,0436007,14,3 ,45,,1",
 "662400,2,2,1998/10/01 10:58:00,139.7998787516,35.7892705259,2,3,0612408,13,2 ,51,,2",
 "731400,2,2,1998/10/01 10:58:00,139.4797312529,35.6961061389,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 10:58:00,139.3154195557,35.6612268496,2,3,0930110,13,2 ,35,,9",
 "793500,2,3,1998/10/01 10:58:00,139.6550525098,35.5102919955,2,11,1414207,14,4 ,39,,10",
 "851000,2,2,1998/10/01 10:58:00,139.6018048395,35.4801989644,2,11,1330011,15,7 ,45,,6",
 "685400,2,2,1998/10/01 10:58:00,139.8521580089,35.6471355565,1,11,0634016,15,10,48,,6",
 "706100,2,4,1998/10/01 10:58:00,139.7767664335,35.6955325314,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 10:59:00,139.9119760693,35.9107934867,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 10:59:00,139.7409032743,35.6687231116,1,11,2910009,8 ,1 ,42,,8",
 "23000,4,7,1998/10/01 10:59:00,139.9969863194,35.7095908117,2,3,4123006,5 ,4 ,26,,12",
 "80500,4,6,1998/10/01 10:59:00,140.1041155863,35.7093807033,1,14,4413233,5 ,10,33,,1",
 "101200,2,4,1998/10/01 10:59:00,140.0759483034,35.6514688723,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 10:59:00,139.7754560006,35.7239252654,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 10:59:00,139.6090187723,35.4533970239,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 10:59:00,140.1591466637,35.6825876459,2,14,4416010,15,4 ,36,,1",
 "142600,6,6,1998/10/01 10:59:00,140.1122512736,35.6394594777,2,14,4013104,14,4 ,27,,6",
 "271400,2,2,1998/10/01 10:59:00,139.5499837265,35.5291458529,1,7,2811315,9 ,11,37,,8",
 "374900,4,4,1998/10/01 10:59:00,139.7724857882,35.676396644,1,4,3040108,4 ,9 ,71,,6",
 "400200,4,4,1998/10/01 10:59:00,139.5491583189,35.8455202583,2,15,3115006,15,3 ,25,,1",
 "621000,4,6,1998/10/01 10:59:00,139.700150442,35.6862962351,2,10,0436007,14,3 ,45,,1",
 "662400,2,2,1998/10/01 10:59:00,139.7972466075,35.7894021613,2,3,0612408,13,2 ,51,,2",
 "731400,2,2,1998/10/01 10:59:00,139.4801300814,35.6960816602,2,10,0771111,14,4 ,32,,6",
 "793500,2,3,1998/10/01 10:59:00,139.6576698447,35.5108781477,2,11,1414207,14,4 ,39,,10",
 "786600,3,5,1998/10/01 10:59:00,139.3157458425,35.6611902815,2,3,0930110,13,2 ,35,,9",
 "851000,2,2,1998/10/01 10:59:00,139.6035449009,35.4788172462,2,11,1330011,15,7 ,45,,6",
 "685400,2,2,1998/10/01 10:59:00,139.8509004689,35.6476953958,1,11,0634016,15,10,48,,6",
 "706100,2,4,1998/10/01 10:59:00,139.7817514671,35.6929688612,1,14,0730209,10,7 ,26,,12",
 "786600,3,5,1998/10/01 11:00:00,139.3160721291,35.6611537126,2,3,0930110,13,2 ,35,,9",
 "69000,2,2,1998/10/01 11:00:00,139.9119758338,35.9107931169,1,4,4312114,13,2 ,52,,6",
 "280600,7,7,1998/10/01 11:00:00,139.7392175646,35.6667159309,1,11,2910009,8 ,1 ,42,,8",
 "23000,4,7,1998/10/01 11:00:00,139.9899415526,35.7080315059,2,3,4123006,5 ,4 ,26,,12",
 "80500,4,6,1998/10/01 11:00:00,140.0919325429,35.6979947117,1,14,4413233,5 ,10,33,,1",
 "80500,5,7,1998/10/01 11:00:00,140.0919325429,35.6979947117,1,14,4413233,5 ,97,33,,97",
 "101200,2,4,1998/10/01 11:00:00,140.0697117734,35.652443454,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 11:00:00,139.7716255141,35.727349497,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 11:00:00,139.6050071741,35.4511781678,1,8,2410601,7 ,3 ,15,,12",
 "82800,2,2,1998/10/01 11:00:00,140.1581680096,35.6826068361,2,14,4416010,15,4 ,36,,1",
 "82800,3,3,1998/10/01 11:00:00,140.1581680096,35.6826068361,2,14,4416010,15,97,36,,97",
 "142600,6,6,1998/10/01 11:00:00,140.1128715763,35.6380266977,2,14,4013104,14,4 ,27,,6",
 "271400,2,2,1998/10/01 11:00:00,139.550993772,35.5302172766,1,7,2811315,9 ,11,37,,8",
 "374900,4,4,1998/10/01 11:00:00,139.7716442435,35.6750683624,1,4,3040108,4 ,9 ,71,,6",
 "400200,4,4,1998/10/01 11:00:00,139.5489151284,35.845341548,2,15,3115006,15,3 ,25,,1",
 "400200,5,5,1998/10/01 11:00:00,139.5489151284,35.845341548,2,15,3115006,15,97,25,,97",
 "621000,4,6,1998/10/01 11:00:00,139.6996642227,35.687181901,2,10,0436007,14,3 ,45,,1",
 "662400,2,2,1998/10/01 11:00:00,139.7959034713,35.7906029944,2,3,0612408,13,2 ,51,,2",
 "662400,3,3,1998/10/01 11:00:00,139.7959034713,35.7906029944,2,3,0612408,13,97,51,,97",
 "731400,2,2,1998/10/01 11:00:00,139.4805307194,35.6960876332,2,10,0771111,14,4 ,32,,6",
 "793500,2,3,1998/10/01 11:00:00,139.6603690905,35.511021971,2,11,1414207,14,4 ,39,,10",
 "685400,2,2,1998/10/01 11:00:00,139.8510182251,35.6489543396,1,11,0634016,15,10,48,,6",
 "685400,3,3,1998/10/01 11:00:00,139.8510182251,35.6489543396,1,11,0634016,15,97,48,,97",
 "851000,2,2,1998/10/01 11:00:00,139.6060249381,35.4780973022,2,11,1330011,15,7 ,45,,6",
 "706100,2,4,1998/10/01 11:00:00,139.7861577683,35.6894318759,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 11:01:00,139.9119755982,35.9107927471,1,4,4312114,13,2 ,52,,6",
 "128800,4,6,1998/10/01 11:01:00,140.4986975329,35.8881626491,1,14,4831221,15,3 ,26,,2",
 "280600,7,7,1998/10/01 11:01:00,139.7387450697,35.6642790513,1,11,2910009,8 ,1 ,42,,8",
 "354200,2,2,1998/10/01 11:01:00,139.7517085746,35.7932933108,2,12,3017007,14,4 ,34,,2",
 "23000,4,7,1998/10/01 11:01:00,139.9827068995,35.7068707462,2,3,4123006,5 ,4 ,26,,12",
 "101200,2,4,1998/10/01 11:01:00,140.064278529,35.6551388224,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 11:01:00,139.7681803403,35.7310082665,2,11,4061110,4 ,5 ,30,,12",
 "11500,2,2,1998/10/01 11:01:00,139.8922633918,35.6766732514,2,7,4115209,14,4 ,32,,2",
 "230000,2,4,1998/10/01 11:01:00,139.601570744,35.4484062096,1,8,2410601,7 ,3 ,15,,12",
 "142600,6,6,1998/10/01 11:01:00,140.1131807542,35.6365215028,2,14,4013104,14,4 ,27,,6",
 "186300,4,4,1998/10/01 11:01:00,140.1433486923,35.9966803128,1,4,5217006,13,3 ,53,,3",
 "190900,2,2,1998/10/01 11:01:00,139.9698019398,35.9585613226,2,11,5410113,14,4 ,37,,6",
 "202400,2,2,1998/10/01 11:01:00,140.4926327057,35.9957058835,2,10,5610009,4 ,4 ,34,,7",
 "271400,2,2,1998/10/01 11:01:00,139.5519426816,35.5313257074,1,7,2811315,9 ,11,37,,8",
 "335800,2,2,1998/10/01 11:01:00,139.5583721898,35.6322504812,2,6,2130206,14,4 ,31,,1",
 "374900,4,4,1998/10/01 11:01:00,139.7707653,35.67375646,1,4,3040108,4 ,9 ,71,,6",
 "531300,4,4,1998/10/01 11:01:00,139.099595626,36.0684893807,1,5,3920007,3 ,9 ,33,,8",
 "552000,4,4,1998/10/01 11:01:00,139.7449134198,35.5459026075,2,14,0133113,15,3 ,35,,1",
 "568100,2,2,1998/10/01 11:01:00,139.7221468334,35.681189894,2,6,0231108,14,5 ,58,,2",
 "621000,4,6,1998/10/01 11:01:00,139.6991779926,35.6880675649,2,10,0436007,14,3 ,45,,1",
 "731400,2,2,1998/10/01 11:01:00,139.4807122878,35.6959094596,2,10,0771111,14,4 ,32,,6",
 "793500,2,3,1998/10/01 11:01:00,139.6629474826,35.5115538646,2,11,1414207,14,4 ,39,,10",
 "786600,3,5,1998/10/01 11:01:00,139.3163984154,35.6611171428,2,3,0930110,13,2 ,35,,9",
 "851000,2,2,1998/10/01 11:01:00,139.6087322274,35.4778063617,2,11,1330011,15,7 ,45,,6",
 "715300,2,2,1998/10/01 11:01:00,139.4656671741,35.6538409571,1,5,0750217,9 ,10,49,,1",
 "706100,2,4,1998/10/01 11:01:00,139.7920061376,35.6880599767,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 11:02:00,139.9119753626,35.9107923772,1,4,4312114,13,2 ,52,,6",
 "128800,4,6,1998/10/01 11:02:00,140.4986955693,35.8881610459,1,14,4831221,15,3 ,26,,2",
 "280600,7,7,1998/10/01 11:02:00,139.7381993494,35.6618527089,1,11,2910009,8 ,1 ,42,,8",
 "354200,2,2,1998/10/01 11:02:00,139.7507055768,35.7925768936,2,12,3017007,14,4 ,34,,2",
 "23000,4,7,1998/10/01 11:02:00,139.9761108152,35.7041693282,2,3,4123006,5 ,4 ,26,,12",
 "101200,2,4,1998/10/01 11:02:00,140.059265432,35.658303416,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 11:02:00,139.765985762,35.7352712289,2,11,4061110,4 ,5 ,30,,12",
 "11500,2,2,1998/10/01 11:02:00,139.89179786,35.6757121173,2,7,4115209,14,4 ,32,,2",
 "230000,2,4,1998/10/01 11:02:00,139.5982777187,35.4455232747,1,8,2410601,7 ,3 ,15,,12",
 "142600,6,6,1998/10/01 11:02:00,140.1137217426,35.6351132014,2,14,4013104,14,4 ,27,,6",
 "186300,4,4,1998/10/01 11:02:00,140.140993034,35.995597123,1,4,5217006,13,3 ,53,,3",
 "190900,2,2,1998/10/01 11:02:00,139.9754196381,35.9549858684,2,11,5410113,14,4 ,37,,6",
 "202400,2,2,1998/10/01 11:02:00,140.4899572695,35.9932675708,2,10,5610009,4 ,4 ,34,,7",
 "271400,2,2,1998/10/01 11:02:00,139.5529014584,35.5324283545,1,7,2811315,9 ,11,37,,8",
 "335800,2,2,1998/10/01 11:02:00,139.5577895718,35.632721554,2,6,2130206,14,4 ,31,,1",
 "374900,4,4,1998/10/01 11:02:00,139.7699977436,35.6725581637,1,4,3040108,4 ,9 ,71,,6",
 "531300,4,4,1998/10/01 11:02:00,139.0974195652,36.0631898276,1,5,3920007,3 ,9 ,33,,8",
 "552000,4,4,1998/10/01 11:02:00,139.7448714164,35.5455510383,2,14,0133113,15,3 ,35,,1",
 "568100,2,2,1998/10/01 11:02:00,139.7208729019,35.681634644,2,6,0231108,14,5 ,58,,2",
 "621000,4,6,1998/10/01 11:02:00,139.6990109607,35.6890252812,2,10,0436007,14,3 ,45,,1",
 "731400,2,2,1998/10/01 11:02:00,139.4807169873,35.6955826137,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:02:00,139.3167247013,35.6610805721,2,3,0930110,13,2 ,35,,9",
 "793500,2,3,1998/10/01 11:02:00,139.6644923349,35.5098190204,2,11,1414207,14,4 ,39,,10",
 "851000,2,2,1998/10/01 11:02:00,139.6106370793,35.4768343576,2,11,1330011,15,7 ,45,,6",
 "715300,2,2,1998/10/01 11:02:00,139.464411566,35.65459314,1,5,0750217,9 ,10,49,,1",
 "706100,2,4,1998/10/01 11:02:00,139.7981907419,35.6880591503,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 11:03:00,139.911975127,35.9107920074,1,4,4312114,13,2 ,52,,6",
 "128800,4,6,1998/10/01 11:03:00,140.4986936057,35.8881594426,1,14,4831221,15,3 ,26,,2",
 "280600,7,7,1998/10/01 11:03:00,139.736681466,35.6597732917,1,11,2910009,8 ,1 ,42,,8",
 "354200,2,2,1998/10/01 11:03:00,139.7497079797,35.7918569784,2,12,3017007,14,4 ,34,,2",
 "23000,4,7,1998/10/01 11:03:00,139.9688958712,35.7035289667,2,3,4123006,5 ,4 ,26,,12",
 "101200,2,4,1998/10/01 11:03:00,140.0548548074,35.6620478973,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 11:03:00,139.7642002949,35.739670837,2,11,4061110,4 ,5 ,30,,12",
 "11500,2,2,1998/10/01 11:03:00,139.892246258,35.6743018756,2,7,4115209,14,4 ,32,,2",
 "230000,2,4,1998/10/01 11:03:00,139.5936387438,35.4450454372,1,8,2410601,7 ,3 ,15,,12",
 "142600,6,6,1998/10/01 11:03:00,140.1149551997,35.6343278058,2,14,4013104,14,4 ,27,,6",
 "186300,4,4,1998/10/01 11:03:00,140.1398646341,35.9958387722,1,4,5217006,13,3 ,53,,3",
 "190900,2,2,1998/10/01 11:03:00,139.9812374443,35.952123491,2,11,5410113,14,4 ,37,,6",
 "202400,2,2,1998/10/01 11:03:00,140.4882898633,35.9903400746,2,10,5610009,4 ,4 ,34,,7",
 "271400,2,2,1998/10/01 11:03:00,139.5540812073,35.5331607477,1,7,2811315,9 ,11,37,,8",
 "335800,2,2,1998/10/01 11:03:00,139.5571475194,35.6331080765,2,6,2130206,14,4 ,31,,1",
 "374900,4,4,1998/10/01 11:03:00,139.7690188851,35.6713301946,1,4,3040108,4 ,9 ,71,,6",
 "531300,4,4,1998/10/01 11:03:00,139.0978625139,36.0577057409,1,5,3920007,3 ,9 ,33,,8",
 "552000,4,4,1998/10/01 11:03:00,139.7455221851,35.5454868057,2,14,0133113,15,3 ,35,,1",
 "568100,2,2,1998/10/01 11:03:00,139.7195415882,35.6819281872,2,6,0231108,14,5 ,58,,2",
 "621000,4,6,1998/10/01 11:03:00,139.6988218395,35.6899790568,2,10,0436007,14,3 ,45,,1",
 "731400,2,2,1998/10/01 11:03:00,139.4807216868,35.6952557678,2,10,0771111,14,4 ,32,,6",
 "793500,2,3,1998/10/01 11:03:00,139.6666634768,35.5104707087,2,11,1414207,14,4 ,39,,10",
 "786600,3,5,1998/10/01 11:03:00,139.317050987,35.6610440005,2,3,0930110,13,2 ,35,,9",
 "851000,2,2,1998/10/01 11:03:00,139.6113342564,35.4749490346,2,11,1330011,15,7 ,45,,6",
 "706100,2,4,1998/10/01 11:03:00,139.8043714283,35.6882818835,1,14,0730209,10,7 ,26,,12",
 "715300,2,2,1998/10/01 11:03:00,139.463695738,35.6547110153,1,5,0750217,9 ,10,49,,1",
 "69000,2,2,1998/10/01 11:04:00,139.9119748914,35.9107916375,1,4,4312114,13,2 ,52,,6",
 "128800,4,6,1998/10/01 11:04:00,140.4986916421,35.8881578394,1,14,4831221,15,3 ,26,,2",
 "280600,7,7,1998/10/01 11:04:00,139.7364340798,35.6574084617,1,11,2910009,8 ,1 ,42,,8",
 "354200,2,2,1998/10/01 11:04:00,139.7485682569,35.791292581,2,12,3017007,14,4 ,34,,2",
 "23000,4,7,1998/10/01 11:04:00,139.9620436313,35.7057189681,2,3,4123006,5 ,4 ,26,,12",
 "101200,2,4,1998/10/01 11:04:00,140.0504230732,35.6657769372,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 11:04:00,139.7604444617,35.7430660211,2,11,4061110,4 ,5 ,30,,12",
 "11500,2,2,1998/10/01 11:04:00,139.8928838206,35.6728485113,2,7,4115209,14,4 ,32,,2",
 "230000,2,4,1998/10/01 11:04:00,139.5888628371,35.4456965883,1,8,2410601,7 ,3 ,15,,12",
 "142600,6,6,1998/10/01 11:04:00,140.1157411565,35.6334693744,2,14,4013104,14,4 ,27,,6",
 "186300,4,4,1998/10/01 11:04:00,140.1363234435,35.9947590793,1,4,5217006,13,3 ,53,,3",
 "190900,2,2,1998/10/01 11:04:00,139.9874193024,35.9496851645,2,11,5410113,14,4 ,37,,6",
 "202400,2,2,1998/10/01 11:04:00,140.4873382423,35.9871058217,2,10,5610009,4 ,4 ,34,,7",
 "271400,2,2,1998/10/01 11:04:00,139.555356591,35.5340225842,1,7,2811315,9 ,11,37,,8",
 "335800,2,2,1998/10/01 11:04:00,139.5563474325,35.6332529915,2,6,2130206,14,4 ,31,,1",
 "374900,4,4,1998/10/01 11:04:00,139.7679395045,35.6701220548,1,4,3040108,4 ,9 ,71,,6",
 "531300,4,4,1998/10/01 11:04:00,139.1003598673,36.0524493626,1,5,3920007,3 ,9 ,33,,8",
 "552000,4,4,1998/10/01 11:04:00,139.7461729527,35.5454225695,2,14,0133113,15,3 ,35,,1",
 "568100,2,2,1998/10/01 11:04:00,139.7183282242,35.6822715132,2,6,0231108,14,5 ,58,,2",
 "621000,4,6,1998/10/01 11:04:00,139.6988600383,35.6909014034,2,10,0436007,14,3 ,45,,1",
 "731400,2,2,1998/10/01 11:04:00,139.4807356708,35.6949291109,2,10,0771111,14,4 ,32,,6",
 "793500,2,3,1998/10/01 11:04:00,139.6693012698,35.5098899911,2,11,1414207,14,4 ,39,,10",
 "786600,3,5,1998/10/01 11:04:00,139.3173772725,35.661007428,2,3,0930110,13,2 ,35,,9",
 "851000,2,2,1998/10/01 11:04:00,139.6139745909,35.4746386541,2,11,1330011,15,7 ,45,,6",
 "715300,2,2,1998/10/01 11:04:00,139.4631086656,35.6543479852,1,5,0750217,9 ,10,49,,1",
 "706100,2,4,1998/10/01 11:04:00,139.8105387626,35.6886941413,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 11:05:00,139.9119746558,35.9107912677,1,4,4312114,13,2 ,52,,6",
 "128800,4,6,1998/10/01 11:05:00,140.4986896785,35.8881562361,1,14,4831221,15,3 ,26,,2",
 "128800,4,7,1998/10/01 11:05:00,140.4986896785,35.8881562361,1,14,4831221,15,3 ,26,,10",
 "280600,7,7,1998/10/01 11:05:00,139.7368462602,35.6550291884,1,11,2910009,8 ,1 ,42,,8",
 "354200,2,2,1998/10/01 11:05:00,139.747819773,35.7913785445,2,12,3017007,14,4 ,34,,2",
 "354200,3,3,1998/10/01 11:05:00,139.747819773,35.7913785445,2,12,3017007,14,97,34,,97",
 "23000,4,7,1998/10/01 11:05:00,139.9557888416,35.7088824506,2,3,4123006,5 ,4 ,26,,12",
 "101200,2,4,1998/10/01 11:05:00,140.0460061782,35.6695180921,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 11:05:00,139.7559106989,35.7458652929,2,11,4061110,4 ,5 ,30,,12",
 "11500,2,2,1998/10/01 11:05:00,139.8930810805,35.6713687352,2,7,4115209,14,4 ,32,,2",
 "230000,2,4,1998/10/01 11:05:00,139.5840868546,35.4463475502,1,8,2410601,7 ,3 ,15,,12",
 "142600,6,6,1998/10/01 11:05:00,140.1163893483,35.6320890972,2,14,4013104,14,4 ,27,,6",
 "186300,4,4,1998/10/01 11:05:00,140.1327681807,35.9936651777,1,4,5217006,13,3 ,53,,3",
 "190900,2,2,1998/10/01 11:05:00,139.9922070798,35.946529453,2,11,5410113,14,4 ,37,,6",
 "202400,2,2,1998/10/01 11:05:00,140.4904461194,35.9853530953,2,10,5610009,4 ,4 ,34,,7",
 "271400,2,2,1998/10/01 11:05:00,139.5565775135,35.5341164183,1,7,2811315,9 ,11,37,,8",
 "335800,2,2,1998/10/01 11:05:00,139.5555354797,35.6333463703,2,6,2130206,14,4 ,31,,1",
 "374900,4,4,1998/10/01 11:05:00,139.7680218214,35.6689544528,1,4,3040108,4 ,9 ,71,,6",
 "531300,4,4,1998/10/01 11:05:00,139.1025166764,36.0471128289,1,5,3920007,3 ,9 ,33,,8",
 "552000,4,4,1998/10/01 11:05:00,139.7466517386,35.5452955792,2,14,0133113,15,3 ,35,,1",
 "568100,2,2,1998/10/01 11:05:00,139.7172285454,35.6827133651,2,6,0231108,14,5 ,58,,2",
 "621000,4,6,1998/10/01 11:05:00,139.6998272499,35.690734646,2,10,0436007,14,3 ,45,,1",
 "621000,4,7,1998/10/01 11:05:00,139.6998272499,35.690734646,2,10,0436007,14,3 ,45,,12",
 "731400,2,2,1998/10/01 11:05:00,139.480750967,35.6946024807,2,10,0771111,14,4 ,32,,6",
 "793500,2,3,1998/10/01 11:05:00,139.6716269573,35.5087441449,2,11,1414207,14,4 ,39,,10",
 "786600,3,5,1998/10/01 11:05:00,139.3177035576,35.6609708547,2,3,0930110,13,2 ,35,,9",
 "851000,2,2,1998/10/01 11:05:00,139.6157191785,35.473311572,2,11,1330011,15,7 ,45,,6",
 "715300,2,2,1998/10/01 11:05:00,139.4620656314,35.655292061,1,5,0750217,9 ,10,49,,1",
 "706100,2,4,1998/10/01 11:05:00,139.8167049117,35.6889996509,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 11:06:00,139.9119744203,35.9107908978,1,4,4312114,13,2 ,52,,6",
 "128800,4,7,1998/10/01 11:06:00,140.4986893511,35.8881559688,1,14,4831221,15,3 ,26,,10",
 "280600,7,7,1998/10/01 11:06:00,139.7363972698,35.6525896983,1,11,2910009,8 ,1 ,42,,8",
 "23000,4,7,1998/10/01 11:06:00,139.948849799,35.7094689116,2,3,4123006,5 ,4 ,26,,12",
 "101200,2,4,1998/10/01 11:06:00,140.0416076042,35.6732737387,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 11:06:00,139.7509022114,35.7480566172,2,11,4061110,4 ,5 ,30,,12",
 "11500,2,2,1998/10/01 11:06:00,139.8930541055,35.6701396368,2,7,4115209,14,4 ,32,,2",
 "230000,2,4,1998/10/01 11:06:00,139.5793058712,35.4469568057,1,8,2410601,7 ,3 ,15,,12",
 "142600,6,6,1998/10/01 11:06:00,140.1168278641,35.6306068067,2,14,4013104,14,4 ,27,,6",
 "186300,4,4,1998/10/01 11:06:00,140.1301822928,35.9954705159,1,4,5217006,13,3 ,53,,3",
 "190900,2,2,1998/10/01 11:06:00,139.9947985851,35.9411000294,2,11,5410113,14,4 ,37,,6",
 "202400,2,2,1998/10/01 11:06:00,140.4936729913,35.9833049884,2,10,5610009,4 ,4 ,34,,7",
 "271400,2,2,1998/10/01 11:06:00,139.5578403988,35.5332465682,1,7,2811315,9 ,11,37,,8",
 "335800,2,2,1998/10/01 11:06:00,139.5547180588,35.6333910914,2,6,2130206,14,4 ,31,,1",
 "374900,4,4,1998/10/01 11:06:00,139.7684347525,35.6678715575,1,4,3040108,4 ,9 ,71,,6",
 "531300,4,4,1998/10/01 11:06:00,139.1040041853,36.041834634,1,5,3920007,3 ,9 ,33,,8",
 "552000,4,4,1998/10/01 11:06:00,139.7472864393,35.5454249937,2,14,0133113,15,3 ,35,,1",
 "568100,2,2,1998/10/01 11:06:00,139.7172533139,35.6839279784,2,6,0231108,14,5 ,58,,2",
 "621000,4,7,1998/10/01 11:06:00,139.6970258197,35.6878082657,2,10,0436007,14,3 ,45,,12",
 "731400,2,2,1998/10/01 11:06:00,139.4807662631,35.6942758505,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:06:00,139.3180298424,35.6609342804,2,3,0930110,13,2 ,35,,9",
 "793500,2,3,1998/10/01 11:06:00,139.6734801648,35.5087519636,2,11,1414207,14,4 ,39,,10",
 "851000,2,2,1998/10/01 11:06:00,139.6182493665,35.4730110144,2,11,1330011,15,7 ,45,,6",
 "715300,2,2,1998/10/01 11:06:00,139.4610225726,35.6562361274,1,5,0750217,9 ,10,49,,1",
 "706100,2,4,1998/10/01 11:06:00,139.822885422,35.6892488434,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 11:07:00,139.9119741847,35.910790528,1,4,4312114,13,2 ,52,,6",
 "128800,4,7,1998/10/01 11:07:00,140.4986890237,35.8881557014,1,14,4831221,15,3 ,26,,10",
 "280600,7,7,1998/10/01 11:07:00,139.7366089078,35.6501368835,1,11,2910009,8 ,1 ,42,,8",
 "23000,4,7,1998/10/01 11:07:00,139.9438009721,35.7050915668,2,3,4123006,5 ,4 ,26,,12",
 "101200,2,4,1998/10/01 11:07:00,140.037197088,35.6770202245,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 11:07:00,139.7454748528,35.7492184965,2,11,4061110,4 ,5 ,30,,12",
 "11500,2,2,1998/10/01 11:07:00,139.8948001624,35.6695402571,2,7,4115209,14,4 ,32,,2",
 "230000,2,4,1998/10/01 11:07:00,139.5748955559,35.4455324866,1,8,2410601,7 ,3 ,15,,12",
 "142600,6,6,1998/10/01 11:07:00,140.1176076397,35.6292656976,2,14,4013104,14,4 ,27,,6",
 "186300,4,4,1998/10/01 11:07:00,140.1273041421,35.9974118293,1,4,5217006,13,3 ,53,,3",
 "190900,2,2,1998/10/01 11:07:00,139.9974732987,35.9357316892,2,11,5410113,14,4 ,37,,6",
 "202400,2,2,1998/10/01 11:07:00,140.4967885857,35.9811794094,2,10,5610009,4 ,4 ,34,,7",
 "271400,2,2,1998/10/01 11:07:00,139.5593148815,35.532755922,1,7,2811315,9 ,11,37,,8",
 "335800,2,2,1998/10/01 11:07:00,139.5538980793,35.6333856585,2,6,2130206,14,4 ,31,,1",
 "374900,4,4,1998/10/01 11:07:00,139.7684124591,35.6665921061,1,4,3040108,4 ,9 ,71,,6",
 "531300,4,4,1998/10/01 11:07:00,139.1022750682,36.0367099231,1,5,3920007,3 ,9 ,33,,8",
 "552000,4,4,1998/10/01 11:07:00,139.7479304225,35.5455221007,2,14,0133113,15,3 ,35,,1",
 "568100,2,2,1998/10/01 11:07:00,139.7172026205,35.6851419969,2,6,0231108,14,5 ,58,,2",
 "621000,4,7,1998/10/01 11:07:00,139.6923991859,35.6852148509,2,10,0436007,14,3 ,45,,12",
 "731400,2,2,1998/10/01 11:07:00,139.480781559,35.6939492203,2,10,0771111,14,4 ,32,,6",
 "793500,2,3,1998/10/01 11:07:00,139.6760316182,35.5083145535,2,11,1414207,14,4 ,39,,10",
 "793500,2,4,1998/10/01 11:07:00,139.6760316182,35.5083145535,2,11,1414207,14,4 ,39,,12",
 "786600,3,5,1998/10/01 11:07:00,139.3183570919,35.6609057568,2,3,0930110,13,2 ,35,,9",
 "851000,2,2,1998/10/01 11:07:00,139.6187373329,35.4710330245,2,11,1330011,15,7 ,45,,6",
 "715300,2,2,1998/10/01 11:07:00,139.4601888531,35.6571706971,1,5,0750217,9 ,10,49,,1",
 "706100,2,4,1998/10/01 11:07:00,139.8290689039,35.6892852599,1,14,0730209,10,7 ,26,,12",
 "69000,2,2,1998/10/01 11:08:00,139.9119739491,35.9107901582,1,4,4312114,13,2 ,52,,6",
 "128800,4,7,1998/10/01 11:08:00,140.4986886962,35.8881554341,1,14,4831221,15,3 ,26,,10",
 "280600,7,7,1998/10/01 11:08:00,139.736228415,35.6477047804,1,11,2910009,8 ,1 ,42,,8",
 "23000,4,7,1998/10/01 11:08:00,139.9388867591,35.7005945165,2,3,4123006,5 ,4 ,26,,12",
 "101200,2,4,1998/10/01 11:08:00,140.0327900131,35.6807695648,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 11:08:00,139.7400954529,35.7504724142,2,11,4061110,4 ,5 ,30,,12",
 "11500,2,2,1998/10/01 11:08:00,139.8964123362,35.6689110986,2,7,4115209,14,4 ,32,,2",
 "230000,2,4,1998/10/01 11:08:00,139.570766934,35.4434628619,1,8,2410601,7 ,3 ,15,,12",
 "142600,6,6,1998/10/01 11:08:00,140.1186727619,35.6280919767,2,14,4013104,14,4 ,27,,6",
 "186300,4,4,1998/10/01 11:08:00,140.1240317027,35.9965878656,1,4,5217006,13,3 ,53,,3",
 "190900,2,2,1998/10/01 11:08:00,140.0037854845,35.933284488,2,11,5410113,14,4 ,37,,6",
 "202400,2,2,1998/10/01 11:08:00,140.4993441945,35.9785776237,2,10,5610009,4 ,4 ,34,,7",
 "271400,2,2,1998/10/01 11:08:00,139.5609014379,35.5329496908,1,7,2811315,9 ,11,37,,8",
 "335800,2,2,1998/10/01 11:08:00,139.5530780999,35.6333802201,2,6,2130206,14,4 ,31,,1",
 "374900,4,4,1998/10/01 11:08:00,139.7693807916,35.6654295385,1,4,3040108,4 ,9 ,71,,6",
 "531300,4,4,1998/10/01 11:08:00,139.1016000498,36.0311590459,1,5,3920007,3 ,9 ,33,,8",
 "552000,4,4,1998/10/01 11:08:00,139.7485829992,35.5455713139,2,14,0133113,15,3 ,35,,1",
 "568100,2,2,1998/10/01 11:08:00,139.717115261,35.6863551801,2,6,0231108,14,5 ,58,,2",
 "621000,4,7,1998/10/01 11:08:00,139.6885274528,35.6819024716,2,10,0436007,14,3 ,45,,12",
 "731400,2,2,1998/10/01 11:08:00,139.4808265931,35.6936246597,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:08:00,139.318684351,35.6608768825,2,3,0930110,13,2 ,35,,9",
 "793500,2,4,1998/10/01 11:08:00,139.6767629781,35.5106778374,2,11,1414207,14,4 ,39,,12",
 "851000,2,2,1998/10/01 11:08:00,139.6188071945,35.4687841461,2,11,1330011,15,7 ,45,,6",
 "706100,2,4,1998/10/01 11:08:00,139.8352193712,35.6898179827,1,14,0730209,10,7 ,26,,12",
 "715300,2,2,1998/10/01 11:08:00,139.4591507028,35.6580375451,1,5,0750217,9 ,10,49,,1",
 "69000,2,2,1998/10/01 11:09:00,139.9119737135,35.9107897883,1,4,4312114,13,2 ,52,,6",
 "128800,4,7,1998/10/01 11:09:00,140.4986883688,35.8881551668,1,14,4831221,15,3 ,26,,10",
 "280600,7,7,1998/10/01 11:09:00,139.7354095376,35.6453348016,1,11,2910009,8 ,1 ,42,,8",
 "23000,4,7,1998/10/01 11:09:00,139.9329161756,35.6970534747,2,3,4123006,5 ,4 ,26,,12",
 "101200,2,4,1998/10/01 11:09:00,140.0283697189,35.6845086741,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 11:09:00,139.7364580033,35.7540035493,2,11,4061110,4 ,5 ,30,,12",
 "11500,2,2,1998/10/01 11:09:00,139.895859184,35.6674326186,2,7,4115209,14,4 ,32,,2",
 "230000,2,4,1998/10/01 11:09:00,139.566638524,35.4413930961,1,8,2410601,7 ,3 ,15,,12",
 "142600,6,6,1998/10/01 11:09:00,140.120512116,35.6282266985,2,14,4013104,14,4 ,27,,6",
 "186300,4,4,1998/10/01 11:09:00,140.1227121492,35.9943182507,1,4,5217006,13,3 ,53,,3",
 "190900,2,2,1998/10/01 11:09:00,140.010040146,35.9304591561,2,11,5410113,14,4 ,37,,6",
 "202400,2,2,1998/10/01 11:09:00,140.5008885909,35.9755628,2,10,5610009,4 ,4 ,34,,7",
 "271400,2,2,1998/10/01 11:09:00,139.5612334362,35.5338833627,1,7,2811315,9 ,11,37,,8",
 "335800,2,2,1998/10/01 11:09:00,139.5522581207,35.633374776,2,6,2130206,14,4 ,31,,1",
 "374900,4,4,1998/10/01 11:09:00,139.7681088967,35.6643530976,1,4,3040108,4 ,9 ,71,,6",
 "531300,4,4,1998/10/01 11:09:00,139.1022243054,36.0256079271,1,5,3920007,3 ,9 ,33,,8",
 "552000,4,4,1998/10/01 11:09:00,139.7491821531,35.5456685878,2,14,0133113,15,3 ,35,,1",
 "568100,2,2,1998/10/01 11:09:00,139.71703472,35.6875687081,2,6,0231108,14,5 ,58,,2",
 "621000,4,7,1998/10/01 11:09:00,139.6838280975,35.6794484999,2,10,0436007,14,3 ,45,,12",
 "731400,2,2,1998/10/01 11:09:00,139.4808793461,35.6933006364,2,10,0771111,14,4 ,32,,6",
 "793500,2,4,1998/10/01 11:09:00,139.6775703109,35.5130217058,2,11,1414207,14,4 ,39,,12",
 "786600,3,5,1998/10/01 11:09:00,139.3190133194,35.6608639833,2,3,0930110,13,2 ,35,,9",
 "851000,2,2,1998/10/01 11:09:00,139.6204473208,35.4672589806,2,11,1330011,15,7 ,45,,6",
 "715300,2,2,1998/10/01 11:09:00,139.4579339773,35.6588312171,1,5,0750217,9 ,10,49,,1",
 "706100,2,4,1998/10/01 11:09:00,139.8413840425,35.6900638162,1,14,0730209,10,7 ,26,,12",
 "793500,2,4,1998/10/01 11:10:00,139.6788099071,35.5152398611,2,11,1414207,14,4 ,39,,12",
 "69000,2,2,1998/10/01 11:10:00,139.9119734779,35.9107894185,1,4,4312114,13,2 ,52,,6",
 "128800,4,7,1998/10/01 11:10:00,140.4986880414,35.8881548994,1,14,4831221,15,3 ,26,,10",
 "280600,7,7,1998/10/01 11:10:00,139.7333894778,35.6452147856,1,11,2910009,8 ,1 ,42,,8",
 "280600,8,8,1998/10/01 11:10:00,139.7333894778,35.6452147856,1,11,2910009,8 ,97,42,,97",
 "23000,4,7,1998/10/01 11:10:00,139.9270827776,35.6933722418,2,3,4123006,5 ,4 ,26,,12",
 "101200,2,4,1998/10/01 11:10:00,140.0239408916,35.6882412375,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 11:10:00,139.7335819964,35.7580025291,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 11:10:00,139.5625403852,35.439286993,1,8,2410601,7 ,3 ,15,,12",
 "11500,2,2,1998/10/01 11:10:00,139.8952102645,35.6659867466,2,7,4115209,14,4 ,32,,2",
 "11500,3,3,1998/10/01 11:10:00,139.8952102645,35.6659867466,2,7,4115209,14,97,32,,97",
 "142600,6,6,1998/10/01 11:10:00,140.1211900419,35.6284820982,2,14,4013104,14,4 ,27,,6",
 "142600,7,7,1998/10/01 11:10:00,140.1211900419,35.6284820982,2,14,4013104,14,97,27,,97",
 "186300,4,4,1998/10/01 11:10:00,140.1230420936,35.991911881,1,4,5217006,13,3 ,53,,3",
 "186300,5,5,1998/10/01 11:10:00,140.1230420936,35.991911881,1,4,5217006,13,97,53,,97",
 "190900,2,2,1998/10/01 11:10:00,140.0164930759,35.9279754064,2,11,5410113,14,4 ,37,,6",
 "202400,2,2,1998/10/01 11:10:00,140.5022722787,35.9724309725,2,10,5610009,4 ,4 ,34,,7",
 "271400,2,2,1998/10/01 11:10:00,139.5610296285,35.5348740372,1,7,2811315,9 ,11,37,,8",
 "271400,3,3,1998/10/01 11:10:00,139.5610296285,35.5348740372,1,7,2811315,9 ,97,37,,97",
 "335800,2,2,1998/10/01 11:10:00,139.5514383628,35.6333817189,2,6,2130206,14,4 ,31,,1",
 "374900,4,4,1998/10/01 11:10:00,139.7682895209,35.6629843437,1,4,3040108,4 ,9 ,71,,6",
 "374900,5,5,1998/10/01 11:10:00,139.7682895209,35.6629843437,1,4,3040108,4 ,97,71,,97",
 "531300,4,4,1998/10/01 11:10:00,139.0981643355,36.0210498474,1,5,3920007,3 ,9 ,33,,8",
 "552000,4,4,1998/10/01 11:10:00,139.7496156371,35.5460630549,2,14,0133113,15,3 ,35,,1",
 "552000,5,5,1998/10/01 11:10:00,139.7496156371,35.5460630549,2,14,0133113,15,97,35,,97",
 "568100,2,2,1998/10/01 11:10:00,139.7156290789,35.6876422216,2,6,0231108,14,5 ,58,,2",
 "621000,4,7,1998/10/01 11:10:00,139.6788725524,35.6772646033,2,10,0436007,14,3 ,45,,12",
 "731400,2,2,1998/10/01 11:10:00,139.480924737,35.6929758784,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:10:00,139.3193422878,35.6608510833,2,3,0930110,13,2 ,35,,9",
 "851000,2,2,1998/10/01 11:10:00,139.6208796637,35.4657833017,2,11,1330011,15,7 ,45,,6",
 "851000,3,3,1998/10/01 11:10:00,139.6208796637,35.4657833017,2,11,1330011,15,97,45,,97",
 "715300,2,2,1998/10/01 11:10:00,139.4575434081,35.6595375798,1,5,0750217,9 ,10,49,,1",
 "715300,2,3,1998/10/01 11:10:00,139.4575434081,35.6595375798,1,5,0750217,9 ,10,49,,12",
 "706100,2,4,1998/10/01 11:10:00,139.8471481275,35.6897656582,1,14,0730209,10,7 ,26,,12",
 "706100,2,5,1998/10/01 11:10:00,139.8471481275,35.6897656582,1,14,0730209,10,7 ,26,,1",
 "69000,2,2,1998/10/01 11:11:00,139.9119732423,35.9107890486,1,4,4312114,13,2 ,52,,6",
 "128800,4,7,1998/10/01 11:11:00,140.498687714,35.8881546321,1,14,4831221,15,3 ,26,,10",
 "273700,4,5,1998/10/01 11:11:00,139.3252935888,35.4057847246,2,5,2820113,14,8 ,42,,6",
 "23000,4,7,1998/10/01 11:11:00,139.9219119053,35.6890702891,2,3,4123006,5 ,4 ,26,,12",
 "101200,2,4,1998/10/01 11:11:00,140.019509383,35.6919718388,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 11:11:00,139.7294808464,35.7611894591,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 11:11:00,139.5594993006,35.4362261728,1,8,2410601,7 ,3 ,15,,12",
 "190900,2,2,1998/10/01 11:11:00,140.0230296998,35.9256351112,2,11,5410113,14,4 ,37,,6",
 "202400,2,2,1998/10/01 11:11:00,140.5040085448,35.9694226307,2,10,5610009,4 ,4 ,34,,7",
 "335800,2,2,1998/10/01 11:11:00,139.5507352822,35.6332984181,2,6,2130206,14,4 ,31,,1",
 "368000,2,2,1998/10/01 11:11:00,139.6090404626,35.8818275631,1,12,3035001,15,5 ,32,,6",
 "531300,4,4,1998/10/01 11:11:00,139.0932261149,36.0171757597,1,5,3920007,3 ,9 ,33,,8",
 "568100,2,2,1998/10/01 11:11:00,139.7141667382,35.6878653607,2,6,0231108,14,5 ,58,,2",
 "621000,4,7,1998/10/01 11:11:00,139.6735198086,35.675836672,2,10,0436007,14,3 ,45,,12",
 "731400,2,2,1998/10/01 11:11:00,139.4809692855,35.6926510364,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:11:00,139.3196713126,35.660839219,2,3,0930110,13,2 ,35,,9",
 "793500,2,4,1998/10/01 11:11:00,139.680427449,35.517272627,2,11,1414207,14,4 ,39,,12",
 "715300,2,3,1998/10/01 11:11:00,139.4636149378,35.6605172471,1,5,0750217,9 ,10,49,,12",
 "706100,2,5,1998/10/01 11:11:00,139.8472613779,35.6888921407,1,14,0730209,10,7 ,26,,1",
 "69000,2,2,1998/10/01 11:12:00,139.9119730067,35.9107886788,1,4,4312114,13,2 ,52,,6",
 "128800,4,7,1998/10/01 11:12:00,140.4986873866,35.8881543648,1,14,4831221,15,3 ,26,,10",
 "273700,4,5,1998/10/01 11:12:00,139.3227768157,35.4059018438,2,5,2820113,14,8 ,42,,6",
 "23000,4,7,1998/10/01 11:12:00,139.9167490023,35.6847619985,2,3,4123006,5 ,4 ,26,,12",
 "101200,2,4,1998/10/01 11:12:00,140.0150683419,35.6956950496,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 11:12:00,139.7260309214,35.7648136814,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 11:12:00,139.5571400066,35.4328090188,1,8,2410601,7 ,3 ,15,,12",
 "190900,2,2,1998/10/01 11:12:00,140.0295238303,35.923186282,2,11,5410113,14,4 ,37,,6",
 "202400,2,2,1998/10/01 11:12:00,140.5063629352,35.9667016286,2,10,5610009,4 ,4 ,34,,7",
 "335800,2,2,1998/10/01 11:12:00,139.5510748372,35.6328345764,2,6,2130206,14,4 ,31,,1",
 "368000,2,2,1998/10/01 11:12:00,139.6125529836,35.8818186754,1,12,3035001,15,5 ,32,,6",
 "531300,4,4,1998/10/01 11:12:00,139.0922428488,36.0116133265,1,5,3920007,3 ,9 ,33,,8",
 "568100,2,2,1998/10/01 11:12:00,139.7127283956,35.6881796119,2,6,0231108,14,5 ,58,,2",
 "621000,4,7,1998/10/01 11:12:00,139.6683261906,35.674044182,2,10,0436007,14,3 ,45,,12",
 "731400,2,2,1998/10/01 11:12:00,139.480952519,35.6923266895,2,10,0771111,14,4 ,32,,6",
 "793500,2,4,1998/10/01 11:12:00,139.6823836312,35.5191145055,2,11,1414207,14,4 ,39,,12",
 "786600,3,5,1998/10/01 11:12:00,139.3200003849,35.6608282251,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:12:00,139.4672364949,35.6643168791,1,5,0750217,9 ,10,49,,12",
 "706100,2,5,1998/10/01 11:12:00,139.8473746258,35.6880186231,1,14,0730209,10,7 ,26,,1",
 "69000,2,2,1998/10/01 11:13:00,139.9119727712,35.910788309,1,4,4312114,13,2 ,52,,6",
 "128800,4,7,1998/10/01 11:13:00,140.4986870591,35.8881540974,1,14,4831221,15,3 ,26,,10",
 "273700,4,5,1998/10/01 11:13:00,139.3220536858,35.404264792,2,5,2820113,14,8 ,42,,6",
 "23000,4,7,1998/10/01 11:13:00,139.911581965,35.6804572289,2,3,4123006,5 ,4 ,26,,12",
 "101200,2,4,1998/10/01 11:13:00,140.0101976636,35.6989645633,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 11:13:00,139.7236304099,35.7690170669,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 11:13:00,139.5568455295,35.42886514,1,8,2410601,7 ,3 ,15,,12",
 "190900,2,2,1998/10/01 11:13:00,140.035810331,35.9203996826,2,11,5410113,14,4 ,37,,6",
 "202400,2,2,1998/10/01 11:13:00,140.5087278292,35.9639893544,2,10,5610009,4 ,4 ,34,,7",
 "335800,2,2,1998/10/01 11:13:00,139.5518297716,35.6325732393,2,6,2130206,14,4 ,31,,1",
 "368000,2,2,1998/10/01 11:13:00,139.6151185555,35.8819493016,1,12,3035001,15,5 ,32,,6",
 "368000,3,3,1998/10/01 11:13:00,139.6151185555,35.8819493016,1,12,3035001,15,97,32,,97",
 "531300,4,4,1998/10/01 11:13:00,139.0909556559,36.0066075085,1,5,3920007,3 ,9 ,33,,8",
 "531300,5,5,1998/10/01 11:13:00,139.0909556559,36.0066075085,1,5,3920007,3 ,97,33,,97",
 "568100,2,2,1998/10/01 11:13:00,139.7112806573,35.6884652527,2,6,0231108,14,5 ,58,,2",
 "621000,4,7,1998/10/01 11:13:00,139.6630771293,35.6723643264,2,10,0436007,14,3 ,45,,12",
 "731400,2,2,1998/10/01 11:13:00,139.4808572985,35.692009185,2,10,0771111,14,4 ,32,,6",
 "793500,2,4,1998/10/01 11:13:00,139.684464336,35.5208630432,2,11,1414207,14,4 ,39,,12",
 "786600,3,5,1998/10/01 11:13:00,139.320329457,35.6608172303,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:13:00,139.4686654785,35.6692642212,1,5,0750217,9 ,10,49,,12",
 "706100,2,5,1998/10/01 11:13:00,139.8480385646,35.6875572485,1,14,0730209,10,7 ,26,,1",
 "69000,2,2,1998/10/01 11:14:00,139.9119725356,35.9107879391,1,4,4312114,13,2 ,52,,6",
 "128800,4,7,1998/10/01 11:14:00,140.4986867317,35.8881538301,1,14,4831221,15,3 ,26,,10",
 "273700,4,5,1998/10/01 11:14:00,139.32147945,35.4022404887,2,5,2820113,14,8 ,42,,6",
 "23000,4,7,1998/10/01 11:14:00,139.9064115405,35.6761553828,2,3,4123006,5 ,4 ,26,,12",
 "101200,2,4,1998/10/01 11:14:00,140.0040603259,35.7001495682,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 11:14:00,139.7222425169,35.7734345734,2,11,4061110,4 ,5 ,30,,12",
 "230000,2,4,1998/10/01 11:14:00,139.5554584503,35.4250900631,1,8,2410601,7 ,3 ,15,,12",
 "190900,2,2,1998/10/01 11:14:00,140.0419996071,35.9174761612,2,11,5410113,14,4 ,37,,6",
 "202400,2,2,1998/10/01 11:14:00,140.5112217215,35.9613478914,2,10,5610009,4 ,4 ,34,,7",
 "335800,2,2,1998/10/01 11:14:00,139.5523542688,35.6321993493,2,6,2130206,14,4 ,31,,1",
 "568100,2,2,1998/10/01 11:14:00,139.7098369296,35.6887646617,2,6,0231108,14,5 ,58,,2",
 "621000,4,7,1998/10/01 11:14:00,139.6578749751,35.6705890201,2,10,0436007,14,3 ,45,,12",
 "731400,2,2,1998/10/01 11:14:00,139.4807558807,35.6916930342,2,10,0771111,14,4 ,32,,6",
 "793500,2,4,1998/10/01 11:14:00,139.6865451311,35.5226115447,2,11,1414207,14,4 ,39,,12",
 "786600,3,5,1998/10/01 11:14:00,139.3206585291,35.6608062347,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:14:00,139.472705148,35.6724997507,1,5,0750217,9 ,10,49,,12",
 "706100,2,5,1998/10/01 11:14:00,139.8490878152,35.6873604948,1,14,0730209,10,7 ,26,,1",
 "69000,2,2,1998/10/01 11:15:00,139.9119723,35.9107875693,1,4,4312114,13,2 ,52,,6",
 "128800,4,7,1998/10/01 11:15:00,140.4986864043,35.8881535627,1,14,4831221,15,3 ,26,,10",
 "273700,4,5,1998/10/01 11:15:00,139.320059428,35.4007237833,2,5,2820113,14,8 ,42,,6",
 "23000,4,7,1998/10/01 11:15:00,139.9012415597,35.6718533999,2,3,4123006,5 ,4 ,26,,12",
 "101200,2,4,1998/10/01 11:15:00,139.9977068224,35.7005085988,2,12,4512004,14,5 ,29,,12",
 "163300,2,3,1998/10/01 11:15:00,139.7209330163,35.7776696053,2,11,4061110,4 ,5 ,30,,12",
 "163300,2,4,1998/10/01 11:15:00,139.7209330163,35.7776696053,2,11,4061110,4 ,5 ,30,,10",
 "230000,2,4,1998/10/01 11:15:00,139.5528770481,35.4217932087,1,8,2410601,7 ,3 ,15,,12",
 "190900,2,2,1998/10/01 11:15:00,140.0468313519,35.9133819362,2,11,5410113,14,4 ,37,,6",
 "202400,2,2,1998/10/01 11:15:00,140.5140572751,35.958956552,2,10,5610009,4 ,4 ,34,,7",
 "335800,2,2,1998/10/01 11:15:00,139.5521622782,35.6319027557,2,6,2130206,14,4 ,31,,1",
 "335800,3,3,1998/10/01 11:15:00,139.5521622782,35.6319027557,2,6,2130206,14,97,31,,97",
 "568100,2,2,1998/10/01 11:15:00,139.7084752449,35.6892419371,2,6,0231108,14,5 ,58,,2",
 "621000,4,7,1998/10/01 11:15:00,139.6526082755,35.6689478128,2,10,0436007,14,3 ,45,,12",
 "731400,2,2,1998/10/01 11:15:00,139.4806473067,35.6913784747,2,10,0771111,14,4 ,32,,6",
 "793500,2,4,1998/10/01 11:15:00,139.6886260165,35.52436001,2,11,1414207,14,4 ,39,,12",
 "786600,3,5,1998/10/01 11:15:00,139.3209876011,35.6607952381,2,3,0930110,13,2 ,35,,9",
 "706100,2,5,1998/10/01 11:15:00,139.8492406151,35.6878767848,1,14,0730209,10,7 ,26,,1",
 "706100,3,6,1998/10/01 11:15:00,139.8492406151,35.6878767848,1,14,0730209,10,97,26,,97",
 "715300,2,3,1998/10/01 11:15:00,139.4788049867,35.6724463867,1,5,0750217,9 ,10,49,,12",
 "69000,2,2,1998/10/01 11:16:00,139.9119720644,35.9107871994,1,4,4312114,13,2 ,52,,6",
 "128800,4,7,1998/10/01 11:16:00,140.4986860768,35.8881532954,1,14,4831221,15,3 ,26,,10",
 "273700,4,5,1998/10/01 11:16:00,139.3177237291,35.3999190502,2,5,2820113,14,8 ,42,,6",
 "23000,4,7,1998/10/01 11:16:00,139.8960666297,35.6675583021,2,3,4123006,5 ,4 ,26,,12",
 "101200,2,4,1998/10/01 11:16:00,139.9913786457,35.7010867645,2,12,4512004,14,5 ,29,,12",
 "163300,2,4,1998/10/01 11:16:00,139.7213552802,35.7776379202,2,11,4061110,4 ,5 ,30,,10",
 "230000,2,4,1998/10/01 11:16:00,139.5488712308,35.4195754991,1,8,2410601,7 ,3 ,15,,12",
 "190900,2,2,1998/10/01 11:16:00,140.0515436287,35.909045399,2,11,5410113,14,4 ,37,,6",
 "202400,2,2,1998/10/01 11:16:00,140.5176375454,35.9573836263,2,10,5610009,4 ,4 ,34,,7",
 "568100,2,2,1998/10/01 11:16:00,139.7071711696,35.6898294239,2,6,0231108,14,5 ,58,,2",
 "621000,4,7,1998/10/01 11:16:00,139.6472872721,35.6674274709,2,10,0436007,14,3 ,45,,12",
 "650900,2,2,1998/10/01 11:16:00,139.7412379041,35.7660034582,1,9,0531007,3 ,9 ,43,,6",
 "731400,2,2,1998/10/01 11:16:00,139.4805594844,35.6910595543,2,10,0771111,14,4 ,32,,6",
 "793500,2,4,1998/10/01 11:16:00,139.6907054065,35.5261096993,2,11,1414207,14,4 ,39,,12",
 "786600,3,5,1998/10/01 11:16:00,139.321316673,35.6607842406,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:16:00,139.484655146,35.6713368724,1,5,0750217,9 ,10,49,,12",
 "69000,2,2,1998/10/01 11:17:00,139.9119718288,35.9107868296,1,4,4312114,13,2 ,52,,6",
 "128800,4,7,1998/10/01 11:17:00,140.4986857494,35.8881530281,1,14,4831221,15,3 ,26,,10",
 "273700,4,5,1998/10/01 11:17:00,139.3154355628,35.3989947105,2,5,2820113,14,8 ,42,,6",
 "23000,4,7,1998/10/01 11:17:00,139.8898852372,35.6642910128,2,3,4123006,5 ,4 ,26,,12",
 "101200,2,4,1998/10/01 11:17:00,139.9850809005,35.7017100316,2,12,4512004,14,5 ,29,,12",
 "101200,2,5,1998/10/01 11:17:00,139.9850809005,35.7017100316,2,12,4512004,14,5 ,29,,1",
 "163300,2,4,1998/10/01 11:17:00,139.7217775437,35.7776062337,2,11,4061110,4 ,5 ,30,,10",
 "230000,2,4,1998/10/01 11:17:00,139.5448157869,35.4174127908,1,8,2410601,7 ,3 ,15,,12",
 "190900,2,2,1998/10/01 11:17:00,140.0539753678,35.9071257678,2,11,5410113,14,4 ,37,,6",
 "202400,2,2,1998/10/01 11:17:00,140.5213116546,35.956085201,2,10,5610009,4 ,4 ,34,,7",
 "568100,2,2,1998/10/01 11:17:00,139.7058712245,35.6904229049,2,6,0231108,14,5 ,58,,2",
 "621000,4,7,1998/10/01 11:17:00,139.6420276334,35.6659859696,2,10,0436007,14,3 ,45,,12",
 "621000,4,8,1998/10/01 11:17:00,139.6420276334,35.6659859696,2,10,0436007,14,3 ,45,,1",
 "650900,2,2,1998/10/01 11:17:00,139.7379548789,35.7659652545,1,9,0531007,3 ,9 ,43,,6",
 "731400,2,2,1998/10/01 11:17:00,139.4804637055,35.6907425749,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:17:00,139.3216457448,35.6607732422,2,3,0930110,13,2 ,35,,9",
 "793500,2,4,1998/10/01 11:17:00,139.6927751381,35.5278669329,2,11,1414207,14,4 ,39,,12",
 "715300,2,3,1998/10/01 11:17:00,139.4907225929,35.6701861658,1,5,0750217,9 ,10,49,,12",
 "69000,2,2,1998/10/01 11:18:00,139.9119715932,35.9107864597,1,4,4312114,13,2 ,52,,6",
 "128800,4,7,1998/10/01 11:18:00,140.498685422,35.8881527607,1,14,4831221,15,3 ,26,,10",
 "273700,4,5,1998/10/01 11:18:00,139.3149663798,35.397153621,2,5,2820113,14,8 ,42,,6",
 "23000,4,7,1998/10/01 11:18:00,139.8825930759,35.663429054,2,3,4123006,5 ,4 ,26,,12",
 "101200,2,5,1998/10/01 11:18:00,139.9858102875,35.7015163992,2,12,4512004,14,5 ,29,,1",
 "163300,2,4,1998/10/01 11:18:00,139.7221998069,35.7775745456,2,11,4061110,4 ,5 ,30,,10",
 "230000,2,4,1998/10/01 11:18:00,139.5407605602,35.4152499465,1,8,2410601,7 ,3 ,15,,12",
 "190900,2,2,1998/10/01 11:18:00,140.0598596349,35.9042347984,2,11,5410113,14,4 ,37,,6",
 "202400,2,2,1998/10/01 11:18:00,140.5249197105,35.9545127618,2,10,5610009,4 ,4 ,34,,7",
 "568100,2,2,1998/10/01 11:18:00,139.7047503683,35.690520459,2,6,0231108,14,5 ,58,,2",
 "621000,4,8,1998/10/01 11:18:00,139.6437348034,35.6650130882,2,10,0436007,14,3 ,45,,1",
 "650900,2,2,1998/10/01 11:18:00,139.7354733336,35.7663984167,1,9,0531007,3 ,9 ,43,,6",
 "731400,2,2,1998/10/01 11:18:00,139.4803459865,35.6904304022,2,10,0771111,14,4 ,32,,6",
 "793500,2,4,1998/10/01 11:18:00,139.6948082274,35.5296526828,2,11,1414207,14,4 ,39,,12",
 "786600,3,5,1998/10/01 11:18:00,139.3219748166,35.660762243,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:18:00,139.4965321943,35.6683784941,1,5,0750217,9 ,10,49,,12",
 "69000,2,2,1998/10/01 11:19:00,139.9119713576,35.9107860899,1,4,4312114,13,2 ,52,,6",
 "128800,4,7,1998/10/01 11:19:00,140.4986850946,35.8881524934,1,14,4831221,15,3 ,26,,10",
 "273700,4,5,1998/10/01 11:19:00,139.3144800798,35.3954245955,2,5,2820113,14,8 ,42,,6",
 "23000,4,7,1998/10/01 11:19:00,139.8752289383,35.6634741155,2,3,4123006,5 ,4 ,26,,12",
 "101200,2,5,1998/10/01 11:19:00,139.986539671,35.7013227623,2,12,4512004,14,5 ,29,,1",
 "163300,2,4,1998/10/01 11:19:00,139.7226220698,35.7775428561,2,11,4061110,4 ,5 ,30,,10",
 "230000,2,4,1998/10/01 11:19:00,139.5368617413,35.4129103597,1,8,2410601,7 ,3 ,15,,12",
 "190900,2,2,1998/10/01 11:19:00,140.0625545214,35.8993772022,2,11,5410113,14,4 ,37,,6",
 "202400,2,2,1998/10/01 11:19:00,140.5280092253,35.9523304251,2,10,5610009,4 ,4 ,34,,7",
 "529000,6,6,1998/10/01 11:19:00,139.0832802961,36.006251879,2,6,3910031,14,3 ,34,,6",
 "568100,2,2,1998/10/01 11:19:00,139.7034511223,35.6910555233,2,6,0231108,14,5 ,58,,2",
 "650900,2,2,1998/10/01 11:19:00,139.7344476427,35.7687235807,1,9,0531007,3 ,9 ,43,,6",
 "621000,4,8,1998/10/01 11:19:00,139.6449436439,35.6639354353,2,10,0436007,14,3 ,45,,1",
 "731400,2,2,1998/10/01 11:19:00,139.4802380234,35.6901156232,2,10,0771111,14,4 ,32,,6",
 "793500,2,4,1998/10/01 11:19:00,139.6968624888,35.5314219481,2,11,1414207,14,4 ,39,,12",
 "793500,2,5,1998/10/01 11:19:00,139.6968624888,35.5314219481,2,11,1414207,14,4 ,39,,1",
 "786600,3,5,1998/10/01 11:19:00,139.3223038882,35.6607512428,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:19:00,139.5022339709,35.6663322634,1,5,0750217,9 ,10,49,,12",
 "69000,2,2,1998/10/01 11:20:00,139.9119711221,35.9107857201,1,4,4312114,13,2 ,52,,6",
 "69000,2,3,1998/10/01 11:20:00,139.9119711221,35.9107857201,1,4,4312114,13,2 ,52,,9",
 "128800,4,7,1998/10/01 11:20:00,140.4986847671,35.8881522261,1,14,4831221,15,3 ,26,,10",
 "273700,4,5,1998/10/01 11:20:00,139.3125344626,35.3940868351,2,5,2820113,14,8 ,42,,6",
 "23000,4,7,1998/10/01 11:20:00,139.8678686328,35.6639055559,2,3,4123006,5 ,4 ,26,,12",
 "101200,2,5,1998/10/01 11:20:00,139.9867806742,35.7014950996,2,12,4512004,14,5 ,29,,1",
 "101200,3,6,1998/10/01 11:20:00,139.9867806742,35.7014950996,2,12,4512004,14,97,29,,97",
 "163300,2,4,1998/10/01 11:20:00,139.7230443323,35.777511165,2,11,4061110,4 ,5 ,30,,10",
 "230000,2,4,1998/10/01 11:20:00,139.5345635853,35.4094767876,1,8,2410601,7 ,3 ,15,,12",
 "190900,2,2,1998/10/01 11:20:00,140.0628558273,35.8956740232,2,11,5410113,14,4 ,37,,6",
 "190900,3,3,1998/10/01 11:20:00,140.0628558273,35.8956740232,2,11,5410113,14,97,37,,97",
 "202400,2,2,1998/10/01 11:20:00,140.5312488387,35.9502950241,2,10,5610009,4 ,4 ,34,,7",
 "529000,6,6,1998/10/01 11:20:00,139.0821160824,36.0045360798,2,6,3910031,14,3 ,34,,6",
 "568100,2,2,1998/10/01 11:20:00,139.7028737014,35.6912275094,2,6,0231108,14,5 ,58,,2",
 "568100,3,3,1998/10/01 11:20:00,139.7028737014,35.6912275094,2,6,0231108,14,97,58,,97",
 "621000,4,8,1998/10/01 11:20:00,139.6465881732,35.6626640494,2,10,0436007,14,3 ,45,,1",
 "650900,2,2,1998/10/01 11:20:00,139.7323097602,35.7699673007,1,9,0531007,3 ,9 ,43,,6",
 "731400,2,2,1998/10/01 11:20:00,139.4801186386,35.6898036274,2,10,0771111,14,4 ,32,,6",
 "793500,2,5,1998/10/01 11:20:00,139.7028141152,35.5287977488,2,11,1414207,14,4 ,39,,1",
 "793500,3,6,1998/10/01 11:20:00,139.7028141152,35.5287977488,2,11,1414207,14,97,39,,97",
 "786600,3,5,1998/10/01 11:20:00,139.3226329598,35.6607402417,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:20:00,139.5081600867,35.6648018987,1,5,0750217,9 ,10,49,,12",
 "69000,2,3,1998/10/01 11:21:00,139.8983083291,35.8891245175,1,4,4312114,13,2 ,52,,9",
 "89700,11,11,1998/10/01 11:21:00,140.2518987918,35.7564912961,2,8,4422113,3 ,3 ,42,,7",
 "128800,4,7,1998/10/01 11:21:00,140.4986844397,35.8881519587,1,14,4831221,15,3 ,26,,10",
 "273700,4,5,1998/10/01 11:21:00,139.3109535991,35.3925945969,2,5,2820113,14,8 ,42,,6",
 "23000,4,7,1998/10/01 11:21:00,139.8605063451,35.6643504256,2,3,4123006,5 ,4 ,26,,12",
 "163300,2,4,1998/10/01 11:21:00,139.7234665945,35.7774794725,2,11,4061110,4 ,5 ,30,,10",
 "230000,2,4,1998/10/01 11:21:00,139.5339831664,35.4055451796,1,8,2410601,7 ,3 ,15,,12",
 "184000,2,2,1998/10/01 11:21:00,140.0982855246,36.0963215308,2,4,5214028,13,5 ,49,,2",
 "202400,2,2,1998/10/01 11:21:00,140.5341614749,35.9481270753,2,10,5610009,4 ,4 ,34,,7",
 "529000,6,6,1998/10/01 11:21:00,139.0822408521,36.0031550719,2,6,3910031,14,3 ,34,,6",
 "650900,2,2,1998/10/01 11:21:00,139.730041222,35.7712351003,1,9,0531007,3 ,9 ,43,,6",
 "621000,4,8,1998/10/01 11:21:00,139.6472742221,35.6624185787,2,10,0436007,14,3 ,45,,1",
 "731400,2,2,1998/10/01 11:21:00,139.4799972921,35.6894921097,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:21:00,139.3229620313,35.6607292397,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:21:00,139.5142127276,35.6636041954,1,5,0750217,9 ,10,49,,12",
 "69000,2,3,1998/10/01 11:22:00,139.8830634241,35.8653037402,1,4,4312114,13,2 ,52,,9",
 "89700,11,11,1998/10/01 11:22:00,140.2531014206,35.7549204288,2,8,4422113,3 ,3 ,42,,7",
 "128800,4,7,1998/10/01 11:22:00,140.4986841123,35.8881516914,1,14,4831221,15,3 ,26,,10",
 "273700,4,5,1998/10/01 11:22:00,139.3092975675,35.3910385945,2,5,2820113,14,8 ,42,,6",
 "23000,4,7,1998/10/01 11:22:00,139.8532221111,35.665092312,2,3,4123006,5 ,4 ,26,,12",
 "163300,2,4,1998/10/01 11:22:00,139.7238888564,35.7774477785,2,11,4061110,4 ,5 ,30,,10",
 "230000,2,4,1998/10/01 11:22:00,139.5339437846,35.4016016736,1,8,2410601,7 ,3 ,15,,12",
 "184000,2,2,1998/10/01 11:22:00,140.0987792126,36.0946580136,2,4,5214028,13,5 ,49,,2",
 "202400,2,2,1998/10/01 11:22:00,140.5359464785,35.9451400896,2,10,5610009,4 ,4 ,34,,7",
 "529000,6,6,1998/10/01 11:22:00,139.0836003891,36.0018782542,2,6,3910031,14,3 ,34,,6",
 "650900,2,2,1998/10/01 11:22:00,139.7271158908,35.7713896605,1,9,0531007,3 ,9 ,43,,6",
 "621000,4,8,1998/10/01 11:22:00,139.6480148558,35.6606005769,2,10,0436007,14,3 ,45,,1",
 "731400,2,2,1998/10/01 11:22:00,139.4798751116,35.6891808114,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:22:00,139.3232908801,35.6607155588,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:22:00,139.5199269779,35.6617118375,1,5,0750217,9 ,10,49,,12",
 "69000,2,3,1998/10/01 11:23:00,139.8629198818,35.8390997427,1,4,4312114,13,2 ,52,,9",
 "89700,11,11,1998/10/01 11:23:00,140.2542671662,35.7534397146,2,8,4422113,3 ,3 ,42,,7",
 "128800,4,7,1998/10/01 11:23:00,140.4986837849,35.8881514241,1,14,4831221,15,3 ,26,,10",
 "273700,4,5,1998/10/01 11:23:00,139.3086612466,35.3893529435,2,5,2820113,14,8 ,42,,6",
 "23000,4,7,1998/10/01 11:23:00,139.8458708993,35.6656531859,2,3,4123006,5 ,4 ,26,,12",
 "163300,2,4,1998/10/01 11:23:00,139.7241088415,35.7771847039,2,11,4061110,4 ,5 ,30,,10",
 "163300,2,5,1998/10/01 11:23:00,139.7241088415,35.7771847039,2,11,4061110,4 ,5 ,30,,1",
 "230000,2,4,1998/10/01 11:23:00,139.5351530284,35.397765875,1,8,2410601,7 ,3 ,15,,12",
 "184000,2,2,1998/10/01 11:23:00,140.0992330079,36.0929857716,2,4,5214028,13,5 ,49,,2",
 "202400,2,2,1998/10/01 11:23:00,140.5389755488,35.9429357506,2,10,5610009,4 ,4 ,34,,7",
 "529000,6,6,1998/10/01 11:23:00,139.0834712813,36.0001762305,2,6,3910031,14,3 ,34,,6",
 "621000,4,8,1998/10/01 11:23:00,139.6485648181,35.6586336896,2,10,0436007,14,3 ,45,,1",
 "650900,2,2,1998/10/01 11:23:00,139.7248670797,35.7722634683,1,9,0531007,3 ,9 ,43,,6",
 "731400,2,2,1998/10/01 11:23:00,139.4797806433,35.688863896,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:23:00,139.3236187565,35.6606901834,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:23:00,139.5253530438,35.6592169007,1,5,0750217,9 ,10,49,,12",
 "69000,2,3,1998/10/01 11:24:00,139.8433516571,35.8120742311,1,4,4312114,13,2 ,52,,9",
 "89700,11,11,1998/10/01 11:24:00,140.2549748795,35.7550796713,2,8,4422113,3 ,3 ,42,,7",
 "128800,4,7,1998/10/01 11:24:00,140.4986834574,35.8881511567,1,14,4831221,15,3 ,26,,10",
 "273700,4,5,1998/10/01 11:24:00,139.3096353304,35.3874280023,2,5,2820113,14,8 ,42,,6",
 "23000,4,7,1998/10/01 11:24:00,139.8385666245,35.6665166264,2,3,4123006,5 ,4 ,26,,12",
 "163300,2,5,1998/10/01 11:24:00,139.7224677109,35.7757432598,2,11,4061110,4 ,5 ,30,,1",
 "230000,2,4,1998/10/01 11:24:00,139.5357067055,35.3938632454,1,8,2410601,7 ,3 ,15,,12",
 "184000,2,2,1998/10/01 11:24:00,140.1007039093,36.0910758029,2,4,5214028,13,5 ,49,,2",
 "202400,2,2,1998/10/01 11:24:00,140.5408231892,35.9403543279,2,10,5610009,4 ,4 ,34,,7",
 "529000,6,6,1998/10/01 11:24:00,139.083672136,35.9984838535,2,6,3910031,14,3 ,34,,6",
 "621000,4,8,1998/10/01 11:24:00,139.6484793362,35.6566038451,2,10,0436007,14,3 ,45,,1",
 "650900,2,2,1998/10/01 11:24:00,139.7235436983,35.7747124702,1,9,0531007,3 ,9 ,43,,6",
 "731400,2,2,1998/10/01 11:24:00,139.4797155853,35.6885414151,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:24:00,139.3239466327,35.6606648072,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:24:00,139.5307870283,35.6567340603,1,5,0750217,9 ,10,49,,12",
 "69000,2,3,1998/10/01 11:25:00,139.826772272,35.7873223093,1,4,4312114,13,2 ,52,,9",
 "89700,11,11,1998/10/01 11:25:00,140.2544045838,35.753401556,2,8,4422113,3 ,3 ,42,,7",
 "128800,4,7,1998/10/01 11:25:00,140.49868313,35.8881508894,1,14,4831221,15,3 ,26,,10",
 "273700,4,5,1998/10/01 11:25:00,139.3105595543,35.388323582,2,5,2820113,14,8 ,42,,6",
 "273700,5,6,1998/10/01 11:25:00,139.3105595543,35.388323582,2,5,2820113,14,97,42,,97",
 "23000,4,7,1998/10/01 11:25:00,139.8315817375,35.6684220943,2,3,4123006,5 ,4 ,26,,12",
 "163300,2,5,1998/10/01 11:25:00,139.7198037272,35.7747856886,2,11,4061110,4 ,5 ,30,,1",
 "230000,2,4,1998/10/01 11:25:00,139.5353422081,35.3899124217,1,8,2410601,7 ,3 ,15,,12",
 "184000,2,2,1998/10/01 11:25:00,140.1026847418,36.0909054553,2,4,5214028,13,5 ,49,,2",
 "202400,2,2,1998/10/01 11:25:00,140.5429776365,35.9379725869,2,10,5610009,4 ,4 ,34,,7",
 "529000,6,6,1998/10/01 11:25:00,139.0832513398,35.9965696662,2,6,3910031,14,3 ,34,,6",
 "621000,4,8,1998/10/01 11:25:00,139.6490173686,35.6550128086,2,10,0436007,14,3 ,45,,1",
 "621000,5,9,1998/10/01 11:25:00,139.6490173686,35.6550128086,2,10,0436007,14,97,45,,97",
 "650900,2,2,1998/10/01 11:25:00,139.7218080494,35.7757954219,1,9,0531007,3 ,9 ,43,,6",
 "731400,2,2,1998/10/01 11:25:00,139.4794300491,35.6883245525,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:25:00,139.3242745087,35.66063943,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:25:00,139.5362378999,35.6542855288,1,5,0750217,9 ,10,49,,12",
 "69000,2,3,1998/10/01 11:26:00,139.8037398858,35.7626883236,1,4,4312114,13,2 ,52,,9",
 "89700,11,11,1998/10/01 11:26:00,140.2532886,35.7519438987,2,8,4422113,3 ,3 ,42,,7",
 "128800,4,7,1998/10/01 11:26:00,140.4986828026,35.888150622,1,14,4831221,15,3 ,26,,10",
 "23000,4,7,1998/10/01 11:26:00,139.8244277912,35.6699025077,2,3,4123006,5 ,4 ,26,,12",
 "163300,2,5,1998/10/01 11:26:00,139.7194111564,35.77428345,2,11,4061110,4 ,5 ,30,,1",
 "230000,2,4,1998/10/01 11:26:00,139.5349769459,35.3859616441,1,8,2410601,7 ,3 ,15,,12",
 "184000,2,2,1998/10/01 11:26:00,140.1051044765,36.0920121615,2,4,5214028,13,5 ,49,,2",
 "202400,2,2,1998/10/01 11:26:00,140.5459575458,35.9360763571,2,10,5610009,4 ,4 ,34,,7",
 "529000,6,6,1998/10/01 11:26:00,139.0822333369,35.9947990227,2,6,3910031,14,3 ,34,,6",
 "650900,2,2,1998/10/01 11:26:00,139.7202655411,35.7763349029,1,9,0531007,3 ,9 ,43,,6",
 "731400,2,2,1998/10/01 11:26:00,139.4792257045,35.6880440668,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:26:00,139.3246023845,35.6606140519,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:26:00,139.542088526,35.6525439596,1,5,0750217,9 ,10,49,,12",
 "69000,2,3,1998/10/01 11:27:00,139.7930142815,35.7328553459,1,4,4312114,13,2 ,52,,9",
 "89700,11,11,1998/10/01 11:27:00,140.2539938329,35.7509314893,2,8,4422113,3 ,3 ,42,,7",
 "128800,4,7,1998/10/01 11:27:00,140.4986824752,35.8881503547,1,14,4831221,15,3 ,26,,10",
 "23000,4,7,1998/10/01 11:27:00,139.8170580946,35.669538482,2,3,4123006,5 ,4 ,26,,12",
 "163300,2,5,1998/10/01 11:27:00,139.7171797388,35.772961924,2,11,4061110,4 ,5 ,30,,1",
 "230000,2,4,1998/10/01 11:27:00,139.5345512383,35.3820162027,1,8,2410601,7 ,3 ,15,,12",
 "184000,2,2,1998/10/01 11:27:00,140.1073412363,36.0925491046,2,4,5214028,13,5 ,49,,2",
 "202400,2,2,1998/10/01 11:27:00,140.5497112335,35.9348261962,2,10,5610009,4 ,4 ,34,,7",
 "529000,6,6,1998/10/01 11:27:00,139.0810940082,35.993259577,2,6,3910031,14,3 ,34,,6",
 "650900,2,2,1998/10/01 11:27:00,139.7181183261,35.7777218623,1,9,0531007,3 ,9 ,43,,6",
 "731400,2,2,1998/10/01 11:27:00,139.4790648895,35.6877458636,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:27:00,139.3249302601,35.660588673,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:27:00,139.5479488704,35.6508250334,1,5,0750217,9 ,10,49,,12",
 "69000,2,3,1998/10/01 11:28:00,139.7729295861,35.7072084681,1,4,4312114,13,2 ,52,,9",
 "89700,11,11,1998/10/01 11:28:00,140.2535098681,35.7493465636,2,8,4422113,3 ,3 ,42,,7",
 "128800,4,7,1998/10/01 11:28:00,140.4986821477,35.8881500874,1,14,4831221,15,3 ,26,,10",
 "23000,4,7,1998/10/01 11:28:00,139.8096817685,35.6692862685,2,3,4123006,5 ,4 ,26,,12",
 "163300,2,5,1998/10/01 11:28:00,139.7147444175,35.7717718119,2,11,4061110,4 ,5 ,30,,1",
 "230000,2,4,1998/10/01 11:28:00,139.5333510289,35.3781929462,1,8,2410601,7 ,3 ,15,,12",
 "184000,2,2,1998/10/01 11:28:00,140.1087412385,36.0906023995,2,4,5214028,13,5 ,49,,2",
 "202400,2,2,1998/10/01 11:28:00,140.5534937224,35.9335563767,2,10,5610009,4 ,4 ,34,,7",
 "529000,6,6,1998/10/01 11:28:00,139.0808797431,35.9913682283,2,6,3910031,14,3 ,34,,6",
 "650900,2,2,1998/10/01 11:28:00,139.715594308,35.7793947547,1,9,0531007,3 ,9 ,43,,6",
 "731400,2,2,1998/10/01 11:28:00,139.478965283,35.6874292569,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:28:00,139.3252581355,35.6605632931,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:28:00,139.5539753109,35.6496974323,1,5,0750217,9 ,10,49,,12",
 "69000,2,3,1998/10/01 11:29:00,139.7613356234,35.6790394705,1,4,4312114,13,2 ,52,,9",
 "89700,11,11,1998/10/01 11:29:00,140.2534086975,35.7479700559,2,8,4422113,3 ,3 ,42,,7",
 "128800,4,7,1998/10/01 11:29:00,140.4986818203,35.88814982,1,14,4831221,15,3 ,26,,10",
 "23000,4,7,1998/10/01 11:29:00,139.8023057075,35.6695298678,2,3,4123006,5 ,4 ,26,,12",
 "163300,2,5,1998/10/01 11:29:00,139.7127309684,35.7704088753,2,11,4061110,4 ,5 ,30,,1",
 "230000,2,4,1998/10/01 11:29:00,139.531443994,35.3745529172,1,8,2410601,7 ,3 ,15,,12",
 "184000,2,2,1998/10/01 11:29:00,140.1101778092,36.0886733049,2,4,5214028,13,5 ,49,,2",
 "202400,2,2,1998/10/01 11:29:00,140.5573082238,35.9323481458,2,10,5610009,4 ,4 ,34,,7",
 "529000,6,6,1998/10/01 11:29:00,139.0807639235,35.9894519376,2,6,3910031,14,3 ,34,,6",
 "650900,2,2,1998/10/01 11:29:00,139.7134095128,35.7813404479,1,9,0531007,3 ,9 ,43,,6",
 "731400,2,2,1998/10/01 11:29:00,139.4788656773,35.68711265,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:29:00,139.3255860107,35.6605379124,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:29:00,139.5598331751,35.6508249425,1,5,0750217,9 ,10,49,,12",
 "786600,3,5,1998/10/01 11:30:00,139.3259138857,35.6605125307,2,3,0930110,13,2 ,35,,9",
 "69000,2,3,1998/10/01 11:30:00,139.7470755545,35.651918824,1,4,4312114,13,2 ,52,,9",
 "69000,3,4,1998/10/01 11:30:00,139.7470755545,35.651918824,1,4,4312114,13,97,52,,97",
 "89700,11,11,1998/10/01 11:30:00,140.2554569324,35.7471696756,2,8,4422113,3 ,3 ,42,,7",
 "89700,12,12,1998/10/01 11:30:00,140.2554569324,35.7471696756,2,8,4422113,3 ,97,42,,97",
 "128800,4,7,1998/10/01 11:30:00,140.4986814929,35.8881495527,1,14,4831221,15,3 ,26,,10",
 "23000,4,7,1998/10/01 11:30:00,139.7956406097,35.6720590573,2,3,4123006,5 ,4 ,26,,12",
 "163300,2,5,1998/10/01 11:30:00,139.7101045148,35.7695451889,2,11,4061110,4 ,5 ,30,,1",
 "163300,3,6,1998/10/01 11:30:00,139.7101045148,35.7695451889,2,11,4061110,4 ,97,30,,97",
 "230000,2,4,1998/10/01 11:30:00,139.5296571713,35.3708768115,1,8,2410601,7 ,3 ,15,,12",
 "184000,2,2,1998/10/01 11:30:00,140.1111140122,36.0887733889,2,4,5214028,13,5 ,49,,2",
 "184000,3,3,1998/10/01 11:30:00,140.1111140122,36.0887733889,2,4,5214028,13,97,49,,97",
 "202400,2,2,1998/10/01 11:30:00,140.5609272425,35.9307973811,2,10,5610009,4 ,4 ,34,,7",
 "202400,3,3,1998/10/01 11:30:00,140.5609272425,35.9307973811,2,10,5610009,4 ,97,34,,97",
 "529000,6,6,1998/10/01 11:30:00,139.080472209,35.9875070426,2,6,3910031,14,3 ,34,,6",
 "650900,2,2,1998/10/01 11:30:00,139.7107332328,35.7828739615,1,9,0531007,3 ,9 ,43,,6",
 "731400,2,2,1998/10/01 11:30:00,139.4787660723,35.686796043,2,10,0771111,14,4 ,32,,6",
 "793500,4,7,1998/10/01 11:30:00,139.6968624888,35.5314219481,2,11,1414207,14,3 ,39,,12",
 "715300,2,3,1998/10/01 11:30:00,139.5652316062,35.6533591932,1,5,0750217,9 ,10,49,,12",
 "128800,4,7,1998/10/01 11:31:00,140.4986811655,35.8881492854,1,14,4831221,15,3 ,26,,10",
 "317400,4,4,1998/10/01 11:31:00,139.705181114,35.5264002511,2,5,2022101,14,3 ,42,,1",
 "23000,4,7,1998/10/01 11:31:00,139.7895850504,35.6754126971,2,3,4123006,5 ,4 ,26,,12",
 "230000,2,4,1998/10/01 11:31:00,139.5303196209,35.3669808282,1,8,2410601,7 ,3 ,15,,12",
 "239200,2,2,1998/10/01 11:31:00,139.4316945868,35.3352818088,2,9,2420201,14,4 ,33,,3",
 "377200,4,4,1998/10/01 11:31:00,139.6329738734,35.9220800439,1,14,3052009,15,3 ,29,,3",
 "384100,2,2,1998/10/01 11:31:00,139.5984868809,35.9574076778,2,11,3057004,14,4 ,31,,2",
 "487600,6,6,1998/10/01 11:31:00,139.6243881936,35.9062277396,1,10,3512006,6 ,1 ,36,,6",
 "529000,6,6,1998/10/01 11:31:00,139.0801453687,35.985566162,2,6,3910031,14,3 ,34,,6",
 "568100,4,4,1998/10/01 11:31:00,139.7040072671,35.6907978304,2,6,0231108,14,5 ,58,,2",
 "650900,2,2,1998/10/01 11:31:00,139.7082288716,35.7845380131,1,9,0531007,3 ,9 ,43,,6",
 "731400,2,2,1998/10/01 11:31:00,139.4786619794,35.6864804039,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:31:00,139.3262417605,35.6604871482,2,3,0930110,13,2 ,35,,9",
 "793500,4,7,1998/10/01 11:31:00,139.694130521,35.5290574367,2,11,1414207,14,3 ,39,,12",
 "715300,2,3,1998/10/01 11:31:00,139.5706338414,35.655888221,1,5,0750217,9 ,10,49,,12",
 "128800,4,7,1998/10/01 11:32:00,140.498680838,35.888149018,1,14,4831221,15,3 ,26,,10",
 "317400,4,4,1998/10/01 11:32:00,139.7051804049,35.5264005515,2,5,2022101,14,3 ,42,,1",
 "23000,4,7,1998/10/01 11:32:00,139.7831966004,35.6782281315,2,3,4123006,5 ,4 ,26,,12",
 "230000,2,4,1998/10/01 11:32:00,139.5295732857,35.3631320229,1,8,2410601,7 ,3 ,15,,12",
 "239200,2,2,1998/10/01 11:32:00,139.4333443326,35.3357683297,2,9,2420201,14,4 ,33,,3",
 "377200,4,4,1998/10/01 11:32:00,139.6330951884,35.9220291836,1,14,3052009,15,3 ,29,,3",
 "384100,2,2,1998/10/01 11:32:00,139.6020679833,35.9585541209,2,11,3057004,14,4 ,31,,2",
 "487600,6,6,1998/10/01 11:32:00,139.6245147412,35.906284004,1,10,3512006,6 ,1 ,36,,6",
 "529000,6,6,1998/10/01 11:32:00,139.0801685513,35.98372859,2,6,3910031,14,3 ,34,,6",
 "568100,4,4,1998/10/01 11:32:00,139.7040894738,35.6907886835,2,6,0231108,14,5 ,58,,2",
 "650900,2,2,1998/10/01 11:32:00,139.706619974,35.7866387555,1,9,0531007,3 ,9 ,43,,6",
 "731400,2,2,1998/10/01 11:32:00,139.4785568393,35.6861649905,2,10,0771111,14,4 ,32,,6",
 "793500,4,7,1998/10/01 11:32:00,139.691398499,35.5266929668,2,11,1414207,14,3 ,39,,12",
 "786600,3,5,1998/10/01 11:32:00,139.3265696351,35.6604617648,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:32:00,139.576045747,35.6584034231,1,5,0750217,9 ,10,49,,12",
 "128800,4,7,1998/10/01 11:33:00,140.4986805106,35.8881487507,1,14,4831221,15,3 ,26,,10",
 "317400,4,4,1998/10/01 11:33:00,139.7051796958,35.5264008519,2,5,2022101,14,3 ,42,,1",
 "23000,4,7,1998/10/01 11:33:00,139.7768685762,35.6812976227,2,3,4123006,5 ,4 ,26,,12",
 "230000,2,4,1998/10/01 11:33:00,139.5299971622,35.3593479704,1,8,2410601,7 ,3 ,15,,12",
 "239200,3,3,1998/10/01 11:33:00,139.4348131125,35.3346416203,2,9,2420201,14,97,33,,97",
 "239200,2,2,1998/10/01 11:33:00,139.4348131125,35.3346416203,2,9,2420201,14,4 ,33,,3",
 "377200,4,4,1998/10/01 11:33:00,139.6332165033,35.9219783232,1,14,3052009,15,3 ,29,,3",
 "384100,2,2,1998/10/01 11:33:00,139.605614883,35.959779461,2,11,3057004,14,4 ,31,,2",
 "487600,6,6,1998/10/01 11:33:00,139.6246412889,35.9063402683,1,10,3512006,6 ,1 ,36,,6",
 "529000,6,6,1998/10/01 11:33:00,139.0805867869,35.9825991896,2,6,3910031,14,3 ,34,,6",
 "568100,4,4,1998/10/01 11:33:00,139.7040727687,35.6909603125,2,6,0231108,14,5 ,58,,2",
 "650900,2,2,1998/10/01 11:33:00,139.7042388473,35.7884428101,1,9,0531007,3 ,9 ,43,,6",
 "731400,2,2,1998/10/01 11:33:00,139.478446725,35.6858507462,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:33:00,139.3268975096,35.6604363804,2,3,0930110,13,2 ,35,,9",
 "793500,4,7,1998/10/01 11:33:00,139.6886260165,35.52436001,2,11,1414207,14,3 ,39,,12",
 "715300,2,3,1998/10/01 11:33:00,139.5814371952,35.6609475414,1,5,0750217,9 ,10,49,,12",
 "128800,4,7,1998/10/01 11:34:00,140.4986801832,35.8881484834,1,14,4831221,15,3 ,26,,10",
 "317400,4,4,1998/10/01 11:34:00,139.7051789868,35.5264011523,2,5,2022101,14,3 ,42,,1",
 "23000,4,7,1998/10/01 11:34:00,139.77016885,35.6838095805,2,3,4123006,5 ,4 ,26,,12",
 "230000,2,4,1998/10/01 11:34:00,139.5317066828,35.3557620709,1,8,2410601,7 ,3 ,15,,12",
 "377200,4,4,1998/10/01 11:34:00,139.633337818,35.9219274627,1,14,3052009,15,3 ,29,,3",
 "384100,2,2,1998/10/01 11:34:00,139.6092401739,35.9608200701,2,11,3057004,14,4 ,31,,2",
 "487600,6,6,1998/10/01 11:34:00,139.6247678369,35.9063965325,1,10,3512006,6 ,1 ,36,,6",
 "529000,6,6,1998/10/01 11:34:00,139.0820088964,35.9817206287,2,6,3910031,14,3 ,34,,6",
 "529000,7,7,1998/10/01 11:34:00,139.0820088964,35.9817206287,2,6,3910031,14,97,34,,97",
 "568100,4,4,1998/10/01 11:34:00,139.7052681229,35.6907037241,2,6,0231108,14,5 ,58,,2",
 "650900,2,2,1998/10/01 11:34:00,139.7016151212,35.7900407464,1,9,0531007,3 ,9 ,43,,6",
 "731400,2,2,1998/10/01 11:34:00,139.478330091,35.6855380342,2,10,0771111,14,4 ,32,,6",
 "793500,4,7,1998/10/01 11:34:00,139.6858515227,35.5220287149,2,11,1414207,14,3 ,39,,12",
 "786600,3,5,1998/10/01 11:34:00,139.3272253838,35.6604109952,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:34:00,139.5871044502,35.6630542174,1,5,0750217,9 ,10,49,,12",
 "128800,4,7,1998/10/01 11:35:00,140.4986798558,35.888148216,1,14,4831221,15,3 ,26,,10",
 "128800,4,8,1998/10/01 11:35:00,140.4986798558,35.888148216,1,14,4831221,15,3 ,26,,1",
 "317400,4,4,1998/10/01 11:35:00,139.7051782777,35.5264014527,2,5,2022101,14,3 ,42,,1",
 "317400,4,5,1998/10/01 11:35:00,139.7051782777,35.5264014527,2,5,2022101,14,3 ,42,,10",
 "23000,4,7,1998/10/01 11:35:00,139.7630338031,35.6853631772,2,3,4123006,5 ,4 ,26,,12",
 "230000,2,4,1998/10/01 11:35:00,139.5303350884,35.351966651,1,8,2410601,7 ,3 ,15,,12",
 "377200,4,4,1998/10/01 11:35:00,139.6334591325,35.921876602,1,14,3052009,15,3 ,29,,3",
 "384100,2,2,1998/10/01 11:35:00,139.6101885067,35.9600179749,2,11,3057004,14,4 ,31,,2",
 "384100,3,3,1998/10/01 11:35:00,139.6101885067,35.9600179749,2,11,3057004,14,97,31,,97",
 "487600,6,6,1998/10/01 11:35:00,139.624894385,35.9064527965,1,10,3512006,6 ,1 ,36,,6",
 "568100,4,4,1998/10/01 11:35:00,139.7063558804,35.6902014957,2,6,0231108,14,5 ,58,,2",
 "650900,2,2,1998/10/01 11:35:00,139.6984301715,35.7906522572,1,9,0531007,3 ,9 ,43,,6",
 "731400,2,2,1998/10/01 11:35:00,139.4782134578,35.685225322,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:35:00,139.3275532578,35.6603856091,2,3,0930110,13,2 ,35,,9",
 "793500,4,7,1998/10/01 11:35:00,139.6830771894,35.5196973554,2,11,1414207,14,3 ,39,,12",
 "715300,2,3,1998/10/01 11:35:00,139.5927999075,35.6651119522,1,5,0750217,9 ,10,49,,12",
 "128800,4,8,1998/10/01 11:36:00,140.4757996911,35.8792582656,1,14,4831221,15,3 ,26,,1",
 "317400,4,5,1998/10/01 11:36:00,139.7051781595,35.5264015028,2,5,2022101,14,3 ,42,,10",
 "23000,4,7,1998/10/01 11:36:00,139.7592775582,35.6900211247,2,3,4123006,5 ,4 ,26,,12",
 "230000,2,4,1998/10/01 11:36:00,139.5273545704,35.348950056,1,8,2410601,7 ,3 ,15,,12",
 "241500,6,6,1998/10/01 11:36:00,139.4026233715,35.3446729666,1,6,2420707,5 ,5 ,37,,6",
 "377200,4,4,1998/10/01 11:36:00,139.6335804469,35.9218257412,1,14,3052009,15,3 ,29,,3",
 "487600,6,6,1998/10/01 11:36:00,139.6250209333,35.9065090604,1,10,3512006,6 ,1 ,36,,6",
 "568100,4,4,1998/10/01 11:36:00,139.707446955,35.6897040568,2,6,0231108,14,5 ,58,,2",
 "650900,2,2,1998/10/01 11:36:00,139.6951799925,35.7910350606,1,9,0531007,3 ,9 ,43,,6",
 "731400,2,2,1998/10/01 11:36:00,139.4781024411,35.6849112534,2,10,0771111,14,4 ,32,,6",
 "793500,4,7,1998/10/01 11:36:00,139.680427449,35.517272627,2,11,1414207,14,3 ,39,,12",
 "786600,3,5,1998/10/01 11:36:00,139.3278811317,35.6603602221,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:36:00,139.5985019988,35.6671577158,1,5,0750217,9 ,10,49,,12",
 "128800,4,8,1998/10/01 11:37:00,140.4668282265,35.8535511922,1,14,4831221,15,3 ,26,,1",
 "317400,4,5,1998/10/01 11:37:00,139.7051780412,35.5264015529,2,5,2022101,14,3 ,42,,10",
 "23000,4,7,1998/10/01 11:37:00,139.7536178322,35.6934622678,2,3,4123006,5 ,4 ,26,,12",
 "230000,2,4,1998/10/01 11:37:00,139.5233195604,35.3467662245,1,8,2410601,7 ,3 ,15,,12",
 "241500,6,6,1998/10/01 11:37:00,139.4023034806,35.344025666,1,6,2420707,5 ,5 ,37,,6",
 "377200,4,4,1998/10/01 11:37:00,139.6337017611,35.9217748803,1,14,3052009,15,3 ,29,,3",
 "487600,6,6,1998/10/01 11:37:00,139.6251474818,35.9065653242,1,10,3512006,6 ,1 ,36,,6",
 "568100,4,4,1998/10/01 11:37:00,139.7085417878,35.6892120943,2,6,0231108,14,5 ,58,,2",
 "650900,2,2,1998/10/01 11:37:00,139.6921997851,35.7918809661,1,9,0531007,3 ,9 ,43,,6",
 "731400,2,2,1998/10/01 11:37:00,139.4779922163,35.6845969991,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:37:00,139.3282090053,35.6603348342,2,3,0930110,13,2 ,35,,9",
 "793500,4,7,1998/10/01 11:37:00,139.6783768641,35.5145078286,2,11,1414207,14,3 ,39,,12",
 "715300,2,3,1998/10/01 11:37:00,139.604198999,35.6692121032,1,5,0750217,9 ,10,49,,12",
 "128800,4,8,1998/10/01 11:38:00,140.4397038662,35.8417778211,1,14,4831221,15,3 ,26,,1",
 "317400,4,5,1998/10/01 11:38:00,139.705177923,35.526401603,2,5,2022101,14,3 ,42,,10",
 "23000,4,7,1998/10/01 11:38:00,139.7476670248,35.6946501221,2,3,4123006,5 ,4 ,26,,12",
 "230000,2,4,1998/10/01 11:38:00,139.5192908597,35.3445756314,1,8,2410601,7 ,3 ,15,,12",
 "241500,6,6,1998/10/01 11:38:00,139.4046820048,35.3447435841,1,6,2420707,5 ,5 ,37,,6",
 "377200,4,4,1998/10/01 11:38:00,139.6338230752,35.9217240192,1,14,3052009,15,3 ,29,,3",
 "487600,6,6,1998/10/01 11:38:00,139.6252740305,35.9066215879,1,10,3512006,6 ,1 ,36,,6",
 "568100,4,4,1998/10/01 11:38:00,139.7096790211,35.6888002937,2,6,0231108,14,5 ,58,,2",
 "650900,2,2,1998/10/01 11:38:00,139.6896038325,35.7933633937,1,9,0531007,3 ,9 ,43,,6",
 "731400,2,2,1998/10/01 11:38:00,139.4778835751,35.684282377,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:38:00,139.3285368787,35.6603094454,2,3,0930110,13,2 ,35,,9",
 "793500,4,7,1998/10/01 11:38:00,139.676968879,35.5114733782,2,11,1414207,14,3 ,39,,12",
 "715300,2,3,1998/10/01 11:38:00,139.6100793137,35.670725721,1,5,0750217,9 ,10,49,,12",
 "128800,4,8,1998/10/01 11:39:00,140.4125763139,35.8291178773,1,14,4831221,15,3 ,26,,1",
 "317400,4,5,1998/10/01 11:39:00,139.7051778048,35.5264016531,2,5,2022101,14,3 ,42,,10",
 "23000,4,7,1998/10/01 11:39:00,139.7407780339,35.6924951313,2,3,4123006,5 ,4 ,26,,12",
 "230000,2,4,1998/10/01 11:39:00,139.5152510374,35.3423980533,1,8,2410601,7 ,3 ,15,,12",
 "241500,6,6,1998/10/01 11:39:00,139.4041365658,35.3447683072,1,6,2420707,5 ,5 ,37,,6",
 "312800,6,6,1998/10/01 11:39:00,139.6976382567,35.5114193167,2,6,2015203,14,4 ,34,,2",
 "377200,4,4,1998/10/01 11:39:00,139.6339443891,35.9216731581,1,14,3052009,15,3 ,29,,3",
 "487600,6,6,1998/10/01 11:39:00,139.6252667909,35.9067244414,1,10,3512006,6 ,1 ,36,,6",
 "568100,4,4,1998/10/01 11:39:00,139.7108891189,35.6885451754,2,6,0231108,14,5 ,58,,2",
 "650900,2,2,1998/10/01 11:39:00,139.6863290263,35.7935647978,1,9,0531007,3 ,9 ,43,,6",
 "731400,2,2,1998/10/01 11:39:00,139.4777749346,35.6839677547,2,10,0771111,14,4 ,32,,6",
 "793500,4,7,1998/10/01 11:39:00,139.6760316182,35.5083145535,2,11,1414207,14,3 ,39,,12",
 "793500,4,8,1998/10/01 11:39:00,139.6760316182,35.5083145535,2,11,1414207,14,3 ,39,,10",
 "786600,3,5,1998/10/01 11:39:00,139.3288650089,35.6602865351,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:39:00,139.6162088824,35.6698306162,1,5,0750217,9 ,10,49,,12",
 "793500,4,8,1998/10/01 11:40:00,139.6756301748,35.5072104433,2,11,1414207,14,3 ,39,,10",
 "128800,4,8,1998/10/01 11:40:00,140.4004605494,35.8079538996,1,14,4831221,15,3 ,26,,1",
 "128800,5,9,1998/10/01 11:40:00,140.4004605494,35.8079538996,1,14,4831221,15,97,26,,97",
 "317400,4,5,1998/10/01 11:40:00,139.7051776865,35.5264017032,2,5,2022101,14,3 ,42,,10",
 "23000,4,7,1998/10/01 11:40:00,139.7337951368,35.6910456048,2,3,4123006,5 ,4 ,26,,12",
 "230000,2,4,1998/10/01 11:40:00,139.5112114325,35.3402203407,1,8,2410601,7 ,3 ,15,,12",
 "241500,6,6,1998/10/01 11:40:00,139.4067738949,35.344790237,1,6,2420707,5 ,5 ,37,,6",
 "312800,6,6,1998/10/01 11:40:00,139.6959338201,35.5158952148,2,6,2015203,14,4 ,34,,2",
 "377200,4,4,1998/10/01 11:40:00,139.6340657029,35.9216222968,1,14,3052009,15,3 ,29,,3",
 "487600,6,6,1998/10/01 11:40:00,139.6252294712,35.90683777,1,10,3512006,6 ,1 ,36,,6",
 "568100,4,4,1998/10/01 11:40:00,139.7121045982,35.6883081173,2,6,0231108,14,5 ,58,,2",
 "650900,2,2,1998/10/01 11:40:00,139.6830774161,35.7938447496,1,9,0531007,3 ,9 ,43,,6",
 "650900,3,3,1998/10/01 11:40:00,139.6830774161,35.7938447496,1,9,0531007,3 ,97,43,,97",
 "731400,2,2,1998/10/01 11:40:00,139.4776552789,35.6836590042,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:40:00,139.329193451,35.6602666373,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:40:00,139.6223579649,35.6690214734,1,5,0750217,9 ,10,49,,12",
 "317400,4,5,1998/10/01 11:41:00,139.7051775683,35.5264017533,2,5,2022101,14,3 ,42,,10",
 "23000,4,7,1998/10/01 11:41:00,139.7266466823,35.6917881545,2,3,4123006,5 ,4 ,26,,12",
 "230000,2,4,1998/10/01 11:41:00,139.507172045,35.3380424934,1,8,2410601,7 ,3 ,15,,12",
 "142600,8,8,1998/10/01 11:41:00,140.1220103194,35.628629844,2,14,4013104,14,8 ,27,,6",
 "241500,6,6,1998/10/01 11:41:00,139.4082662396,35.3438028185,1,6,2420707,5 ,5 ,37,,6",
 "312800,6,6,1998/10/01 11:41:00,139.6943511194,35.5204017312,2,6,2015203,14,4 ,34,,2",
 "377200,4,4,1998/10/01 11:41:00,139.6341870165,35.9215714354,1,14,3052009,15,3 ,29,,3",
 "439300,2,2,1998/10/01 11:41:00,139.8131704818,35.8548012841,2,6,3213107,14,4 ,29,,1",
 "487600,6,6,1998/10/01 11:41:00,139.6251921513,35.9069510986,1,10,3512006,6 ,1 ,36,,6",
 "568100,4,4,1998/10/01 11:41:00,139.713313872,35.6880505286,2,6,0231108,14,5 ,58,,2",
 "731400,2,2,1998/10/01 11:41:00,139.4776045411,35.683334767,2,10,0771111,14,4 ,32,,6",
 "726800,2,2,1998/10/01 11:41:00,139.4359762403,35.7082564403,1,10,0761308,15,4 ,34,,6",
 "793500,4,8,1998/10/01 11:41:00,139.6752287423,35.5061063316,2,11,1414207,14,3 ,39,,10",
 "786600,3,5,1998/10/01 11:41:00,139.329521893,35.6602467385,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:41:00,139.6284794451,35.6680880658,1,5,0750217,9 ,10,49,,12",
 "317400,4,5,1998/10/01 11:42:00,139.7051774501,35.5264018034,2,5,2022101,14,3 ,42,,10",
 "23000,4,7,1998/10/01 11:42:00,139.7194874031,35.6918214761,2,3,4123006,5 ,4 ,26,,12",
 "230000,2,4,1998/10/01 11:42:00,139.5029787366,35.336110309,1,8,2410601,7 ,3 ,15,,12",
 "142600,8,8,1998/10/01 11:42:00,140.1221926554,35.6285857845,2,14,4013104,14,8 ,27,,6",
 "241500,6,6,1998/10/01 11:42:00,139.4107973933,35.3431934999,1,6,2420707,5 ,5 ,37,,6",
 "312800,6,6,1998/10/01 11:42:00,139.6914559383,35.5241652555,2,6,2015203,14,4 ,34,,2",
 "377200,4,4,1998/10/01 11:42:00,139.63430833,35.9215205739,1,14,3052009,15,3 ,29,,3",
 "439300,2,2,1998/10/01 11:42:00,139.8124805918,35.8548228566,2,6,3213107,14,4 ,29,,1",
 "487600,6,6,1998/10/01 11:42:00,139.6251548314,35.9070644272,1,10,3512006,6 ,1 ,36,,6",
 "568100,4,4,1998/10/01 11:42:00,139.7145232974,35.6877955418,2,6,0231108,14,5 ,58,,2",
 "726800,2,2,1998/10/01 11:42:00,139.4361948389,35.7078391784,1,10,0761308,15,4 ,34,,6",
 "731400,2,2,1998/10/01 11:42:00,139.47762512,35.6830093877,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:42:00,139.3298503348,35.6602268388,2,3,0930110,13,2 ,35,,9",
 "793500,4,8,1998/10/01 11:42:00,139.6748979946,35.5052359449,2,11,1414207,14,3 ,39,,10",
 "715300,2,3,1998/10/01 11:42:00,139.6346062142,35.6671728853,1,5,0750217,9 ,10,49,,12",
 "317400,4,5,1998/10/01 11:43:00,139.7051773318,35.5264018534,2,5,2022101,14,3 ,42,,10",
 "23000,4,7,1998/10/01 11:43:00,139.7122683629,35.6912887927,2,3,4123006,5 ,4 ,26,,12",
 "230000,2,4,1998/10/01 11:43:00,139.4982489496,35.3363806126,1,8,2410601,7 ,3 ,15,,12",
 "142600,8,8,1998/10/01 11:43:00,140.1230231532,35.6284829125,2,14,4013104,14,8 ,27,,6",
 "241500,6,6,1998/10/01 11:43:00,139.4124643607,35.3419954428,1,6,2420707,5 ,5 ,37,,6",
 "312800,6,6,1998/10/01 11:43:00,139.6877237637,35.526893639,2,6,2015203,14,4 ,34,,2",
 "377200,4,4,1998/10/01 11:43:00,139.6344296433,35.9214697122,1,14,3052009,15,3 ,29,,3",
 "439300,2,2,1998/10/01 11:43:00,139.8117907015,35.8548444251,2,6,3213107,14,4 ,29,,1",
 "487600,6,6,1998/10/01 11:43:00,139.6251175113,35.9071777557,1,10,3512006,6 ,1 ,36,,6",
 "568100,4,4,1998/10/01 11:43:00,139.7157549917,35.6876287378,2,6,0231108,14,5 ,58,,2",
 "726800,2,2,1998/10/01 11:43:00,139.4356889356,35.7081631123,1,10,0761308,15,4 ,34,,6",
 "731400,2,2,1998/10/01 11:43:00,139.4777395382,35.6826961321,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:43:00,139.3301785543,35.6602048947,2,3,0930110,13,2 ,35,,9",
 "793500,4,8,1998/10/01 11:43:00,139.6748380537,35.5050034632,2,11,1414207,14,3 ,39,,10",
 "715300,2,3,1998/10/01 11:43:00,139.6407331427,35.6662577898,1,5,0750217,9 ,10,49,,12",
 "317400,4,5,1998/10/01 11:44:00,139.7051772136,35.5264019035,2,5,2022101,14,3 ,42,,10",
 "23000,4,7,1998/10/01 11:44:00,139.7051786747,35.6904031095,2,3,4123006,5 ,4 ,26,,12",
 "230000,2,4,1998/10/01 11:44:00,139.4936555622,35.3376174511,1,8,2410601,7 ,3 ,15,,12",
 "142600,8,8,1998/10/01 11:44:00,140.1233380808,35.6284017813,2,14,4013104,14,8 ,27,,6",
 "241500,6,6,1998/10/01 11:44:00,139.4131721677,35.3399130381,1,6,2420707,5 ,5 ,37,,6",
 "312800,6,6,1998/10/01 11:44:00,139.6830931369,35.5293632481,2,6,2015203,14,4 ,34,,2",
 "377200,4,4,1998/10/01 11:44:00,139.6345509564,35.9214188504,1,14,3052009,15,3 ,29,,3",
 "439300,2,2,1998/10/01 11:44:00,139.8111008107,35.8548659897,2,6,3213107,14,4 ,29,,1",
 "487600,6,6,1998/10/01 11:44:00,139.6250801911,35.9072910843,1,10,3512006,6 ,1 ,36,,6",
 "568100,4,4,1998/10/01 11:44:00,139.7169234785,35.6875593953,2,6,0231108,14,5 ,58,,2",
 "731400,2,2,1998/10/01 11:44:00,139.4778539554,35.6823828764,2,10,0771111,14,4 ,32,,6",
 "726800,2,2,1998/10/01 11:44:00,139.434736367,35.7082194286,1,10,0761308,15,4 ,34,,6",
 "793500,4,8,1998/10/01 11:44:00,139.6756970179,35.5043023898,2,11,1414207,14,3 ,39,,10",
 "786600,3,5,1998/10/01 11:44:00,139.330506158,35.6601772873,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:44:00,139.646698026,35.6672453979,1,5,0750217,9 ,10,49,,12",
 "317400,4,5,1998/10/01 11:45:00,139.7051770954,35.5264019536,2,5,2022101,14,3 ,42,,10",
 "23000,4,7,1998/10/01 11:45:00,139.6998272499,35.690734646,2,3,4123006,5 ,4 ,26,,12",
 "23000,4,8,1998/10/01 11:45:00,139.6998272499,35.690734646,2,3,4123006,5 ,4 ,26,,1",
 "230000,2,4,1998/10/01 11:45:00,139.4890504402,35.3388082521,1,8,2410601,7 ,3 ,15,,12",
 "142600,8,8,1998/10/01 11:45:00,140.122529199,35.6285407575,2,14,4013104,14,8 ,27,,6",
 "241500,6,6,1998/10/01 11:45:00,139.413864028,35.3378272,1,6,2420707,5 ,5 ,37,,6",
 "312800,6,6,1998/10/01 11:45:00,139.6826735614,35.5333940626,2,6,2015203,14,4 ,34,,2",
 "312800,7,7,1998/10/01 11:45:00,139.6826735614,35.5333940626,2,6,2015203,14,97,34,,97",
 "377200,4,4,1998/10/01 11:45:00,139.6346722694,35.9213679885,1,14,3052009,15,3 ,29,,3",
 "439300,2,2,1998/10/01 11:45:00,139.8104109196,35.8548875503,2,6,3213107,14,4 ,29,,1",
 "487600,6,6,1998/10/01 11:45:00,139.6250428709,35.9074044128,1,10,3512006,6 ,1 ,36,,6",
 "568100,4,4,1998/10/01 11:45:00,139.7170962004,35.6866423728,2,6,0231108,14,5 ,58,,2",
 "726800,2,2,1998/10/01 11:45:00,139.4327893255,35.7078228428,1,10,0761308,15,4 ,34,,6",
 "731400,2,2,1998/10/01 11:45:00,139.4779683717,35.6820696205,2,10,0771111,14,4 ,32,,6",
 "793500,4,8,1998/10/01 11:45:00,139.6769201962,35.5038706161,2,11,1414207,14,3 ,39,,10",
 "786600,3,5,1998/10/01 11:45:00,139.3308337614,35.6601496791,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:45:00,139.6525709238,35.6689367303,1,5,0750217,9 ,10,49,,12",
 "317400,4,5,1998/10/01 11:46:00,139.7051769771,35.5264020037,2,5,2022101,14,3 ,42,,10",
 "23000,4,8,1998/10/01 11:46:00,139.7023228614,35.6900809398,2,3,4123006,5 ,4 ,26,,1",
 "230000,2,4,1998/10/01 11:46:00,139.4842262244,35.3387030477,1,8,2410601,7 ,3 ,15,,12",
 "142600,8,8,1998/10/01 11:46:00,140.1228660884,35.6286057741,2,14,4013104,14,8 ,27,,6",
 "241500,6,6,1998/10/01 11:46:00,139.4146369834,35.3357603926,1,6,2420707,5 ,5 ,37,,6",
 "335800,4,4,1998/10/01 11:46:00,139.5516621158,35.6324329522,2,6,2130206,14,3 ,31,,1",
 "377200,4,4,1998/10/01 11:46:00,139.6347935822,35.9213171265,1,14,3052009,15,3 ,29,,3",
 "430100,14,14,1998/10/01 11:46:00,139.4040450419,35.8570797879,1,14,3135204,15,3 ,33,,6",
 "439300,2,2,1998/10/01 11:46:00,139.809725154,35.8548692269,2,6,3213107,14,4 ,29,,1",
 "487600,6,6,1998/10/01 11:46:00,139.6250055505,35.9075177413,1,10,3512006,6 ,1 ,36,,6",
 "531300,6,6,1998/10/01 11:46:00,139.0924757272,36.0135975442,1,5,3920007,3 ,1 ,33,,8",
 "568100,4,4,1998/10/01 11:46:00,139.7171637766,35.6856241669,2,6,0231108,14,5 ,58,,2",
 "650900,4,4,1998/10/01 11:46:00,139.6863206234,35.7935651625,1,9,0531007,3 ,3 ,43,,6",
 "621000,10,14,1998/10/01 11:46:00,139.6478098942,35.6545584945,2,10,0436007,14,13,45,,2",
 "731400,2,2,1998/10/01 11:46:00,139.4781094076,35.6817637051,2,10,0771111,14,4 ,32,,6",
 "726800,2,2,1998/10/01 11:46:00,139.4307932126,35.7076493186,1,10,0761308,15,4 ,34,,6",
 "793500,4,8,1998/10/01 11:46:00,139.6780887175,35.5045138957,2,11,1414207,14,3 ,39,,10",
 "786600,3,5,1998/10/01 11:46:00,139.3311613646,35.66012207,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:46:00,139.6583843191,35.6707596797,1,5,0750217,9 ,10,49,,12",
 "317400,4,5,1998/10/01 11:47:00,139.7051768589,35.5264020538,2,5,2022101,14,3 ,42,,10",
 "23000,4,8,1998/10/01 11:47:00,139.7048801573,35.6898932374,2,3,4123006,5 ,4 ,26,,1",
 "230000,2,4,1998/10/01 11:47:00,139.4793991682,35.3384713635,1,8,2410601,7 ,3 ,15,,12",
 "142600,8,8,1998/10/01 11:47:00,140.1237060615,35.6286000288,2,14,4013104,14,8 ,27,,6",
 "241500,6,6,1998/10/01 11:47:00,139.4148869006,35.3336359032,1,6,2420707,5 ,5 ,37,,6",
 "335800,4,4,1998/10/01 11:47:00,139.5511619468,35.6329631466,2,6,2130206,14,3 ,31,,1",
 "377200,4,4,1998/10/01 11:47:00,139.6349148949,35.9212662643,1,14,3052009,15,3 ,29,,3",
 "430100,14,14,1998/10/01 11:47:00,139.4022661977,35.8571637672,1,14,3135204,15,3 ,33,,6",
 "439300,2,2,1998/10/01 11:47:00,139.809287691,35.854549541,2,6,3213107,14,4 ,29,,1",
 "487600,6,6,1998/10/01 11:47:00,139.62496823,35.9076310698,1,10,3512006,6 ,1 ,36,,6",
 "531300,6,6,1998/10/01 11:47:00,139.0962919807,36.0198277449,1,5,3920007,3 ,1 ,33,,8",
 "568100,4,4,1998/10/01 11:47:00,139.7172473383,35.6846067914,2,6,0231108,14,5 ,58,,2",
 "621000,10,14,1998/10/01 11:47:00,139.6470809274,35.6537426435,2,10,0436007,14,13,45,,2",
 "650900,4,4,1998/10/01 11:47:00,139.6895729101,35.7933654281,1,9,0531007,3 ,3 ,43,,6",
 "726800,2,2,1998/10/01 11:47:00,139.4287869191,35.707548027,1,10,0761308,15,4 ,34,,6",
 "731400,2,2,1998/10/01 11:47:00,139.4782513772,35.6814580473,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:47:00,139.3314895299,35.6600993688,2,3,0930110,13,2 ,35,,9",
 "793500,4,8,1998/10/01 11:47:00,139.6793687393,35.5049756978,2,11,1414207,14,3 ,39,,10",
 "715300,2,3,1998/10/01 11:47:00,139.6641341788,35.6727148028,1,5,0750217,9 ,10,49,,12",
 "317400,4,5,1998/10/01 11:48:00,139.7051767407,35.5264021039,2,5,2022101,14,3 ,42,,10",
 "23000,4,8,1998/10/01 11:48:00,139.7074144186,35.6897102098,2,3,4123006,5 ,4 ,26,,1",
 "230000,2,4,1998/10/01 11:48:00,139.4745725207,35.3382342259,1,8,2410601,7 ,3 ,15,,12",
 "142600,8,8,1998/10/01 11:48:00,140.1237513269,35.6291827144,2,14,4013104,14,8 ,27,,6",
 "241500,6,6,1998/10/01 11:48:00,139.4152117818,35.3321554028,1,6,2420707,5 ,5 ,37,,6",
 "335800,4,4,1998/10/01 11:48:00,139.5508871471,35.6333985073,2,6,2130206,14,3 ,31,,1",
 "377200,4,4,1998/10/01 11:48:00,139.6350362075,35.9212154021,1,14,3052009,15,3 ,29,,3",
 "430100,14,14,1998/10/01 11:48:00,139.4002505116,35.8579416959,1,14,3135204,15,3 ,33,,6",
 "439300,2,2,1998/10/01 11:48:00,139.8091281322,35.8540039102,2,6,3213107,14,4 ,29,,1",
 "487600,6,6,1998/10/01 11:48:00,139.6249309094,35.9077443982,1,10,3512006,6 ,1 ,36,,6",
 "531300,6,6,1998/10/01 11:48:00,139.1020247845,36.0253228802,1,5,3920007,3 ,1 ,33,,8",
 "568100,4,4,1998/10/01 11:48:00,139.7172563144,35.68358712,2,6,0231108,14,5 ,58,,2",
 "621000,10,14,1998/10/01 11:48:00,139.646487849,35.6530079551,2,10,0436007,14,13,45,,2",
 "650900,4,4,1998/10/01 11:48:00,139.69216086,35.7919110236,1,9,0531007,3 ,3 ,43,,6",
 "726800,2,2,1998/10/01 11:48:00,139.4267803207,35.7074508065,1,10,0761308,15,4 ,34,,6",
 "731400,2,2,1998/10/01 11:48:00,139.4782764208,35.6811343091,2,10,0771111,14,4 ,32,,6",
 "793500,4,8,1998/10/01 11:48:00,139.6806974666,35.5053582268,2,11,1414207,14,3 ,39,,10",
 "793500,4,9,1998/10/01 11:48:00,139.6806974666,35.5053582268,2,11,1414207,14,3 ,39,,1",
 "786600,3,5,1998/10/01 11:48:00,139.3318177272,35.6600769469,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:48:00,139.6699210445,35.6745940867,1,5,0750217,9 ,10,49,,12",
 "317400,4,5,1998/10/01 11:49:00,139.7051766224,35.526402154,2,5,2022101,14,3 ,42,,10",
 "23000,4,8,1998/10/01 11:49:00,139.7095470452,35.6893160345,2,3,4123006,5 ,4 ,26,,1",
 "230000,2,4,1998/10/01 11:49:00,139.4697459031,35.3379968957,1,8,2410601,7 ,3 ,15,,12",
 "142600,8,8,1998/10/01 11:49:00,140.1232124587,35.6296987319,2,14,4013104,14,8 ,27,,6",
 "241500,6,6,1998/10/01 11:49:00,139.4177558787,35.3327176391,1,6,2420707,5 ,5 ,37,,6",
 "335800,4,4,1998/10/01 11:49:00,139.5517063045,35.6333735572,2,6,2130206,14,3 ,31,,1",
 "377200,4,4,1998/10/01 11:49:00,139.6351575198,35.9211645397,1,14,3052009,15,3 ,29,,3",
 "430100,14,14,1998/10/01 11:49:00,139.3980978126,35.8584249979,1,14,3135204,15,3 ,33,,6",
 "439300,2,2,1998/10/01 11:49:00,139.809079482,35.8534432591,2,6,3213107,14,4 ,29,,1",
 "487600,6,6,1998/10/01 11:49:00,139.6248935887,35.9078577267,1,10,3512006,6 ,1 ,36,,6",
 "531300,6,6,1998/10/01 11:49:00,139.1015985797,36.0324855038,1,5,3920007,3 ,1 ,33,,8",
 "568100,4,4,1998/10/01 11:49:00,139.7172191295,35.6825683097,2,6,0231108,14,5 ,58,,2",
 "621000,10,14,1998/10/01 11:49:00,139.6451718157,35.6528695088,2,10,0436007,14,13,45,,2",
 "650900,4,4,1998/10/01 11:49:00,139.695104337,35.7910427181,1,9,0531007,3 ,3 ,43,,6",
 "731400,2,2,1998/10/01 11:49:00,139.4782813787,35.680807465,2,10,0771111,14,4 ,32,,6",
 "726800,2,2,1998/10/01 11:49:00,139.4253625328,35.7084718514,1,10,0761308,15,4 ,34,,6",
 "786600,3,5,1998/10/01 11:49:00,139.3321459242,35.6600545242,2,3,0930110,13,2 ,35,,9",
 "793500,4,9,1998/10/01 11:49:00,139.6776304035,35.504261593,2,11,1414207,14,3 ,39,,1",
 "715300,2,3,1998/10/01 11:49:00,139.6756936437,35.6764955535,1,5,0750217,9 ,10,49,,12",
 "793500,4,9,1998/10/01 11:50:00,139.6747779963,35.5047600586,2,11,1414207,14,3 ,39,,1",
 "317400,4,5,1998/10/01 11:50:00,139.7051765042,35.5264022041,2,5,2022101,14,3 ,42,,10",
 "23000,4,8,1998/10/01 11:50:00,139.7116392689,35.6896543222,2,3,4123006,5 ,4 ,26,,1",
 "23000,5,9,1998/10/01 11:50:00,139.7116392689,35.6896543222,2,3,4123006,5 ,97,26,,97",
 "230000,2,4,1998/10/01 11:50:00,139.4649193155,35.337759373,1,8,2410601,7 ,3 ,15,,12",
 "142600,8,8,1998/10/01 11:50:00,140.1226348966,35.6301815271,2,14,4013104,14,8 ,27,,6",
 "241500,6,6,1998/10/01 11:50:00,139.418920084,35.3332964672,1,6,2420707,5 ,5 ,37,,6",
 "241500,7,7,1998/10/01 11:50:00,139.418920084,35.3332964672,1,6,2420707,5 ,97,37,,97",
 "335800,4,4,1998/10/01 11:50:00,139.552525961,35.6333765549,2,6,2130206,14,3 ,31,,1",
 "377200,4,4,1998/10/01 11:50:00,139.635278832,35.9211136771,1,14,3052009,15,3 ,29,,3",
 "430100,14,14,1998/10/01 11:50:00,139.3958960448,35.858673736,1,14,3135204,15,3 ,33,,6",
 "439300,2,2,1998/10/01 11:50:00,139.8089013752,35.8535227842,2,6,3213107,14,4 ,29,,1",
 "439300,3,3,1998/10/01 11:50:00,139.8089013752,35.8535227842,2,6,3213107,14,97,29,,97",
 "487600,6,6,1998/10/01 11:50:00,139.6248562679,35.9079710552,1,10,3512006,6 ,1 ,36,,6",
 "531300,6,6,1998/10/01 11:50:00,139.1040023753,36.0392825705,1,5,3920007,3 ,1 ,33,,8",
 "568100,4,4,1998/10/01 11:50:00,139.7182673511,35.6822797906,2,6,0231108,14,5 ,58,,2",
 "650900,4,4,1998/10/01 11:50:00,139.6983328114,35.7906661595,1,9,0531007,3 ,3 ,43,,6",
 "621000,10,14,1998/10/01 11:50:00,139.6443852491,35.6520819783,2,10,0436007,14,13,45,,2",
 "621000,11,15,1998/10/01 11:50:00,139.6443852491,35.6520819783,2,10,0436007,14,97,45,,97",
 "731400,2,2,1998/10/01 11:50:00,139.4782763879,35.6804808211,2,10,0771111,14,4 ,32,,6",
 "726800,2,2,1998/10/01 11:50:00,139.4257592523,35.7090406615,1,10,0761308,15,4 ,34,,6",
 "726800,3,3,1998/10/01 11:50:00,139.4257592523,35.7090406615,1,10,0761308,15,97,34,,97",
 "786600,3,5,1998/10/01 11:50:00,139.3324741211,35.6600321005,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:50:00,139.681399588,35.6784400492,1,5,0750217,9 ,10,49,,12",
 "317400,4,5,1998/10/01 11:51:00,139.705176386,35.5264022542,2,5,2022101,14,3 ,42,,10",
 "230000,2,4,1998/10/01 11:51:00,139.4600927579,35.3375216577,1,8,2410601,7 ,3 ,15,,12",
 "142600,8,8,1998/10/01 11:51:00,140.1223282301,35.630818455,2,14,4013104,14,8 ,27,,6",
 "335800,4,4,1998/10/01 11:51:00,139.5533456612,35.6333819953,2,6,2130206,14,3 ,31,,1",
 "368000,4,4,1998/10/01 11:51:00,139.6115296716,35.8816539254,1,12,3035001,15,3 ,32,,6",
 "377200,4,4,1998/10/01 11:51:00,139.6353461457,35.9210310893,1,14,3052009,15,3 ,29,,3",
 "423200,4,4,1998/10/01 11:51:00,139.4955781249,35.8933877399,2,9,3133014,15,3 ,36,,7",
 "430100,14,14,1998/10/01 11:51:00,139.3941642163,35.8587466573,1,14,3135204,15,3 ,33,,6",
 "487600,6,6,1998/10/01 11:51:00,139.6248189469,35.9080843836,1,10,3512006,6 ,1 ,36,,6",
 "531300,6,6,1998/10/01 11:51:00,139.1026263856,36.0461246293,1,5,3920007,3 ,1 ,33,,8",
 "568100,4,4,1998/10/01 11:51:00,139.7195001641,35.6821121492,2,6,0231108,14,5 ,58,,2",
 "650900,4,4,1998/10/01 11:51:00,139.7015038865,35.7900804701,1,9,0531007,3 ,3 ,43,,6",
 "731400,2,2,1998/10/01 11:51:00,139.4782516818,35.6801545741,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:51:00,139.3328021398,35.660008061,2,3,0930110,13,2 ,35,,9",
 "793500,4,9,1998/10/01 11:51:00,139.6740142005,35.5071450979,2,11,1414207,14,3 ,39,,1",
 "715300,2,3,1998/10/01 11:51:00,139.6869326523,35.6807434332,1,5,0750217,9 ,10,49,,12",
 "317400,4,5,1998/10/01 11:52:00,139.7051762677,35.5264023043,2,5,2022101,14,3 ,42,,10",
 "230000,2,4,1998/10/01 11:52:00,139.4552657263,35.3372918196,1,8,2410601,7 ,3 ,15,,12",
 "142600,8,8,1998/10/01 11:52:00,140.1220032838,35.6314442807,2,14,4013104,14,8 ,27,,6",
 "335800,4,4,1998/10/01 11:52:00,139.5541653617,35.6333874301,2,6,2130206,14,3 ,31,,1",
 "368000,4,4,1998/10/01 11:52:00,139.6085934254,35.8819325659,1,12,3035001,15,3 ,32,,6",
 "377200,4,4,1998/10/01 11:52:00,139.6353673781,35.9209214277,1,14,3052009,15,3 ,29,,3",
 "423200,4,4,1998/10/01 11:52:00,139.4961224624,35.8918103687,2,9,3133014,15,3 ,36,,7",
 "430100,14,14,1998/10/01 11:52:00,139.392916638,35.8602920575,1,14,3135204,15,3 ,33,,6",
 "487600,6,6,1998/10/01 11:52:00,139.6247816259,35.908197712,1,10,3512006,6 ,1 ,36,,6",
 "531300,6,6,1998/10/01 11:52:00,139.1000738959,36.0530720705,1,5,3920007,3 ,1 ,33,,8",
 "568100,4,4,1998/10/01 11:52:00,139.7203325999,35.6816592006,2,6,0231108,14,5 ,58,,2",
 "650900,4,4,1998/10/01 11:52:00,139.7041311995,35.7885208068,1,9,0531007,3 ,3 ,43,,6",
 "731400,2,2,1998/10/01 11:52:00,139.4782269759,35.6798283271,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:52:00,139.3331299733,35.6599823408,2,3,0930110,13,2 ,35,,9",
 "793500,4,9,1998/10/01 11:52:00,139.6714021822,35.5088858187,2,11,1414207,14,3 ,39,,1",
 "715300,2,3,1998/10/01 11:52:00,139.6912055003,35.6844135966,1,5,0750217,9 ,10,49,,12",
 "317400,4,5,1998/10/01 11:53:00,139.7051761495,35.5264023543,2,5,2022101,14,3 ,42,,10",
 "230000,2,4,1998/10/01 11:53:00,139.4504347895,35.337124722,1,8,2410601,7 ,3 ,15,,12",
 "142600,8,8,1998/10/01 11:53:00,140.121831646,35.6321122932,2,14,4013104,14,8 ,27,,6",
 "184000,4,4,1998/10/01 11:53:00,140.1122204222,36.087377534,2,4,5214028,13,4 ,49,,2",
 "335800,4,4,1998/10/01 11:53:00,139.5549847825,35.6333870427,2,6,2130206,14,3 ,31,,1",
 "368000,4,4,1998/10/01 11:53:00,139.6051045708,35.8817183214,1,12,3035001,15,3 ,32,,6",
 "368000,5,5,1998/10/01 11:53:00,139.6051045708,35.8817183214,1,12,3035001,15,97,32,,97",
 "377200,4,4,1998/10/01 11:53:00,139.6353886105,35.9208117662,1,14,3052009,15,3 ,29,,3",
 "423200,4,4,1998/10/01 11:53:00,139.4952764866,35.8902422115,2,9,3133014,15,3 ,36,,7",
 "430100,14,14,1998/10/01 11:53:00,139.3916950961,35.8618514824,1,14,3135204,15,3 ,33,,6",
 "487600,6,6,1998/10/01 11:53:00,139.6247443048,35.9083110404,1,10,3512006,6 ,1 ,36,,6",
 "531300,6,6,1998/10/01 11:53:00,139.0978710603,36.0599178145,1,5,3920007,3 ,1 ,33,,8",
 "568100,4,4,1998/10/01 11:53:00,139.7215809919,35.6816127982,2,6,0231108,14,5 ,58,,2",
 "650900,4,4,1998/10/01 11:53:00,139.7065821632,35.7867674481,1,9,0531007,3 ,3 ,43,,6",
 "731400,2,2,1998/10/01 11:53:00,139.4781889738,35.6795033554,2,10,0771111,14,4 ,32,,6",
 "793500,4,9,1998/10/01 11:53:00,139.6684161589,35.5100927827,2,11,1414207,14,3 ,39,,1",
 "786600,3,5,1998/10/01 11:53:00,139.3334578066,35.6599566198,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:53:00,139.6961718501,35.6874578176,1,5,0750217,9 ,10,49,,12",
 "317400,4,5,1998/10/01 11:54:00,139.7051760313,35.5264024044,2,5,2022101,14,3 ,42,,10",
 "230000,2,4,1998/10/01 11:54:00,139.4456919241,35.3368882673,1,8,2410601,7 ,3 ,15,,12",
 "230000,2,5,1998/10/01 11:54:00,139.4456919241,35.3368882673,1,8,2410601,7 ,3 ,15,,1",
 "142600,8,8,1998/10/01 11:54:00,140.1211883255,35.6320996561,2,14,4013104,14,8 ,27,,6",
 "184000,4,4,1998/10/01 11:54:00,140.1119378236,36.0857953083,2,4,5214028,13,4 ,49,,2",
 "335800,4,4,1998/10/01 11:54:00,139.5557996672,35.6333163576,2,6,2130206,14,3 ,31,,1",
 "377200,4,4,1998/10/01 11:54:00,139.6354775264,35.9207768862,1,14,3052009,15,3 ,29,,3",
 "423200,4,4,1998/10/01 11:54:00,139.4935582607,35.8889486856,2,9,3133014,15,3 ,36,,7",
 "430100,14,14,1998/10/01 11:54:00,139.390477317,35.8634127556,1,14,3135204,15,3 ,33,,6",
 "487600,6,6,1998/10/01 11:54:00,139.6247069835,35.9084243688,1,10,3512006,6 ,1 ,36,,6",
 "531300,6,6,1998/10/01 11:54:00,139.098850653,36.0669479126,1,5,3920007,3 ,1 ,33,,8",
 "568100,4,4,1998/10/01 11:54:00,139.7220961139,35.6808115536,2,6,0231108,14,5 ,58,,2",
 "650900,4,4,1998/10/01 11:54:00,139.7080537087,35.7845964135,1,9,0531007,3 ,3 ,43,,6",
 "731400,2,2,1998/10/01 11:54:00,139.4781202717,35.6791813284,2,10,0771111,14,4 ,32,,6",
 "793500,4,9,1998/10/01 11:54:00,139.665559366,35.5097569424,2,11,1414207,14,3 ,39,,1",
 "786600,3,5,1998/10/01 11:54:00,139.3337867103,35.659943373,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:54:00,139.6998931572,35.6904413647,1,5,0750217,9 ,10,49,,12",
 "317400,4,5,1998/10/01 11:55:00,139.705175913,35.5264024545,2,5,2022101,14,3 ,42,,10",
 "230000,2,5,1998/10/01 11:55:00,139.4456357459,35.3365753515,1,8,2410601,7 ,3 ,15,,1",
 "142600,8,8,1998/10/01 11:55:00,140.1203949902,35.6322408066,2,14,4013104,14,8 ,27,,6",
 "184000,4,4,1998/10/01 11:55:00,140.1128624926,36.084327639,2,4,5214028,13,4 ,49,,2",
 "335800,4,4,1998/10/01 11:55:00,139.5566089865,35.6332114673,2,6,2130206,14,3 ,31,,1",
 "377200,4,4,1998/10/01 11:55:00,139.635612566,35.9207929669,1,14,3052009,15,3 ,29,,3",
 "423200,4,4,1998/10/01 11:55:00,139.4941688464,35.8870894866,2,9,3133014,15,3 ,36,,7",
 "430100,14,14,1998/10/01 11:55:00,139.3916955301,35.8643946486,1,14,3135204,15,3 ,33,,6",
 "430100,15,15,1998/10/01 11:55:00,139.3916955301,35.8643946486,1,14,3135204,15,97,33,,97",
 "487600,6,6,1998/10/01 11:55:00,139.6246696622,35.9085376972,1,10,3512006,6 ,1 ,36,,6",
 "531300,6,6,1998/10/01 11:55:00,139.104597267,36.0698903951,1,5,3920007,3 ,1 ,33,,8",
 "531300,7,7,1998/10/01 11:55:00,139.104597267,36.0698903951,1,5,3920007,3 ,97,33,,97",
 "568100,4,4,1998/10/01 11:55:00,139.723195834,35.680327075,2,6,0231108,14,5 ,58,,2",
 "568100,5,5,1998/10/01 11:55:00,139.723195834,35.680327075,2,6,0231108,14,97,58,,97",
 "650900,4,4,1998/10/01 11:55:00,139.7105483512,35.7829582629,1,9,0531007,3 ,3 ,43,,6",
 "731400,2,2,1998/10/01 11:55:00,139.4780515703,35.6788593013,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:55:00,139.3341157362,35.6599315504,2,3,0930110,13,2 ,35,,9",
 "793500,4,9,1998/10/01 11:55:00,139.6632476187,35.5112154584,2,11,1414207,14,3 ,39,,1",
 "715300,2,3,1998/10/01 11:55:00,139.699416412,35.6954605789,1,5,0750217,9 ,10,49,,12",
 "317400,4,5,1998/10/01 11:56:00,139.7051757948,35.5264025046,2,5,2022101,14,3 ,42,,10",
 "230000,2,5,1998/10/01 11:56:00,139.4455795681,35.3362624357,1,8,2410601,7 ,3 ,15,,1",
 "142600,8,8,1998/10/01 11:56:00,140.1196040166,35.6324717621,2,14,4013104,14,8 ,27,,6",
 "184000,4,4,1998/10/01 11:56:00,140.1139102156,36.0829022983,2,4,5214028,13,4 ,49,,2",
 "335800,4,4,1998/10/01 11:56:00,139.5574042997,35.6330509226,2,6,2130206,14,3 ,31,,1",
 "377200,4,4,1998/10/01 11:56:00,139.6357476057,35.9208090474,1,14,3052009,15,3 ,29,,3",
 "423200,4,4,1998/10/01 11:56:00,139.4929560562,35.8856861235,2,9,3133014,15,3 ,36,,7",
 "487600,6,6,1998/10/01 11:56:00,139.6246323407,35.9086510256,1,10,3512006,6 ,1 ,36,,6",
 "650900,4,4,1998/10/01 11:56:00,139.7132442793,35.7814759466,1,9,0531007,3 ,3 ,43,,6",
 "731400,2,2,1998/10/01 11:56:00,139.4779941682,35.6785360133,2,10,0771111,14,4 ,32,,6",
 "793500,4,9,1998/10/01 11:56:00,139.660216464,35.5109708534,2,11,1414207,14,3 ,39,,1",
 "786600,3,5,1998/10/01 11:56:00,139.334444762,35.659919727,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:56:00,139.7002150307,35.7004866422,1,5,0750217,9 ,10,49,,12",
 "317400,4,5,1998/10/01 11:57:00,139.7051756766,35.5264025547,2,5,2022101,14,3 ,42,,10",
 "230000,2,5,1998/10/01 11:57:00,139.4455233907,35.3359495198,1,8,2410601,7 ,3 ,15,,1",
 "142600,8,8,1998/10/01 11:57:00,140.120059377,35.6329874647,2,14,4013104,14,8 ,27,,6",
 "184000,4,4,1998/10/01 11:57:00,140.1149459834,36.0814712201,2,4,5214028,13,4 ,49,,2",
 "335800,4,4,1998/10/01 11:57:00,139.5581875081,35.6328534138,2,6,2130206,14,3 ,31,,1",
 "377200,4,4,1998/10/01 11:57:00,139.6358826455,35.9208251278,1,14,3052009,15,3 ,29,,3",
 "423200,4,4,1998/10/01 11:57:00,139.4916195853,35.8840985745,2,9,3133014,15,3 ,36,,7",
 "487600,6,6,1998/10/01 11:57:00,139.6245950191,35.9087643539,1,10,3512006,6 ,1 ,36,,6",
 "650900,4,4,1998/10/01 11:57:00,139.7154145977,35.7795445314,1,9,0531007,3 ,3 ,43,,6",
 "731400,2,2,1998/10/01 11:57:00,139.4779543604,35.6782107617,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 11:57:00,139.3347737877,35.6599079026,2,3,0930110,13,2 ,35,,9",
 "793500,4,9,1998/10/01 11:57:00,139.6568754894,35.5106980968,2,11,1414207,14,3 ,39,,1",
 "715300,2,3,1998/10/01 11:57:00,139.7012126902,35.7054854191,1,5,0750217,9 ,10,49,,12",
 "317400,4,5,1998/10/01 11:58:00,139.7051755583,35.5264026048,2,5,2022101,14,3 ,42,,10",
 "230000,2,5,1998/10/01 11:58:00,139.4454672138,35.3356366038,1,8,2410601,7 ,3 ,15,,1",
 "142600,8,8,1998/10/01 11:58:00,140.1202660576,35.6336239285,2,14,4013104,14,8 ,27,,6",
 "184000,4,4,1998/10/01 11:58:00,140.1133597726,36.0820032951,2,4,5214028,13,4 ,49,,2",
 "184000,5,5,1998/10/01 11:58:00,140.1133597726,36.0820032951,2,4,5214028,13,97,49,,97",
 "335800,4,4,1998/10/01 11:58:00,139.5589547537,35.6326185661,2,6,2130206,14,3 ,31,,1",
 "377200,4,4,1998/10/01 11:58:00,139.6360176853,35.9208412081,1,14,3052009,15,3 ,29,,3",
 "423200,4,4,1998/10/01 11:58:00,139.4918630358,35.8826919699,2,9,3133014,15,3 ,36,,7",
 "487600,6,6,1998/10/01 11:58:00,139.624557262,35.9088775762,1,10,3512006,6 ,1 ,36,,6",
 "650900,4,4,1998/10/01 11:58:00,139.7178639567,35.7778165605,1,9,0531007,3 ,3 ,43,,6",
 "731400,2,2,1998/10/01 11:58:00,139.4779425478,35.6778842084,2,10,0771111,14,4 ,32,,6",
 "793500,4,9,1998/10/01 11:58:00,139.6539072494,35.5095667212,2,11,1414207,14,3 ,39,,1",
 "786600,3,5,1998/10/01 11:58:00,139.3351028133,35.6598960773,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:58:00,139.7029547167,35.7103657937,1,5,0750217,9 ,10,49,,12",
 "317400,4,5,1998/10/01 11:59:00,139.7051754401,35.5264026549,2,5,2022101,14,3 ,42,,10",
 "230000,2,5,1998/10/01 11:59:00,139.4457361739,35.3354152136,1,8,2410601,7 ,3 ,15,,1",
 "142600,8,8,1998/10/01 11:59:00,140.120231451,35.634308418,2,14,4013104,14,8 ,27,,6",
 "335800,4,4,1998/10/01 11:59:00,139.5594586384,35.6323072776,2,6,2130206,14,3 ,31,,1",
 "377200,4,4,1998/10/01 11:59:00,139.6361527252,35.9208572882,1,14,3052009,15,3 ,29,,3",
 "423200,4,4,1998/10/01 11:59:00,139.4909374275,35.8809340168,2,9,3133014,15,3 ,36,,7",
 "487600,6,6,1998/10/01 11:59:00,139.62451191,35.9089889491,1,10,3512006,6 ,1 ,36,,6",
 "650900,4,4,1998/10/01 11:59:00,139.7201642699,35.7765669775,1,9,0531007,3 ,3 ,43,,6",
 "731400,2,2,1998/10/01 11:59:00,139.4779368112,35.6775573727,2,10,0771111,14,4 ,32,,6",
 "793500,4,9,1998/10/01 11:59:00,139.6506053609,35.5099947944,2,11,1414207,14,3 ,39,,1",
 "786600,3,5,1998/10/01 11:59:00,139.3354318389,35.6598842511,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 11:59:00,139.7046313774,35.7152611983,1,5,0750217,9 ,10,49,,12",
 "317400,4,5,1998/10/01 12:00:00,139.7051753219,35.526402705,2,5,2022101,14,3 ,42,,10",
 "230000,2,5,1998/10/01 12:00:00,139.4460151591,35.3351966451,1,8,2410601,7 ,3 ,15,,1",
 "142600,8,8,1998/10/01 12:00:00,140.1201678562,35.6349922521,2,14,4013104,14,8 ,27,,6",
 "142600,9,9,1998/10/01 12:00:00,140.1201678562,35.6349922521,2,14,4013104,14,97,27,,97",
 "335800,4,4,1998/10/01 12:00:00,139.558954801,35.6317794054,2,6,2130206,14,3 ,31,,1",
 "335800,5,5,1998/10/01 12:00:00,139.558954801,35.6317794054,2,6,2130206,14,97,31,,97",
 "377200,4,4,1998/10/01 12:00:00,139.6362877651,35.9208733681,1,14,3052009,15,3 ,29,,3",
 "423200,4,4,1998/10/01 12:00:00,139.4898133641,35.8792612521,2,9,3133014,15,3 ,36,,7",
 "423200,5,5,1998/10/01 12:00:00,139.4898133641,35.8792612521,2,9,3133014,15,97,36,,97",
 "487600,6,6,1998/10/01 12:00:00,139.6244665579,35.909100322,1,10,3512006,6 ,1 ,36,,6",
 "650900,4,4,1998/10/01 12:00:00,139.7225002766,35.7758866138,1,9,0531007,3 ,3 ,43,,6",
 "731400,2,2,1998/10/01 12:00:00,139.4779310747,35.6772305369,2,10,0771111,14,4 ,32,,6",
 "793500,4,9,1998/10/01 12:00:00,139.6472679683,35.5103775248,2,11,1414207,14,3 ,39,,1",
 "793500,5,10,1998/10/01 12:00:00,139.6472679683,35.5103775248,2,11,1414207,14,97,39,,97",
 "786600,3,5,1998/10/01 12:00:00,139.3357608643,35.659872424,2,3,0930110,13,2 ,35,,9",
 "715300,2,3,1998/10/01 12:00:00,139.7062144199,35.7201759266,1,5,0750217,9 ,10,49,,12",
 "715300,2,4,1998/10/01 12:00:00,139.7062144199,35.7201759266,1,5,0750217,9 ,10,49,,1",
 "273700,6,7,1998/10/01 12:01:00,139.3091817465,35.38977209,2,5,2820113,14,3 ,42,,6",
 "317400,4,5,1998/10/01 12:01:00,139.7051752036,35.5264027551,2,5,2022101,14,3 ,42,,10",
 "230000,2,5,1998/10/01 12:01:00,139.4460839763,35.3351755621,1,8,2410601,7 ,3 ,15,,1",
 "239200,4,4,1998/10/01 12:01:00,139.4319149521,35.335459123,2,9,2420201,14,3 ,33,,3",
 "377200,4,4,1998/10/01 12:01:00,139.6364228051,35.9208894479,1,14,3052009,15,3 ,29,,3",
 "487600,6,6,1998/10/01 12:01:00,139.6244212056,35.9092116949,1,10,3512006,6 ,1 ,36,,6",
 "503700,4,4,1998/10/01 12:01:00,139.6728844489,36.0147785527,2,7,3613326,14,3 ,29,,6",
 "650900,4,4,1998/10/01 12:01:00,139.7240497818,35.7737910776,1,9,0531007,3 ,3 ,43,,6",
 "731400,2,2,1998/10/01 12:01:00,139.4779253383,35.676903701,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:01:00,139.3360898897,35.6598605961,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:01:00,139.6198669405,35.4673768443,2,11,1330011,15,3 ,45,,6",
 "715300,2,4,1998/10/01 12:01:00,139.7052397456,35.718050755,1,5,0750217,9 ,10,49,,1",
 "685400,4,4,1998/10/01 12:01:00,139.8579415133,35.6466088272,1,11,0634016,15,7 ,48,,1",
 "273700,6,7,1998/10/01 12:02:00,139.3095742678,35.3913309905,2,5,2820113,14,3 ,42,,6",
 "317400,4,5,1998/10/01 12:02:00,139.7051750854,35.5264028052,2,5,2022101,14,3 ,42,,10",
 "230000,2,5,1998/10/01 12:02:00,139.4461003365,35.3349636194,1,8,2410601,7 ,3 ,15,,1",
 "239200,4,4,1998/10/01 12:02:00,139.4290300201,35.3356196403,2,9,2420201,14,3 ,33,,3",
 "377200,4,4,1998/10/01 12:02:00,139.6365578451,35.9209055275,1,14,3052009,15,3 ,29,,3",
 "487600,6,6,1998/10/01 12:02:00,139.6243758532,35.9093230677,1,10,3512006,6 ,1 ,36,,6",
 "503700,4,4,1998/10/01 12:02:00,139.6740412197,36.0170436062,2,7,3613326,14,3 ,29,,6",
 "650900,4,4,1998/10/01 12:02:00,139.7257134583,35.7721876495,1,9,0531007,3 ,3 ,43,,6",
 "731400,2,2,1998/10/01 12:02:00,139.4779771838,35.6765837665,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:02:00,139.336418915,35.6598487672,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:02:00,139.6197467379,35.4680049646,2,11,1330011,15,3 ,45,,6",
 "715300,2,4,1998/10/01 12:02:00,139.7069181089,35.7169504003,1,5,0750217,9 ,10,49,,1",
 "685400,4,4,1998/10/01 12:02:00,139.8648425721,35.6441729725,1,11,0634016,15,7 ,48,,1",
 "273700,6,7,1998/10/01 12:03:00,139.3102487443,35.3927853233,2,5,2820113,14,3 ,42,,6",
 "317400,4,5,1998/10/01 12:03:00,139.7051749672,35.5264028552,2,5,2022101,14,3 ,42,,10",
 "230000,2,5,1998/10/01 12:03:00,139.4461731118,35.3346530086,1,8,2410601,7 ,3 ,15,,1",
 "239200,4,4,1998/10/01 12:03:00,139.4299997959,35.3343933589,2,9,2420201,14,3 ,33,,3",
 "239200,5,5,1998/10/01 12:03:00,139.4299997959,35.3343933589,2,9,2420201,14,97,33,,97",
 "377200,4,4,1998/10/01 12:03:00,139.6366928852,35.920921607,1,14,3052009,15,3 ,29,,3",
 "487600,6,6,1998/10/01 12:03:00,139.6243305007,35.9094344405,1,10,3512006,6 ,1 ,36,,6",
 "503700,4,4,1998/10/01 12:03:00,139.6749308234,36.0201399343,2,7,3613326,14,3 ,29,,6",
 "650900,4,4,1998/10/01 12:03:00,139.7283085195,35.7714233291,1,9,0531007,3 ,3 ,43,,6",
 "731400,2,2,1998/10/01 12:03:00,139.4780743215,35.6762675047,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:03:00,139.3367479402,35.6598369374,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:03:00,139.6192025778,35.4678337585,2,11,1330011,15,3 ,45,,6",
 "715300,2,4,1998/10/01 12:03:00,139.7092616198,35.7165155346,1,5,0750217,9 ,10,49,,1",
 "685400,4,4,1998/10/01 12:03:00,139.8613984795,35.6441329297,1,11,0634016,15,7 ,48,,1",
 "685400,4,5,1998/10/01 12:03:00,139.8613984795,35.6441329297,1,11,0634016,15,7 ,48,,12",
 "273700,6,7,1998/10/01 12:04:00,139.3089419821,35.3942772163,2,5,2820113,14,3 ,42,,6",
 "317400,4,5,1998/10/01 12:04:00,139.7051748489,35.5264029053,2,5,2022101,14,3 ,42,,10",
 "230000,2,5,1998/10/01 12:04:00,139.4462458865,35.3343423976,1,8,2410601,7 ,3 ,15,,1",
 "377200,4,4,1998/10/01 12:04:00,139.6368279253,35.9209376863,1,14,3052009,15,3 ,29,,3",
 "487600,6,6,1998/10/01 12:04:00,139.6242869727,35.9095462918,1,10,3512006,6 ,1 ,36,,6",
 "503700,4,4,1998/10/01 12:04:00,139.6736998409,36.0239112916,2,7,3613326,14,3 ,29,,6",
 "650900,4,4,1998/10/01 12:04:00,139.73087557,35.7708685976,1,9,0531007,3 ,3 ,43,,6",
 "731400,2,2,1998/10/01 12:04:00,139.4781319212,35.675944177,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:04:00,139.3370768794,35.6598237487,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:04:00,139.6187848103,35.4692244172,2,11,1330011,15,3 ,45,,6",
 "715300,2,4,1998/10/01 12:04:00,139.7105013468,35.716299377,1,5,0750217,9 ,10,49,,1",
 "685400,4,5,1998/10/01 12:04:00,139.8567975139,35.6457103768,1,11,0634016,15,7 ,48,,12",
 "273700,6,7,1998/10/01 12:05:00,139.3079584024,35.3955938168,2,5,2820113,14,3 ,42,,6",
 "273700,7,8,1998/10/01 12:05:00,139.3079584024,35.3955938168,2,5,2820113,14,97,42,,97",
 "317400,4,5,1998/10/01 12:05:00,139.7051747307,35.5264029554,2,5,2022101,14,3 ,42,,10",
 "317400,4,6,1998/10/01 12:05:00,139.7051747307,35.5264029554,2,5,2022101,14,3 ,42,,1",
 "230000,2,5,1998/10/01 12:05:00,139.4463186606,35.3340317866,1,8,2410601,7 ,3 ,15,,1",
 "377200,4,4,1998/10/01 12:05:00,139.6369629655,35.9209537655,1,14,3052009,15,3 ,29,,3",
 "487600,6,6,1998/10/01 12:05:00,139.6242438535,35.9096582501,1,10,3512006,6 ,1 ,36,,6",
 "503700,4,4,1998/10/01 12:05:00,139.6750633195,36.0270131336,2,7,3613326,14,3 ,29,,6",
 "650900,4,4,1998/10/01 12:05:00,139.733197928,35.7693821526,1,9,0531007,3 ,3 ,43,,6",
 "731400,2,2,1998/10/01 12:05:00,139.4781694707,35.6756187467,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:05:00,139.3374056498,35.6598078876,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 12:05:00,139.8517400241,35.6460919149,1,11,0634016,15,7 ,48,,12",
 "851000,4,4,1998/10/01 12:05:00,139.6187276459,35.4715490425,2,11,1330011,15,3 ,45,,6",
 "715300,2,4,1998/10/01 12:05:00,139.7125644573,35.7153845204,1,5,0750217,9 ,10,49,,1",
 "715300,3,5,1998/10/01 12:05:00,139.7125644573,35.7153845204,1,5,0750217,9 ,97,49,,97",
 "317400,4,6,1998/10/01 12:06:00,139.7024506862,35.5267758455,2,5,2022101,14,3 ,42,,1",
 "230000,2,5,1998/10/01 12:06:00,139.4463914341,35.3337211755,1,8,2410601,7 ,3 ,15,,1",
 "142600,10,10,1998/10/01 12:06:00,140.1202439276,35.6324056038,2,14,4013104,14,3 ,27,,6",
 "377200,4,4,1998/10/01 12:06:00,139.6370980058,35.9209698445,1,14,3052009,15,3 ,29,,3",
 "487600,6,6,1998/10/01 12:06:00,139.6242007342,35.9097702085,1,10,3512006,6 ,1 ,36,,6",
 "503700,4,4,1998/10/01 12:06:00,139.6778564343,36.0301919759,2,7,3613326,14,3 ,29,,6",
 "650900,4,4,1998/10/01 12:06:00,139.7347480287,35.7678394778,1,9,0531007,3 ,3 ,43,,6",
 "731400,2,2,1998/10/01 12:06:00,139.4781626448,35.6752933071,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:06:00,139.3377344199,35.6597920255,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:06:00,139.6175444151,35.4728953587,2,11,1330011,15,3 ,45,,6",
 "685400,4,5,1998/10/01 12:06:00,139.8466760894,35.6464241239,1,11,0634016,15,7 ,48,,12",
 "317400,4,6,1998/10/01 12:07:00,139.6998347334,35.5281839903,2,5,2022101,14,3 ,42,,1",
 "230000,2,5,1998/10/01 12:07:00,139.4464642071,35.3334105644,1,8,2410601,7 ,3 ,15,,1",
 "142600,10,10,1998/10/01 12:07:00,140.1225409392,35.6304108023,2,14,4013104,14,3 ,27,,6",
 "377200,4,4,1998/10/01 12:07:00,139.6372330461,35.9209859234,1,14,3052009,15,3 ,29,,3",
 "487600,6,6,1998/10/01 12:07:00,139.6241576148,35.9098821669,1,10,3512006,6 ,1 ,36,,6",
 "503700,4,4,1998/10/01 12:07:00,139.6783931515,36.0339076445,2,7,3613326,14,3 ,29,,6",
 "650900,4,4,1998/10/01 12:07:00,139.7358459164,35.7656465199,1,9,0531007,3 ,3 ,43,,6",
 "731400,2,2,1998/10/01 12:07:00,139.4780769071,35.6749742285,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:07:00,139.338063266,35.659777265,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:07:00,139.615590201,35.4739524987,2,11,1330011,15,3 ,45,,6",
 "685400,4,5,1998/10/01 12:07:00,139.8416102944,35.646736873,1,11,0634016,15,7 ,48,,12",
 "317400,4,6,1998/10/01 12:08:00,139.6970930165,35.527021786,2,5,2022101,14,3 ,42,,1",
 "230000,2,5,1998/10/01 12:08:00,139.4465369795,35.3330999532,1,8,2410601,7 ,3 ,15,,1",
 "142600,10,10,1998/10/01 12:08:00,140.124204504,35.6277191442,2,14,4013104,14,3 ,27,,6",
 "312800,8,8,1998/10/01 12:08:00,139.6835485619,35.5288029267,2,6,2015203,14,3 ,34,,2",
 "377200,4,4,1998/10/01 12:08:00,139.6372516186,35.9208773111,1,14,3052009,15,3 ,29,,3",
 "487600,6,6,1998/10/01 12:08:00,139.6241144952,35.9099941252,1,10,3512006,6 ,1 ,36,,6",
 "503700,4,4,1998/10/01 12:08:00,139.6805825806,36.0362732354,2,7,3613326,14,3 ,29,,6",
 "503700,5,5,1998/10/01 12:08:00,139.6805825806,36.0362732354,2,7,3613326,14,97,29,,97",
 "650900,4,4,1998/10/01 12:08:00,139.7390350411,35.7659813902,1,9,0531007,3 ,3 ,43,,6",
 "731400,2,2,1998/10/01 12:08:00,139.4779951644,35.6746544498,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:08:00,139.3383921423,35.6597629454,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:08:00,139.6130855236,35.4746056649,2,11,1330011,15,3 ,45,,6",
 "685400,4,5,1998/10/01 12:08:00,139.836543061,35.6468974782,1,11,0634016,15,7 ,48,,12",
 "317400,4,6,1998/10/01 12:09:00,139.6945979075,35.5270251642,2,5,2022101,14,3 ,42,,1",
 "230000,2,5,1998/10/01 12:09:00,139.4466097514,35.3327893419,1,8,2410601,7 ,3 ,15,,1",
 "142600,10,10,1998/10/01 12:09:00,140.1257318007,35.6258087078,2,14,4013104,14,3 ,27,,6",
 "312800,8,8,1998/10/01 12:09:00,139.6886221581,35.5265847886,2,6,2015203,14,3 ,34,,2",
 "377200,4,4,1998/10/01 12:09:00,139.6372687706,35.9207671781,1,14,3052009,15,3 ,29,,3",
 "487600,6,6,1998/10/01 12:09:00,139.6240713755,35.9101060835,1,10,3512006,6 ,1 ,36,,6",
 "650900,4,4,1998/10/01 12:09:00,139.742287397,35.7660872188,1,9,0531007,3 ,3 ,43,,6",
 "731400,2,2,1998/10/01 12:09:00,139.4779376424,35.6743309676,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:09:00,139.3387210355,35.6597488862,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:09:00,139.6108738713,35.4754205692,2,11,1330011,15,3 ,45,,6",
 "685400,4,5,1998/10/01 12:09:00,139.8314951199,35.6464351328,1,11,0634016,15,7 ,48,,12",
 "317400,4,6,1998/10/01 12:10:00,139.6922685919,35.5289414796,2,5,2022101,14,3 ,42,,1",
 "230000,2,5,1998/10/01 12:10:00,139.4466825227,35.3324787306,1,8,2410601,7 ,3 ,15,,1",
 "142600,10,10,1998/10/01 12:10:00,140.128556259,35.62411213,2,14,4013104,14,3 ,27,,6",
 "312800,8,8,1998/10/01 12:10:00,139.6919581091,35.5234276758,2,6,2015203,14,3 ,34,,2",
 "377200,4,4,1998/10/01 12:10:00,139.6372859225,35.920657045,1,14,3052009,15,3 ,29,,3",
 "487600,6,6,1998/10/01 12:10:00,139.6240282557,35.9102180418,1,10,3512006,6 ,1 ,36,,6",
 "650900,4,4,1998/10/01 12:10:00,139.7439854938,35.7648861317,1,9,0531007,3 ,3 ,43,,6",
 "650900,5,5,1998/10/01 12:10:00,139.7439854938,35.7648861317,1,9,0531007,3 ,97,43,,97",
 "731400,2,2,1998/10/01 12:10:00,139.4778847115,35.6740069848,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:10:00,139.3390499292,35.6597348355,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:10:00,139.610171885,35.4776150974,2,11,1330011,15,3 ,45,,6",
 "685400,4,5,1998/10/01 12:10:00,139.8264341972,35.6460927159,1,11,0634016,15,7 ,48,,12",
 "317400,4,6,1998/10/01 12:11:00,139.6906516633,35.5313002341,2,5,2022101,14,3 ,42,,1",
 "230000,2,5,1998/10/01 12:11:00,139.4467552934,35.3321681192,1,8,2410601,7 ,3 ,15,,1",
 "142600,10,10,1998/10/01 12:11:00,140.1308001961,35.6214129521,2,14,4013104,14,3 ,27,,6",
 "202400,4,4,1998/10/01 12:11:00,140.556722176,35.9323135804,2,10,5610009,4 ,3 ,34,,7",
 "312800,8,8,1998/10/01 12:11:00,139.6947041093,35.5194223605,2,6,2015203,14,3 ,34,,2",
 "377200,4,4,1998/10/01 12:11:00,139.6373030743,35.9205469119,1,14,3052009,15,3 ,29,,3",
 "384100,4,4,1998/10/01 12:11:00,139.6093651032,35.9608683079,2,11,3057004,14,3 ,31,,2",
 "487600,6,6,1998/10/01 12:11:00,139.6239851358,35.9103300001,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,6,1998/10/01 12:11:00,139.7645437034,35.6999019558,1,5,0420106,13,3 ,64,,1",
 "600300,4,7,1998/10/01 12:11:00,139.7645437034,35.6999019558,1,5,0420106,13,3 ,64,,12",
 "667000,4,4,1998/10/01 12:11:00,139.781208459,35.7814885311,2,12,0614310,6 ,4 ,43,,2",
 "731400,2,2,1998/10/01 12:11:00,139.4778571561,35.6736810469,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:11:00,139.3393788228,35.6597207838,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:11:00,139.6075778442,35.4780062499,2,11,1330011,15,3 ,45,,6",
 "862500,4,4,1998/10/01 12:11:00,139.5827918709,35.5404703743,2,6,1321110,15,3 ,29,,2",
 "685400,4,5,1998/10/01 12:11:00,139.8216107975,35.6466951882,1,11,0634016,15,7 ,48,,12",
 "317400,4,6,1998/10/01 12:12:00,139.6892223539,35.5337418865,2,5,2022101,14,3 ,42,,1",
 "230000,2,5,1998/10/01 12:12:00,139.4468280635,35.3318575078,1,8,2410601,7 ,3 ,15,,1",
 "142600,10,10,1998/10/01 12:12:00,140.1318963025,35.6182871812,2,14,4013104,14,3 ,27,,6",
 "202400,4,4,1998/10/01 12:12:00,140.5525169469,35.9338296338,2,10,5610009,4 ,3 ,34,,7",
 "312800,8,8,1998/10/01 12:12:00,139.6963416204,35.514812714,2,6,2015203,14,3 ,34,,2",
 "377200,4,4,1998/10/01 12:12:00,139.6373202261,35.9204367789,1,14,3052009,15,3 ,29,,3",
 "384100,4,4,1998/10/01 12:12:00,139.6077742723,35.9604703511,2,11,3057004,14,3 ,31,,2",
 "487600,6,6,1998/10/01 12:12:00,139.6239420157,35.9104419583,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:12:00,139.7595939481,35.701432139,1,5,0420106,13,3 ,64,,12",
 "667000,4,4,1998/10/01 12:12:00,139.7816543437,35.782059051,2,12,0614310,6 ,4 ,43,,2",
 "731400,2,2,1998/10/01 12:12:00,139.4778368753,35.6733545966,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:12:00,139.3397077163,35.6597067312,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:12:00,139.6047900083,35.4781665438,2,11,1330011,15,3 ,45,,6",
 "862500,4,4,1998/10/01 12:12:00,139.5830257699,35.5411682986,2,6,1321110,15,3 ,29,,2",
 "685400,4,5,1998/10/01 12:12:00,139.8198405375,35.6504252976,1,11,0634016,15,7 ,48,,12",
 "317400,4,6,1998/10/01 12:13:00,139.6872674875,35.5358765016,2,5,2022101,14,3 ,42,,1",
 "230000,2,5,1998/10/01 12:13:00,139.4469008331,35.3315468963,1,8,2410601,7 ,3 ,15,,1",
 "142600,10,10,1998/10/01 12:13:00,140.1332366076,35.6152314056,2,14,4013104,14,3 ,27,,6",
 "202400,4,4,1998/10/01 12:13:00,140.5483087874,35.9353403581,2,10,5610009,4 ,3 ,34,,7",
 "312800,8,8,1998/10/01 12:13:00,139.6981129581,35.5102375577,2,6,2015203,14,3 ,34,,2",
 "377200,4,4,1998/10/01 12:13:00,139.6374414182,35.9204365959,1,14,3052009,15,3 ,29,,3",
 "384100,4,4,1998/10/01 12:13:00,139.6062221173,35.9599836912,2,11,3057004,14,3 ,31,,2",
 "487600,6,6,1998/10/01 12:13:00,139.6238988956,35.9105539166,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:13:00,139.7540794895,35.7021882283,1,5,0420106,13,3 ,64,,12",
 "667000,4,4,1998/10/01 12:13:00,139.7822805861,35.7825976593,2,12,0614310,6 ,4 ,43,,2",
 "731400,2,2,1998/10/01 12:13:00,139.4777726602,35.6730321973,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:13:00,139.3400366096,35.6596926778,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:13:00,139.6025804574,35.479574992,2,11,1330011,15,3 ,45,,6",
 "862500,4,4,1998/10/01 12:13:00,139.5823778745,35.5413749866,2,6,1321110,15,3 ,29,,2",
 "685400,4,5,1998/10/01 12:13:00,139.8193444288,35.6545522159,1,11,0634016,15,7 ,48,,12",
 "317400,4,6,1998/10/01 12:14:00,139.6843997709,35.5370860444,2,5,2022101,14,3 ,42,,1",
 "230000,2,5,1998/10/01 12:14:00,139.4469736021,35.3312362847,1,8,2410601,7 ,3 ,15,,1",
 "142600,10,10,1998/10/01 12:14:00,140.1352741094,35.6124260553,2,14,4013104,14,3 ,27,,6",
 "202400,4,4,1998/10/01 12:14:00,140.5442041465,35.9369272217,2,10,5610009,4 ,3 ,34,,7",
 "312800,8,8,1998/10/01 12:14:00,139.6999748263,35.5073410372,2,6,2015203,14,3 ,34,,2",
 "312800,9,9,1998/10/01 12:14:00,139.6999748263,35.5073410372,2,6,2015203,14,97,34,,97",
 "377200,4,4,1998/10/01 12:14:00,139.6375766947,35.9204512971,1,14,3052009,15,3 ,29,,3",
 "384100,4,4,1998/10/01 12:14:00,139.6046798395,35.9594678221,2,11,3057004,14,3 ,31,,2",
 "487600,6,6,1998/10/01 12:14:00,139.6238557753,35.9106658748,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:14:00,139.7484239332,35.7024194618,1,5,0420106,13,3 ,64,,12",
 "667000,4,4,1998/10/01 12:14:00,139.7836362339,35.7825902929,2,12,0614310,6 ,4 ,43,,2",
 "731400,2,2,1998/10/01 12:14:00,139.4777031492,35.6727102864,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:14:00,139.3403655029,35.6596786234,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:14:00,139.600958786,35.4808385443,2,11,1330011,15,3 ,45,,6",
 "862500,4,4,1998/10/01 12:14:00,139.5814709511,35.5411991619,2,6,1321110,15,3 ,29,,2",
 "685400,4,5,1998/10/01 12:14:00,139.8176486124,35.6583635085,1,11,0634016,15,7 ,48,,12",
 "317400,4,6,1998/10/01 12:15:00,139.6813884197,35.5382105786,2,5,2022101,14,3 ,42,,1",
 "230000,2,5,1998/10/01 12:15:00,139.4470463706,35.330925673,1,8,2410601,7 ,3 ,15,,1",
 "142600,10,10,1998/10/01 12:15:00,140.1365389132,35.6093733114,2,14,4013104,14,3 ,27,,6",
 "202400,4,4,1998/10/01 12:15:00,140.5418666372,35.9394268827,2,10,5610009,4 ,3 ,34,,7",
 "377200,4,4,1998/10/01 12:15:00,139.6377119712,35.9204659982,1,14,3052009,15,3 ,29,,3",
 "384100,4,4,1998/10/01 12:15:00,139.6031421421,35.9589430808,2,11,3057004,14,3 ,31,,2",
 "487600,6,6,1998/10/01 12:15:00,139.6238126548,35.910777833,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:15:00,139.7438288859,35.7003912991,1,5,0420106,13,3 ,64,,12",
 "667000,4,4,1998/10/01 12:15:00,139.7849904085,35.7825429828,2,12,0614310,6 ,4 ,43,,2",
 "731400,2,2,1998/10/01 12:15:00,139.4776336387,35.6723883754,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:15:00,139.3406943961,35.6596645681,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 12:15:00,139.8140619961,35.6612209126,1,11,0634016,15,7 ,48,,12",
 "862500,4,4,1998/10/01 12:15:00,139.5805640317,35.5410233303,2,6,1321110,15,3 ,29,,2",
 "851000,4,4,1998/10/01 12:15:00,139.5988847464,35.4823905524,2,11,1330011,15,3 ,45,,6",
 "317400,4,6,1998/10/01 12:16:00,139.678357846,35.5393015262,2,5,2022101,14,3 ,42,,1",
 "230000,2,5,1998/10/01 12:16:00,139.4471191384,35.3306150613,1,8,2410601,7 ,3 ,15,,1",
 "142600,10,10,1998/10/01 12:16:00,140.1371044713,35.6061439042,2,14,4013104,14,3 ,27,,6",
 "202400,4,4,1998/10/01 12:16:00,140.5396701271,35.9423023315,2,10,5610009,4 ,3 ,34,,7",
 "377200,4,4,1998/10/01 12:16:00,139.6378472478,35.9204806992,1,14,3052009,15,3 ,29,,3",
 "384100,4,4,1998/10/01 12:16:00,139.6016179019,35.9583931483,2,11,3057004,14,3 ,31,,2",
 "487600,6,6,1998/10/01 12:16:00,139.6237695343,35.9108897912,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:16:00,139.7407425297,35.6965193646,1,5,0420106,13,3 ,64,,12",
 "667000,4,4,1998/10/01 12:16:00,139.7863450693,35.7825069343,2,12,0614310,6 ,4 ,43,,2",
 "731400,2,2,1998/10/01 12:16:00,139.4773470564,35.6722881223,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:16:00,139.3410232891,35.659650512,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:16:00,139.5964518603,35.4835940369,2,11,1330011,15,3 ,45,,6",
 "862500,4,4,1998/10/01 12:16:00,139.5796533486,35.5408651251,2,6,1321110,15,3 ,29,,2",
 "685400,4,5,1998/10/01 12:16:00,139.8090197638,35.6611928286,1,11,0634016,15,7 ,48,,12",
 "317400,4,6,1998/10/01 12:17:00,139.6757614953,35.5405531377,2,5,2022101,14,3 ,42,,1",
 "230000,2,5,1998/10/01 12:17:00,139.4471919057,35.3303044496,1,8,2410601,7 ,3 ,15,,1",
 "142600,10,10,1998/10/01 12:17:00,140.1362882821,35.6031393078,2,14,4013104,14,3 ,27,,6",
 "202400,4,4,1998/10/01 12:17:00,140.5363129338,35.9448212706,2,10,5610009,4 ,3 ,34,,7",
 "377200,4,4,1998/10/01 12:17:00,139.6379825245,35.9204954,1,14,3052009,15,3 ,29,,3",
 "384100,4,4,1998/10/01 12:17:00,139.6000932699,35.957846139,2,11,3057004,14,3 ,31,,2",
 "487600,6,6,1998/10/01 12:17:00,139.6237264136,35.9110017494,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:17:00,139.7376396194,35.6926690471,1,5,0420106,13,3 ,64,,12",
 "667000,4,4,1998/10/01 12:17:00,139.7876999456,35.7824696048,2,12,0614310,6 ,4 ,43,,2",
 "731400,2,2,1998/10/01 12:17:00,139.476946932,35.6723038087,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:17:00,139.3413521821,35.6596364549,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:17:00,139.5947797709,35.4852943526,2,11,1330011,15,3 ,45,,6",
 "862500,4,4,1998/10/01 12:17:00,139.5787241549,35.5408709307,2,6,1321110,15,3 ,29,,2",
 "685400,4,5,1998/10/01 12:17:00,139.8039438575,35.6612133635,1,11,0634016,15,7 ,48,,12",
 "317400,4,6,1998/10/01 12:18:00,139.6743963992,35.5424833505,2,5,2022101,14,3 ,42,,1",
 "230000,2,5,1998/10/01 12:18:00,139.4472646725,35.3299938377,1,8,2410601,7 ,3 ,15,,1",
 "142600,10,10,1998/10/01 12:18:00,140.1361355891,35.5999990891,2,14,4013104,14,3 ,27,,6",
 "202400,4,4,1998/10/01 12:18:00,140.5341737423,35.9481005502,2,10,5610009,4 ,3 ,34,,7",
 "377200,4,4,1998/10/01 12:18:00,139.6381178011,35.9205101007,1,14,3052009,15,3 ,29,,3",
 "384100,4,4,1998/10/01 12:18:00,139.5985110719,35.9574187014,2,11,3057004,14,3 ,31,,2",
 "487600,6,6,1998/10/01 12:18:00,139.6236832928,35.9111137075,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:18:00,139.7331258364,35.6898908009,1,5,0420106,13,3 ,64,,12",
 "667000,4,4,1998/10/01 12:18:00,139.7890550746,35.7824527842,2,12,0614310,6 ,4 ,43,,2",
 "731400,2,2,1998/10/01 12:18:00,139.4765468325,35.6723193566,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:18:00,139.3416810749,35.659622397,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:18:00,139.5935937781,35.4873614442,2,11,1330011,15,3 ,45,,6",
 "862500,4,4,1998/10/01 12:18:00,139.5777957112,35.5409381859,2,6,1321110,15,3 ,29,,2",
 "685400,4,5,1998/10/01 12:18:00,139.7992454049,35.6627332883,1,11,0634016,15,7 ,48,,12",
 "317400,4,6,1998/10/01 12:19:00,139.6725214186,35.5412312751,2,5,2022101,14,3 ,42,,1",
 "230000,2,5,1998/10/01 12:19:00,139.4473374387,35.3296832258,1,8,2410601,7 ,3 ,15,,1",
 "142600,10,10,1998/10/01 12:19:00,140.1361516835,35.5967379878,2,14,4013104,14,3 ,27,,6",
 "202400,4,4,1998/10/01 12:19:00,140.5308586607,35.9505074594,2,10,5610009,4 ,3 ,34,,7",
 "377200,4,4,1998/10/01 12:19:00,139.6382530779,35.9205248012,1,14,3052009,15,3 ,29,,3",
 "384100,4,4,1998/10/01 12:19:00,139.5969222988,35.9570069479,2,11,3057004,14,3 ,31,,2",
 "487600,6,6,1998/10/01 12:19:00,139.6236797071,35.9112294493,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:19:00,139.7306317984,35.6859171861,1,5,0420106,13,3 ,64,,12",
 "667000,4,4,1998/10/01 12:19:00,139.7904099556,35.7824172934,2,12,0614310,6 ,4 ,43,,2",
 "731400,2,2,1998/10/01 12:19:00,139.47616963,35.6722093137,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:19:00,139.3420099677,35.6596083381,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:19:00,139.5913362301,35.48858846,2,11,1330011,15,3 ,45,,6",
 "862500,4,4,1998/10/01 12:19:00,139.576871328,35.5410348984,2,6,1321110,15,3 ,29,,2",
 "685400,4,5,1998/10/01 12:19:00,139.7955190729,35.6655054725,1,11,0634016,15,7 ,48,,12",
 "317400,4,6,1998/10/01 12:20:00,139.6697066819,35.5414292459,2,5,2022101,14,3 ,42,,1",
 "317400,5,7,1998/10/01 12:20:00,139.6697066819,35.5414292459,2,5,2022101,14,97,42,,97",
 "230000,2,5,1998/10/01 12:20:00,139.4474102043,35.3293726139,1,8,2410601,7 ,3 ,15,,1",
 "142600,10,10,1998/10/01 12:20:00,140.1375111478,35.5945808697,2,14,4013104,14,3 ,27,,6",
 "202400,4,4,1998/10/01 12:20:00,140.5272543035,35.952830936,2,10,5610009,4 ,3 ,34,,7",
 "377200,4,4,1998/10/01 12:20:00,139.6383883547,35.9205395015,1,14,3052009,15,3 ,29,,3",
 "384100,4,4,1998/10/01 12:20:00,139.5957893987,35.9573655267,2,11,3057004,14,3 ,31,,2",
 "384100,5,5,1998/10/01 12:20:00,139.5957893987,35.9573655267,2,11,3057004,14,97,31,,97",
 "487600,6,6,1998/10/01 12:20:00,139.6236897116,35.9113464918,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:20:00,139.7276820797,35.6820713771,1,5,0420106,13,3 ,64,,12",
 "667000,4,4,1998/10/01 12:20:00,139.7915425687,35.7825790227,2,12,0614310,6 ,4 ,43,,2",
 "731400,2,2,1998/10/01 12:20:00,139.4757924758,35.6720991662,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:20:00,139.3423388603,35.6595942783,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 12:20:00,139.7919780173,35.6684795045,1,11,0634016,15,7 ,48,,12",
 "862500,4,4,1998/10/01 12:20:00,139.5759472939,35.5411348111,2,6,1321110,15,3 ,29,,2",
 "851000,4,4,1998/10/01 12:20:00,139.5896551701,35.4903895766,2,11,1330011,15,3 ,45,,6",
 "749800,2,2,1998/10/01 12:21:00,139.4809920145,35.5211957482,1,7,0810411,10,1 ,36,,6",
 "230000,2,5,1998/10/01 12:21:00,139.4474829693,35.3290620019,1,8,2410601,7 ,3 ,15,,1",
 "234600,4,6,1998/10/01 12:21:00,139.7867246111,35.6741996833,1,14,2412116,10,7 ,63,,1",
 "142600,10,10,1998/10/01 12:21:00,140.1413474405,35.5936795366,2,14,4013104,14,3 ,27,,6",
 "202400,4,4,1998/10/01 12:21:00,140.5236372863,35.9551076986,2,10,5610009,4 ,3 ,34,,7",
 "370300,4,6,1998/10/01 12:21:00,139.7466345649,35.6878723574,1,10,3037010,10,13,39,,1",
 "377200,4,4,1998/10/01 12:21:00,139.6385236315,35.9205542017,1,14,3052009,15,3 ,29,,3",
 "487600,6,6,1998/10/01 12:21:00,139.6236997221,35.9114635339,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:21:00,139.722663954,35.6799563045,1,5,0420106,13,3 ,64,,12",
 "667000,4,4,1998/10/01 12:21:00,139.7917274399,35.7836519689,2,12,0614310,6 ,4 ,43,,2",
 "731400,2,2,1998/10/01 12:21:00,139.4754190322,35.6719808971,2,10,0771111,14,4 ,32,,6",
 "802700,2,2,1998/10/01 12:21:00,139.5899222219,35.4946195794,2,6,1425101,14,7 ,32,,1",
 "786600,3,5,1998/10/01 12:21:00,139.3426677528,35.6595802177,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:21:00,139.5880160386,35.4922611226,2,11,1330011,15,3 ,45,,6",
 "862500,4,4,1998/10/01 12:21:00,139.5750225657,35.5412297882,2,6,1321110,15,3 ,29,,2",
 "685400,4,5,1998/10/01 12:21:00,139.7882442791,35.6712759983,1,11,0634016,15,7 ,48,,12",
 "749800,2,2,1998/10/01 12:22:00,139.480992152,35.5211957384,1,7,0810411,10,1 ,36,,6",
 "230000,2,5,1998/10/01 12:22:00,139.447538214,35.3287495188,1,8,2410601,7 ,3 ,15,,1",
 "234600,4,6,1998/10/01 12:22:00,139.7864082235,35.6746354005,1,14,2412116,10,7 ,63,,1",
 "142600,10,10,1998/10/01 12:22:00,140.1438144041,35.5924033146,2,14,4013104,14,3 ,27,,6",
 "202400,4,4,1998/10/01 12:22:00,140.5194299656,35.9565769291,2,10,5610009,4 ,3 ,34,,7",
 "370300,4,6,1998/10/01 12:22:00,139.746417342,35.6882603478,1,10,3037010,10,13,39,,1",
 "377200,4,4,1998/10/01 12:22:00,139.6386589084,35.9205689018,1,14,3052009,15,3 ,29,,3",
 "487600,6,6,1998/10/01 12:22:00,139.6237097504,35.911580575,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:22:00,139.7170629284,35.6804927068,1,5,0420106,13,3 ,64,,12",
 "667000,4,4,1998/10/01 12:22:00,139.7921258876,35.7846705913,2,12,0614310,6 ,4 ,43,,2",
 "731400,2,2,1998/10/01 12:22:00,139.4750410358,35.6718739789,2,10,0771111,14,4 ,32,,6",
 "802700,2,2,1998/10/01 12:22:00,139.5899226955,35.494615523,2,6,1425101,14,7 ,32,,1",
 "802700,2,3,1998/10/01 12:22:00,139.5899226955,35.494615523,2,6,1425101,14,7 ,32,,10",
 "786600,3,5,1998/10/01 12:22:00,139.3429966453,35.6595661561,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:22:00,139.5874264818,35.4945022238,2,11,1330011,15,3 ,45,,6",
 "862500,4,4,1998/10/01 12:22:00,139.574098282,35.5413281457,2,6,1321110,15,3 ,29,,2",
 "685400,4,5,1998/10/01 12:22:00,139.7839361891,35.6734755233,1,11,0634016,15,7 ,48,,12",
 "749800,2,2,1998/10/01 12:23:00,139.4809922896,35.5211957287,1,7,0810411,10,1 ,36,,6",
 "230000,2,5,1998/10/01 12:23:00,139.4475650155,35.3284339981,1,8,2410601,7 ,3 ,15,,1",
 "234600,4,6,1998/10/01 12:23:00,139.7860918325,35.6750711169,1,14,2412116,10,7 ,63,,1",
 "142600,10,10,1998/10/01 12:23:00,140.147771816,35.5921618401,2,14,4013104,14,3 ,27,,6",
 "202400,4,4,1998/10/01 12:23:00,140.5153761929,35.9582186503,2,10,5610009,4 ,3 ,34,,7",
 "370300,4,6,1998/10/01 12:23:00,139.7456896986,35.688708746,1,10,3037010,10,13,39,,1",
 "377200,4,4,1998/10/01 12:23:00,139.6387941854,35.9205836017,1,14,3052009,15,3 ,29,,3",
 "487600,6,6,1998/10/01 12:23:00,139.6237197787,35.9116976161,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:23:00,139.7114798158,35.6812544155,1,5,0420106,13,3 ,64,,12",
 "667000,4,4,1998/10/01 12:23:00,139.7930368895,35.7854041304,2,12,0614310,6 ,4 ,43,,2",
 "731400,2,2,1998/10/01 12:23:00,139.4746472626,35.671816468,2,10,0771111,14,4 ,32,,6",
 "802700,2,3,1998/10/01 12:23:00,139.5899227454,35.4946150956,2,6,1425101,14,7 ,32,,10",
 "786600,3,5,1998/10/01 12:23:00,139.3433255376,35.6595520937,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:23:00,139.5852219759,35.4956785999,2,11,1330011,15,3 ,45,,6",
 "862500,4,4,1998/10/01 12:23:00,139.5731731752,35.5414212123,2,6,1321110,15,3 ,29,,2",
 "685400,4,5,1998/10/01 12:23:00,139.7791707334,35.6746300835,1,11,0634016,15,7 ,48,,12",
 "749800,2,2,1998/10/01 12:24:00,139.4809924272,35.5211957189,1,7,0810411,10,1 ,36,,6",
 "230000,2,5,1998/10/01 12:24:00,139.4479437452,35.3284444649,1,8,2410601,7 ,3 ,15,,1",
 "234600,4,6,1998/10/01 12:24:00,139.7857754381,35.6755068324,1,14,2412116,10,7 ,63,,1",
 "142600,10,10,1998/10/01 12:24:00,140.1512614366,35.5906481591,2,14,4013104,14,3 ,27,,6",
 "202400,4,4,1998/10/01 12:24:00,140.5119141104,35.9606495864,2,10,5610009,4 ,3 ,34,,7",
 "370300,4,6,1998/10/01 12:24:00,139.7453485067,35.6883755853,1,10,3037010,10,13,39,,1",
 "377200,4,4,1998/10/01 12:24:00,139.6389294624,35.9205983014,1,14,3052009,15,3 ,29,,3",
 "487600,6,6,1998/10/01 12:24:00,139.623729807,35.9118146572,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:24:00,139.7058620224,35.6813957201,1,5,0420106,13,3 ,64,,12",
 "667000,4,4,1998/10/01 12:24:00,139.7943282423,35.7854890907,2,12,0614310,6 ,4 ,43,,2",
 "731400,2,2,1998/10/01 12:24:00,139.4742508709,35.6717693058,2,10,0771111,14,4 ,32,,6",
 "802700,2,3,1998/10/01 12:24:00,139.5899227953,35.4946146683,2,6,1425101,14,7 ,32,,10",
 "786600,3,5,1998/10/01 12:24:00,139.3436544298,35.6595380303,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:24:00,139.5832988921,35.4970747009,2,11,1330011,15,3 ,45,,6",
 "862500,4,4,1998/10/01 12:24:00,139.5722754846,35.5416050496,2,6,1321110,15,3 ,29,,2",
 "685400,4,5,1998/10/01 12:24:00,139.7740924341,35.6747270771,1,11,0634016,15,7 ,48,,12",
 "749800,2,2,1998/10/01 12:25:00,139.4809925647,35.5211957091,1,7,0810411,10,1 ,36,,6",
 "230000,2,5,1998/10/01 12:25:00,139.4483289818,35.3284635873,1,8,2410601,7 ,3 ,15,,1",
 "234600,4,6,1998/10/01 12:25:00,139.7852674877,35.6757914722,1,14,2412116,10,7 ,63,,1",
 "142600,10,10,1998/10/01 12:25:00,140.1492382343,35.5889689912,2,14,4013104,14,3 ,27,,6",
 "142600,11,11,1998/10/01 12:25:00,140.1492382343,35.5889689912,2,14,4013104,14,97,27,,97",
 "184000,6,6,1998/10/01 12:25:00,140.112961757,36.083464373,2,4,5214028,13,3 ,49,,2",
 "202400,4,4,1998/10/01 12:25:00,140.5091104434,35.9636135104,2,10,5610009,4 ,3 ,34,,7",
 "370300,4,6,1998/10/01 12:25:00,139.7453479755,35.6886698753,1,10,3037010,10,13,39,,1",
 "377200,4,4,1998/10/01 12:25:00,139.6390647394,35.920613001,1,14,3052009,15,3 ,29,,3",
 "487600,6,6,1998/10/01 12:25:00,139.6237398354,35.9119316983,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:25:00,139.702077913,35.6842449067,1,5,0420106,13,3 ,64,,12",
 "667000,4,4,1998/10/01 12:25:00,139.7950157245,35.7861829234,2,12,0614310,6 ,4 ,43,,2",
 "802700,2,3,1998/10/01 12:25:00,139.5899228452,35.494614241,2,6,1425101,14,7 ,32,,10",
 "731400,2,2,1998/10/01 12:25:00,139.4740404501,35.6715780517,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:25:00,139.3439833219,35.6595239661,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:25:00,139.5826610381,35.4993386642,2,11,1330011,15,3 ,45,,6",
 "862500,4,4,1998/10/01 12:25:00,139.5714361668,35.5419277445,2,6,1321110,15,3 ,29,,2",
 "685400,4,5,1998/10/01 12:25:00,139.7694480734,35.6763769162,1,11,0634016,15,7 ,48,,12",
 "749800,2,2,1998/10/01 12:26:00,139.4809927023,35.5211956993,1,7,0810411,10,1 ,36,,6",
 "230000,2,5,1998/10/01 12:26:00,139.4487142145,35.3284827689,1,8,2410601,7 ,3 ,15,,1",
 "234600,4,6,1998/10/01 12:26:00,139.7847445384,35.6760642831,1,14,2412116,10,7 ,63,,1",
 "184000,6,6,1998/10/01 12:26:00,140.1108000742,36.0824717517,2,4,5214028,13,3 ,49,,2",
 "202400,4,4,1998/10/01 12:26:00,140.5064141119,35.9666359382,2,10,5610009,4 ,3 ,34,,7",
 "370300,4,6,1998/10/01 12:26:00,139.7454786714,35.6894036531,1,10,3037010,10,13,39,,1",
 "377200,4,4,1998/10/01 12:26:00,139.6392000165,35.9206277005,1,14,3052009,15,3 ,29,,3",
 "487600,6,6,1998/10/01 12:26:00,139.623747181,35.9120488154,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:26:00,139.7003930057,35.6886497683,1,5,0420106,13,3 ,64,,12",
 "667000,4,4,1998/10/01 12:26:00,139.7963689702,35.7862441661,2,12,0614310,6 ,4 ,43,,2",
 "731400,2,2,1998/10/01 12:26:00,139.474003237,35.6712525957,2,10,0771111,14,4 ,32,,6",
 "802700,2,3,1998/10/01 12:26:00,139.5899228951,35.4946138136,2,6,1425101,14,7 ,32,,10",
 "786600,3,5,1998/10/01 12:26:00,139.344312531,35.6595173348,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:26:00,139.5805968227,35.5006637017,2,11,1330011,15,3 ,45,,6",
 "862500,4,4,1998/10/01 12:26:00,139.5707351825,35.5418652862,2,6,1321110,15,3 ,29,,2",
 "685400,4,5,1998/10/01 12:26:00,139.7666465631,35.6788493935,1,11,0634016,15,7 ,48,,12",
 "749800,2,2,1998/10/01 12:27:00,139.4809928399,35.5211956895,1,7,0810411,10,1 ,36,,6",
 "230000,2,5,1998/10/01 12:27:00,139.4490008827,35.328300461,1,8,2410601,7 ,3 ,15,,1",
 "234600,4,6,1998/10/01 12:27:00,139.784226137,35.6763427841,1,14,2412116,10,7 ,63,,1",
 "184000,6,6,1998/10/01 12:27:00,140.1086511431,36.0814611178,2,4,5214028,13,3 ,49,,2",
 "202400,4,4,1998/10/01 12:27:00,140.5037945143,35.9697058823,2,10,5610009,4 ,3 ,34,,7",
 "370300,4,6,1998/10/01 12:27:00,139.7452478786,35.6898410391,1,10,3037010,10,13,39,,1",
 "377200,4,4,1998/10/01 12:27:00,139.6393345302,35.9206428077,1,14,3052009,15,3 ,29,,3",
 "487600,6,6,1998/10/01 12:27:00,139.6237471889,35.9121661405,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:27:00,139.7000875329,35.6931568472,1,5,0420106,13,3 ,64,,12",
 "667000,4,4,1998/10/01 12:27:00,139.7977199132,35.7861708354,2,12,0614310,6 ,4 ,43,,2",
 "731400,2,2,1998/10/01 12:27:00,139.4737855536,35.6711110048,2,10,0771111,14,4 ,32,,6",
 "802700,2,3,1998/10/01 12:27:00,139.589922945,35.4946133863,2,6,1425101,14,7 ,32,,10",
 "786600,3,5,1998/10/01 12:27:00,139.3446418253,35.6595127011,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:27:00,139.5788817643,35.5019954626,2,11,1330011,15,3 ,45,,6",
 "862500,4,4,1998/10/01 12:27:00,139.5701112915,35.5412993357,2,6,1321110,15,3 ,29,,2",
 "685400,4,5,1998/10/01 12:27:00,139.7673929167,35.6829276397,1,11,0634016,15,7 ,48,,12",
 "749800,2,2,1998/10/01 12:28:00,139.4809929775,35.5211956797,1,7,0810411,10,1 ,36,,6",
 "230000,2,5,1998/10/01 12:28:00,139.4492632683,35.3280685159,1,8,2410601,7 ,3 ,15,,1",
 "234600,4,6,1998/10/01 12:28:00,139.78370943,35.6766234066,1,14,2412116,10,7 ,63,,1",
 "184000,6,6,1998/10/01 12:28:00,140.1068958,36.0820798879,2,4,5214028,13,3 ,49,,2",
 "202400,4,4,1998/10/01 12:28:00,140.5019467218,35.9731248039,2,10,5610009,4 ,3 ,34,,7",
 "370300,4,6,1998/10/01 12:28:00,139.744336016,35.6898329795,1,10,3037010,10,13,39,,1",
 "377200,4,4,1998/10/01 12:28:00,139.6392973982,35.9207496332,1,14,3052009,15,3 ,29,,3",
 "487600,6,6,1998/10/01 12:28:00,139.6237471967,35.9122834655,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:28:00,139.699021753,35.6976690408,1,5,0420106,13,3 ,64,,12",
 "667000,4,4,1998/10/01 12:28:00,139.797948103,35.7871135424,2,12,0614310,6 ,4 ,43,,2",
 "731400,2,2,1998/10/01 12:28:00,139.4737649939,35.6707852775,2,10,0771111,14,4 ,32,,6",
 "802700,2,3,1998/10/01 12:28:00,139.5899229949,35.494612959,2,6,1425101,14,7 ,32,,10",
 "786600,3,5,1998/10/01 12:28:00,139.3449711196,35.6595080666,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:28:00,139.5761361191,35.5025594996,2,11,1330011,15,3 ,45,,6",
 "862500,4,4,1998/10/01 12:28:00,139.5694216384,35.5408297291,2,6,1321110,15,3 ,29,,2",
 "685400,4,5,1998/10/01 12:28:00,139.7688655959,35.6868964941,1,11,0634016,15,7 ,48,,12",
 "749800,2,2,1998/10/01 12:29:00,139.480993115,35.5211956699,1,7,0810411,10,1 ,36,,6",
 "230000,2,5,1998/10/01 12:29:00,139.4495256524,35.3278365702,1,8,2410601,7 ,3 ,15,,1",
 "234600,4,6,1998/10/01 12:29:00,139.7838620054,35.6769882343,1,14,2412116,10,7 ,63,,1",
 "184000,6,6,1998/10/01 12:29:00,140.1057002683,36.0838469946,2,4,5214028,13,3 ,49,,2",
 "202400,4,4,1998/10/01 12:29:00,140.5006375561,35.9766890877,2,10,5610009,4 ,3 ,34,,7",
 "370300,4,6,1998/10/01 12:29:00,139.7434241536,35.689824913,1,10,3037010,10,13,39,,1",
 "377200,4,4,1998/10/01 12:29:00,139.639260266,35.9208564586,1,14,3052009,15,3 ,29,,3",
 "487600,6,6,1998/10/01 12:29:00,139.6237472046,35.9124007906,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:29:00,139.6966607477,35.7018459675,1,5,0420106,13,3 ,64,,12",
 "667000,4,4,1998/10/01 12:29:00,139.7993036029,35.7871375551,2,12,0614310,6 ,4 ,43,,2",
 "731400,2,2,1998/10/01 12:29:00,139.4737451966,35.6704588074,2,10,0771111,14,4 ,32,,6",
 "802700,2,3,1998/10/01 12:29:00,139.5899230448,35.4946125316,2,6,1425101,14,7 ,32,,10",
 "786600,3,5,1998/10/01 12:29:00,139.3453004139,35.6595034311,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:29:00,139.5734590713,35.5030846087,2,11,1330011,15,3 ,45,,6",
 "862500,4,4,1998/10/01 12:29:00,139.5685601227,35.540554022,2,6,1321110,15,3 ,29,,2",
 "685400,4,5,1998/10/01 12:29:00,139.770492924,35.6908245768,1,11,0634016,15,7 ,48,,12",
 "802700,2,3,1998/10/01 12:30:00,139.5899230947,35.4946121043,2,6,1425101,14,7 ,32,,10",
 "749800,2,2,1998/10/01 12:30:00,139.4809932526,35.5211956601,1,7,0810411,10,1 ,36,,6",
 "230000,2,5,1998/10/01 12:30:00,139.449788035,35.3276046239,1,8,2410601,7 ,3 ,15,,1",
 "230000,3,6,1998/10/01 12:30:00,139.449788035,35.3276046239,1,8,2410601,7 ,97,15,,97",
 "234600,4,6,1998/10/01 12:30:00,139.7833440769,35.6772673587,1,14,2412116,10,7 ,63,,1",
 "234600,5,7,1998/10/01 12:30:00,139.7833440769,35.6772673587,1,14,2412116,10,97,63,,97",
 "184000,6,6,1998/10/01 12:30:00,140.1044422582,36.0855852141,2,4,5214028,13,3 ,49,,2",
 "202400,4,4,1998/10/01 12:30:00,140.4981760653,35.9798191253,2,10,5610009,4 ,3 ,34,,7",
 "370300,4,6,1998/10/01 12:30:00,139.7425122915,35.6898168396,1,10,3037010,10,13,39,,1",
 "377200,4,4,1998/10/01 12:30:00,139.6392231337,35.9209632841,1,14,3052009,15,3 ,29,,3",
 "377200,5,5,1998/10/01 12:30:00,139.6392231337,35.9209632841,1,14,3052009,15,97,29,,97",
 "487600,6,6,1998/10/01 12:30:00,139.6237472125,35.9125181156,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:30:00,139.6926199347,35.7050200571,1,5,0420106,13,3 ,64,,12",
 "667000,4,4,1998/10/01 12:30:00,139.8004417177,35.7868441368,2,12,0614310,6 ,4 ,43,,2",
 "667000,5,5,1998/10/01 12:30:00,139.8004417177,35.7868441368,2,12,0614310,6 ,97,43,,97",
 "731400,2,2,1998/10/01 12:30:00,139.4737253996,35.6701323372,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:30:00,139.3456297081,35.6594987948,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 12:30:00,139.771706528,35.6948152765,1,11,0634016,15,7 ,48,,12",
 "851000,4,4,1998/10/01 12:30:00,139.57070681,35.5036449315,2,11,1330011,15,3 ,45,,6",
 "862500,4,4,1998/10/01 12:30:00,139.5679652294,35.5400299058,2,6,1321110,15,3 ,29,,2",
 "862500,5,5,1998/10/01 12:30:00,139.5679652294,35.5400299058,2,6,1321110,15,97,29,,97",
 "607200,2,2,1998/10/01 12:31:00,139.6088781324,35.7171299945,2,7,0424006,14,4 ,59,,1",
 "607200,2,3,1998/10/01 12:31:00,139.6088781324,35.7171299945,2,7,0424006,14,4 ,59,,10",
 "749800,2,2,1998/10/01 12:31:00,139.4809933902,35.5211956503,1,7,0810411,10,1 ,36,,6",
 "11500,4,4,1998/10/01 12:31:00,139.8958056617,35.6672989165,2,7,4115209,14,3 ,32,,2",
 "184000,6,6,1998/10/01 12:31:00,140.10323824,36.0873479502,2,4,5214028,13,3 ,49,,2",
 "202400,4,4,1998/10/01 12:31:00,140.4949402271,35.9824578721,2,10,5610009,4 ,3 ,34,,7",
 "333500,2,2,1998/10/01 12:31:00,139.5780082143,35.6164637712,1,8,2130004,9 ,1 ,46,,1",
 "370300,4,6,1998/10/01 12:31:00,139.7416004295,35.6898087593,1,10,3037010,10,13,39,,1",
 "374900,6,6,1998/10/01 12:31:00,139.7670064453,35.664424164,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:31:00,139.6237465678,35.9126354003,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:31:00,139.6871989473,35.7062717361,1,5,0420106,13,3 ,64,,12",
 "731400,2,2,1998/10/01 12:31:00,139.473699677,35.6698061729,2,10,0771111,14,4 ,32,,6",
 "802700,2,3,1998/10/01 12:31:00,139.5899231446,35.494611677,2,6,1425101,14,7 ,32,,10",
 "786600,3,5,1998/10/01 12:31:00,139.3459127725,35.6595776068,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:31:00,139.5681570136,35.5044621795,2,11,1330011,15,3 ,45,,6",
 "685400,4,5,1998/10/01 12:31:00,139.7689413102,35.6980842012,1,11,0634016,15,7 ,48,,12",
 "607200,2,3,1998/10/01 12:32:00,139.6088780534,35.7171299064,2,7,0424006,14,4 ,59,,10",
 "749800,2,2,1998/10/01 12:32:00,139.4809935277,35.5211956405,1,7,0810411,10,1 ,36,,6",
 "11500,4,4,1998/10/01 12:32:00,139.8964186402,35.6689284355,2,7,4115209,14,3 ,32,,2",
 "184000,6,6,1998/10/01 12:32:00,140.1019917302,36.0890917371,2,4,5214028,13,3 ,49,,2",
 "202400,4,4,1998/10/01 12:32:00,140.4913906757,35.9847977085,2,10,5610009,4 ,3 ,34,,7",
 "333500,2,2,1998/10/01 12:32:00,139.5774827029,35.616051083,1,8,2130004,9 ,1 ,46,,1",
 "370300,4,6,1998/10/01 12:32:00,139.7406885677,35.689800672,1,10,3037010,10,13,39,,1",
 "374900,6,6,1998/10/01 12:32:00,139.7657122147,35.6658560597,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:32:00,139.6237247351,35.9127513732,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:32:00,139.6815485321,35.70612152,1,5,0420106,13,3 ,64,,12",
 "731400,2,2,1998/10/01 12:32:00,139.4736656976,35.6694804815,2,10,0771111,14,4 ,32,,6",
 "802700,2,3,1998/10/01 12:32:00,139.5899231945,35.4946112496,2,6,1425101,14,7 ,32,,10",
 "786600,3,5,1998/10/01 12:32:00,139.3461260279,35.6597824316,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:32:00,139.565715984,35.5051219553,2,11,1330011,15,3 ,45,,6",
 "685400,4,5,1998/10/01 12:32:00,139.7643523717,35.6997879882,1,11,0634016,15,7 ,48,,12",
 "607200,2,3,1998/10/01 12:33:00,139.6088779744,35.7171298183,2,7,0424006,14,4 ,59,,10",
 "749800,2,2,1998/10/01 12:33:00,139.4809936653,35.5211956307,1,7,0810411,10,1 ,36,,6",
 "11500,4,4,1998/10/01 12:33:00,139.8945481372,35.6696218658,2,7,4115209,14,3 ,32,,2",
 "184000,6,6,1998/10/01 12:33:00,140.1007457929,36.0908358161,2,4,5214028,13,3 ,49,,2",
 "202400,4,4,1998/10/01 12:33:00,140.4875402181,35.9867925866,2,10,5610009,4 ,3 ,34,,7",
 "333500,2,2,1998/10/01 12:33:00,139.5771062467,35.6155053946,1,8,2130004,9 ,1 ,46,,1",
 "370300,4,6,1998/10/01 12:33:00,139.7398872613,35.6899219799,1,10,3037010,10,13,39,,1",
 "374900,6,6,1998/10/01 12:33:00,139.7641212307,35.6668641873,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:33:00,139.6237029024,35.9128673462,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:33:00,139.6758866009,35.7060354722,1,5,0420106,13,3 ,64,,12",
 "731400,2,2,1998/10/01 12:33:00,139.4736431065,35.6691541602,2,10,0771111,14,4 ,32,,6",
 "802700,2,3,1998/10/01 12:33:00,139.5899232444,35.4946108223,2,6,1425101,14,7 ,32,,10",
 "786600,3,5,1998/10/01 12:33:00,139.3463392845,35.6599872559,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:33:00,139.5641931174,35.5052018272,2,11,1330011,15,3 ,45,,6",
 "685400,4,5,1998/10/01 12:33:00,139.7598211625,35.7014367807,1,11,0634016,15,7 ,48,,12",
 "607200,2,3,1998/10/01 12:34:00,139.6088778953,35.7171297302,2,7,0424006,14,4 ,59,,10",
 "749800,2,2,1998/10/01 12:34:00,139.4809938029,35.521195621,1,7,0810411,10,1 ,36,,6",
 "11500,4,4,1998/10/01 12:34:00,139.8926046126,35.6703168161,2,7,4115209,14,3 ,32,,2",
 "184000,6,6,1998/10/01 12:34:00,140.0994998008,36.0925798818,2,4,5214028,13,3 ,49,,2",
 "202400,4,4,1998/10/01 12:34:00,140.4884802799,35.9901476949,2,10,5610009,4 ,3 ,34,,7",
 "333500,2,2,1998/10/01 12:34:00,139.5768395516,35.614917934,1,8,2130004,9 ,1 ,46,,1",
 "370300,4,6,1998/10/01 12:34:00,139.7395809821,35.6906227044,1,10,3037010,10,13,39,,1",
 "374900,6,6,1998/10/01 12:34:00,139.7620929217,35.6664618971,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:34:00,139.6236810697,35.9129833191,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:34:00,139.6702260402,35.7059040272,1,5,0420106,13,3 ,64,,12",
 "731400,2,2,1998/10/01 12:34:00,139.473622924,35.6688277056,2,10,0771111,14,4 ,32,,6",
 "802700,2,3,1998/10/01 12:34:00,139.5899232943,35.4946103949,2,6,1425101,14,7 ,32,,10",
 "786600,3,5,1998/10/01 12:34:00,139.3465525421,35.6601920799,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:34:00,139.5630736858,35.5035190607,2,11,1330011,15,3 ,45,,6",
 "685400,4,5,1998/10/01 12:34:00,139.7548393882,35.7019896342,1,11,0634016,15,7 ,48,,12",
 "607200,2,3,1998/10/01 12:35:00,139.6088778163,35.7171296421,2,7,0424006,14,4 ,59,,10",
 "749800,2,2,1998/10/01 12:35:00,139.4809939405,35.5211956112,1,7,0810411,10,1 ,36,,6",
 "11500,4,4,1998/10/01 12:35:00,139.8932257882,35.671760173,2,7,4115209,14,3 ,32,,2",
 "184000,6,6,1998/10/01 12:35:00,140.1000212283,36.0920412403,2,4,5214028,13,3 ,49,,2",
 "202400,4,4,1998/10/01 12:35:00,140.4903056854,35.9935819901,2,10,5610009,4 ,3 ,34,,7",
 "333500,2,2,1998/10/01 12:35:00,139.5766535155,35.6143143213,1,8,2130004,9 ,1 ,46,,1",
 "370300,4,6,1998/10/01 12:35:00,139.7392825086,35.691325652,1,10,3037010,10,13,39,,1",
 "374900,6,6,1998/10/01 12:35:00,139.7600112538,35.6668772466,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:35:00,139.6236592368,35.9130992921,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:35:00,139.6645662602,35.7057589521,1,5,0420106,13,3 ,64,,12",
 "802700,2,3,1998/10/01 12:35:00,139.5899233442,35.4946099676,2,6,1425101,14,7 ,32,,10",
 "731400,2,2,1998/10/01 12:35:00,139.4736027417,35.6685012511,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:35:00,139.3467522212,35.6604057962,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:35:00,139.5606074016,35.5029988512,2,11,1330011,15,3 ,45,,6",
 "685400,4,5,1998/10/01 12:35:00,139.7498071457,35.7023629323,1,11,0634016,15,7 ,48,,12",
 "607200,2,3,1998/10/01 12:36:00,139.6088777372,35.717129554,2,7,0424006,14,4 ,59,,10",
 "749800,2,2,1998/10/01 12:36:00,139.480994078,35.5211956014,1,7,0810411,10,1 ,36,,6",
 "11500,4,4,1998/10/01 12:36:00,139.8931854675,35.6721235999,2,7,4115209,14,3 ,32,,2",
 "184000,6,6,1998/10/01 12:36:00,140.1012448509,36.090286912,2,4,5214028,13,3 ,49,,2",
 "202400,4,4,1998/10/01 12:36:00,140.4891478135,35.9912847576,2,10,5610009,4 ,3 ,34,,7",
 "333500,2,2,1998/10/01 12:36:00,139.5765571116,35.6136927597,1,8,2130004,9 ,1 ,46,,1",
 "370300,4,6,1998/10/01 12:36:00,139.7389149434,35.6919143272,1,10,3037010,10,13,39,,1",
 "374900,6,6,1998/10/01 12:36:00,139.7580022747,35.6675698136,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:36:00,139.6235825623,35.9131663648,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:36:00,139.6589088757,35.7055654948,1,5,0420106,13,3 ,64,,12",
 "731400,2,2,1998/10/01 12:36:00,139.4735825595,35.6681747966,2,10,0771111,14,4 ,32,,6",
 "802700,2,3,1998/10/01 12:36:00,139.5899233941,35.4946095403,2,6,1425101,14,7 ,32,,10",
 "786600,3,5,1998/10/01 12:36:00,139.3469510393,35.6606200766,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:36:00,139.5578961592,35.502769975,2,11,1330011,15,3 ,45,,6",
 "685400,4,5,1998/10/01 12:36:00,139.7449554551,35.7016924032,1,11,0634016,15,7 ,48,,12",
 "607200,2,3,1998/10/01 12:37:00,139.6088776582,35.7171294659,2,7,0424006,14,4 ,59,,10",
 "749800,2,2,1998/10/01 12:37:00,139.4809942156,35.5211955916,1,7,0810411,10,1 ,36,,6",
 "11500,4,4,1998/10/01 12:37:00,139.8925055751,35.6737821205,2,7,4115209,14,3 ,32,,2",
 "184000,6,6,1998/10/01 12:37:00,140.1000807792,36.0920007922,2,4,5214028,13,3 ,49,,2",
 "202400,4,4,1998/10/01 12:37:00,140.490361982,35.9930092821,2,10,5610009,4 ,3 ,34,,7",
 "333500,2,2,1998/10/01 12:37:00,139.5758449572,35.6136648734,1,8,2130004,9 ,1 ,46,,1",
 "370300,4,6,1998/10/01 12:37:00,139.7380544487,35.6916679976,1,10,3037010,10,13,39,,1",
 "374900,6,6,1998/10/01 12:37:00,139.7559697254,35.6682166223,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:37:00,139.6234386,35.9131734394,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:37:00,139.653249012,35.7054162797,1,5,0420106,13,3 ,64,,12",
 "731400,2,2,1998/10/01 12:37:00,139.4735623775,35.667848342,2,10,0771111,14,4 ,32,,6",
 "802700,2,3,1998/10/01 12:37:00,139.589923444,35.4946091129,2,6,1425101,14,7 ,32,,10",
 "786600,3,5,1998/10/01 12:37:00,139.3471498585,35.6608343568,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:37:00,139.5558583803,35.5016379353,2,11,1330011,15,3 ,45,,6",
 "685400,4,5,1998/10/01 12:37:00,139.7421009653,35.6982723167,1,11,0634016,15,7 ,48,,12",
 "607200,2,3,1998/10/01 12:38:00,139.6088775792,35.7171293778,2,7,0424006,14,4 ,59,,10",
 "749800,2,2,1998/10/01 12:38:00,139.4809943532,35.5211955818,1,7,0810411,10,1 ,36,,6",
 "11500,4,4,1998/10/01 12:38:00,139.8920232989,35.6754722349,2,7,4115209,14,3 ,32,,2",
 "184000,6,6,1998/10/01 12:38:00,140.0988814286,36.0937662738,2,4,5214028,13,3 ,49,,2",
 "202400,4,4,1998/10/01 12:38:00,140.4929545759,35.9961005881,2,10,5610009,4 ,3 ,34,,7",
 "333500,2,2,1998/10/01 12:38:00,139.5750782973,35.6136895333,1,8,2130004,9 ,1 ,46,,1",
 "370300,4,6,1998/10/01 12:38:00,139.7371954336,35.6914182579,1,10,3037010,10,13,39,,1",
 "374900,6,6,1998/10/01 12:38:00,139.7539345606,35.6688574331,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:38:00,139.623402579,35.9132847276,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:38:00,139.6475896052,35.7052558072,1,5,0420106,13,3 ,64,,12",
 "731400,2,2,1998/10/01 12:38:00,139.4735421956,35.6675218874,2,10,0771111,14,4 ,32,,6",
 "802700,2,3,1998/10/01 12:38:00,139.5899234939,35.4946086856,2,6,1425101,14,7 ,32,,10",
 "786600,3,5,1998/10/01 12:38:00,139.3473479899,35.6610490554,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:38:00,139.5536639357,35.5014835834,2,11,1330011,15,3 ,45,,6",
 "685400,4,5,1998/10/01 12:38:00,139.7394448818,35.6947376612,1,11,0634016,15,7 ,48,,12",
 "607200,2,3,1998/10/01 12:39:00,139.6088775001,35.7171292896,2,7,0424006,14,4 ,59,,10",
 "749800,2,2,1998/10/01 12:39:00,139.4809944907,35.521195572,1,7,0810411,10,1 ,36,,6",
 "11500,4,4,1998/10/01 12:39:00,139.8921307363,35.6765606603,2,7,4115209,14,3 ,32,,2",
 "184000,6,6,1998/10/01 12:39:00,140.0985147655,36.0953102159,2,4,5214028,13,3 ,49,,2",
 "202400,4,4,1998/10/01 12:39:00,140.4908291027,35.9950191403,2,10,5610009,4 ,3 ,34,,7",
 "333500,2,2,1998/10/01 12:39:00,139.5743116369,35.6137141883,1,8,2130004,9 ,1 ,46,,1",
 "370300,4,6,1998/10/01 12:39:00,139.736328804,35.6911943723,1,10,3037010,10,13,39,,1",
 "374900,6,6,1998/10/01 12:39:00,139.7519046618,35.6695098342,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:39:00,139.6233695888,35.913398942,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:39:00,139.6419313653,35.7050696217,1,5,0420106,13,3 ,64,,12",
 "731400,2,2,1998/10/01 12:39:00,139.4735220139,35.6671954328,2,10,0771111,14,4 ,32,,6",
 "802700,2,3,1998/10/01 12:39:00,139.5899235438,35.4946082582,2,6,1425101,14,7 ,32,,10",
 "786600,3,5,1998/10/01 12:39:00,139.3475467257,35.661263377,2,3,0930110,13,2 ,35,,9",
 "851000,4,4,1998/10/01 12:39:00,139.5519050329,35.5025075206,2,11,1330011,15,3 ,45,,6",
 "685400,4,5,1998/10/01 12:39:00,139.7361381702,35.6916427185,1,11,0634016,15,7 ,48,,12",
 "607200,2,3,1998/10/01 12:40:00,139.6088774211,35.7171292015,2,7,0424006,14,4 ,59,,10",
 "749800,2,2,1998/10/01 12:40:00,139.4809946283,35.5211955622,1,7,0810411,10,1 ,36,,6",
 "11500,4,4,1998/10/01 12:40:00,139.8916091155,35.6774817198,2,7,4115209,14,3 ,32,,2",
 "11500,5,5,1998/10/01 12:40:00,139.8916091155,35.6774817198,2,7,4115209,14,97,32,,97",
 "184000,6,6,1998/10/01 12:40:00,140.0984988134,36.0968153713,2,4,5214028,13,3 ,49,,2",
 "202400,4,4,1998/10/01 12:40:00,140.4916020357,35.9987070227,2,10,5610009,4 ,3 ,34,,7",
 "202400,5,5,1998/10/01 12:40:00,140.4916020357,35.9987070227,2,10,5610009,4 ,97,34,,97",
 "333500,2,2,1998/10/01 12:40:00,139.5735449762,35.6137388385,1,8,2130004,9 ,1 ,46,,1",
 "370300,4,6,1998/10/01 12:40:00,139.7359351211,35.6915687753,1,10,3037010,10,13,39,,1",
 "370300,4,7,1998/10/01 12:40:00,139.7359351211,35.6915687753,1,10,3037010,10,13,39,,12",
 "374900,6,6,1998/10/01 12:40:00,139.7499010184,35.6702090399,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:40:00,139.6233365986,35.9135131565,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,7,1998/10/01 12:40:00,139.6363044357,35.7048979168,1,5,0420106,13,3 ,64,,12",
 "600300,4,8,1998/10/01 12:40:00,139.6363044357,35.7048979168,1,5,0420106,13,3 ,64,,2",
 "731400,2,2,1998/10/01 12:40:00,139.4735030526,35.6668689318,2,10,0771111,14,4 ,32,,6",
 "802700,2,3,1998/10/01 12:40:00,139.5899235937,35.4946078309,2,6,1425101,14,7 ,32,,10",
 "786600,3,5,1998/10/01 12:40:00,139.3477465667,35.6614770241,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 12:40:00,139.7320534991,35.6891824201,1,11,0634016,15,7 ,48,,12",
 "851000,4,4,1998/10/01 12:40:00,139.5516544179,35.5047655785,2,11,1330011,15,3 ,45,,6",
 "851000,5,5,1998/10/01 12:40:00,139.5516544179,35.5047655785,2,11,1330011,15,97,45,,97",
 "154100,4,5,1998/10/01 12:41:00,140.1133354959,35.6105359891,2,10,4040012,14,3 ,41,,1",
 "607200,2,3,1998/10/01 12:41:00,139.608877342,35.7171291134,2,7,0424006,14,4 ,59,,10",
 "749800,2,2,1998/10/01 12:41:00,139.4809947659,35.5211955524,1,7,0810411,10,1 ,36,,6",
 "184000,6,6,1998/10/01 12:41:00,140.0990521264,36.0982688414,2,4,5214028,13,3 ,49,,2",
 "184000,7,7,1998/10/01 12:41:00,140.0990521264,36.0982688414,2,4,5214028,13,97,49,,97",
 "333500,2,2,1998/10/01 12:41:00,139.572778315,35.6137634837,1,8,2130004,9 ,1 ,46,,1",
 "287500,2,2,1998/10/01 12:41:00,139.4270596996,35.4766871841,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 12:41:00,139.7382991512,35.6945729934,1,10,3037010,10,13,39,,12",
 "308200,2,2,1998/10/01 12:41:00,139.3440818347,35.5529653115,2,12,2924401,14,7 ,33,,6",
 "374900,6,6,1998/10/01 12:41:00,139.7479568344,35.6704731382,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:41:00,139.6233036083,35.9136273709,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,8,1998/10/01 12:41:00,139.6366289535,35.705006398,1,5,0420106,13,3 ,64,,2",
 "632500,2,2,1998/10/01 12:41:00,139.5838062937,35.7126731038,2,6,0513201,14,7 ,39,,2",
 "731400,2,2,1998/10/01 12:41:00,139.4734835892,35.6665424512,2,10,0771111,14,4 ,32,,6",
 "802700,2,3,1998/10/01 12:41:00,139.5899236436,35.4946074036,2,6,1425101,14,7 ,32,,10",
 "802700,2,4,1998/10/01 12:41:00,139.5899236436,35.4946074036,2,6,1425101,14,7 ,32,,1",
 "786600,3,5,1998/10/01 12:41:00,139.3479464087,35.6616906709,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 12:41:00,139.7304103804,35.6852861313,1,11,0634016,15,7 ,48,,12",
 "154100,4,5,1998/10/01 12:42:00,140.1133365752,35.6105370386,2,10,4040012,14,3 ,41,,1",
 "607200,2,3,1998/10/01 12:42:00,139.608877263,35.7171290253,2,7,0424006,14,4 ,59,,10",
 "749800,2,2,1998/10/01 12:42:00,139.4809949034,35.5211955426,1,7,0810411,10,1 ,36,,6",
 "333500,2,2,1998/10/01 12:42:00,139.5720116533,35.6137881241,1,8,2130004,9 ,1 ,46,,1",
 "287500,2,2,1998/10/01 12:42:00,139.4274146052,35.4770477933,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 12:42:00,139.7407410741,35.6978260231,1,10,3037010,10,13,39,,12",
 "308200,2,2,1998/10/01 12:42:00,139.3433286159,35.5541673957,2,12,2924401,14,7 ,33,,6",
 "374900,6,6,1998/10/01 12:42:00,139.7457989415,35.6704912963,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:42:00,139.6232659753,35.9137385307,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,8,1998/10/01 12:42:00,139.6376856449,35.705327496,1,5,0420106,13,3 ,64,,2",
 "632500,2,2,1998/10/01 12:42:00,139.5833976235,35.7118670526,2,6,0513201,14,7 ,39,,2",
 "731400,2,2,1998/10/01 12:42:00,139.473462871,35.666216019,2,10,0771111,14,4 ,32,,6",
 "802700,2,4,1998/10/01 12:42:00,139.5907003759,35.4890927042,2,6,1425101,14,7 ,32,,1",
 "786600,3,5,1998/10/01 12:42:00,139.348148778,35.6619026461,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 12:42:00,139.727498106,35.6819648445,1,11,0634016,15,7 ,48,,12",
 "154100,4,5,1998/10/01 12:43:00,140.1133376546,35.610538088,2,10,4040012,14,3 ,41,,1",
 "154100,4,6,1998/10/01 12:43:00,140.1133376546,35.610538088,2,10,4040012,14,3 ,41,,6",
 "607200,2,3,1998/10/01 12:43:00,139.6088771839,35.7171289372,2,7,0424006,14,4 ,59,,10",
 "749800,2,2,1998/10/01 12:43:00,139.480995041,35.5211955328,1,7,0810411,10,1 ,36,,6",
 "333500,2,2,1998/10/01 12:43:00,139.5712449912,35.6138127596,1,8,2130004,9 ,1 ,46,,1",
 "287500,2,2,1998/10/01 12:43:00,139.4277695139,35.4774084015,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 12:43:00,139.7431042283,35.7011167619,1,10,3037010,10,13,39,,12",
 "308200,2,2,1998/10/01 12:43:00,139.3447626851,35.5548897217,2,12,2924401,14,7 ,33,,6",
 "374900,6,6,1998/10/01 12:43:00,139.7436657544,35.6708624956,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:43:00,139.6231355708,35.913788649,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,8,1998/10/01 12:43:00,139.6388018354,35.7057021104,1,5,0420106,13,3 ,64,,2",
 "632500,2,2,1998/10/01 12:43:00,139.5829889614,35.711061,2,6,0513201,14,7 ,39,,2",
 "731400,2,2,1998/10/01 12:43:00,139.473442153,35.6658895867,2,10,0771111,14,4 ,32,,6",
 "802700,2,4,1998/10/01 12:43:00,139.5977923315,35.4802800655,2,6,1425101,14,7 ,32,,1",
 "786600,3,5,1998/10/01 12:43:00,139.3483925647,35.6620792505,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 12:43:00,139.72300067,35.6800386548,1,11,0634016,15,7 ,48,,12",
 "154100,4,6,1998/10/01 12:44:00,140.1150490011,35.6115829493,2,10,4040012,14,3 ,41,,6",
 "607200,2,3,1998/10/01 12:44:00,139.6088771049,35.7171288491,2,7,0424006,14,4 ,59,,10",
 "607200,2,4,1998/10/01 12:44:00,139.6088771049,35.7171288491,2,7,0424006,14,4 ,59,,1",
 "749800,2,2,1998/10/01 12:44:00,139.4809951786,35.521195523,1,7,0810411,10,1 ,36,,6",
 "333500,2,2,1998/10/01 12:44:00,139.5705781654,35.6137450274,1,8,2130004,9 ,1 ,46,,1",
 "287500,2,2,1998/10/01 12:44:00,139.4279070907,35.4778344096,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 12:44:00,139.7467484377,35.7032604455,1,10,3037010,10,13,39,,12",
 "308200,2,2,1998/10/01 12:44:00,139.3464662763,35.5553815539,2,12,2924401,14,7 ,33,,6",
 "374900,6,6,1998/10/01 12:44:00,139.7418254848,35.6717641522,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:44:00,139.6230031746,35.913835142,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,8,1998/10/01 12:44:00,139.6392399462,35.7061300836,1,5,0420106,13,3 ,64,,2",
 "632500,2,2,1998/10/01 12:44:00,139.5825803076,35.7102549459,2,6,0513201,14,7 ,39,,2",
 "731400,2,2,1998/10/01 12:44:00,139.4734214352,35.6655631543,2,10,0771111,14,4 ,32,,6",
 "802700,2,4,1998/10/01 12:44:00,139.599313527,35.4695949124,2,6,1425101,14,7 ,32,,1",
 "786600,3,5,1998/10/01 12:44:00,139.3487119887,35.6621231203,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 12:44:00,139.7179830199,35.6803741489,1,11,0634016,15,7 ,48,,12",
 "802700,2,4,1998/10/01 12:45:00,139.6029710647,35.4604223522,2,6,1425101,14,7 ,32,,1",
 "154100,4,6,1998/10/01 12:45:00,140.1166621411,35.613185312,2,10,4040012,14,3 ,41,,6",
 "607200,2,4,1998/10/01 12:45:00,139.5986027851,35.7056753471,2,7,0424006,14,4 ,59,,1",
 "607200,3,5,1998/10/01 12:45:00,139.5986027851,35.7056753471,2,7,0424006,14,97,59,,97",
 "749800,2,2,1998/10/01 12:45:00,139.4809953162,35.5211955132,1,7,0810411,10,1 ,36,,6",
 "333500,2,2,1998/10/01 12:45:00,139.5701226117,35.6135464514,1,8,2130004,9 ,1 ,46,,1",
 "287500,2,2,1998/10/01 12:45:00,139.4279224904,35.4782971834,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 12:45:00,139.751234838,35.7038183941,1,10,3037010,10,13,39,,12",
 "308200,2,2,1998/10/01 12:45:00,139.3481748567,35.5558597772,2,12,2924401,14,7 ,33,,6",
 "374900,6,6,1998/10/01 12:45:00,139.7401222971,35.6728768032,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:45:00,139.6228738728,35.913885806,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,8,1998/10/01 12:45:00,139.6398777907,35.7069658595,1,5,0420106,13,3 ,64,,2",
 "632500,2,2,1998/10/01 12:45:00,139.5821716619,35.7094488903,2,6,0513201,14,7 ,39,,2",
 "731400,2,2,1998/10/01 12:45:00,139.4734007176,35.665236722,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:45:00,139.3490401407,35.6621460636,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 12:45:00,139.7129641172,35.681027297,1,11,0634016,15,7 ,48,,12",
 "154100,4,6,1998/10/01 12:46:00,140.1188473816,35.614345469,2,10,4040012,14,3 ,41,,6",
 "848700,2,2,1998/10/01 12:46:00,139.6165125671,35.5386139688,1,4,1313101,13,2 ,37,,2",
 "749800,2,2,1998/10/01 12:46:00,139.4809954537,35.5211955035,1,7,0810411,10,1 ,36,,6",
 "333500,2,2,1998/10/01 12:46:00,139.5693706982,35.6136711086,1,8,2130004,9 ,1 ,46,,1",
 "287500,2,2,1998/10/01 12:46:00,139.4279347371,35.478759993,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 12:46:00,139.7518297668,35.7075282037,1,10,3037010,10,13,39,,12",
 "308200,2,2,1998/10/01 12:46:00,139.3499500601,35.5561285117,2,12,2924401,14,7 ,33,,6",
 "338100,4,4,1998/10/01 12:46:00,139.5328079329,35.6268707764,2,6,2132009,14,3 ,31,,1",
 "374900,6,6,1998/10/01 12:46:00,139.7387675422,35.6742111391,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:46:00,139.6227586841,35.913956409,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,8,1998/10/01 12:46:00,139.6405156484,35.707801632,1,5,0420106,13,3 ,64,,2",
 "632500,2,2,1998/10/01 12:46:00,139.5817630244,35.7086428333,2,6,0513201,14,7 ,39,,2",
 "731400,2,2,1998/10/01 12:46:00,139.47338,35.6649102897,2,10,0771111,14,4 ,32,,6",
 "802700,2,4,1998/10/01 12:46:00,139.5996575946,35.4509207943,2,6,1425101,14,7 ,32,,1",
 "786600,3,5,1998/10/01 12:46:00,139.349368293,35.6621690059,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 12:46:00,139.7079462398,35.6815093153,1,11,0634016,15,7 ,48,,12",
 "154100,4,6,1998/10/01 12:47:00,140.1209955422,35.6155601923,2,10,4040012,14,3 ,41,,6",
 "848700,2,2,1998/10/01 12:47:00,139.6177510785,35.5386998965,1,4,1313101,13,2 ,37,,2",
 "749800,2,2,1998/10/01 12:47:00,139.4809955913,35.5211954937,1,7,0810411,10,1 ,36,,6",
 "333500,2,2,1998/10/01 12:47:00,139.5686187824,35.613795761,1,8,2130004,9 ,1 ,46,,1",
 "287500,2,2,1998/10/01 12:47:00,139.4279474424,35.4792228114,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 12:47:00,139.7527130389,35.711097784,1,10,3037010,10,13,39,,12",
 "308200,2,2,1998/10/01 12:47:00,139.3517235226,35.5564098609,2,12,2924401,14,7 ,33,,6",
 "338100,4,4,1998/10/01 12:47:00,139.5322708006,35.6266659226,2,6,2132009,14,3 ,31,,1",
 "374900,6,6,1998/10/01 12:47:00,139.737994245,35.6758616142,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:47:00,139.6226434952,35.9140270119,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,8,1998/10/01 12:47:00,139.6411535195,35.708637401,1,5,0420106,13,3 ,64,,2",
 "632500,2,2,1998/10/01 12:47:00,139.5814395669,35.7078117238,2,6,0513201,14,7 ,39,,2",
 "731400,2,2,1998/10/01 12:47:00,139.4733593437,35.6645838549,2,10,0771111,14,4 ,32,,6",
 "802700,2,4,1998/10/01 12:47:00,139.5961695677,35.44218669,2,6,1425101,14,7 ,32,,1",
 "786600,3,5,1998/10/01 12:47:00,139.3496964454,35.6621919474,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 12:47:00,139.7031696567,35.6823078588,1,11,0634016,15,7 ,48,,12",
 "154100,4,6,1998/10/01 12:48:00,140.1231655322,35.616747942,2,10,4040012,14,3 ,41,,6",
 "848700,2,2,1998/10/01 12:48:00,139.6189895925,35.5387858115,1,4,1313101,13,2 ,37,,2",
 "749800,2,2,1998/10/01 12:48:00,139.4809957289,35.5211954839,1,7,0810411,10,1 ,36,,6",
 "333500,2,2,1998/10/01 12:48:00,139.5678668644,35.6139204088,1,8,2130004,9 ,1 ,46,,1",
 "287500,2,2,1998/10/01 12:48:00,139.4279608918,35.479685625,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 12:48:00,139.7564500552,35.7130843246,1,10,3037010,10,13,39,,12",
 "308200,2,2,1998/10/01 12:48:00,139.3535029699,35.5566643633,2,12,2924401,14,7 ,33,,6",
 "338100,4,4,1998/10/01 12:48:00,139.5317336711,35.6264610663,2,6,2132009,14,3 ,31,,1",
 "374900,6,6,1998/10/01 12:48:00,139.7371157876,35.6774801535,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:48:00,139.622528306,35.9140976147,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,8,1998/10/01 12:48:00,139.6419555778,35.7093669548,1,5,0420106,13,3 ,64,,2",
 "632500,2,2,1998/10/01 12:48:00,139.5811233875,35.7069784746,2,6,0513201,14,7 ,39,,2",
 "731400,2,2,1998/10/01 12:48:00,139.4733417138,35.6642573018,2,10,0771111,14,4 ,32,,6",
 "802700,2,4,1998/10/01 12:48:00,139.6010433562,35.4328997053,2,6,1425101,14,7 ,32,,1",
 "786600,3,5,1998/10/01 12:48:00,139.3500245551,35.6622152672,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 12:48:00,139.7013330997,35.6861170668,1,11,0634016,15,7 ,48,,12",
 "154100,4,6,1998/10/01 12:49:00,140.1252225227,35.6180637702,2,10,4040012,14,3 ,41,,6",
 "848700,2,2,1998/10/01 12:49:00,139.6202281093,35.5388717138,1,4,1313101,13,2 ,37,,2",
 "749800,2,2,1998/10/01 12:49:00,139.4809958664,35.5211954741,1,7,0810411,10,1 ,36,,6",
 "333500,2,2,1998/10/01 12:49:00,139.567114944,35.6140450519,1,8,2130004,9 ,1 ,46,,1",
 "287500,2,2,1998/10/01 12:49:00,139.4279714111,35.4801484901,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 12:49:00,139.7582306757,35.7165389359,1,10,3037010,10,13,39,,12",
 "308200,2,2,1998/10/01 12:49:00,139.3550884204,35.5573638392,2,12,2924401,14,7 ,33,,6",
 "338100,4,4,1998/10/01 12:49:00,139.5311965443,35.6262562076,2,6,2132009,14,3 ,31,,1",
 "374900,6,6,1998/10/01 12:49:00,139.7355863275,35.6783927654,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:49:00,139.622421465,35.9141758014,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,8,1998/10/01 12:49:00,139.6421364556,35.7102066812,1,5,0420106,13,3 ,64,,2",
 "632500,2,2,1998/10/01 12:49:00,139.5811111173,35.706249046,2,6,0513201,14,7 ,39,,2",
 "731400,2,2,1998/10/01 12:49:00,139.473324084,35.6639307486,2,10,0771111,14,4 ,32,,6",
 "802700,2,4,1998/10/01 12:49:00,139.6000492197,35.4230931951,2,6,1425101,14,7 ,32,,1",
 "786600,3,5,1998/10/01 12:49:00,139.3503523025,35.6622417882,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 12:49:00,139.7000098563,35.6901143786,1,11,0634016,15,7 ,48,,12",
 "154100,4,6,1998/10/01 12:50:00,140.1273320876,35.6193058405,2,10,4040012,14,3 ,41,,6",
 "848700,2,2,1998/10/01 12:50:00,139.6214666287,35.5389576034,1,4,1313101,13,2 ,37,,2",
 "749800,2,2,1998/10/01 12:50:00,139.480996004,35.5211954643,1,7,0810411,10,1 ,36,,6",
 "333500,2,2,1998/10/01 12:50:00,139.5663630213,35.6141696903,1,8,2130004,9 ,1 ,46,,1",
 "287500,2,2,1998/10/01 12:50:00,139.4279758363,35.4806114188,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 12:50:00,139.7565602763,35.7200171127,1,10,3037010,10,13,39,,12",
 "308200,2,2,1998/10/01 12:50:00,139.3566386565,35.5578220998,2,12,2924401,14,7 ,33,,6",
 "338100,4,4,1998/10/01 12:50:00,139.531064282,35.6258587613,2,6,2132009,14,3 ,31,,1",
 "374900,6,6,1998/10/01 12:50:00,139.7334553026,35.6787287325,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:50:00,139.6223225309,35.9142611715,1,10,3512006,6 ,1 ,36,,6",
 "600300,4,8,1998/10/01 12:50:00,139.6417581399,35.7111415451,1,5,0420106,13,3 ,64,,2",
 "600300,5,9,1998/10/01 12:50:00,139.6417581399,35.7111415451,1,5,0420106,13,97,64,,97",
 "632500,2,2,1998/10/01 12:50:00,139.5807051314,35.7054421033,2,6,0513201,14,7 ,39,,2",
 "731400,2,2,1998/10/01 12:50:00,139.4733064544,35.6636041954,2,10,0771111,14,4 ,32,,6",
 "802700,2,4,1998/10/01 12:50:00,139.5994033728,35.4134175774,2,6,1425101,14,7 ,32,,1",
 "802700,3,5,1998/10/01 12:50:00,139.5994033728,35.4134175774,2,6,1425101,14,97,32,,97",
 "786600,3,5,1998/10/01 12:50:00,139.3506798963,35.6622695401,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 12:50:00,139.6982262125,35.6882924512,1,11,0634016,15,7 ,48,,12",
 "154100,4,6,1998/10/01 12:51:00,140.1298059011,35.6199926843,2,10,4040012,14,3 ,41,,6",
 "848700,2,2,1998/10/01 12:51:00,139.622705808,35.5390367955,1,4,1313101,13,2 ,37,,2",
 "80500,6,8,1998/10/01 12:51:00,140.0921570871,35.697752643,1,14,4413233,5 ,10,33,,1",
 "749800,2,2,1998/10/01 12:51:00,139.4809961416,35.5211954545,1,7,0810411,10,1 ,36,,6",
 "333500,2,2,1998/10/01 12:51:00,139.5658401267,35.6138814024,1,8,2130004,9 ,1 ,46,,1",
 "287500,2,2,1998/10/01 12:51:00,139.427979755,35.4810743527,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 12:51:00,139.7541426421,35.7232729574,1,10,3037010,10,13,39,,12",
 "308200,2,2,1998/10/01 12:51:00,139.357814367,35.556734947,2,12,2924401,14,7 ,33,,6",
 "338100,4,4,1998/10/01 12:51:00,139.5309660621,35.6254452592,2,6,2132009,14,3 ,31,,1",
 "374900,6,6,1998/10/01 12:51:00,139.7323242175,35.6802016935,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:51:00,139.6222235967,35.9143465415,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,2,1998/10/01 12:51:00,139.5802775362,35.7046428179,2,6,0513201,14,7 ,39,,2",
 "731400,2,2,1998/10/01 12:51:00,139.4732888249,35.6632776422,2,10,0771111,14,4 ,32,,6",
 "726800,4,4,1998/10/01 12:51:00,139.4260961168,35.7082393818,1,10,0761308,15,3 ,34,,6",
 "786600,3,5,1998/10/01 12:51:00,139.3510074625,35.6622975134,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 12:51:00,139.6938636324,35.6861973339,1,11,0634016,15,7 ,48,,12",
 "154100,4,6,1998/10/01 12:52:00,140.1322765695,35.6206873238,2,10,4040012,14,3 ,41,,6",
 "848700,2,2,1998/10/01 12:52:00,139.6239422612,35.5391372283,1,4,1313101,13,2 ,37,,2",
 "80500,6,8,1998/10/01 12:52:00,140.09238163,35.6975105738,1,14,4413233,5 ,10,33,,1",
 "749800,2,2,1998/10/01 12:52:00,139.4809962792,35.5211954447,1,7,0810411,10,1 ,36,,6",
 "333500,2,2,1998/10/01 12:52:00,139.5654589127,35.6133376819,1,8,2130004,9 ,1 ,46,,1",
 "287500,2,2,1998/10/01 12:52:00,139.4279836736,35.4815372866,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 12:52:00,139.7531120974,35.7269626621,1,10,3037010,10,13,39,,12",
 "308200,2,2,1998/10/01 12:52:00,139.3595614273,35.5571114445,2,12,2924401,14,7 ,33,,6",
 "338100,4,4,1998/10/01 12:52:00,139.5305860731,35.6250737357,2,6,2132009,14,3 ,31,,1",
 "374900,6,6,1998/10/01 12:52:00,139.7308571452,35.6815112396,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:52:00,139.6221246622,35.9144319114,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,2,1998/10/01 12:52:00,139.5799710974,35.7038175596,2,6,0513201,14,7 ,39,,2",
 "731400,2,2,1998/10/01 12:52:00,139.4732711955,35.662951089,2,10,0771111,14,4 ,32,,6",
 "726800,4,4,1998/10/01 12:52:00,139.4264329745,35.7074381011,1,10,0761308,15,3 ,34,,6",
 "786600,3,5,1998/10/01 12:52:00,139.351335029,35.6623254859,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 12:52:00,139.6901482832,35.683394406,1,11,0634016,15,7 ,48,,12",
 "154100,4,6,1998/10/01 12:53:00,140.1347415271,35.6213926509,2,10,4040012,14,3 ,41,,6",
 "848700,2,2,1998/10/01 12:53:00,139.6251774607,35.5392460952,1,4,1313101,13,2 ,37,,2",
 "80500,6,8,1998/10/01 12:53:00,140.0925844521,35.697259384,1,14,4413233,5 ,10,33,,1",
 "749800,2,2,1998/10/01 12:53:00,139.4809964167,35.5211954349,1,7,0810411,10,1 ,36,,6",
 "333500,2,2,1998/10/01 12:53:00,139.5650777038,35.6127939601,1,8,2130004,9 ,1 ,46,,1",
 "287500,2,2,1998/10/01 12:53:00,139.4279880187,35.4820002178,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 12:53:00,139.7510897991,35.7303939922,1,10,3037010,10,13,39,,12",
 "308200,2,2,1998/10/01 12:53:00,139.3612544663,35.557617189,2,12,2924401,14,7 ,33,,6",
 "338100,4,4,1998/10/01 12:53:00,139.5302088451,35.6247003617,2,6,2132009,14,3 ,31,,1",
 "374900,6,6,1998/10/01 12:53:00,139.7294818906,35.6826082991,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:53:00,139.6220938045,35.9145460901,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,2,1998/10/01 12:53:00,139.5798368485,35.7029522173,2,6,0513201,14,7 ,39,,2",
 "632500,2,3,1998/10/01 12:53:00,139.5798368485,35.7029522173,2,6,0513201,14,7 ,39,,12",
 "726800,4,4,1998/10/01 12:53:00,139.4274661844,35.7074840407,1,10,0761308,15,3 ,34,,6",
 "731400,2,2,1998/10/01 12:53:00,139.4732535662,35.6626245358,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:53:00,139.3516625957,35.6623534574,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 12:53:00,139.6865426078,35.6804981119,1,11,0634016,15,7 ,48,,12",
 "154100,4,6,1998/10/01 12:54:00,140.1369217488,35.6225536357,2,10,4040012,14,3 ,41,,6",
 "848700,2,2,1998/10/01 12:54:00,139.6264133752,35.5393542311,1,4,1313101,13,2 ,37,,2",
 "80500,6,8,1998/10/01 12:54:00,140.0926955035,35.6969696594,1,14,4413233,5 ,10,33,,1",
 "749800,2,2,1998/10/01 12:54:00,139.4809965543,35.5211954251,1,7,0810411,10,1 ,36,,6",
 "333500,2,2,1998/10/01 12:54:00,139.5646965001,35.612250237,1,8,2130004,9 ,1 ,46,,1",
 "287500,2,2,1998/10/01 12:54:00,139.4279925231,35.4824631481,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 12:54:00,139.748447446,35.7335400251,1,10,3037010,10,13,39,,12",
 "308200,2,2,1998/10/01 12:54:00,139.3629556137,35.5581108105,2,12,2924401,14,7 ,33,,6",
 "338100,4,4,1998/10/01 12:54:00,139.5298362852,35.6243238581,2,6,2132009,14,3 ,31,,1",
 "374900,6,6,1998/10/01 12:54:00,139.7277028363,35.6831147931,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:54:00,139.6220646635,35.9146609953,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,3,1998/10/01 12:54:00,139.5819423861,35.6988067582,2,6,0513201,14,7 ,39,,12",
 "731400,2,2,1998/10/01 12:54:00,139.4732350404,35.6622980732,2,10,0771111,14,4 ,32,,6",
 "726800,4,4,1998/10/01 12:54:00,139.4285029285,35.7075342696,1,10,0761308,15,3 ,34,,6",
 "786600,3,5,1998/10/01 12:54:00,139.3519901627,35.6623814281,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 12:54:00,139.6819650463,35.6787098527,1,11,0634016,15,7 ,48,,12",
 "154100,4,6,1998/10/01 12:55:00,140.138502432,35.6241667105,2,10,4040012,14,3 ,41,,6",
 "848700,2,2,1998/10/01 12:55:00,139.6274948855,35.5390924171,1,4,1313101,13,2 ,37,,2",
 "80500,6,8,1998/10/01 12:55:00,140.0928065542,35.6966799347,1,14,4413233,5 ,10,33,,1",
 "749800,2,2,1998/10/01 12:55:00,139.4809966919,35.5211954153,1,7,0810411,10,1 ,36,,6",
 "333500,2,2,1998/10/01 12:55:00,139.5643153015,35.6117065128,1,8,2130004,9 ,1 ,46,,1",
 "287500,2,2,1998/10/01 12:55:00,139.4279970275,35.4829260783,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 12:55:00,139.7464699094,35.7369775641,1,10,3037010,10,13,39,,12",
 "308200,2,2,1998/10/01 12:55:00,139.3645776382,35.5587438969,2,12,2924401,14,7 ,33,,6",
 "338100,4,4,1998/10/01 12:55:00,139.5294637289,35.6239473534,2,6,2132009,14,3 ,31,,1",
 "374900,6,6,1998/10/01 12:55:00,139.7258772019,35.6829524867,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:55:00,139.6220355224,35.9147759005,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,3,1998/10/01 12:55:00,139.5852004,35.6951903875,2,6,0513201,14,7 ,39,,12",
 "726800,4,4,1998/10/01 12:55:00,139.429539674,35.7075844895,1,10,0761308,15,3 ,34,,6",
 "731400,2,2,1998/10/01 12:55:00,139.4732109842,35.6619717934,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:55:00,139.3523177299,35.6624093978,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 12:55:00,139.6774333814,35.6768792463,1,11,0634016,15,7 ,48,,12",
 "154100,4,6,1998/10/01 12:56:00,140.1393215888,35.6261745491,2,10,4040012,14,3 ,41,,6",
 "848700,2,2,1998/10/01 12:56:00,139.6283873577,35.5387568537,1,4,1313101,13,2 ,37,,2",
 "80500,6,8,1998/10/01 12:56:00,140.0929221776,35.6963914204,1,14,4413233,5 ,10,33,,1",
 "749800,2,2,1998/10/01 12:56:00,139.4809968294,35.5211954055,1,7,0810411,10,1 ,36,,6",
 "333500,2,2,1998/10/01 12:56:00,139.5639341081,35.6111627872,1,8,2130004,9 ,1 ,46,,1",
 "287500,2,2,1998/10/01 12:56:00,139.428001532,35.4833890085,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 12:56:00,139.7461312623,35.7405490083,1,10,3037010,10,13,39,,12",
 "308200,2,2,1998/10/01 12:56:00,139.3657799625,35.5598358897,2,12,2924401,14,7 ,33,,6",
 "338100,4,4,1998/10/01 12:56:00,139.5290498824,35.6236861282,2,6,2132009,14,3 ,31,,1",
 "374900,6,6,1998/10/01 12:56:00,139.7247131737,35.683978134,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:56:00,139.6220063812,35.9148908057,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,3,1998/10/01 12:56:00,139.5890692254,35.6921221097,2,6,0513201,14,7 ,39,,12",
 "731400,2,2,1998/10/01 12:56:00,139.4731865034,35.6616455345,2,10,0771111,14,4 ,32,,6",
 "726800,4,4,1998/10/01 12:56:00,139.4305761703,35.7076380192,1,10,0761308,15,3 ,34,,6",
 "786600,3,5,1998/10/01 12:56:00,139.3526452974,35.6624373667,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 12:56:00,139.6726383845,35.6755309579,1,11,0634016,15,7 ,48,,12",
 "154100,4,6,1998/10/01 12:57:00,140.1401732994,35.6281554873,2,10,4040012,14,3 ,41,,6",
 "848700,2,2,1998/10/01 12:57:00,139.6292608998,35.5383135347,1,4,1313101,13,2 ,37,,2",
 "80500,6,8,1998/10/01 12:57:00,140.0930408077,35.6961037019,1,14,4413233,5 ,10,33,,1",
 "749800,2,2,1998/10/01 12:57:00,139.480996967,35.5211953958,1,7,0810411,10,1 ,36,,6",
 "333500,2,2,1998/10/01 12:57:00,139.5635529198,35.6106190605,1,8,2130004,9 ,1 ,46,,1",
 "287500,2,2,1998/10/01 12:57:00,139.4280060365,35.4838519387,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 12:57:00,139.7438036787,35.7438579758,1,10,3037010,10,13,39,,12",
 "308200,2,2,1998/10/01 12:57:00,139.3667298887,35.5610911586,2,12,2924401,14,7 ,33,,6",
 "338100,4,4,1998/10/01 12:57:00,139.5284875639,35.6238393953,2,6,2132009,14,3 ,31,,1",
 "374900,6,6,1998/10/01 12:57:00,139.7225534823,35.6842326601,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:57:00,139.62197724,35.9150057109,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,3,1998/10/01 12:57:00,139.5942114774,35.690449696,2,6,0513201,14,7 ,39,,12",
 "726800,4,4,1998/10/01 12:57:00,139.4316126352,35.7076919748,1,10,0761308,15,3 ,34,,6",
 "731400,2,2,1998/10/01 12:57:00,139.4731620228,35.6613192756,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:57:00,139.3529727355,35.6624662087,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 12:57:00,139.6679565143,35.673918106,1,11,0634016,15,7 ,48,,12",
 "154100,4,6,1998/10/01 12:58:00,140.1402256875,35.6301791595,2,10,4040012,14,3 ,41,,6",
 "848700,2,2,1998/10/01 12:58:00,139.6301461214,35.5376004906,1,4,1313101,13,2 ,37,,2",
 "80500,6,8,1998/10/01 12:58:00,140.093159437,35.6958159833,1,14,4413233,5 ,10,33,,1",
 "749800,2,2,1998/10/01 12:58:00,139.4809971046,35.521195386,1,7,0810411,10,1 ,36,,6",
 "333500,2,2,1998/10/01 12:58:00,139.5633763541,35.6100184177,1,8,2130004,9 ,1 ,46,,1",
 "287500,2,2,1998/10/01 12:58:00,139.4279959019,35.4843141571,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 12:58:00,139.7411523634,35.7469754796,1,10,3037010,10,13,39,,12",
 "308200,2,2,1998/10/01 12:58:00,139.3678901659,35.5616308953,2,12,2924401,14,7 ,33,,6",
 "338100,4,4,1998/10/01 12:58:00,139.5279252432,35.6239926597,2,6,2132009,14,3 ,31,,1",
 "374900,6,6,1998/10/01 12:58:00,139.7203931804,35.684472445,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:58:00,139.6219480986,35.9151206161,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,3,1998/10/01 12:58:00,139.5990505915,35.6883044011,2,6,0513201,14,7 ,39,,12",
 "726800,4,4,1998/10/01 12:58:00,139.4326417595,35.7077988185,1,10,0761308,15,3 ,34,,6",
 "731400,2,2,1998/10/01 12:58:00,139.473148461,35.6609926057,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 12:58:00,139.3532999045,35.6624970292,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 12:58:00,139.6632217612,35.6724128313,1,11,0634016,15,7 ,48,,12",
 "154100,4,6,1998/10/01 12:59:00,140.1421515675,35.6316155748,2,10,4040012,14,3 ,41,,6",
 "848700,2,2,1998/10/01 12:59:00,139.6310473984,35.5369074269,1,4,1313101,13,2 ,37,,2",
 "80500,6,8,1998/10/01 12:59:00,140.0932780654,35.6955282646,1,14,4413233,5 ,10,33,,1",
 "749800,2,2,1998/10/01 12:59:00,139.4809972422,35.5211953762,1,7,0810411,10,1 ,36,,6",
 "333500,2,2,1998/10/01 12:59:00,139.5633658657,35.6095182549,1,8,2130004,9 ,1 ,46,,1",
 "287500,2,2,1998/10/01 12:59:00,139.4279243858,35.4847733916,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 12:59:00,139.7382131723,35.7499079771,1,10,3037010,10,13,39,,12",
 "308200,2,2,1998/10/01 12:59:00,139.3688157385,35.5628988763,2,12,2924401,14,7 ,33,,6",
 "338100,4,4,1998/10/01 12:59:00,139.5273721948,35.6241667034,2,6,2132009,14,3 ,31,,1",
 "374900,6,6,1998/10/01 12:59:00,139.7185320232,35.6843116675,1,4,3040108,4 ,9 ,71,,6",
 "487600,6,6,1998/10/01 12:59:00,139.6219189572,35.9152355213,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,3,1998/10/01 12:59:00,139.6038123548,35.686029466,2,6,0513201,14,7 ,39,,12",
 "731400,2,2,1998/10/01 12:59:00,139.4731360495,35.6606658926,2,10,0771111,14,4 ,32,,6",
 "726800,4,4,1998/10/01 12:59:00,139.4336609786,35.7079586034,1,10,0761308,15,3 ,34,,6",
 "786600,3,5,1998/10/01 12:59:00,139.3536219057,35.6625526563,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 12:59:00,139.6585349276,35.6708101416,1,11,0634016,15,7 ,48,,12",
 "154100,4,6,1998/10/01 13:00:00,140.1434370674,35.6333560085,2,10,4040012,14,3 ,41,,6",
 "848700,2,2,1998/10/01 13:00:00,139.6322566766,35.5370024585,1,4,1313101,13,2 ,37,,2",
 "80500,6,8,1998/10/01 13:00:00,140.093396693,35.6952405458,1,14,4413233,5 ,10,33,,1",
 "80500,7,9,1998/10/01 13:00:00,140.093396693,35.6952405458,1,14,4413233,5 ,97,33,,97",
 "749800,2,2,1998/10/01 13:00:00,139.4809973797,35.5211953664,1,7,0810411,10,1 ,36,,6",
 "333500,2,2,1998/10/01 13:00:00,139.5626693314,35.6093488127,1,8,2130004,9 ,1 ,46,,1",
 "333500,3,3,1998/10/01 13:00:00,139.5626693314,35.6093488127,1,8,2130004,9 ,97,46,,97",
 "287500,2,2,1998/10/01 13:00:00,139.4278528689,35.485232626,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 13:00:00,139.7375893656,35.7535511949,1,10,3037010,10,13,39,,12",
 "308200,2,2,1998/10/01 13:00:00,139.3697636122,35.5641558992,2,12,2924401,14,7 ,33,,6",
 "338100,4,4,1998/10/01 13:00:00,139.526827115,35.6243298871,2,6,2132009,14,3 ,31,,1",
 "374900,6,6,1998/10/01 13:00:00,139.7169179392,35.6841558156,1,4,3040108,4 ,9 ,71,,6",
 "374900,7,7,1998/10/01 13:00:00,139.7169179392,35.6841558156,1,4,3040108,4 ,97,71,,97",
 "487600,6,6,1998/10/01 13:00:00,139.6218898157,35.9153504265,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,3,1998/10/01 13:00:00,139.6088933591,35.6842376196,2,6,0513201,14,7 ,39,,12",
 "726800,4,4,1998/10/01 13:00:00,139.4346565835,35.7081994201,1,10,0761308,15,3 ,34,,6",
 "731400,2,2,1998/10/01 13:00:00,139.4731236381,35.6603391795,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 13:00:00,139.3539428329,35.6626130746,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:00:00,139.6538022496,35.6693020658,1,11,0634016,15,7 ,48,,12",
 "154100,4,6,1998/10/01 13:01:00,140.1439196039,35.6354523588,2,10,4040012,14,3 ,41,,6",
 "848700,2,2,1998/10/01 13:01:00,139.6334551898,35.5367332682,1,4,1313101,13,2 ,37,,2",
 "280600,9,9,1998/10/01 13:01:00,139.7356339118,35.6458227749,1,11,2910009,8 ,9 ,42,,8",
 "607200,4,6,1998/10/01 13:01:00,139.5986038114,35.7056764911,2,7,0424006,14,3 ,59,,1",
 "607200,4,7,1998/10/01 13:01:00,139.5986038114,35.7056764911,2,7,0424006,14,3 ,59,,10",
 "749800,2,2,1998/10/01 13:01:00,139.4809975173,35.5211953566,1,7,0810411,10,1 ,36,,6",
 "234600,6,8,1998/10/01 13:01:00,139.7830139895,35.6775338583,1,14,2412116,10,10,63,,1",
 "253000,2,2,1998/10/01 13:01:00,139.7729343834,35.6814663727,1,8,2520301,9 ,3 ,30,,1",
 "190900,4,4,1998/10/01 13:01:00,140.0625235248,35.8994805508,2,11,5410113,14,3 ,37,,6",
 "287500,2,2,1998/10/01 13:01:00,139.4277798026,35.485691691,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 13:01:00,139.7348381824,35.7566367987,1,10,3037010,10,13,39,,12",
 "308200,2,2,1998/10/01 13:01:00,139.3709854578,35.5644145639,2,12,2924401,14,7 ,33,,6",
 "338100,4,4,1998/10/01 13:01:00,139.5265234783,35.6239141676,2,6,2132009,14,3 ,31,,1",
 "349600,2,2,1998/10/01 13:01:00,139.7049231456,35.8549124434,2,13,3014406,14,4 ,34,,1",
 "439300,4,4,1998/10/01 13:01:00,139.809233042,35.8539711529,2,6,3213107,14,3 ,29,,2",
 "443900,2,2,1998/10/01 13:01:00,139.8788009835,35.8071823909,2,10,3215307,5 ,4 ,46,,1",
 "487600,6,6,1998/10/01 13:01:00,139.6218606741,35.9154653316,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,3,1998/10/01 13:01:00,139.6142540744,35.6833117427,2,6,0513201,14,7 ,39,,12",
 "726800,4,4,1998/10/01 13:01:00,139.4350806474,35.7086492489,1,10,0761308,15,3 ,34,,6",
 "731400,2,2,1998/10/01 13:01:00,139.4731112268,35.6600124663,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 13:01:00,139.3542637607,35.662673492,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:01:00,139.6490064426,35.6679322093,1,11,0634016,15,7 ,48,,12",
 "154100,4,6,1998/10/01 13:02:00,140.1439525375,35.6375467328,2,10,4040012,14,3 ,41,,6",
 "848700,2,2,1998/10/01 13:02:00,139.6345904615,35.5367552668,1,4,1313101,13,2 ,37,,2",
 "848700,2,3,1998/10/01 13:02:00,139.6345904615,35.5367552668,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:02:00,139.7364083799,35.6482085631,1,11,2910009,8 ,9 ,42,,8",
 "607200,4,7,1998/10/01 13:02:00,139.5986038904,35.7056765793,2,7,0424006,14,3 ,59,,10",
 "749800,2,2,1998/10/01 13:02:00,139.4809976549,35.5211953468,1,7,0810411,10,1 ,36,,6",
 "234600,6,8,1998/10/01 13:02:00,139.7826838998,35.6778003571,1,14,2412116,10,10,63,,1",
 "253000,2,2,1998/10/01 13:02:00,139.7706282504,35.6830238152,1,8,2520301,9 ,3 ,30,,1",
 "190900,4,4,1998/10/01 13:02:00,140.0599009285,35.9041871498,2,11,5410113,14,3 ,37,,6",
 "287500,2,2,1998/10/01 13:02:00,139.4281671058,35.4860015789,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 13:02:00,139.7318187467,35.7595279703,1,10,3037010,10,13,39,,12",
 "308200,2,2,1998/10/01 13:02:00,139.372158624,35.5649249935,2,12,2924401,14,7 ,33,,6",
 "338100,4,4,1998/10/01 13:02:00,139.5261744217,35.6237128161,2,6,2132009,14,3 ,31,,1",
 "349600,2,2,1998/10/01 13:02:00,139.7042800807,35.8547308491,2,13,3014406,14,4 ,34,,1",
 "439300,4,4,1998/10/01 13:02:00,139.8095647126,35.8544195207,2,6,3213107,14,3 ,29,,2",
 "443900,2,2,1998/10/01 13:02:00,139.8787150023,35.8068764521,2,10,3215307,5 ,4 ,46,,1",
 "487600,6,6,1998/10/01 13:02:00,139.6218315324,35.9155802368,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,3,1998/10/01 13:02:00,139.6197537818,35.682844869,2,6,0513201,14,7 ,39,,12",
 "726800,4,4,1998/10/01 13:02:00,139.4356298827,35.7085886192,1,10,0761308,15,3 ,34,,6",
 "731400,2,2,1998/10/01 13:02:00,139.4730988156,35.6596857531,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 13:02:00,139.354584689,35.6627339086,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:02:00,139.6442440213,35.6664870872,1,11,0634016,15,7 ,48,,12",
 "154100,4,6,1998/10/01 13:03:00,140.1450819237,35.6392384251,2,10,4040012,14,3 ,41,,6",
 "848700,2,3,1998/10/01 13:03:00,139.6375315856,35.5400427483,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:03:00,139.7366064103,35.6506649781,1,11,2910009,8 ,9 ,42,,8",
 "607200,4,7,1998/10/01 13:03:00,139.5986039695,35.7056766674,2,7,0424006,14,3 ,59,,10",
 "749800,2,2,1998/10/01 13:03:00,139.4809977924,35.521195337,1,7,0810411,10,1 ,36,,6",
 "234600,6,8,1998/10/01 13:03:00,139.7823538079,35.6780668549,1,14,2412116,10,10,63,,1",
 "253000,2,2,1998/10/01 13:03:00,139.7681160906,35.6841397412,1,8,2520301,9 ,3 ,30,,1",
 "190900,4,4,1998/10/01 13:03:00,140.054069071,35.9068459376,2,11,5410113,14,3 ,37,,6",
 "287500,2,2,1998/10/01 13:03:00,139.4286043872,35.4862955248,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 13:03:00,139.7283003967,35.7620430484,1,10,3037010,10,13,39,,12",
 "308200,2,2,1998/10/01 13:03:00,139.3731078909,35.5661811485,2,12,2924401,14,7 ,33,,6",
 "338100,4,4,1998/10/01 13:03:00,139.5257121386,35.6240158034,2,6,2132009,14,3 ,31,,1",
 "349600,2,2,1998/10/01 13:03:00,139.7036155056,35.8545992698,2,13,3014406,14,4 ,34,,1",
 "439300,4,4,1998/10/01 13:03:00,139.809896387,35.8548678876,2,6,3213107,14,3 ,29,,2",
 "443900,2,2,1998/10/01 13:03:00,139.8786290218,35.8065705132,2,10,3215307,5 ,4 ,46,,1",
 "487600,6,6,1998/10/01 13:03:00,139.6218023907,35.9156951419,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,3,1998/10/01 13:03:00,139.6251889284,35.6819946041,2,6,0513201,14,7 ,39,,12",
 "726800,4,4,1998/10/01 13:03:00,139.4361684972,35.7078694316,1,10,0761308,15,3 ,34,,6",
 "731400,2,2,1998/10/01 13:03:00,139.473356196,35.6594535213,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 13:03:00,139.3549056177,35.6627943244,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:03:00,139.6392784998,35.6664646374,1,11,0634016,15,7 ,48,,12",
 "154100,4,6,1998/10/01 13:04:00,140.1464815324,35.6410389576,2,10,4040012,14,3 ,41,,6",
 "848700,2,3,1998/10/01 13:04:00,139.6404660568,35.5434498115,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:04:00,139.7364622155,35.6531181509,1,11,2910009,8 ,9 ,42,,8",
 "607200,4,7,1998/10/01 13:04:00,139.5986040485,35.7056767555,2,7,0424006,14,3 ,59,,10",
 "749800,2,2,1998/10/01 13:04:00,139.48099793,35.5211953272,1,7,0810411,10,1 ,36,,6",
 "234600,6,8,1998/10/01 13:04:00,139.7820237138,35.6783333518,1,14,2412116,10,10,63,,1",
 "253000,2,2,1998/10/01 13:04:00,139.7663534772,35.6827577294,1,8,2520301,9 ,3 ,30,,1",
 "190900,4,4,1998/10/01 13:04:00,140.049263901,35.9109269361,2,11,5410113,14,3 ,37,,6",
 "287500,2,2,1998/10/01 13:04:00,139.4290416717,35.4865894693,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 13:04:00,139.7257856398,35.7652326244,1,10,3037010,10,13,39,,12",
 "308200,2,2,1998/10/01 13:04:00,139.3740543557,35.5674386639,2,12,2924401,14,7 ,33,,6",
 "338100,4,4,1998/10/01 13:04:00,139.5252478311,35.6243167512,2,6,2132009,14,3 ,31,,1",
 "349600,2,2,1998/10/01 13:04:00,139.7029406708,35.8545789814,2,13,3014406,14,4 ,34,,1",
 "439300,4,4,1998/10/01 13:04:00,139.8105136093,35.8549365033,2,6,3213107,14,3 ,29,,2",
 "443900,2,2,1998/10/01 13:04:00,139.8785430419,35.8062645743,2,10,3215307,5 ,4 ,46,,1",
 "487600,6,6,1998/10/01 13:04:00,139.6217690325,35.9158092742,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,3,1998/10/01 13:04:00,139.6304174956,35.6805793382,2,6,0513201,14,7 ,39,,12",
 "731400,2,2,1998/10/01 13:04:00,139.4735749737,35.6591818225,2,10,0771111,14,4 ,32,,6",
 "726800,4,4,1998/10/01 13:04:00,139.4368479325,35.707251648,1,10,0761308,15,3 ,34,,6",
 "786600,3,5,1998/10/01 13:04:00,139.3552265469,35.6628547393,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:04:00,139.6342824025,35.6672219631,1,11,0634016,15,7 ,48,,12",
 "154100,4,6,1998/10/01 13:05:00,140.1457385209,35.6420416991,2,10,4040012,14,3 ,41,,6",
 "154100,5,7,1998/10/01 13:05:00,140.1457385209,35.6420416991,2,10,4040012,14,97,41,,97",
 "848700,2,3,1998/10/01 13:05:00,139.6434007758,35.5468568021,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:05:00,139.7369330327,35.6555589539,1,11,2910009,8 ,9 ,42,,8",
 "607200,4,7,1998/10/01 13:05:00,139.5986041275,35.7056768436,2,7,0424006,14,3 ,59,,10",
 "749800,2,2,1998/10/01 13:05:00,139.4809980676,35.5211953174,1,7,0810411,10,1 ,36,,6",
 "234600,6,8,1998/10/01 13:05:00,139.7816936175,35.6785998477,1,14,2412116,10,10,63,,1",
 "253000,2,2,1998/10/01 13:05:00,139.767210733,35.6813207001,1,8,2520301,9 ,3 ,30,,1",
 "253000,2,3,1998/10/01 13:05:00,139.767210733,35.6813207001,1,8,2520301,9 ,3 ,30,,12",
 "190900,4,4,1998/10/01 13:05:00,140.0452549635,35.915503191,2,11,5410113,14,3 ,37,,6",
 "287500,2,2,1998/10/01 13:05:00,139.4294789595,35.4868834121,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 13:05:00,139.7238152328,35.7686924499,1,10,3037010,10,13,39,,12",
 "308200,2,2,1998/10/01 13:05:00,139.3747950581,35.5685889962,2,12,2924401,14,7 ,33,,6",
 "308200,3,3,1998/10/01 13:05:00,139.3747950581,35.5685889962,2,12,2924401,14,97,33,,97",
 "338100,4,4,1998/10/01 13:05:00,139.5252244867,35.6242436504,2,6,2132009,14,3 ,31,,1",
 "338100,5,5,1998/10/01 13:05:00,139.5252244867,35.6242436504,2,6,2132009,14,97,31,,97",
 "349600,2,2,1998/10/01 13:05:00,139.7026671247,35.8550087968,2,13,3014406,14,4 ,34,,1",
 "439300,4,4,1998/10/01 13:05:00,139.8111549594,35.8549730286,2,6,3213107,14,3 ,29,,2",
 "443900,2,2,1998/10/01 13:05:00,139.8784570627,35.8059586353,2,10,3215307,5 ,4 ,46,,1",
 "487600,6,6,1998/10/01 13:05:00,139.6217347761,35.9159232419,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,3,1998/10/01 13:05:00,139.6354831532,35.6787579005,2,6,0513201,14,7 ,39,,12",
 "726800,4,4,1998/10/01 13:05:00,139.4370978694,35.7068959013,1,10,0761308,15,3 ,34,,6",
 "726800,5,5,1998/10/01 13:05:00,139.4370978694,35.7068959013,1,10,0761308,15,97,34,,97",
 "731400,2,2,1998/10/01 13:05:00,139.4738359537,35.6590390353,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 13:05:00,139.3554341238,35.6628857963,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:05:00,139.6292859701,35.6679765694,1,11,0634016,15,7 ,48,,12",
 "848700,2,3,1998/10/01 13:06:00,139.6457874961,35.5505055066,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:06:00,139.7362482864,35.6579223956,1,11,2910009,8 ,9 ,42,,8",
 "607200,4,7,1998/10/01 13:06:00,139.5986042066,35.7056769317,2,7,0424006,14,3 ,59,,10",
 "749800,2,2,1998/10/01 13:06:00,139.4809982052,35.5211953076,1,7,0810411,10,1 ,36,,6",
 "234600,6,8,1998/10/01 13:06:00,139.7813635191,35.6788663428,1,14,2412116,10,10,63,,1",
 "253000,2,3,1998/10/01 13:06:00,139.7621023289,35.674298455,1,8,2520301,9 ,3 ,30,,12",
 "190900,4,4,1998/10/01 13:06:00,140.0395442575,35.9186453439,2,11,5410113,14,3 ,37,,6",
 "213900,2,2,1998/10/01 13:06:00,139.6910375064,35.2346089586,2,4,2220414,13,6 ,38,,1",
 "287500,2,2,1998/10/01 13:06:00,139.4299162505,35.4871773534,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 13:06:00,139.72225395,35.7722547242,1,10,3037010,10,13,39,,12",
 "349600,2,2,1998/10/01 13:06:00,139.7025449635,35.8555563982,2,13,3014406,14,4 ,34,,1",
 "439300,4,4,1998/10/01 13:06:00,139.8117894766,35.855051279,2,6,3213107,14,3 ,29,,2",
 "443900,2,2,1998/10/01 13:06:00,139.8783346997,35.805727536,2,10,3215307,5 ,4 ,46,,1",
 "487600,6,6,1998/10/01 13:06:00,139.6216987932,35.9160368569,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,3,1998/10/01 13:06:00,139.6405854519,35.6770059873,2,6,0513201,14,7 ,39,,12",
 "731400,2,2,1998/10/01 13:06:00,139.4742062342,35.6591636327,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 13:06:00,139.3555480328,35.6629274156,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:06:00,139.6242788572,35.6686825405,1,11,0634016,15,7 ,48,,12",
 "848700,2,3,1998/10/01 13:07:00,139.647250638,35.5544924154,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:07:00,139.7369939697,35.6602511239,1,11,2910009,8 ,9 ,42,,8",
 "607200,4,7,1998/10/01 13:07:00,139.5986042856,35.7056770198,2,7,0424006,14,3 ,59,,10",
 "749800,2,2,1998/10/01 13:07:00,139.4809983427,35.5211952978,1,7,0810411,10,1 ,36,,6",
 "234600,6,8,1998/10/01 13:07:00,139.7810334184,35.6791328369,1,14,2412116,10,10,63,,1",
 "253000,2,3,1998/10/01 13:07:00,139.7583744457,35.6647703912,1,8,2520301,9 ,3 ,30,,12",
 "190900,4,4,1998/10/01 13:07:00,140.0335096432,35.9214574266,2,11,5410113,14,3 ,37,,6",
 "213900,2,2,1998/10/01 13:07:00,139.6919661904,35.2350584884,2,4,2220414,13,6 ,38,,1",
 "287500,2,2,1998/10/01 13:07:00,139.4298590373,35.4875624425,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 13:07:00,139.7219837074,35.7760567254,1,10,3037010,10,13,39,,12",
 "349600,2,2,1998/10/01 13:07:00,139.7024309219,35.8561003945,2,13,3014406,14,4 ,34,,1",
 "439300,4,4,1998/10/01 13:07:00,139.8124201818,35.8551528135,2,6,3213107,14,3 ,29,,2",
 "443900,2,2,1998/10/01 13:07:00,139.8783347248,35.8054136824,2,10,3215307,5 ,4 ,46,,1",
 "487600,6,6,1998/10/01 13:07:00,139.6216628047,35.9161504708,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,3,1998/10/01 13:07:00,139.6453604366,35.6747760057,2,6,0513201,14,7 ,39,,12",
 "731400,2,2,1998/10/01 13:07:00,139.4745879832,35.6592165537,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 13:07:00,139.355850608,35.6630335833,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:07:00,139.6192841866,35.6694374633,1,11,0634016,15,7 ,48,,12",
 "848700,2,3,1998/10/01 13:08:00,139.6497118128,35.5580311095,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:08:00,139.7380824345,35.6625407712,1,11,2910009,8 ,9 ,42,,8",
 "607200,4,7,1998/10/01 13:08:00,139.5986043647,35.7056771079,2,7,0424006,14,3 ,59,,10",
 "749800,2,2,1998/10/01 13:08:00,139.4809984803,35.521195288,1,7,0810411,10,1 ,36,,6",
 "234600,6,8,1998/10/01 13:08:00,139.7807033156,35.6793993302,1,14,2412116,10,10,63,,1",
 "253000,2,3,1998/10/01 13:08:00,139.7569667833,35.6547106587,1,8,2520301,9 ,3 ,30,,12",
 "190900,4,4,1998/10/01 13:08:00,140.0273388052,35.9240700546,2,11,5410113,14,3 ,37,,6",
 "213900,2,2,1998/10/01 13:08:00,139.6928948845,35.235508011,2,4,2220414,13,6 ,38,,1",
 "287500,2,2,1998/10/01 13:08:00,139.4296181742,35.4879813816,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 13:08:00,139.7199206935,35.7794725028,1,10,3037010,10,13,39,,12",
 "349600,2,2,1998/10/01 13:08:00,139.7022752922,35.856639766,2,13,3014406,14,4 ,34,,1",
 "439300,4,4,1998/10/01 13:08:00,139.8130508885,35.8552543447,2,6,3213107,14,3 ,29,,2",
 "443900,2,2,1998/10/01 13:08:00,139.8783347498,35.8050998288,2,10,3215307,5 ,4 ,46,,1",
 "487600,6,6,1998/10/01 13:08:00,139.6216268162,35.9162640847,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,3,1998/10/01 13:08:00,139.6495438776,35.6718203583,2,6,0513201,14,7 ,39,,12",
 "731400,2,2,1998/10/01 13:08:00,139.4749131213,35.6590331558,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 13:08:00,139.3561531839,35.6631397503,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:08:00,139.6142744692,35.6701282953,1,11,0634016,15,7 ,48,,12",
 "848700,2,3,1998/10/01 13:09:00,139.6525924904,35.5614432215,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:09:00,139.7385087952,35.664986749,1,11,2910009,8 ,9 ,42,,8",
 "607200,4,7,1998/10/01 13:09:00,139.5986044437,35.705677196,2,7,0424006,14,3 ,59,,10",
 "749800,2,2,1998/10/01 13:09:00,139.4809986179,35.5211952783,1,7,0810411,10,1 ,36,,6",
 "234600,6,8,1998/10/01 13:09:00,139.7803732105,35.6796658225,1,14,2412116,10,10,63,,1",
 "253000,2,3,1998/10/01 13:09:00,139.7499615818,35.6469068625,1,8,2520301,9 ,3 ,30,,12",
 "190900,4,4,1998/10/01 13:09:00,140.0209739671,35.9263546016,2,11,5410113,14,3 ,37,,6",
 "213900,2,2,1998/10/01 13:09:00,139.6936259821,35.2352617384,2,4,2220414,13,6 ,38,,1",
 "287500,2,2,1998/10/01 13:09:00,139.4293773085,35.4884003202,1,13,2912305,15,7 ,31,,4",
 "370300,4,7,1998/10/01 13:09:00,139.7180844529,35.782976958,1,10,3037010,10,13,39,,12",
 "349600,2,2,1998/10/01 13:09:00,139.7017410507,35.8568081654,2,13,3014406,14,4 ,34,,1",
 "439300,4,4,1998/10/01 13:09:00,139.813621397,35.8552656006,2,6,3213107,14,3 ,29,,2",
 "443900,2,2,1998/10/01 13:09:00,139.8783360238,35.8047859806,2,10,3215307,5 ,4 ,46,,1",
 "487600,6,6,1998/10/01 13:09:00,139.6215908275,35.9163776986,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,3,1998/10/01 13:09:00,139.6507975387,35.6674895635,2,6,0513201,14,7 ,39,,12",
 "731400,2,2,1998/10/01 13:09:00,139.4752173267,35.6588205349,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 13:09:00,139.3564557607,35.6632459164,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:09:00,139.6092591141,35.6707177362,1,11,0634016,15,7 ,48,,12",
 "848700,2,3,1998/10/01 13:10:00,139.654490019,35.5653094589,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:10:00,139.7390661521,35.6673918174,1,11,2910009,8 ,9 ,42,,8",
 "607200,4,7,1998/10/01 13:10:00,139.5986045227,35.7056772842,2,7,0424006,14,3 ,59,,10",
 "749800,2,2,1998/10/01 13:10:00,139.4809987554,35.5211952685,1,7,0810411,10,1 ,36,,6",
 "234600,6,8,1998/10/01 13:10:00,139.7800431033,35.6799323139,1,14,2412116,10,10,63,,1",
 "234600,6,9,1998/10/01 13:10:00,139.7800431033,35.6799323139,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:10:00,139.742616267,35.6392356667,1,8,2520301,9 ,3 ,30,,12",
 "190900,4,4,1998/10/01 13:10:00,140.0146158695,35.9286156341,2,11,5410113,14,3 ,37,,6",
 "213900,2,2,1998/10/01 13:10:00,139.693981693,35.2344777263,2,4,2220414,13,6 ,38,,1",
 "287500,2,2,1998/10/01 13:10:00,139.4291888918,35.4886894792,1,13,2912305,15,7 ,31,,4",
 "287500,3,3,1998/10/01 13:10:00,139.4291888918,35.4886894792,1,13,2912305,15,97,31,,97",
 "370300,4,7,1998/10/01 13:10:00,139.7169884503,35.7866443039,1,10,3037010,10,13,39,,12",
 "349600,2,2,1998/10/01 13:10:00,139.7010612042,35.85686918,2,13,3014406,14,4 ,34,,1",
 "439300,4,4,1998/10/01 13:10:00,139.8138603714,35.8547797076,2,6,3213107,14,3 ,29,,2",
 "439300,5,5,1998/10/01 13:10:00,139.8138603714,35.8547797076,2,6,3213107,14,97,29,,97",
 "443900,2,2,1998/10/01 13:10:00,139.8783401327,35.8044721448,2,10,3215307,5 ,4 ,46,,1",
 "487600,6,6,1998/10/01 13:10:00,139.6215548388,35.9164913125,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,3,1998/10/01 13:10:00,139.6533869219,35.6635226833,2,6,0513201,14,7 ,39,,12",
 "731400,2,2,1998/10/01 13:10:00,139.4755338251,35.6586256695,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 13:10:00,139.3567583384,35.6633520819,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:10:00,139.6044987789,35.6693163461,1,11,0634016,15,7 ,48,,12",
 "848700,2,3,1998/10/01 13:11:00,139.656392605,35.5691751689,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:11:00,139.740995355,35.6692967925,1,11,2910009,8 ,9 ,42,,8",
 "607200,4,7,1998/10/01 13:11:00,139.5986046018,35.7056773723,2,7,0424006,14,3 ,59,,10",
 "749800,2,2,1998/10/01 13:11:00,139.480998893,35.5211952587,1,7,0810411,10,1 ,36,,6",
 "234600,6,9,1998/10/01 13:11:00,139.7764430442,35.6814739105,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:11:00,139.739592594,35.6294111032,1,8,2520301,9 ,3 ,30,,12",
 "190900,4,4,1998/10/01 13:11:00,140.008459316,35.9312140006,2,11,5410113,14,3 ,37,,6",
 "213900,2,2,1998/10/01 13:11:00,139.6946644867,35.2339991122,2,4,2220414,13,6 ,38,,1",
 "370300,4,7,1998/10/01 13:11:00,139.7173850101,35.7904449697,1,10,3037010,10,13,39,,12",
 "349600,2,2,1998/10/01 13:11:00,139.7004007861,35.8570084463,2,13,3014406,14,4 ,34,,1",
 "443900,2,2,1998/10/01 13:11:00,139.8783442416,35.804158309,2,10,3215307,5 ,4 ,46,,1",
 "487600,6,6,1998/10/01 13:11:00,139.6215188499,35.9166049263,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,3,1998/10/01 13:11:00,139.6586314204,35.6625911328,2,6,0513201,14,7 ,39,,12",
 "731400,2,2,1998/10/01 13:11:00,139.4759139474,35.6585226923,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 13:11:00,139.3570607394,35.6634585801,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:11:00,139.599851001,35.6676408671,1,11,0634016,15,7 ,48,,12",
 "848700,2,3,1998/10/01 13:12:00,139.6582903903,35.5730424888,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:12:00,139.7429041773,35.6712154227,1,11,2910009,8 ,9 ,42,,8",
 "607200,4,7,1998/10/01 13:12:00,139.5986046808,35.7056774604,2,7,0424006,14,3 ,59,,10",
 "749800,2,2,1998/10/01 13:12:00,139.4809990306,35.5211952489,1,7,0810411,10,1 ,36,,6",
 "234600,6,9,1998/10/01 13:12:00,139.7727141535,35.6829619344,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:12:00,139.7373026061,35.6193904781,1,8,2520301,9 ,3 ,30,,12",
 "190900,4,4,1998/10/01 13:12:00,140.0022194993,35.9336474423,2,11,5410113,14,3 ,37,,6",
 "213900,2,2,1998/10/01 13:12:00,139.6950299502,35.233167154,2,4,2220414,13,6 ,38,,1",
 "370300,4,7,1998/10/01 13:12:00,139.7177978054,35.7942443043,1,10,3037010,10,13,39,,12",
 "349600,2,2,1998/10/01 13:12:00,139.7000174924,35.8574284579,2,13,3014406,14,4 ,34,,1",
 "443900,2,2,1998/10/01 13:12:00,139.8784502037,35.8039028018,2,10,3215307,5 ,4 ,46,,1",
 "487600,6,6,1998/10/01 13:12:00,139.621482861,35.9167185402,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,3,1998/10/01 13:12:00,139.6640953263,35.662041193,2,6,0513201,14,7 ,39,,12",
 "731400,2,2,1998/10/01 13:12:00,139.4762974751,35.658428584,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 13:12:00,139.3573624107,35.6635664509,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:12:00,139.5951987927,35.6659726561,1,11,0634016,15,7 ,48,,12",
 "848700,2,3,1998/10/01 13:13:00,139.6601883244,35.5769097878,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:13:00,139.7455375574,35.6722684731,1,11,2910009,8 ,9 ,42,,8",
 "607200,4,7,1998/10/01 13:13:00,139.5986047599,35.7056775485,2,7,0424006,14,3 ,59,,10",
 "749800,2,2,1998/10/01 13:13:00,139.4809991682,35.5211952391,1,7,0810411,10,1 ,36,,6",
 "234600,6,9,1998/10/01 13:13:00,139.7688175402,35.6840924073,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:13:00,139.7353031943,35.6093394357,1,8,2520301,9 ,3 ,30,,12",
 "190900,4,4,1998/10/01 13:13:00,139.9968573558,35.9367769869,2,11,5410113,14,3 ,37,,6",
 "213900,2,2,1998/10/01 13:13:00,139.6955944606,35.2324324534,2,4,2220414,13,6 ,38,,1",
 "370300,4,7,1998/10/01 13:13:00,139.7182505396,35.7980365784,1,10,3037010,10,13,39,,12",
 "349600,2,2,1998/10/01 13:13:00,139.6998643651,35.8579563324,2,13,3014406,14,4 ,34,,1",
 "443900,2,2,1998/10/01 13:13:00,139.8788113228,35.8037934156,2,10,3215307,5 ,4 ,46,,1",
 "487600,6,6,1998/10/01 13:13:00,139.6214468719,35.916832154,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,3,1998/10/01 13:13:00,139.6695083045,35.6610987083,2,6,0513201,14,7 ,39,,12",
 "731400,2,2,1998/10/01 13:13:00,139.4766816184,35.6583360798,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 13:13:00,139.3576640829,35.6636743209,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:13:00,139.5905471307,35.6643036107,1,11,0634016,15,7 ,48,,12",
 "848700,2,3,1998/10/01 13:14:00,139.6620835835,35.580777978,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:14:00,139.7463302137,35.6741325664,1,11,2910009,8 ,9 ,42,,8",
 "607200,4,7,1998/10/01 13:14:00,139.5986048389,35.7056776366,2,7,0424006,14,3 ,59,,10",
 "607200,4,8,1998/10/01 13:14:00,139.5986048389,35.7056776366,2,7,0424006,14,3 ,59,,1",
 "749800,2,2,1998/10/01 13:14:00,139.4809993057,35.5211952293,1,7,0810411,10,1 ,36,,6",
 "234600,6,9,1998/10/01 13:14:00,139.7648036503,35.684956881,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:14:00,139.7340349858,35.5991977165,1,8,2520301,9 ,3 ,30,,12",
 "190900,4,4,1998/10/01 13:14:00,139.9943565367,35.9420298177,2,11,5410113,14,3 ,37,,6",
 "213900,2,2,1998/10/01 13:14:00,139.6965616025,35.232101586,2,4,2220414,13,6 ,38,,1",
 "370300,4,7,1998/10/01 13:14:00,139.7175060072,35.8017664927,1,10,3037010,10,13,39,,12",
 "349600,2,2,1998/10/01 13:14:00,139.6998967252,35.8584978096,2,13,3014406,14,4 ,34,,1",
 "443900,2,2,1998/10/01 13:14:00,139.8791724409,35.8036840283,2,10,3215307,5 ,4 ,46,,1",
 "487600,6,6,1998/10/01 13:14:00,139.6214108827,35.9169457678,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,3,1998/10/01 13:14:00,139.6749066511,35.6601013971,2,6,0513201,14,7 ,39,,12",
 "731400,2,2,1998/10/01 13:14:00,139.4770657608,35.6582435743,2,10,0771111,14,4 ,32,,6",
 "786600,3,5,1998/10/01 13:14:00,139.357965756,35.6637821902,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:14:00,139.5859038964,35.6626191987,1,11,0634016,15,7 ,48,,12",
 "848700,2,3,1998/10/01 13:15:00,139.6641943057,35.584554269,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:15:00,139.7468375322,35.6761350114,1,11,2910009,8 ,9 ,42,,8",
 "607200,4,8,1998/10/01 13:15:00,139.6088791587,35.7171311386,2,7,0424006,14,3 ,59,,1",
 "607200,5,9,1998/10/01 13:15:00,139.6088791587,35.7171311386,2,7,0424006,14,97,59,,97",
 "749800,2,2,1998/10/01 13:15:00,139.4809994433,35.5211952195,1,7,0810411,10,1 ,36,,6",
 "234600,6,9,1998/10/01 13:15:00,139.7612750304,35.686389812,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:15:00,139.7288074921,35.5899992668,1,8,2520301,9 ,3 ,30,,12",
 "190900,4,4,1998/10/01 13:15:00,139.991839832,35.9473062026,2,11,5410113,14,3 ,37,,6",
 "213900,2,2,1998/10/01 13:15:00,139.6976381738,35.2320529223,2,4,2220414,13,6 ,38,,1",
 "370300,4,7,1998/10/01 13:15:00,139.7148594705,35.804898196,1,10,3037010,10,13,39,,12",
 "349600,2,2,1998/10/01 13:15:00,139.6995898553,35.8589828292,2,13,3014406,14,4 ,34,,1",
 "443900,2,2,1998/10/01 13:15:00,139.8795335581,35.80357464,2,10,3215307,5 ,4 ,46,,1",
 "443900,3,3,1998/10/01 13:15:00,139.8795335581,35.80357464,2,10,3215307,5 ,97,46,,97",
 "487600,6,6,1998/10/01 13:15:00,139.6213748934,35.9170593816,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,3,1998/10/01 13:15:00,139.6803589877,35.6593522828,2,6,0513201,14,7 ,39,,12",
 "731400,2,2,1998/10/01 13:15:00,139.4774499023,35.6581510675,2,10,0771111,14,4 ,32,,6",
 "731400,3,3,1998/10/01 13:15:00,139.4774499023,35.6581510675,2,10,0771111,14,97,32,,97",
 "786600,3,5,1998/10/01 13:15:00,139.3582674298,35.6638900587,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:15:00,139.5812904708,35.6608836871,1,11,0634016,15,7 ,48,,12",
 "848700,2,3,1998/10/01 13:16:00,139.6674735061,35.5876778994,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:16:00,139.7464128303,35.6785464022,1,11,2910009,8 ,9 ,42,,8",
 "749800,2,2,1998/10/01 13:16:00,139.4809995809,35.5211952097,1,7,0810411,10,1 ,36,,6",
 "749800,2,3,1998/10/01 13:16:00,139.4809995809,35.5211952097,1,7,0810411,10,1 ,36,,1",
 "234600,6,9,1998/10/01 13:16:00,139.75999258,35.6895490587,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:16:00,139.7236704817,35.5807386217,1,8,2520301,9 ,3 ,30,,12",
 "190900,4,4,1998/10/01 13:16:00,139.9866034266,35.9496319284,2,11,5410113,14,3 ,37,,6",
 "213900,2,2,1998/10/01 13:16:00,139.6986306752,35.2318282216,2,4,2220414,13,6 ,38,,1",
 "370300,4,7,1998/10/01 13:16:00,139.7118947982,35.8078507324,1,10,3037010,10,13,39,,12",
 "349600,2,2,1998/10/01 13:16:00,139.699269831,35.8594748048,2,13,3014406,14,4 ,34,,1",
 "487600,6,6,1998/10/01 13:16:00,139.6213389041,35.9171729954,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,3,1998/10/01 13:16:00,139.6857177164,35.658260575,2,6,0513201,14,7 ,39,,12",
 "786600,3,5,1998/10/01 13:16:00,139.3585691044,35.6639979265,2,3,0930110,13,2 ,35,,9",
 "855600,4,4,1998/10/01 13:16:00,139.5412016611,35.5660006814,2,14,1341201,15,3 ,47,,6",
 "685400,4,5,1998/10/01 13:16:00,139.5768940494,35.6588053896,1,11,0634016,15,7 ,48,,12",
 "848700,2,3,1998/10/01 13:17:00,139.6681766854,35.5915648856,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:17:00,139.7454599649,35.6808291487,1,11,2910009,8 ,9 ,42,,8",
 "749800,2,3,1998/10/01 13:17:00,139.4848594611,35.5198400449,1,7,0810411,10,1 ,36,,1",
 "234600,6,9,1998/10/01 13:17:00,139.7562833746,35.6909037199,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:17:00,139.7196329125,35.5710925957,1,8,2520301,9 ,3 ,30,,12",
 "190900,4,4,1998/10/01 13:17:00,139.9806363083,35.9522276544,2,11,5410113,14,3 ,37,,6",
 "213900,2,2,1998/10/01 13:17:00,139.6990900833,35.2310298577,2,4,2220414,13,6 ,38,,1",
 "370300,4,7,1998/10/01 13:17:00,139.7089418496,35.8108110395,1,10,3037010,10,13,39,,12",
 "349600,2,2,1998/10/01 13:17:00,139.6990021772,35.8599867442,2,13,3014406,14,4 ,34,,1",
 "487600,6,6,1998/10/01 13:17:00,139.6212507808,35.917201479,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,3,1998/10/01 13:17:00,139.6909406942,35.6570918674,2,6,0513201,14,7 ,39,,12",
 "786600,3,5,1998/10/01 13:17:00,139.3588707799,35.6641057936,2,3,0930110,13,2 ,35,,9",
 "855600,4,4,1998/10/01 13:17:00,139.5403888822,35.5648805988,2,14,1341201,15,3 ,47,,6",
 "685400,4,5,1998/10/01 13:17:00,139.5724750562,35.6567591811,1,11,0634016,15,7 ,48,,12",
 "848700,2,3,1998/10/01 13:18:00,139.6673925103,35.5956743897,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:18:00,139.7443727384,35.6831272343,1,11,2910009,8 ,9 ,42,,8",
 "749800,2,3,1998/10/01 13:18:00,139.4898134128,35.5236463087,1,7,0810411,10,1 ,36,,1",
 "234600,6,9,1998/10/01 13:18:00,139.7536202654,35.6934609025,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:18:00,139.7155747318,35.56145294,1,8,2520301,9 ,3 ,30,,12",
 "190900,4,4,1998/10/01 13:18:00,139.9751446971,35.9552395621,2,11,5410113,14,3 ,37,,6",
 "213900,2,2,1998/10/01 13:18:00,139.6997656317,35.2303423276,2,4,2220414,13,6 ,38,,1",
 "370300,4,7,1998/10/01 13:18:00,139.7060078527,35.813783943,1,10,3037010,10,13,39,,12",
 "349600,2,2,1998/10/01 13:18:00,139.6987478528,35.8605036204,2,13,3014406,14,4 ,34,,1",
 "487600,6,6,1998/10/01 13:18:00,139.6211176145,35.9171564108,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,3,1998/10/01 13:18:00,139.6963563682,35.6578227441,2,6,0513201,14,7 ,39,,12",
 "786600,3,5,1998/10/01 13:18:00,139.3591724562,35.6642136598,2,3,0930110,13,2 ,35,,9",
 "855600,4,4,1998/10/01 13:18:00,139.5408968416,35.5635534808,2,14,1341201,15,3 ,47,,6",
 "685400,4,5,1998/10/01 13:18:00,139.5680792368,35.6546797243,1,11,0634016,15,7 ,48,,12",
 "848700,2,3,1998/10/01 13:19:00,139.6674069437,35.5998363761,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:19:00,139.7447223387,35.6855631945,1,11,2910009,8 ,9 ,42,,8",
 "749800,2,3,1998/10/01 13:19:00,139.4952130309,35.5269315627,1,7,0810411,10,1 ,36,,1",
 "234600,6,9,1998/10/01 13:19:00,139.7513214474,35.6960563083,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:19:00,139.7115395488,35.5518064019,1,8,2520301,9 ,3 ,30,,12",
 "190900,4,4,1998/10/01 13:19:00,139.9696163492,35.9586468575,2,11,5410113,14,3 ,37,,6",
 "213900,2,2,1998/10/01 13:19:00,139.7006357484,35.2298712976,2,4,2220414,13,6 ,38,,1",
 "370300,4,7,1998/10/01 13:19:00,139.7030562449,35.8167447104,1,10,3037010,10,13,39,,12",
 "349600,2,2,1998/10/01 13:19:00,139.6984928398,35.861020272,2,13,3014406,14,4 ,34,,1",
 "487600,6,6,1998/10/01 13:19:00,139.6209844483,35.9171113423,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,3,1998/10/01 13:19:00,139.7017727956,35.658516117,2,6,0513201,14,7 ,39,,12",
 "632500,2,4,1998/10/01 13:19:00,139.7017727956,35.658516117,2,6,0513201,14,7 ,39,,1",
 "786600,3,5,1998/10/01 13:19:00,139.3594761373,35.6643176894,2,3,0930110,13,2 ,35,,9",
 "855600,4,4,1998/10/01 13:19:00,139.5411426438,35.5622425749,2,14,1341201,15,3 ,47,,6",
 "685400,4,5,1998/10/01 13:19:00,139.5636640794,35.6526279858,1,11,0634016,15,7 ,48,,12",
 "848700,2,3,1998/10/01 13:20:00,139.6677907851,35.6039897565,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:20:00,139.7452395466,35.6879879503,1,11,2910009,8 ,9 ,42,,8",
 "749800,2,3,1998/10/01 13:20:00,139.5013043492,35.5291553445,1,7,0810411,10,1 ,36,,1",
 "234600,6,9,1998/10/01 13:20:00,139.7493830721,35.6989766434,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:20:00,139.7075593294,35.5421624885,1,8,2520301,9 ,3 ,30,,12",
 "190900,4,4,1998/10/01 13:20:00,139.9653573151,35.9622777835,2,11,5410113,14,3 ,37,,6",
 "190900,5,5,1998/10/01 13:20:00,139.9653573151,35.9622777835,2,11,5410113,14,97,37,,97",
 "213900,2,2,1998/10/01 13:20:00,139.7017116416,35.2298172615,2,4,2220414,13,6 ,38,,1",
 "370300,4,7,1998/10/01 13:20:00,139.7000980395,35.8197017069,1,10,3037010,10,13,39,,12",
 "349600,2,2,1998/10/01 13:20:00,139.6981281435,35.8614691623,2,13,3014406,14,4 ,34,,1",
 "349600,3,3,1998/10/01 13:20:00,139.6981281435,35.8614691623,2,13,3014406,14,97,34,,97",
 "487600,6,6,1998/10/01 13:20:00,139.6208538432,35.917061778,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,4,1998/10/01 13:20:00,139.7023214466,35.6581766167,2,6,0513201,14,7 ,39,,1",
 "786600,3,5,1998/10/01 13:20:00,139.3597803864,35.6644206324,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:20:00,139.5592651678,35.6505533283,1,11,0634016,15,7 ,48,,12",
 "855600,4,4,1998/10/01 13:20:00,139.5417945816,35.5608815441,2,14,1341201,15,3 ,47,,6",
 "848700,2,3,1998/10/01 13:21:00,139.6690530912,35.6079889216,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:21:00,139.7457636146,35.690398618,1,11,2910009,8 ,9 ,42,,8",
 "749800,2,3,1998/10/01 13:21:00,139.5073216537,35.5278995113,1,7,0810411,10,1 ,36,,1",
 "234600,6,9,1998/10/01 13:21:00,139.7465907919,35.7014412881,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:21:00,139.7001762655,35.5339904888,1,8,2520301,9 ,3 ,30,,12",
 "213900,2,2,1998/10/01 13:21:00,139.7025479798,35.2299209233,2,4,2220414,13,6 ,38,,1",
 "370300,4,7,1998/10/01 13:21:00,139.6967845991,35.8223866783,1,10,3037010,10,13,39,,12",
 "374900,8,8,1998/10/01 13:21:00,139.7170170904,35.6870553423,1,4,3040108,4 ,9 ,71,,6",
 "455400,4,4,1998/10/01 13:21:00,139.8325730284,35.8915908318,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:21:00,139.6207254828,35.9170082731,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,4,1998/10/01 13:21:00,139.7022505362,35.6579113887,2,6,0513201,14,7 ,39,,1",
 "786600,3,5,1998/10/01 13:21:00,139.3600845362,35.6645237641,2,3,0930110,13,2 ,35,,9",
 "855600,4,4,1998/10/01 13:21:00,139.5424487021,35.5595398894,2,14,1341201,15,3 ,47,,6",
 "878600,2,2,1998/10/01 13:21:00,139.5426023897,35.4221744433,2,8,1221105,14,4 ,43,,6",
 "685400,4,5,1998/10/01 13:21:00,139.5544625833,35.6496659133,1,11,0634016,15,7 ,48,,12",
 "848700,2,3,1998/10/01 13:22:00,139.6711985221,35.6117692093,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:22:00,139.7462571598,35.6928365803,1,11,2910009,8 ,9 ,42,,8",
 "749800,2,3,1998/10/01 13:22:00,139.5124323009,35.5245569448,1,7,0810411,10,1 ,36,,1",
 "234600,6,9,1998/10/01 13:22:00,139.7430520563,35.7027636778,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:22:00,139.6914374527,35.5267257475,1,8,2520301,9 ,3 ,30,,12",
 "213900,2,2,1998/10/01 13:22:00,139.7029763956,35.2305975487,2,4,2220414,13,6 ,38,,1",
 "213900,3,3,1998/10/01 13:22:00,139.7029763956,35.2305975487,2,4,2220414,13,97,38,,97",
 "370300,4,7,1998/10/01 13:22:00,139.693343928,35.8249719083,1,10,3037010,10,13,39,,12",
 "374900,8,8,1998/10/01 13:22:00,139.7155951883,35.6889199402,1,4,3040108,4 ,9 ,71,,6",
 "455400,4,4,1998/10/01 13:22:00,139.8326997545,35.8920382594,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:22:00,139.6205991252,35.9169517044,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,4,1998/10/01 13:22:00,139.7016105975,35.657704055,2,6,0513201,14,7 ,39,,1",
 "786600,3,5,1998/10/01 13:22:00,139.3603850653,35.6646337453,2,3,0930110,13,2 ,35,,9",
 "855600,4,4,1998/10/01 13:22:00,139.5435966041,35.5581205193,2,14,1341201,15,3 ,47,,6",
 "878600,2,2,1998/10/01 13:22:00,139.5445016702,35.4224749299,2,8,1221105,14,4 ,43,,6",
 "685400,4,5,1998/10/01 13:22:00,139.5494866535,35.6503696447,1,11,0634016,15,7 ,48,,12",
 "848700,2,3,1998/10/01 13:23:00,139.6740324583,35.6152189795,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:23:00,139.7475960159,35.6947114881,1,11,2910009,8 ,9 ,42,,8",
 "749800,2,3,1998/10/01 13:23:00,139.5189930639,35.5236308565,1,7,0810411,10,1 ,36,,1",
 "234600,6,9,1998/10/01 13:23:00,139.7391395164,35.7030513164,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:23:00,139.6827436964,35.5194170974,1,8,2520301,9 ,3 ,30,,12",
 "370300,4,7,1998/10/01 13:23:00,139.6903837126,35.8279273168,1,10,3037010,10,13,39,,12",
 "374900,8,8,1998/10/01 13:23:00,139.7143149434,35.6915149605,1,4,3040108,4 ,9 ,71,,6",
 "455400,4,4,1998/10/01 13:23:00,139.8314626509,35.8927535924,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:23:00,139.6204728196,35.9168950563,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,4,1998/10/01 13:23:00,139.7009721403,35.657493776,2,6,0513201,14,7 ,39,,1",
 "786600,3,5,1998/10/01 13:23:00,139.3606855952,35.6647437258,2,3,0930110,13,2 ,35,,9",
 "855600,4,4,1998/10/01 13:23:00,139.5447887182,35.5567254629,2,14,1341201,15,3 ,47,,6",
 "878600,2,2,1998/10/01 13:23:00,139.5447423864,35.4239981794,2,8,1221105,14,4 ,43,,6",
 "685400,4,5,1998/10/01 13:23:00,139.5447176193,35.6517989757,1,11,0634016,15,7 ,48,,12",
 "848700,2,3,1998/10/01 13:24:00,139.6770990132,35.618549156,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:24:00,139.7504959844,35.6954077522,1,11,2910009,8 ,9 ,42,,8",
 "749800,2,3,1998/10/01 13:24:00,139.525078425,35.5218948399,1,7,0810411,10,1 ,36,,1",
 "234600,6,9,1998/10/01 13:24:00,139.7361934957,35.7013702281,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:24:00,139.6767592954,35.5106636086,1,8,2520301,9 ,3 ,30,,12",
 "370300,4,7,1998/10/01 13:24:00,139.6873589082,35.8308396911,1,10,3037010,10,13,39,,12",
 "374900,8,8,1998/10/01 13:24:00,139.7136835504,35.6937963267,1,4,3040108,4 ,9 ,71,,6",
 "455400,4,4,1998/10/01 13:24:00,139.8302255251,35.8934689125,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:24:00,139.6203455229,35.9168399362,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,4,1998/10/01 13:24:00,139.7008690061,35.6570410622,2,6,0513201,14,7 ,39,,1",
 "786600,3,5,1998/10/01 13:24:00,139.360986942,35.6648522069,2,3,0930110,13,2 ,35,,9",
 "855600,4,4,1998/10/01 13:24:00,139.5456699097,35.5553710699,2,14,1341201,15,3 ,47,,6",
 "878600,2,2,1998/10/01 13:24:00,139.5446986533,35.4258424181,2,8,1221105,14,4 ,43,,6",
 "685400,4,5,1998/10/01 13:24:00,139.5399243747,35.6531735988,1,11,0634016,15,7 ,48,,12",
 "848700,2,3,1998/10/01 13:25:00,139.6801577546,35.6218841455,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:25:00,139.7521773198,35.6967165312,1,11,2910009,8 ,9 ,42,,8",
 "749800,2,3,1998/10/01 13:25:00,139.530825605,35.5189260468,1,7,0810411,10,1 ,36,,1",
 "234600,6,9,1998/10/01 13:25:00,139.7339270565,35.7039769452,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:25:00,139.6733517484,35.5010549625,1,8,2520301,9 ,3 ,30,,12",
 "370300,4,7,1998/10/01 13:25:00,139.6843875212,35.8337881543,1,10,3037010,10,13,39,,12",
 "374900,8,8,1998/10/01 13:25:00,139.713512276,35.6962065763,1,4,3040108,4 ,9 ,71,,6",
 "455400,4,4,1998/10/01 13:25:00,139.8289296586,35.8936113874,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:25:00,139.6202167478,35.9167870955,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,4,1998/10/01 13:25:00,139.701409998,35.656697569,2,6,0513201,14,7 ,39,,1",
 "786600,3,5,1998/10/01 13:25:00,139.3612883743,35.6649605318,2,3,0930110,13,2 ,35,,9",
 "855600,4,4,1998/10/01 13:25:00,139.5441662654,35.5543600981,2,14,1341201,15,3 ,47,,6",
 "878600,2,2,1998/10/01 13:25:00,139.5453845103,35.4273397965,2,8,1221105,14,4 ,43,,6",
 "685400,4,5,1998/10/01 13:25:00,139.5351885674,35.6546691344,1,11,0634016,15,7 ,48,,12",
 "848700,2,3,1998/10/01 13:26:00,139.6831462198,35.6252610729,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:26:00,139.7525833365,35.6986615303,1,11,2910009,8 ,9 ,42,,8",
 "749800,2,3,1998/10/01 13:26:00,139.5359159617,35.5153265019,1,7,0810411,10,1 ,36,,1",
 "234600,6,9,1998/10/01 13:26:00,139.7299254917,35.7047762889,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:26:00,139.6647109793,35.493708724,1,8,2520301,9 ,3 ,30,,12",
 "370300,4,7,1998/10/01 13:26:00,139.6814144613,35.8367348123,1,10,3037010,10,13,39,,12",
 "374900,8,8,1998/10/01 13:26:00,139.711799881,35.6979625478,1,4,3040108,4 ,9 ,71,,6",
 "455400,4,4,1998/10/01 13:26:00,139.8287222241,35.8948347126,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:26:00,139.6200879728,35.9167342546,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,4,1998/10/01 13:26:00,139.7014191409,35.6561821692,2,6,0513201,14,7 ,39,,1",
 "786600,3,5,1998/10/01 13:26:00,139.3615898074,35.665068856,2,3,0930110,13,2 ,35,,9",
 "855600,4,4,1998/10/01 13:26:00,139.5428420804,35.5533497019,2,14,1341201,15,3 ,47,,6",
 "878600,2,2,1998/10/01 13:26:00,139.5454143796,35.4282615112,2,8,1221105,14,4 ,43,,6",
 "685400,4,5,1998/10/01 13:26:00,139.5307842256,35.6567353924,1,11,0634016,15,7 ,48,,12",
 "848700,2,3,1998/10/01 13:27:00,139.6853740217,35.6290078083,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:27:00,139.7535451063,35.7009762841,1,11,2910009,8 ,9 ,42,,8",
 "749800,2,3,1998/10/01 13:27:00,139.5417976862,35.5131847173,1,7,0810411,10,1 ,36,,1",
 "234600,6,9,1998/10/01 13:27:00,139.7258747641,35.7051046639,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:27:00,139.6572454185,35.485955311,1,8,2520301,9 ,3 ,30,,12",
 "370300,4,7,1998/10/01 13:27:00,139.6783774332,35.8396377005,1,10,3037010,10,13,39,,12",
 "374900,8,8,1998/10/01 13:27:00,139.7096457588,35.6996271088,1,4,3040108,4 ,9 ,71,,6",
 "455400,4,4,1998/10/01 13:27:00,139.8285268766,35.8960593211,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:27:00,139.6199591979,35.9166814136,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,4,1998/10/01 13:27:00,139.7012797293,35.6556318879,2,6,0513201,14,7 ,39,,1",
 "786600,3,5,1998/10/01 13:27:00,139.3618912413,35.6651771794,2,3,0930110,13,2 ,35,,9",
 "855600,4,4,1998/10/01 13:27:00,139.5429406979,35.5519869329,2,14,1341201,15,3 ,47,,6",
 "878600,2,2,1998/10/01 13:27:00,139.5458738448,35.4302095361,2,8,1221105,14,4 ,43,,6",
 "685400,4,5,1998/10/01 13:27:00,139.5263483925,35.6587566838,1,11,0634016,15,7 ,48,,12",
 "848700,2,3,1998/10/01 13:28:00,139.6876166688,35.6327504588,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:28:00,139.755240479,35.7023449893,1,11,2910009,8 ,9 ,42,,8",
 "749800,2,3,1998/10/01 13:28:00,139.5479219556,35.5128410539,1,7,0810411,10,1 ,36,,1",
 "234600,6,9,1998/10/01 13:28:00,139.7218221952,35.7056828669,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:28:00,139.6467744977,35.485315363,1,8,2520301,9 ,3 ,30,,12",
 "370300,4,7,1998/10/01 13:28:00,139.6752983106,35.8425123219,1,10,3037010,10,13,39,,12",
 "374900,8,8,1998/10/01 13:28:00,139.7090137938,35.7018779034,1,4,3040108,4 ,9 ,71,,6",
 "455400,4,4,1998/10/01 13:28:00,139.8283138217,35.8972819067,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:28:00,139.6198304233,35.9166285724,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,4,1998/10/01 13:28:00,139.7013825893,35.6551132804,2,6,0513201,14,7 ,39,,1",
 "786600,3,5,1998/10/01 13:28:00,139.362192676,35.6652855021,2,3,0930110,13,2 ,35,,9",
 "855600,4,4,1998/10/01 13:28:00,139.5417015472,35.5510349492,2,14,1341201,15,3 ,47,,6",
 "878600,2,2,1998/10/01 13:28:00,139.5458904025,35.4317295502,2,8,1221105,14,4 ,43,,6",
 "685400,4,5,1998/10/01 13:28:00,139.5219200218,35.6607894914,1,11,0634016,15,7 ,48,,12",
 "848700,2,3,1998/10/01 13:29:00,139.6901069012,35.6363787863,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:29:00,139.754684167,35.7047484754,1,11,2910009,8 ,9 ,42,,8",
 "749800,2,3,1998/10/01 13:29:00,139.5546363081,35.5135114255,1,7,0810411,10,1 ,36,,1",
 "234600,6,9,1998/10/01 13:29:00,139.7182894995,35.7071792403,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:29:00,139.63578128,35.481010718,1,8,2520301,9 ,3 ,30,,12",
 "370300,4,7,1998/10/01 13:29:00,139.6720601889,35.8452684078,1,10,3037010,10,13,39,,12",
 "374900,8,8,1998/10/01 13:29:00,139.7087526306,35.7045982056,1,4,3040108,4 ,9 ,71,,6",
 "455400,4,4,1998/10/01 13:29:00,139.8280495043,35.8984976601,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:29:00,139.6197016488,35.9165757312,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,4,1998/10/01 13:29:00,139.7016881729,35.6546246813,2,6,0513201,14,7 ,39,,1",
 "786600,3,5,1998/10/01 13:29:00,139.3624940239,35.6653939843,2,3,0930110,13,2 ,35,,9",
 "855600,4,4,1998/10/01 13:29:00,139.5406815625,35.5496602976,2,14,1341201,15,3 ,47,,6",
 "878600,2,2,1998/10/01 13:29:00,139.5458989207,35.4334477082,2,8,1221105,14,4 ,43,,6",
 "685400,4,5,1998/10/01 13:29:00,139.5174835098,35.6628107048,1,11,0634016,15,7 ,48,,12",
 "848700,2,3,1998/10/01 13:30:00,139.6929575312,35.6398346344,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:30:00,139.7539228964,35.7071402554,1,11,2910009,8 ,9 ,42,,8",
 "749800,2,3,1998/10/01 13:30:00,139.5580335923,35.5157124244,1,7,0810411,10,1 ,36,,1",
 "749800,3,4,1998/10/01 13:30:00,139.5580335923,35.5157124244,1,7,0810411,10,97,36,,97",
 "234600,6,9,1998/10/01 13:30:00,139.7156507538,35.7095849883,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:30:00,139.6282636216,35.4728804417,1,8,2520301,9 ,3 ,30,,12",
 "370300,4,7,1998/10/01 13:30:00,139.6688608101,35.848054049,1,10,3037010,10,13,39,,12",
 "374900,8,8,1998/10/01 13:30:00,139.7090804634,35.7074859987,1,4,3040108,4 ,9 ,71,,6",
 "455400,4,4,1998/10/01 13:30:00,139.8277039322,35.8996884779,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:30:00,139.6195725721,35.9165234115,1,10,3512006,6 ,1 ,36,,6",
 "632500,2,4,1998/10/01 13:30:00,139.7017493915,35.6540649857,2,6,0513201,14,7 ,39,,1",
 "632500,3,5,1998/10/01 13:30:00,139.7017493915,35.6540649857,2,6,0513201,14,97,39,,97",
 "786600,3,5,1998/10/01 13:30:00,139.3627918231,35.6655086508,2,3,0930110,13,2 ,35,,9",
 "855600,4,4,1998/10/01 13:30:00,139.5405991039,35.5481955951,2,14,1341201,15,3 ,47,,6",
 "855600,5,5,1998/10/01 13:30:00,139.5405991039,35.5481955951,2,14,1341201,15,97,47,,97",
 "878600,2,2,1998/10/01 13:30:00,139.5468239621,35.4350994088,2,8,1221105,14,4 ,43,,6",
 "878600,3,3,1998/10/01 13:30:00,139.5468239621,35.4350994088,2,8,1221105,14,97,43,,97",
 "685400,4,5,1998/10/01 13:30:00,139.5126092174,35.6638951654,1,11,0634016,15,7 ,48,,12",
 "73600,4,5,1998/10/01 13:31:00,140.0968466663,35.7047095066,2,1,4410011,12,3 ,35,,1",
 "73600,4,6,1998/10/01 13:31:00,140.0968466663,35.7047095066,2,1,4410011,12,3 ,35,,9",
 "848700,2,3,1998/10/01 13:31:00,139.696882298,35.642462659,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:31:00,139.7531875148,35.7095372298,1,11,2910009,8 ,9 ,42,,8",
 "533600,4,4,1998/10/01 13:31:00,139.7686115436,35.6636711909,1,5,0023204,4 ,3 ,82,,2",
 "561200,2,2,1998/10/01 13:31:00,139.7278476959,35.7393476896,2,13,0210202,14,7 ,53,,1",
 "71300,4,6,1998/10/01 13:31:00,139.7704536456,35.7308905358,1,12,4313309,9 ,13,34,,1",
 "80500,8,10,1998/10/01 13:31:00,140.0932780654,35.6955282646,1,14,4413233,5 ,10,33,,1",
 "234600,6,9,1998/10/01 13:31:00,139.7118921236,35.7108658739,1,14,2412116,10,10,63,,12",
 "27600,2,2,1998/10/01 13:31:00,140.0088234297,35.7063977764,2,6,4125008,14,4 ,25,,2",
 "253000,2,3,1998/10/01 13:31:00,139.6210971193,35.4645406087,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:31:00,139.9887711836,36.021433738,2,15,5311005,15,4 ,29,,6",
 "370300,4,7,1998/10/01 13:31:00,139.6669462134,35.8496620413,1,10,3037010,10,13,39,,12",
 "374900,8,8,1998/10/01 13:31:00,139.7097623229,35.7103274782,1,4,3040108,4 ,9 ,71,,6",
 "443900,4,4,1998/10/01 13:31:00,139.8793913213,35.8038520999,2,10,3215307,5 ,3 ,46,,1",
 "457700,2,2,1998/10/01 13:31:00,139.7241837086,35.9373226767,2,12,3231014,14,4 ,32,,6",
 "455400,4,4,1998/10/01 13:31:00,139.8269823441,35.9007552162,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:31:00,139.6194405031,35.9164762573,1,10,3512006,6 ,1 ,36,,6",
 "802700,4,6,1998/10/01 13:31:00,139.6155472441,35.4387922195,2,6,1425101,14,5 ,32,,1",
 "786600,3,5,1998/10/01 13:31:00,139.3630848093,35.6656314459,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:31:00,139.507680178,35.6649021348,1,11,0634016,15,7 ,48,,12",
 "73600,4,6,1998/10/01 13:32:00,140.0965249487,35.704406855,2,1,4410011,12,3 ,35,,9",
 "848700,2,3,1998/10/01 13:32:00,139.7004677037,35.6454278107,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:32:00,139.7525499949,35.7119529674,1,11,2910009,8 ,9 ,42,,8",
 "533600,4,4,1998/10/01 13:32:00,139.7689335719,35.6643580371,1,5,0023204,4 ,3 ,82,,2",
 "561200,2,2,1998/10/01 13:32:00,139.7278475642,35.7393476546,2,13,0210202,14,7 ,53,,1",
 "71300,4,6,1998/10/01 13:32:00,139.7693005797,35.7318256632,1,12,4313309,9 ,13,34,,1",
 "80500,8,10,1998/10/01 13:32:00,140.093159437,35.6958159833,1,14,4413233,5 ,10,33,,1",
 "234600,6,9,1998/10/01 13:32:00,139.7079714487,35.7118603992,1,14,2412116,10,10,63,,12",
 "27600,2,2,1998/10/01 13:32:00,140.0103025589,35.7075091232,2,6,4125008,14,4 ,25,,2",
 "253000,2,3,1998/10/01 13:32:00,139.6135236986,35.456446361,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:32:00,139.9945987542,36.0226159176,2,15,5311005,15,4 ,29,,6",
 "370300,4,7,1998/10/01 13:32:00,139.6682570148,35.8483258366,1,10,3037010,10,13,39,,12",
 "374900,8,8,1998/10/01 13:32:00,139.7106798737,35.7131230614,1,4,3040108,4 ,9 ,71,,6",
 "443900,4,4,1998/10/01 13:32:00,139.8792490836,35.8041295597,2,10,3215307,5 ,3 ,46,,1",
 "455400,4,4,1998/10/01 13:32:00,139.8267693439,35.901977172,1,12,3226003,3 ,3 ,31,,8",
 "457700,2,2,1998/10/01 13:32:00,139.7221370058,35.9384591673,2,12,3231014,14,4 ,32,,6",
 "487600,6,6,1998/10/01 13:32:00,139.6193084342,35.9164291029,1,10,3512006,6 ,1 ,36,,6",
 "802700,4,6,1998/10/01 13:32:00,139.6212320355,35.4654852753,2,6,1425101,14,5 ,32,,1",
 "802700,4,7,1998/10/01 13:32:00,139.6212320355,35.4654852753,2,6,1425101,14,5 ,32,,12",
 "786600,3,5,1998/10/01 13:32:00,139.3633777965,35.6657542402,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:32:00,139.5028347062,35.6661255699,1,11,0634016,15,7 ,48,,12",
 "73600,4,6,1998/10/01 13:33:00,140.0962032334,35.7041042026,2,1,4410011,12,3 ,35,,9",
 "848700,2,3,1998/10/01 13:33:00,139.7038345094,35.6484826992,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:33:00,139.7520444145,35.7143891664,1,11,2910009,8 ,9 ,42,,8",
 "533600,4,4,1998/10/01 13:33:00,139.7692556056,35.6650448824,1,5,0023204,4 ,3 ,82,,2",
 "561200,2,2,1998/10/01 13:33:00,139.7278474324,35.7393476196,2,13,0210202,14,7 ,53,,1",
 "71300,4,6,1998/10/01 13:33:00,139.7682786578,35.732648987,1,12,4313309,9 ,13,34,,1",
 "80500,8,10,1998/10/01 13:33:00,140.0930408077,35.6961037019,1,14,4413233,5 ,10,33,,1",
 "234600,6,9,1998/10/01 13:33:00,139.704450088,35.7136369187,1,14,2412116,10,10,63,,12",
 "27600,2,2,1998/10/01 13:33:00,140.0109726379,35.7082834008,2,6,4125008,14,4 ,25,,2",
 "253000,2,3,1998/10/01 13:33:00,139.6036567579,35.4502868165,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:33:00,139.9991589207,36.0242297839,2,15,5311005,15,4 ,29,,6",
 "370300,4,7,1998/10/01 13:33:00,139.665265033,35.8512562455,1,10,3037010,10,13,39,,12",
 "374900,8,8,1998/10/01 13:33:00,139.7121295133,35.7157705318,1,4,3040108,4 ,9 ,71,,6",
 "443900,4,4,1998/10/01 13:33:00,139.8791068448,35.8044070193,2,10,3215307,5 ,3 ,46,,1",
 "455400,4,4,1998/10/01 13:33:00,139.8265712624,35.903199225,1,12,3226003,3 ,3 ,31,,8",
 "457700,2,2,1998/10/01 13:33:00,139.7199112632,35.9393865955,2,12,3231014,14,4 ,32,,6",
 "487600,6,6,1998/10/01 13:33:00,139.6191763654,35.9163819483,1,10,3512006,6 ,1 ,36,,6",
 "802700,4,7,1998/10/01 13:33:00,139.6190565038,35.4598689824,2,6,1425101,14,5 ,32,,12",
 "786600,3,5,1998/10/01 13:33:00,139.3636707846,35.6658770338,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:33:00,139.4981831167,35.667794627,1,11,0634016,15,7 ,48,,12",
 "73600,4,6,1998/10/01 13:34:00,140.0958815207,35.7038015493,2,1,4410011,12,3 ,35,,9",
 "848700,2,3,1998/10/01 13:34:00,139.7063398213,35.6520841672,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:34:00,139.7515938664,35.7168330957,1,11,2910009,8 ,9 ,42,,8",
 "533600,4,4,1998/10/01 13:34:00,139.7692920951,35.665697501,1,5,0023204,4 ,3 ,82,,2",
 "561200,2,2,1998/10/01 13:34:00,139.7278473007,35.7393475845,2,13,0210202,14,7 ,53,,1",
 "71300,4,6,1998/10/01 13:34:00,139.7670425592,35.732398908,1,12,4313309,9 ,13,34,,1",
 "71300,4,7,1998/10/01 13:34:00,139.7670425592,35.732398908,1,12,4313309,9 ,13,34,,12",
 "80500,8,10,1998/10/01 13:34:00,140.0929221776,35.6963914204,1,14,4413233,5 ,10,33,,1",
 "234600,6,9,1998/10/01 13:34:00,139.7006162379,35.7127934272,1,14,2412116,10,10,63,,12",
 "27600,2,2,1998/10/01 13:34:00,140.0100916397,35.7081975584,2,6,4125008,14,4 ,25,,2",
 "253000,2,3,1998/10/01 13:34:00,139.5939645577,35.4450010081,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:34:00,140.0043800387,36.0244043623,2,15,5311005,15,4 ,29,,6",
 "370300,4,7,1998/10/01 13:34:00,139.6617371483,35.8537656524,1,10,3037010,10,13,39,,12",
 "374900,8,8,1998/10/01 13:34:00,139.7134000623,35.7184796936,1,4,3040108,4 ,9 ,71,,6",
 "443900,4,4,1998/10/01 13:34:00,139.8789646051,35.8046844787,2,10,3215307,5 ,3 ,46,,1",
 "457700,2,2,1998/10/01 13:34:00,139.7176854687,35.9403139822,2,12,3231014,14,4 ,32,,6",
 "455400,4,4,1998/10/01 13:34:00,139.8263096228,35.9044155603,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:34:00,139.6190439157,35.9163355621,1,10,3512006,6 ,1 ,36,,6",
 "802700,4,7,1998/10/01 13:34:00,139.6205731509,35.4538208722,2,6,1425101,14,5 ,32,,12",
 "786600,3,5,1998/10/01 13:34:00,139.3639637735,35.6659998267,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:34:00,139.493505228,35.6694142308,1,11,0634016,15,7 ,48,,12",
 "786600,3,5,1998/10/01 13:35:00,139.3642581641,35.666120319,2,3,0930110,13,2 ,35,,9",
 "73600,4,6,1998/10/01 13:35:00,140.0955598103,35.7034988951,2,1,4410011,12,3 ,35,,9",
 "848700,2,3,1998/10/01 13:35:00,139.7054227296,35.6556668656,1,4,1313101,13,2 ,37,,12",
 "280600,9,9,1998/10/01 13:35:00,139.7514050799,35.7192572891,1,11,2910009,8 ,9 ,42,,8",
 "533600,4,4,1998/10/01 13:35:00,139.7695395208,35.6662879356,1,5,0023204,4 ,3 ,82,,2",
 "533600,5,5,1998/10/01 13:35:00,139.7695395208,35.6662879356,1,5,0023204,4 ,97,82,,97",
 "561200,2,2,1998/10/01 13:35:00,139.727847169,35.7393475495,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 13:35:00,139.7640979515,35.7352320917,1,12,4313309,9 ,13,34,,12",
 "80500,8,10,1998/10/01 13:35:00,140.0928065542,35.6966799347,1,14,4413233,5 ,10,33,,1",
 "234600,6,9,1998/10/01 13:35:00,139.6967642652,35.7115993142,1,14,2412116,10,10,63,,12",
 "27600,2,2,1998/10/01 13:35:00,140.0120186868,35.7086668533,2,6,4125008,14,4 ,25,,2",
 "253000,2,3,1998/10/01 13:35:00,139.5816756362,35.4466761211,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:35:00,140.0084935386,36.0279671137,2,15,5311005,15,4 ,29,,6",
 "370300,4,7,1998/10/01 13:35:00,139.6585281202,35.8565254476,1,10,3037010,10,13,39,,12",
 "374900,8,8,1998/10/01 13:35:00,139.7127256546,35.7212344805,1,4,3040108,4 ,9 ,71,,6",
 "443900,4,4,1998/10/01 13:35:00,139.8788223644,35.8049619379,2,10,3215307,5 ,3 ,46,,1",
 "455400,4,4,1998/10/01 13:35:00,139.8256125118,35.9054620832,1,12,3226003,3 ,3 ,31,,8",
 "457700,2,2,1998/10/01 13:35:00,139.7154745186,35.9412632543,2,12,3231014,14,4 ,32,,6",
 "487600,6,6,1998/10/01 13:35:00,139.6189085933,35.9162949686,1,10,3512006,6 ,1 ,36,,6",
 "802700,4,7,1998/10/01 13:35:00,139.6242287699,35.448328453,2,6,1425101,14,5 ,32,,12",
 "685400,4,5,1998/10/01 13:35:00,139.4886430564,35.6705805934,1,11,0634016,15,7 ,48,,12",
 "73600,4,6,1998/10/01 13:36:00,140.0952381024,35.70319624,2,1,4410011,12,3 ,35,,9",
 "848700,2,3,1998/10/01 13:36:00,139.7017727956,35.658516117,1,4,1313101,13,2 ,37,,12",
 "848700,2,4,1998/10/01 13:36:00,139.7017727956,35.658516117,1,4,1313101,13,2 ,37,,1",
 "280600,9,9,1998/10/01 13:36:00,139.7523218262,35.7216129468,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,2,1998/10/01 13:36:00,139.7278470373,35.7393475145,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 13:36:00,139.7607346299,35.7380211392,1,12,4313309,9 ,13,34,,12",
 "80500,8,10,1998/10/01 13:36:00,140.0926955035,35.6969696594,1,14,4413233,5 ,10,33,,1",
 "234600,6,9,1998/10/01 13:36:00,139.6927042119,35.710946604,1,14,2412116,10,10,63,,12",
 "27600,2,2,1998/10/01 13:36:00,140.0139880674,35.7088128959,2,6,4125008,14,4 ,25,,2",
 "253000,2,3,1998/10/01 13:36:00,139.5704451768,35.4433015587,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:36:00,140.0111838369,36.0318002884,2,15,5311005,15,4 ,29,,6",
 "370300,4,7,1998/10/01 13:36:00,139.6563568866,35.8599013263,1,10,3037010,10,13,39,,12",
 "374900,8,8,1998/10/01 13:36:00,139.7115991684,35.7239857621,1,4,3040108,4 ,9 ,71,,6",
 "443900,4,4,1998/10/01 13:36:00,139.8786801227,35.805239397,2,10,3215307,5 ,3 ,46,,1",
 "457700,2,2,1998/10/01 13:36:00,139.7132278992,35.9421180638,2,12,3231014,14,4 ,32,,6",
 "455400,4,4,1998/10/01 13:36:00,139.8244989768,35.9063008488,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:36:00,139.618773271,35.9162543749,1,10,3512006,6 ,1 ,36,,6",
 "802700,4,7,1998/10/01 13:36:00,139.6261948654,35.4427868985,2,6,1425101,14,5 ,32,,12",
 "786600,3,5,1998/10/01 13:36:00,139.3645498915,35.6662450928,2,3,0930110,13,2 ,35,,9",
 "788900,4,4,1998/10/01 13:36:00,139.2728547143,35.8181087665,2,2,0931009,12,3 ,35,,1",
 "685400,4,5,1998/10/01 13:36:00,139.4836931852,35.6715192795,1,11,0634016,15,7 ,48,,12",
 "73600,4,6,1998/10/01 13:37:00,140.0949654757,35.7028631031,2,1,4410011,12,3 ,35,,9",
 "848700,2,4,1998/10/01 13:37:00,139.7027013655,35.6581826482,1,4,1313101,13,2 ,37,,1",
 "280600,9,9,1998/10/01 13:37:00,139.7534745138,35.7238863015,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,2,1998/10/01 13:37:00,139.7278469055,35.7393474795,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 13:37:00,139.7572214613,35.7409429942,1,12,4313309,9 ,13,34,,12",
 "80500,8,10,1998/10/01 13:37:00,140.0925844521,35.697259384,1,14,4413233,5 ,10,33,,1",
 "234600,6,9,1998/10/01 13:37:00,139.6885989675,35.7111719839,1,14,2412116,10,10,63,,12",
 "27600,2,2,1998/10/01 13:37:00,140.0159238027,35.708501149,2,6,4125008,14,4 ,25,,2",
 "253000,2,3,1998/10/01 13:37:00,139.5604957723,35.4373427766,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:37:00,140.0110109608,36.0366935178,2,15,5311005,15,4 ,29,,6",
 "370300,4,7,1998/10/01 13:37:00,139.6542106354,35.8632910857,1,10,3037010,10,13,39,,12",
 "374900,8,8,1998/10/01 13:37:00,139.7119169954,35.7267825902,1,4,3040108,4 ,9 ,71,,6",
 "443900,4,4,1998/10/01 13:37:00,139.87853788,35.8055168559,2,10,3215307,5 ,3 ,46,,1",
 "457700,2,2,1998/10/01 13:37:00,139.7107833599,35.9425366976,2,12,3231014,14,4 ,32,,6",
 "455400,4,4,1998/10/01 13:37:00,139.8239929805,35.9074010736,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:37:00,139.6187136195,35.9163372705,1,10,3512006,6 ,1 ,36,,6",
 "802700,4,7,1998/10/01 13:37:00,139.6203344628,35.4389736132,2,6,1425101,14,5 ,32,,12",
 "786600,3,5,1998/10/01 13:37:00,139.3648422752,35.6663688448,2,3,0930110,13,2 ,35,,9",
 "788900,4,4,1998/10/01 13:37:00,139.2733436075,35.8182572817,2,2,0931009,12,3 ,35,,1",
 "685400,4,5,1998/10/01 13:37:00,139.4787432727,35.6724580155,1,11,0634016,15,7 ,48,,12",
 "73600,4,6,1998/10/01 13:38:00,140.0946933699,35.7025296435,2,1,4410011,12,3 ,35,,9",
 "848700,2,4,1998/10/01 13:38:00,139.703591118,35.6579063034,1,4,1313101,13,2 ,37,,1",
 "280600,9,9,1998/10/01 13:38:00,139.7552589127,35.7258627575,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,2,1998/10/01 13:38:00,139.7278467738,35.7393474445,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 13:38:00,139.7531396726,35.7433335742,1,12,4313309,9 ,13,34,,12",
 "80500,8,10,1998/10/01 13:38:00,140.09238163,35.6975105738,1,14,4413233,5 ,10,33,,1",
 "234600,6,9,1998/10/01 13:38:00,139.6845813564,35.7103183838,1,14,2412116,10,10,63,,12",
 "27600,2,2,1998/10/01 13:38:00,140.0179209648,35.7083176459,2,6,4125008,14,4 ,25,,2",
 "253000,2,3,1998/10/01 13:38:00,139.5567072024,35.4279862505,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:38:00,140.0161986048,36.037753707,2,15,5311005,15,4 ,29,,6",
 "370300,4,7,1998/10/01 13:38:00,139.651904017,35.8666098075,1,10,3037010,10,13,39,,12",
 "374900,8,8,1998/10/01 13:38:00,139.7140124639,35.729090664,1,4,3040108,4 ,9 ,71,,6",
 "443900,4,4,1998/10/01 13:38:00,139.8783956363,35.8057943146,2,10,3215307,5 ,3 ,46,,1",
 "457700,2,2,1998/10/01 13:38:00,139.7086204995,35.943318622,2,12,3231014,14,4 ,32,,6",
 "455400,4,4,1998/10/01 13:38:00,139.8237429714,35.9086191203,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:38:00,139.6186720083,35.9164496066,1,10,3512006,6 ,1 ,36,,6",
 "802700,4,7,1998/10/01 13:38:00,139.6130264424,35.4368131176,2,6,1425101,14,5 ,32,,12",
 "786600,3,5,1998/10/01 13:38:00,139.3651346599,35.6664925962,2,3,0930110,13,2 ,35,,9",
 "788900,4,4,1998/10/01 13:38:00,139.2738325026,35.8184057949,2,2,0931009,12,3 ,35,,1",
 "685400,4,5,1998/10/01 13:38:00,139.4737538886,35.6727237899,1,11,0634016,15,7 ,48,,12",
 "73600,4,6,1998/10/01 13:39:00,140.0944110691,35.7022019571,2,1,4410011,12,3 ,35,,9",
 "848700,2,4,1998/10/01 13:39:00,139.7046034437,35.6579591343,1,4,1313101,13,2 ,37,,1",
 "280600,9,9,1998/10/01 13:39:00,139.7566310662,35.7280619621,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,2,1998/10/01 13:39:00,139.7278466421,35.7393474095,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 13:39:00,139.7489298524,35.7455726271,1,12,4313309,9 ,13,34,,12",
 "80500,8,10,1998/10/01 13:39:00,140.0921570871,35.697752643,1,14,4413233,5 ,10,33,,1",
 "234600,6,9,1998/10/01 13:39:00,139.6807029177,35.7091714543,1,14,2412116,10,10,63,,12",
 "27600,2,2,1998/10/01 13:39:00,140.0198717483,35.7085118154,2,6,4125008,14,4 ,25,,2",
 "253000,2,3,1998/10/01 13:39:00,139.5496946914,35.4200130422,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:39:00,140.0220781551,36.0388307216,2,15,5311005,15,4 ,29,,6",
 "370300,4,7,1998/10/01 13:39:00,139.6488132083,35.8694729339,1,10,3037010,10,13,39,,12",
 "374900,8,8,1998/10/01 13:39:00,139.7142187887,35.7319821121,1,4,3040108,4 ,9 ,71,,6",
 "443900,4,4,1998/10/01 13:39:00,139.8784510344,35.8060031362,2,10,3215307,5 ,3 ,46,,1",
 "457700,2,2,1998/10/01 13:39:00,139.7067050688,35.9445836354,2,12,3231014,14,4 ,32,,6",
 "455400,4,4,1998/10/01 13:39:00,139.8234929547,35.9098371662,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:39:00,139.618630397,35.9165619427,1,10,3512006,6 ,1 ,36,,6",
 "802700,4,7,1998/10/01 13:39:00,139.605537916,35.4350866671,2,6,1425101,14,5 ,32,,12",
 "788900,4,4,1998/10/01 13:39:00,139.2743213995,35.8185543062,2,2,0931009,12,3 ,35,,1",
 "786600,3,5,1998/10/01 13:39:00,139.3654270454,35.6666163468,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:39:00,139.469372255,35.6709856129,1,11,0634016,15,7 ,48,,12",
 "73600,4,6,1998/10/01 13:40:00,140.0941231977,35.7018774254,2,1,4410011,12,3 ,35,,9",
 "848700,2,4,1998/10/01 13:40:00,139.70561512,35.6580203717,1,4,1313101,13,2 ,37,,1",
 "280600,9,9,1998/10/01 13:40:00,139.7576319591,35.7303882939,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,2,1998/10/01 13:40:00,139.7278465104,35.7393473744,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 13:40:00,139.7447062129,35.747795324,1,12,4313309,9 ,13,34,,12",
 "80500,8,10,1998/10/01 13:40:00,140.0919325429,35.6979947117,1,14,4413233,5 ,10,33,,1",
 "80500,9,11,1998/10/01 13:40:00,140.0919325429,35.6979947117,1,14,4413233,5 ,97,33,,97",
 "234600,6,9,1998/10/01 13:40:00,139.6773333723,35.7072095703,1,14,2412116,10,10,63,,12",
 "27600,2,2,1998/10/01 13:40:00,140.0211723024,35.7087597594,2,6,4125008,14,4 ,25,,2",
 "27600,3,3,1998/10/01 13:40:00,140.0211723024,35.7087597594,2,6,4125008,14,97,25,,97",
 "253000,2,3,1998/10/01 13:40:00,139.5392591747,35.4144491227,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:40:00,140.0279639247,36.039885212,2,15,5311005,15,4 ,29,,6",
 "370300,4,7,1998/10/01 13:40:00,139.6457424231,35.8722938874,1,10,3037010,10,13,39,,12",
 "370300,4,8,1998/10/01 13:40:00,139.6457424231,35.8722938874,1,10,3037010,10,13,39,,1",
 "374900,8,8,1998/10/01 13:40:00,139.7163249411,35.7338046888,1,4,3040108,4 ,9 ,71,,6",
 "443900,4,4,1998/10/01 13:40:00,139.8784654397,35.8063035891,2,10,3215307,5 ,3 ,46,,1",
 "455400,4,4,1998/10/01 13:40:00,139.8231936267,35.9105817048,1,12,3226003,3 ,3 ,31,,8",
 "457700,2,2,1998/10/01 13:40:00,139.704663849,35.9455113639,2,12,3231014,14,4 ,32,,6",
 "487600,6,6,1998/10/01 13:40:00,139.6185887855,35.9166742788,1,10,3512006,6 ,1 ,36,,6",
 "802700,4,7,1998/10/01 13:40:00,139.5980438224,35.4333002099,2,6,1425101,14,5 ,32,,12",
 "786600,3,5,1998/10/01 13:40:00,139.3657194318,35.6667400968,2,3,0930110,13,2 ,35,,9",
 "788900,4,4,1998/10/01 13:40:00,139.2748102982,35.8187028155,2,2,0931009,12,3 ,35,,1",
 "685400,4,5,1998/10/01 13:40:00,139.468020291,35.6669987936,1,11,0634016,15,7 ,48,,12",
 "73600,4,6,1998/10/01 13:41:00,140.0938353286,35.7015528929,2,1,4410011,12,3 ,35,,9",
 "848700,2,4,1998/10/01 13:41:00,139.7066266171,35.6580839219,1,4,1313101,13,2 ,37,,1",
 "280600,9,9,1998/10/01 13:41:00,139.7587673436,35.7326604814,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,2,1998/10/01 13:41:00,139.7278463786,35.7393473394,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 13:41:00,139.7407424414,35.7502413491,1,12,4313309,9 ,13,34,,12",
 "234600,6,9,1998/10/01 13:41:00,139.6736172785,35.7059796662,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:41:00,139.534014601,35.4057682446,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:41:00,140.0338669339,36.040874391,2,15,5311005,15,4 ,29,,6",
 "271400,4,4,1998/10/01 13:41:00,139.5621261324,35.5321222725,1,7,2811315,9 ,11,37,,8",
 "370300,4,8,1998/10/01 13:41:00,139.6468740083,35.8725862192,1,10,3037010,10,13,39,,1",
 "374900,8,8,1998/10/01 13:41:00,139.7144215525,35.7362465762,1,4,3040108,4 ,9 ,71,,6",
 "443900,4,4,1998/10/01 13:41:00,139.8784661401,35.8066040875,2,10,3215307,5 ,3 ,46,,1",
 "457700,2,2,1998/10/01 13:41:00,139.7022918298,35.946121071,2,12,3231014,14,4 ,32,,6",
 "455400,4,4,1998/10/01 13:41:00,139.8238426418,35.9116980572,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:41:00,139.6185471739,35.9167866149,1,10,3512006,6 ,1 ,36,,6",
 "584200,2,2,1998/10/01 13:41:00,139.8211255295,35.7145856037,2,6,0332001,9 ,11,49,,1",
 "667000,6,6,1998/10/01 13:41:00,139.7991051655,35.7869790963,2,12,0614310,6 ,3 ,43,,2",
 "802700,4,7,1998/10/01 13:41:00,139.5960552828,35.4275937701,2,6,1425101,14,5 ,32,,12",
 "788900,4,4,1998/10/01 13:41:00,139.2752986988,35.8188519486,2,2,0931009,12,3 ,35,,1",
 "786600,3,5,1998/10/01 13:41:00,139.3660118068,35.6668638654,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:41:00,139.4667883233,35.6629776982,1,11,0634016,15,7 ,48,,12",
 "73600,4,6,1998/10/01 13:42:00,140.0934781067,35.7012830364,2,1,4410011,12,3 ,35,,9",
 "848700,2,4,1998/10/01 13:42:00,139.7076384359,35.6581438798,1,4,1313101,13,2 ,37,,1",
 "280600,9,9,1998/10/01 13:42:00,139.759336188,35.7350834427,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,2,1998/10/01 13:42:00,139.7278462469,35.7393473044,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 13:42:00,139.7373719262,35.7532680623,1,12,4313309,9 ,13,34,,12",
 "234600,6,9,1998/10/01 13:42:00,139.6694705426,35.705807062,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:42:00,139.5357172083,35.3957771531,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:42:00,140.0398089732,36.0416149223,2,15,5311005,15,4 ,29,,6",
 "271400,4,4,1998/10/01 13:42:00,139.5617744391,35.5297732435,1,7,2811315,9 ,11,37,,8",
 "370300,4,8,1998/10/01 13:42:00,139.6478169749,35.8729211806,1,10,3037010,10,13,39,,1",
 "374900,8,8,1998/10/01 13:42:00,139.7129662588,35.7385925434,1,4,3040108,4 ,9 ,71,,6",
 "443900,4,4,1998/10/01 13:42:00,139.8784547503,35.8069046262,2,10,3215307,5 ,3 ,46,,1",
 "457700,2,2,1998/10/01 13:42:00,139.6999969897,35.9469210643,2,12,3231014,14,4 ,32,,6",
 "455400,4,4,1998/10/01 13:42:00,139.8239721334,35.9111251334,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:42:00,139.6185055622,35.9168989509,1,10,3512006,6 ,1 ,36,,6",
 "584200,2,2,1998/10/01 13:42:00,139.8198153199,35.7158226706,2,6,0332001,9 ,11,49,,1",
 "667000,6,6,1998/10/01 13:42:00,139.7977963462,35.7870876213,2,12,0614310,6 ,3 ,43,,2",
 "802700,4,7,1998/10/01 13:42:00,139.597451129,35.4213078507,2,6,1425101,14,5 ,32,,12",
 "786600,3,5,1998/10/01 13:42:00,139.3663040412,35.6669878558,2,3,0930110,13,2 ,35,,9",
 "788900,4,4,1998/10/01 13:42:00,139.2756492049,35.8191654662,2,2,0931009,12,3 ,35,,1",
 "685400,4,5,1998/10/01 13:42:00,139.4630786502,35.6604014127,1,11,0634016,15,7 ,48,,12",
 "73600,4,6,1998/10/01 13:43:00,140.0930254479,35.7011405089,2,1,4410011,12,3 ,35,,9",
 "848700,2,4,1998/10/01 13:43:00,139.7086495011,35.6582105819,1,4,1313101,13,2 ,37,,1",
 "280600,9,9,1998/10/01 13:43:00,139.7598001266,35.7375242329,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,2,1998/10/01 13:43:00,139.7278461152,35.7393472694,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 13:43:00,139.7346207683,35.7567046255,1,12,4313309,9 ,13,34,,12",
 "234600,6,9,1998/10/01 13:43:00,139.6653187797,35.7057897957,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:43:00,139.5349442556,35.3856081176,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:43:00,140.045106637,36.0417049285,2,15,5311005,15,4 ,29,,6",
 "271400,4,4,1998/10/01 13:43:00,139.5621391056,35.5270450201,1,7,2811315,9 ,11,37,,8",
 "370300,4,8,1998/10/01 13:43:00,139.6489933516,35.8730515205,1,10,3037010,10,13,39,,1",
 "374900,8,8,1998/10/01 13:43:00,139.7123728185,35.7413150867,1,4,3040108,4 ,9 ,71,,6",
 "443900,4,4,1998/10/01 13:43:00,139.8784433604,35.8072051648,2,10,3215307,5 ,3 ,46,,1",
 "457700,2,2,1998/10/01 13:43:00,139.6978429469,35.9479484531,2,12,3231014,14,4 ,32,,6",
 "455400,4,4,1998/10/01 13:43:00,139.8235248155,35.911268685,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:43:00,139.6184639504,35.9170112869,1,10,3512006,6 ,1 ,36,,6",
 "584200,2,2,1998/10/01 13:43:00,139.8186887713,35.7171777027,2,6,0332001,9 ,11,49,,1",
 "667000,6,6,1998/10/01 13:43:00,139.7975526543,35.7861962146,2,12,0614310,6 ,3 ,43,,2",
 "802700,4,7,1998/10/01 13:43:00,139.5967736167,35.4149973119,2,6,1425101,14,5 ,32,,12",
 "786600,3,5,1998/10/01 13:43:00,139.3665962764,35.6671118455,2,3,0930110,13,2 ,35,,9",
 "788900,4,4,1998/10/01 13:43:00,139.2757989732,35.8195630418,2,2,0931009,12,3 ,35,,1",
 "685400,4,5,1998/10/01 13:43:00,139.4580971896,35.659588065,1,11,0634016,15,7 ,48,,12",
 "73600,4,6,1998/10/01 13:44:00,140.092537937,35.7010922636,2,1,4410011,12,3 ,35,,9",
 "848700,2,4,1998/10/01 13:44:00,139.7096587423,35.6582947155,1,4,1313101,13,2 ,37,,1",
 "280600,9,9,1998/10/01 13:44:00,139.7618186133,35.739252587,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,2,1998/10/01 13:44:00,139.7278459835,35.7393472344,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 13:44:00,139.7314061455,35.7598202045,1,12,4313309,9 ,13,34,,12",
 "234600,6,9,1998/10/01 13:44:00,139.6611714606,35.7056197505,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:44:00,139.532072137,35.3757864345,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:44:00,140.0482303875,36.0458683026,2,15,5311005,15,4 ,29,,6",
 "271400,4,4,1998/10/01 13:44:00,139.5626286803,35.523834081,1,7,2811315,9 ,11,37,,8",
 "370300,4,8,1998/10/01 13:44:00,139.6501697323,35.8731818488,1,10,3037010,10,13,39,,1",
 "374900,8,8,1998/10/01 13:44:00,139.7137673907,35.7438597771,1,4,3040108,4 ,9 ,71,,6",
 "443900,4,4,1998/10/01 13:44:00,139.8785217878,35.8074444793,2,10,3215307,5 ,3 ,46,,1",
 "457700,2,2,1998/10/01 13:44:00,139.6964374562,35.9492317704,2,12,3231014,14,4 ,32,,6",
 "455400,4,4,1998/10/01 13:44:00,139.8234330056,35.912501335,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:44:00,139.6184259252,35.9171243661,1,10,3512006,6 ,1 ,36,,6",
 "584200,2,2,1998/10/01 13:44:00,139.8177739962,35.7186263839,2,6,0332001,9 ,11,49,,1",
 "667000,6,6,1998/10/01 13:44:00,139.7962075588,35.7862423024,2,12,0614310,6 ,3 ,43,,2",
 "802700,4,7,1998/10/01 13:44:00,139.5959293954,35.4091870198,2,6,1425101,14,5 ,32,,12",
 "802700,4,8,1998/10/01 13:44:00,139.5959293954,35.4091870198,2,6,1425101,14,5 ,32,,1",
 "786600,3,5,1998/10/01 13:44:00,139.3668885126,35.6672358345,2,3,0930110,13,2 ,35,,9",
 "788900,4,4,1998/10/01 13:44:00,139.2758025179,35.8199861711,2,2,0931009,12,3 ,35,,1",
 "685400,4,5,1998/10/01 13:44:00,139.4531162305,35.6587777302,1,11,0634016,15,7 ,48,,12",
 "802700,4,8,1998/10/01 13:45:00,139.5994033728,35.4134175774,2,6,1425101,14,5 ,32,,1",
 "802700,5,9,1998/10/01 13:45:00,139.5994033728,35.4134175774,2,6,1425101,14,97,32,,97",
 "73600,4,6,1998/10/01 13:45:00,140.0920503584,35.7010444775,2,1,4410011,12,3 ,35,,9",
 "848700,2,4,1998/10/01 13:45:00,139.7106657933,35.6583943094,1,4,1313101,13,2 ,37,,1",
 "280600,9,9,1998/10/01 13:45:00,139.7609843247,35.7411378836,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,2,1998/10/01 13:45:00,139.7278458518,35.7393471994,2,13,0210202,14,7 ,53,,1",
 "561200,2,3,1998/10/01 13:45:00,139.7278458518,35.7393471994,2,13,0210202,14,7 ,53,,10",
 "71300,4,7,1998/10/01 13:45:00,139.727684659,35.7625770238,1,12,4313309,9 ,13,34,,12",
 "234600,6,9,1998/10/01 13:45:00,139.6570209858,35.7055199222,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:45:00,139.5304696736,35.3660574989,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:45:00,140.0509838705,36.049941763,2,15,5311005,15,4 ,29,,6",
 "271400,4,4,1998/10/01 13:45:00,139.5620335068,35.5214620173,1,7,2811315,9 ,11,37,,8",
 "370300,4,8,1998/10/01 13:45:00,139.6513461169,35.8733121656,1,10,3037010,10,13,39,,1",
 "374900,8,8,1998/10/01 13:45:00,139.7151758723,35.7465151062,1,4,3040108,4 ,9 ,71,,6",
 "443900,4,4,1998/10/01 13:45:00,139.8788869653,35.8074883296,2,10,3215307,5 ,3 ,46,,1",
 "443900,5,5,1998/10/01 13:45:00,139.8788869653,35.8074883296,2,10,3215307,5 ,97,46,,97",
 "457700,2,2,1998/10/01 13:45:00,139.6960206472,35.9507847417,2,12,3231014,14,4 ,32,,6",
 "457700,3,3,1998/10/01 13:45:00,139.6960206472,35.9507847417,2,12,3231014,14,97,32,,97",
 "455400,4,4,1998/10/01 13:45:00,139.8233411927,35.9137339847,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:45:00,139.6183963975,35.9172392061,1,10,3512006,6 ,1 ,36,,6",
 "584200,2,2,1998/10/01 13:45:00,139.816790129,35.7198744825,2,6,0332001,9 ,11,49,,1",
 "667000,6,6,1998/10/01 13:45:00,139.7948632073,35.7861800498,2,12,0614310,6 ,3 ,43,,2",
 "788900,4,4,1998/10/01 13:45:00,139.2756457484,35.8203914863,2,2,0931009,12,3 ,35,,1",
 "786600,3,5,1998/10/01 13:45:00,139.367181267,35.6673590064,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:45:00,139.450731147,35.6553643217,1,11,0634016,15,7 ,48,,12",
 "73600,4,6,1998/10/01 13:46:00,140.0915627803,35.7009966895,2,1,4410011,12,3 ,35,,9",
 "848700,2,4,1998/10/01 13:46:00,139.7116735436,35.6584895895,1,4,1313101,13,2 ,37,,1",
 "280600,9,9,1998/10/01 13:46:00,139.7627472777,35.7431399827,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,3,1998/10/01 13:46:00,139.72784572,35.7393471643,2,13,0210202,14,7 ,53,,10",
 "71300,4,7,1998/10/01 13:46:00,139.725249464,35.7661583602,1,12,4313309,9 ,13,34,,12",
 "234600,6,9,1998/10/01 13:46:00,139.6528707982,35.7054058809,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:46:00,139.5317303652,35.3565147578,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:46:00,140.0554007866,36.0532067272,2,15,5311005,15,4 ,29,,6",
 "271400,4,4,1998/10/01 13:46:00,139.5598047154,35.5187157651,1,7,2811315,9 ,11,37,,8",
 "370300,4,8,1998/10/01 13:46:00,139.6525203298,35.873454761,1,10,3037010,10,13,39,,1",
 "391000,4,6,1998/10/01 13:46:00,139.755475242,35.6712749479,1,7,3110313,8 ,10,33,,5",
 "374900,8,8,1998/10/01 13:46:00,139.714161309,35.7491448983,1,4,3040108,4 ,9 ,71,,6",
 "455400,4,4,1998/10/01 13:46:00,139.823249377,35.9149666341,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:46:00,139.6183668698,35.9173540461,1,10,3512006,6 ,1 ,36,,6",
 "584200,2,2,1998/10/01 13:46:00,139.8166782071,35.7183745969,2,6,0332001,9 ,11,49,,1",
 "584200,2,3,1998/10/01 13:46:00,139.8166782071,35.7183745969,2,6,0332001,9 ,11,49,,12",
 "667000,6,6,1998/10/01 13:46:00,139.7942488351,35.7854197268,2,12,0614310,6 ,3 ,43,,2",
 "786600,3,5,1998/10/01 13:46:00,139.3674741626,35.6674819564,2,3,0930110,13,2 ,35,,9",
 "788900,4,4,1998/10/01 13:46:00,139.2754865689,35.8207962138,2,2,0931009,12,3 ,35,,1",
 "685400,4,5,1998/10/01 13:46:00,139.4490429028,35.6514872435,1,11,0634016,15,7 ,48,,12",
 "73600,4,6,1998/10/01 13:47:00,140.0910752028,35.7009488994,2,1,4410011,12,3 ,35,,9",
 "848700,2,4,1998/10/01 13:47:00,139.7126325793,35.6584655731,1,4,1313101,13,2 ,37,,1",
 "280600,9,9,1998/10/01 13:47:00,139.7632366669,35.7454140296,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,3,1998/10/01 13:47:00,139.7278455883,35.7393471293,2,13,0210202,14,7 ,53,,10",
 "71300,4,7,1998/10/01 13:47:00,139.7231491852,35.7698877132,1,12,4313309,9 ,13,34,,12",
 "234600,6,9,1998/10/01 13:47:00,139.6487206119,35.7052922235,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:47:00,139.5258139956,35.3481162946,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:47:00,140.0590409449,36.0569091589,2,15,5311005,15,4 ,29,,6",
 "271400,4,4,1998/10/01 13:47:00,139.5584246326,35.515662314,1,7,2811315,9 ,11,37,,8",
 "370300,4,8,1998/10/01 13:47:00,139.6536940716,35.8736000312,1,10,3037010,10,13,39,,1",
 "391000,4,6,1998/10/01 13:47:00,139.7570433407,35.6702895956,1,7,3110313,8 ,10,33,,5",
 "374900,8,8,1998/10/01 13:47:00,139.7162023002,35.7514034292,1,4,3040108,4 ,9 ,71,,6",
 "455400,4,4,1998/10/01 13:47:00,139.823467886,35.9141427782,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:47:00,139.6183373419,35.917468886,1,10,3512006,6 ,1 ,36,,6",
 "584200,2,3,1998/10/01 13:47:00,139.8195410518,35.7183987809,2,6,0332001,9 ,11,49,,12",
 "667000,6,6,1998/10/01 13:47:00,139.7929024609,35.7854030305,2,12,0614310,6 ,3 ,43,,2",
 "788900,4,4,1998/10/01 13:47:00,139.2753273878,35.821200941,2,2,0931009,12,3 ,35,,1",
 "786600,3,5,1998/10/01 13:47:00,139.367767059,35.6676049057,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:47:00,139.4442264921,35.6508840124,1,11,0634016,15,7 ,48,,12",
 "73600,4,6,1998/10/01 13:48:00,140.0906557292,35.7007680583,2,1,4410011,12,3 ,35,,9",
 "848700,2,4,1998/10/01 13:48:00,139.7133946237,35.6579190245,1,4,1313101,13,2 ,37,,1",
 "280600,9,9,1998/10/01 13:48:00,139.762546841,35.7478199613,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,3,1998/10/01 13:48:00,139.7278454566,35.7393470943,2,13,0210202,14,7 ,53,,10",
 "71300,4,7,1998/10/01 13:48:00,139.7222248492,35.7738562463,1,12,4313309,9 ,13,34,,12",
 "234600,6,9,1998/10/01 13:48:00,139.6445714219,35.7051565265,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:48:00,139.5154333346,35.3424963221,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:48:00,140.0648351613,36.0577782538,2,15,5311005,15,4 ,29,,6",
 "271400,4,4,1998/10/01 13:48:00,139.5560149032,35.51363103,1,7,2811315,9 ,11,37,,8",
 "370300,4,8,1998/10/01 13:48:00,139.6548676446,35.8737460158,1,10,3037010,10,13,39,,1",
 "391000,4,6,1998/10/01 13:48:00,139.759055414,35.6695643579,1,7,3110313,8 ,10,33,,5",
 "374900,8,8,1998/10/01 13:48:00,139.7150445057,35.7537696404,1,4,3040108,4 ,9 ,71,,6",
 "455400,4,4,1998/10/01 13:48:00,139.8237648013,35.9129963017,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:48:00,139.618307814,35.917583726,1,10,3512006,6 ,1 ,36,,6",
 "584200,2,3,1998/10/01 13:48:00,139.8183480785,35.716972578,2,6,0332001,9 ,11,49,,12",
 "667000,6,6,1998/10/01 13:48:00,139.7921097444,35.7845692852,2,12,0614310,6 ,3 ,43,,2",
 "786600,3,5,1998/10/01 13:48:00,139.3680599563,35.6677278543,2,3,0930110,13,2 ,35,,9",
 "788900,4,4,1998/10/01 13:48:00,139.2752864381,35.8216197435,2,2,0931009,12,3 ,35,,1",
 "685400,4,5,1998/10/01 13:48:00,139.4393321204,35.6519962205,1,11,0634016,15,7 ,48,,12",
 "73600,4,6,1998/10/01 13:49:00,140.0903194169,35.7005611766,2,1,4410011,12,3 ,35,,9",
 "848700,2,4,1998/10/01 13:49:00,139.7141566578,35.6573724711,1,4,1313101,13,2 ,37,,1",
 "280600,9,9,1998/10/01 13:49:00,139.7621824639,35.7502723411,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,3,1998/10/01 13:49:00,139.7278453249,35.7393470593,2,13,0210202,14,7 ,53,,10",
 "71300,4,7,1998/10/01 13:49:00,139.7210387916,35.7777398301,1,12,4313309,9 ,13,34,,12",
 "234600,6,9,1998/10/01 13:49:00,139.64042228,35.7050199198,1,14,2412116,10,10,63,,12",
 "253000,2,3,1998/10/01 13:49:00,139.50503953,35.3368926404,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:49:00,140.069920753,36.0576023687,2,15,5311005,15,4 ,29,,6",
 "271400,4,4,1998/10/01 13:49:00,139.5520133487,35.5132289718,1,7,2811315,9 ,11,37,,8",
 "370300,4,8,1998/10/01 13:49:00,139.6554052833,35.8743413396,1,10,3037010,10,13,39,,1",
 "391000,4,6,1998/10/01 13:49:00,139.7593287204,35.6682662617,1,7,3110313,8 ,10,33,,5",
 "374900,8,8,1998/10/01 13:49:00,139.716517603,35.7562873477,1,4,3040108,4 ,9 ,71,,6",
 "455400,4,4,1998/10/01 13:49:00,139.8234729599,35.9142081766,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:49:00,139.618278286,35.9176985659,1,10,3512006,6 ,1 ,36,,6",
 "584200,2,3,1998/10/01 13:49:00,139.8170299368,35.7149013982,2,6,0332001,9 ,11,49,,12",
 "667000,6,6,1998/10/01 13:49:00,139.791646705,35.7835854076,2,12,0614310,6 ,3 ,43,,2",
 "788900,4,4,1998/10/01 13:49:00,139.2755581261,35.8219720115,2,2,0931009,12,3 ,35,,1",
 "786600,3,5,1998/10/01 13:49:00,139.3683528546,35.6678508022,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:49:00,139.4355057045,35.6544797544,1,11,0634016,15,7 ,48,,12",
 "73600,4,6,1998/10/01 13:50:00,140.0900018348,35.7002556573,2,1,4410011,12,3 ,35,,9",
 "848700,2,4,1998/10/01 13:50:00,139.7140410986,35.6567911549,1,4,1313101,13,2 ,37,,1",
 "848700,3,5,1998/10/01 13:50:00,139.7140410986,35.6567911549,1,4,1313101,13,97,37,,97",
 "280600,9,9,1998/10/01 13:50:00,139.7622152543,35.7527187561,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,3,1998/10/01 13:50:00,139.7278451932,35.7393470243,2,13,0210202,14,7 ,53,,10",
 "71300,4,7,1998/10/01 13:50:00,139.718933316,35.7811922611,1,12,4313309,9 ,13,34,,12",
 "234600,6,9,1998/10/01 13:50:00,139.6363044357,35.7048979168,1,14,2412116,10,10,63,,12",
 "234600,6,10,1998/10/01 13:50:00,139.6363044357,35.7048979168,1,14,2412116,10,10,63,,1",
 "253000,2,3,1998/10/01 13:50:00,139.4931919669,35.3377436537,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:50:00,140.07583381,36.0582983042,2,15,5311005,15,4 ,29,,6",
 "271400,4,4,1998/10/01 13:50:00,139.5481468215,35.512506757,1,7,2811315,9 ,11,37,,8",
 "370300,4,8,1998/10/01 13:50:00,139.6559094333,35.8752161829,1,10,3037010,10,13,39,,1",
 "370300,5,9,1998/10/01 13:50:00,139.6559094333,35.8752161829,1,10,3037010,10,97,39,,97",
 "391000,4,6,1998/10/01 13:50:00,139.7612403192,35.6675677887,1,7,3110313,8 ,10,33,,5",
 "391000,5,7,1998/10/01 13:50:00,139.7612403192,35.6675677887,1,7,3110313,8 ,97,33,,97",
 "374900,8,8,1998/10/01 13:50:00,139.7149369602,35.757838656,1,4,3040108,4 ,9 ,71,,6",
 "374900,9,9,1998/10/01 13:50:00,139.7149369602,35.757838656,1,4,3040108,4 ,97,71,,97",
 "455400,4,4,1998/10/01 13:50:00,139.8231811095,35.9154200506,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:50:00,139.6182487579,35.9178134059,1,10,3512006,6 ,1 ,36,,6",
 "584200,2,3,1998/10/01 13:50:00,139.8157393379,35.7128192742,2,6,0332001,9 ,11,49,,12",
 "667000,6,6,1998/10/01 13:50:00,139.7915335579,35.7824919402,2,12,0614310,6 ,3 ,43,,2",
 "786600,3,5,1998/10/01 13:50:00,139.3686457537,35.6679737494,2,3,0930110,13,2 ,35,,9",
 "788900,4,4,1998/10/01 13:50:00,139.2759738591,35.8222288602,2,2,0931009,12,3 ,35,,1",
 "685400,4,5,1998/10/01 13:50:00,139.4318525301,35.6572384004,1,11,0634016,15,7 ,48,,12",
 "73600,4,6,1998/10/01 13:51:00,140.0896943485,35.6999433299,2,1,4410011,12,3 ,35,,9",
 "280600,9,9,1998/10/01 13:51:00,139.762859713,35.7551215159,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,3,1998/10/01 13:51:00,139.7278450615,35.7393469893,2,13,0210202,14,7 ,53,,10",
 "71300,4,7,1998/10/01 13:51:00,139.7171953701,35.7850412262,1,12,4313309,9 ,13,34,,12",
 "234600,6,10,1998/10/01 13:51:00,139.6370535535,35.7044210252,1,14,2412116,10,10,63,,1",
 "253000,2,3,1998/10/01 13:51:00,139.4809721822,35.3385479992,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:51:00,140.08060485,36.060736705,2,15,5311005,15,4 ,29,,6",
 "216200,4,4,1998/10/01 13:51:00,139.677809646,35.245816375,2,1,2221106,12,3 ,34,,1",
 "271400,4,4,1998/10/01 13:51:00,139.5453444195,35.5101472089,1,7,2811315,9 ,11,37,,8",
 "455400,4,4,1998/10/01 13:51:00,139.8227743037,35.9166050322,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:51:00,139.6182192298,35.9179282458,1,10,3512006,6 ,1 ,36,,6",
 "584200,2,3,1998/10/01 13:51:00,139.8138316906,35.7110919184,2,6,0332001,9 ,11,49,,12",
 "667000,6,6,1998/10/01 13:51:00,139.7903118328,35.7824202415,2,12,0614310,6 ,3 ,43,,2",
 "786600,3,5,1998/10/01 13:51:00,139.3689386538,35.6680966959,2,3,0930110,13,2 ,35,,9",
 "788900,4,4,1998/10/01 13:51:00,139.2764158739,35.8224340695,2,2,0931009,12,3 ,35,,1",
 "685400,4,5,1998/10/01 13:51:00,139.426984558,35.6584255299,1,11,0634016,15,7 ,48,,12",
 "706100,4,7,1998/10/01 13:51:00,139.8479966552,35.6877249703,1,14,0730209,10,1 ,26,,1",
 "73600,4,6,1998/10/01 13:52:00,140.0894029895,35.6996210099,2,1,4410011,12,3 ,35,,9",
 "280600,9,9,1998/10/01 13:52:00,139.7620906105,35.7574853854,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,3,1998/10/01 13:52:00,139.7278449298,35.7393469543,2,13,0210202,14,7 ,53,,10",
 "71300,4,7,1998/10/01 13:52:00,139.7172395412,35.7891143047,1,12,4313309,9 ,13,34,,12",
 "234600,6,10,1998/10/01 13:52:00,139.6380030399,35.7038437881,1,14,2412116,10,10,63,,1",
 "253000,2,3,1998/10/01 13:52:00,139.4685528328,35.3379382012,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:52:00,140.0836670638,36.0640962909,2,15,5311005,15,4 ,29,,6",
 "216200,4,4,1998/10/01 13:52:00,139.6779195735,35.2460311655,2,1,2221106,12,3 ,34,,1",
 "271400,4,4,1998/10/01 13:52:00,139.5435381878,35.5072031452,1,7,2811315,9 ,11,37,,8",
 "455400,4,4,1998/10/01 13:52:00,139.8222301062,35.9177578723,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:52:00,139.6181909813,35.9180432962,1,10,3512006,6 ,1 ,36,,6",
 "584200,2,3,1998/10/01 13:52:00,139.8115390223,35.7096938625,2,6,0332001,9 ,11,49,,12",
 "667000,6,6,1998/10/01 13:52:00,139.7889659715,35.7824510226,2,12,0614310,6 ,3 ,43,,2",
 "786600,3,5,1998/10/01 13:52:00,139.3692315547,35.6682196417,2,3,0930110,13,2 ,35,,9",
 "788900,4,4,1998/10/01 13:52:00,139.2768931831,35.8222634222,2,2,0931009,12,3 ,35,,1",
 "685400,4,5,1998/10/01 13:52:00,139.4221077016,35.6595891268,1,11,0634016,15,7 ,48,,12",
 "706100,4,7,1998/10/01 13:52:00,139.8467541169,35.6877438627,1,14,0730209,10,1 ,26,,1",
 "73600,4,6,1998/10/01 13:53:00,140.0896952086,35.6994044509,2,1,4410011,12,3 ,35,,9",
 "280600,9,9,1998/10/01 13:53:00,139.7609273165,35.7596512995,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,3,1998/10/01 13:53:00,139.7278447981,35.7393469193,2,13,0210202,14,7 ,53,,10",
 "71300,4,7,1998/10/01 13:53:00,139.7176694017,35.7932027257,1,12,4313309,9 ,13,34,,12",
 "234600,6,10,1998/10/01 13:53:00,139.6388595145,35.7031793946,1,14,2412116,10,10,63,,1",
 "253000,2,3,1998/10/01 13:53:00,139.4561337253,35.3373265245,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:53:00,140.0869623317,36.0673779128,2,15,5311005,15,4 ,29,,6",
 "216200,4,4,1998/10/01 13:53:00,139.6779460182,35.2462295986,2,1,2221106,12,3 ,34,,1",
 "271400,4,4,1998/10/01 13:53:00,139.543317452,35.5039507389,1,7,2811315,9 ,11,37,,8",
 "455400,4,4,1998/10/01 13:53:00,139.8216858929,35.9189107098,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:53:00,139.6181633414,35.9181584467,1,10,3512006,6 ,1 ,36,,6",
 "584200,2,3,1998/10/01 13:53:00,139.8089691402,35.7088390157,2,6,0332001,9 ,11,49,,12",
 "667000,6,6,1998/10/01 13:53:00,139.7876199201,35.782471756,2,12,0614310,6 ,3 ,43,,2",
 "788900,4,4,1998/10/01 13:53:00,139.2773896251,35.8221348853,2,2,0931009,12,3 ,35,,1",
 "786600,3,5,1998/10/01 13:53:00,139.3695244566,35.6683425867,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:53:00,139.4172369503,35.6607694495,1,11,0634016,15,7 ,48,,12",
 "706100,4,7,1998/10/01 13:53:00,139.8464842997,35.688582968,1,14,0730209,10,1 ,26,,1",
 "73600,4,6,1998/10/01 13:54:00,140.090127366,35.6992142083,2,1,4410011,12,3 ,35,,9",
 "280600,9,9,1998/10/01 13:54:00,139.7589737795,35.761528543,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,3,1998/10/01 13:54:00,139.7278446664,35.7393468842,2,13,0210202,14,7 ,53,,10",
 "71300,4,7,1998/10/01 13:54:00,139.7181561188,35.7972833249,1,12,4313309,9 ,13,34,,12",
 "234600,6,10,1998/10/01 13:54:00,139.6396913937,35.70249196,1,14,2412116,10,10,63,,1",
 "253000,2,3,1998/10/01 13:54:00,139.4437143515,35.3367783364,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:54:00,140.0900262241,36.0702416836,2,15,5311005,15,4 ,29,,6",
 "216200,4,4,1998/10/01 13:54:00,139.6777296326,35.2463804523,2,1,2221106,12,3 ,34,,1",
 "271400,4,4,1998/10/01 13:54:00,139.54162429,35.5010218521,1,7,2811315,9 ,11,37,,8",
 "455400,4,4,1998/10/01 13:54:00,139.8212166984,35.9200849794,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:54:00,139.6181357014,35.9182735972,1,10,3512006,6 ,1 ,36,,6",
 "584200,2,3,1998/10/01 13:54:00,139.8061093391,35.70873479,2,6,0332001,9 ,11,49,,12",
 "667000,6,6,1998/10/01 13:54:00,139.7862740604,35.7825069431,2,12,0614310,6 ,3 ,43,,2",
 "788900,4,4,1998/10/01 13:54:00,139.2779069856,35.8220902856,2,2,0931009,12,3 ,35,,1",
 "786600,3,5,1998/10/01 13:54:00,139.3698176169,35.668465121,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:54:00,139.4155619712,35.6607924515,1,11,0634016,15,7 ,48,,12",
 "706100,4,7,1998/10/01 13:54:00,139.8464655416,35.6896086152,1,14,0730209,10,1 ,26,,1",
 "73600,4,6,1998/10/01 13:55:00,140.0905595214,35.6990239642,2,1,4410011,12,3 ,35,,9",
 "280600,9,9,1998/10/01 13:55:00,139.7599577407,35.7631746597,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,3,1998/10/01 13:55:00,139.7278445347,35.7393468492,2,13,0210202,14,7 ,53,,10",
 "71300,4,7,1998/10/01 13:55:00,139.7177101073,35.8013284279,1,12,4313309,9 ,13,34,,12",
 "234600,6,10,1998/10/01 13:55:00,139.6402432115,35.7016386389,1,14,2412116,10,10,63,,1",
 "253000,2,3,1998/10/01 13:55:00,139.4313064013,35.3360525279,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:55:00,140.0949496945,36.072018639,2,15,5311005,15,4 ,29,,6",
 "216200,4,4,1998/10/01 13:55:00,139.6775132461,35.2465313056,2,1,2221106,12,3 ,34,,1",
 "271400,4,4,1998/10/01 13:55:00,139.5388169407,35.49865971,1,7,2811315,9 ,11,37,,8",
 "455400,4,4,1998/10/01 13:55:00,139.8206794004,35.9212373448,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:55:00,139.6181080613,35.9183887477,1,10,3512006,6 ,1 ,36,,6",
 "584200,2,3,1998/10/01 13:55:00,139.8032555182,35.7085700362,2,6,0332001,9 ,11,49,,12",
 "667000,6,6,1998/10/01 13:55:00,139.7849284996,35.7825447905,2,12,0614310,6 ,3 ,43,,2",
 "786600,3,5,1998/10/01 13:55:00,139.3701109728,35.6685873445,2,3,0930110,13,2 ,35,,9",
 "788900,4,4,1998/10/01 13:55:00,139.2784255992,35.8221270008,2,2,0931009,12,3 ,35,,1",
 "685400,4,5,1998/10/01 13:55:00,139.413890241,35.6573949305,1,11,0634016,15,7 ,48,,12",
 "706100,4,7,1998/10/01 13:55:00,139.8471481275,35.6897656582,1,14,0730209,10,1 ,26,,1",
 "706100,4,8,1998/10/01 13:55:00,139.8471481275,35.6897656582,1,14,0730209,10,1 ,26,,12",
 "73600,4,6,1998/10/01 13:56:00,140.0909916748,35.6988337186,2,1,4410011,12,3 ,35,,9",
 "280600,9,9,1998/10/01 13:56:00,139.7626336078,35.7643372421,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,3,1998/10/01 13:56:00,139.727844403,35.7393468142,2,13,0210202,14,7 ,53,,10",
 "71300,4,7,1998/10/01 13:56:00,139.7149987404,35.8047589411,1,12,4313309,9 ,13,34,,12",
 "234600,6,10,1998/10/01 13:56:00,139.6407871963,35.7007806824,1,14,2412116,10,10,63,,1",
 "253000,2,3,1998/10/01 13:56:00,139.4190657627,35.3347167077,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:56:00,140.1001611469,36.0744664604,2,15,5311005,15,4 ,29,,6",
 "216200,4,4,1998/10/01 13:56:00,139.6773468485,35.2467108719,2,1,2221106,12,3 ,34,,1",
 "271400,4,4,1998/10/01 13:56:00,139.5359865819,35.4963157928,1,7,2811315,9 ,11,37,,8",
 "455400,4,4,1998/10/01 13:56:00,139.8199746939,35.922330678,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:56:00,139.6180760324,35.9185031054,1,10,3512006,6 ,1 ,36,,6",
 "584200,2,3,1998/10/01 13:56:00,139.8006198935,35.7091926593,2,6,0332001,9 ,11,49,,12",
 "667000,6,6,1998/10/01 13:56:00,139.7835833454,35.7825902922,2,12,0614310,6 ,3 ,43,,2",
 "788900,4,4,1998/10/01 13:56:00,139.2788965549,35.8220199429,2,2,0931009,12,3 ,35,,1",
 "786600,3,5,1998/10/01 13:56:00,139.3704028474,35.6687117934,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:56:00,139.4105350554,35.6547979319,1,11,0634016,15,7 ,48,,12",
 "706100,4,8,1998/10/01 13:56:00,139.8434389202,35.6902734882,1,14,0730209,10,1 ,26,,12",
 "73600,4,6,1998/10/01 13:57:00,140.0912803606,35.6986130672,2,1,4410011,12,3 ,35,,9",
 "280600,9,9,1998/10/01 13:57:00,139.762321167,35.7663996363,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,3,1998/10/01 13:57:00,139.7278442713,35.7393467792,2,13,0210202,14,7 ,53,,10",
 "71300,4,7,1998/10/01 13:57:00,139.7118097656,35.8079354888,1,12,4313309,9 ,13,34,,12",
 "234600,6,10,1998/10/01 13:57:00,139.6413311695,35.6999227233,1,14,2412116,10,10,63,,1",
 "253000,2,3,1998/10/01 13:57:00,139.4077252009,35.3305255396,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:57:00,140.1053940882,36.0768985207,2,15,5311005,15,4 ,29,,6",
 "216200,4,4,1998/10/01 13:57:00,139.6772480685,35.2469292772,2,1,2221106,12,3 ,34,,1",
 "271400,4,4,1998/10/01 13:57:00,139.5331086963,35.4940641099,1,7,2811315,9 ,11,37,,8",
 "455400,4,4,1998/10/01 13:57:00,139.8193561871,35.9234570108,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:57:00,139.6180411603,35.9186169496,1,10,3512006,6 ,1 ,36,,6",
 "584200,2,3,1998/10/01 13:57:00,139.79790988,35.7096980533,2,6,0332001,9 ,11,49,,12",
 "667000,6,6,1998/10/01 13:57:00,139.7822367722,35.7825985727,2,12,0614310,6 ,3 ,43,,2",
 "788900,4,4,1998/10/01 13:57:00,139.2793332196,35.8217872713,2,2,0931009,12,3 ,35,,1",
 "786600,3,5,1998/10/01 13:57:00,139.3706956169,35.6688339899,2,3,0930110,13,2 ,35,,9",
 "685400,4,5,1998/10/01 13:57:00,139.4080174469,35.6512779648,1,11,0634016,15,7 ,48,,12",
 "706100,4,8,1998/10/01 13:57:00,139.839344384,35.6899950115,1,14,0730209,10,1 ,26,,12",
 "73600,4,6,1998/10/01 13:58:00,140.0910163899,35.698275292,2,1,4410011,12,3 ,35,,9",
 "280600,9,9,1998/10/01 13:58:00,139.7617996235,35.7687188623,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,3,1998/10/01 13:58:00,139.7278441396,35.7393467442,2,13,0210202,14,7 ,53,,10",
 "71300,4,7,1998/10/01 13:58:00,139.7086334551,35.8111204422,1,12,4313309,9 ,13,34,,12",
 "234600,6,10,1998/10/01 13:58:00,139.641875131,35.6990647616,1,14,2412116,10,10,63,,1",
 "253000,2,3,1998/10/01 13:58:00,139.3960580091,35.3272636131,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:58:00,140.11063431,36.079320659,2,15,5311005,15,4 ,29,,6",
 "216200,4,4,1998/10/01 13:58:00,139.6771492881,35.2471476824,2,1,2221106,12,3 ,34,,1",
 "271400,4,4,1998/10/01 13:58:00,139.5298963911,35.4920793399,1,7,2811315,9 ,11,37,,8",
 "455400,4,4,1998/10/01 13:58:00,139.8187830643,35.924600531,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:58:00,139.618006288,35.9187307938,1,10,3512006,6 ,1 ,36,,6",
 "584200,2,3,1998/10/01 13:58:00,139.7958183084,35.7081865603,2,6,0332001,9 ,11,49,,12",
 "667000,6,6,1998/10/01 13:58:00,139.7815490432,35.782158487,2,12,0614310,6 ,3 ,43,,2",
 "786600,3,5,1998/10/01 13:58:00,139.3709932679,35.6689489638,2,3,0930110,13,2 ,35,,9",
 "788900,4,4,1998/10/01 13:58:00,139.2797698818,35.821554598,2,2,0931009,12,3 ,35,,1",
 "685400,4,5,1998/10/01 13:58:00,139.4040205259,35.6490651245,1,11,0634016,15,7 ,48,,12",
 "685400,4,6,1998/10/01 13:58:00,139.4040205259,35.6490651245,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 13:58:00,139.8352450583,35.6898205621,1,14,0730209,10,1 ,26,,12",
 "73600,4,6,1998/10/01 13:59:00,140.0905794561,35.6983570682,2,1,4410011,12,3 ,35,,9",
 "280600,9,9,1998/10/01 13:59:00,139.7618896294,35.771045313,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,3,1998/10/01 13:59:00,139.7278440079,35.7393467092,2,13,0210202,14,7 ,53,,10",
 "71300,4,7,1998/10/01 13:59:00,139.7054811544,35.8143213173,1,12,4313309,9 ,13,34,,12",
 "234600,6,10,1998/10/01 13:59:00,139.6424190807,35.6982067973,1,14,2412116,10,10,63,,1",
 "253000,2,3,1998/10/01 13:59:00,139.3838102962,35.328978369,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 13:59:00,140.1158796402,36.0817354532,2,15,5311005,15,4 ,29,,6",
 "216200,4,4,1998/10/01 13:59:00,139.6770505071,35.2473660875,2,1,2221106,12,3 ,34,,1",
 "271400,4,4,1998/10/01 13:59:00,139.5265753454,35.4902783137,1,7,2811315,9 ,11,37,,8",
 "312800,10,10,1998/10/01 13:59:00,139.7015267732,35.5069181794,2,6,2015203,14,8 ,34,,2",
 "411700,4,4,1998/10/01 13:59:00,139.4271690942,35.7926290149,2,1,3122110,12,3 ,29,,1",
 "455400,4,4,1998/10/01 13:59:00,139.8182099433,35.9257433689,1,12,3226003,3 ,3 ,31,,8",
 "487600,6,6,1998/10/01 13:59:00,139.6179714157,35.9188446379,1,10,3512006,6 ,1 ,36,,6",
 "584200,2,3,1998/10/01 13:59:00,139.7942940256,35.7062140963,2,6,0332001,9 ,11,49,,12",
 "667000,6,6,1998/10/01 13:59:00,139.7811146939,35.7812568311,2,12,0614310,6 ,3 ,43,,2",
 "786600,3,5,1998/10/01 13:59:00,139.3712892429,35.6690669108,2,3,0930110,13,2 ,35,,9",
 "788900,4,4,1998/10/01 13:59:00,139.2802065415,35.8213219232,2,2,0931009,12,3 ,35,,1",
 "685400,4,6,1998/10/01 13:59:00,139.4043744596,35.6493076468,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 13:59:00,139.831156966,35.6894251229,1,14,0730209,10,1 ,26,,12",
 "73600,4,6,1998/10/01 14:00:00,140.0901189153,35.6984961013,2,1,4410011,12,3 ,35,,9",
 "73600,5,7,1998/10/01 14:00:00,140.0901189153,35.6984961013,2,1,4410011,12,97,35,,97",
 "280600,9,9,1998/10/01 14:00:00,139.7615414238,35.7735001275,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,3,1998/10/01 14:00:00,139.7278438761,35.7393466742,2,13,0210202,14,7 ,53,,10",
 "561200,2,4,1998/10/01 14:00:00,139.7278438761,35.7393466742,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 14:00:00,139.7022941484,35.8174987943,1,12,4313309,9 ,13,34,,12",
 "234600,6,10,1998/10/01 14:00:00,139.6429630188,35.6973488303,1,14,2412116,10,10,63,,1",
 "234600,7,11,1998/10/01 14:00:00,139.6429630188,35.6973488303,1,14,2412116,10,97,63,,97",
 "253000,2,3,1998/10/01 14:00:00,139.3720354533,35.331902144,1,8,2520301,9 ,3 ,30,,12",
 "188600,2,2,1998/10/01 14:00:00,140.121164664,36.0840911538,2,15,5311005,15,4 ,29,,6",
 "216200,4,4,1998/10/01 14:00:00,139.6769517256,35.2475844925,2,1,2221106,12,3 ,34,,1",
 "271400,4,4,1998/10/01 14:00:00,139.523996065,35.4878234129,1,7,2811315,9 ,11,37,,8",
 "312800,10,10,1998/10/01 14:00:00,139.7026444455,35.5071916534,2,6,2015203,14,8 ,34,,2",
 "312800,11,11,1998/10/01 14:00:00,139.7026444455,35.5071916534,2,6,2015203,14,97,34,,97",
 "411700,4,4,1998/10/01 14:00:00,139.4280780496,35.7924119487,2,1,3122110,12,3 ,29,,1",
 "455400,4,4,1998/10/01 14:00:00,139.8172558244,35.9264218701,1,12,3226003,3 ,3 ,31,,8",
 "455400,5,5,1998/10/01 14:00:00,139.8172558244,35.9264218701,1,12,3226003,3 ,97,31,,97",
 "487600,6,6,1998/10/01 14:00:00,139.6179365433,35.9189584821,1,10,3512006,6 ,1 ,36,,6",
 "584200,2,3,1998/10/01 14:00:00,139.7924467453,35.7044362825,2,6,0332001,9 ,11,49,,12",
 "667000,6,6,1998/10/01 14:00:00,139.7804425622,35.7806041969,2,12,0614310,6 ,3 ,43,,2",
 "788900,4,4,1998/10/01 14:00:00,139.2806431986,35.8210892467,2,2,0931009,12,3 ,35,,1",
 "788900,5,5,1998/10/01 14:00:00,139.2806431986,35.8210892467,2,2,0931009,12,97,35,,97",
 "786600,3,5,1998/10/01 14:00:00,139.3715852188,35.6691848571,2,3,0930110,13,2 ,35,,9",
 "685400,4,6,1998/10/01 14:00:00,139.4047283954,35.6495501681,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 14:00:00,139.8270512539,35.6893376148,1,14,0730209,10,1 ,26,,12",
 "280600,9,9,1998/10/01 14:01:00,139.7611681734,35.775951747,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,4,1998/10/01 14:01:00,139.726563487,35.7393518227,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 14:01:00,139.6990884831,35.8206627084,1,12,4313309,9 ,13,34,,12",
 "253000,2,3,1998/10/01 14:01:00,139.3599034089,35.3297017268,1,8,2520301,9 ,3 ,30,,12",
 "66700,6,6,1998/10/01 14:01:00,139.9478438439,35.8512761038,1,11,4311002,3 ,9 ,37,,2",
 "188600,2,2,1998/10/01 14:01:00,140.1265346053,36.0863113999,2,15,5311005,15,4 ,29,,6",
 "216200,4,4,1998/10/01 14:01:00,139.6768529435,35.2478028974,2,1,2221106,12,3 ,34,,1",
 "271400,4,4,1998/10/01 14:01:00,139.5210572838,35.4856029279,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:01:00,139.4289630876,35.4882187638,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:01:00,139.3740661228,35.5675163913,2,12,2924401,14,3 ,33,,6",
 "331200,4,4,1998/10/01 14:01:00,139.5778480583,35.5707193701,2,1,2121208,12,3 ,28,,1",
 "448500,4,6,1998/10/01 14:01:00,139.728774184,35.7581178611,2,7,3222102,4 ,4 ,27,,1",
 "411700,4,4,1998/10/01 14:01:00,139.428204735,35.7925184164,2,1,3122110,12,3 ,29,,1",
 "441600,6,6,1998/10/01 14:01:00,139.7317752909,35.691504462,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:01:00,139.6179016707,35.9190723262,1,10,3512006,6 ,1 ,36,,6",
 "499100,2,2,1998/10/01 14:01:00,139.7401060321,36.0256995024,2,11,3612106,14,7 ,39,,2",
 "581900,4,6,1998/10/01 14:01:00,139.7314458985,35.7269009213,1,9,0321101,5 ,3 ,97,,1",
 "584200,2,3,1998/10/01 14:01:00,139.7904011225,35.7028038213,2,6,0332001,9 ,11,49,,12",
 "627900,4,4,1998/10/01 14:01:00,139.6512496621,35.7496187733,2,7,0511204,9 ,3 ,34,,2",
 "667000,6,6,1998/10/01 14:01:00,139.7791197328,35.7804948599,2,12,0614310,6 ,3 ,43,,2",
 "690000,4,4,1998/10/01 14:01:00,139.5717395144,35.6670933005,2,1,0710226,12,3 ,18,,2",
 "786600,3,5,1998/10/01 14:01:00,139.3718811956,35.6693028026,2,3,0930110,13,2 ,35,,9",
 "857900,2,2,1998/10/01 14:01:00,139.5074672968,35.5553473071,1,11,1340115,10,4 ,37,,6",
 "685400,4,6,1998/10/01 14:01:00,139.4050823334,35.6497926883,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 14:01:00,139.8229371206,35.6892509265,1,14,0730209,10,1 ,26,,12",
 "280600,9,9,1998/10/01 14:02:00,139.7605598237,35.778362927,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,4,1998/10/01 14:02:00,139.7258782023,35.7385903538,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 14:02:00,139.6953618163,35.8234234246,1,12,4313309,9 ,13,34,,12",
 "253000,2,3,1998/10/01 14:02:00,139.3477827798,35.3274121661,1,8,2520301,9 ,3 ,30,,12",
 "66700,6,6,1998/10/01 14:02:00,139.947894107,35.8509587048,1,11,4311002,3 ,9 ,37,,2",
 "188600,2,2,1998/10/01 14:02:00,140.1320000166,36.0883736695,2,15,5311005,15,4 ,29,,6",
 "216200,4,4,1998/10/01 14:02:00,139.6768211613,35.2480095243,2,1,2221106,12,3 ,34,,1",
 "271400,4,4,1998/10/01 14:02:00,139.5176775277,35.4838249023,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:02:00,139.4287372859,35.4877480479,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:02:00,139.3733214204,35.5664515132,2,12,2924401,14,3 ,33,,6",
 "331200,4,4,1998/10/01 14:02:00,139.5782585047,35.5708223932,2,1,2121208,12,3 ,28,,1",
 "448500,4,6,1998/10/01 14:02:00,139.7295005304,35.7579117306,2,7,3222102,4 ,4 ,27,,1",
 "411700,4,4,1998/10/01 14:02:00,139.4282085897,35.7932898727,2,1,3122110,12,3 ,29,,1",
 "441600,6,6,1998/10/01 14:02:00,139.7314186443,35.6912574782,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:02:00,139.6178667981,35.9191861704,1,10,3512006,6 ,1 ,36,,6",
 "499100,2,2,1998/10/01 14:02:00,139.7387573994,36.0251338032,2,11,3612106,14,7 ,39,,2",
 "581900,4,6,1998/10/01 14:02:00,139.7306771898,35.7281398967,1,9,0321101,5 ,3 ,97,,1",
 "584200,2,3,1998/10/01 14:02:00,139.7886939041,35.7009483795,2,6,0332001,9 ,11,49,,12",
 "627900,4,4,1998/10/01 14:02:00,139.6500673511,35.7487045773,2,7,0511204,9 ,3 ,34,,2",
 "667000,6,6,1998/10/01 14:02:00,139.7781183844,35.7802774312,2,12,0614310,6 ,3 ,43,,2",
 "690000,4,4,1998/10/01 14:02:00,139.5709719858,35.6671484839,2,1,0710226,12,3 ,18,,2",
 "786600,3,5,1998/10/01 14:02:00,139.3721771732,35.6694207475,2,3,0930110,13,2 ,35,,9",
 "857900,2,2,1998/10/01 14:02:00,139.5075923806,35.5563626877,1,11,1340115,10,4 ,37,,6",
 "685400,4,6,1998/10/01 14:02:00,139.4054362735,35.6500352075,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 14:02:00,139.8188253923,35.6890851826,1,14,0730209,10,1 ,26,,12",
 "280600,9,9,1998/10/01 14:03:00,139.7608198586,35.7808243488,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,4,1998/10/01 14:03:00,139.7249517714,35.737917288,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 14:03:00,139.6919076575,35.8263969297,1,12,4313309,9 ,13,34,,12",
 "253000,2,3,1998/10/01 14:03:00,139.3360363231,35.3242102881,1,8,2520301,9 ,3 ,30,,12",
 "66700,6,6,1998/10/01 14:03:00,139.9479443696,35.8506413059,1,11,4311002,3 ,9 ,37,,2",
 "188600,2,2,1998/10/01 14:03:00,140.1375384015,36.0903115421,2,15,5311005,15,4 ,29,,6",
 "216200,4,4,1998/10/01 14:03:00,139.6770203654,35.2481755464,2,1,2221106,12,3 ,34,,1",
 "271400,4,4,1998/10/01 14:03:00,139.5140499543,35.4823881729,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:03:00,139.4285114869,35.4872773316,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:03:00,139.3725255434,35.5654114054,2,12,2924401,14,3 ,33,,6",
 "331200,4,4,1998/10/01 14:03:00,139.5786689521,35.570925415,2,1,2121208,12,3 ,28,,1",
 "448500,4,6,1998/10/01 14:03:00,139.730273992,35.7582187379,2,7,3222102,4 ,4 ,27,,1",
 "411700,4,4,1998/10/01 14:03:00,139.4286850157,35.7939477543,2,1,3122110,12,3 ,29,,1",
 "441600,6,6,1998/10/01 14:03:00,139.7343324967,35.6917803581,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:03:00,139.6178319254,35.9193000145,1,10,3512006,6 ,1 ,36,,6",
 "499100,2,2,1998/10/01 14:03:00,139.737224508,36.0245347229,2,11,3612106,14,7 ,39,,2",
 "581900,4,6,1998/10/01 14:03:00,139.7304050132,35.7295047391,1,9,0321101,5 ,3 ,97,,1",
 "584200,2,3,1998/10/01 14:03:00,139.7872939379,35.6989125381,2,6,0332001,9 ,11,49,,12",
 "627900,4,4,1998/10/01 14:03:00,139.6494780808,35.7474400249,2,7,0511204,9 ,3 ,34,,2",
 "667000,6,6,1998/10/01 14:03:00,139.7771494182,35.7806657379,2,12,0614310,6 ,3 ,43,,2",
 "690000,4,4,1998/10/01 14:03:00,139.5705524672,35.6675103508,2,1,0710226,12,3 ,18,,2",
 "786600,3,5,1998/10/01 14:03:00,139.3724731518,35.6695386916,2,3,0930110,13,2 ,35,,9",
 "857900,2,2,1998/10/01 14:03:00,139.5069310053,35.5578760621,1,11,1340115,10,4 ,37,,6",
 "685400,4,6,1998/10/01 14:03:00,139.4056432682,35.650202143,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 14:03:00,139.8147300797,35.6888213836,1,14,0730209,10,1 ,26,,12",
 "280600,9,9,1998/10/01 14:04:00,139.7610604447,35.7832876383,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,4,1998/10/01 14:04:00,139.7237225065,35.7380693487,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 14:04:00,139.6886932168,35.8295565651,1,12,4313309,9 ,13,34,,12",
 "253000,2,3,1998/10/01 14:04:00,139.3252193286,35.3192437933,1,8,2520301,9 ,3 ,30,,12",
 "66700,6,6,1998/10/01 14:04:00,139.9479946319,35.8503239069,1,11,4311002,3 ,9 ,37,,2",
 "188600,2,2,1998/10/01 14:04:00,140.1414584015,36.0915674929,2,15,5311005,15,4 ,29,,6",
 "216200,4,4,1998/10/01 14:04:00,139.6772195704,35.2483415681,2,1,2221106,12,3 ,34,,1",
 "271400,4,4,1998/10/01 14:04:00,139.5129643979,35.4799028748,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:04:00,139.4282856904,35.4868066149,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:04:00,139.3717359928,35.5643679236,2,12,2924401,14,3 ,33,,6",
 "331200,4,4,1998/10/01 14:04:00,139.5790794006,35.5710284354,2,1,2121208,12,3 ,28,,1",
 "448500,4,6,1998/10/01 14:04:00,139.7310183164,35.7585902612,2,7,3222102,4 ,4 ,27,,1",
 "411700,4,4,1998/10/01 14:04:00,139.4292315328,35.7945780649,2,1,3122110,12,3 ,29,,1",
 "441600,6,6,1998/10/01 14:04:00,139.7364948989,35.6912135495,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:04:00,139.6177970677,35.9194138615,1,10,3512006,6 ,1 ,36,,6",
 "499100,2,2,1998/10/01 14:04:00,139.7357643932,36.0254334567,2,11,3612106,14,7 ,39,,2",
 "581900,4,6,1998/10/01 14:04:00,139.7300621525,35.7306336042,1,9,0321101,5 ,3 ,97,,1",
 "584200,2,3,1998/10/01 14:04:00,139.785918989,35.6968661725,2,6,0332001,9 ,11,49,,12",
 "627900,4,4,1998/10/01 14:04:00,139.6488532164,35.7461867823,2,7,0511204,9 ,3 ,34,,2",
 "667000,6,6,1998/10/01 14:04:00,139.7758789797,35.7805482167,2,12,0614310,6 ,3 ,43,,2",
 "690000,4,4,1998/10/01 14:04:00,139.5703660956,35.6681203654,2,1,0710226,12,3 ,18,,2",
 "786600,3,5,1998/10/01 14:04:00,139.3727691312,35.669656635,2,3,0930110,13,2 ,35,,9",
 "857900,2,2,1998/10/01 14:04:00,139.5064406862,35.5595185677,1,11,1340115,10,4 ,37,,6",
 "685400,4,6,1998/10/01 14:04:00,139.4052363472,35.6500257839,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 14:04:00,139.8106161522,35.6886992433,1,14,0730209,10,1 ,26,,12",
 "280600,9,9,1998/10/01 14:05:00,139.761487425,35.7857322623,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,4,1998/10/01 14:05:00,139.722533158,35.7376524183,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 14:05:00,139.6854665261,35.8327080986,1,12,4313309,9 ,13,34,,12",
 "253000,2,3,1998/10/01 14:05:00,139.3154848388,35.3128989211,1,8,2520301,9 ,3 ,30,,12",
 "66700,6,6,1998/10/01 14:05:00,139.9480448938,35.8500065078,1,11,4311002,3 ,9 ,37,,2",
 "188600,2,2,1998/10/01 14:05:00,140.1413757799,36.0963475309,2,15,5311005,15,4 ,29,,6",
 "216200,4,4,1998/10/01 14:05:00,139.6774505248,35.2484666953,2,1,2221106,12,3 ,34,,1",
 "271400,4,4,1998/10/01 14:05:00,139.5109745708,35.4772112464,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:05:00,139.4280598966,35.4863358977,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:05:00,139.370562503,35.5646273905,2,12,2924401,14,3 ,33,,6",
 "331200,4,4,1998/10/01 14:05:00,139.579359815,35.5712785659,2,1,2121208,12,3 ,28,,1",
 "448500,4,6,1998/10/01 14:05:00,139.7314054857,35.7591203758,2,7,3222102,4 ,4 ,27,,1",
 "411700,4,4,1998/10/01 14:05:00,139.4297780584,35.7952083731,2,1,3122110,12,3 ,29,,1",
 "441600,6,6,1998/10/01 14:05:00,139.7393198563,35.6920184666,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:05:00,139.6177649392,35.9195282387,1,10,3512006,6 ,1 ,36,,6",
 "499100,2,2,1998/10/01 14:05:00,139.7369117,36.0254451672,2,11,3612106,14,7 ,39,,2",
 "499100,3,3,1998/10/01 14:05:00,139.7369117,36.0254451672,2,11,3612106,14,97,39,,97",
 "581900,4,6,1998/10/01 14:05:00,139.7293785169,35.7311266381,1,9,0321101,5 ,3 ,97,,1",
 "584200,2,3,1998/10/01 14:05:00,139.7852009312,35.6946229853,2,6,0332001,9 ,11,49,,12",
 "627900,4,4,1998/10/01 14:05:00,139.6482841289,35.7449235358,2,7,0511204,9 ,3 ,34,,2",
 "667000,6,6,1998/10/01 14:05:00,139.7761613173,35.7805709408,2,12,0614310,6 ,3 ,43,,2",
 "667000,7,7,1998/10/01 14:05:00,139.7761613173,35.7805709408,2,12,0614310,6 ,97,43,,97",
 "690000,4,4,1998/10/01 14:05:00,139.5702449572,35.6687386702,2,1,0710226,12,3 ,18,,2",
 "786600,3,5,1998/10/01 14:05:00,139.3728356159,35.6696822807,2,3,0930110,13,2 ,35,,9",
 "685400,4,6,1998/10/01 14:05:00,139.4048593641,35.6498081595,1,11,0634016,15,7 ,48,,10",
 "857900,2,2,1998/10/01 14:05:00,139.5053864822,35.5605903829,1,11,1340115,10,4 ,37,,6",
 "857900,3,3,1998/10/01 14:05:00,139.5053864822,35.5605903829,1,11,1340115,10,97,37,,97",
 "706100,4,8,1998/10/01 14:05:00,139.8065130892,35.6884259893,1,14,0730209,10,1 ,26,,12",
 "280600,9,9,1998/10/01 14:06:00,139.7619877121,35.7881692913,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,4,1998/10/01 14:06:00,139.7213435398,35.7376049271,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 14:06:00,139.6822790791,35.8358861765,1,12,4313309,9 ,13,34,,12",
 "6900,6,6,1998/10/01 14:06:00,139.9197607897,35.7165147169,2,11,4114005,14,4 ,32,,1",
 "253000,2,3,1998/10/01 14:06:00,139.3044163291,35.3088411972,1,8,2520301,9 ,3 ,30,,12",
 "66700,6,6,1998/10/01 14:06:00,139.9479819679,35.8501301311,1,11,4311002,3 ,9 ,37,,2",
 "188600,2,2,1998/10/01 14:06:00,140.1418191512,36.1007825646,2,15,5311005,15,4 ,29,,6",
 "216200,4,4,1998/10/01 14:06:00,139.6777181626,35.2485445722,2,1,2221106,12,3 ,34,,1",
 "271400,4,4,1998/10/01 14:06:00,139.50903312,35.4744034361,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:06:00,139.4278341054,35.4858651801,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:06:00,139.3696636889,35.5640226719,2,12,2924401,14,3 ,33,,6",
 "331200,4,4,1998/10/01 14:06:00,139.5796085768,35.5715645068,2,1,2121208,12,3 ,28,,1",
 "448500,4,6,1998/10/01 14:06:00,139.7316887868,35.7597932715,2,7,3222102,4 ,4 ,27,,1",
 "411700,4,4,1998/10/01 14:06:00,139.4302827171,35.7958611473,2,1,3122110,12,3 ,29,,1",
 "441600,6,6,1998/10/01 14:06:00,139.7421304597,35.6928568426,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:06:00,139.6177328107,35.9196426159,1,10,3512006,6 ,1 ,36,,6",
 "581900,4,6,1998/10/01 14:06:00,139.7281009668,35.7317863102,1,9,0321101,5 ,3 ,97,,1",
 "581900,4,7,1998/10/01 14:06:00,139.7281009668,35.7317863102,1,9,0321101,5 ,3 ,97,,12",
 "584200,2,3,1998/10/01 14:06:00,139.7848383804,35.692307,2,6,0332001,9 ,11,49,,12",
 "627900,4,4,1998/10/01 14:06:00,139.6488483809,35.743652949,2,7,0511204,9 ,3 ,34,,2",
 "690000,4,4,1998/10/01 14:06:00,139.569680593,35.668954201,2,1,0710226,12,3 ,18,,2",
 "786600,3,5,1998/10/01 14:06:00,139.3725406984,35.669562579,2,3,0930110,13,2 ,35,,9",
 "878600,4,4,1998/10/01 14:06:00,139.546435999,35.4340744189,2,8,1221105,14,3 ,43,,6",
 "685400,4,6,1998/10/01 14:06:00,139.40457797,35.6496186328,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 14:06:00,139.8024080067,35.6881801313,1,14,0730209,10,1 ,26,,12",
 "280600,9,9,1998/10/01 14:07:00,139.7621356854,35.790615114,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,4,1998/10/01 14:07:00,139.7204588759,35.7368183437,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 14:07:00,139.679030888,35.8390214575,1,12,4313309,9 ,13,34,,12",
 "6900,6,6,1998/10/01 14:07:00,139.9201384418,35.7170031554,2,11,4114005,14,4 ,32,,1",
 "253000,2,3,1998/10/01 14:07:00,139.2921140612,35.3073465599,1,8,2520301,9 ,3 ,30,,12",
 "66700,6,6,1998/10/01 14:07:00,139.9478719194,35.8504373596,1,11,4311002,3 ,9 ,37,,2",
 "188600,2,2,1998/10/01 14:07:00,140.1413577317,36.1051566516,2,15,5311005,15,4 ,29,,6",
 "216200,4,4,1998/10/01 14:07:00,139.6779858009,35.2486224484,2,1,2221106,12,3 ,34,,1",
 "271400,4,4,1998/10/01 14:07:00,139.5058406483,35.4725299068,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:07:00,139.427831118,35.485372295,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:07:00,139.3688759859,35.5629781996,2,12,2924401,14,3 ,33,,6",
 "331200,4,4,1998/10/01 14:07:00,139.5798573404,35.5718504473,2,1,2121208,12,3 ,28,,1",
 "448500,4,6,1998/10/01 14:07:00,139.732093936,35.7604000024,2,7,3222102,4 ,4 ,27,,1",
 "411700,4,4,1998/10/01 14:07:00,139.4307626668,35.7965261965,2,1,3122110,12,3 ,29,,1",
 "441600,6,6,1998/10/01 14:07:00,139.7448901662,35.693799373,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:07:00,139.617700682,35.9197569931,1,10,3512006,6 ,1 ,36,,6",
 "581900,4,7,1998/10/01 14:07:00,139.7316844282,35.7309236699,1,9,0321101,5 ,3 ,97,,12",
 "584200,2,3,1998/10/01 14:07:00,139.7848672982,35.6900161525,2,6,0332001,9 ,11,49,,12",
 "627900,4,4,1998/10/01 14:07:00,139.6495617295,35.7424385979,2,7,0511204,9 ,3 ,34,,2",
 "627900,5,5,1998/10/01 14:07:00,139.6495617295,35.7424385979,2,7,0511204,9 ,97,34,,97",
 "690000,4,4,1998/10/01 14:07:00,139.5689875915,35.6692171355,2,1,0710226,12,3 ,18,,2",
 "786600,3,5,1998/10/01 14:07:00,139.3722457818,35.6694428766,2,3,0930110,13,2 ,35,,9",
 "878600,4,4,1998/10/01 14:07:00,139.5452024327,35.4323739377,2,8,1221105,14,3 ,43,,6",
 "685400,4,6,1998/10/01 14:07:00,139.4049766394,35.6498093901,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 14:07:00,139.7982942542,35.6880602853,1,14,0730209,10,1 ,26,,12",
 "280600,9,9,1998/10/01 14:08:00,139.761914301,35.7930782151,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,4,1998/10/01 14:08:00,139.7195918376,35.7360191907,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 14:08:00,139.675728043,35.8421208105,1,12,4313309,9 ,13,34,,12",
 "6900,6,6,1998/10/01 14:08:00,139.9205190386,35.7174913111,2,11,4114005,14,4 ,32,,1",
 "253000,2,3,1998/10/01 14:08:00,139.279821886,35.3057985049,1,8,2520301,9 ,3 ,30,,12",
 "66700,6,6,1998/10/01 14:08:00,139.9478215879,35.8504723699,1,11,4311002,3 ,9 ,37,,2",
 "188600,2,2,1998/10/01 14:08:00,140.1425562264,36.1098877742,2,15,5311005,15,4 ,29,,6",
 "216200,4,4,1998/10/01 14:08:00,139.6782534398,35.2487003241,2,1,2221106,12,3 ,34,,1",
 "271400,4,4,1998/10/01 14:08:00,139.5039367573,35.4701447508,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:08:00,139.427909232,35.4848707,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:08:00,139.3681039392,35.5619260419,2,12,2924401,14,3 ,33,,6",
 "331200,4,4,1998/10/01 14:08:00,139.580204017,35.5717124796,2,1,2121208,12,3 ,28,,1",
 "448500,4,6,1998/10/01 14:08:00,139.7324811487,35.7609748242,2,7,3222102,4 ,4 ,27,,1",
 "411700,4,4,1998/10/01 14:08:00,139.4316887909,35.7964256541,2,1,3122110,12,3 ,29,,1",
 "441600,6,6,1998/10/01 14:08:00,139.7476562514,35.6947282205,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:08:00,139.6176685532,35.9198713703,1,10,3512006,6 ,1 ,36,,6",
 "581900,4,7,1998/10/01 14:08:00,139.7353232888,35.731525221,1,9,0321101,5 ,3 ,97,,12",
 "584200,2,3,1998/10/01 14:08:00,139.7842887034,35.687878947,2,6,0332001,9 ,11,49,,12",
 "690000,4,4,1998/10/01 14:08:00,139.5683449426,35.6695637839,2,1,0710226,12,3 ,18,,2",
 "786600,3,5,1998/10/01 14:08:00,139.371950866,35.6693231734,2,3,0930110,13,2 ,35,,9",
 "878600,4,4,1998/10/01 14:08:00,139.5449725056,35.4307345698,2,8,1221105,14,3 ,43,,6",
 "685400,4,6,1998/10/01 14:08:00,139.4053753107,35.6500001461,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 14:08:00,139.7941780389,35.6880129555,1,14,0730209,10,1 ,26,,12",
 "280600,9,9,1998/10/01 14:09:00,139.761794631,35.7955476954,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,4,1998/10/01 14:09:00,139.718726045,35.7352188645,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 14:09:00,139.6722607089,35.8450979783,1,12,4313309,9 ,13,34,,12",
 "6900,6,6,1998/10/01 14:09:00,139.9208996401,35.7179794655,2,11,4114005,14,4 ,32,,1",
 "253000,2,3,1998/10/01 14:09:00,139.2679269689,35.3031215568,1,8,2520301,9 ,3 ,30,,12",
 "66700,6,6,1998/10/01 14:09:00,139.9478489715,35.8501531215,1,11,4311002,3 ,9 ,37,,2",
 "188600,2,2,1998/10/01 14:09:00,140.1450799606,36.1143187885,2,15,5311005,15,4 ,29,,6",
 "216200,4,4,1998/10/01 14:09:00,139.6785210791,35.2487781992,2,1,2221106,12,3 ,34,,1",
 "271400,4,4,1998/10/01 14:09:00,139.5011145219,35.4679213128,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:09:00,139.427987345,35.4843691049,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:09:00,139.3673227903,35.5608783495,2,12,2924401,14,3 ,33,,6",
 "331200,4,4,1998/10/01 14:09:00,139.5806134824,35.5716147861,2,1,2121208,12,3 ,28,,1",
 "448500,4,6,1998/10/01 14:09:00,139.7329066577,35.7614978446,2,7,3222102,4 ,4 ,27,,1",
 "411700,4,4,1998/10/01 14:09:00,139.4322724827,35.7970025197,2,1,3122110,12,3 ,29,,1",
 "441600,6,6,1998/10/01 14:09:00,139.750522121,35.6954124197,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:09:00,139.6176396859,35.9199863093,1,10,3512006,6 ,1 ,36,,6",
 "581900,4,7,1998/10/01 14:09:00,139.7386547042,35.7329766424,1,9,0321101,5 ,3 ,97,,12",
 "584200,2,3,1998/10/01 14:09:00,139.7821663065,35.6863227868,2,6,0332001,9 ,11,49,,12",
 "690000,4,4,1998/10/01 14:09:00,139.5677232362,35.6699331558,2,1,0710226,12,3 ,18,,2",
 "786600,3,5,1998/10/01 14:09:00,139.3719463978,35.6693176808,2,3,0930110,13,2 ,35,,9",
 "878600,4,4,1998/10/01 14:09:00,139.5453085375,35.4291225224,2,8,1221105,14,3 ,43,,6",
 "685400,4,6,1998/10/01 14:09:00,139.4057739839,35.6501909007,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 14:09:00,139.7900808181,35.6882537118,1,14,0730209,10,1 ,26,,12",
 "786600,3,5,1998/10/01 14:10:00,139.3722448941,35.6694313207,2,3,0930110,13,2 ,35,,9",
 "280600,9,9,1998/10/01 14:10:00,139.7616474473,35.798008732,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,4,1998/10/01 14:10:00,139.7178512483,35.7344253691,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 14:10:00,139.6688175045,35.8480937093,1,12,4313309,9 ,13,34,,12",
 "6900,6,6,1998/10/01 14:10:00,139.9213367761,35.7183066117,2,11,4114005,14,4 ,32,,1",
 "253000,2,3,1998/10/01 14:10:00,139.2567910294,35.2986387416,1,8,2520301,9 ,3 ,30,,12",
 "253000,2,4,1998/10/01 14:10:00,139.2567910294,35.2986387416,1,8,2520301,9 ,3 ,30,,6",
 "66700,6,6,1998/10/01 14:10:00,139.9478763549,35.849833873,1,11,4311002,3 ,9 ,37,,2",
 "188600,2,2,1998/10/01 14:10:00,140.150335388,36.1157911231,2,15,5311005,15,4 ,29,,6",
 "216200,4,4,1998/10/01 14:10:00,139.678788719,35.2488560737,2,1,2221106,12,3 ,34,,1",
 "216200,5,5,1998/10/01 14:10:00,139.678788719,35.2488560737,2,1,2221106,12,97,34,,97",
 "271400,4,4,1998/10/01 14:10:00,139.5003178769,35.4647200604,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:10:00,139.4280061599,35.483864627,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:10:00,139.3663314023,35.5605468349,2,12,2924401,14,3 ,33,,6",
 "331200,4,4,1998/10/01 14:10:00,139.5810274223,35.5717080268,2,1,2121208,12,3 ,28,,1",
 "448500,4,6,1998/10/01 14:10:00,139.7329383314,35.7622087893,2,7,3222102,4 ,4 ,27,,1",
 "448500,5,7,1998/10/01 14:10:00,139.7329383314,35.7622087893,2,7,3222102,4 ,97,27,,97",
 "411700,4,4,1998/10/01 14:10:00,139.4328372989,35.7976215883,2,1,3122110,12,3 ,29,,1",
 "441600,6,6,1998/10/01 14:10:00,139.7534269035,35.6959228135,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:10:00,139.6176001973,35.9200990562,1,10,3512006,6 ,1 ,36,,6",
 "581900,4,7,1998/10/01 14:10:00,139.7419946092,35.7344153451,1,9,0321101,5 ,3 ,97,,12",
 "584200,2,3,1998/10/01 14:10:00,139.7796519677,35.6852151872,2,6,0332001,9 ,11,49,,12",
 "690000,4,4,1998/10/01 14:10:00,139.5670773403,35.6702572309,2,1,0710226,12,3 ,18,,2",
 "878600,4,4,1998/10/01 14:10:00,139.544326067,35.4275598712,2,8,1221105,14,3 ,43,,6",
 "685400,4,6,1998/10/01 14:10:00,139.4061744007,35.65030088,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 14:10:00,139.7862549588,35.6893621763,1,14,0730209,10,1 ,26,,12",
 "147200,4,5,1998/10/01 14:11:00,140.1897375804,35.6239011498,2,9,4022001,15,4 ,38,,6",
 "280600,9,9,1998/10/01 14:11:00,139.7610321501,35.8004284015,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,4,1998/10/01 14:11:00,139.7169589267,35.7336454879,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 14:11:00,139.665421589,35.8511252806,1,12,4313309,9 ,13,34,,12",
 "832600,2,2,1998/10/01 14:11:00,139.6165145966,35.4192612173,1,14,1111003,15,4 ,30,,1",
 "6900,6,6,1998/10/01 14:11:00,139.9219765142,35.7180567077,2,11,4114005,14,4 ,32,,1",
 "27600,4,4,1998/10/01 14:11:00,140.019482541,35.7083451957,2,6,4125008,14,3 ,25,,2",
 "253000,2,4,1998/10/01 14:11:00,139.2630204917,35.2995568881,1,8,2520301,9 ,3 ,30,,6",
 "66700,6,6,1998/10/01 14:11:00,139.947903738,35.8495146245,1,11,4311002,3 ,9 ,37,,2",
 "188600,2,2,1998/10/01 14:11:00,140.1534947981,36.1199613889,2,15,5311005,15,4 ,29,,6",
 "328900,2,2,1998/10/01 14:11:00,139.5959748832,35.5795643247,2,8,2120102,9 ,10,28,,1",
 "271400,4,4,1998/10/01 14:11:00,139.5000217529,35.4614323215,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:11:00,139.42800124,35.4833589954,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:11:00,139.3655092705,35.5595212068,2,12,2924401,14,3 ,33,,6",
 "331200,4,4,1998/10/01 14:11:00,139.5811511037,35.571400561,2,1,2121208,12,3 ,28,,1",
 "411700,4,4,1998/10/01 14:11:00,139.4336423802,35.7978093935,2,1,3122110,12,3 ,29,,1",
 "441600,6,6,1998/10/01 14:11:00,139.7564121477,35.6960804096,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:11:00,139.6175625535,35.920212194,1,10,3512006,6 ,1 ,36,,6",
 "581900,4,7,1998/10/01 14:11:00,139.7454097712,35.7357315707,1,9,0321101,5 ,3 ,97,,12",
 "572700,4,4,1998/10/01 14:11:00,139.7096169902,35.6680462708,2,2,0241003,12,3 ,27,,1",
 "584200,2,3,1998/10/01 14:11:00,139.7773350741,35.6838906342,2,6,0332001,9 ,11,49,,12",
 "680800,2,2,1998/10/01 14:11:00,139.8882588212,35.7320871641,1,5,0632105,15,4 ,63,,1",
 "690000,4,4,1998/10/01 14:11:00,139.5663739683,35.6705139647,2,1,0710226,12,3 ,18,,2",
 "786600,3,5,1998/10/01 14:11:00,139.3725433913,35.6695449598,2,3,0930110,13,2 ,35,,9",
 "878600,4,4,1998/10/01 14:11:00,139.5449904077,35.4267986076,2,8,1221105,14,3 ,43,,6",
 "685400,4,6,1998/10/01 14:11:00,139.4065810519,35.6501216945,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 14:11:00,139.7832614238,35.6916627179,1,14,0730209,10,1 ,26,,12",
 "147200,4,5,1998/10/01 14:12:00,140.1891817768,35.6242454346,2,9,4022001,15,4 ,38,,6",
 "280600,9,9,1998/10/01 14:12:00,139.7603561118,35.8028375624,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,4,1998/10/01 14:12:00,139.7161554607,35.7342228171,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 14:12:00,139.6616398575,35.8538365894,1,12,4313309,9 ,13,34,,12",
 "832600,2,2,1998/10/01 14:12:00,139.6165491998,35.4191618237,1,14,1111003,15,4 ,30,,1",
 "6900,6,6,1998/10/01 14:12:00,139.9226162483,35.7178068003,2,11,4114005,14,4 ,32,,1",
 "27600,4,4,1998/10/01 14:12:00,140.017718818,35.7083281938,2,6,4125008,14,3 ,25,,2",
 "253000,2,4,1998/10/01 14:12:00,139.2712092777,35.303126076,1,8,2520301,9 ,3 ,30,,6",
 "66700,6,6,1998/10/01 14:12:00,139.9478380304,35.8492016933,1,11,4311002,3 ,9 ,37,,2",
 "188600,2,2,1998/10/01 14:12:00,140.1553232057,36.1242834816,2,15,5311005,15,4 ,29,,6",
 "328900,2,2,1998/10/01 14:12:00,139.5958397021,35.5805607863,2,8,2120102,9 ,10,28,,1",
 "271400,4,4,1998/10/01 14:12:00,139.4990254213,35.4582480704,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:12:00,139.42799632,35.4828533636,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:12:00,139.364441156,35.5586681913,2,12,2924401,14,3 ,33,,6",
 "331200,4,4,1998/10/01 14:12:00,139.5812517669,35.5710596037,2,1,2121208,12,3 ,28,,1",
 "411700,4,4,1998/10/01 14:12:00,139.4345872593,35.797754866,2,1,3122110,12,3 ,29,,1",
 "441600,6,6,1998/10/01 14:12:00,139.7594020213,35.6961735723,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:12:00,139.6175336859,35.9203271451,1,10,3512006,6 ,1 ,36,,6",
 "572700,4,4,1998/10/01 14:12:00,139.7098635239,35.6678310274,2,2,0241003,12,3 ,27,,1",
 "581900,4,7,1998/10/01 14:12:00,139.7487395275,35.7371782715,1,9,0321101,5 ,3 ,97,,12",
 "584200,2,3,1998/10/01 14:12:00,139.7759296459,35.6818657446,2,6,0332001,9 ,11,49,,12",
 "680800,2,2,1998/10/01 14:12:00,139.8876373018,35.732038273,1,5,0632105,15,4 ,63,,1",
 "690000,4,4,1998/10/01 14:12:00,139.5656606437,35.6707507379,2,1,0710226,12,3 ,18,,2",
 "786600,3,5,1998/10/01 14:12:00,139.3728418893,35.6696585982,2,3,0930110,13,2 ,35,,9",
 "878600,4,4,1998/10/01 14:12:00,139.5444375302,35.4248673487,2,8,1221105,14,3 ,43,,6",
 "685400,4,6,1998/10/01 14:12:00,139.4069877013,35.6499425076,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 14:12:00,139.7804531848,35.6941184807,1,14,0730209,10,1 ,26,,12",
 "147200,4,5,1998/10/01 14:13:00,140.1893403953,35.624617148,2,9,4022001,15,4 ,38,,6",
 "280600,9,9,1998/10/01 14:13:00,139.7597404129,35.8052554205,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,4,1998/10/01 14:13:00,139.7148900638,35.7339723218,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 14:13:00,139.6582985226,35.8568740123,1,12,4313309,9 ,13,34,,12",
 "832600,2,2,1998/10/01 14:13:00,139.6166401725,35.4190917297,1,14,1111003,15,4 ,30,,1",
 "6900,6,6,1998/10/01 14:13:00,139.923054799,35.7180938444,2,11,4114005,14,4 ,32,,1",
 "27600,4,4,1998/10/01 14:13:00,140.01596578,35.7084961995,2,6,4125008,14,3 ,25,,2",
 "253000,2,4,1998/10/01 14:13:00,139.2727552646,35.3094014204,1,8,2520301,9 ,3 ,30,,6",
 "66700,6,6,1998/10/01 14:13:00,139.9477461071,35.8488905411,1,11,4311002,3 ,9 ,37,,2",
 "188600,2,2,1998/10/01 14:13:00,140.1558616089,36.1287313052,2,15,5311005,15,4 ,29,,6",
 "328900,2,2,1998/10/01 14:13:00,139.5954643903,35.5815097871,2,8,2120102,9 ,10,28,,1",
 "271400,4,4,1998/10/01 14:13:00,139.4984561109,35.4550553049,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:13:00,139.4279914001,35.4823477319,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:13:00,139.363087059,35.558150637,2,12,2924401,14,3 ,33,,6",
 "331200,4,4,1998/10/01 14:13:00,139.5814103709,35.5707344341,2,1,2121208,12,3 ,28,,1",
 "411700,4,4,1998/10/01 14:13:00,139.4346191322,35.7976563357,2,1,3122110,12,3 ,29,,1",
 "411700,5,5,1998/10/01 14:13:00,139.4346191322,35.7976563357,2,1,3122110,12,97,29,,97",
 "441600,6,6,1998/10/01 14:13:00,139.7619936372,35.6952206543,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:13:00,139.6175048182,35.9204420961,1,10,3512006,6 ,1 ,36,,6",
 "572700,4,4,1998/10/01 14:13:00,139.7101100563,35.6676157835,2,2,0241003,12,3 ,27,,1",
 "581900,4,7,1998/10/01 14:13:00,139.7519586437,35.7387901459,1,9,0321101,5 ,3 ,97,,12",
 "584200,2,3,1998/10/01 14:13:00,139.7746503826,35.6797772597,2,6,0332001,9 ,11,49,,12",
 "680800,2,2,1998/10/01 14:13:00,139.887015783,35.7319893787,1,5,0632105,15,4 ,63,,1",
 "690000,4,4,1998/10/01 14:13:00,139.5649373645,35.6709675456,2,1,0710226,12,3 ,18,,2",
 "786600,3,5,1998/10/01 14:13:00,139.3731403881,35.6697722358,2,3,0930110,13,2 ,35,,9",
 "878600,4,4,1998/10/01 14:13:00,139.5451606032,35.423266173,2,8,1221105,14,3 ,43,,6",
 "685400,4,6,1998/10/01 14:13:00,139.4073943489,35.6497633193,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 14:13:00,139.7769215849,35.6955270477,1,14,0730209,10,1 ,26,,12",
 "147200,4,5,1998/10/01 14:14:00,140.1898541837,35.625002496,2,9,4022001,15,4 ,38,,6",
 "280600,9,9,1998/10/01 14:14:00,139.7592738991,35.8076945972,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,4,1998/10/01 14:14:00,139.7136632729,35.7340429941,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 14:14:00,139.6559853482,35.8605186955,1,12,4313309,9 ,13,34,,12",
 "832600,2,2,1998/10/01 14:14:00,139.6174577305,35.4187178551,1,14,1111003,15,4 ,30,,1",
 "6900,6,6,1998/10/01 14:14:00,139.9234182869,35.7185905032,2,11,4114005,14,4 ,32,,1",
 "27600,4,4,1998/10/01 14:14:00,140.0142753734,35.7088055514,2,6,4125008,14,3 ,25,,2",
 "253000,2,4,1998/10/01 14:14:00,139.2725957309,35.3169704042,1,8,2520301,9 ,3 ,30,,6",
 "66700,6,6,1998/10/01 14:14:00,139.9477097415,35.8485740271,1,11,4311002,3 ,9 ,37,,2",
 "188600,2,2,1998/10/01 14:14:00,140.1590419494,36.1325656061,2,15,5311005,15,4 ,29,,6",
 "328900,2,2,1998/10/01 14:14:00,139.594668472,35.5820422053,2,8,2120102,9 ,10,28,,1",
 "271400,4,4,1998/10/01 14:14:00,139.4979464492,35.4523782645,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:14:00,139.4279864802,35.4818421001,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:14:00,139.3616636911,35.5577611988,2,12,2924401,14,3 ,33,,6",
 "331200,4,4,1998/10/01 14:14:00,139.581705038,35.57049768,2,1,2121208,12,3 ,28,,1",
 "441600,6,6,1998/10/01 14:14:00,139.7646738568,35.6945871355,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:14:00,139.6174759504,35.9205570472,1,10,3512006,6 ,1 ,36,,6",
 "581900,4,7,1998/10/01 14:14:00,139.7552000619,35.7403684802,1,9,0321101,5 ,3 ,97,,12",
 "572700,4,4,1998/10/01 14:14:00,139.710338823,35.6676253217,2,2,0241003,12,3 ,27,,1",
 "584200,2,3,1998/10/01 14:14:00,139.7733607136,35.6776930474,2,6,0332001,9 ,11,49,,12",
 "680800,2,2,1998/10/01 14:14:00,139.8867914269,35.7317156498,1,5,0632105,15,4 ,63,,1",
 "690000,4,4,1998/10/01 14:14:00,139.5646157762,35.6714022573,2,1,0710226,12,3 ,18,,2",
 "786600,3,5,1998/10/01 14:14:00,139.3734388878,35.6698858728,2,3,0930110,13,2 ,35,,9",
 "878600,4,4,1998/10/01 14:14:00,139.5435526745,35.4220069295,2,8,1221105,14,3 ,43,,6",
 "685400,4,6,1998/10/01 14:14:00,139.4076283487,35.6494740077,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 14:14:00,139.7728086278,35.6956771351,1,14,0730209,10,1 ,26,,12",
 "147200,4,5,1998/10/01 14:15:00,140.1893976036,35.6254058807,2,9,4022001,15,4 ,38,,6",
 "280600,9,9,1998/10/01 14:15:00,139.7590512166,35.8101449601,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,4,1998/10/01 14:15:00,139.7127094246,35.7337510418,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 14:15:00,139.653632892,35.8641469348,1,12,4313309,9 ,13,34,,12",
 "832600,2,2,1998/10/01 14:15:00,139.6181343236,35.4181635439,1,14,1111003,15,4 ,30,,1",
 "6900,6,6,1998/10/01 14:15:00,139.9238058514,35.7190750031,2,11,4114005,14,4 ,32,,1",
 "27600,4,4,1998/10/01 14:15:00,140.0125095218,35.7088180899,2,6,4125008,14,3 ,25,,2",
 "253000,2,4,1998/10/01 14:15:00,139.2685644126,35.3171842021,1,8,2520301,9 ,3 ,30,,6",
 "253000,3,5,1998/10/01 14:15:00,139.2685644126,35.3171842021,1,8,2520301,9 ,97,30,,97",
 "66700,6,6,1998/10/01 14:15:00,139.9477260198,35.8482542992,1,11,4311002,3 ,9 ,37,,2",
 "188600,2,2,1998/10/01 14:15:00,140.1623171368,36.1363766041,2,15,5311005,15,4 ,29,,6",
 "328900,2,2,1998/10/01 14:15:00,139.5936481363,35.5825143176,2,8,2120102,9 ,10,28,,1",
 "271400,4,4,1998/10/01 14:15:00,139.4989515294,35.4491867877,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:15:00,139.4279819737,35.4813364657,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:15:00,139.3602824602,35.557281127,2,12,2924401,14,3 ,33,,6",
 "331200,4,4,1998/10/01 14:15:00,139.5821238513,35.570423948,2,1,2121208,12,3 ,28,,1",
 "441600,6,6,1998/10/01 14:15:00,139.7674281842,35.6955225511,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:15:00,139.6174458798,35.9206717885,1,10,3512006,6 ,1 ,36,,6",
 "572700,4,4,1998/10/01 14:15:00,139.7105494322,35.6678646234,2,2,0241003,12,3 ,27,,1",
 "581900,4,7,1998/10/01 14:15:00,139.7587512533,35.7398761119,1,9,0321101,5 ,3 ,97,,12",
 "584200,2,3,1998/10/01 14:15:00,139.7720438048,35.6756547743,2,6,0332001,9 ,11,49,,12",
 "584200,2,4,1998/10/01 14:15:00,139.7720438048,35.6756547743,2,6,0332001,9 ,11,49,,1",
 "680800,2,2,1998/10/01 14:15:00,139.886686483,35.7313618376,1,5,0632105,15,4 ,63,,1",
 "690000,4,4,1998/10/01 14:15:00,139.5641632864,35.671166052,2,1,0710226,12,3 ,18,,2",
 "786600,3,5,1998/10/01 14:15:00,139.3737373884,35.6699995089,2,3,0930110,13,2 ,35,,9",
 "878600,4,4,1998/10/01 14:15:00,139.542996112,35.4213974027,2,8,1221105,14,3 ,43,,6",
 "685400,4,6,1998/10/01 14:15:00,139.407743093,35.6491086296,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 14:15:00,139.7687014561,35.6958822588,1,14,0730209,10,1 ,26,,12",
 "147200,4,5,1998/10/01 14:16:00,140.1889008819,35.6256845862,2,9,4022001,15,4 ,38,,6",
 "280600,9,9,1998/10/01 14:16:00,139.7595015556,35.8125883318,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,4,1998/10/01 14:16:00,139.7117339427,35.7332403438,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 14:16:00,139.6509313043,35.8675959176,1,12,4313309,9 ,13,34,,12",
 "832600,2,2,1998/10/01 14:16:00,139.6188792016,35.4176832476,1,14,1111003,15,4 ,30,,1",
 "6900,6,6,1998/10/01 14:16:00,139.9241934206,35.7195595017,2,11,4114005,14,4 ,32,,1",
 "27600,4,4,1998/10/01 14:16:00,140.0109005421,35.7082768599,2,6,4125008,14,3 ,25,,2",
 "66700,6,6,1998/10/01 14:16:00,139.9477436984,35.847934597,1,11,4311002,3 ,9 ,37,,2",
 "188600,2,2,1998/10/01 14:16:00,140.1652397226,36.1405391603,2,15,5311005,15,4 ,29,,6",
 "328900,2,2,1998/10/01 14:16:00,139.5928518522,35.5832772072,2,8,2120102,9 ,10,28,,1",
 "271400,4,4,1998/10/01 14:16:00,139.499639601,35.4459629436,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:16:00,139.4279776936,35.4808308299,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:16:00,139.358834831,35.5569587286,2,12,2924401,14,3 ,33,,6",
 "331200,4,4,1998/10/01 14:16:00,139.5825452421,35.5703566014,2,1,2121208,12,3 ,28,,1",
 "441600,6,6,1998/10/01 14:16:00,139.7701618755,35.6965151563,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:16:00,139.6174142743,35.9207862622,1,10,3512006,6 ,1 ,36,,6",
 "572700,4,4,1998/10/01 14:16:00,139.7107600427,35.6681039248,2,2,0241003,12,3 ,27,,1",
 "581900,4,7,1998/10/01 14:16:00,139.7613708791,35.7376568975,1,9,0321101,5 ,3 ,97,,12",
 "584200,2,4,1998/10/01 14:16:00,139.7724596056,35.675158912,2,6,0332001,9 ,11,49,,1",
 "680800,2,2,1998/10/01 14:16:00,139.8863108519,35.7311301091,1,5,0632105,15,4 ,63,,1",
 "690000,4,4,1998/10/01 14:16:00,139.5636177962,35.6707220429,2,1,0710226,12,3 ,18,,2",
 "786600,3,5,1998/10/01 14:16:00,139.3740358898,35.6701131444,2,3,0930110,13,2 ,35,,9",
 "878600,4,4,1998/10/01 14:16:00,139.5449257901,35.4214274237,2,8,1221105,14,3 ,43,,6",
 "878600,5,5,1998/10/01 14:16:00,139.5449257901,35.4214274237,2,8,1221105,14,97,43,,97",
 "685400,4,6,1998/10/01 14:16:00,139.4078578362,35.6487432513,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 14:16:00,139.7649306707,35.6945486694,1,14,0730209,10,1 ,26,,12",
 "147200,4,5,1998/10/01 14:17:00,140.1883759137,35.625639128,2,9,4022001,15,4 ,38,,6",
 "280600,9,9,1998/10/01 14:17:00,139.759879199,35.8150395414,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,4,1998/10/01 14:17:00,139.7115492953,35.7342968879,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 14:17:00,139.6475174503,35.8706151383,1,12,4313309,9 ,13,34,,12",
 "832600,2,2,1998/10/01 14:17:00,139.6197969971,35.4176661543,1,14,1111003,15,4 ,30,,1",
 "6900,6,6,1998/10/01 14:17:00,139.9245809946,35.7200439989,2,11,4114005,14,4 ,32,,1",
 "27600,4,4,1998/10/01 14:17:00,140.0091936951,35.7079812887,2,6,4125008,14,3 ,25,,2",
 "66700,6,6,1998/10/01 14:17:00,139.947756811,35.8476148658,1,11,4311002,3 ,9 ,37,,2",
 "188600,2,2,1998/10/01 14:17:00,140.1701804079,36.1432900142,2,15,5311005,15,4 ,29,,6",
 "328900,2,2,1998/10/01 14:17:00,139.5920555531,35.5840400913,2,8,2120102,9 ,10,28,,1",
 "271400,4,4,1998/10/01 14:17:00,139.5007311292,35.4427896529,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:17:00,139.4279734135,35.4803251941,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:17:00,139.3575461502,35.5569965331,2,12,2924401,14,3 ,33,,6",
 "331200,4,4,1998/10/01 14:17:00,139.5829695824,35.5703036682,2,1,2121208,12,3 ,28,,1",
 "441600,6,6,1998/10/01 14:17:00,139.7723208343,35.6975578142,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:17:00,139.6173826687,35.9209007358,1,10,3512006,6 ,1 ,36,,6",
 "572700,4,4,1998/10/01 14:17:00,139.7109699544,35.6683436349,2,2,0241003,12,3 ,27,,1",
 "581900,4,7,1998/10/01 14:17:00,139.7639249691,35.7353880325,1,9,0321101,5 ,3 ,97,,12",
 "584200,2,4,1998/10/01 14:17:00,139.7730872081,35.6748537347,2,6,0332001,9 ,11,49,,1",
 "680800,2,2,1998/10/01 14:17:00,139.8857501223,35.730909817,1,5,0632105,15,4 ,63,,1",
 "690000,4,4,1998/10/01 14:17:00,139.5630473963,35.671045918,2,1,0710226,12,3 ,18,,2",
 "786600,3,5,1998/10/01 14:17:00,139.3743343921,35.6702267791,2,3,0930110,13,2 ,35,,9",
 "685400,4,6,1998/10/01 14:17:00,139.4079661713,35.6483765934,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 14:17:00,139.7613382195,35.6955563542,1,14,0730209,10,1 ,26,,12",
 "147200,4,5,1998/10/01 14:18:00,140.1877241731,35.6257891833,2,9,4022001,15,4 ,38,,6",
 "280600,9,9,1998/10/01 14:18:00,139.7601674903,35.8174985937,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,4,1998/10/01 14:18:00,139.7105475563,35.7344625007,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 14:18:00,139.6441332197,35.8736567007,1,12,4313309,9 ,13,34,,12",
 "832600,2,2,1998/10/01 14:18:00,139.6206454111,35.4173032721,1,14,1111003,15,4 ,30,,1",
 "6900,6,6,1998/10/01 14:18:00,139.9251688289,35.7198692276,2,11,4114005,14,4 ,32,,1",
 "27600,4,4,1998/10/01 14:18:00,140.0075265512,35.7076421762,2,6,4125008,14,3 ,25,,2",
 "66700,6,6,1998/10/01 14:18:00,139.9477800672,35.8472954003,1,11,4311002,3 ,9 ,37,,2",
 "188600,2,2,1998/10/01 14:18:00,140.1739129725,36.14544114,2,15,5311005,15,4 ,29,,6",
 "328900,2,2,1998/10/01 14:18:00,139.5912592388,35.5848029701,2,8,2120102,9 ,10,28,,1",
 "271400,4,4,1998/10/01 14:18:00,139.5013184832,35.4395752833,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:18:00,139.4279639369,35.4798196124,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:18:00,139.3565390829,35.5579017404,2,12,2924401,14,3 ,33,,6",
 "331200,4,4,1998/10/01 14:18:00,139.5830130321,35.5703405851,2,1,2121208,12,3 ,28,,1",
 "331200,5,5,1998/10/01 14:18:00,139.5830130321,35.5703405851,2,1,2121208,12,97,28,,97",
 "441600,6,6,1998/10/01 14:18:00,139.7753123332,35.697496867,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:18:00,139.617351063,35.9210152094,1,10,3512006,6 ,1 ,36,,6",
 "581900,4,7,1998/10/01 14:18:00,139.766216174,35.7329499602,1,9,0321101,5 ,3 ,97,,12",
 "572700,4,4,1998/10/01 14:18:00,139.7111797745,35.6685833989,2,2,0241003,12,3 ,27,,1",
 "584200,2,4,1998/10/01 14:18:00,139.773055762,35.674314048,2,6,0332001,9 ,11,49,,1",
 "680800,2,2,1998/10/01 14:18:00,139.8852799079,35.730758976,1,5,0632105,15,4 ,63,,1",
 "690000,4,4,1998/10/01 14:18:00,139.5624390755,35.6714296392,2,1,0710226,12,3 ,18,,2",
 "786600,3,5,1998/10/01 14:18:00,139.3746328952,35.6703404131,2,3,0930110,13,2 ,35,,9",
 "685400,4,6,1998/10/01 14:18:00,139.4080710506,35.6480092456,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 14:18:00,139.7574769211,35.6959992721,1,14,0730209,10,1 ,26,,12",
 "147200,4,5,1998/10/01 14:19:00,140.187027324,35.6258168359,2,9,4022001,15,4 ,38,,6",
 "280600,9,9,1998/10/01 14:19:00,139.7602182849,35.8199694821,1,11,2910009,8 ,9 ,42,,8",
 "561200,2,4,1998/10/01 14:19:00,139.709245857,35.7344068546,2,13,0210202,14,7 ,53,,1",
 "71300,4,7,1998/10/01 14:19:00,139.6419529732,35.8773233418,1,12,4313309,9 ,13,34,,12",
 "832600,2,2,1998/10/01 14:19:00,139.6213190577,35.4167985006,1,14,1111003,15,4 ,30,,1",
 "6900,6,6,1998/10/01 14:19:00,139.9256318587,35.7195620074,2,11,4114005,14,4 ,32,,1",
 "27600,4,4,1998/10/01 14:19:00,140.0069225884,35.7066851621,2,6,4125008,14,3 ,25,,2",
 "66700,6,6,1998/10/01 14:19:00,139.947797265,35.8469757891,1,11,4311002,3 ,9 ,37,,2",
 "188600,2,2,1998/10/01 14:19:00,140.1767354203,36.1490573957,2,15,5311005,15,4 ,29,,6",
 "328900,2,2,1998/10/01 14:19:00,139.5904629094,35.5855658434,2,8,2120102,9 ,10,28,,1",
 "271400,4,4,1998/10/01 14:19:00,139.5010951242,35.4363020043,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:19:00,139.4279501709,35.4793140918,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:19:00,139.3552334821,35.5574300079,2,12,2924401,14,3 ,33,,6",
 "312800,12,12,1998/10/01 14:19:00,139.7010697198,35.5069147658,2,6,2015203,14,3 ,34,,2",
 "441600,6,6,1998/10/01 14:19:00,139.7777968956,35.6977446292,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:19:00,139.6173194573,35.921129683,1,10,3512006,6 ,1 ,36,,6",
 "572700,4,4,1998/10/01 14:19:00,139.7111915546,35.6688655848,2,2,0241003,12,3 ,27,,1",
 "581900,4,7,1998/10/01 14:19:00,139.7681742199,35.7310175131,1,9,0321101,5 ,3 ,97,,12",
 "584200,2,4,1998/10/01 14:19:00,139.7728943125,35.6737278483,2,6,0332001,9 ,11,49,,1",
 "680800,2,2,1998/10/01 14:19:00,139.885049549,35.7305118935,1,5,0632105,15,4 ,63,,1",
 "690000,4,4,1998/10/01 14:19:00,139.5617822433,35.6717533368,2,1,0710226,12,3 ,18,,2",
 "786600,3,5,1998/10/01 14:19:00,139.3749313992,35.6704540463,2,3,0930110,13,2 ,35,,9",
 "685400,4,6,1998/10/01 14:19:00,139.4081759289,35.6476418976,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 14:19:00,139.7533706801,35.6957802132,1,14,0730209,10,1 ,26,,12",
 "147200,4,5,1998/10/01 14:20:00,140.1876196291,35.6256209032,2,9,4022001,15,4 ,38,,6",
 "147200,5,6,1998/10/01 14:20:00,140.1876196291,35.6256209032,2,9,4022001,15,97,38,,97",
 "280600,9,9,1998/10/01 14:20:00,139.761003775,35.8210795185,1,11,2910009,8 ,9 ,42,,8",
 "280600,10,10,1998/10/01 14:20:00,139.761003775,35.8210795185,1,11,2910009,8 ,97,42,,97",
 "561200,2,4,1998/10/01 14:20:00,139.7080872239,35.7340946666,2,13,0210202,14,7 ,53,,1",
 "561200,3,5,1998/10/01 14:20:00,139.7080872239,35.7340946666,2,13,0210202,14,97,53,,97",
 "71300,4,7,1998/10/01 14:20:00,139.6403442846,35.881212315,1,12,4313309,9 ,13,34,,12",
 "832600,2,2,1998/10/01 14:20:00,139.6221502177,35.4166581509,1,14,1111003,15,4 ,30,,1",
 "6900,6,6,1998/10/01 14:20:00,139.9253537524,35.7190298445,2,11,4114005,14,4 ,32,,1",
 "6900,7,7,1998/10/01 14:20:00,139.9253537524,35.7190298445,2,11,4114005,14,97,32,,97",
 "27600,4,4,1998/10/01 14:20:00,140.0073443415,35.7052864111,2,6,4125008,14,3 ,25,,2",
 "27600,5,5,1998/10/01 14:20:00,140.0073443415,35.7052864111,2,6,4125008,14,97,25,,97",
 "66700,6,6,1998/10/01 14:20:00,139.9477924691,35.846655889,1,11,4311002,3 ,9 ,37,,2",
 "188600,2,2,1998/10/01 14:20:00,140.1824766662,36.1505300812,2,15,5311005,15,4 ,29,,6",
 "328900,2,2,1998/10/01 14:20:00,139.5902192725,35.5862397344,2,8,2120102,9 ,10,28,,1",
 "271400,4,4,1998/10/01 14:20:00,139.5015942424,35.4330409226,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:20:00,139.4279350612,35.4788085948,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:20:00,139.3539337379,35.556815944,2,12,2924401,14,3 ,33,,6",
 "312800,12,12,1998/10/01 14:20:00,139.6999748263,35.5073410372,2,6,2015203,14,3 ,34,,2",
 "312800,13,13,1998/10/01 14:20:00,139.6999748263,35.5073410372,2,6,2015203,14,97,34,,97",
 "441600,6,6,1998/10/01 14:20:00,139.7807752459,35.6975091869,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:20:00,139.6172878514,35.9212441566,1,10,3512006,6 ,1 ,36,,6",
 "572700,4,4,1998/10/01 14:20:00,139.711153102,35.669158531,2,2,0241003,12,3 ,27,,1",
 "584200,2,4,1998/10/01 14:20:00,139.7727328653,35.6731416483,2,6,0332001,9 ,11,49,,1",
 "581900,4,7,1998/10/01 14:20:00,139.770302469,35.7284859149,1,9,0321101,5 ,3 ,97,,12",
 "680800,2,2,1998/10/01 14:20:00,139.8848893442,35.7301856364,1,5,0632105,15,4 ,63,,1",
 "690000,4,4,1998/10/01 14:20:00,139.5618130131,35.671795292,2,1,0710226,12,3 ,18,,2",
 "690000,5,5,1998/10/01 14:20:00,139.5618130131,35.671795292,2,1,0710226,12,97,18,,97",
 "786600,3,5,1998/10/01 14:20:00,139.375229904,35.6705676788,2,3,0930110,13,2 ,35,,9",
 "685400,4,6,1998/10/01 14:20:00,139.4082808063,35.6472745495,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 14:20:00,139.7494560417,35.6950297268,1,14,0730209,10,1 ,26,,12",
 "71300,4,7,1998/10/01 14:21:00,139.6386568451,35.8850783101,1,12,4313309,9 ,13,34,,12",
 "832600,2,2,1998/10/01 14:21:00,139.6231059995,35.4166150864,1,14,1111003,15,4 ,30,,1",
 "163300,4,7,1998/10/01 14:21:00,139.7101047149,35.7695452981,2,11,4061110,4 ,3 ,30,,1",
 "234600,8,12,1998/10/01 14:21:00,139.6424030094,35.6982321466,1,14,2412116,10,3 ,63,,1",
 "66700,6,6,1998/10/01 14:21:00,139.9477270366,35.8463411748,1,11,4311002,3 ,9 ,37,,2",
 "149500,6,6,1998/10/01 14:21:00,140.1050186186,35.7139571157,2,10,4022410,4 ,3 ,29,,6",
 "188600,2,2,1998/10/01 14:21:00,140.1882977166,36.1517312766,2,15,5311005,15,4 ,29,,6",
 "328900,2,2,1998/10/01 14:21:00,139.5912312047,35.5867536633,2,8,2120102,9 ,10,28,,1",
 "271400,4,4,1998/10/01 14:21:00,139.5021596924,35.4297805896,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:21:00,139.4279226867,35.4783030831,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:21:00,139.3524892172,35.5565180055,2,12,2924401,14,3 ,33,,6",
 "441600,6,6,1998/10/01 14:21:00,139.7837541415,35.6972746687,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:21:00,139.6172562455,35.9213586302,1,10,3512006,6 ,1 ,36,,6",
 "499100,4,4,1998/10/01 14:21:00,139.7359329122,36.0257800638,2,11,3612106,14,7 ,39,,2",
 "581900,4,7,1998/10/01 14:21:00,139.7728782657,35.7262333644,1,9,0321101,5 ,3 ,97,,12",
 "572700,4,4,1998/10/01 14:21:00,139.711249966,35.669420247,2,2,0241003,12,3 ,27,,1",
 "584200,2,4,1998/10/01 14:21:00,139.7725714205,35.6725554481,2,6,0332001,9 ,11,49,,1",
 "680800,2,2,1998/10/01 14:21:00,139.8843974217,35.729939635,1,5,0632105,15,4 ,63,,1",
 "786600,3,5,1998/10/01 14:21:00,139.3755284097,35.6706813106,2,3,0930110,13,2 ,35,,9",
 "685400,4,6,1998/10/01 14:21:00,139.4083856827,35.6469072013,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 14:21:00,139.7455460499,35.6939975516,1,14,0730209,10,1 ,26,,12",
 "71300,4,7,1998/10/01 14:22:00,139.6367587592,35.8888781929,1,12,4313309,9 ,13,34,,12",
 "163300,4,7,1998/10/01 14:22:00,139.710104915,35.7695454072,2,11,4061110,4 ,3 ,30,,1",
 "832600,2,2,1998/10/01 14:22:00,139.6240497302,35.416671706,1,14,1111003,15,4 ,30,,1",
 "234600,8,12,1998/10/01 14:22:00,139.6418429876,35.6991154602,1,14,2412116,10,3 ,63,,1",
 "66700,6,6,1998/10/01 14:22:00,139.9476164674,35.8460341946,1,11,4311002,3 ,9 ,37,,2",
 "149500,6,6,1998/10/01 14:22:00,140.1066505562,35.7131073733,2,10,4022410,4 ,3 ,29,,6",
 "188600,2,2,1998/10/01 14:22:00,140.1940390518,36.1531902682,2,15,5311005,15,4 ,29,,6",
 "328900,2,2,1998/10/01 14:22:00,139.5912037586,35.5871635575,2,8,2120102,9 ,10,28,,1",
 "328900,2,3,1998/10/01 14:22:00,139.5912037586,35.5871635575,2,8,2120102,9 ,10,28,,12",
 "271400,4,4,1998/10/01 14:22:00,139.5016653871,35.4265565424,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:22:00,139.4279058665,35.4777976221,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:22:00,139.351010575,35.5563056262,2,12,2924401,14,3 ,33,,6",
 "441600,6,6,1998/10/01 14:22:00,139.7860620532,35.6980571958,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:22:00,139.6172246395,35.9214731037,1,10,3512006,6 ,1 ,36,,6",
 "499100,4,4,1998/10/01 14:22:00,139.7349541161,36.0261149523,2,11,3612106,14,7 ,39,,2",
 "529000,8,8,1998/10/01 14:22:00,139.0806589786,35.9831827272,2,6,3910031,14,4 ,34,,6",
 "581900,4,7,1998/10/01 14:22:00,139.7754216483,35.7239576932,1,9,0321101,5 ,3 ,97,,12",
 "572700,4,4,1998/10/01 14:22:00,139.7114707539,35.6696533624,2,2,0241003,12,3 ,27,,1",
 "584200,2,4,1998/10/01 14:22:00,139.7724099781,35.6719692476,2,6,0332001,9 ,11,49,,1",
 "680800,2,2,1998/10/01 14:22:00,139.8840398067,35.7296364585,1,5,0632105,15,4 ,63,,1",
 "786600,3,5,1998/10/01 14:22:00,139.375828696,35.6707915515,2,3,0930110,13,2 ,35,,9",
 "685400,4,6,1998/10/01 14:22:00,139.4084905581,35.646539853,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 14:22:00,139.7434343177,35.6912508181,1,14,0730209,10,1 ,26,,12",
 "71300,4,7,1998/10/01 14:23:00,139.6345136264,35.8925490803,1,12,4313309,9 ,13,34,,12",
 "163300,4,7,1998/10/01 14:23:00,139.710105115,35.7695455163,2,11,4061110,4 ,3 ,30,,1",
 "832600,2,2,1998/10/01 14:23:00,139.6249796318,35.4168269091,1,14,1111003,15,4 ,30,,1",
 "234600,8,12,1998/10/01 14:23:00,139.6412829534,35.699998771,1,14,2412116,10,3 ,63,,1",
 "66700,6,6,1998/10/01 14:23:00,139.9474947559,35.8457298974,1,11,4311002,3 ,9 ,37,,2",
 "149500,6,6,1998/10/01 14:23:00,140.1074239985,35.711634122,2,10,4022410,4 ,3 ,29,,6",
 "188600,2,2,1998/10/01 14:23:00,140.1999470356,36.1536063026,2,15,5311005,15,4 ,29,,6",
 "328900,2,3,1998/10/01 14:23:00,139.6124311853,35.6008148757,2,8,2120102,9 ,10,28,,12",
 "271400,4,4,1998/10/01 14:23:00,139.5016266909,35.4232813609,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:23:00,139.4275964908,35.4775451355,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:23:00,139.3495418502,35.5560518744,2,12,2924401,14,3 ,33,,6",
 "441600,6,6,1998/10/01 14:23:00,139.787468373,35.7002104715,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:23:00,139.6171930333,35.9215875773,1,10,3512006,6 ,1 ,36,,6",
 "499100,4,4,1998/10/01 14:23:00,139.7339753117,36.0264498328,2,11,3612106,14,7 ,39,,2",
 "529000,8,8,1998/10/01 14:23:00,139.0807247737,35.9817942857,2,6,3910031,14,4 ,34,,6",
 "581900,4,7,1998/10/01 14:23:00,139.777879256,35.7216192581,1,9,0321101,5 ,3 ,97,,12",
 "572700,4,4,1998/10/01 14:23:00,139.7116931159,35.6698854785,2,2,0241003,12,3 ,27,,1",
 "584200,2,4,1998/10/01 14:23:00,139.7723370424,35.6714163204,2,6,0332001,9 ,11,49,,1",
 "680800,2,2,1998/10/01 14:23:00,139.883451888,35.7295794604,1,5,0632105,15,4 ,63,,1",
 "680800,3,3,1998/10/01 14:23:00,139.883451888,35.7295794604,1,5,0632105,15,97,63,,97",
 "786600,3,5,1998/10/01 14:23:00,139.3761354375,35.6708894976,2,3,0930110,13,2 ,35,,9",
 "685400,4,6,1998/10/01 14:23:00,139.4085973824,35.6461729143,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 14:23:00,139.7421894875,35.6880506314,1,14,0730209,10,1 ,26,,12",
 "71300,4,7,1998/10/01 14:24:00,139.6320645297,35.8961322346,1,12,4313309,9 ,13,34,,12",
 "832600,2,2,1998/10/01 14:24:00,139.62592183,35.4169211529,1,14,1111003,15,4 ,30,,1",
 "163300,4,7,1998/10/01 14:24:00,139.7101053151,35.7695456255,2,11,4061110,4 ,3 ,30,,1",
 "234600,8,12,1998/10/01 14:24:00,139.6407229068,35.700882079,1,14,2412116,10,3 ,63,,1",
 "66700,6,6,1998/10/01 14:24:00,139.9473730453,35.8454256,1,11,4311002,3 ,9 ,37,,2",
 "149500,6,6,1998/10/01 14:24:00,140.1079192443,35.7100694083,2,10,4022410,4 ,3 ,29,,6",
 "188600,2,2,1998/10/01 14:24:00,140.2055896105,36.1541405387,2,15,5311005,15,4 ,29,,6",
 "328900,2,3,1998/10/01 14:24:00,139.6297378296,35.6177311678,2,8,2120102,9 ,10,28,,12",
 "271400,4,4,1998/10/01 14:24:00,139.5006740403,35.4200809772,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:24:00,139.4269785733,35.477559444,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:24:00,139.3480646052,35.5558381399,2,12,2924401,14,3 ,33,,6",
 "441600,6,6,1998/10/01 14:24:00,139.7892969786,35.7018868282,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:24:00,139.6171614271,35.9217020508,1,10,3512006,6 ,1 ,36,,6",
 "499100,4,4,1998/10/01 14:24:00,139.7330053977,36.0267990539,2,11,3612106,14,7 ,39,,2",
 "529000,8,8,1998/10/01 14:24:00,139.0807249717,35.9835053929,2,6,3910031,14,4 ,34,,6",
 "581900,4,7,1998/10/01 14:24:00,139.7797301109,35.71899947,1,9,0321101,5 ,3 ,97,,12",
 "572700,4,4,1998/10/01 14:24:00,139.7119186299,35.670115561,2,2,0241003,12,3 ,27,,1",
 "584200,2,4,1998/10/01 14:24:00,139.7727970672,35.6710035743,2,6,0332001,9 ,11,49,,1",
 "786600,3,5,1998/10/01 14:24:00,139.3764419395,35.6709879385,2,3,0930110,13,2 ,35,,9",
 "685400,4,6,1998/10/01 14:24:00,139.4087228781,35.6458098995,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 14:24:00,139.7415966646,35.6847375356,1,14,0730209,10,1 ,26,,12",
 "71300,4,7,1998/10/01 14:25:00,139.6288840647,35.899308258,1,12,4313309,9 ,13,34,,12",
 "163300,4,7,1998/10/01 14:25:00,139.7101055151,35.7695457346,2,11,4061110,4 ,3 ,30,,1",
 "832600,2,2,1998/10/01 14:25:00,139.6268239534,35.4171830634,1,14,1111003,15,4 ,30,,1",
 "234600,8,12,1998/10/01 14:25:00,139.6401628478,35.7017653842,1,14,2412116,10,3 ,63,,1",
 "66700,6,6,1998/10/01 14:25:00,139.9472187227,35.8451312803,1,11,4311002,3 ,9 ,37,,2",
 "149500,6,6,1998/10/01 14:25:00,140.1084015609,35.7087258847,2,10,4022410,4 ,3 ,29,,6",
 "188600,2,2,1998/10/01 14:25:00,140.2065084286,36.1588171081,2,15,5311005,15,4 ,29,,6",
 "328900,2,3,1998/10/01 14:25:00,139.648433746,35.6317810578,2,8,2120102,9 ,10,28,,12",
 "271400,4,4,1998/10/01 14:25:00,139.4998662589,35.4168527855,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:25:00,139.4263606557,35.4775737493,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:25:00,139.3466473766,35.5554338364,2,12,2924401,14,3 ,33,,6",
 "441600,6,6,1998/10/01 14:25:00,139.7913954224,35.7035919895,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:25:00,139.6171304044,35.9218166244,1,10,3512006,6 ,1 ,36,,6",
 "499100,4,4,1998/10/01 14:25:00,139.7320915527,36.027238691,2,11,3612106,14,7 ,39,,2",
 "529000,8,8,1998/10/01 14:25:00,139.0812200609,35.9843651364,2,6,3910031,14,4 ,34,,6",
 "572700,4,4,1998/10/01 14:25:00,139.7121581672,35.6703353139,2,2,0241003,12,3 ,27,,1",
 "581900,4,7,1998/10/01 14:25:00,139.7782828207,35.7162487089,1,9,0321101,5 ,3 ,97,,12",
 "584200,2,4,1998/10/01 14:25:00,139.7727727501,35.6706319829,2,6,0332001,9 ,11,49,,1",
 "584200,3,5,1998/10/01 14:25:00,139.7727727501,35.6706319829,2,6,0332001,9 ,97,49,,97",
 "786600,3,5,1998/10/01 14:25:00,139.3767477596,35.6710877864,2,3,0930110,13,2 ,35,,9",
 "706100,4,8,1998/10/01 14:25:00,139.7418824614,35.6813921761,1,14,0730209,10,1 ,26,,12",
 "685400,4,6,1998/10/01 14:25:00,139.4088483726,35.6454468846,1,11,0634016,15,7 ,48,,10",
 "71300,4,7,1998/10/01 14:26:00,139.6260055014,35.9026528528,1,12,4313309,9 ,13,34,,12",
 "832600,2,2,1998/10/01 14:26:00,139.6273100538,35.4167387732,1,14,1111003,15,4 ,30,,1",
 "163300,4,7,1998/10/01 14:26:00,139.7101057152,35.7695458437,2,11,4061110,4 ,3 ,30,,1",
 "234600,8,12,1998/10/01 14:26:00,139.6395439229,35.7026138257,1,14,2412116,10,3 ,63,,1",
 "66700,6,6,1998/10/01 14:26:00,139.9471658332,35.8448181061,1,11,4311002,3 ,9 ,37,,2",
 "149500,6,6,1998/10/01 14:26:00,140.1100849534,35.7093923132,2,10,4022410,4 ,3 ,29,,6",
 "188600,2,2,1998/10/01 14:26:00,140.2057382947,36.1631657635,2,15,5311005,15,4 ,29,,6",
 "328900,2,3,1998/10/01 14:26:00,139.6699257016,35.6420498028,2,8,2120102,9 ,10,28,,12",
 "271400,4,4,1998/10/01 14:26:00,139.4993372544,35.4136242522,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:26:00,139.4257427379,35.4775880514,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:26:00,139.3452317906,35.555025157,2,12,2924401,14,3 ,33,,6",
 "388700,2,2,1998/10/01 14:26:00,139.5945006764,35.8033569803,2,11,3110001,14,5 ,34,,6",
 "441600,6,6,1998/10/01 14:26:00,139.7934517405,35.7053646831,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:26:00,139.6171025229,35.9219317365,1,10,3512006,6 ,1 ,36,,6",
 "499100,4,4,1998/10/01 14:26:00,139.7311776976,36.0276783211,2,11,3612106,14,7 ,39,,2",
 "529000,8,8,1998/10/01 14:26:00,139.0825402665,35.9858364176,2,6,3910031,14,4 ,34,,6",
 "581900,4,7,1998/10/01 14:26:00,139.7767647111,35.7135676179,1,9,0321101,5 ,3 ,97,,12",
 "581900,4,8,1998/10/01 14:26:00,139.7767647111,35.7135676179,1,9,0321101,5 ,3 ,97,,2",
 "572700,4,4,1998/10/01 14:26:00,139.7122835739,35.6705132381,2,2,0241003,12,3 ,27,,1",
 "786600,3,5,1998/10/01 14:26:00,139.3770535804,35.6711876335,2,3,0930110,13,2 ,35,,9",
 "685400,4,6,1998/10/01 14:26:00,139.408973866,35.6450838695,1,11,0634016,15,7 ,48,,10",
 "706100,4,8,1998/10/01 14:26:00,139.7404452624,35.6786197724,1,14,0730209,10,1 ,26,,12",
 "71300,4,7,1998/10/01 14:27:00,139.6236044817,35.906261475,1,12,4313309,9 ,13,34,,12",
 "71300,4,8,1998/10/01 14:27:00,139.6236044817,35.906261475,1,12,4313309,9 ,13,34,,10",
 "163300,4,7,1998/10/01 14:27:00,139.7101059153,35.7695459529,2,11,4061110,4 ,3 ,30,,1",
 "163300,4,8,1998/10/01 14:27:00,139.7101059153,35.7695459529,2,11,4061110,4 ,3 ,30,,10",
 "832600,2,2,1998/10/01 14:27:00,139.6280828283,35.4167471713,1,14,1111003,15,4 ,30,,1",
 "234600,8,12,1998/10/01 14:27:00,139.6386874623,35.7033215701,1,14,2412116,10,3 ,63,,1",
 "66700,6,6,1998/10/01 14:27:00,139.9471814051,35.8444983311,1,11,4311002,3 ,9 ,37,,2",
 "149500,6,6,1998/10/01 14:27:00,140.1119285364,35.7100096329,2,10,4022410,4 ,3 ,29,,6",
 "188600,2,2,1998/10/01 14:27:00,140.2074933615,36.1678430065,2,15,5311005,15,4 ,29,,6",
 "328900,2,3,1998/10/01 14:27:00,139.6933993927,35.6540802893,2,8,2120102,9 ,10,28,,12",
 "271400,4,4,1998/10/01 14:27:00,139.4975640054,35.4106652364,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:27:00,139.4256258525,35.4771631655,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:27:00,139.3438066157,35.5546404176,2,12,2924401,14,3 ,33,,6",
 "388700,2,2,1998/10/01 14:27:00,139.5946190519,35.8054575936,2,11,3110001,14,5 ,34,,6",
 "441600,6,6,1998/10/01 14:27:00,139.7951465323,35.7073737643,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:27:00,139.6170746413,35.9220468486,1,10,3512006,6 ,1 ,36,,6",
 "499100,4,4,1998/10/01 14:27:00,139.7302638316,36.0281179431,2,11,3612106,14,7 ,39,,2",
 "529000,8,8,1998/10/01 14:27:00,139.0837771896,35.9873630965,2,6,3910031,14,4 ,34,,6",
 "581900,4,8,1998/10/01 14:27:00,139.7785661487,35.7130754171,1,9,0321101,5 ,3 ,97,,2",
 "572700,4,4,1998/10/01 14:27:00,139.7119540132,35.6706274479,2,2,0241003,12,3 ,27,,1",
 "786600,3,5,1998/10/01 14:27:00,139.377359402,35.6712874799,2,3,0930110,13,2 ,35,,9",
 "685400,4,6,1998/10/01 14:27:00,139.4090993583,35.6447208542,1,11,0634016,15,7 ,48,,10",
 "685400,4,7,1998/10/01 14:27:00,139.4090993583,35.6447208542,1,11,0634016,15,7 ,48,,1",
 "706100,4,8,1998/10/01 14:27:00,139.7364336323,35.6780511366,1,14,0730209,10,1 ,26,,12",
 "71300,4,8,1998/10/01 14:28:00,139.6231656423,35.9058219331,1,12,4313309,9 ,13,34,,10",
 "163300,4,8,1998/10/01 14:28:00,139.7123828918,35.7703212525,2,11,4061110,4 ,3 ,30,,10",
 "832600,2,2,1998/10/01 14:28:00,139.6285623978,35.4162702736,1,14,1111003,15,4 ,30,,1",
 "234600,8,12,1998/10/01 14:28:00,139.6377804569,35.7039822338,1,14,2412116,10,3 ,63,,1",
 "66700,6,6,1998/10/01 14:28:00,139.9471969769,35.844178556,1,11,4311002,3 ,9 ,37,,2",
 "149500,6,6,1998/10/01 14:28:00,140.1138282616,35.7099384548,2,10,4022410,4 ,3 ,29,,6",
 "188600,2,2,1998/10/01 14:28:00,140.2057122684,36.1685059803,2,15,5311005,15,4 ,29,,6",
 "328900,2,3,1998/10/01 14:28:00,139.7138251993,35.666518146,2,8,2120102,9 ,10,28,,12",
 "271400,4,4,1998/10/01 14:28:00,139.4964808181,35.4077938712,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:28:00,139.4256006219,35.4766579387,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:28:00,139.3435851099,35.5537156083,2,12,2924401,14,3 ,33,,6",
 "388700,2,2,1998/10/01 14:28:00,139.5936097622,35.8079812099,2,11,3110001,14,5 ,34,,6",
 "441600,6,6,1998/10/01 14:28:00,139.7970232173,35.7084585126,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:28:00,139.6170467596,35.9221619606,1,10,3512006,6 ,1 ,36,,6",
 "499100,4,4,1998/10/01 14:28:00,139.7293499502,36.0285575509,2,11,3612106,14,7 ,39,,2",
 "529000,8,8,1998/10/01 14:28:00,139.0839930604,35.9891411121,2,6,3910031,14,4 ,34,,6",
 "581900,4,8,1998/10/01 14:28:00,139.7810281839,35.7131768219,1,9,0321101,5 ,3 ,97,,2",
 "572700,4,4,1998/10/01 14:28:00,139.711640934,35.6707742009,2,2,0241003,12,3 ,27,,1",
 "786600,3,5,1998/10/01 14:28:00,139.3776652244,35.6713873254,2,3,0930110,13,2 ,35,,9",
 "685400,4,7,1998/10/01 14:28:00,139.4034074995,35.6476698906,1,11,0634016,15,7 ,48,,1",
 "706100,4,8,1998/10/01 14:28:00,139.7330833812,35.6761011941,1,14,0730209,10,1 ,26,,12",
 "71300,4,8,1998/10/01 14:29:00,139.6228524179,35.9053649129,1,12,4313309,9 ,13,34,,10",
 "163300,4,8,1998/10/01 14:29:00,139.714011981,35.7715359854,2,11,4061110,4 ,3 ,30,,10",
 "832600,2,2,1998/10/01 14:29:00,139.6289426826,35.4155511789,1,14,1111003,15,4 ,30,,1",
 "234600,8,12,1998/10/01 14:29:00,139.6368071742,35.7045811583,1,14,2412116,10,3 ,63,,1",
 "66700,6,6,1998/10/01 14:29:00,139.9468107052,35.8441578804,1,11,4311002,3 ,9 ,37,,2",
 "149500,6,6,1998/10/01 14:29:00,140.1152923151,35.7089118529,2,10,4022410,4 ,3 ,29,,6",
 "188600,2,2,1998/10/01 14:29:00,140.2015567007,36.1696999111,2,15,5311005,15,4 ,29,,6",
 "328900,2,3,1998/10/01 14:29:00,139.7370557983,35.6783134116,2,8,2120102,9 ,10,28,,12",
 "271400,4,4,1998/10/01 14:29:00,139.4943287099,35.4052857996,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:29:00,139.4261112712,35.4764678855,1,13,2912305,15,3 ,31,,4",
 "308200,4,4,1998/10/01 14:29:00,139.3441211282,35.5527130508,2,12,2924401,14,3 ,33,,6",
 "388700,2,2,1998/10/01 14:29:00,139.5943506919,35.8102880513,2,11,3110001,14,5 ,34,,6",
 "441600,6,6,1998/10/01 14:29:00,139.7992861105,35.7083837974,1,9,3215009,9 ,10,30,,8",
 "487600,6,6,1998/10/01 14:29:00,139.6170188778,35.9222770727,1,10,3512006,6 ,1 ,36,,6",
 "499100,4,4,1998/10/01 14:29:00,139.7290793433,36.0291342416,2,11,3612106,14,7 ,39,,2",
 "529000,8,8,1998/10/01 14:29:00,139.0842344588,35.9909564734,2,6,3910031,14,4 ,34,,6",
 "581900,4,8,1998/10/01 14:29:00,139.7835466139,35.7140045822,1,9,0321101,5 ,3 ,97,,2",
 "572700,4,4,1998/10/01 14:29:00,139.7113798541,35.6709594705,2,2,0241003,12,3 ,27,,1",
 "786600,3,5,1998/10/01 14:29:00,139.3779710475,35.6714871702,2,3,0930110,13,2 ,35,,9",
 "685400,4,7,1998/10/01 14:29:00,139.4020292979,35.6399933859,1,11,0634016,15,7 ,48,,1",
 "706100,4,8,1998/10/01 14:29:00,139.7294543969,35.6745905275,1,14,0730209,10,1 ,26,,12",
 "786600,3,5,1998/10/01 14:30:00,139.3782768714,35.6715870143,2,3,0930110,13,2 ,35,,9",
 "71300,4,8,1998/10/01 14:30:00,139.6230867692,35.904831705,1,12,4313309,9 ,13,34,,10",
 "832600,2,2,1998/10/01 14:30:00,139.6291643414,35.4147922097,1,14,1111003,15,4 ,30,,1",
 "832600,3,3,1998/10/01 14:30:00,139.6291643414,35.4147922097,1,14,1111003,15,3 ,30,,1",
 "163300,4,8,1998/10/01 14:30:00,139.7163146877,35.7722710675,2,11,4061110,4 ,3 ,30,,10",
 "234600,8,12,1998/10/01 14:30:00,139.6363044357,35.7048979168,1,14,2412116,10,3 ,63,,1",
 "234600,8,13,1998/10/01 14:30:00,139.6363044357,35.7048979168,1,14,2412116,10,3 ,63,,12",
 "66700,6,6,1998/10/01 14:30:00,139.9467526879,35.8439425681,1,11,4311002,3 ,9 ,37,,2",
 "66700,7,7,1998/10/01 14:30:00,139.9467526879,35.8439425681,1,11,4311002,3 ,97,37,,97",
 "149500,6,6,1998/10/01 14:30:00,140.1155674189,35.7073044076,2,10,4022410,4 ,3 ,29,,6",
 "188600,2,2,1998/10/01 14:30:00,140.2017300116,36.172062121,2,15,5311005,15,4 ,29,,6",
 "188600,3,3,1998/10/01 14:30:00,140.2017300116,36.172062121,2,15,5311005,15,97,29,,97",
 "328900,2,3,1998/10/01 14:30:00,139.7633632325,35.6757580918,2,8,2120102,9 ,10,28,,12",
 "271400,4,4,1998/10/01 14:30:00,139.4917703412,35.4027762137,1,7,2811315,9 ,11,37,,8",
 "287500,4,4,1998/10/01 14:30:00,139.4267047972,35.4763265739,1,13,2912305,15,3 ,31,,4",
 "287500,5,5,1998/10/01 14:30:00,139.4267047972,35.4763265739,1,13,2912305,15,97,31,,97",
 "308200,4,4,1998/10/01 14:30:00,139.3439944286,35.5514905642,2,12,2924401,14,3 ,33,,6",
 "308200,5,5,1998/10/01 14:30:00,139.3439944286,35.5514905642,2,12,2924401,14,97,33,,97",
 "388700,2,2,1998/10/01 14:30:00,139.5936797016,35.8125430385,2,11,3110001,14,5 ,34,,6",
 "441600,6,6,1998/10/01 14:30:00,139.8017240299,35.7095433653,1,9,3215009,9 ,10,30,,8",
 "499100,4,4,1998/10/01 14:30:00,139.7292252231,36.029916712,2,11,3612106,14,7 ,39,,2",
 "499100,5,5,1998/10/01 14:30:00,139.7292252231,36.029916712,2,11,3612106,14,97,39,,97",
 "487600,6,6,1998/10/01 14:30:00,139.616990996,35.9223921848,1,10,3512006,6 ,1 ,36,,6",
 "529000,8,8,1998/10/01 14:30:00,139.0841166953,35.992669122,2,6,3910031,14,4 ,34,,6",
 "572700,4,4,1998/10/01 14:30:00,139.7111766548,35.6707159357,2,2,0241003,12,3 ,27,,1",
 "572700,5,5,1998/10/01 14:30:00,139.7111766548,35.6707159357,2,2,0241003,12,97,27,,97",
 "581900,4,8,1998/10/01 14:30:00,139.7858126214,35.7140504291,1,9,0321101,5 ,3 ,97,,2",
 "581900,5,9,1998/10/01 14:30:00,139.7858126214,35.7140504291,1,9,0321101,5 ,97,97,,97",
 "685400,4,7,1998/10/01 14:30:00,139.4100779878,35.6346435822,1,11,0634016,15,7 ,48,,1",
 "685400,5,8,1998/10/01 14:30:00,139.4100779878,35.6346435822,1,11,0634016,15,97,48,,97",
 "706100,4,8,1998/10/01 14:30:00,139.7256351464,35.6733398578,1,14,0730209,10,1 ,26,,12",
 "71300,4,8,1998/10/01 14:31:00,139.6233211173,35.9042984966,1,12,4313309,9 ,13,34,,10",
 "163300,4,8,1998/10/01 14:31:00,139.7182063237,35.7735885312,2,11,4061110,4 ,3 ,30,,10",
 "832600,3,3,1998/10/01 14:31:00,139.6288581659,35.4154915309,1,14,1111003,15,3 ,30,,1",
 "234600,8,13,1998/10/01 14:31:00,139.6421837864,35.7050779333,1,14,2412116,10,3 ,63,,12",
 "149500,6,6,1998/10/01 14:31:00,140.1160225634,35.7057652398,2,10,4022410,4 ,3 ,29,,6",
 "328900,2,3,1998/10/01 14:31:00,139.7860884079,35.663215706,2,8,2120102,9 ,10,28,,12",
 "216200,6,6,1998/10/01 14:31:00,139.678249607,35.2493785246,2,1,2221106,12,5 ,34,,1",
 "271400,4,4,1998/10/01 14:31:00,139.4898179593,35.3999082209,1,7,2811315,9 ,11,37,,8",
 "377200,6,6,1998/10/01 14:31:00,139.637810454,35.9209795089,1,14,3052009,15,6 ,29,,3",
 "388700,2,2,1998/10/01 14:31:00,139.5918186119,35.8147873947,2,11,3110001,14,5 ,34,,6",
 "395600,2,2,1998/10/01 14:31:00,139.5700801128,35.7731986143,1,10,3113009,1 ,12,41,,8",
 "441600,6,6,1998/10/01 14:31:00,139.8042911131,35.7101870289,1,9,3215009,9 ,10,30,,8",
 "476100,4,6,1998/10/01 14:31:00,139.569411114,35.8123778534,1,3,3411213,13,3 ,34,,1",
 "487600,6,6,1998/10/01 14:31:00,139.616963114,35.9225072968,1,10,3512006,6 ,1 ,36,,6",
 "510600,2,2,1998/10/01 14:31:00,139.4538447153,36.1386802062,2,13,3631014,5 ,7 ,36,,3",
 "529000,8,8,1998/10/01 14:31:00,139.0836420627,35.9940849706,2,6,3910031,14,4 ,34,,6",
 "621000,12,16,1998/10/01 14:31:00,139.6453038725,35.6529009612,2,10,0436007,14,3 ,45,,2",
 "731400,4,4,1998/10/01 14:31:00,139.4770537142,35.6582691555,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:31:00,139.3785826961,35.6716868576,2,3,0930110,13,2 ,35,,9",
 "706100,4,8,1998/10/01 14:31:00,139.7258279615,35.6708628034,1,14,0730209,10,1 ,26,,12",
 "71300,4,8,1998/10/01 14:32:00,139.6235554623,35.9037652877,1,12,4313309,9 ,13,34,,10",
 "163300,4,8,1998/10/01 14:32:00,139.7192449522,35.7744338298,2,11,4061110,4 ,3 ,30,,10",
 "832600,3,3,1998/10/01 14:32:00,139.6285519851,35.4161908512,1,14,1111003,15,3 ,30,,1",
 "234600,8,13,1998/10/01 14:32:00,139.6480944499,35.7052724063,1,14,2412116,10,3 ,63,,12",
 "149500,6,6,1998/10/01 14:32:00,140.1160424164,35.7041757856,2,10,4022410,4 ,3 ,29,,6",
 "328900,2,3,1998/10/01 14:32:00,139.8067065812,35.6481217252,2,8,2120102,9 ,10,28,,12",
 "216200,6,6,1998/10/01 14:32:00,139.67712532,35.2488151975,2,1,2221106,12,5 ,34,,1",
 "271400,4,4,1998/10/01 14:32:00,139.4889964067,35.3968222189,1,7,2811315,9 ,11,37,,8",
 "377200,6,6,1998/10/01 14:32:00,139.6364065172,35.9208875084,1,14,3052009,15,6 ,29,,3",
 "388700,2,2,1998/10/01 14:32:00,139.5897313586,35.8168929977,2,11,3110001,14,5 ,34,,6",
 "395600,2,2,1998/10/01 14:32:00,139.5708346522,35.7714370144,1,10,3113009,1 ,12,41,,8",
 "441600,6,6,1998/10/01 14:32:00,139.8054015698,35.7124498808,1,9,3215009,9 ,10,30,,8",
 "476100,4,6,1998/10/01 14:32:00,139.5705171421,35.81329849,1,3,3411213,13,3 ,34,,1",
 "487600,6,6,1998/10/01 14:32:00,139.616935232,35.9226224088,1,10,3512006,6 ,1 ,36,,6",
 "510600,2,2,1998/10/01 14:32:00,139.4520601469,36.1379133115,2,13,3631014,5 ,7 ,36,,3",
 "529000,8,8,1998/10/01 14:32:00,139.0838803223,35.9959015064,2,6,3910031,14,4 ,34,,6",
 "621000,12,16,1998/10/01 14:32:00,139.646650517,35.6533753841,2,10,0436007,14,3 ,45,,2",
 "731400,4,4,1998/10/01 14:32:00,139.4766575251,35.6583872422,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:32:00,139.3788881088,35.6717875296,2,3,0930110,13,2 ,35,,9",
 "706100,4,8,1998/10/01 14:32:00,139.7276571708,35.6678903512,1,14,0730209,10,1 ,26,,12",
 "71300,4,8,1998/10/01 14:33:00,139.6237898041,35.9032320783,1,12,4313309,9 ,13,34,,10",
 "163300,4,8,1998/10/01 14:33:00,139.7208328791,35.7756434064,2,11,4061110,4 ,3 ,30,,10",
 "832600,3,3,1998/10/01 14:33:00,139.6282031344,35.4167996883,1,14,1111003,15,3 ,30,,1",
 "234600,8,13,1998/10/01 14:33:00,139.6540064656,35.7054371017,1,14,2412116,10,3 ,63,,12",
 "149500,6,6,1998/10/01 14:33:00,140.1175926226,35.7031852311,2,10,4022410,4 ,3 ,29,,6",
 "328900,2,3,1998/10/01 14:33:00,139.8322673769,35.6464973967,2,8,2120102,9 ,10,28,,12",
 "216200,6,6,1998/10/01 14:33:00,139.6779203942,35.2495141929,2,1,2221106,12,5 ,34,,1",
 "271400,4,4,1998/10/01 14:33:00,139.4898249897,35.3935976848,1,7,2811315,9 ,11,37,,8",
 "377200,6,6,1998/10/01 14:33:00,139.6353361132,35.9210829054,1,14,3052009,15,6 ,29,,3",
 "388700,2,2,1998/10/01 14:33:00,139.5872442504,35.8186550007,2,11,3110001,14,5 ,34,,6",
 "395600,2,2,1998/10/01 14:33:00,139.5709344642,35.7708719714,1,10,3113009,1 ,12,41,,8",
 "441600,6,6,1998/10/01 14:33:00,139.8076165117,35.7140771045,1,9,3215009,9 ,10,30,,8",
 "476100,4,6,1998/10/01 14:33:00,139.5711535118,35.8143373638,1,3,3411213,13,3 ,34,,1",
 "487600,6,6,1998/10/01 14:33:00,139.6169073499,35.9227375209,1,10,3512006,6 ,1 ,36,,6",
 "510600,2,2,1998/10/01 14:33:00,139.4507772004,36.1366719225,2,13,3631014,5 ,7 ,36,,3",
 "529000,8,8,1998/10/01 14:33:00,139.0845032015,35.9976492351,2,6,3910031,14,4 ,34,,6",
 "621000,12,16,1998/10/01 14:33:00,139.6470889252,35.6535053015,2,10,0436007,14,3 ,45,,2",
 "731400,4,4,1998/10/01 14:33:00,139.4762613348,35.6585053276,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:33:00,139.3791926903,35.6718898734,2,3,0930110,13,2 ,35,,9",
 "706100,4,8,1998/10/01 14:33:00,139.7246774213,35.6656140706,1,14,0730209,10,1 ,26,,12",
 "71300,4,8,1998/10/01 14:34:00,139.6240241428,35.9026988683,1,12,4313309,9 ,13,34,,10",
 "163300,4,8,1998/10/01 14:34:00,139.7209330163,35.7776696053,2,11,4061110,4 ,3 ,30,,10",
 "163300,4,9,1998/10/01 14:34:00,139.7209330163,35.7776696053,2,11,4061110,4 ,3 ,30,,12",
 "832600,3,3,1998/10/01 14:34:00,139.627348138,35.4165902967,1,14,1111003,15,3 ,30,,1",
 "234600,8,13,1998/10/01 14:34:00,139.6599191951,35.7055816561,1,14,2412116,10,3 ,63,,12",
 "149500,6,6,1998/10/01 14:34:00,140.1165625156,35.7018002778,2,10,4022410,4 ,3 ,29,,6",
 "328900,2,3,1998/10/01 14:34:00,139.8599943005,35.6447317044,2,8,2120102,9 ,10,28,,12",
 "216200,6,6,1998/10/01 14:34:00,139.6787223485,35.2501656906,2,1,2221106,12,5 ,34,,1",
 "271400,4,4,1998/10/01 14:34:00,139.4909295811,35.3904274929,1,7,2811315,9 ,11,37,,8",
 "377200,6,6,1998/10/01 14:34:00,139.6340379855,35.9215265545,1,14,3052009,15,6 ,29,,3",
 "388700,2,2,1998/10/01 14:34:00,139.5842840973,35.8198361647,2,11,3110001,14,5 ,34,,6",
 "395600,2,2,1998/10/01 14:34:00,139.569892656,35.7725328456,1,10,3113009,1 ,12,41,,8",
 "441600,6,6,1998/10/01 14:34:00,139.8099309226,35.7156252457,1,9,3215009,9 ,10,30,,8",
 "476100,4,6,1998/10/01 14:34:00,139.5717099102,35.8156252642,1,3,3411213,13,3 ,34,,1",
 "487600,6,6,1998/10/01 14:34:00,139.6168794678,35.9228526329,1,10,3512006,6 ,1 ,36,,6",
 "510600,2,2,1998/10/01 14:34:00,139.4488936727,36.1360831694,2,13,3631014,5 ,7 ,36,,3",
 "529000,8,8,1998/10/01 14:34:00,139.0847713984,35.9993454984,2,6,3910031,14,4 ,34,,6",
 "529000,9,9,1998/10/01 14:34:00,139.0847713984,35.9993454984,2,6,3910031,14,97,34,,97",
 "621000,12,16,1998/10/01 14:34:00,139.6476849634,35.6545114881,2,10,0436007,14,3 ,45,,2",
 "731400,4,4,1998/10/01 14:34:00,139.4758651433,35.6586234117,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:34:00,139.3794972726,35.6719922165,2,3,0930110,13,2 ,35,,9",
 "706100,4,8,1998/10/01 14:34:00,139.7208049506,35.6644955948,1,14,0730209,10,1 ,26,,12",
 "71300,4,8,1998/10/01 14:35:00,139.6242584783,35.9021656579,1,12,4313309,9 ,13,34,,10",
 "163300,4,9,1998/10/01 14:35:00,139.7222539313,35.7727312788,2,11,4061110,4 ,3 ,30,,12",
 "832600,3,3,1998/10/01 14:35:00,139.6270478919,35.4172435899,1,14,1111003,15,3 ,30,,1",
 "234600,8,13,1998/10/01 14:35:00,139.6658310912,35.7057487462,1,14,2412116,10,3 ,63,,12",
 "149500,6,6,1998/10/01 14:35:00,140.1154363149,35.7004601594,2,10,4022410,4 ,3 ,29,,6",
 "328900,2,3,1998/10/01 14:35:00,139.885655336,35.6354988602,2,8,2120102,9 ,10,28,,12",
 "216200,6,6,1998/10/01 14:35:00,139.6787201316,35.2503774362,2,1,2221106,12,5 ,34,,1",
 "271400,4,4,1998/10/01 14:35:00,139.4896082873,35.3879634422,1,7,2811315,9 ,11,37,,8",
 "377200,6,6,1998/10/01 14:35:00,139.6328525583,35.9221309041,1,14,3052009,15,6 ,29,,3",
 "377200,7,7,1998/10/01 14:35:00,139.6328525583,35.9221309041,1,14,3052009,15,97,29,,97",
 "388700,2,2,1998/10/01 14:35:00,139.5820488244,35.8193193652,2,11,3110001,14,5 ,34,,6",
 "388700,3,3,1998/10/01 14:35:00,139.5820488244,35.8193193652,2,11,3110001,14,97,34,,97",
 "395600,2,2,1998/10/01 14:35:00,139.5704783076,35.7722612933,1,10,3113009,1 ,12,41,,8",
 "441600,6,6,1998/10/01 14:35:00,139.8122364197,35.7171819681,1,9,3215009,9 ,10,30,,8",
 "476100,4,6,1998/10/01 14:35:00,139.5723299123,35.8168939982,1,3,3411213,13,3 ,34,,1",
 "487600,6,6,1998/10/01 14:35:00,139.6168533152,35.9229679918,1,10,3512006,6 ,1 ,36,,6",
 "510600,2,2,1998/10/01 14:35:00,139.4470684497,36.1356037795,2,13,3631014,5 ,7 ,36,,3",
 "621000,12,16,1998/10/01 14:35:00,139.6490173686,35.6550128086,2,10,0436007,14,3 ,45,,2",
 "621000,13,17,1998/10/01 14:35:00,139.6490173686,35.6550128086,2,10,0436007,14,97,45,,97",
 "731400,4,4,1998/10/01 14:35:00,139.4754689506,35.6587414944,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:35:00,139.3798018557,35.6720945588,2,3,0930110,13,2 ,35,,9",
 "706100,4,8,1998/10/01 14:35:00,139.7172335146,35.6630127324,1,14,0730209,10,1 ,26,,12",
 "71300,4,8,1998/10/01 14:36:00,139.6244928107,35.9016324469,1,12,4313309,9 ,13,34,,10",
 "163300,4,9,1998/10/01 14:36:00,139.7243566673,35.7677407698,2,11,4061110,4 ,3 ,30,,12",
 "832600,3,3,1998/10/01 14:36:00,139.6261904206,35.4170005619,1,14,1111003,15,3 ,30,,1",
 "234600,8,13,1998/10/01 14:36:00,139.6717427695,35.7059227026,1,14,2412116,10,3 ,63,,12",
 "149500,6,6,1998/10/01 14:36:00,140.1142418404,35.6991967092,2,10,4022410,4 ,3 ,29,,6",
 "328900,2,3,1998/10/01 14:36:00,139.9080726308,35.6458169002,2,8,2120102,9 ,10,28,,12",
 "216200,6,6,1998/10/01 14:36:00,139.6798528336,35.2512150395,2,1,2221106,12,5 ,34,,1",
 "271400,4,4,1998/10/01 14:36:00,139.4897972574,35.3852441215,1,7,2811315,9 ,11,37,,8",
 "395600,2,2,1998/10/01 14:36:00,139.5718843584,35.7707887658,1,10,3113009,1 ,12,41,,8",
 "441600,6,6,1998/10/01 14:36:00,139.814363761,35.7188969184,1,9,3215009,9 ,10,30,,8",
 "476100,4,6,1998/10/01 14:36:00,139.5729206424,35.8181719674,1,3,3411213,13,3 ,34,,1",
 "487600,6,6,1998/10/01 14:36:00,139.6168313412,35.9230839472,1,10,3512006,6 ,1 ,36,,6",
 "510600,2,2,1998/10/01 14:36:00,139.4450847463,36.1359734763,2,13,3631014,5 ,7 ,36,,3",
 "604900,4,4,1998/10/01 14:36:00,139.6131616583,35.6877727749,2,1,0423005,12,4 ,49,,1",
 "731400,4,4,1998/10/01 14:36:00,139.4750727568,35.6588595758,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:36:00,139.3801064395,35.6721969003,2,3,0930110,13,2 ,35,,9",
 "706100,4,8,1998/10/01 14:36:00,139.7136668149,35.6643886117,1,14,0730209,10,1 ,26,,12",
 "71300,4,8,1998/10/01 14:37:00,139.6247271399,35.9010992354,1,12,4313309,9 ,13,34,,10",
 "163300,4,9,1998/10/01 14:37:00,139.7273247545,35.7629882873,2,11,4061110,4 ,3 ,30,,12",
 "832600,3,3,1998/10/01 14:37:00,139.6253148289,35.4168341416,1,14,1111003,15,3 ,30,,1",
 "234600,8,13,1998/10/01 14:37:00,139.6776556652,35.7060623864,1,14,2412116,10,3 ,63,,12",
 "149500,6,6,1998/10/01 14:37:00,140.112938411,35.698136716,2,10,4022410,4 ,3 ,29,,6",
 "149500,7,7,1998/10/01 14:37:00,140.112938411,35.698136716,2,10,4022410,4 ,97,29,,97",
 "328900,2,3,1998/10/01 14:37:00,139.9228333825,35.6648508141,2,8,2120102,9 ,10,28,,12",
 "216200,6,6,1998/10/01 14:37:00,139.6811178981,35.2509739397,2,1,2221106,12,5 ,34,,1",
 "271400,4,4,1998/10/01 14:37:00,139.491144045,35.3823951957,1,7,2811315,9 ,11,37,,8",
 "395600,2,2,1998/10/01 14:37:00,139.5717437929,35.7702178022,1,10,3113009,1 ,12,41,,8",
 "441600,6,6,1998/10/01 14:37:00,139.8163752869,35.7207050194,1,9,3215009,9 ,10,30,,8",
 "476100,4,6,1998/10/01 14:37:00,139.5732198031,35.819512914,1,3,3411213,13,3 ,34,,1",
 "487600,6,6,1998/10/01 14:37:00,139.6168093671,35.9231999026,1,10,3512006,6 ,1 ,36,,6",
 "510600,2,2,1998/10/01 14:37:00,139.4430950593,36.1363204473,2,13,3631014,5 ,7 ,36,,3",
 "604900,4,4,1998/10/01 14:37:00,139.6133393965,35.6873524823,2,1,0423005,12,4 ,49,,1",
 "731400,4,4,1998/10/01 14:37:00,139.4746765619,35.658977656,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:37:00,139.3804112329,35.6722988225,2,3,0930110,13,2 ,35,,9",
 "706100,4,8,1998/10/01 14:37:00,139.7116508192,35.6640985584,1,14,0730209,10,1 ,26,,12",
 "71300,4,8,1998/10/01 14:38:00,139.6247320803,35.9011543116,1,12,4313309,9 ,13,34,,10",
 "163300,4,9,1998/10/01 14:38:00,139.7320833066,35.7593405732,2,11,4061110,4 ,3 ,30,,12",
 "832600,3,3,1998/10/01 14:38:00,139.6244176673,35.4167646462,1,14,1111003,15,3 ,30,,1",
 "234600,8,13,1998/10/01 14:38:00,139.6835701873,35.7061521798,1,14,2412116,10,3 ,63,,12",
 "328900,2,3,1998/10/01 14:38:00,139.9406491853,35.682133783,2,8,2120102,9 ,10,28,,12",
 "216200,6,6,1998/10/01 14:38:00,139.6821765806,35.2505217659,2,1,2221106,12,5 ,34,,1",
 "271400,4,4,1998/10/01 14:38:00,139.4903219438,35.3793367134,1,7,2811315,9 ,11,37,,8",
 "395600,2,2,1998/10/01 14:38:00,139.5740264551,35.7703615242,1,10,3113009,1 ,12,41,,8",
 "441600,6,6,1998/10/01 14:38:00,139.8184078052,35.72249683,1,9,3215009,9 ,10,30,,8",
 "476100,4,6,1998/10/01 14:38:00,139.5734185376,35.8208622478,1,3,3411213,13,3 ,34,,1",
 "487600,6,6,1998/10/01 14:38:00,139.6167873929,35.923315858,1,10,3512006,6 ,1 ,36,,6",
 "510600,2,2,1998/10/01 14:38:00,139.4410903764,36.1365814839,2,13,3631014,5 ,7 ,36,,3",
 "604900,4,4,1998/10/01 14:38:00,139.6135171327,35.6869321895,2,1,0423005,12,4 ,49,,1",
 "731400,4,4,1998/10/01 14:38:00,139.4742803658,35.6590957348,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:38:00,139.3807168521,35.6723990893,2,3,0930110,13,2 ,35,,9",
 "706100,4,8,1998/10/01 14:38:00,139.7087390449,35.6617562547,1,14,0730209,10,1 ,26,,12",
 "71300,4,8,1998/10/01 14:39:00,139.6245457522,35.901699913,1,12,4313309,9 ,13,34,,10",
 "832600,3,3,1998/10/01 14:39:00,139.6235398824,35.4165920101,1,14,1111003,15,3 ,30,,1",
 "163300,4,9,1998/10/01 14:39:00,139.7359400191,35.7550567502,2,11,4061110,4 ,3 ,30,,12",
 "234600,8,13,1998/10/01 14:39:00,139.6894322672,35.7059340973,1,14,2412116,10,3 ,63,,12",
 "328900,2,3,1998/10/01 14:39:00,139.9634686773,35.6913898095,2,8,2120102,9 ,10,28,,12",
 "216200,6,6,1998/10/01 14:39:00,139.6833378029,35.2504820119,2,1,2221106,12,5 ,34,,1",
 "271400,4,4,1998/10/01 14:39:00,139.4902058649,35.3762641796,1,7,2811315,9 ,11,37,,8",
 "395600,2,2,1998/10/01 14:39:00,139.5729920677,35.7704167017,1,10,3113009,1 ,12,41,,8",
 "441600,6,6,1998/10/01 14:39:00,139.8203832791,35.7243309914,1,9,3215009,9 ,10,30,,8",
 "476100,4,6,1998/10/01 14:39:00,139.5736693414,35.8220533793,1,3,3411213,13,3 ,34,,1",
 "487600,6,6,1998/10/01 14:39:00,139.6167654187,35.9234318134,1,10,3512006,6 ,1 ,36,,6",
 "510600,2,2,1998/10/01 14:39:00,139.4402240145,36.1356767489,2,13,3631014,5 ,7 ,36,,3",
 "604900,4,4,1998/10/01 14:39:00,139.6134587653,35.6864988123,2,1,0423005,12,4 ,49,,1",
 "731400,4,4,1998/10/01 14:39:00,139.4738841685,35.6592138123,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:39:00,139.3810224722,35.6724993553,2,3,0930110,13,2 ,35,,9",
 "706100,4,8,1998/10/01 14:39:00,139.7053893272,35.6598292566,1,14,0730209,10,1 ,26,,12",
 "71300,4,8,1998/10/01 14:40:00,139.6246687679,35.9015352816,1,12,4313309,9 ,13,34,,10",
 "832600,3,3,1998/10/01 14:40:00,139.6226340637,35.4166369226,1,14,1111003,15,3 ,30,,1",
 "163300,4,9,1998/10/01 14:40:00,139.7399582961,35.7508726291,2,11,4061110,4 ,3 ,30,,12",
 "234600,8,13,1998/10/01 14:40:00,139.6946408062,35.7037934739,1,14,2412116,10,3 ,63,,12",
 "328900,2,3,1998/10/01 14:40:00,139.9901509171,35.6843340872,2,8,2120102,9 ,10,28,,12",
 "216200,6,6,1998/10/01 14:40:00,139.6840674244,35.2506775335,2,1,2221106,12,5 ,34,,1",
 "216200,7,7,1998/10/01 14:40:00,139.6840674244,35.2506775335,2,1,2221106,12,97,34,,97",
 "271400,4,4,1998/10/01 14:40:00,139.4872787198,35.3741215288,1,7,2811315,9 ,11,37,,8",
 "395600,2,2,1998/10/01 14:40:00,139.5752218424,35.77084001,1,10,3113009,1 ,12,41,,8",
 "395600,3,3,1998/10/01 14:40:00,139.5752218424,35.77084001,1,10,3113009,1 ,97,41,,97",
 "441600,6,6,1998/10/01 14:40:00,139.8223809709,35.7261490131,1,9,3215009,9 ,10,30,,8",
 "476100,4,6,1998/10/01 14:40:00,139.5730543906,35.8230974615,1,3,3411213,13,3 ,34,,1",
 "487600,6,6,1998/10/01 14:40:00,139.6167434444,35.9235477687,1,10,3512006,6 ,1 ,36,,6",
 "510600,2,2,1998/10/01 14:40:00,139.4383259639,36.1360020519,2,13,3631014,5 ,7 ,36,,3",
 "510600,3,3,1998/10/01 14:40:00,139.4383259639,36.1360020519,2,13,3631014,5 ,97,36,,97",
 "604900,4,4,1998/10/01 14:40:00,139.6133523313,35.6860627712,2,1,0423005,12,4 ,49,,1",
 "731400,4,4,1998/10/01 14:40:00,139.4734879701,35.6593318884,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:40:00,139.3813262026,35.6726033285,2,3,0930110,13,2 ,35,,9",
 "706100,4,8,1998/10/01 14:40:00,139.7017727956,35.658516117,1,14,0730209,10,1 ,26,,12",
 "706100,4,9,1998/10/01 14:40:00,139.7017727956,35.658516117,1,14,0730209,10,1 ,26,,1",
 "71300,4,8,1998/10/01 14:41:00,139.624946265,35.901015966,1,12,4313309,9 ,13,34,,10",
 "163300,4,9,1998/10/01 14:41:00,139.7450474482,35.7476048452,2,11,4061110,4 ,3 ,30,,12",
 "832600,3,3,1998/10/01 14:41:00,139.6217279836,35.4166780731,1,14,1111003,15,3 ,30,,1",
 "234600,8,13,1998/10/01 14:41:00,139.6979655218,35.6998443857,1,14,2412116,10,3 ,63,,12",
 "105800,4,4,1998/10/01 14:41:00,139.9212753432,35.3425923337,2,2,4612227,12,3 ,32,,1",
 "328900,2,3,1998/10/01 14:41:00,140.0099124066,35.669146613,2,8,2120102,9 ,10,28,,12",
 "271400,4,4,1998/10/01 14:41:00,139.4863326209,35.3709687603,1,7,2811315,9 ,11,37,,8",
 "441600,6,6,1998/10/01 14:41:00,139.8235381434,35.727172662,1,9,3215009,9 ,10,30,,8",
 "476100,4,6,1998/10/01 14:41:00,139.574092582,35.8231318659,1,3,3411213,13,3 ,34,,1",
 "457700,4,4,1998/10/01 14:41:00,139.6965048842,35.9487439869,2,12,3231014,14,3 ,32,,6",
 "487600,6,6,1998/10/01 14:41:00,139.6167214701,35.9236637241,1,10,3512006,6 ,1 ,36,,6",
 "554300,2,2,1998/10/01 14:41:00,139.6991749122,35.5633786987,1,14,0135008,15,7 ,38,,1",
 "604900,4,4,1998/10/01 14:41:00,139.6132406169,35.6856320732,2,1,0423005,12,4 ,49,,1",
 "680800,4,4,1998/10/01 14:41:00,139.8839825275,35.7296012637,1,5,0632105,15,3 ,63,,1",
 "731400,4,4,1998/10/01 14:41:00,139.4731943597,35.6595715073,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:41:00,139.3816288025,35.67270952,2,3,0930110,13,2 ,35,,9",
 "706100,4,9,1998/10/01 14:41:00,139.7014088733,35.6586820782,1,14,0730209,10,1 ,26,,1",
 "71300,4,8,1998/10/01 14:42:00,139.625255648,35.9005194459,1,12,4313309,9 ,13,34,,10",
 "163300,4,9,1998/10/01 14:42:00,139.7504898885,35.744649185,2,11,4061110,4 ,3 ,30,,12",
 "832600,3,3,1998/10/01 14:42:00,139.6211398705,35.4170537017,1,14,1111003,15,3 ,30,,1",
 "234600,8,13,1998/10/01 14:42:00,139.6997904007,35.6952826668,1,14,2412116,10,3 ,63,,12",
 "105800,4,4,1998/10/01 14:42:00,139.9204720828,35.3432044534,2,2,4612227,12,3 ,32,,1",
 "328900,2,3,1998/10/01 14:42:00,140.0318379143,35.6548841238,2,8,2120102,9 ,10,28,,12",
 "271400,4,4,1998/10/01 14:42:00,139.4861229074,35.3677911808,1,7,2811315,9 ,11,37,,8",
 "441600,6,6,1998/10/01 14:42:00,139.8255250239,35.7289986675,1,9,3215009,9 ,10,30,,8",
 "476100,4,6,1998/10/01 14:42:00,139.5751669776,35.8221322088,1,3,3411213,13,3 ,34,,1",
 "476100,4,7,1998/10/01 14:42:00,139.5751669776,35.8221322088,1,3,3411213,13,3 ,34,,12",
 "457700,4,4,1998/10/01 14:42:00,139.6986938974,35.9474954577,2,12,3231014,14,3 ,32,,6",
 "487600,6,6,1998/10/01 14:42:00,139.6166994957,35.9237796795,1,10,3512006,6 ,1 ,36,,6",
 "554300,2,2,1998/10/01 14:42:00,139.6985095466,35.5635826438,1,14,0135008,15,7 ,38,,1",
 "604900,4,4,1998/10/01 14:42:00,139.6126957322,35.685639589,2,1,0423005,12,4 ,49,,1",
 "680800,4,4,1998/10/01 14:42:00,139.8844372987,35.7297713264,1,5,0632105,15,3 ,63,,1",
 "731400,4,4,1998/10/01 14:42:00,139.4731061991,35.6598801182,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:42:00,139.3819313053,35.6728158959,2,3,0930110,13,2 ,35,,9",
 "706100,4,9,1998/10/01 14:42:00,139.7010449496,35.6588480384,1,14,0730209,10,1 ,26,,1",
 "71300,4,8,1998/10/01 14:43:00,139.6258529049,35.9002287138,1,12,4313309,9 ,13,34,,10",
 "163300,4,9,1998/10/01 14:43:00,139.7559710426,35.741739001,2,11,4061110,4 ,3 ,30,,12",
 "832600,3,3,1998/10/01 14:43:00,139.6203587175,35.4174258972,1,14,1111003,15,3 ,30,,1",
 "234600,8,13,1998/10/01 14:43:00,139.7000951704,35.6908460404,1,14,2412116,10,3 ,63,,12",
 "105800,4,4,1998/10/01 14:43:00,139.9196688102,35.3438165676,2,2,4612227,12,3 ,32,,1",
 "328900,2,3,1998/10/01 14:43:00,140.0539511165,35.6408134173,2,8,2120102,9 ,10,28,,12",
 "271400,4,4,1998/10/01 14:43:00,139.4859960428,35.3646611162,1,7,2811315,9 ,11,37,,8",
 "441600,6,6,1998/10/01 14:43:00,139.8275789029,35.7307714285,1,9,3215009,9 ,10,30,,8",
 "476100,4,7,1998/10/01 14:43:00,139.569197684,35.8261072195,1,3,3411213,13,3 ,34,,12",
 "457700,4,4,1998/10/01 14:43:00,139.7010706764,35.9464962854,2,12,3231014,14,3 ,32,,6",
 "487600,6,6,1998/10/01 14:43:00,139.6166775212,35.9238956348,1,10,3512006,6 ,1 ,36,,6",
 "554300,2,2,1998/10/01 14:43:00,139.6978054194,35.5635253908,1,14,0135008,15,7 ,38,,1",
 "604900,4,4,1998/10/01 14:43:00,139.6122959994,35.6854122803,2,1,0423005,12,4 ,49,,1",
 "680800,4,4,1998/10/01 14:43:00,139.8848295745,35.7300635199,1,5,0632105,15,3 ,63,,1",
 "731400,4,4,1998/10/01 14:43:00,139.4731192695,35.6602241802,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:43:00,139.3822335459,35.6729227682,2,3,0930110,13,2 ,35,,9",
 "706100,4,9,1998/10/01 14:43:00,139.7006810244,35.6590139974,1,14,0730209,10,1 ,26,,1",
 "71300,4,8,1998/10/01 14:44:00,139.6264501574,35.8999379786,1,12,4313309,9 ,13,34,,10",
 "832600,3,3,1998/10/01 14:44:00,139.6195516421,35.4177497337,1,14,1111003,15,3 ,30,,1",
 "163300,4,9,1998/10/01 14:44:00,139.760795563,35.7381442917,2,11,4061110,4 ,3 ,30,,12",
 "234600,8,13,1998/10/01 14:44:00,139.7008154761,35.6864647569,1,14,2412116,10,3 ,63,,12",
 "105800,4,4,1998/10/01 14:44:00,139.918879777,35.3444193782,2,2,4612227,12,3 ,32,,1",
 "328900,2,3,1998/10/01 14:44:00,140.0770980535,35.6291358746,2,8,2120102,9 ,10,28,,12",
 "271400,4,4,1998/10/01 14:44:00,139.4850866705,35.3614937165,1,7,2811315,9 ,11,37,,8",
 "441600,6,6,1998/10/01 14:44:00,139.829787628,35.7324190903,1,9,3215009,9 ,10,30,,8",
 "476100,4,7,1998/10/01 14:44:00,139.5631227593,35.8300664228,1,3,3411213,13,3 ,34,,12",
 "457700,4,4,1998/10/01 14:44:00,139.7035741995,35.9457224269,2,12,3231014,14,3 ,32,,6",
 "487600,6,6,1998/10/01 14:44:00,139.6166631026,35.9240123198,1,10,3512006,6 ,1 ,36,,6",
 "554300,2,2,1998/10/01 14:44:00,139.6970996525,35.5634575023,1,14,0135008,15,7 ,38,,1",
 "604900,4,4,1998/10/01 14:44:00,139.6119794773,35.6850503585,2,1,0423005,12,4 ,49,,1",
 "680800,4,4,1998/10/01 14:44:00,139.8849546573,35.7304030784,1,5,0632105,15,3 ,63,,1",
 "731400,4,4,1998/10/01 14:44:00,139.4731323399,35.6605682421,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:44:00,139.3825357873,35.6730296396,2,3,0930110,13,2 ,35,,9",
 "706100,4,9,1998/10/01 14:44:00,139.7003170977,35.6591799553,1,14,0730209,10,1 ,26,,1",
 "71300,4,8,1998/10/01 14:45:00,139.6270474056,35.8996472404,1,12,4313309,9 ,13,34,,10",
 "163300,4,9,1998/10/01 14:45:00,139.765200925,35.7341945968,2,11,4061110,4 ,3 ,30,,12",
 "832600,3,3,1998/10/01 14:45:00,139.6186841128,35.4177697409,1,14,1111003,15,3 ,30,,1",
 "234600,8,13,1998/10/01 14:45:00,139.6975445387,35.6826840696,1,14,2412116,10,3 ,63,,12",
 "105800,4,4,1998/10/01 14:45:00,139.9197877354,35.3439149707,2,2,4612227,12,3 ,32,,1",
 "328900,2,3,1998/10/01 14:45:00,140.0963795477,35.6129096695,2,8,2120102,9 ,10,28,,12",
 "271400,4,4,1998/10/01 14:45:00,139.4827697147,35.3588968653,1,7,2811315,9 ,11,37,,8",
 "441600,6,6,1998/10/01 14:45:00,139.8319370624,35.7341187699,1,9,3215009,9 ,10,30,,8",
 "476100,4,7,1998/10/01 14:45:00,139.557070313,35.8340485701,1,3,3411213,13,3 ,34,,12",
 "457700,4,4,1998/10/01 14:45:00,139.7060798052,35.9451663498,2,12,3231014,14,3 ,32,,6",
 "487600,6,6,1998/10/01 14:45:00,139.6166510902,35.924129237,1,10,3512006,6 ,1 ,36,,6",
 "554300,2,2,1998/10/01 14:45:00,139.6963934955,35.563392446,1,14,0135008,15,7 ,38,,1",
 "604900,4,4,1998/10/01 14:45:00,139.6119063284,35.6854423591,2,1,0423005,12,4 ,49,,1",
 "604900,5,5,1998/10/01 14:45:00,139.6119063284,35.6854423591,2,1,0423005,12,97,49,,97",
 "680800,4,4,1998/10/01 14:45:00,139.8853312771,35.7306149334,1,5,0632105,15,3 ,63,,1",
 "731400,4,4,1998/10/01 14:45:00,139.4731454104,35.6609123041,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:45:00,139.3828380295,35.6731365104,2,3,0930110,13,2 ,35,,9",
 "706100,4,9,1998/10/01 14:45:00,139.6999531694,35.6593459121,1,14,0730209,10,1 ,26,,1",
 "542800,6,6,1998/10/01 14:46:00,139.7429668461,35.620294345,2,13,0121101,14,7 ,45,,1",
 "71300,4,8,1998/10/01 14:46:00,139.6276436825,35.8998920349,1,12,4313309,9 ,13,34,,10",
 "163300,4,9,1998/10/01 14:46:00,139.7686168054,35.7303445415,2,11,4061110,4 ,3 ,30,,12",
 "832600,3,3,1998/10/01 14:46:00,139.6180122209,35.4182652981,1,14,1111003,15,3 ,30,,1",
 "234600,8,13,1998/10/01 14:46:00,139.6938308901,35.6789750318,1,14,2412116,10,3 ,63,,12",
 "66700,8,8,1998/10/01 14:46:00,139.9468774079,35.8442495743,1,11,4311002,3 ,3 ,37,,2",
 "105800,4,4,1998/10/01 14:46:00,139.9206956826,35.3434105563,2,2,4612227,12,3 ,32,,1",
 "328900,2,3,1998/10/01 14:46:00,140.1166995131,35.5980433501,2,8,2120102,9 ,10,28,,12",
 "271400,4,4,1998/10/01 14:46:00,139.4809059062,35.3560029311,1,7,2811315,9 ,11,37,,8",
 "391000,6,8,1998/10/01 14:46:00,139.760597963,35.6678439295,1,7,3110313,8 ,1 ,33,,1",
 "377200,8,8,1998/10/01 14:46:00,139.63430833,35.9215205739,1,14,3052009,15,3 ,29,,3",
 "441600,6,6,1998/10/01 14:46:00,139.833656099,35.7354471647,1,9,3215009,9 ,10,30,,8",
 "476100,4,7,1998/10/01 14:46:00,139.5510245151,35.8380378044,1,3,3411213,13,3 ,34,,12",
 "457700,4,4,1998/10/01 14:46:00,139.7079231679,35.9435894933,2,12,3231014,14,3 ,32,,6",
 "487600,6,6,1998/10/01 14:46:00,139.6166390776,35.9242461542,1,10,3512006,6 ,1 ,36,,6",
 "554300,2,2,1998/10/01 14:46:00,139.6956869492,35.5633302144,1,14,0135008,15,7 ,38,,1",
 "680800,4,4,1998/10/01 14:46:00,139.8853606016,35.7308170544,1,5,0632105,15,3 ,63,,1",
 "731400,4,4,1998/10/01 14:46:00,139.4731584811,35.661256366,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:46:00,139.3831402725,35.6732433804,2,3,0930110,13,2 ,35,,9",
 "759000,4,6,1998/10/01 14:46:00,139.7447086788,35.7000649658,2,4,0821006,9 ,3 ,55,,1",
 "867100,4,4,1998/10/01 14:46:00,139.4933419531,35.415951884,2,2,1241102,12,3 ,29,,1",
 "706100,4,9,1998/10/01 14:46:00,139.6995876597,35.6595092894,1,14,0730209,10,1 ,26,,1",
 "542800,6,6,1998/10/01 14:47:00,139.7427790099,35.6181711127,2,13,0121101,14,7 ,45,,1",
 "542800,7,7,1998/10/01 14:47:00,139.7427790099,35.6181711127,2,13,0121101,14,97,45,,97",
 "71300,4,8,1998/10/01 14:47:00,139.6282398779,35.9001842025,1,12,4313309,9 ,13,34,,10",
 "832600,3,3,1998/10/01 14:47:00,139.6173741403,35.418793173,1,14,1111003,15,3 ,30,,1",
 "163300,4,9,1998/10/01 14:47:00,139.7728254889,35.7266215334,2,11,4061110,4 ,3 ,30,,12",
 "234600,8,13,1998/10/01 14:47:00,139.6913516841,35.6745991264,1,14,2412116,10,3 ,63,,12",
 "66700,8,8,1998/10/01 14:47:00,139.9470021288,35.8445565803,1,11,4311002,3 ,3 ,37,,2",
 "105800,4,4,1998/10/01 14:47:00,139.9198265847,35.3439121423,2,2,4612227,12,3 ,32,,1",
 "328900,2,3,1998/10/01 14:47:00,140.1319154899,35.5796967343,2,8,2120102,9 ,10,28,,12",
 "271400,4,4,1998/10/01 14:47:00,139.4791795447,35.3530794336,1,7,2811315,9 ,11,37,,8",
 "391000,6,8,1998/10/01 14:47:00,139.7599252066,35.6680685901,1,7,3110313,8 ,1 ,33,,1",
 "377200,8,8,1998/10/01 14:47:00,139.6354775264,35.9207768862,1,14,3052009,15,3 ,29,,3",
 "441600,6,6,1998/10/01 14:47:00,139.8354646092,35.7368038647,1,9,3215009,9 ,10,30,,8",
 "476100,4,7,1998/10/01 14:47:00,139.5449709316,35.8420195623,1,3,3411213,13,3 ,34,,12",
 "457700,4,4,1998/10/01 14:47:00,139.7101977999,35.9426298343,2,12,3231014,14,3 ,32,,6",
 "487600,6,6,1998/10/01 14:47:00,139.6165430117,35.9242971819,1,10,3512006,6 ,1 ,36,,6",
 "554300,2,2,1998/10/01 14:47:00,139.6949803142,35.5632686713,1,14,0135008,15,7 ,38,,1",
 "680800,4,4,1998/10/01 14:47:00,139.8858587836,35.730956153,1,5,0632105,15,3 ,63,,1",
 "731400,4,4,1998/10/01 14:47:00,139.4731830863,35.6615999939,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:47:00,139.383438307,35.673357581,2,3,0930110,13,2 ,35,,9",
 "759000,4,6,1998/10/01 14:47:00,139.7447522228,35.7002319599,2,4,0821006,9 ,3 ,55,,1",
 "867100,4,4,1998/10/01 14:47:00,139.4926234415,35.4158907038,2,2,1241102,12,3 ,29,,1",
 "706100,4,9,1998/10/01 14:47:00,139.6992066489,35.6596473718,1,14,0730209,10,1 ,26,,1",
 "71300,4,8,1998/10/01 14:48:00,139.6288360776,35.9004763672,1,12,4313309,9 ,13,34,,10",
 "163300,4,9,1998/10/01 14:48:00,139.7771868451,35.7226402783,2,11,4061110,4 ,3 ,30,,12",
 "832600,3,3,1998/10/01 14:48:00,139.6167404082,35.4191490661,1,14,1111003,15,3 ,30,,1",
 "234600,8,13,1998/10/01 14:48:00,139.6895928977,35.670033087,1,14,2412116,10,3 ,63,,12",
 "66700,8,8,1998/10/01 14:48:00,139.9471268506,35.8448635862,1,11,4311002,3 ,3 ,37,,2",
 "105800,4,4,1998/10/01 14:48:00,139.918929565,35.3444295221,2,2,4612227,12,3 ,32,,1",
 "328900,2,3,1998/10/01 14:48:00,140.1538163794,35.5659611883,2,8,2120102,9 ,10,28,,12",
 "271400,4,4,1998/10/01 14:48:00,139.4785432932,35.349989685,1,7,2811315,9 ,11,37,,8",
 "391000,6,8,1998/10/01 14:48:00,139.7592533377,35.668294733,1,7,3110313,8 ,1 ,33,,1",
 "377200,8,8,1998/10/01 14:48:00,139.6370980058,35.9209698445,1,14,3052009,15,3 ,29,,3",
 "441600,6,6,1998/10/01 14:48:00,139.837557205,35.7385487599,1,9,3215009,9 ,10,30,,8",
 "476100,4,7,1998/10/01 14:48:00,139.5388979044,35.8459819263,1,3,3411213,13,3 ,34,,12",
 "457700,4,4,1998/10/01 14:48:00,139.712827096,35.9422175539,2,12,3231014,14,3 ,32,,6",
 "487600,6,6,1998/10/01 14:48:00,139.6164021285,35.9243174333,1,10,3512006,6 ,1 ,36,,6",
 "554300,2,2,1998/10/01 14:48:00,139.6942734933,35.563208566,1,14,0135008,15,7 ,38,,1",
 "680800,4,4,1998/10/01 14:48:00,139.8863394313,35.731136232,1,5,0632105,15,3 ,63,,1",
 "731400,4,4,1998/10/01 14:48:00,139.473208867,35.6619435775,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:48:00,139.3837323863,35.6734786711,2,3,0930110,13,2 ,35,,9",
 "759000,4,6,1998/10/01 14:48:00,139.7447957669,35.700398954,2,4,0821006,9 ,3 ,55,,1",
 "867100,4,4,1998/10/01 14:48:00,139.4919279911,35.4160711538,2,2,1241102,12,3 ,29,,1",
 "706100,4,9,1998/10/01 14:48:00,139.6988256368,35.659785453,1,14,0730209,10,1 ,26,,1",
 "71300,4,8,1998/10/01 14:49:00,139.6287383449,35.9009254472,1,12,4313309,9 ,13,34,,10",
 "163300,4,9,1998/10/01 14:49:00,139.7796450782,35.7180849257,2,11,4061110,4 ,3 ,30,,12",
 "832600,3,3,1998/10/01 14:49:00,139.615862752,35.4192061649,1,14,1111003,15,3 ,30,,1",
 "234600,8,13,1998/10/01 14:49:00,139.6837975599,35.6702414482,1,14,2412116,10,3 ,63,,12",
 "66700,8,8,1998/10/01 14:49:00,139.9472402647,35.8451722991,1,11,4311002,3 ,3 ,37,,2",
 "105800,4,4,1998/10/01 14:49:00,139.9180325338,35.3449468952,2,2,4612227,12,3 ,32,,1",
 "328900,2,3,1998/10/01 14:49:00,140.1799160951,35.561627887,2,8,2120102,9 ,10,28,,12",
 "271400,4,4,1998/10/01 14:49:00,139.4773594858,35.3474621876,1,7,2811315,9 ,11,37,,8",
 "391000,6,8,1998/10/01 14:49:00,139.7591784646,35.6687301576,1,7,3110313,8 ,1 ,33,,1",
 "377200,8,8,1998/10/01 14:49:00,139.6381178011,35.9205101007,1,14,3052009,15,3 ,29,,3",
 "441600,6,6,1998/10/01 14:49:00,139.8396035664,35.7403268306,1,9,3215009,9 ,10,30,,8",
 "476100,4,7,1998/10/01 14:49:00,139.5330206104,35.8501281873,1,3,3411213,13,3 ,34,,12",
 "457700,4,4,1998/10/01 14:49:00,139.7152818531,35.9413827323,2,12,3231014,14,3 ,32,,6",
 "487600,6,6,1998/10/01 14:49:00,139.6163118134,35.9243941814,1,10,3512006,6 ,1 ,36,,6",
 "554300,2,2,1998/10/01 14:49:00,139.6935671748,35.5631447205,1,14,0135008,15,7 ,38,,1",
 "680800,4,4,1998/10/01 14:49:00,139.8866239385,35.7313527687,1,5,0632105,15,3 ,63,,1",
 "731400,4,4,1998/10/01 14:49:00,139.4732342493,35.6622871808,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:49:00,139.3840264665,35.6735997604,2,3,0930110,13,2 ,35,,9",
 "759000,4,6,1998/10/01 14:49:00,139.7448393113,35.7005659481,2,4,0821006,9 ,3 ,55,,1",
 "867100,4,4,1998/10/01 14:49:00,139.4912277618,35.416238503,2,2,1241102,12,3 ,29,,1",
 "706100,4,9,1998/10/01 14:49:00,139.6984446234,35.659923533,1,14,0730209,10,1 ,26,,1",
 "71300,4,8,1998/10/01 14:50:00,139.6284129289,35.9014260108,1,12,4313309,9 ,13,34,,10",
 "163300,4,9,1998/10/01 14:50:00,139.776301953,35.7135235994,2,11,4061110,4 ,3 ,30,,12",
 "832600,3,3,1998/10/01 14:50:00,139.6156048083,35.4195049372,1,14,1111003,15,3 ,30,,1",
 "832600,4,4,1998/10/01 14:50:00,139.6156048083,35.4195049372,1,14,1111003,15,97,30,,97",
 "234600,8,13,1998/10/01 14:50:00,139.6784569264,35.6683037062,1,14,2412116,10,3 ,63,,12",
 "66700,8,8,1998/10/01 14:50:00,139.9474336963,35.845423633,1,11,4311002,3 ,3 ,37,,2",
 "105800,4,4,1998/10/01 14:50:00,139.9171354912,35.3454642616,2,2,4612227,12,3 ,32,,1",
 "328900,2,3,1998/10/01 14:50:00,140.2042766974,35.5504592449,2,8,2120102,9 ,10,28,,12",
 "271400,4,4,1998/10/01 14:50:00,139.4758153257,35.3446050967,1,7,2811315,9 ,11,37,,8",
 "391000,6,8,1998/10/01 14:50:00,139.7594569342,35.6692781132,1,7,3110313,8 ,1 ,33,,1",
 "377200,8,8,1998/10/01 14:50:00,139.6392231337,35.9209632841,1,14,3052009,15,3 ,29,,3",
 "377200,9,9,1998/10/01 14:50:00,139.6392231337,35.9209632841,1,14,3052009,15,97,29,,97",
 "441600,6,6,1998/10/01 14:50:00,139.8415669786,35.7421524305,1,9,3215009,9 ,10,30,,8",
 "476100,4,7,1998/10/01 14:50:00,139.5281328898,35.8550521295,1,3,3411213,13,3 ,34,,12",
 "457700,4,4,1998/10/01 14:50:00,139.7176776857,35.9404242475,2,12,3231014,14,3 ,32,,6",
 "487600,6,6,1998/10/01 14:50:00,139.6162932046,35.92450757,1,10,3512006,6 ,1 ,36,,6",
 "554300,2,2,1998/10/01 14:50:00,139.6929403154,35.5629274673,1,14,0135008,15,7 ,38,,1",
 "554300,3,3,1998/10/01 14:50:00,139.6929403154,35.5629274673,1,14,0135008,15,97,38,,97",
 "680800,4,4,1998/10/01 14:50:00,139.8868318133,35.7315922814,1,5,0632105,15,3 ,63,,1",
 "731400,4,4,1998/10/01 14:50:00,139.4732539139,35.6626309751,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:50:00,139.3843205476,35.6737208491,2,3,0930110,13,2 ,35,,9",
 "759000,4,6,1998/10/01 14:50:00,139.7448828558,35.7007329421,2,4,0821006,9 ,3 ,55,,1",
 "867100,4,4,1998/10/01 14:50:00,139.4905281129,35.4164077227,2,2,1241102,12,3 ,29,,1",
 "706100,4,9,1998/10/01 14:50:00,139.698065442,35.6600649143,1,14,0730209,10,1 ,26,,1",
 "23000,6,10,1998/10/01 14:51:00,139.7103934458,35.6894002668,2,3,4123006,5 ,3 ,26,,1",
 "71300,4,8,1998/10/01 14:51:00,139.6281181849,35.9019379777,1,12,4313309,9 ,13,34,,10",
 "80500,10,12,1998/10/01 14:51:00,140.0922711144,35.6979741385,1,14,4413233,5 ,7 ,33,,1",
 "163300,4,9,1998/10/01 14:51:00,139.7752201831,35.7084029206,2,11,4061110,4 ,3 ,30,,12",
 "234600,8,13,1998/10/01 14:51:00,139.6734580094,35.6657383122,1,14,2412116,10,3 ,63,,12",
 "66700,8,8,1998/10/01 14:51:00,139.947830722,35.8454154679,1,11,4311002,3 ,3 ,37,,2",
 "105800,4,4,1998/10/01 14:51:00,139.9162384371,35.3459816214,2,2,4612227,12,3 ,32,,1",
 "328900,2,3,1998/10/01 14:51:00,140.2303207481,35.5421141089,2,8,2120102,9 ,10,28,,12",
 "271400,4,4,1998/10/01 14:51:00,139.4739526839,35.3417373022,1,7,2811315,9 ,11,37,,8",
 "391000,6,8,1998/10/01 14:51:00,139.758996841,35.6695855342,1,7,3110313,8 ,1 ,33,,1",
 "441600,6,6,1998/10/01 14:51:00,139.8434355086,35.7440602375,1,9,3215009,9 ,10,30,,8",
 "476100,4,7,1998/10/01 14:51:00,139.5235974506,35.8602017169,1,3,3411213,13,3 ,34,,12",
 "457700,4,4,1998/10/01 14:51:00,139.7200197768,35.939377028,2,12,3231014,14,3 ,32,,6",
 "487600,6,6,1998/10/01 14:51:00,139.6162719102,35.9246037553,1,10,3512006,6 ,1 ,36,,6",
 "680800,4,4,1998/10/01 14:51:00,139.8867670141,35.7319715601,1,5,0632105,15,3 ,63,,1",
 "731400,4,4,1998/10/01 14:51:00,139.4732724793,35.6629748687,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:51:00,139.3846146297,35.673841937,2,3,0930110,13,2 ,35,,9",
 "759000,4,6,1998/10/01 14:51:00,139.7449264005,35.7008999362,2,4,0821006,9 ,3 ,55,,1",
 "867100,4,4,1998/10/01 14:51:00,139.4898313364,35.4165846614,2,2,1241102,12,3 ,29,,1",
 "706100,4,9,1998/10/01 14:51:00,139.6976869948,35.6602076196,1,14,0730209,10,1 ,26,,1",
 "23000,6,10,1998/10/01 14:52:00,139.7092622095,35.6894504627,2,3,4123006,5 ,3 ,26,,1",
 "71300,4,8,1998/10/01 14:52:00,139.6277901229,35.9024373985,1,12,4313309,9 ,13,34,,10",
 "80500,10,12,1998/10/01 14:52:00,140.0926096857,35.6979535643,1,14,4413233,5 ,7 ,33,,1",
 "163300,4,9,1998/10/01 14:52:00,139.7741180648,35.703384858,2,11,4061110,4 ,3 ,30,,12",
 "234600,8,13,1998/10/01 14:52:00,139.6689517483,35.6626147821,1,14,2412116,10,3 ,63,,12",
 "66700,8,8,1998/10/01 14:52:00,139.9480254902,35.8456400992,1,11,4311002,3 ,3 ,37,,2",
 "105800,4,4,1998/10/01 14:52:00,139.9152050711,35.3461030817,2,2,4612227,12,3 ,32,,1",
 "328900,2,3,1998/10/01 14:52:00,140.2562041923,35.5337503029,2,8,2120102,9 ,10,28,,12",
 "271400,4,4,1998/10/01 14:52:00,139.4722765214,35.3387789362,1,7,2811315,9 ,11,37,,8",
 "391000,6,8,1998/10/01 14:52:00,139.7583345328,35.6698299691,1,7,3110313,8 ,1 ,33,,1",
 "441600,6,6,1998/10/01 14:52:00,139.8451894716,35.7460386674,1,9,3215009,9 ,10,30,,8",
 "476100,4,7,1998/10/01 14:52:00,139.5191110361,35.8653793704,1,3,3411213,13,3 ,34,,12",
 "457700,4,4,1998/10/01 14:52:00,139.722307937,35.9382453937,2,12,3231014,14,3 ,32,,6",
 "487600,6,6,1998/10/01 14:52:00,139.6161323669,35.9246251114,1,10,3512006,6 ,1 ,36,,6",
 "680800,4,4,1998/10/01 14:52:00,139.8872936364,35.7320290956,1,5,0632105,15,3 ,63,,1",
 "731400,4,4,1998/10/01 14:52:00,139.4732910448,35.6633187622,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:52:00,139.3849087126,35.6739630242,2,3,0930110,13,2 ,35,,9",
 "759000,4,6,1998/10/01 14:52:00,139.7449699454,35.7010669302,2,4,0821006,9 ,3 ,55,,1",
 "867100,4,4,1998/10/01 14:52:00,139.489124219,35.4167293717,2,2,1241102,12,3 ,29,,1",
 "706100,4,9,1998/10/01 14:52:00,139.6973085463,35.6603503238,1,14,0730209,10,1 ,26,,1",
 "23000,6,10,1998/10/01 14:53:00,139.7079480049,35.6899369749,2,3,4123006,5 ,3 ,26,,1",
 "71300,4,8,1998/10/01 14:53:00,139.6274620568,35.9029368182,1,12,4313309,9 ,13,34,,10",
 "71300,4,9,1998/10/01 14:53:00,139.6274620568,35.9029368182,1,12,4313309,9 ,13,34,,1",
 "80500,10,12,1998/10/01 14:53:00,140.0929482569,35.6979329891,1,14,4413233,5 ,7 ,33,,1",
 "163300,4,9,1998/10/01 14:53:00,139.7730184992,35.6981509473,2,11,4061110,4 ,3 ,30,,12",
 "234600,8,13,1998/10/01 14:53:00,139.6644665963,35.6594727444,1,14,2412116,10,3 ,63,,12",
 "66700,8,8,1998/10/01 14:53:00,139.9481533493,35.8459461858,1,11,4311002,3 ,3 ,37,,2",
 "105800,4,4,1998/10/01 14:53:00,139.9142506943,35.3463312189,2,2,4612227,12,3 ,32,,1",
 "328900,2,3,1998/10/01 14:53:00,140.2829157552,35.5281829255,2,8,2120102,9 ,10,28,,12",
 "271400,4,4,1998/10/01 14:53:00,139.4701952048,35.336632218,1,7,2811315,9 ,11,37,,8",
 "391000,6,8,1998/10/01 14:53:00,139.7576705444,35.6700712444,1,7,3110313,8 ,1 ,33,,1",
 "441600,6,6,1998/10/01 14:53:00,139.8467607472,35.748116427,1,9,3215009,9 ,10,30,,8",
 "476100,4,7,1998/10/01 14:53:00,139.5147261441,35.8706149591,1,3,3411213,13,3 ,34,,12",
 "457700,4,4,1998/10/01 14:53:00,139.7238753112,35.9375154414,2,12,3231014,14,3 ,32,,6",
 "487600,6,6,1998/10/01 14:53:00,139.6159882933,35.9246308379,1,10,3512006,6 ,1 ,36,,6",
 "680800,4,4,1998/10/01 14:53:00,139.8878217354,35.7320761962,1,5,0632105,15,3 ,63,,1",
 "731400,4,4,1998/10/01 14:53:00,139.4733096105,35.6636626557,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:53:00,139.3852039228,35.6740822654,2,3,0930110,13,2 ,35,,9",
 "759000,4,6,1998/10/01 14:53:00,139.7450134905,35.7012339242,2,4,0821006,9 ,3 ,55,,1",
 "867100,4,4,1998/10/01 14:53:00,139.4884107879,35.416852817,2,2,1241102,12,3 ,29,,1",
 "706100,4,9,1998/10/01 14:53:00,139.6969300965,35.6604930267,1,14,0730209,10,1 ,26,,1",
 "23000,6,10,1998/10/01 14:54:00,139.7068749406,35.6899339261,2,3,4123006,5 ,3 ,26,,1",
 "71300,4,9,1998/10/01 14:54:00,139.6265630864,35.9072314464,1,12,4313309,9 ,13,34,,1",
 "80500,10,12,1998/10/01 14:54:00,140.0932868279,35.6979124129,1,14,4413233,5 ,7 ,33,,1",
 "163300,4,9,1998/10/01 14:54:00,139.7714321169,35.6930160076,2,11,4061110,4 ,3 ,30,,12",
 "234600,8,13,1998/10/01 14:54:00,139.6590319394,35.6576728625,1,14,2412116,10,3 ,63,,12",
 "66700,8,8,1998/10/01 14:54:00,139.9482873471,35.8462505733,1,11,4311002,3 ,3 ,37,,2",
 "105800,4,4,1998/10/01 14:54:00,139.9136213572,35.345615438,2,2,4612227,12,3 ,32,,1",
 "328900,2,3,1998/10/01 14:54:00,140.3077886801,35.523681776,2,8,2120102,9 ,10,28,,12",
 "271400,4,4,1998/10/01 14:54:00,139.4684039406,35.3346443045,1,7,2811315,9 ,11,37,,8",
 "391000,6,8,1998/10/01 14:54:00,139.7570018601,35.6703039628,1,7,3110313,8 ,1 ,33,,1",
 "441600,6,6,1998/10/01 14:54:00,139.8483225399,35.7501994221,1,9,3215009,9 ,10,30,,8",
 "476100,4,7,1998/10/01 14:54:00,139.5102663108,35.8758085351,1,3,3411213,13,3 ,34,,12",
 "457700,4,4,1998/10/01 14:54:00,139.7237888628,35.9369372118,2,12,3231014,14,3 ,32,,6",
 "487600,6,6,1998/10/01 14:54:00,139.6158623497,35.9246827145,1,10,3512006,6 ,1 ,36,,6",
 "680800,4,4,1998/10/01 14:54:00,139.8883505922,35.7321179423,1,5,0632105,15,3 ,63,,1",
 "731400,4,4,1998/10/01 14:54:00,139.4733281763,35.6640065492,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:54:00,139.3854995397,35.6742008409,2,3,0930110,13,2 ,35,,9",
 "759000,4,6,1998/10/01 14:54:00,139.7450570357,35.7014009181,2,4,0821006,9 ,3 ,55,,1",
 "867100,4,4,1998/10/01 14:54:00,139.4876839314,35.4169028543,2,2,1241102,12,3 ,29,,1",
 "706100,4,9,1998/10/01 14:54:00,139.6965562725,35.6604325885,1,14,0730209,10,1 ,26,,1",
 "23000,6,10,1998/10/01 14:55:00,139.7054278713,35.689903402,2,3,4123006,5 ,3 ,26,,1",
 "71300,4,9,1998/10/01 14:55:00,139.6255213941,35.9111419422,1,12,4313309,9 ,13,34,,1",
 "80500,10,12,1998/10/01 14:55:00,140.0936253987,35.6978918359,1,14,4413233,5 ,7 ,33,,1",
 "163300,4,9,1998/10/01 14:55:00,139.7692883505,35.6879704977,2,11,4061110,4 ,3 ,30,,12",
 "234600,8,13,1998/10/01 14:55:00,139.65348572,35.6560023916,1,14,2412116,10,3 ,63,,12",
 "66700,8,8,1998/10/01 14:55:00,139.948449253,35.8465451264,1,11,4311002,3 ,3 ,37,,2",
 "105800,4,4,1998/10/01 14:55:00,139.9130343132,35.3450582562,2,2,4612227,12,3 ,32,,1",
 "105800,5,5,1998/10/01 14:55:00,139.9130343132,35.3450582562,2,2,4612227,12,97,32,,97",
 "328900,2,3,1998/10/01 14:55:00,140.3111696116,35.5029898863,2,8,2120102,9 ,10,28,,12",
 "328900,2,4,1998/10/01 14:55:00,140.3111696116,35.5029898863,2,8,2120102,9 ,10,28,,1",
 "271400,4,4,1998/10/01 14:55:00,139.4666916124,35.3316689865,1,7,2811315,9 ,11,37,,8",
 "391000,6,8,1998/10/01 14:55:00,139.7563326788,35.6705357372,1,7,3110313,8 ,1 ,33,,1",
 "441600,6,6,1998/10/01 14:55:00,139.8499522083,35.7522462275,1,9,3215009,9 ,10,30,,8",
 "476100,4,7,1998/10/01 14:55:00,139.505797103,35.8809969943,1,3,3411213,13,3 ,34,,12",
 "457700,4,4,1998/10/01 14:55:00,139.7250423975,35.9354191667,2,12,3231014,14,3 ,32,,6",
 "457700,5,5,1998/10/01 14:55:00,139.7250423975,35.9354191667,2,12,3231014,14,97,32,,97",
 "487600,6,6,1998/10/01 14:55:00,139.6157279338,35.9247064809,1,10,3512006,6 ,1 ,36,,6",
 "680800,4,4,1998/10/01 14:55:00,139.8888803415,35.732136052,1,5,0632105,15,3 ,63,,1",
 "680800,5,5,1998/10/01 14:55:00,139.8888803415,35.732136052,1,5,0632105,15,97,63,,97",
 "759000,4,6,1998/10/01 14:55:00,139.7451005811,35.7015679121,2,4,0821006,9 ,3 ,55,,1",
 "759000,4,7,1998/10/01 14:55:00,139.7451005811,35.7015679121,2,4,0821006,9 ,3 ,55,,12",
 "731400,4,4,1998/10/01 14:55:00,139.4733467423,35.6643504427,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:55:00,139.3857951576,35.6743194158,2,3,0930110,13,2 ,35,,9",
 "867100,4,4,1998/10/01 14:55:00,139.4869555955,35.4169134657,2,2,1241102,12,3 ,29,,1",
 "706100,4,9,1998/10/01 14:55:00,139.6961842401,35.6602790595,1,14,0730209,10,1 ,26,,1",
 "23000,6,10,1998/10/01 14:56:00,139.7039830437,35.6898604156,2,3,4123006,5 ,3 ,26,,1",
 "71300,4,9,1998/10/01 14:56:00,139.6224402605,35.914118778,1,12,4313309,9 ,13,34,,1",
 "80500,10,12,1998/10/01 14:56:00,140.0939639693,35.6978712578,1,14,4413233,5 ,7 ,33,,1",
 "163300,4,9,1998/10/01 14:56:00,139.7673699504,35.682865217,2,11,4061110,4 ,3 ,30,,12",
 "234600,8,13,1998/10/01 14:56:00,139.6480756739,35.6540963833,1,14,2412116,10,3 ,63,,12",
 "66700,8,8,1998/10/01 14:56:00,139.9486583433,35.8468188373,1,11,4311002,3 ,3 ,37,,2",
 "328900,2,4,1998/10/01 14:56:00,140.2011614157,35.5499750083,2,8,2120102,9 ,10,28,,1",
 "271400,4,4,1998/10/01 14:56:00,139.4641328416,35.3291313454,1,7,2811315,9 ,11,37,,8",
 "391000,6,8,1998/10/01 14:56:00,139.756078277,35.6709693825,1,7,3110313,8 ,1 ,33,,1",
 "441600,6,6,1998/10/01 14:56:00,139.8516346682,35.754265682,1,9,3215009,9 ,10,30,,8",
 "476100,4,7,1998/10/01 14:56:00,139.5013500673,35.8861981968,1,3,3411213,13,3 ,34,,12",
 "487600,6,6,1998/10/01 14:56:00,139.6155878036,35.9246786553,1,10,3512006,6 ,1 ,36,,6",
 "731400,4,4,1998/10/01 14:56:00,139.4733662919,35.6646942977,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:56:00,139.3860907763,35.6744379899,2,3,0930110,13,2 ,35,,9",
 "759000,4,7,1998/10/01 14:56:00,139.7410838957,35.6982908559,2,4,0821006,9 ,3 ,55,,12",
 "867100,4,4,1998/10/01 14:56:00,139.487207331,35.4174174158,2,2,1241102,12,3 ,29,,1",
 "706100,4,9,1998/10/01 14:56:00,139.6958117737,35.6601262325,1,14,0730209,10,1 ,26,,1",
 "23000,6,10,1998/10/01 14:57:00,139.7025837595,35.6900125959,2,3,4123006,5 ,3 ,26,,1",
 "71300,4,9,1998/10/01 14:57:00,139.618902883,35.9168865134,1,12,4313309,9 ,13,34,,1",
 "80500,10,12,1998/10/01 14:57:00,140.0943025398,35.6978506789,1,14,4413233,5 ,7 ,33,,1",
 "163300,4,9,1998/10/01 14:57:00,139.7663641295,35.6776121544,2,11,4061110,4 ,3 ,30,,12",
 "234600,8,13,1998/10/01 14:57:00,139.6424514673,35.6526483021,1,14,2412116,10,3 ,63,,12",
 "66700,8,8,1998/10/01 14:57:00,139.9489318782,35.8470532879,1,11,4311002,3 ,3 ,37,,2",
 "328900,2,4,1998/10/01 14:57:00,140.1098414065,35.6076244083,2,8,2120102,9 ,10,28,,1",
 "271400,4,4,1998/10/01 14:57:00,139.4627644404,35.3260507573,1,7,2811315,9 ,11,37,,8",
 "391000,6,8,1998/10/01 14:57:00,139.7554861486,35.671313528,1,7,3110313,8 ,1 ,33,,1",
 "441600,6,6,1998/10/01 14:57:00,139.8533651999,35.7562582595,1,9,3215009,9 ,10,30,,8",
 "476100,4,7,1998/10/01 14:57:00,139.4969106829,35.891403901,1,3,3411213,13,3 ,34,,12",
 "487600,6,6,1998/10/01 14:57:00,139.6154461963,35.9246577564,1,10,3512006,6 ,1 ,36,,6",
 "731400,4,4,1998/10/01 14:57:00,139.4733881094,35.665038064,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:57:00,139.3863863959,35.6745565633,2,3,0930110,13,2 ,35,,9",
 "759000,4,7,1998/10/01 14:57:00,139.7375207266,35.6935249631,2,4,0821006,9 ,3 ,55,,12",
 "867100,4,4,1998/10/01 14:57:00,139.486814695,35.4177560422,2,2,1241102,12,3 ,29,,1",
 "706100,4,9,1998/10/01 14:57:00,139.6954465252,35.6600625913,1,14,0730209,10,1 ,26,,1",
 "23000,6,10,1998/10/01 14:58:00,139.7012055109,35.6903736289,2,3,4123006,5 ,3 ,26,,1",
 "71300,4,9,1998/10/01 14:58:00,139.6142157376,35.9175389922,1,12,4313309,9 ,13,34,,1",
 "80500,10,12,1998/10/01 14:58:00,140.09464111,35.6978300989,1,14,4413233,5 ,7 ,33,,1",
 "163300,4,9,1998/10/01 14:58:00,139.7721521352,35.6753075222,2,11,4061110,4 ,3 ,30,,12",
 "234600,8,13,1998/10/01 14:58:00,139.6368005369,35.6512403979,1,14,2412116,10,3 ,63,,12",
 "66700,8,8,1998/10/01 14:58:00,139.9492054147,35.8472877378,1,11,4311002,3 ,3 ,37,,2",
 "328900,2,4,1998/10/01 14:58:00,140.0041443261,35.6868931309,2,8,2120102,9 ,10,28,,1",
 "271400,4,4,1998/10/01 14:58:00,139.4611894108,35.3236883451,1,7,2811315,9 ,11,37,,8",
 "391000,6,8,1998/10/01 14:58:00,139.7548940151,35.6716576706,1,7,3110313,8 ,1 ,33,,1",
 "441600,6,6,1998/10/01 14:58:00,139.8554364325,35.7579570929,1,9,3215009,9 ,10,30,,8",
 "476100,4,7,1998/10/01 14:58:00,139.4923926396,35.8965647449,1,3,3411213,13,3 ,34,,12",
 "487600,6,6,1998/10/01 14:58:00,139.615302731,35.9246455688,1,10,3512006,6 ,1 ,36,,6",
 "731400,4,4,1998/10/01 14:58:00,139.4734099271,35.6653818302,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:58:00,139.3866820163,35.674675136,2,3,0930110,13,2 ,35,,9",
 "759000,4,7,1998/10/01 14:58:00,139.7331371651,35.6912595696,2,4,0821006,9 ,3 ,55,,12",
 "867100,4,4,1998/10/01 14:58:00,139.4862813038,35.418137058,2,2,1241102,12,3 ,29,,1",
 "706100,4,9,1998/10/01 14:58:00,139.6951019184,35.6602540631,1,14,0730209,10,1 ,26,,1",
 "23000,6,10,1998/10/01 14:59:00,139.6998272499,35.690734646,2,3,4123006,5 ,3 ,26,,1",
 "23000,6,11,1998/10/01 14:59:00,139.6998272499,35.690734646,2,3,4123006,5 ,3 ,26,,12",
 "71300,4,9,1998/10/01 14:59:00,139.6088693606,35.9172707965,1,12,4313309,9 ,13,34,,1",
 "80500,10,12,1998/10/01 14:59:00,140.0949796801,35.697809518,1,14,4413233,5 ,7 ,33,,1",
 "163300,4,9,1998/10/01 14:59:00,139.7785560698,35.6746300685,2,11,4061110,4 ,3 ,30,,12",
 "234600,8,13,1998/10/01 14:59:00,139.6312263504,35.649634021,1,14,2412116,10,3 ,63,,12",
 "66700,8,8,1998/10/01 14:59:00,139.9494789529,35.8475221871,1,11,4311002,3 ,3 ,37,,2",
 "328900,2,4,1998/10/01 14:59:00,139.8765729662,35.7028481797,2,8,2120102,9 ,10,28,,1",
 "271400,4,4,1998/10/01 14:59:00,139.4588476304,35.3215082619,1,7,2811315,9 ,11,37,,8",
 "391000,6,8,1998/10/01 14:59:00,139.7543018765,35.6720018102,1,7,3110313,8 ,1 ,33,,1",
 "441600,6,6,1998/10/01 14:59:00,139.8578412087,35.7594120245,1,9,3215009,9 ,10,30,,8",
 "476100,4,7,1998/10/01 14:59:00,139.4879200668,35.9017516733,1,3,3411213,13,3 ,34,,12",
 "487600,6,6,1998/10/01 14:59:00,139.6151592659,35.924633381,1,10,3512006,6 ,1 ,36,,6",
 "731400,4,4,1998/10/01 14:59:00,139.473431745,35.6657255965,2,10,0771111,14,3 ,32,,6",
 "786600,3,5,1998/10/01 14:59:00,139.3869776377,35.674793708,2,3,0930110,13,2 ,35,,9",
 "759000,4,7,1998/10/01 14:59:00,139.726505458,35.6918587605,2,4,0821006,9 ,3 ,55,,12",
 "867100,4,4,1998/10/01 14:59:00,139.4855962461,35.4181589991,2,2,1241102,12,3 ,29,,1",
 "706100,4,9,1998/10/01 14:59:00,139.6947589233,35.6604474217,1,14,0730209,10,1 ,26,,1"
];

export default TEST_MOVING_DATA_SOURCE;