import ColorGeneratorBase from './ColorGeneratorBase'
import { hsvToRGB } from './ColorGeneratorBase'

class HueColorGenerator extends ColorGeneratorBase {
	constructor(name, title) {
		super(name, title);
	}

	getRGB(outRGB, position) {
		var a = Math.floor(position * 216.0);
		if (a > 216){ a=216; }
		else if (a < 0){ a=0; }

		outRGB[0] = 216-a;
		outRGB[1] = 0.9;
		outRGB[2] = 1;

		hsvToRGB(outRGB);
	}
}

export default HueColorGenerator;