import MMEventDispatcher from '../leafmob/EventDispatcher'
import MovingObjectLayer from './MovingObjectLayer'
import GridLayer from './GridLayer'
import MMLayerList from './MMLayerList'

class MMProject extends MMEventDispatcher
{
	constructor() {
		super();
		this.layerList = new MMLayerList();
		this.selectedLayerId = -1;
		this.timeRange = {
			min: Number.POSITIVE_INFINITY,
			max: Number.NEGATIVE_INFINITY
		}

		this.layerList.setEventParent(this);
	}

	resetTimeRange() {
		this.timeRange.min = Number.POSITIVE_INFINITY;
		this.timeRange.max = Number.NEGATIVE_INFINITY;
	}

	addMovingObjectLayer() {
		const layer = new MovingObjectLayer();
		this.layerList.add(layer);
		this.initialSelectIf(layer);
		return layer;
	}
	
	addGenerativeGridLayer(initialTargetId) {
		const layer = new GridLayer();
		if (0 === initialTargetId || initialTargetId) {
			layer.targetId = initialTargetId;
		}
		
		this.layerList.add(layer);
		this.initialSelectIf(layer);

		return layer;
	}

	initialSelectIf(layer) {
		if (this.layerList.count() === 1) {
			this.selectedLayerId = layer.layerId;
		}
	}

	on_LayerTimeRangeSet(layer, timeRange) {
		this.updateEntireTimeRange();
	}
	
	updateEntireTimeRange() {
		this.resetTimeRange();

		const oldMin = this.timeRange.min;
		const oldMax = this.timeRange.max;

		const n = this.layerList.count();
		for (var i = 0;i < n;++i) {
			const lyr = this.layerList.getAt(i);
			const r = lyr.getTimeRange();
			if (r) {
				if (r.min < this.timeRange.min) {
					this.timeRange.min = r.min;
				}
				
				if (r.max > this.timeRange.max) {
					this.timeRange.max = r.max;
				}
			}
		}
		
		if (this.timeRange.min !== oldMin || 
			this.timeRange.max !== oldMax) {
			this.eventInvoke('ProjectTimeRangeChange', this, this.timeRange);
		}
	}
	
	selectById(newId) {
		const found = this.layerList.byId(newId);
		if (found) {
			if (newId !== this.selectedLayerId) {
				this.selectedLayerId = newId;
				this.eventInvoke('ProjectLayerSelectionChange', this, newId);
			}

			return found;
		}
		
		return null;
	}
}

export default MMProject;