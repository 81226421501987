import ClockRenderer from './ClockRenderer'
import leaflet from 'leaflet'
import { toolbase_init, toolbase_set_ev_parent, toolbase_make_button } from '../leafmob/map-tool-base'
const L = leaflet;

const MapClockControl = L.Control.extend({
	initialize: toolbase_init,
	setEventParent: toolbase_set_ev_parent,
	
	onAdd: function(map) {
		const div = document.createElement('div');
		this._bodyArea = this._addBodyArea(div);

		const openLink = this._addClockIcon(div);
		openLink.className = 'mm-open-clock-link';
		
		this._clockContainer = div;
		return div;
	},

	setUnixtime: function(t) {
		if (this._bodyArea) {
			this._bodyArea.clock.setUnixtime(t);
			if (this._isBodyShown()) {
				this._bodyArea.clock.render();
				this._updateDateArea();
			}
		}
	},

	onRemove: function(map) {
		
	},

	_addBodyArea: function(container) {
		const div = document.createElement('div');
		div.className = 'mm-map-clock-body-area';
		container.appendChild(div);

		const cap = document.createElement('h3');
		cap.innerHTML = '1900-01-01';
		div.append(cap);

		const clock = new ClockRenderer({ viewWidth: 128, viewHeight: 128, radius: 50 });
		div.appendChild( clock.getElement() );
		clock.render();

		const closeButton = document.createElement('img');
		closeButton.src = '/images/comp-x2.png';
		closeButton.className = 'mm-map-clock-close';
		div.appendChild(closeButton);
		
		closeButton.addEventListener('click', (e)=>{ this._toggleVisibility(false); }, false);

		return {
			outer: div,
			caption: cap,
			clock: clock,
			shown: false
		};
	},

	_updateDateArea: function() {
		if (this._bodyArea.clock.checkDateDirty()) {
			const t = this._bodyArea.clock.currentTime;
			this._bodyArea.caption.innerHTML = t.year + '-' + pad02(t.month) + '-' + pad02(t.date);
		}
	},

	_addClockIcon: function(container) {
		return toolbase_make_button(
			container,
			'/images/clockicon.png',
			'Show clock',
			this._onClockButtonClick.bind(this),
			24
		);
	},

	_onClockButtonClick: function() {
		this._toggleVisibility(true);
	},
	
	_toggleVisibility: function(bodyShown) {
		const c = this._clockContainer;
		if (c) {
			c.setAttribute('data-clock-open', bodyShown ? '1' : '0');
			const o = this._bodyArea.outer;
			this._bodyArea.shown = bodyShown;
			
			if (bodyShown) {
				this._bodyArea.clock.render();
				this._updateDateArea();
			}
			
			setTimeout(()=>{
				if (bodyShown) {
					o.style.opacity = 1;
					o.style.transform = 'scale(1,1)';
				} else {
					o.style.opacity = 0;
					o.style.transform = '';
				}
			}, 90);
		}
	},
	
	_isBodyShown() {
		if (this._bodyArea) {
			return this._bodyArea.shown;
		}

		return false;
	}
});

function pad02(i) {
	if (i < 10) { return '0'+i; }
	return i;
}

export default MapClockControl;