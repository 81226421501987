import L from 'leaflet'

const ToggleControl = L.Control.extend({
	options: {
		position: 'topleft'
	},
	
	initialize: function(options) {
		options = L.Util.setOptions(this, options);
		this.selectedButton = null;
	},
	
	onAdd: function (map) {
		const cls = this.options.horizontal ? 
			'lm-movtype-control leaflet-bar leaflet-horizontal-bar' :
			'lm-movtype-control leaflet-bar';
		
		const container = L.DomUtil.create('div', cls);
		const bls = this.options.buttonList;
		if (bls) {
			for (const b of bls) {
				b._element = this._createButton(b.id, b.title, b.className, container, b.handler, b.icon, b.label);
			}
		}

		return container;
	},
	
	_createButton: function (id, title, className, container, fn, iconURL, label) {
		const link = L.DomUtil.create('a', className, container);
		link.href = '#';
		link.title = title;
		
		if (label) {
			link.appendChild( document.createTextNode(label) );
		}
		
		if (iconURL) {
			const img = L.DomUtil.create('img');
			img.src = iconURL;
			link.appendChild(img);
		}

		L.DomEvent.disableClickPropagation(link);
		L.DomEvent.on(link, 'click', L.DomEvent.stop);
		L.DomEvent.on(link, 'click', this.onButtonClick.bind(this, id));

		if (this.options.horizontal) {
			this._setHorizontalStyle(link);
		}

		if (this.selectedButton) {
			if (this.selectedButton.id === id) {
				L.DomUtil.addClass(link, 'lm-selected');
			}
		}

		return link;
	},

	_setHorizontalStyle: function(element) {
		L.DomUtil.addClass(element, 'lm-leaflet-bar-horizontal');
	},

	selectFirst: function() {
		this.showToggle(this.options.buttonList[0].id);
		return this;
	},

	showToggle: function(id) {
		const old = this.selectedButton;
		if (this.selectedButton) {
			L.DomUtil.removeClass(this.selectedButton._element, 'lm-selected');
			this.selectedButton = null;
		}
		
		const b = this.findById(id);
		if (b) {
			if (b._element) {
				L.DomUtil.addClass(b._element, 'lm-selected');
			}
			this.selectedButton = b;
		}
		
		if (old !== b && this.options.onChange) {
			this.options.onChange(b);
		}
	},

	onButtonClick: function(buttonId) {
		this.showToggle(buttonId);
	},
	
	findById: function(bid) {
		const bls = this.options.buttonList;
		if (bls) {
			for (const b of bls) {
				if (b.id === bid) {return b;}
			}
		}
		
		return null;
	}
});

export default ToggleControl;