import FastProjectionGrid from './fast-projection'
import leaflet from 'leaflet'
const L = leaflet;

class RendererBase {
	constructor(targetCanvas) {
		this._temp_ll = new L.LatLng(0, 0);
		this._temp_lpt = new L.Point(0, 0);
		this._temp_lpt2 = new L.Point(0, 0);

		this.selectionMode = RendererBase.SelectionMode.None;
		this.size = {width: 256, height:224};
		this.origin = {
			screenCoord: {x:0, y:0},
			projectedCoord: {x:0, y:0},
			geoCoord: {lat:0, lng:0},
			endGeoCoord: {lat:0, lng:0}
		};

		this.projGrid = new FastProjectionGrid(5);

		this.targetCanvas = targetCanvas || null;
		if (targetCanvas) {
			this.initGL();
		}
	}

	setTargetCanvas(cv) {
		this.targetCanvas = cv;
		if (!this.gl) {
			this.initGL();
		}
	}

	initGL() {
		
	}

	resize(width, height) {
		this.size.width  = width;
		this.size.height = height;
		this.adjustViewport();
	}

	adjustViewport() {
		
	}

	setOrigin(ox, oy, originLatLng, endLatLng, zoomLevel) {
		const o = this.origin;

		o.screenCoord.x = ox; // offset on the canvas(X)
		o.screenCoord.y = oy; // offset on the canvas(Y)
		o.geoCoord.lat = originLatLng.lat;
		o.geoCoord.lng = originLatLng.lng;
		o.endGeoCoord.lat = endLatLng.lat;
		o.endGeoCoord.lng = endLatLng.lng;
		this.projectOrigin(o.projectedCoord, o.geoCoord, zoomLevel);
	}

	projectOrigin(outCoord, geoCoord, zoomLevel) {
		const worldSize = zoom_to_w(zoomLevel);
		FastSphericalMercator.projectNormal(outCoord, geoCoord);
		outCoord.x *= worldSize;
		outCoord.y *= worldSize;
	}

	projectLatLng(worldSize, lat, lng) {
		const sCoord = this._temp_lpt2;
		const ll = this._temp_ll;
		
		ll.lat = lat;
		ll.lng = lng;
		FastSphericalMercator.projectNormal(sCoord, ll);
		sCoord.x *= worldSize;
		sCoord.y *= worldSize;

		return sCoord;
	}

	updateProjection(map) {
		const z = map.getZoom();
		const worldSize = zoom_to_w(z);
		
		const o = this.origin;
		const pjCoord = o.projectedCoord;
		const oo = o.screenCoord;

		this.projGrid.update(this.projectLatLng.bind(this, worldSize),
			o.endGeoCoord.lat, o.geoCoord.lng,
			o.geoCoord.lat   , o.endGeoCoord.lng);
		this.projGrid.setOffset(
			oo.x - pjCoord.x,
			oo.y - pjCoord.y
		);
	}

	setSelectionMode(m) {
		this.selectionMode = m;
	}
}

function zoom_to_w(z) {  return Math.pow(2, z+8);  }

RendererBase.SelectionMode = {
	None:       0,
	Highlight:  1,
	Visibility: 2
};

// FAST-PROJECTION
const FastSphericalMercator = (function() {
	const earthRadius = 6378137;
	
	return {
		R: earthRadius,
		MAX_LATITUDE: 85.0511287798,

		project: function (outPt, latlng) {
			const d = Math.PI / 180,
			    max = this.MAX_LATITUDE,
			    lat = Math.max(Math.min(max, latlng.lat), -max),
			    sin = Math.sin(lat * d);

			outPt.x = this.R * latlng.lng * d;
			outPt.y = this.R * Math.log((1 + sin) / (1 - sin)) / 2;
			return outPt;
		},

		projectNormal: function(outPt, latlng) {
			const d = earthRadius * Math.PI;
			this.project(outPt, latlng);
			outPt.x = (outPt.x+d) / (2.0*d);

			outPt.y = (outPt.y+d) / (2.0*d);
			outPt.y = 1.0 - outPt.y;

			return outPt;
		},

		bounds: (function () {
			var d = earthRadius * Math.PI;
			return new L.Bounds([-d, -d], [d, d]);
		})()
	};
})();

export { FastSphericalMercator };
export {  zoom_to_w  };
export default RendererBase;