import RendererBase from './renderer-base'
import {zoom_to_w as zoom_to_w} from './renderer-base'
import leaflet from 'leaflet'
const L = leaflet;
const _tempRGBA=[0,0,0,255];

class LMGridRenderer extends RendererBase {
	constructor(targetCanvas) {
		super(targetCanvas);

		this.colorGenerator = null;
		this.coloringMaxValue = 10;
		this.colsLimit = 200;
		this.rowsLimit = 200;
		this.targetPickPool = null;
		this.spacing = 1;
		
		this.countSelectedOnly = false;
		
		this._xTemps = new Array( this.colsLimit );
		this._yTemps = new Array( this.rowsLimit );
		this._valTemps = this.generateValueBuffer(this.colsLimit, this.rowsLimit);

		this.girdConfig = {
			originLng: 100.0,        // MESHxx00
			originLat: (40/60) * 6,  // MESH06xx (4.00)
			lngStep:   1.0    / 80,
			latStep:   (40/60)/ 80
		};
		
		this.valueLabelVisible = false;
	}

	setSpacing(s) {
		this.spacing = s;
	}

	setValueLabelVisible(v) {
		this.valueLabelVisible = v;
	}

	generateGridConfig(preset) {
		const cf = this.girdConfig;
		cf.originLng = 100.0;
		cf.originLat = (40/60) * 6;
		
		switch(preset) {
		case 'j1':  // Japan 1 次
			cf.lngStep =    1.0 ;
			cf.latStep = (40/60);
			break;

		case 'j2':  // Japan 2 次
			cf.lngStep =    1.0 / 8;
			cf.latStep = (40/60)/ 8;
			break;

		case 'j3':  // Japan 3 次 (1000m)
			cf.lngStep =    1.0 / 80;
			cf.latStep = (40/60)/ 80;
			break;

		case 'j4':  // Japan 4 次 (500m)
			cf.lngStep =    1.0 / 160;
			cf.latStep = (40/60)/ 160;
			break;
			
		case 'j5':  // Japan 5 次 (250m)
			cf.lngStep =    1.0 / 320;
			cf.latStep = (40/60)/ 320;
			break;

		case 'jm100':  // Japan 1/10 (100m)
			cf.lngStep =    1.0 / 800;
			cf.latStep = (40/60)/ 800;
			break;

		case 'jm50':  // Japan 1/20 (50m)
			cf.lngStep =    1.0 / 1600;
			cf.latStep = (40/60)/ 1600;
			break;
		}
	}

	setCountSelectedOnly(b) {
		this.countSelectedOnly = b;
	}
	
	setColorGenerator(cg) {
		const changed = (this.colorGenerator !== cg);
		this.colorGenerator = cg;
		return changed;
	}

	setTargetPickPool(pl) {
		this.targetPickPool = pl;
	}

	projectAndRender(map) {
		if (!map) { return; }
		if (!this.targetCanvas) { return; }
		this.clear();

		const zoomLevel = map.getZoom();
		const worldSize = zoom_to_w(zoomLevel);

		const cv = this.targetCanvas;
		const g = cv.getContext('2d');

		g.font = 'normal 12px monospace';
		g.fillStyle = 'rgba(255,0,0,0.5)';
//		g.fillRect(0, 0, 40, 40);
		
		const c = this.girdConfig;
		const leftCellIndex = this.origin.geoCoord.lng / c.lngStep;
		const li = Math.floor(leftCellIndex);
		const leftMargin = leftCellIndex - li;
		const leftLngMargin = c.lngStep * leftMargin;
		
		const bottomCellIndex = this.origin.endGeoCoord.lat / c.latStep;
		const bi = Math.floor(bottomCellIndex);
		const bottomMargin = bottomCellIndex - bi;
		const bottomLatMargin = c.latStep * bottomMargin;
		
		const lngViewSpan = Math.abs(this.origin.endGeoCoord.lng -  this.origin.geoCoord.lng);
		const latViewSpan = Math.abs(this.origin.geoCoord.lat    -  this.origin.endGeoCoord.lat);
		const numX = Math.ceil(lngViewSpan / c.lngStep) + 2;
		const numY = Math.ceil(latViewSpan / c.latStep) + 2;


		var x, y;
		if (numX < this.colsLimit && numY < this.rowsLimit) {
			
			// Project lngs
			const xs = this._xTemps;
			const ys = this._yTemps;

			const ox = this.origin.projectedCoord.x;
			const oy = this.origin.projectedCoord.y;

			var lng = this.origin.geoCoord.lng - leftLngMargin;
			const minLng = lng;
			var lat = (this.origin.geoCoord.lat + this.origin.endGeoCoord.lat) / 2.0;
			for (x = 0;x < numX;++x) {
				const screenCoord = this.projectLatLng(worldSize, lat, lng);
				xs[x] = screenCoord.x - ox;

				lng += c.lngStep;
			}
			const maxLng = lng;

			lng = (this.origin.geoCoord.lng - this.origin.endGeoCoord.lng) / 2;
			lat = this.origin.endGeoCoord.lat - bottomLatMargin;
			const minLat = lat;
			for (y = 0;y < numY;++y) {
				const screenCoord = this.projectLatLng(worldSize, lat, lng);
				ys[y] = screenCoord.y - oy;
				
				lat += c.latStep;
			}
			const maxLat = lat;

			this.clearValueBuffer();
			this.updateValueBufferWithPickPool(this.targetPickPool, minLat, minLng, c.latStep, c.lngStep);
//			console.log(minLat+','+minLng +' -> '+ maxLat+','+maxLng);

			const v_scale = this.coloringMaxValue ? (255/this.coloringMaxValue) : 255;
			const spc = this.spacing;
			
			const numCols = numX-1;
			const numRows = numY-1;
			for (y = 0;y < numRows;++y) {
				for (x = 0;x < numCols;++x) {
					const x1 = xs[  x];
					const y1 = ys[y+1];
					const x2 = xs[x+1];
					const y2 = ys[y  ];

					const cellVal = this._valTemps[this.colsLimit*y + x] || 0;
					const colorVal = Math.min( Math.floor(cellVal*v_scale), 255);
					const alphaVal = 0.3+colorVal/512.0;
					
					if (this.colorGenerator) {
						this.colorGenerator.getRGB(_tempRGBA, colorVal / 255.0);
						_tempRGBA[3] = alphaVal;
						g.fillStyle = 'rgba(' +_tempRGBA.join(',')+ ')';
					} else {
						g.fillStyle = 'rgba(' +colorVal+ ',0,' +(255-colorVal)+ ',' +alphaVal+ ')';
					}
	
					const cellW = x2-x1-spc;
					const cellH = y2-y1-spc;
					g.fillRect(x1, y1, cellW, cellH);
					
					if (this.valueLabelVisible) {
						this.renderValueLabel(g, cellVal.toString(), x1, y1, cellW, cellH);
					}
				}
			} // for y
			
		}
	}
	
	renderValueLabel(g, tx, x1, y1, cellWidth, cellHeight) {
		const sidePadding  = 2;
		const sidePaddingD = 4;

		const metrics = g.measureText(tx);
		if (metrics.width >= (cellWidth-sidePaddingD)) {
			return;
		}

		g.save();
		g.shadowColor = '#000';
		g.shadowOffsetY = 1;
		g.shadowBlur = 1;
		g.textBaseline = 'bottom';
		g.fillStyle = '#FFF';
		g.fillText(tx, x1 + sidePadding, y1 + cellHeight - 2);
		g.restore();
	}

	clear() {
		const cv = this.targetCanvas;
		if (cv) {
			const g = cv.getContext('2d');
			const w = cv.width - 0;
			const h = cv.height - 0;

			g.clearRect(0, 0, w, h);
		}
	}

	generateValueBuffer(cols, rows) {
		const sz = cols*rows;
		const b = new Float32Array(sz);
		return b;
	}

	clearValueBuffer() {
		const b = this._valTemps;
		const len = b.length;
		for (var i = 0;i < len;++i) {
			b[i] = 0;
		}
		
		return b;
	}

	updateValueBufferWithPickPool(pickPool, minLat, minLng, latStep, lngStep) {
		if (!pickPool) {return;}

		const selOnly = this.countSelectedOnly;

		const n = pickPool.pickedCount;
		const srcList = pickPool.referRaw();

		const nCols = this.colsLimit;
		for (var i = 0;i < n;++i) {
			const record = srcList[i]; 
			if (selOnly) {
				if (!record.selected) { continue; }
			}
			
			const attrs = record.attrList;
			const lat = attrs[0];
			const lng = attrs[1];

			const cy = Math.floor( (lat - minLat) / latStep);
			const cx = Math.floor( (lng - minLng) / lngStep);
			
			if (cx >= 0 && cx < nCols && cy >= 0 && cy < this.rowsLimit) {
				++ this._valTemps[ cy*nCols+cx ];
			}
			
			if (0 === (i%17)) {
			//	console.log(i, cx, cy)
			}
		}
	}
}

export default LMGridRenderer;