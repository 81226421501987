
function addCaptureFunctions(klass) {

	klass.prototype.capSetupExtension = function() {
		window.addEventListener('message', this.capOnMessage.bind(this), false);
	};

	klass.prototype.capOnMessage = function(e) {
		if (e.data) {
			const cmd = e.data.command;
			if (cmd && cmd.startsWith('mmx')) {
				this[cmd]( e.data );
			}
		}
	};

	klass.prototype.mmxSetupCapture = function(params) {
		const mapArea = document.getElementById('map-area');
		if (mapArea) {
			const markerElement = document.createElement('div');
			markerElement.className = 'mmx-capture-marker';
			mapArea.appendChild(markerElement);

			markerElement.style.left = '-5px';
			markerElement.style.top = '-5px';

			const markerElement2 = document.createElement('div');
			markerElement2.className = 'mmx-capture-marker mmx-second-marker';
			mapArea.appendChild(markerElement2);

			markerElement2.style.right = '-5px';
			markerElement2.style.bottom = '-5px';
		}

		// Wait for the marker to be visible
		window.setTimeout(
			() => { window.postMessage({responseTo: 'mmxSetupCapture'}, '*');   } , 300
		);		
	};

	klass.prototype.mmxReadyToCapture = function(params) {
		this.numCapturedFrames = 0;

		const mapArea = document.getElementById('map-area');
		if (mapArea) {
			mapArea.querySelectorAll('.mmx-capture-marker').forEach( (el) => {
				el.parentNode.removeChild(el);
			} );

			// Wait for the marker to be removed
			window.setTimeout(
				() => { window.postMessage({responseTo: 'mmxReadyToCapture'}, '*');   } , 300
			);		

		}
	};

	klass.prototype.mmxPrepareFrame = function(params) {
		if (this.numCapturedFrames !== 0) {
			// 30fpsのとき1秒ずつ進めると 動画1秒=30秒
			// 30fpsのとき2秒ずつ進めると 動画1秒=60秒 ...
			// というルールで進行幅を決定
			const stepSeconds = params.speed / params.fps;
			this.mmxAdvanceFrame(stepSeconds);
		}

		this.numCapturedFrames++;
		window.setTimeout( () => {
			window.postMessage({responseTo: 'mmxPrepareFrame'}, '*');
		}, 40);
	};

	klass.prototype.mmxAdvanceFrame = function(step) {

		const cb = this.referControlBar();
		if (cb) {
			const ts = cb.getTimeSlider();
			if (ts) {
				const shouldStop = ts.advanceCurrentTime(step);

			}
		}

	};
}

export { addCaptureFunctions };