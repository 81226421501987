
class ColorGeneratorBase {
	constructor(name, title) {
		this.name = name;
		this.title = title;
	}

	getRGB(outRGB, position) {
		outRGB[0] = 0;
		outRGB[1] = 0;
		outRGB[2] = 0;
	}
}

export function hsvToRGB(aHSV) {
	var hue = aHSV[0] % 360;
	var sat = aHSV[1];
	var v = aHSV[2];
	var r, g, b;
	
	var h = hue / 60.0;
	var i = Math.floor(h);
	var f = h - i;
	var p = v * (1.0 - sat);
	var q = (i%2) ? v * (1.0 - f * sat) : v * (1.0 - (1.0 - f) * sat);
	
	switch(i) {
	case 0:
 		r = v;
		g = q;
		b = p;
		break;
	case 1:
		r = q;
		g = v;
		b = p;
		break;
	case 2:
		r = p;
		g = v;
		b = q;
		break;
	case 3:
		r = p;
		g = q;
		b = v;
		break;
	case 4:
		r = q;
		g = p;
		b = v;
		break;
	default:
		r = v;
		g = p;
		b = q;
		break;
	}
	
	aHSV[0] = Math.floor(r * 255);
	aHSV[1] = Math.floor(g * 255);
	aHSV[2] = Math.floor(b * 255);
};

export default ColorGeneratorBase;