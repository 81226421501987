import React from 'react';
import { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MuiDialogActions from '@material-ui/core/DialogActions';
import get_i18n from '../Internationalization';

import "./InterpolationLimitDialog.css";
import { InterpolationLimitType } from '../model/MMLayer';
import leaflet from 'leaflet'

class InterpolationLimitDialog extends Component {	
	constructor(props) {
		super(props);
		this.state = {
			selectedIndex: 0,
			appearance: 0,
			threshold: 0,
			reportBusy: false,
			hasReport: false
		};

		this.refValueInput = null;
		this.targetLayer = null;
	}

	clearReport() {
		this.setState({hasReport: false});
	}

	setTargetLayer(layer) {
		this.targetLayer = layer;
	}

	pickLayerState(layer) {
		if (layer.data && layer.data.interpolationLimit) {
			const opt = layer.data.interpolationLimit;

			this.setState( {
				selectedIndex: opt.type,
				threshold: opt.threshold,
				appearance: opt.appearance
			} );
		}
	}

	render() {
		const r_busy = this.state.reportBusy;
		const si = this.state.selectedIndex;
		const ai = this.state.appearance;
		const inputUnit = (si == 2) ? "meter" : "seconds";

		return ( <Dialog open={ this.props.open } className="mm-interpolation-limit-dialog">
				<DialogTitle>{ get_i18n('LimitInterpolation') }</DialogTitle>

				<InputLabel shrink>{ get_i18n('Condition') }</InputLabel>
				<ButtonGroup
				 disableElevation={ true }
				 color="primary"
				 disableRipple={true} disableFocusRipple={true} >
					{ makeIntpSelectorButton(get_i18n('None'  )    , 0, si, this.onSelectButtonClick.bind(this, 0)) }
					{ makeIntpSelectorButton(get_i18n('ByTime')    , 1, si, this.onSelectButtonClick.bind(this, 1)) }
					{ makeIntpSelectorButton(get_i18n('ByDistance'), 2, si, this.onSelectButtonClick.bind(this, 2)) }
				</ButtonGroup>


				<InputLabel shrink>{ get_i18n('LimitedSectionAppearance') }</InputLabel>
				<ButtonGroup
				 disableElevation={ true }
				 size="small"
				 color="primary"
				 disableRipple={true} disableFocusRipple={true} >
					{ makeIntpSelectorButton(get_i18n('Hidden'    )    , 0, ai, this.onAppearanceButtonClick.bind(this, 0)) }
					{ makeIntpSelectorButton(get_i18n('Dashed'    )    , 1, ai, this.onAppearanceButtonClick.bind(this, 1)) }
					{ makeIntpSelectorButton(get_i18n('Emphasized')    , 2, ai, this.onAppearanceButtonClick.bind(this, 2)) }
				</ButtonGroup>

				<div>
					<TextField disabled={ (si === 0) } onChange={ this.onThresholdChange.bind(this) } value={ this.state.threshold } ref={ this.ref_val.bind(this) }
					  label={ `${ get_i18n('Threshold') } (${ inputUnit })` } type="number" />
				</div>

				{ this.renderReport() }

				<MuiDialogActions>
					<Button disabled={ r_busy || (0 === si) } onClick={ this.onReportClick.bind(this) } variant="contained">Generate report</Button> <Button variant="contained" color="secondary" onClick={ this.onCloseClick.bind(this) }>Close</Button>
				</MuiDialogActions>
			</Dialog> );
	}

	renderReport() {
		const rep = this.state.hasReport;
		if (rep && rep.allStat) {
			return ( <table className="mm-interpolation-limit-dialog-report">
				<tbody>
					<tr>
						<td className="mm-interpolation-limit-rowhead">Num of IDs(All)</td>
						<td>{ rep.allStat.numAllTrajectories }</td>
					</tr>
					<tr>
						<td className="mm-interpolation-limit-rowhead">Num of IDs(Masked)</td>
						<td>{ rep.allStat.numTrajectoryWithMask }</td>
					</tr>
					<tr>
						<td className="mm-interpolation-limit-rowhead">Ratio</td>
						<td>{ rep.allStat.numTrajectoryWithMask / rep.allStat.numAllTrajectories }</td>
					</tr>
				</tbody>
			</table> );
		}

		return '';
	}

	ref_val(el) {
		if (el) {
			const i = el.querySelector("input");
			if (i) {
				this.refValueInput = i;
			}
		}
	}

	onReportClick() {
		this.setState({reportBusy: true});
		window.setTimeout(this.generateReport.bind(this), 200);
	}

	generateReport() {
		this.generateMaskArray();
		this.setState({reportBusy: false});
	}

	onAppearanceButtonClick(index) {
		this.setState({
			appearance: index
		});
	}

	onSelectButtonClick(index) {
		this.setState({
			selectedIndex: index
		});
	}

	onCloseClick() {
		if (this.props.onCloseClick) {
			this.props.onCloseClick(this);
		}
	}

	onThresholdChange(e) {
		const v = this.getThresholdValue();
		this.setState({threshold: v});
	}

	getLimitTime() {
		return (this.state.selectedIndex === 1);
	}

	getLimitDistance() {
		return (this.state.selectedIndex === 2);
	}

	getAppearanceType() {
		return this.state.appearance;
	}

	getThresholdValue() {
		const el = this.refValueInput;
		if (!el) { return 0; }

		return parseInt(el.value, 10);
	}

	generateMaskArray() {
		const eth = leaflet.CRS.Earth;
		const pt1 = {lat:0, lng:0};
		const pt2 = {lat:0, lng:0};
	
		const limitType = this.state.selectedIndex;
		const threshold = this.getThresholdValue();

		if (this.targetLayer && this.targetLayer.data) {
			if (limitType === InterpolationLimitType.Distance) {
				this.targetLayer.data.ensureDerivedData();
			}

			const allStat = {
				numTrajectoryWithMask: 0,
				numAllTrajectories: 0
			};

			const flattenList = this.targetLayer.data.referFlattenList();
			allStat.numAllTrajectories = flattenList.length;
			for (const tset of flattenList) {
				const m_array = this.targetLayer.makeInterpolationMaskArray(tset, limitType, threshold);
				const count = tset.tjGetLength();
				const nSegments = count - 1;

				const stat = {
					id: tset.ownerId,

					totalCount: 0,
					totalDistance: 0,
					totalTime: 0,

					maskedCount: 0,
					maskedDistance: 0,
					maskedTime: 0
				};

				let anyMasked = false;
				for (let i = 0; i < nSegments; i++) {
					pt1.lat = tset.tjGetLatAt(i);
					pt1.lng = tset.tjGetLngAt(i);

					pt2.lat = tset.tjGetLatAt(i + 1);
					pt2.lng = tset.tjGetLngAt(i + 1);

					const time1 = tset.tjGetTimeAt(i);
					const time2 = tset.tjGetTimeAt(i + 1);

					const distance = eth.distance(pt1, pt2);
					const dTime = time2 - time1;

					const isMasked = m_array[i];
					if (isMasked > 0) {
						++stat.maskedCount;
						stat.maskedDistance += distance;
						stat.maskedTime += dTime;

						anyMasked = true;
					}

					++stat.totalCount;
					stat.totalDistance += distance;
					stat.totalTime += dTime;

				}

				if (anyMasked) {
					++allStat.numTrajectoryWithMask;
				}

			}

			this.setState({hasReport: {
				allStat: allStat
			} });
		}
	}
}

function makeIntpSelectorButton(label, selfIndex, selectedIndex, handler) {
	const v = (selectedIndex === selfIndex) ? 'contained' : 'outlined';
	return ( <Button onClick={ handler } variant={ v }>{label}</Button> );
}

export default InterpolationLimitDialog;
