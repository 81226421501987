import React from 'react';
import { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import InputLabel from '@material-ui/core/InputLabel';
import Slider from '@material-ui/core/Slider';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

import SelOnlySwitch from './SelOnlySwitch'

import FormControlLabel from '@material-ui/core/FormControlLabel';
import { invoke_cp_handler } from '../misc-utils'
import { cond_grid } from './cond-grid'
import { PanelTitle } from './MarkerSettingPanel';
import get_i18n from '../Internationalization';
const kInitialTrajectoryColor = '#4080E0';

class TrajectorySettingPanel extends Component {
	constructor(props) {
		super(props);
	}

	render() {		
		const pcolor = this.props.currentColor || kInitialTrajectoryColor;
		var sliderVal = Math.floor(this.props.currentOpacity * 10.01);
		if (sliderVal < 0.5) { sliderVal = 0; }

		const busy = !!this.props.busy;

		const btn0 = this.props.visible ? null : 'secondary';
		const btn1 = (this.props.visible===1) ? 'primary' : null;
		const btn2 = (this.props.visible===2) ? 'primary' : null;

		const tc0 = (0 === this.props.coloringRule) ? 'primary' : '';
		const tc1 = (0 !== this.props.coloringRule) ? 'primary' : '';

		const disable_color = !this.props.visible || (0 !== this.props.coloringRule);
		const disable_all = !this.props.visible;

//		<InputLabel className="mm-setting-side-label">Trajectory</InputLabel>
		
		return ( <div>
			<Grid container spacing={1} alignItems="center" className="has-pred mm-setting-panel-section">
				<Grid item xs={12}>
					<PanelTitle icon="/images/cat-traj.png" label="Trajectory" />
				</Grid>

				<Grid item xs={12}>
					<div className="mm-setting-panel-toggle"><ButtonGroup size="small" disableRipple={true}>
						<Button variant="contained" disableElevation color={btn0} onClick={ this.onModeToggle.bind(this, 0) }
						        startIcon={ <img src="/images/comp-x2.png" alt="X" title="hide" /> } className="mm-toggle-x">&#65279;</Button>
						<Button variant="contained" disableElevation color={btn1} onClick={ this.onModeToggle.bind(this, 1) }
						        startIcon={ <img src="/images/comp-n2.png" alt="" /> }>{ get_i18n('NormalComp') }</Button>
						<Button variant="contained" disableElevation color={btn2} onClick={ this.onModeToggle.bind(this, 2) }
						        startIcon={ <img src="/images/comp-a2.png" alt="" /> }>{ get_i18n('AddComp') }</Button>
					</ButtonGroup></div>
				</Grid>

				{ cond_grid(1,disable_all) }
				{ disable_all ? '' : <SelOnlySwitch flags={ this.props.selDispFlags || 0 } onClick={ this.onSelDispClick.bind(this) } xs={11} /> }

				{ cond_grid(1,disable_all) }
				{ disable_all ? '' : <Grid item xs={11} className="mm-traj-coloring-type">
						<InputLabel shrink>{ get_i18n('ColoringRule') }</InputLabel>
						<ButtonGroup size="small" disableRipple={true}>
							<Button variant="contained" disableElevation color={tc0} onClick={ this.onColoringRuleToggle.bind(this, 0) }>{ get_i18n('FixedColor') }</Button>
							<Button variant="contained" disableElevation color={tc1} onClick={ this.onColoringRuleToggle.bind(this, 1) }>{ get_i18n('MarkerColor') }</Button>
						</ButtonGroup>
					</Grid>
				}

				{ cond_grid(1,disable_all) }
				{ disable_all ? '' : <Grid item xs={3}>
					{ busy ? '' : <input className="mm-dyn-color-picker" disabled={ disable_color } type="color" value={pcolor} onChange={ this.onColorChange.bind(this) } /> }
					</Grid> }
				{ disable_all ? '' : <Grid item xs={7}>
					{ busy ? '' : <InputLabel shrink>{ get_i18n('Opacity') }</InputLabel> }
					{ busy ? '' : <Slider min={0} max={10} value={ sliderVal } onChange={ this.onOpacityChange.bind(this) } /> }
					</Grid> }
				{ cond_grid(1,disable_all) }

				<Grid item xs={1} />
				<Grid item xs={10}>
					{busy ? <div className="mm-traj-work-progress-outer">
							<div className="mm-traj-work-progress-inner" style={ {left: (this.props.busy*100 - 100)+'%' } } />
						</div> : ''}
				</Grid>
				<Grid item xs={1} />
			</Grid>
		</div> );
	}

	onColorChange(e) {
		if (e.target) {
			invoke_cp_handler(this, 'onColorChange', e.target.value);
		}
	}

	onSelDispClick(flags) {
		if (this.props.onSelDispClick) {
			this.props.onSelDispClick(flags);
		}
	}

	onOpacityChange(e,f) {  invoke_cp_handler(this, 'onOpacityChange', f)  }
	onModeToggle(newValue) {  invoke_cp_handler(this, 'onVisibilityChange', newValue);  }
	onColoringRuleToggle(newValue) {  invoke_cp_handler(this, 'onColoringRuleToggle', newValue);  }
}

export default TrajectorySettingPanel;