import MMEventDispatcher from '../leafmob/EventDispatcher'
import SelectionManager from '../selection/SelectionManager'

var NextLayerId = 1;

class MMLayer extends MMEventDispatcher {
	constructor() {
		super();

		this.loader = null;
		this.annotation = null;
		this.title = 'Untitled';
		this.subTitle = 'layer';
		this.layerId = NextLayerId++;
		this.coloringKey = 'hue';
		this.targetId = -1;
		this.loadProgress = -1;
		this.idCountProgress = -1;
		this.loadComplete = false;
		this.visibility = true;
		
		this.legendVisibilities = [false, false];

		this.selection = new SelectionManager(this);
		this.selection.setEventParent(this);
		this.selectionAppearance = 0;
		this.selectionTrajectoryAppearance = 0;
		
		this._configOpen = false;
		this._annOpen = false;
		this.removalWatcher = null;

		this.filterExpression = null;
	}

	hasDataError() {  return false;  }
	referDataErrorList() { return null; }
	clearDataError() {
		this.eventInvoke('LayerErrorCleared', this);
	}

	watchRemoval(watcher) {
		this.removalWatcher = watcher;
	}
	
	cancel() {
		this.eventInvoke('LayerCancelled', this);
	}

	notifyRemoval() {
		if (this.removalWatcher) {
			this.removalWatcher.layerWillBeRemoved(this);
		}
	}
	
	hasMovingData() { return false; }
	refersMovingData() { return false; }
	referSelection() { return this.selection; }

	getLayerType() {
		return 'mo';
	}

	createLoader(file) {
		return this.loader;
	}
	
	loaderSetNumHeaderLines(n) {
		if (this.loader) {
			this.loader.setNumHeaderLines(n);
		}
	}
	
	loaderSourceSet(loader, dataSource) {
		if (dataSource.name) {
			this.setTitle(dataSource.name);
		}
	}

	loaderFoundTitle(title) {
		this.setTitle(title);
	}
	
	loaderFileError(loader, errorMessage) {
		console.log("%cLoader error: "+errorMessage, "color:#f7a");
		this.eventInvoke('LayerFileError', this, errorMessage);
	}
	
	setTitle(t) {
		if (this.title !== t) {
			this.title = t;
			this.eventInvoke('LayerTitleChange', this, t);
		}
	}
	
	setSubTitle(t) {
		if (this.subTitle !== t) {
			this.subTitle = t;
		}
	}

	getTimeRange() {
		return null;
	}

	setTargetId(i, fire) {
		this.targetId = i;
		if (fire) {
			this.eventInvoke('LayerTargetChange', this, i);
		}
	}
	
	setColoringKey(k, fire) {
		this.coloringKey = k;
		if (fire) {
			this.eventInvoke('LayerColoringChange', this, k);
		}
	}
	
	setVisibility(v, suppressEvent) {
		if (this.visibility !== v) {
			this.visibility = v;
			if (!suppressEvent) {
				this.eventInvoke('LayerVisibilityChange', this, v);
			}
		}
	}

	setLegendVisible(index, v) {
		if (index === 0 || index === 1) {
			if (this.legendVisibilities[index] !== v) {
				this.legendVisibilities[index] = v;
				this.eventInvoke('LayerLegendVisibilityChange', this, index);
			}
		}
	}

	// Selection appearance

	setSelectionAppearance(a, suppressEvent) {
		if (this.selectionAppearance !== a) {
			this.selectionAppearance = a;
			if (!suppressEvent) {
				this.eventInvoke('LayerSelectionAppearanceChange', this, a);
			}
		}
	}
	
	setSelectionTrajectoryAppearance(a, suppressEvent) {
		if (this.selectionTrajectoryAppearance !== a) {
			this.selectionTrajectoryAppearance = a;
			if (!suppressEvent) {
				this.eventInvoke('LayerSelectionTrajectoryAppearanceChange', this, a);
			}
		}
	}

	setAnnotation(ann) {
		if (this.annotation !== ann) {
			this.annotation = ann;
			this.eventInvoke('LayerAnnotationChange', this, ann);
		}
	}

	// Layer Capability
	hasTrajectory() {
		return false;
	}

	countTrajectories() {
		return 0;
	}

	referTrajectoryAt(index) { return null; }

	// Filter
	setFilterExpression(x) {
		this.filterExpression = x;
	}
}

const InterpolationLimitType = {
	None: 0,
	Time: 1,
	Distance: 2
}

const LimitedSectionAppearance = {
	Hidden: 0,
	Dashed: 1,
	Emphasized: 2
};

export { InterpolationLimitType, LimitedSectionAppearance };
export default MMLayer;