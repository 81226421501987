import HueColorGenerator from '../marker/HueColorGenerator'
import BlendColorGenerator from '../marker/BlendColorGenerator'

const ColoringPresetManager = {
	init: function() {
		if (window.GlobalColorPreset) {
			return window.GlobalColorPreset;
		}
		
		const a = [];
		this.addPresets(a);
		
		window.GlobalColorPreset = a;
		return a;
	},

	find: function(name) {
		if (!window.GlobalColorPreset) {
			this.init();
		}
		
		for (const c of window.GlobalColorPreset) {
			if (c.name === name) { return c; }
		}

		return null;
	},

	addPresets: function(a) {
		a.push( new HueColorGenerator(  'hue'           , 'Hue rotation') );
		a.push( new BlendColorGenerator('green_to_red'  , 'Green-yellow-red' , ['#2D9', '#FE2', '#F54']) );
		a.push( new BlendColorGenerator('w_to_red'      , 'White-red'                   ) );
		a.push( new BlendColorGenerator('blue_to_red'   , 'Blue-red'  , ['#27F', '#F54']) );
		a.push( new BlendColorGenerator('black_to_red'  , 'Black-red' , ['#333', '#F54']) );
		a.push( new BlendColorGenerator('black_to_w'    , 'Black-white' , ['#333', '#FFF']) );
		return a;
	}
};

export default ColoringPresetManager;