
export default function FastProjectionGrid(size) {
	this.size = size;
	this.vertices = new Array((size+1)*(size+1));

	this.latSpan = 1;
	this.lngSpan = 1;

	this.latCell = 1.0;
	this.lngCell = 1.0;
	
	this.offsetX = this.offsetY = 0;
}

FastProjectionGrid.prototype = {
	setOffset: function(ox, oy) {
		this.offsetX = ox;
		this.offsetY = oy;
	},
	
	update: function(projectionProvider, latMin, lngMin,  latMax, lngMax) {
		const size = this.size;
		let ty, tx;

		let dlat = latMax - latMin;
		let dlng = lngMax - lngMin;
		let lat, lng;
		
		let vi = 0;
		let vls = this.vertices;
		
		for (let y = 0;y <= size;y++) {
			ty = y / size;
			lat = latMin + ty * dlat;
		
			for (let x = 0;x <= size;x++) { // 0 < x < size+1
				tx = x / size;
				
				lng = lngMin + tx * dlng;
				const spos = projectionProvider(lat, lng);
				vls[vi++] = new FastProjectionGrid.Vertex(lat, lng, spos);
			}
		}
		
		if (dlng > -0.00000001 && dlng < 0.00000001) {dlng = 1}
		if (dlat > -0.00000001 && dlat < 0.00000001) {dlat = 1}
		this.lngSpan = dlng;
		this.latSpan = dlat;

		this.lngCell = dlng / size;
		this.latCell = dlat / size;
	},
	
	calcForList: function(ls) {
		for (const o of ls) {
			this.calc(o);
		}
	},

	calc: function(a) {
		let vls = this.vertices;
		let originCell = vls[0];
		let sz = this.size;
		let sz1 = sz+1;
		
		let olat = a.lat - originCell.lat;
		let olng = a.lng - originCell.lng;

		let cx = Math.floor((olng * sz)/this.lngSpan);
		let cy = Math.floor((olat * sz)/this.latSpan);
		
		let vx1 =  cx;
		if (vx1 >= sz) { vx1 = sz-1; }
		else if (vx1 < 0) { vx1 = 0; }
//		let vx2 = (cx == sz) ? cx : (cx+1);
		let vx2 = vx1 + 1;

		let vy1 =  cy;
		if (vy1 >= sz) { vy1 = sz-1; }
		else if (vy1 < 0) { vy1 = 0; }
//		let vy2 = (cy == sz) ? cy : (cy+1);
		let vy2 = vy1 + 1;

		if (isNaN(vx1) || isNaN(vy1) || isNaN(vx2) || isNaN(vy2)) {
			a.sx = null;
			a.sy = null;
		} else {
			let v0 = vls[vy1 * sz1 + vx1];
			let v1 = vls[vy1 * sz1 + vx2];
			let v2 = vls[vy2 * sz1 + vx1];

			let tx = (a.lng - v0.lng) / this.lngCell;
			let ty = (a.lat - v0.lat) / this.latCell;
			let _tx = 1.0 - tx;
			let _ty = 1.0 - ty;

			// Write output - - - - - - - - - - - - - - - - - -
			a.sx = v0.sx * _tx  +  v1.sx * tx + this.offsetX;
			a.sy = v0.sy * _ty  +  v2.sy * ty + this.offsetY;
		}
	},
	
	exportConfiguration: function() {
		return {
			vertices: this.vertices,
			size: this.size,
			latSpan: this.latSpan,
			lngSpan: this.lngSpan,
			latCell: this.latCell,
			lngCell: this.lngCell
		};
	},
	
	importConfiguration: function(src) {
		this.size = src.size;
		this.vertices = src.vertices;

		this.latSpan = src.latSpan;
		this.lngSpan = src.lngSpan;

		this.latCell = src.latCell;
		this.lngCell = src.lngCell;
	},
	
	relocateOrigin: function() {
		let ls = this.vertices;
		if (ls.length < 1) {
			return;
		}

		let oi = (this.size+1) * this.size;
		let o = ls[oi];
		for (let i = 0;i < ls.length;++i) {
			if (i === oi) { continue; }
			
			let v = ls[i];
			v.sx -= o.sx;
			v.sy -= o.sy;
		}
		
		o.sx = 0;
		o.sy = 0;
	},
	
	getEntireScreenWidth: function() {
		const ls = this.vertices;
		const n = this.size;
		if (!ls[n]) {
			return 0;
		}
		
		return ls[n].sx - ls[0].sx;
	},
	
	getEntireScreenHeight: function() {
		const ls = this.vertices;
		const n = this.size;
		return ls[0].sy - ls[(n+1)*n].sy;
	},
	
	invertCalc: function(inoutVec2) {
		let sx = inoutVec2[0];
		let sy = inoutVec2[1];
		let scrW = this.getEntireScreenWidth();
		if (scrW < 1.0) {
			// not ready
			return false;
		}
		
		sx = (sx*0.5) + 0.5;
		sy = (sy*0.5) + 0.5;
		sx *= scrW;
		sy *= this.getEntireScreenHeight();
		
		const ls = this.vertices;
		// const len = ls.length;
		const loop_len = (this.size+1) * this.size;
		for (let i = 0;i < loop_len;++i) {
			if (!ls[i]) {break;}

			const x1 = ls[i].sx;
			const x2 = ls[i+1].sx;
			const y1 = ls[i+this.size+1].sy;
			const y2 = ls[i].sy;
			
			if (sx >= x1 && sy >= y1 && sx <= x2 && sy <= y2) {
				const xlen = x2-x1;
				const ylen = y2-y1;
				const nx = (sx-x1) / xlen;
				const ny = (sy-y1) / ylen;

				const lat1 = ls[i].lat;
				const lng1 = ls[i].lng;
				
				inoutVec2[0] = lng1 + this.lngCell * nx;
				inoutVec2[1] = lat1 + this.latCell * (1.0 - ny);

				return true;
			}
		}
		
		return false;
	}
};

FastProjectionGrid.Vertex = function(lat, lng, spos) {
	this.lat = lat;
	this.lng = lng;
	this.sx = spos.x;
	this.sy = spos.y;
};
