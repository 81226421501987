import MMEventDispatcher from '../leafmob/EventDispatcher'

class MMLayerList extends MMEventDispatcher
{
	constructor() {
		super();
		this.list = [];
	}

	add(layer) {
		layer.setEventParent(this);
		
		this.list.push(layer);
		this.notify();
	}
	
	swapLayers(firstIndex) {
		const secondIndex = firstIndex+1;
		const ls = this.list;
		if (secondIndex < ls.length) {
			const old1 = ls[firstIndex];
			ls[firstIndex]  = ls[secondIndex];
			ls[secondIndex] = old1;
		}
	}

	remove(layer) {
		const lid = layer.layerId;
		const i = this.list.indexOf(layer);
		if (i >= 0) {
			this.list.splice(i, 1);
		}
		
		this.invalidateReference(lid);
	}
	
	invalidateReference(targetLayerId) {
		for (const layer of this.list) {
			if (layer.refersMovingData() && layer.targetId === targetLayerId) {
				layer.setTargetId(-1, true);
			}
		}
	}

	notify() {
		this.eventInvoke('LayerListChange', this);
	}
	
	count() {
		return this.list.length;
	}
	
	getAt(i) {
		return this.list[i] || null;
	}
	
	byId(id) {
		const ls = this.list;
		for (const item of ls) {
			if (item.layerId === id) {
				return item;
			}
		}
		
		return null;
	}
}

export default MMLayerList;