import React from 'react';
import { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import InputLabel from  '@material-ui/core/InputLabel';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import get_i18n from '../Internationalization';
import { PanelTitle } from './MarkerSettingPanel';
import { invoke_cp_handler } from '../misc-utils';
import { cond_grid } from './cond-grid';

class TailSettingPanel extends Component {

	render() {
		const shown = !!this.props.currentMode;
		const btn0 = shown ? null : 'secondary';
		const btn1 = shown ? 'primary' : null;
		const curDuration = this.props.currentDuration;
	
		return ( <div>
			<Grid container spacing={1} alignItems="center" className="has-pred mm-setting-panel-section">
				<Grid item xs={12}>
					<PanelTitle icon="/images/cat-tail.png" label="Tail" />
				</Grid>

				<Grid item xs={12}>
					<div className="mm-setting-panel-toggle"><ButtonGroup size="small" disableRipple={true}>
						<Button variant="contained" disableElevation color={btn0} onClick={ this.onModeToggle.bind(this, 0) }
						        startIcon={ <img src="/images/comp-x2.png" alt="X" title="hide" /> } className="mm-toggle-x">&#65279;</Button>
						<Button variant="contained" disableElevation color={btn1}  onClick={ this.onModeToggle.bind(this, 1) }>{ get_i18n('TailEnabled') }</Button>

					</ButtonGroup></div>
				</Grid>

				{ cond_grid(1, !shown) }
				{ shown ? <Grid item xs={10}>

					<div className="mm-setting-panel-toggle"><InputLabel className="tail-duration-label">{ get_i18n('TailDuration') }</InputLabel>
					<ButtonGroup size="small" disableRipple={true}>
						<TailDurationButton selected={curDuration} value={60} onClick={ this.onDurationClick.bind(this, 60) } />
						<TailDurationButton selected={curDuration} value={300} onClick={ this.onDurationClick.bind(this, 300) } />
						<TailDurationButton selected={curDuration} value={600} onClick={ this.onDurationClick.bind(this, 600) } />
						<TailDurationButton selected={curDuration} value={1800} onClick={ this.onDurationClick.bind(this, 1800) } />
					</ButtonGroup></div>

				</Grid> : '' }
				{ cond_grid(1, !shown) }
			</Grid>
		</div> );
	}

	onModeToggle(newValue) {  invoke_cp_handler(this, 'onModeChange', newValue);  }
	onDurationClick(newValue) {  invoke_cp_handler(this, 'onDurationClick', newValue);  }
}

function TailDurationButton(props) {
	const mins = Math.floor( props.value / 60 );
	const btnColor = ((props.selected-0) === props.value) ? 'primary' : 'default';
	return ( <Button onClick={ props.onClick } disableRipple={true} className="tail-duration-button" color={ btnColor } variant="contained" disableElevation>{ mins + get_i18n('Minutes') }</Button> );
}

export default TailSettingPanel;