import MMEventDispatcher from './EventDispatcher'
import { GateFeatureUtils } from './gate-feature';
import leaflet from 'leaflet'

class PolygonGateFeature extends MMEventDispatcher {
	constructor(id, map, autoLocate) {
		super();
		this.map = map;
		this.id = id;
		this.funcMarker = null;
		this.commandIconPath = null;
		this.coordList = [];

		this.editor = new LeafPolyEditor(map, this);
		this.gearIcon = GateFeatureUtils.createGearIcon();
	}

	isPolygon() { return true; }

	destroy() {
		if (this.funcMarker) { this.funcMarker.remove(); }
		this.editor.destroy();
	}

	closeControl() {
		if (this.funcMarker) {
			this.funcMarker.closePopup();
		}
	}

	setCommandIconPath(path) {
		this.commandIconPath = path;
	}

	generateIconMarkers() {
		GateFeatureUtils.installFunctionMarker(this, this.map, this.gearIcon,
			this.relocateFunctionMarker());
	}

	relocateFunctionMarker() {
		return GateFeatureUtils.calcEastEndLocation(this.editor.handles, this.funcMarker);
	}

	peOnChange(editor) {
		this.relocateFunctionMarker();
	}

	buildPopupContent(container) {
		GateFeatureUtils.addExecuteButton(container, this.commandIconPath, this.onExecButtonClick.bind(this));
		GateFeatureUtils.addRemoveButton(container, this.commandIconPath, this.onRemoveButtonClick.bind(this));
	}

	onExecButtonClick() {
		this.eventInvoke('GateExecRequested', this, null);
	}

	onRemoveButtonClick() {
		this.eventInvoke('GateRemoveRequested', this);
	}

	updateCoordList() {
		var src = this.editor.getLatLngs();
		if (Array.isArray(src[0])) {
			src = src[0];
		}

		const dest = this.coordList;
		dest.length = src.length;
		for (var i = 0;i < src.length;++i) {
			const loc = src[i];
			dest[i] = [ loc.lat, loc.lng ];
		}

		return dest;
	}

	referCoordList() {
		this.updateCoordList();
		return this.coordList;
	}

}


// POLYGON EDITOR ////////////////////////////////////////////////

const kHandleSize = 20;
const kHandleViewSize = 10;

function renderNormalHandle(g, w, h) {
	g.fillStyle = '#000';
	g.fillRect(0, 0, w, h);
	g.fillStyle = '#fff';
	g.fillRect(2, 2, w-4, h-4);
}

function renderBendHandle(g, w, h) {
	g.fillStyle = '#000';
	g.fillRect(4, 4, w-8, h-8);
	g.fillStyle = '#fff';
	g.fillRect(6, 6, w-12, h-12);
}

function getHandleImageGenerator(renderer) {
	var cv = null;
	return function() {
		if (!cv) {
			cv = document.createElement('canvas');
			cv.width = kHandleSize;
			cv.height = kHandleSize;
			
			const g = cv.getContext('2d');
			renderer(g, kHandleSize, kHandleSize);
		}

		return cv;
	};
}

const generateNormalHandleImage = getHandleImageGenerator(renderNormalHandle);
const generateBendHandleImage = getHandleImageGenerator(renderBendHandle);

class LeafPolyEditor {
	constructor(map, listener) {
		this.markerHandlers = {
			dragstart: this.onMarkerDragStart.bind(this),
			drag: this.onMarkerDrag.bind(this),
			dragend: this.onMarkerDragEnd.bind(this)
		};

		this.map = map;
		this.listener = listener || null;
		this.handles = [];
		this.indexToRemove = -1;
		
		this.polygonVertices = [];
		this.mPolygon = null;
		
		this.generateInitialVertices();
	}

	getLatLngs() {
		return this.mPolygon.getLatLngs();
	}

	generateInitialVertices() {
		const m = this.map;
		const c = m.getCenter();
		const bounds = m.getBounds();
		const shortLen = Math.min( bounds.getEast() - bounds.getWest() , bounds.getNorth() - bounds.getSouth() );
		const span = shortLen * 0.2;

		this.append(c.lat + span, c.lng - span);
		this.append(c.lat + span, c.lng + span);
		this.append(c.lat - span, c.lng + span);
		this.append(c.lat - span, c.lng - span);

		this.insertMidHandle(3);
		this.insertMidHandle(2);
		this.insertMidHandle(1);
		this.insertMidHandle(0);

		this.updatePolygon();
	}

	insertMidHandle(firstIndex) {
		const len = this.handles.length;
		const h1 = this.handles[firstIndex];
		const h2 = this.handles[ (firstIndex+1) % len ];
		if (h1._is_mid_handle || h2._is_mid_handle) { return null; }

		const pos1 = h1.getLatLng();
		const pos2 = h2.getLatLng();
		
		const hm = createHandleMarker(this.map, (pos1.lat+pos2.lat)/2, (pos1.lng+pos2.lng)/2, true);
		this.handles.splice(firstIndex+1, 0, hm);
		onAll(this.markerHandlers, hm);

		return hm;
	}

	append(lat, lng) {
		const h = this.createVertex(lat, lng);
		this.handles.push(h);
	}

	createVertex(lat, lng) {
		const hm = createHandleMarker(this.map, lat, lng, false);
		onAll(this.markerHandlers, hm);
		return hm;
	}

	updatePolygon() {
		this.polygonVertices.length = countEndHandles(this.handles);
		copyHandleVertices(this.polygonVertices, this.handles);

		if (!this.mPolygon) {
			this.mPolygon = leaflet.polygon(this.polygonVertices).addTo(this.map);
		} else {
			this.mPolygon.setLatLngs( this.polygonVertices );
		}
	}
	
	onMarkerDragStart(e) {
		this.indexToRemove = -1;
		this.updatePolygon();

		const h = e.target;
		if (h._is_mid_handle) {
			this.bendStart(h);
		}

		this.fireChange();
	}

	onMarkerDrag(e) {
		const h = e.target;
		if (!h._is_mid_handle && !h._is_now_bending) {
			if (this.handles.length > 6) {
				this.indexToRemove = checkUnbend(h, this.handles);
				h.setOpacity(this.indexToRemove >= 0 ? 0.25 : 1);
			}
		}

		this.updatePolygon();
		relocateMidHandles(this.handles);
		this.fireChange();
	}

	onMarkerDragEnd(e) {
		const h = e.target;
		if (h._is_now_bending) {
			convertToNormalHandle(h);
			this.insertMidHandlesBeforeAfter(h);
			relocateMidHandles(this.handles);
			this.fireChange();
		}

		if (this.indexToRemove >= 0) {
			this.finishUnbend(this.indexToRemove);
			this.fireChange();
		}

		this.indexToRemove = -1;
	}

	// 指定された中間ハンドルの箇所に新しい頂点を作成
	bendStart(midHandle) {
		midHandle._is_mid_handle = false;
		midHandle._is_now_bending = true;
	}

	insertMidHandlesBeforeAfter(centerHandle) {
		const centerIndex = this.handles.indexOf(centerHandle);
		if (centerIndex < 0) { return; }
		// インデックスが大きい方から処理

		const n = this.handles.length;
		const prevIndex = (centerIndex + n - 1) % n;
		const nextIndex = (centerIndex + 1) % n;
		
		if (nextIndex > prevIndex) {
			// nextIndexのほうが大きい(通常パターン)
			this.insertMidHandleBetweenIndices(centerIndex, nextIndex);
			this.insertMidHandleBetweenIndices(prevIndex, centerIndex);
		} else {
			// nextIndexのほうが小さい(配列先頭を跨ぐ場合)
			this.insertMidHandleBetweenIndices(prevIndex, centerIndex);
			this.insertMidHandleBetweenIndices(centerIndex+1, nextIndex);
		}
	}

	insertMidHandleBetweenIndices(i1, i2) {
		// console.log(i1, i2)
		const h1 = this.handles[i1];
		const h2 = this.handles[i2];

		const loc1 = h1.getLatLng();
		const loc2 = h2.getLatLng();

		const newHandle = this.createVertex(
			(loc1.lat + loc2.lat) / 2 ,
			(loc1.lng + loc2.lng) / 2
		);

		convertToMidHandle(newHandle);
		this.handles.splice(i1+1, 0, newHandle);
	}

	finishUnbend(centerIndex) {
		const n = this.handles.length;
		const prevIndex = (centerIndex + n - 1) % n;
		const nextIndex = (centerIndex + 1) % n;
		
		const ch = this.handles[centerIndex];
		const ph = this.handles[prevIndex];
		const nh = this.handles[nextIndex];

		convertToMidHandle( ch );
		if (nextIndex > prevIndex) {
			// インデックスが大きい方から処理(通常パターン)
			if (nh._is_mid_handle) {  this.deleteHandle(nextIndex);  }
			if (ph._is_mid_handle) {  this.deleteHandle(prevIndex);  }
		} else {
			if (ph._is_mid_handle) {  this.deleteHandle(prevIndex);  }
			if (nh._is_mid_handle) {  this.deleteHandle(nextIndex);  }
			this.moveIfFirstIsMid();
		}

		this.updatePolygon();
		relocateMidHandles(this.handles);
	}
	
	deleteHandle(index) {
		const h = this.handles[index];
		if (h) {
			offAll(this.markerHandlers, h);
			h.remove();
			this.handles.splice(index, 1);
		}
	}

	moveIfFirstIsMid() {
		const first = this.handles[0];
		if (first._is_mid_handle) {
			this.handles.shift();
			this.handles.push(first);
		}
	}

	fireChange() {
		if (this.listener && this.listener.peOnChange) {
			this.listener.peOnChange(this);
		}
	}

	destroy() {
		if (this.mPolygon) {
			this.mPolygon.remove();
		}

		for (const h of this.handles) {
			offAll(this.markerHandlers, h);
			h.remove();
		}

		this.handles.length = 0;
	}
}

function onAll(handlers, feature) {
	for (const name in handlers) if (handlers.hasOwnProperty(name)) {
		feature.on(name, handlers[name]);
	}
}

function offAll(handlers, feature) {
	for (const name in handlers) if (handlers.hasOwnProperty(name)) {
		feature.off(name, handlers[name]);
	}
}

function getHandleLIcon(isMid) {
	return leaflet.icon({
		className: 'leafmob-gate-edit-handle',
		iconUrl: isMid ? generateBendHandleImage().toDataURL() : generateNormalHandleImage().toDataURL(),
		iconSize: [ kHandleViewSize , kHandleViewSize ],
		iconAnchor: [ kHandleViewSize >> 1 , kHandleViewSize >> 1 ]
	});
}

function createHandleMarker(map, lat, lng, isMid) {
	const handleIcon = getHandleLIcon(isMid);
	const mk = leaflet.marker([lat, lng], {icon: handleIcon, draggable: true}).addTo(map);
	mk._is_mid_handle = isMid;
	mk._is_now_bending = false;
	return mk;
}

function countEndHandles(handles) {
	var n = 0;
	for (const h of handles) {
		if (!h._is_mid_handle) { ++n; }
	}

	return n;
}

function fillArrayLatLngs(a) {
	const n = a.length;
	for (var i = 0;i < n;++i) {
		if (!a[i]) {
			a[i] = {lat: 0, lng: 0};
		}
	}
}

function copyHandleVertices(aOut, handles) {
	fillArrayLatLngs(aOut);

	var writePos = 0;
	const n = handles.length;
	for (var i = 0;i < n;++i) {
		const h = handles[i];
		if (h._is_mid_handle) { continue; }
		
		const srcPos = h.getLatLng();			
		aOut[writePos].lat = srcPos.lat;
		aOut[writePos].lng = srcPos.lng;
		++writePos;
	}

	return aOut;
}

// 中間ハンドルのアイコンを端点ハンドルに変更
function convertToNormalHandle(h) {
	if (h._is_now_bending) {
		h.setIcon( getHandleLIcon(false) );
		h._is_now_bending = false;
	}
}

// 端点ハンドルを中間ハンドルに変更
function convertToMidHandle(h) {
	if (!h._is_mid_handle) {
		h.setIcon( getHandleLIcon(true) );
		h._is_mid_handle = true;
		h._is_now_bending = false;
		h.setOpacity(1);
	}
}

// 全ての中間ハンドルの位置を修正
function relocateMidHandles(handles) {
	const n = handles.length;
	for (var i = 1;i < n;++i) {
		const h = handles[i];
		if (h._is_mid_handle) {
			const prevIndex = i-1;
			const nextIndex = (i+1) % n;

			const pos0 = handles[ prevIndex ].getLatLng();
			const pos2 = handles[ nextIndex ].getLatLng();
			
			h.setLatLng([ (pos0.lat+pos2.lat)/2 , (pos0.lng+pos2.lng)/2 ]);
		}
	}
}

// 折り曲げのキャンセルをチェック
function checkUnbend(centerHandle, handles) {
	const index = handles.indexOf(centerHandle);
	if (index >= 0) {
		const prevHandle = findForeignHandle(handles, index, -1);
		const nextHandle = findForeignHandle(handles, index, 1);
		
		if (calcEdgesDP(prevHandle.getLatLng() , centerHandle.getLatLng() , nextHandle.getLatLng() ) > 0.92) {
			return index;
		}
	}

	return -1;
}

function findForeignHandle(handles, center, dir) {
	// dir = -1 or 1

	const n = handles.length;
	const fi1 = (center + dir   + n) % n;
	const fi2 = (center + dir*2 + n) % n;

	const h1 = handles[fi1];
	const h2 = handles[fi2];
	if (h1._is_mid_handle) {
		return h2;
	} else {
		return h1;
	}
}

// P1->P2 P2->P3 の内積(大きさは正規化)を計算して直線化したか判断
const _temp_v1 = new Vec2();
const _temp_v2 = new Vec2();
function calcEdgesDP(loc1, loc2, loc3) {
	const E = leaflet.CRS.EPSG3857;
	const p1 = E.project(loc1);
	const p2 = E.project(loc2);
	const p3 = E.project(loc3);

	_temp_v1.diff(p1, p2).normalize();
	_temp_v2.diff(p2, p3).normalize();
	return dotproduct(_temp_v1, _temp_v2);
}

function Vec2(x, y) {
	this.x = x || 0;
	this.y = y || 0;
}

Vec2.prototype.diff = function(p1, p2) {
	this.x = p2.x - p1.x;
	this.y = p2.y - p1.y;
	return this;
};

Vec2.prototype.normalize = function() {
	const len = Math.sqrt(this.x * this.x + this.y * this.y);
	if (len > 0.000001) {
		this.x /= len;
		this.y /= len;
	}

	return this;
};

function dotproduct(v1, v2) {
	return v1.x*v2.x + v1.y*v2.y;
}


export default PolygonGateFeature;