import React from 'react';
import { Component } from 'react';
import '../App.css';

const kGaugeColor = '#555';
const kGaugeFrontColor = '#888';
const kGaugeFontSize = 10;
const kGaugeFontColor = '#BBB';
const kViewBarColor = '#444';
const kViewTextColor = '#AAA';
const kViewBackFontSize = 16;
const kCursorFontSize = 12;

const kDefaultMonthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const kDefaultShortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

class TimeSlider extends Component {
	// SETUP ==============================================================
	constructor(props) {
		super(props);
		this.state = this.generateDefaultState();
		
		this.animation = {
			callback: this.enterAnimationFrame.bind(this),
			active: false,
			startTime: -1,
			startMin: 0,
			startMax: 0
		};
	}

	generateDefaultState() {
		return {
			currentTime: 1577804400,
			windowMin: 1577804400,
			windowMax: 1577804400+86400*365,
			validMin: 1577804400,
			validMax: 1577804400+86400*365
		};
	}
	
	getCurrentTime() {
		return this.state.currentTime;
	}
	
	setCurrentTime(t) {
		this.state.currentTime = t;
		this.setState({ currentTime: t });
		this.afterCurrentTimeChange();
	}

	advanceCurrentTime(d) {
		if (d) {
			this.state.currentTime += d;
			this.setState(this.state);
			this.afterCurrentTimeChange();

			return this.checkEndTime();
		}

		return false;
	}

	// 再生時間が終わりに達したかチェック(+境界値補正)
	checkEndTime() {
		const w = this.state.windowMax;
		const t = this.state.currentTime;
		if (t >= w) {
			this.state.currentTime = w;
			return true;
		}
		return false;
	}

	setTimeRange(timeRange) {
		var s = this.state;
		
		s.validMin = timeRange.min;
		s.validMax = timeRange.max;

		s.windowMin = s.validMin;
		s.windowMax = s.validMax;
		
		s.currentTime = s.validMin;
		
		this.setState(s);
	}

	render() {
		this.redrawGaugeBar();
		this.redrawWindowBar();

		return (
			<div className="mm-time-slider-component"
				 ref={ el => this.containerElement=el }
				 style={ {userSelect: "none"} } >
				<canvas className="mm-time-slider-gauge" width="0" height="0"
					onMouseDown={ e => this.onGaugeMouseDown(e) }
					onMouseUp={   e => this.onGaugeMouseUp(e)   }
					ref={ el => this.gaugeCanvasElement=el }>
				</canvas><canvas className="mm-time-slider-window"
					width="0" height="0" style={ {backgroundColor: "#def",boxShadow:"0 0 2px #08F inset"} }
						onMouseDown={ e => this.onCanvasMouseDown(e) }
						onMouseUp={   e => this.onCanvasMouseUp(e)   }
						onWheel={ e=> this.onWindowWheel(e) }
						ref={ el => this.canvasElement=el }>
				</canvas>
				<span title="Reset range" onClick={ this.onFullSpanClick.bind(this) } className="mm-time-slider-fullspan"> </span>
			</div>
    	);
	}

	componentDidMount() {
		this.viewHeight = 38;
		this.gaugeHeight = 12;
		this.observeOuterMouseEvents();
		this.dragState = {
			active: false,
			prevScreenX: 0
		};

		this.gaugeDragState = {
			active: false,
			prevX: 0
		};
		
		this.onGlobalResize(null);
	}

	// Animation
	startRangeAnimation() {
		this.animation.startTime = (new Date() - 0);
		this.animation.startMin = this.state.windowMin;
		this.animation.startMax = this.state.windowMax;
		this.startAnimationIf();
	}
	
	startAnimationIf() {
		if (!this.animation.active) {
			this.animation.active = true;
			window.requestAnimationFrame( this.animation.callback );
		}
	}
	
	enterAnimationFrame() {
		this.animation.active = false;

		const curT = (new Date() - 0);

		var alpha = (curT - this.animation.startTime) / 300.0;
		if (alpha > 0.99) {
			alpha = 1.0;
		} else {
			this.animation.active = true;
			window.requestAnimationFrame( this.animation.callback );
		}
		
		alpha = Math.sin(alpha * Math.PI / 2);
		const _a = 1.0 - alpha;
		
		this.state.windowMin = _a * this.animation.startMin  +  alpha * this.state.validMin;
		this.state.windowMax = _a * this.animation.startMax  +  alpha * this.state.validMax;
		this.setState(this.state);
	}
	

	// EVENT HANDLING ======================================================

	observeOuterMouseEvents() {
		window.addEventListener('mousemove', this.onGlobalMouseMove.bind(this));
		window.addEventListener('mouseup', this.onGlobalMouseUp.bind(this));
		window.addEventListener('resize', this.onGlobalResize.bind(this));
	}
	
	onFullSpanClick(e) {
		if (this.props.onFullSpanClick) {
			this.props.onFullSpanClick(e);
		}
		
		this.startRangeAnimation();
	}

	onGlobalResize(e) {
		const that = this;
		process.nextTick(function(){ that.adjustSize() });
	}

	onGlobalMouseMove(e) {
		const dx = e.screenX - this.dragState.prevScreenX;
		this.dragState.prevScreenX = e.screenX;

		if (this.gaugeDragState.active) {
			this.moveGauge(dx);
			this.gaugeDragState.prevX = e.screenX;
		}
		
		if (this.dragState.active) {
			this.moveCursor(dx, false);
		}
	}

	onGlobalMouseUp(e) {
		this.dragState.active = false;
		this.gaugeDragState.active = false;
	}


	onCanvasMouseDown(e) {
		this.dragState.prevScreenX = e.screenX;
		
		this.dragState.active = true;
		this.moveCursor(e.nativeEvent.offsetX, true);
	}

	onCanvasMouseUp(e) {
		this.dragState.active = false;
	}

	onGaugeMouseDown(e) {
		this.gaugeDragState.active = true;
		this.gaugeDragState.prevX = e.screenX;
	}

	onGaugeMouseUp(e) {
		this.gaugeDragState.active = false;
	}

	onWindowWheel(e) {
		this.zoomWindowArea(e.nativeEvent.offsetX, e.deltaY)
	}


	adjustSize() {
		const bounds = this.containerElement.getBoundingClientRect();
		const w = bounds.width;
		const r = window.devicePixelRatio;

		this.setCanvasSizeWithRatio(this.canvasElement     , w, this.viewHeight , r);
		this.setCanvasSizeWithRatio(this.gaugeCanvasElement, w, this.gaugeHeight, r);

		this.redrawGaugeBar();
		this.redrawWindowBar();
	}

	setCanvasSizeWithRatio(cv, w, h, r) {
		cv.width  = w *r;
		cv.height = h *r;
		
		cv.style.width  = w + 'px';
		cv.style.height = h + 'px';
	}

	// GAUGE BAR
	
	// - events
	moveGauge(dx) {
		const cv = this.gaugeCanvasElement;
		const w = cv.width - 0;

		const st = this.state;
		const dAll = st.validMax - st.validMin;
		
		const nDX = (dx * window.devicePixelRatio) / w;
		const dTime = nDX * dAll;
		st.windowMin += dTime;
		st.windowMax += dTime;
		this.setState(st);
	}

	redrawGaugeBar() {
		const cv = this.gaugeCanvasElement;
		if (!cv) { return; }

		const g = cv.getContext('2d');
		const w = cv.width - 0;
		const h = cv.height - 0;

		const st = this.state;
		const dAll = st.validMax - st.validMin;
		const dWin = st.windowMax - st.windowMin;

		const nX = (st.windowMin - st.validMin) / dAll;
		const nW = dWin / dAll;
		
		g.clearRect(0,0, w,h);
		g.fillStyle = kGaugeColor;
		g.fillRect(0, 0, w, h);

		g.fillStyle = kGaugeFrontColor;
		g.fillRect(nX*w, 0, nW*w, h);

		g.fillStyle = '#000';
		g.fillRect(0, h-1, w, 1);
	}

	// WINDOW BAR
	moveCursor(viewX, absoluteMode) {
		const cv = this.canvasElement;
		const w = cv.width - 0;
		const st = this.state;
		const dWin = st.windowMax - st.windowMin;

		if (absoluteMode) {
			const nx = ((viewX-1)*window.devicePixelRatio) / w;
			st.currentTime = st.windowMin + dWin*nx;
			this.afterCurrentTimeChange();
		} else {			
			const nx = (viewX*window.devicePixelRatio) / w;
			st.currentTime += dWin*nx;
			this.afterCurrentTimeChange();
		}
		
		this.redrawGaugeBar();
		this.redrawWindowBar();
//		this.setState(st);
	}

	afterCurrentTimeChange() {
		if (this.props.afterCurrentTimeChange) {
			this.props.afterCurrentTimeChange(this, this.state.currentTime);
		}
	}

	zoomWindowArea(centerX, dy) {
		const cv = this.canvasElement;
		const w = cv.width - 0;
		const nCX = (centerX * window.devicePixelRatio) / w;

		const st = this.state;
		const dWin = st.windowMax - st.windowMin;
		const centerTime = st.windowMin + dWin*nCX;

		const lenLeft  = centerTime - st.windowMin;
		const lenRight = st.windowMax - centerTime;
		
		st.windowMin -= (dy*lenLeft) / 40.0;
		st.windowMax += (dy*lenRight) / 40.0;
		this.setState(st);
	}

	redrawWindowBar() {
		const cv = this.canvasElement;
		if (!cv) { return; }

		const g = cv.getContext('2d');
		const w = cv.width - 0;
		const h = cv.height - 0;
		
		g.clearRect(0,0, w,h);
		g.fillStyle = kViewBarColor;
		g.fillRect(0, 0, w, h);

		this.drawMonBoxes(g, w, h);
		this.drawCursor(g, w, h);
	}
	
	drawCursor(g, canvasWidth, canvasHeight) {
		const st = this.state;
		const t0 = st.windowMin;
		const t1 = st.windowMax;
		const dt = t1 - t0;
		
		const cursorRelT = st.currentTime - t0;
		const cursorNormalizedT = cursorRelT / dt;
		const r = window.devicePixelRatio;
		const cH = kCursorFontSize * r;
		const labelOffset = Math.floor(kCursorFontSize/2*r);
		const x = Math.floor(cursorNormalizedT * canvasWidth);
		const y = cH >> 1;
		const labelY = (canvasHeight >> 1) - labelOffset;
		
		const labelText = format_date_full( new Date(st.currentTime*1000) );
		
		g.save();
		g.textBaseline = 'middle';
		g.textAlign = 'center';
		g.font = 'bold ' + (kCursorFontSize*r) + 'px monospace';
		const textExtent = g.measureText(labelText);
		const cW = (textExtent.width + kCursorFontSize*r) >> 1;
		
		var labelX = x;
		const leftOver = labelX-cW;
		const rightOver = (labelX+cW) - canvasWidth;
		if (leftOver < 0) { labelX -= leftOver; }
		if (rightOver > 0) { labelX -= rightOver; }

		const barVisible   = (x >= -r && x < canvasWidth+r)
		const arrowVisible = (x >= (r*4) && x < canvasWidth-(r*4))

		g.fillStyle = '#000';
		//g.fillRect(x-r, 0, r*3, cH-labelOffset);
		g.fillRect(x-r, canvasHeight-cH, r*3, cH);
		g.fillText(labelText, labelX, labelY+r+r);

		const boxX = labelX-cW;
		const boxY = cH-labelOffset;
		const boxWidth = cW*2;
		const boxHeight = canvasHeight-cH-cH;

		if (barVisible) {
			this.drawTimeLabelBox(g, boxX, boxY, boxWidth, boxHeight, r, x, labelOffset-r-r);
		} else {
			this.drawTimeArrowBox(g, boxX, boxY, boxWidth, boxHeight, r, x > cW);
		}

		g.fillStyle = '#FFF';
		g.fillRect(x  , canvasHeight-cH, r, cH);
		g.fillStyle = '#444';
		g.fillText(labelText, labelX, labelY+r);

		g.restore();
	}

	drawTimeLabelBox(g, boxX, boxY, boxWidth, boxHeight, r, ax, a_height) {
		const pad = r*4;
		const cx = boxX + boxWidth / 2;
		const y2 = boxY + boxHeight+r;
		const x2 = boxX + boxWidth-r;
		
//		g.fillStyle = 'rgba(0,0,0,0.5)';
//		g.strokeStyle = '#FFF';
		g.fillStyle = 'rgba(255,255,255,0.75)';
		g.strokeStyle = '#000';
		g.lineWidth = r;

		// box outer
		/*
		g.fillRect(boxX, boxY-r, boxWidth, r);
		g.fillRect(boxX, boxY+boxHeight, boxWidth, r);
		g.fillRect(boxX-r, boxY-r, r, boxHeight+r*2);
		g.fillRect(boxX+boxWidth, boxY-r, r, boxHeight+r*2);
		*/
		
		var ax2 = ax - pad;
		var ax3 = ax + pad;

		if (ax2 < r)  { ax2 = r; }
		if (ax3 > x2) { ax3 = x2;  }
		
		g.beginPath();
		g.moveTo(boxX + r         , boxY-r);
		g.lineTo(x2, boxY-r);
		g.lineTo(x2, y2);
			g.lineTo(ax3     , y2);
			g.lineTo(ax+0.5*r, y2+a_height);
			g.lineTo(ax2     , y2);
		g.lineTo(boxX + r         , y2);
		g.closePath();
		g.fill();
		g.stroke();

	}
	
	drawTimeArrowBox(g, boxX, boxY, boxWidth, boxHeight, r, isRight) {
//		g.fillStyle = 'rgba(0,0,0,0.5)';
//		g.strokeStyle = '#FFF';
		g.fillStyle = 'rgba(255,255,255,0.75)';
		g.strokeStyle = '#000';
		g.lineWidth = r;
		
		const h = boxHeight >> 1;
		const pad = r*5;
		
		g.beginPath();
		if (!isRight) {
			g.moveTo(boxX + r         , boxY + h);
			g.lineTo(boxX + pad       , boxY-r);
			g.lineTo(boxX + boxWidth-r, boxY-r);
			g.lineTo(boxX + boxWidth-r, boxY+boxHeight+r);
			g.lineTo(boxX + pad       , boxY+boxHeight+r);
		} else {
			g.moveTo(boxX + r           , boxY-r);
			g.lineTo(boxX + boxWidth-pad, boxY-r);
			g.lineTo(boxX + boxWidth-r  , boxY + h);
			g.lineTo(boxX + boxWidth-pad, boxY+boxHeight+r);
			g.lineTo(boxX + r           , boxY+boxHeight+r);
		}

		g.closePath();
		g.fill();
		g.stroke();
	}

	drawMonBoxes(g, canvasWidth, canvasHeight) {
		const t0 = this.state.windowMin;
		const d0 = new Date(t0*1000);
		d0.setDate(1);
		d0.setHours(0);
		d0.setMinutes(0);
		d0.setSeconds(0);

		const d1 = new Date(d0 - 0);
		d1.setDate( d1.getDate() + 1 );

		const dt = this.state.windowMax - this.state.windowMin;
		const pxPerSec = canvasWidth / dt;
		const wDate = pxPerSec * Math.floor( (d1-d0) / 1000 );
		
		const startTime = Math.floor(d0 / 1000);
		const offset = startTime - t0;
		
		const fontSize = kViewBackFontSize * window.devicePixelRatio;
		const showMonThreshold = (window.devicePixelRatio * 1.5);
		const showMonthBox = (wDate > showMonThreshold);
		const showMonthBar = (wDate > (showMonThreshold / 16));
		
		g.save();
		g.fillStyle = kViewTextColor;
		g.textAlign = 'center';
		g.textBaseline = 'middle';

		const m0 = d0.getMonth();
		
		var x = pxPerSec * offset;
		var prevYear = null;
		for (var i = 0;i < 299;++i) {
			const nDays = this.countDatesInMonth(d0, i);
			const yd = new Date(d0);
			yd.setMonth( d0.getMonth()+i );
			const currentYear = yd.getFullYear();
			
			if (yd.getMonth() === 0) {
				g.fillRect(x-1, 0, 2, canvasHeight);
			} else {
				if (showMonthBar) {
					g.fillRect(x, fontSize, 1, canvasHeight-fontSize);
				}
			}

			
			const wThisMon = wDate * nDays;
			if (showMonthBox) {
				const d_shown = this.drawMonSubDays(g, canvasWidth, x, canvasHeight, nDays, wDate, fontSize, yd);
				if (!d_shown) {
					this.drawMonthLabel(g, (m0+i)%12, Math.floor(x + wThisMon/2), canvasHeight >> 1, fontSize, currentYear, wThisMon);
				}
			} else {
				// show year only
				if (prevYear !== currentYear) {
					const yearFirst = new Date(yd);
					yearFirst.setMonth(0);
					const relDayCount = Math.floor((yd - yearFirst) / 86400000);
					const daysToCenter = 183 - relDayCount;
					
					g.save();
					g.font = (fontSize >> 1)+'px monospace';
					const yearLabelX = x + daysToCenter * wDate;
					g.fillText(currentYear, yearLabelX-1, fontSize >> 1);
					g.restore();
				}
			}

			x += wThisMon;
			prevYear = currentYear;
			if (x >= canvasWidth) { break; }
		}
		g.restore();
	}

	drawMonSubDays(g, canvasWidth, baseX, h, nDays, widthOfDate, fontSize, firstDate) {
		const g_thresh = fontSize >> 2;
		const d_thresh = fontSize;
		const show_d = widthOfDate > d_thresh;
		const show_long = widthOfDate > (d_thresh << 2);
		const barH = show_d ? (h >> 2) : (h >> 3);
		
		const widthOfHour = widthOfDate / 24.0;
		const hourAlpha = Math.max(0, Math.min(widthOfHour / window.devicePixelRatio / 10.0 - 0.25, 1));
		const show_hour = hourAlpha > 0.1;
		const hourbar_h = (fontSize >> 2);

		const halfF = (fontSize >> 1);
		g.font = halfF+'px monospace';

		var x = baseX;
		const halfY = h >> 1;
		for (var i = 0;i < nDays;++i) {
			if ( (x+widthOfDate) >= 0 && x <= canvasWidth) {
				if (i > 0 && widthOfDate > g_thresh) {
					g.fillRect(Math.floor(x+0.4), h-barH, 1, barH);
				}

				if (show_d) {
					const lab = show_long ? format_date_bulk(firstDate, i+1) : pad02(i+1);
					
					if (show_hour) {
						g.save();
						g.globalAlpha = hourAlpha;
						for (var j = 1;j < 24;++j) {
							g.fillRect(x + j*widthOfHour, h - hourbar_h-1, 1, hourbar_h);
						}
						g.restore();
					}

					g.fillText( lab,
					x + (widthOfDate>>1), halfY + halfF + (halfF>>2));
				}
			}

			x += widthOfDate;
		}
		
		return show_d;
	}

	countDatesInMonth(d0, monthsOffset) {
		const d1 = new Date(d0 - 0);
		d1.setMonth( d0.getMonth() + monthsOffset );
		d1.setDate(1);
		d1.setHours(0);
		d1.setMinutes(0);
		d1.setSeconds(0);

		const d2 = new Date(d1 - 0);
		d2.setMonth( d1.getMonth() + 1 );

		return Math.floor( (d2-d1) / 86400000 );
	}

	drawMonthLabel(g, index, x ,y, fontSize, fullYear, areaWidth) {
		const half = fontSize >> 1;

		var showLevel = 2;
		if (areaWidth < (fontSize*6)) { --showLevel }

		const nameList = (showLevel===2) ? kDefaultMonthNames : kDefaultShortMonthNames;
		const mname = nameList[ index ];

		g.font = fontSize+'px monospace';
		g.fillText(mname, x, y - (half >> 1) - window.devicePixelRatio);

		g.font = half+'px monospace';
		if (showLevel === 2) {
			g.fillText(fullYear + "-" + pad02(index+1), x, y + half);
		} else {
			g.fillText(fullYear, x, y + half);
		}
	}
}

function pad02(i) {
	if (i < 10) { return '0'+i; }
	return i;
}

function format_date_full(d) {
	return d.getFullYear() + '-' + pad02( d.getMonth()+1 ) + '-' + pad02( d.getDate() ) + ' ' + 
	 pad02( d.getHours() ) + ':' + pad02( d.getMinutes() ) + ':' + pad02( d.getSeconds() );
}

function format_date_bulk(d, dateLabelNum) {
	return d.getFullYear() + '-' + pad02( d.getMonth()+1 ) + '-' + pad02( dateLabelNum );
}

export { format_date_full };
export default TimeSlider;