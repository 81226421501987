
class Vec4 {
	constructor(_x, _y, _z, _w) {
		if (!_w && _w !== 0) { _w = 1; }
		this.x = _x || 0;
		this.y = _y || 0;
		this.z = _z || 0;
		this.w = _w;
	}

	add3(v) {
		this.x += v.x;
		this.y += v.y;
		this.z += v.z;
		return this;
	}

	sub3(v) {
		this.x -= v.x;
		this.y -= v.y;
		this.z -= v.z;
		return this;
	}

	diff(fromV, toV) {
		this.x = toV.x - fromV.x;
		this.y = toV.y - fromV.y;
		this.z = toV.z - fromV.z;
		this.w = 1;
		
		return this;
	}

	turnLeft() {
		// rotate around Z
		const oldX = this.x;
		this.x = this.y;
		this.y = -oldX;
		
		return this;
	}

	smul3(k) {
		this.x *= k;
		this.y *= k;
		this.z *= k;
	
		return this;
	}

	applyM(v1, m) {
		//            | 11 12 13 14
		//            | 21 22 23 24
		//            | 31 32 33 34
		//           m| 41 42 43 44
		// v1[x,y,z,w]| [this]

		this.x = m._11*v1.x  +  m._21*v1.y  +  m._31*v1.z  +  m._41*v1.w;
		this.y = m._12*v1.x  +  m._22*v1.y  +  m._32*v1.z  +  m._42*v1.w;
		this.z = m._13*v1.x  +  m._23*v1.y  +  m._33*v1.z  +  m._43*v1.w;
		this.w = m._14*v1.x  +  m._24*v1.y  +  m._34*v1.z  +  m._44*v1.w;

		return this;
	}
	
	applyRotationOnly(v1, m) {
		//            | 11 12 13  0
		//            | 21 22 23  0
		//            | 31 32 33  0
		//           m|  0  0  0  1
		// v1[x,y,z,w]| [this]

		this.x = m._11*v1.x  +  m._21*v1.y  +  m._31*v1.z;
		this.y = m._12*v1.x  +  m._22*v1.y  +  m._32*v1.z;
		this.z = m._13*v1.x  +  m._23*v1.y  +  m._33*v1.z;
		this.w = m._44*v1.w;

		return this;
	}

	copyFrom(src) {
		this.x = src.x;
		this.y = src.y;
		this.z = src.z;
		this.w = src.w;
		
		return this;
	}

	calcLen2() {
		return Math.sqrt( this.x*this.x + this.y*this.y );
	}

	calcLen3() {
		return Math.sqrt( this.x*this.x + this.y*this.y + this.z*this.z );
	}

	normalize2() {
		const len = this.calcLen2();
		this.x /= len;
		this.y /= len;
		return this;
	}

	normalize3() {
		const len = this.calcLen3();
		this.x /= len;
		this.y /= len;
		this.z /= len;
		return this;
	}

	dump() {
		console.log(
			'(%c' +this.x+ ', ' +this.y+ ', %c' +this.z+ ', ' +this.w+ ')',
			'color: #19a', ''
		);
	}
	
	static xp2(A, B, C) {
		const x1 = B.x - A.x;
		const y1 = B.y - A.y;
		
		const x2 = C.x - B.x;
		const y2 = C.y - B.y;
		
		return x1*y2 - y1*x2;
	}
};

class Mat4 {
	constructor() {
		this.ident();
	}

	copyFrom(m) {
		this._11 = m._11;  this._12 = m._12;  this._13 = m._13;  this._14 = m._14;
		this._21 = m._21;  this._22 = m._22;  this._23 = m._23;  this._24 = m._24;
		this._31 = m._31;  this._32 = m._32;  this._33 = m._33;  this._34 = m._34;
		this._41 = m._41;  this._42 = m._42;  this._43 = m._43;  this._44 = m._44;

		return this;
	}

	exportGL(a) {
		a[0]  = this._11; a[1]  = this._12; a[2]  = this._13; a[3]  = this._14; 
		a[4]  = this._21; a[5]  = this._22; a[6]  = this._23; a[7]  = this._24; 
		a[8]  = this._31; a[9]  = this._32; a[10] = this._33; a[11] = this._34; 
		a[12] = this._41; a[13] = this._42; a[14] = this._43; a[15] = this._44;
		return this;
	}

	makeTranspose(s) {
		this._11 = s._11;  this._12 = s._21;  this._13 = s._31;  this._14 = s._41; 
		this._21 = s._12;  this._22 = s._22;  this._23 = s._32;  this._24 = s._42; 
		this._31 = s._13;  this._32 = s._23;  this._33 = s._33;  this._34 = s._43; 
		this._41 = s._14;  this._42 = s._24;  this._43 = s._34;  this._44 = s._44;
		return this;
	}

	ident() {
		this._11 = 1; this._12 = 0; this._13 = 0; this._14 = 0;
		this._21 = 0; this._22 = 1; this._23 = 0; this._24 = 0;
		this._31 = 0; this._32 = 0; this._33 = 1; this._34 = 0;
		this._41 = 0; this._42 = 0; this._43 = 0; this._44 = 1;
		return this;
	}

	smul(k) {
		this._11 *= k; this._12 *= k; this._13 *= k; this._14 *= k;
		this._21 *= k; this._22 *= k; this._23 *= k; this._24 *= k;
		this._31 *= k; this._32 *= k; this._33 *= k; this._34 *= k;
		this._41 *= k; this._42 *= k; this._43 *= k; this._44 *= k;
		return this;
	}

	mul(m1, m2) {
		
		//          m2 11 12 13 14
		//             21 22 23 24
		//             31 32 33 34
		// m1        x 41 42 43 44
		// 11 12 13 14|-----------
		// 21 22 23 24|
		// 31 32 33 34|  [ this ]
		// 41 42 43 44|
		
		this._11 = m1._11 * m2._11  +  m1._12 * m2._21  +  m1._13 * m2._31  +  m1._14 * m2._41;
		this._21 = m1._21 * m2._11  +  m1._22 * m2._21  +  m1._23 * m2._31  +  m1._24 * m2._41;
		this._31 = m1._31 * m2._11  +  m1._32 * m2._21  +  m1._33 * m2._31  +  m1._34 * m2._41;
		this._41 = m1._41 * m2._11  +  m1._42 * m2._21  +  m1._43 * m2._31  +  m1._44 * m2._41;
		
		this._12 = m1._11 * m2._12  +  m1._12 * m2._22  +  m1._13 * m2._32  +  m1._14 * m2._42;
		this._22 = m1._21 * m2._12  +  m1._22 * m2._22  +  m1._23 * m2._32  +  m1._24 * m2._42;
		this._32 = m1._31 * m2._12  +  m1._32 * m2._22  +  m1._33 * m2._32  +  m1._34 * m2._42;
		this._42 = m1._41 * m2._12  +  m1._42 * m2._22  +  m1._43 * m2._32  +  m1._44 * m2._42;
		
		this._13 = m1._11 * m2._13  +  m1._12 * m2._23  +  m1._13 * m2._33  +  m1._14 * m2._43;
		this._23 = m1._21 * m2._13  +  m1._22 * m2._23  +  m1._23 * m2._33  +  m1._24 * m2._43;
		this._33 = m1._31 * m2._13  +  m1._32 * m2._23  +  m1._33 * m2._33  +  m1._34 * m2._43;
		this._43 = m1._41 * m2._13  +  m1._42 * m2._23  +  m1._43 * m2._33  +  m1._44 * m2._43;

		this._14 = m1._11 * m2._14  +  m1._12 * m2._24  +  m1._13 * m2._34  +  m1._14 * m2._44;
		this._24 = m1._21 * m2._14  +  m1._22 * m2._24  +  m1._23 * m2._34  +  m1._24 * m2._44;
		this._34 = m1._31 * m2._14  +  m1._32 * m2._24  +  m1._33 * m2._34  +  m1._34 * m2._44;
		this._44 = m1._41 * m2._14  +  m1._42 * m2._24  +  m1._43 * m2._34  +  m1._44 * m2._44;
		
		return this;
	}

	translate2(x, y) {
		this._41 = x;
		this._42 = y;
		return this;
	}

	translateZ(d) {
		this._43 = d;
		return this;
	}
	
	scale2(s) {
		this.ident();
		this._11 = s;
		this._22 = s;
		this._33 = 1;
		return this;
	}

	rotX(rad) {
		const CS = Math.cos(rad);
		const SN = Math.sin(rad);

		this._22 =  CS; this._23 = SN;
		this._32 = -SN; this._33 = CS;
		return this;
	}

	rotY(rad) {
		const CS = Math.cos(rad);
		const SN = Math.sin(rad);

		this._11 = CS; this._13 = -SN;
		this._31 = SN; this._33 =  CS;
		return this;
	}

	rotZ(rad) {
		const CS = Math.cos(rad);
		const SN = Math.sin(rad);

		this._11 =  CS; this._12 = SN;
		this._21 = -SN; this._22 =  CS;
		return this;
	}
};

function test_assert(title, got, expected) {
	if (got === expected) {
		console.log(title + "%c o %c|" + got, 'color: #6d7', '');
		return true;
	} else {
		console.log(title + "%cBAD%c|" + got + ' <> ' + expected, 'background-color: red;color:#FF0', '');
		return false;
	}
}

function selfTest() {
	const v1 = new Vec4();
	const v2 = new Vec4(1,2,3,0);

	test_assert("Vector initialize 1(X)", v1.x, 0);
	test_assert("Vector initialize 1(Y)", v1.y, 0);
	test_assert("Vector initialize 1(Z)", v1.z, 0);
	test_assert("Vector initialize 1(W)", v1.w, 1);

	test_assert("Vector initialize 2(X)", v2.x, 1);
	test_assert("Vector initialize 2(Y)", v2.y, 2);
	test_assert("Vector initialize 2(Z)", v2.z, 3);
	test_assert("Vector initialize 2(W)", v2.w, 0);
	
	const m1 = new Mat4();
	const m2 = new Mat4();
	// ----------------------
	var m3 = new Mat4();
	m3.smul(2);

	var mM = new Mat4();
	mM.mul(m1, m2);

	var mM2 = new Mat4();
	mM2.mul(m1, m3);
	// ----------------------

	test_assert("[E*E] _11" , mM._11, 1);
	test_assert("[E*E] _22" , mM._22, 1);
	test_assert("[E*E] _33" , mM._33, 1);
	test_assert("[E*E] _44" , mM._44, 1);
	test_assert("[E*E] _12" , mM._12, 0);
	test_assert("[E*E] _23" , mM._23, 0);
	test_assert("[2E] _11"  , m3._11, 2);
	test_assert("[2E] _22"  , m3._22, 2);
	test_assert("[E*2E] _11", mM2._11, 2);
	test_assert("[E*2E] _22", mM2._22, 2);
	
	const vP = new Vec4(0, 1, 0);
	var vRotated = new Vec4(0, 1, 0);
	var mRot = new Mat4();
	mRot.rotX(Math.PI*0.25);
	
	vRotated.applyM(vP, mRot);
	
	const nx = vRotated.y;
	const nz = vRotated.z;
	console.log((nx-nz) < 0.00001);
}

// ----------------------------------------------
const qmath = {
	Vec4: Vec4,
	Mat4: Mat4,
	selfTest: selfTest
};
export default qmath;