import React from 'react';
import { useDrop } from 'react-dnd'
import DraggableHead from './DraggableHead'

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

function renderButtonsIf(props) {
	function handler() {
		if (props.onAddButton) { props.onAddButton(); }
	}
	
	if (parseInt(props.showButton) === 1) {
		return <IconButton onClick={handler} size="small" className="mm-loader-attribute-add-button"><AddIcon fontSize="small" /></IconButton> ;
	}
	
	return '';
}

function DroppableCell(props) {
	const [{ isOver, attrName }, drop] = useDrop({
		accept: DraggableHead.ItemType,
		drop: function(droppedItem){
			if (props.onDrop) {  props.onDrop(droppedItem, parseInt(props.columnIndex,10) );  }
		},
		collect: function(monitor) {
			const item = monitor.getItem();
			return {
				isOver: !!monitor.isOver(),
				attrName: item ? item.attrName : null
			};
		}
	})

	return ( <th data-is-additional={props.isAdditional} className={ isOver ? 'mm-drop-target-active mm-drop-accept-'+attrName : '' } ref={drop}>{ props.children }{ renderButtonsIf(props) }</th> );
}

export default DroppableCell;