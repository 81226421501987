import React, { useCallback } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import './ExpressionForm.css'

function ExpressionForm(props) {
	const click_handler = useCallback( (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (props.onClearClick) {
			props.onClearClick();
		}

	} , [props.onClearClick]);

	return (<div onClick={ props.onClick } className="mm-expform-outer">
		<span className="mm-expform-inner">{ props.current ? props.current.toString() : 'フィルタなし'}</span>
		<span className="mm-expform-controls">
			<IconButton size="small" onClick={ click_handler }><CancelIcon fontSize="small" /></IconButton>
		</span>
	</div>);
}

export default ExpressionForm;