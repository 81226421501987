import LM_SHADER_SOURCES from './Shaders'
import qmath from './qmath'
import RendererBase from './renderer-base'
import { pickAttr, pickUniform } from './renderer-utils';

// const kTestLocation = {lat:35.6978434, lng:139.7728504};
const kNormalCompositionMode = 1;
const kAddCompositionMode    = 2;

const kDirTexSize = 64;
const USE_SECOND_TEXTURE = false;

const kPaletteCapacity = 128;

class LMRenderer extends RendererBase {
	constructor(targetCanvas) {
		super(targetCanvas);

		this.gl = null;
		this.shaderCollection = {};
		this.currentShaderSet = null;
		this.lineShaderSet = null;
		this.currentMarkerTexture = null;
		this.secondTexture = null;
		this.objectList = [];

		this.posBuffer   = new BufferSet();
		this.cPosBuffer  = new BufferSet();
		this.uvBuffer    = new BufferSet();
		this.relUvBuffer = new BufferSet();
		this.colorBuffer = new BufferSet();
		this.flagBuffer  = new BufferSet();
		// テイル色ピックアップ用のバッファ
		this.paletteBuffer = new BufferSet();

		this.compositionMode = kNormalCompositionMode;
		this.dirShiftAmount = 0;
		this.useAngle = false;
		this.useTail = false;
		this.tailDuration = 0;
		this.currentTime = 0;

		this.drawJob = {
			drawnCount: 0,
			numNext: 0
		};
	}
	
	setCurrentTime(t) {
		this.currentTime = t;
	}

	setCompositionMode(mode) {
		this.compositionMode = mode;
	}

	setUseAngle(u) {
		this.useAngle = u;
	}

	setUseTail(u) {
		this.useTail = u;
	}

	setTailDuration(d) {
		this.tailDuration = d;
	}
	
	setDirShiftAmount(a) {
		this.dirShiftAmount = a;
	}

	clearObjectList() {
		this.objectList.length = 0;
	}

	referObjectList() {
		return this.objectList;
	}
	
	setMarkerTexture(t) {
		this.currentMarkerTexture = t;
	}
	
	referMarkerTexture() {
		return this.currentMarkerTexture;
	}

	initGL() {
		if (this.gl) {return;}

		const gl = this.targetCanvas.getContext('webgl', { antialias: false });
		this.gl = gl;
		if (USE_SECOND_TEXTURE) {
			this.secondTexture = generateHighlightTexture(gl);
		}

		this.currentShaderSet = this.buildMainShader(gl);
		this.lineShaderSet = this.buildLineShader(gl);
	}

	buildMainShader(gl) {
		const testShader = this.buildShaderByName(gl, 'test-tex');
		this.pickMainShaderParams(gl, testShader);
		
		const kBufferCapacity = 192*256;
		this.generateMainShaderBuffers(gl, kBufferCapacity);

		// test coords
		writeTestCoords(this.posBuffer.raw, this.colorBuffer.raw, this.uvBuffer.raw);

		return testShader;
	}

	buildLineShader(gl) {
		const lineShader = this.buildShaderByName(gl, 'line');
		this.pickLineShaderParams(gl, lineShader);
		return lineShader;
	}

	// Shader params (main) ---------------------
	pickMainShaderParams(gl, shaderSet) {
		pickAttr(gl, shaderSet, 'position'); 
		pickAttr(gl, shaderSet, 'centerPos'); 
		pickAttr(gl, shaderSet, 'uv'); 
		pickAttr(gl, shaderSet, 'rel_uv'); 
		pickAttr(gl, shaderSet, 'flags');
		pickUniform(gl, shaderSet, 'viewTrans');
		pickUniform(gl, shaderSet, 'boundsThresh');
	}

	generateMainShaderBuffers(gl, capacity) {
		generateVertexBuffer(gl, this.posBuffer  , capacity, 2);
		generateVertexBuffer(gl, this.cPosBuffer , capacity, 2);
		generateVertexBuffer(gl, this.uvBuffer   , capacity, 2);
		generateVertexBuffer(gl, this.relUvBuffer, capacity, 2, initRelUVBuffer);
		generateVertexBuffer(gl, this.colorBuffer, capacity, 4);
		generateFlagsBuffer( gl, this.flagBuffer , capacity);

		generateVertexBuffer(gl, this.paletteBuffer, kPaletteCapacity, 4);
	}

	enableMainShaderBuffers(gl, shaderSet) {
		enableArrayBuffer(gl, shaderSet, this.posBuffer   , 'position');
		enableArrayBuffer(gl, shaderSet, this.cPosBuffer  , 'centerPos');
		enableArrayBuffer(gl, shaderSet, this.uvBuffer    , 'uv');
		enableArrayBuffer(gl, shaderSet, this.relUvBuffer , 'rel_uv');
		enableArrayBuffer(gl, shaderSet, this.flagBuffer  , 'flags');
	}

	// Shader params (line) ---------------------
	pickLineShaderParams(gl, shaderSet) {
		pickAttr(gl, shaderSet, 'position'); 
		pickAttr(gl, shaderSet, 'uv'); 
		pickUniform(gl, shaderSet, 'palette');
		pickUniform(gl, shaderSet, 'viewTrans');
	}

	enableLineShaderBuffers(gl, shaderSet) {
		enableArrayBuffer(gl, shaderSet, this.posBuffer   , 'position');
		enableArrayBuffer(gl, shaderSet, this.uvBuffer    , 'uv');
	}


	renewVertexBuffer() {
		const gl = this.gl;
		gl.bindBuffer(gl.ARRAY_BUFFER, this.posBuffer.vbo);
		gl.bufferSubData(gl.ARRAY_BUFFER, 0, this.posBuffer.raw); 

		if (this.useAngle) {
			gl.bindBuffer(gl.ARRAY_BUFFER, this.cPosBuffer.vbo);
			gl.bufferSubData(gl.ARRAY_BUFFER, 0, this.cPosBuffer.raw); 
		}

		gl.bindBuffer(gl.ARRAY_BUFFER, this.uvBuffer.vbo);
		gl.bufferSubData(gl.ARRAY_BUFFER, 0, this.uvBuffer.raw); 

		gl.bindBuffer(gl.ARRAY_BUFFER, this.colorBuffer.vbo);
		gl.bufferSubData(gl.ARRAY_BUFFER, 0, this.colorBuffer.raw); 

		gl.bindBuffer(gl.ARRAY_BUFFER, this.flagBuffer.vbo);
		gl.bufferSubData(gl.ARRAY_BUFFER, 0, this.flagBuffer.raw); 
	}
	
	buildShaderByName(gl, name) {
		this.shaderCollection[name] = buildShaderPair(gl, name);
		return this.shaderCollection[name];
	}
	
	adjustViewport() {
		const gl = this.gl;
		if (gl) {
			gl.viewport(0, 0, this.size.width, this.size.height);
			const trans = makePixelPerfectTransform(this.size.width, this.size.height);
			const uL = this.lineShaderSet.attrs['viewTrans'];
			const u1 = this.currentShaderSet.attrs['viewTrans'];

			gl.useProgram(this.lineShaderSet.program);
			gl.uniformMatrix4fv(uL, false, trans);

			gl.useProgram(this.currentShaderSet.program);
			gl.uniformMatrix4fv(u1, false, trans);
		}
	}

	projectAndRender(map) {
		if (!map) { return; }
		if (!this.targetCanvas) { return; }
		
		this.updateProjection(map);
		this._updateScreenCoords();
		this.render();
		
		/*
		const z = map.getZoom();
		const worldSize = zoom_to_w(z);
		
		const sCoord = this._temp_lpt2;
		FastSphericalMercator.projectNormal(sCoord, kTestLocation);
		sCoord.x *= worldSize;
		sCoord.y *= worldSize;
*/
/*
		const sx = sCoord.x - pjCoord.x + oo.x;
		const sy = sCoord.y - pjCoord.y + oo.y;
*/
/*
		const g = this.targetCanvas.getContext('2d');

		g.clearRect(0, 0, 960, 480);

		g.fillStyle = '#0FF';
		g.fillRect(sx-1, sy-1, 3, 3);
		*/
	}

	_updateScreenCoords() {
		const g = this.projGrid;
		const ls = this.objectList;
		const useTail = this.useTail;

		g.calcForList(ls);
		if (useTail) {
			for (const o of ls) {
				if (o.tailLog) {
					g.calcForList(o.tailLog);
				}
			}
		}
	}

	_renderTile(canvas, map, cx, cy, tileSize) {
		if (!map || !this.targetCanvas) { return; }

		const cvW = this.targetCanvas.width  - 0;
		const cvH = this.targetCanvas.height - 0;

		// const z = map.getZoom();
		// const worldSize = zoom_to_w(z);
		
		const tileWX = tileSize.x * cx;
		const tileWY = tileSize.y * cy;

		const mgn = this.origin.screenCoord;
		const o = this.origin.projectedCoord;

		const leftX   = mgn.x + tileWX - o.x;
		const rightX  = leftX + tileSize.x;
		
		const topY    = mgn.y + tileWY - o.y;
		const bottomY = topY  + tileSize.y;
		if (rightX > 0 && leftX < cvW && bottomY > 0 && topY < cvH) {
			this._copyOffscreen(canvas, leftX, topY, tileSize);
		}
	}
	
	_copyOffscreen(dest, sx, sy, tileSize) {
		const g = dest.getContext('2d');
		const TW = tileSize.x;
		const TH = tileSize.y;

//		g.clearRect(0, 0, TW, TH);
//		g.clearRect(0, 0, 1, 1);

		const w = this.targetCanvas.width | 0;
		const h = this.targetCanvas.height | 0;
		const restW = w - sx - TW;
		const restH = h - sy - TH;
//		console.log(restW, restH);
		if (sx >= 0 && sy >= 0 && restW >= 0 && restH >= 0) {
//			this.targetCanvas.getContext('2d').getImageData(sx, sy, TW,TH);
		} else {
		/*
			g.drawImage(this.targetCanvas,
				sx, sy, tileSize.x, tileSize.y,
				0, 0, tileSize.x, tileSize.y);
				*/
		}
		
		//const idata = g.getImageData(0, 0, TW, TH);
		//g.putImageData(idata, 0, 0);
		g.drawImage(this.targetCanvas,
			sx, sy, TW, TH,
			0, 0, TW, TH);
	}

	clearScreen() {
		const gl = this.gl;
		
		gl.clearColor(0.0, 0.0, 0.0, 0.0);
		gl.clear(gl.COLOR_BUFFER_BIT);
	}

	render() {
		const gl = this.gl;
		this.setupRenderStates(gl);
		this.clearScreen();

		if (this.useTail && this.tailDuration > 0) {
			gl.useProgram(this.lineShaderSet.program);
			this.sendLineUniforms(gl, this.lineShaderSet);
			this.enableLineShaderBuffers(gl, this.lineShaderSet);
			this.resetDrawJob();
			this.renderTails(gl);
		}

		gl.useProgram(this.currentShaderSet.program); 
		this.enableMainShaderBuffers(gl, this.currentShaderSet);
		this.prepareTextureMapping(gl);

		this.resetDrawJob();
		for (let i = 0;i < 9999;++i) {
			const nPolygons = this.fillNextJob();

			if (nPolygons > 0) {
				this.renewVertexBuffer();
				gl.drawArrays(gl.TRIANGLES, 0, nPolygons * 3);
			} else {
				if (nPolygons < 0) {
					break;
				}
			}
		}


//		console.log(nPolygons, this.posBuffer.raw.length / 6);
	}

	renderTails(gl) {
		for (var i = 0;i < 9999;++i) {
			const nLines = this.fillLineJob(this.tailDuration);
			
			if (nLines > 0) {
				this.renewVertexBuffer();
				gl.drawArrays(gl.LINES, 0, nLines * 2);
			} else {
				break;
			}	
		}

	}

	setupRenderStates(gl) {
		gl.enable(gl.BLEND);
		gl.disable(gl.DEPTH_TEST);

		if (this.compositionMode === kAddCompositionMode) {
			// ADD MODE
			gl.blendFuncSeparate(gl.SRC_ALPHA, gl.ONE, gl.SRC_ALPHA, gl.ONE);
		} else {
			gl.blendFuncSeparate(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA, gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
		}
	}

	clear() {
		const gl = this.gl;
		gl.clearColor(0.0, 0.0, 0.0, 0.0);
		gl.clear(gl.COLOR_BUFFER_BIT);
	}

	prepareTextureMapping(gl) {
		const t = this.currentMarkerTexture;
		if (t) {
			t.use(gl, this.currentShaderSet.program);
			this.setFragmentBounds(gl,
				t.getFragmentUMin() / 2,
				t.getFragmentVMin() / 2,
				t.getFragmentUMax() / 2,
				t.getFragmentVMax() / 2
			);
		}
		
		if (USE_SECOND_TEXTURE) {
			useMarkerTexture(gl, this.secondTexture, this.currentShaderSet.program);
		}
	}

	setFragmentBounds(gl, u1, v1, u2, v2) {
		const loc = this.currentShaderSet.attrs['boundsThresh'];
		gl.uniform4fv(loc, [u1, v1, u2, v2]);
	}

	// DRAW JOB
	resetDrawJob() {
		this.drawJob.drawnCount = 0;
		this.drawJob.numNext = 0;
	}
	
	fillNextJob() {
		const selmode = this.selectionMode;
		const selmode_v = (selmode === RendererBase.SelectionMode.Visibility);
		const flagVal = (selmode === RendererBase.SelectionMode.Highlight) ? 10 : 0;
		
		const j = this.drawJob;
		const capacity = Math.floor(this.posBuffer.count / 3);
		const src = this.objectList;
		j.numNext = 0;
		
		let triW = 32;
		let triH = 32;
		let uSpan  = 0.5;
		let vSpan  = 0.5;
		let uSpan2 = 1;
		let vSpan2 = 1;
		let cx = 0;
		let cy = 0;

		if (this.currentMarkerTexture) {
			const chipData = this.currentMarkerTexture.chipData;
			triW = chipData.width * 2;
			triH = chipData.height * 2;
			uSpan  = chipData.uSpan;
			vSpan  = chipData.vSpan;
			uSpan2 = uSpan * 2;
			vSpan2 = vSpan * 2;

			cx = chipData.cx;
			cy = chipData.cy;
		}

		let rest = src.length - j.drawnCount;
		let nAdded = 0;
		if (rest > capacity) { rest = capacity; }
		if (rest < 1) {
			return -1;
		} else {
			const baseIndex = j.drawnCount;

			let pI = 0;
			let cI = 0;
			let tI = 0;
			let fI = 0;
			const pLS = this.posBuffer.raw;
			const cLS = this.cPosBuffer.raw;
			const tLS = this.uvBuffer.raw;
			const fLS = this.flagBuffer.raw;

			const dSft = this.dirShiftAmount;
			for (let i = 0;i < rest;++i) {
				const record = src[baseIndex + i];
				// ------ 非表示処理 INVISIBLE ------
				if (selmode_v && !record.selected) {
					continue;
				}
				if (record.filterResult === false) {
					continue;
				}

				// center of the triangle
				let triCX = record.sx;
				let triCY = record.sy;
				if (dSft) {
					triCX -= Math.sin(record.direction) * dSft;
					triCY -= Math.cos(record.direction) * dSft;
				}
				// top-left of the triangle
				let sx = triCX - cx;
				let sy = triCY - cy;

				const u1 = record.chipX * uSpan;
				const v1 = record.chipY * vSpan;
				const u2 = u1 + uSpan2;
				const v2 = v1 + vSpan2;
				
				// fill vertex position buffer
				pLS[pI++] = sx     ;  pLS[pI++] = sy     ;
				pLS[pI++] = sx+triW;  pLS[pI++] = sy     ;
				pLS[pI++] = sx     ;  pLS[pI++] = sy+triH;
				
				if (this.useAngle) {
					// fill center position buffer
					cLS[cI++] = triCX; cLS[cI++] = triCY;
					cLS[cI++] = triCX; cLS[cI++] = triCY;
					cLS[cI++] = triCX; cLS[cI++] = triCY;
				}
				
				// fill texture coord buffer
				tLS[tI++] = u1;  tLS[tI++] = v1;
				tLS[tI++] = u2;  tLS[tI++] = v1;
				tLS[tI++] = u1;  tLS[tI++] = v2;
				
				// fill flag buffer
				const fv = (record.selected ? 0 : flagVal) + (this.useAngle ? record.direction : 0);
				fLS[fI++] = fv;
				fLS[fI++] = fv;
				fLS[fI++] = fv;
				
				++nAdded;
			}

			j.drawnCount += rest; // Include skipped record
			return nAdded; // Actual, NO include skipped!
		}
	}

	// テイル用パレット関連
	fillTailColorPalette(colorProvider) {
		if ( !(colorProvider.writeOutMarkerIndexColor) ) { return; }
		const arr = this.paletteBuffer.raw;
		for (var i = 0;i < kPaletteCapacity;++i) {
			colorProvider.writeOutMarkerIndexColor(arr, i*4, i);
		}
	}

	sendLineUniforms(gl, shaderSet) {
		const pal = shaderSet.attrs.palette;
		if (pal !== null) {
			gl.uniform4fv(pal, this.paletteBuffer.raw);
		}
	}

	fillLineJob(timeLimit) {
		const selmode = this.selectionMode;
		const selmode_v = (selmode === RendererBase.SelectionMode.Visibility);
		const flagVal = (selmode === RendererBase.SelectionMode.Highlight) ? 10 : 0;

		const j = this.drawJob;
		// ↓ bufferのcountは(x,y)セットの数、バッファサイズは次元*count
		//   segCapacityは線分の数
		const segCapacity = Math.floor(this.posBuffer.count / 2);

		const src = this.objectList;
		const numSrc = src.length;

		var baseIndex = j.drawnCount;
		var wp = 0;

		var nRead = 0;
		var nDrawnSegments = 0;

		// バッファ参照
		const pLS = this.posBuffer.raw;
		var pI = 0; // ポジション書き込み位置

		const tLS = this.uvBuffer.raw;
		var tI = 0; // UV書き込み位置

		for (var i = 0;i < segCapacity;++i) {
			const srcIndex = baseIndex+i;
			if (srcIndex >= numSrc) { break; }
			const record = src[baseIndex+i];
			const tailLog = record.tailLog;
			const tailLen = tailLog ? tailLog.length : 0;
			const allSegments = tailLen; // テイル本体: tailLen-1 + マーカーから1

			if (allSegments > (segCapacity - nDrawnSegments)) {
				// キャパシティ不足なら打ち切り
				break;
			}

			// nReadは表示・非表示にかかわらず進行
			++nRead;

			// ------ 非表示処理 INVISIBLE ------
			if (selmode_v && !record.selected) { continue; } // 選択外
			if (record.filterResult === false) { continue; } //	フィルタ
			if (0 === tailLen) { continue; } // テイル配列無効

			const firstTail = tailLog[0];
			const originTime = this.currentTime;
			const markerTime = record.attrList[2]; // #2 = time attribute
			const palIndex = record.chipX;
			pLS[pI++] = record.sx;
			pLS[pI++] = record.sy;
			tLS[tI++] = Math.abs(markerTime - originTime) / timeLimit;
			tLS[tI++] = palIndex;

			pLS[pI++] = firstTail.sx;
			pLS[pI++] = firstTail.sy;
			tLS[tI++] = Math.abs(firstTail.time - originTime) / timeLimit;
			tLS[tI++] = palIndex;
			++nDrawnSegments;

			for (var s = 1;s < allSegments;++s) {
				const v0 = tailLog[s-1];
				const v1 = tailLog[s  ];

				pLS[pI++] = v0.sx;
				pLS[pI++] = v0.sy;
				tLS[tI++] = Math.abs(v0.time - originTime) / timeLimit;
				tLS[tI++] = palIndex;
	
				pLS[pI++] = v1.sx;
				pLS[pI++] = v1.sy;
				tLS[tI++] = Math.abs(v1.time - originTime) / timeLimit;
				tLS[tI++] = palIndex;

//				console.log(v0.time, v1.time, v0);
				++nDrawnSegments;
			}
		}

		j.drawnCount += nRead;
		return nDrawnSegments;
	}
};

function BufferSet() {
	this.count = 0;
	this.dimension = 1;
	this.raw   = null;
	this.vbo   = null;
}


function buildShaderPair(gl, name) {
	const vs_src = LM_SHADER_SOURCES[name + '.vs'];
	const fs_src = LM_SHADER_SOURCES[name + '.fs'];

	if (vs_src && fs_src) {
		const v_shader = compileShaderSource(gl, vs_src, false);
		const f_shader = compileShaderSource(gl, fs_src, true);
		
		if (v_shader && f_shader) {
			const prog = gl.createProgram();
			gl.attachShader(prog, v_shader);
			gl.attachShader(prog, f_shader);
			gl.linkProgram(prog);

			return {
				vertexShader: v_shader,
				fragmentShader: f_shader,
				program: prog,
				attrs: {}
			} ;
		}
		
		return null;
	} else {
		console.log("**!Bug! bad shader**");
		return null;
	}
}

function makePixelPerfectTransform(w, h) {
	const m = new qmath.Mat4();
	m._11 = 2/w;
	m._22 = -2/h;
	m.translate2(-1, 1);
	// console.log(m);
	const a = new Array(16);
	m.exportGL(a);
	return a;
}

function compileShaderSource(gl, src, is_fs) {
	const sh = gl.createShader( is_fs ? gl.FRAGMENT_SHADER : gl.VERTEX_SHADER );
	gl.shaderSource( sh, src );
	gl.compileShader( sh );
	
	if (gl.getShaderParameter(sh, gl.COMPILE_STATUS)) {
		return sh;
	}

	// error
	console.log( '%c'+ gl.getShaderInfoLog(sh), 'border-left:3px solid #C09;padding-left:4px' );

	return null;
}

function generateVertexBuffer(gl, outObj, count, dimension, initializer) {
	const vbo = gl.createBuffer();
	const  a = new Float32Array(count * dimension);

	if (initializer) { initializer(a); }

	gl.bindBuffer(gl.ARRAY_BUFFER, vbo);
	gl.bufferData( gl.ARRAY_BUFFER, a, gl.DYNAMIC_DRAW );
	gl.bindBuffer( gl.ARRAY_BUFFER, null );
	
	outObj.count = count;
	outObj.dimension = dimension;
	outObj.raw = a;
	outObj.vbo = vbo;
	
	return outObj;
}

function generateFlagsBuffer(gl, outObj, count) {
	const vbo = gl.createBuffer();
	const a = new Float32Array(count);

	gl.bindBuffer(gl.ARRAY_BUFFER, vbo);
	gl.bufferData( gl.ARRAY_BUFFER, a, gl.DYNAMIC_DRAW );
	gl.bindBuffer( gl.ARRAY_BUFFER, null );

	outObj.count = count;
	outObj.dimension = 1;
	outObj.raw = a;
	outObj.vbo = vbo;
	
	return outObj;
}

function enableArrayBuffer(gl, shaderSet, bufferSet, name) {
	if( void(0) === shaderSet.attrs[name] ) {return;}

	gl.bindBuffer(gl.ARRAY_BUFFER, bufferSet.vbo);
	gl.enableVertexAttribArray(shaderSet.attrs[name]);
	gl.vertexAttribPointer(shaderSet.attrs[name], bufferSet.dimension, gl.FLOAT, false, 0, 0 );
}

function initRelUVBuffer(arr) {
	const nVertices = arr.length >> 1;
	const nTriangles = Math.floor(nVertices / 3);
	
	var vi = 0;
	for (var i = 0;i < nTriangles;++i) {
		arr[vi++] = 0; arr[vi++] = 0;
		arr[vi++] = 1; arr[vi++] = 0;
		arr[vi++] = 0; arr[vi++] = 1;
	}
}

// Highlight texture
function generateHighlightTexture(gl) {
	const cv = document.createElement('canvas');
	cv.width = kDirTexSize;
	cv.height = kDirTexSize;
	const g = cv.getContext('2d');

	const cx = cv.width >> 2;
	const cy = cv.height >> 2;
	renderHighlight(g, cx, cy, cx/2);

	return createDirectionMarkerTextureObject(gl, cv);
}

function renderHighlight(g, cx, cy, r) {
	g.save()

	const gradient = g.createRadialGradient(cx, cy, 1, cx, cy, r);
	gradient.addColorStop(0, '#FFF');
	gradient.addColorStop(1, 'rgba(0,0,0,0)');

	g.fillStyle = gradient;
	g.beginPath();
	g.arc(cx, cy, r, 0, Math.PI*2, false);
	g.fill();

	g.restore();
}

// Direction marker
function generateDirectionMarkerTexture(gl) {
	const cv = createDirectionMarkerSourceCanvas();
	return createDirectionMarkerTextureObject(gl, cv);
}

function createDirectionMarkerTextureObject(gl, sourceCanvas) {
	const t = gl.createTexture();

	gl.bindTexture(gl.TEXTURE_2D, t);
	gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, sourceCanvas);
	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
	gl.bindTexture(gl.TEXTURE_2D, null);

	return t;
}

function createDirectionMarkerSourceCanvas() {
	const cv = document.createElement('canvas');
	cv.width = kDirTexSize;
	cv.height = kDirTexSize;
	const g = cv.getContext('2d');

	const cx = cv.width >> 2;
	const cy = cv.height >> 2;
	const q = kDirTexSize >> 3;

	g.fillStyle = '#fff';
	g.strokeStyle = '#000';
	g.lineWidth = 2;
	
	g.beginPath();
	g.moveTo(cx    , cy - q);
	g.lineTo(cx + q, cy    );
	g.lineTo(cx    , cy + q);
	g.closePath();
	g.fill();
	g.stroke();

	return cv;
}

function useMarkerTexture(gl, texture, shaderProgram) {
	gl.activeTexture(gl.TEXTURE1);
	gl.bindTexture(gl.TEXTURE_2D, texture);
	gl.uniform1i(gl.getUniformLocation(shaderProgram, "uDSampler"), 1);
}

// TEST DRAWINGS
function writeTestCoords(rawPosArr, rawColorArr, rawUV) {
	rawPosArr[0] =  1;  rawPosArr[1] = 1;
	rawPosArr[2] = 65;  rawPosArr[3] = 1;
	rawPosArr[4] =  1;  rawPosArr[5] = 65;
	rawPosArr[6] = 65;  rawPosArr[7] = 65;
	
	if (rawUV) {
		const span = 0.25;
		rawUV[0] =    0; rawUV[1] =    0;
		rawUV[2] = span; rawUV[3] =    0;
		rawUV[4] =    0; rawUV[5] = span;
		rawUV[6] = span; rawUV[7] = span;
	}

	rawColorArr[ 0] = 1; rawColorArr[ 1] = 1; rawColorArr[ 2] = 0; rawColorArr[ 3] = 1;
	rawColorArr[ 4] = 1; rawColorArr[ 5] = 1; rawColorArr[ 6] = 0; rawColorArr[ 7] = 1;
	rawColorArr[ 8] = 1; rawColorArr[ 9] = 1; rawColorArr[10] = 0; rawColorArr[11] = 1;
	rawColorArr[12] = 1; rawColorArr[13] = 1; rawColorArr[14] = 0; rawColorArr[15] = 1;
}

export default LMRenderer;