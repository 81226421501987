import React from 'react';
import { useDrag } from 'react-dnd'

import Select from '@material-ui/core/Select';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import AttributeType from '../leafmob/md/AttributeType'


function DraggableHead(props) {
	const [{isDragging}, drag] = useDrag({
		item: {
			type: DraggableHead.ItemType,
			attrName: props.attrName
		},
		collect: monitor => ({
			isDragging: !!monitor.isDragging(),
		}),
	})

	var data_a = props.attrName;
	var innerForm = '';
	if (!props.label) {
		// additional attribute
		data_a = '*';
		innerForm = <div>
		 <TextField onChange={ props.onUserAttributeNameChange } size="small" label="Name" value={ props.attrName } /><br/>
		 { generateTypeList(props) } <IconButton onClick={ props.onRemoveClick } className="mm-additional-attr-remove" size="small"><RemoveIcon /></IconButton>
		</div> ;
	}

	return <div className="mm-draggable-head-item" data-attr-name={data_a} ref={drag}>{props.label}{innerForm}</div>
}

function generateTypeList(props) {
	return (<Select size="small" value={ props.dataType } onChange={ props.onUserAttributeTypeChange }>
		<MenuItem value={ AttributeType.STRING  }>string</MenuItem>
		<MenuItem value={ AttributeType.INTEGER }>int</MenuItem>
		<MenuItem value={ AttributeType.FLOAT   }>float</MenuItem>
		<MenuItem value={ AttributeType.CFLOAT }>float <br /><span className="mm-attribute-type-sub">+interpolation</span></MenuItem>
	</Select> );
}

DraggableHead.ItemType = 'draggable-table-head';

export default DraggableHead;