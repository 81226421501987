import { getMarkerSizingPresetName } from "../marker/MarkerSizingPreset";

function generateLayerSettingJSON(layer) {
	layer.rebuildOrderedAttributeNames();

	const obj = {
		config: {
			"marker-sizing": generateSizingSection(layer),
			"marker-coloring": generateColoringSection(layer),
			"filter": archiveFilter(layer)
		}
	};

	return obj;
}

function generateLayerAnnotationJSON(layer) {
	return layer.annotation || null;
}

function archiveFilter(layer) {
	if (!layer.filterExpression) {
		return null;
	}

	return layer.filterExpression.exportTree();
}

function generateColoringSection(layer) {
	const ret = {};
	const m = layer.markerSetting;
	
	if (m) {
		const num = m.primaryVariation;
		ret.bind = getBindAttrName(layer, layer.bindAttrIndex);
		ret.variation = num;
		ret.rule = layer.coloringKey;
		ret.legend = !!layer.legendVisibilities[0];
	}

	return ret;
}

function generateSizingSection(layer) {
	const ret = {};
	const m = layer.markerSetting;

	if (m) {
		const num = m.secondaryVariation;
		ret.bind = getBindAttrName(layer, layer.secondAttrIndex);
		ret.variation = num;
		ret.rule = getMarkerSizingPresetName( m.sizingType , true );
		ret.legend = !!layer.legendVisibilities[1];
	}

	return ret;
}

function getBindAttrName(layer, attrIndex) {
	if (attrIndex < 0) { return null; }

	const a = layer.orderedAttributeNames[ attrIndex ];
	return a || null;
}

export { generateLayerSettingJSON, generateLayerAnnotationJSON } ;