import leaflet from 'leaflet'
import { toolbase_init, toolbase_set_ev_parent, toolbase_make_button } from './map-tool-base'
const L = leaflet;

const SelectToolsControl = L.Control.extend({
	initialize: toolbase_init,
	setEventParent: toolbase_set_ev_parent,

	onAdd: function(map) {
		const div = document.createElement('div');
		div.className = 'leaflet-bar';
		
		this.peekButtonElement = this._addPickerIcon(div);
		this.peekButtonSelected = false;

		return div;
    },

	onRemove: function(map) {
		
    },
    
    _addPickerIcon: function(container) {
		return toolbase_make_button(
			container,
			'/images/sel-tool-icon.gif',
			'Peek',
			this._onPeekToolButtonClick.bind(this)
		);
    },

    _onPeekToolButtonClick(e) {
		e.stopPropagation();
		this.eventDispatcher.eventInvoke( SelectToolsControl.CLICK_EVENT, SelectToolsControl.PEEK_BUTTON );
    },

	isPeekButtonSelected() {
		return this.peekButtonSelected;
	},
	
	setPeekButtonSelected(s) {
		this.peekButtonSelected = s;
		this.peekButtonElement.setAttribute('class', s ? 'maptool-selected' : '');
	}
});

SelectToolsControl.CLICK_EVENT = 'SelToolClick';
SelectToolsControl.PEEK_BUTTON = 1;

export default SelectToolsControl;
