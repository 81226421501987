const TimeParseFlags = {
	TP_UNIX_SEC: 0x1,
	TP_UNIX_MS: 0x2
};

const mm_utils = {
	parseFieldTime: function(rawVal, flags) {
		let absTime = 0;

		// Any flag?
		if (flags) {
			// Unix time
			if (flags & TimeParseFlags.TP_UNIX_SEC) {
				return parseInt(rawVal, 10);
			} else if (flags & TimeParseFlags.TP_UNIX_MS) {
				return Math.floor(  parseInt(rawVal, 10) / 1000  );
			}
		}

		if (rawVal.indexOf('T') >= 0) {
			const iso_d = new Date(rawVal);
			absTime = Math.floor( iso_d / 1000 );
		} else if (rawVal.indexOf(':') >= 0) {
			var dateIndex    = readFieldDate(rawVal);
			var secondsInDay = readFieldHMSTime(rawVal);

			absTime = dateIndex + secondsInDay;
		} else {
			absTime = parseInt(rawVal, 10);
		}

		return absTime;
	}
};



const kDateRegExp = /([0-9]+)[-¥/]([0-9]+)[-¥/]([0-9]+)/ ;
const kTimeRegExp = /([0-9]+):([0-9]+)(:([0-9]+))?/ ;

function readFieldDate(rawVal) {
	if (kDateRegExp.exec(rawVal)) {
		// Has date ------------------------
		var yr = parseInt( RegExp['$1'] , 10);
		var mn = parseInt( RegExp['$2'] , 10);
		var dy = parseInt( RegExp['$3'] , 10);
		
		//                    v zero-base
		var dt = new Date(yr,   mn - 1  , dy);
		return Math.floor(dt.getTime() / 1000);
	} else {
		// No date -------------------------
		return 0;
	}
}

function readFieldHMSTime(rawVal) {
	var hh, mm, ss;
	ss = 0;
	
	if (kTimeRegExp.exec(rawVal)) {
		hh = parseInt(RegExp['$1'], 10);
		mm = parseInt(RegExp['$2'], 10);
		
		// Second(:SS) is optional
		var s_raw = RegExp['$4'];
		ss = s_raw ? parseInt(s_raw, 10) : 0;
	}
	
	return hh*3600 + mm*60 + ss;
}

export { TimeParseFlags } ;
export default mm_utils;