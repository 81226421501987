
class MarkerTexture {
	constructor(useAutoCanvas) {
		this.glTex = null;
		this.canvas = null;

		this.width = 256;
		this.height = 256;
		this.chipData = {
			width: 32,
			height: 32,
			uSpan: 0,
			vSpan: 0,
			cx: 6,
			cy: 6,
			validBounds: {
				minU: 0,
				minV: 0,
				maxU: 1,
				maxV: 1
			}
		};

		this.calcNumCols();
		this.calcUVSpan();
		
		if (useAutoCanvas) {
			this.canvas = document.createElement('canvas');
			this.canvas.width = this.width;
			this.canvas.height = this.height;
		}
	}

	setChipData(w, h, cx, cy, boundsData) {
		const d = this.chipData;
		if (d.width  !== w  ||
			d.height !== h  ||
			d.cx     !== cx ||
			d.cy     !== cy ) {

			d.width  = w;
			d.height = h;
			d.cx     = cx;
			d.cy     = cy;

			if (boundsData) {
				this.updateValidBounds(d.validBounds, w, h, boundsData);
			}

			this.calcNumCols();
			this.calcUVSpan();
		}
	}

	setChipBounds(boundsData) {
		const d = this.chipData;
		this.updateValidBounds(d.validBounds, d.width, d.height, boundsData);
	}

	updateValidBounds(outBounds, chipW, chipH, source) {
		outBounds.minU = source.minX / chipW;
		outBounds.minV = source.minY / chipH;

		outBounds.maxU = source.maxX / chipW;
		outBounds.maxV = source.maxY / chipH;
	}

	autoResize(desiredSize) {
		if (this.width !== desiredSize.width ||
			this.height !== desiredSize.height) {

			this.width = desiredSize.width;
			this.height = desiredSize.height;
			
			if (this.canvas) {
				this.canvas.width = this.width;
				this.canvas.height = this.height;
			}

			this.calcNumCols();
			this.calcUVSpan();
		}
	}

	calcNumCols() {
		this.cols = this.width  / this.chipData.width;
		this.rows = this.height / this.chipData.height;
	}

	calcUVSpan() {
		const c = this.chipData;
		c.uSpan = c.width  / this.width;
		c.vSpan = c.height / this.height;
		return c;
	}

	getFragmentUMin() {
		return this.chipData.validBounds.minU;
	}

	getFragmentUMax() {
		return this.chipData.validBounds.maxU;
	}

	getFragmentVMin() {
		return this.chipData.validBounds.minV;
	}

	getFragmentVMax() {
		return this.chipData.validBounds.maxV;
	}

	fromImage(gl, img) {
		if (!this.glTex) { this.glTex = gl.createTexture(); }
		const t = this.glTex;

		gl.bindTexture(gl.TEXTURE_2D, t);

		gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, img);
		gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
		gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
		gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
		gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);

		gl.bindTexture(gl.TEXTURE_2D, null);
	}
	
	fromSelfCanvas(gl) {
		this.fromImage(gl, this.canvas);
	}


	use(gl, shaderProgram) {
		gl.activeTexture(gl.TEXTURE0);
		gl.bindTexture(gl.TEXTURE_2D, this.glTex);
		gl.uniform1i(gl.getUniformLocation(shaderProgram, "uSampler"), 0);
	}
};

export default MarkerTexture;
