import zlibjs from 'zlibjs/bin/zip.min.js'
import AttributeType, { attrTypeToString } from '../leafmob/md/AttributeType';
import { format_date_full } from '../widgets/TimeSlider';

async function generateMovementCSV(layer) {
	if (!layer.data) {
		return null;
	}

	const amap = layer.attributeMapping;

	// 元データと同じ順で属性のリストを作成
	const colIndices = amap.makeColumnIndexList();
	colIndices.sort( (a,b) => { return a-b; } );

	const attrList = [];
	for (const c of colIndices) {
		const attrInfo = amap.byColumn(c);
		attrList.push(attrInfo);
	}

	const headerLine = generateHeaderLine(amap, attrList);
	const rows = [headerLine];

	// IDカラムは特別扱い
	const idAttr = amap.byName('id');
	const tsetList = layer.data.flattenList;
	for (const tset of tsetList) {
		await generateCSVofTset(rows, tset, attrList, idAttr);
	}

	return rows;
}

async function generateCSVofTset(outRows, tset, attrList, idAttr) {

	// レコード数を取得するため適当なTVを見る
	const anyTv = tset.flattenList[0];
	const n = anyTv.arr.length;

	const id = tset.ownerId;
	const rawTemp = new Array(attrList.length);
	for (var i = 0;i < n;++i) {
		var colIndex = 0;
		for (const a of attrList) {
			if (a === idAttr) {
				rawTemp[colIndex] = id;
			} else {				
				const tv = tset.attrs[ a.name ];
				const value = tv.arr[ i ];
				if (a.type === AttributeType.DATETIME) {
					rawTemp[colIndex] = format_date_full( new Date(value*1000) );
				} else {
					rawTemp[colIndex] = value;
				}
			}

			++colIndex;
		}
//		console.log( rawTemp.join(',') );
		outRows.push( rawTemp.join(',') );
	}
//	console.log(tset);
}

function generateHeaderLine(amap, attrList) {
	const parts = [];
	for (const a of attrList) {
		if (amap.isRequiredAttribute(a.name)) {
			parts.push(a.name);
		} else {
			parts.push(a.name + ':' + attrTypeToString(a.type));
		}

	}
	return parts.join(',');
}

async function generateMMZ(movingDataRows, manifestObj, annotationContent) {
	var zip = new zlibjs.Zlib.Zip();
	const m_filename = 'movement-data.csv';

	// レイヤ情報(file/title)を追加
	const layerList = manifestObj.layers;
	if (layerList && layerList[0]) {
		layerList[0].file = m_filename;
	}

	const manifestString = JSON.stringify(manifestObj, null, " ");
	zip.addFile(stringToBA(manifestString), {
		filename: stringToBA("manifest.json")
	})

	const m_ba = await buildMCSVByteArray(movingDataRows);
	zip.addFile(m_ba, {
		filename: stringToBA(m_filename)
	})

	if (annotationContent) {
		const annString = JSON.stringify(annotationContent, null, " ");
		zip.addFile(stringToBA(annString), {
			filename: stringToBA("ann.geojson")
		})
	}

	const file = zip.compress();
	return file;
}

async function buildMCSVByteArray(movingDataRows) {
	var i, totalLength;
	const n = movingDataRows.length;

	totalLength = 0;
	for (i = 0;i < n;++i) {
		totalLength += stringToBA(movingDataRows[i]+"\n").length;
	}

	const res = new Uint8Array(totalLength);
	var writePos = 0;
	for (i = 0;i < n;++i) {
		const lineArr = stringToBA(movingDataRows[i]+"\n");
		res.set(lineArr, writePos);
		writePos += lineArr.length;
	}

	return res;
}

function stringToBA(str) {
    const encoder = new TextEncoder();
	return encoder.encode(str);
}

function selftestMMZExporter() {
	generateMMZ(["id,time", "1,2001-01-01 00:00:00"], {title: "foobar"} ).then( (content) => {
		const blob = new Blob([content], {type: 'application/zip'});
		const url = window.URL.createObjectURL(blob);
		console.log(url);
	} );
}

export { selftestMMZExporter , generateMovementCSV, generateMMZ };