import zlibjs from 'zlibjs/bin/unzip.min.js'

class LoaderBase {
	constructor(listener, isZip) {
		this.listener = listener;
		this.isZip = isZip;
		this.manifest = null;
		this.annotation = null;
		this.mainFileExt = 'csv';
		this.previewHandler = null;

		this.file = null;
		this.fileReader = null;
		this.compressedFile = null;
		this.compressedFileReader = null;
		
		this.loaderWorker = null;
		this.numHeaderLines = 0;

		this.chunkSize = 10000;
		this.currentLineIndex = 0;
		this.workerResult = null;
		this.idCount = 0;
		
		this.next_proc = this.consumeContent.bind(this);
	}

	nudge(layer) {
		// implement polling
	}

	setNumHeaderLines(n) {
		this.numHeaderLines = n;
	}

	readFile(file) {
		if (this.listener && this.listener.loaderSourceSet) {
			this.listener.loaderSourceSet(this, file);
		}
		
		if (this.isZip) {
			this.compressedFile = file;
			this.compressedFileReader = new FileReader();
			
			this.compressedFileReader.onload = this.onCompressedFileLoad.bind(this);
			this.compressedFileReader.readAsArrayBuffer(file);
		} else {
			this.readMainFile(file);
		}
	}

	readMainFile(file) {
		this.file = file;
		this.fileReader = new FileReader();

		this.fileReader.onload = this.onFileLoad.bind(this);
		this.fileReader.readAsArrayBuffer(file);
	}

	onCompressedFileLoad(e) {
		var error = null;
		const buf = e.target.result;
		const z = new zlibjs.Zlib.Unzip( new Uint8Array(buf) );

		const foundMf = findManifestFile(z.getFilenames());
		if (foundMf) {
			this.manifest = decompressJSON(z, foundMf);
			if (!this.manifest) {
				error = 'manifest.json is broken.';
			}
		}		

		if (!error) {
			const foundAnn = findAnnotationFile(z.getFilenames());
			if (foundAnn) {
				this.annotation = decompressJSON(z, foundAnn);
				if (!this.annotation) {
					error = 'annotation file is broken.';
				}
			}
		}

		if (!error) {
			const mainFilename = findMainFile(z.getFilenames(), this.mainFileExt);
			if (mainFilename) {
				const content = z.decompress(mainFilename);
				this.loadFromBuffer(content.buffer);
			} else {
				error = 'Main content file is not found.';
			}
		}
		
		// Send error -----------------------------------------------
		if (error && this.listener && this.listener.loaderFileError) {
			this.listener.loaderFileError(this, error);
		}
	}

	onFileLoad(e) {
		const buf = e.target.result;
		this.loadFromBuffer(buf);
	}
	
	loadFromBuffer(buf) {
		this.loaderWorker = new Worker('workers/loader-worker.js');
		this.loaderWorker.onmessage = this.onWorkerResponse.bind(this);
		this.loaderWorker.postMessage(buf, [buf]);
	}

	onWorkerResponse(e) {
		if (e.data.hasOwnProperty('countProgress')) {
			const cc = e.data.countProgress - 0;
			this.processCountProgress(cc);
		} if (e.data.hasOwnProperty('idCount')) {
			this.idCount = e.data.idCount | 0;
			this.processAfterIdCount(this.idCount, e.data.idMap);
			this.fullLoad();
		} else if (!e.data.lineIndices) {
			// preview phase
			if (this.previewHandler) {
				this.previewHandler(e.data.content);
			}
		} else {
			this.workerResult = e.data;

			// const fileContent = e.data.content;
			// const lineIndices = e.data.lineIndices;
			// const nLines = lineIndices.length-1;

			// notify ready
			if (this.previewHandler) {
				this.previewHandler(null);
			}
		}
	}

	customPreLoad(data) {
		this.loaderWorker.postMessage(data);
	}

	fullLoad() {
		this.consumeContent();
	}

	consumeContent() {
		const CHK = this.chunkSize;
		const lineIndices = this.workerResult.lineIndices;
		const fileContent = this.workerResult.content;
		const nLines = lineIndices.length-1;

		for (var i = 0;i < CHK;++i) {
			if (this.currentLineIndex < nLines) {
				const begIndex = lineIndices[ this.currentLineIndex  ];
				const endIndex = lineIndices[ this.currentLineIndex+1];

				const subs = fileContent.substring(begIndex, endIndex);
				this.processLine(subs, this.currentLineIndex, nLines);
			} else {
				// end
				this.processFileEnd();
				return false;
			}

			++this.currentLineIndex;
		}
	
		window.setTimeout(this.next_proc, 1);
		return true;
	}

	processCountProgress(prog) {}
	processAfterIdCount(count, idMap) { }
	processLine(line, lineNo) {  }
	processFileEnd() { }
};

function decompressJSON(z, filename) {
	const src = z.decompress(filename);
	try {
		return JSON.parse( (new TextDecoder('utf-8')).decode(src) );
	} catch(err) { console.log(err); }
	return null;
}

function findByPattern(filenameList, re) {
	for (const n of filenameList) {
		if (re.test(n)) { return n; }
	}
	return null;
}

function findManifestFile(filenameList) {  return findByPattern(filenameList, /manifest\.json$/ );  }
function findAnnotationFile(filenameList) {  return findByPattern(filenameList, /a.*\.geojson$/ );  }

function findMainFile(filenameList, ext) {
	const re = new RegExp('[^._].+\\.' + ext, 'i');
	return findByPattern(filenameList, re);
}

export default LoaderBase;